import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Switch } from '@blueprintjs/core';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import { FormGroup } from '@blueprintjs/core';
import Select from 'react-select';

interface Person {
    firstName: string;
    lastName: string;
}

interface Comment {
    comment: string;
    createdAt: string;
    createdBy: string;
    id: string;
    isEdited: boolean;
    isImportant: boolean;
    updatedAt: string;
    createdPerson: Person;
}

interface CurrentUser {
    id: string;
    firstName: string;
    lastName: string;
}

interface Props {
    comments: Comment[];
    rotaBuilderStore: any;
    updateRotaShiftComments: (rotaShiftId: string, commentsArr: Comment[]) => void;
    updateNotPublishedComments: (comments: Comment[]) => void;
    rotaShiftId: string;
    shiftWasPublished: boolean;
    currentUser: CurrentUser;
}

const commentsVisibilityOptions = [
    { value: 'all', label: 'All' },
    { value: 'visible', label: 'Visible on rota' },
    { value: 'notVisible', label: 'Not visible on rota' },
];

const CommentsSection = (props: Props) => {
    const {
        comments,
        rotaBuilderStore,
        updateRotaShiftComments,
        updateNotPublishedComments,
        rotaShiftId,
        shiftWasPublished,
        currentUser,
    } = props;

    const commentsInRightOrder = comments
        ? comments.sort((x, y) => Date.parse(y.createdAt) - Date.parse(x.createdAt))
        : [];
    const [commentField, setCommentField] = useState('');
    const [isImportant, setIsImportant] = useState(false);
    const [warningField, setWarningField] = useState(false);
    const [commentUpdateId, setCommentUpdateId] = useState('');
    const [commentUpdateText, setCommentUpdateText] = useState('');
    const [commentUpdateImportant, setCommentUpdateImportant] = useState(false);
    const [warningFieldUpdateComment, setWarningFieldUpdateComment] = useState(false);
    const [commentsArr, setComments] = useState(commentsInRightOrder);
    const [commentsToShow, setCommentsToShow] = useState(commentsArr);
    const [deletePopUpState, setDeletePopUpState] = useState(false);
    const [deleteCommentId, setDeleteCommentId] = useState('');
    const [visibilityFilter, setVisibilityFilter] = useState(commentsVisibilityOptions[0]);

    const commentPublishEnabled = rotaShiftId && shiftWasPublished;

    useEffect(() => {
        updateNotPublishedComments(commentsArr);
        updateRotaShiftComments(rotaShiftId, commentsArr);
    }, [commentsArr]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (visibilityFilter.value !== 'all') {
            setCommentsToShow(
                commentsArr.filter((item: any) =>
                    visibilityFilter.value === 'visible' ? item.isImportant : !item.isImportant,
                ),
            );
        } else {
            setCommentsToShow(commentsArr);
        }
    }, [visibilityFilter, commentsArr]);

    useEffect(() => {
        const visibilityOn = commentsArr.find((item) => item.id === commentUpdateId);
        setCommentUpdateImportant(visibilityOn ? visibilityOn.isImportant : false);
    }, [commentUpdateId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCommentPost = async () => {
        if (commentField === '') {
            return setWarningField(true);
        }

        if (!commentPublishEnabled) {
            const commentCreationDate = new Date();
            const commentObj = {
                comment: commentField,
                createdAt: commentCreationDate,
                createdBy: currentUser.id,
                isEdited: false,
                isImportant: isImportant,
                id: Date.now(),
                updatedAt: commentCreationDate,
                createdPerson: { firstName: currentUser.firstName, lastName: currentUser.lastName },
            };
            setComments((prevState: any) => [commentObj, ...prevState]);
        } else {
            const newComment = await rotaBuilderStore.createRotaShiftComment(
                commentField,
                rotaShiftId,
                isImportant,
            );
            setComments((prevState: any) => [newComment, ...prevState]);
        }
        setCommentField('');
    };

    const handleCommentEdit = (id: string, comment: string) => {
        setCommentUpdateId(id);
        setCommentUpdateText(comment);
    };

    const handleCommentUpdate = async () => {
        if (commentUpdateText === '') {
            return setWarningFieldUpdateComment(true);
        }

        if (!commentPublishEnabled) {
            setComments((prevState: any) => [
                ...prevState.map((comment: any) => {
                    if (comment.id !== commentUpdateId) return { ...comment };
                    const commentUpdateDate = new Date();
                    return {
                        ...comment,
                        comment: commentUpdateText,
                        isEdited: true,
                        updatedAt: commentUpdateDate,
                        isImportant: commentUpdateImportant,
                    };
                }),
            ]);
        } else {
            const updatedComment = await rotaBuilderStore.updateRotaShiftComment(
                commentUpdateText,
                commentUpdateId,
                commentUpdateImportant,
            );

            setComments((prevState: any) => [
                ...prevState.map((comment: any) => {
                    if (comment.id !== commentUpdateId) return { ...comment };
                    return { ...updatedComment };
                }),
            ]);
        }

        setCommentUpdateId('');
        setCommentUpdateText('');
    };

    const handleCommentDelete = (id: string) => {
        setDeletePopUpState(true);
        setDeleteCommentId(id);
    };

    const deleteComment = () => {
        if (!commentPublishEnabled) {
            setComments((prevState: any) => [
                ...prevState.filter((comment: any) => comment.id !== deleteCommentId),
            ]);
        } else {
            rotaBuilderStore.deleteRotaShiftComment(deleteCommentId);
            setComments((prevState: any) => [
                ...prevState.filter((comment: any) => comment.id !== deleteCommentId),
            ]);
        }

        setDeletePopUpState(false);
        setDeleteCommentId('');
    };

    return (
        <React.Fragment>
            <div className="comments">
                <div className="comments__form">
                    <div className="comments__form-row">
                        <label htmlFor="commentField" className="comments__form__comment-label">
                            Comments
                        </label>
                        <input
                            type="text"
                            value={commentField}
                            name="commentField"
                            id="commentField"
                            className="comments__form__comment-input"
                            onChange={(e: any) => {
                                setWarningField(false);
                                setCommentField(e.target.value);
                            }}
                        />

                        <Button
                            className="comments__form-post-button"
                            type="button"
                            onClick={() => handleCommentPost()}
                        >
                            Post
                        </Button>
                        {warningField && commentField === '' ? (
                            <div className="comments__form__comment-warning-empty-input">
                                Please fill in the comment before publishing
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <FormGroup
                        label="Visible on rota?"
                        className="comments__form-visibility_toggle"
                    >
                        <Switch
                            checked={isImportant}
                            onChange={() => setIsImportant((prev) => !prev)}
                            innerLabelChecked="yes"
                            innerLabel="no"
                        />
                    </FormGroup>
                    <ConfirmationAlert
                        confirmButtonText="Yes"
                        onConfirm={() => deleteComment()}
                        onCancel={() => setDeletePopUpState(false)}
                        isOpen={deletePopUpState}
                        title="Confirm comment delete"
                        description="Do you want to delete the comment?"
                    />
                </div>
                <FormGroup label="Show:" className="comments__form-visibility-filter">
                    <Select
                        defaultValue={commentsVisibilityOptions[0]}
                        options={commentsVisibilityOptions}
                        onChange={(val: any) => {
                            setVisibilityFilter(val);
                        }}
                        value={visibilityFilter || commentsVisibilityOptions[0]}
                    />
                </FormGroup>
                <div className="comments__list">
                    {commentsToShow.map((singleComment: any) => {
                        const {
                            comment,
                            id,
                            createdPerson: { firstName, lastName },
                            createdAt,
                            createdBy,
                            isEdited,
                            isImportant,
                        } = singleComment;

                        return (
                            <div className="comments__list__comment" key={id}>
                                <div className="comments__list__comment-row">
                                    <div className="comments__list__comment__user-avatar">
                                        {firstName.charAt(0)}
                                        {lastName.charAt(0)}
                                    </div>
                                    <div className="comments__list__comment__user-name">
                                        {firstName} {lastName},
                                    </div>
                                    <div className="comments__list__comment__date">
                                        {moment(createdAt).format('DD/MM/YYYY, hh:mm A')}
                                    </div>
                                    <div className="comments__list__comment__visibility-label">
                                        {isImportant ? 'Visible on rota' : 'Not visible on rota'}
                                    </div>
                                    {isEdited ? (
                                        <div className="comments__list__comment__was-edited">
                                            Edited
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="comments__list__comment-row">
                                    <div className="comments__list__comment__text">
                                        {commentUpdateId !== id ? (
                                            <>
                                                <div>{comment}</div>
                                                {createdBy === currentUser.id ? (
                                                    <div className="comments__list__comment-row">
                                                        <div className="comments__list__comment__controls">
                                                            <span
                                                                className="comments__list__comment__controls-edit"
                                                                onClick={() =>
                                                                    handleCommentEdit(id, comment)
                                                                }
                                                            >
                                                                Edit
                                                            </span>
                                                            <span
                                                                className="comments__list__comment__controls-delete"
                                                                onClick={() =>
                                                                    handleCommentDelete(id)
                                                                }
                                                            >
                                                                Delete
                                                            </span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            <div>
                                                <textarea
                                                    name="commentText"
                                                    id="commentText"
                                                    value={commentUpdateText}
                                                    autoFocus={true}
                                                    onChange={(e: any) => {
                                                        setCommentUpdateText(e.target.value);
                                                        setWarningFieldUpdateComment(false);
                                                    }}
                                                ></textarea>
                                                {warningFieldUpdateComment &&
                                                commentUpdateText === '' ? (
                                                    <div className="comments__list__empty-comment-warning">
                                                        Please fill in the comment before updating
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <div className="comments__form__controls">
                                                    <div className="comments__form__button-wrap">
                                                        <Button
                                                            className="comments__form__save-edit-comment-button"
                                                            type="button"
                                                            onClick={() => handleCommentUpdate()}
                                                        >
                                                            Save
                                                        </Button>
                                                        <Button
                                                            className="comments__form__cancel-save-comment-button"
                                                            type="button"
                                                            onClick={() => {
                                                                setCommentUpdateId('');
                                                                setCommentUpdateText('');
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                    <FormGroup
                                                        label="Visible on rota?"
                                                        className="comments__form-visibility_toggle"
                                                    >
                                                        <Switch
                                                            checked={commentUpdateImportant}
                                                            onChange={() =>
                                                                setCommentUpdateImportant(
                                                                    (prev) => !prev,
                                                                )
                                                            }
                                                            innerLabelChecked="yes"
                                                            innerLabel="no"
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default CommentsSection;
