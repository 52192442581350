import { FC } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../hook/useStores';
import { SideSizes } from '../../../../stores/UCRStore';

import { Views, UrgentWarningsButton } from './side';
import { Arrow } from '../components/side';

interface IProps {}

const Left: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: { sideLeftSize, setSideLeftSize },
        },
    } = useStores() as { RootStore: any };

    const toggleSize = () =>
        setSideLeftSize(sideLeftSize === SideSizes.SMALL ? SideSizes.LARGE : SideSizes.SMALL);

    return (
        <aside className={`ucr__side ucr__side--left ucr__side--${sideLeftSize}`}>
            <div className="ucr__side-wrapper ucr__side-wrapper--left">
                <div className="ucr__side-content">
                    <UrgentWarningsButton size={sideLeftSize} />
                    <Views size={sideLeftSize} />
                    <Arrow
                        panel="left"
                        isReverse={sideLeftSize === SideSizes.LARGE}
                        clickEvent={toggleSize}
                    />
                </div>
            </div>
        </aside>
    );
};

export default observer(Left);
