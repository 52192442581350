import { Tooltip } from '@blueprintjs/core';
import { FC } from 'react';
import {
    IconCarRequired,
    IconWarning,
    People,
    EarliestArrival,
    LatestArrival,
    IconComplexCare,
    IconNonEnglishSpeaker,
    IconNote,
} from '../../../../../helpers/ucr/icons';

import { Patient } from '@doc-abode/data-models';
import useStores from '../../../../../hook/useStores';
import moment from 'moment';

interface IPropsDisplayWarning {
    job: Patient;
}

/*
    The 'flags' that get displayed we check the warnings store against 
    the job.id to find all the warnings. We also check the staff required amount and 
    car required.
*/
const ListViewWarnings: FC<IPropsDisplayWarning> = ({ job }): JSX.Element | null => {
    const {
        RootStore: {
            ucrStore: { warnings },
        },
    } = useStores();

    let warningArr = [];
    if (warnings[job.id]) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'has-warnings'}
                content={'Has warnings'}
            >
                <IconWarning className="ucr-listview__icon ucr-listview__icon--warnings" />
            </Tooltip>,
        );
    }
    if (job.staffRequired === 2) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'double-up'}
                content="Is double up visit"
            >
                <People className="ucr-listview__icon ucr-listview__icon--doubleup" />
            </Tooltip>,
        );
    }
    if (job.carRequired) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'car-required'}
                content="Car required"
            >
                <IconCarRequired className="ucr-listview__icon ucr-listview__icon--car" />
            </Tooltip>,
        );
    }

    if (job.availableFrom) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'earliest-arrival'}
                content={`Earliest time of arrival: ${moment(job.availableFrom).format('h:mm a')} `}
            >
                <EarliestArrival className="ucr-listview__icon ucr-listview__icon--earliest" />
            </Tooltip>,
        );
    }

    if (job.availableTo) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'latest-arrival'}
                content={`Latest time of arrival: ${moment(job.availableTo).format('h:mm a')} `}
            >
                <LatestArrival className="ucr-listview__icon ucr-listview__icon--latest" />
            </Tooltip>,
        );
    }

    if (job.careComplexity === 'complex') {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'complex'}
                content={`Care to provide is complex`}
            >
                <IconComplexCare className="ucr-listview__icon ucr-listview__icon--complexcare" />
            </Tooltip>,
        );
    }

    if (job.languagesSpoken && job.languagesSpoken?.length > 0) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'non-english'}
                content={`Non english speaker: ${job.languagesSpoken.join(', ')}`}
            >
                <IconNonEnglishSpeaker className="ucr-listview__icon ucr-listview__icon--languages" />
            </Tooltip>,
        );
    }

    if (job.notes) {
        warningArr.push(
            <Tooltip
                popoverClassName={'ucr-listview__popover'}
                key={job.id + 'notes'}
                content="Notes recorded"
            >
                <IconNote className="ucr-listview__icon ucr-listview__icon--notes" />
            </Tooltip>,
        );
    }

    if (warningArr.length > 0) {
        return <>{warningArr}</>;
    }
    return null;
};

export default ListViewWarnings;
