import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';
import RadioInput from '../common/formik/RadioInput';

const VerifyEmail = ({ didError, onVerifyContact, onRequestCode }) => {
    const [codeSent, setCodeSent] = useState(false);
    const [contactType, setContactType] = useState(false);

    const handleRequestCode = async ({ contactType }) => {
        setContactType(contactType);
        await onRequestCode(contactType);
        setCodeSent(true);
    };

    if (codeSent) {
        return (
            <Formik
                initialValues={{
                    code: '',
                    contactType,
                }}
                onSubmit={onVerifyContact}
            >
                {({ isSubmitting }) => {
                    const contactTypeText =
                        contactType === 'email' ? 'email address' : 'phone number';
                    return (
                        <Form>
                            <h2 className="h2">Verify your {contactTypeText}</h2>

                            <Input
                                name="code"
                                type="text"
                                inputMode="numeric"
                                label={`Please enter the verifcation code sent to your ${contactTypeText}`}
                                placeholder="e.g. 123456"
                                disabled={isSubmitting}
                            />

                            <p>
                                <button
                                    type="button"
                                    className="minimal-button"
                                    onClick={() => onRequestCode(contactType)}
                                >
                                    Resend verification code
                                </button>
                            </p>

                            <Button
                                intent="success"
                                large
                                loading={isSubmitting}
                                text={`Verify your ${contactTypeText}`}
                                outlined
                                icon="tick"
                                type="submit"
                                fill
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    return (
        <Formik
            initialValues={{
                contactType: '',
            }}
            onSubmit={handleRequestCode}
        >
            {({ values, isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Verify your contact details</h2>

                        <p>
                            We need to verify either your email address or phone number before you
                            proceed further. Please choose the option you prefer below and then
                            enter the verification code when prompted.
                        </p>

                        <RadioInput
                            label="Choose contact method to verify"
                            name="contactType"
                            options={[
                                { label: 'Email address', value: 'email' },
                                { label: 'Phone number', value: 'phone_number' },
                            ]}
                            disabled={isSubmitting}
                            small
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Send verification code"
                            icon="send-message"
                            type="submit"
                            outlined
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default VerifyEmail;
