import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

const SpecialitiesTableRow = ({ onClick, id, selected, name, createdAt, updatedAt, onToggle }) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{name}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
        </tr>
    );
};

export default SpecialitiesTableRow;
