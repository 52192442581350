import React, { FC } from 'react';
import { Patient, PatientAlert } from '@doc-abode/data-models';

import { formatDisplayDateTime } from '../../../modules/helpers/formatData';
import Warnings from '../../../v2/form/Warnings';
import { Button, Callout } from '../../../v2/components';
import { getHcpName, filterPatientAlerts } from '../../../../helpers/ucr';
import { IconWarning } from '../../../../helpers/ucr/icons';
import useStores from '../../../../hook/useStores';
import { WarningType } from '../../../../interfaces/ucr';
import { observer } from 'mobx-react';
import { abortStatusCheck } from '../forms/common';
import { createStepWarnings } from '../../../../helpers/ucr/warnings';

interface IProps {
    patient: Patient;
    warnings: WarningType[];
    patientAlerts: PatientAlert[];
}

/**
 * Displays warnings and alerts relating to the patient visit.
 */
export const VisitDetailsWarnings: FC<IProps> = ({ patient, patientAlerts, warnings }) => {
    const {
        RootStore: {
            usersStore: { hcpUsers },
            ucrStore: { setOpenedPatientAlertResolver, setOpenedPatientAlertInfo },
            usersStore: { users },
        },
    } = useStores() as { RootStore: any };

    const stepWarnings = createStepWarnings(hcpUsers, patient);

    const { unresolved, resolved, hasUnresolvedAlerts, hasResolvedAlerts } = filterPatientAlerts(
        patientAlerts,
    );
    const hasWarnings =
        !abortStatusCheck(patient?.jobStatus) && (warnings?.length > 0 || stepWarnings?.length > 0);
    const showWarnings = hasWarnings || hasUnresolvedAlerts || hasResolvedAlerts;

    if (!showWarnings) {
        return null;
    }
    const onViewDetailsClick = (alert: PatientAlert) => {
        setOpenedPatientAlertInfo(alert);
    };

    return (
        <div className="visit-details__warning">
            {hasUnresolvedAlerts && (
                <Callout intent="danger" Icon={IconWarning} spacerTop={false}>
                    {unresolved.map((alert, i) => (
                        <div
                            key={`visit-details__patient-unresolved-alert-${i}`}
                            className="visit-details-warnings__outer"
                        >
                            <div className="visit-details-warnings__button">
                                <div className="visit-details-warnings__button-inner">
                                    <Button
                                        name="Resolve Patient Alert"
                                        clickEvent={() => setOpenedPatientAlertResolver(alert)}
                                    />
                                </div>
                            </div>
                            <span
                                className="visit-details-warnings__view-details"
                                onClick={() => onViewDetailsClick(alert)}
                            >
                                View details
                            </span>
                            <div className="visit-details-warnings__text">
                                {`Unresolved patient alert raised by ${getHcpName(
                                    users,
                                    alert.createdBy,
                                )} at ${formatDisplayDateTime(alert.createdAt)}: ${alert.type}`}
                            </div>
                        </div>
                    ))}
                </Callout>
            )}
            {hasResolvedAlerts && (
                <Callout intent="primary" Icon={IconWarning} spacerTop={false}>
                    {resolved.map((alert, i) => (
                        <React.Fragment key={`visit-details__patient-resolved-alert-${i}`}>
                            <div className="visit-details-warnings__button">
                                <span
                                    className="visit-details-warnings__view-details"
                                    onClick={() => onViewDetailsClick(alert)}
                                >
                                    View details
                                </span>
                                <p>
                                    {`Patient alert resolved by ${getHcpName(
                                        users,
                                        alert.resolvedBy,
                                    )} on ${formatDisplayDateTime(alert.resolvedAt)}: ${
                                        alert.resolution
                                    }`}
                                </p>
                            </div>

                            <p className="visit-details-warnings__originalalert-text">
                                {`Original alert raised by ${getHcpName(
                                    users,
                                    alert.createdBy,
                                )} on ${formatDisplayDateTime(alert.createdAt)}: ${alert.type}`}
                            </p>
                        </React.Fragment>
                    ))}
                </Callout>
            )}
            {Boolean(hasWarnings) && (
                <Warnings warnings={warnings} stepWarnings={stepWarnings} spacerTop={false} />
            )}
        </div>
    );
};

export default observer(VisitDetailsWarnings);
