import React, { useState } from 'react';
import { Button } from '@blueprintjs/core';
import { Redirect } from 'react-router-dom';

function Edit(props) {
    const pathname = props.match.path.startsWith('/scheduled')
        ? `/scheduled/jobs/${props.id}/edit`
        : `/on-demand/${props.match.params.tab}/jobs/${props.id}/edit`;

    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname,
                    state: { closeModal: true },
                }}
                push
            />
        );
    }

    return (
        <Button large={true} onClick={() => setRedirect(true)}>
            Edit
        </Button>
    );
}

export default Edit;
