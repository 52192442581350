import moment from 'moment/moment';
import { EnumOptionPattern, HcpSchedule, ScheduleHcp } from './HcpAvailabilityFormTypes';

export function getPatternLabel(schedule: ScheduleHcp, isMultipleTimes = false) {
    const scheduleKeyString = `${schedule.startDateTime.format(
        'HH:mm',
    )} - ${schedule.endDateTime.format('HH:mm')}`;

    if (isMultipleTimes) {
        return { label: 'Custom', value: 'custom' };
    }

    switch (scheduleKeyString) {
        case '00:00 - 00:00':
            return { label: 'None', value: 'none' };
        case '08:00 - 20:15':
            return { label: 'Long day (8)', value: EnumOptionPattern.LONG_DAY_8 };
        case '09:00 - 21:15':
            return { label: 'Long day (9)', value: EnumOptionPattern.LONG_DAY_9 };
        case '09:00 - 17:00':
            return { label: 'Early day', value: EnumOptionPattern.EARLY_DAY };
        case '13:15 - 21:15':
            return { label: 'Late day', value: EnumOptionPattern.LATE_DAY };
        case '17:00 - 21:15':
            return { label: 'Late (evening only)', value: EnumOptionPattern.LATE_EVENING_ONLY };

        default:
            return { label: 'Custom', value: 'custom' };
    }
}

export function getSortedHcpHours(hcpSchedules: HcpSchedule[]) {
    const sortedSchedules = hcpSchedules?.sort((a, b) => {
        return moment(a.startDateTime).isAfter(moment(b.startDateTime)) ? 1 : -1;
    });
    const sortedHcpSchedules = hcpSchedules?.length > 1 ? sortedSchedules : [...hcpSchedules];

    return sortedHcpSchedules;
}

export function getMaxSchedule(schedules: HcpSchedule[]) {
    return {
        startDateTime: moment(schedules[0].startDateTime),
        endDateTime: moment(schedules[0].endDateTime),
    };
}
