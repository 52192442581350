import { JobStatus, Vaccination } from '@doc-abode/data-models';
import { VaccinationRoute } from '../components/pages/vaccinations/types';

/**
 * 3 factors are used to decide if a patient (Vaccination) can be removed from a route (Vaccination Route)
 * the job status of the patient (Vaccination.jobStatus)
 * the job status of the route   (VaccinationRoute.jobStatus)
 * if the current user of the system is a superuser (RootStore.userStore.isSuperuser)
 *
 * 1 factor is used to decide if the route (VaccinationRoute) should be recalculated after a patient (Vaccination) is removed
 * the job status of the route (VaccinationRoute.jobStatus)
 *
 * On route recalculation the normal way
 *
 *   "The changes to the route are not saved until the recalculation completes and results are accepted"
 *   from https://docabode.atlassian.net/wiki/spaces/KB/pages/2546237441/VAC-RM-04c+Removing+patients+from+an+existing+route
 *   currently assuming that this is the normal way
 *
 *  When no recalculation takes place the patient (Vaccination) is removed from the route
 *  if the patient had required a pickup (instruction.instructionType = 'Pickup') from a location (Instruction.itineraryItem )
 *  and no other patients in the route require a pickup from the same location (Instruction.itineraryItem.location) remove the pickup from the route.
 *
 *  how is the removal to be done, what job state should the removed pickup job have
 */
export enum EnumJobStatusVaccineRouteStatusMatrixRules {
    NA = 'N/A',
    NA_GETS_UNASSIGNED = 'N/A gets unassigned',
    NO = 'NO',
    YES_RETAIN_TRACING = ' with retaining strikethrough tracing record in the itinerary',
    YES = 'YES',
    // only for Superuser, if set to Assigned by Superuser via the ‘Mark as incomplete’ button
    YES_ONLY_SUPERUSER = 'Yes only super user',
}

// The possible JobStatus for VaccineRoute (vaccinationRoute) in the matrix
type TVaccineRouteJobStatus = {
    // looks like unconfirmed (from table t1 https://docabode.atlassian.net/wiki/spaces/KB/pages/2546237441/VAC-RM-04c+Removing+patients+from+an+existing+route)
    // is AVAILABLE, compared functionality of existing code.
    [JobStatus.AVAILABLE]: EnumJobStatusVaccineRouteStatusMatrixRules;
    [JobStatus.ACCEPTED]: EnumJobStatusVaccineRouteStatusMatrixRules;
    [JobStatus.CURRENT]: EnumJobStatusVaccineRouteStatusMatrixRules;
    [JobStatus.COMPLETED]: EnumJobStatusVaccineRouteStatusMatrixRules;
    [JobStatus.CONTROLLER_ABORTED]: EnumJobStatusVaccineRouteStatusMatrixRules;
    [JobStatus.HCP_ABORTED]: EnumJobStatusVaccineRouteStatusMatrixRules;
};
function makeVaccineRouteJobStatus(): TVaccineRouteJobStatus {
    return {
        [JobStatus.AVAILABLE]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
        [JobStatus.ACCEPTED]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
        [JobStatus.CURRENT]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
        [JobStatus.COMPLETED]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
        [JobStatus.CONTROLLER_ABORTED]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
        [JobStatus.HCP_ABORTED]: EnumJobStatusVaccineRouteStatusMatrixRules.NA,
    };
}
// The possible JobStatus for Vaccination (vaccinationPatient) in the matrix
type TJobStatusVaccineRouteStatusMatrix = {
    [JobStatus.AVAILABLE]: TVaccineRouteJobStatus;
    [JobStatus.CURRENT]: TVaccineRouteJobStatus;
    [JobStatus.ARRIVED]: TVaccineRouteJobStatus;
    [JobStatus.COMPLETED]: TVaccineRouteJobStatus;
    [JobStatus.HCP_ABORTED]: TVaccineRouteJobStatus;
};

const removalMatrixRules: TJobStatusVaccineRouteStatusMatrix = {
    [JobStatus.AVAILABLE]: makeVaccineRouteJobStatus(),
    [JobStatus.CURRENT]: makeVaccineRouteJobStatus(),
    [JobStatus.ARRIVED]: makeVaccineRouteJobStatus(),
    [JobStatus.COMPLETED]: makeVaccineRouteJobStatus(),
    [JobStatus.HCP_ABORTED]: makeVaccineRouteJobStatus(),
};
// setup the matrix as per table T1
// Permissions to reomve patients from the route by route and job statuses
// https://docabode.atlassian.net/wiki/spaces/KB/pages/2546237441/VAC-RM-04c+Withdrawing+patients+from+an+existing+route
//
/* eslint-disable */
// having these assignments one line makes it much easier to read for me, will
// restore the linting if required.
// Vaccination Job JobStatus.AVAILABLE (table T1 row 1) i think assigned in the text  == JobStatus.AVAILABLE
// from src/components/pages/vaccinations/utils/constants.ts  AVAILABLE: 'Unconfirmed', so unconfirmed for the route in the table T1 is JobStatus.AVAILABLE
//                          patient               route
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.AVAILABLE] = EnumJobStatusVaccineRouteStatusMatrixRules.YES;
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.ACCEPTED] = EnumJobStatusVaccineRouteStatusMatrixRules.YES;
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.CURRENT] = EnumJobStatusVaccineRouteStatusMatrixRules.YES;
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.COMPLETED] = EnumJobStatusVaccineRouteStatusMatrixRules.YES_ONLY_SUPERUSER
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.CONTROLLER_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA_GETS_UNASSIGNED;
removalMatrixRules[JobStatus.AVAILABLE][JobStatus.HCP_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA_GETS_UNASSIGNED;

// Vaccination Job JobStatus.CURRENT  (table T1 row 2)
//                          patient               route
removalMatrixRules[JobStatus.CURRENT][JobStatus.AVAILABLE] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.CURRENT][JobStatus.ACCEPTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.CURRENT][JobStatus.CURRENT] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;
removalMatrixRules[JobStatus.CURRENT][JobStatus.COMPLETED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.CURRENT][JobStatus.CONTROLLER_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA_GETS_UNASSIGNED;
removalMatrixRules[JobStatus.CURRENT][JobStatus.HCP_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;

//  Vaccination Job JobStatus.ARRIVED (table T1 row 3)
//                          patient               route
removalMatrixRules[JobStatus.ARRIVED][JobStatus.AVAILABLE] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.ARRIVED][JobStatus.ACCEPTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.ARRIVED][JobStatus.CURRENT] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;
removalMatrixRules[JobStatus.ARRIVED][JobStatus.COMPLETED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.ARRIVED][JobStatus.CONTROLLER_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA_GETS_UNASSIGNED;
removalMatrixRules[JobStatus.ARRIVED][JobStatus.HCP_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;

//  Vaccination Job JobStatus.COMPLETED (table T1 row 4)
//                          patient               route
removalMatrixRules[JobStatus.COMPLETED][JobStatus.AVAILABLE] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.COMPLETED][JobStatus.ACCEPTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.COMPLETED][JobStatus.CURRENT] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;
removalMatrixRules[JobStatus.COMPLETED][JobStatus.COMPLETED] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;
removalMatrixRules[JobStatus.COMPLETED][JobStatus.CONTROLLER_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;
removalMatrixRules[JobStatus.COMPLETED][JobStatus.HCP_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NO;

//  Vaccination Job JobStatus.HCP_ABORTED (table T1 row 5)
//                          patient               route
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.AVAILABLE] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.ACCEPTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.CURRENT] = EnumJobStatusVaccineRouteStatusMatrixRules.YES;
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.COMPLETED] = EnumJobStatusVaccineRouteStatusMatrixRules.YES_RETAIN_TRACING;
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.CONTROLLER_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.NA_GETS_UNASSIGNED;
removalMatrixRules[JobStatus.HCP_ABORTED][JobStatus.HCP_ABORTED] = EnumJobStatusVaccineRouteStatusMatrixRules.YES_RETAIN_TRACING;
/* eslint-enable */

interface IIsRemoveableableJobStatusVaccineRouteStatusMatrixRule {
    jobStatusVaccineRouteStatusMatrixRule: EnumJobStatusVaccineRouteStatusMatrixRules;
    isSuperuser: boolean;
}
/**
 *
 */
export function isRemovalAllowedByMatrixRule({
    jobStatusVaccineRouteStatusMatrixRule,
    isSuperuser,
}: IIsRemoveableableJobStatusVaccineRouteStatusMatrixRule): boolean {
    const anyoneCanRemove: EnumJobStatusVaccineRouteStatusMatrixRules[] = [
        EnumJobStatusVaccineRouteStatusMatrixRules.YES,
        EnumJobStatusVaccineRouteStatusMatrixRules.YES_RETAIN_TRACING,
    ];
    if (anyoneCanRemove.includes(jobStatusVaccineRouteStatusMatrixRule)) {
        return true;
    }
    // only for Superuser, if set to Assigned by Superuser via the ‘Mark as incomplete’ button
    // the note for the superuser suggests that no only do you have to be a super user but also that the person
    // who assigned it was a superuser and did it in a particular way.
    if (
        jobStatusVaccineRouteStatusMatrixRule ===
            EnumJobStatusVaccineRouteStatusMatrixRules.YES_ONLY_SUPERUSER &&
        isSuperuser
    ) {
        return true;
    }
    return false;
}

type TAllowableVaccinationPatientStatus =
    | JobStatus.AVAILABLE
    | JobStatus.CURRENT
    | JobStatus.ARRIVED
    | JobStatus.COMPLETED
    | JobStatus.HCP_ABORTED;
type TAllowableVaccinationRouteStatus =
    | JobStatus.AVAILABLE
    | JobStatus.ACCEPTED
    | JobStatus.CURRENT
    | JobStatus.COMPLETED
    | JobStatus.CONTROLLER_ABORTED
    | JobStatus.HCP_ABORTED;

interface IGetMatrixRule {
    // see note below about why these can be undefined.
    vaccinationPatient: Vaccination | undefined;
    vaccinationRoute: VaccinationRoute | undefined;
}
export function getVaccinePatientVaccineRouteStatusMatrixRule({
    vaccinationPatient,
    vaccinationRoute,
}: IGetMatrixRule): EnumJobStatusVaccineRouteStatusMatrixRules {
    const defaultTo = EnumJobStatusVaccineRouteStatusMatrixRules.NA;
    // When I ran this the VaccinationRoute was undefined, so that is possible, not sure if its intended
    // that vaccinationRoute can be undefined, so accounting for that and a possible undefined vaccinationPatient.
    // I think that the value gets populated by a useEffect so starts off undefined, and this has not been accounted for in the typescript
    // According to IPatientDetailsFooter VaccinationRoute should not be undefined, but it looks like the code in
    // PatientDetailsFooter does as we get route via getJobs and not passed to PatientDetailsFooter as a prop.
    if (!vaccinationPatient || !vaccinationRoute) {
        return defaultTo;
    }
    const vaccinePatientJobStatusMatrixKeys = Object.keys(removalMatrixRules);
    // Check if the Vaccination.jobStatus (vaccinationPatient) is in the  rules matrix
    if (!vaccinePatientJobStatusMatrixKeys.includes(vaccinationPatient.jobStatus)) {
        return defaultTo;
    }
    // Check if the VaccinationRoute.jobStatus is in the rules matrix
    const vaccineRouteJobStatusMatrixKeys = Object.keys(makeVaccineRouteJobStatus());
    if (!vaccineRouteJobStatusMatrixKeys.includes(vaccinationRoute.jobStatus)) {
        return defaultTo;
    }
    // we know its TAllowableVaccinationPatientStatus so safe to cast.  Is there a better way to do this? Can we just
    // check vaccinationPatient.jobStatus is on of the type values of TAllowableVaccinationPatientStatus
    // and vaccinationRoute.jobStatus  is on of the type values of TAllowableVaccinationRouteStatus
    const vaccinationPatientJobStatus = vaccinationPatient.jobStatus as TAllowableVaccinationPatientStatus;
    const vaccinationRouteJobStatus = vaccinationRoute.jobStatus as TAllowableVaccinationRouteStatus;
    return removalMatrixRules[vaccinationPatientJobStatus][vaccinationRouteJobStatus];
}

interface IVaccinationRouteAllowsRecalculation {
    vaccinationRoute: VaccinationRoute;
}

/**
 * this just checks if the VaccinationRoute.jobStatus allows for it to be recalculated
 * you need vaccinationRouteShouldBeRecalculated to see if you should do the recalculation
 * trigger recalculation The Route status is either:
 * Available
 * Accepted
 * Current
 * and patient is eligible for removal
 */
export function vaccinationRouteStatusAllowsRecalculation({
    vaccinationRoute,
}: IVaccinationRouteAllowsRecalculation): boolean {
    return [JobStatus.AVAILABLE, JobStatus.ACCEPTED, JobStatus.CURRENT].includes(
        vaccinationRoute.jobStatus,
    );
}

interface ICanRemoveVaccinationPatientFromVaccinationRoute {
    vaccinationPatient: Vaccination;
    vaccinationRoute: VaccinationRoute;
    isSuperuser: boolean;
}
/**
 * rules / spec here:
 * https://docabode.atlassian.net/wiki/spaces/KB/pages/2546237441/VAC-RM-04c+Withdrawing+patients+from+an+existing+route
 *
 * Cross-reference the vaccinationPatient.jobStatus and vaccinationRoute.jobStatus on the rules matrix
 * (jobStatusVaccineRouteStatusMatrixRule) and the users status as a superuser or non-superuser
 */
export function canRemoveVaccinationPatientFromVaccinationRoute({
    vaccinationPatient,
    vaccinationRoute,
    isSuperuser,
}: ICanRemoveVaccinationPatientFromVaccinationRoute): boolean {
    const jobStatusVaccineRouteStatusMatrixRule = getVaccinePatientVaccineRouteStatusMatrixRule({
        vaccinationPatient: vaccinationPatient,
        vaccinationRoute: vaccinationRoute,
    });
    // now we have the rule from the matrix, so we know if the vaccination Patient can be removed
    return isRemovalAllowedByMatrixRule({
        jobStatusVaccineRouteStatusMatrixRule,
        isSuperuser,
    });
}

interface IVaccinationRouteShouldBeRecalculatedWhenRemovingPatient {
    vaccinationPatient: Vaccination;
    vaccinationRoute: VaccinationRoute;
    isSuperuser: boolean;
}

/**
 * not currently used in code but may be in the future see note in UpdateRoute.
 */
export function vaccinationRouteShouldBeRecalculatedWhenRemovingPatient({
    vaccinationPatient,
    vaccinationRoute,
    isSuperuser,
}: IVaccinationRouteShouldBeRecalculatedWhenRemovingPatient): boolean {
    return (
        canRemoveVaccinationPatientFromVaccinationRoute({
            vaccinationPatient,
            vaccinationRoute,
            isSuperuser,
        }) && vaccinationRouteStatusAllowsRecalculation({ vaccinationRoute })
    );
}
