import React from 'react';

import { formatDate } from '../../../modules/helpers/formatData';
import { Registration } from '../../../../stores/RegistrationStore';

interface RegistrationItemProps extends Registration {
    onClick: (id: string, event: React.MouseEvent) => void;
}

const RegistrationItem = ({
    onClick,
    id,
    updatedAt,
    createdPerson,
    professionalBody,
    fitToPractice,
    registrationContent = {},
}: RegistrationItemProps) => {
    return (
        <tr
            onClick={(event) => {
                if (!id || id.length === 0) return;
                onClick(id, event);
            }}
            className="common-table__row"
        >
            <td>{professionalBody.name}</td>
            <td>{registrationContent.professionalBodyId}</td>
            <td>{`${createdPerson.firstName} ${createdPerson.lastName}`}</td>
            <td>{formatDate(updatedAt)}</td>
            <td>{fitToPractice.label}</td>
        </tr>
    );
};

export default RegistrationItem;
