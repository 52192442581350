import {
    Alert,
    Button,
    FormGroup,
    InputGroup,
    Menu,
    MenuItem,
    Popover,
    RadioGroup,
} from '@blueprintjs/core';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import CommonPagination from '../CommonHRRotaPagination';
import { onFilterChange, onTableSearch } from '../../../../helpers/common';
import {
    EMPLOYEE_RECORD_STATUS_OPTIONS,
    EMPLOYEE_RECORD_COMPLIANCE_STATUS,
} from '../../../../constants/hrConst';

const NavigationStaff = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onFilter,
    onSearch,
    dictionaryStore: { roles, groups, payRates },
    departmentsStore,
    addNewEmployee,
    showFilters = true,
    personId,
    refresh,
    typeOfStaffRecord,
    handleChangeTypeOfStaffRecord,
    addDisabled,
    selected,
    validateStaffBeforeArchiving,
}) => {
    const [showAddNewConfirmation, setShowAddNewConfirmation] = useState(false);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const onChange = useCallback((name) => (el) => onFilterChange(name, el, onFilter), [onFilter]);

    useEffect(() => {
        if (personId) {
            onChange('personId')([{ value: personId }]);
        }
    }, [personId, onChange]);

    const onSearchChange = useCallback(
        async (inputValue) => {
            onTableSearch(inputValue, onSearch);
        },
        [onSearch],
    );

    const addNew = useCallback(() => {
        if (personId) {
            return setShowAddNewConfirmation(true);
        }
        addNewEmployee();
    }, [personId, addNewEmployee]);

    const Content = () => (
        <Menu>
            <MenuItem
                text="Archive"
                disabled={!selected.length}
                onClick={() => validateStaffBeforeArchiving(selected)}
            />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    {showFilters && (
                        <div className="common-header__actions">
                            <div className="common-header__filters common-header__filters--wrap">
                                <FormGroup label="Department" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        onMenuOpen={async () => {
                                            await departmentsStore.getAllDepartments({
                                                hasActiveAssignments: true,
                                            });
                                            await setDepartmentOptions(
                                                departmentsStore.allDepartmentsActiveOptions,
                                            );
                                        }}
                                        isLoading={
                                            !departmentsStore.allDepartmentsActiveOptions?.length
                                        }
                                        options={[
                                            ...departmentOptions,
                                            { label: 'Not assigned', value: 'notAssigned' },
                                        ]}
                                        isMulti
                                        onChange={onChange('departmentId')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup label="Role" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        options={roles}
                                        isMulti
                                        onChange={onChange('roles')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup label="Group" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        options={groups}
                                        isMulti
                                        onChange={onChange('groups')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Employee Status"
                                    className="common-header__filter"
                                >
                                    <Select
                                        placeholder="All"
                                        options={EMPLOYEE_RECORD_STATUS_OPTIONS}
                                        isMulti
                                        onChange={onChange('statusId')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup label="Compliance" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        options={EMPLOYEE_RECORD_COMPLIANCE_STATUS}
                                        isMulti
                                        onChange={onChange('isCompliant')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup label="Pay rates" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        options={payRates}
                                        isMulti
                                        onChange={onChange('payRateId')}
                                        className="react-select"
                                    />
                                </FormGroup>
                                <FormGroup
                                    label="Search"
                                    helperText="Type at least three characters to see results"
                                    className="common-header__filter"
                                >
                                    <InputGroup
                                        onChange={(e) => onSearchChange(e.target.value)}
                                        placeholder="Enter search query..."
                                        large
                                    />
                                </FormGroup>
                            </div>
                            <CommonPagination
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageInfo={pageInfo}
                                itemsName="staff records"
                            />
                        </div>
                    )}
                    <div>
                        <Alert
                            isOpen={showAddNewConfirmation}
                            onConfirm={addNewEmployee}
                            onCancel={() => setShowAddNewConfirmation(false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="warning"
                        >
                            <p>What do you want to create?</p>
                            <RadioGroup
                                name="group"
                                options={[
                                    { label: 'New Staff Record', value: 'employed' },
                                    { label: 'New Engagement Record', value: 'sessional' },
                                ]}
                                onChange={handleChangeTypeOfStaffRecord}
                                selectedValue={typeOfStaffRecord}
                            />
                        </Alert>
                        <Button
                            disabled={addDisabled}
                            text="New"
                            intent="success"
                            icon="add-to-artifact"
                            onClick={addNew}
                            className="common-header__button"
                            outlined
                        />
                        <Button
                            text="Refresh"
                            icon="refresh"
                            onClick={refresh}
                            className="common-header__button"
                            outlined
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content shouldDismissPopover={false} />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    outlined
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default NavigationStaff;
