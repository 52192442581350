import moment from 'moment';
import {
    Vaccination,
    ConsentStatus,
    VaccinationCategory,
    Covid19Vaccine,
    FluVaccine,
    ShinglesVaccine,
    PneumococcalVaccine,
} from '@doc-abode/data-models';

import { flagsIcons } from '../../../../constants/patientsConst';

export const filterableJobStatuses = [
    'PENDING',
    'AVAILABLE',
    'ACCEPTED',
    'CURRENT',
    'ARRIVED',
    'WITHDRAWN',
    'HCP_ABORTED',
];

export default function filterOptions(configStore: any, filters: any, practiceOptions: any) {
    return [
        {
            name: 'jobStatus',
            label: 'Status',
            options: filterableJobStatuses,
            labelTransform: Vaccination.getFriendlyPatientStatus,
        },
        {
            name: 'hubId',
            label: 'Hub',
            options: configStore.hubIds,
            labelTransform: configStore.getHubName,
        },
        {
            name: 'practice',
            label: 'Practice',
            multiSelectOptions: practiceOptions,
        },
        {
            name: 'flags',
            label: 'Flags',
            options: Object.keys(flagsIcons),
            valueTransform: (values: string[]) => values.map((value) => ({ contains: value })),
        },
        {
            name: 'vaccinationCategory',
            label: 'Vaccine',
            options: Object.values(VaccinationCategory),
            labelTransform: Vaccination.getFriendlyVaccinationCategory,
        },
        {
            name: 'doseNumber',
            label: 'Dose no.',
            options: ['1', '2', '3', '4', '5'],
        },
        {
            name: 'vaccineManufacturer',
            label: 'Manufacturer',
            options: Object.values({
                ...(!filters.vaccinationCategory ||
                filters.vaccinationCategory.includes(VaccinationCategory.COVID_19)
                    ? Covid19Vaccine
                    : {}),
                ...(!filters.vaccinationCategory ||
                filters.vaccinationCategory.includes(VaccinationCategory.FLU)
                    ? FluVaccine
                    : {}),
                ...(!filters.vaccinationCategory ||
                filters.vaccinationCategory.includes(VaccinationCategory.SHINGLES)
                    ? ShinglesVaccine
                    : {}),
                ...(!filters.vaccinationCategory ||
                filters.vaccinationCategory.includes(VaccinationCategory.PNEUMOCOCCAL)
                    ? PneumococcalVaccine
                    : {}),
            }),
            labelTransform: Vaccination.getFriendlyManufacturer,
        },
        {
            name: 'consented',
            label: 'Consented',
            options: Object.keys(ConsentStatus),
            labelTransform: Vaccination.getFriendlyConsentStatus,
        },
        {
            name: 'dateOfBirth',
            label: 'Age',
            range: [0, 120],
            valueTransform: (value: [number, number]) => {
                const [min, max] = value;

                return [
                    {
                        le: moment.utc().subtract(min, 'years').format(),
                        ge: moment
                            .utc()
                            .subtract(max + 1, 'years')
                            .format(),
                    },
                ];
            },
        },
        {
            name: 'dateOfPreviousDose',
            label: 'Weeks since previous dose',
            range: [0, 28],
            labelRenderer: (value: number) => (value === 28 ? '28+' : value),
            valueTransform: (value: [number, number]) => {
                const [min, max] = value;

                if (min === 0 && max === 28) {
                    return null;
                }

                const le = moment.utc().subtract(min, 'weeks').format();
                const ge =
                    max < 28
                        ? moment
                              .utc()
                              .subtract(max + 1, 'weeks')
                              .format()
                        : moment.utc('2021-01-01').format();

                return {
                    spread: [
                        {
                            and: [
                                {
                                    doseNumber: {
                                        eq: 2,
                                    },
                                },
                                {
                                    and: [
                                        {
                                            dateOfDose1: {
                                                le,
                                            },
                                        },
                                        {
                                            dateOfDose1: {
                                                ge,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            and: [
                                {
                                    doseNumber: {
                                        eq: 3,
                                    },
                                },
                                {
                                    and: [
                                        {
                                            dateOfDose2: {
                                                le,
                                            },
                                        },
                                        {
                                            dateOfDose2: {
                                                ge,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            and: [
                                {
                                    doseNumber: {
                                        eq: 4,
                                    },
                                },
                                {
                                    and: [
                                        {
                                            dateOfDose3: {
                                                le,
                                            },
                                        },
                                        {
                                            dateOfDose3: {
                                                ge,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            and: [
                                {
                                    doseNumber: {
                                        eq: 5,
                                    },
                                },
                                {
                                    and: [
                                        {
                                            dateOfDose4: {
                                                le,
                                            },
                                        },
                                        {
                                            dateOfDose4: {
                                                ge,
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                };
            },
        },
    ];
}
