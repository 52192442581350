import * as Yup from 'yup';
import moment from 'moment';
import { dateFormat } from '../../../../constants/patientsConst';

export const validationSchema = Yup.object({
    title: Yup.mixed().typeError('This field is required.').required('This field is required.'),
    firstName: Yup.string()
        .trim()
        .min(3, 'The name must be a minimum of 3 characters excluding whitespace')
        .required('This field is required.'),
    preferredName: Yup.string()
        .nullable()
        .trim()
        .min(3, 'The name must be a minimum of 3 characters excluding whitespace'),
    lastName: Yup.string()
        .trim()
        .min(3, 'The name must be a minimum of 3 characters excluding whitespace')
        .required('This field is required.'),
    maidenName: Yup.string()
        .nullable()
        .trim()
        .min(3, 'The name must be a minimum of 3 characters excluding whitespace'),
    personalPhone: Yup.string()
        .nullable()
        .matches(/^\d{11}$/, 'Mobile number is invalid, must be 11 digits only.'),
    personalEmail: Yup.string()
        .nullable()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email format.'),
    dateOfBirth: Yup.date()
        .typeError('Date format must be DD/MM/YYYY')
        .nullable()
        .test('min date', 'The person needs to be at least 16 years old', (value) => {
            if (!value) {
                return true;
            }
            const inputDate = moment(value, dateFormat);
            return moment().diff(inputDate, 'years') >= 16;
        })
        .test('max date', 'The person needs to be maximum 100 years old', (value) => {
            if (!value) {
                return true;
            }
            return moment().diff(value, 'days') <= 36525;
        }),
    genderId: Yup.string().required('This field is required.'),
    nationalInsurance: Yup.string()
        .nullable()
        .matches(
            /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s*\d{2}){3}\s*[A-D]$/,
            'National insurance number should match format XX 12 34 56 X',
        ),
    dbsNumber: Yup.string()
        .typeError('Invalid DBS reference number')
        .nullable(true)
        .notRequired()
        .test('check if is number', 'Invalid DBS reference number', (value) => {
            if (!value) {
                return true;
            }
            return !isNaN(value);
        })
        .test(
            'min characters',
            'The DBS must be a minimum of 12 characters excluding whitespace',
            (value) => {
                if (!value) {
                    return true;
                }
                const inputDbsNumberLength = value.trim().length;
                return inputDbsNumberLength === 12;
            },
        ),
    employmentStartDate: Yup.date()
        .nullable()
        .required('The Continuous Employment Start Date is required.'),
    employmentEndDate: Yup.date()
        .nullable()
        .when('employmentStartDate', (employmentStartDate, yup) => {
            if (employmentStartDate) {
                yup.min(
                    employmentStartDate,
                    'Continuous Employment End Date cannot be before Continuous Employment Start Date',
                );
            }
        }),
});
