import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { formatTime } from '../../../helpers/FormatTime';
import TableRowActions from '../../../../common/TableRowActions';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';
import { Icon, Tooltip } from '@blueprintjs/core';
import { Colors } from '@blueprintjs/core';
import _ from 'lodash';

const ShiftPattern = inject('RootStore')(
    observer(
        class ShiftPatternDay extends Component {
            async componentDidMount() {
                await this.props.RootStore.staffStore.getAllStaffs();
            }

            _handleDeleteClick = () => {
                this.props.RootStore.shiftPatternsStore.deleteNewShiftPatternEntry(
                    this.props.shift,
                );
            };

            _handleDuplicateClick = (e) => {
                e.stopPropagation();
                const parentShift = Object.assign({}, this.props.shift);
                delete parentShift.id;
                delete parentShift.newShiftId;
                const newShift = {
                    ...parentShift,
                    newShiftId: uuid_v4(),
                };
                this.props.RootStore.shiftPatternsStore.addNewShiftPatternEntry(newShift);

                this.props.RootStore.shiftPatternsStore.clipboardActive = true;
            };

            render() {
                const { editOrCreateMode, dayNum, weekNum, shift, onClick } = this.props;

                const staffList = this.props.RootStore.staffStore.allStaffs.reduce((acc, item) => {
                    acc[item.id] =
                        item.employeeStatus?.name.match('Suspended') ||
                        item.employeeStatus?.name.match('Archived')
                            ? item.person.firstName +
                              ' ' +
                              item.person.lastName +
                              ' (' +
                              item.employeeStatus?.name +
                              ')'
                            : item.person.firstName + ' ' + item.person.lastName;
                    return acc;
                }, {});

                const isSupervisorStaff = this.props.RootStore.staffStore.allStaffs.filter(
                    (staff) => staff.isSupervisor && staff.id === shift.defaultEmployeeId,
                ).length;

                return (
                    <>
                        {+shift.dayOfWeek === +dayNum && +shift.weekNumber === +weekNum && (
                            <Tooltip
                                openOnTargetFocus={false}
                                position={'bottom'}
                                content={
                                    <div className="rota-shift-tooltip__inner">
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Location:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {
                                                    this.props.RootStore.dictionaryStore
                                                        .locationsDictionary[shift.locationId]
                                                }
                                            </p>
                                        </div>

                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">Role:</p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {
                                                    this.props.RootStore.dictionaryStore
                                                        .rolesDictionary[
                                                        shift.shiftEmployeeRoleTypeId
                                                    ]
                                                }
                                            </p>
                                        </div>

                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">Shift:</p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {moment(shift.startTime).format('HH:mm')} -{' '}
                                                {moment(shift.endTime).format('HH:mm')}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Break duration:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">{`${shift.breakDurationMinutes} mins`}</p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Break is paid:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {shift.breakIsPaid ? 'Yes' : 'No'}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Default staff member:
                                            </p>
                                            <p
                                                style={{
                                                    color: `${
                                                        staffList[shift.defaultEmployeeId]?.match(
                                                            'Suspended',
                                                        ) ||
                                                        staffList[shift.defaultEmployeeId]?.match(
                                                            'Archived',
                                                        )
                                                            ? Colors.RED3
                                                            : ''
                                                    }`,
                                                }}
                                                className="rota-shift-tooltip__item-val"
                                            >
                                                {shift.defaultEmployeeId ? (
                                                    staffList[shift.defaultEmployeeId]
                                                ) : (
                                                    <span className="rota-shift-tooltip__item--warning">
                                                        Unfilled
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Override value per shift :
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                &#163;{' '}
                                                {` ${
                                                    shift.overrideValueInPence
                                                        ? shift.overrideValueInPence
                                                        : '-'
                                                }`}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Pay rate:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {
                                                    this.props.RootStore.dictionaryStore
                                                        .payRatesDictionary[shift.payRateId]
                                                }
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Shift type:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {
                                                    this.props.RootStore.dictionaryStore
                                                        .shiftTypesDictionary[shift.typeId]
                                                }
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Third-party paid:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {shift.thirdPartyPaid ? 'Yes' : 'No'}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">
                                                Training Shift:
                                            </p>
                                            <p className="rota-shift-tooltip__item-val">
                                                {shift.trainingShift ? 'Yes' : 'No'}
                                            </p>
                                        </div>
                                        <div className="rota-shift-tooltip__item">
                                            <p className="rota-shift-tooltip__item-key">Trainee:</p>
                                            <p
                                                style={{
                                                    color: `${
                                                        staffList[
                                                            shift.trainees[0]?.traineeId
                                                        ]?.match('Suspended') ||
                                                        staffList[
                                                            shift.trainees[0]?.traineeId
                                                        ]?.match('Archived')
                                                            ? Colors.RED3
                                                            : ''
                                                    }`,
                                                }}
                                                className="rota-shift-tooltip__item-val"
                                            >
                                                {!_.values(shift.trainees[0]).every(_.isEmpty) &&
                                                    staffList[shift.trainees[0]?.traineeId]}
                                            </p>
                                        </div>
                                    </div>
                                }
                                usePortal={true}
                            >
                                <div className="rota-shift">
                                    <div onClick={(event) => onClick(shift, event)}>
                                        <div className="truncate rota-shift__content-duty-station">
                                            {shift.shiftEmployeeRoleTypeId
                                                ? this.props.RootStore.dictionaryStore
                                                      .rolesDictionary[
                                                      shift.shiftEmployeeRoleTypeId
                                                  ]
                                                : 'Shift Entry'}
                                        </div>
                                        <span
                                            style={{
                                                color: `${
                                                    staffList[shift.defaultEmployeeId]?.match(
                                                        'Suspended',
                                                    ) ||
                                                    staffList[shift.defaultEmployeeId]?.match(
                                                        'Archived',
                                                    )
                                                        ? Colors.RED3
                                                        : ''
                                                }`,
                                            }}
                                            className="weeks-table__col-content-subtitle"
                                        >
                                            {staffList[shift.defaultEmployeeId]}
                                        </span>
                                        <span className="weeks-table__col-content-time">
                                            {`${formatTime(
                                                new Date(shift.startTime),
                                            )} - ${formatTime(new Date(shift.endTime))}`}
                                        </span>
                                        <span
                                            style={{
                                                color: `${
                                                    staffList[shift.trainees[0]?.traineeId]?.match(
                                                        'Suspended',
                                                    ) ||
                                                    staffList[shift.trainees[0]?.traineeId]?.match(
                                                        'Archived',
                                                    )
                                                        ? Colors.RED3
                                                        : ''
                                                }`,
                                            }}
                                            className="weeks-table__col-content-subtitle"
                                        >
                                            {shift.trainingShift && (
                                                <span>
                                                    <Icon
                                                        icon="learning"
                                                        intent={
                                                            isSupervisorStaff ? 'none' : 'danger'
                                                        }
                                                    />
                                                    {!_.values(shift.trainees[0]).every(_.isEmpty)
                                                        ? staffList[shift.trainees[0]?.traineeId]
                                                        : 'No trainee set'}
                                                </span>
                                            )}
                                        </span>
                                        <span className="weeks-table__col-content-subtitle">
                                            {shift.conflicts && shift.conflicts.length > 0 && (
                                                <>
                                                    <Icon icon="warning-sign" intent="danger" />
                                                    Conflict
                                                </>
                                            )}
                                        </span>
                                        <div>
                                            {shift.comments?.length > 0 &&
                                                shift.comments.some((item) => item.isImportant) && (
                                                    <Icon
                                                        color={'#FF7F00'}
                                                        icon="envelope"
                                                        size={20}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                    {editOrCreateMode && (
                                        <div className="rota-shift__actions">
                                            {editOrCreateMode && (
                                                <TableRowActions
                                                    handleDeleteClick={this._handleDeleteClick}
                                                    handleDuplicateClick={
                                                        this._handleDuplicateClick
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Tooltip>
                        )}
                    </>
                );
            }
        },
    ),
);

export default ShiftPattern;
