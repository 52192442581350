import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';

import {
    CREATE_GROUP,
    DELETE_GROUP,
    UPDATE_GROUP,
    GET_GROUP,
    GET_GROUPS_LIST,
    BULK_DELETE_GROUP,
} from '../graphql/queries/groups';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';

class GroupsStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            selected: observable,
            groups: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridGroups: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getGroups: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    groups = [];
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeGroups = (group) => ({
        id: group.id,
        selected: this.selected.includes(group.id),
        name: group.name,
        createdAt: group.createdAt,
        updatedAt: group.updatedAt,
    });

    get gridGroups() {
        const groups = this.groups.map(this.normalizeGroups);
        if (this.sortFn) {
            groups.sort(this.sortFn);
        }
        return groups;
    }

    get allSelected() {
        return this.gridGroups.every((r) => this.selected.includes(r.id));
    }

    async createGroup(group) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_GROUP,
                variables: {
                    data: {
                        name: group.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Group created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating group record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteGroup(group) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_GROUP,
                variables: {
                    id: group.id,
                },
            });
            AppToaster.show({
                message: 'Group deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting group record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateGroup(group) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_GROUP,
                variables: {
                    id: group.id,
                    data: {
                        name: group.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Group updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating group record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (groupId) => {
        if (!this.selected.includes(groupId)) {
            this.selected.push(groupId);
        }
    };

    deselect = (groupId) => {
        const index = this.selected.indexOf(groupId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getGroups();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getGroups();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getGroups();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                groups: null,
                roles: null,
            });
        });
        await this.getGroups();
    };

    getGroups = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getGroups: data },
        } = await this.apolloClient.query({
            query: GET_GROUPS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            this.groups = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getGroupById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getGroupById: data },
        } = await this.apolloClient.query({
            query: GET_GROUP,
            variables: {
                id,
            },
        });

        return this.normalizeGroups(data);
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getGroups();
            this.fetchInterval = setInterval(() => this.getGroups(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridGroups.forEach((group) => {
                this.select(group.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async bulkDeleteGroup(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_GROUP,
                variables: {
                    id: ids,
                },
            });
            AppToaster.show({
                message: 'All selected groups deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected groups. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default GroupsStore;
