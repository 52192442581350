/* eslint-disable prettier/prettier */
import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';

import {
    CREATE_LOCATION,
    GET_LOCATIONS,
    GET_LOCATION,
    UPDATE_LOCATION,
    DELETE_LOCATION,
    ARCHIVE_LOCATION,
    BULK_ARCHIVE_LOCATIONS,
    IS_LOCATION_ARCHIVABLE,
} from '../graphql/queries/locations';

import { PAGE_SIZE } from '../constants/hrConst';
import AppToaster from '../components/modules/helpers/Toaster';

class LocationsStore {
    constructor(rootStore) {
        makeObservable(this, {
            selected: observable,
            locations: observable,
            allLocations: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridLocations: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getLocations: action,
            getAllLocations: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    locations = [];
    allLocations = [];
    pageInfo = {
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        currentStartCursor: null,
        currentEndCursor: null,
        startCursor: null,
        endCursor: null,
        page: 0,
        sortColumn: null,
        sortBy: null,
        filter: {},
        searchText: null,
    };
    loaded = false;
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeItem = (item) => ({
        id: item.id,
        selected: this.selected.includes(item.id),
        name: item.name,
        address1: item.address1,
        address2: item.address2,
        address3: item.address3,
        townCity: item.townCity,
        postcode: item.postcode,
        isArchived: item.isArchived,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        createdBy: item.createdBy,
        updatedBy: item.updatedBy,
    });

    get gridLocations() {
        const result = this.locations.map(this.normalizeItem);
        if (this.sortFn) {
            result.sort(this.sortFn);
        }
        return result;
    }

    get allSelected() {
        return this.gridLocations.every((r) => this.selected.includes(r.id));
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (id) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getLocations();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getLocations();
    };

    onSort = (sortInfo) => {
        this.pageInfo = {
            totalCount: 0,
            hasNextPage: false,
            hasPreviousPage: false,
            currentStartCursor: null,
            currentEndCursor: null,
            startCursor: null,
            endCursor: null,
            page: 0,
            sortColumn: sortInfo.column,
            sortBy: sortInfo.orderBy,
            filter: {},
            searchText: null,
        };
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (filter) => {
        this.pageInfo.filter = {
            isArchived: filter.isArchived,
            townCity: filter.townCity,
        };
        this.refresh();
    };

    refresh = async () => {
        runInAction(() => {
            this.loaded = false;
            this.pageInfo.page = 0;
            this.pageInfo.currentStartCursor = null;
            this.pageInfo.currentEndCursor = null;
            this.pageInfo.hasPreviousPage = false;
            this.pageInfo.hasNextPage = false;
        });
        await this.getLocations();
    };

    getLocations = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }

        const {
            data: { getLocations: data },
        } = await this.apolloClient.query({
            query: GET_LOCATIONS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.locations = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllLocations = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }

        const {
            data: { getLocations: data },
        } = await this.apolloClient.query({
            query: GET_LOCATIONS,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? '',
            },
        });

        runInAction(() => {
            this.allLocations = data.edges.map((x) => x.node);
        });
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getLocations();
            this.fetchInterval = setInterval(() => this.getLocations(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridLocations.forEach((item) => {
                // remove items that were archived previously from the list
                if (!item.isArchived) {
                    this.select(item.id);
                }
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    getItemById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getLocation: data },
        } = await this.apolloClient.query({
            query: GET_LOCATION,
            variables: {
                id,
            },
        });

        return this.normalizeItem(data);
    };

    async createItem(item) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_LOCATION,
                variables: {
                    data: {
                        name: _.isEmpty(item.name) ? null : item.name,
                        address1: _.isEmpty(item.address1) ? null : item.address1,
                        address2: _.isEmpty(item.address2) ? null : item.address2,
                        address3: _.isEmpty(item.address3) ? null : item.address3,
                        townCity: _.isEmpty(item.townCity) ? null : item.townCity,
                        postcode: _.isEmpty(item.postcode) ? null : item.postcode,
                    },
                },
            });
            AppToaster.show({
                message: 'Location created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating location record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateItem(item) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_LOCATION,
                variables: {
                    id: item.id,
                    data: {
                        name: _.isEmpty(item.name) ? null : item.name,
                        address1: _.isEmpty(item.address1) ? null : item.address1,
                        address2: _.isEmpty(item.address2) ? null : item.address2,
                        address3: _.isEmpty(item.address3) ? null : item.address3,
                        townCity: _.isEmpty(item.townCity) ? null : item.townCity,
                        postcode: _.isEmpty(item.postcode) ? null : item.postcode,
                    },
                },
            });
            AppToaster.show({
                message: 'Location updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating location record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.getLocations();
    }

    async deleteLocation(id) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_LOCATION,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Location deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting Location record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async archiveLocation(id) {
        try {
            const {
                data: {
                    archiveLocation: { count },
                },
            } = await this.apolloClient.mutate({
                mutation: ARCHIVE_LOCATION,
                variables: {
                    id,
                },
            });
            if (count > 0) {
                AppToaster.show({
                    message: 'Location archived successfully!',
                    intent: 'success',
                });
            } else {
                AppToaster.show({
                    message:
                        'Sorry, there was a problem archiving Location record. Please try again.',
                    intent: 'danger',
                });
            }
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem archiving Location record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    bulkArchiveLocations = async (ids) => {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_LOCATIONS,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'Locations archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem archiving Locations record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    };

    async nameIsUnique(name) {
        const {
            data: { getLocations: data },
        } = await this.apolloClient.query({
            query: GET_LOCATIONS,
            variables: {
                first: 1,
                field: 'updatedAt',
                orderBy: 'desc',
                filter: { name, isArchived: false },
            },
        });
        const result = data.edges.map((x) => x.node);

        return result;
    }

    isLocationArchivable = async (ids) => {
        const {
            data: { isLocationArchivable: data },
        } = await this.apolloClient.query({
            query: IS_LOCATION_ARCHIVABLE,
            variables: {
                ids,
            },
        });

        const result = data.map((x) => ({
            id: x.id,
            name: x.name,
            isArchivable: x.isArchivable,
        }));

        return result;
    };

    getLocationsByDepartment = async (departmentId) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }

        const {
            data: { getLocations: data },
        } = await this.apolloClient.query({
            query: GET_LOCATIONS,
            variables: {
                field: 'name',
                orderBy: 'asc',
                filter: { isArchived: false, departmentId },
            },
        });

        return data.edges.map((x) => ({
            label: x.node.name,
            value: x.node.id,
        }));
    };
}

export default LocationsStore;
