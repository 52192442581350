import { FC, ReactNode } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { Radio, RadioProps, ControlProps } from '@blueprintjs/core';

import { FormGroup } from '../FormGroup';

interface IProps extends RadioProps {
    name: string;
    className?: string;
    label?: string;
    title?: string;
    required?: boolean;
    children?: ReactNode;
}

const RadioComponent: FC<IProps> = ({ className, label, title, required, children, ...props }) => {
    const [field, meta] = useField<IProps>({ ...props, type: 'radio' } as FieldHookConfig<IProps>);

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
        >
            {children ? (
                <Radio
                    /* TODO better typing*/
                    {...((field as unknown) as ControlProps)}
                    {...(props as RadioProps)}
                    label={title}
                    className="v2__form-radio-input"
                >
                    {children}
                </Radio>
            ) : (
                <Radio /* TODO better typing*/
                    {...((field as unknown) as ControlProps)}
                    {...(props as RadioProps)}
                    label={title}
                    className="v2__form-radio-input"
                />
            )}
        </FormGroup>
    );
};

export default RadioComponent;
