import { IHcp } from '../../interfaces/ucr';

/**
 * A simple helper to find the name of a HCP staff memeber based on the ID
 *
 * @param users Array of users
 * @param hcpId ID of user to find
 * @returns Full name of staff member, or their ID if nothing is found
 */
const getHcpName = (users: IHcp[], hcpId?: string | null): string => {
    if (!hcpId) {
        return 'Unrecognised HCP ID';
    }

    if (users.length === 0) {
        return hcpId;
    }

    const hcp = users.find((user: any) => user.userId === hcpId);

    if (!hcp) {
        return hcpId;
    }

    return hcp.userName || hcpId;
};

export default getHcpName;
