import React, { Component } from 'react';
import { Tag } from '@blueprintjs/core';
import { getTimeRemaining } from '../helpers/formatData';
class TimeRemaining extends Component {
    render() {
        let unit = 'mins';
        let timeRemaining = getTimeRemaining(this.props.date);

        if (timeRemaining >= 180) {
            unit = 'hours';
            timeRemaining = Math.floor(timeRemaining / 60);
        }

        if (timeRemaining <= -180) {
            unit = 'hours';
            timeRemaining = Math.ceil(timeRemaining / 60);
        }

        return (
            <div className="time-temaining">
                <Tag multiline intent={timeRemaining < 0 ? 'danger' : 'none'}>
                    {timeRemaining} {unit}
                </Tag>
            </div>
        );
    }
}

export default TimeRemaining;
