import { observer } from 'mobx-react';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import useStores from '../../../../../hook/useStores';
import { LIST_STATE, SORT_COLUMN } from '../../../../../stores/PatientListStore';
import { PatientListHeader } from './PatientListHeader';
import { PatientListRows } from './PatientListRows';
import {
    IPatientsListQueryResponse,
    IPatientsListQueryVariables,
    PatientListPatient,
    QUERY_PATIENTS,
} from '../../../../../graphql/queries/patients';
import { useLazyQuery } from '@apollo/client';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Button } from '../../../../v2/components';
import { useComponentDidMount } from '../../../../../hook/useComponentDidMount';
import { useComponentDidUmount } from '../../../../../hook/useComponentDidUnmount';
import { JobsContext } from '../../../../../providers';

const PatientList: FC = observer(() => {
    const {
        RootStore: {
            patientListStore: { setPatients, reset, sorts, filters, setState },
        },
    } = useStores() as { RootStore: any };

    const jobsContext = useContext(JobsContext);

    const PATIENT_LIST_PAGE_SIZE = 50;

    const [_loadPatients, { data, loading }] = useLazyQuery<
        IPatientsListQueryResponse,
        IPatientsListQueryVariables
    >(QUERY_PATIENTS, {
        variables: {
            sorts,
            filters,
        },
        fetchPolicy: 'no-cache',
        pollInterval: 60000,
        onError: ({ graphQLErrors }) => {
            let message = 'Please check your network connection!';
            if (graphQLErrors && graphQLErrors.length > 0) {
                message = graphQLErrors[0].message;
                console.log('error:', message);
            }
            AppToaster.show({
                message,
                intent: 'danger',
            });
        },
    });

    const [rawPatients, setRawPatients] = useState<PatientListPatient[]>();
    const [showLoadMore, setShowLoadMore] = useState(true);

    const { getPatients } = data || {};
    const { nextPageNumber = 1 } = getPatients || {};

    const loadPatients = useCallback(
        (pageNumber: number) => {
            _loadPatients({
                variables: {
                    sorts,
                    filters,
                    pagination: { pageSize: PATIENT_LIST_PAGE_SIZE, pageNumber },
                },
            });
        },
        [_loadPatients, filters, sorts],
    );

    useComponentDidMount(() => {
        loadPatients(1);
    });

    useComponentDidUmount(() => {
        reset();
    });

    useEffect(() => {
        if (jobsContext.refreshPatients) {
            setState(LIST_STATE.LOADING);
            loadPatients(1);
        }
    }, [jobsContext.refreshPatients, loadPatients, reset, setState]);

    useEffect(
        () => {
            setState(LIST_STATE.LOADING);
            // Debounce fetching
            const timer = setTimeout(() => loadPatients(1), 1000);
            return () => clearTimeout(timer);
        },
        // Disable reloading when callback updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sorts, filters?.nameOrNhsNumber, filters?.showDischargedPatients],
    );

    useEffect(
        () => {
            if (!getPatients) {
                return;
            }

            if (getPatients.nextPageNumber === 2) {
                setRawPatients(getPatients.patients);
            } else {
                setRawPatients([...(rawPatients || []), ...getPatients.patients]);
            }

            setShowLoadMore(getPatients.patients.length === PATIENT_LIST_PAGE_SIZE);
            jobsContext.setRefreshPatients(false);
        },
        // Disable to prevent infinte loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getPatients],
    );

    useEffect(() => {
        if (rawPatients) {
            setPatients(rawPatients);
        }
    }, [setPatients, rawPatients]);

    const loadMore = useCallback(() => {
        if (loading) {
            return;
        }

        loadPatients(nextPageNumber);
    }, [loading, loadPatients, nextPageNumber]);

    return (
        <div className="patient-list">
            <table className="patient-list__table">
                <thead>
                    <tr>
                        <PatientListHeader column={SORT_COLUMN.NHS_NUMBER}>
                            NHS number
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.NAME}>
                            Patient name
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.DOB}>
                            Date of birth (Age)
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.GENDER}>Gender</PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.POSTCODE}>
                            Postcode
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.ADMISSIONS}>
                            Status
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.LAST_COMPLETED_VISIT}>
                            Last completed visit
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.UPCOMING_VISIT}>
                            Upcoming visit
                        </PatientListHeader>
                        {/* Intentionally blank header for quick actions */}
                        <PatientListHeader />
                    </tr>
                </thead>

                <tbody>
                    <PatientListRows />
                    {showLoadMore && (
                        <tr className="patient-list__no-patients-row">
                            <td
                                // @ts-ignore Don't know why; colspan supports percentages
                                colSpan="100%"
                            >
                                <Button name="Load More" clickEvent={loadMore} disabled={loading} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
});

export default PatientList;
