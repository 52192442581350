import React from 'react';
import useHeaderSorting from '../../../../hook/useHeaderSorting';
import SortedHeader from '../../../common/sorting/SortedHeader';
import { Checkbox } from '@blueprintjs/core';

const PeopleHeader = ({ onSort, isFrontendSort = true, onToggleSelectAll, checked, showName }) => {
    const { sort, sortData } = useHeaderSorting((s) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr>
                <th>
                    <Checkbox onChange={onToggleSelectAll} checked={checked} />
                </th>
                {showName && (
                    <>
                        <SortedHeader
                            sort={sort}
                            sortBy={'person.firstName'}
                            text="First name"
                            sortData={sortData}
                        />
                        <SortedHeader
                            sort={sort}
                            sortBy={'person.middleName'}
                            text="Middle name"
                            sortData={sortData}
                        />
                        <SortedHeader
                            sort={sort}
                            sortBy={'person.lastName'}
                            text="Last name"
                            sortData={sortData}
                        />
                    </>
                )}
                <th>Departments</th>
                <th>Job title</th>
                <th>Role</th>
                <SortedHeader
                    sort={sort}
                    sortBy={'employeeStatus.name'}
                    text="Record status"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'isCompliant'}
                    text="Compliance status"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'startedWorkOn'}
                    text="Start date"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'endedWorkOn'}
                    text="End date"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdAt'}
                    text="Created on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdBy'}
                    text="Created by"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedAt'}
                    text="Last updated on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedBy'}
                    text="Last updated by"
                    sortData={sortData}
                />
            </tr>
        </thead>
    );
};

export default PeopleHeader;
