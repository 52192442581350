import { FormEventHandler } from 'react';
import { Checkbox } from '@blueprintjs/core';
import { HeaderColumnType } from './TableHeader';

interface Props {
    columns: HeaderColumnType[];
    item: any;
    onClick: Function;
    onToggle?: FormEventHandler<HTMLInputElement>;
    selectable?: boolean;
    selected?: boolean;
    selectionDisabled?: boolean;
}

const TableRow = ({
    columns,
    onClick,
    item,
    onToggle = () => {},
    selected = false,
    selectable = false,
    selectionDisabled = false,
}: Props) => {
    if (!item) return null;

    return (
        <tr
            key={item.id}
            onClick={(event) => onClick(item['id'], event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            {selectable && (
                <td>
                    <Checkbox
                        id={item['id']}
                        checked={selected}
                        onChange={(evt: any) => {
                            onToggle(evt.target.id);
                        }}
                        disabled={selectionDisabled || item.isArchived}
                        onClick={(event) => event.stopPropagation()}
                    />
                </td>
            )}
            {columns.map((col: HeaderColumnType) =>
                col.render ? (
                    <td key={`${item['id']}_${col.key}`}>{col.render(item)}</td>
                ) : (
                    <td key={`${item['id']}_${col.key}`}>{item[col.key]}</td>
                ),
            )}
        </tr>
    );
};

export default TableRow;
