import { useContext, useEffect, useState, FunctionComponent } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Formik, FormikValues } from 'formik';
import moment from 'moment';
import { cloneDeep, isNull, isUndefined, omitBy } from 'lodash';
import { JobStatus } from '@doc-abode/data-models';
import { IHcp } from '../../../../../interfaces/ucr';

import AdminTimeForm from './AdminTimeForm';
import { TAdminTimeData, TAPIAdminTimeData } from './AdminTimeTypes';
import ReviewForm from './ReviewForm';
import { getValidationSchema } from './validation';
import { CREATE_JOB, GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';

import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import AppToaster from '../../../../modules/helpers/Toaster';
import useStores from '../../../../../hook/useStores';
import { FormMode, FormSteps } from '../common';
import { getMidnightDayString } from '../../../../modules/helpers/formatData';
import { JobsContext } from '../../../../../providers';
import { useView } from '../../views/useView';
import RootStore from '../../../../../stores/RootStore';
import { ADMIN_TIME } from './AdminTimeConsts';

const formatTooApiDate = (
    values: TAdminTimeData,
    org: string,
    username: string,
    id?: string,
    staffMemberWasChanged = false,
    users?: IHcp[],
): TAPIAdminTimeData => {
    const visitDate = moment(values.visitDate).seconds(0);
    const startTime = moment(values.startTime).seconds(0);

    const data: TAPIAdminTimeData = {
        ...cloneDeep(values),
        disposition: ADMIN_TIME,
        startDateTime: visitDate
            .clone()
            .hour(startTime.hour())
            .minute(startTime.minute())
            .seconds(0)
            .toISOString(),
        jobStatus: staffMemberWasChanged ? JobStatus.ACCEPTED : (values.jobStatus as string),
        jobType: 'ucr',
        lastUpdatedBy: username,
        languagesSpoken: values.languagesSpoken,
        organisation: org,
        dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).toISOString() : '',
        dateOfVisit: getMidnightDayString(visitDate),
        version: 0,
        hcpName: users?.find((user: any) => values.hcpId === user.userId)?.userName,
        nhsNumber: values.nhsNumber || undefined,
        additionalContactNumbers:
            values.additionalContactNumbers?.filter((str: string) => str) || [],
    };

    if (values.pds) {
        data.pds = {
            versionId: values.pds.versionId,
        };
    }
    if (id) {
        data.id = id;
    }

    const filedData = omitBy(data, (v) => isUndefined(v) || isNull(v));

    // nullify empty strings instead of sending "";
    Object.keys(filedData).forEach((key) => {
        if (filedData[key] === '') {
            filedData[key] = null;
        }
    });

    delete filedData.visitDate;
    delete filedData.date;
    delete filedData.startTime;
    delete filedData.endTime;
    delete filedData.staff;
    delete filedData.hcpIdTemp;
    delete filedData.previouslyHcpId;
    delete filedData.previouslyBuddyId;
    delete filedData.latitude;
    delete filedData.longitude;
    delete filedData.fromReferral;
    delete filedData.madeCurrentDateTime;

    return filedData as TAPIAdminTimeData;
};

type AdminTimeType = {
    isEdit: boolean;
};

const AdminTime: FunctionComponent<AdminTimeType> = ({ isEdit = false }) => {
    const {
        RootStore: {
            configStore: { org },
            ucrStore: {
                setOpenedDialog,
                setOpenedDialogAlert,
                followUpAdminTimeData,
                setFollowUpAdminTimeData,
                focusedJobId,
                selectedDate,
            },
            userStore: {
                user: { username },
            },
            usersStore: { users },
        },
    } = useStores() as { RootStore: RootStore };

    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [createJob, { loading }] = useMutation(CREATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [updateJob, updateJobData] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                if (moment(formData.visitDate).isSame(moment(selectedDate), 'day')) {
                    jobsContext.setRefreshAssignedJobs(true);
                }
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });
    const [visitData, setVisitData] = useState<FormikValues | null>(null);
    const [params, setParams] = useState<{
        step: FormSteps;
        formMode: FormMode;
    }>({
        step: FormSteps.PATIENT,
        formMode: FormMode.DEFAULT,
    });

    const [formData, setFormData] = useState<FormikValues>({
        gender: '',
        hcpId: '',
        activityType: '',
        visitDate: new Date(),
        startTime: new Date(),
        duration: '01:00',
        createdBy: '',
        lastUpdatedBy: '',
        lastUpdatedDateTime: '',
        createDateTime: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        town: '',
        postCode: '',
        languagesSpoken: [],
        jobStatus: JobStatus.ACCEPTED,
    });

    const [initialised, setInitialised] = useState(false);

    const [patientFieldsRequired, setPatientFieldsRequired] = useState(false);
    const [validationSchema, setValidationSchema] = useState(getValidationSchema({}));

    const initialData = followUpAdminTimeData
        ? { ...formData, ...followUpAdminTimeData }
        : formData;

    const getVisit = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        pollInterval: 60000,
    });

    useEffect(() => {
        const data = getVisit.data?.getJob;
        if (!data || !isEdit) return;
        const adminTypeFields = [
            'nhsNumber',
            'firstName',
            'middleName',
            'lastName',
            'contactNumber',
            'additionalContactNumbers',
            'dateOfBirth',
            'gender',
            'addressLine1',
            'addressLine2',
            'addressLine3',
            'town',
            'postCode',
            'languagesSpoken',
            'staffPreferredGender',
            'activityType',
            'notes',
            'postVisitNotes',
            'id',
        ];

        let filledData = {};
        Object.keys(data).forEach((field: string) => {
            if (adminTypeFields.includes(field) && data[field] !== null) {
                filledData = { ...filledData, [field]: data[field] };
            }
        });

        const formattedVisitData = {
            ...filledData,
            hcpId: data.hcpId,
            visitDate: new Date(data.startDateTime),
            startTime: new Date(data.startDateTime),
            dateOfBirth: data.dateOfBirth ? moment(data.dateOfBirth).toDate() : undefined,
            duration: data.duration,
            jobStatus: data.jobStatus,
            ...(data.pds?.versionId && { pds: { versionId: data.pds.versionId } }),
        };

        setFormData(formattedVisitData as TAdminTimeData);
        setVisitData(formattedVisitData as TAdminTimeData);
        setInitialised(true);
        handleChangePatientRequired(!!(formattedVisitData as TAdminTimeData).nhsNumber);
    }, [getVisit, isEdit, username]);

    const onSubmit = async (values: FormikValues) => {
        const data = {
            ...cloneDeep(values),
            contactNumber: values.contactNumber ? values.contactNumber : undefined,
            additionalContactNumbers: values.additionalContactNumbers
                ? values.additionalContactNumbers.filter((str: string) => str)
                : undefined,
        };

        setVisitData(data as TAdminTimeData);
        setFormData(values as TAdminTimeData);
    };

    const successSaving = () => {
        AppToaster.show({
            message: `Visit ${isEdit ? 'updated' : 'saved'} successfully!`,
            intent: 'success',
        });

        setFollowUpAdminTimeData({});
        setOpenedDialog(Dialogs.NONE);
    };

    const saveVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;
        const formattedData = formatTooApiDate(
            visitData as TAdminTimeData,
            org,
            username,
            undefined,
            true,
            users,
        );
        formattedData.createDateTime = moment().toISOString();
        formattedData.createdBy = username;
        try {
            await createJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error creating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unabled to create the visit',
                intent: 'danger',
            });
            return;
        }
        successSaving();
    };

    const updateVisitData = async (visitData: FormikValues) => {
        if (!visitData) return;
        const hcpWasChanged = initialData.hcpId !== visitData.hcpId;
        const formattedData = formatTooApiDate(
            visitData as TAdminTimeData,
            org,
            username,
            getVisit.data?.getJob.id,
            hcpWasChanged,
        );
        formattedData.lastUpdatedBy = username;
        formattedData.lastUpdatedDateTime = moment().toISOString();

        try {
            await updateJob({ variables: { input: formattedData } });
        } catch (err) {
            console.error('Error updating visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to create the visit',
                intent: 'danger',
            });
            return;
        }

        successSaving();
    };

    const onEditReviewForm = (step: FormSteps) => {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
        setParams({
            step,
            formMode: isEdit ? FormMode.EDIT_VISIT : params.formMode,
        });
        setVisitData(null);
    };

    useEffect(() => {
        setOpenedDialogAlert(
            !visitData ? DialogAlerts.SAVE_ADMINISTRATIVE_TIME : DialogAlerts.NONE,
        );
    }, [visitData, setOpenedDialogAlert]);

    useEffect(() => {
        setValidationSchema(getValidationSchema({ patientFieldsRequired }));
    }, [patientFieldsRequired]);

    const handleChangePatientRequired = (isRequired: boolean) =>
        setPatientFieldsRequired(isRequired);

    return (
        <Formik
            initialValues={initialData as TAdminTimeData}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {visitData ? (
                <ReviewForm
                    values={visitData}
                    onSave={isEdit ? updateVisitData : saveVisitData}
                    onEdit={onEditReviewForm}
                    formMode={isEdit ? FormMode.EDIT_VISIT : params.formMode}
                    setVisitData={setVisitData}
                />
            ) : (
                (initialised || !isEdit) && (
                    <AdminTimeForm
                        handleSubmit={isEdit ? updateVisitData : onSubmit}
                        step={params.step}
                        loading={isEdit ? updateJobData.loading : loading}
                        formMode={params.formMode}
                        patientFieldsRequired={patientFieldsRequired}
                        handleChangePatientRequired={handleChangePatientRequired}
                    />
                )
            )}
        </Formik>
    );
};

export default AdminTime;
