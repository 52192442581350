import { Button, FormGroup, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import React from 'react';
import CommonPagination from '../CommonHRRotaPagination';

const PayRatesTableNav = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onSearch,
    addNew,
    deleteItem,
    editItem,
    archiveItem,
    selected,
}) => {
    const onSearchChange = async (inputValue) => {
        if (inputValue && inputValue.length >= 3) {
            return onSearch(inputValue);
        } else if (inputValue?.length === 0) {
            return onSearch('');
        } else {
            return () => {};
        }
    };

    const Content = () => (
        <Menu>
            <MenuItem
                disabled={!selected.length || selected.length > 1}
                onClick={editItem}
                text="Edit"
            />
            <MenuItem disabled={!selected.length} onClick={archiveItem} text="Archive" />
            <MenuItem
                className="bp5-intent-danger"
                disabled={!selected.length}
                onClick={deleteItem}
                text="Delete"
            />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup
                                label="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Search..."
                                    large
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="pay rates"
                        />
                    </div>
                    <div className="common-header__buttons">
                        <Button
                            text="New"
                            intent="success"
                            icon="add"
                            onClick={addNew}
                            className="common-header__button common-header__button--add bp5-outlined"
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content shouldDismissPopover={false} />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    className="bp5-outlined"
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default PayRatesTableNav;
