import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

interface optionValues {
    value: string;
    label: string;
}

interface selectProps {
    options: optionValues[] | [];
    name: string;
    onChange: (val: any) => void;
    defaultValue: number;
    placeholder: string;
    isDisabled?: boolean;
}

const CreatableSelectAdvanced = (props: selectProps) => {
    const { options, name, onChange, defaultValue, placeholder, isDisabled = false } = props;
    const [value, setValue] = useState() as any;
    const [selectOptions, setSelectOptions] = useState(options);
    const [defaultValueIndex, setDefaultValueIndex] = useState(0);
    let select: any;

    useEffect(() => {
        setSelectOptions(options);
    }, [options]);

    useEffect(() => {
        const index = defaultValue
            ? options.findIndex((item: any) => item.value === defaultValue)
            : -1;
        setDefaultValueIndex(index);
        setValue(() => (options[index] ? options[index] : ''));
    }, [options, defaultValue, defaultValueIndex]);

    const handleChange = (newValue: any) => {
        setValue(newValue);
    };

    const handleFocus = () => {
        if (value) {
            select.state.inputValue = value.label;
        }
    };
    const handleMenuClose = () => {
        select.blur();
    };

    return (
        <CreatableSelect
            ref={(ref) => {
                select = ref;
            }}
            blurInputOnSelect={true}
            id={name}
            name={name}
            onChange={(e) => {
                handleChange(e);
                onChange(e);
            }}
            options={selectOptions}
            defaultValue={selectOptions[defaultValueIndex]}
            placeholder={placeholder}
            onMenuClose={handleMenuClose}
            onFocus={handleFocus}
            value={value}
            isDisabled={isDisabled}
        />
    );
};

export default CreatableSelectAdvanced;
