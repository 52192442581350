import { FC, useEffect } from 'react';
import { Form, FormikValues, useFormikContext, FormikContextType } from 'formik';
import { JobStatus, Patient } from '@doc-abode/data-models';

import { Select, TextArea } from '../../../../v2/form';
import { Button, ButtonColors, ButtonElems, ButtonSizes, Callout } from '../../../../v2/components';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';
import useStores from '../../../../../hook/useStores';
import { IHcp } from '../../../../../interfaces/ucr';

const defaultOption = {
    label: 'Select option',
    value: '',
    disabled: true,
};

interface IProps {
    visit: Patient;
    loading: boolean;
    onClose: () => void;
}

const ReverseDoubleVisitForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        RootStore: {
            lovsStore: { controllerReverseReason },
            ucrStore: { hcps, focusedUser },
            usersStore: { users },
        },
    } = useStores() as { RootStore: any };

    const { values, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();

    const user1 = hcps.find((hcp: IHcp) => hcp.userId === visit.hcpId);
    const user2 = hcps.find((hcp: IHcp) => hcp.userId === visit.buddyId);
    const hcp = hcps.find((hcp: IHcp) => hcp.userId === visit.lastUpdatedBy);
    const controller = users.find((user: any) => user.userId === visit.lastUpdatedBy);
    const reasonsForReversing = [defaultOption, ...controllerReverseReason];

    useEffect(() => {
        setFieldValue('controllerReverseReason', '');
    }, [values.reverseForOption, setFieldValue]);

    const isControllerReverseReasonDisabled = values.reverseForOption === '';
    const isConfirmReverseVisitDisabled =
        loading || values.reverseForOption === '' || values.controllerReverseReason === '';

    const labelUser1 = `#1 ${user1 && `- ${user1.firstName} ${user1.lastName}`} - ${
        visit.friendlyVisitStatus
    }`;
    const labelUser2 = `#2 ${
        user2 && `- ${user2.firstName} ${user2.lastName}`
    } - ${Patient.getFriendlyVisitStatus(visit.buddyJobStatus as JobStatus)}`;

    const firstVisit = {
        label: labelUser1,
        value: 'user1',
        disabled:
            visit.jobStatus !== JobStatus.CONTROLLER_ABORTED &&
            visit.jobStatus !== JobStatus.HCP_ABORTED,
    };
    const seconsVisit = {
        label: labelUser2,
        value: 'user2',
        disabled:
            visit.jobStatus !== JobStatus.CONTROLLER_ABORTED &&
            visit.jobStatus !== JobStatus.HCP_ABORTED,
    };
    const bothVisits = {
        label: 'All visits',
        value: 'all',
        disabled:
            visit.jobStatus !== JobStatus.CONTROLLER_ABORTED &&
            visit.jobStatus !== JobStatus.HCP_ABORTED &&
            visit.buddyJobStatus !== JobStatus.CONTROLLER_ABORTED &&
            visit.buddyJobStatus !== JobStatus.HCP_ABORTED,
    };
    const reverseForOption = [firstVisit, seconsVisit, bothVisits];
    let choice = focusedUser === 'user1' ? firstVisit : seconsVisit;

    reverseForOption.sort(function (x, y) {
        return x === choice ? -1 : y === choice ? 1 : 0;
    });
    return (
        <Form>
            <div className="v2__dialog-block">
                <p>This visit has been aborted with the following details</p>
                {visit.controllerAbortedReason ? (
                    <>
                        <p>{labelUser1}</p>
                        <ul className="v2__dialog-block-list">
                            <li className="v2__dialog-block-list-item">
                                <span>Aborted on</span>
                                <span>{formatDisplayDateTime(visit.finishedDateTime)}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Aborted by</span>
                                <span>{hcp?.userName || controller?.userName}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Reason</span>
                                <span>{visit.controllerAbortedReason || 'Not set'}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Notes</span>
                                <span>{visit.controllerAbortedNotes || 'Not set'}</span>
                            </li>
                        </ul>
                    </>
                ) : undefined}
                {visit.buddyControllerAbortedReason ? (
                    <>
                        <p>{labelUser2}</p>
                        <ul className="v2__dialog-block-list">
                            <li className="v2__dialog-block-list-item">
                                <span>Aborted on</span>
                                <span>{formatDisplayDateTime(visit.buddyFinishedDateTime)}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Aborted by</span>
                                <span>{hcp?.userName || controller?.userName}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Reason</span>
                                <span>{visit.buddyControllerAbortedReason || 'Not set'}</span>
                            </li>
                            <li className="v2__dialog-block-list-item">
                                <span>Notes</span>
                                <span>{visit.buddyControllerAbortedNotes || 'Not set'}</span>
                            </li>
                        </ul>
                    </>
                ) : undefined}

                <Callout intent="warning">
                    The visit is part of a double-up which has been cancelled for all staff members.
                    Please indicate which visits should have their cancellation reverted.
                </Callout>
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Reverse cancellation for"
                    name="reverseForOption"
                    defaultChecked={true}
                    options={reverseForOption}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for reverting the cancellation"
                    name="controllerReverseReason"
                    options={reasonsForReversing}
                    disabled={isControllerReverseReasonDisabled}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea label="Notes on reversal" name="controllerReverseNotes" />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--right">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm reversal"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={isConfirmReverseVisitDisabled}
                />
            </div>
        </Form>
    );
};

export default ReverseDoubleVisitForm;
