import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import PeopleItem from './PeopleItem';
import PeopleHeader from './PeopleHeader';
import NavigationStaff from './NavigationStaff';
import EmployeeDetails from '../../../modules/staffs/detail/EmployeeDetails';
import Loader from '../../../modules/helpers/Loader';
import { CONTRACTOR_IDS, EMPLOYED_IDS } from '../../../../constants/hrConst';
import ArchivePersonStaffConfirmationPopUp from '../../../common/ArchivePersonStaffConfirmationPopUp';
import ArchiveStaffConfirmationPopUpText from './ArchiveStaffConfirmationPopUpText';

const AdminManageStaff = inject('RootStore')(
    observer(
        class AdminManageStaff extends Component {
            state = {
                typeOfStaffRecord: 'employed',
                showAlert: false,
                listOfNotValidStaffForArchive: [],
                selectedRecords: [],
            };

            _getStaffRecords = () => {
                if (this.props.history.location.pathname.includes('employed-records')) {
                    this.props.RootStore.staffStore.onFilter('contractTypeId', EMPLOYED_IDS);
                } else if (this.props.history.location.pathname.includes('sessional-records')) {
                    this.props.RootStore.staffStore.onFilter('contractTypeId', CONTRACTOR_IDS);
                }
                this.props.RootStore.staffStore.getStaffs();
            };

            componentDidMount() {
                this._getStaffRecords();
            }

            componentDidUpdate(prevProps) {
                if (this.props.location.pathname !== prevProps.location.pathname) {
                    this._getStaffRecords();
                }
            }

            componentWillUnmount() {
                this.props.RootStore.staffStore.clear();
            }

            _handleEmployeeClick = ({ employeeId, contractTypeId, event }) => {
                if (event.target.tagName === 'TD' && this.props.person?.id) {
                    const url = EMPLOYED_IDS.includes(contractTypeId)
                        ? '/hr/employed-records'
                        : '/hr/sessional-records';
                    return this.props.history.push(`${url}/${employeeId}`);
                } else if (event.target.tagName === 'TD') {
                    this.props.history.push(`${this.props.location.pathname}/${employeeId}`);
                }
            };

            _handleAddNew = () => {
                if (this.props.person?.id) {
                    const { personalEmail, firstName, lastName } = this.props.person;
                    const label = `${firstName} ${lastName} ${personalEmail}`;

                    const url =
                        this.state.typeOfStaffRecord === 'sessional'
                            ? '/hr/sessional-records/new'
                            : '/hr/employed-records/new';

                    this.props.history.push(url, {
                        personId: this.props.person.id,
                        label: label,
                    });
                } else {
                    this.props.history.replace(`${this.props.location.pathname}/new`);
                }
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.staffStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.staffStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };
            _handleChangeTypeOfStaffRecord = (e) => {
                this.setState({ typeOfStaffRecord: e.target.value });
            };

            _handleTabChange = (tabId) => {
                const { employeeId } = this.props.match?.params ?? {};
                const type = this.props.history.location.pathname.includes('sessional-records')
                    ? '/hr/sessional-records'
                    : '/hr/employed-records';
                this.props.history?.push(`${type}/${employeeId}/${tabId}`);
            };

            _validateStaffBeforeArchiving = async (ids) => {
                const { staffStore } = this.props.RootStore;
                const validationData = await staffStore.validateEmployeeRecordsArchivation(ids);
                if (!validationData.length) {
                    return this.setState({
                        listOfNotValidStaffForArchive: [],
                        numberOfSelectedItems: ids.length,
                        showAlert: true,
                    });
                }
                // Create a list of items that didn't pass validation
                let listOfNotValidStaff = [];
                validationData.forEach((item) => {
                    const staffIsSessional = CONTRACTOR_IDS.includes(item.contractTypeId);
                    listOfNotValidStaff.push({
                        id: item.id,
                        staffType: staffIsSessional ? 'sessional-records' : 'employed-records',
                        role: item.roles.length ? item.roles[0]?.employeeRole?.name : '-',
                    });
                });

                this.setState({
                    listOfNotValidStaffForArchive: listOfNotValidStaff,
                    selectedRecords: ids,
                    showAlert: true,
                });
            };

            _archiveStuff = async () => {
                const { staffStore } = this.props.RootStore;
                await staffStore.archiveEmployeeRecords(this.state.selectedRecords);
                staffStore.deselectAll();
                this.setState({ showAlert: false });
                this._getStaffRecords();
            };

            _closePopUp = () => {
                this.setState({ showAlert: false });
            };

            render() {
                const {
                    RootStore: {
                        staffStore: {
                            gridStaffs,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            onSearch,
                            loaded,
                            selected,
                        },
                        dictionaryStore,
                        departmentsStore,
                        configStore: { isFeatureEnabled },
                    },
                    embedded,
                    person,
                } = this.props;
                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { showAlert, listOfNotValidStaffForArchive, selectedRecords } = this.state;

                const { employeeId, tab } = this.props.match?.params ?? {};

                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                if (gridStaffs.length === 0 && employeeId) {
                    return null;
                }
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className={!embedded ? 'common-layout__main' : ''}>
                            <NavigationStaff
                                onFilter={onFilter}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                dictionaryStore={dictionaryStore}
                                departmentsStore={departmentsStore}
                                addNewEmployee={this._handleAddNew}
                                showFilters={!embedded}
                                personId={this.props.person?.id}
                                refresh={this.props.RootStore.staffStore.refreshPage}
                                typeOfStaffRecord={this.state.typeOfStaffRecord}
                                handleChangeTypeOfStaffRecord={this._handleChangeTypeOfStaffRecord}
                                addDisabled={
                                    !!person &&
                                    person.statusId !== 'd89d6d81-1ca1-4038-b4f1-7794ef3cd4a9'
                                }
                                selected={selected}
                                validateStaffBeforeArchiving={this._validateStaffBeforeArchiving}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <PeopleHeader
                                    data={gridStaffs}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                    showName={!embedded}
                                />
                                <tbody>
                                    {gridStaffs.map((employee) => (
                                        <PeopleItem
                                            {...employee}
                                            onClick={this._handleEmployeeClick}
                                            key={employee.id}
                                            onToggle={this._handleToggleSelected}
                                            dictionaryStore={dictionaryStore}
                                            showName={!embedded}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(employeeId || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <EmployeeDetails
                                    {...this.props}
                                    actionType={actionType}
                                    personId={this.props.person?.id}
                                    handleTabChange={this._handleTabChange}
                                    tab={tab}
                                />
                            </div>
                        )}
                        <ArchivePersonStaffConfirmationPopUp
                            isOpen={showAlert}
                            titleText={'Confirm archiving!'}
                            bodyText={ArchiveStaffConfirmationPopUpText(
                                listOfNotValidStaffForArchive,
                                selectedRecords.length,
                            )}
                            onSubmit={this._archiveStuff}
                            onCancel={this._closePopUp}
                        />
                    </>
                );
            }
        },
    ),
);

export default AdminManageStaff;
