import { gql } from '@apollo/client';

export const GET_HOLIDAYS = gql`
    query getHoliday($filter: HolidayFilter!) {
        getHoliday(filter: $filter) {
            edges {
                node {
                    id
                    name
                    date
                }
            }
        }
    }
`;
