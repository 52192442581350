import React from 'react';
import { observer, inject } from 'mobx-react';

import AbsenceHeader from './AbsenceHeader';
import Table from './Table';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import ApproveConfirmationAlert from './ApproveConfirmationAlert';
import RejectConfirmationAlert from './RejectConfirmationAlert';

const Absences = (props: any) => {
    const {
        RootStore: {
            absencesStore: {
                getItems,
                gridItems,
                onSort,
                pageInfo,
                toggleSelect,
                toggleSelectAll,
                selected,
                allSelected,
                absenceTypes,
                approvalStatuses,
                getFilterOptions,
                getPersonRecords,
                nextPage,
                onFilter,
                previousPage,
                recordStatuses,
                selectedApproved,
                selectedPending,
                selectedRejected,
            },
        },
        history,
        person,
        handleAddNewAbsenceFromPerson,
        handleOpenAbsenceRecordFromPerson,
    } = props;

    const [showAlert, setShowAlert] = React.useState(false);
    const [showApproveAlert, setShowApproveAlert] = React.useState(false);
    const [showRejectAlert, setShowRejectAlert] = React.useState(false);

    React.useEffect(() => {
        if (person) {
            onFilter({
                personId: person.id,
            });
        } else {
            getItems();
        }
    }, [getItems, onFilter, person]);

    const handleArchivation = () => {
        setShowAlert(true);
    };

    const archiveAbsenceRecords = async () => {
        const selectedRecords = [...selected];
        const { absencesStore } = props.RootStore;
        closeAlert();
        await absencesStore.bulkArchiveAbsence(selectedRecords);
        absencesStore.deselectAll();
    };

    const approveAbsenceRecords = async () => {
        const pIds = selectedPending.map((r: any) => r.id);
        const rIds = selectedRejected.map((r: any) => r.id);
        const { absencesStore } = props.RootStore;
        await absencesStore.bulkChangeAbsenceStatus(
            [...pIds, ...rIds],
            'a4c22059-52d2-4b23-8240-a97aeb896a59',
        );
        absencesStore.deselectAll();
        closeAlert();
    };

    const rejectAbsenceRecords = async () => {
        const aIds = selectedApproved.map((r: any) => r.id);
        const rIds = selectedRejected.map((r: any) => r.id);
        const { absencesStore } = props.RootStore;
        await absencesStore.bulkChangeAbsenceStatus(
            [...aIds, ...rIds],
            '6e4f0c21-81fd-427f-a2fc-6afbc716bc50',
        );
        absencesStore.deselectAll();
        closeAlert();
    };

    const closeAlert = () => {
        setShowAlert(false);
        setShowApproveAlert(false);
        setShowRejectAlert(false);
    };

    return (
        <main className="common-layout__main absences">
            <AbsenceHeader
                pageInfo={pageInfo}
                absenceTypes={absenceTypes}
                approveItems={() => setShowApproveAlert(true)}
                rejectItems={() => setShowRejectAlert(true)}
                approvalStatuses={approvalStatuses}
                getFilterOptions={getFilterOptions}
                getPersonRecords={getPersonRecords}
                nextPage={nextPage}
                onFilter={onFilter}
                previousPage={previousPage}
                recordStatuses={recordStatuses}
                selected={selected}
                archiveItems={handleArchivation}
                history={history}
                personId={person ? person.id : null}
                disableRejection={
                    selected.length === 0 || selected.length === selectedRejected.length
                }
                disableApproval={
                    selected.length === 0 || selected.length === selectedApproved.length
                }
                handleAddNewAbsenceFromPerson={handleAddNewAbsenceFromPerson}
                handleOpenAbsenceRecordFromPerson={handleOpenAbsenceRecordFromPerson}
            />
            <Table
                data={gridItems}
                onSort={onSort}
                onToggle={toggleSelect}
                onToggleSelectAll={toggleSelectAll}
                allSelected={allSelected}
                history={history}
                extended={!person}
                handleOpenAbsenceRecord={handleOpenAbsenceRecordFromPerson}
            />
            <ConfirmationAlert
                confirmButtonText="Archive"
                onConfirm={archiveAbsenceRecords}
                onCancel={closeAlert}
                isOpen={showAlert}
                title="Confirm archiving!"
                icon={'archive'}
                intent={'warning'}
                description="Are you sure you want to archive the selected absence record(s)? This action is irreversible"
            />
            <ApproveConfirmationAlert
                confirmButtonText="Yes"
                onConfirm={approveAbsenceRecords}
                onCancel={closeAlert}
                isOpen={showApproveAlert}
                title="Confirm approval!"
                icon={'archive'}
                intent={'warning'}
                approvedItems={selectedApproved}
                pendingItems={selectedPending}
                rejectedItems={selectedRejected}
                selectedItems={selected}
            />
            <RejectConfirmationAlert
                confirmButtonText="Yes"
                onConfirm={rejectAbsenceRecords}
                onCancel={closeAlert}
                isOpen={showRejectAlert}
                title="Confirm rejection!"
                icon={'archive'}
                intent={'warning'}
                approvedItems={selectedApproved}
                pendingItems={selectedPending}
                rejectedItems={selectedRejected}
                selectedItems={selected}
            />
        </main>
    );
};

export default inject('RootStore')(observer(Absences));
