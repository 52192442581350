/* eslint-disable prettier/prettier */
import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';
import moment from 'moment';

import {
    CREATE_DOCUMENT,
    GET_DOCUMENTS,
    UPDATE_DOCUMENT,
    BULK_UPDATE_VERIFICATION,
    BULK_UPDATE_STATUS,
    DELETE_DOCUMENTS,
    GET_OPTIONAL_DOCUMENTS,
    ADD_EMPLOYEE_DOCUMENT,
    REMOVE_EMPLOYEE_DOCUMENT,
} from '../graphql/queries/documents';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore, SortInfo } from './PagedStore';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import type {
    GetPersonDocuments,
    GetPersonDocuments_getPersonDocuments_edges_node,
} from '../__generated__/GetPersonDocuments';
import { dateFormat } from '../constants/patientsConst';
import AppToaster from '../components/modules/helpers/Toaster';

export interface NormalizedDocument extends Document {
    expiresAtDate: Date | null;
    status: string;
    categoryName: string;
    isVerified: boolean;
}

export interface Document extends GetPersonDocuments_getPersonDocuments_edges_node {
    selected?: boolean;
}

class DocumentsStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            documents: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            optionalDocuments: observable,
            gridDocuments: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getDocuments: action,
            init: action,
            getOptionalDocuments: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    documents: Document[] = [];
    optionalDocuments: Document[] = [];
    sortFn: (a: any, b: any) => number = () => 0;
    fetchInterval: NodeJS.Timeout | null = null;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeDocument = (item: Document): NormalizedDocument => ({
        ...item,
        expiresAtDate: item.expiresAt ? moment(item.expiresAt).toDate() : null,
        expiresAt: item.expiresAt ? moment(item.expiresAt).format(dateFormat) : null,
        selected: this.selected.includes(item.id),
        status: item.isArchived ? 'Archived' : 'Active',
        categoryName: item.category.name,
    });

    get gridDocuments() {
        const result = this.documents.map(this.normalizeDocument);

        if (this.sortFn) {
            result.sort(this.sortFn);
        }
        return result;
    }

    select = (id: string) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id: string) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getDocuments();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getDocuments();
    };

    onSort = (sortInfo: SortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getDocuments();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                personId: null,
            });
        });
    };

    addOptionalDocument = async (employeeId: string, documentId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        await this.apolloClient.mutate({
            mutation: ADD_EMPLOYEE_DOCUMENT,
            variables: {
                documentId,
                employeeId,
            },
        });

        await this.refresh();

        await this.getOptionalDocuments(employeeId);
    };

    hideDocument = async (employeeId: string, documentId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        await this.apolloClient.mutate({
            mutation: REMOVE_EMPLOYEE_DOCUMENT,
            variables: {
                documentId,
                employeeId,
            },
        });

        await this.refresh();

        await this.getOptionalDocuments(employeeId);
    };

    getOptionalDocuments = async (employeeId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getEmployeeOptionalDocuments },
        } = await this.apolloClient.query({
            query: GET_OPTIONAL_DOCUMENTS,
            variables: {
                employeeId,
            },
        });

        runInAction(() => {
            this.optionalDocuments = getEmployeeOptionalDocuments.map((r: any) => ({
                value: r.id,
                label: `${r.category.name} (${r.fileName})`,
            }));
        });
    };

    getDocuments = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getPersonDocuments: data },
        }: { data: GetPersonDocuments } = await this.apolloClient.query({
            query: GET_DOCUMENTS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'fileName',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.documents = data.edges?.map((x: any) => x.node) || [];
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    selectAll = () => {
        runInAction(() => {
            this.gridDocuments.forEach((item) => {
                this.select(item.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async createDocument({
        personId,
        categoryId,
        fileName,
        mimeType,
        key,
        sizeInBytes,
        description,
        expiresAtDate,
        isArchived,
        isVerified,
    }: NormalizedDocument) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_DOCUMENT,
                variables: {
                    data: {
                        isArchived,
                        isVerified,
                        personId,
                        categoryId,
                        fileName,
                        mimeType,
                        key,
                        sizeInBytes,
                        expiresAt: expiresAtDate
                            ? moment.utc(expiresAtDate, dateFormat).format()
                            : null,
                        description: description || null,
                    },
                },
            });
            AppToaster.show({
                message: 'Document created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating document record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateDocument({
        id,
        categoryId,
        isArchived,
        isVerified,
        description,
        expiresAtDate,
    }: NormalizedDocument) {
        try {
            const {
                data: { updateDocument },
            } = await this.apolloClient.mutate({
                mutation: UPDATE_DOCUMENT,
                variables: {
                    id,
                    data: {
                        categoryId,
                        description,
                        isArchived,
                        isVerified,
                        expiresAt: expiresAtDate
                            ? moment.utc(expiresAtDate, dateFormat).format()
                            : null,
                    },
                },
            });

            AppToaster.show({
                message: 'Document updated successfully!',
                intent: 'success',
            });

            this.refresh();

            const result = this.normalizeDocument(updateDocument);

            return result;
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating document record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async bulkStatusChange(isArchived: boolean, ids: [string]) {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_UPDATE_STATUS,
                variables: {
                    isArchived,
                    ids,
                },
            });

            this.deselectAll();
            this.refresh();

            AppToaster.show({
                message: 'Selected Documents updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating document record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async bulkVerificationChange(isVerified: boolean, ids: [string]) {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_UPDATE_VERIFICATION,
                variables: {
                    isVerified,
                    ids,
                },
            });

            this.deselectAll();
            this.refresh();

            AppToaster.show({
                message: 'Selected Documents updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating selected documents. Please try again.',
                intent: 'danger',
            });
        }
    }

    async deleteDocuments(ids: [string]) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_DOCUMENTS,
                variables: {
                    ids,
                },
            });

            AppToaster.show({
                message: `Document${ids.length > 0 ? '1' : ''} deleted successfully!`,
                intent: 'success',
            });

            this.refresh();
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting document. Please try again.',
                intent: 'danger',
            });
            return err;
        }
    }
}

export default DocumentsStore;
