import * as Yup from 'yup';

export const validationSchema = Yup.object({
    dayOfWeek: Yup.mixed().required('This field is required.'),
    weekNumber: Yup.mixed().required('This field is required.'),
    shiftEmployeeRoleTypeId: Yup.string().required('This field is required.'),
    shiftFunctions: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    locationId: Yup.string().required('This field is required.'),
    typeId: Yup.string().required('This field is required.'),
    breakDurationMinutes: Yup.number().required('This field is required.'),
    breakIsPaid: Yup.bool().required('This field is required.'),
    payRateId: Yup.mixed().required('This field is required.'),
    trainingShift: Yup.bool(),
    trainees: Yup.array().when('trainingShift', {
        is: true,
        then: Yup.array().of(
            Yup.object().shape({
                traineeId: Yup.string().nullable(),
                roleId: Yup.string().required('This field is required.'),
                payRateId: Yup.string().required('This field is required.'),
                overrideValue: Yup.string().nullable(),
            }),
        ),
    }),
});
