import { FC, useState, useMemo } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../../../hook/useStores';
import moment from 'moment';
import EmptyListView from './EmptyListView';
import {
    getServiceDisplayName,
    getAdminDisplayName,
    getPathwayDisplayName,
} from '../../forms/common';
import { JobStatus, Patient } from '@doc-abode/data-models';

import { abortedJobStatuses } from '../visits/ContainerConst';
import { headerProperties, emptyStateMessage } from './ListViewConst';
import ListViewWarnings from './ListViewWarnings';
import { uniqBy } from 'lodash';
import { statusTags, genderMapping } from '../../../../../constants/mainConst';
import { useView } from '../../views/useView';

import { IconArrowDropDown, IconArrowDropUp } from '../../../../../helpers/ucr/icons';
import { getAgeFromDate } from '../../../../modules/helpers/formatData';
import { nhsNumberFormatter } from '../../../../../helpers/presentation';
import { presentationNameLMF } from '../../../../../helpers/names';

interface IProps {}

interface IPropsDisplayStatus {
    jobStatus?: string;
}
interface IPropsFindHCP {
    hcpId?: string | undefined | null;
}
interface IPropsDisplayDate {
    date?: string | undefined | null;
}
interface IPropsGender {
    gender?: string;
}
interface IPatientExtended extends Patient {
    hasStaffAlert?: boolean | null;
    hasPatientAlert?: boolean | null;
}
interface IpationSortedBy {
    key: string;
    direction: string;
}
interface IListGenerate {
    jobList: IPatientExtended[];
}

/*
    Displays the status as a friendly name.
*/
const DisplayStatus: FC<IPropsDisplayStatus> = ({ jobStatus }): JSX.Element | null => {
    const status = jobStatus ? jobStatus : 'PENDING';
    const friendlyStatus = Patient.getFriendlyVisitStatus(status as JobStatus);
    if (status && friendlyStatus) {
        let className = `ucr-listview__status ucr-listview__status--${statusTags[status]}`;
        return <span className={className}>{friendlyStatus}</span>;
    }
    return null;
};

// date formatter for arrived time, end time, planned time.
const DisplayDate: FC<IPropsDisplayDate> = ({ date }): JSX.Element | null => {
    if (!date) return null;
    return (
        <>
            {moment(date).format('DD/MM/YYYY')}
            <br></br>
            {moment(date).format('hh:mm A')}
        </>
    );
};

// display the patient date of birth or displays an empty string.
const DisplayDatePatient: FC<IPropsDisplayDate> = ({ date }): JSX.Element | null => {
    if (!date) return null;
    return <>{`${moment(date).format('DD/MM/YYYY')} (Age: ${getAgeFromDate(date)})`}</>;
};

// displays a safe string for gender.
const DisplayGender: FC<IPropsGender> = ({ gender }): JSX.Element | null => {
    if (!gender) return null;
    return <>{genderMapping[gender]}</>;
};

const ListView: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: {
                unassignedJobs,
                assignedJobs,
                showAbortedJobs,
                setFocused,
                focusedJobId,
                hcpFilters,
                selectedDate,
                patientAlerts,
                staffAlerts,
                hcps,
                nameFilters,
            },
            usersStore: { users },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: any };
    const { openDeepLink } = useView();

    const [listOfJobs, setListOfJobs] = useState<IPatientExtended[]>([]);
    const [sortConfig, setSortConfig] = useState<IpationSortedBy>({
        key: 'startDateTime',
        direction: 'ascending',
    });
    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
            direction = '';
        } else {
            direction = 'ascending';
        }
        setSortConfig({ key, direction });
    };

    const getClassNamesFor = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    useMemo(() => {
        const assignedJobsFiltered = (assignedJobs as Patient[]).filter((job) =>
            moment(job.startDateTime || job.dateOfVisit).isSame(selectedDate, 'day'),
        );

        const filteredJobsAssigned = showAbortedJobs
            ? assignedJobsFiltered
            : assignedJobsFiltered.filter(
                  (j) =>
                      !abortedJobStatuses.includes(j.jobStatus) ||
                      (j.buddyJobStatus && !abortedJobStatuses.includes(j.buddyJobStatus)),
              );

        const filteredUnassignedJobs = unassignedJobs.filter((job: any) =>
            moment(job.dateOfVisit).isSame(selectedDate, 'day'),
        );

        let jobs = [...filteredUnassignedJobs, ...filteredJobsAssigned];

        if (
            hcpFilters?.hcpType?.length ||
            hcpFilters?.availability?.length ||
            nameFilters.staffName?.length ||
            hcpFilters?.band?.length ||
            hcpFilters?.gender?.length
        ) {
            let filteredList = filteredJobsAssigned.filter((value: Patient) => {
                let staffNameStatus: boolean = true;
                if (nameFilters.staffName.length > 0) {
                    staffNameStatus = nameFilters.staffName.some((name: any) => {
                        return (
                            value.hcpName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyName
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.buddyId
                                ?.toLowerCase()
                                .includes(String(name).toLowerCase().trim()) ||
                            value.hcpId?.toLowerCase().includes(String(name).toLowerCase().trim())
                        );
                    });
                }

                return staffNameStatus;
            });

            if (
                hcpFilters.hcpType.length ||
                hcpFilters.availability.length ||
                hcpFilters.band.length ||
                hcpFilters.gender.length
            ) {
                filteredList = filteredList.filter((value: Patient) => {
                    let staffNameStatus: boolean = false;

                    staffNameStatus = hcps.some(({ userId }: { userId: any }) => {
                        return value.buddyId === userId || value.hcpId === userId;
                    });

                    return staffNameStatus;
                });
            }
            jobs = [...filteredList];
        }

        // remove any duplicates from assigned and unassigned. (mainly double up causing this.)
        jobs = uniqBy(jobs, 'id');

        // find all the double ups and duplicate there id so they appear twice in the list.
        let doubleUps: Patient[] = [];
        jobs.forEach((patient: Patient) => {
            if (patient.staffRequired === 2) {
                doubleUps.push({
                    ...patient,
                    jobStatus: patient.buddyJobStatus,
                    hcpName: patient.buddyName,
                    hcpId: patient.buddyId,
                    finishedDateTime: patient.buddyFinishedDateTime,
                    arrivedDateTime: patient.buddyArrivedDateTime,
                } as Patient);
            }
        });

        jobs = [...jobs, ...doubleUps];

        const unresolvedStaffAlert = new Set(staffAlerts.map((alert: any) => alert.userId));
        const jobList = jobs
            .map(
                (patient: IPatientExtended): IPatientExtended => {
                    const hasPatientAlert: boolean = patientAlerts.some((alert: any) => {
                        const isSame = moment(alert.createdAt).isSame(selectedDate, 'day');
                        return alert.jobId === patient.id && isSame;
                    });

                    patient.hasPatientAlert = hasPatientAlert;
                    patient.hasStaffAlert =
                        unresolvedStaffAlert.has(patient.hcpId || '') ||
                        unresolvedStaffAlert.has(patient.buddyId || '')
                            ? true
                            : false;

                    return patient;
                },
            )
            .sort((patientA: IPatientExtended, patientB: IPatientExtended): number => {
                let aLastName = patientA.lastName || '';
                let bLastName = patientB.lastName || '';
                let aStartTime =
                    moment(patientA.startDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;
                let bStartTime =
                    moment(patientB.startDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;

                let aArriveTime =
                    moment(patientA.arrivedDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;
                let bArriveTime =
                    moment(patientB.arrivedDateTime)
                        .set('seconds', 0)
                        .set('milliseconds', 0)
                        .valueOf() || false;

                if (patientA.hasStaffAlert) {
                    return -1;
                } else if (patientB.hasStaffAlert) {
                    return 1;
                } else if (patientA.hasStaffAlert && patientB.hasStaffAlert) {
                    if (aStartTime && bStartTime) {
                        return aStartTime < bStartTime ? -1 : 1;
                    } else if (aStartTime !== bStartTime) {
                        return bStartTime ? -1 : 1;
                    }
                }

                if (patientA.hasPatientAlert) {
                    return -1;
                } else if (patientB.hasPatientAlert) {
                    return 1;
                } else if (patientA.hasPatientAlert && patientB.hasPatientAlert) {
                    if (aStartTime && bStartTime) {
                        return aStartTime < bStartTime ? -1 : 1;
                    } else if (aStartTime !== bStartTime) {
                        return bStartTime ? -1 : 1;
                    }
                }

                if (aStartTime && bStartTime && aStartTime !== bStartTime) {
                    return aStartTime < bStartTime ? -1 : 1;
                } else if (aStartTime !== bStartTime) {
                    return bStartTime ? -1 : 1;
                }

                if (aArriveTime && bArriveTime) {
                    return aArriveTime < bArriveTime ? 1 : -1;
                }

                return aLastName.localeCompare(bLastName);
            })
            .sort((a: any, b: any) => {
                if (a[sortConfig.key] === null) {
                    return 1;
                }

                if (b[sortConfig.key] === null) {
                    return -1;
                }
                if (a[sortConfig.key] === b[sortConfig.key]) {
                    return 0;
                }
                if (
                    a[sortConfig.key] !== null &&
                    b[sortConfig.key] !== null &&
                    a[sortConfig.key] !== undefined &&
                    b[sortConfig.key] !== undefined
                ) {
                    if (sortConfig.direction === '') {
                        return NaN;
                    }
                    if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                } else {
                    if (sortConfig.direction === '') {
                        return NaN;
                    }
                    if (a[sortConfig.key] < b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? -1 : 1;
                    }
                    if (a[sortConfig.key] > b[sortConfig.key]) {
                        return sortConfig.direction === 'ascending' ? 1 : -1;
                    }
                    return 0;
                }
            });

        setListOfJobs(jobList);
    }, [
        assignedJobs,
        hcpFilters,
        hcps,
        nameFilters.staffName,
        patientAlerts,
        selectedDate,
        showAbortedJobs,
        staffAlerts,
        unassignedJobs,
        sortConfig,
    ]);

    // sets the focus of a patient, highlights the row and amends the listview history.
    const focusJob = (id: string, isDoubleUp: boolean): void => {
        const userId = isDoubleUp ? 'user2' : 'user1';
        setFocused({ jobId: id, user: userId });
        openDeepLink(id, userId);
    };

    // finds the hcp user name from the hcps list via the userId
    const FindUsername: FC<IPropsFindHCP> = ({ hcpId }): JSX.Element | null => {
        if (!hcpId) return null;
        let found = users.find((hcp: any) => {
            return hcp.userId === hcpId;
        });
        return found ? (
            <>
                {found.firstName} {found.lastName}
            </>
        ) : null;
    };

    // generates the header if adding or removing a remove please add/remove grid-template-columns on listview.scss
    const GenerateHeader: FC<IProps> = (): JSX.Element => {
        const jsxArr = headerProperties.map((value, index) => {
            return (
                <th
                    className="ucr-listview__header"
                    key={'listview-header-' + index}
                    onClick={() => requestSort(value.columnName)}
                >
                    <div className="patient-list__header-flex">
                        <div
                            className={`patient-list__header-text ${getClassNamesFor(
                                value.columnName,
                            )}`}
                        >
                            {value.headerName}
                        </div>
                        {value.sortable && (
                            <span className={getClassNamesFor(value.columnName)}>
                                <IconArrowDropUp
                                    className={'patient-list__header-sort-icon icon-ascending'}
                                />
                                <IconArrowDropDown
                                    className={`patient-list__header-sort-icon icon-descending`}
                                />
                            </span>
                        )}
                    </div>
                </th>
            );
        });
        return <>{jsxArr}</>;
    };

    // generates the list view per row.
    const GenerateList: FC<IListGenerate> = ({ jobList }): JSX.Element => {
        let jsxArr = jobList.map((job: IPatientExtended, index: number) => {
            const hasStaffAlert = job.hasStaffAlert ? 'ucr-listview__list-row--staff_alert' : '';
            const hasPatientAlert = job.hasPatientAlert
                ? 'ucr-listview__list-row--patient_alert'
                : '';

            const focused =
                focusedJobId === job.id
                    ? 'ucr-listview__containerWidth ucr-listview__list-row ucr-listview__list-row--content ucr-listview__list-row--focused'
                    : `ucr-listview__containerWidth ucr-listview__list-row ucr-listview__list-row--content ${
                          hasStaffAlert || hasPatientAlert
                      }`;

            const pressed = focusedJobId === job.id ? true : false;

            let arrivedDisplayVal = job.arrivedDateTime;
            let finishedDisplayVal = job.finishedDateTime;
            let hcpDisplayVal = job.hcpId;

            // To-be displayed information for double-ups depends on the HCP we are rendering
            const doubleUp = job.staffRequired === 2 && job.hcpId === job.buddyId;
            if (doubleUp) {
                arrivedDisplayVal = job.buddyArrivedDateTime;
                finishedDisplayVal = job.buddyFinishedDateTime;
                hcpDisplayVal = job.buddyId;
            }

            // To-be displayed information depends on the type of job
            const isAdmin = job.disposition === 'admin' ? true : false;
            let pathwayDisplayVal;
            let serviceDisplayVal;

            if (isAdmin) {
                pathwayDisplayVal = 'Admin';
                serviceDisplayVal = getAdminDisplayName(adminTypes, job.activityType);
            } else {
                const pathway = pathways.find(
                    (pathway: any) => pathway.value === job?.referralPathway,
                );

                pathwayDisplayVal = getPathwayDisplayName(pathways, job?.referralPathway);
                serviceDisplayVal = getServiceDisplayName(pathway?.services, job.disposition);
            }

            return (
                <tr
                    role="button"
                    aria-pressed={pressed}
                    tabIndex={index + 1}
                    onKeyDown={(key) => {
                        if (key.code === 'Enter') {
                            focusJob(job.id, doubleUp);
                        }
                    }}
                    onClick={() => {
                        focusJob(job.id, doubleUp);
                    }}
                    className={focused}
                    key={'listview-content-' + index}
                >
                    <td className="whiteSpace__nowrap">{nhsNumberFormatter(job.nhsNumber)}</td>
                    <td>{presentationNameLMF(job)}</td>
                    <td className="whiteSpace__nowrap">
                        <DisplayDatePatient date={job.dateOfBirth} />
                    </td>
                    <td className="ucr-listview__child--uppercase">
                        <DisplayGender gender={job.gender} />
                    </td>
                    <td>{job.postCode}</td>
                    <td>{pathwayDisplayVal}</td>
                    <td>{serviceDisplayVal}</td>
                    <td>
                        <DisplayDate date={job.startDateTime} />
                    </td>
                    <td>{job.duration}</td>
                    <td>
                        <DisplayDate date={arrivedDisplayVal} />
                    </td>
                    <td>
                        <DisplayDate date={finishedDisplayVal} />
                    </td>
                    <td>
                        <FindUsername hcpId={hcpDisplayVal} />
                    </td>
                    <td>
                        {/* doubleUp for status checked in  useMemo*/}
                        <DisplayStatus jobStatus={job.jobStatus} />
                    </td>
                    <td>
                        <ListViewWarnings job={job} />
                    </td>
                </tr>
            );
        });
        return <>{jsxArr}</>;
    };

    return (
        <div className="ucr-listview__main">
            <table className="ucr-listview__containerWidth">
                <thead>
                    <tr className="ucr-listview__list-row ucr-listview__list-row--sticky">
                        <GenerateHeader />
                    </tr>
                </thead>
                {listOfJobs.length > 0 && (
                    <tbody>
                        <GenerateList jobList={listOfJobs} />
                    </tbody>
                )}
            </table>
            {listOfJobs.length === 0 && <EmptyListView message={emptyStateMessage} />}
        </div>
    );
};

export default observer(ListView);
