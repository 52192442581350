export const formFields = [
    {
        name: 'firstName',
        value: () => '',
        validation: {
            required: true,
            type: 'string',
            validationMessage: 'First name is required',
        },
    },
    {
        name: 'lastName',
        value: () => '',
        validation: {
            required: true,
            type: 'string',
            validationMessage: 'Last name is required',
        },
    },
    {
        name: 'email',
        value: () => '',
        validation: {
            required: true,
            type: 'string',
            regex: {
                rule: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            },
            validationMessage: 'Email address is required',
            regexMessage: 'Invalid email format',
        },
    },
    {
        name: 'phoneNumber',
        value: () => '',
        validation: {
            type: 'string',
            regex: {
                rule: /(^$|^\d{9,10}$)/,
            },
            regexMessage:
                'Invalid phone number; number must be 9 or 10 digits excluding the country code',
        },
    },
    {
        name: 'hcpTypes',
        value: () => [],
        validation: {
            required: true,
            type: 'array',
            validationMessage: 'HCP Type(s) is required',
        },
    },
    {
        name: 'languages',
        value: () => [],
        validation: { required: false, type: 'array' },
    },
    {
        name: 'specialities',
        value: () => [],
        validation: { required: false, type: 'array' },
    },
    {
        name: 'practices',
        value: () => [],
        validation: { required: false, type: 'array' },
    },
    {
        name: 'gender',
        value: () => ({}),
        validation: {
            required: true,
            type: 'object',
            validationMessage: 'Gender is required',
        },
    },
    {
        name: 'band',
        value: () => ({}),
        validation: { required: false, type: 'object' },
    },
    {
        name: 'roles',
        value: () => [],
        validation: {
            required: true,
            type: 'array',
            validationMessage: 'Please select at least one role.',
        },
    },
];

export const getFieldsDefaultState = () => {
    let defaultState = {
        fields: {},
        validation: {},
        errors: {},
    };

    for (let i = 0; i < formFields.length; i++) {
        let name = formFields[i].name;

        defaultState.fields[name] = formFields[i].value();
        defaultState.validation[name] = formFields[i].validation;
        defaultState.errors[name] = [];
    }

    return defaultState;
};
