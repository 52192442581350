import React from 'react';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';

const FuncSummary = ({ ...props }: any) => {
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'functions'}
                componentPath={'/hr/functions'}
                items={props.functionsStore.allFunctionsActive}
                {...props}
            />
        </>
    );
};

export default FuncSummary;
