import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import {
    daysBetweenNegativeIncluded,
    formatDisplayDateTime,
} from '../../../../modules/helpers/formatData';
import Statuses from '../../../../common/Statuses';
import moment from 'moment';
import {
    SHIFT_TYPE_UNPAID_ID,
    SHIFT_STATUS_BACKFILLED_ID,
    SHIFT_STATUS_BLOCKED_ID,
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_BANK_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    SHIFT_TYPE_EXTRA_ID,
    SHIFT_TYPE_FLEXI_ID,
    SHIFT_TYPE_SICK_ID,
    SHIFT_TYPE_NON_CONTRACTED_ID,
} from '../../../../../constants/rotaConst';

import {
    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID,
} from '../../../../../constants/hrConst';

const RotaRow = ({
    onClick,
    id,
    selected,
    name,
    isPublished,
    createdAt,
    rotaShifts,
    onToggle,
    startDate,
    endDate,
    department,
    updatedAt,
    createdBy,
    allStaffs,
    costCentre,
}) => {
    const dt = new Date();

    const dayOfWeek = moment(startDate).toDate().getDay();

    const isArchivedOrSuspended = (employeeId) => {
        const employeeData = allStaffs.find((item) => item.id === employeeId);

        return (
            employeeData &&
            (employeeData.statusId === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID ||
                employeeData.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID)
        );
    };

    const getContractedWarning = (item, date) => {
        return (
            (item.typeId === SHIFT_TYPE_CONTRACTED_ID &&
                !item.confirmedEmployeeId &&
                daysBetweenNegativeIncluded(date, dt) < 4 &&
                item.typeId === SHIFT_TYPE_UNPAID_ID) ||
            ((item.typeId === SHIFT_TYPE_CONTRACTED_ID ||
                item.typeId === SHIFT_TYPE_EXTRA_ID ||
                item.typeId === SHIFT_TYPE_FLEXI_ID ||
                item.typeId === SHIFT_TYPE_SICK_ID ||
                item.typeId === SHIFT_TYPE_UNPAID_ID ||
                item.typeId === SHIFT_TYPE_NON_CONTRACTED_ID) &&
                (!item.confirmedEmployeeId || isArchivedOrSuspended(item.confirmedEmployeeId)) &&
                item.statusId === SHIFT_STATUS_STANDARD_ID)
        );
    };

    const getBankWarning = (item, date) => {
        return (
            (item.typeId === SHIFT_TYPE_BANK_ID &&
                !item.confirmedEmployeeId &&
                daysBetweenNegativeIncluded(date, dt) < 4 &&
                item.typeId === SHIFT_TYPE_UNPAID_ID) ||
            (item.typeId === SHIFT_TYPE_BANK_ID &&
                !item.confirmedEmployeeId &&
                daysBetweenNegativeIncluded(date, dt) < 4 &&
                item.statusId === SHIFT_STATUS_STANDARD_ID)
        );
    };

    const filledShifts = rotaShifts.filter((item) => {
        const calculatedDate = moment(startDate)
            .add((item.weekNumber - 1) * 7 + item.dayOfWeek - dayOfWeek, 'days')
            .toDate();

        const hasConfirmedEmployeeId =
            !!item.confirmedEmployeeId && !isArchivedOrSuspended(item.confirmedEmployeeId);
        const isBlockedOrBackFilled =
            item.statusId === SHIFT_STATUS_BACKFILLED_ID ||
            item.statusId === SHIFT_STATUS_BLOCKED_ID;
        const contractedAudit =
            item.typeId === SHIFT_TYPE_CONTRACTED_ID &&
            !item.confirmedEmployeeId &&
            daysBetweenNegativeIncluded(calculatedDate, dt) >= 4 &&
            item.typeId === SHIFT_TYPE_UNPAID_ID;

        const bankAudit =
            (item.typeId === SHIFT_TYPE_BANK_ID &&
                !item.confirmedEmployeeId &&
                daysBetweenNegativeIncluded(calculatedDate, dt) >= 4 &&
                item.typeId === SHIFT_TYPE_UNPAID_ID) ||
            (item.typeId === SHIFT_TYPE_BANK_ID &&
                !item.confirmedEmployeeId &&
                daysBetweenNegativeIncluded(calculatedDate, dt) >= 4 &&
                item.statusId === SHIFT_STATUS_STANDARD_ID);

        return hasConfirmedEmployeeId || isBlockedOrBackFilled || contractedAudit || bankAudit;
    });

    const unfilledShifts = rotaShifts.filter((item) => {
        const calculatedDate = moment(startDate)
            .add((item.weekNumber - 1) * 7 + item.dayOfWeek - dayOfWeek, 'days')
            .toDate();

        const contractedWarning = getContractedWarning(item, calculatedDate);

        const bankWarning = getBankWarning(item, calculatedDate);

        return (
            (contractedWarning && daysBetweenNegativeIncluded(calculatedDate, dt) >= 3) ||
            (bankWarning && daysBetweenNegativeIncluded(calculatedDate, dt) >= 3)
        );
    });

    const requiresAttentionShifts = rotaShifts.filter((item) => {
        const calculatedDate = moment(startDate)
            .add((item.weekNumber - 1) * 7 + item.dayOfWeek - dayOfWeek, 'days')
            .toDate();
        const contractedWarning = getContractedWarning(item, calculatedDate);

        const bankWarning = getBankWarning(item, calculatedDate);

        return (
            (contractedWarning && daysBetweenNegativeIncluded(calculatedDate, dt) < 3) ||
            (bankWarning && daysBetweenNegativeIncluded(calculatedDate, dt) < 3)
        );
    });

    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{isPublished ? name : '[Draft] ' + name}</td>
            <td>{costCentre.code}</td>
            <td>{costCentre.name}</td>
            <td>{department.costCode}</td>
            <td>{department.name}</td>
            <td>{moment(startDate).format('DD MMM YY')}</td>
            <td>{moment(endDate).format('DD MMM YY')}</td>
            <td>
                <Statuses
                    filled={filledShifts.length}
                    unfilled={unfilledShifts.length}
                    requiresAttention={requiresAttentionShifts.length}
                />
            </td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>{createdBy}</td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
        </tr>
    );
};
export default RotaRow;
