import moment from 'moment';

export type Values = {
    availableFrom?: Date;
    availableTo?: Date;
    startTime?: Date;
};

class VisitTimeLimiter {
    MIN_TIME: Date;
    MAX_TIME: Date;

    constructor(MIN_TIME: Date, MAX_TIME: Date) {
        this.MIN_TIME = MIN_TIME;
        this.MAX_TIME = MAX_TIME;
    }

    availableFrom(values: Values) {
        let minTime = this.MIN_TIME;
        let maxTime = this.MAX_TIME;

        const maxTimes = [];

        if (values.availableTo) {
            maxTimes.push(moment(values.availableTo).subtract(1, 'minute'));
        }

        if (maxTimes.length > 0) {
            maxTime = moment.min(maxTimes).toDate();
        }

        return { minTime, maxTime };
    }

    availableTo(values: Values) {
        let minTime = this.MIN_TIME;
        let maxTime = this.MAX_TIME;

        const minTimes = [];

        if (values.availableFrom) {
            minTimes.push(moment(values.availableFrom).add(1, 'minute'));
        }

        if (minTimes.length > 0) {
            minTime = moment.max(minTimes).toDate();
        }

        return { minTime, maxTime };
    }

    startTime(values: Values) {
        let minTime = this.MIN_TIME;
        let maxTime = this.MAX_TIME;

        const minTimes = [];
        const maxTimes = [];

        if (values.availableFrom) {
            minTimes.push(moment(values.availableFrom));
        }

        if (values.availableTo) {
            maxTimes.push(moment(values.availableTo));
        }

        if (minTimes.length > 0) {
            minTime = moment.max(minTimes).toDate();
        }

        if (maxTimes.length > 0) {
            maxTime = moment.min(maxTimes).toDate();
        }

        return { minTime, maxTime };
    }

    /**
     * same as startTime except you want it to destructure to minDate, maxDate
     */
    startDate(values: Values) {
        const startTimeResult = this.startTime(values);
        return {
            minDate: startTimeResult.minTime,
            maxDate: startTimeResult.maxTime,
        };
    }

    endTime(values: Values) {
        let minTime = this.MIN_TIME;
        let maxTime = this.MAX_TIME;

        const minTimes = [];

        if (values.startTime) {
            minTimes.push(moment(values.startTime).add(1, 'minute'));
        }

        if (minTimes.length > 0) {
            minTime = moment.max(minTimes).toDate();
        }

        return { minTime, maxTime };
    }

    /**
     * same as endTime except you want it to destructure to minDate, maxDate
     *
     */
    endDate(values: Values) {
        const endTimeResult = this.endTime(values);
        return {
            minDate: endTimeResult.minTime,
            maxDate: endTimeResult.maxTime,
        };
    }
}

export default VisitTimeLimiter;
