import { FormattedPDSRecord, formatPdsRecord } from '@doc-abode/data-models';
import { baseUrl } from './baseApi';
import axios from 'axios';
export const getPDSSpineData = async (
    authToken: string,
    nhsNumber: string,
    handleError: (errorMessage: string) => void = (errorMessage: string) => {},
): Promise<FormattedPDSRecord | undefined> => {
    try {
        const { data } = await axios.get(`${baseUrl}/nhs-digital/pds-fhir/patient/${nhsNumber}`, {
            headers: {
                Authorization: authToken,
            },
        });

        const formattedData: FormattedPDSRecord = formatPdsRecord(data, true);
        return formattedData;
    } catch (e: any) {
        console.error('PDS lookup error', e);

        const errorMessage = e.response?.data?.issue?.[0]?.details?.coding?.[0]?.display;
        if (errorMessage) {
            handleError(errorMessage);
        }
    }
};
