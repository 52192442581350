import { gql } from '@apollo/client';

export const GET_STAFF_RECORD_OPTIONS = gql`
    query GetStaffRecordOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: EmployeeFilter
        $query: String
    ) {
        getEmployees(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            pageInfo {
                hasNextPage
            }
            edges {
                node {
                    id
                    person {
                        firstName
                        middleName
                        lastName
                    }
                    roles {
                        employeeRole {
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const GET_STAFF_RECORDS = gql`
    query GetStaffRecords(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: EmployeeFilter
        $query: String
    ) {
        getEmployees(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    updatedAt
                    createdAt
                    updatedByPerson {
                        firstName
                        lastName
                    }
                    createdByPerson {
                        firstName
                        lastName
                    }
                    email
                    contractedWorkingHours
                    mondayContractedHours
                    tuesdayContractedHours
                    wednesdayContractedHours
                    thursdayContractedHours
                    fridayContractedHours
                    saturdayContractedHours
                    sundayContractedHours
                    startedWorkOn
                    endedWorkOn
                    contractTypeId
                    phone
                    gpPracticeOod
                    holidayAllowance
                    holidayTaken
                    workHoursTotal
                    sickOffTotal
                    holidayPending
                    companyRegistrationNumber
                    registeredCompanyName
                    registeredCompanyAddressLine1
                    registeredCompanyAddressLine2
                    registeredCompanyAddressLine3
                    registeredCompanyAddressLine4
                    registeredCompanyAddressCity
                    registeredCompanyAddressPostcode
                    registeredCompanyAddressCountry
                    vatRegistrationNumber
                    typeOfRegistration
                    isTupe
                    tupeOrganisationName
                    patientFacing
                    countryOfRegistration {
                        id
                        name
                    }
                    agencyId
                    statusId
                    isCompliant
                    isSupervisor
                    employeeStatus {
                        name
                    }
                    departments {
                        jobTitle
                        departmentId
                        department {
                            name
                            costCentre {
                                name
                            }
                        }
                    }
                    notificationType {
                        id
                        name
                    }
                    person {
                        id
                        firstName
                        middleName
                        lastName
                        dateOfBirth
                        personalEmail
                        genderId
                        homeAddress
                        gender {
                            name
                        }
                        languages {
                            language {
                                name
                            }
                        }
                    }
                    groups {
                        group {
                            id
                            name
                        }
                    }
                    roles {
                        id
                        employeeRoleTypeId
                        employeeRole {
                            id
                            name
                        }
                    }
                    specialities {
                        speciality {
                            name
                            id
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_STAFF_RECORD = gql`
    query GetEmployeeById($id: String!) {
        getEmployeeById(id: $id) {
            id
            email
            contractedWorkingHours
            mondayContractedHours
            tuesdayContractedHours
            wednesdayContractedHours
            thursdayContractedHours
            fridayContractedHours
            saturdayContractedHours
            sundayContractedHours
            startedWorkOn
            endedWorkOn
            contractTypeId
            phone
            gpPracticeOod
            holidayAllowance
            holidayTaken
            workHoursTotal
            sickOffTotal
            holidayPending
            agencyId
            statusId
            isCompliant
            isSupervisor
            employeeStatus {
                name
            }
            departments {
                startDateOfAssignment
                endDateOfAssignment
                isArchived
                departmentId
                payRates {
                    startDate
                    endDate
                    payRate {
                        name
                        id
                    }
                }
            }
            notificationType {
                id
                name
            }
            person {
                id
                firstName
                middleName
                lastName
                dateOfBirth
                personalEmail
                genderId
                homeAddress
                gender {
                    name
                }
                languages {
                    language {
                        name
                    }
                }
            }
            groups {
                group {
                    id
                    name
                }
            }
            roles {
                employeeRole {
                    id
                    name
                }
            }
            specialities {
                speciality {
                    name
                    id
                }
            }
            typeOfRegistration
            countryOfRegistration {
                id
                name
            }
            companyRegistrationNumber
            registeredCompanyName
            registeredCompanyAddressLine1
            registeredCompanyAddressLine2
            registeredCompanyAddressLine3
            registeredCompanyAddressLine4
            registeredCompanyAddressCity
            registeredCompanyAddressPostcode
            registeredCompanyAddressCountry
            vatRegistrationNumber
            isTupe
            tupeOrganisationName
            patientFacing
        }
    }
`;

export const CREATE_STAFF_RECORD = gql`
    mutation CreateStaffRecord($data: CreateOrUpdateEmployeeInput!) {
        createEmployee(data: $data) {
            id
        }
    }
`;

export const UPDATE_STAFF_RECORD = gql`
    mutation UpdateStaffRecord($id: String!, $data: CreateOrUpdateEmployeeInput!) {
        updateEmployee(id: $id, data: $data) {
            id
            email
            startedWorkOn
            endedWorkOn
            contractedWorkingHours
            mondayContractedHours
            tuesdayContractedHours
            wednesdayContractedHours
            thursdayContractedHours
            fridayContractedHours
            saturdayContractedHours
            sundayContractedHours
            holidayAllowance
            isTupe
            tupeOrganisationName
            agencyId
            statusId
            isSupervisor
            patientFacing
            employeeStatus {
                name
            }
            person {
                firstName
                middleName
                lastName
                id
            }
            groups {
                group {
                    id
                    name
                }
            }
            roles {
                employeeRole {
                    id
                    name
                }
            }
            specialities {
                speciality {
                    name
                    id
                }
            }
        }
    }
`;

export const DELETE_STAFF_RECORD = gql`
    mutation DeleteEmployee($id: String!) {
        deleteEmployee(id: $id) {
            count
        }
    }
`;

export const UPDATE_EMPLOYEE_STATUS = gql`
    mutation UpdateEmployeeStatus($employeeId: String!, $statusId: String!) {
        updateEmployeeStatus(employeeId: $employeeId, statusId: $statusId) {
            statusId
        }
    }
`;

export const IS_DEPARTMENT_IN_USE = gql`
    query IsDepartmentInUse($departmentId: String!, $staffId: String!) {
        isDepartmentInUse(departmentId: $departmentId, staffId: $staffId)
    }
`;

export const GET_COUNTRIES_LIST = gql`
    query GetCountries {
        getCountries {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_TUPE_ORGANISATIONS = gql`
    query searchTupeOrganisations($search: String!) {
        searchTupeOrganisations(search: $search)
    }
`;

export const VALIDATE_EMPLOYEE_RECORDS_ARCHIVATION = gql`
    query ValidateEmployeeRecordsArchivation($ids: [String!]!) {
        validateEmployeeRecordsArchivation(ids: $ids) {
            id
            endedWorkOn
            contractTypeId
            roles {
                employeeRole {
                    name
                }
            }
        }
    }
`;

export const ARCHIVE_EMPLOYEE_RECORDS = gql`
    mutation ArchiveEmployeeRecords($ids: [String!]!) {
        archiveEmployeeRecords(ids: $ids)
    }
`;
