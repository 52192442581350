import React from 'react';
import { Spinner } from '@blueprintjs/core';

const Loader = ({ fullscreen = true, size = 70 }) => {
    return (
        <div className={`loader-wrapper ${fullscreen ? 'loader-wrapper--fullscreen' : ''}`}>
            <div className="loader-content">
                <Spinner size={size} />
            </div>
        </div>
    );
};

export default Loader;
