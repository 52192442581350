import { JobStatus, Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import moment from 'moment';
import { FC, Fragment, useMemo } from 'react';
import slice from 'lodash/slice';

import { getMomentEndTime } from '../../../../../../helpers/ucr';
import {
    EarliestArrival,
    IconCarRequired,
    IconComplexCare,
    IconNonEnglishSpeaker,
    IconNote,
    IconMore,
    IconWarning,
    LatestArrival,
    People,
} from '../../../../../../helpers/ucr/icons';
import { ADMIN_TIME } from '../../../forms/AdminTime/AdminTimeConsts';
import { hasSecondIconsRow, hasThirdIconsRow } from './JobFunctions';
import { EnumJobContainer } from './JobTypes';

type IWarningIcons = {
    container: EnumJobContainer;
    job: Patient;
    hasUnresolvedAlerts: boolean;
    isDoubleUpWithoutStaff: boolean;
    isSecondDoubleUpHcp: boolean;
    isCareComplex: boolean;
    isDoubleUpVisit?: boolean;
    isNonEnlishSpoken: boolean;
    warningStatus: boolean;
};

const WarningIcons: FC<IWarningIcons> = (props) => {
    const {
        container,
        job,
        warningStatus,
        hasUnresolvedAlerts,
        isDoubleUpWithoutStaff,
        isCareComplex,
        isDoubleUpVisit,
        isNonEnlishSpoken,
        isSecondDoubleUpHcp,
    } = props;
    const { isVisitEarliestTimeBreach, isVisitLatestTimeBreach } = useGetVisitTimeBreach(job);

    const showTriangleIcon = hasUnresolvedAlerts || warningStatus;
    const showDoubleUpIcon = isDoubleUpVisit && hasSecondIconsRow(job, isSecondDoubleUpHcp);
    const showDoubleUpWithoutStaffIcon =
        isDoubleUpWithoutStaff && hasSecondIconsRow(job, isSecondDoubleUpHcp);
    const showEarliestIcon = job.availableFrom;
    const showLatestIcon = job.availableTo;
    const showCarRequiredIcon = hasSecondIconsRow(job, isSecondDoubleUpHcp) && job.carRequired;
    const showCareComplexIcon = hasSecondIconsRow(job, isSecondDoubleUpHcp) && isCareComplex;
    const showNotesIcon = hasThirdIconsRow(job, isSecondDoubleUpHcp) && job?.notes;
    const showNonEnlishSpokenIcon = hasThirdIconsRow(job, isSecondDoubleUpHcp) && isNonEnlishSpoken;

    const prioritizedWarnings = [
        {
            name: 'triangleWarning',
            isActive: showTriangleIcon,
            render: () => (
                <div data-test="icon-unresolved-alert">
                    <IconWarning className="ucr__calendar-icon ucr__calendar-icon--alert" />
                </div>
            ),
        },
        {
            name: 'doubleUpIcon',
            isActive: showDoubleUpIcon,
            render: () => (
                <div data-test="icon-job-alert">
                    <People className="ucr__calendar-icon" />
                </div>
            ),
        },
        {
            name: 'doubleUpWithoutStaffIcon',
            isActive: showDoubleUpWithoutStaffIcon,
            render: () => (
                <div data-test="icon-assigned-warning">
                    <People className="ucr__calendar-icon ucr__calendar-icon--alert" />
                </div>
            ),
        },
        {
            name: 'earliestIcon',
            isActive: showEarliestIcon,
            render: () => (
                <div>
                    <EarliestArrival
                        className={cn('ucr__calendar-icon', {
                            'ucr__calendar-icon--alert': isVisitEarliestTimeBreach,
                        })}
                    />
                </div>
            ),
        },
        {
            name: 'latestIcon',
            isActive: showLatestIcon,
            render: () => (
                <div>
                    <LatestArrival
                        className={cn('ucr__calendar-icon', {
                            'ucr__calendar-icon--alert': isVisitLatestTimeBreach,
                        })}
                    />
                </div>
            ),
        },
        {
            name: 'carRequiredIcon',
            isActive: showCarRequiredIcon,
            render: () => (
                <div data-test="icon-car-required">
                    <IconCarRequired className="ucr__calendar-icon" />
                </div>
            ),
        },
        {
            name: 'careComplexIcon',
            isActive: showCareComplexIcon,
            render: () => (
                <div data-test="icon-complex-care">
                    <IconComplexCare className="ucr__calendar-icon" />
                </div>
            ),
        },
        {
            name: 'notesIcon',
            isActive: showNotesIcon,
            render: () => (
                <div data-test="icon-note">
                    <IconNote className="ucr__calendar-icon" />
                </div>
            ),
        },
        {
            name: 'nonEnlishSpoken',
            isActive: showNonEnlishSpokenIcon,
            render: () => (
                <div data-test="icon-non-english-speaker">
                    <IconNonEnglishSpeaker className="ucr__calendar-icon" />
                </div>
            ),
        },
    ];

    let maxIconLength = prioritizedWarnings.length;

    if (job.duration) {
        const [hour, minute] = job.duration.split(':');
        const durationInMinutes =
            Number(hour) !== 0 ? Number(hour) * 60 + Number(minute) : Number(minute);

        maxIconLength = Math.floor(durationInMinutes / 10) * 2;
    }
    const shouldShowMoreIcon = prioritizedWarnings.filter((i) => i.isActive).length > maxIconLength;

    const filteredPrioritizedWarnings = slice(
        prioritizedWarnings.filter((i) => i.isActive),
        0,
        maxIconLength,
    );

    if (shouldShowMoreIcon) {
        filteredPrioritizedWarnings[filteredPrioritizedWarnings.length - 1] = {
            name: 'more',
            isActive: shouldShowMoreIcon,
            render: () => (
                <div data-test="icon-non-english-speaker">
                    <IconMore className="ucr__calendar-icon" />
                </div>
            ),
        };
    }

    return container === EnumJobContainer.CALENDAR ? (
        <>
            <div className="ucr__calendar-job-icons-container">
                {filteredPrioritizedWarnings.map((i) => (
                    <Fragment key={i.name}>{i.render()}</Fragment>
                ))}
            </div>
        </>
    ) : (
        <UnassignedWarningIcons {...props} />
    );
};

const UnassignedWarningIcons: FC<IWarningIcons> = ({
    job,
    warningStatus,
    hasUnresolvedAlerts,
    isDoubleUpWithoutStaff,
    isCareComplex,
    isDoubleUpVisit,
    isNonEnlishSpoken,
    isSecondDoubleUpHcp,
}) => {
    const { isVisitEarliestTimeBreach, isVisitLatestTimeBreach } = useGetVisitTimeBreach(job);
    const isAdminTime = job.disposition === ADMIN_TIME;

    return (
        <div className="ucr__calendar-job-icons-container">
            {hasSecondIconsRow(job, isSecondDoubleUpHcp) && (
                <>
                    {isDoubleUpVisit ? (
                        <div data-test="icon-job-alert">
                            <People className="ucr__calendar-icon" />
                        </div>
                    ) : undefined}
                    {isDoubleUpWithoutStaff ? (
                        <div data-test="icon-assigned-warning">
                            <People className="ucr__calendar-icon ucr__calendar-icon--alert" />
                        </div>
                    ) : undefined}
                </>
            )}

            {!isAdminTime && (hasUnresolvedAlerts || warningStatus) ? (
                <div data-test="icon-unresolved-alert">
                    <IconWarning className="ucr__calendar-icon ucr__calendar-icon--alert" />
                </div>
            ) : undefined}

            {job.availableFrom ? (
                <div
                    className={cn('ucr__calendar-time-wrapper', {
                        'ucr__calendar-time-wrapper--alert': isVisitEarliestTimeBreach,
                    })}
                >
                    <EarliestArrival
                        className={cn('ucr__calendar-icon', {
                            'ucr__calendar-icon--alert': isVisitEarliestTimeBreach,
                        })}
                    />
                    <span
                        className={cn('ucr__calendar-time-text', {
                            'ucr__calendar-time-text--alert':
                                hasUnresolvedAlerts || isVisitEarliestTimeBreach,
                        })}
                    >
                        {moment(job.availableFrom).format('h:mm a')}
                    </span>
                </div>
            ) : undefined}

            {job.availableTo ? (
                <div
                    className={cn('ucr__calendar-time-wrapper', {
                        'ucr__calendar-time-wrapper--alert': isVisitLatestTimeBreach,
                    })}
                >
                    <LatestArrival
                        className={cn('ucr__calendar-icon', {
                            'ucr__calendar-icon--alert': isVisitLatestTimeBreach,
                        })}
                    />
                    <span
                        className={cn('ucr__calendar-time-text', {
                            'ucr__calendar-time-text--alert':
                                hasUnresolvedAlerts || isVisitLatestTimeBreach,
                        })}
                    >
                        {moment(job.availableTo).format('h:mm a')}
                    </span>
                </div>
            ) : null}
            {/* =================car============== */}
            {job.carRequired ? (
                <div data-test="icon-car-required">
                    <IconCarRequired className="ucr__calendar-icon" />
                </div>
            ) : null}
            {/* =================job indicator================ */}
            {isCareComplex ? (
                <div data-test="icon-complex-care">
                    <IconComplexCare className="ucr__calendar-icon" />
                </div>
            ) : null}

            {hasThirdIconsRow(job, isSecondDoubleUpHcp) && (
                <>
                    {isNonEnlishSpoken ? (
                        <div data-test="icon-non-english-speaker">
                            <IconNonEnglishSpeaker className="ucr__calendar-icon" />
                        </div>
                    ) : null}
                    {job?.notes ? (
                        <div data-test="icon-note">
                            <IconNote className="ucr__calendar-icon" />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};

export function useGetVisitTimeBreach(job: Patient) {
    const { jobStatus, startDateTime, duration, availableTo, availableFrom } = job;

    return useMemo(() => {
        const isVisitTimeBreachStatus =
            jobStatus === JobStatus.ACCEPTED || jobStatus === JobStatus.CURRENT;

        const isVisitLatestTimeBreach =
            isVisitTimeBreachStatus &&
            moment(getMomentEndTime(startDateTime, duration)).isAfter(availableTo);
        const isVisitEarliestTimeBreach =
            isVisitTimeBreachStatus && moment(startDateTime).isBefore(availableFrom);

        return {
            isVisitLatestTimeBreach,
            isVisitEarliestTimeBreach,
        };
    }, [jobStatus, startDateTime, duration, availableTo, availableFrom]);
}

export default WarningIcons;
