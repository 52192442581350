import { Button } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';

interface Props {
    isSubmitting: boolean;
    toggleIsEdit: () => void;
    isEditing: boolean;
    onCancel: () => void;
    onDelete?: () => void;
    hasDeleteOption?: boolean;
    hasEditOption?: boolean;
    submitText?: string;
    submitIcon?: IconName;
    hasArchiveOption?: boolean;
    onArchive?: () => void;
}

const CommonModalFooter = ({
    isSubmitting,
    toggleIsEdit,
    isEditing,
    onCancel,
    onDelete,
    hasEditOption = true,
    hasDeleteOption,
    submitText = 'Save',
    submitIcon = 'floppy-disk',
    hasArchiveOption,
    onArchive,
}: Props) => {
    if (isEditing) {
        return (
            <>
                <Button large onClick={onCancel} icon="cross" outlined>
                    Cancel
                </Button>
                <Button
                    intent="success"
                    large
                    icon={submitIcon}
                    type="submit"
                    loading={isSubmitting}
                    outlined
                >
                    {submitText}
                </Button>
            </>
        );
    }
    return (
        <>
            {hasEditOption && (
                <Button
                    large
                    onClick={toggleIsEdit}
                    icon="edit"
                    intent="primary"
                    disabled={isSubmitting}
                    outlined
                >
                    Edit details
                </Button>
            )}
            {hasDeleteOption && (
                <Button
                    className="bp5-intent-danger"
                    large
                    onClick={onDelete}
                    icon="trash"
                    outlined
                >
                    Delete
                </Button>
            )}
            {hasArchiveOption && (
                <Button
                    className="bp5-intent-warning"
                    large
                    onClick={onArchive}
                    icon="archive"
                    outlined
                >
                    Archive
                </Button>
            )}
        </>
    );
};

export default CommonModalFooter;
