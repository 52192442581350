import { gql } from '@apollo/client';

export const GET_ABSENCES_LIST = gql`
    query GetAbsences1(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $filter: AbsenceFilter
        $query: String
        $field: String!
    ) {
        getAbsences(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    absenceTypeId
                    statusId
                    isArchived
                    startDateTime
                    endDateTime
                    createdAt
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedPerson {
                        firstName
                        lastName
                    }
                    person {
                        id
                        firstName
                        lastName
                        middleName
                        employees {
                            statusId
                            departments {
                                isArchived
                                department {
                                    id
                                    name
                                }
                            }
                            roles {
                                employeeRole {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    absenceType {
                        id
                        name
                    }
                    status {
                        id
                        name
                    }
                    documents {
                        fileName
                        key
                        mimeType
                        sizeInBytes
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_ABSENCES_FILTER_OPTIONS = gql`
    query GetAbsenceFilterOptions {
        getAbsenceFilterOptions {
            absenceTypeFilterOptions {
                label
                value
            }
            approvalStatusFilterOptions {
                label
                value
            }
            recordStatusFilterOptions {
                label
                value
            }
        }
    }
`;

export const GET_ABSENCE_BY_ID = gql`
    query GetAbsenceById($id: String!) {
        getAbsenceById(id: $id) {
            id
            isArchived
            person {
                id
                firstName
                lastName
                employees {
                    statusId
                    roles {
                        employeeRole {
                            name
                        }
                    }
                    departments {
                        isArchived
                        department {
                            name
                        }
                    }
                }
            }
            notes
            absenceTypeId
            absenceType {
                id
                name
            }
            status {
                id
                name
            }
            startDateTime
            endDateTime
            createdPerson {
                firstName
                lastName
            }
            createdAt
            updatedPerson {
                firstName
                lastName
            }
            updatedAt
            documents {
                fileName
                key
                mimeType
                sizeInBytes
            }
            statusId
        }
    }
`;

export const CREATE_ABSENCE = gql`
    mutation CreateAbsence($data: AbsenceInput!) {
        createAbsence(data: $data) {
            id
        }
    }
`;

export const UPDATE_ABSENCE = gql`
    mutation UpdateAbsence($id: String!, $data: AbsenceInput!) {
        updateAbsence(id: $id, data: $data) {
            id
            person {
                id
                firstName
                lastName
                employees {
                    roles {
                        employeeRole {
                            name
                        }
                    }
                    departments {
                        department {
                            name
                        }
                    }
                }
            }
            notes
            absenceTypeId
            startDateTime
            endDateTime
            createdPerson {
                firstName
                lastName
            }
            createdAt
            updatedPerson {
                firstName
                lastName
            }
            updatedAt
            documents {
                fileName
                key
                mimeType
                sizeInBytes
            }
            statusId
        }
    }
`;

export const APPROVE_ABSENCE = gql`
    mutation ApproveAbsence($id: String!) {
        approveAbsence(id: $id) {
            id
        }
    }
`;

export const REJECT_ABSENCE = gql`
    mutation RejectAbsence($id: String!) {
        rejectAbsence(id: $id) {
            id
        }
    }
`;

export const BULK_ARCHIVE_ABSENCE = gql`
    mutation BulkArchiveAbsence($ids: [String!]!) {
        bulkArchiveAbsence(ids: $ids) {
            count
        }
    }
`;

export const BULK_CHANGE_ABSENCE_STATUS = gql`
    mutation BulkChangeAbsenceStatus($ids: [String!]!, $statusId: String!) {
        bulkChangeAbsenceStatus(ids: $ids, statusId: $statusId) {
            count
        }
    }
`;

export const GET_PEOPLE_FOR_SELECT = gql`
    query GetPeopleForSelect(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: PersonFilter
        $query: String
    ) {
        getPersons(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    middleName
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;
