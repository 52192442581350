import React from 'react';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';

const AgenciesSummary = ({ ...props }) => {
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'agencies'}
                componentPath={'/hr/agencies/'}
                items={props.agenciesStore.allAgenciesActive}
                {...props}
            />
        </>
    );
};

export default AgenciesSummary;
