import { rootUrl, retryable } from './baseApi';
export const fetchUserOnboarding = (authToken: string, onboardingId: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/users/${onboardingId}`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const fetchOwnOnboarding = (authToken: string, onboardingId: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/organisations/${onboardingId}`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const updateOwnOnboarding = (
    authToken: string,
    onboardingId: string,
    params: { [key: string]: any },
): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/organisations/${onboardingId}`, {
            method: 'PATCH',
            body: JSON.stringify(params),
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const downloadFile = (authToken: string, key: string, source: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/${source}/files/download/${encodeURI(key)}`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const getFileUploadParams = (authToken: string, onboardingId: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/organisations/${onboardingId}/uploads`, {
            method: 'POST',
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const confirmFileUpload = (
    authToken: string,
    onboardingId: string,
    uploadId: string,
    body: { [key: string]: any },
): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/onboarding/organisations/${onboardingId}/uploads/${uploadId}/confirm`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const uploadFile = async (url: string, body: BodyInit): Promise<any> => {
    const response = await fetch(url, {
        method: 'POST',
        body,
    });

    if (!response.ok) {
        const error = await response.text();
        throw new Error(error);
    }

    return response;
};
