import _ from 'lodash';

import { formatAPIMultiSelect, splitPracticeDetails } from '../../helpers/formatData';

export const emptyErrorByType = (fieldValue, fieldType) => {
    switch (fieldType) {
        case 'number':
            return !_.isNumber(fieldValue);
        case 'date':
            return !_.isDate(fieldValue);
        case 'object':
            return _.isEmpty(fieldValue);
        case 'string':
            return _.isEmpty(fieldValue);
        case 'array':
            return _.isEmpty(fieldValue);
        default:
            return [];
    }
};

export const regexFieldValidation = (value, regex) => {
    if (regex) {
        return regex.test(value);
    }
    return true;
};

export const getAPIReadyUserData = (state) => {
    const normalizedData = {
        name: state.fields.firstName.trim() + ' ' + state.fields.lastName.trim(),
        email: state.fields.email !== '' ? state.fields.email.toLowerCase().trim() : null,
        phoneNumber: state.fields.phoneNumber !== '' ? state.fields.phoneNumber.trim() : null,
        hcpTypes: state.fields.hcpTypes.length ? formatAPIMultiSelect(state.fields.hcpTypes) : [],
        currentPractices: state.fields.practices.length
            ? state.fields.practices.map((practice) => splitPracticeDetails(practice.value))
            : [],
        languages: state.fields.languages.length
            ? formatAPIMultiSelect(state.fields.languages)
            : [],
        specialities: state.fields.specialities.length
            ? formatAPIMultiSelect(state.fields.specialities)
            : [],
        gender: state.fields.gender.value ? state.fields.gender.value : null,
        band: state.fields.band.value || null,
        roles: state.fields.roles || [],
    };

    // Remove empty optional fields
    _.forIn(state.validation, (fieldValidation, field) => {
        if (normalizedData[field] === null && !fieldValidation.required) {
            delete normalizedData[field];
        }
    });

    return normalizedData;
};
