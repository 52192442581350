import { FC, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../hook/useStores';
import { Dialogs } from '../../../../stores/UCRStore';

import { Panel } from './side/';
import { Container } from './visits/';
import { Button, ButtonElems, ButtonSizes } from '../../../v2/components';
import { IconAdd } from '../../../../helpers/ucr/icons';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const Visits: FC<IProps> = ({ isShow, closePanel }) => {
    const jobsRef = useRef<HTMLDivElement>(null);

    const {
        RootStore: {
            ucrStore: { setOpenedDialog, setVisitsEl },
        },
    } = useStores() as { RootStore: any };

    const openDialog = () => setOpenedDialog(Dialogs.ADD_NEW_VISIT);

    useEffect(() => {
        if (!jobsRef.current) return;

        setVisitsEl(jobsRef.current);
    }, [jobsRef, setVisitsEl]);

    return (
        <Panel title="Unassigned Visits" side="right" isShow={isShow} closePanel={closePanel}>
            <div ref={jobsRef} className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <Container />
            </div>
            <div className="ucr__side-panel-block">
                <Button
                    elem={ButtonElems.DIV}
                    name="Add a new visit"
                    size={ButtonSizes.VERTICAL}
                    isFullWidth={true}
                    Icon={IconAdd}
                    clickEvent={openDialog}
                />
            </div>
        </Panel>
    );
};

export default observer(Visits);
