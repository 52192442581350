import { FC, useState, useCallback } from 'react';
import { Form, useFormikContext, FormikContextType, FormikValues } from 'formik';
import { observer } from 'mobx-react';

import { Accordion, AccordionTab, AccordionColors } from '../../../../v2/components';
import { PatientTab } from '../PatientFormTab';
import ActivityDetails from './ActivityDetails';
import { TAdminTimeData } from './AdminTimeTypes';
import { getStepSchema } from './validation';
import { PullFromReferralForm } from '../DetailsFromReferral';
import { FormMode, FormSteps } from '../common';

export interface IProps {
    step: FormSteps;
    loading: boolean;
    handleSubmit: (values: FormikValues) => Promise<void>;
    formMode: FormMode;
    patientFieldsRequired: boolean;
    handleChangePatientRequired: (isRequired: boolean) => void;
}

const AddVisitForm: FC<IProps> = ({
    step,
    loading,
    handleSubmit,
    formMode,
    patientFieldsRequired,
    handleChangePatientRequired,
}) => {
    const [currentStep, setCurrentStep] = useState<FormSteps>(step);
    const [stepsCompleted, setStepsCompleted] = useState<string[]>([]);

    const {
        values,
        setFieldValue,
        isValid,
        setTouched,
    }: FormikContextType<TAdminTimeData> = useFormikContext();

    const onNextStep = useCallback(
        (nextStep: FormSteps, prevStep: FormSteps) => {
            const stepSchema = getStepSchema(prevStep, { patientFieldsRequired });
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, true);

            if (nextStep === FormSteps.REVIEW) {
                if (isValid) {
                    setStepsCompleted([...stepsCompleted, prevStep]);
                    handleSubmit(values);
                }
            } else {
                try {
                    const stepIsValid = stepSchema.validateSync(values);
                    if (stepIsValid) {
                        setStepsCompleted([...stepsCompleted, prevStep]);
                        setCurrentStep(nextStep);
                    }
                } catch (err: any) {
                    console.error('Validation error:', err.message);
                }
            }
        },
        [patientFieldsRequired, setTouched, isValid, stepsCompleted, handleSubmit, values],
    );

    const onSaveForm = useCallback(
        (step: FormSteps) => {
            const stepSchema = getStepSchema(step, { patientFieldsRequired });
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, true);

            try {
                const stepIsValid = stepSchema.validateSync(values);
                if (stepIsValid) {
                    handleSubmit(values);
                }
            } catch (err: any) {
                console.error('Validation error:', err.message);
            }
        },
        [handleSubmit, patientFieldsRequired, setTouched, values],
    );

    const onPatientDetailsNextStep = useCallback(() => {
        if (formMode === FormMode.DEFAULT || formMode === FormMode.FOLLOW_UP) {
            onNextStep(FormSteps.ACTIVITY, FormSteps.PATIENT);
        } else if (formMode === FormMode.ADD_VISIT) {
            onNextStep(FormSteps.REVIEW, FormSteps.PATIENT);
        }
    }, [formMode, onNextStep]);

    const activityStepColor =
        currentStep === FormSteps.ACTIVITY ? AccordionColors.PINK : AccordionColors.GREY;

    return (
        <Form>
            <Accordion>
                <PatientTab
                    stepsCompleted={stepsCompleted}
                    currentStep={currentStep}
                    formMode={formMode}
                    loading={loading}
                    onClick={() => setCurrentStep(FormSteps.PATIENT)}
                    onNextStep={onPatientDetailsNextStep}
                    onSaveForm={onSaveForm}
                    patientDetailsRequired={patientFieldsRequired}
                    handleChangePatientRequired={handleChangePatientRequired}
                />
                <AccordionTab
                    name="Activity"
                    title="Activity details"
                    color={
                        stepsCompleted.includes(FormSteps.ACTIVITY) &&
                        currentStep !== FormSteps.ACTIVITY
                            ? AccordionColors.BLUE
                            : activityStepColor
                    }
                    open={currentStep === FormSteps.ACTIVITY}
                    disabled={!stepsCompleted.includes(FormSteps.PATIENT)}
                >
                    <ActivityDetails
                        values={values}
                        loading={loading}
                        onNextStep={onNextStep}
                        setFieldValue={setFieldValue}
                        formMode={formMode}
                        onSaveForm={handleSubmit}
                    />
                </AccordionTab>
            </Accordion>
            <PullFromReferralForm isAdminTime />
        </Form>
    );
};

export default observer(AddVisitForm);
