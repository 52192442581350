import React, { useState } from 'react';
import { Classes, Dialog } from '@blueprintjs/core';

import ShiftForm from '../ShiftForm';
import { Formik } from 'formik';
import {
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    PAYMENT_STATUS_PLANED,
} from '../../../../../../constants/rotaConst';
import { getShiftDate } from '../helper';
import { validationSchema } from './ShiftDialogValidationSchema';

const ShiftDialog = (props: any) => {
    const {
        isOpen,
        onClose,
        onSubmit,
        editItem,
        updateRotaShiftComments,
        updateNotPublishedComments,
        currentUser,
        staffRecords,
        shiftStatuses,
        rotaStartDate,
        dayOfWeek,
        weekNumber,
        actionType,
        getShiftConflicts,
        dateRange,
        isGridView,
        rotaBuilderStore,
        fundingPoolsOptions,
        paymentStatusNormalized,
        isPublished,
        isViewMode,
    } = props;

    const initStartDate = new Date();
    initStartDate.setHours(8);
    initStartDate.setMinutes(0);

    const initEndDate = new Date();
    initEndDate.setHours(17);
    initEndDate.setMinutes(0);

    const onSave = (item: any) => {
        rotaBuilderStore.updateChangesState(true);
        onSubmit && onSubmit(item);
    };

    const [title, setTitle] = useState('');

    const isValidForShiftCost = (data: any) => {
        if (!data.locationId || !data.roleId || !data.payRateId) {
            return false;
        }
        return !(
            data.breakDurationMinutes === '' ||
            data.overrideValueInPence === '' ||
            data.overrideValueInPence === null
        );
    };

    const getRotaShiftCost = async (val: any, setFieldValue: any) => {
        const data = { ...val };
        delete data.cost;
        delete data.comments;
        delete data.shiftPatientFacingWarnings;
        if (isValidForShiftCost(data)) {
            const { getRotaShiftCost } = rotaBuilderStore;
            const calculatedCost = await getRotaShiftCost(data);
            setFieldValue('cost', calculatedCost ? calculatedCost : 0);
        }
    };

    const getShiftPatientFacingWarnings = async (val: any, setFieldValue: any) => {
        if (val.confirmedEmployeeId || val.defaultEmployeeId) {
            const data = {
                staffMemberId: val.confirmedEmployeeId || val.defaultEmployeeId,
                isConfirmed: val.confirmedEmployeeId ? true : false,
                shiftType: 'rotaShift',
            };
            const shiftPatientFacingWarnings = await rotaBuilderStore.getShiftPatientFacingWarnings(
                data,
            );
            setFieldValue('shiftPatientFacingWarnings', shiftPatientFacingWarnings);
        } else {
            setFieldValue('shiftPatientFacingWarnings', []);
        }
    };

    return (
        <Dialog
            onClose={onClose}
            title={title}
            autoFocus
            canOutsideClickClose={false}
            usePortal
            isOpen={isOpen}
            className="rota-builder__shift-dialog"
        >
            <div className={Classes.DIALOG_BODY}>
                <Formik
                    enableReinitialize
                    initialValues={
                        editItem
                            ? {
                                  id: editItem.id,
                                  breakDurationMinutes: editItem.breakDurationMinutes,
                                  breaksPaid: editItem.breaksPaid,
                                  dayOfWeek: editItem.dayOfWeek,
                                  defaultEmployeeId: editItem.defaultEmployeeId,
                                  confirmedEmployeeId: editItem.confirmedEmployeeId,
                                  endTime: editItem.endTime,
                                  locationId: editItem.locationId,
                                  overrideValueInPence: editItem.overrideValueInPence,
                                  roleId: editItem.roleId,
                                  rotaShiftFunctions: editItem.rotaShiftFunctions,
                                  fundingPoolId: editItem.fundingPoolId,
                                  startTime: editItem.startTime,
                                  weekNumber: editItem.weekNumber,
                                  statusId: editItem.statusId
                                      ? editItem.statusId
                                      : SHIFT_STATUS_STANDARD_ID,
                                  typeId: editItem.typeId,
                                  isAdditional: editItem.isAdditional,
                                  thirdPartyPaid: editItem.thirdPartyPaid,
                                  payRateId: editItem.payRateId,
                                  cost: editItem.cost,
                                  trainingShift: editItem.trainingShift,
                                  trainees: editItem.trainees?.map((trainee: any) => ({
                                      traineeId: trainee.traineeId,
                                      roleId: trainee.roleId,
                                      overrideValue: trainee.overrideValue,
                                      payRateId: trainee.payRateId,
                                  })),
                                  comments: editItem.comments,
                                  startDate:
                                      editItem.startDate !== null
                                          ? editItem.startDate
                                          : getShiftDate(
                                                rotaStartDate,
                                                { dayOfWeek, weekNumber },
                                                8,
                                                0,
                                            ),
                                  endDate:
                                      editItem.endDate !== null
                                          ? editItem.endDate
                                          : getShiftDate(
                                                rotaStartDate,
                                                { dayOfWeek, weekNumber },
                                                17,
                                                0,
                                            ),
                                  conflicts: editItem.conflicts,
                                  paymentStatusId:
                                      editItem.paymentStatusId || PAYMENT_STATUS_PLANED,
                                  patientFacing: editItem.patientFacing,
                              }
                            : {
                                  weekNumber: isGridView ? weekNumber : null,
                                  dayOfWeek: isGridView ? dayOfWeek : null,
                                  roleId: null,
                                  rotaShiftFunctions: [],
                                  fundingPoolId: null,
                                  payRateId: null,
                                  cost: 0,
                                  locationId: null,
                                  startTime: getShiftDate(
                                      rotaStartDate,
                                      { dayOfWeek, weekNumber },
                                      8,
                                      0,
                                  ),
                                  endTime: getShiftDate(
                                      rotaStartDate,
                                      { dayOfWeek, weekNumber },
                                      17,
                                      0,
                                  ),
                                  breakDurationMinutes: 0,
                                  breaksPaid: true,
                                  defaultEmployeeId: null,
                                  confirmedEmployeeId: null,
                                  overrideValueInPence: 0,
                                  statusId: SHIFT_STATUS_STANDARD_ID,
                                  typeId: SHIFT_TYPE_CONTRACTED_ID,
                                  isAdditional: true,
                                  thirdPartyPaid: false,
                                  trainingShift: false,
                                  trainees: [
                                      {
                                          traineeId: '',
                                          roleId: '',
                                          overrideValue: null,
                                          payRateId: '',
                                      },
                                  ],
                                  comments: [],
                                  startDate: isGridView
                                      ? getShiftDate(rotaStartDate, { dayOfWeek, weekNumber }, 8, 0)
                                      : null,
                                  endDate: getShiftDate(
                                      rotaStartDate,
                                      { dayOfWeek, weekNumber },
                                      17,
                                      0,
                                  ),
                                  conflicts: [],
                                  paymentStatusId: PAYMENT_STATUS_PLANED,
                                  patientFacing: false,
                              }
                    }
                    validationSchema={validationSchema}
                    onSubmit={onSave}
                >
                    {({
                        values,
                        isSubmitting,
                        handleChange,
                        setFieldValue,
                        setValues,
                        errors,
                        touched,
                    }) => {
                        return (
                            <ShiftForm
                                isViewMode={isViewMode}
                                isGridView={isGridView}
                                dateRange={dateRange}
                                staffMembers={staffRecords}
                                editItem={editItem}
                                onClose={onClose}
                                shiftStatuses={shiftStatuses}
                                updateRotaShiftComments={updateRotaShiftComments}
                                updateNotPublishedComments={updateNotPublishedComments}
                                currentUser={currentUser}
                                setFieldValue={setFieldValue}
                                setValues={setValues}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                values={values}
                                isSubmitting={isSubmitting}
                                actionType={actionType}
                                getShiftConflicts={getShiftConflicts}
                                getRotaShiftCost={getRotaShiftCost}
                                fundingPoolsOptions={fundingPoolsOptions}
                                setTitle={setTitle}
                                paymentStatusNormalized={paymentStatusNormalized}
                                isPublished={isPublished}
                                getShiftPatientFacingWarnings={getShiftPatientFacingWarnings}
                            />
                        );
                    }}
                </Formik>
            </div>
        </Dialog>
    );
};

export default ShiftDialog;
