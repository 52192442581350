import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import PeopleItem from './PeopleItem';
import PeopleHeader from './PeopleHeader';
import NavigationPeople from './NavigationPeople';
import Loader from '../../../modules/helpers/Loader';
import PersonDetails from '../../../modules/people/detail/PersonDetails';
import ArchivePersonStaffConfirmationPopUp from '../../../common/ArchivePersonStaffConfirmationPopUp';
import ArchivePersonConfirmationPopUpText from './ArchivePersonConfirmationPopUpText';
import { CONTRACTOR_IDS, EMPLOYEE_RECORD_ARCHIVED_STATUS_ID } from '../../../../constants/hrConst';
import moment from 'moment';

const AdminManagePeople = inject('RootStore')(
    observer(
        class AdminManagePeople extends Component {
            state = {
                listOfNotValidPeopleForArchive: [],
                listOfStaffNotValidForArchive: [],
                showAlert: false,
            };

            componentDidMount() {
                this.props.RootStore.peopleStore.getPeople();
            }

            componentDidUpdate(prevProps, nextProps) {
                if (this.props.location.pathname !== prevProps.location.pathname) {
                    this.props.RootStore.peopleStore.getPeople();
                }
            }
            componentWillUnmount() {
                this.props.RootStore.peopleStore.clear();
            }
            _handleEmployeeClick = (personId, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/people/${personId}`);
                }
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/people/new`);
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.peopleStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.peopleStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleTabChange = (tabId) => {
                const { personId } = this.props.match?.params ?? {};
                this.props.history?.push(`/hr/people/${personId}/${tabId}`);
            };

            _validatePersonBeforeArchiving = async (ids) => {
                const { peopleStore } = this.props.RootStore;
                const validationData = await peopleStore.validatePersonArchivation(ids);
                if (!validationData.length) {
                    return this.setState({
                        listOfNotValidPeopleForArchive: [],
                        listOfStaffNotValidForArchive: [],
                        showAlert: true,
                    });
                }
                // Create a list of items that didn't pass validation
                let listOfNotValidPeople = [];
                let listOfNotValidStaff = [];
                validationData.forEach((person) => {
                    if (
                        person.employmentEndDate === null ||
                        moment(person.employmentEndDate) > moment().toDate()
                    ) {
                        if (
                            person.employees.length === 0 ||
                            !person.employees.every(
                                (staff) =>
                                    CONTRACTOR_IDS.includes(staff.contractTypeId) ||
                                    staff.statusId === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
                            )
                        ) {
                            listOfNotValidPeople.push({
                                id: person.id,
                                person: `${person.firstName} ${person.middleName ?? ''} ${
                                    person.lastName
                                }`,
                            });
                        }
                    }
                    if (person.employees.length) {
                        person.employees.forEach((employee) => {
                            let staffRecordNotValid =
                                employee.endedWorkOn === null ||
                                moment(employee.endedWorkOn).toDate() > moment().toDate();
                            // check for inner departments only in case if the staff record has a valid date and we have departments
                            if (!staffRecordNotValid && employee.departments.length) {
                                staffRecordNotValid = employee.departments.some(
                                    (dep) =>
                                        dep.endDateOfAssignment === null ||
                                        moment(dep.endDateOfAssignment).toDate() >
                                            moment().toDate(),
                                );
                            }
                            // if record not valid add it to the overall list
                            if (staffRecordNotValid) {
                                const staffIsSessional = CONTRACTOR_IDS.includes(
                                    employee.contractTypeId,
                                );
                                listOfNotValidStaff.push({
                                    id: employee.id,
                                    person: `${person.firstName} ${person.middleName ?? ''} ${
                                        person.lastName
                                    }`,
                                    staffType: staffIsSessional
                                        ? 'sessional-records'
                                        : 'employed-records',
                                    role: employee.roles.length
                                        ? employee.roles[0]?.employeeRole?.name
                                        : '-',
                                });
                            }
                        });
                    }
                });

                return this.setState({
                    listOfNotValidPeopleForArchive: listOfNotValidPeople,
                    listOfStaffNotValidForArchive: listOfNotValidStaff,
                    showAlert: true,
                });
            };

            _archiveStuff = async () => {
                const { peopleStore } = this.props.RootStore;
                await peopleStore.archivePersonRecords(peopleStore.selected);
                peopleStore.deselectAll();
                this.setState({ showAlert: false });
                peopleStore.getPeople();
            };

            _closePopUp = () => {
                this.setState({ showAlert: false });
            };

            render() {
                const {
                    RootStore: {
                        peopleStore: {
                            gridPeople,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            onSearch,
                            loaded,
                            selected,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                const {
                    showAlert,
                    listOfNotValidPeopleForArchive,
                    listOfStaffNotValidForArchive,
                } = this.state;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { personId, tab } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <NavigationPeople
                                onFilter={onFilter}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                refresh={this.props.RootStore.peopleStore.refreshPage}
                                selected={selected}
                                validatePersonBeforeArchiving={this._validatePersonBeforeArchiving}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <PeopleHeader
                                    data={gridPeople}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridPeople.map((person) => (
                                        <PeopleItem
                                            {...person}
                                            onClick={this._handleEmployeeClick}
                                            key={person.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(personId || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <PersonDetails
                                    {...this.props}
                                    actionType={actionType}
                                    tab={tab}
                                    handleTabChange={this._handleTabChange}
                                />
                            </div>
                        )}
                        <ArchivePersonStaffConfirmationPopUp
                            isOpen={showAlert}
                            titleText={'Confirm archiving!'}
                            bodyText={ArchivePersonConfirmationPopUpText(
                                listOfNotValidPeopleForArchive,
                                listOfStaffNotValidForArchive,
                            )}
                            onSubmit={this._archiveStuff}
                            onCancel={this._closePopUp}
                        />
                    </>
                );
            }
        },
    ),
);

export default AdminManagePeople;
