import { rootUrl, retryable } from './baseApi';
import { getBearerToken } from './amplifyApi';

const { REACT_APP_GRAPHQL_URL } = process.env;

const host = REACT_APP_GRAPHQL_URL
    ? REACT_APP_GRAPHQL_URL.replace('/graphql', '')
    : `${rootUrl}/hr-rota`;
export const getUploadParams = async (fileName: string, org: string): Promise<any> => {
    const Authorization = await getBearerToken({ asBearerToken: true });

    return retryable(() =>
        fetch(`${host}/documents/upload`, {
            method: 'POST',
            body: JSON.stringify({ fileName, organisation: 'demo' }),
            headers: {
                Authorization,
                'Content-Type': 'application/json',
                'x-docabode-organisation': org,
            },
        }),
    );
};
export const getPhotoUploadParams = async (fileName: string, org: string): Promise<any> => {
    const Authorization = await getBearerToken({ asBearerToken: true });

    return retryable(() =>
        fetch(`${host}/documents/upload_profile`, {
            method: 'POST',
            body: JSON.stringify({ fileName, organisation: 'demo' }),
            headers: {
                Authorization,
                'Content-Type': 'application/json',
                'x-docabode-organisation': org,
            },
        }),
    );
};
export const uploadFile = (file: string | Blob, uploadParams: { [key: string]: any }) => {
    const formData = new FormData();

    Object.keys(uploadParams.fields).forEach((param) => {
        formData.append(param, uploadParams.fields[param]);
    });

    formData.append('file', file);

    return fetch(uploadParams.url, {
        method: 'POST',
        body: formData,
    });
};
export const downloadFile = async (key: string, org: string): Promise<any> => {
    const Authorization = await getBearerToken({ asBearerToken: true });

    return retryable(() =>
        fetch(`${host}/documents/download`, {
            method: 'POST',
            body: JSON.stringify({ key }),
            headers: {
                Authorization,
                'Content-Type': 'application/json',
                'x-docabode-organisation': org,
            },
        }),
    );
};
export const documentsBulkDownload = async (keys: any[], org: string): Promise<any> => {
    const Authorization = await getBearerToken({ asBearerToken: true });

    return retryable(() =>
        fetch(`${host}/documents/bulk_download`, {
            method: 'POST',
            body: JSON.stringify({ keys }),
            headers: {
                Authorization,
                'Content-Type': 'application/json',
                'x-docabode-organisation': org,
            },
        }),
    );
};

export const sendInvite = async (personId: string, org: string) => {
    const Authorization = await getBearerToken({ asBearerToken: true });

    return fetch(`${host}/onboarding/send-invite`, {
        method: 'POST',
        headers: {
            Authorization,
            'Content-Type': 'application/json',
            'x-docabode-organisation': org,
        },
        body: JSON.stringify({ personId }),
    });
};
