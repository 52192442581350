import React from 'react';

import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import { GOOGLE_API_LIBRARIES } from '../../../constants/googleAPIs';

function GMapComponent({ data, onMarkerClick, zoom, centre, apiKey }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_API_LIBRARIES,
    });

    return isLoaded ? (
        <GoogleMap center={centre} zoom={10}>
            {data.map((jobs) =>
                jobs.data.map((job, index) => {
                    if (job.latitude && job.longitude) {
                        return (
                            <Marker
                                type={jobs.type}
                                onClick={() => onMarkerClick(job.id)}
                                position={{ lat: job.latitude, lng: job.longitude }}
                                icon={`/images/icons/${job.jobType}-${jobs.type}.svg`}
                                key={`${jobs.type}-${index}-marker`}
                            />
                        );
                    }

                    return null;
                }),
            )}
        </GoogleMap>
    ) : null;
}

export default React.memo(GMapComponent);
