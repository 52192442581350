import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import GroupsTableRow from './GroupsTableRow';
import GroupsTableHeader from './GroupsTableHeader';
import GroupsTableNav from './GroupsTableNav';
import Loader from '../../../../modules/helpers/Loader';
import GroupsDetails from '../../../../modules/groups/detail/GroupsDetails';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';

const Groups = inject('RootStore')(
    observer(
        class AdminManageGroups extends Component {
            state = {
                showAlert: false,
            };

            componentWillUnmount() {
                this.props.RootStore.groupsStore.clear();
            }

            _handleRowClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/groups/${id}`);
                }
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/groups/new`);
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.groupsStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.groupsStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.groupsStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.groupsStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
                const isNotEmpty = !_.isEmpty(selected);
                isNotEmpty && (await this.props.RootStore.groupsStore.bulkDeleteGroup(selected));
            };

            render() {
                const {
                    RootStore: {
                        groupsStore: {
                            gridGroups,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            loaded,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <GroupsTableNav
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleAlert}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <GroupsTableHeader
                                    data={gridGroups}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridGroups.map((group) => (
                                        <GroupsTableRow
                                            {...group}
                                            onClick={this._handleRowClick}
                                            key={group.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <GroupsDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these groups? You won't be
                                        able to recover them."
                        />
                    </>
                );
            }
        },
    ),
);

export default Groups;
