import React from 'react';
import ReactDOM from 'react-dom';
import { Callout } from '@blueprintjs/core';
import { Provider } from 'mobx-react';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { configure } from 'mobx';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';

import './assets/index.scss';
import App from './components/App';
import Authenticator from './components/auth/Authenticator';
import Footer from './components/layout/Footer';
import RootStore from './stores/RootStore';
import packageInfo from '../package.json';
import reportWebVitals from './reportWebVitals';
import config from './config';

const version = packageInfo.version;
const { REACT_APP_ENVIRONMENT: ENV, NODE_ENV, REACT_APP_DEV_BRANCH: BRANCH } = process.env;

const {
    ga: { measurementId },
    hotjar: { hjid },
} = config();

if (measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send('pageview');
}

if (hjid) {
    hotjar.initialize(hjid, 6);
}

if (NODE_ENV === 'production') {
    Sentry.init({
        environment: ENV,
        release: version,
        dsn: 'https://cd010e53aafe4398a06efecf434ef3fc@o421207.ingest.sentry.io/5340722',
        integrations: [new CaptureConsole({ levels: ['warn', 'error'] })],
    });
}

configure({ enforceActions: 'never' });
library.add(faEllipsisV);

const rootStore = new RootStore();

const Root = (
    <React.StrictMode>
        <div className={`container ${ENV !== 'prod' ? 'container--non-prod' : ''}`}>
            {ENV !== 'prod' && (
                <div className="info-bar info-bar--top">
                    <Callout intent="warning">
                        This is a{' '}
                        <strong className="highlight-warning">
                            {`${ENV?.toUpperCase()} ${BRANCH ? `DEV BRANCH (${BRANCH})` : ''}`}
                        </strong>{' '}
                        environment. Any actions performed here will not affect production systems.
                    </Callout>
                </div>
            )}
            <Provider RootStore={rootStore}>
                <Authenticator RootStore={rootStore}>
                    <App />
                </Authenticator>
                <Footer />
            </Provider>
        </div>
        <div id="custom-prompt"></div>
    </React.StrictMode>
);

ReactDOM.render(Root, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
