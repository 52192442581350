import { gql } from '@apollo/client';

export const GET_GROUPS_LIST = gql`
    query GetGroups(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getGroups(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const CREATE_GROUP = gql`
    mutation CreateGroup($data: GroupEntityInput!) {
        createGroup(data: $data) {
            id
        }
    }
`;

export const UPDATE_GROUP = gql`
    mutation UpdateGroup($id: String!, $data: GroupEntityInput!) {
        updateGroup(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const DELETE_GROUP = gql`
    mutation DeleteGroup($id: String!) {
        deleteGroup(id: $id) {
            count
        }
    }
`;

export const GET_GROUP = gql`
    query GetGroupById($id: String!) {
        getGroupById(id: $id) {
            id
            name
            createdAt
            updatedAt
        }
    }
`;

export const BULK_DELETE_GROUP = gql`
    mutation BulkDeleteGroup($id: [String!]!) {
        bulkDeleteGroup(id: $id) {
            count
        }
    }
`;
