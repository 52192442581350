import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { AlertStatus, StaffAlert } from '@doc-abode/data-models';

import useStores from '../../../../../hook/useStores';
import HCP from './HCP';
import { ViewToShow } from '../../../../../constants/mainConst';
import { IHcp } from '../../../../../interfaces/ucr';

const HCPs: FC = () => {
    const hcpsRef = useRef<HTMLDivElement>(null);
    const [numberAllHcpsPos, setNumberAllHcpsPos] = useState<number>(0);

    const [initalized, setInitalized] = useState(false);
    const [hcpsList, setHcpsList] = useState<IHcp[]>([]);
    const [staffAlerts, setStaffAlerts] = useState<StaffAlert[]>([]);
    const [pinnedStatusesObject, setPinnedStatusesObject] = useState({});

    const {
        RootStore: {
            ucrStore: {
                hcps,
                staffAlerts: allStaffAlerts,
                setHcpsOffset,
                setPinnedStaff,
                viewToShow,
            },
            usersStore: { users },
            userStore: { user },
            mapStore: { hcps: mapHcps, hcpsBreakdownInfo },
        },
    } = useStores() as { RootStore: any };

    const updateAllHcpsPos = useCallback(() => {
        setNumberAllHcpsPos((numb) => numb + 1);
    }, []);
    const isMapView = viewToShow === ViewToShow.MAP;

    useEffect(() => {
        if (!hcpsRef.current) return;

        setHcpsOffset(hcpsRef.current.offsetTop);
    }, [hcpsRef, setHcpsOffset]);

    useEffect(() => {
        setStaffAlerts(allStaffAlerts.filter((alert: any) => alert.status === AlertStatus.OPEN));
        const pinnedStatuses: any[] = users.filter((filterUser: any) => {
            return filterUser.userId === user.username;
        });

        if (pinnedStatuses && pinnedStatuses.length > 0 && !initalized) {
            setPinnedStaff(pinnedStatuses[0].staffPins || {});
            setInitalized(true);
        }

        setPinnedStatusesObject(
            pinnedStatuses && pinnedStatuses.length > 0 && pinnedStatuses[0].staffPins
                ? pinnedStatuses[0].staffPins
                : {},
        );

        const displayHcps = isMapView
            ? mapHcps.filter(
                  ({ userId, hasJobs }: { userId: string; hasJobs: boolean }) =>
                      hasJobs && hcpsBreakdownInfo[userId]?.length,
              )
            : hcps;

        setHcpsList(displayHcps);
    }, [
        allStaffAlerts,
        hcps,
        hcpsBreakdownInfo,
        initalized,
        isMapView,
        mapHcps,
        setPinnedStaff,
        user.username,
        users,
    ]);

    return (
        <div ref={hcpsRef} className="ucr__calendar-hcps">
            {hcpsList.map((hcp) => (
                <HCP
                    key={hcp.userId}
                    hcp={hcp}
                    pinnedStatuses={pinnedStatusesObject}
                    staffAlerts={staffAlerts}
                    numberAllHcpsPos={numberAllHcpsPos}
                    updateAllHcpsPos={updateAllHcpsPos}
                    mapViewBreakDown={
                        isMapView && hcpsBreakdownInfo ? hcpsBreakdownInfo[hcp.userId] : undefined
                    }
                />
            ))}
        </div>
    );
};

export default observer(HCPs);
