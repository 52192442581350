/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import cn from 'classnames';
import Select from 'react-select';

import { AsyncSelect } from '../../../v2/components/AsyncSelect';
import { FormGroup } from '../../../v2/form';

const Filters = (props: any) => {
    const [activeStatuses, setActiveStatuses] = useState<any[]>([
        { label: 'All', value: undefined },
    ]);

    const {
        absenceTypes,
        approvalStatuses,
        filter,
        getPersonRecords,
        onChange,
        personId,
        recordStatuses,
        show,
    } = props;

    useEffect(() => {
        const result = [...activeStatuses] as any[];
        if (recordStatuses.find((r: any) => r.value === 'true')) {
            result.push({ label: 'Archived', value: true });
        }
        if (recordStatuses.find((r: any) => r.value === 'false')) {
            result.push({ label: 'Active', value: false });
        }
        setActiveStatuses(result);
    }, [recordStatuses]);

    return (
        <div className="absences-header-filters">
            <div
                className={cn('absences-header-filtersContainer', {
                    'absences-header-filtersContainer--show': show,
                    'absences-header-filtersContainer--hide': !show,
                })}
            >
                {!personId && (
                    <FormGroup label="Person" className="absence-filter-select">
                        <AsyncSelect
                            onChange={(val: any) => {
                                onChange({
                                    ...filter,
                                    persons: val.length === 0 ? null : val,
                                });
                            }}
                            onLoadOptions={async (
                                pageSize: number,
                                query: string | null,
                                lastItem: string | null,
                            ) => {
                                const persons = await getPersonRecords(pageSize, query, lastItem);
                                return persons;
                            }}
                            values={filter.persons || []}
                        />
                    </FormGroup>
                )}
                <FormGroup label="Absence type" className="absence-filter-select">
                    <Select
                        options={absenceTypes}
                        isMulti
                        onChange={(val) => {
                            onChange({
                                ...filter,
                                absenceTypes: val.length === 0 ? null : val,
                            });
                        }}
                        value={filter.absenceTypes || []}
                    />
                </FormGroup>
                <FormGroup label="Approval status" className="absence-filter-select">
                    <Select
                        options={approvalStatuses}
                        isMulti
                        onChange={(val) => {
                            onChange({
                                ...filter,
                                approvalStatuses: val.length === 0 ? null : val,
                            });
                        }}
                        value={filter.approvalStatuses || []}
                    />
                </FormGroup>
                <FormGroup label="Record status" className="absence-filter-select">
                    <Select
                        options={activeStatuses}
                        onChange={(val) => {
                            onChange({
                                ...filter,
                                isArchived: val,
                            });
                        }}
                        value={filter.isArchived || []}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default Filters;
