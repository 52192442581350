import { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import useStores from '../../../../hook/useStores';

import {
    CollapseAndExpandButtons,
    HCPs,
    Timeline,
    TimeGrid,
    Container,
    DragLayer,
    CurrentTime,
} from './calendar/';
import { DragContainers } from '../../../../stores/UCRStore';
import Empty from './calendar/Empty';

interface IProps {}

const Calendar: FC<IProps> = () => {
    const calendarRef = useRef<HTMLDivElement>(null);
    const [isToday, setIsToday] = useState(false);

    const {
        RootStore: {
            ucrStore: {
                cellWidth,
                hcpsWidth,
                hourStart,
                hourEnd,
                hourScroll,
                dragContainer,
                setCalendarEl,
                selectedDate,
                hcps,
            },
            usersStore: { loaded: usersLoaded },
        },
    } = useStores() as { RootStore: any };

    useEffect(() => {
        const startDate = moment(selectedDate).set({
            hours: hourStart,
            minutes: 0,
            seconds: 0,
        });
        const endDate = moment(selectedDate).set({
            hours: hourEnd,
            minutes: 0,
            seconds: 0,
        });

        setIsToday(moment().isBetween(startDate, endDate));
    }, [hourEnd, hourStart, selectedDate]);

    useEffect(() => {
        if (!calendarRef.current) return;

        calendarRef.current.scrollLeft = (hourScroll - hourStart) * cellWidth;

        setCalendarEl(calendarRef.current);
        // We need to track the changes of current which useEffect seems to think is not a dependency
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarRef.current, cellWidth, hourStart, hourScroll, setCalendarEl]);

    if (!usersLoaded) {
        return null;
    }

    if (hcps.length === 0) {
        return <Empty />;
    }

    return (
        <section className="ucr__calendar">
            <div ref={calendarRef} className="ucr__calendar-wrapper">
                <div className="ucr__calendar-content">
                    <div className="ucr__calendar-side" style={{ width: `${hcpsWidth}px` }}>
                        <CollapseAndExpandButtons />
                        <HCPs />
                    </div>
                    <div className="ucr__calendar-main">
                        <Timeline />
                        <TimeGrid />
                        <Container isSnapToGrid={true} />
                        <DragLayer isSnapToGrid={dragContainer === DragContainers.CALENDAR} />
                        {isToday && <CurrentTime />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default observer(Calendar);
