import * as Yup from 'yup';
import moment from 'moment';

const isSameOrBefore = (startTime, endTime) => {
    const start = moment(startTime, 'HH:mm');
    let end = moment(endTime, 'HH:mm');
    if (start.hours() === end.hours() && start.minutes() === end.minutes() && end.hours() !== 0)
        return false;

    if (end.minutes() === end.hours() && end.hours() === 0) {
        end = end.add(1, 'days');
    }

    return start.isSameOrBefore(end);
};

export const validationSchema = Yup.object({
    name: Yup.string()
        .trim('The contact name cannot include leading and trailing spaces')
        .required('This field is required.')
        .max(100, 'Max 100 symbols'),
    employeeRoleId: Yup.string().required('This field is required.'),
    generalPayRates: Yup.object({
        code: Yup.string().required().max(10, 'Max 10 symbols'),
        rate: Yup.number().required().min(0, 'This field is required.'),
    }),
    customPayRates: Yup.array().of(
        Yup.object({
            code: Yup.string().required('This field is required.').max(10, 'Max 10 symbols'),
            rate: Yup.number().required().min(0, 'This field is required.'),
            startTime: Yup.string().required('This field is required.'),
            endTime: Yup.string()
                .required('This field is required.')
                // eslint-disable-next-line no-template-curly-in-string
                .test('startTime_before_endTime', '${path}', (value, context) => {
                    return isSameOrBefore(context.parent.startTime, value);
                }),
        }),
    ),
});
