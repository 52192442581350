import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import Error from '../../forms/Error';
import PayRatesSummaryGeneral from './PayRatesSummaryGeneral';
import PayRatesSummaryCustom from './PayRatesSummaryCustom';
import CustomAsyncSelect from '../../../common/CustomAsyncSelect';

const PayRatesSummary = ({
    editOrCreateMode,
    isCreate,
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
    rolesStore,
    agenciesStore,
}: any) => {
    return (
        <>
            <section className="payRates-info">
                <div className="payRates-info__block">
                    <h3 className="payRates-info__title">Name *</h3>
                    <div className="payRates-info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="name">
                                <InputGroup
                                    disabled={!isCreate}
                                    id="name"
                                    name="name"
                                    onChange={handleChange}
                                    value={values.name}
                                    maxLength={100}
                                    large
                                />
                                {touched.name && <Error errors={[errors.name]} />}
                            </FormGroup>
                        ) : (
                            values.name
                        )}
                    </div>
                    <h3 className="payRates-info__title">Role *</h3>
                    <div className="payRates-info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="role">
                                <CustomAsyncSelect
                                    isDisabled={!isCreate}
                                    onLoadOptions={rolesStore?.getRolesRecords}
                                    values={values?.employeeRole}
                                    onChange={(e) => {
                                        setFieldValue('employeeRoleId', e && e.value);
                                    }}
                                    placeholder={'Select...'}
                                />
                                {touched.employeeRoleId && (
                                    <Error errors={[errors.employeeRoleId]} />
                                )}
                            </FormGroup>
                        ) : (
                            values.employeeRole?.name
                        )}
                    </div>
                    <h3 className="payRates-info__title">Agency</h3>
                    <div className="payRates-info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="agency">
                                <CustomAsyncSelect
                                    isDisabled={!isCreate}
                                    onLoadOptions={agenciesStore?.getAgenciesRecords}
                                    values={values?.agency}
                                    onChange={(e) => {
                                        setFieldValue('agencyId', e && e.value);
                                    }}
                                    placeholder={
                                        'Select an agency if this pay rate relates to agency staff, or leave blank for all others'
                                    }
                                    emptyOption
                                />
                                {touched.agencyId && <Error errors={[errors.agencyId]} />}
                            </FormGroup>
                        ) : (
                            values.agency?.name
                        )}
                    </div>
                </div>

                <PayRatesSummaryGeneral
                    editOrCreateMode={editOrCreateMode}
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                />

                <PayRatesSummaryCustom
                    editOrCreateMode={editOrCreateMode}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                />
            </section>
        </>
    );
};

export default PayRatesSummary;
