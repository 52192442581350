import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import { FormGroup, InputGroup, Button, Alert, Intent, Checkbox } from '@blueprintjs/core';
import Select from 'react-select';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';

import Error from '../../forms/Error';
import { getFieldsDefaultState } from './formFields';
import { formatPractices } from '../../helpers/formatData';
import Loader from '../../helpers/Loader';
import AppToaster from '../../helpers/Toaster';
import { emptyErrorByType, getAPIReadyUserData, regexFieldValidation } from './formFormats';
import Warning from '../../forms/Warning';
import PhoneInput from 'react-phone-input-2';
import { formatPhoneNumber } from '../../helpers/FormatPhoneNumber';

const AddUser = inject('RootStore')(
    observer(
        class AddUser extends Component {
            state = {
                isLoading: false,
                user: {},
                fields: getFieldsDefaultState().fields,
                validation: getFieldsDefaultState().validation,
                errors: getFieldsDefaultState().errors,
                formValid: null,
                isOpenConfirm: false,
                warnings: [],
            };

            filterPractices = (inputValue) =>
                formatPractices(this.props.RootStore.lovsStore.practice).filter((practice) =>
                    practice.label.toLowerCase().includes(inputValue.toLowerCase()),
                );

            loadOptions = (inputValue) => {
                if (inputValue && inputValue.length >= 3) {
                    return new Promise((resolve) => {
                        resolve(this.filterPractices(inputValue));
                    });
                }
            };

            handleToggleLoading = (status) => {
                this.setState({
                    isLoading: status,
                });
            };

            handleInputChange = (value, field) => {
                // React state doesn't handle updating multi-level state. So below is an accepted technique, to do that
                let fields = { ...this.state.fields };

                fields[field] = value;
                this.setState(
                    {
                        fields,
                    },
                    () => {
                        if (field === 'email' || field === 'phoneNumber') {
                            this.checkWarnings();
                        }
                    },
                );

                // Prevent showing errors until the user has tried to submit
                // to avoid all fields showing red the first time they type something
                // But then start tracking their changes in order to remove errors as they fix them
                if (this.state.formValid !== null) {
                    setTimeout(() => {
                        this.validateFields();
                    });
                }
            };

            validateFields = () => {
                let errorsList = {},
                    errorsCount = 0;

                // Add an error message for a given field
                const setError = (field, errorMessage, errorsList) => {
                    errorsList[field].push(errorMessage);
                    return errorsList;
                };

                // For each field, if it is REQUIRED, generate an error message
                _.forIn(this.state.errors, (value, field) => {
                    errorsList[field] = [];

                    const fieldValue =
                        field === 'phoneNumber'
                            ? this.state.fields[field].substr(2)
                            : this.state.fields[field];

                    const {
                        required,
                        type,
                        regex: { rule: regex } = {},
                        validationMessage,
                        regexMessage,
                    } = this.state.validation[field];

                    let errorMessage;

                    if (regex) {
                        errorMessage = !regexFieldValidation(fieldValue, regex) ? regexMessage : '';
                    }

                    if (required) {
                        errorMessage = emptyErrorByType(fieldValue, type) ? validationMessage : '';
                    }

                    if (field === 'email' && fieldValue && errorMessage === '') {
                        const emailDomain = fieldValue.split('@')[1]?.toLowerCase();
                        if (
                            this.props.RootStore.configStore.permittedDomains?.length > 0 &&
                            !this.props.RootStore.configStore.permittedDomains.includes(emailDomain)
                        ) {
                            errorMessage =
                                'Email domain is not permitted, please speak with an administrator';
                        }
                    }

                    if (errorMessage && errorMessage !== '') {
                        errorsList = setError(field, errorMessage, errorsList);
                        errorsCount = errorsCount + 1;
                    }
                });

                const formValid = errorsCount === 0;

                this.setState({
                    formValid,
                    errors: errorsList,
                });

                // setState (above) doesn't update state synchronously
                // so the handlSubmit is expecting to know if the form is valid,
                // but when this function finishes state hasn't updated yet and the form
                // is still invalid. Returning formValid let's handleSubmit to continue immediatelly.
                // However, this.state.formValid is used for showing errors too, so it is still needed.
                return formValid;
            };

            checkWarnings = () => {
                const { users } = this.props.RootStore.usersStore;
                const warnings = [];

                if (this.state.fields.email) {
                    const user = users.find(
                        (u) => u.email?.toLowerCase() === this.state.fields.email.toLowerCase(),
                    );
                    if (user) {
                        warnings.push(
                            'This email address is already associated with a Doc Abode account. If you need to modify the roles of an existing user, please contact Doc Abode customer support.',
                        );
                    }
                }

                if (this.state.fields.phoneNumber) {
                    const user = users.find(
                        (u) =>
                            u.phoneNumber === this.state.fields.phoneNumber ||
                            u.phoneNumber === `0${this.state.fields.phoneNumber.substr(2)}` ||
                            u.phoneNumber === `+${this.state.fields.phoneNumber}`,
                    );
                    if (user) {
                        warnings.push(
                            'This phone number is already associated with a Doc Abode account. If you need to modify the roles of an existing user, please contact Doc Abode customer support.',
                        );
                    }
                }

                this.setState({ warnings });
            };

            handleSubmit = () => {
                const formValid = this.validateFields();

                // Validate the form
                if (!formValid) {
                    return;
                }

                this.setState({ isOpenConfirm: true });
            };

            handleConfirm = async () => {
                this.setState({ isOpenConfirm: false });
                this.handleToggleLoading(true);
                const userData = getAPIReadyUserData(this.state);
                const apiResponseStatus = await this.props.RootStore.usersStore.addUser(userData);

                if (apiResponseStatus.response.status === 200) {
                    AppToaster.show({
                        message: 'User was added successfully!',
                        intent: 'success',
                    });
                    this.setState({
                        fields: getFieldsDefaultState().fields,
                    });
                } else if (apiResponseStatus.response.status === 409) {
                    AppToaster.show({
                        message: 'User already exists for provided email address or phone number.',
                        intent: 'danger',
                    });
                } else {
                    AppToaster.show({
                        message:
                            'User was not added. Please check the details you have added and try again.',
                        intent: 'danger',
                    });
                }
                this.handleToggleLoading(false);
            };

            handleCancel = () => {
                this.setState({ isOpenConfirm: false });
            };

            handleRolesSelect = (checked, role) => {
                const roles = checked
                    ? [...this.state.fields.roles, role]
                    : this.state.fields.roles.filter((r) => r !== role);
                this.setState({
                    fields: {
                        ...this.state.fields,
                        roles,
                    },
                });
                if (this.state.formValid !== null) {
                    setTimeout(() => {
                        this.validateFields();
                    });
                }
            };

            render() {
                const lovsStore = this.props.RootStore.lovsStore;
                const {
                    confirmRegisterHCPText,
                    confirmRegisterHCPChecklist,
                    isFeatureEnabled,
                    bands,
                } = this.props.RootStore.configStore;
                const { isComplianceOfficer } = this.props.RootStore.userStore;
                if (!isFeatureEnabled('addUser') || isComplianceOfficer) {
                    return <Redirect to="/page-not-found" />;
                }

                return (
                    <div className="page">
                        {this.state.isLoading && <Loader />}
                        <h1 className="h1">Add User</h1>
                        <Row>
                            <Col md={6}>
                                {/* FIRST NAME */}
                                <FormGroup
                                    label="First Name"
                                    labelFor="firstName"
                                    labelInfo="(required)"
                                >
                                    <InputGroup
                                        id="firstName"
                                        large
                                        onChange={(e) =>
                                            this.handleInputChange(e.target.value, e.target.id)
                                        }
                                        value={this.state.fields.firstName}
                                    />
                                    <Error errors={this.state.errors.firstName} />
                                </FormGroup>

                                {/* LAST NAME */}
                                <FormGroup
                                    label="Last Name"
                                    labelFor="lastName"
                                    labelInfo="(required)"
                                >
                                    <InputGroup
                                        id="lastName"
                                        large
                                        onChange={(e) =>
                                            this.handleInputChange(e.target.value, e.target.id)
                                        }
                                        value={this.state.fields.lastName}
                                    />
                                    <Error errors={this.state.errors.lastName} />
                                </FormGroup>

                                {/* EMAIL */}
                                <FormGroup
                                    label="Email Address"
                                    labelFor="email"
                                    labelInfo="(required)"
                                >
                                    <InputGroup
                                        id="email"
                                        large
                                        onChange={(e) =>
                                            this.handleInputChange(
                                                e.target.value.trim(),
                                                e.target.id,
                                            )
                                        }
                                        value={this.state.fields.email}
                                    />
                                    <Error errors={this.state.errors.email} />
                                </FormGroup>

                                {/* PHONE NUMBER */}
                                <FormGroup label="Mobile Phone Number" labelFor="phoneNumber">
                                    <PhoneInput
                                        inputProps={{
                                            id: 'phoneNumber',
                                            'data-test': 'phoneNumber',
                                        }}
                                        value={
                                            this.state.fields.phoneNumber
                                                ? this.state.fields.phoneNumber
                                                : '44'
                                        }
                                        country="gb"
                                        onlyCountries={['gb']}
                                        autoFormat={false}
                                        onChange={(value) =>
                                            this.handleInputChange(
                                                formatPhoneNumber(value),
                                                'phoneNumber',
                                            )
                                        }
                                        inputClass="v2__form-phone-input"
                                        dropdownClass="v2__form-phone-drop-down"
                                        specialLabel=""
                                        countryCodeEditable={false}
                                    />
                                    <Error errors={this.state.errors.phoneNumber} />
                                </FormGroup>

                                {/* GENDER */}
                                <FormGroup label="Gender" labelFor="gender" labelInfo="(required)">
                                    <Select
                                        inputId="gender"
                                        options={lovsStore.gender}
                                        onChange={(e) => this.handleInputChange(e, 'gender')}
                                        value={this.state.fields.gender}
                                    />
                                    <Error errors={this.state.errors.gender} />
                                </FormGroup>
                                {/* BAND */}
                                <FormGroup label="Band" labelFor="band">
                                    <Select
                                        inputId="band"
                                        options={bands
                                            ?.slice()
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        menuPlacement="top"
                                        onChange={(e) => this.handleInputChange(e, 'band')}
                                        isClearable={
                                            this.state.fields.band !== null &&
                                            Object.keys(this.state.fields.band).length === 0
                                                ? false
                                                : true
                                        }
                                        value={this.state.fields.band}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                {/* ROLE */}
                                <FormGroup label="Role(s)">
                                    <Checkbox
                                        label="HCP"
                                        checked={this.state.fields.roles.includes('hcp')}
                                        onChange={(e) =>
                                            this.handleRolesSelect(e.target.checked, 'hcp')
                                        }
                                        inline
                                        data-test="role-hcp"
                                    />
                                    <Checkbox
                                        label="Controller"
                                        checked={this.state.fields.roles.includes('controller')}
                                        onChange={(e) =>
                                            this.handleRolesSelect(e.target.checked, 'controller')
                                        }
                                        inline
                                        data-test="role-controller"
                                    />
                                    <Checkbox
                                        label="Admin"
                                        checked={this.state.fields.roles.includes('org_admin')}
                                        onChange={(e) =>
                                            this.handleRolesSelect(e.target.checked, 'org_admin')
                                        }
                                        inline
                                        data-test="role-admin"
                                    />
                                    <Error errors={this.state.errors.roles} />
                                </FormGroup>

                                {/* HCP TYPE */}
                                <FormGroup
                                    label="HCP Type(s)"
                                    labelFor="hcpTypes"
                                    labelInfo="(required)"
                                >
                                    <Select
                                        id="hcpTypesSelect"
                                        inputId="hcpTypes"
                                        options={lovsStore.hcpType
                                            ?.slice()
                                            .sort((a, b) => a.label.localeCompare(b.label))}
                                        onChange={(e) => this.handleInputChange(e, 'hcpTypes')}
                                        value={this.state.fields.hcpTypes}
                                        isMulti
                                        className="react-select"
                                    />
                                    <Error errors={this.state.errors.hcpTypes} />
                                </FormGroup>

                                {/* LANGUAGE */}
                                <FormGroup label="Languages" labelFor="languages">
                                    <Select
                                        inputId="languages"
                                        options={lovsStore.languagesSpoken}
                                        onChange={(e) => this.handleInputChange(e, 'languages')}
                                        value={this.state.fields.languages}
                                        isMulti
                                        className="react-select"
                                    />
                                    <Error errors={this.state.errors.languages} />
                                </FormGroup>

                                {/* SPECIALITY */}
                                <FormGroup label="Specialities" labelFor="specialities">
                                    <Select
                                        inputId="specialities"
                                        options={lovsStore.speciality}
                                        onChange={(e) => this.handleInputChange(e, 'specialities')}
                                        value={this.state.fields.specialities}
                                        isMulti
                                        className="react-select"
                                    />
                                    <Error errors={this.state.errors.specialities} />
                                </FormGroup>

                                {/* PRACTICES */}
                                <FormGroup label="Practice(s)" labelFor="practices">
                                    <AsyncSelect
                                        inputId="practices"
                                        loadOptions={this.loadOptions}
                                        placeholder={
                                            'Start typing the practice name to see results'
                                        }
                                        noOptionsMessage={(val) =>
                                            val.inputValue
                                                ? 'No Options'
                                                : 'Start typing the practice name to see results'
                                        }
                                        value={this.state.fields.practice}
                                        onChange={(e) => this.handleInputChange(e, 'practices')}
                                        isMulti
                                        className="react-select"
                                    />
                                    <Error errors={this.state.errors.practices} />
                                </FormGroup>
                            </Col>
                            <Warning warnings={this.state.warnings} />
                        </Row>

                        {/* SUBMIT BUTTON */}
                        <footer className="form-footer">
                            <Button intent="success" large onClick={this.handleSubmit} icon="tick">
                                Add user
                            </Button>
                        </footer>

                        <Alert
                            isOpen={this.state.isOpenConfirm}
                            onConfirm={this.handleConfirm}
                            onCancel={this.handleCancel}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent={Intent.PRIMARY}
                            className="dialog--wide"
                        >
                            <h2 className="h2">Important Note</h2>
                            <p>{confirmRegisterHCPText}</p>
                            {confirmRegisterHCPChecklist && (
                                <ul className="bp5-list">
                                    {confirmRegisterHCPChecklist.map((item) => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </Alert>
                    </div>
                );
            }
        },
    ),
);

export default AddUser;
