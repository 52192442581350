import { FC } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../../../hook/useStores';
import { TagInputComponent } from '../../../../v2/form/TagInput/TagInput';
import { CommonControls } from '../CommonControls';
import { ActionsButtonActions } from '../../components/calendar/ActionsDropDown';

export const TimelineViewControls: FC<{ refreshJobs(): void }> = observer(({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: { nameFilters, setNameFilters },
        },
    } = useStores() as { RootStore: any };

    const actions = [
        ActionsButtonActions.EXPORT_JOBS,
        ActionsButtonActions.EXPORT_SCHEDULES,
        ActionsButtonActions.IMPORT_SCHEDULES,
    ];

    const onChangeInput = (name: string, values: string[]): void => {
        setNameFilters(name, values);
    };

    return (
        <>
            <CommonControls
                actions={actions}
                refresh={refreshJobs}
                additionalLeft={
                    <>
                        <TagInputComponent
                            name="patientName"
                            className="ucr__filters-filter-input"
                            placeholder="Patient"
                            values={nameFilters.patientName}
                            onChange={(values) => onChangeInput('patientName', values as string[])}
                            inputProps={{ 'aria-label': 'patient filter' }}
                        />
                        <TagInputComponent
                            name="staffName"
                            className="ucr__filters-filter-input"
                            placeholder="Staff"
                            values={nameFilters.staffName}
                            onChange={(values) => onChangeInput('staffName', values as string[])}
                            inputProps={{ 'aria-label': 'staff filter' }}
                        />
                    </>
                }
            />
        </>
    );
});
