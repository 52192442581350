// import { camelCaseString, formatParserAPIDate } from '../helpers/formatData';
import { parseString, processors } from 'xml2js';

const XmlParser = (patientDetails) => {
    if (patientDetails) {
        let parsedXml;
        parseString(
            patientDetails,
            { tagNameProcessors: [processors.firstCharLowerCase] },
            function (err, result) {
                parsedXml = result.case;
            },
        );
        return parsedXml;
    } else {
        return {};
    }
};

export default XmlParser;
