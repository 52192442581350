import { Button } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import React from 'react';
import ExitConfirmationDialog from './PersonModalConfirmationDialog';
import ArchivePopUpText from './PersonModalArchivePopUpText';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { CONTRACTOR_IDS } from '../../constants/hrConst';

interface Props {
    values: any;
    setFieldValue: any;
    validateForm: any;
    isSubmitting: boolean;
    toggleIsEdit: () => void;
    isEditing: boolean;
    onCancel: () => void;
    onClose?: () => void;
    onModalExit: () => void;
    onSubmit?: () => void;
    onDelete?: () => void;
    hasDeleteOption?: boolean;
    submitText?: string;
    submitIcon?: IconName;
    inProgressPersonRecordStatus?: string;
    activePersonRecordStatus?: string;
    archivedPersonRecordStatus?: string;
    suspendedPersonRecordStatus?: string;
    hasSuspendedParent?: boolean;
    hideButtons?: boolean;
    employeeFooter?: boolean;
    staffStore?: any;
    peopleStore?: any;
    departmentAssignmentsStore?: any;
    updateEmployeeData: () => void;
    isValid?: boolean;
}

const CommonModalFooter = ({
    isValid,
    isSubmitting,
    toggleIsEdit,
    isEditing,
    onCancel,
    onModalExit,
    onDelete,
    onSubmit,
    values,
    validateForm,
    setFieldValue,
    inProgressPersonRecordStatus,
    activePersonRecordStatus,
    archivedPersonRecordStatus,
    suspendedPersonRecordStatus,
    submitIcon = 'floppy-disk',
    hasSuspendedParent = false,
    hideButtons = false,
    employeeFooter = false,
    staffStore,
    peopleStore,
    departmentAssignmentsStore,
    updateEmployeeData,
}: Props) => {
    const { submitForm } = useFormikContext();

    // Statuses
    const newPersonRecordStatus =
        values.statusId !== inProgressPersonRecordStatus &&
        values.statusId !== archivedPersonRecordStatus &&
        values.statusId !== activePersonRecordStatus &&
        values.statusId !== suspendedPersonRecordStatus;

    // Confirmation modals states
    const [inProgressDialogOpen, setInProgressDialogOpen] = React.useState(false);
    const [activeDialogOpen, setActiveDialogOpen] = React.useState(false);
    const [activeAdvancedDialogOpen, setActiveAdvancedDialogOpen] = React.useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [archivedDialogOpen, setArchivedDialogOpen] = React.useState(false);
    const [suspendedDialogOpen, setSuspendedDialogOpen] = React.useState(false);
    const [personEndDateNotValid, setPersonEndDateNotValid] = React.useState(false);
    const [
        staffRecordsWithWrongEngagementDates,
        setStaffRecordsWithWrongEngagementDates,
    ] = React.useState([] as any);

    // Staff record endedWorkOn dependency
    const hasEndedWorkOn = _.has(values, 'endedWorkOn');
    const hasEndedWorkOnNotFilled = hasEndedWorkOn && !values.endedWorkOn;
    const endedWorkOnBiggerThanToday = hasEndedWorkOn && values.endedWorkOn > moment().toDate();
    const datesOfEngagementNotSetOrAreInFuture =
        hasEndedWorkOnNotFilled || endedWorkOnBiggerThanToday;

    // Button actions
    const newPersonStatusSave = () => {
        submitForm();
        setInProgressDialogOpen(false);
        setActiveAdvancedDialogOpen(false);
        setArchivedDialogOpen(false);
        setSuspendedDialogOpen(false);
    };
    const newPersonStatusToInProgressSubmit = () => {
        submitForm();
        isValid && setFieldValue('statusId', inProgressPersonRecordStatus);
        setInProgressDialogOpen(false);
    };
    const newPersonStatusToActiveSubmit = () => {
        submitForm();
        isValid && setFieldValue('statusId', activePersonRecordStatus);
        setActiveAdvancedDialogOpen(false);
    };

    const archiveEmployeeNoValidation = async () => {
        if (staffStore && updateEmployeeData) {
            const { id } = values;
            await staffStore.archiveEmployeeRecords([id]);
            setArchivedDialogOpen(false);
            updateEmployeeData();
        }
    };

    const archivePersonNoValidation = async () => {
        if (peopleStore) {
            const { id } = values;
            await peopleStore.archivePersonRecords([id]);
            setArchivedDialogOpen(false);
            updateEmployeeData && updateEmployeeData();
        }
    };

    const newPersonStatusToSuspendedSubmit = () => {
        submitForm();
        isValid && setFieldValue('statusId', suspendedPersonRecordStatus);
        setSuspendedDialogOpen(false);
    };

    const engagementDatesOfDocumentsNotSetAccordingly = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const filtererDepartmentAssignments = departmentAssignmentsStore.gridDepartmentAssignments.filter(
            (item: any) =>
                !item.endDate || (item.endDate && item.endDate.setHours(0, 0, 0, 0) > currentDate),
        );
        return filtererDepartmentAssignments.length ? true : false;
    };

    const checkStatusOfEngagementsPersonAndStaffRecords = async () => {
        const personId = values.id;
        const validationData = await peopleStore.validatePersonArchivation([personId]);

        if (!validationData.length) {
            setPersonEndDateNotValid(false);
            setStaffRecordsWithWrongEngagementDates([]);
            return;
        }

        const hasEmploymentEndDate = _.has(values, 'employmentEndDate');
        const hasEmploymentEndDateNotFilled = hasEmploymentEndDate && !values.employmentEndDate;
        const employmentEndDateBiggerThanToday =
            hasEmploymentEndDate && values.employmentEndDate > moment().toDate();
        let employmentEndDateNotValid =
            hasEmploymentEndDateNotFilled || employmentEndDateBiggerThanToday;
        let listOfNotValidStaffRecords = [] as any;

        if (validationData[0].employees.length) {
            // Check if we have sessional records
            const staffIsSessional = CONTRACTOR_IDS.includes(
                validationData[0].employees[0].contractTypeId,
            );
            // validate staff records
            validationData[0].employees.forEach((staff: any) => {
                let staffRecordNotValid =
                    staff.endedWorkOn === null ||
                    moment(staff.endedWorkOn).toDate() > moment().toDate();

                // check for inner departments only in case if the staff record has a valid date and we have departments
                if (!staffRecordNotValid && staff.departments.length) {
                    staffRecordNotValid = staff.departments.some(
                        (dep: any) =>
                            dep.endDateOfAssignment === null ||
                            moment(dep.endDateOfAssignment).toDate() > moment().toDate(),
                    );
                }
                // if record not valid add it to the overall list
                if (staffRecordNotValid) {
                    listOfNotValidStaffRecords.push({
                        id: staff.id,
                        staffType: staffIsSessional ? 'sessional-records' : 'employed-records',
                        role: staff.roles.length ? staff.roles[0]?.employeeRole?.name : '-',
                    });
                }
            });
            // Check if we have sessional records and the issue is in records themselves in that case we don't check Person employment date
            if (staffIsSessional && listOfNotValidStaffRecords.length) {
                employmentEndDateNotValid = false;
            }
        }
        setPersonEndDateNotValid(employmentEndDateNotValid);
        setStaffRecordsWithWrongEngagementDates(listOfNotValidStaffRecords);
    };

    if (isEditing) {
        return (
            <>
                {newPersonRecordStatus && (
                    <>
                        <Button
                            intent="success"
                            onClick={async () => {
                                await validateForm();
                                isValid && setInProgressDialogOpen(true);
                            }}
                            large
                            icon={submitIcon}
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Save as in progress
                        </Button>
                        <Button
                            intent="primary"
                            onClick={async () => {
                                await validateForm();
                                isValid && setActiveDialogOpen(true);
                            }}
                            large
                            icon="tick"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Activate
                        </Button>
                        <Button large onClick={onCancel} icon="cross" outlined>
                            Cancel
                        </Button>
                    </>
                )}
                {values.statusId === inProgressPersonRecordStatus && !hideButtons && (
                    <>
                        <Button
                            intent="success"
                            type="submit"
                            large
                            icon={submitIcon}
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            intent="primary"
                            onClick={async () => {
                                await validateForm();
                                isValid && setActiveAdvancedDialogOpen(true);
                            }}
                            large
                            icon="tick"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Activate
                        </Button>
                        <Button
                            intent="danger"
                            onClick={async () => {
                                await validateForm();
                                isValid && setDeleteDialogOpen(true);
                            }}
                            large
                            icon="trash"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Delete
                        </Button>
                        <Button
                            intent="warning"
                            onClick={async () => {
                                if (employeeFooter) {
                                    setArchivedDialogOpen(true);
                                } else {
                                    await validateForm();
                                    isValid && setArchivedDialogOpen(true);
                                }
                            }}
                            large
                            icon="archive"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Archive
                        </Button>
                        <Button large onClick={onCancel} icon="cross" outlined>
                            Cancel
                        </Button>
                    </>
                )}
                {values.statusId === activePersonRecordStatus && !hideButtons && (
                    <>
                        <Button
                            intent="success"
                            type="submit"
                            large
                            icon={submitIcon}
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Save
                        </Button>
                        <Button
                            intent="primary"
                            onClick={async () => {
                                await validateForm();
                                isValid && setSuspendedDialogOpen(true);
                            }}
                            large
                            icon="arrow-up"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Suspend
                        </Button>
                        <Button
                            intent="warning"
                            onClick={async () => {
                                if (employeeFooter) {
                                    setArchivedDialogOpen(true);
                                } else {
                                    await checkStatusOfEngagementsPersonAndStaffRecords();
                                    setArchivedDialogOpen(true);
                                }
                            }}
                            large
                            icon="archive"
                            loading={isSubmitting}
                            outlined
                            onSubmit={onSubmit}
                        >
                            Archive
                        </Button>
                        <Button large onClick={onCancel} icon="cross" outlined>
                            Cancel
                        </Button>
                    </>
                )}
                {values.statusId === suspendedPersonRecordStatus && !hideButtons && (
                    <>
                        <Button
                            intent="success"
                            type="submit"
                            large
                            icon={submitIcon}
                            loading={isSubmitting}
                            outlined
                        >
                            Save
                        </Button>
                        {!hasSuspendedParent && (
                            <>
                                <Button
                                    intent="primary"
                                    onClick={async () => {
                                        await validateForm();
                                        isValid && setActiveAdvancedDialogOpen(true);
                                    }}
                                    large
                                    icon="tick"
                                    loading={isSubmitting}
                                    outlined
                                >
                                    Activate
                                </Button>
                                <Button
                                    intent="warning"
                                    onClick={async () => {
                                        if (employeeFooter) {
                                            setArchivedDialogOpen(true);
                                        } else {
                                            await validateForm();
                                            isValid && setArchivedDialogOpen(true);
                                        }
                                    }}
                                    large
                                    icon="archive"
                                    loading={isSubmitting}
                                    outlined
                                >
                                    Archive
                                </Button>
                            </>
                        )}

                        <Button large onClick={onCancel} icon="cross" outlined>
                            Cancel
                        </Button>
                    </>
                )}
                {values.statusId === archivedPersonRecordStatus && !hideButtons && (
                    <>
                        <Button
                            intent="success"
                            large
                            type="submit"
                            icon={submitIcon}
                            loading={isSubmitting}
                            outlined
                        >
                            Save
                        </Button>
                        <Button large onClick={onCancel} icon="cross" outlined>
                            Cancel
                        </Button>
                    </>
                )}
                {inProgressDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setInProgressDialogOpen(false)}
                        onSubmit={newPersonStatusToInProgressSubmit}
                        isOpen={isValid && inProgressDialogOpen}
                        bodyText="Are you sure you want to save the record as “In progress”?"
                    />
                )}

                {activeDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setActiveDialogOpen(false)}
                        onSubmit={newPersonStatusToActiveSubmit}
                        isOpen={isValid && activeDialogOpen}
                        submitText={'Activate'}
                        bodyText="Are you sure you want to activate the record?"
                    />
                )}
                {activeAdvancedDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setActiveAdvancedDialogOpen(false)}
                        onSubmit={newPersonStatusToActiveSubmit}
                        onSave={newPersonStatusSave}
                        onExit={onModalExit}
                        hasSecondaryAction={true}
                        hasTertiaryAction={true}
                        isOpen={isValid && activeAdvancedDialogOpen}
                        submitText={'Yes'}
                        bodyText="Are you sure you want to activate the record?"
                    />
                )}

                {deleteDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setDeleteDialogOpen(false)}
                        onSubmit={onDelete}
                        onSave={newPersonStatusSave}
                        hasSecondaryAction={true}
                        hasTertiaryAction={true}
                        onExit={onModalExit}
                        isOpen={isValid && deleteDialogOpen}
                        submitText={'Yes'}
                        bodyText="Are you sure you want to delete the record?"
                    />
                )}
                {archivedDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setArchivedDialogOpen(false)}
                        onSubmit={
                            employeeFooter ? archiveEmployeeNoValidation : archivePersonNoValidation
                        }
                        onExit={onModalExit}
                        onSave={newPersonStatusSave}
                        hasSecondaryAction={true}
                        hasTertiaryAction={true}
                        isOpen={archivedDialogOpen}
                        submitText={'Yes'}
                        bodyText={ArchivePopUpText(
                            employeeFooter,
                            datesOfEngagementNotSetOrAreInFuture,
                            personEndDateNotValid,
                            staffRecordsWithWrongEngagementDates,
                            engagementDatesOfDocumentsNotSetAccordingly,
                        )}
                    />
                )}
                {suspendedDialogOpen && (
                    <ExitConfirmationDialog
                        onCancel={() => setSuspendedDialogOpen(false)}
                        onExit={onModalExit}
                        onSubmit={newPersonStatusToSuspendedSubmit}
                        onSave={newPersonStatusSave}
                        hasSecondaryAction={true}
                        hasTertiaryAction={true}
                        isOpen={isValid && suspendedDialogOpen}
                        submitText={'Yes'}
                        bodyText="Are you sure you want to suspend the record?"
                    />
                )}
            </>
        );
    }

    return (
        <>
            {values.statusId !== archivedPersonRecordStatus && !hideButtons && (
                <Button
                    large
                    onClick={toggleIsEdit}
                    icon="edit"
                    intent="primary"
                    disabled={isSubmitting}
                    outlined
                >
                    Edit details
                </Button>
            )}
        </>
    );
};
export default CommonModalFooter;
