import { FC } from 'react';
import classNames from 'classnames';
import { Tag } from '@blueprintjs/core';

import { formatDisplayTime, metresToMiles } from '../../../../../modules/helpers/formatData';
import { statusTags, statusMappings } from '../../../../../../constants/patientsConst';
import { ItineraryItemSummary } from '../ItineraryItemSummary';
import { IInstructionProps } from './ItineraryTypes';

export const Instruction: FC<IInstructionProps> = ({
    instructionType,
    startTime,
    endTime,
    itineraryItem,
    distance,
    hub,
    newItem,
    vaccination,
    openInNewWindow,
    strikeThrough = false,
    jobStatusTag,
    modalRef,
    nameTravelToNext,
}) => {
    const { name: id } = itineraryItem || { name: '' };
    const {
        firstName,
        lastName,
        nhsNumber,
        addressLine1,
        addressLine2,
        town,
        county,
        postCode,
        dateOfBirth,
        notes,
        vaccinationCategory,
        practice,
        odsCode,
    } = vaccination || {};
    let travelToAddress = '';
    if (vaccination) {
        travelToAddress = [addressLine1, addressLine2, town, county, postCode]
            .filter((_) => _)
            .join(', ');
    }
    if (!vaccination && nameTravelToNext) {
        travelToAddress = nameTravelToNext;
    }

    return (
        <tr>
            <td
                className={classNames({
                    'vaccinations__instruction--removed': strikeThrough,
                })}
                data-testid={`instruction-start-time-${id}`}
            >
                {formatDisplayTime(startTime)}
            </td>
            <td
                className={classNames({
                    'vaccinations__instruction--removed': strikeThrough,
                })}
                data-testid={`instruction-end-time-${id}`}
            >
                {formatDisplayTime(endTime)}
            </td>
            <td data-testid={`instruction-text-${id}`}>
                {instructionType === 'LeaveFromStartPoint' ? (
                    <>Leave from {hub?.address || 'start location'}</>
                ) : instructionType === 'TravelBetweenLocations' ? (
                    <>
                        Travel {metresToMiles(distance || 0)} to {travelToAddress}
                    </>
                ) : instructionType === 'Pickup' ? (
                    <span
                        className={classNames('vaccinations__instruction', {
                            'vaccinations__instruction--removed': strikeThrough,
                        })}
                    >
                        Pick up vaccination doses at {id}
                    </span>
                ) : instructionType === 'VisitLocation' ? (
                    vaccination ? (
                        <>
                            <span
                                className={classNames('vaccinations__instruction', {
                                    'vaccinations__instruction--removed': strikeThrough,
                                })}
                            >
                                <span data-testid={`instruction-patient-name-${id}`}>
                                    {newItem && (
                                        <Tag
                                            intent="success"
                                            className="vaccinations__instruction-tag-new"
                                            data-testid={`instruction-tag-new-${id}`}
                                        >
                                            New
                                        </Tag>
                                    )}
                                    <ItineraryItemSummary
                                        firstName={firstName}
                                        lastName={lastName}
                                        nhsNumber={nhsNumber}
                                        dateOfBirth={dateOfBirth}
                                        vaccinationCategory={vaccinationCategory}
                                        practice={practice}
                                        odsCode={odsCode}
                                        id={id}
                                        openInNewWindow={openInNewWindow}
                                        modalRef={modalRef}
                                    />
                                </span>
                                <span
                                    className="vaccinations__instruction-tag"
                                    data-testid={`instruction-patient-status-tag-${id}`}
                                >
                                    <Tag
                                        intent={statusTags[jobStatusTag]}
                                        minimal={!['COMPLETED', 'REMOVED'].includes(jobStatusTag)}
                                    >
                                        {statusMappings[jobStatusTag]}
                                    </Tag>
                                </span>
                            </span>
                            <span className="vaccinations__print-only">{notes}</span>
                        </>
                    ) : (
                        'Loading patient details...'
                    )
                ) : (
                    <>Arrive at {hub?.address || 'end location'}</>
                )}
            </td>
        </tr>
    );
};
