import { gql } from '@apollo/client';

export const GET_PEOPLE_OPTIONS = gql`
    query GetPeopleOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: PersonFilter
        $query: String
    ) {
        getPersons(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    firstName
                    lastName
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_PEOPLE = gql`
    query GetPeople(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: PersonFilter
        $query: String
    ) {
        getPersons(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    status
                    statusId
                    personStatus {
                        name
                    }
                    updatedAt
                    createdAt
                    updatedByPerson {
                        firstName
                        lastName
                    }
                    createdByPerson {
                        firstName
                        lastName
                    }
                    firstName
                    lastName
                    middleName
                    personalEmail
                    dateOfBirth
                    personalPhone
                    homeAddress
                    gender {
                        id
                        name
                    }
                    employees {
                        id
                        statusId
                        roles {
                            employeeRole {
                                name
                            }
                        }
                        departments {
                            isArchived
                            department {
                                name
                            }
                        }
                    }
                    cognitoId
                    cognitoUsername
                    inviteStatus
                    languages {
                        languageId
                    }
                    profilePhoto
                    emergencyContactPhone
                    emergencyContactName
                    homePhone
                    dbsNumber
                    professionalBodyName
                    hasPerformerInfo
                    performerAlignmentId
                    performerRoleId
                    performerProfessionalRegNumber
                    performerStatusId
                    performerDateFirstOnPerformersList
                    performerDateInGPRegister
                    performerTeamId
                    performerCurrentlyInProbationary
                    hasPerformerInfoLastCheckDate
                    hasPerformerInfoCheckedByPerson {
                        id
                        firstName
                        lastName
                    }
                    nationalInsurance
                    payrollReference
                    personInductionChecklists {
                        id
                        isApplicable
                        isChecked
                        inductionChecklistId
                        name
                        createdAt
                        orderNumber
                        doneAt
                        doneBy
                        updatedPerson {
                            firstName
                            lastName
                        }
                    }
                    nationality {
                        id
                        name
                    }
                    ethnicity {
                        id
                        name
                    }
                    genderReassignment
                    religionOrBelief {
                        id
                        name
                    }
                    sexualOrientation {
                        id
                        name
                    }
                    maritalStatus {
                        id
                        name
                    }
                    personDisability {
                        disability {
                            id
                            name
                        }
                    }
                    carer
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const VALIDATE_EMAIL = gql`
    query isPersonEmailUnique($email: String!) {
        isPersonEmailUnique(email: $email)
    }
`;

export const VALIDATE_PHONE = gql`
    query isPersonPhoneUnique($phone: String!) {
        isPersonPhoneUnique(phone: $phone)
    }
`;

export const VALIDATE_DBS = gql`
    query isDBSNumberUnique($dbsNumber: String!) {
        isDBSNumberUnique(dbsNumber: $dbsNumber) {
            isUnique
            person {
                id
                firstName
                middleName
                lastName
            }
        }
    }
`;

export const VALIDATE_NATIONAL_INSURANCE = gql`
    query isNationalInsuranceUnique($nationalInsurance: String!) {
        isNationalInsuranceUnique(nationalInsurance: $nationalInsurance)
    }
`;

export const GET_PERSON = gql`
    query GetPerson($id: String!) {
        getPerson(id: $id) {
            id
            title {
                id
                name
            }
            firstName
            lastName
            middleName
            maidenName
            preferredName
            alternativeNames {
                id
                firstName
                middleName
                lastName
            }
            personalEmail
            dateOfBirth
            personalPhone
            homeAddress
            statusId
            updatedAt
            gender {
                id
                name
            }
            employees {
                id
            }
            cognitoId
            cognitoUsername
            inviteStatus
            languages {
                languageId
            }
            hasPerformerInfoLastCheckDate
            hasPerformerInfoCheckedByPerson {
                id
                firstName
                lastName
            }
            profilePhoto
            emergencyContactPhone
            emergencyContactName
            homePhone
            dbsNumber
            professionalBodyName
            nationalInsurance
            payrollReference
            personInductionChecklists {
                id
                isApplicable
                isChecked
                inductionChecklistId
                name
                createdAt
                orderNumber
                doneAt
                doneBy
                createdAt
                updatedPerson {
                    firstName
                    lastName
                }
            }
            hasPerformerInfo
            performerAlignmentId
            performerRoleId
            performerProfessionalRegNumber
            performerStatusId
            performerDateFirstOnPerformersList
            performerDateInGPRegister
            performerTeamId
            performerCurrentlyInProbationary
            nationality {
                id
                name
            }
            ethnicity {
                id
                name
            }
            genderReassignment
            religionOrBelief {
                id
                name
            }
            sexualOrientation {
                id
                name
            }
            maritalStatus {
                id
                name
            }
            personDisability {
                disability {
                    id
                    name
                }
            }
            carer
            employmentStartDate
            employmentEndDate
            employmentPeriodErrors
        }
    }
`;

export const CREATE_PERSON = gql`
    mutation CreatePerson($data: CreateOrUpdatePersonInput!) {
        createPerson(data: $data) {
            id
        }
    }
`;

export const UPDATE_PERSON = gql`
    mutation UpdatePerson($id: String!, $data: CreateOrUpdatePersonInput!) {
        updatePerson(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_PERSON = gql`
    mutation DeletePerson($id: String!) {
        deletePerson(id: $id) {
            count
        }
    }
`;

export const GET_PERSON_BY_COGNITO_ID = gql`
    query GetPersonByCognitoId($cognitoId: String!) {
        getPersonByCognitoId(cognitoId: $cognitoId) {
            id
            firstName
            lastName
        }
    }
`;

export const GET_PERSON_EMAILS = gql`
    query GetPersonEmails($id: String!) {
        getPersonEmails(id: $id)
    }
`;

export const GET_PERSON_PHONE_NUMBERS = gql`
    query GetPersonPhoneNumbers($id: String!) {
        getPersonPhoneNumbers(id: $id)
    }
`;

export const GET_ALLOWED_EMAIL_DOMAINS = gql`
    query getAllowedEmailDomains {
        getAllowedEmailDomains
    }
`;

export const VALIDATE_PERSON_ARCHIVATION = gql`
    query ValidatePersonArchivation($ids: [String!]!) {
        validatePersonArchivation(ids: $ids) {
            id
            firstName
            middleName
            lastName
            employmentEndDate
            employees {
                id
                statusId
                endedWorkOn
                contractTypeId
                roles {
                    employeeRole {
                        name
                    }
                }
                departments {
                    id
                    endDateOfAssignment
                }
            }
        }
    }
`;

export const ARCHIVE_PERSON_RECORDS = gql`
    mutation ArchivePersonRecords($ids: [String!]!) {
        archivePersonRecords(ids: $ids)
    }
`;
