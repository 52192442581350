import { FC } from 'react';
import { IconWarning } from '../../../../../../helpers/ucr/icons';
import { Callout, calloutIntent } from '../../../../../v2/components';

export interface JobSummaryWarning {
    intent: calloutIntent;
    content: string;
}

export const JobSummaryWarnings: FC<{ warnings: JobSummaryWarning[] }> = ({ warnings = [] }) => {
    if (!warnings.length) {
        return null;
    }

    return (
        <div className="job-summary__section job-summary__section--borderless">
            {warnings.map(({ content, intent }, i) => (
                <Callout
                    key={`job-summary__callout++${i}`}
                    Icon={IconWarning}
                    intent={intent}
                    spacerTop={i !== 0}
                    compact={true}
                >
                    {content}
                </Callout>
            ))}
        </div>
    );
};
