import { gql } from '@apollo/client';

export const COMMON_DICTIONARY_QUERIES = gql`
    query GetEmployeeRoleTypes1 {
        getEmployeeRoleTypes {
            edges {
                node {
                    id
                    name
                    isArchived
                }
            }
        }
        getSpecialities {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getContractTypes {
            id
            name
        }
        getNotificationTypes {
            id
            name
        }
        getLanguages {
            id
            name
        }
        getGenders {
            id
            name
        }
        getAbsenceTypes {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getAbsenceStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getGroups {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getPayRates {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getShiftTypes {
            id
            name
        }
        getShiftStatuses {
            id
            name
        }

        getDepartments {
            edges {
                node {
                    name
                    id
                    isArchived
                }
            }
        }
        getPersons {
            edges {
                node {
                    id
                    firstName
                    lastName
                }
            }
        }
        getAgencies {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getLocations {
            edges {
                node {
                    id
                    name
                    isArchived
                }
            }
        }
        getPerformerAlignments {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getPerformerRoles {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getPerformerStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getPerformerTeams {
            edges {
                node {
                    id
                    name
                }
            }
        }
        getCostCentres {
            edges {
                node {
                    id
                    name
                    isArchived
                }
            }
        }
        getFundingPools {
            edges {
                node {
                    id
                    name
                    isArchived
                }
            }
        }
        getFunctions {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_COUNTRIES_OPTIONS = gql`
    query GetCountriesOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getCountries(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_ETHNICITIES_OPTIONS = gql`
    query GetEthnicitiesOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getEthnicities(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_RELIGION_OPTIONS = gql`
    query GetReligionsOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getReligionsOrBeliefs(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_SEXUAL_OPTIONS = gql`
    query GetSexualOrientationsOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getSexualOrientations(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_MARITAL_OPTIONS = gql`
    query GetMaritalStatusesOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getMaritalStatuses(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_DISABILITY_OPTIONS = gql`
    query GetDisabilitiesOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getDisabilities(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_TITLES_OPTIONS = gql`
    query GetTitles(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getTitles(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
