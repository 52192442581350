import { Button, Checkbox } from '@blueprintjs/core';
import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Pathway, Service } from '@doc-abode/data-models';

import { Panel } from '../side';
import { ALL_OPTION, MultiSelectComponent } from '../../../../v2/form/MultiSelect';
import useStores from '../../../../../hook/useStores';
import { IFilterOption } from '../../../../../stores/UCRStore';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const FiltersPanel: FC<IProps> = observer(({ isShow, closePanel }) => {
    const {
        RootStore: {
            configStore: { bands, pathways },
            ucrStore: {
                jobFilters,
                setShowAbortedJobs,
                showAbortedJobs,
                setHcpFilters,
                setJobFilters,
                clearAllFilters,
                hcpTypes,
                hcpFilters,
            },
            lovsStore,
        },
    } = useStores();

    const [jobTransportOption, setJobTransportOption] = useState<IFilterOption[]>([]);

    const onChangeJobTransport = (option: IFilterOption, remove?: boolean): void => {
        if (
            remove ||
            option.label === ALL_OPTION.label ||
            jobTransportOption[0]?.label === option.label
        ) {
            setJobFilters('carRequired', undefined);
            setJobTransportOption([]);
        } else {
            setJobFilters('carRequired', option.value === 'carRequired');
            setJobTransportOption([option]);
        }
    };
    const onAddJobInput = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = jobFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? jobFilters[name]
                    : [...jobFilters[name], option];
                setJobFilters(name, filters);
            } else {
                setJobFilters(name, [option]);
            }
        } else {
            setJobFilters(name, []);
        }
    };
    const onRemoveJobInput = (name: string, option: IFilterOption): void => {
        setJobFilters(
            name,
            jobFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };
    const onSelectJobInput = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (jobFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            onRemoveJobInput(name, option);
        } else {
            onAddJobInput(name, option, forceSingleOption);
        }
    };

    const onAddHcpFilterValue = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = hcpFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? hcpFilters[name]
                    : [...hcpFilters[name], option];
                setHcpFilters(name, filters);
            } else {
                setHcpFilters(name, [option]);
            }
        } else {
            setHcpFilters(name, []);
        }
    };

    const onRemoveHcpFilterValue = (name: string, option: IFilterOption): void => {
        setHcpFilters(
            name,
            hcpFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };

    const onSelectHcpFilterOption = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (hcpFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            onRemoveHcpFilterValue(name, option);
        } else {
            onAddHcpFilterValue(name, option, forceSingleOption);
        }
    };

    const onClearAllFilters = (): void => {
        clearAllFilters();
    };

    const jobStatusOptions = [
        {
            label: 'Not started',
            value: 'notStarted',
        },
        {
            label: 'Current',
            value: 'current',
        },
        {
            label: 'Arrived',
            value: 'arrived',
        },
        {
            label: 'Completed',
            value: 'completed',
        },
    ];

    useEffect(() => {
        if (typeof jobFilters.carRequired === 'undefined') {
            setJobTransportOption([]);
        }
    }, [jobFilters.carRequired]);

    const selectedPathways: Pathway[] = jobFilters.referralPathway;

    const serviceOptions: Service[] = (pathways as Pathway[])
        .map(({ services }) => services)
        .flat()
        .filter(({ enabled }) => enabled)
        .reduce((acc, service) => {
            if (!acc.find(({ value }) => value === service.value)) {
                acc.push(service);
            }
            return acc;
        }, [] as Service[]);

    return (
        <Panel title="Filters" side="right" isShow={isShow} closePanel={closePanel}>
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-container">
                    <MultiSelectComponent
                        name="referralPathway"
                        className="ucr__filters-panel-filter"
                        optionsSelected={selectedPathways}
                        placeholder="Pathway"
                        options={pathways.filter((pathway: Pathway) => pathway.enabled)}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectJobInput('referralPathway', option)}
                        onRemove={(option) => onRemoveJobInput('referralPathway', option)}
                    />
                    <MultiSelectComponent
                        name="disposition"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.disposition}
                        placeholder="Service"
                        options={serviceOptions}
                        optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                        onItemSelect={(option) => onSelectJobInput('disposition', option)}
                        onRemove={(option) => onRemoveJobInput('disposition', option)}
                    />

                    <MultiSelectComponent
                        name="referrer"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.referrer}
                        placeholder="Referrer"
                        options={lovsStore.referrer?.map((label: string) => ({
                            label,
                            value: label,
                        }))}
                        optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                        onItemSelect={(option) => onSelectJobInput('referrer', option)}
                        onRemove={(option) => onRemoveJobInput('referrer', option)}
                    />
                    <MultiSelectComponent
                        name="jobStatus"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.jobStatus}
                        placeholder="Status"
                        options={jobStatusOptions}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectJobInput('jobStatus', option)}
                        onRemove={(option) => onRemoveJobInput('jobStatus', option)}
                    />
                    <MultiSelectComponent
                        name="availability"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.availability}
                        placeholder="Availability"
                        options={[
                            {
                                label: 'Available staff',
                                value: 'availableStaff',
                            },
                            {
                                label: 'Staff with visits',
                                value: 'staffWithVisits',
                            },
                            {
                                label: 'Staff without visits',
                                value: 'staffWithoutVisits',
                            },
                        ]}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectHcpFilterOption('availability', option)}
                        onRemove={(option) => onSelectHcpFilterOption('availability', option)}
                    />
                    <MultiSelectComponent
                        name="hcpType"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.hcpType}
                        placeholder="HCP Type"
                        options={hcpTypes}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectHcpFilterOption('hcpType', option)}
                        onRemove={(option) => onSelectHcpFilterOption('hcpType', option)}
                    />
                    <MultiSelectComponent
                        name="band"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.band}
                        placeholder="Band"
                        options={bands}
                        optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                        onItemSelect={(option) => onSelectHcpFilterOption('band', option)}
                        onRemove={(option) => onSelectHcpFilterOption('band', option)}
                    />
                    <MultiSelectComponent
                        name="gender"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.gender}
                        placeholder="Gender"
                        options={lovsStore.gender.filter(
                            (gender: IFilterOption) => gender.label !== 'Not Set',
                        )}
                        optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                        onItemSelect={(option) => onSelectHcpFilterOption('gender', option)}
                        onRemove={(option) => onSelectHcpFilterOption('gender', option)}
                    />
                    <MultiSelectComponent
                        name="careComplexity"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.careComplexity}
                        placeholder="Care complexity"
                        options={[
                            {
                                label: 'Complex',
                                value: 'complex',
                            },
                            {
                                label: 'Non-complex',
                                value: 'nonComplex',
                            },
                            {
                                label: 'Not set',
                                value: 'unset',
                            },
                        ]}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectJobInput('careComplexity', option, true)}
                        onRemove={(option) => onRemoveJobInput('careComplexity', option)}
                    />
                    <MultiSelectComponent
                        name="carRequired"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobTransportOption}
                        placeholder="Transport required"
                        options={[
                            {
                                label: 'Car required',
                                value: 'carRequired',
                            },
                            {
                                label: 'No car required',
                                value: 'noCarRequired',
                            },
                        ]}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onChangeJobTransport(option)}
                        onRemove={(option) => onChangeJobTransport(option, true)}
                    />
                    <MultiSelectComponent
                        name="warning"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.warning}
                        placeholder="Warning"
                        options={[
                            {
                                label: 'With any warning',
                                value: 'withAnyWarnings',
                            },
                            {
                                label: 'With patient alerts',
                                value: 'withPatientAlerts',
                            },
                            {
                                label: 'With scheduling conflicts',
                                value: 'withConflicts',
                            },
                            {
                                label: 'Which are outside of HCPs available hours',
                                value: 'outsideAvailable',
                            },
                            {
                                label: 'With breaches of the latest arrival time',
                                value: 'latestArrival',
                            },
                            {
                                label: 'With breaches of the earliest arrival time',
                                value: 'earliestArrival',
                            },
                            {
                                label: 'Affected by delays',
                                value: 'affectedByDelays',
                            },
                            {
                                label: 'With double-up',
                                value: 'withDoubleUp',
                            },
                        ]}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectJobInput('warning', option, true)}
                        onRemove={(option) => onSelectJobInput('warning', option)}
                    />

                    <Checkbox
                        label="Show aborted visits"
                        checked={showAbortedJobs}
                        className="shift-pattern-select-all-checkbox"
                        onChange={(e) => setShowAbortedJobs(!showAbortedJobs)}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Button
                        className="ucr__filters-clear-filters"
                        text="Clear filters"
                        minimal={true}
                        name="Clear filters"
                        onClick={onClearAllFilters}
                    />
                </div>
            </div>
        </Panel>
    );
});

export default FiltersPanel;
