import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import { PAGE_SIZE } from '../constants/hrConst';
import AppToaster from '../components/modules/helpers/Toaster';
import {
    GET_SHIFT_PATTERNS,
    GET_SHIFT_PATTERN_BY_ID,
    DELETE_SHIFT_PATTERN,
    CREATE_SHIFT_PATTERN,
    UPDATE_SHIFT_PATTERN,
    UPDATE_SHIFT_PATTERN_ENTRY,
    CREATE_SHIFT_PATTERN_COMMENT,
    UPDATE_SHIFT_PATTERN_COMMENT,
    DELETE_SHIFT_PATTERN_COMMENT,
    GET_SHIFT_PATTERN_ENTRY_CONFLICTS,
} from '../graphql/queries/shiftPatterns';
import { GET_SHIFT_PATIENT_FACING_WARNINGS } from '../graphql/queries/shiftPatientFacing';
import { PagedStore } from './PagedStore';
import { DAYS } from '../constants/rotaConst';
import { formatTime } from '../components/modules/helpers/FormatTime';

class ShiftPatternsStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            entryActionType: observable,
            selected: observable,
            shiftPatterns: observable,
            shiftPatternsByDepartment: observable,
            shiftPatternsByPayRate: observable,
            shiftPatternsByLocation: observable,
            newShiftPattern: observable,
            newShiftPatternEntry: observable,
            formattedShifts: observable,
            filteredShifts: observable,
            filteredDeps: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            changesNotSaved: observable,
            gridShiftPatterns: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getShiftPatterns: action,
            setSelectableCriteria: action,
            isCreatePath: observable,
            showShiftEntryModal: observable,
            clipboardActive: observable,
            replaceNewShiftPatternEntry: action,
            updateShiftEntry: action,
            addNewShiftPatternEntry: action,
            addNewWeek: action,
            clearNewShiftPattern: action,
            clearNewShiftPatternEntry: action,
            updateNewShiftPatternEntry: action,
            updateNewShiftPattern: action,
            updateNewShiftPatternName: action,
            updateNewShiftPatternDepartment: action,
            updateNewShiftPatternEntries: action,
            modifyNewShiftPatternEntries: action,
            modifyFilterEntries: action,
            deleteNewShiftPatternEntry: action,
            createShiftPatternComment: action,
            updateShiftPatternComment: action,
            deleteShiftPatternComment: action,
            generateFormattedShifts: action,
            clearFormattedShifts: action,
            filterShifts: action,
            updateFilterDeps: action,
            clearFilterDeps: action,
            updateChangesState: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    entryActionType = '';
    shiftPatterns = [];
    shiftPatternsByDepartment = [];
    shiftPatternsByPayRate = [];
    shiftPatternsByLocation = [];
    newShiftPattern = {};
    newShiftPatternEntry = [];
    formattedShifts = [];
    filteredShifts = [];
    filteredDeps = {};
    showShiftEntryModal = false;
    clipboardActive = false;
    isCreatePath = false;
    changesNotSaved = false;
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeConflicts = (shiftPatternEntryId, conflicts) => {
        const result = [];

        if (conflicts) {
            for (const item of conflicts) {
                const itp =
                    item.shiftPatternEntryId0 === shiftPatternEntryId
                        ? item.shiftPatternEntry1
                        : item.shiftPatternEntry0;
                const time = moment(itp.startTime).format('HH:mm');
                result.push({
                    shiftPatternEntryId: itp.shiftPattern.id,
                    label: `Scheduling conflict with ${time} shift of ${itp.shiftPattern.name} shift pattern`,
                });
            }
        }

        return result;
    };

    normalizeShiftPatterns = (shiftPattern) => ({
        id: shiftPattern.id,
        selected: this.selected.includes(shiftPattern.id),
        name: shiftPattern.name,
        costCentreId: shiftPattern.costCentreId,
        departmentId: shiftPattern.departmentId,
        createdAt: shiftPattern.createdAt,
        createdBy: shiftPattern.createdBy,
        updatedAt: shiftPattern.updatedAt,
        createdPerson:
            shiftPattern.createdPerson &&
            `${shiftPattern.createdPerson.firstName} ${shiftPattern.createdPerson.lastName}`,
        department: shiftPattern.department,
        costCentre: shiftPattern.costCentre,
        shiftPatternEntries: shiftPattern.shiftPatternEntries?.map((item) => {
            return {
                id: item.id,
                shiftPatternId: item.shiftPatternId ?? '',
                weekNumber: item.weekNumber,
                dayOfWeek: item.dayOfWeek,
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
                breakDurationMinutes: item.breakDurationMinutes,
                breakIsPaid: item.breakIsPaid,
                overrideValueInPence: item.overrideValueInPence,
                shiftEmployeeRoleTypeId: item.shiftEmployeeRoleTypeId,
                shiftFunctions: item.shiftFunctions.map(
                    (shiftFunction) => shiftFunction.functionId,
                ),
                patientFacing: item.patientFacing,
                fundingPoolId: item.fundingPoolId,
                payRateId: item.payRateId,
                locationId: item.locationId,
                defaultEmployeeId: item.defaultEmployeeId,
                defaultEmployee: _.isEmpty(item.defaultEmployee) ? null : item.defaultEmployee,
                typeId: item.typeId,
                thirdPartyPaid: item.thirdPartyPaid,
                trainingShift: item.trainingShift,
                trainees: item.trainees?.length
                    ? item.trainees.map((trainee) => {
                          return {
                              traineeId: trainee.traineeId,
                              roleId: trainee.roleId,
                              overrideValue: trainee.overrideValue,
                              payRateId: trainee.payRateId,
                          };
                      })
                    : [
                          {
                              traineeId: '',
                              roleId: '',
                              overrideValue: null,
                              payRateId: '',
                          },
                      ],
                traineePerson: item.trainees?.length
                    ? item.trainees[0].trainee
                        ? {
                              id: item.trainees[0].traineeId,
                              firstName: item.trainees[0].trainee.person.firstName,
                              lastName: item.trainees[0].trainee.person.lastName,
                          }
                        : null
                    : null,
                conflicts: [
                    ...this.normalizeConflicts(item.id, item.conflicts0),
                    ...this.normalizeConflicts(item.id, item.conflicts1),
                ],
                comments: item.comments?.length
                    ? item.comments.map((comment) => {
                          return {
                              comment: comment.comment,
                              createdAt: comment.createdAt,
                              createdBy: comment.createdBy,
                              id: comment.id,
                              isDeleted: comment.isDeleted,
                              isEdited: comment.isEdited,
                              updatedAt: comment.updatedAt,
                              createdPerson: comment.createdPerson,
                              isImportant: comment.isImportant,
                          };
                      })
                    : [],
            };
        }),
        shifts: shiftPattern.shiftPatternEntries?.length,
        weeks: [...Array(shiftPattern.numberOfWeeks).keys()].map((i) => i + 1),
    });

    get gridShiftPatterns() {
        const shiftPatterns = this.shiftPatterns.map(this.normalizeShiftPatterns);
        if (this.sortFn) {
            shiftPatterns.sort(this.sortFn);
        }
        return shiftPatterns;
    }

    get allSelected() {
        return this.gridShiftPatterns.every((r) => this.selected.includes(r.id));
    }

    updateNewShiftPatternName(value) {
        this.changesNotSaved = true;
        this.newShiftPattern.name = value;
    }

    updateNewShiftPatternCostCentre(value) {
        this.newShiftPattern.costCentreId = value.value;
        this.newShiftPattern.costCentreName = value.label;
    }

    updateNewShiftPatternDepartment(value) {
        this.newShiftPattern.departmentId = value.value;
        this.newShiftPattern.departmentName = value.label;
    }

    orderFilteredShiftsByDefault() {
        return (this.filteredShifts = _.orderBy(
            this.filteredShifts,
            ['sortWeek', 'sortDay', 'sortStartTime', 'sortEndTime'],
            ['asc', 'asc', 'asc', 'asc'],
        ));
    }

    updateNewShiftPatternEntries(value) {
        this.changesNotSaved = true;
        this.newShiftPattern.shiftPatternEntries.unshift(value);
        this.generateFormattedShifts();
    }

    deleteNewShiftPatternEntry(entry) {
        this.changesNotSaved = true;
        this.newShiftPattern.shiftPatternEntries.splice(
            this.newShiftPattern.shiftPatternEntries.findIndex(
                (shiftPattern) =>
                    (entry.id && entry.id === shiftPattern.id) ||
                    (entry.newShiftId && entry.newShiftId === shiftPattern.newShiftId),
            ),
            1,
        );
        this.generateFormattedShifts();
    }

    modifyNewShiftPatternEntries(value) {
        this.changesNotSaved = true;
        this.newShiftPattern.shiftPatternEntries = value;
        this.generateFormattedShifts();
    }

    modifyFilterEntries(value) {
        this.filteredShifts = value;
    }

    updateShiftEntry(value) {
        this.changesNotSaved = true;
        this.newShiftPatternEntry = value;
        this.generateFormattedShifts();
    }

    updateNewShiftPattern(value) {
        this.newShiftPattern = { ...value };
    }

    updateEntryActionType(value) {
        this.entryActionType = value;
    }

    updateChangesState(value) {
        this.changesNotSaved = value;
    }

    clearNewShiftPattern() {
        this.newShiftPattern = {};
    }

    clearNewShiftPatternEntry() {
        return (this.newShiftPatternEntry = []);
    }

    addNewShiftPatternEntry(data) {
        this.newShiftPatternEntry = data;
    }

    addNewWeek() {
        if (this.newShiftPattern.weeks.length < 8) {
            this.newShiftPattern.weeks.push(this.newShiftPattern.weeks.length + 1);
        }
    }

    updateNewShiftPatternEntry(data) {
        this.changesNotSaved = true;
        runInAction(() => {
            this.newShiftPatternEntry = data;
            this.updateNewShiftPatternEntries(this.newShiftPatternEntry);
            this.clearNewShiftPatternEntry();
        });
    }

    replaceNewShiftPatternEntry(entry) {
        const prevEntry = this.newShiftPattern.shiftPatternEntries.findIndex(
            (shiftPattern) =>
                (entry.id && entry.id === shiftPattern.id) ||
                (entry.newShiftId && entry.newShiftId === shiftPattern.newShiftId),
        );
        if (prevEntry > -1) {
            this.newShiftPattern.shiftPatternEntries[prevEntry] = entry;
            this.generateFormattedShifts();
        }
    }

    initializeShiftPattern() {
        this.newShiftPattern = {
            id: '',
            name: '',
            cratedAt: '',
            cratedBy: '',
            updatedAt: '',
            selected: '',
            costCentreId: '',
            departmentId: '',
            weeks: [1],
            shifts: '',
            shiftPatternEntries: [],
        };
    }

    async createShiftPattern(shiftPattern) {
        const shiftPatternEntriesModified = shiftPattern.shiftPatternEntries.map(
            ({ newShiftId, ...rest }) => rest,
        );

        const shiftPatternEntries = shiftPatternEntriesModified.map((item) => {
            return {
                id: item.id,
                shiftPatternId: item.shiftPatternId ?? '',
                weekNumber: item.weekNumber,
                dayOfWeek: item.dayOfWeek,
                startTime: new Date(item.startTime),
                endTime: new Date(item.endTime),
                breakDurationMinutes: item.breakDurationMinutes,
                breakIsPaid: item.breakIsPaid,
                overrideValueInPence: item.overrideValueInPence ? item.overrideValueInPence : null,
                shiftEmployeeRoleTypeId: item.shiftEmployeeRoleTypeId,
                shiftFunctions: item.shiftFunctions,
                patientFacing: item.patientFacing,
                fundingPoolId: _.isEmpty(item.fundingPoolId) ? null : item.fundingPoolId,
                payRateId: item.payRateId,
                locationId: item.locationId,
                defaultEmployeeId: _.isEmpty(item.defaultEmployeeId)
                    ? null
                    : item.defaultEmployeeId,
                typeId: item.typeId,
                thirdPartyPaid: item.thirdPartyPaid,
                trainingShift: item.trainingShift,
                trainees: item.trainees.map((trainee) => {
                    return {
                        traineeId: trainee.traineeId,
                        roleId: trainee.roleId,
                        overrideValue: trainee.overrideValue,
                        payRateId: trainee.payRateId,
                    };
                }),
                conflicts: item.conflicts.map((r) => r.shiftPatternEntryId).filter((r) => !!r),
                comments: !item.comments
                    ? []
                    : item.comments?.map((comment) => ({
                          comment: comment.comment,
                          isImportant: comment.isImportant,
                      })),
            };
        });

        try {
            await this.apolloClient.mutate({
                mutation: CREATE_SHIFT_PATTERN,
                variables: {
                    data: {
                        name: shiftPattern.name,
                        costCentreId: shiftPattern.costCentreId,
                        departmentId: shiftPattern.departmentId,
                        shiftPatternEntries: shiftPatternEntries,
                        numberOfWeeks: shiftPattern.weeks.length,
                    },
                },
            });
            AppToaster.show({
                message: 'Shift pattern created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating shift pattern record. Please try again
                        or contact <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                        for help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateShiftPatternEntry(shiftPatternEntry) {
        const { newShiftId, ...shiftPatternEntryModified } = shiftPatternEntry;
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_SHIFT_PATTERN_ENTRY,
                variables: {
                    id: shiftPatternEntryModified.id,
                    data: {
                        shiftPatternId: shiftPatternEntryModified.shiftPatternId,
                        defaultEmployeeId: _.isEmpty(shiftPatternEntryModified.defaultEmployeeId)
                            ? null
                            : shiftPatternEntryModified.defaultEmployeeId,
                        weekNumber: shiftPatternEntryModified.weekNumber,
                        dayOfWeek: shiftPatternEntryModified.dayOfWeek,
                        startTime: new Date(shiftPatternEntryModified.startTime),
                        endTime: new Date(shiftPatternEntryModified.endTime),
                        breakDurationMinutes: shiftPatternEntryModified.breakDurationMinutes,
                        breakIsPaid: shiftPatternEntryModified.breakIsPaid,
                        overrideValueInPence: shiftPatternEntryModified.overrideValueInPence
                            ? shiftPatternEntryModified.overrideValueInPence
                            : null,
                        shiftEmployeeRoleTypeId: _.isEmpty(
                            shiftPatternEntryModified.shiftEmployeeRoleTypeId,
                        )
                            ? null
                            : shiftPatternEntryModified.shiftEmployeeRoleTypeId,
                        shiftFunctions: shiftPatternEntryModified.shiftFunctions,
                        patientFacing: shiftPatternEntryModified.patientFacing,
                        fundingPoolId: _.isEmpty(shiftPatternEntryModified.fundingPoolId)
                            ? null
                            : shiftPatternEntryModified.fundingPoolId,
                        locationId: _.isEmpty(shiftPatternEntryModified.locationId)
                            ? null
                            : shiftPatternEntryModified.locationId,
                        typeId: shiftPatternEntryModified.typeId,
                        payRateId: shiftPatternEntryModified.payRateId,
                        thirdPartyPaid: shiftPatternEntryModified.thirdPartyPaid,
                        trainingShift: shiftPatternEntryModified.trainingShift,
                        trainees: shiftPatternEntryModified.trainees.map((trainee) => {
                            return {
                                traineeId: trainee.traineeId,
                                roleId: trainee.roleId,
                                overrideValue: trainee.overrideValue,
                                payRateId: trainee.payRateId,
                            };
                        }),
                        comments: shiftPatternEntryModified.comments.map((comment) => {
                            return {
                                comment: comment.comment,
                                createdBy: comment.createdBy,
                                isEdited: comment.isEdited,
                                createdAt: comment.createdAt,
                                updatedAt: comment.updatedAt,
                                isImportant: comment.isImportant,
                            };
                        }),
                    },
                },
            });
            AppToaster.show({
                message: 'Shift pattern entry updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating shift pattern entry record. Please try
                        again or contact{' '}
                        <a href="mailto:support@docabode.com">support@docabode.com</a> for help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteShiftPattern(shiftPattern) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_SHIFT_PATTERN,
                variables: {
                    id: shiftPattern.id,
                },
            });
            AppToaster.show({
                message: 'Shift pattern deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting shift pattern record. Please try again
                        or contact <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                        for help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateShiftPattern(shiftPattern) {
        const newShift = shiftPattern.shiftPatternEntries.map((entry) => {
            return {
                id: entry.id,
                shiftPatternId: entry.shiftPatternId ?? '',
                weekNumber: entry.weekNumber,
                dayOfWeek: entry.dayOfWeek,
                startTime: new Date(entry.startTime),
                endTime: new Date(entry.endTime),
                breakDurationMinutes: entry.breakDurationMinutes,
                breakIsPaid: entry.breakIsPaid,
                overrideValueInPence: _.isEmpty(entry.overrideValueInPence)
                    ? null
                    : entry.overrideValueInPence,
                shiftEmployeeRoleTypeId: entry.shiftEmployeeRoleTypeId,
                shiftFunctions: entry.shiftFunctions,
                patientFacing: entry.patientFacing,
                fundingPoolId: _.isEmpty(entry.fundingPoolId) ? null : entry.fundingPoolId,
                payRateId: entry.payRateId,
                locationId: entry.locationId,
                defaultEmployeeId: _.isEmpty(entry.defaultEmployeeId)
                    ? null
                    : entry.defaultEmployeeId,
                typeId: entry.typeId,
                thirdPartyPaid: entry.thirdPartyPaid,
                trainingShift: entry.trainingShift,
                trainees: entry.trainees.map((trainee) => {
                    return {
                        traineeId: trainee.traineeId,
                        roleId: trainee.roleId,
                        overrideValue: trainee.overrideValue,
                        payRateId: trainee.payRateId,
                    };
                }),
                conflicts: entry.conflicts.map((r) => r.shiftPatternEntryId).filter((r) => !!r),
                comments: entry.comments.map((comment) => {
                    return {
                        comment: comment.comment,
                        createdBy: comment.createdBy,
                        isEdited: comment.isEdited,
                        createdAt: comment.createdAt,
                        updatedAt: comment.updatedAt,
                        isImportant: comment.isImportant,
                    };
                }),
            };
        });
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_SHIFT_PATTERN,
                variables: {
                    id: shiftPattern.id,
                    data: {
                        name: shiftPattern.name,
                        costCentreId: shiftPattern.costCentreId,
                        departmentId: shiftPattern.departmentId,
                        shiftPatternEntries: newShift,
                        numberOfWeeks: shiftPattern.weeks.length,
                    },
                },
            });
            AppToaster.show({
                message: 'Shift pattern updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating shift pattern record. Please try again
                        or contact <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                        for help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (id) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getShiftPatterns();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getShiftPatterns();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getShiftPatterns();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                status: null,
            });
        });
        await this.getShiftPatterns();
    };

    getShiftPatterns = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            this.shiftPatterns = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    createRotaByShiftPatternId = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatternById: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERN_BY_ID,
            variables: {
                id,
            },
        });
        runInAction(() => {
            this.rotaByShiftPatternId = this.normalizeShiftPatterns(data);
        });
    };

    getShiftPatternById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatternById: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERN_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeShiftPatterns(data);
    };

    getShiftPatternsByPayRate = async (payRateId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { payRateId },
            },
        });
        runInAction(() => {
            this.shiftPatternsByPayRate = data.edges.map((x) => {
                return this.normalizeShiftPatterns(x.node);
            });
            this.shiftPatternsByPayRate = _.orderBy(this.shiftPatternsByPayRate, ['name'], ['asc']);
        });
    };

    getShiftPatternsByFunction = async (functionId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { functionId },
            },
        });
        runInAction(() => {
            this.shiftPatternsByFunction = data?.edges?.map((x) => {
                return this.normalizeShiftPatterns(x.node);
            });
            this.shiftPatternsByFunction = _.orderBy(
                this.shiftPatternsByFunction,
                ['name'],
                ['asc'],
            );
        });
    };

    getShiftPatternsByRole = async (roleId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roleId },
            },
        });
        runInAction(() => {
            this.shiftPatternsByRole = data.edges.map((x) => {
                return this.normalizeShiftPatterns(x.node);
            });
            this.shiftPatternsByRole = _.orderBy(this.shiftPatternsByRole, ['name'], ['asc']);
        });
    };

    getShiftPatternsByDepartment = async (departmentId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { departmentId },
            },
        });
        runInAction(() => {
            this.shiftPatternsByDepartment = data.edges.map((x) => {
                return this.normalizeShiftPatterns(x.node);
            });
            this.shiftPatternsByDepartment = _.orderBy(
                this.shiftPatternsByDepartment,
                ['name'],
                ['asc'],
            );
        });
    };

    getShiftPatternsByLocation = async (locationId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { locationId: [locationId] },
            },
        });
        runInAction(() => {
            this.shiftPatternsByLocation = data.edges.map((x) => {
                return this.normalizeShiftPatterns(x.node);
            });
            this.shiftPatternsByLocation = _.orderBy(
                this.shiftPatternsByLocation,
                ['name'],
                ['asc'],
            );
        });
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getShiftPatterns();
            this.fetchInterval = setInterval(() => this.getShiftPatterns(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridShiftPatterns.forEach((item) => {
                this.select(item.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    createShiftPatternComment = async (comment, shiftPatternEntryId, isImportant) => {
        try {
            const newComment = await this.apolloClient.mutate({
                mutation: CREATE_SHIFT_PATTERN_COMMENT,
                variables: {
                    shiftPatternEntryId: shiftPatternEntryId,
                    comment: comment,
                    isImportant: isImportant,
                },
            });

            AppToaster.show({
                message: 'New comment added!',
                intent: 'success',
            });

            return newComment?.data?.createRotaShiftComment;
        } catch (err) {}
    };

    updateShiftPatternComment = async (comment, commentId, isImportant) => {
        try {
            const updatedComment = await this.apolloClient.mutate({
                mutation: UPDATE_SHIFT_PATTERN_COMMENT,
                variables: {
                    id: commentId,
                    comment: comment,
                    isImportant: isImportant,
                },
            });
            AppToaster.show({
                message: 'Comment updated',
                intent: 'success',
            });

            return updatedComment?.data?.updateRotaShiftComment;
        } catch (err) {}
    };

    deleteShiftPatternComment = async (commentId) => {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_SHIFT_PATTERN_COMMENT,
                variables: {
                    id: commentId,
                },
            });
            AppToaster.show({
                message: 'Comment deleted',
                intent: 'success',
            });
        } catch (err) {}
    };

    getShiftConflicts = async (employeeId, startTime, endTime, weekNumber, dayOfWeek, entryId) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getShiftPatternEntityConflicts },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERN_ENTRY_CONFLICTS,
            variables: {
                employeeId,
                startTime,
                endTime,
                weekNumber,
                dayOfWeek,
                entryId,
            },
        });

        const result = getShiftPatternEntityConflicts.map(
            ({ shiftPatternEntryId, startTime, shiftPatternName }) => ({
                shiftPatternEntryId,
                label: `Scheduling conflict with ${moment(startTime).format(
                    'HH:mm',
                )} shift of ${shiftPatternName} shift pattern`,
            }),
        );

        for (const spe of this.newShiftPattern.shiftPatternEntries) {
            if (
                spe.defaultEmployeeId === employeeId &&
                spe.weekNumber === weekNumber &&
                spe.dayOfWeek === dayOfWeek
            ) {
                const sTime = moment(spe.startTime).format('HH:mm');
                const eTime = moment(spe.endTime).format('HH:mm');

                const overlap = this.isTimeOverlap([
                    [sTime, eTime],
                    [startTime, endTime],
                ]);

                if (overlap) {
                    result.push({
                        shiftPatternEntryId: spe.id,
                        label: `Scheduling conflict with ${sTime} shift of the current shift pattern`,
                    });
                }
            }
        }

        return result;
    };

    isTimeOverlap = (timeSegments) => {
        if (timeSegments.length === 1) return false;

        timeSegments.sort((timeSegment1, timeSegment2) =>
            timeSegment1[0].localeCompare(timeSegment2[0]),
        );

        for (let i = 0; i < timeSegments.length - 1; i++) {
            const currentEndTime = timeSegments[i][1];
            const nextStartTime = timeSegments[i + 1][0];

            if (currentEndTime > nextStartTime) {
                return true;
            }
        }

        return false;
    };

    checkVal = (shift) => {
        const depsEntries = Object.entries(this.filteredDeps);
        const check = depsEntries.every((dep) => {
            const depName = dep[0];
            return dep[1].some((depInner) => {
                if (Array.isArray(shift[depName])) {
                    return shift[depName].includes(depInner.value);
                } else {
                    return shift[depName] === depInner.value;
                }
            });
        });
        return check;
    };

    getShiftPatientFacingWarnings = async (data) => {
        try {
            const {
                data: { getShiftPatientFacingWarnings },
            } = await this.apolloClient.query({
                query: GET_SHIFT_PATIENT_FACING_WARNINGS,
                variables: {
                    data,
                },
            });
            return getShiftPatientFacingWarnings;
        } catch (err) {}
    };

    generateFormattedShifts = () => {
        this.formattedShifts = this.newShiftPattern?.shiftPatternEntries?.map((entry) => {
            const midday = new Date('2022-01-01 12:00');
            const entryStartTime = new Date(entry.startTime);
            const entryEndTime = new Date(entry.endTime);
            const entryStartTimeToCompare = new Date(
                `2022-01-01  ${entryStartTime.getHours()}:${entryStartTime.getMinutes()}`,
            );
            const entryEndTimeToCompare = new Date(
                `2022-01-01 ${entryEndTime.getHours()}:${entryEndTime.getMinutes()}`,
            );

            return {
                ...entry,
                sortWeek: entry.weekNumber,
                sortDay: DAYS[entry.dayOfWeek - 1].label,
                sortStartTime: formatTime(entry.startTime),
                sortEndTime: formatTime(entry.endTime),
                traineesId: entry.trainees[0].traineeId,
                timeCover:
                    entryEndTimeToCompare >= midday && entryStartTimeToCompare <= midday
                        ? ['AM', 'PM']
                        : entryStartTimeToCompare < midday
                        ? 'AM'
                        : 'PM',
                contractTypeId: entry?.defaultEmployee?.contractType?.id
                    ? entry.defaultEmployee.contractType.id
                    : '',
                agencyId: entry?.defaultEmployee?.agency?.id ? entry.defaultEmployee.agency.id : '',
            };
        });
        this.formattedShifts = _.orderBy(
            this.formattedShifts,
            ['sortWeek', 'sortDay', 'sortStartTime', 'sortEndTime'],
            ['asc', 'asc', 'asc', 'asc'],
        );
        this.filteredShifts = this.formattedShifts;
        this.filterShifts();
    };

    clearFormattedShifts() {
        this.formattedShifts = [];
        this.filteredShifts = [];
        this.filterShifts();
    }

    filterShifts = () => {
        this.filteredShifts = this.formattedShifts.filter((shift) => {
            return this.checkVal(shift);
        });
    };

    updateFilterDeps = (usedFilters) => {
        this.filteredDeps = usedFilters;
        this.filterShifts();
    };

    clearFilterDeps = () => {
        this.filteredDeps = {};
    };
}

export default ShiftPatternsStore;
