import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../modules/helpers/formatData';
import { CostCentres } from '../../../../stores/CostCentresStore';

interface CostCentresItemProps extends CostCentres {
    onClick: (id: string, event: React.MouseEvent) => void;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CostCentresTableRow = ({
    onClick,
    id,
    selected,
    name,
    code,
    createdAt,
    updatedAt,
    createdPerson,
    updatedPerson,
    isArchived,
    onToggle,
}: CostCentresItemProps) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                    disabled={isArchived}
                />
            </td>
            <td>{name}</td>
            <td>{code}</td>
            <td>{isArchived ? 'Archived' : 'Active'}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>
                {createdPerson.firstName} {createdPerson.lastName}
            </td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
            <td>
                {updatedPerson?.firstName} {updatedPerson?.lastName}
            </td>
        </tr>
    );
};

export default CostCentresTableRow;
