import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from '../../modal/Modal';
import { Formik, Form } from 'formik';
import { validationShiftSchema } from './validation';
import CommonModalFooter from '../../../common/CommonModalFooter';
import ShiftPatternGridView from './gridView/ShiftPatternGridView';
import ShiftPatternListView from './listView/ShiftPatternListView';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import TableViewVariants from '../../../common/TableViewVariants';
import { FormGroup, InputGroup, Icon, Collapse } from '@blueprintjs/core';
import Error from '../../forms/Error';
import _ from 'lodash';
import Select from 'react-select';
import { IconFilter } from '../../../../helpers/ucr/icons';
import { compareByLabels, compareByValues } from '../../helpers/sortFunctions';
import { SHIFT_TIME, SHIFT_TYPES_OPTIONS, DAYS } from '../../../../constants/rotaConst';
import { CONTRACT_TYPE_OPTIONS } from '../../../../constants/hrConst';
import AppToaster from '../../helpers/Toaster';
import { RouterPrompt } from '../../../common/RouterPrompt';

const ShiftPatternsDetails = inject('RootStore')(
    observer(
        class ShiftPatternsDetails extends Component {
            state = {
                isEdit: false,
                isLoaded: false,
                hasDeleteOption: false,
                showAlert: false,
                showInvalidMessage: false,
                isGridView: true,
                showMoreFilters: false,
                deps: {},
                locations: [],
                locationsFromShifts: [],
                roles: [],
                payRates: [],
                agencies: [],
                rolesFromShifts: [],
                daysFromShifts: [],
                typesFromShifts: [],
                defaultStaffsFromShifts: [],
                traineesFromShifts: [],
                payRatesFromShifts: [],
                weeksFromShifts: [],
                contractsFromShifts: [],
                agenciesFromShifts: [],
                functions: [],
                functionsFromShifts: [],
                fundingPools: [],
                fundingPoolsFromShifts: [],
                loading: true,
                savingChanges: false,
            };

            async componentDidMount() {
                const { dictionaryStore, shiftPatternsStore, staffStore } = this.props.RootStore;

                if (this.props.actionType === 'create') {
                    shiftPatternsStore.clearFormattedShifts();
                    this.setState({
                        isEdit: true,
                    });
                    shiftPatternsStore.updateEntryActionType('create');
                }
                if (this.props.actionType === 'edit') {
                    this._updateShiftPattern(this.props.location.state?.editModeOpenedFromViewList);
                    shiftPatternsStore.updateEntryActionType('edit');
                }

                shiftPatternsStore.updateChangesState(false);

                staffStore.refresh();
                staffStore.getAllStaffs();

                this.setState({
                    locations: dictionaryStore.locations,
                    roles: dictionaryStore.roles,
                    payRates: dictionaryStore.payRates,
                    agencies: dictionaryStore.agencies,
                    functions: dictionaryStore.functions,
                    fundingPools: dictionaryStore.fundingPools,
                });

                const { match } = this.props;
                if (match?.params.id) {
                    await shiftPatternsStore.getShiftPatternById(match.params.id);
                    shiftPatternsStore.generateFormattedShifts();
                    this._generateFilterOptions();
                }
            }

            componentWillUnmount() {
                const { shiftPatternsStore } = this.props.RootStore;
                shiftPatternsStore.clearFilterDeps();
            }

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/rota/shift-patterns');
                }

                return this.props.history.goBack();
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
                this.state.isEdit
                    ? this.props.RootStore.shiftPatternsStore.updateEntryActionType('edit')
                    : this.props.RootStore.shiftPatternsStore.updateEntryActionType('create');
            };

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _handleGridClick = () => {
                this.setState({
                    isGridView: true,
                });
            };
            _handleListClick = () => {
                this.setState({
                    isGridView: false,
                });
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                const shiftPattern = this.props.RootStore.shiftPatternsStore.gridShiftPatterns.find(
                    (shiftPattern) => shiftPattern.id === id,
                );
                shiftPattern &&
                    (await this.props.RootStore.shiftPatternsStore.deleteShiftPattern(
                        shiftPattern,
                    ));
                this.props.history.replace('/rota/shift-patterns');
            };

            _handleOnCancel = () => {
                return this.props.history.goBack();
            };

            _handleOnCancelConfirm = async () => {
                this.props.RootStore.shiftPatternsStore.changesNotSaved = false;
                await this.setState({ savingChanges: true });

                if (this.props.actionType === 'edit') {
                    this.props.RootStore.shiftPatternsStore.updateNewShiftPattern(
                        this.state.shiftPattern,
                    );
                    this.props.RootStore.shiftPatternsStore.generateFormattedShifts();
                    this.setState((prevState) => {
                        return { isEdit: !prevState.isEdit };
                    });
                }
                if (this.props.actionType === 'create') {
                    this.props.history.replace('/rota/shift-patterns');
                }
            };

            _updateShiftPattern = async (editModeOpenedFromViewList = false) => {
                const { id } = this.props.match.params;
                const shiftPattern = await this.props.RootStore.shiftPatternsStore.getShiftPatternById(
                    id,
                );
                if (shiftPattern) {
                    this.props.RootStore.shiftPatternsStore.updateNewShiftPattern(shiftPattern);
                    this.setState({
                        isLoaded: true,
                        hasDeleteOption: true,
                        shiftPattern: shiftPattern,
                        isEdit: editModeOpenedFromViewList,
                    });
                }
            };

            _updateNewShiftPatternName({ target: { value } }) {
                this.props.RootStore.shiftPatternsStore.updateNewShiftPatternName(value);
            }

            _generateFilterOptions = () => {
                const locationsFromShifts = this._extractShiftsData(
                    'locationId',
                    this.state.locations,
                );
                const rolesFromShifts = this._extractShiftsData(
                    'shiftEmployeeRoleTypeId',
                    this.state.roles,
                );

                const functionsIds = [];
                this.props.RootStore.shiftPatternsStore.formattedShifts?.forEach((shift) => {
                    shift.shiftFunctions.forEach((functionId) => {
                        functionsIds.push(functionId);
                    });
                });
                const functionsIdsFiltered = Array.from(new Set(functionsIds));
                const functionsFromShifts = this.state.functions.filter((item) =>
                    functionsIdsFiltered.includes(item.value),
                );

                const fundingPoolsFromShifts = this._extractShiftsData(
                    'fundingPoolId',
                    this.state.fundingPools,
                );
                const typesFromShifts = this._extractShiftsData('typeId', SHIFT_TYPES_OPTIONS);

                const defaultStaffsFromShifts = this._extractEmployeeData('defaultEmployee');
                const traineesFromShifts = this._extractEmployeeData('traineePerson');

                const payRatesFromShifts = this._extractShiftsData(
                    'payRateId',
                    this.state.payRates,
                );
                const daysFromShifts = this._extractShiftsData('dayOfWeek', DAYS);
                const contractsFromShifts = this._extractShiftsData(
                    'contractTypeId',
                    CONTRACT_TYPE_OPTIONS,
                );
                const agenciesFromShifts = this._extractShiftsData('agencyId', this.state.agencies);
                const weeksFromShifts = this.props.RootStore.shiftPatternsStore.newShiftPattern.weeks.map(
                    (week) => {
                        return {
                            label: week,
                            value: week,
                        };
                    },
                );

                this.setState({
                    locationsFromShifts: locationsFromShifts,
                    rolesFromShifts: rolesFromShifts,
                    fundingPoolsFromShifts: fundingPoolsFromShifts,
                    functionsFromShifts: functionsFromShifts,
                    typesFromShifts: typesFromShifts,
                    defaultStaffsFromShifts: defaultStaffsFromShifts,
                    traineesFromShifts: traineesFromShifts,
                    payRatesFromShifts: payRatesFromShifts,
                    daysFromShifts: daysFromShifts,
                    weeksFromShifts: weeksFromShifts,
                    contractsFromShifts: contractsFromShifts,
                    agenciesFromShifts: agenciesFromShifts,
                });
            };

            // Get unique data from all shifts
            _extractShiftsData = (values, item) =>
                Array.from(
                    new Set(
                        this.props.RootStore.shiftPatternsStore.formattedShifts.map((shift) => {
                            return shift[values];
                        }),
                    ),
                )
                    .filter((x) => x != null && !Array.isArray(x)) //[AM,PM] array check
                    .map((id) => item.find((val) => val.value === id))
                    .filter((x) => x !== undefined);

            _clearFilter = () => {
                this.setState({
                    deps: {},
                });
                this.props.RootStore.shiftPatternsStore.updateFilterDeps({});
            };

            // Get unique data for employees
            _extractEmployeeData = (item) =>
                Array.from(
                    new Set(
                        this.props.RootStore.shiftPatternsStore.formattedShifts.map((shift) => {
                            if (shift[item]) {
                                if (item !== 'traineePerson') {
                                    return (
                                        shift[item]?.person?.firstName &&
                                        `${shift[item].id}$$${shift[item].person.firstName} ${shift[item].person.lastName}`
                                    );
                                } else {
                                    return shift[item]
                                        ? `${shift[item].id}$$${shift[item].firstName} ${shift[item].lastName}`
                                        : undefined;
                                }
                            }
                            return undefined;
                        }),
                    ),
                )
                    .filter((x) => x !== undefined)
                    .map((item) => {
                        const [value, label] = item.split('$$');
                        return { label, value };
                    });

            // Handle logic after particular select change
            _handleShiftsFilter = (values, item) => {
                // Add particular item to dependency
                const filterDeps = {
                    ...this.state.deps,
                    [item]: values,
                };
                // Remove empty item from dependency
                if (filterDeps[item] && !filterDeps[item].length) delete filterDeps[item];

                this.setState(
                    {
                        deps: filterDeps,
                    },
                    () => {
                        return this.props.RootStore.shiftPatternsStore.updateFilterDeps(
                            this.state.deps,
                        );
                    },
                );
            };

            render() {
                const {
                    RootStore: { shiftPatternsStore, dictionaryStore },
                } = this.props;

                const {
                    deps,
                    locationsFromShifts,
                    rolesFromShifts,
                    weeksFromShifts,
                    daysFromShifts,
                    typesFromShifts,
                    defaultStaffsFromShifts,
                    traineesFromShifts,
                    payRatesFromShifts,
                    contractsFromShifts,
                    agenciesFromShifts,
                    fundingPoolsFromShifts,
                    functionsFromShifts,
                    isGridView,
                    savingChanges,
                } = this.state;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Create shift pattern'
                        : `${shiftPatternsStore.newShiftPattern.name}`;
                return (
                    <Formik
                        initialValues={{
                            ...shiftPatternsStore.newShiftPattern,
                        }}
                        validationSchema={validationShiftSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (!values.shiftPatternEntries.length) {
                                return AppToaster.show({
                                    message:
                                        'Please create at least one shift to save shift pattern',
                                    intent: 'danger',
                                });
                            }

                            this.setState({ savingChanges: true });

                            if (this.props.actionType === 'create') {
                                await shiftPatternsStore.createShiftPattern(
                                    shiftPatternsStore.newShiftPattern,
                                );
                            } else {
                                await shiftPatternsStore.updateShiftPattern(
                                    shiftPatternsStore.newShiftPattern,
                                );
                            }
                            this.props.history.replace('/rota/shift-patterns');
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="shift-patterns__form common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        <CommonModalFooter
                                            isSubmitting={isSubmitting}
                                            toggleIsEdit={this._toggleIsEdit}
                                            isEditing={this.state.isEdit}
                                            onCancel={this._handleOnCancel}
                                            onDelete={this._handleAlertClick}
                                            hasDeleteOption={this.state.hasDeleteOption}
                                        />
                                    }
                                    shadow
                                >
                                    {shiftPatternsStore.formattedShifts.length ? (
                                        <>
                                            <div className="rota-filters">
                                                <div className="rota-filters__main">
                                                    <FormGroup
                                                        label="Location"
                                                        className="rota-filter"
                                                    >
                                                        <Select
                                                            isMulti
                                                            value={
                                                                deps['locationId']
                                                                    ? Object.values(
                                                                          deps['locationId'],
                                                                      )
                                                                    : null
                                                            }
                                                            options={locationsFromShifts.sort(
                                                                compareByLabels,
                                                            )}
                                                            onChange={(val) => {
                                                                this._handleShiftsFilter(
                                                                    val,
                                                                    'locationId',
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup label="Role" className="rota-filter">
                                                        <Select
                                                            isMulti
                                                            value={
                                                                deps['shiftEmployeeRoleTypeId']
                                                                    ? Object.values(
                                                                          deps[
                                                                              'shiftEmployeeRoleTypeId'
                                                                          ],
                                                                      )
                                                                    : null
                                                            }
                                                            options={rolesFromShifts.sort(
                                                                compareByLabels,
                                                            )}
                                                            onChange={(val) => {
                                                                this._handleShiftsFilter(
                                                                    val,
                                                                    'shiftEmployeeRoleTypeId',
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup
                                                        label="Function"
                                                        className="rota-filter"
                                                    >
                                                        <Select
                                                            isMulti
                                                            value={
                                                                deps['shiftFunctions']
                                                                    ? Object.values(
                                                                          deps['shiftFunctions'],
                                                                      )
                                                                    : null
                                                            }
                                                            options={functionsFromShifts.sort(
                                                                compareByLabels,
                                                            )}
                                                            onChange={(val) => {
                                                                this._handleShiftsFilter(
                                                                    val,
                                                                    'shiftFunctions',
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {/* Show week number filter only on list view */}
                                                    {!isGridView ? (
                                                        <FormGroup
                                                            label="Week number"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['weekNumber']
                                                                        ? Object.values(
                                                                              deps['weekNumber'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={weeksFromShifts.sort(
                                                                    compareByValues,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'weekNumber',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    ) : null}
                                                    <FormGroup
                                                        label="Day of week"
                                                        className="rota-filter"
                                                    >
                                                        <Select
                                                            isMulti
                                                            value={
                                                                deps['dayOfWeek']
                                                                    ? Object.values(
                                                                          deps['dayOfWeek'],
                                                                      )
                                                                    : null
                                                            }
                                                            options={daysFromShifts.sort(
                                                                compareByValues,
                                                            )}
                                                            onChange={(val) => {
                                                                this._handleShiftsFilter(
                                                                    val,
                                                                    'dayOfWeek',
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <button
                                                        className="rota-filters__secondary-toggle btn btn--outlined"
                                                        type="button"
                                                        onClick={() =>
                                                            this.setState({
                                                                showMoreFilters: !this.state
                                                                    .showMoreFilters,
                                                            })
                                                        }
                                                    >
                                                        <IconFilter />
                                                        {this.state.showMoreFilters
                                                            ? 'Hide'
                                                            : 'Show'}{' '}
                                                        more filters
                                                    </button>

                                                    {!_.isEmpty(deps) && (
                                                        <button
                                                            className="rota-filters__secondary-toggle btn btn--secondary"
                                                            type="button"
                                                            onClick={() => {
                                                                this._clearFilter();
                                                            }}
                                                        >
                                                            Clear filters
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="rota-filters__secondary">
                                                    <Collapse
                                                        keepChildrenMounted={true}
                                                        isOpen={this.state.showMoreFilters}
                                                    >
                                                        <FormGroup
                                                            label="AM/PM"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['timeCover']
                                                                        ? Object.values(
                                                                              deps['timeCover'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={SHIFT_TIME.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'timeCover',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Shift type"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['typeId']
                                                                        ? Object.values(
                                                                              deps['typeId'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={typesFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'typeId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Default staff member"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['defaultEmployeeId']
                                                                        ? Object.values(
                                                                              deps[
                                                                                  'defaultEmployeeId'
                                                                              ],
                                                                          )
                                                                        : null
                                                                }
                                                                options={defaultStaffsFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'defaultEmployeeId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Trainee"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['traineesId']
                                                                        ? Object.values(
                                                                              deps['traineesId'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={traineesFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'traineesId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Pay rate"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['payRateId']
                                                                        ? Object.values(
                                                                              deps['payRateId'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={payRatesFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'payRateId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Contract type"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['contractTypeId']
                                                                        ? Object.values(
                                                                              deps[
                                                                                  'contractTypeId'
                                                                              ],
                                                                          )
                                                                        : null
                                                                }
                                                                options={contractsFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'contractTypeId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Agency"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['agencyId']
                                                                        ? Object.values(
                                                                              deps['agencyId'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={agenciesFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'agencyId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            label="Funding Pool"
                                                            className="rota-filter"
                                                        >
                                                            <Select
                                                                isMulti
                                                                value={
                                                                    deps['fundingPoolId']
                                                                        ? Object.values(
                                                                              deps['fundingPoolId'],
                                                                          )
                                                                        : null
                                                                }
                                                                options={fundingPoolsFromShifts.sort(
                                                                    compareByLabels,
                                                                )}
                                                                onChange={(val) => {
                                                                    this._handleShiftsFilter(
                                                                        val,
                                                                        'fundingPoolId',
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Collapse>
                                                </div>
                                            </div>
                                            {!shiftPatternsStore.filteredShifts.length &&
                                                !_.isEmpty(shiftPatternsStore.filteredDeps) && (
                                                    <b className="bp5-intent-danger">
                                                        <Icon icon="warning-sign" intent="danger" />{' '}
                                                        No filter data !
                                                    </b>
                                                )}
                                        </>
                                    ) : null}
                                    <div className="shift-patterns__info_and_view-variants">
                                        <div className="rota-builder__info">
                                            {this.state.isEdit && (
                                                <div>
                                                    <div className="rota-builder__info-title">
                                                        Name *
                                                    </div>
                                                    <dd className="rota-builder__info-text">
                                                        <FormGroup labelFor="name">
                                                            <InputGroup
                                                                id="name"
                                                                name="name"
                                                                onChange={(e) => {
                                                                    handleChange(e);
                                                                    this._updateNewShiftPatternName(
                                                                        e,
                                                                    );
                                                                }}
                                                                value={
                                                                    shiftPatternsStore
                                                                        .newShiftPattern.name ||
                                                                    values.name
                                                                }
                                                                large
                                                            />
                                                            {touched.name && (
                                                                <Error errors={[errors.name]} />
                                                            )}
                                                        </FormGroup>
                                                    </dd>
                                                </div>
                                            )}
                                            <div>
                                                <dt className="rota-builder__info-title">
                                                    Cost Centre:
                                                </dt>
                                                <dd className="rota-builder__info-text">
                                                    {shiftPatternsStore?.newShiftPattern
                                                        ?.costCentreName
                                                        ? shiftPatternsStore?.newShiftPattern
                                                              ?.costCentreName
                                                        : `${values.costCentre?.name} (${values.costCentre?.code})`}
                                                </dd>
                                            </div>
                                            <div>
                                                <dt className="rota-builder__info-title">
                                                    Department:
                                                </dt>
                                                <dd className="rota-builder__info-text">
                                                    {shiftPatternsStore?.newShiftPattern
                                                        ?.departmentName
                                                        ? shiftPatternsStore?.newShiftPattern
                                                              ?.departmentName
                                                        : `${values.department?.name} (${values.department?.costCode})`}
                                                </dd>
                                            </div>
                                        </div>

                                        <div className="shift-patterns__views-variants">
                                            <TableViewVariants
                                                isGridView={this.state.isGridView}
                                                handleGridClick={this._handleGridClick}
                                                handleListClick={this._handleListClick}
                                            />
                                        </div>
                                    </div>

                                    {this.state.isGridView && (
                                        <ShiftPatternGridView
                                            shiftPattern={shiftPatternsStore.newShiftPattern}
                                            editOrCreateMode={this.state.isEdit}
                                            values={values}
                                            shiftPatternsStore={shiftPatternsStore}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            actionType={this.props.actionType}
                                            errors={errors}
                                            touched={touched}
                                            history={this.props.history}
                                            dictionaryStore={dictionaryStore}
                                            updateShiftPattern={this._updateShiftPattern}
                                            generateFilterOptions={this._generateFilterOptions}
                                        />
                                    )}
                                    {!this.state.isGridView && (
                                        <ShiftPatternListView
                                            shiftPattern={shiftPatternsStore.newShiftPattern}
                                            editOrCreateMode={this.state.isEdit}
                                            values={values}
                                            shiftPatternsStore={shiftPatternsStore}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            actionType={this.props.actionType}
                                            errors={errors}
                                            touched={touched}
                                            history={this.props.history}
                                            dictionaryStore={dictionaryStore}
                                            generateFilterOptions={this._generateFilterOptions}
                                        />
                                    )}
                                    {this.state.showInvalidMessage ? (
                                        <>
                                            <br />
                                            <p className="highlight-warning bp5-rtl">
                                                Please remove empty weeks !
                                            </p>
                                        </>
                                    ) : null}
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this shift? You won't be
                                        able to recover it."
                                />
                                <RouterPrompt
                                    when={!savingChanges && shiftPatternsStore.changesNotSaved}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default ShiftPatternsDetails;
