import moment from 'moment';

export const formatDate = (date?: string) =>
    date ? moment(date, 'DDMMYYYY').format('DD MMM YYYY') : '';

interface GMC {
    arfDueDate?: string;
    designatedBody?: string;
    doctorInTraining?: string;
    fileReference?: string;
    frDate?: string;
    ftpConditionsExist?: string;
    ftpUndertakingsExist?: string;
    ftpWarningsExist?: string;
    gender?: string;
    givenName?: string;
    gmcApprovedTrainer?: string;
    gpRegisterDate?: string;
    otherNames?: string;
    placeOfQualification?: string;
    placeOfQualificationCountry?: string;
    prDate?: string;
    professionalBodyId?: string;
    qualification?: string;
    registrationStatus?: string;
    responsibleOfficer?: string;
    revalidationStatus?: string;
    specialistRegisterDate?: string;
    specialty1?: string;
    specialty2?: string;
    specialty3?: string;
    specialty4?: string;
    specialty5?: string;
    specialty6?: string;
    specialty7?: string;
    subSpecialty1?: string;
    subSpecialty2?: string;
    subSpecialty3?: string;
    subSpecialty4?: string;
    subSpecialty5?: string;
    subSpecialty6?: string;
    subSpecialty7?: string;
    surname?: string;
    trainerDeaneryLetb1?: string;
    trainerDeaneryLetb2?: string;
    trainerDeaneryLetb3?: string;
    trainerProgrammesSpecialty1?: string;
    trainerProgrammesSpecialty2?: string;
    trainerProgrammesSpecialty3?: string;
    trainingDeaneryLetb?: string;
    trainingProgrammeSpecialty?: string;
    yearOfQualification?: string;
}

const GmcDetails = ({
    arfDueDate,
    designatedBody,
    doctorInTraining,
    fileReference,
    frDate,
    ftpConditionsExist,
    ftpUndertakingsExist,
    ftpWarningsExist,
    gender,
    givenName,
    gmcApprovedTrainer,
    gpRegisterDate,
    otherNames,
    placeOfQualification,
    placeOfQualificationCountry,
    prDate,
    professionalBodyId,
    qualification,
    registrationStatus,
    responsibleOfficer,
    revalidationStatus,
    specialistRegisterDate,
    specialty1,
    specialty2,
    specialty3,
    specialty4,
    specialty5,
    specialty6,
    specialty7,
    subSpecialty1,
    subSpecialty2,
    subSpecialty3,
    subSpecialty4,
    subSpecialty5,
    subSpecialty6,
    subSpecialty7,
    surname,
    trainerDeaneryLetb1,
    trainerDeaneryLetb2,
    trainerDeaneryLetb3,
    trainerProgrammesSpecialty1,
    trainerProgrammesSpecialty2,
    trainerProgrammesSpecialty3,
    trainingDeaneryLetb,
    trainingProgrammeSpecialty,
    yearOfQualification,
}: GMC) => (
    <>
        <h3 className="h3 modal__sub-heading">Key Details</h3>
        <dl className="info">
            <dt className="info__title">GMC Ref No</dt>
            <dd className="info__definition">{professionalBodyId}</dd>
            <dt className="info__title">Given Name</dt>
            <dd className="info__definition">{givenName}</dd>
            <dt className="info__title">Other Names</dt>
            <dd className="info__definition">{otherNames}</dd>
            <dt className="info__title">Surname</dt>
            <dd className="info__definition">{surname}</dd>
            <dt className="info__title">Registration Status</dt>
            <dd className="info__definition">{registrationStatus}</dd>
            <dt className="info__title">Revalidation Status</dt>
            <dd className="info__definition">{revalidationStatus}</dd>
            <dt className="info__title">FTP Conditions Exist</dt>
            <dd className="info__definition">{ftpConditionsExist}</dd>
            <dt className="info__title">FTP Undertakings Exist</dt>
            <dd className="info__definition">{ftpUndertakingsExist}</dd>
            <dt className="info__title">FTP Warnings Exist</dt>
            <dd className="info__definition">{ftpWarningsExist}</dd>
        </dl>
        <h3 className="h3 modal__sub-heading">Additional Details</h3>
        <dl className="info">
            <dt className="info__title">ARF Due Date</dt>
            <dd className="info__definition">{formatDate(arfDueDate)}</dd>
            <dt className="info__title">Designated Body</dt>
            <dd className="info__definition">{designatedBody}</dd>
            <dt className="info__title">Doctor In Training</dt>
            <dd className="info__definition">{doctorInTraining}</dd>
            <dt className="info__title">FR Date</dt>
            <dd className="info__definition">{formatDate(frDate)}</dd>
            <dt className="info__title">Gender</dt>
            <dd className="info__definition">{gender}</dd>
            <dt className="info__title">GMC Approved Trainer</dt>
            <dd className="info__definition">{gmcApprovedTrainer}</dd>
            <dt className="info__title">GP Register Date</dt>
            <dd className="info__definition">{formatDate(gpRegisterDate)}</dd>
            <dt className="info__title">Place of Qualification</dt>
            <dd className="info__definition">{placeOfQualification}</dd>
            <dt className="info__title">Place of Qualification Country</dt>
            <dd className="info__definition">{placeOfQualificationCountry}</dd>
            <dt className="info__title">PR Date</dt>
            <dd className="info__definition">{formatDate(prDate)}</dd>
            <dt className="info__title">Qualification</dt>
            <dd className="info__definition">{qualification}</dd>
            <dt className="info__title">Responsible Officer</dt>
            <dd className="info__definition">{responsibleOfficer}</dd>
            <dt className="info__title">Specialist Register Date</dt>
            <dd className="info__definition">{formatDate(specialistRegisterDate)}</dd>
            <dt className="info__title">Specialty 1</dt>
            <dd className="info__definition">{specialty1}</dd>
            <dt className="info__title">Specialty 2</dt>
            <dd className="info__definition">{specialty2}</dd>
            <dt className="info__title">Specialty 3</dt>
            <dd className="info__definition">{specialty3}</dd>
            <dt className="info__title">Specialty 4</dt>
            <dd className="info__definition">{specialty4}</dd>
            <dt className="info__title">Specialty 5</dt>
            <dd className="info__definition">{specialty5}</dd>
            <dt className="info__title">Specialty 6</dt>
            <dd className="info__definition">{specialty6}</dd>
            <dt className="info__title">Specialty 7</dt>
            <dd className="info__definition">{specialty7}</dd>
            <dt className="info__title">Sub-specialty 1</dt>
            <dd className="info__definition">{subSpecialty1}</dd>
            <dt className="info__title">Sub-specialty 2</dt>
            <dd className="info__definition">{subSpecialty2}</dd>
            <dt className="info__title">Sub-specialty 3</dt>
            <dd className="info__definition">{subSpecialty3}</dd>
            <dt className="info__title">Sub-specialty 4</dt>
            <dd className="info__definition">{subSpecialty4}</dd>
            <dt className="info__title">Sub-specialty 5</dt>
            <dd className="info__definition">{subSpecialty5}</dd>
            <dt className="info__title">Sub-specialty 6</dt>
            <dd className="info__definition">{subSpecialty6}</dd>
            <dt className="info__title">Sub-specialty 7</dt>
            <dd className="info__definition">{subSpecialty7}</dd>
            <dt className="info__title">Trainer Deanery/LETB 1</dt>
            <dd className="info__definition">{trainerDeaneryLetb1}</dd>
            <dt className="info__title">Trainer Deanery/LETB 2</dt>
            <dd className="info__definition">{trainerDeaneryLetb2}</dd>
            <dt className="info__title">Trainer Deanery/LETB 3</dt>
            <dd className="info__definition">{trainerDeaneryLetb3}</dd>
            <dt className="info__title">Trainer Programmes Specialty 1</dt>
            <dd className="info__definition">{trainerProgrammesSpecialty1}</dd>
            <dt className="info__title">Trainer Programmes Specialty 2</dt>
            <dd className="info__definition">{trainerProgrammesSpecialty2}</dd>
            <dt className="info__title">Trainer Programmes Specialty 3</dt>
            <dd className="info__definition">{trainerProgrammesSpecialty3}</dd>
            <dt className="info__title">Training Deanery/LETB</dt>
            <dd className="info__definition">{trainingDeaneryLetb}</dd>
            <dt className="info__title">Training Programme Specialty</dt>
            <dd className="info__definition">{trainingProgrammeSpecialty}</dd>
            <dt className="info__title">Year Of Qualification</dt>
            <dd className="info__definition">{yearOfQualification}</dd>
        </dl>
    </>
);

export default GmcDetails;
