import { FC, ReactNode } from 'react';
import cn from 'classnames';

export type calloutIntent = 'success' | 'warning' | 'danger' | 'primary';

interface IProps {
    children: ReactNode;
    intent?: calloutIntent;
    Icon?: FC;
    urgent?: boolean;
    spacerTop?: boolean;
    spacerBottom?: boolean;
    compact?: boolean;
}

const Callout: FC<IProps> = ({
    children,
    intent,
    Icon,
    urgent = false,
    spacerTop = false,
    spacerBottom = false,
    compact = false,
}) => {
    return (
        <aside
            className={cn('v2-callout', {
                [`v2-callout--${intent}`]: intent,
                'v2-callout--spacer-top': spacerTop,
                'v2-callout--spacer-bottom': spacerBottom,
                'v2-callout--compact': compact,
            })}
        >
            {Icon && (
                <span
                    className={cn('v2-callout__icon', {
                        'v2-callout__icon--danger': intent === 'danger',
                    })}
                >
                    <Icon />
                </span>
            )}
            <span
                className={cn('v2-callout__text', {
                    'v2-callout__text--danger': intent === 'danger',
                })}
            >
                {children}
            </span>
        </aside>
    );
};

export default Callout;
