/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import ShiftDialog from './Dialogs/ShiftDialog';
import SplitDialog from './Dialogs/SplitDialog';

import WeekGrid from './gridView/Week';
import ListRow from './listView/Row';
import { getNumberOfWeeks, numToTime } from './helper';
import moment from 'moment';
import _, { groupBy } from 'lodash';
import { Button, Checkbox, Menu, MenuItem, Tab, Tabs, Popover } from '@blueprintjs/core';
import { daysBetweenNegativeIncluded } from '../../../../modules/helpers/formatData';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import SelectPaymentDialog from './SelectPaymentDialog';
import {
    DAYS,
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_BANK_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    SHIFT_TYPE_EXTRA_ID,
    SHIFT_TYPE_FLEXI_ID,
    SHIFT_TYPE_NON_CONTRACTED_ID,
    SHIFT_TYPE_SICK_ID,
    SHIFT_TYPE_UNPAID_ID,
    PAYMENT_STATUS_PLANED,
} from '../../../../../constants/rotaConst';
import { EMPLOYEE_RECORD_STATUS_OPTIONS } from '../../../../../constants/hrConst';
import { formatTime } from '../../../../modules/helpers/FormatTime';
import AppToaster from '../../../../modules/helpers/Toaster';

const Calendar = forwardRef((props: any, ref) => {
    useImperativeHandle(ref, () => ({
        setGridConfirmStaffDialogOpen: () => {
            setGridConfirmStaffDialogOpen(true);
        },
    }));

    const {
        loading,
        deps,
        dateRange,
        department,
        locations,
        staffStore,
        rotaBuilderStore,
        onStaffSearch,
        shifts,
        filteredShifts,
        onChange,
        shiftTypes,
        shiftStatuses,
        isGridView,
        updateFilter,
        shiftPatternStartWeek,
        appliedShiftPatternWeeksCount,
        holidaysStore,
        dictionaryStore,
        actionType,
        updateRotaShiftComments,
        getShiftConflicts,
        onUpdateConflicts,
        currentUser,
        functionsStore,
        fundingPoolStore,
        isPublished,
        paymentStatusOptions,
        isViewMode,
        applyShiftPattern,
    } = props;

    const [entriesToShow, setEntriesToShow] = React.useState(filteredShifts);
    const [entries, setEntries] = React.useState(shifts);
    const [sortTarget, setSortTarget] = React.useState('');
    const [sortDir, setSortDir] = React.useState('');
    const [splitEntry, setSplitEntry] = React.useState({});
    const [shiftDialogOpen, setShiftDialogOpen] = React.useState(false);
    const [splitDialogOpen, setSplitDialogOpen] = React.useState(false);
    const [gridConfirmStaffDialogOpen, setGridConfirmStaffDialogOpen] = React.useState(false);
    const [listConfirmStaffDialogOpen, setListConfirmStaffDialogOpen] = React.useState(false);
    const [currentWeekNumber, setCurrentWeekNumber] = React.useState(1);
    const [currentDayOfWeek, setCurrentDayOfWeek] = React.useState(1);
    const [editItem, setEditItem] = React.useState(null);
    const [numberOfWeeks, setNumberOfWeeks] = React.useState(0);
    const [activeTab, setActiveTab] = React.useState(1);
    const [appliedShiftStart, setAppliedShiftStart] = React.useState(0);
    const [selectedListViewShifts, setSelectedListViewShifts] = React.useState(['']);
    const [allItemsSelected, setAllItemsSelected] = React.useState(false);
    const [alertPopUp, setAlertPopUp] = React.useState({
        showAlert: false,
        alertText: '',
        alertButtonText: '',
        alertIcon: '',
        alertIntent: '',
    });
    const [fundingPoolsOptions, setFundingPoolsOptions] = React.useState([] as any);
    const [paymentDialog, setPaymentDialog] = React.useState(false);
    const [shiftIdsForPaymentDialog, setShiftIdsForPaymentDialog] = React.useState([] as any);
    const [activePaymentStatusId, setActivePaymentStatusId] = React.useState('');
    const [paymentStatusNormalized, setPaymentStatusNormalized] = React.useState({} as any);

    const {
        shiftTypesDictionary,
        shiftStatusesDictionary,
        payRatesDictionary,
        rolesDictionary,
    } = dictionaryStore;

    React.useEffect(() => {
        setEntries(shifts);
        setEntriesToShow(filteredShifts);
        setAppliedShiftStart(shiftPatternStartWeek);
    }, [shifts, filteredShifts]);

    React.useEffect(() => {
        const startDate = dateRange[0];
        const endDate = dateRange[1];

        if (startDate > endDate) return;

        if (!startDate || !endDate) return;

        const numberOfWeeks = getNumberOfWeeks(startDate, endDate);
        setNumberOfWeeks(numberOfWeeks);
        onDateChangeHolidayCheck(startDate, endDate);
        setActiveTab(1);
    }, [dateRange]);

    React.useEffect(() => {
        const paymentStatuses = {} as any;
        paymentStatusOptions?.forEach((item: any) => {
            paymentStatuses[item.value] = item.label;
        });
        setPaymentStatusNormalized(paymentStatuses);
    }, [paymentStatusOptions]);

    React.useEffect(() => {
        const shiftsToCheck = !_.isEmpty(deps) ? entriesToShow : entries;
        if (!shiftsToCheck.length) return;
        const allChecked = shiftsToCheck.every((item: any) =>
            selectedListViewShifts.includes(item.id),
        );
        setAllItemsSelected(allChecked);
    }, [selectedListViewShifts]);

    React.useEffect(() => {
        const updateFundingPoolsOptions = async () => {
            const fundingPoolsOptions = await fundingPoolStore.getFundingPoolsOptions();
            setFundingPoolsOptions(fundingPoolsOptions);
        };
        updateFundingPoolsOptions();
    }, []);

    const onDateChangeHolidayCheck = async (start: Date, end: Date) => {
        !loading && (await holidaysStore.getHolidays(start, end));
    };

    const onDeleteEntry = (id: string) => {
        rotaBuilderStore.updateChangesState(true);
        const newEntries = entries.filter((r: any) => r.id !== id);
        setEntries(newEntries);
        onChange(newEntries);
    };

    const onEntrySplit = (id: string) => {
        rotaBuilderStore.updateChangesState(true);
        const entry = entries.find((r: any) => r.id === id);
        setSplitDialogOpen(true);
        setSplitEntry(entry);
    };

    const onGridConfirmStaff = async () => {
        const entriesToShowConfirmedStaffUpdated = entries.map((entry: any) => {
            return {
                ...entry,
                confirmedEmployeeId:
                    entriesToShow.some((item: any) => item.id === entry.id) &&
                    entry.defaultEmployeeId &&
                    !entry.confirmedEmployeeId
                        ? entry.defaultEmployeeId
                        : entry.confirmedEmployeeId,
            };
        });
        const entriesConfirmedStaffUpdated = entries.map((entry: any) => {
            return {
                ...entry,
                confirmedEmployeeId:
                    entry.defaultEmployeeId && !entry.confirmedEmployeeId
                        ? entry.defaultEmployeeId
                        : entry.confirmedEmployeeId,
            };
        });
        setSortTarget('');
        await setEntries(entriesConfirmedStaffUpdated);
        await setEntriesToShow(entriesToShowConfirmedStaffUpdated);
        onChange(
            entriesToShow.length < entries.length
                ? entriesToShowConfirmedStaffUpdated
                : entriesConfirmedStaffUpdated,
        );
        updateFilter(
            entriesToShow.length < entries.length
                ? entriesToShowConfirmedStaffUpdated
                : entriesConfirmedStaffUpdated,
        );
        rotaBuilderStore.updateChangesState(true);
        setGridConfirmStaffDialogOpen(false);
    };

    const onListConfirmStaff = async () => {
        const entriesToShowConfirmedStaffUpdated = entries.map((entry: any) => {
            return {
                ...entry,
                confirmedEmployeeId:
                    entriesToShow.some((item: any) => item.id === entry.id) &&
                    selectedListViewShifts.includes(entry.id) &&
                    entry.defaultEmployeeId &&
                    !entry.confirmedEmployeeId
                        ? entry.defaultEmployeeId
                        : entry.confirmedEmployeeId,
            };
        });
        const entriesConfirmedStaffUpdated = entries.map((entry: any) => {
            return {
                ...entry,
                confirmedEmployeeId:
                    selectedListViewShifts.includes(entry.id) &&
                    entry.defaultEmployeeId &&
                    !entry.confirmedEmployeeId
                        ? entry.defaultEmployeeId
                        : entry.confirmedEmployeeId,
            };
        });
        await setEntries(entriesConfirmedStaffUpdated);
        await setEntriesToShow(entriesToShowConfirmedStaffUpdated);
        onChange(
            entriesToShow.length < entries.length
                ? entriesToShowConfirmedStaffUpdated
                : entriesConfirmedStaffUpdated,
        );
        updateFilter(
            entriesToShow.length < entries.length
                ? entriesToShowConfirmedStaffUpdated
                : entriesConfirmedStaffUpdated,
        );
        setSortTarget('');
        rotaBuilderStore.updateChangesState(true);
        setListConfirmStaffDialogOpen(false);
    };

    const openPaymentDialog = (shiftIds: string[], statusId: string) => {
        setActivePaymentStatusId(statusId);
        setPaymentDialog(true);
        setShiftIdsForPaymentDialog(shiftIds);
    };

    const onChangePaymentStatus = (statusId: string) => {
        if (shiftIdsForPaymentDialog.length === 1) {
            const index = entries.findIndex((r: any) => r.id === shiftIdsForPaymentDialog[0]);
            if (index > -1) {
                entries[index].paymentStatusId = statusId;
            }
        }

        if (shiftIdsForPaymentDialog.length > 1) {
            shiftIdsForPaymentDialog.forEach((id: string) => {
                const index = entries.findIndex((r: any) => r.id === id);
                if (index > -1) {
                    entries[index].paymentStatusId = statusId;
                }
            });
        }
        onChange(entries);
        AppToaster.show({
            message: 'The status has been changed successfully',
            intent: 'success',
        });
        setPaymentDialog(false);
        setShiftIdsForPaymentDialog([]);
        setActivePaymentStatusId('');
    };

    const getStaffName = (id: string) => {
        const staffEntity = department.employees.find((sr: any) => id === sr.value);

        return staffEntity ? staffEntity.label : null;
    };

    const staffList = staffStore.allStaffs.reduce((acc: any, item: any) => {
        acc[item.id] = item.person.firstName + ' ' + item.person.lastName;
        return acc;
    }, {});

    const dt = new Date();

    const workStatus = (employee: string) => {
        if (!employee) return null;
        const person = staffStore.staffs.filter((item: any) => item.id === employee);
        if (person && person.length > 0) {
            const statusObj = EMPLOYEE_RECORD_STATUS_OPTIONS.filter(
                (obj: any) => obj.value === person[0].statusId,
            );
            return statusObj[0].label;
        }
    };

    const getEntriesSortingUpdated = (val: any) =>
        val.map((entry: any) => {
            const dayOfWeek = moment(dateRange[0]).toDate().getDay();
            const startDate = moment(dateRange[0])
                .add((entry.weekNumber - 1) * 7 + entry.dayOfWeek - dayOfWeek, 'days')
                .toDate();
            const confirmedEmployeeStatus = workStatus(entry.confirmedEmployeeId);
            const daysBetweenDates = daysBetweenNegativeIncluded(startDate, dt);

            const contractedWarning =
                (entry.typeId === SHIFT_TYPE_CONTRACTED_ID &&
                    !entry.confirmedEmployeeId &&
                    daysBetweenDates < 4 &&
                    entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
                ((entry.typeId === SHIFT_TYPE_CONTRACTED_ID ||
                    entry.typeId === SHIFT_TYPE_EXTRA_ID ||
                    entry.typeId === SHIFT_TYPE_FLEXI_ID ||
                    entry.typeId === SHIFT_TYPE_SICK_ID ||
                    entry.typeId === SHIFT_TYPE_UNPAID_ID ||
                    entry.typeId === SHIFT_TYPE_NON_CONTRACTED_ID) &&
                    (!entry.confirmedEmployeeId ||
                        confirmedEmployeeStatus === 'Archived' ||
                        confirmedEmployeeStatus === 'Suspended') &&
                    entry.statusId === SHIFT_STATUS_STANDARD_ID);

            const bankWarning =
                (entry.typeId === SHIFT_TYPE_BANK_ID &&
                    !entry.confirmedEmployeeId &&
                    daysBetweenDates < 4 &&
                    entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
                (entry.typeId === SHIFT_TYPE_BANK_ID &&
                    !entry.confirmedEmployeeId &&
                    daysBetweenDates < 4 &&
                    entry.statusId === SHIFT_STATUS_STANDARD_ID);

            const warning = contractedWarning || bankWarning;

            const requiresAttention = warning && daysBetweenDates < 3;

            const shiftDuration = new Date(entry.endTime.getTime() - entry.startTime.getTime())
                .toISOString()
                .substring(11, 16);
            const netDuration = entry.breaksPaid
                ? new Date(entry.endTime.getTime() - entry.startTime.getTime())
                      .toISOString()
                      .substring(11, 16)
                : new Date(
                      entry.endTime.getTime() -
                          entry.startTime.getTime() -
                          1000 * (60 * entry.breakDurationMinutes),
                  )
                      .toISOString()
                      .substring(11, 16);

            let status = 0;

            if (!warning && !requiresAttention) {
                status = 1; //Green
            } else if (warning && !requiresAttention) {
                status = 2; // Amber
            } else if (requiresAttention && warning) {
                status = 3; // Red
            }

            return {
                ...entry,
                sortStatus: status,
                sortWeek: entry.weekNumber,
                sortDay: DAYS[entry.dayOfWeek - 1].label,
                sortDate: entry.startDate,
                sortRole: department.roles.find((rr: any) => entry.roleId === rr.value).label,
                sortDefaultEmployee: getStaffName(entry.defaultEmployeeId),
                sortConfirmedEmployee: getStaffName(entry.confirmedEmployeeId),
                sortTrainee: entry.trainees[0]?.traineeId
                    ? staffList[entry.trainees[0]?.traineeId]
                    : null,
                sortShiftType: shiftTypesDictionary[entry.typeId],
                sortShiftStatus: shiftStatusesDictionary[entry.statusId],
                sortStartTime: formatTime(entry.startTime),
                sortEndTime: formatTime(entry.endTime),
                sortShiftDuration: shiftDuration,
                sortBreakDuration: entry.breakDurationMinutes ? entry.breakDurationMinutes : null,
                sortBreakIsPaid: entry.breaksPaid ? 1 : 0,
                sortPaidBreakDuration:
                    entry.breaksPaid && entry.breakDurationMinutes > 0
                        ? numToTime(entry.breakDurationMinutes)
                        : null,
                sortNet: netDuration,
                sortPayRate: payRatesDictionary[entry.payRateId],
                sortSource: entry.isAdditional ? 0 : 1,
                sortContractType: entry.confirmedEmployee?.contractType?.name
                    ? entry.confirmedEmployee?.contractType?.name
                    : entry.defaultEmployee?.contractType?.name
                    ? entry.defaultEmployee?.contractType?.name
                    : null,
                sortAgency: entry.confirmedEmployee?.agency?.name
                    ? entry.confirmedEmployee?.agency?.name
                    : entry.defaultEmployee?.agency?.name
                    ? entry.defaultEmployee?.agency?.name
                    : null,
            };
        });

    const onTableSort = (val?: any) => {
        const activeFilterEntries = !_.isEmpty(deps);
        if (val !== sortTarget) {
            activeFilterEntries
                ? setEntriesToShow(
                      _.orderBy(getEntriesSortingUpdated(filteredShifts), [val], 'asc'),
                  )
                : setEntries(_.orderBy(getEntriesSortingUpdated(entries), [val], 'asc'));
            setSortDir('desc');
        } else {
            activeFilterEntries
                ? setEntriesToShow(
                      _.orderBy(getEntriesSortingUpdated(filteredShifts), [val], [sortDir as any]),
                  )
                : setEntries(_.orderBy(getEntriesSortingUpdated(entries), [val], [sortDir as any]));
        }
        if (val === sortTarget && sortDir === '') {
            activeFilterEntries ? setEntriesToShow(filteredShifts) : setEntries(shifts);
            setSortDir('asc');
        } else if (sortDir === 'asc') {
            setSortDir('desc');
        } else if (val === sortTarget && sortDir === 'desc') {
            setSortDir('');
        }
    };

    const onSplitSubmit = (splitBy: number) => {
        //Get value from split dialog and convert to hours:minutes
        const splitTime = moment.utc().startOf('day').add(splitBy, 'minutes');
        //Convert time to date
        const splitTimeToDate = moment((splitEntry as any)?.endTime)
            .set({
                hour: splitTime.get('hour'),
                minute: splitTime.get('minute'),
            })
            .toDate();

        //Duplicate splitting shift entry
        const newEntryAfterSplit = _.cloneDeep({
            ...splitEntry,
            id: uuid(),
            startTime: splitTimeToDate as any,
            endTime: (splitEntry as any)?.endTime,
            overrideValueInPence: '',
        });

        //Update splitting shift entry with new time
        const entry = entries.find((r: any) => r.id === (splitEntry as any)?.id);
        entry.endTime = splitTimeToDate;
        entry.overrideValueInPence = '';
        const newEntries = [newEntryAfterSplit, ...entries];

        setEntries(newEntries);
        setEntriesToShow([newEntryAfterSplit, ...filteredShifts]);
        onChange(newEntries);
        setSplitDialogOpen(false);
        setSplitEntry({});
    };

    const handleAddItem = (weekNumber: number, dayOfWeek: number) => {
        setEditItem(null);
        setCurrentWeekNumber(weekNumber);
        setCurrentDayOfWeek(dayOfWeek);
        setShiftDialogOpen(true);
    };

    const weekItems: any = [];

    const getTitleDates = () => {
        let daysRange: any = [];
        let end = dateRange[1] && _.clone(dateRange[1]).setDate(dateRange[1].getDate() + 1);

        if (dateRange[0])
            for (
                let start = dateRange[0] && _.clone(dateRange[0]);
                start <= end;
                start.setDate(start.getDate() + 1)
            ) {
                daysRange = [...daysRange, start.toString()];
            }

        const weeks = groupBy(daysRange, (dt) => moment(dt).isoWeek());

        return Object.values(weeks).sort((a, b) => {
            return Date.parse(a[0]) - Date.parse(b[0]);
        });
    };

    const getTitleNumbers = () => {
        let numbs: any = [];
        for (let i = 1; i <= appliedShiftPatternWeeksCount; i++) {
            numbs = [...numbs, i];
        }
        return numbs;
    };

    const makeRepeatedArray = (arr: any, repeats: number) =>
        Array.from(
            {
                length: repeats,
            },
            () => arr,
        ).flat();

    const titleNumbers = makeRepeatedArray(getTitleNumbers(), numberOfWeeks).splice(
        appliedShiftStart,
    );

    const getStaffAgencyAndContractType = async (id: string) =>
        await staffStore.getStaffRecordAgencyAndContractTypeById(id);

    for (let i = 0; i < numberOfWeeks; i++) {
        let startDay = i === 0 && dateRange[0] ? dateRange[0].getDay() : null;
        if (startDay === 0) startDay = 7;

        const endDay = i === numberOfWeeks - 1 && dateRange[1] ? dateRange[1].getDay() : null;

        const appliedShitNumber = titleNumbers?.find((item, index) => index === i && item);

        const tabTitle = getTitleDates().map(
            (item, index) =>
                index === i &&
                `${moment(item[0]).format('DD MMM')} - ${moment(item[item.length - 1]).format(
                    'DD MMM',
                )} ${
                    shiftPatternStartWeek != null
                        ? `${appliedShitNumber ? `(${appliedShitNumber})` : ''}`
                        : ''
                }`,
        );

        isGridView &&
            weekItems.push(
                <Tab
                    id={i + 1}
                    key={i}
                    title={tabTitle}
                    panel={
                        <WeekGrid
                            key={i}
                            weekNumber={i + 1}
                            department={department}
                            entries={!_.isEmpty(deps) ? entriesToShow : entries}
                            onAdd={handleAddItem}
                            onDutyStationClick={(ds: any) => {
                                setEditItem(ds);
                                setShiftDialogOpen(true);
                            }}
                            rotaStartDate={dateRange[0]}
                            startDay={startDay}
                            endDay={endDay}
                            isViewMode={isViewMode}
                            locations={locations}
                            onDelete={onDeleteEntry}
                            onSplit={onEntrySplit}
                            shiftTypes={shiftTypes}
                            shiftStatuses={shiftStatuses}
                            staffStore={staffStore}
                            payRatesDictionary={payRatesDictionary}
                            rolesDictionary={rolesDictionary}
                            holidayDates={holidaysStore.holidayDates}
                            isPublished={isPublished}
                            openPaymentDialog={openPaymentDialog}
                            paymentStatusNormalized={paymentStatusNormalized}
                        />
                    }
                />,
            );
    }

    const handleToggleCheckbox = (event: any) => {
        if (event.target.checked) {
            setSelectedListViewShifts((prev) => [...prev, event.target.id]);
        } else {
            setSelectedListViewShifts((prev) => prev.filter((item) => item !== event.target.id));
        }
    };

    const handleToggleSelectAll = (event: any) => {
        if (event.target.checked) {
            const shiftIds = entriesToShow.map((entry: any) => entry.id);
            setSelectedListViewShifts(shiftIds);
            setAllItemsSelected(true);
        } else {
            setSelectedListViewShifts([]);
            setAllItemsSelected(false);
        }
    };

    const handleToggleAlert = (action: string) => {
        if (!selectedListViewShifts.length) return;
        switch (action) {
            case 'markAsPaid':
                setAlertPopUp({
                    showAlert: true,
                    alertText: 'Are you sure you want to mark the selected shifts as paid?',
                    alertButtonText: 'Mark as paid',
                    alertIcon: 'credit-card',
                    alertIntent: 'success',
                });
                break;
            case 'markAsUnpaid':
                setAlertPopUp({
                    showAlert: true,
                    alertText: 'Are you sure you want to mark the selected shifts as unpaid?',
                    alertButtonText: 'Mark as unpaid',
                    alertIcon: 'credit-card',
                    alertIntent: 'warning',
                });
                break;
            case 'delete':
                setAlertPopUp({
                    showAlert: true,
                    alertText: 'Are you sure you want to delete the selected shifts?',
                    alertButtonText: 'Delete',
                    alertIcon: 'trash',
                    alertIntent: 'danger',
                });
                break;
            case 'sendToOnDemand':
                return;
            default:
                return;
        }
    };

    const handleBulkAction = () => {
        switch (alertPopUp.alertButtonText) {
            case 'Mark as paid':
                return onBulkMarkPayments(true);
            case 'Mark as unpaid':
                return onBulkMarkPayments(false);
            case 'Delete':
                return onBulkDelete();
            case 'sendToOnDemand':
                return;
            default:
                return;
        }
    };

    const onBulkDelete = () => {
        const newEntries = entries.filter((r: any) => !selectedListViewShifts.includes(r.id));
        setEntries(newEntries);
        onChange(newEntries);
        setSelectedListViewShifts([]);
        setAllItemsSelected(false);
        setAlertPopUp((prev) => ({ ...prev, showAlert: false }));
        rotaBuilderStore.updateChangesState(true);
    };

    const onBulkMarkPayments = (flag: boolean) => {
        entries.forEach((entry: any) => {
            if (selectedListViewShifts.includes(entry.id)) {
                entry.isPaid = flag;
            }
        });
        setEntries(entries);
        onChange(entries);
        setSelectedListViewShifts([]);
        setAllItemsSelected(false);
        setAlertPopUp((prev) => ({ ...prev, showAlert: false }));
    };

    const ContentProps = {
        shouldDismissPopover: false,
    };

    const Content = (shouldDismissPopover: any) => (
        <Menu>
            <MenuItem
                disabled={!department || isPublished}
                onClick={applyShiftPattern}
                text="Apply shift pattern"
            />
            {isPublished ? (
                <MenuItem
                    disabled={!selectedListViewShifts.some(Boolean)}
                    className="bp5-intent-primary"
                    onClick={() => openPaymentDialog(selectedListViewShifts, '')}
                    text="Set payment status"
                />
            ) : null}
            <MenuItem
                disabled={!selectedListViewShifts.some(Boolean)}
                className="bp5-intent-danger"
                onClick={() => handleToggleAlert('delete')}
                text="Delete shift"
            />
            <MenuItem
                disabled={!selectedListViewShifts.some(Boolean)}
                onClick={() => handleToggleAlert('sendToOnDemand')}
                text="Send to on-demand"
            />
            <MenuItem
                disabled={!selectedListViewShifts.some(Boolean)}
                onClick={() => {
                    setListConfirmStaffDialogOpen(true);
                }}
                text="Confirm default staff"
            />
        </Menu>
    );

    const functionsDictionary = {} as any;
    functionsStore.allFunctions.forEach((func: any) => {
        functionsDictionary[func.id] = func.name;
    });

    const fundingPoolsDictionary = {} as any;
    fundingPoolStore.allFundingPools.forEach((fundPool: any) => {
        fundingPoolsDictionary[fundPool.id] = fundPool.name;
    });

    return (
        <div>
            {isGridView ? (
                <div className={'rota-builder__weeks'}>
                    <Tabs
                        className={'tabs-new tabs-new--rota-builder'}
                        id="rota-builder__tabs"
                        defaultSelectedTabId={activeTab}
                        onChange={(newTabId: any) => {
                            setActiveTab(newTabId);
                        }}
                        large
                        selectedTabId={activeTab}
                    >
                        {weekItems}
                    </Tabs>
                </div>
            ) : (
                <div className="rota-builder-lv-wrapper">
                    {!isViewMode && (
                        <div className="rota-builder-lv-buttons-wrap">
                            <button
                                onClick={() => {
                                    setEditItem(null);
                                    setShiftDialogOpen(true);
                                }}
                                className="rota-builder-lv-add-shift btn btn--outlined btn--outlined-active"
                            >
                                + Add shift
                            </button>
                            <Popover
                                enforceFocus={false}
                                placement="bottom-start"
                                interactionKind="click"
                                className="rota-builder-lv-add-shift "
                                content={<Content {...ContentProps} />}
                                renderTarget={({ isOpen, ref, ...p }: any) => (
                                    <Button
                                        {...p}
                                        active={isOpen}
                                        elementRef={ref}
                                        rightIcon="chevron-down"
                                        text="Actions"
                                        className="btn btn--outlined"
                                    />
                                )}
                            />
                        </div>
                    )}

                    <table className="bp5-html-table bp5-interactive common-table common-table--list-view">
                        <thead>
                            <tr
                                onClick={(e) => {
                                    const dataVal = (e.target as any).offsetParent.getAttribute(
                                        'data-sort',
                                    );
                                    if (dataVal) {
                                        setSortTarget(dataVal);
                                        onTableSort(dataVal);
                                    }
                                }}
                            >
                                {!isViewMode && (
                                    <th>
                                        <Checkbox
                                            checked={allItemsSelected}
                                            className="shift-pattern-select-all-checkbox"
                                            onChange={(e) => handleToggleSelectAll(e)}
                                            onClick={(event) => event.stopPropagation()}
                                        />
                                    </th>
                                )}
                                <th data-sort={'sortStatus'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Status
                                    </Button>
                                </th>
                                <th data-sort={'sortDay'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Day
                                    </Button>
                                </th>
                                <th data-sort={'sortDate'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Date
                                    </Button>
                                </th>
                                <th data-sort={'sortWeek'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Week number
                                    </Button>
                                </th>
                                <th data-sort={'sortRole'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Role
                                    </Button>
                                </th>
                                <th data-sort={'sortStartTime'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Start time
                                    </Button>
                                </th>
                                <th data-sort={'sortEndTime'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        End time
                                    </Button>
                                </th>
                                <th data-sort={'sortFunction'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Function
                                    </Button>
                                </th>
                                <th data-sort={'sortLocation'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Location
                                    </Button>
                                </th>
                                <th data-sort={'sortDefaultEmployee'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Default staff member
                                    </Button>
                                </th>
                                <th data-sort={'sortConfirmedEmployee'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Confirmed staff member
                                    </Button>
                                </th>
                                <th data-sort={'sortTrainee'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Trainee
                                    </Button>
                                </th>
                                <th data-sort={'sortShiftType'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Shift type
                                    </Button>
                                </th>
                                <th data-sort={'sortShiftStatus'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Shift status
                                    </Button>
                                </th>
                                <th data-sort={'sortShiftDuration'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Shift duration
                                    </Button>
                                </th>
                                <th data-sort={'sortBreakDuration'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Break duration
                                    </Button>
                                </th>
                                <th data-sort={'sortBreakIsPaid'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Break is paid
                                    </Button>
                                </th>

                                <th data-sort={'sortPaidBreakDuration'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Paid break duration
                                    </Button>
                                </th>
                                <th data-sort={'sortNet'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Net duration
                                    </Button>
                                </th>
                                <th data-sort={'sortPayRate'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Pay rate name
                                    </Button>
                                </th>
                                <th data-sort={'sortSource'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Shift source
                                    </Button>
                                </th>
                                <th data-sort={'sortContractType'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Contract type
                                    </Button>
                                </th>
                                <th data-sort={'sortAgency'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Agency
                                    </Button>
                                </th>
                                <th data-sort={'sortFundingPool'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Funding Pool
                                    </Button>
                                </th>
                                <th>Conflicts</th>
                                <th>Comments</th>
                                {isPublished ? <th>Payment status</th> : null}
                                <th data-sort={'sortCost'} className="sorted-header">
                                    <Button minimal fill alignText="left">
                                        Shift value (£)
                                    </Button>
                                </th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            <ListRow
                                isViewMode={isViewMode}
                                department={department}
                                entries={!_.isEmpty(deps) ? entriesToShow : entries}
                                onSplit={onEntrySplit}
                                onDutyStationClick={(ds: any) => {
                                    setEditItem(ds);
                                    setShiftDialogOpen(true);
                                }}
                                rotaStartDate={dateRange[0]}
                                locations={locations}
                                onDelete={onDeleteEntry}
                                shiftTypes={shiftTypes}
                                shiftStatuses={shiftStatuses}
                                dictionaryStore={dictionaryStore}
                                handleToggle={handleToggleCheckbox}
                                selectedListViewShifts={selectedListViewShifts}
                                holidayDates={holidaysStore.holidayDates}
                                appliedShitNumber={1}
                                staffStore={staffStore}
                                isPublished={isPublished}
                                openPaymentDialog={openPaymentDialog}
                                paymentStatusNormalized={paymentStatusNormalized}
                                functionsDictionary={functionsDictionary}
                                fundingPoolsDictionary={fundingPoolsDictionary}
                            />
                        </tbody>
                    </table>
                </div>
            )}
            {locations.length > 0 && !!department && (
                <ShiftDialog
                    isViewMode={isViewMode}
                    isGridView={isGridView}
                    dateRange={dateRange}
                    isOpen={shiftDialogOpen}
                    editItem={editItem}
                    onStaffSearch={onStaffSearch}
                    staffRecords={department.employees}
                    onClose={() => {
                        setShiftDialogOpen(false);
                    }}
                    updateRotaShiftComments={updateRotaShiftComments}
                    currentUser={currentUser}
                    shiftStatuses={shiftStatuses}
                    weekNumber={currentWeekNumber}
                    dayOfWeek={currentDayOfWeek}
                    actionType={actionType}
                    getShiftConflicts={getShiftConflicts}
                    rotaStartDate={dateRange[0]}
                    rotaBuilderStore={rotaBuilderStore}
                    fundingPoolsOptions={fundingPoolsOptions}
                    paymentStatusNormalized={paymentStatusNormalized}
                    isPublished={isPublished}
                    onSubmit={async (val: any) => {
                        setSortTarget('');
                        const submitValues = async () => {
                            const agencyAndContractTypeDefault = val.defaultEmployeeId
                                ? await getStaffAgencyAndContractType(val.defaultEmployeeId)
                                : '';
                            const agencyAndContractTypeConfirmed = val.confirmedEmployeeId
                                ? await getStaffAgencyAndContractType(val.confirmedEmployeeId)
                                : '';
                            if (val.id) {
                                const index = entries.findIndex((r: any) => r.id === val.id);
                                const newEntries = entries;

                                newEntries[index] = {
                                    ...newEntries[index],
                                    breakDurationMinutes: val.breakDurationMinutes,
                                    breaksPaid: val.breaksPaid,
                                    dayOfWeek: val.dayOfWeek,
                                    defaultEmployeeId: val.defaultEmployeeId,
                                    confirmedEmployeeId: val.confirmedEmployeeId,
                                    endTime: val.endTime,
                                    locationId: val.locationId,
                                    overrideValueInPence: val.overrideValueInPence,
                                    roleId: val.roleId,
                                    rotaShiftFunctions: val.rotaShiftFunctions,
                                    fundingPoolId: val.fundingPoolId,
                                    payRateId: val.payRateId,
                                    startTime: val.startTime,
                                    statusId: val.statusId,
                                    typeId: val.typeId,
                                    weekNumber: val.weekNumber,
                                    thirdPartyPaid: val.thirdPartyPaid,
                                    trainingShift: val.trainingShift,
                                    trainees: val.trainees?.map((trainee: any) => ({
                                        traineeId: trainee.traineeId,
                                        roleId: trainee.roleId,
                                        overrideValue: trainee.overrideValue,
                                        payRateId: trainee.payRateId,
                                    })),
                                    comments: val.comments?.map((comment: any) => ({
                                        comment: comment.comment,
                                        createdAt: comment.createdAt,
                                        createdBy: comment.createdBy,
                                        isEdited: comment.isEdited,
                                        isImportant: comment.isImportant,
                                        id: comment.id,
                                        updatedAt: comment.updatedAt,
                                        createdPerson: comment.createdPerson,
                                    })),
                                    startDate: moment(val.startDate).toISOString(),
                                    endDate: val.endDate,
                                    conflicts: val.conflicts,
                                    defaultEmployee: {
                                        contractType: {
                                            name:
                                                dictionaryStore.contractDictionary[
                                                    agencyAndContractTypeDefault.contractTypeId
                                                ],
                                        },
                                        agency: {
                                            name:
                                                dictionaryStore.agenciesDictionary[
                                                    agencyAndContractTypeDefault.agencyId
                                                ],
                                        },
                                    },
                                    confirmedEmployee: {
                                        contractType: {
                                            name:
                                                dictionaryStore.contractDictionary[
                                                    agencyAndContractTypeConfirmed.contractTypeId
                                                ],
                                        },
                                        agency: {
                                            name:
                                                dictionaryStore.agenciesDictionary[
                                                    agencyAndContractTypeConfirmed.agencyId
                                                ],
                                        },
                                    },
                                    cost: val.cost,
                                    paymentStatusId: val?.paymentStatusId
                                        ? val.paymentStatusId
                                        : PAYMENT_STATUS_PLANED,
                                    patientFacing: val.patientFacing,
                                };

                                await setEntries(newEntries);
                                onChange(newEntries);
                                onUpdateConflicts && onUpdateConflicts(val.conflicts, val.id);
                                updateFilter(newEntries);
                            } else {
                                const newId = uuid();
                                const newEntries = [
                                    {
                                        id: newId,
                                        breakDurationMinutes: val.breakDurationMinutes,
                                        breaksPaid: val.breaksPaid,
                                        dayOfWeek: val.dayOfWeek ? val.dayOfWeek : currentDayOfWeek,
                                        confirmedEmployeeId: val.confirmedEmployeeId,
                                        defaultEmployeeId: val.defaultEmployeeId,
                                        endTime: val.endTime,
                                        locationId: val.locationId,
                                        overrideValueInPence: val.overrideValueInPence,
                                        roleId: val.roleId,
                                        rotaShiftFunctions: val.rotaShiftFunctions,
                                        fundingPoolId: val.fundingPoolId,
                                        payRateId: val.payRateId,
                                        startTime: val.startTime,
                                        statusId: val.statusId,
                                        typeId: val.typeId,
                                        isAdditional: val.isAdditional,
                                        weekNumber: val.weekNumber
                                            ? val.weekNumber
                                            : currentWeekNumber,
                                        thirdPartyPaid: val.thirdPartyPaid,
                                        trainingShift: val.trainingShift,
                                        trainees: val.trainees?.map((trainee: any) => ({
                                            traineeId: trainee.traineeId,
                                            roleId: trainee.roleId,
                                            overrideValue: trainee.overrideValue,
                                            payRateId: trainee.payRateId,
                                        })),
                                        comments: val.comments?.map((comment: any) => ({
                                            comment: comment.comment,
                                            createdAt: comment.createdAt,
                                            createdBy: comment.createdBy,
                                            isEdited: comment.isEdited,
                                            isImportant: comment.isImportant,
                                            id: comment.id,
                                            updatedAt: comment.updatedAt,
                                            createdPerson: comment.createdPerson,
                                        })),
                                        startDate: moment(val.startDate).toISOString(),
                                        endDate: val.endDate,
                                        conflicts: val.conflicts,
                                        defaultEmployee: {
                                            contractType: {
                                                name:
                                                    dictionaryStore.contractDictionary[
                                                        agencyAndContractTypeDefault.contractTypeId
                                                    ],
                                            },
                                            agency: {
                                                name:
                                                    dictionaryStore.agenciesDictionary[
                                                        agencyAndContractTypeDefault.agencyId
                                                    ],
                                            },
                                        },
                                        confirmedEmployee: {
                                            contractType: {
                                                name:
                                                    dictionaryStore.contractDictionary[
                                                        agencyAndContractTypeConfirmed
                                                            .contractTypeId
                                                    ],
                                            },
                                            agency: {
                                                name:
                                                    dictionaryStore.agenciesDictionary[
                                                        agencyAndContractTypeConfirmed.agencyId
                                                    ],
                                            },
                                        },
                                        cost: val.cost,
                                        paymentStatusId: PAYMENT_STATUS_PLANED,
                                        patientFacing: val.patientFacing,
                                    },
                                    ...entries,
                                ];
                                await setEntries(newEntries);
                                onChange(newEntries);
                                updateFilter(newEntries);
                                onUpdateConflicts && onUpdateConflicts(val.conflicts, newId);
                            }
                        };
                        await submitValues();
                        setShiftDialogOpen(false);
                    }}
                />
            )}

            <SplitDialog
                entry={splitEntry}
                onCancel={() => {
                    setSplitDialogOpen(false);
                    setSplitEntry({});
                }}
                onSubmit={onSplitSubmit}
                isOpen={splitDialogOpen}
            />
            <ConfirmationAlert
                confirmButtonText={alertPopUp.alertButtonText}
                onConfirm={handleBulkAction}
                onCancel={() => setAlertPopUp((prev) => ({ ...prev, showAlert: false }))}
                isOpen={alertPopUp.showAlert}
                title="Confirm!"
                description={alertPopUp.alertText}
                icon={alertPopUp.alertIcon}
                intent={alertPopUp.alertIntent}
            />
            <ConfirmationAlert
                confirmButtonText="Yes"
                onConfirm={() => onGridConfirmStaff()}
                onCancel={() => setGridConfirmStaffDialogOpen(false)}
                isOpen={gridConfirmStaffDialogOpen}
                icon="new-person"
                intent="primary"
                description="For shifts where no confirmed staff member is set, the default staff member will be set as confirmed. If filter is applied then the change will only be applied to filtered shifts."
            />
            <ConfirmationAlert
                confirmButtonText="Yes"
                onConfirm={() => onListConfirmStaff()}
                onCancel={() => setListConfirmStaffDialogOpen(false)}
                isOpen={listConfirmStaffDialogOpen}
                icon="new-person"
                intent="primary"
                description="For selected shifts where no confirmed staff member is set, the default staff member will be set as confirmed. Do you want to proceed?"
            />
            <SelectPaymentDialog
                title={'Select payment status:'}
                options={paymentStatusOptions}
                activePaymentStatusId={activePaymentStatusId}
                onCancel={() => {
                    setPaymentDialog(false);
                    setShiftIdsForPaymentDialog([]);
                    setActivePaymentStatusId('');
                }}
                onSubmit={(paymentStatusId: string) => {
                    rotaBuilderStore.updateChangesState(true);
                    onChangePaymentStatus(paymentStatusId);
                }}
                isOpen={paymentDialog}
            />
        </div>
    );
});

export default Calendar;
