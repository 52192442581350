import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';

import Modal from '../../modal/Modal';
import AgenciesSummary from './AgenciesSummary';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert, Tab, Tabs } from '@blueprintjs/core';
import RelatedEntities from '../../../common/RelatedEntities';
import _, { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';

const AgenciesDetails = inject('RootStore')(
    observer(
        class AgenciesDetails extends Component {
            state = {
                activeTab: 1,
                isEdit: false,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                },
                isLoaded: false,
                relatedLoaded: false,
                showAlert: false,
                showEditAlert: false,
                showArchiveAlert: false,
                showDeniedArchiveAlert: false,
                formWasEdited: false,
            };

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    await this._updateAgency(this.props.location.state?.editModeOpenedFromViewList);
                    await this._queryRelatedEntries();
                }
                await this.props.RootStore.agenciesStore.getAllAgencies();
            }

            componentWillUnmount() {
                this.props.RootStore.payRatesStore.payRatesByAgency = [];
                this.props.RootStore.staffStore.staffsByAgency = [];
            }

            _editStatusForm = async (values) => {
                await this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _updateAgency = async (editModeOpenedFromViewList = false) => {
                const { id } = this.props.match.params;
                const agency = await this.props.RootStore.agenciesStore.getAgencyById(id);
                if (agency) {
                    this.setState({
                        initialData: { ...agency },
                        isEdit: editModeOpenedFromViewList ? editModeOpenedFromViewList : false,
                        isLoaded: true,
                    });
                }
            };

            _handleOnCancelConfirm = async (replacePath) => {
                await this.setState({ formWasEdited: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/hr/agencies');
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/agencies');
                }
                return this.props.history.goBack();
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };
            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/agencies');
                }
                if (this.props.actionType === 'edit' && !this.state.formWasEdited) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _handleEditAlertClick = () => {
                this.setState((prevState) => {
                    return { showEditAlert: !prevState.showEditAlert };
                });
            };

            _handleOnArchive = async () => {
                const { id } = this.props.match.params;

                const agency = this.props.RootStore.agenciesStore.gridAgencies.find(
                    (a) => a.id === id,
                );
                this.props.RootStore.agenciesStore.deselect(id);
                agency && (await this.props.RootStore.agenciesStore.archiveAgency(agency));
                await this.setState({ formWasEdited: false });
                this.props.history.replace('/hr/agencies');
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                const agency = this.props.RootStore.agenciesStore.gridAgencies.find(
                    (agency) => agency.id === id,
                );

                agency && (await this.props.RootStore.agenciesStore.deleteAgency(agency));
                this.props.history.replace('/hr/agencies');
            };

            _handleArchiveAlertClick = async () => {
                const {
                    data: { isAgencyArchivable },
                } = await this.props.RootStore.agenciesStore.validateArchiveAgencies([
                    this.state.initialData.id,
                ]);

                const isArchivable = isAgencyArchivable.every((agency) => agency.isArchivable);

                if (isArchivable) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _queryRelatedEntries = async () => {
                return (
                    await this.props.RootStore.staffStore.getStaffsByAgency(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.payRatesStore.getPayRatesByAgency(
                        this.state.initialData.id,
                    ),
                    this.setState({
                        relatedLoaded: true,
                    })
                );
            };

            render() {
                const {
                    RootStore: { dictionaryStore, agenciesStore, staffStore, payRatesStore },
                } = this.props;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Add agency record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        enableReinitialize={true}
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            const errors = {};
                            await this._editStatusForm(values);
                            if (
                                !!values.name &&
                                values.name.length > 0 &&
                                values.name !== this.state.initialData.name
                            ) {
                                const { data } = await agenciesStore.validateAgencyByName(
                                    values.name,
                                );
                                if (!!data && data?.getAgencyByName) {
                                    errors.notUniqueId = data?.getAgencyByName.id;
                                    errors.notUniqueName = data?.getAgencyByName.name;
                                }
                                return errors;
                            }
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (this.props.actionType === 'create') {
                                await this.setState({
                                    formWasEdited: false,
                                });
                                await agenciesStore.createAgency(values);
                                this.props.history.replace('/hr/agencies');
                            }
                            if (this.props.actionType === 'edit') {
                                if (
                                    this.state.formWasEdited &&
                                    (!_.isEmpty(staffStore.staffsByAgency) ||
                                        !_.isEmpty(payRatesStore.payRatesByAgency))
                                ) {
                                    this.setState({ showEditAlert: true });
                                } else {
                                    await agenciesStore.updateAgency(values);
                                    this._updateAgency();
                                    await this.setState({
                                        formWasEdited: false,
                                    });
                                }
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setSubmitting,
                            errors,
                            touched,
                        }) => (
                            <Modal title={title} onClose={this._handleCloseClick} shadow>
                                <Form className="modal__form">
                                    <Tabs
                                        id="functionsTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        selectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                        }}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                this.props.actionType === 'edit' ? 'Summary' : ''
                                            }
                                            panel={
                                                <AgenciesSummary
                                                    agency={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    actionType={this.props.actionType}
                                                    errors={errors}
                                                    touched={touched}
                                                    agenciesStore={agenciesStore}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          !this.state.relatedLoaded ? '...' : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        dictionaryStore={dictionaryStore}
                                                        description={
                                                            'This agency is related to the following entities (archived entities are not displayed)'
                                                        }
                                                        relatedStaffsTitle={
                                                            'Sessional staff records'
                                                        }
                                                        relatedStaffs={staffStore.staffsByAgency}
                                                        relatedPayRates={
                                                            payRatesStore.payRatesByAgency
                                                        }
                                                        hasRelatedStaffs={true}
                                                        hasRelatedPayRates={true}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                    {!this.state.initialData.isArchived && (
                                        <footer className="modal__footer">
                                            <CommonModalFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onArchive={this._handleArchiveAlertClick}
                                                onDelete={this._handleAlertClick}
                                                hasArchiveOption={
                                                    !this.state.initialData.isArchived
                                                }
                                            />
                                        </footer>
                                    )}
                                </Form>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this agency? You won't be
                                        able to recover it."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._handleOnArchive}
                                    onCancel={() =>
                                        this.setState({
                                            showArchiveAlert: false,
                                        })
                                    }
                                    isOpen={this.state.showArchiveAlert}
                                    title="Confirm archiving!"
                                    icon={'archive'}
                                    intent={'warning'}
                                    description="Are you sure you want to archive the agency? This action is irreversible"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Yes"
                                    cancelButtonText="No"
                                    onConfirm={async () => {
                                        if (this.props.actionType === 'create') {
                                            await agenciesStore.createAgency(values);
                                            this.props.history.replace('/hr/agencies');
                                        }
                                        if (this.props.actionType === 'edit') {
                                            await agenciesStore.updateAgency(values);
                                            this.setState({ formWasEdited: false });
                                            this._updateAgency();
                                        }
                                        this._handleEditAlertClick();
                                        setSubmitting(false);
                                    }}
                                    onCancel={this._handleEditAlertClick}
                                    isOpen={this.state.showEditAlert}
                                    title="Confirm editing!"
                                    icon={'edit'}
                                    intent={'primary'}
                                    description="Are you sure you want to save changes to this agency? It will affect all related sessional staff records and pay rates"
                                />
                                <Alert
                                    confirmButtonText="OK"
                                    intent={'primary'}
                                    isOpen={this.state.showDeniedArchiveAlert}
                                    onClose={() => {
                                        this.setState({ showDeniedArchiveAlert: false });
                                    }}
                                >
                                    <p>
                                        The agency cannot be archived because one or more of its
                                        staff record(s) and/or pay rate(s) are still active. Review
                                        and archive related entities first
                                    </p>
                                </Alert>
                                <RouterPrompt
                                    when={this.state.formWasEdited}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Modal>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default AgenciesDetails;
