import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Modal from '../../modal/Modal';
import RolesSummary from './RolesSummary';
import { Formik, Form } from 'formik';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert, Tab, Tabs } from '@blueprintjs/core';
import RelatedEntities from '../../../common/RelatedEntities';
import _, { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';

const RolesDetails = inject('RootStore')(
    observer(
        class RolesDetails extends Component {
            state = {
                activeTab: 1,
                isEdit: false,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                    categories: [],
                    functions: [],
                    isArchived: false,
                },
                isLoaded: false,
                relatedLoaded: false,
                categories: [],
                functions: [],
                showDeleteAlert: false,
                formWasEdited: false,
            };

            async componentDidMount() {
                await this.props.RootStore.functionsStore.getAllFunctions();
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    await this._updateRole(this.props.location.state?.editModeOpenedFromViewList);
                    await this._queryRelatedEntries();
                }
                this.setState({
                    categories: [
                        ...this.props.RootStore.dictionaryStore.peopleCategories,
                        ...this.props.RootStore.dictionaryStore.staffCategories,
                    ],
                    functions: this.props.RootStore.functionsStore.allFunctionsActiveOptions,
                });
            }

            componentWillUnmount() {
                this.props.RootStore.staffStore.staffsByRole = [];
                this.props.RootStore.shiftPatternsStore.shiftPatternsByRole = [];
                this.props.RootStore.rotaBuilderStore.rotasByRole = [];
                this.props.RootStore.departmentsStore.departmentsByRole = [];
            }

            _updateRole = async (editModeOpenedFromViewList = false) => {
                const { id } = this.props.match.params;
                const role = await this.props.RootStore.rolesStore.getRoleById(id);
                if (role) {
                    this.setState({
                        initialData: {
                            ...role,
                            categories: role.categories.map((c) => ({
                                ...c.documentCategory,
                            })),
                            functions: role.functions.map((f) => ({
                                ...f,
                            })),
                        },
                        isEdit: editModeOpenedFromViewList ? editModeOpenedFromViewList : false,
                        isLoaded: true,
                    });
                }
            };

            _editStatusForm = async (values) => {
                await this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };
            _removeEditStatusForm = () => {
                this.setState({
                    formWasEdited: false,
                });
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/roles');
                }
                return this.props.history.goBack();
            };

            _handleEditAlertClick = () => {
                this.setState((prevState) => {
                    return { showEditAlert: !prevState.showEditAlert };
                });
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };
            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/roles');
                }
                if (this.props.actionType === 'edit' && !this.state.formWasEdited) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };
            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                id && (await this.props.RootStore.rolesStore.deleteRole(id));
                this.props.history.replace('/hr/roles');
            };

            _handleDeleteAlertClick = () => {
                this.setState((prevState) => {
                    return { showDeleteAlert: !prevState.showDeleteAlert };
                });
            };
            _handleOnArchive = async () => {
                const { id } = this.props.match.params;

                const role = this.props.RootStore.rolesStore.gridRoles.find((r) => r.id === id);
                this.props.RootStore.rolesStore.deselect(id);
                role && (await this.props.RootStore.rolesStore.archiveRole(role.id));
                this._removeEditStatusForm();
                this.props.history.replace('/hr/roles');
            };

            _handleArchiveAlertClick = async () => {
                const {
                    data: { isEmployeeRoleTypeArchivable },
                } = await this.props.RootStore.rolesStore.validateArchiveRoles([
                    this.state.initialData.id,
                ]);

                const isArchivable = isEmployeeRoleTypeArchivable.every(
                    (role) => role.isArchivable,
                );

                if (isArchivable) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _queryRelatedEntries = async () => {
                return (
                    await this.props.RootStore.shiftPatternsStore.getShiftPatternsByRole(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.rotaBuilderStore.getRotasByRole(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.staffStore.getStaffsByRole(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.departmentsStore.getDepartmentsByRole(
                        this.state.initialData.id,
                    ),
                    this.setState({
                        relatedLoaded: true,
                    })
                );
            };

            _handleOnCancelConfirm = async (replacePath) => {
                this._removeEditStatusForm();
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/hr/roles');
            };

            render() {
                const {
                    RootStore: {
                        shiftPatternsStore,
                        rotaBuilderStore,
                        dictionaryStore,
                        departmentsStore,
                        staffStore,
                        rolesStore,
                    },
                } = this.props;
                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Add role record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        enableReinitialize={true}
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validate={async (values) => {
                            const errors = {};
                            await this._editStatusForm(values);
                            if (
                                !!values.name &&
                                values.name.length > 0 &&
                                values.name !== this.state.initialData.name
                            ) {
                                const { data } = await rolesStore.validateRoleName(values.name);
                                if (!!data && data?.getRoleByName) {
                                    errors.notUniqueId = data?.getRoleByName.id;
                                    errors.notUniqueName = data?.getRoleByName.name;
                                }
                                return errors;
                            }
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (this.props.actionType === 'create') {
                                this._removeEditStatusForm();
                                await rolesStore.createRole(values);
                                this.props.history.replace('/hr/roles');
                            }
                            if (this.props.actionType === 'edit') {
                                if (this.state.formWasEdited) {
                                    if (
                                        !_.isEmpty(rotaBuilderStore.rotasByRole) ||
                                        !_.isEmpty(staffStore.staffsByRole) ||
                                        !_.isEmpty(shiftPatternsStore.shiftPatternsByRole) ||
                                        !_.isEmpty(departmentsStore.departmentsByRole)
                                    ) {
                                        this.setState({ showEditAlert: true });
                                    } else {
                                        await rolesStore.updateRole(values);
                                        this._updateRole();
                                        this._removeEditStatusForm();
                                    }
                                } else {
                                    this._removeEditStatusForm();
                                    this.props.history.replace('/hr/roles');
                                }
                            }

                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setSubmitting,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        this.state.activeTab === 1 ? (
                                            <CommonModalFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onDelete={this._handleDeleteAlertClick}
                                                onArchive={this._handleArchiveAlertClick}
                                                hasArchiveOption={
                                                    !this.state.initialData.isArchived
                                                }
                                                hasEditOption={!this.state.initialData.isArchived}
                                            />
                                        ) : null
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="rolesTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        selectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                        }}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                this.props.actionType === 'edit' ? 'Current' : ''
                                            }
                                            panel={
                                                <RolesSummary
                                                    rolesStore={rolesStore}
                                                    role={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    actionType={this.props.actionType}
                                                    errors={errors}
                                                    touched={touched}
                                                    categories={this.state.categories}
                                                    functions={this.state.functions}
                                                />
                                            }
                                        />

                                        <Tab
                                            id={2}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          !this.state.relatedLoaded ? '...' : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        hasRelatedRotas={true}
                                                        hasRelatedShiftPatterns={true}
                                                        hasRelatedStaffs={true}
                                                        hasRelatedDepartments={true}
                                                        dictionaryStore={dictionaryStore}
                                                        relatedShiftPatterns={
                                                            shiftPatternsStore.shiftPatternsByRole
                                                        }
                                                        relatedRotas={rotaBuilderStore.rotasByRole}
                                                        relatedStaffs={staffStore.staffsByRole}
                                                        relatedDepartments={
                                                            departmentsStore.departmentsByRole
                                                        }
                                                        description={
                                                            'This role is related to the following entities (archived entities are not displayed)'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showDeleteAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this role? You won't be
                                        able to recover it."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Yes"
                                    cancelButtonText="No"
                                    onConfirm={async () => {
                                        if (this.props.actionType === 'create') {
                                            await rolesStore.createRole(values);
                                            this.props.history.replace('/hr/roles');
                                        }
                                        if (this.props.actionType === 'edit') {
                                            await rolesStore.updateRole(values);
                                            await this._updateRole();
                                        }
                                        this._handleEditAlertClick();
                                        this._removeEditStatusForm();
                                        setSubmitting(false);
                                    }}
                                    onCancel={this._handleEditAlertClick}
                                    isOpen={this.state.showEditAlert}
                                    title="Confirm editing!"
                                    icon={'edit'}
                                    intent={'primary'}
                                    description="Are you sure you want to save the changes? The role will be updated in all related departments, staff records, shift pattern shifts and past, current, and future rota shifts"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._handleOnArchive}
                                    onCancel={() =>
                                        this.setState({
                                            showArchiveAlert: false,
                                        })
                                    }
                                    isOpen={this.state.showArchiveAlert}
                                    title="Confirm archiving!"
                                    icon={'archive'}
                                    intent={'warning'}
                                    description="Are you sure you want to archive this role? This action is irreversible"
                                />
                                <Alert
                                    confirmButtonText="OK"
                                    intent={'primary'}
                                    isOpen={this.state.showDeniedArchiveAlert}
                                    onClose={() => {
                                        this.setState({ showDeniedArchiveAlert: false });
                                    }}
                                >
                                    <p>
                                        This role cannot be archived because it is related to other
                                        active entities and/or future shifts (see the ‘Related
                                        entities’ tab)
                                    </p>
                                </Alert>
                                <RouterPrompt
                                    when={this.state.formWasEdited}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default RolesDetails;
