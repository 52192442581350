import React from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import Loader from '../../../modules/helpers/Loader';
import Navigation from './Navigation';
import TableHeader from './TableHeader';
import TableRow from './TableRow';
import DepartmentDetails from './DepartmentDetails';
import DepartmentsRelatedDialog from './DepartmentsRelatedDialog';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert } from '@blueprintjs/core';

interface RolesListProps extends Partial<RouteComponentProps<{ id: string }>> {
    actionType: string;
    RootStore?: any;
}

interface departmentsInfo {
    id: string;
    name: string;
    isArchivable: boolean;
}

const AdminManageDepartments = inject('RootStore')(
    observer(
        class AdminManageDepartments extends React.Component<
            RolesListProps,
            {
                showAlert: boolean;
                showOnlyRelatedEntitiesAlert: boolean;
                showRelatedAlert: boolean;
                notAchievableDepartments: departmentsInfo[];
                achievableDepartments: departmentsInfo[];
            }
        > {
            state = {
                showAlert: false,
                showOnlyRelatedEntitiesAlert: false,
                showRelatedAlert: false,
                notAchievableDepartments: [],
                achievableDepartments: [],
            };

            _handleToggleSelectAll = (event: any) => {
                const { selectAll, deselectAll } = (this.props as any).RootStore.departmentsStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleAddNew = () => {
                (this.props as any).history?.replace(`/hr/departments/new`);
            };

            _handleRowClick = (id: string, event: any) => {
                if (event.target.tagName === 'TD') {
                    (this.props as any).history?.push(`/hr/departments/${id}`);
                }
            };

            _handleToggleSelected = (event: any) => {
                const { select, deselect } = (this.props as any).RootStore.departmentsStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleEditItem = () => {
                let { selected } = this.props.RootStore.departmentsStore;
                this.props?.history?.push(`/hr/departments/${selected[0]}`, {
                    actionType: 'edit',
                    editModeOpenedFromViewList: true,
                });
            };

            _handleArchiveItem = async () => {
                const { selected, isDepartmentArchivable } = this.props.RootStore.departmentsStore;
                const selectedIds = selected.map((item: any) => item);
                const departmentsList = await isDepartmentArchivable(selectedIds);

                if (departmentsList.every((item: any) => item.isArchivable)) {
                    this.setState({ achievableDepartments: departmentsList, showAlert: true });
                } else if (departmentsList.some((item: any) => item.isArchivable)) {
                    const canBeArchived = departmentsList.filter((item: any) => item.isArchivable);
                    const notArchivable = departmentsList.filter((item: any) => !item.isArchivable);
                    this.setState({
                        notAchievableDepartments: notArchivable,
                        achievableDepartments: canBeArchived,
                        showRelatedAlert: true,
                    });
                } else {
                    this.setState({ showOnlyRelatedEntitiesAlert: true });
                }
            };

            _archiveItems = async () => {
                const {
                    bulkArchiveDepartments,
                    deselectAll,
                } = this.props.RootStore.departmentsStore;
                const departmentIds = this.state.achievableDepartments.map(
                    (department: any) => department.id,
                );
                bulkArchiveDepartments(departmentIds);
                this._closeAlert();
                deselectAll();
            };

            _closeAlert = () => {
                this.setState({
                    showAlert: false,
                    showOnlyRelatedEntitiesAlert: false,
                    showRelatedAlert: false,
                });
            };

            _handleToggleAlert = () => {
                let { selected } = (this.props as any).RootStore.departmentsStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !(prevState as any).showAlert };
                    });
            };

            render() {
                const {
                    RootStore: {
                        departmentsStore: {
                            gridDepartments,
                            onSort,
                            onFilter,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            loaded,
                            selected,
                        },
                        departmentsStore,
                        dictionaryStore,
                        configStore: { isFeatureEnabled },
                    },
                } = this.props as any;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = (this.props as any).match?.params ?? {};
                const actionType = (this.props as any).actionType === 'create' ? 'create' : 'edit';

                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <Navigation
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleAlert}
                                onFilter={onFilter}
                                editItem={this._handleEditItem}
                                archiveItems={this._handleArchiveItem}
                                selected={selected}
                                departmentsStore={departmentsStore}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <TableHeader
                                    data={gridDepartments}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridDepartments.map((item: any) => (
                                        <TableRow
                                            {...item}
                                            onClick={this._handleRowClick}
                                            key={item.id}
                                            onToggle={this._handleToggleSelected}
                                            dictionaryStore={dictionaryStore}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || (this.props as any).actionType === 'create') && (
                            <div className="common-layout__modal">
                                <DepartmentDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <DepartmentsRelatedDialog
                            isOpen={this.state.showRelatedAlert}
                            onClose={this._closeAlert}
                            notAchievableDepartments={this.state.notAchievableDepartments}
                            achievableDepartments={this.state.achievableDepartments}
                            onArchiveNotRelated={this._archiveItems}
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._archiveItems}
                            onCancel={this._closeAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm archiving!"
                            description="Are you sure you want to archive selected department(s)? This action is irreversible. "
                        />
                        <Alert
                            confirmButtonText="Ok"
                            isOpen={this.state.showOnlyRelatedEntitiesAlert}
                            onClose={this._closeAlert}
                        >
                            <p>
                                This department cannot be archived because it is related to other
                                active entities and/or future shifts (see the ‘Related entities’
                                tab).
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default AdminManageDepartments;
