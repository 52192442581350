import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tabs, Tab } from '@blueprintjs/core';

import Modal from '../../modal/Modal';
import PersonSummary from './PersonSummary';
import AdminManageStaff from '../../../pages/admin/AdminManageStaff';
import AdminManageRegistrations from '../../../pages/admin/AdminManageRegistration/AdminManageRegistration';
import Documents from '../../staffs/documents/Documents';
import Onboarding from '../../users/detail/Onboarding';
import { when } from 'mobx';
import { getUser } from '../../../../api/usersApi';
import PersonChecklist from './PersonChecklist';
import AbsenceManagement from '../../../pages/admin/AdminManageAbsences/index';
import AbsenceRecord from '../../../pages/admin/AdminManageAbsences/AbsenceManagement/AbsenceManagement';

const PersonDetails = inject('RootStore')(
    observer(
        class PersonDetails extends Component {
            state = {
                isEdit: false,
                initialData: {
                    cognitoId: '',
                    cognitoUsername: '',
                    dateOfBirth: null,
                    title: null,
                    firstName: '',
                    preferredName: '',
                    lastName: '',
                    maidenName: '',
                    alternativeNamesToggle: false,
                    alternativeNames: [],
                    genderId: '',
                    homeAddress: '',
                    personalEmail: '',
                    nationalInsurance: '',
                    payrollReference: '',
                    personalPhone: '',
                    languages: [],
                    departmentId: '',
                    profilePhoto: '',
                    emergencyContactPhone: '',
                    emergencyContactName: '',
                    homePhone: '',
                    middleName: '',
                    dbsNumber: null,
                    professionalBodyName: '',
                    hasPerformerInfo: false,
                    performerAlignmentId: null,
                    performerRoleId: null,
                    performerProfessionalRegNumber: '',
                    performerStatusId: null,
                    performerDateFirstOnPerformersList: null,
                    performerDateInGPRegister: null,
                    personInductionChecklists: [],
                    performerTeamId: null,
                    performerCurrentlyInProbationary: false,
                    hasPerformerInfoLastCheckDate: null,
                    nationality: null,
                    ethnicity: null,
                    genderReassignment: null,
                    religionOrBelief: null,
                    sexualOrientation: null,
                    maritalStatus: null,
                    personDisability: [],
                    carer: false,
                    employmentStartDate: null,
                    employmentEndDate: null,
                    employmentPeriodErrors: [],
                },
                helpers: {
                    minDate: new Date('1900-01-01'),
                },
                showAbsenceRecord: false,
                actionType: '',
                absenceRecordId: '',
                isLoaded: false,
                onboarding: null,
            };

            async componentDidMount() {
                const {
                    RootStore: {
                        onboardingsStore: { getUserOnboarding, fetchOnboarding },
                        userStore: { getUserSession },
                    },
                    actionType,
                } = this.props;

                if (actionType === 'create') {
                    this.setState({ isEdit: true });
                } else if (actionType === 'edit') {
                    this._updatePerson();
                }

                when(
                    () => this.state.initialData.cognitoUsername,
                    async () => {
                        try {
                            const userSession = await getUserSession();
                            const { onboardingId } = await getUser(
                                this.state.initialData.cognitoUsername,
                                userSession.tokens.id,
                            );

                            if (onboardingId) {
                                await fetchOnboarding(onboardingId);
                                const onboarding = getUserOnboarding(onboardingId);
                                this.setState({ onboarding });
                            }
                        } catch (err) {
                            console.error('Error getting user onboarding', err);
                        }
                    },
                );
            }

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/people');
                }
                return this.props.history.goBack();
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnModalExit = () => {
                this.props.history.replace('/hr/people');
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'edit') {
                    return this._toggleIsEdit();
                }
                this.props.history.replace('/hr/people');
            };

            _updatePerson = async () => {
                const { personId } = this.props.match.params;
                const person = await this.props.RootStore.peopleStore.getPersonById(personId);
                if (person) {
                    this.setState({
                        initialData: { ...person },
                        isEdit: false,
                        isLoaded: true,
                    });
                }
            };
            _deletePerson = async () => {
                const { personId } = this.props.match.params;

                const person = await this.props.RootStore.peopleStore.getPersonById(personId);

                person && (await this.props.RootStore.peopleStore.deletePerson(person));
                this.props.history.replace('/hr/people');
            };

            _updatePersonDataAfterChange = async () => {
                await this.props.RootStore.peopleStore.getPeople();
                this._updatePerson();
            };

            _handleAddNewAbsence = () => {
                this.setState({ showAbsenceRecord: true, actionType: 'create' });
            };

            _handleOpenAbsenceRecord = (id) => {
                this.setState({ showAbsenceRecord: true, actionType: 'edit', absenceRecordId: id });
            };

            _closeAbsenceRecord = () => {
                this._updatePerson();
                this.setState({
                    showAbsenceRecord: false,
                    absenceRecordId: '',
                });
            };

            render() {
                const {
                    RootStore: {
                        peopleStore,
                        dictionaryStore: { personsDictionary },
                    },
                } = this.props;

                const { showAbsenceRecord, actionType, absenceRecordId } = this.state;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const { personId } = this.props.match.params;

                const title =
                    this.props.actionType === 'create'
                        ? 'Add person record'
                        : `${this.state.initialData.firstName} ${this.state.initialData.lastName}`;

                return (
                    <>
                        <Modal title={title} onClose={this._handleCloseClick} shadow>
                            <Tabs
                                id="staffTabs"
                                defaultSelectedTabId="summary"
                                large
                                selectedTabId={this.props.tab}
                                onChange={this.props.handleTabChange}
                            >
                                <Tab
                                    id="summary"
                                    title="Summary"
                                    panel={
                                        <PersonSummary
                                            person={this.state.initialData}
                                            editOrCreateMode={this.state.isEdit}
                                            isExit={this.state.isExit}
                                            actionType={this.props.actionType}
                                            isEditMode={this.props.actionType === 'edit'}
                                            isCreateMode={this.props.actionType === 'create'}
                                            minDate={this.state.helpers.minDate}
                                            toggleIsEdit={this._toggleIsEdit}
                                            handleOnCancel={this._handleOnCancel}
                                            handleOnModalCancel={this._handleOnModalCancel}
                                            handleOnModalExit={this._handleOnModalExit}
                                            updatePerson={this._updatePerson}
                                            updatePersonDataAfterChange={
                                                this._updatePersonDataAfterChange
                                            }
                                            deletePerson={this._deletePerson}
                                            {...this.props}
                                        />
                                    }
                                />
                                {this.props.actionType === 'edit' && (
                                    <Tab
                                        id="documents"
                                        title="Documents"
                                        className="modal__tab"
                                        panel={
                                            <Documents
                                                person={this.state.initialData}
                                                updatePersonDataAfterChange={
                                                    this._updatePersonDataAfterChange
                                                }
                                                {...this.props}
                                            />
                                        }
                                    />
                                )}
                                {this.props.actionType === 'edit' && (
                                    <Tab
                                        id="staffRecords"
                                        title="Staff records"
                                        panel={
                                            <AdminManageStaff
                                                {...this.props}
                                                person={this.state.initialData}
                                                embedded
                                            />
                                        }
                                    />
                                )}
                                {this.props.actionType === 'edit' && (
                                    <Tab
                                        id="registrations"
                                        title="Registrations"
                                        panel={
                                            <AdminManageRegistrations
                                                {...this.props}
                                                person={this.state.initialData}
                                                embedded
                                            />
                                        }
                                    />
                                )}
                                {this.props.actionType === 'edit' && (
                                    <Tab
                                        id="absence"
                                        title="Absence"
                                        className="modal__tab"
                                        panel={
                                            <AbsenceManagement
                                                person={this.state.initialData}
                                                history={this.props.history}
                                                handleAddNewAbsenceFromPerson={
                                                    this._handleAddNewAbsence
                                                }
                                                handleOpenAbsenceRecordFromPerson={
                                                    this._handleOpenAbsenceRecord
                                                }
                                            />
                                        }
                                    />
                                )}
                                {this.props.actionType === 'edit' && (
                                    <Tab
                                        id="inductionChecklist"
                                        title="Introduction Checklist"
                                        panel={
                                            <PersonChecklist
                                                person={this.state.initialData}
                                                checklist={
                                                    this.state.initialData.personInductionChecklists
                                                }
                                                peopleStore={peopleStore}
                                                onUpdate={this._updatePersonDataAfterChange}
                                                personsDictionary={personsDictionary}
                                            />
                                        }
                                    />
                                )}
                                {this.props.actionType === 'edit' && this.state.onboarding && (
                                    <Tab
                                        id="onboarding"
                                        title="Onboarding"
                                        panel={
                                            <Onboarding
                                                onboarding={this.state.onboarding}
                                                hideDocuments
                                            />
                                        }
                                    />
                                )}
                            </Tabs>
                        </Modal>
                        {personId && showAbsenceRecord && (
                            <AbsenceRecord
                                personProfileId={personId}
                                absenceRecordId={absenceRecordId}
                                actionType={actionType}
                                history={this.props.history}
                                closeAbsenceRecordFromProfile={this._closeAbsenceRecord}
                            />
                        )}
                    </>
                );
            }
        },
    ),
);

export default PersonDetails;
