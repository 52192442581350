import React from 'react';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Tab, Tabs, Alert } from '@blueprintjs/core';

import CommonModalFooter from '../../../common/CommonModalFooter';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import Modal from '../../../modules/modal/Modal';
import DepartmentSummary from './DepartmentSummary';
import RelatedEntities from '../../../common/RelatedEntities';
import { validationSchema } from './validation';
import { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../../modules/helpers/Toaster';

const DepartmentDetails = inject('RootStore')(
    observer(
        class DepartmentDetails extends React.Component {
            state = {
                loadingRelatedEntries: false,
                isEdit: false,
                activeTab: 1,
                initialData: {
                    id: '',
                    name: '',
                    costCode: '',
                    roles: [],
                    manager: null,
                    locations: [],
                    isArchived: false,
                },
                changesNotSaved: false,
                relatedEntitiesConnected: false,
                departmentNames: [],
                shiftPatternsByDepartment: [],
                rotasByDepartment: [],
                staffsByDepartment: [],
                isLoaded: false,
                showCancelAlert: false,
                showRelatedAlert: false,
                showArchiveRelatedAlert: false,
                showAlert: false,
                roles: [],
                persons: [],
                costCentres: [],
                locations: [],
            };

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    const { id } = this.props.match.params;
                    const editModeOpenedFromViewList = this.props.location.state
                        ?.editModeOpenedFromViewList;
                    const item = await this.props.RootStore.departmentsStore.getDepartmentById(id);
                    if (item) {
                        this.setState(
                            {
                                initialData: {
                                    ...item,
                                    locations: item.locations.map((location) => ({
                                        ...location,
                                    })),
                                },
                                isLoaded: true,
                                isEdit: editModeOpenedFromViewList
                                    ? editModeOpenedFromViewList
                                    : false,
                            },
                            () => {
                                this._queryRelatedEntries();
                            },
                        );
                    }
                }

                await this.props.RootStore.departmentsStore.getAllDepartments();

                const departmentNames = [...this.props.RootStore.departmentsStore.allDepartments]
                    .filter((dep) => !dep.isArchived)
                    .map((dep) => ({ name: dep.name }));

                const roles = this.props.RootStore.dictionaryStore.roles
                    .filter((item) => !item.isArchived)
                    .map(({ label, value }) => ({ label, value }));

                const persons = this.props.RootStore.dictionaryStore.persons.map(
                    ({ label, value }) => ({ label, value }),
                );

                const costCentres = this.props.RootStore.dictionaryStore.costCentres
                    .filter((item) => !item.isArchived)
                    .map(({ label, value }) => ({ label, value }));

                const locations = this.props.RootStore.dictionaryStore.locations
                    .filter((loc) => !loc.isArchived)
                    .map(({ label, value }) => ({ label, value }));

                this.setState({
                    roles,
                    persons,
                    costCentres,
                    locations,
                    departmentNames,
                });
            }

            componentWillUnmount() {
                this.props.RootStore.shiftPatternsStore.shiftPatternsByDepartment = [];
                this.props.RootStore.rotaBuilderStore.rotasByDepartment = [];
                this.props.RootStore.staffStore.staffsByDepartment = [];
            }

            _updateDepartmentItem = async () => {
                const { id } = this.props.match.params;
                const item = await this.props.RootStore.departmentsStore.getDepartmentById(id);
                if (item) {
                    this.setState({
                        initialData: {
                            ...item,
                        },
                        isLoaded: true,
                        isEdit: false,
                    });
                }
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/departments');
                }
                return this.props.history.goBack();
            };

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _queryRelatedEntries = async () => {
                const { staffStore, rotaBuilderStore, shiftPatternsStore } = this.props.RootStore;
                Promise.all([
                    shiftPatternsStore.getShiftPatternsByDepartment(this.state.initialData.id),
                    rotaBuilderStore.getRotasByDepartment(this.state.initialData.id),
                    staffStore.getStaffsByDepartment(this.state.initialData.id),
                ]).then(() => {
                    const relatedEntitiesConnected =
                        shiftPatternsStore.shiftPatternsByDepartment.length ||
                        rotaBuilderStore.rotasByDepartment.length ||
                        staffStore.staffsByDepartment.length;
                    this.setState({
                        shiftPatternsByDepartment: shiftPatternsStore.shiftPatternsByDepartment,
                        rotasByDepartment: rotaBuilderStore.rotasByDepartment,
                        staffsByDepartment: staffStore.staffsByDepartment,
                        relatedEntitiesConnected: relatedEntitiesConnected,
                    });
                });
            };

            _handleOnArchive = async () => {
                if (this.state.relatedEntitiesConnected) {
                    return this.setState({ showArchiveRelatedAlert: true });
                }
                this.setState({ showAlert: true });
            };

            _archiveLocation = async () => {
                const { id } = this.state.initialData;
                this.props.RootStore.departmentsStore.archiveDepartment(id);
                this.props.history.replace('/hr/departments');
            };

            _handleSubmit = async (values, { setSubmitting }) => {
                const normalizedValues = validationSchema.cast(values);
                const { departmentsStore } = this.props.RootStore;
                if (this.props.actionType === 'create') {
                    await departmentsStore.createDepartment(normalizedValues);
                }

                if (this.props.actionType === 'edit') {
                    if (this.state.relatedEntitiesConnected && this.state.changesNotSaved) {
                        return this.setState({ showRelatedAlert: true });
                    }
                    await departmentsStore.updateDepartment(normalizedValues);
                }

                this.setState({ changesNotSaved: false });
                setSubmitting(false);

                if (this.props.actionType === 'create') {
                    this.props.history.replace('/hr/departments');
                }

                if (this.props.actionType === 'edit') {
                    this._updateDepartmentItem();
                }
            };

            _finishSubmitProcess = async (values) => {
                this._closeAlert();
                const normalizedValues = validationSchema.cast(values);
                const { departmentsStore } = this.props.RootStore;
                await departmentsStore.updateDepartment(normalizedValues);
                this.setState({ changesNotSaved: false });
                this._updateDepartmentItem();
            };

            _closeAlert = () => {
                this.setState({
                    showCancelAlert: false,
                    showRelatedAlert: false,
                    showArchiveRelatedAlert: false,
                    showAlert: false,
                });
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/departments');
                }
                if (this.props.actionType === 'edit' && !this.state.changesNotSaved) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };

            _handleOnCancelConfirm = async (replacePath) => {
                await this.setState({ changesNotSaved: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/hr/departments');
            };

            render() {
                const {
                    RootStore: { departmentsStore },
                } = this.props;
                const title =
                    this.props.actionType === 'create'
                        ? 'Add Department'
                        : `${this.state.initialData.name}`;

                const {
                    roles,
                    persons,
                    costCentres,
                    locations,
                    departmentNames,
                    shiftPatternsByDepartment,
                    rotasByDepartment,
                    staffsByDepartment,
                    initialData: { isArchived },
                } = this.state;

                return (
                    <Formik
                        innerRef={(f) => (this.formik = f)}
                        enableReinitialize
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            const normalizedValues = validationSchema.cast(values);
                            const { name, costCode } = normalizedValues;
                            const errors = {};
                            if (name !== this.state.initialData.name) {
                                const nameIsUnique = await departmentsStore.nameIsUnique(name);
                                if (nameIsUnique.length) {
                                    errors.notUniqueId = nameIsUnique[0].id;
                                    errors.notUniqueName = nameIsUnique[0].name;
                                }
                            }
                            if (costCode !== this.state.initialData.costCode) {
                                const costCodeIsUnique = await departmentsStore.costCodeIsUnique(
                                    costCode,
                                );
                                if (costCodeIsUnique.length) {
                                    errors.costCodeExistsDepId = costCodeIsUnique[0].id;
                                }
                            }
                            // update changesNotSaved flag to show popUp if closing form without saving
                            if (isEqual(this.state.initialData, normalizedValues)) {
                                this.setState({ changesNotSaved: false });
                            } else {
                                this.setState({ changesNotSaved: true });
                            }
                            return errors;
                        }}
                        onSubmit={this._handleSubmit}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        this.state.activeTab === 1 ? (
                                            <CommonModalFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onArchive={this._handleOnArchive}
                                                onDelete={this._handleAlertClick}
                                                hasDeleteOption={false}
                                                hasArchiveOption={!isArchived}
                                                hasEditOption={!isArchived}
                                            />
                                        ) : null
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="departmentsTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                        }}
                                        selectedTabId={this.state.activeTab}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                this.props.actionType === 'edit' ? 'Summary' : ''
                                            }
                                            panel={
                                                <DepartmentSummary
                                                    item={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    actionType={this.props.actionType}
                                                    errors={errors}
                                                    touched={touched}
                                                    roles={roles}
                                                    persons={persons}
                                                    costCentres={costCentres}
                                                    locations={locations}
                                                    departmentsStore={departmentsStore}
                                                    departmentNames={departmentNames}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          this.state.loadingRelatedEntries
                                                              ? '...'
                                                              : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        relatedShiftPatterns={
                                                            shiftPatternsByDepartment
                                                        }
                                                        hasRelatedShiftPatterns={true}
                                                        relatedRotas={rotasByDepartment}
                                                        hasRelatedRotas={true}
                                                        relatedStaffs={staffsByDepartment}
                                                        hasRelatedStaffs={true}
                                                        description={
                                                            'This department is related to the following entities (archived entities are not displayed)'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <Alert
                                    confirmButtonText="Ok"
                                    isOpen={this.state.showArchiveRelatedAlert}
                                    onClose={this._closeAlert}
                                >
                                    <>
                                        <p>
                                            This department cannot be archived because it is related
                                            to other active entities and/or future shifts (see the
                                            ‘Related entities’ tab).
                                        </p>
                                    </>
                                </Alert>
                                <ConfirmationAlert
                                    confirmButtonText="Yes"
                                    cancelButtonText="No"
                                    onConfirm={() => this._finishSubmitProcess(values)}
                                    onCancel={this._closeAlert}
                                    isOpen={this.state.showRelatedAlert}
                                    icon="refresh"
                                    title="Confirm updates!"
                                    description="Are you sure you want to save changes to this department? It will affect all related staff records, shifts of shift patterns and past, current and future shifts of rotas"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._archiveLocation}
                                    onCancel={this._closeAlert}
                                    isOpen={this.state.showAlert}
                                    title="Confirm archiving!"
                                    description="Are you sure you want to archive this department? This action is irreversible."
                                />
                                <RouterPrompt
                                    when={this.state.changesNotSaved && this.state.isEdit}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default DepartmentDetails;
