import { Intent } from '@blueprintjs/core';
import { JobStatus } from '@doc-abode/data-models';

export const consentMappings = {
    CONSENTED: 'Consented',
    DID_NOT_CONSENT: 'Did not consent',
    UNKNOWN: 'Unknown',
};

export const statusMappings = {
    PENDING: 'Unassigned',
    AVAILABLE: 'Assigned',
    ACCEPTED: 'Accepted',
    CURRENT: 'Current',
    ARRIVED: 'Arrived',
    COMPLETED: 'Completed',
    HCP_ABORTED: 'Aborted by HCP',
    WITHDRAWN: 'Withdrawn',
    CONTROLLER_ABORTED: 'Archived',
    REMOVED: 'Removed',
};

export const statusTags = {
    PENDING: Intent.NONE,
    AVAILABLE: Intent.SUCCESS,
    ACCEPTED: Intent.SUCCESS,
    CURRENT: Intent.PRIMARY,
    ARRIVED: Intent.PRIMARY,
    HCP_ABORTED: Intent.DANGER,
    WITHDRAWN: Intent.WARNING,
    CONTROLLER_ABORTED: Intent.DANGER,
    REMOVED: Intent.DANGER,
};

export const manufacturerOfFirstDoseList = {
    PFIZER: 'Pfizer',
    ASTRAZENECA: 'AstraZeneca',
    MODERNA: 'Moderna',
};

export const archiveOptions = [
    {
        label: 'Vaccinated elsewhere',
        value: 'Vaccinated elsewhere',
    },
    {
        label: 'Unresponsive',
        value: 'Unresponsive',
    },
    {
        label: 'Declined',
        value: 'Declined',
    },
    {
        label: 'Moved out of region',
        value: 'Moved out of region',
    },
    {
        label: 'Passed away',
        value: 'Passed away',
    },
];

export const withdrawnOptions = [
    {
        label: 'Select...',
        value: '',
    },
    {
        label: 'Unable to contact patient',
        value: 'Unable to contact patient',
    },
    {
        label: 'Patient unable to receive vaccination at present',
        value: 'Patient unable to receive vaccination at present',
    },
    {
        label: 'Other reason',
        value: 'Other reason',
    },
];

export const dateFormat = 'DD/MM/YYYY';
export const dateTimeFormat = 'DD/MM/YYYY HH:mm';
export const timeFormat = 'HH:mm';
export const timeFormatLabel = 'HH:MM';

const CARE_HOME = 'Care Home';
const CARER = 'Carer';
const STAFF = 'Staff';
const SHELTERED_HOUSING = 'Sheltered Housing';
const SUPPORTED_LIVING = 'Supported Living';
const REFUGEE = 'Refugee';
const TEST = 'Test';

export const flagsOptions = [
    {
        label: CARE_HOME,
        value: CARE_HOME,
    },
    {
        label: CARER,
        value: CARER,
    },
    {
        label: STAFF,
        value: STAFF,
    },
    {
        label: SHELTERED_HOUSING,
        value: SHELTERED_HOUSING,
    },
    {
        label: SUPPORTED_LIVING,
        value: SUPPORTED_LIVING,
    },
    {
        label: REFUGEE,
        value: REFUGEE,
    },
    {
        label: 'Test Patient',
        value: TEST,
    },
];

export const flagsIcons = {
    'Care Home': 'office',
    Carer: 'person',
    Staff: 'briefcase',
    'Sheltered Housing': 'home',
    'Supported Living': 'lifesaver',
    Refugee: 'symbol-cross',
    Test: 'lab-test',
    'Learning difficulty': 'learning',
    NOK: 'person',
    Housebound: 'home',
};

export const incompleteOptions = [
    {
        label: 'Select...',
        value: '',
    },
    {
        label: 'Assigned',
        value: JobStatus.AVAILABLE,
    },
    {
        label: 'Current',
        value: JobStatus.CURRENT,
    },
    {
        label: 'Arrived',
        value: JobStatus.ARRIVED,
    },
];
