import React, { Component, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Button, FormGroup, InputGroup, ControlGroup } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';

import { validationSchema } from './validation';
import FormError from '../../../modules/forms/Error';
import { PerformersList, GMC } from './professional-bodies';
import Select from 'react-select';
import { Registration } from '../../../../stores/RegistrationStore';
import { getRegistrationData } from '../../../../api/registrationApi';
import AppToaster from '../../../modules/helpers/Toaster';

interface RouterProps {
    employeeId?: string;
    personId?: string;
}

interface RegistrationDetailsProps extends Partial<RouteComponentProps<RouterProps>> {
    actionType: string;
    RootStore?: any;
    registrationId: string | null;
    personId: string;
    handleCancel: () => void;
    disableEditing: boolean;
}
interface RegistrationDetailsState {
    isEdit: boolean;
    isLoaded: boolean;
    isSearching: boolean;
    initialData: any;
    registrationData: { [key: string]: any } | null;
    fitToPracticeData: any;
    professionalBodyData: any;
    registrationQueryError: string;
    registrationNumberError: string;
    registrationNumberValid: boolean;
}
const fixStateRegistrationDataSpreading = (
    registrationData: { [key: string]: any } | null,
): { [key: string]: any } => {
    return registrationData || {};
};
const RegistrationDetails = inject('RootStore')(
    observer(
        class RegistrationDetails extends Component<
            RegistrationDetailsProps,
            RegistrationDetailsState
        > {
            state = {
                isEdit: false,
                isLoaded: false,
                isSearching: false,
                initialData: {
                    id: '',
                    personId: '',
                    fitToPracticeId: '',
                    professionalBodyId: '',
                    registrationContent: null,
                    createdAt: null,
                    updatedAt: null,
                    fitToPractice: {},
                    professionalBody: {},
                    person: null,
                    createdPerson: null,
                    updatedPerson: null,
                    registrationNumber: '',
                },
                registrationData: null,
                fitToPracticeData: {
                    value: '',
                    label: '',
                },
                professionalBodyData: {
                    value: '',
                    label: '',
                    key: '',
                },
                registrationQueryError: '',
                registrationNumberError: '',
                registrationNumberValid: false,
            };

            componentDidMount() {
                const {
                    RootStore: {
                        registrationStore: { registrations },
                    },
                    actionType,
                    registrationId,
                } = this.props;

                if (actionType === 'create') {
                    this.setState({ isEdit: true });
                }

                if (actionType === 'edit') {
                    const registration = registrations.find((registration: Registration) => {
                        return registration.id === registrationId;
                    });

                    if (registration) {
                        const {
                            id,
                            personId,
                            fitToPracticeId,
                            professionalBodyId,
                            registrationContent,
                            createdAt,
                            updatedAt,
                            fitToPractice,
                            professionalBody,
                            person,
                            createdPerson,
                            updatedPerson,
                        } = registration;

                        const fitToPracticeData = this.props.RootStore.fitToPracticeStore.fitToPractices.find(
                            (fitToPractice: any) =>
                                fitToPractice.id === registration.fitToPracticeId,
                        );

                        const professionalBodyData = this.props.RootStore.professionalBodyStore.professionalBodies.find(
                            (professionalBody: any) =>
                                professionalBody.id === registration.professionalBodyId,
                        );

                        this.setState({
                            initialData: {
                                id,
                                personId,
                                fitToPracticeId,
                                professionalBodyId,
                                registrationContent,
                                createdAt,
                                updatedAt,
                                fitToPractice,
                                professionalBody,
                                person,
                                createdPerson,
                                updatedPerson,
                                registrationNumber: registrationContent.registrationNumber,
                            },
                            isLoaded: true,
                            fitToPracticeData: {
                                value: fitToPracticeData.id,
                                label: fitToPracticeData.label,
                            },
                            registrationData: registration.registrationContent,
                            professionalBodyData,
                        });
                    }
                }
            }

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'edit') {
                    return this._toggleIsEdit();
                } else {
                    this.props.handleCancel();
                }
            };

            _handleDelete = async () => {
                const err = await this.props.RootStore.registrationStore.deleteRegistration(
                    this.state.initialData.id,
                );
                if (!err) {
                    this.props.handleCancel();
                }
            };

            _handleSaveClick = (callback: () => void) => {
                callback?.();
            };

            _handleQueryRegistration = async (registrationNumber?: string) => {
                let professionalBody;

                if (this.props.actionType === 'create') {
                    professionalBody = this.state.professionalBodyData;
                } else {
                    professionalBody = this.props.RootStore.professionalBodyStore.professionalBodies.find(
                        (professionalBody: any) =>
                            professionalBody.id === this.state.initialData.professionalBodyId,
                    );
                }

                try {
                    this.setState({ isSearching: true });

                    const userSession = await this.props.RootStore.userStore.getUserSession();
                    const response = await getRegistrationData(
                        professionalBody.key,
                        registrationNumber || this.state.initialData.registrationNumber,
                        userSession.tokens.id,
                    );

                    const [registrationData] = response.records;

                    if (registrationData) {
                        this.setState({
                            registrationData,
                        });
                    }
                } catch (err) {
                    if (err instanceof Error && err.message) {
                        this.setState({
                            registrationQueryError: `No ${this.state.professionalBodyData.label} registration found for registration number ${registrationNumber}`,
                        });
                    } else {
                        AppToaster.show({
                            message:
                                'Sorry, there was a problem querying registration. Please try again.',
                            intent: 'danger',
                        });
                    }
                }

                this.setState({ isSearching: false });
            };

            _setFitToPracticeValue = (value: any) => {
                this.setState({
                    fitToPracticeData: value,
                });
            };

            _setProfessionalBodyValue = (value: any, registrationNumber: string) => {
                this.setState(
                    {
                        professionalBodyData: value,
                        registrationQueryError: '',
                        registrationNumberError: '',
                    },
                    () => {
                        if (registrationNumber) {
                            this._handleRegistrationBlur(registrationNumber);
                        }
                    },
                );
            };

            _handleRegistrationBlur = (registrationNumber: string) => {
                if (registrationNumber) {
                    if (
                        this.state.professionalBodyData.key === 'gmc' &&
                        !registrationNumber.match(/^\d{7}$/)
                    ) {
                        this.setState({
                            registrationNumberError:
                                'Invalid registration number format; should be 7 digits',
                            registrationNumberValid: false,
                        });
                    } else {
                        this.setState({
                            registrationNumberValid: true,
                        });
                    }
                } else {
                    this.setState({
                        registrationNumberError: 'This field is required',
                        registrationNumberValid: false,
                    });
                }
            };

            render() {
                const {
                    RootStore: {
                        registrationStore,
                        professionalBodyStore: { professionalBodies },
                        fitToPracticeStore: { fitToPractices },
                    },
                    actionType,
                    handleCancel,
                } = this.props;

                const { isLoaded, isEdit } = this.state;

                if (actionType === 'edit' && !isLoaded) {
                    return null;
                }

                return (
                    <Formik
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validateOnChange={true}
                        onSubmit={async (values, { setSubmitting }) => {
                            const {
                                id,
                                fitToPracticeId,
                                professionalBodyId,
                                registrationNumber,
                            } = values;

                            const normalisedValues = {
                                id,
                                personId: this.props.personId,
                                fitToPracticeId,
                                professionalBodyId,
                                registrationContent: {
                                    ...(this.state.registrationData || {}),
                                    registrationNumber,
                                },
                            };

                            if (actionType === 'create') {
                                await registrationStore.createRegistration(normalisedValues);
                                handleCancel();
                            }

                            if (actionType === 'edit') {
                                const registration = await registrationStore.updateRegistration(
                                    normalisedValues,
                                );

                                const {
                                    id,
                                    personId,
                                    fitToPracticeId,
                                    professionalBodyId,
                                    registrationContent,
                                    createdAt,
                                    updatedAt,
                                    fitToPractice,
                                    professionalBody,
                                    person,
                                    createdPerson,
                                    updatedPerson,
                                } = registration;

                                this.setState({
                                    initialData: {
                                        id,
                                        personId,
                                        fitToPracticeId,
                                        professionalBodyId,
                                        registrationContent,
                                        createdAt,
                                        updatedAt,
                                        fitToPractice,
                                        professionalBody,
                                        person,
                                        createdPerson,
                                        updatedPerson,
                                    },
                                    isEdit: false,
                                });
                            }

                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                            handleSubmit,
                        }) => {
                            useEffect(() => {
                                this.setState({
                                    registrationQueryError: '',
                                    registrationNumberError: '',
                                });
                            }, [values.registrationNumber]);

                            return (
                                <Form className="modal__form">
                                    <h3 className="h3 modal__sub-heading">
                                        {actionType === 'create'
                                            ? 'Add Registration'
                                            : 'View Registration'}
                                        <Button
                                            icon="cross"
                                            minimal
                                            className="modal__sub-heading-btn"
                                            onClick={handleCancel}
                                        />
                                    </h3>
                                    <dl className="info">
                                        <dt className="info__title">Professional Body</dt>
                                        <dd className="info__definition">
                                            {actionType === 'create' ? (
                                                <FormGroup labelFor="professionalBodyId">
                                                    <Select
                                                        id="professionalBodyId"
                                                        name="professionalBodyId"
                                                        options={professionalBodies.map(
                                                            (professionalBody: any) => ({
                                                                value: professionalBody.id,
                                                                label: professionalBody.name,
                                                                key: professionalBody.key,
                                                            }),
                                                        )}
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'professionalBodyId',
                                                                e!.value,
                                                            );
                                                            this._setProfessionalBodyValue(
                                                                e,
                                                                values.registrationNumber,
                                                            );
                                                        }}
                                                    />
                                                    {touched.professionalBodyId && (
                                                        <FormError
                                                            errors={[errors.professionalBodyId]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : (
                                                (this.state.initialData.professionalBody as any)
                                                    .name
                                            )}
                                        </dd>
                                        <dt className="info__title">Registration Number</dt>
                                        <dd className="info__definition">
                                            {actionType === 'create' ? (
                                                <FormGroup labelFor="registrationNumber">
                                                    <ControlGroup fill>
                                                        <InputGroup
                                                            id="registrationNumber"
                                                            name="registrationNumber"
                                                            onChange={handleChange}
                                                            onBlur={() =>
                                                                this._handleRegistrationBlur(
                                                                    values.registrationNumber,
                                                                )
                                                            }
                                                            value={values.registrationNumber || ''}
                                                            large
                                                        />
                                                        <Button
                                                            type="button"
                                                            intent="primary"
                                                            onClick={() =>
                                                                this._handleQueryRegistration(
                                                                    values.registrationNumber,
                                                                )
                                                            }
                                                            disabled={
                                                                isSubmitting ||
                                                                !values.professionalBodyId ||
                                                                !values.registrationNumber ||
                                                                !this.state.registrationNumberValid
                                                            }
                                                            loading={this.state.isSearching}
                                                            icon="search"
                                                            outlined
                                                        >
                                                            Look up
                                                        </Button>
                                                    </ControlGroup>
                                                    {touched.registrationNumber && (
                                                        <FormError
                                                            errors={[errors.registrationNumber]}
                                                        />
                                                    )}
                                                    {this.state.registrationQueryError && (
                                                        <FormError
                                                            errors={[
                                                                this.state.registrationQueryError,
                                                            ]}
                                                        />
                                                    )}
                                                    {this.state.registrationNumberError && (
                                                        <FormError
                                                            errors={[
                                                                this.state.registrationNumberError,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : (
                                                (this.state.initialData.registrationContent as any)
                                                    .registrationNumber
                                            )}
                                        </dd>
                                        {actionType === 'edit' && (
                                            <>
                                                <dt className="info__title"></dt>
                                                <dd className="info__definition">
                                                    <Button
                                                        type="button"
                                                        intent="primary"
                                                        onClick={() =>
                                                            this._handleQueryRegistration()
                                                        }
                                                        disabled={isSubmitting}
                                                        loading={this.state.isSearching}
                                                        icon="refresh"
                                                        outlined
                                                    >
                                                        Reload registration details
                                                    </Button>
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                    {this.state.registrationData !== null && (
                                        <>
                                            {this.state.professionalBodyData.key ===
                                            'performers-list' ? (
                                                <PerformersList
                                                    {...fixStateRegistrationDataSpreading(
                                                        this.state.registrationData,
                                                    )}
                                                />
                                            ) : this.state.professionalBodyData.key === 'gmc' ? (
                                                <GMC
                                                    {...fixStateRegistrationDataSpreading(
                                                        this.state.registrationData,
                                                    )}
                                                />
                                            ) : null}

                                            <h3 className="h3 modal__sub-heading">Conclusion</h3>
                                            <dl className="info">
                                                <dt className="info__title">Fit To Practice</dt>
                                                <dd className="info__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="fitToPracticeId">
                                                            <Select
                                                                id="fitToPracticeId"
                                                                name="fitToPracticeId"
                                                                options={fitToPractices.map(
                                                                    (fitToPractice: any) => ({
                                                                        value: fitToPractice.id,
                                                                        label: fitToPractice.label,
                                                                    }),
                                                                )}
                                                                value={this.state.fitToPracticeData}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        'fitToPracticeId',
                                                                        e!.value,
                                                                    );
                                                                    this._setFitToPracticeValue(e);
                                                                }}
                                                            />
                                                            {touched.fitToPracticeId && (
                                                                <FormError
                                                                    errors={[
                                                                        errors.fitToPracticeId,
                                                                    ]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        (this.state.initialData
                                                            .fitToPractice as any).label
                                                    )}
                                                </dd>
                                            </dl>
                                        </>
                                    )}

                                    <footer className="modal__footer">
                                        {actionType === 'edit' && !isEdit && (
                                            <>
                                                {!this.props.disableEditing && (
                                                    <Button
                                                        type="button"
                                                        large
                                                        intent="danger"
                                                        onClick={this._handleDelete}
                                                        icon="trash"
                                                        disabled={isSubmitting}
                                                        outlined
                                                    >
                                                        Delete
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                        {isEdit && (
                                            <>
                                                <Button
                                                    large
                                                    onClick={this._handleOnCancel}
                                                    icon="cross"
                                                    outlined
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    intent="success"
                                                    large
                                                    icon="tick"
                                                    type="button"
                                                    loading={isSubmitting}
                                                    outlined
                                                    onClick={() =>
                                                        this._handleSaveClick(handleSubmit)
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                        {!isEdit && !this.props.disableEditing && (
                                            <Button
                                                large
                                                onClick={this._toggleIsEdit}
                                                icon="edit"
                                                intent="primary"
                                                disabled={isSubmitting}
                                                outlined
                                            >
                                                Edit details
                                            </Button>
                                        )}
                                    </footer>
                                </Form>
                            );
                        }}
                    </Formik>
                );
            }
        },
    ),
);

export default RegistrationDetails;
