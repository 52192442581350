import React, { Component, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import Admin, { routes as adminRoutes } from './admin/Main';
import Controller, { routes as controllerRoutes } from './controller/Main';
import Loader from '../modules/helpers/Loader';

const RedirectToHome = ({ setRedirectToHome }) => {
    useEffect(() => {
        setRedirectToHome(false);
    }, [setRedirectToHome]);

    return <Redirect to="/" />;
};

const Layout = inject('RootStore')(
    observer(
        class Layout extends Component {
            componentDidMount() {
                const {
                    configStore: { PORTAL_MODES, setPortalMode },
                } = this.props.RootStore;

                // Handle deep links by checking pathname and changing portalMode if necessary
                controllerRoutes.forEach(({ path }) => {
                    if (this.props.location.pathname.startsWith(path)) {
                        setPortalMode(PORTAL_MODES.CONTROLLER);
                    }
                });
                adminRoutes.forEach(({ path }) => {
                    if (this.props.location.pathname.startsWith(path)) {
                        setPortalMode(PORTAL_MODES.ADMIN);
                    }
                });
            }

            render() {
                const {
                    configStore: { portalMode, PORTAL_MODES },
                    userStore: { redirectToHome, setRedirectToHome },
                } = this.props.RootStore;

                if (redirectToHome) {
                    return <RedirectToHome setRedirectToHome={setRedirectToHome} />;
                }

                return portalMode === PORTAL_MODES.ADMIN ? (
                    <Admin {...this.props} />
                ) : portalMode === PORTAL_MODES.CONTROLLER ? (
                    <Controller {...this.props} />
                ) : (
                    <Loader />
                );
            }
        },
    ),
);

export default Layout;
