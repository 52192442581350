import React from 'react';
import useHeaderSorting from '../../../../hook/useHeaderSorting';
import SortedHeader from '../../../common/sorting/SortedHeader';

interface DepartmentAssignmentsHeaderProps {
    onSort: (props: any) => void;
    isFrontendSort: boolean;
    isEmployee: boolean;
}

const DepartmentAssignmentsHeader = ({
    onSort,
    isFrontendSort = true,
}: DepartmentAssignmentsHeaderProps) => {
    const defaultSortState = {
        column: 'startDate',
        orderBy: 'asc',
    };
    const { sort, sortData } = useHeaderSorting(
        (s: any) => s,
        onSort,
        isFrontendSort,
        defaultSortState,
    );
    return (
        <thead>
            <tr>
                <SortedHeader
                    sort={sort}
                    sortBy={'costCentreName'}
                    text="Cost Centre"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'departmentName'}
                    text="Department"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'startDate'}
                    text="Start Date"
                    sortData={sortData}
                />
                <SortedHeader sort={sort} sortBy={'endDate'} text="End Date" sortData={sortData} />
                <SortedHeader
                    sort={sort}
                    sortBy={'payRateName'}
                    text="Pay Rate"
                    sortData={sortData}
                />
                <SortedHeader sort={sort} sortBy={'isArchived'} text="Status" sortData={sortData} />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdOn'}
                    text="Created on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdBy'}
                    text="Created by"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedOn'}
                    text="Updated on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedBy'}
                    text="Updated by"
                    sortData={sortData}
                />
            </tr>
        </thead>
    );
};

export default DepartmentAssignmentsHeader;
