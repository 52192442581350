import { FC, useContext } from 'react';
import { Formik, FormikValues } from 'formik';
import { useMutation } from '@apollo/client';
import { JobStatus } from '@doc-abode/data-models';
import moment from 'moment';

import { UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import { JobsContext } from '../../../../../providers';
import { useView } from '../../views/useView';
import AbortDoubleVisitForm from './AbortDoubleVisitForm';
import AbortSingleVisitForm from './AbortSingleVisitForm';

interface IProps {
    onClose: () => void;
}

interface APIValuesInterface {
    controllerAbortedReason?: string;
    controllerAbortedNotes?: string;
    buddyControllerAbortedReason?: string;
    buddyControllerAbortedNotes?: string;
    id: string;
    jobStatus?: JobStatus | undefined;
    jobStatusBeforeAbort?: JobStatus;
    buddyJobStatusBeforeAbort?: JobStatus | undefined;
    buddyJobStatus?: JobStatus | undefined;
    lastUpdatedBy: string | undefined;
    lastUpdatedDateTime: string;
    version: number;
    finishedDateTime?: string;
    buddyFinishedDateTime?: string;
}

const AbortVisit: FC<IProps> = ({ onClose }) => {
    const jobsContext = useContext(JobsContext);

    const { currentViewState } = useView();

    const [updateJob, { loading }] = useMutation(UPDATE_JOB, {
        onCompleted: () => {
            if (!currentViewState.patientList) {
                jobsContext.setRefreshAssignedJobs(true);
            } else {
                jobsContext.setRefreshPatients(true);
            }
        },
    });

    const {
        RootStore: {
            ucrStore: { focusedUser, abortVisit, setAbortVisit, setOpenedDialog },
            userStore: {
                user: { username },
            },
            lovsStore: { controllerAbortReason },
        },
    } = useStores() as { RootStore: any };

    if (!abortVisit) return <></>;

    const isDoubleUp = abortVisit.staffRequired === 2;
    const isFirstUser = Boolean(!isDoubleUp || (isDoubleUp && focusedUser === 'user1'));

    const isDisabled =
        (isFirstUser &&
            (abortVisit.jobStatus === JobStatus.CONTROLLER_ABORTED ||
                abortVisit.jobStatus === JobStatus.HCP_ABORTED)) ||
        (!isFirstUser &&
            (abortVisit.buddyJobStatus === JobStatus.CONTROLLER_ABORTED ||
                abortVisit.buddyJobStatus === JobStatus.HCP_ABORTED));

    const onSubmit = async (values: FormikValues) => {
        if (
            isDisabled ||
            (isDoubleUp && values.cancelationOption === '') ||
            values.controllerAbortedReason === ''
        ) {
            return;
        }

        const isUser1 = !isDoubleUp || (isDoubleUp && values.cancelationOption === 'user1');
        const isUser2 = isDoubleUp && values.cancelationOption === 'user2';

        let input: APIValuesInterface = {
            id: abortVisit.id,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().toISOString(),
            version: abortVisit.version + 1,
        };

        if (isUser1 || !isDoubleUp) {
            // if user1
            input.controllerAbortedNotes = values.controllerAbortedNotes;
            input.controllerAbortedReason = values.controllerAbortedReason;
            input.jobStatusBeforeAbort = abortVisit.jobStatus;
            input.finishedDateTime = new Date().toISOString();
            input.jobStatus = JobStatus.CONTROLLER_ABORTED;
        } else if (isUser2) {
            /// if user2
            input.buddyControllerAbortedReason = values.controllerAbortedReason;
            input.buddyControllerAbortedNotes = values.controllerAbortedNotes;
            input.buddyJobStatusBeforeAbort = abortVisit.buddyJobStatus;
            input.buddyFinishedDateTime = new Date().toISOString();
            input.buddyJobStatus = JobStatus.CONTROLLER_ABORTED;
        } else {
            // all visits
            input.buddyControllerAbortedReason = values.controllerAbortedReason;
            input.buddyControllerAbortedNotes = values.controllerAbortedNotes;
            input.buddyJobStatusBeforeAbort = abortVisit.buddyJobStatus;
            input.buddyFinishedDateTime = new Date().toISOString();
            input.buddyJobStatus = JobStatus.CONTROLLER_ABORTED;
            input.controllerAbortedNotes = values.controllerAbortedNotes;
            input.controllerAbortedReason = values.controllerAbortedReason;
            input.jobStatusBeforeAbort = abortVisit.jobStatus;
            input.finishedDateTime = new Date().toISOString();
            input.jobStatus = JobStatus.CONTROLLER_ABORTED;
        }

        try {
            await updateJob({ variables: { input } });
            setAbortVisit(null);
            setOpenedDialog(Dialogs.NONE);
            AppToaster.show({
                message: 'Visit aborted successfully',
                intent: 'success',
            });
        } catch (err) {
            console.error('Error aborting visit', err);
            AppToaster.show({
                message: 'Sorry, an error occurred and we were unable to cancel the visit',
                intent: 'danger',
            });
        }
    };

    return (
        <Formik
            initialValues={{
                cancelationOption: !isDisabled && (focusedUser || ''),
                controllerAbortedNotes: '',
                controllerAbortedReason:
                    controllerAbortReason && controllerAbortReason.length > 0
                        ? controllerAbortReason[0].value
                        : '',
            }}
            onSubmit={onSubmit}
        >
            {abortVisit &&
                (isDoubleUp ? (
                    <AbortDoubleVisitForm visit={abortVisit} loading={loading} onClose={onClose} />
                ) : (
                    <AbortSingleVisitForm visit={abortVisit} loading={loading} onClose={onClose} />
                ))}
        </Formik>
    );
};

export default AbortVisit;
