import { gql } from '@apollo/client';

export const UPDATE_PERSON_CHECKLIST = gql`
    mutation updatePersonInductionChecklist(
        $id: String!
        $data: PersonInductionChecklistEntityInput!
    ) {
        updatePersonInductionChecklist(id: $id, data: $data) {
            id
        }
    }
`;
