import React from 'react';
import CommonPagination from '../CommonHRRotaPagination';
import { Button } from '@blueprintjs/core';

const RegistrationNavigation = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    addRegistration,
}: any) => {
    return (
        <>
            <div>
                <Button
                    text="Add"
                    intent="success"
                    icon="add"
                    onClick={addRegistration}
                    className="common-header__button"
                    outlined
                />
            </div>
            <nav className="common-header">
                {!loading && (
                    <CommonPagination
                        previousPage={previousPage}
                        nextPage={nextPage}
                        pageInfo={pageInfo}
                        itemsName="registrations"
                    />
                )}
            </nav>
        </>
    );
};
export default RegistrationNavigation;
