import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_CATEGORIES } from '../graphql/queries/categories';
import {
    COMMON_DICTIONARY_QUERIES,
    GET_COUNTRIES_OPTIONS,
    GET_DISABILITY_OPTIONS,
    GET_ETHNICITIES_OPTIONS,
    GET_MARITAL_OPTIONS,
    GET_RELIGION_OPTIONS,
    GET_SEXUAL_OPTIONS,
    GET_TITLES_OPTIONS,
} from '../graphql/queries/dictionary';
import _ from 'lodash';
import { ANY_FUNCTION } from '../constants/hrConst';

export interface DictionaryItem {
    value: string;
    label: string;
    id: string;
    name: string;
}

export interface Dictionary {
    [key: string]: string;
}

class DictionaryStore {
    constructor(rootStore: any) {
        makeObservable(this, {
            apolloClient: observable,
            agencies: observable,
            roles: observable,
            locations: observable,
            groups: observable,
            payRates: observable,
            specialities: observable,
            contractTypes: observable,
            notificationTypes: observable,
            languages: observable,
            genders: observable,
            absenceTypes: observable,
            absenceStatuses: observable,
            peopleCategories: observable,
            staffCategories: observable,
            loaded: observable,
            shiftStatuses: observable,
            shiftTypes: observable,
            departments: observable,
            persons: observable,
            performerAlignments: observable,
            performerRoles: observable,
            performerStatuses: observable,
            performerTeams: observable,
            costCentres: observable,
            fundingPools: observable,
            functions: observable,
            load: action,
            agenciesDictionary: computed,
            rolesDictionary: computed,
            locationsDictionary: computed,
            groupsDictionary: computed,
            payRatesDictionary: computed,
            specialitiesDictionary: computed,
            contractDictionary: computed,
            notificationDictionary: computed,
            languagesDictionary: computed,
            gendersDictionary: computed,
            absenceTypesDictionary: computed,
            absenceStatusesDictionary: computed,
            peopleCategoriesDictionary: computed,
            staffCategoriesDictionary: computed,
            shiftStatusesDictionary: computed,
            shiftTypesDictionary: computed,
            departmentsDictionary: computed,
            personsDictionary: computed,
            performerAlignmentsDictionary: computed,
            performerRolesDictionary: computed,
            performerStatusesDictionary: computed,
            performerTeamsDictionary: computed,
            costCentresDictionary: computed,
            fundingPoolsDictionary: computed,
            functionsDictionary: computed,
        });
        this.rootStore = rootStore;
    }

    apolloClient: any;
    rootStore: any;
    agencies = [];
    roles = [];
    locations = [];
    groups = [];
    payRates = [];
    specialities = [];
    contractTypes = [];
    notificationTypes = [];
    languages = [];
    genders = [];
    absenceTypes = [];
    absenceStatuses = [];
    peopleCategories = [];
    staffCategories = [];
    shiftTypes = [];
    departments = [];
    shiftStatuses = [];
    loaded = false;
    persons = [];
    performerAlignments = [];
    performerRoles = [];
    performerStatuses = [];
    performerTeams = [];
    costCentres = [];
    fundingPools = [];
    functions = [];

    load = async (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
        const [commonDictionary, peopleCategories, staffCategories] = await Promise.all([
            apolloClient.query({ query: COMMON_DICTIONARY_QUERIES }),
            apolloClient.query({
                query: GET_CATEGORIES,
                variables: {
                    filter: {
                        role: 'person',
                    },
                },
            }),
            apolloClient.query({
                query: GET_CATEGORIES,
                variables: {
                    filter: {
                        role: 'employee',
                    },
                },
            }),
        ]);
        runInAction(() => {
            this.agencies = this.mapItems(commonDictionary.data.getAgencies);
            this.roles = this.mapItemsWithArchiveField(commonDictionary.data.getEmployeeRoleTypes);
            this.locations = this.mapItemsWithArchiveField(commonDictionary.data.getLocations);
            this.groups = this.mapItems(commonDictionary.data.getGroups);
            this.payRates = this.mapItems(commonDictionary.data.getPayRates);
            this.specialities = this.mapItems(commonDictionary.data.getSpecialities);
            this.contractTypes = this.mapItemsWithoutNode(commonDictionary.data.getContractTypes);
            this.notificationTypes = this.mapItemsWithoutNode(
                commonDictionary.data.getNotificationTypes,
            );
            this.languages = this.mapItemsWithoutNode(commonDictionary.data.getLanguages);
            this.genders = this.mapItemsWithoutNode(commonDictionary.data.getGenders);

            this.absenceTypes = this.mapItems(commonDictionary.data.getAbsenceTypes);
            this.absenceStatuses = this.mapItems(commonDictionary.data.getAbsenceStatuses);
            this.peopleCategories = this.mapItemsWithoutNode(
                peopleCategories.data.getDocumentCategories,
            );
            this.staffCategories = this.mapItemsWithoutNode(
                staffCategories.data.getDocumentCategories,
            );
            this.shiftTypes = this.mapItemsWithoutNode(commonDictionary.data.getShiftTypes);
            this.shiftStatuses = this.mapItemsWithoutNode(commonDictionary.data.getShiftStatuses);
            this.departments = this.mapItemsWithArchiveField(commonDictionary.data.getDepartments);
            this.loaded = true;
            this.persons = commonDictionary.data.getPersons.edges.map((r: any) => ({
                value: r.node.id,
                label: `${r.node.firstName} ${r.node.lastName}`,
            }));
            this.performerAlignments = this.mapItems(commonDictionary.data.getPerformerAlignments);
            this.performerRoles = this.mapItems(commonDictionary.data.getPerformerRoles);
            this.performerStatuses = this.mapItems(commonDictionary.data.getPerformerStatuses);
            this.performerTeams = this.mapItems(commonDictionary.data.getPerformerTeams);
            this.costCentres = this.mapItemsWithArchiveField(commonDictionary.data.getCostCentres);
            this.fundingPools = this.mapItemsWithArchiveField(
                commonDictionary.data.getFundingPools,
            );
            this.functions = this.mapItems(commonDictionary.data.getFunctions);
        });
    };

    get agenciesDictionary() {
        return this.makeDictionary(this.agencies);
    }

    get rolesDictionary() {
        return this.makeDictionary(this.roles);
    }

    get locationsDictionary() {
        return this.makeDictionary(this.locations);
    }

    get groupsDictionary() {
        return this.makeDictionary(this.groups);
    }

    get payRatesDictionary() {
        return this.makeDictionary(this.payRates);
    }

    get specialitiesDictionary() {
        return this.makeDictionary(this.specialities);
    }

    get contractDictionary() {
        return this.makeDictionary(this.contractTypes);
    }

    get notificationDictionary() {
        return this.makeDictionary(this.notificationTypes);
    }

    get languagesDictionary() {
        return this.makeDictionary(this.languages);
    }

    get gendersDictionary() {
        return this.makeDictionary(this.genders);
    }

    get absenceTypesDictionary() {
        return this.makeDictionary(this.absenceTypes);
    }

    get absenceStatusesDictionary() {
        return this.makeDictionary(this.absenceStatuses);
    }

    get peopleCategoriesDictionary() {
        return this.makeDictionary(this.peopleCategories);
    }

    get staffCategoriesDictionary() {
        return this.makeDictionary(this.staffCategories);
    }

    get shiftStatusesDictionary() {
        return this.makeDictionary(this.shiftStatuses);
    }

    get shiftTypesDictionary() {
        return this.makeDictionary(this.shiftTypes);
    }

    get departmentsDictionary() {
        return this.makeDictionary(this.departments);
    }

    get personsDictionary() {
        return this.makeDictionary(this.persons);
    }

    get performerAlignmentsDictionary() {
        return this.makeDictionary(this.performerAlignments);
    }

    get performerRolesDictionary() {
        return this.makeDictionary(this.performerRoles);
    }

    get performerStatusesDictionary() {
        return this.makeDictionary(this.performerStatuses);
    }

    get performerTeamsDictionary() {
        return this.makeDictionary(this.performerTeams);
    }

    get costCentresDictionary() {
        return this.makeDictionary(this.costCentres);
    }

    get fundingPoolsDictionary() {
        return this.makeDictionary(this.fundingPools);
    }

    get functionsDictionary() {
        return { [ANY_FUNCTION]: 'Any', ...this.makeDictionary(this.functions) };
    }

    getCountries = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_COUNTRIES_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };
    getEthnicities = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_ETHNICITIES_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };
    getReligionsOrBeliefs = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_RELIGION_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };
    getSexualOrientations = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_SEXUAL_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };

    getMartialStatuses = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_MARITAL_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };
    getDisabilities = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_DISABILITY_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };

    getTitles = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_TITLES_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: 'asc',
                field: 'name',
                query: query || '',
            },
        });
    };

    mapItems(items: any) {
        let arr = items?.edges.map((item: any) => ({
            value: item.node.id,
            label: item.node.name,
        }));

        arr = _.orderBy(arr, ['label'], ['asc']);
        return arr ?? [];
    }

    mapItemsWithArchiveField(items: any) {
        let arr = items?.edges.map((item: any) => ({
            value: item.node.id,
            label: item.node.name,
            isArchived: item.node.isArchived,
        }));

        arr = _.orderBy(arr, ['label'], ['asc']);
        return arr ?? [];
    }

    mapItemsWithoutNode(items: any) {
        let arr =
            items?.map((item: any) => ({
                value: item.id,
                label: item.name,
            })) ?? [];

        arr = _.orderBy(arr, ['label'], ['asc']);
        return arr ?? [];
    }

    makeDictionary(items: Array<DictionaryItem>) {
        return items.reduce((acc: Dictionary, item: DictionaryItem) => {
            const key: string = item.value || item.id;
            acc[key] = item.label || item.name;
            return acc;
        }, {});
    }

    mapServices(items: any) {
        let arr = items?.edges.map((item: any) => ({
            value: item.node.id,
            label: item.node.name,
        }));

        arr = _.orderBy(arr, ['label'], ['asc']);
        return arr ?? [];
    }

    mapArray(items: any) {
        return items.map((r: any) => r);
    }
}

export default DictionaryStore;
