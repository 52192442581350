import { observer } from 'mobx-react';
import { FC } from 'react';

import { IconAdd, IconRemove } from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';

interface IProps {}

const CollapseAndExpandButtons: FC<IProps> = observer(() => {
    const {
        RootStore: {
            ucrStore: { setAllExpandedState },
        },
    } = useStores() as { RootStore: any };

    const collapseAll = () => {
        setAllExpandedState(false);
    };

    const expandAll = () => {
        setAllExpandedState(true);
    };

    return (
        <div className="ucr__calendar-buttons">
            <div className="ucr__calendar-buttons-button-wrapper">
                <Button
                    color={ButtonColors.GREY}
                    size={ButtonSizes.SMALL}
                    Icon={IconRemove}
                    name="Collapse all"
                    clickEvent={collapseAll}
                />
            </div>
            <div className="ucr__calendar-buttons-button-wrapper">
                <Button
                    color={ButtonColors.GREY}
                    size={ButtonSizes.SMALL}
                    Icon={IconAdd}
                    name="Expand all"
                    clickEvent={expandAll}
                />
            </div>
        </div>
    );
});

export default CollapseAndExpandButtons;
