import React from 'react';
import { Icon, Tooltip } from '@blueprintjs/core';

interface ViewProps {
    hasDeleteOption?: boolean;
    hasDuplicateOption?: boolean;
    hasSplitOption?: boolean;
    handleDeleteClick?: () => void;
    handleDuplicateClick?: () => void;
    handleSplitClick?: () => void;
    isPublished?: boolean;
    openPaymentDialog?: (id: [string], activePaymentStatusId?: string) => void;
    shiftId?: string;
    paymentStatusId: string;
}

const TableRowActions = (props: ViewProps) => {
    const {
        hasSplitOption = false,
        hasDeleteOption = true,
        hasDuplicateOption = true,
        handleDeleteClick,
        handleDuplicateClick,
        handleSplitClick,
        isPublished,
        openPaymentDialog,
        shiftId,
        paymentStatusId,
    } = props;

    const paidText = `Set payment status`;

    return (
        <div className="table-row-actions">
            <Tooltip
                usePortal={false}
                hoverCloseDelay={200}
                position={'left'}
                minimal={true}
                content={
                    <div className="table-row-actions__list">
                        {hasSplitOption && (
                            <span onClick={handleSplitClick} className="table-row-action">
                                Split <Icon title="Split" intent="primary" icon="fork" />
                            </span>
                        )}
                        {hasDeleteOption && (
                            <span onClick={handleDeleteClick} className="table-row-action">
                                Delete <Icon title="Delete" intent="danger" icon="trash" />
                            </span>
                        )}
                        {hasDuplicateOption && (
                            <span
                                onClick={handleDuplicateClick}
                                className="table-row-action table-row-action--duplicate"
                            >
                                Duplicate <Icon icon="duplicate" />
                            </span>
                        )}
                        {isPublished && (
                            <span
                                onClick={() =>
                                    openPaymentDialog &&
                                    shiftId &&
                                    openPaymentDialog([shiftId], paymentStatusId)
                                }
                                className="table-row-action table-row-action--duplicate"
                            >
                                {paidText}
                                {'   '}
                                <Icon icon="dollar" intent="success" />
                            </span>
                        )}
                    </div>
                }
            >
                <Icon title="More" icon="more" />
            </Tooltip>
        </div>
    );
};

export default TableRowActions;
