import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { formatDisplayDateTime, formatFullName } from '../../helpers/formatData';
import { Card, Tag, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import { capitalize, lowerCase } from 'lodash';

import InfoPair from '../../helpers/InfoPair';
import HcpNameLink from '../detail/HcpNameLink';

class HistoricJobListItem extends Component {
    render() {
        return (
            <Card
                className={classNames(
                    'historic-jobs-list__item',
                    `historic-jobs-list__item--${this.props.data.jobStatus.toLowerCase()}`,
                )}
                interactive
                onClick={(event) =>
                    this.props.onListItemClick(this.props.data.id, this.props.type, event)
                }
            >
                <Tag
                    className={classNames(
                        'historic-jobs-list__tag',
                        this.props.data.jobStatus === 'CONTROLLER_ABORTED'
                            ? 'controller_aborted-bg'
                            : 'completed-bg',
                    )}
                >
                    {this.props.data.jobStatus}
                </Tag>
                <Row>
                    <Col xs={3}>
                        <InfoPair
                            label="JOB TYPE"
                            content={capitalize(lowerCase(this.props.data.jobType))}
                        />
                    </Col>
                    <Col xs={3}>
                        {this.props.data.systmOneRef && (
                            <InfoPair label="REF" content={this.props.data.systmOneRef} />
                        )}
                    </Col>
                    <Col xs={3}>
                        {(this.props.data.firstName || this.props.data.lastName) && (
                            <InfoPair
                                label="PATIENT NAME"
                                content={formatFullName(
                                    this.props.data.firstName,
                                    this.props.data.lastName,
                                )}
                            />
                        )}
                    </Col>
                    <Col xs={3}>
                        {this.props.data.disposition && (
                            <InfoPair
                                label="DISPOSITION"
                                content={capitalize(this.props.data.disposition)}
                            />
                        )}
                        {this.props.data.blockSize && (
                            <InfoPair label="BLOCK SIZE" content={this.props.data.blockSize} />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={3}>
                        <InfoPair
                            label={
                                this.props.data.jobStatus === 'CONTROLLER_ABORTED'
                                    ? 'ABORTED AT'
                                    : 'COMPLETED AT'
                            }
                            content={formatDisplayDateTime(this.props.data.finishedDateTime)}
                        />
                    </Col>
                    <Col xs={3}>
                        <InfoPair label="HCP">
                            {this.props.data.hcpId ? (
                                <HcpNameLink
                                    hcpId={this.props.data.hcpId}
                                    hcpName={this.props.data.hcpName}
                                    closeModal
                                    {...this.props}
                                />
                            ) : (
                                '-'
                            )}
                        </InfoPair>
                    </Col>
                    {this.props.invoicingEnabled && this.props.data.hcpId && (
                        <>
                            <Col xs={3}>
                                <InfoPair label="INVOICEABLE">
                                    {this.props.data.invoiceable === true && (
                                        <span className="historic-jobs-list__ok">
                                            <Icon icon="tick-circle" /> Yes
                                        </span>
                                    )}
                                    {this.props.data.invoiceable === false && (
                                        <span className="historic-jobs-list__danger">
                                            <Icon icon="ban-circle" /> No
                                        </span>
                                    )}
                                    {this.props.data.invoiceable === undefined && (
                                        <span className="historic-jobs-list__warning">
                                            <Icon icon="warning-sign" /> Not set
                                        </span>
                                    )}
                                </InfoPair>
                            </Col>
                            <Col xs={3}>
                                <InfoPair label="PAYMENT TYPE">
                                    {this.props.data.paymentType ? (
                                        capitalize(lowerCase(this.props.data.paymentType))
                                    ) : (
                                        <span className="historic-jobs-list__warning">
                                            <Icon icon="warning-sign" /> Not set
                                        </span>
                                    )}
                                </InfoPair>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        );
    }
}

export default HistoricJobListItem;
