import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import { PagedStore } from './PagedStore';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_CHECKLISTS, UPDATE_CHECKLISTS } from '../graphql/queries/inductionTasks';
import AppToaster from '../components/modules/helpers/Toaster';

export interface InductionTasksInterface {
    id: string;
    name: string;
    deletedBy: string;
    orderNumber: number;
    isDeleted: boolean;
}

class InductionTasksStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            inductionTasks: observable,
            pageInfo: observable,
            loaded: observable,
            gridInductionTasks: computed,
            getInductionTasks: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    inductionTasks: InductionTasksInterface[] = [];
    fetchInterval: NodeJS.Timeout | null = null;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    get gridInductionTasks() {
        return this.inductionTasks;
    }

    refresh = async () => {
        runInAction(this.reset);
        await this.getInductionTasks();
    };

    getInductionTasks = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }

        const {
            data: { getInductionChecklists: data },
        } = await this.apolloClient.query({
            query: GET_CHECKLISTS,
        });

        runInAction(() => {
            this.inductionTasks = data.edges.map((x: any) => x.node);
            this.loaded = true;
        });
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getInductionTasks();
    };

    normalizeInductionTasks = (tasks: any) => {
        return tasks.map((task: InductionTasksInterface) => ({
            ...(task.id && { id: task.id }),
            name: task.name,
            orderNumber: task.orderNumber,
            isDeleted: task.isDeleted,
        }));
    };

    async updateInductionTasks(updateForCurrentStaff: any, tasks: any) {
        const items = this.normalizeInductionTasks(tasks).map(({ newId, ...rest }: any) => rest);

        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_CHECKLISTS,
                variables: {
                    data: {
                        updateForCurrentStaff,
                        items,
                    },
                },
            });
            AppToaster.show({
                message: 'Induction task(s) updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating induction task(s). Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    startTimer() {
        if (!this.fetchInterval) {
            this.getInductionTasks();
            this.fetchInterval = setInterval(() => this.getInductionTasks(), 10000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }
}

export default InductionTasksStore;
