import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Button, FormGroup, InputGroup, Alert, Checkbox } from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { validationSchema } from './validation';
import Error from '../../forms/Error';
import FileInput from '../../../common/formik/FileInput';
import {
    momentFormatter,
    formatDisplayDateTime,
    formatFileSize,
    daysDifferenceWithToday,
} from '../../helpers/formatData';
import { dateFormat } from '../../../../constants/patientsConst';

import type { Document } from '../../../../stores/DocumentStore';
import Select from 'react-select';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { compareByLabels } from '../../helpers/sortFunctions';

const DOCUMENT_STATUSES: { label: string; value: boolean }[] = [
    { label: 'Active', value: false },
    { label: 'Archived', value: true },
];

interface RouterProps {
    employeeId?: string;
    personId?: string;
}

interface DocumentDetailsProps extends Partial<RouteComponentProps<RouterProps>> {
    actionType: string;
    RootStore?: any;
    documentId: string | null;
    handleCancel: () => void;
    handleDownload: (key: string) => Promise<void>;
    updateEmployeeDataAfterChange: () => Promise<void>;
    updatePersonDataAfterChange: () => Promise<void>;
    categories: any;
    categoriesDictionary: any;
    disableEditing: boolean;
}

interface DocumentDetailsState {
    isEdit: boolean;
    isLoaded: boolean;
    initialData: any;
    isExpiryDateInPast: boolean;
    showAddNewConfirmation: boolean;
    showAlert: boolean;
    message: string;
}

const DocumentDetails = inject('RootStore')(
    observer(
        class DocumentDetails extends Component<DocumentDetailsProps, DocumentDetailsState> {
            state = {
                isEdit: false,
                isLoaded: false,
                isExpiryDateInPast: false,
                showAddNewConfirmation: false,
                message: '',
                showAlert: false,
                initialData: {
                    expiresAt: null,
                    id: '',
                    description: '',
                    expiresAtDate: null,
                    createdAt: null,
                    updatedAt: null,
                    key: '',
                    fileName: '',
                    mimeType: '',
                    sizeInBytes: 0,
                    createdBy: '',
                    verify: false,
                    status: 'active',
                    category: '',
                    verifiedBy: '',
                    categoryName: '',
                    categoryId: '',
                    createdPerson: null,
                    verifiedPerson: null,
                    isArchived: false,
                    isVerified: false,
                },
            };

            componentDidMount() {
                const {
                    RootStore: {
                        documentStore: { gridDocuments },
                    },
                    actionType,
                    documentId,
                } = this.props;

                if (actionType === 'create') {
                    this.setState({ isEdit: true });
                }

                if (actionType === 'edit') {
                    const document = gridDocuments.find((document: Document) => {
                        return document?.id === documentId;
                    });

                    if (document) {
                        const {
                            id,
                            description,
                            expiresAt,
                            expiresAtDate,
                            createdAt,
                            updatedAt,
                            createdBy,
                            fileName,
                            key,
                            mimeType,
                            sizeInBytes,
                            verify,
                            status,
                            category,
                            verifiedBy,
                            categoryName,
                            categoryId,
                            createdPerson,
                            verifiedPerson,
                            isArchived,
                            isVerified,
                        } = document;

                        this.setState({
                            initialData: {
                                id,
                                description,
                                expiresAt,
                                expiresAtDate,
                                createdAt,
                                updatedAt,
                                fileName,
                                key,
                                mimeType,
                                sizeInBytes,
                                createdBy,
                                verifiedBy,
                                verify,
                                status,
                                category,
                                categoryName,
                                categoryId,
                                isArchived,
                                isVerified,
                                createdPerson,
                                verifiedPerson,
                            },
                            isLoaded: true,
                        });
                    }
                }
            }

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'edit') {
                    return this._toggleIsEdit();
                } else {
                    this.props.handleCancel();
                }
            };

            _handleDelete = async () => {
                const err = await this.props.RootStore.documentStore.deleteDocuments([
                    this.state.initialData.id,
                ]);
                if (!err) {
                    this.props.handleCancel();
                }
            };

            _handleExpiryDateChange = (dateString: string | null) => {
                const date = new Date(dateString || '');
                const expiryDate = moment(date);
                const diffWithToday = daysDifferenceWithToday(expiryDate);
                const isDateInThePast = diffWithToday < 1;
                let message = '';
                if (diffWithToday === 0) {
                    message = 'today';
                }
                if (diffWithToday < 0) {
                    message = 'in the past';
                }
                if (
                    this.state.isExpiryDateInPast !== isDateInThePast ||
                    this.state.message !== message
                ) {
                    this.setState({ isExpiryDateInPast: isDateInThePast, message: message });
                }
            };

            _handleCancelConfirmModal = () => this.setState({ showAddNewConfirmation: false });

            _handleSaveClick = (callback: () => void) => {
                if (this.state.isExpiryDateInPast) {
                    this.setState({ showAddNewConfirmation: true });
                } else {
                    callback?.();
                }
            };

            _handleAlertClick = () => {
                this.setState((prevState: DocumentDetailsState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            render() {
                const {
                    RootStore: {
                        documentStore,
                        configStore: { org },
                    },
                    match,
                    actionType,
                    updateEmployeeDataAfterChange,
                    updatePersonDataAfterChange,
                    handleCancel,
                    handleDownload,
                    categories,
                    categoriesDictionary,
                } = this.props;

                const {
                    isLoaded,
                    isEdit,
                    initialData: {
                        description,
                        expiresAt,
                        createdAt,
                        updatedAt,
                        fileName,
                        mimeType,
                        sizeInBytes,
                        key,
                        isArchived,
                    },
                } = this.state;

                if (actionType === 'edit' && !isLoaded) {
                    return null;
                }

                return (
                    <Formik
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            const { employeeId } = match?.params || {};
                            const { personId } = match?.params || {};

                            const {
                                id,
                                description,
                                expiresAtDate,
                                createdAt,
                                updatedAt,
                                fileName,
                                key,
                                mimeType,
                                sizeInBytes,
                                verify,
                                status,
                                category,
                                categoryName,
                                categoryId,
                                isVerified,
                                createdPerson,
                                verifiedPerson,
                                isArchived,
                            } = values;

                            const normalisedValues = {
                                id,
                                employeeId,
                                description,
                                expiresAtDate,
                                createdAt,
                                updatedAt,
                                verify,
                                status,
                                category,
                                personId,
                                categoryName,
                                categoryId,
                                isVerified,
                                createdPerson,
                                verifiedPerson,
                                fileName,
                                key,
                                mimeType,
                                sizeInBytes,
                                isArchived,
                            };

                            if (actionType === 'create') {
                                await documentStore.createDocument(normalisedValues);
                                employeeId && (await updateEmployeeDataAfterChange());
                                personId && (await updatePersonDataAfterChange());
                                handleCancel();
                            }

                            if (actionType === 'edit') {
                                const document = await documentStore.updateDocument(
                                    normalisedValues,
                                );

                                employeeId && (await updateEmployeeDataAfterChange());
                                personId && (await updatePersonDataAfterChange());

                                const {
                                    id,
                                    description,
                                    expiresAt,
                                    expiresAtDate,
                                    createdAt,
                                    createdBy,
                                    verifiedBy,
                                    updatedAt,
                                    verify,
                                    status,
                                    category,
                                    isArchived,
                                    categoryId,
                                } = document;

                                this.setState({
                                    initialData: {
                                        id,
                                        description,
                                        expiresAt,
                                        createdAt,
                                        createdBy,
                                        verifiedBy,
                                        updatedAt,
                                        expiresAtDate,
                                        fileName,
                                        key,
                                        mimeType,
                                        sizeInBytes,
                                        verify,
                                        status,
                                        category,
                                        categoryId,
                                        isArchived,
                                    },
                                    isEdit: false,
                                });
                            }

                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                            handleSubmit,
                        }) => {
                            return (
                                <Form className="modal__form">
                                    <h3 className="h3 modal__sub-heading">
                                        {actionType === 'create' ? 'Add document' : fileName}
                                        <Button
                                            icon="cross"
                                            minimal
                                            className="modal__sub-heading-btn"
                                            onClick={handleCancel}
                                        />
                                    </h3>
                                    {actionType === 'create' && <FileInput org={org} />}
                                    <dl className="info">
                                        <dt className="info__title">Description</dt>
                                        <dd className="info__definition">
                                            {isEdit ? (
                                                <FormGroup labelFor="description">
                                                    <InputGroup
                                                        id="description"
                                                        name="description"
                                                        onChange={handleChange}
                                                        value={values.description}
                                                        large
                                                    />
                                                    {touched.description && (
                                                        <Error errors={[errors.description]} />
                                                    )}
                                                </FormGroup>
                                            ) : (
                                                description
                                            )}
                                        </dd>
                                        <dt className="info__title">Category *</dt>
                                        <dd className="info__definition">
                                            {isEdit ? (
                                                <FormGroup labelFor="categoryId">
                                                    <Select
                                                        options={categories.sort(compareByLabels)}
                                                        defaultValue={{
                                                            label:
                                                                categoriesDictionary[
                                                                    values.categoryId
                                                                ],
                                                            value: values.categoryId,
                                                        }}
                                                        onChange={(e) =>
                                                            setFieldValue('categoryId', e!.value)
                                                        }
                                                    />
                                                    {touched.category && (
                                                        <Error errors={[errors.categoryId]} />
                                                    )}
                                                </FormGroup>
                                            ) : (
                                                (values as any).categoryName
                                            )}
                                        </dd>
                                        <dt className="info__title">Status</dt>
                                        <dd className="info__definition">
                                            {isEdit ? (
                                                <FormGroup labelFor="isArchived">
                                                    <Select
                                                        options={DOCUMENT_STATUSES as any}
                                                        defaultValue={{
                                                            label: isArchived
                                                                ? 'Archived'
                                                                : 'Active',
                                                            value: values.isArchived,
                                                        }}
                                                        onChange={(e) =>
                                                            setFieldValue('isArchived', e!.value)
                                                        }
                                                    />
                                                </FormGroup>
                                            ) : isArchived ? (
                                                'Archived'
                                            ) : (
                                                'Active'
                                            )}
                                        </dd>
                                        <dt className="info__title">Verified</dt>
                                        <dd className="info__definition">
                                            <Checkbox
                                                id="isVerified"
                                                checked={values.isVerified}
                                                large
                                                disabled={!isEdit}
                                                onChange={handleChange}
                                            />
                                        </dd>
                                        <dt className="info__title">Expires</dt>
                                        <dd className="info__definition">
                                            {isEdit ? (
                                                <FormGroup labelFor="expiresAt">
                                                    <DateInput3
                                                        showTimezoneSelect={false}
                                                        maxDate={moment().add(10, 'years').toDate()}
                                                        minDate={moment()
                                                            .subtract(10, 'years')
                                                            .toDate()}
                                                        {...momentFormatter(dateFormat)}
                                                        onChange={(value) => {
                                                            this._handleExpiryDateChange(value);
                                                            setFieldValue('expiresAtDate', value);
                                                        }}
                                                        value={values.expiresAtDate}
                                                    />
                                                    {touched.expiresAtDate && (
                                                        <Error errors={[errors.expiresAtDate]} />
                                                    )}
                                                </FormGroup>
                                            ) : (
                                                expiresAt
                                            )}
                                        </dd>
                                        {actionType !== 'create' && (
                                            <>
                                                <dt className="info__title">File size</dt>
                                                <dd className="info__definition">
                                                    {formatFileSize(sizeInBytes)}
                                                </dd>
                                                <dt className="info__title">File type</dt>
                                                <dd className="info__definition">{mimeType}</dd>
                                                <dt className="info__title">Created by</dt>
                                                <dd className="info__definition">
                                                    {values.createdPerson &&
                                                        `${
                                                            (values.createdPerson as any).firstName
                                                        } ${
                                                            (values.createdPerson as any).lastName
                                                        }`}
                                                </dd>
                                                <dt className="info__title">Created on</dt>
                                                <dd className="info__definition">
                                                    {formatDisplayDateTime(createdAt)}
                                                </dd>
                                                <dt className="info__title">Last updated on</dt>
                                                <dd className="info__definition">
                                                    {formatDisplayDateTime(updatedAt)}
                                                </dd>
                                                <dt className="info__title">Verified by</dt>
                                                <dd className="info__definition">
                                                    {values.verifiedPerson &&
                                                        `${
                                                            (values.verifiedPerson as any).firstName
                                                        } ${
                                                            (values.verifiedPerson as any).lastName
                                                        }`}
                                                </dd>
                                            </>
                                        )}
                                    </dl>
                                    <footer className="modal__footer">
                                        <Alert
                                            isOpen={this.state.showAddNewConfirmation}
                                            onConfirm={() => {
                                                handleSubmit();
                                                this.setState({ showAddNewConfirmation: false });
                                            }}
                                            onCancel={this._handleCancelConfirmModal}
                                            cancelButtonText="Cancel"
                                            confirmButtonText="Confirm"
                                            icon="warning-sign"
                                            intent="warning"
                                        >
                                            <p>
                                                {`Please confirm you are aware that the expiry date is ${this.state.message}.`}
                                            </p>
                                        </Alert>
                                        <ConfirmationAlert
                                            confirmButtonText="Delete"
                                            onConfirm={this._handleDelete}
                                            onCancel={this._handleAlertClick}
                                            isOpen={this.state.showAlert}
                                            title="Confirm deletion!"
                                            description="Are you sure you want to delete this document? You won't be
                                        able to recover it."
                                        />
                                        {actionType === 'edit' && !isEdit && (
                                            <>
                                                {!this.props.match?.params.employeeId &&
                                                    !this.props.disableEditing && (
                                                        <Button
                                                            type="button"
                                                            large
                                                            intent="danger"
                                                            onClick={this._handleAlertClick}
                                                            icon="trash"
                                                            disabled={isSubmitting}
                                                            outlined
                                                        >
                                                            Delete
                                                        </Button>
                                                    )}
                                                <Button
                                                    large
                                                    intent="primary"
                                                    onClick={() => handleDownload(key)}
                                                    icon="cloud-download"
                                                    disabled={isSubmitting}
                                                    outlined
                                                >
                                                    Download
                                                </Button>
                                            </>
                                        )}
                                        {isEdit && (
                                            <>
                                                <Button
                                                    large
                                                    onClick={this._handleOnCancel}
                                                    icon="cross"
                                                    outlined
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    intent="success"
                                                    large
                                                    icon="tick"
                                                    type="button"
                                                    loading={isSubmitting}
                                                    outlined
                                                    onClick={() =>
                                                        this._handleSaveClick(handleSubmit)
                                                    }
                                                >
                                                    Save
                                                </Button>
                                            </>
                                        )}
                                        {!isEdit &&
                                            !this.props.match?.params.employeeId &&
                                            !this.props.disableEditing && (
                                                <Button
                                                    large
                                                    onClick={this._toggleIsEdit}
                                                    icon="edit"
                                                    intent="primary"
                                                    disabled={isSubmitting}
                                                    outlined
                                                >
                                                    Edit details
                                                </Button>
                                            )}
                                    </footer>
                                </Form>
                            );
                        }}
                    </Formik>
                );
            }
        },
    ),
);

export default DocumentDetails;
