import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import Header from './Header';
import HRRota from './HRRota';
import PageNotFound from '../../pages/PageNotFound';
import AdminReporting from '../../pages/admin/AdminReporting';
import AdminManageUsers from '../../pages/admin/AdminManageUsers';
import AdminManageInvites from '../../pages/admin/AdminManageInvites';
import Onboarding from '../../pages/admin/Onboarding';
import AddUser from '../../modules/users/add-user/AddUser';
import Loader from '../../modules/helpers/Loader';

export const routes = [
    { path: '/hr', component: HRRota },
    { path: '/rota', component: HRRota },
    { path: '/reporting', component: AdminReporting },
    { path: '/manage-users/:userId?', component: AdminManageUsers, exact: true },
    { path: '/manage-applications/:inviteId?', component: AdminManageInvites, exact: true },
    { path: '/organisation-details', component: Onboarding, exact: true },
    { path: '/add-user', component: AddUser, exact: true },
];

const Router = ({ homePage }) => {
    return (
        <div className="admin">
            <Route path="/:section" component={Header} />
            <Switch>
                <Redirect exact from="/" to={homePage} />
                {routes.map(({ path, component, exact }) => (
                    <Route key={path} path={path} component={component} exact={exact} />
                ))}
                <Route component={PageNotFound} />
            </Switch>
        </div>
    );
};

const AdminMain = inject('RootStore')(
    observer(
        class AdminMain extends Component {
            render() {
                const {
                    configStore: { loaded, isFeatureEnabled },
                    userStore: { isComplianceOfficer },
                } = this.props.RootStore;

                if (!loaded) {
                    return (
                        <div>
                            <Loader />
                        </div>
                    );
                }

                let homePage;

                if (isComplianceOfficer === true) {
                    homePage = '/manage-applications';
                } else if (isFeatureEnabled('onDemandJobs') || isFeatureEnabled('scheduledJobs')) {
                    homePage = '/reporting';
                } else if (isFeatureEnabled('hr')) {
                    homePage = '/hr';
                } else if (isFeatureEnabled('rota')) {
                    homePage = '/rota';
                } else {
                    homePage = '/manage-users';
                }

                return <Router props={this.props} homePage={homePage} />;
            }
        },
    ),
);

export default AdminMain;
