import { observable, action, makeObservable } from 'mobx';
import { fetchInvites, updateInvite } from '../api/invitesApi';

class InvitesStore {
    constructor(rootStore) {
        makeObservable(this, {
            loaded: observable,
            invites: observable,
            getInvites: action,
        });

        this.rootStore = rootStore;
    }

    loaded = false;
    invites = [];

    getInvites = async () => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            this.invites = await fetchInvites(userSession.tokens.id);
        } catch (err) {
            console.error('Unable to fetch Invites for organisation', err);
        }

        this.loaded = true;
    };

    updateInvite = async (inviteId, body) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();

            await updateInvite(userSession.tokens.id, inviteId, body);

            this.loaded = false;
            await this.getInvites();
            return { success: true };
        } catch (err) {
            console.error('Unable to fetch Invites for organisation', err);
            return { success: false };
        }
    };
}

export default InvitesStore;
