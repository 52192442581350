import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';
import Error from '../../forms/Error';

interface CostCentresSummaryProps {
    editOrCreateMode: boolean;
    values: FormikValues;
    handleChange: any;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    costCentre: any;
    costCentres: any[];
    costCentreNames: any[];
    setFieldValue: (key: string, value: any) => void;
}

const CostCentresSummary = ({
    costCentre,
    editOrCreateMode,
    values,
    handleChange,
    errors,
    touched,
    costCentres,
    costCentreNames,
    setFieldValue,
}: CostCentresSummaryProps) => {
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'costCentres'}
                componentPath={'/hr/cost-centres/'}
                items={costCentreNames}
                editOrCreateMode={editOrCreateMode}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                className={'costCentres_suggest'}
            />
            <dl className="info">
                <dt className="info__title">Code *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="code">
                            <InputGroup
                                id="code"
                                name="code"
                                onChange={handleChange}
                                value={values.code}
                                large
                                type="number"
                            />
                            {errors.codeExistsCostCentreId && (
                                <div
                                    style={{ margin: '10px 0' }}
                                    className="form-error-message no-padding"
                                    hidden={!errors.codeExistsCostCentreId}
                                >
                                    The{' '}
                                    <a
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        href={`/hr/cost-centres/${errors.codeExistsCostCentreId}`}
                                    >
                                        {values.code}
                                    </a>{' '}
                                    already exists in the system. Please enter a unique code to save
                                </div>
                            )}
                            {touched.code && <Error errors={[errors.code]} />}
                        </FormGroup>
                    ) : (
                        costCentre.code
                    )}
                </dd>
            </dl>
        </>
    );
};

export default CostCentresSummary;
