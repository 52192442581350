/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Classes, Dialog, FormGroup, Intent, RadioGroup, Radio } from '@blueprintjs/core';
import Select from 'react-select';

const weeks = [
    { value: 0, label: 'First' },
    { value: 1, label: 'Second' },
    { value: 2, label: 'Third' },
    { value: 3, label: 'Fourth' },
    { value: 4, label: 'Fifth' },
    { value: 5, label: 'Sixth' },
    { value: 6, label: 'Seventh' },
    { value: 7, label: 'Eighth' },
];

interface ExtendDialogProps {
    onClose: () => void;
    onSubmit: (option: string, offset: number) => void;
    isOpen: boolean;
    title?: string | undefined;
    weeksCount: number;
}

const ExtendRotaDialog = (props: ExtendDialogProps) => {
    const { isOpen, onClose, onSubmit, weeksCount } = props;
    const [option, setOption] = React.useState<string>('0');
    const [weekItems, setWeekItems] = React.useState(weeks);
    const [offset, setOffset] = React.useState(weeks[0]);

    React.useEffect(() => {
        if (isOpen) {
            setOption('0');
            const fWeekItems = weeks.filter((r: any) => r.value < weeksCount);
            setWeekItems(fWeekItems);
        }
    }, [isOpen]);

    return (
        <Dialog
            onClose={onClose}
            title="Extend Rota"
            autoFocus
            canOutsideClickClose
            usePortal
            isOpen={isOpen}
            className="rota-builder__shift-dialog"
        >
            <div className={Classes.DIALOG_BODY}>
                <div>
                    <RadioGroup
                        label="The shift pattern applied to this rota has been changed. You cannot reapply the
                        current shift pattern to extended dates. How do you want to extend the date
                        range?"
                        onChange={(evt: any) => {
                            setOption(evt.target.value);
                        }}
                        selectedValue={option}
                    >
                        <Radio
                            label="Extend without reapplying shift pattern. Empty days would be added"
                            value="0"
                        />
                        <Radio label="Extend with applying updated shift pattern" value="1" />
                    </RadioGroup>
                    <FormGroup label="Select week to start">
                        <Select
                            options={weekItems}
                            isDisabled={option !== '1'}
                            onChange={(e: any) => {
                                setOffset(e);
                            }}
                            value={offset}
                            id="weeks"
                        />
                    </FormGroup>
                </div>
                <div className={`${Classes.DIALOG_FOOTER}`}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            className="dialog-select__button"
                            intent={Intent.NONE}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="dialog-select__button"
                            type="submit"
                            intent={Intent.SUCCESS}
                            onClick={() => {
                                onSubmit(option, offset.value);
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ExtendRotaDialog;
