import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

const ExitConfirmationDialog = (props: any) => {
    const {
        bodyText = 'Do you want to save the changes made to this record?',
        onCancel,
        onSubmit,
        onSave,
        onExit,
        submitText = 'Save',
        secondaryActionText = 'No, save and exit',
        tertiaryActionText = 'No, exit without saving',
        isOpen,
        hasSecondaryAction = false,
        hasTertiaryAction = false,
    } = props;

    return (
        <Dialog icon="warning-sign" onClose={onCancel} title="Confirmation" isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <p>{bodyText}</p>
            </div>
            <div className={`${Classes.DIALOG_FOOTER} exit-dialog-footer`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button type="submit" intent={Intent.SUCCESS} onClick={onSubmit}>
                        {submitText}
                    </Button>
                    {hasSecondaryAction && (
                        <Button type="submit" intent={Intent.WARNING} onClick={onSave}>
                            {secondaryActionText}
                        </Button>
                    )}
                    {hasTertiaryAction && (
                        <Button type="submit" intent={Intent.DANGER} onClick={onExit}>
                            {tertiaryActionText}
                        </Button>
                    )}
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ExitConfirmationDialog;
