import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import useStores from '../../../../../../hook/useStores';
import { SORT_COLUMN } from '../../../../../../stores/v2/PatientListStore';
import { PatientListHeader } from './PatientListHeader';
import AppToaster from '../../../../../modules/helpers/Toaster';
import { Button } from '../../../../../v2/components';
import PatientListRow from './PatientListRow';
import { GetPatientsDocument, PatientFilter } from '../../../../../../__generated__/v2';

const PATIENT_LIST_PAGE_SIZE = 51;

const PatientList: FC = observer(() => {
    const {
        RootStore: {
            patientListStoreV2: {
                listPatients,
                setPatients,
                sortsGql,
                filter,
                clearPatients,
                nameOrNhsNumber,
            },
        },
    } = useStores() as { RootStore: any };

    const [showLoadMore, setShowLoadMore] = useState(false);

    const [_loadPatients, { data, loading }] = useLazyQuery(GetPatientsDocument, {
        fetchPolicy: 'no-cache',
        onError: ({ graphQLErrors }) => {
            let message = 'Please check your network connection!';
            if (graphQLErrors && graphQLErrors.length > 0) {
                message = graphQLErrors[0].message;
                console.log('error:', message);
            }
            AppToaster.show({
                message,
                intent: 'danger',
            });
        },
    });

    const { getPatients } = data || {};

    const loadPatients = useCallback(() => {
        let gqlFilter: PatientFilter = {};

        if (!filter.includeDischarged) {
            gqlFilter.admissions = { some: { isDeleted: false } };
        }

        if (filter.nameOrNhsNumber.length > 0) {
            gqlFilter.OR = [];

            for (const item of filter.nameOrNhsNumber) {
                gqlFilter.OR.push(
                    { nhsNumber: { contains: item } },
                    { person: { firstName: { contains: item } } },
                    { person: { lastName: { contains: item } } },
                    { person: { middleName: { contains: item } } },
                );
            }
        }

        const after = listPatients.length > 0 ? listPatients[listPatients.length - 1].id : null;

        _loadPatients({
            variables: {
                filter: gqlFilter,
                first: PATIENT_LIST_PAGE_SIZE,
                after,
                orderBy: sortsGql,
            },
        });
    }, [_loadPatients, filter, listPatients, sortsGql]);

    const loadMore = useCallback(() => {
        if (loading) {
            return;
        }

        loadPatients();
    }, [loading, loadPatients]);

    useEffect(
        () => {
            if (!getPatients) {
                return;
            }

            const {
                edges,
                pageInfo: { hasNextPage },
            } = getPatients;

            setPatients(edges?.map((edge) => edge.node) || []);
            setShowLoadMore(hasNextPage);
        },
        // Disable to prevent infinte loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getPatients],
    );

    useEffect(
        () => {
            clearPatients();
            loadPatients();
        },
        // Disable reloading when callback updates
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter, sortsGql, nameOrNhsNumber],
    );

    return (
        <div className="patient-list">
            <table className="patient-list__table">
                <thead>
                    <tr>
                        <PatientListHeader column={SORT_COLUMN.NHS_NUMBER}>
                            NHS number
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.NAME}>
                            Patient name
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.DOB}>
                            Date of birth (Age)
                        </PatientListHeader>
                        <PatientListHeader column={SORT_COLUMN.GENDER}>Gender</PatientListHeader>
                        <PatientListHeader>Postcode</PatientListHeader>
                        <PatientListHeader>Status</PatientListHeader>
                        <PatientListHeader>Last completed visit</PatientListHeader>
                        <PatientListHeader>Upcoming visit</PatientListHeader>
                        {/* Intentionally blank header for quick actions */}
                        <PatientListHeader />
                    </tr>
                </thead>
                {listPatients.map((p: any) => (
                    <PatientListRow key={`patient-list-row++${p.nhsNumber}`} patient={p} />
                ))}
                <tbody>
                    {showLoadMore && (
                        <tr className="patient-list__no-patients-row">
                            <td
                                // @ts-ignore Don't know why; colspan supports percentages
                                colSpan="100%"
                            >
                                <Button name="Load More" clickEvent={loadMore} disabled={loading} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
});

export default PatientList;
