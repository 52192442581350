import { gql } from '@apollo/client';

export const GET_ROLES_LIST = gql`
    query GetEmployeeRoleTypes(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: EmployeeRoleTypeFilter
    ) {
        getEmployeeRoleTypes(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    updatedAt
                    createdBy
                    updatedBy
                    createdPerson {
                        id
                        firstName
                        lastName
                    }
                    updatedPerson {
                        id
                        firstName
                        lastName
                    }
                    isArchived
                    isDeleted
                    documentCategories {
                        documentCategory {
                            name
                            id
                        }
                    }
                    roleFunctions {
                        isInUse
                        function {
                            isArchived
                            name
                            id
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_ROLES_BY_DEPARTMENT_AND_FUNCTIONS = gql`
    query GetEmployeeRoleTypesByDepartmentAndFunctions(
        $departmentId: String!
        $functionIds: [String!]
    ) {
        getEmployeeRoleTypesByDepartmentAndFunctions(
            departmentId: $departmentId
            functionIds: $functionIds
        ) {
            id
            name
        }
    }
`;

export const CREATE_ROLE = gql`
    mutation CreateEmployeeRoleType($data: EmployeeRoleTypeInput!) {
        createEmployeeRoleType(data: $data) {
            id
        }
    }
`;

export const UPDATE_ROLE = gql`
    mutation UpdateEmployeeRoleType($id: String!, $data: EmployeeRoleTypeInput!) {
        updateEmployeeRoleType(id: $id, data: $data) {
            id
            name
            documentCategories {
                documentCategory {
                    name
                }
            }
            roleFunctions {
                function {
                    name
                }
            }
        }
    }
`;

export const DELETE_ROLE = gql`
    mutation deleteEmployeeRoleType($id: String!) {
        deleteEmployeeRoleType(id: $id) {
            id
        }
    }
`;

export const GET_ROLE_BY_ID = gql`
    query GetEmployeeRoleTypeById($id: String!) {
        getEmployeeRoleTypeById(id: $id) {
            id
            name
            documentCategories {
                documentCategory {
                    name
                    id
                }
            }
            roleFunctions {
                isInUse
                function {
                    name
                    id
                    isArchived
                }
            }
            createdAt
            updatedAt
            createdBy
            updatedBy
            isArchived
            isDeleted
        }
    }
`;

export const BULK_DELETE_ROLE = gql`
    mutation BulkDeleteEmployeeRoleType($ids: [String!]!) {
        bulkDeleteEmployeeRoleType(ids: $ids) {
            count
        }
    }
`;

export const ARCHIVE_ROLE = gql`
    mutation ArchiveEmployeeRoleType($id: String!) {
        archiveEmployeeRoleType(id: $id) {
            id
        }
    }
`;

export const VALIDATE_ARCHIVE_ROLES = gql`
    query IsEmployeeRoleTypeArchivable($ids: [String!]!) {
        isEmployeeRoleTypeArchivable(ids: $ids) {
            id
            name
            isArchivable
        }
    }
`;

export const BULK_ARCHIVE_ROLE = gql`
    mutation BulkArchiveEmployeeRoleType($ids: [String!]!) {
        bulkArchiveEmployeeRoleType(ids: $ids) {
            count
        }
    }
`;

export const GET_ROLE_BY_NAME = gql`
    query GetRoleByName($name: String!) {
        getRoleByName(name: $name) {
            id
            name
        }
    }
`;
