import UserStore from './UserStore';
import UsersStore from './UsersStore';
import JobsStore from './JobsStore';
import LovsStore from './LovsStore';
import ConfigStore from './ConfigStore';
import InvitesStore from './InvitesStore';
import OnboardingsStore from './OnboardingsStore';
import SchedulesStore from './SchedulesStore';
import StaffStore from './StaffStore';
import DictionaryStore from './DictionaryStore';
import PeopleStore from './PeopleStore';
import LocationsStore from './LocationsStore';
import InvoicesStore from './InvoicesStore';
import RotaBuilderStore from './RotaBuilderStore';
import GroupsStore from './GroupsStore';
import SpecialitiesStore from './SpecialitiesStore';
import DocumentStore from './DocumentStore';
import PayRatesStore from './PayRatesStore';
import ShiftPatternsStore from './ShiftPatternsStore';
import RoleStore from './RolesStore';
import FunctionsStore from './FunctionsStore';
import AlertsStore from './AlertStore';
import DepartmentsStore from './DepartmentsStore';
import AgenciesStore from './AgenciesStore';
import InductionTasksStore from './InductionTasksStore';
import HolidaysStore from './HolidaysStore';
import UCRStore from './UCRStore';
import FundingPoolStore from './FundingPoolStore';
import RegistrationStore from './RegistrationStore';
import ProfessionalBodyStore from './ProfessionalBodyStore';
import FitToPracticeStore from './FitToPracticeStore';
import CostCentresStore from './CostCentresStore';
import DepartmentAssignmentsStore from './DepartmentAssignmentsStore';
import AbsencesStore from './AbsencesStore';
import PatientListStore from './PatientListStore';
import MapStore from './MapStore';
import PatientListStoreV2 from './v2/PatientListStore';

class RootStore {
    constructor() {
        this.userStore = new UserStore(this);
        this.jobsStore = new JobsStore(this);
        this.vaccinationsStore = new JobsStore(this, 'vaccination');
        this.routesStore = new JobsStore(this, 'route');
        this.lovsStore = new LovsStore(this);
        this.usersStore = new UsersStore(this);
        this.configStore = new ConfigStore(this);
        this.invitesStore = new InvitesStore(this);
        this.onboardingsStore = new OnboardingsStore(this);
        this.schedulesStore = new SchedulesStore(this);
        this.invoicesStore = new InvoicesStore(this);
        this.staffStore = new StaffStore(this);
        this.dictionaryStore = new DictionaryStore(this);
        this.peopleStore = new PeopleStore(this);
        this.locationsStore = new LocationsStore(this);
        this.shiftPatternsStore = new ShiftPatternsStore(this);
        this.groupsStore = new GroupsStore(this);
        this.specialitiesStore = new SpecialitiesStore(this);
        this.documentStore = new DocumentStore(this);
        this.payRatesStore = new PayRatesStore(this);
        this.rolesStore = new RoleStore(this);
        this.functionsStore = new FunctionsStore(this);
        this.rotaBuilderStore = new RotaBuilderStore(this);
        this.shiftPatternsStore = new ShiftPatternsStore(this);
        this.alertsStore = new AlertsStore(this);
        this.departmentsStore = new DepartmentsStore(this);
        this.agenciesStore = new AgenciesStore(this);
        this.inductionTasksStore = new InductionTasksStore(this);
        this.holidaysStore = new HolidaysStore(this);
        this.ucrStore = new UCRStore(this);
        this.fundingPoolStore = new FundingPoolStore(this);
        this.registrationStore = new RegistrationStore(this);
        this.professionalBodyStore = new ProfessionalBodyStore(this);
        this.fitToPracticeStore = new FitToPracticeStore(this);
        this.costCentresStore = new CostCentresStore(this);
        this.departmentAssignmentsStore = new DepartmentAssignmentsStore(this);
        this.absencesStore = new AbsencesStore(this);
        this.patientListStore = new PatientListStore(this);
        this.mapStore = new MapStore(this);
        this.patientListStoreV2 = new PatientListStoreV2();
    }
}

export default RootStore;
