import { FC, useCallback, useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker, MarkerClusterer } from '@react-google-maps/api';
import { ClusterIconStyle } from '@react-google-maps/marker-clusterer';

import Loader from '../../../../modules/helpers/Loader';
import { JobStatus, Patient } from '@doc-abode/data-models';
import { Markers } from './Markers';

import moment from 'moment';
import { IHcp, IHcpExtended } from '../../../../../interfaces/ucr';
import { GOOGLE_API_LIBRARIES } from '../../../../../constants/googleAPIs';
import { useView } from '../../views/useView';
import { IHCPBreakdown, PatientWithAlert } from '../../../../../stores/MapStoreTypes';
import useStores from '../../../../../hook/useStores';
import { Button, ButtonElems } from '../../../../v2/components';
import { IconRefresh } from '../../../../../helpers';

const patientAlertColor = 'ff425e';
interface IProps {
    mapCentre: {
        lat?: number;
        lng?: number;
    };
    assignedJobs: PatientWithAlert[];
    unassignedJobs: PatientWithAlert[];
    selectedDate: Date;
    loadingJobs: boolean;
    users: IHcp[];
    processing: boolean;
    focusedHCPBreakdownOfJobs: Record<string, IHCPBreakdown[]>;
    boundedArea?: IHCPBreakdown;
    setBoundedArea: (breakdown?: IHCPBreakdown) => void;
    resetHCPBreakdown: () => void;
    apiKey: string;
}
interface LocationThreshold {
    value: number;
    color: {
        above: string;
        below: string;
    };
    gracePeriodMinutes: number;
}
interface PinInterface {
    id: string;
    lat?: number;
    long?: number;
    address?: string;
    pass?: boolean;
    dateValue?: Date;
    initals?: string;
    jobStatus?: JobStatus;
    doubleUp?: boolean;
    buddyJobStatus?: JobStatus;
    hcpId?: string;
    buddyId?: string;
    job: Patient;
    hasPatientAlert: boolean;
}

const getColour = (jobStatus?: JobStatus) => {
    let color = '';
    switch (jobStatus) {
        case JobStatus.ACCEPTED:
        case JobStatus.PENDING:
            color = '546175';
            break;
        case JobStatus.AVAILABLE:
            color = '9e9e9e';
            break;
        case JobStatus.CURRENT:
            color = '1e8bf2';
            break;
        case JobStatus.ARRIVED:
            color = 'e91e63';
            break;
        case JobStatus.HCP_ABORTED:
        case JobStatus.CONTROLLER_ABORTED:
            color = 'f44336';
            break;

        case JobStatus.COMPLETED:
            color = '4caf50';
            break;
        default:
            color = '546175';
    }
    return color;
};

const getIcon = (
    pinDetails: PinInterface,
    focusedHCPBreakdownOfJobs: Record<string, IHCPBreakdown[]>,
) => {
    const {
        jobStatus,
        buddyJobStatus,
        initals,
        doubleUp,
        hcpId,
        buddyId,
        hasPatientAlert,
    } = pinDetails;

    let fill = getColour(jobStatus);
    let fillTwo = doubleUp ? getColour(buddyJobStatus) : '';
    fill = hasPatientAlert ? patientAlertColor : fill;
    fillTwo = hasPatientAlert ? patientAlertColor : fillTwo;

    const names: string[] | undefined = initals?.split(' ');
    const nameOne: string = names ? names[0] || '' : '';
    const nameTwo: string = names ? names[1] || '' : '';

    const isSingleUpActive: boolean =
        jobStatus === JobStatus.ARRIVED || jobStatus === JobStatus.CURRENT;

    const isDoubleUpActive: boolean =
        jobStatus === JobStatus.ARRIVED ||
        jobStatus === JobStatus.CURRENT ||
        buddyJobStatus === JobStatus.CURRENT ||
        buddyJobStatus === JobStatus.ARRIVED;

    let icon = '';

    const foundHcp = focusedHCPBreakdownOfJobs[hcpId || '']?.find(
        (job) => job.jobId === pinDetails.id,
    );

    const foundBuddy = focusedHCPBreakdownOfJobs[buddyId || '']?.find(
        (job) => job.jobId === pinDetails.id,
    );

    if (doubleUp) {
        const pinData = {
            fill,
            fillTwo,
            label: nameOne,
            label2: nameTwo,
            foundBuddy: foundBuddy,
            foundHcp: foundHcp,
            buddyPosition: foundBuddy?.position,
            hcpPosition: foundHcp?.position,
        };

        const iconData = isDoubleUpActive
            ? btoa(Markers.doubleActive(pinData))
            : btoa(Markers.double(pinData));
        icon = `data:image/svg+xml;charset=UTF-8;base64, ${iconData}`;
    } else {
        const pinData = {
            fill,
            label: nameOne,
            foundHcp: foundHcp,
            hcpPosition: foundHcp?.position,
        };

        const iconData = isSingleUpActive
            ? btoa(Markers.singleActive(pinData))
            : btoa(Markers.single(pinData));
        icon = `data:image/svg+xml;charset=UTF-8;base64, ${iconData}`;
    }

    let image = {
        url: icon,
    };

    return image;
};

export const MapContainer: FC<IProps> = ({
    users,
    assignedJobs,
    loadingJobs,
    unassignedJobs,
    selectedDate,
    focusedHCPBreakdownOfJobs,
    boundedArea,
    setBoundedArea,
    resetHCPBreakdown,
    apiKey,
}) => {
    const { clearDeepLink, openDeepLink } = useView();
    const {
        RootStore: {
            mapStore: { hcps: mapHcps, hcpsBreakdownInfo, showUnassignedJobs },
            configStore: { mapZoomLevel, isFeatureEnabled, liveLocationThreshold },
        },
    } = useStores() as { RootStore: any };

    const pins: PinInterface[] = [];
    const intervalData = liveLocationThreshold as LocationThreshold;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: apiKey,
        libraries: GOOGLE_API_LIBRARIES,
    });
    const [loadedMap, setLoadedMap] = useState<any>({});
    const [currentDate, setCurrentDate] = useState<number>(0);

    let maps: any;
    let bounds: any;

    if (isLoaded) {
        maps = (window as any).google.maps;
    }

    const userInitials: Record<string, string> = {};

    users.forEach((user) => {
        userInitials[user.userId] = user.firstName.charAt(0) + '' + user.lastName.charAt(0);
    });

    const pushPinInformation = (model: PatientWithAlert, overwriteShowUnassigned = false) => {
        // Take into account filtered HCPs
        const hcpIds = mapHcps
            .filter(({ userId }: { userId: string }) => !!hcpsBreakdownInfo[userId])
            .map(({ userId }: { userId: string }) => userId);

        const job = model.job;

        const includeJob =
            (showUnassignedJobs && !overwriteShowUnassigned) ||
            (job.hcpId && hcpIds.includes(job.hcpId)) ||
            (job.buddyId && hcpIds.includes(job.buddyId));

        if (job.latitude && job.longitude && includeJob) {
            const pin: PinInterface = {
                id: job.id,
                job: job,
                hcpId: job.hcpId || undefined,
                buddyId: job.buddyId || undefined,
                hasPatientAlert: model.hasPatientAlert,
            };
            pin.jobStatus = job.jobStatus;
            pin.dateValue = selectedDate;
            const initials =
                job.staffRequired === 2
                    ? `${userInitials[job.hcpId!] || 'U'} ${userInitials[job.buddyId!] || 'U'}`
                    : `${userInitials[job.hcpId!] || 'U'}`;

            pin.initals = initials;
            pin.lat = job.latitude;
            pin.long = job.longitude;
            pin.doubleUp = job.staffRequired === 2 ? true : false;
            pin.buddyJobStatus = job.buddyJobStatus;

            if (bounds && pin.lat && pin.long) {
                bounds.extend({ lat: pin.lat, lng: pin.long });
            }
            pins.push(pin);

            return pin;
        }
    };

    if (maps) {
        bounds = new maps.LatLngBounds();
        assignedJobs.forEach((model) => {
            pushPinInformation(model, true);
        });

        unassignedJobs.forEach((model) => {
            pushPinInformation(model);
        });
    }

    useEffect(() => {
        if (
            maps &&
            loadedMap &&
            loadedMap.fitBounds &&
            pins.length > 0 &&
            !loadingJobs &&
            moment(selectedDate).valueOf() !== currentDate
        ) {
            loadedMap.fitBounds(bounds);
            setCurrentDate(moment(selectedDate).valueOf());
        }
        if (loadingJobs) {
            loadedMap.fitBounds && loadedMap.fitBounds(bounds);
        }
    }, [
        loadedMap,
        loadedMap?.fitBounds,
        pins.length,
        selectedDate,
        maps,
        bounds,
        currentDate,
        loadingJobs,
    ]);

    let fullyLoaded = true;

    const notLoaded = !isLoaded || !fullyLoaded;

    useEffect(() => {
        if (
            maps &&
            loadedMap &&
            !notLoaded &&
            loadedMap.fitBounds &&
            pins.length >= 1 &&
            moment(selectedDate).valueOf() !== currentDate
        ) {
            resetHCPBreakdown();
            loadedMap.fitBounds(bounds);
            setCurrentDate(moment(selectedDate).valueOf());
        }
    }, [
        notLoaded,
        loadedMap,
        pins.length,
        selectedDate,
        maps,
        bounds,
        currentDate,
        resetHCPBreakdown,
    ]);

    useEffect(() => {
        if (
            Object.keys(focusedHCPBreakdownOfJobs).length > 0 &&
            maps &&
            loadedMap &&
            loadedMap.fitBounds
        ) {
            const bounding = new maps.LatLngBounds();
            let bounded = false;
            Object.values(focusedHCPBreakdownOfJobs).forEach((breakdown) => {
                breakdown.forEach((jobList) => {
                    if (jobList.latitude && jobList.longitude) {
                        bounding.extend({ lat: jobList.latitude, lng: jobList.longitude });
                        bounded = true;
                    }
                });
            });

            if (bounded) {
                loadedMap.fitBounds(bounding);
            }
        }
    }, [focusedHCPBreakdownOfJobs, loadedMap, maps, maps?.LatLngBounds]);

    useEffect(() => {
        if (boundedArea && maps) {
            if (boundedArea.latitude && boundedArea.longitude) {
                loadedMap.setZoom(19);
                loadedMap.setCenter({
                    lat: boundedArea.latitude - 0.0002,
                    lng: boundedArea.longitude,
                });
            }
            if (boundedArea.jobId) {
                openDeepLink(boundedArea.jobId, !boundedArea.isBuddy ? 'user1' : 'user2');
            }

            setBoundedArea(undefined);
        }
    }, [
        boundedArea,
        maps,
        loadedMap,
        loadedMap?.setCenter,
        loadedMap?.setZoom,
        setBoundedArea,
        openDeepLink,
    ]);

    const onLoad = useCallback(function callback(map: any) {
        setLoadedMap(map);
    }, []);

    const generateImageOptions = () => {
        const icon = `data:image/svg+xml;charset=UTF-8;base64, ${btoa(
            Markers.cluster({
                fill: '546e7a',
                label: '',
            }),
        )}`;

        const style: ClusterIconStyle[] = [];

        for (let i = 0; i < 5; i++) {
            style.push({
                textColor: 'white',
                url: icon,
                height: 40,
                width: 32,
                anchorText: [-2, 0],
                backgroundPosition: '0 0',
            });
        }

        return style;
    };

    return (
        <div className="map-container">
            {notLoaded ? (
                <Loader fullscreen={false} />
            ) : (
                <GoogleMap onLoad={onLoad} id="scheduling-map" zoom={12}>
                    <MarkerClusterer
                        maxZoom={18}
                        averageCenter={false}
                        ignoreHidden={true}
                        minimumClusterSize={2}
                        gridSize={Object.keys(focusedHCPBreakdownOfJobs).length >= 1 ? 0 : 10}
                        styles={generateImageOptions()}
                    >
                        {(clusterer) => {
                            return (
                                <>
                                    {pins.map((pin) => {
                                        if (
                                            pin.lat &&
                                            pin.long &&
                                            moment(pin.dateValue).diff(selectedDate, 'days') === 0
                                        ) {
                                            return (
                                                <Marker
                                                    position={{ lat: pin.lat, lng: pin.long }}
                                                    clusterer={clusterer}
                                                    icon={getIcon(pin, focusedHCPBreakdownOfJobs)}
                                                    onClick={(e) => {
                                                        const lat = pin.lat || 0;
                                                        const long = pin.long || 0;
                                                        openDeepLink(pin.id, 'user1');
                                                        loadedMap.setZoom(19);
                                                        loadedMap.setCenter({
                                                            lat: lat - 0.0002,
                                                            lng: long,
                                                        });
                                                    }}
                                                    key={pin.id}
                                                />
                                            );
                                        } else {
                                            return <></>;
                                        }
                                    })}

                                    {isFeatureEnabled('hcpLiveLocation') &&
                                        moment(selectedDate).isSame(moment(), 'day') &&
                                        mapHcps
                                            .filter(
                                                ({
                                                    userId,
                                                    available,
                                                    lastKnownLocationTime,
                                                }: {
                                                    userId: string;
                                                    available: boolean;
                                                    lastKnownLocationTime?: string;
                                                }) =>
                                                    !!hcpsBreakdownInfo[userId] &&
                                                    available &&
                                                    moment(lastKnownLocationTime).isAfter(
                                                        moment(moment().startOf('day')).subtract(
                                                            intervalData.gracePeriodMinutes,
                                                            'minute',
                                                        ),
                                                    ),
                                            )
                                            .map((pin: IHcpExtended) => {
                                                const minutesPassed = moment().diff(
                                                    moment(pin.lastKnownLocationTime),
                                                    'minutes',
                                                );
                                                return pin.latitude && pin.longitude ? (
                                                    <Marker
                                                        position={{
                                                            lat: pin.latitude,
                                                            lng: pin.longitude,
                                                        }}
                                                        icon={{
                                                            url: `data:image/svg+xml;charset=UTF-8;base64, ${btoa(
                                                                Markers.singleHcp({
                                                                    fill:
                                                                        minutesPassed >
                                                                        intervalData.value
                                                                            ? intervalData.color
                                                                                  .above
                                                                            : intervalData.color
                                                                                  .below,
                                                                    label:
                                                                        pin.firstName
                                                                            .charAt(0)
                                                                            .toUpperCase() +
                                                                        pin.lastName
                                                                            .charAt(0)
                                                                            .toUpperCase(),
                                                                }),
                                                            )}`,
                                                        }}
                                                        clusterer={clusterer}
                                                        key={pin.userId}
                                                    />
                                                ) : null;
                                            })}
                                </>
                            );
                        }}
                    </MarkerClusterer>
                    <Button
                        name="Reset zoom"
                        Icon={IconRefresh}
                        elem={ButtonElems.BUTTON}
                        className="ucr__controls-button ucr__controls-button--map-zoom"
                        clickEvent={() => {
                            loadedMap.setZoom(mapZoomLevel);
                            loadedMap.fitBounds(bounds);
                            clearDeepLink();
                        }}
                    />
                </GoogleMap>
            )}
        </div>
    );
};
