import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import FundingPoolsTableRow from './FundingPoolsTableRow';
import FundingPoolsTableHeader from './FundingPoolsTableHeader';
import FundingPoolsTableNav from './FundingPoolsTableNav';
import Loader from '../../../modules/helpers/Loader';
import FundingPoolsDetails from '../../../modules/fundingPools/detail/FundingPoolsDetails';
import { Alert } from '@blueprintjs/core';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import FundingPoolRelatedDialog from './FundingPoolRelatedDialog';

const FundingPools = inject('RootStore')(
    observer(
        class AdminManageFundingPools extends Component {
            state = {
                showAlert: false,
                showRelatedAlert: false,
                showOnlyRelatedAlert: false,
                relatedItems: [],
                emptyFundingPools: [],
            };

            componentWillUnmount() {
                (this.props as any).RootStore.fundingPoolStore.clear();
            }

            _handleRowClick = (id: string, event: any) => {
                if (event.target.tagName === 'TD') {
                    (this.props as any).history.push(`/hr/funding-pools/${id}`);
                }
            };

            _handleAddNew = () => {
                (this.props as any).history.replace(`/hr/funding-pools/new`);
            };

            _handleToggleSelectAll = (event: any) => {
                const { selectAll, deselectAll } = (this.props as any).RootStore.fundingPoolStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event: any) => {
                const { select, deselect } = (this.props as any).RootStore.fundingPoolStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleEditItem = () => {
                let { selected } = (this.props as any).RootStore.fundingPoolStore;
                (this.props as any).history.push(`/hr/funding-pools/${selected[0]}`, {
                    actionType: 'edit',
                    editModeOpenedFromViewList: true,
                });
            };

            _handleArchiveItem = () => {
                const { selected, gridFundingPools } = (this
                    .props as any).RootStore.fundingPoolStore;
                if (selected.length) {
                    const relatedItems = gridFundingPools.filter(
                        (item: any) =>
                            selected.includes(item.id) &&
                            (item.relatedRotas.length || item.relatedShiftPatterns.length),
                    );
                    if (selected.length === relatedItems.length) {
                        this.setState({ showOnlyRelatedAlert: true });
                    } else if (relatedItems.length) {
                        const emptyFundingPools = gridFundingPools.filter(
                            (item: any) =>
                                selected.includes(item.id) &&
                                !item.relatedRotas.length &&
                                !item.relatedShiftPatterns.length,
                        );
                        this.setState({ showRelatedAlert: true, relatedItems, emptyFundingPools });
                    } else {
                        this.setState({ showAlert: true });
                    }
                }
            };

            _closeAlert = () => {
                this.setState({ showAlert: false, showRelatedAlert: false });
            };

            _archiveItems = () => {
                const { selected } = (this.props as any).RootStore.fundingPoolStore;
                (this.props as any).RootStore.fundingPoolStore.bulkArchiveFundingPools(selected);
                this._closeAlert();
            };

            _archiveNotRelatedItems = () => {
                const { selected, gridFundingPools } = (this
                    .props as any).RootStore.fundingPoolStore;
                const notRelatedItems = gridFundingPools.filter(
                    (item: any) => selected.includes(item.id) && !item.relatedRotas.length,
                );
                (this.props as any).RootStore.fundingPoolStore.bulkArchiveFundingPools(
                    notRelatedItems.map((item: any) => item.id),
                );
                this._closeAlert();
            };

            render() {
                const {
                    RootStore: {
                        fundingPoolStore: {
                            gridFundingPools,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            onFilter,
                            loaded,
                            selected,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props as any;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = (this.props as any).match?.params ?? {};
                const actionType = (this.props as any).actionType === 'create' ? 'create' : 'edit';

                const fundingPoolsDetailsProps = { ...this.props, actionType };

                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <FundingPoolsTableNav
                                onSearch={onSearch}
                                onFilter={onFilter}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                editItem={this._handleEditItem}
                                archiveItem={this._handleArchiveItem}
                                selected={selected}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <FundingPoolsTableHeader
                                    data={gridFundingPools}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridFundingPools.map((fundingPool: any) => (
                                        <FundingPoolsTableRow
                                            {...fundingPool}
                                            onClick={this._handleRowClick}
                                            key={fundingPool.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || (this.props as any).actionType === 'create') && (
                            <div className="common-layout__modal">
                                <FundingPoolsDetails {...fundingPoolsDetailsProps} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._archiveItems}
                            onCancel={this._closeAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm archiving!"
                            description="Are you sure you want to archive these Funding Pools? You won’t be able to recover them."
                        />
                        <FundingPoolRelatedDialog
                            isOpen={this.state.showRelatedAlert}
                            onClose={this._closeAlert}
                            relatedItems={this.state.relatedItems}
                            emptyFundingPools={this.state.emptyFundingPools}
                            onArchiveNotRelated={this._archiveNotRelatedItems}
                        />
                        <Alert
                            confirmButtonText="Ok"
                            isOpen={this.state.showOnlyRelatedAlert}
                            onClose={() => this.setState({ showOnlyRelatedAlert: false })}
                        >
                            <p>
                                The selected funding pool(s) cannot be archived because of being
                                selected in future rota shifts and/or shift pattern shifts. Remove
                                the funding pool(s) from related shifts to archive
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default FundingPools;
