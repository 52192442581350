import moment from 'moment';
import { daysDifferenceWithToday } from '../components/modules/helpers/formatData';

export const onFilterChange = (name, el, onFilter) => {
    onFilter(name, Array.isArray(el) ? el.map((e) => e.value) : el.value);
};

export const onTableSearch = async (inputValue, onSearch) => {
    if (inputValue && inputValue.length >= 3) {
        return onSearch(inputValue);
    } else if (inputValue?.length === 0) {
        return onSearch('');
    } else {
        return () => {};
    }
};

export const cutLastItemInURL = (currentUrl) => currentUrl.slice(0, currentUrl.lastIndexOf('/'));

export const cutTwoLastItemsInURL = (currentUrl) => {
    const url = currentUrl.slice(0, currentUrl.lastIndexOf('/'));
    return url.slice(0, url.lastIndexOf('/'));
};

export const getDocumentColor = (expiredDate) => {
    const momentExpiredDate = moment(expiredDate);
    const daysToExpired = daysDifferenceWithToday(momentExpiredDate);

    if (daysToExpired < 0) {
        return {
            documentClass: 'danger__row',
            text: 'Expired',
        };
    }
    if (daysToExpired < 1) {
        return {
            documentClass: 'danger__row',
            text: 'Expires today',
        };
    }
    if (daysToExpired < 28) {
        return {
            documentClass: 'warning__row',
            text: 'Expires soon',
        };
    }
};
