type Configs = {
    [env: string]: {
        [key: string]: any;
    };
};

const configs: Configs = {
    dev: {
        aws: {
            aws_user_pools_id: 'eu-west-2_YGerVS6BD',
            aws_user_pools_web_client_id: '27bij46rn35oncuh4l9v7fs7p0',
        },
        ga: {
            measurementId: 'G-YZQ5DDRCZE',
        },
        hotjar: {
            hjid: 3087610,
        },
    },
    staging: {
        aws: {
            aws_user_pools_id: 'eu-west-2_w0ci4HTUd',
            aws_user_pools_web_client_id: '4vihjt1pqbmkbj0nrc7mkn2i69',
        },
        ga: {
            measurementId: 'G-6T7T3LD1NH',
        },
        hotjar: {
            hjid: 3087606,
        },
    },
    prod: {
        aws: {
            aws_user_pools_id: 'eu-west-2_x7m1md0M8',
            aws_user_pools_web_client_id: '6vt06vgp14pldosedeseakho5o',
        },
        ga: {
            measurementId: 'G-N00QVZH8K0',
        },
        hotjar: {
            hjid: 3100736,
        },
    },
    js: {
        aws: {
            aws_user_pools_id: 'eu-west-2_8hesaBqmf',
            aws_user_pools_web_client_id: '6bald38cug25d6t0mo0l3fnaha',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    sandbox1: {
        aws: {
            aws_user_pools_id: 'eu-west-2_qS1VuJmmU',
            aws_user_pools_web_client_id: '7po94adg9h6goutb1epj13sn25',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    sandbox2: {
        aws: {
            aws_user_pools_id: 'eu-west-2_hpsypI7Br',
            aws_user_pools_web_client_id: '7ljd8thmf0j3md8httvtqsishv',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    sandbox3: {
        aws: {
            aws_user_pools_id: 'eu-west-2_itmyKpjuc',
            aws_user_pools_web_client_id: '4ecq4gff746so17k26ijqq2je7',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    bz: {
        aws: {
            aws_user_pools_id: 'eu-west-2_cXX41UriF',
            aws_user_pools_web_client_id: '72m2e95lgg09k037jnskogsa3',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    gs: {
        aws: {
            aws_user_pools_id: 'eu-west-2_MujfcGtvx',
            aws_user_pools_web_client_id: '10un4gdkr2e26hgbmol8v5fol8',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
    wb: {
        aws: {
            aws_user_pools_id: 'eu-west-2_duqLIpfLS',
            aws_user_pools_web_client_id: '55cora8rorgcikhtjaob2h1jgq',
        },
        ga: {
            measurementId: null,
        },
        hotjar: {
            hjid: null,
        },
    },
};

export default function config(ENV?: string) {
    const env = ENV || process.env.REACT_APP_ENVIRONMENT;

    if (!env || !(env in configs))
        throw new Error(`No config found for REACT_APP_ENVIRONMENT: ${String(env)}`);

    return configs[env];
}
