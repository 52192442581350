import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import _ from 'lodash';

import DocumentsNavigation from './DocumentsNavigation';
import DocumentsHeader from './DocumentsHeader';
import DocumentItem from './DocumentItem';
import DocumentDetails from './DocumentDetails';
import Loader from '../../helpers/Loader';
import { downloadFile, documentsBulkDownload } from '../../../../api/hrRotaApi';

import { NormalizedDocument } from '../../../../stores/DocumentStore';

const Documents = inject('RootStore')(
    observer(
        class Documents extends Component<any> {
            state = {
                isLoading: true,
                isShowEditForm: false,
                isShowCreateForm: false,
                documentId: null,
                categories: [],
                categoriesDictionary: {},
            };

            async componentDidMount() {
                if (this.props.person) {
                    this.props.RootStore.documentStore.onFilter('personId', this.props.person.id);
                    this.setState({
                        categories: [
                            ...this.props.RootStore.dictionaryStore.peopleCategories,
                            ...this.props.RootStore.dictionaryStore.staffCategories,
                        ],
                        categoriesDictionary: {
                            ...this.props.RootStore.dictionaryStore.peopleCategoriesDictionary,
                            ...this.props.RootStore.dictionaryStore.staffCategoriesDictionary,
                        },
                    });
                } else {
                    this.props.RootStore.documentStore.onFilter(
                        'employeeId',
                        this.props.match.params.employeeId,
                    );
                    this.setState({
                        categories: this.props.RootStore.dictionaryStore.staffCategories,
                        categoriesDictionary: this.props.RootStore.dictionaryStore
                            .staffCategoriesDictionary,
                    });
                }

                try {
                    await this.props.RootStore.documentStore.getDocuments();
                    if (this.props.match.params.employeeId) {
                        await this.props.RootStore.documentStore.getOptionalDocuments(
                            this.props.match.params.employeeId,
                        );
                    }
                } catch (err) {
                    //TODO
                    console.log('err', err);
                } finally {
                    if (this.props.location.state && this.props.location.state.documentId) {
                        this.setState({
                            isShowEditForm: true,
                            documentId: this.props.location.state.documentId,
                        });
                    }
                    this.setState({ isLoading: false });
                }
            }

            componentWillUnmount() {
                this.props.RootStore.documentStore.clear();
            }

            _handleDocumentClick = (documentId: string, event: React.MouseEvent) => {
                const { tagName } = event.target as HTMLElement;
                if (tagName === 'TD') {
                    this.setState({ isShowEditForm: true, documentId });
                }
            };

            _handleDocumentDownload = async (key: string) => {
                const { org } = this.props.RootStore.configStore;
                const { url } = await downloadFile(key, org);
                window.open(url);
            };

            _handleAddNew = () => {
                this.setState({ isShowCreateForm: true });
            };

            _handleToggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
                const { selectAll, deselectAll } = this.props.RootStore.documentStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
                const { select, deselect } = this.props.RootStore.documentStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleCancel = () => {
                this.setState({ isShowCreateForm: false, isShowEditForm: false, documentId: null });
            };

            _handleDownloadItem = async () => {
                const { selected } = this.props.RootStore.documentStore;
                if (selected.length < 1) return;
                this.setState({ isLoading: true });
                const { org } = this.props.RootStore.configStore;
                const documents: Array<NormalizedDocument> = [];
                selected.forEach((selectedItem: string) => {
                    const selectedDocument = this.props.RootStore.documentStore.gridDocuments.find(
                        (document: NormalizedDocument) => document.id === selectedItem,
                    );
                    documents.push(selectedDocument);
                });
                if (documents.length > 1) {
                    const keys = documents.map((r: NormalizedDocument) => r.key);
                    const { url } = await documentsBulkDownload(keys, org);
                    window.open(url);
                    this.setState({ isLoading: false });
                } else {
                    const { url } = await downloadFile(documents[0].key, org);
                    window.open(url);
                    this.setState({ isLoading: false });
                }
            };

            _handleBulkStatusChange = async (value: boolean) => {
                const { selected } = this.props.RootStore.documentStore;
                const isNotEmpty = !_.isEmpty(selected);
                isNotEmpty &&
                    (await this.props.RootStore.documentStore.bulkStatusChange(value, selected));
            };

            _handleBulkVerificationChange = async (value: boolean) => {
                const { selected } = this.props.RootStore.documentStore;
                const isNotEmpty = !_.isEmpty(selected);
                isNotEmpty &&
                    (await this.props.RootStore.documentStore.bulkVerificationChange(
                        value,
                        selected,
                    ));
            };

            render() {
                const {
                    RootStore: {
                        documentStore: {
                            gridDocuments,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            optionalDocuments,
                            addOptionalDocument,
                            hideDocument,
                        },
                    },
                    person,
                    employee,
                } = this.props;

                const { isLoading, isShowEditForm, isShowCreateForm, documentId } = this.state;

                return (
                    <>
                        {isLoading && <Loader />}
                        {!isShowEditForm && !isShowCreateForm ? (
                            <>
                                <DocumentsNavigation
                                    pageInfo={pageInfo}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    addNewDocument={this._handleAddNew}
                                    loading={false}
                                    onFilter={onFilter}
                                    categories={this.state.categories}
                                    onBulkDownload={this._handleDownloadItem}
                                    onBulkStatusChange={this._handleBulkStatusChange}
                                    onBulkVerificationChange={this._handleBulkVerificationChange}
                                    isEmployee={!!this.props.match.params.employeeId}
                                    optionalDocuments={optionalDocuments}
                                    onAddOptionalDocument={(documentId: string) => {
                                        this.setState({ isLoading: true });
                                        addOptionalDocument(
                                            this.props.match.params.employeeId,
                                            documentId,
                                        );
                                        this.setState({ isLoading: false });
                                    }}
                                    disableEditing={
                                        person &&
                                        person.statusId === '7799ad39-2f73-490d-9e98-27ac149dee55'
                                    }
                                    isEmployeeArchived={
                                        employee &&
                                        employee.statusId === '097741c6-c0af-48c0-a848-947a9e8e3472'
                                    }
                                />
                                <table className="bp5-html-table bp5-interactive common-table">
                                    <DocumentsHeader
                                        onSort={onSort}
                                        isFrontendSort={false}
                                        onToggleSelectAll={this._handleToggleSelectAll}
                                        checked={allSelected}
                                        isEmployee={!!this.props.match.params.employeeId}
                                    />
                                    <tbody>
                                        {gridDocuments &&
                                            gridDocuments.map((document: NormalizedDocument) => (
                                                <DocumentItem
                                                    {...document}
                                                    onClick={this._handleDocumentClick}
                                                    key={document.id}
                                                    onToggle={this._handleToggleSelected}
                                                    onDownload={this._handleDocumentDownload}
                                                    categoriesDictionary={
                                                        this.state.categoriesDictionary
                                                    }
                                                    isEmployee={
                                                        !!this.props.match.params.employeeId
                                                    }
                                                    onHideDocument={(documentId: string) => {
                                                        this.setState({ isLoading: true });
                                                        hideDocument(
                                                            this.props.match.params.employeeId,
                                                            documentId,
                                                        );
                                                        this.setState({ isLoading: false });
                                                    }}
                                                    isEmployeeArchived={
                                                        employee &&
                                                        employee.statusId ===
                                                            '097741c6-c0af-48c0-a848-947a9e8e3472'
                                                    }
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <DocumentDetails
                                {...this.props}
                                actionType={isShowEditForm ? 'edit' : 'create'}
                                handleCancel={this._handleCancel}
                                documentId={documentId}
                                handleDownload={this._handleDocumentDownload}
                                updateEmployeeDataAfterChange={
                                    this.props.updateEmployeeDataAfterChange
                                }
                                categories={this.state.categories}
                                categoriesDictionary={this.state.categoriesDictionary}
                                updatePersonDataAfterChange={this.props.updatePersonDataAfterChange}
                                disableEditing={
                                    person &&
                                    person.statusId === '7799ad39-2f73-490d-9e98-27ac149dee55'
                                }
                            />
                        )}
                    </>
                );
            }
        },
    ),
);

export default Documents;
