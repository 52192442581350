import React from 'react';
import { Button, Classes, Dialog, Intent, Slider } from '@blueprintjs/core';
import { convertHoursToMinutes, convertMinutesToHoursDate, convertMinutesToHours } from '../helper';
import { TimePicker } from '@blueprintjs/datetime';

const SplitDialog = (props: any) => {
    const {
        bodyText = 'Please set a splitting time',
        onCancel,
        onSubmit,
        entry,
        submitText = 'Split shift',
        isOpen,
    } = props;

    const rangeMinValue = entry.startTime && convertHoursToMinutes(entry.startTime);
    const rangeMaxValue = entry.endTime && convertHoursToMinutes(entry.endTime);
    const [splitValue, setSplitValue] = React.useState(0);

    const stepSize = 5;
    const disabledBtn = splitValue <= rangeMinValue || splitValue >= rangeMaxValue;

    React.useEffect(() => {
        setSplitValue(rangeMinValue);
    }, [rangeMaxValue, rangeMinValue]);

    const handleChange = (val: any) => {
        const minutes = convertHoursToMinutes(val);
        if (minutes % stepSize === 0) {
            setSplitValue(minutes);
        } else {
            const rest = minutes % stepSize;
            const fullStepSize = rest <= 2 ? minutes - rest : minutes - rest + stepSize;
            setSplitValue(fullStepSize);
        }
    };

    return (
        <Dialog
            icon="fork"
            onClose={onCancel}
            canOutsideClickClose={true}
            title="Shift Splitting"
            isOpen={isOpen}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className="rota-builder__pay-rate__time-input-wrap">
                    <p>{bodyText}</p>
                    <TimePicker
                        onChange={handleChange}
                        value={convertMinutesToHoursDate(splitValue)}
                        minTime={convertMinutesToHoursDate(rangeMinValue)}
                        maxTime={convertMinutesToHoursDate(rangeMaxValue)}
                    />
                </div>
                <Slider
                    min={rangeMinValue}
                    max={rangeMaxValue}
                    stepSize={stepSize}
                    labelStepSize={rangeMaxValue - rangeMinValue}
                    labelRenderer={convertMinutesToHours}
                    onChange={(val: number) => {
                        setSplitValue(val);
                    }}
                    value={splitValue}
                    className="rota-builder__pay-rate__slider"
                />
            </div>
            <div className={`${Classes.DIALOG_FOOTER}`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        type="submit"
                        disabled={disabledBtn}
                        intent={Intent.SUCCESS}
                        onClick={() => onSubmit(splitValue)}
                    >
                        {submitText}
                    </Button>
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default SplitDialog;
