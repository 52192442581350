import { action, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import {
    CREATE_REGISTRATION,
    DELETE_REGISTRATION,
    GET_REGISTRATION,
    GET_REGISTRATIONS,
    UPDATE_REGISTRATION,
} from '../graphql/queries/registrations';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';

interface ProfessionalBody {
    id: string;
    name: string;
    key: string;
    createdAt: any;
    updatedAt: any;
}

interface FitToPractice {
    id: string;
    label: string;
    createdAt: any;
    updatedAt: any;
}

export interface Registration {
    id: string;
    personId: string;
    fitToPracticeId: string;
    professionalBodyId: string;
    registrationContent: any;
    createdAt: any;
    updatedAt: any;
    createdBy: string;
    updatedBy: string;
    fitToPractice: FitToPractice;
    professionalBody: ProfessionalBody;
    person: any;
    createdPerson: any;
    updatedPerson: any;
}

class RegistrationStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            registrations: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getRegistrations: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    registrations = [];
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeRegistrations = (registration: Registration) => ({
        id: registration.id,
        personId: registration.personId,
        fitToPracticeId: registration.fitToPracticeId,
        professionalBodyId: registration.professionalBodyId,
        registrationContent: registration.registrationContent,
        createdAt: registration.createdAt,
        updatedAt: registration.updatedAt,
        createdBy: registration.createdBy,
        updatedBy: registration.updatedBy,
        fitToPractice: registration.fitToPractice,
        professionalBody: registration.professionalBody,
        person: registration.person,
        createdPerson: registration.createdPerson,
        updatedPerson: registration.updatedPerson,
    });

    get gridRegistrations() {
        const registrations = this.registrations.map(this.normalizeRegistrations);
        if (this.sortFn) {
            registrations.sort(this.sortFn);
        }
        return registrations;
    }

    async createRegistration(registration: Registration) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_REGISTRATION,
                variables: {
                    data: {
                        personId: registration.personId,
                        professionalBodyId: registration.professionalBodyId,
                        fitToPracticeId: registration.fitToPracticeId,
                        registrationContent: registration.registrationContent,
                    },
                },
            });
            AppToaster.show({
                message: 'Registration created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating registration. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteRegistration(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_REGISTRATION,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Registration deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem deleting registration record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateRegistration(registration: Registration) {
        console.log('updateRegistration', registration);
        try {
            const {
                data: { updateRegistration },
            } = await this.apolloClient.mutate({
                mutation: UPDATE_REGISTRATION,
                variables: {
                    id: registration.id,
                    data: {
                        personId: registration.personId,
                        professionalBodyId: registration.professionalBodyId,
                        fitToPracticeId: registration.fitToPracticeId,
                        registrationContent: registration.registrationContent,
                    },
                },
            });
            AppToaster.show({
                message: 'Registration updated successfully!',
                intent: 'success',
            });

            return this.normalizeRegistrations(updateRegistration);
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating registration record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (roleId: string) => {
        if (!this.selected.includes(roleId)) {
            this.selected.push(roleId);
        }
    };

    deselect = (roleId: string) => {
        const index = this.selected.indexOf(roleId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getRegistrations();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getRegistrations();
    };

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText: any) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getRegistrations();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getRegistrations();
    };

    getRegistrations = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRegistrations: data },
        } = await this.apolloClient.query({
            query: GET_REGISTRATIONS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                filter: this.pageInfo.filter || null,
            },
        });

        runInAction(() => {
            this.registrations = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getRegistrationById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getRegistration: data },
        } = await this.apolloClient.query({
            query: GET_REGISTRATION,
            variables: {
                id,
            },
        });
        return this.normalizeRegistrations(data);
    };

    selectAll = () => {
        runInAction(() => {
            this.registrations.forEach((registration: Registration) => {
                this.select(registration.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };
}

export default RegistrationStore;
