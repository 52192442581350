export const relatedRotasTh = [
    {
        name: 'Name',
        dataSort: 'name',
    },
    {
        name: 'Start time',
        dataSort: 'startTime',
    },
    {
        name: 'End time',
        dataSort: 'endTime',
    },
    {
        name: 'Department',
        dataSort: 'department',
    },
    {
        name: 'Cost centre',
        dataSort: 'costCentre',
    },
];

export const relatedShiftPatternsTh = [
    {
        name: 'Name',
        dataSort: 'name',
    },
    {
        name: 'Weeks',
        dataSort: 'weeks',
    },
    {
        name: 'Shifts',
        dataSort: 'shifts',
    },
    {
        name: 'Department',
        dataSort: 'department',
    },
    {
        name: 'Cost centre',
        dataSort: 'costCentre',
    },
];

export const relatedStaffsTh = [
    {
        name: 'First name',
        dataSort: 'firstName',
    },
    {
        name: 'Middle name',
        dataSort: 'middleName',
    },
    {
        name: 'Last name',
        dataSort: 'lastName',
    },
    {
        name: 'Department(s)',
        dataSort: 'department',
    },
    {
        name: 'Cost Centre(s)',
        dataSort: 'costCentre',
    },
    {
        name: 'Job title',
        dataSort: 'jobTitle',
    },
    {
        name: 'Role',
        dataSort: 'role',
    },
    {
        name: 'Record status',
        dataSort: 'recordStatus',
    },
];

export const relatedRolesTh = [
    {
        name: 'Name',
        dataSort: 'name',
    },
    {
        name: 'Functions',
        dataSort: 'functions',
    },
];

export const relatedDepartmentsTh = [
    {
        name: 'Name',
        dataSort: 'name',
    },
    {
        name: 'Cost code',
        dataSort: 'costCode',
    },
    {
        name: 'Cost centre',
        dataSort: 'costCentre',
    },
    {
        name: 'Roles',
        dataSort: 'roles',
    },
    {
        name: 'Locations',
        dataSort: 'locations',
    },
];

export const relatedPayRatesTh = [
    {
        name: 'Name',
        dataSort: 'name',
    },
    {
        name: 'Role',
        dataSort: 'role',
    },
    {
        name: 'General code',
        dataSort: 'generalCode',
    },
];
