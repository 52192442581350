import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import AdminManageStaff from '../../pages/admin/AdminManageStaff';
import AdminManagePeople from '../../pages/admin/AdminManagePeople';
import AdminManageRoles from '../../pages/admin/AdminManageRoles';
import AdminManageFunctions from '../../pages/admin/AdminManageFunctions';
import SideNav from './SideNav';
import AdminManageAlerts from '../../pages/admin/AdminManageAlerts/Alerts';
import AdminManageDepartments from '../../pages/admin/AdminManageDepartments';
import AdminManagePayRates from '../../pages/admin/AdminManagePayRates';
import AdminManageAgencies from '../../pages/admin/AdminManageAgencies';
import AdminManageFundingPools from '../../pages/admin/AdminManageFundingPools';
import AdminManageInductionTasks from '../../pages/admin/AdminManageInductionTasks';
import AdminManageCostCentres from '../../pages/admin/AdminManageCostCentres';
import Locations from '../../pages/admin/Rota/Locations';
import AdminManageGroups from '../../pages/admin/Rota/AdminManageGroups';
import AdminManageSpecialities from '../../pages/admin/Rota/AdminManageSpecialities';
import Absences from '../../pages/admin/AdminManageAbsences';
import AbsenceManagement from '../../pages/admin/AdminManageAbsences/AbsenceManagement/AbsenceManagement';

const sideNavItems = (importantAlerts) => [
    {
        title: 'People',
        to: '/hr/people',
        icon: 'user',
        subItems: [
            {
                title: 'All people',
                to: '/hr/people',
            },
            {
                title: 'Employed records',
                to: '/hr/employed-records',
            },
            {
                title: 'Sessional records',
                to: '/hr/sessional-records',
            },
        ],
    },
    {
        title: 'Organisation',
        to: '/hr/agencies',
        icon: 'people',
        subItems: [
            {
                title: 'Agencies',
                to: '/hr/agencies',
                icon: 'people',
            },
            {
                title: 'Cost Centres',
                to: '/hr/cost-centres',
                icon: 'dollar',
            },
            {
                title: 'Departments',
                to: '/hr/departments',
                icon: 'inbox-geo',
            },
            {
                title: 'Functions',
                to: '/hr/functions',
                icon: 'function',
            },
            {
                title: 'Funding pools',
                to: '/hr/funding-pools',
                icon: 'bank-account',
            },
            {
                title: 'Locations',
                to: '/hr/locations',
                icon: 'map',
            },
            {
                title: 'Groups',
                to: '/hr/groups',
                icon: 'group-objects',
            },
            {
                title: 'Specialities',
                to: '/hr/specialities',
                icon: 'id-number',
            },
        ],
    },
    {
        title: 'Absence management',
        to: '/hr/absence-management',
        icon: 'calendar',
    },
    {
        title: 'Alerts',
        to: '/hr/alerts',
        icon: 'warning-sign',
        ...(importantAlerts && {
            details: <span className="side-nav__important-details">{importantAlerts}</span>,
        }),
    },
    {
        title: 'Roles',
        to: '/hr/roles',
        icon: 'build',
    },
    {
        title: 'Pay rates',
        to: '/hr/pay-rates',
        icon: 'briefcase',
    },
    {
        title: 'Induction Tasks',
        to: '/hr/induction-tasks',
        icon: 'label',
    },
];
const AdminMain = inject('RootStore')(
    observer(
        class AdminMain extends Component {
            render() {
                const importantAlerts = this.props.RootStore?.alertsStore?.importantAlerts;
                return (
                    <section className="common-layout">
                        <aside className="common-layout__tools">
                            <SideNav items={sideNavItems(importantAlerts)} />
                        </aside>
                        <Switch>
                            <Redirect exact from="/hr" to="/hr/people" />
                            <Route
                                exact
                                path="/hr/employed-records/new"
                                component={(props) => (
                                    <AdminManageStaff actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/employed-records/:employeeId?/:tab?"
                                component={AdminManageStaff}
                            />
                            <Route
                                exact
                                path="/hr/sessional-records/new"
                                component={(props) => (
                                    <AdminManageStaff actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/sessional-records/:employeeId?/:tab?"
                                component={AdminManageStaff}
                            />
                            <Route
                                exact
                                path="/hr/people/new"
                                component={(props) => (
                                    <AdminManagePeople actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/people/:personId?/:tab?"
                                component={AdminManagePeople}
                            />

                            <Route
                                exact
                                path="/hr/agencies/new"
                                component={(props) => (
                                    <AdminManageAgencies actionType="create" {...props} />
                                )}
                            />
                            <Route exact path="/hr/agencies/:id?" component={AdminManageAgencies} />

                            <Route
                                exact
                                path="/hr/funding-pools/new"
                                component={(props) => (
                                    <AdminManageFundingPools actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/funding-pools/:id?"
                                component={AdminManageFundingPools}
                            />

                            <Route
                                exact
                                path="/hr/roles/new"
                                component={(props) => (
                                    <AdminManageRoles actionType="create" {...props} />
                                )}
                            />
                            <Route exact path="/hr/roles/:id?" component={AdminManageRoles} />

                            <Route
                                exact
                                path="/hr/functions/new"
                                component={(props) => (
                                    <AdminManageFunctions actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/functions/:id?"
                                component={AdminManageFunctions}
                            />

                            <Route
                                exact
                                path="/hr/absence-management"
                                component={(props) => <Absences {...props} embedded={true} />}
                            />

                            <Route
                                exact
                                path="/hr/absence-management/new"
                                component={(props) => (
                                    <AbsenceManagement
                                        {...props}
                                        embedded={true}
                                        actionType="create"
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/hr/absence-management/:id?"
                                component={AbsenceManagement}
                            />
                            <Route exact path="/hr/alerts" component={AdminManageAlerts} />
                            <Route
                                exact
                                path="/hr/departments/new"
                                component={(props) => (
                                    <AdminManageDepartments actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/departments/:id?"
                                component={AdminManageDepartments}
                            />
                            <Route
                                exact
                                path="/hr/pay-rates/new"
                                component={(props) => (
                                    <AdminManagePayRates actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/pay-rates/:id?"
                                component={AdminManagePayRates}
                            />
                            <Route
                                exact
                                path="/hr/induction-tasks/"
                                component={AdminManageInductionTasks}
                            />
                            <Route
                                exact
                                path="/hr/cost-centres/new"
                                component={(props) => (
                                    <AdminManageCostCentres actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/cost-centres/:id?"
                                component={AdminManageCostCentres}
                            />
                            <Route
                                exact
                                path="/hr/locations/new"
                                component={(props) => <Locations actionType="create" {...props} />}
                            />
                            <Route exact path="/hr/locations/:id?" component={Locations} />
                            <Route
                                exact
                                path="/hr/groups/new"
                                component={(props) => (
                                    <AdminManageGroups actionType="create" {...props} />
                                )}
                            />
                            <Route exact path="/hr/groups/:id?" component={AdminManageGroups} />
                            <Route
                                exact
                                path="/hr/specialities/new"
                                component={(props) => (
                                    <AdminManageSpecialities actionType="create" {...props} />
                                )}
                            />
                            <Route
                                exact
                                path="/hr/specialities/:id?"
                                component={AdminManageSpecialities}
                            />
                        </Switch>
                    </section>
                );
            }
        },
    ),
);

export default AdminMain;
