import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Alert,
    Button,
    Checkbox,
    FormGroup,
    InputGroup,
    Switch,
    Tab,
    Tabs,
} from '@blueprintjs/core';

import Modal from '../../modal/Modal';
import AppToaster from '../../helpers/Toaster';
import Onboarding from './Onboarding';
import Select from 'react-select';
import Error from '../../forms/Error';
import { formatDisplayDateTime } from '../../helpers/formatData';
import { getHcpName } from '../../../../helpers/ucr';
import PhoneInput from 'react-phone-input-2';
import Warning from '../../forms/Warning';
import { genderMapping, getDisplayBand } from '../../../../constants/mainConst';
import { formatPhoneNumber } from '../../helpers/FormatPhoneNumber';

const AdminUserDetails = inject('RootStore')(
    observer(
        class AdminUserDetails extends Component {
            state = {
                saving: false,
                enabled: this.props.enabled,
                isEdit: false,
                band: '',
                gender: '',
                phoneNumber: '',
                email: '',
                languages: [],
                roles: [],
                rolesInDB: [],
                errors: {
                    gender: [],
                    languages: [],
                    phoneNumber: [],
                    email: [],
                    roles: [],
                },
                warnings: [],
                isValid: true,
                showResetPasswordDialog: false,
                userSession: {},
                accountType: '',
                organisation: '',
            };

            componentDidMount() {
                const { userId } = this.props.match.params;
                const { users } = this.props.RootStore.usersStore;

                const user = users.find((user: any) => user.userId === userId);

                this._handleSetEnabled(user.enabled);

                this._handleInitialState(user);

                this._getUserSession();
            }

            _getUserSession = async (user) => {
                const userSession = await this.props.RootStore.userStore.getUserSession();
                this.setState({ userSession });
            };

            _handleSetEnabled = (enabled) => {
                this.setState({ enabled });
            };

            _handleInitialState = (user) => {
                const gender = this.props.RootStore.lovsStore.gender.find(
                    (g) => g.value === user.gender,
                );
                const { bands } = this.props.RootStore.configStore;
                let band = bands.find((g) => g.value === user.band);
                let phoneNumber;
                if (user.phoneNumber) {
                    phoneNumber = user.phoneNumber.replace(/\s/g, '');
                    if (phoneNumber.startsWith('0')) {
                        phoneNumber = phoneNumber.substring(1);
                    }
                    if (phoneNumber.startsWith('+')) {
                        phoneNumber = phoneNumber.substring(1);
                    }
                    if (!phoneNumber.startsWith('44')) {
                        phoneNumber = `44${phoneNumber}`;
                    }
                }

                this.setState({
                    band,
                    gender,
                    languages: (user.preferences?.languages || []).map((item) => ({
                        label: item,
                        value: item,
                    })),
                    phoneNumber,
                    email: user.email,
                    roles: user.roles || [],
                    rolesInDB: user.roles || [],
                    accountType: user.accountType,
                    organisation: user.organisation,
                    errors: {
                        gender: [],
                        languages: [],
                        phoneNumber: [],
                        email: [],
                        roles: [],
                    },
                    warnings: [],
                });
            };

            _handleCloseClick = () => {
                this.props.history.push(`/manage-users`);
            };

            _toggleIsEdit = () => {
                const { userId } = this.props.match.params;
                const { users } = this.props.RootStore.usersStore;

                const user = users.find((user: any) => user.userId === userId);

                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });

                this._handleInitialState(user);
            };

            _setFieldValue = (field, value) => {
                this.setState({
                    [field]: value,
                });
            };

            _handleSaveChanges = async (user) => {
                const { enabled, phoneNumber, email } = this.state;
                const rolesData = this._getRolesData();
                const userData = {
                    enabled,
                    phoneNumber,
                    email,
                    rolesData,
                };

                const isValid = this._validateFields(user);
                if (!isValid) {
                    return null;
                }

                this.setState({ saving: true });

                const response = await this.props.RootStore.usersStore.updateController(
                    user.userId,
                    userData,
                );

                if (response.successful) {
                    AppToaster.show({
                        message: 'User successfully updated.',
                        intent: 'success',
                    });
                    this.setState({ isEdit: false });
                } else {
                    AppToaster.show({
                        message: 'Unable to update user, please try again.',
                        intent: 'danger',
                    });
                }

                this.setState({ saving: false });
            };

            _handleSaveDemographics = async (user) => {
                const { gender, languages, enabled, phoneNumber, email, band } = this.state;
                const rolesData = this._getRolesData();

                if (!gender.value) {
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            gender: ['This field is required.'],
                        },
                    });
                    return null;
                }

                const isValid = this._validateFields(user);

                if (!isValid) {
                    return null;
                }

                this.setState({ saving: true });

                const languagesData = languages.map((l) => l.value);
                const demographicsData = {
                    gender: gender.value,
                    phoneNumber,
                    email,
                    orgData: {
                        preferences: {
                            ...user.preferences,
                            languages: languagesData,
                        },
                        band: band ? band.value : '',
                        enabled,
                    },
                    rolesData,
                };

                const response = await this.props.RootStore.usersStore.setUserDemographics(
                    user.userId,
                    demographicsData,
                );

                if (response.successful) {
                    AppToaster.show({
                        message: 'User successfully updated.',
                        intent: 'success',
                    });

                    this.setState({ isEdit: false });
                } else {
                    AppToaster.show({
                        message: 'Unable to update user, please try again.',
                        intent: 'danger',
                    });
                }

                this.setState({ saving: false });
            };

            _validateFields = (currentUser, field) => {
                const { users } = this.props.RootStore.usersStore;
                const { phoneNumber, email, roles } = this.state;
                const warnings = [];
                let emailErrors = [];
                let phoneNumberErrors = [];
                let roleErrors = [];
                let isValid = true;

                if (field && field === 'email') {
                    phoneNumberErrors = this.state.errors.phoneNumber;
                    roleErrors = this.state.errors.roles;
                }

                if (field && field === 'phoneNumber') {
                    emailErrors = this.state.errors.email;
                    roleErrors = this.state.errors.roles;
                }

                if (field && field === 'roles') {
                    emailErrors = this.state.errors.email;
                    phoneNumberErrors = this.state.errors.phoneNumber;
                }

                if (email && ((field && field === 'email') || !field)) {
                    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (!emailRegex.test(email)) {
                        emailErrors.push('Invalid email format.');
                        isValid = false;
                    } else {
                        const emailDomain = email.split('@')[1].toLowerCase();
                        if (
                            this.props.RootStore.configStore.permittedDomains?.length > 0 &&
                            !this.props.RootStore.configStore.permittedDomains.includes(emailDomain)
                        ) {
                            emailErrors.push(
                                'Email domain is not permitted, please speak with an administrator.',
                            );
                            isValid = false;
                        }
                    }
                }

                if (phoneNumber && ((field && field === 'phoneNumber') || !field)) {
                    const phoneNumberRegex = /^\d{9,10}$/;
                    if (!phoneNumberRegex.test(phoneNumber.substr(2))) {
                        phoneNumberErrors.push(
                            'Invalid phone number; number must be 9 or 10 digits excluding the country code.',
                        );
                        isValid = false;
                    }
                }

                if (email) {
                    const user = users.find(
                        (u) =>
                            u.email?.toLowerCase() === email.toLowerCase() &&
                            u.userId !== currentUser.userId,
                    );
                    if (user) {
                        warnings.push(
                            'This email address is already set on another user account and you may create a duplicate account.',
                        );
                    }
                }

                if (phoneNumber) {
                    const user = users.find(
                        (u) =>
                            (u.phoneNumber === phoneNumber ||
                                u.phoneNumber === `0${phoneNumber.substr(2)}` ||
                                u.phoneNumber === `+${phoneNumber}`) &&
                            u.userId !== currentUser.userId,
                    );
                    if (user) {
                        warnings.push(
                            'This phone number is already set on another user account and you may create a duplicate account.',
                        );
                    }
                }

                if (roles && ((field && field === 'roles') || !field)) {
                    if (
                        !roles.some((g) =>
                            [
                                'controller',
                                `controller-${currentUser.organisation}`,
                                'org_admin',
                                `org_admin-${currentUser.organisation}`,
                                'hcp',
                            ].includes(g),
                        )
                    ) {
                        roleErrors.push('Please select at least one role.');
                        isValid = false;
                    }
                }

                this.setState({
                    errors: {
                        ...this.state.errors,
                        phoneNumber: phoneNumberErrors,
                        email: emailErrors,
                        roles: roleErrors,
                    },
                    warnings: warnings,
                    isValid: isValid,
                });

                return isValid;
            };

            _handleResetPassword = async (user) => {
                this.setState({ saving: true });

                const response = await this.props.RootStore.usersStore.resetPassword(
                    user.userId,
                    user.accountType === 'legacy',
                );

                if (response.successful) {
                    AppToaster.show({
                        message: "User's password succesfully reset.",
                        intent: 'success',
                    });

                    this.setState({ isEdit: false });
                } else {
                    AppToaster.show({
                        message: "Unable to update user's password, please try again.",
                        intent: 'danger',
                    });
                }

                this.setState({ saving: false, showResetPasswordDialog: false });
            };

            _handleRolesChange = (role, user) => {
                const { roles } = this.state;
                if (roles.find((g) => g === role)) {
                    this.setState(
                        {
                            roles: roles.filter((g) => g !== role),
                        },
                        () => this._validateFields(user, 'roles'),
                    );
                } else {
                    this.setState(
                        {
                            roles: [...roles, role],
                        },
                        () => this._validateFields(user, 'roles'),
                    );
                }
            };

            _getRolesData = () => {
                const { roles, rolesInDB } = this.state;
                const add = [];
                const remove = [];
                rolesInDB.forEach((g) => {
                    if (!roles.includes(g)) {
                        remove.push(
                            this.state.accountType === 'unified'
                                ? `${g}-${this.state.organisation}`
                                : g,
                        );
                    }
                });
                roles.forEach((g) => {
                    if (!rolesInDB.includes(g)) {
                        add.push(
                            this.state.accountType === 'unified'
                                ? `${g}-${this.state.organisation}`
                                : g,
                        );
                    }
                });
                return { add, remove };
            };

            _renderUserSummary = (user) => {
                const {
                    userId,
                    email,
                    preferences: { languages } = {},
                    gender,
                    accountType,
                    phoneNumber,
                    lastUpdatedBy,
                    lastUpdatedAt,
                } = user;
                const { enabled, isEdit } = this.state;
                const {
                    usersStore: { users },
                } = this.props.RootStore;
                const { bands } = this.props.RootStore.configStore;
                const labelBand = this.state.band && this.state.band.label;

                return (
                    <div>
                        <dl className="info">
                            <dt className="info__title">User ID</dt>
                            <dd className="info__definition">{userId}</dd>
                            <dt className="info__title">Phone Number</dt>
                            {isEdit ? (
                                <dd className="info__definition">
                                    <FormGroup labelFor="phoneNumber">
                                        <PhoneInput
                                            inputProps={{
                                                id: 'phoneNumber',
                                            }}
                                            value={
                                                this.state.phoneNumber
                                                    ? this.state.phoneNumber
                                                    : '44'
                                            }
                                            country="gb"
                                            onlyCountries={['gb']}
                                            autoFormat={false}
                                            onChange={(value) =>
                                                this._setFieldValue(
                                                    'phoneNumber',
                                                    formatPhoneNumber(value),
                                                )
                                            }
                                            onBlur={(e) =>
                                                this._validateFields(user, 'phoneNumber')
                                            }
                                            inputClass="v2__form-phone-input"
                                            dropdownClass="v2__form-phone-drop-down"
                                            specialLabel=""
                                            countryCodeEditable={false}
                                        />
                                        <Error errors={this.state.errors.phoneNumber} />
                                    </FormGroup>
                                </dd>
                            ) : (
                                <dd className="info__definition">
                                    {phoneNumber && phoneNumber.startsWith('44')
                                        ? `+${phoneNumber}`
                                        : phoneNumber}
                                </dd>
                            )}
                            <dt className="info__title">Email Address</dt>
                            {isEdit ? (
                                <dd className="info__definition">
                                    <FormGroup labelFor="email">
                                        <InputGroup
                                            id="email"
                                            large
                                            onChange={(e) =>
                                                this._setFieldValue('email', e.target.value.trim())
                                            }
                                            onBlur={(e) => this._validateFields(user, 'email')}
                                            value={this.state.email}
                                        />
                                        <Error errors={this.state.errors.email} />
                                    </FormGroup>
                                </dd>
                            ) : (
                                <dd className="info__definition">{email}</dd>
                            )}
                            <dt className="info__title">Enabled</dt>
                            <dd className="info__definition">
                                <Switch
                                    checked={enabled}
                                    large
                                    disabled={!isEdit}
                                    onChange={() => this._handleSetEnabled(!enabled)}
                                />
                            </dd>
                            <dt className="info__title">Roles</dt>
                            <dd className="info__definition">
                                <FormGroup labelFor="roles">
                                    <Checkbox
                                        checked={this.state.roles.includes('hcp')}
                                        disabled={!isEdit || user.accountType === 'legacy'}
                                        onChange={() => this._handleRolesChange('hcp', user)}
                                    >
                                        HCP
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.roles.includes('org_admin')}
                                        disabled={
                                            !isEdit ||
                                            user.userId === this.state.userSession.username
                                        }
                                        onChange={() => this._handleRolesChange('org_admin', user)}
                                    >
                                        Administrator
                                    </Checkbox>
                                    <Checkbox
                                        checked={this.state.roles.includes('controller')}
                                        disabled={!isEdit}
                                        onChange={() => this._handleRolesChange('controller', user)}
                                    >
                                        Controller
                                    </Checkbox>
                                    <Error errors={this.state.errors.roles} />
                                </FormGroup>
                            </dd>
                        </dl>
                        {accountType === 'unified' && (
                            <div>
                                <h3 className="h3">Demographics</h3>
                                <dl className="info">
                                    <dt className="info__title">Gender</dt>
                                    {isEdit ? (
                                        <dd className="info__definition">
                                            <FormGroup label="" labelFor="gender">
                                                <Select
                                                    inputId="gender"
                                                    options={this.props.RootStore.lovsStore.gender}
                                                    value={this.state.gender}
                                                    onChange={(e) =>
                                                        this._setFieldValue('gender', e)
                                                    }
                                                />
                                                <Error errors={this.state.errors.gender} />
                                            </FormGroup>
                                        </dd>
                                    ) : (
                                        <dd className="info__definition">
                                            {gender ? genderMapping[gender] : 'Not set'}
                                        </dd>
                                    )}
                                    <dt className="info__title">Languages</dt>
                                    {isEdit ? (
                                        <dd className="info__definition">
                                            <FormGroup label="" labelFor="languages">
                                                <Select
                                                    inputId="languages"
                                                    options={
                                                        this.props.RootStore.lovsStore
                                                            .languagesSpoken
                                                    }
                                                    value={this.state.languages}
                                                    onChange={(e) =>
                                                        this._setFieldValue('languages', e)
                                                    }
                                                    isMulti
                                                    className="react-select"
                                                />
                                                <Error errors={this.state.errors.languages} />
                                            </FormGroup>
                                        </dd>
                                    ) : (
                                        <dd className="info__definition">
                                            {languages?.length > 0
                                                ? languages.join(', ')
                                                : 'Not set'}
                                        </dd>
                                    )}
                                    <dt className="info__title">Last changed on</dt>
                                    <dd className="info__definition">
                                        {lastUpdatedAt
                                            ? formatDisplayDateTime(lastUpdatedAt)
                                            : null}
                                    </dd>
                                    <dt className="info__title">Last changed by</dt>
                                    <dd className="info__definition">
                                        {getHcpName(users, lastUpdatedBy)}
                                    </dd>
                                </dl>
                            </div>
                        )}
                        <div>
                            <h3 className="h3">Professional details</h3>
                            <dl className="info">
                                <dt className="info__title">Band</dt>
                                {isEdit ? (
                                    <dd className="info__definition">
                                        <FormGroup labelFor="band">
                                            <Select
                                                inputId="band"
                                                options={bands
                                                    ?.slice()
                                                    .sort((a, b) => a.label.localeCompare(b.label))}
                                                menuPlacement="top"
                                                isClearable
                                                value={this.state.band}
                                                onChange={(e) => this._setFieldValue('band', e)}
                                                className="react-select"
                                            />
                                        </FormGroup>
                                    </dd>
                                ) : (
                                    <dd className="info__definition">
                                        {labelBand ? getDisplayBand(labelBand) : 'Not set'}
                                    </dd>
                                )}
                            </dl>
                        </div>
                        {isEdit && <Warning warnings={this.state.warnings} />}
                    </div>
                );
            };

            _renderFooter = (user) => {
                const { accountType } = user;
                const { saving, isEdit } = this.state;

                return (
                    <>
                        {isEdit ? (
                            <>
                                <Button
                                    text="Cancel"
                                    icon="cross"
                                    loading={saving}
                                    onClick={this._toggleIsEdit}
                                    large
                                    outlined
                                />
                                <Button
                                    text="Save changes"
                                    intent="success"
                                    icon="tick"
                                    loading={saving}
                                    onClick={() =>
                                        accountType === 'unified'
                                            ? this._handleSaveDemographics(user)
                                            : this._handleSaveChanges(user)
                                    }
                                    disabled={!this.state.isValid}
                                    large
                                    outlined
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    text="Edit"
                                    intent="primary"
                                    icon="edit"
                                    onClick={this._toggleIsEdit}
                                    large
                                    outlined
                                    disabled={accountType === 'legacy'}
                                />
                                <Button
                                    text="Reset password"
                                    intent="danger"
                                    icon="key"
                                    onClick={() => this.setState({ showResetPasswordDialog: true })}
                                    large
                                    outlined
                                    disabled={accountType === 'legacy'}
                                />
                            </>
                        )}
                    </>
                );
            };

            render() {
                const {
                    RootStore: {
                        usersStore: { users },
                        onboardingsStore: { getUserOnboarding },
                    },
                    match: {
                        params: { userId },
                    },
                } = this.props;

                const { showResetPasswordDialog, saving } = this.state;

                const user = users.find((user: any) => user.userId === userId);

                let onboarding;

                if (user?.onboardingId) {
                    onboarding = getUserOnboarding(user.onboardingId);
                }

                return (
                    <Modal
                        title={user.userName}
                        onClose={this._handleCloseClick}
                        shadow
                        footer={this._renderFooter(user)}
                    >
                        <Tabs id="TabsExample" defaultSelectedTabId="summary" large>
                            <Tab
                                id="summary"
                                title="Summary"
                                panel={this._renderUserSummary(user)}
                            />
                            {onboarding && (
                                <Tab
                                    id="onboarding"
                                    title="Onboarding"
                                    panel={<Onboarding onboarding={onboarding} />}
                                />
                            )}
                        </Tabs>
                        <Alert
                            isOpen={showResetPasswordDialog}
                            onConfirm={() => this._handleResetPassword(user)}
                            onCancel={() => this.setState({ showResetPasswordDialog: false })}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="danger"
                            loading={saving}
                        >
                            <p>Are you sure you want to reset this user's password?</p>
                        </Alert>
                    </Modal>
                );
            }
        },
    ),
);

export default AdminUserDetails;
