import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { FC } from 'react';
import useStores from '../../../../../hook/useStores';
import { LIST_STATE } from '../../../../../stores/PatientListStore';
import { PatientListRow } from './PatientListRow';

export const PatientListRows: FC = observer(() => {
    const {
        RootStore: {
            patientListStore: { patients, state },
        },
    } = useStores() as { RootStore: any };

    if (state !== LIST_STATE.PATIENTS_TO_LIST) {
        return (
            <tr className="patient-list__no-patients-row">
                <td
                    // @ts-ignore Don't know why; colspan supports percentages
                    colSpan="100%"
                >
                    {state}
                </td>
            </tr>
        );
    }

    return (
        <>
            {patients.map((patient: any) => (
                <PatientListRow
                    key={`patient-list-row++${patient.nhsNumber}`}
                    patient={toJS(patient)}
                />
            ))}
        </>
    );
});
