import React from 'react';

const ArchivePopUpText = (
    employeeFooter: boolean,
    datesOfEngagementNotSetOrAreInFuture: boolean,
    personEndDateNotValid: boolean,
    staffRecordsWithWrongEngagementDates: [],
    engagementDatesOfDocumentsNotSetAccordingly: () => boolean,
) => {
    // Text for staff records
    if (employeeFooter) {
        return datesOfEngagementNotSetOrAreInFuture || engagementDatesOfDocumentsNotSetAccordingly()
            ? 'This staff record does not have an End Date of Engagement and/or End Date of Department Assignment(s) set, or its end dates are already set for the future. Proceeding with the Archive operation will set the end dates to today’s date.'
            : 'Are you sure you want to archive the staff record? This action is irreversible, and related Department Assignment(s) will be archived as well.';
    }

    // Text for person records
    if (!personEndDateNotValid && !staffRecordsWithWrongEngagementDates.length) {
        return 'Are you sure you want to archive the person record? This action is irreversible, and related staff record(s) will be archived as well.';
    }

    if (personEndDateNotValid && !staffRecordsWithWrongEngagementDates.length) {
        return 'The person record does not have a Continuous Employment End Date set, or its end date is already set for the future. Proceeding with the Archive operation will set the Continuous Employment End Date to today’s date.';
    }

    if (!personEndDateNotValid && staffRecordsWithWrongEngagementDates.length) {
        return (
            <div>
                <p>
                    The following staff record(s) do not have an End Date of Engagement and/or End
                    Date of Department Assignment set, or their end dates are already set for the
                    future:
                </p>
                <ul>
                    {staffRecordsWithWrongEngagementDates.map((item: any) => (
                        <li>
                            <a href={`/hr/${item.staffType}/${item.id}`} target="blank">
                                {item.role}
                            </a>
                        </li>
                    ))}
                </ul>
                <p>
                    Proceeding with the Archive operation will set the end dates for affected
                    records to today’s date.
                </p>
            </div>
        );
    }

    if (personEndDateNotValid && staffRecordsWithWrongEngagementDates.length) {
        return (
            <div>
                <p>
                    The person record does not have a Continuous Employment End Date set, or its end
                    date is already set for the future. Also the following staff record(s) do not
                    have an End Date of Engagement and/or End Date of Department Assignment set, or
                    their end dates are already set for the future:
                </p>
                <ul>
                    {staffRecordsWithWrongEngagementDates.map((item: any) => (
                        <li>
                            <a href={`/hr/${item.staffType}/${item.id}`} target="blank">
                                {item.role}
                            </a>
                        </li>
                    ))}
                </ul>
                <p>
                    Proceeding with the Archive operation will set the end dates for affected
                    records to today’s date.
                </p>
            </div>
        );
    }
};

export default ArchivePopUpText;
