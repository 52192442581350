import { FC, useContext, useEffect, useState } from 'react';
import { Patient, JobStatus } from '@doc-abode/data-models';
import { useQuery, NetworkStatus, useMutation } from '@apollo/client';
import { Callout } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import moment from 'moment';

import useStores from '../../../../../hook/useStores';
import { GET_JOB_BY_ID, UPDATE_JOB } from '../../../../../graphql/queries/jobs';
import { ADMIN_TIME } from '../../forms/AdminTime/AdminTimeConsts';
import { WarningType } from '../../../../../interfaces/ucr';

import PatientDataForm from '../../forms/PatientDataForm/PatientDetailsPdsLookup';

import { JobsContext } from '../../../../../providers';
import AdminDetails from './AdminDetails';
import Header from './children/Header';
import Loader from './children/Loader';
import VisitDetails from './VisitDetails';

const activeStates = [JobStatus.PENDING, JobStatus.ACCEPTED, JobStatus.CURRENT, JobStatus.ARRIVED];

interface IProps {
    refreshJobs(): void;
}

interface APIValuesInterface {
    id: string;
    jobStatus?: JobStatus;
    buddyJobStatus?: JobStatus;
    lastUpdatedBy: string;
    lastUpdatedDateTime: string;
    version: number;
    hcpId?: string | null;
    hcpName?: string;
    buddyId?: string | null;
    buddyName?: string;
}

const PanelDetails: FC<IProps> = ({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: {
                warnings,
                loadingWarnings,
                focusedJobId,
                focusedUser,
                openAlert,
                closeAlert,
                getPDSData,
                loadingPDSData,
                setLocalPatientData,
                localPatientData,
                pdsData,
                clearLocalPatientData,
                clearPDSData,
                jobAlerts,
                loadingJobAlerts,
                fetchJobAlerts,
            },
            userStore: {
                user: { username },
            },
            usersStore: { users },
            configStore: { isFeatureEnabled },
        },
    } = useStores() as { RootStore: any };

    const jobsContext = useContext(JobsContext);

    const [updateJob, { loading: updatingJob }] = useMutation(UPDATE_JOB);
    const { error, data, refetch, networkStatus } = useQuery(GET_JOB_BY_ID, {
        variables: {
            id: focusedJobId,
        },
        notifyOnNetworkStatusChange: true,
        pollInterval: 60000,
        skip: focusedJobId === '',
    });

    const [showDetailsAmendDialog, setShowDetailsAmendDialog] = useState(false);
    const [patient, setPatient] = useState({} as Patient);

    const isNotDefinedBuddyStatus = !patient.buddyJobStatus ? true : false;
    if (isNotDefinedBuddyStatus && patient.staffRequired === 2 && patient.buddyId) {
        patient.buddyJobStatus = JobStatus.ACCEPTED;
    }

    const isAdminTime = patient.disposition === ADMIN_TIME;
    const isDoubleUp = Boolean(patient.staffRequired === 2);
    const isFirstUser = Boolean(!isDoubleUp || (isDoubleUp && focusedUser === 'user1'));
    const isLoading =
        networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.refetch ||
        networkStatus === NetworkStatus.setVariables;

    const currentWarnings: WarningType[] = warnings[focusedJobId] || [];

    const setToFormPatientData = async (data: Patient) => {
        const {
            nhsNumber,
            firstName,
            lastName,
            middleName,
            gender,
            dateOfBirth,
            addressLine1,
            addressLine2,
            addressLine3,
            town,
            postCode,
            contactNumber,
            additionalContactNumbers,
            pds,
        } = data;

        const patientData = {
            nhsNumber,
            dateOfBirth,
            gender,
            firstName,
            middleName,
            lastName,
            addressLine1,
            addressLine2,
            addressLine3,
            contactNumber,
            additionalContactNumbers: additionalContactNumbers?.filter((str) => str),
            town,
            postCode,
            pds,
            version: patient.version + 1,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().seconds(0).toISOString(),
            id: patient.id,
        };

        clearLocalPatientData();
        clearPDSData();

        await updateJob({ variables: { input: patientData } });
        refetch();
    };

    const onClose = () => {
        setShowDetailsAmendDialog(false);
        clearLocalPatientData();
        clearPDSData();
    };

    useEffect(() => {
        fetchJobAlerts();
    }, [fetchJobAlerts]);

    useEffect(() => {
        if (jobsContext.refreshAssignedJobs) {
            refetch();
        }
    }, [jobsContext.refreshAssignedJobs, refetch]);

    useEffect(() => {
        if (data?.getJob?.nhsNumber && data?.getJob?.nhsNumber !== patient?.nhsNumber) {
            const triggerPDSLookup =
                isFeatureEnabled('pdsLookup') &&
                (activeStates.includes(data.getJob.jobStatus) ||
                    (data.getJob.staffRequired === 2 &&
                        activeStates.includes(data.getJob.buddyJobStatus)));

            if (triggerPDSLookup) {
                setLocalPatientData(data.getJob);
                getPDSData(data.getJob.nhsNumber);
            } else {
                clearLocalPatientData();
                clearPDSData();
            }

            setShowDetailsAmendDialog(triggerPDSLookup);
        }

        setPatient(new Patient(data?.getJob || {}));
    }, [
        clearLocalPatientData,
        clearPDSData,
        data,
        getPDSData,
        isFeatureEnabled,
        patient?.nhsNumber,
        setLocalPatientData,
    ]);

    const loading =
        isLoading || loadingWarnings || loadingPDSData || updatingJob || loadingJobAlerts;

    const onHcpReassignment = (hcpId: string | null) => {
        if ([JobStatus.CURRENT, JobStatus.ARRIVED].includes(patient?.jobStatus)) {
            openAlert({
                message: `The ${
                    isAdminTime ? 'administrative time' : 'visit'
                } status is ${patient?.jobStatus.toLowerCase()}. Are you sure you want to change it?`,
                isOpen: true,
                onConfirm: () => {
                    updateAssignedStaff(hcpId);
                    closeAlert();
                },
            });
            return;
        }
        updateAssignedStaff(hcpId);
    };

    const updateAssignedStaff = async (hcpId: string | null) => {
        const staff = users.find(({ userId }: { userId: any }) => userId === hcpId);
        const staffName = staff ? `${staff.firstName} ${staff.lastName}` : '';
        let input: APIValuesInterface = {
            id: patient.id,
            lastUpdatedBy: username,
            lastUpdatedDateTime: moment().toISOString(),
            version: patient.version + 1,
        };
        const statusNeedToBeReset = [JobStatus.CURRENT, JobStatus.ARRIVED, JobStatus.PENDING];
        if (isFirstUser || isAdminTime) {
            input.hcpId = hcpId || null;
            input.hcpName = staffName;
            if (statusNeedToBeReset.includes(patient.jobStatus)) {
                input.jobStatus = hcpId ? JobStatus.ACCEPTED : JobStatus.PENDING;
            }
        } else if (isDoubleUp && !isFirstUser) {
            input.buddyId = hcpId || null;
            input.buddyName = staffName;
            if (statusNeedToBeReset.includes(patient.buddyJobStatus as JobStatus)) {
                input.buddyJobStatus = hcpId ? JobStatus.ACCEPTED : JobStatus.PENDING;
            }
        }
        try {
            await updateJob({
                variables: { input },
            });
            refreshJobs();
        } catch (err) {
            console.error(err);
        }
    };

    const inactiveStatus = [
        JobStatus.COMPLETED,
        JobStatus.HCP_ABORTED,
        JobStatus.CONTROLLER_ABORTED,
    ];

    const isStaffMemberEditable = Boolean(
        patient?.startDateTime &&
            patient?.dateOfVisit &&
            moment().startOf('day').isSameOrBefore(moment(patient.dateOfVisit)) &&
            (isFirstUser
                ? !inactiveStatus.includes(patient?.jobStatus)
                : !inactiveStatus.includes(patient?.buddyJobStatus as JobStatus)),
    );

    return (
        <section className="visit-details">
            <Header
                patient={patient}
                refetch={refetch}
                isAdminTime={isAdminTime}
                isFirstUser={isFirstUser}
                isLoading={isLoading}
                patientAlerts={jobAlerts}
            />
            {error && <Callout intent="danger">{error.message}</Callout>}
            {loading ? (
                <Loader />
            ) : isAdminTime ? (
                <AdminDetails
                    patient={patient}
                    warnings={currentWarnings}
                    onHcpReassignment={onHcpReassignment}
                    isStaffMemberEditable={isStaffMemberEditable}
                />
            ) : (
                <VisitDetails
                    patient={patient}
                    isDoubleUp={isDoubleUp}
                    isFirstUser={isFirstUser}
                    warnings={currentWarnings}
                    patientAlerts={jobAlerts}
                    onHcpReassignment={onHcpReassignment}
                    isStaffMemberEditable={isStaffMemberEditable}
                />
            )}
            {!error && patient.nhsNumber && !!localPatientData && !!pdsData?.[patient.nhsNumber] && (
                <Formik initialValues={patient} onSubmit={() => {}}>
                    <PatientDataForm
                        insideDialog
                        nhsNumber={patient.nhsNumber}
                        hasReferralData={false}
                        setToFormPatientData={setToFormPatientData}
                        showDialog={showDetailsAmendDialog}
                        onClose={onClose}
                    />
                </Formik>
            )}
        </section>
    );
};

export default observer(PanelDetails);
