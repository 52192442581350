import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';

import Error from '../../forms/Error';

const SpecialitiesSummary = ({
    speciality,
    editOrCreateMode,
    values,
    handleChange,
    errors,
    touched,
}) => {
    return (
        <>
            <dl className="info">
                <dt className="info__title">Name *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="name">
                            <InputGroup
                                id="name"
                                name="name"
                                onChange={handleChange}
                                value={values.name}
                                large
                            />
                            {touched.name && <Error errors={[errors.name]} />}
                        </FormGroup>
                    ) : (
                        speciality.name
                    )}
                </dd>
            </dl>
        </>
    );
};

export default SpecialitiesSummary;
