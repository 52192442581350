import React, { useEffect, useState } from 'react';
import { Button, FormGroup, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import CommonPagination from '../../CommonHRRotaPagination';
import Select from 'react-select';

const recordStatusOptions = [
    { value: 'All', label: 'All' },
    { value: false, label: 'Active' },
    { value: true, label: 'Archived' },
];

const Navigation = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onSearch,
    addNew,
    editItem,
    archiveItems,
    allLocations,
    selected,
    onFilter,
}) => {
    const onSearchChange = async (inputValue) => {
        if (inputValue && inputValue.length >= 3) {
            return onSearch(inputValue);
        } else if (inputValue?.length === 0) {
            return onSearch('');
        } else {
            return () => {};
        }
    };

    const [recordStatus, setRecordStatus] = useState(0);
    const [townCityOptions, setTownCityOptions] = useState([{ label: 'All', value: 'All' }]);
    const [townCityVal, setTownCityVal] = useState(0);

    useEffect(() => {
        const townCityOptions = allLocations
            .filter((item) => item.townCity)
            .map((location) => ({ value: location.townCity, label: location.townCity }));
        setTownCityOptions([{ label: 'All', value: 'All' }, ...townCityOptions]);
    }, [allLocations]);

    useEffect(() => {
        const updateFilters = () => {
            const filterParams = {};
            if (recordStatusOptions[recordStatus].value !== 'All')
                filterParams.isArchived = recordStatusOptions[recordStatus].value;

            if (townCityOptions[townCityVal].value !== 'All')
                filterParams.townCity = townCityOptions[townCityVal].value;
            onFilter(filterParams);
        };

        updateFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recordStatus, townCityVal, onFilter]);

    const Content = () => (
        <Menu>
            <MenuItem text="Edit" disabled={selected.length !== 1} onClick={editItem} />
            <MenuItem text="Archive" disabled={!selected.length} onClick={archiveItems} />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup
                                label="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Search..."
                                    large
                                />
                            </FormGroup>
                            <FormGroup label="Record Status" className="common-header__filter">
                                <Select
                                    defaultValue={recordStatusOptions[recordStatus]}
                                    options={recordStatusOptions}
                                    onChange={(val) => {
                                        const index = recordStatusOptions.findIndex(
                                            (item) => item.value === val.value,
                                        );
                                        setRecordStatus(index);
                                    }}
                                    value={recordStatusOptions[recordStatus]}
                                />
                            </FormGroup>
                            <FormGroup label="Town/city" className="common-header__filter">
                                <Select
                                    defaultValue={townCityOptions[townCityVal]}
                                    options={townCityOptions}
                                    onChange={(val) => {
                                        const index = townCityOptions.findIndex(
                                            (item) => item.value === val.value,
                                        );
                                        setTownCityVal(index);
                                    }}
                                    value={townCityOptions[townCityVal]}
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="locations"
                        />
                    </div>
                    <div>
                        <Button
                            text="New"
                            intent="success"
                            icon="add"
                            onClick={addNew}
                            className="common-header__button"
                            outlined
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    outlined
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default Navigation;
