import React, { useState } from 'react';
import { Collapse, FormGroup, Icon } from '@blueprintjs/core';
import Select from 'react-select';
import { compareByLabels, compareByValues } from '../../../../../modules/helpers/sortFunctions';
import { SHIFT_TIME } from '../../../../../../constants/rotaConst';
import { IconFilter } from '../../../../../../helpers/ucr/icons';
import _ from 'lodash';
import { DateRangeInput } from '@blueprintjs/datetime';
import moment from 'moment';
import ShiftValueSlider from '../ShiftValueSlider';

interface BuilderFilterProps {
    clearFilter: () => void;
    handleDatesChange: (date: any) => void;
    handleSliderChange: (range: [number, number]) => void;
    handleShiftsFilter: () => void;
    handleDatesFilter: () => void;
}

const BuilderFilter = (props: BuilderFilterProps | any) => {
    const {
        deps,
        filteredShifts,
        isGridView,
        locationsFromShifts,
        payRatesFromShifts,
        rolesFromShifts,
        functionsFromShifts,
        fundingPoolsFromShifts,
        statusFromShifts,
        typesFromShifts,
        confirmedStaffsFromShifts,
        defaultStaffsFromShifts,
        daysFromShifts,
        weeksFromShifts,
        fillStatusFromShifts,
        contractTypesFromShifts,
        agenciesFromShifts,
        shiftSourceFromShifts,
        traineesFromShifts,
        shiftValueMin,
        shiftValueMax,
        shiftValueChosen,
        loading,
        handleShiftsFilter,
        handleSliderChange,
        handleDatesFilter,
        clearFilter,
        dateRange,
        filterDates,
        handleDatesChange,
    } = props;

    const [showMoreFilters, setShowMoreFilters] = useState(false);

    return (
        <>
            <div className="rota-filters">
                <div className="rota-filters__main">
                    <FormGroup label="Location" className="rota-filter">
                        <Select
                            isMulti
                            isLoading={loading}
                            value={
                                deps['locationId' as any]
                                    ? Object.values(deps['locationId' as any])
                                    : null
                            }
                            options={locationsFromShifts.sort(compareByLabels)}
                            onChange={(val: any) => {
                                handleShiftsFilter(val, 'locationId');
                            }}
                        />
                    </FormGroup>
                    <FormGroup label="Role" className="rota-filter">
                        <Select
                            isMulti
                            isLoading={loading}
                            value={
                                deps['roleId' as any] ? Object.values(deps['roleId' as any]) : null
                            }
                            options={rolesFromShifts.sort(compareByLabels)}
                            onChange={(val: any) => {
                                handleShiftsFilter(val, 'roleId');
                            }}
                        />
                    </FormGroup>
                    <FormGroup label="Function" className="rota-filter">
                        <Select
                            isMulti
                            isLoading={loading}
                            value={
                                deps['rotaShiftFunctions' as any]
                                    ? Object.values(deps['rotaShiftFunctions' as any])
                                    : null
                            }
                            options={functionsFromShifts.sort(compareByLabels)}
                            onChange={(val: any) => {
                                handleShiftsFilter(val, 'rotaShiftFunctions');
                            }}
                        />
                    </FormGroup>
                    <FormGroup label="Day of week" className="rota-filter">
                        <Select
                            isMulti
                            isLoading={loading}
                            value={
                                deps['dayOfWeek' as any]
                                    ? Object.values(deps['dayOfWeek' as any])
                                    : null
                            }
                            options={daysFromShifts.sort(compareByValues)}
                            onChange={(val: any) => {
                                handleShiftsFilter(val, 'dayOfWeek');
                            }}
                        />
                    </FormGroup>
                    <FormGroup label="AM/PM" className="rota-filter">
                        <Select
                            isMulti
                            isLoading={loading}
                            value={
                                deps['timeCover' as any]
                                    ? Object.values(deps['timeCover' as any])
                                    : null
                            }
                            options={SHIFT_TIME.sort(compareByLabels)}
                            onChange={(val: any) => {
                                handleShiftsFilter(val, 'timeCover');
                            }}
                        />
                    </FormGroup>

                    <button
                        className="rota-filters__secondary-toggle btn btn--outlined"
                        onClick={() => setShowMoreFilters(!showMoreFilters)}
                    >
                        <IconFilter />
                        {showMoreFilters ? 'Hide' : 'Show'} more filters
                    </button>

                    {!_.isEmpty(deps) || Object.keys(deps).length ? (
                        <button
                            className="rota-filters__secondary-toggle btn btn--secondary"
                            onClick={() => {
                                clearFilter();
                            }}
                        >
                            Clear filters
                        </button>
                    ) : null}
                </div>
                <div className="rota-filters__secondary-flex">
                    <Collapse keepChildrenMounted={true} isOpen={showMoreFilters}>
                        <FormGroup label="Special day" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['specialDays' as any]
                                        ? Object.values(deps['specialDays' as any])
                                        : null
                                }
                                options={[
                                    {
                                        label: 'Special day',
                                        value: true,
                                    },
                                ]}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'specialDays');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Shift type" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['typeId' as any]
                                        ? Object.values(deps['typeId' as any])
                                        : null
                                }
                                options={typesFromShifts.sort(compareByLabels)}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'typeId');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Shift status" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['statusId' as any]
                                        ? Object.values(deps['statusId' as any])
                                        : null
                                }
                                options={statusFromShifts.sort(compareByLabels)}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'statusId');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Fill status" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['fillStatus' as any]
                                        ? Object.values(deps['fillStatus' as any])
                                        : null
                                }
                                options={fillStatusFromShifts.sort(compareByLabels)}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'fillStatus');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Default staff member" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['defaultEmployeeFilter' as any]
                                        ? Object.values(deps['defaultEmployeeFilter' as any])
                                        : null
                                }
                                options={[
                                    ...defaultStaffsFromShifts.sort(compareByLabels),
                                    {
                                        value: ' ',
                                        label: 'No default staff member',
                                    },
                                ]}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'defaultEmployeeFilter');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Confirmed staff member" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['confirmedEmployeeFilter' as any]
                                        ? Object.values(deps['confirmedEmployeeFilter' as any])
                                        : null
                                }
                                options={[
                                    ...confirmedStaffsFromShifts.sort(compareByLabels),
                                    {
                                        value: ' ',
                                        label: 'No confirmed staff member',
                                    },
                                ]}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'confirmedEmployeeFilter');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Trainee" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['trainee' as any]
                                        ? Object.values(deps['trainee' as any])
                                        : null
                                }
                                options={[
                                    ...(traineesFromShifts
                                        ? traineesFromShifts?.sort(compareByLabels)
                                        : []),
                                    {
                                        value: 'no-trainee-set',
                                        label: 'No trainee set',
                                    },
                                ]}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'trainee');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Pay rate" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['payRateId' as any]
                                        ? Object.values(deps['payRateId' as any])
                                        : null
                                }
                                options={
                                    payRatesFromShifts && payRatesFromShifts.sort(compareByLabels)
                                }
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'payRateId');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Shift source" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['shiftSource' as any]
                                        ? Object.values(deps['shiftSource' as any])
                                        : null
                                }
                                options={
                                    shiftSourceFromShifts &&
                                    shiftSourceFromShifts.sort(compareByLabels)
                                }
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'shiftSource');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Contract type" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['contractType' as any]
                                        ? Object.values(deps['contractType' as any])
                                        : null
                                }
                                options={
                                    contractTypesFromShifts &&
                                    contractTypesFromShifts.sort(compareByLabels)
                                }
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'contractType');
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Agency" className="rota-filter">
                            <Select
                                isMulti
                                value={
                                    deps['agency' as any]
                                        ? Object.values(deps['agency' as any])
                                        : null
                                }
                                options={
                                    agenciesFromShifts && agenciesFromShifts.sort(compareByLabels)
                                }
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'agency');
                                }}
                            />
                        </FormGroup>
                        {!isGridView && (
                            <FormGroup label="Week number" className="rota-filter">
                                <Select
                                    isMulti
                                    value={
                                        deps['weekNumber' as any]
                                            ? Object.values(deps['weekNumber' as any])
                                            : null
                                    }
                                    options={
                                        weeksFromShifts && weeksFromShifts.sort(compareByValues)
                                    }
                                    onChange={(val: any) => {
                                        handleShiftsFilter(val, 'weekNumber');
                                    }}
                                />
                            </FormGroup>
                        )}
                        <FormGroup label="Funding Pool" className="rota-filter">
                            <Select
                                isMulti
                                isLoading={loading}
                                value={
                                    deps['fundingPoolId' as any]
                                        ? Object.values(deps['fundingPoolId' as any])
                                        : null
                                }
                                options={fundingPoolsFromShifts.sort(compareByLabels)}
                                onChange={(val: any) => {
                                    handleShiftsFilter(val, 'fundingPoolId');
                                }}
                            />
                        </FormGroup>

                        <FormGroup label="Date" className="rota-filter date-fields">
                            <DateRangeInput
                                value={filterDates}
                                formatDate={(date: any) => date.toLocaleDateString()}
                                parseDate={(str) => new Date(str)}
                                singleMonthOnly={true}
                                shortcuts={false}
                                allowSingleDayRange={true}
                                maxDate={dateRange[1]}
                                minDate={dateRange[0]}
                                onChange={async (val: any) => {
                                    await handleDatesChange(val);
                                    const valUpdated = val.map((date: any) =>
                                        !!date
                                            ? moment(date)
                                                  .add({
                                                      hours: 11,
                                                      minutes: 59,
                                                  })
                                                  .toDate()
                                            : null,
                                    );
                                    await handleDatesFilter(valUpdated);
                                }}
                            />
                        </FormGroup>
                        {!isGridView && (
                            <FormGroup label="Shift value" className="rota-filter slider">
                                <ShiftValueSlider
                                    shiftValueMin={shiftValueMin}
                                    shiftValueMax={shiftValueMax}
                                    stepSize={10}
                                    labelStepSize={shiftValueMax === 1 ? 1 : shiftValueMax / 10}
                                    onChange={handleSliderChange}
                                    activeInterval={shiftValueChosen}
                                />
                            </FormGroup>
                        )}
                    </Collapse>
                </div>
            </div>
            {!filteredShifts.length && !_.isEmpty(deps) && (
                <b className="bp5-intent-danger">
                    <Icon icon="warning-sign" intent="danger" /> No filter data !
                </b>
            )}
        </>
    );
};

export default BuilderFilter;
