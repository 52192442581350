import { FC } from 'react';
import { FormikValues } from 'formik';
import moment from 'moment';
import { Patient, JobStatus, Pathway } from '@doc-abode/data-models';
import { getGenderWarning, getLanguageWarning } from '../../../../../helpers/';

import { getFollowUpAdminTimeValues } from '../../../../../helpers/ucr/getFollowUpValues';

import useStores from '../../../../../hook/useStores';
import useVisitWarnings, { VisitValuesType } from '../../../../../hook/useVisitWarnings';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import {
    abortStatusCheck,
    FormMode,
    friendlyCareComplexity,
    getServiceDisplayName,
    FormSteps,
} from '../common';
import { dateFormat } from '../../../../../constants/patientsConst';
import {
    Accordion,
    AccordionTab,
    AccordionColors,
    Button,
    ButtonElems,
    ButtonColors,
    ButtonSizes,
} from '../../../../v2/components';
import { IconAbort, IconAdd, IconChangeStatus, IconTick } from '../../../../../helpers/ucr/icons';
import { Text } from '../../../../v2/form';
import { Dialogs, DialogAlerts } from '../../../../../stores/UCRStore';
import Warnings from '../../../../v2/form/Warnings';
import { TimeHelperActualStart, TimeHelperActualEnd } from '../../blocks/panels/TimeHelper';
import { genderMapping } from '../../../../../constants/mainConst';
import { VisitData } from './AddVisitTypes';
// to prevent circular references we go direct.
import { getAllWarnings } from '../../../../../helpers/ucr/getVisitWarnings';
import { getFollowUpValues } from '../../../../../helpers/ucr/getFollowUpValues';
import { getStringEndTime } from '../../../../../helpers/ucr/getEndDateTime';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';

interface Props {
    values: FormikValues;
    onSave?: () => Promise<void>;
    onEdit: (step: FormSteps) => void;
    patient?: Patient;
    formMode: string;
    isJobCreated: boolean;
}

interface careDetailsDoubleUpInterface {
    values: FormikValues;
    staffFiltered: string[];
    isSingleUp?: boolean;
}

interface startAndFinishedInterface {
    patient: Patient;
    isBuddy: boolean;
}

interface statusFieldInterface {
    arrivedAt?: string;
    finishedAt?: string;
    notes?: string;
    completed: boolean;
    hideNotes?: boolean;
    staffMember: string;
    status: string;
    memberName: string;
    aborted: boolean;
    isBuddy: boolean;
    values: FormikValues;
    codAborted: boolean;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode, patient, isJobCreated }) => {
    const {
        RootStore: {
            usersStore: { users, hcpUsers },
            configStore: { pathways },
            ucrStore: {
                focusedJobId,
                warnings,
                openedDialogAlert,
                setOpenedDialogAlert,
                setOpenedDialog,
                setAbortVisit,
                setFollowUpVisitData,
                setFollowUpAdminTimeData,
                setLocalPatientData,
            },
        },
    } = useStores() as { RootStore: any };

    const { isDoubleUp, isDisabled } = usePatientFlagsToChangeStatus(patient);
    const isSaveEnabled =
        formMode === FormMode.DEFAULT ||
        formMode === FormMode.ADD_VISIT ||
        formMode === FormMode.FOLLOW_UP;

    if (openedDialogAlert !== DialogAlerts.SAVE_VISIT && isSaveEnabled) {
        setOpenedDialogAlert(DialogAlerts.SAVE_VISIT);
    }

    const visitWarnings = useVisitWarnings(values as VisitValuesType) || [];

    const staffOne = users
        .filter((user: any) => [values.hcpId].includes(user.userId))
        .map((user: any, index: number) => {
            return `${user.userName}`;
        });
    const staffTwo = users
        .filter((user: any) => [values.buddyId].includes(user.userId))
        .map((user: any, index: number) => {
            return `${user.userName}`;
        });

    const staffFiltered =
        staffOne.length > 0
            ? staffTwo.length > 0
                ? [...staffOne, ...staffTwo]
                : [...staffOne]
            : ['', ...staffTwo];

    const allStepWarnings = getAllWarnings(values);

    const abortedStatuses = [JobStatus.CONTROLLER_ABORTED, JobStatus.HCP_ABORTED];
    const isAborted = isDoubleUp
        ? abortedStatuses.includes(patient?.jobStatus || JobStatus.PENDING) &&
          abortedStatuses.includes(patient?.buddyJobStatus || JobStatus.PENDING)
        : abortedStatuses.includes(patient?.jobStatus || JobStatus.PENDING);

    const isCompleted = patient?.jobStatus === JobStatus.COMPLETED;
    const isHCPAborted = values?.jobStatus === JobStatus.HCP_ABORTED;
    const isBuddyAborted = values?.buddyJobStatus === JobStatus.HCP_ABORTED;
    const isControllerAborted = values?.jobStatus === JobStatus.CONTROLLER_ABORTED;
    const isBuddyControllerAborted = values?.buddyJobStatus === JobStatus.CONTROLLER_ABORTED;
    const isAbortedMustShow =
        (isHCPAborted || isControllerAborted || isBuddyAborted || isBuddyControllerAborted) &&
        (values.arrivedDateTime || values.buddyArrivedDateTime);
    const onCancelVisit = () => {
        if (patient) {
            setAbortVisit(patient);
        }
    };

    const hcp = hcpUsers.find((hcp: any) => hcp.userId === values?.hcpId);
    const secondHcp = hcpUsers.find((hcp: any) => hcp.userId === values?.buddyId);

    const firstGenderWarnings = hcp ? getGenderWarning(hcp, values.staffPreferredGender) : [];
    const secondGenderWarnings = secondHcp
        ? getGenderWarning(secondHcp, values.staffPreferredGender)
        : [];
    const genderWarnings = [...firstGenderWarnings, ...secondGenderWarnings];
    const firstHcpLanguageWarning = hcp ? getLanguageWarning(hcp, values.languagesSpoken) : [];
    const secondHcpLanguageWarning = secondHcp
        ? getLanguageWarning(secondHcp, values.languagesSpoken)
        : [];
    const hcpLanguageWarnings = [...firstHcpLanguageWarning, ...secondHcpLanguageWarning];

    return (
        <>
            <Accordion>
                <AccordionTab
                    name="patients"
                    title="Patient details"
                    onEdit={!isAborted ? () => onEdit(FormSteps.PATIENT) : undefined}
                    color={AccordionColors.PINK}
                    open
                >
                    <PatientDetails values={values} />
                </AccordionTab>
                <AccordionTab
                    name="referral"
                    title="Referral details"
                    onEdit={!isAborted ? () => onEdit(FormSteps.REFERRAL) : undefined}
                    color={AccordionColors.PINK}
                    open
                >
                    <ReferralDetails values={values} />
                </AccordionTab>
                <AccordionTab
                    name="care"
                    title="Care details"
                    onEdit={
                        !isAborted || isAbortedMustShow ? () => onEdit(FormSteps.CARE) : undefined
                    }
                    color={AccordionColors.PINK}
                    open
                >
                    <CareDetails
                        values={values}
                        staffFiltered={staffFiltered}
                        pathways={pathways}
                    />
                </AccordionTab>
                {!abortStatusCheck(values?.jobStatus) && (
                    <Warnings
                        warnings={
                            focusedJobId === values.id ? warnings[focusedJobId] : visitWarnings
                        }
                        stepWarnings={allStepWarnings.concat([
                            ...genderWarnings,
                            ...hcpLanguageWarnings,
                        ])}
                    />
                )}
                <div className="v2__form-btn-section v2__form-btn-section--mb">
                    {(formMode === FormMode.DEFAULT ||
                        formMode === FormMode.ADD_VISIT ||
                        formMode === FormMode.FOLLOW_UP) && (
                        <Button
                            className="v2__form-submit-button"
                            elem={ButtonElems.DIV}
                            name="Save visit"
                            Icon={IconTick}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => {
                                if (onSave) onSave();
                                setLocalPatientData();
                            }}
                        />
                    )}
                    {patient?.jobStatus && (
                        <Button
                            className="v2__form-submit-button"
                            name="Create follow-up visit"
                            Icon={IconAdd}
                            clickEvent={() => {
                                const visitValues: VisitData = getFollowUpValues(values);
                                setFollowUpVisitData(visitValues);
                                setOpenedDialog(Dialogs.ADD_NEW_VISIT);
                            }}
                        />
                    )}

                    {patient?.jobStatus && (
                        <Button
                            className="v2__form-submit-button"
                            name="Create admin task"
                            Icon={IconAdd}
                            clickEvent={() => {
                                const visitValues: Partial<VisitData> = getFollowUpAdminTimeValues(
                                    values,
                                );
                                setFollowUpAdminTimeData(visitValues);
                                setOpenedDialog(Dialogs.ADMINISTRATIVE_TIME);
                            }}
                        />
                    )}
                    {isDisabled ? (
                        <Button
                            className="v2__form-submit-button"
                            size={ButtonSizes.MEDIUM}
                            name="Change status"
                            Icon={IconChangeStatus}
                            clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                        />
                    ) : null}
                    {!isAborted && !isCompleted && isJobCreated && formMode !== 'default' && (
                        <Button
                            className="v2__form-submit-button"
                            size={ButtonSizes.MEDIUM}
                            elem={ButtonElems.BUTTON}
                            color={ButtonColors.RED}
                            name="Abort visit"
                            Icon={IconAbort}
                            clickEvent={onCancelVisit}
                        />
                    )}
                </div>
            </Accordion>
        </>
    );
};

export default ReviewForm;

function PatientDetails({ values }: { values: FormikValues }) {
    const allContactNumbers = values.contactNumber
        ? [values.contactNumber, ...(values.additionalContactNumbers || [])]
        : [];

    let genderPreferredMapping = values.staffPreferredGender ? values.staffPreferredGender : [];
    genderPreferredMapping = genderPreferredMapping.map((index: any) => {
        if (index) {
            return genderMapping[index];
        }
        return '';
    });

    return (
        <div className="v2__form-section">
            <Text name="nhsNumber" title="NHS number" description={values.nhsNumber} />
            <Text
                name="name"
                title="Name"
                description={`${values.lastName}, ${values.firstName} ${values.middleName || ''}`}
            />
            <Text
                name="contactNumber"
                title="Phone number(s)"
                description={
                    allContactNumbers?.map((number: string) => number && `+${number}`) || 'Not set'
                }
            />
            <Text
                name="dateOfBirth"
                title="Date of birth"
                description={moment(values.dateOfBirth).format(dateFormat)}
            />
            <Text name="Gender" title="Gender" description={genderMapping[values.gender]} />
            <Text name="addressLine1" title="Address line 1" description={values.addressLine1} />
            <Text name="addressLine2" title="Address line 2" description={values.addressLine2} />
            <Text name="addressLine3" title="Address line 3" description={values.addressLine3} />
            <Text name="town" title="Town" description={values.town} />
            <Text name="postCode" title="Post code" description={values.postCode} />
            <Text
                name="languagesSpoken"
                title="Spoken language"
                description={values.languagesSpoken}
            />
            <Text
                name="staffPreferredGender"
                title="Gender of visiting staff"
                description={genderPreferredMapping}
            />
        </div>
    );
}

function ReferralDetails({ values }: { values: FormikValues }) {
    return (
        <div className="v2__form-section">
            <Text
                name="systmOneRef"
                title="Referral reference number"
                description={values.systmOneRef}
            />
            <Text name="referredBy" title="Referred by" description={values.referredBy} />
            <Text name="referrer" title="Referrer" description={values.referrer} />
            <Text
                name="referralDateTime"
                title="Referral date"
                description={
                    values.referralDateTime
                        ? moment(values.referralDateTime).format('DD/MM/YYYY')
                        : values.referralDateTime
                }
            />
            <Text
                name="referralDateTime"
                title="Referral time"
                description={
                    values.referralDateTime
                        ? moment(values.referralDateTime).format('hh:mm a')
                        : values.referralDateTime
                }
            />
        </div>
    );
}

const StartAndFinishedDates: FC<startAndFinishedInterface> = ({
    patient,
    isBuddy,
}): JSX.Element => {
    return (
        <>
            <Text
                name="arrivedDateTime"
                title="Actual start time"
                notSetText="Not yet recorded"
                description={TimeHelperActualStart({ patient: patient, isFirstUser: !isBuddy })}
            />
            <Text
                name="finishedDateTime"
                title="Actual end time"
                notSetText="Not yet recorded"
                description={TimeHelperActualEnd({ patient: patient, isFirstUser: !isBuddy })}
            />{' '}
        </>
    );
};

const StatusFields: FC<statusFieldInterface> = ({
    arrivedAt,
    finishedAt,
    staffMember,
    status,
    memberName,
    aborted,
    isBuddy,
    values,
    codAborted,
}): JSX.Element => {
    const patient: Patient = values as Patient;

    return (
        <>
            <Text
                name="staffMember"
                title={`Staff member ${staffMember}`}
                notSetText="Not yet recorded"
                description={memberName}
            />
            <Text
                name="Status"
                title={`Status`}
                notSetText="Not yet recorded"
                description={status}
            />
            <StartAndFinishedDates patient={patient} isBuddy={isBuddy} />

            {isBuddy && aborted && (
                <>
                    <Text
                        name="buddyAborted"
                        title="Aborted reason (HCP)"
                        description={values.buddyHcpAbortedReason}
                    />
                    <Text
                        name="buddyAbortedNotes"
                        title="Aborted Notes (HCP)"
                        description={values.buddyHcpAbortedNotes}
                    />
                </>
            )}

            {isBuddy && codAborted && (
                <>
                    <Text
                        name="buddyAborted"
                        title="Aborted reason (COD)"
                        description={values.buddyControllerAbortedReason}
                    />
                    <Text
                        name="buddyAborted"
                        title="Aborted Notes (COD)"
                        description={values.buddyControllerAbortedNotes}
                    />
                </>
            )}

            {!isBuddy && aborted && (
                <>
                    <Text
                        name="aborted"
                        title="Aborted reason (HCP)"
                        description={values.hcpAbortedReason}
                    />
                    <Text
                        name="abortedNotes"
                        title="Aborted Notes (HCP)"
                        description={values.hcpAbortedNotes}
                    />
                </>
            )}
            {!isBuddy && codAborted && (
                <>
                    <Text
                        name="abortedCod"
                        title="Aborted reason (COD)"
                        description={values.controllerAbortedReason}
                    />
                    <Text
                        name="abortedCodNotes"
                        title="Aborted Notes (COD)"
                        description={values.controllerAbortedNotes}
                    />
                </>
            )}
        </>
    );
};

const CareDetailsDoubleUp: FC<careDetailsDoubleUpInterface> = ({
    values,
    staffFiltered,
    isSingleUp,
}): JSX.Element => {
    const statusOfJobHcp1 = values?.jobStatus as JobStatus | undefined;
    const statusOfJobHcp2 = values?.buddyJobStatus as JobStatus | undefined;

    const isCompleted = values?.jobStatus === JobStatus.COMPLETED;
    const isBuddyCompleted = values?.buddyJobStatus === JobStatus.COMPLETED;
    const isAborted = values?.jobStatus === JobStatus.HCP_ABORTED;
    const isBuddyAborted = values?.buddyJobStatus === JobStatus.HCP_ABORTED;
    const isControllerAborted = values?.jobStatus === JobStatus.CONTROLLER_ABORTED;
    const isBuddyControllerAborted = values?.buddyJobStatus === JobStatus.CONTROLLER_ABORTED;
    const friendlyStatus = Patient.getFriendlyVisitStatus(values.jobStatus);
    const buddyStatus = Patient.getFriendlyVisitStatus(values.buddyJobStatus);

    const showHcp1PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp1 });
    const showHcp2PostNotes = shouldShowPostJobNotes({ jobStatus: statusOfJobHcp2 });

    return (
        <div className="v2__form-block v2__form-block--no-padding v2__form-block--spacer-top">
            <div className={isSingleUp ? 'v2__form-group--pos-1-1' : 'v2__form-group--pos-1-2'}>
                <StatusFields
                    completed={isCompleted}
                    status={friendlyStatus}
                    staffMember={'#1'}
                    arrivedAt={values.arrivedDateTime}
                    finishedAt={values.finishedDateTime}
                    memberName={staffFiltered[0]}
                    aborted={isAborted}
                    codAborted={isControllerAborted}
                    isBuddy={false}
                    values={values}
                />
                {showHcp1PostNotes && (
                    <Text
                        name="postVisitNotes"
                        title={isSingleUp ? 'Post-job notes' : 'Post-job notes #1'}
                        className="visit-details__notes"
                        description={values.postVisitNotes}
                    />
                )}
            </div>
            {!isSingleUp && (
                <div className="v2__form-group--pos-2-2">
                    <StatusFields
                        completed={isBuddyCompleted}
                        status={buddyStatus}
                        staffMember={'#2'}
                        arrivedAt={values.buddyArrivedDateTime}
                        finishedAt={values.buddyFinishedDateTime}
                        memberName={staffFiltered[1]}
                        isBuddy={true}
                        aborted={isBuddyAborted}
                        codAborted={isBuddyControllerAborted}
                        values={values}
                    />
                    {showHcp2PostNotes && (
                        <Text
                            name="postVisitNotesBuddy"
                            title="Post-job notes #2"
                            className="visit-details__notes"
                            description={values.postVisitNotesBuddy}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

function CareDetails({
    values,
    staffFiltered,
    pathways,
}: {
    values: FormikValues;
    staffFiltered: string[];
    pathways: Pathway[];
}) {
    const pathway = pathways.find((pathway) => pathway.value === values.referralPathway);

    return (
        <div className="v2__form-section">
            <Text name="referralPathway" title="Pathway" description={pathway?.label || ''} />

            <Text
                name="Service required"
                title="Service required"
                description={getServiceDisplayName(pathway?.services, values.disposition)}
            />
            <Text
                name="careComplexity"
                title="Care complexity"
                description={
                    values.careComplexity ? friendlyCareComplexity[values.careComplexity] : ''
                }
            />
            <Text name="staffRequired" title="Staffing requirements" description={staffFiltered} />
            <Text
                name="carRequired"
                title="Car required"
                description={values.carRequired ? 'Yes' : 'No'}
            />
            <Text
                name="earliestDateOfVisit"
                title="Earliest date of visit"
                description={
                    values.earliestDateOfVisit
                        ? moment(values.earliestDateOfVisit).format('dddd Do MMMM YYYY')
                        : ''
                }
            />
            <Text
                name="availableFrom"
                title="Earliest time of visit"
                description={
                    values.availableFrom ? moment(values.availableFrom).format('h:mm a') : ''
                }
            />
            <Text
                name="availableTo"
                title="Latest time of visit"
                description={values.availableTo ? moment(values.availableTo).format('h:mm a') : ''}
            />

            <Text
                name="visitDate"
                title="Planned date of visit"
                description={moment(values.visitDate).format('dddd Do MMMM YYYY')}
            />
            <Text
                name="startTime"
                title="Planned start time"
                description={values.startTime ? moment(values.startTime).format('h:mm a') : ''}
            />
            <Text name="duration" title="Planned duration" description={values.duration} />
            <Text
                name="endTime"
                title="Expected end time"
                description={
                    values.startTime
                        ? getStringEndTime(values.startTime, values.duration, 'hh:mm a')
                        : ''
                }
            />

            <Text
                name="notes"
                title="Pre-job notes"
                className="visit-details__notes"
                description={values.notes}
            />

            <CareDetailsDoubleUp
                isSingleUp={values.staffRequired !== 2}
                values={values}
                staffFiltered={staffFiltered}
            />
        </div>
    );
}
