import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Colors, Icon, Checkbox, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { v4 as uuid_v4 } from 'uuid';
import { DAYS } from '../../../../../constants/rotaConst';
import _ from 'lodash';
import { formatTime } from '../../../helpers/FormatTime';
import { numToTime } from '../../../../pages/admin/Rota/Builder/helper';
import TableRowActions from '../../../../common/TableRowActions';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';

const ShiftPatternListView = inject('RootStore')(
    observer(
        class ShiftPatternWeeks extends Component {
            state = {
                sortTarget: '',
                sortDir: '',
                showAlert: false,
                selectedListViewShifts: [],
                allItemsSelected: false,
            };

            async componentDidMount() {
                Object.keys(this.props.RootStore.shiftPatternsStore.newShiftPattern).length === 0 &&
                    this.props.RootStore.shiftPatternsStore.initializeShiftPattern();
                await this.props.RootStore.staffStore.getAllStaffs();
            }

            componentDidUpdate(prevProps, prevState) {
                // update filter options on change of shifts (adding, deleting)
                this.props.generateFilterOptions();
                if (prevState.selectedListViewShifts !== this.state.selectedListViewShifts) {
                    const { filteredShifts } = this.props.RootStore.shiftPatternsStore;
                    const { selectedListViewShifts } = this.state;
                    if (!filteredShifts.length) return;
                    const allChecked = filteredShifts.every((item) =>
                        selectedListViewShifts.includes(item.id),
                    );
                    this.setState({ allItemsSelected: allChecked });
                }
            }

            _modifyDefaultEntries = () => {
                this.props.RootStore.shiftPatternsStore.orderFilteredShiftsByDefault();
            };

            _handleAddShift = () => {
                this.props.RootStore.shiftPatternsStore.addNewShiftPatternEntry({
                    weekNumber: null,
                    dayOfWeek: null,
                    newShiftId: uuid_v4(),
                });
                this.props.RootStore.shiftPatternsStore.updateEntryActionType('create');
                this.props.RootStore.shiftPatternsStore.showShiftEntryModal = true;
                this.props.RootStore.shiftPatternsStore.isCreatePath = false;
            };

            _handleShiftClick = (shiftItem) => {
                if (shiftItem) {
                    if (this.props.RootStore.shiftPatternsStore.entryActionType === 'create') {
                        this.props.RootStore.shiftPatternsStore.isCreatePath = true;
                    }
                    this.props.RootStore.shiftPatternsStore.updateShiftEntry(shiftItem);
                    this.props.RootStore.shiftPatternsStore.showShiftEntryModal = true;
                }
            };

            _handleDeleteClick = (id) => {
                const filteredShifts = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => (obj.id ? obj.id !== id : obj.newShiftId !== id),
                );
                this.props.RootStore.shiftPatternsStore.modifyNewShiftPatternEntries(
                    filteredShifts,
                );
            };
            _getEntriesSortingUpdated = (val) =>
                val.map((entry) => {
                    const staffList = this.props.RootStore.staffStore.allStaffs.reduce(
                        (acc, item) => {
                            acc[item.id] = item.person.firstName + ' ' + item.person.lastName;
                            return acc;
                        },
                        {},
                    );

                    const shiftDuration = new Date(
                        entry.endTime.getTime() - entry.startTime.getTime(),
                    )
                        .toISOString()
                        .substring(11, 16);
                    const netDuration = entry.breakIsPaid
                        ? new Date(entry.endTime.getTime() - entry.startTime.getTime())
                              .toISOString()
                              .substring(11, 16)
                        : new Date(
                              entry.endTime.getTime() -
                                  entry.startTime.getTime() -
                                  1000 * (60 * entry.breakDurationMinutes),
                          )
                              .toISOString()
                              .substring(11, 16);

                    return {
                        ...entry,
                        sortWeek: entry.weekNumber,
                        sortDay: DAYS[entry.dayOfWeek - 1].label,
                        sortRole: entry.shiftEmployeeRoleTypeId
                            ? this.props.RootStore.dictionaryStore.rolesDictionary[
                                  entry.shiftEmployeeRoleTypeId
                              ]
                            : null,
                        sortLocation: entry.locationId
                            ? this.props.RootStore.dictionaryStore.locationsDictionary[
                                  entry.locationId
                              ]
                            : null,
                        sortStaff: staffList[entry.defaultEmployeeId],
                        sortTrainee: entry.trainees[0]?.traineeId
                            ? staffList[entry.trainees[0]?.traineeId]
                            : null,
                        sortShiftType: this.props.RootStore.dictionaryStore.shiftTypesDictionary[
                            entry.typeId
                        ],
                        sortShiftStatus: this.props.RootStore.dictionaryStore
                            .shiftStatusesDictionary[entry.statusId],
                        sortStartTime: formatTime(entry.startTime),
                        sortEndTime: formatTime(entry.endTime),
                        sortShiftDuration: shiftDuration,
                        sortBreakDuration: entry.breakDurationMinutes
                            ? entry.breakDurationMinutes
                            : null,
                        sortBreakIsPaid: entry.breakIsPaid ? 1 : 0,
                        sortPaidBreakDuration:
                            entry.breakIsPaid && entry.breakDurationMinutes > 0
                                ? numToTime(entry.breakDurationMinutes)
                                : null,
                        sortNet: netDuration,
                        sortPayRate: this.props.RootStore.dictionaryStore.payRatesDictionary[
                            entry.payRateId
                        ],
                        sortSource: entry.isAdditional ? 1 : 0,
                        sortContractType: entry.defaultEmployee?.contractType?.name
                            ? entry.defaultEmployee?.contractType?.name
                            : null,
                        sortAgency: entry.defaultEmployee?.agency?.name
                            ? entry.defaultEmployee?.agency?.name
                            : null,
                    };
                });
            _onTableSort = (val = '') => {
                if (val !== this.state.sortTarget) {
                    this.props.RootStore.shiftPatternsStore.modifyFilterEntries(
                        _.orderBy(
                            this._getEntriesSortingUpdated(
                                this.props.RootStore.shiftPatternsStore.filteredShifts,
                            ),
                            val,
                            'asc',
                        ),
                    );
                    this.setState({
                        sortDir: 'desc',
                    });
                } else {
                    this.props.RootStore.shiftPatternsStore.modifyFilterEntries(
                        _.orderBy(
                            this._getEntriesSortingUpdated(
                                this.props.RootStore.shiftPatternsStore.filteredShifts,
                            ),
                            val,
                            this.state.sortDir,
                        ),
                    );
                }
                if (val === this.state.sortTarget && this.state.sortDir === '') {
                    !_.isEmpty(this.props.RootStore.shiftPatternsStore.filteredDeps)
                        ? this.props.RootStore.shiftPatternsStore.modifyFilterEntries(
                              this.props.RootStore.shiftPatternsStore.filteredShifts,
                          )
                        : this._modifyDefaultEntries();

                    this.setState({
                        sortDir: 'asc',
                    });
                } else if (this.state.sortDir === 'asc') {
                    this.setState({
                        sortDir: 'desc',
                    });
                } else if (val === this.state.sortTarget && this.state.sortDir === 'desc') {
                    this.setState({
                        sortDir: '',
                    });
                }
            };

            _handleToggleCheckbox = (event) => {
                if (event.target.checked) {
                    this.setState((prevState) => {
                        return {
                            selectedListViewShifts: [
                                ...prevState.selectedListViewShifts,
                                event.target.id,
                            ],
                        };
                    });
                } else {
                    this.setState((prevState) => {
                        return {
                            selectedListViewShifts: prevState.selectedListViewShifts.filter(
                                (item) => item !== event.target.id,
                            ),
                        };
                    });
                }
            };

            _handleToggleSelectAll = (event) => {
                const { filteredShifts } = this.props.RootStore.shiftPatternsStore;
                if (event.target.checked) {
                    const shiftIds = filteredShifts.map((entry) => entry.id);
                    this.setState({ selectedListViewShifts: shiftIds, allItemsSelected: true });
                } else {
                    this.setState({ selectedListViewShifts: [], allItemsSelected: false });
                }
            };

            _handleToggleAlert = () => {
                this.state.selectedListViewShifts.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _handleDeleteItem = () => {
                const { selectedListViewShifts } = this.state;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert, allItemsSelected: false };
                });
                let shifts = [];
                selectedListViewShifts.forEach((selectedItem) => {
                    const shiftsToRemove = this.props.RootStore.shiftPatternsStore.filteredShifts.find(
                        (r) => r.id === selectedItem,
                    );
                    shifts.push(shiftsToRemove);
                });
                shifts.map(
                    (item) =>
                        item &&
                        this.props.RootStore.shiftPatternsStore.deleteNewShiftPatternEntry(item),
                );
            };

            render() {
                const { editOrCreateMode, shiftPatternsStore } = this.props;
                const { functionsStore, fundingPoolStore } = this.props.RootStore;
                const { selectedListViewShifts, allItemsSelected } = this.state;

                const staffList = this.props.RootStore.staffStore.allStaffs.reduce((acc, item) => {
                    acc[item.id] =
                        item.employeeStatus?.name.match('Suspended') ||
                        item.employeeStatus?.name.match('Archived')
                            ? item.person.firstName +
                              ' ' +
                              item.person.lastName +
                              ' (' +
                              item.employeeStatus?.name +
                              ')'
                            : item.person.firstName + ' ' + item.person.lastName;
                    return acc;
                }, {});

                const functionsDictionary = {};
                functionsStore.allFunctions.forEach((func) => {
                    functionsDictionary[func.id] = func.name;
                });

                const fundingPoolsDictionary = {};
                fundingPoolStore.allFundingPools.forEach((fundPool) => {
                    fundingPoolsDictionary[fundPool.id] = fundPool.name;
                });

                const availableStore =
                    shiftPatternsStore &&
                    shiftPatternsStore.newShiftPattern.shiftPatternEntries.length > 0;

                const Content = () => (
                    <Menu>
                        <MenuItem
                            disabled={!selectedListViewShifts.length}
                            className="bp5-intent-danger"
                            onClick={this._handleToggleAlert}
                            text="Delete shift"
                        />
                    </Menu>
                );

                return (
                    <div className="rota-builder-lv-wrapper">
                        <br />
                        {editOrCreateMode && (
                            <div>
                                <Button
                                    icon="add"
                                    outlined={true}
                                    intent={'success'}
                                    onClick={this._handleAddShift}
                                    className="rota-builder-lv-add-shift shift-pattern-list-view-add-new-shift"
                                >
                                    New shift
                                </Button>
                                <Popover
                                    enforceFocus={false}
                                    placement="bottom-start"
                                    interactionKind="click"
                                    content={<Content shouldDismissPopover={false} />}
                                    renderTarget={({ isOpen, ref, ...p }) => (
                                        <Button
                                            {...p}
                                            active={isOpen}
                                            elementRef={ref}
                                            intent="primary"
                                            rightIcon="chevron-down"
                                            text="Actions"
                                            className="bp5-outlined"
                                        />
                                    )}
                                />
                            </div>
                        )}

                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view">
                            <thead>
                                <tr
                                    onClick={(e) => {
                                        const dataSet = e.target.offsetParent.dataset.sort;
                                        if (dataSet) {
                                            this.setState({
                                                sortTarget: dataSet,
                                            });
                                            this._onTableSort(dataSet);
                                        }
                                    }}
                                >
                                    {editOrCreateMode && (
                                        <th>
                                            <Checkbox
                                                checked={allItemsSelected}
                                                className="shift-pattern-select-all-checkbox"
                                                onChange={this._handleToggleSelectAll}
                                                onClick={(event) => event.stopPropagation()}
                                            />
                                        </th>
                                    )}
                                    <th data-sort={'sortWeek'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Week number
                                        </Button>
                                    </th>
                                    <th data-sort={'sortDay'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Day
                                        </Button>
                                    </th>
                                    <th data-sort={'sortRole'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Role
                                        </Button>
                                    </th>
                                    <th data-sort={'sortFunction'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Function
                                        </Button>
                                    </th>
                                    <th data-sort={'sortLocation'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Location
                                        </Button>
                                    </th>
                                    <th data-sort={'sortStaff'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Default staff member
                                        </Button>
                                    </th>
                                    <th data-sort={'sortTrainee'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Trainee
                                        </Button>
                                    </th>
                                    <th data-sort={'sortShiftType'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Shift type
                                        </Button>
                                    </th>
                                    <th data-sort={'sortStartTime'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Start time
                                        </Button>
                                    </th>
                                    <th data-sort={'sortEndTime'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            End time
                                        </Button>
                                    </th>
                                    <th data-sort={'sortShiftDuration'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Shift duration
                                        </Button>
                                    </th>
                                    <th data-sort={'sortBreakDuration'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Break duration
                                        </Button>
                                    </th>
                                    <th data-sort={'sortBreakIsPaid'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Break is paid
                                        </Button>
                                    </th>
                                    <th
                                        data-sort={'sortPaidBreakDuration'}
                                        className="sorted-header"
                                    >
                                        <Button minimal fill alignText="left">
                                            Paid break duration
                                        </Button>
                                    </th>
                                    <th data-sort={'sortNetDuration'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Net duration
                                        </Button>
                                    </th>
                                    <th data-sort={'sortPayRate'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Pay rate name
                                        </Button>
                                    </th>
                                    <th data-sort={'sortContractType'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Contract type
                                        </Button>
                                    </th>
                                    <th data-sort={'sortAgency'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Agency
                                        </Button>
                                    </th>
                                    <th data-sort={'sortFundingPool'} className="sorted-header">
                                        <Button minimal fill alignText="left">
                                            Funding Pool
                                        </Button>
                                    </th>
                                    <th>Conflicts</th>
                                    <th>Comments</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <>
                                    {availableStore &&
                                        shiftPatternsStore.filteredShifts.map((shift, index) => {
                                            const netDuration = shift.breakIsPaid
                                                ? new Date(
                                                      shift.endTime.getTime() -
                                                          shift.startTime.getTime(),
                                                  )
                                                      .toISOString()
                                                      .substring(11, 16)
                                                : new Date(
                                                      shift.endTime.getTime() -
                                                          shift.startTime.getTime() -
                                                          1000 * (60 * shift.breakDurationMinutes),
                                                  )
                                                      .toISOString()
                                                      .substring(11, 16);

                                            const isSupervisorStaff = this.props.RootStore.staffStore.allStaffs.filter(
                                                (staff) =>
                                                    staff.isSupervisor &&
                                                    staff.id === shift.defaultEmployeeId,
                                            ).length;

                                            return (
                                                <>
                                                    {
                                                        <tr className="common-table__row ">
                                                            {editOrCreateMode && (
                                                                <td>
                                                                    <Checkbox
                                                                        id={shift.id}
                                                                        checked={selectedListViewShifts.includes(
                                                                            shift.id,
                                                                        )}
                                                                        onChange={
                                                                            this
                                                                                ._handleToggleCheckbox
                                                                        }
                                                                        onClick={(event) =>
                                                                            event.stopPropagation()
                                                                        }
                                                                    />
                                                                </td>
                                                            )}
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.weekNumber}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {
                                                                        DAYS[shift.dayOfWeek - 1]
                                                                            .label
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.shiftEmployeeRoleTypeId
                                                                        ? this.props.RootStore
                                                                              .dictionaryStore
                                                                              .rolesDictionary[
                                                                              shift
                                                                                  .shiftEmployeeRoleTypeId
                                                                          ]
                                                                        : null}
                                                                </span>
                                                            </td>
                                                            <td
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.shiftFunctions
                                                                        ? shift.shiftFunctions.map(
                                                                              (id, index) =>
                                                                                  index === 0
                                                                                      ? functionsDictionary[
                                                                                            id
                                                                                        ]
                                                                                      : `, ${functionsDictionary[id]}`,
                                                                          )
                                                                        : null}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.locationId
                                                                        ? this.props.RootStore
                                                                              .dictionaryStore
                                                                              .locationsDictionary[
                                                                              shift.locationId
                                                                          ]
                                                                        : null}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            staffList[
                                                                                shift
                                                                                    .defaultEmployeeId
                                                                            ]?.match('Suspended') ||
                                                                            staffList[
                                                                                shift
                                                                                    .defaultEmployeeId
                                                                            ]?.match('Archived')
                                                                                ? Colors.RED3
                                                                                : ''
                                                                        }`,
                                                                    }}
                                                                    className="weeks-table__col-content-title"
                                                                >
                                                                    {
                                                                        staffList[
                                                                            shift.defaultEmployeeId
                                                                        ]
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        color: `${
                                                                            staffList[
                                                                                shift.trainees[0]
                                                                                    ?.traineeId
                                                                            ]?.match('Suspended') ||
                                                                            staffList[
                                                                                shift.trainees[0]
                                                                                    ?.traineeId
                                                                            ]?.match('Archived')
                                                                                ? Colors.RED3
                                                                                : ''
                                                                        }`,
                                                                    }}
                                                                    className="weeks-table__col-content-title"
                                                                >
                                                                    {shift.trainingShift && (
                                                                        <>
                                                                            <Icon
                                                                                icon="learning"
                                                                                intent={
                                                                                    isSupervisorStaff
                                                                                        ? 'none'
                                                                                        : 'danger'
                                                                                }
                                                                            />
                                                                            {!_.values(
                                                                                shift.trainees[0],
                                                                            ).every(_.isEmpty)
                                                                                ? '   ' +
                                                                                  staffList[
                                                                                      shift
                                                                                          .trainees[0]
                                                                                          ?.traineeId
                                                                                  ]
                                                                                : 'No trainee set'}
                                                                        </>
                                                                    )}
                                                                </span>
                                                            </td>

                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {
                                                                        this.props.RootStore
                                                                            .dictionaryStore
                                                                            .shiftTypesDictionary[
                                                                            shift.typeId
                                                                        ]
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {formatTime(
                                                                        new Date(shift.startTime),
                                                                    )}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {formatTime(
                                                                        new Date(shift.endTime),
                                                                    )}
                                                                </span>
                                                            </td>

                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {new Date(
                                                                        shift.endTime.getTime() -
                                                                            shift.startTime.getTime(),
                                                                    )
                                                                        .toISOString()
                                                                        .substring(11, 16)}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.breakDurationMinutes >
                                                                        0 &&
                                                                        numToTime(
                                                                            shift.breakDurationMinutes,
                                                                        )}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.breakIsPaid
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </span>
                                                            </td>

                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.breakIsPaid &&
                                                                        shift.breakDurationMinutes >
                                                                            0 &&
                                                                        numToTime(
                                                                            shift.breakDurationMinutes,
                                                                        )}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {netDuration}
                                                                </span>
                                                            </td>

                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {
                                                                        this.props.RootStore
                                                                            .dictionaryStore
                                                                            .payRatesDictionary[
                                                                            shift.payRateId
                                                                        ]
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.defaultEmployee
                                                                        ?.contractType?.name
                                                                        ? shift.defaultEmployee
                                                                              ?.contractType?.name
                                                                        : ''}
                                                                </span>
                                                            </td>
                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.defaultEmployee?.agency
                                                                        ?.name
                                                                        ? shift.defaultEmployee
                                                                              ?.agency?.name
                                                                        : ''}
                                                                </span>
                                                            </td>

                                                            <td
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                <span className="weeks-table__col-content-title">
                                                                    {shift.fundingPoolId
                                                                        ? fundingPoolsDictionary[
                                                                              shift.fundingPoolId
                                                                          ]
                                                                        : null}
                                                                </span>
                                                            </td>

                                                            <td
                                                                className=""
                                                                onClick={(event) =>
                                                                    this._handleShiftClick(
                                                                        shift,
                                                                        event,
                                                                    )
                                                                }
                                                            >
                                                                {shift.conflicts &&
                                                                    shift.conflicts.length > 0 && (
                                                                        <span className="weeks-table__col-content-title">
                                                                            <Icon
                                                                                icon="warning-sign"
                                                                                intent="danger"
                                                                            />
                                                                            Conflict
                                                                        </span>
                                                                    )}
                                                            </td>

                                                            <td className="">
                                                                {shift.comments?.length > 0 &&
                                                                    shift.comments.some(
                                                                        (item) => item.isImportant,
                                                                    ) && (
                                                                        <Icon
                                                                            color={'#FF7F00'}
                                                                            icon="envelope"
                                                                            size={20}
                                                                        />
                                                                    )}
                                                            </td>
                                                            <td className="">
                                                                {editOrCreateMode && (
                                                                    <TableRowActions
                                                                        handleDeleteClick={() =>
                                                                            this._handleDeleteClick(
                                                                                shift.id
                                                                                    ? shift.id
                                                                                    : shift.newShiftId,
                                                                            )
                                                                        }
                                                                        hasDuplicateOption={false}
                                                                    />
                                                                )}
                                                            </td>
                                                        </tr>
                                                    }
                                                </>
                                            );
                                        })}
                                </>
                            </tbody>
                        </table>
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these shifts?"
                        />
                    </div>
                );
            }
        },
    ),
);

export default ShiftPatternListView;
