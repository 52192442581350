import * as Yup from 'yup';

export const validationSchema = Yup.object({
    costCentre: Yup.mixed().required('This field is required.'),
    department: Yup.mixed().required('This field is required.'),
    jobTitle: Yup.string().trim().required('This field is required'),
    startDateOfAssignment: Yup.date().nullable().required('This field is required.'),
    endDateOfAssignment: Yup.date().nullable(),
    payRates: Yup.mixed().required('This field is required.'),
    showFuturePayRate: Yup.boolean(),
    payRatesFuture: Yup.mixed().when('showFuturePayRate', {
        is: (value: any) => value === true,
        then: Yup.mixed().required('This field is required.'),
    }),
    payRatesFutureDate: Yup.mixed().when('showFuturePayRate', {
        is: (value: any) => value === true,
        then: Yup.mixed().required('This field is required.'),
    }),
    reasonForEnding: Yup.string()
        .nullable()
        .when('endDateOfAssignment', {
            is: (value: any) => value,
            then: Yup.string().nullable().required('This field is required.'),
        }),
});
