import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import Map from '../../pages/Map';
import Reporting from '../../pages/Reporting';
import AddJob from '../../modules/jobs/add/AddJob';
import EditJob from '../../modules/jobs/add/EditJob';
import JobDetails from '../../modules/jobs/detail/JobDetails';
import MainNavigation from './MainNavigation';
import SidebarNavigation from './SidebarNavigation';
import JobsList from '../../modules/jobs/list/JobsList';

const OnDemand = inject('RootStore')(
    observer(
        class OnDemand extends Component {
            render() {
                const {
                    RootStore: {
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('onDemandJobs')) {
                    return <Redirect to="/page-not-found" />;
                }

                return (
                    <div className="on-demand">
                        <div className="on-demand__tabs">
                            <Route
                                path="/on-demand/:tab/:page?/:subpage?/:id?"
                                component={SidebarNavigation}
                            />
                        </div>
                        <div className="on-demand__jobs-list">
                            <Route
                                path="/on-demand/:tab/:page?/:subpage?/:id?"
                                component={JobsList}
                            />
                        </div>
                        <div className="on-demand__navigation">
                            <Route path="/on-demand/:tab/:page" component={MainNavigation} />
                        </div>
                        <div className="on-demand__content">
                            <Switch>
                                <Redirect exact from="/on-demand" to="/on-demand/available/map" />
                                <Redirect
                                    exact
                                    from="/on-demand/:tab/jobs"
                                    to="/on-demand/:tab/map"
                                />
                                <Route exact path="/on-demand/:tab/map" component={Map} />
                                <Route exact path="/on-demand/:tab/jobs/add" component={AddJob} />
                                <Route
                                    exact
                                    path="/on-demand/:tab/jobs/:id?/edit"
                                    component={EditJob}
                                />
                                <Route
                                    exact
                                    path="/on-demand/:tab/jobs/:id?"
                                    component={JobDetails}
                                />
                                <Route
                                    exact
                                    path="/on-demand/:tab/reporting"
                                    component={Reporting}
                                />
                            </Switch>
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default OnDemand;
