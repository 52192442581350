import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import ConfirmationAlert from './ConfirmationAlert';

interface RouterPromptProps {
    when: any;
    title?: string;
    message?: string;
    onPromptConfirm: any;
}

export function RouterPrompt(props: RouterPromptProps) {
    const {
        when,
        onPromptConfirm,
        title,
        message = 'Are you sure you want to cancel? The changes won’t be saved.',
    } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [replacePath, setReplacePath] = useState('');

    const unblockRef = useRef<any>();

    useEffect(() => {
        // @ts-ignore
        unblockRef.current = history.block((path) => {
            if (when) {
                setShowPrompt(true);
                setReplacePath(path.pathname);
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [history, when]);

    return (
        <ConfirmationAlert
            confirmButtonText="Yes"
            cancelButtonText="No"
            onConfirm={() => {
                setShowPrompt(false);
                return onPromptConfirm(replacePath);
            }}
            onCancel={() => setShowPrompt(false)}
            isOpen={showPrompt}
            intent={'warning'}
            icon={'undo'}
            title={title}
            description={message}
        />
    );
}
