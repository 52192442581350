import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';

import useStores from '../../../../../hook/useStores';
import { formatTimeAMPM } from '../../../../modules/helpers/formatData';

const MINUTE_MS = 60000;

interface IProps {}
interface IData {
    left: number;
    time: string;
}

const CurrentTime: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: { cellWidth, hourStart, selectedDate },
        },
    } = useStores() as { RootStore: any };

    const getPosition = useCallback(() => {
        const now = moment().toDate();
        const time = formatTimeAMPM(now);

        const current = now.getHours() + now.getMinutes() / 60;
        const left = cellWidth * (current - hourStart);

        return { time, left };
    }, [cellWidth, hourStart]);

    const [intervalRef, setIntervalRef] = useState<number | undefined>();

    const [{ left, time }, setData] = useState<IData>(getPosition());

    // Check current time every minute
    const setTimerRefresh = useCallback(() => {
        clearInterval(intervalRef);

        setData(getPosition());

        setIntervalRef(
            (setInterval(() => {
                setData(getPosition());
            }, MINUTE_MS) as unknown) as number,
        );
    }, [getPosition, intervalRef]);

    useEffect(() => {
        setTimerRefresh();
        return () => clearInterval(intervalRef);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate]);

    return (
        <div className="ucr__calendar-current-time" style={{ left }}>
            <span className="ucr__calendar-current-time-dot" />
            <span className="ucr__calendar-current-time-text">{time}</span>
            <span className="ucr__calendar-current-time-line" />
        </div>
    );
};

export default observer(CurrentTime);
