import React from 'react';
import { IconGrid, IconListBulleted } from '../../helpers/ucr/icons';

interface ViewProps {
    isGridView: boolean;
    handleGridClick: () => void;
    handleListClick: () => void;
}

const TableViewVariants = (props: ViewProps) => {
    const { isGridView = true, handleGridClick, handleListClick } = props;
    return (
        <div className="views-variants">
            <div
                onClick={handleGridClick}
                className={`btn btn--outlined ${isGridView ? 'btn--outlined-active' : ''}`}
            >
                <IconGrid />
                Grid view
            </div>
            <div
                onClick={handleListClick}
                className={`btn btn--outlined ${!isGridView ? 'btn--outlined-active' : ''}`}
            >
                <IconListBulleted />
                List view
            </div>
        </div>
    );
};

export default TableViewVariants;
