import React, { Component } from 'react';
import { Button, Tag } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';
import { observer, inject } from 'mobx-react';

const InvoiceListAndExport = inject('RootStore')(
    observer(
        class InvoiceListAndExport extends Component {
            state = {
                hasRequestedInvoices: false,
                from: null,
                to: null,
            };

            _handleDateChange = (event) => {
                const [from, to] = event;
                this.setState({ from, to });
            };

            _handleGetInvoices = () => {
                const { from, to } = this.state;
                if (!from || !to) {
                    return;
                }
                this.setState({ hasRequestedInvoices: true });
                this.props.RootStore.invoicesStore.getInvoices(from, to);
            };

            _handleExportAllAsCSV = async () => {
                const { from, to } = this.state;
                if (!from || !to) {
                    return;
                }

                const url = await this.props.RootStore.invoicesStore.exportAllInvoicesAsCSV(
                    from,
                    to,
                );
                window.location.href = url;
            };
            _handleDownloadInvoice = async (invoicedId, type = 'PDF') => {
                if (type === 'CSV') {
                    const url = await this.props.RootStore.invoicesStore.exportInvoiceAsCSV(
                        invoicedId,
                        'csv',
                    );
                    window.location.href = url;
                } else {
                    const url = await this.props.RootStore.invoicesStore.exportInvoiceAsPDF(
                        invoicedId,
                        'pdf',
                    );
                    window.location.href = url;
                }
            };

            render() {
                const {
                    RootStore: {
                        invoicesStore: {
                            invoices,
                            isExportingAsCSV,
                            individualExportingInvoiceId,
                            isLoading,
                        },
                    },
                } = this.props;
                const { hasRequestedInvoices, from, to } = this.state;
                const hasSelectedDateRange = from && to;

                return (
                    <div>
                        <h2 className="h2 admin-report__hcp-invoices-heading {">
                            HCP Invoices
                            <Tag intent="success" className="admin-report__tag" minimal>
                                New!
                            </Tag>
                        </h2>
                        <p>Generate a list of all invoices generated between two dates.</p>
                        <DateRangePicker
                            maxDate={new Date()}
                            onChange={this._handleDateChange}
                            className="admin-report__date-range-picker"
                        />
                        <Button
                            icon="confirm"
                            intent="primary"
                            loading={isLoading}
                            onClick={this._handleGetInvoices}
                            disabled={!hasSelectedDateRange}
                        >
                            Get Invoices
                        </Button>{' '}
                        {!hasSelectedDateRange && (
                            <span className="admin-report__helper-text">
                                <em>Please select a date range above</em>
                            </span>
                        )}
                        {hasSelectedDateRange &&
                            hasRequestedInvoices &&
                            !isLoading &&
                            Array.isArray(invoices) &&
                            invoices.length === 0 && (
                                <p>There were no invoices for the period of time selected.</p>
                            )}
                        {Array.isArray(invoices) && invoices.length > 0 && (
                            <div>
                                <div style={{ float: 'right', overflow: 'hidden' }}>
                                    <Button
                                        icon="bring-data"
                                        intent="primary"
                                        loading={isExportingAsCSV}
                                        onClick={this._handleExportAllAsCSV}
                                    >
                                        Download all as CSV
                                    </Button>
                                </div>
                                <table className="bp5-html-table bp5-html-table-striped hcp-invoices-table">
                                    <thead>
                                        <tr>
                                            <th>HCP Name</th>
                                            <th>Invoice Total Price</th>
                                            <th>Invoice Covering Period</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices.map((invoice) => {
                                            return (
                                                <tr key={invoice.invoiceId}>
                                                    <td>{invoice.userName}</td>
                                                    <td>
                                                        £
                                                        {invoice.totalPriceInPence
                                                            .toString()
                                                            .slice(0, -2)}
                                                        .
                                                        {invoice.totalPriceInPence
                                                            .toString()
                                                            .slice(-2)}
                                                    </td>
                                                    <td>
                                                        {Intl.DateTimeFormat('en', {
                                                            month: 'short',
                                                        }).format(
                                                            Date.parse(invoice.coveringPeriod),
                                                        )}
                                                        -
                                                        {Intl.DateTimeFormat('en', {
                                                            year: 'numeric',
                                                        }).format(
                                                            Date.parse(invoice.coveringPeriod),
                                                        )}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Button
                                                                icon="bring-data"
                                                                intent="primary"
                                                                loading={
                                                                    individualExportingInvoiceId ===
                                                                    invoice.invoiceId
                                                                }
                                                                onClick={(e) =>
                                                                    this._handleDownloadInvoice(
                                                                        invoice.invoiceId,
                                                                        'PDF',
                                                                    )
                                                                }
                                                            >
                                                                Download HCP PDF
                                                            </Button>
                                                            <Button
                                                                icon="bring-data"
                                                                intent="primary"
                                                                loading={
                                                                    individualExportingInvoiceId ===
                                                                    invoice.invoiceId
                                                                }
                                                                onClick={(e) =>
                                                                    this._handleDownloadInvoice(
                                                                        invoice.invoiceId,
                                                                        'CSV',
                                                                    )
                                                                }
                                                            >
                                                                Download CSV
                                                            </Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                );
            }
        },
    ),
);

export default InvoiceListAndExport;
