import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';

import Modal from '../../modal/Modal';
import PayRatesSummary from './PayRatesSummary.tsx';
import { Formik, Form } from 'formik';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert, Tab, Tabs } from '@blueprintjs/core';
import PayRatesFooter from './PayRatesFooter';
import PayRatesFutureDialog from './PayRatesFutureDialog';
import { isEqual } from 'lodash';
import RelatedEntities from '../../../common/RelatedEntities';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';
import { validateCode, validateOverlap } from '../../../../helpers/customPayRateValidation';

const PayRatesDetails = inject('RootStore')(
    observer(
        class PayRatesDetails extends Component {
            state = {
                loadingRelatedEntries: false,
                activeTab: 1,
                isEdit: false,
                isExit: false,
                hasDeleteOption: true,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                    isArchived: false,
                    employeeRoleId: '',
                    agencyId: '',
                    generalPayRates: {
                        id: '',
                        code: ' ',
                        rate: '',
                    },
                    customPayRates: [],
                    futurePayRate: null,
                },
                futureDialogDate: new Date(),
                isLoaded: false,
                showDeleteAlert: false,
                showArchiveAlert: false,
                showExitAlert: false,
                showFutureDialog: false,
                showDeniedDeleteAlert: false,
                showDeniedArchiveAlert: false,
                showDeleteFutureAlert: false,
                formWasEdited: false,
            };

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    await this._updatePayRateItem();
                }
                await this.props.RootStore.agenciesStore.getAgencies();
            }

            _updatePayRateItem = async () => {
                const { id } = this.props.match.params;
                const editModeOpenedFromViewList = this.props.location.state
                    ?.editModeOpenedFromViewList;
                const payRate = await this.props.RootStore.payRatesStore.getPayRateById(id);
                if (payRate) {
                    this.setState({
                        initialData: { ...payRate },
                        isEdit: editModeOpenedFromViewList ? editModeOpenedFromViewList : false,
                        isLoaded: true,
                    });
                }
            };

            componentWillUnmount() {
                this.props.RootStore.shiftPatternsStore.shiftPatternsByPayRate = [];
                this.props.RootStore.rotaBuilderStore.rotasByPayRate = [];
                this.props.RootStore.staffStore.staffsByPayRate = [];
            }

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _editStatusForm = (values, func) => {
                this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _toggleFutureDialog = () => {
                this.setState((prevState) => {
                    return { showFutureDialog: !prevState.showFutureDialog };
                });
            };

            _handleFutureDialogChange(date) {
                this.setState({
                    futureDialogDate: date,
                });
            }

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/pay-rates');
                }
                return this.props.history.goBack();
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/pay-rates');
                }
                if (this.props.actionType === 'edit' && !this.state.formWasEdited) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };

            _handleOnCancelConfirm = async (replacePath) => {
                await this.setState({ formWasEdited: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/hr/pay-rates');
            };

            _handleDeleteAlertClick = async () => {
                const {
                    data: { arePayRatesDeletable },
                } = await this.props.RootStore.payRatesStore.validateDeletePayRates([
                    this.state.initialData.id,
                ]);

                const hasNotDeletable = arePayRatesDeletable.some((pr) => pr.isDeletable === false);

                if (!hasNotDeletable) {
                    this.setState((prevState) => {
                        return { showDeleteAlert: !prevState.showDeleteAlert };
                    });
                } else {
                    this.setState((prevState) => {
                        return { showDeniedDeleteAlert: !prevState.showDeniedDeleteAlert };
                    });
                }
            };
            _handleArchiveAlertClick = async () => {
                const {
                    data: { arePayRatesArchivable },
                } = await this.props.RootStore.payRatesStore.validateArchivePayRates([
                    this.state.initialData.id,
                ]);

                const hasNotAchrivable = arePayRatesArchivable.some(
                    (pr) => pr.isArchivable === false,
                );

                if (!hasNotAchrivable) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                const payRate = this.props.RootStore.payRatesStore.gridPayRates.find(
                    (payRate) => payRate.id === id,
                );

                payRate && (await this.props.RootStore.payRatesStore.deletePayRate(payRate));
                this.props.history.replace('/hr/pay-rates');
            };

            _handleOnArchive = async () => {
                const { id } = this.props.match.params;

                const payRate = this.props.RootStore.payRatesStore.gridPayRates.find(
                    (payRate) => payRate.id === id,
                );
                this.props.RootStore.payRatesStore.deselect(id);

                payRate && (await this.props.RootStore.payRatesStore.archivePayRate(payRate));
                this.props.history.replace('/hr/pay-rates');
            };
            _handleOnDeleteFuture = async () => {
                const { id } = this.props.match.params;
                const payRate = this.props.RootStore.payRatesStore.gridPayRates.find(
                    (payRate) => payRate.id === id,
                );

                payRate && (await this.props.RootStore.payRatesStore.deleteFuturePayRate(payRate));
                this.setState({ activeTab: 1 });

                if (payRate) {
                    this.setState({
                        initialData: { ...payRate, futurePayRate: null },
                        showDeleteFutureAlert: false,
                    });
                }
            };

            _handleDeleteFuture = () => {
                this.setState({
                    showDeleteFutureAlert: true,
                });
            };

            _queryRelatedEntries = async () => {
                return (
                    await this.props.RootStore.shiftPatternsStore.getShiftPatternsByPayRate(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.rotaBuilderStore.getRotasByPayRate(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.staffStore.getStaffsByPayRate(
                        this.state.initialData.id,
                    )
                );
            };

            render() {
                const {
                    RootStore: {
                        payRatesStore,
                        staffStore,
                        shiftPatternsStore,
                        rotaBuilderStore,
                        dictionaryStore,
                        rolesStore,
                        agenciesStore,
                    },
                } = this.props;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }
                const title =
                    this.props.actionType === 'create'
                        ? 'Add pay rate record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        enableReinitialize={true}
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            const { name } = values;
                            const errors = {};

                            this._editStatusForm(values);

                            if (this.props.actionType !== 'create') return {};

                            const {
                                data: { isPayRateNameUnique },
                            } = await payRatesStore.validateName(name);

                            if (!isPayRateNameUnique) {
                                errors.name = 'Name should be unique';
                            }

                            if (
                                validateCode(values.customPayRates) ||
                                validateOverlap(values.customPayRates)
                            ) {
                                errors.customPayRatesOverlap = true;
                            }

                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            this.setState({ formWasEdited: false });
                            if (this.props.actionType === 'create') {
                                await this.setState({ formWasEdited: false });
                                await payRatesStore.createPayRate(values);
                                this.props.history.replace('/hr/pay-rates');
                            } else {
                                const hrs = moment
                                    .duration(moment(this.state.futureDialogDate).diff(moment()))
                                    .asHours();

                                if (hrs > 1) {
                                    if (!!this.state.initialData.futurePayRate) {
                                        const payRateId = this.state.initialData.futurePayRate
                                            .generalPayRates.id;
                                        await payRatesStore.updateGeneralPayRate(
                                            payRateId,
                                            values,
                                            this.state.futureDialogDate,
                                        );
                                    } else {
                                        await payRatesStore.createFuturePayRate(
                                            values.id,
                                            this.state.futureDialogDate,
                                            values,
                                        );
                                    }
                                } else {
                                    const payRateId = this.state.initialData.generalPayRates.id;
                                    await payRatesStore.updateGeneralPayRate(payRateId, values);
                                }
                                this.setState({ formWasEdited: false });
                            }

                            setSubmitting(false);

                            if (this.props.actionType === 'edit') {
                                const { id } = this.props.match.params;
                                const payRate = await this.props.RootStore.payRatesStore.getPayRateById(
                                    id,
                                );
                                if (payRate) {
                                    this.setState({
                                        initialData: { ...payRate },
                                        isEdit: false,
                                    });
                                }
                            }
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                            submitForm,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        this.state.activeTab === 1 ? (
                                            <PayRatesFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onDelete={this._handleDeleteAlertClick}
                                                onArchive={this._handleArchiveAlertClick}
                                                onShowFutureDialog={this._toggleFutureDialog}
                                                hasDeleteOption={this.state.hasDeleteOption}
                                                hasArchiveOption={
                                                    !this.state.initialData.isArchived
                                                }
                                                actionType={this.props.actionType}
                                                formWasEdited={this.state.formWasEdited}
                                                submitForm={submitForm}
                                            />
                                        ) : null
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="payRatesTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            if (newTabId === 3) {
                                                this.setState({
                                                    loadingRelatedEntries: true,
                                                });
                                                await this._queryRelatedEntries();
                                            }

                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                            this.setState({
                                                loadingRelatedEntries: false,
                                            });
                                        }}
                                        selectedTabId={this.state.activeTab}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                this.props.actionType === 'edit' ? 'Current' : ''
                                            }
                                            panel={
                                                <PayRatesSummary
                                                    payRate={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    isCreate={this.props.actionType === 'create'}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    actionType={this.props.actionType}
                                                    errors={errors}
                                                    touched={touched}
                                                    rolesStore={rolesStore}
                                                    agenciesStore={agenciesStore}
                                                />
                                            }
                                        />

                                        {this.state.initialData.futurePayRate &&
                                            !this.state.isEdit && (
                                                <Tab
                                                    id={2}
                                                    title={
                                                        this.props.actionType === 'edit'
                                                            ? 'Future'
                                                            : ''
                                                    }
                                                    panel={
                                                        <div>
                                                            <PayRatesSummary
                                                                payRate={
                                                                    this.state.initialData
                                                                        .futurePayRate
                                                                }
                                                                values={
                                                                    this.state.initialData
                                                                        .futurePayRate
                                                                }
                                                                editOrCreateMode={false}
                                                                isCreate={this.state.isCreate}
                                                                handleChange={handleChange}
                                                                setFieldValue={setFieldValue}
                                                                actionType={this.props.actionType}
                                                                errors={errors}
                                                                touched={touched}
                                                            />
                                                            <div className="payRates-future-date-box">
                                                                <div>{`Update date: ${moment(
                                                                    this.state.initialData
                                                                        .futurePayRate
                                                                        .generalPayRates.startDate,
                                                                ).format('DD/MM/YYYY')}`}</div>
                                                                <div>{`Update time: ${moment(
                                                                    this.state.initialData
                                                                        .futurePayRate
                                                                        .generalPayRates.startDate,
                                                                ).format('HH:mm')}`}</div>
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            )}

                                        <Tab
                                            id={3}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          this.state.loadingRelatedEntries
                                                              ? '...'
                                                              : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        hasRelatedRotas={true}
                                                        hasRelatedShiftPatterns={true}
                                                        hasRelatedStaffs={true}
                                                        dictionaryStore={dictionaryStore}
                                                        relatedShiftPatterns={
                                                            shiftPatternsStore.shiftPatternsByPayRate
                                                        }
                                                        relatedRotas={
                                                            rotaBuilderStore.rotasByPayRate
                                                        }
                                                        relatedStaffs={staffStore.staffsByPayRate}
                                                        description={
                                                            'This pay rate is related to the following entities (archived entities are not displayed)'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleDeleteAlertClick}
                                    isOpen={this.state.showDeleteAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this pay rate? You won't be
                                        able to recover it."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._handleOnArchive}
                                    onCancel={this._handleArchiveAlertClick}
                                    isOpen={this.state.showArchiveAlert}
                                    title="Confirm archiving!"
                                    icon={'archive'}
                                    intent={'warning'}
                                    description="Are you sure you want to archive this pay rate? This action is irreversible"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDeleteFuture}
                                    onCancel={() => {
                                        this.setState({ showDeleteFutureAlert: false });
                                    }}
                                    isOpen={this.state.showDeleteFutureAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete future pay rate update?"
                                />
                                <PayRatesFutureDialog
                                    onSubmit={(date) => {
                                        this._handleFutureDialogChange(date);
                                        this._toggleFutureDialog();
                                        submitForm();
                                    }}
                                    onCancel={() => this._toggleFutureDialog()}
                                    isOpen={this.state.showFutureDialog}
                                    showWarning={!!this.state.initialData.futurePayRate}
                                />
                                <Alert
                                    confirmButtonText="OK"
                                    intent={'primary'}
                                    isOpen={this.state.showDeniedDeleteAlert}
                                    onClose={() => {
                                        this.setState({ showDeniedDeleteAlert: false });
                                    }}
                                >
                                    <p>
                                        This pay rate cannot be deleted because it is assigned to
                                        staff records and/or shifts
                                    </p>
                                </Alert>
                                <Alert
                                    confirmButtonText="OK"
                                    intent={'primary'}
                                    isOpen={this.state.showDeniedArchiveAlert}
                                    onClose={() => {
                                        this.setState({ showDeniedArchiveAlert: false });
                                    }}
                                >
                                    <p>
                                        This pay rate cannot be archived because it is related to
                                        other active entities and/or future shifts (see the ‘Related
                                        entities’ tab).
                                    </p>
                                </Alert>
                                <RouterPrompt
                                    when={this.state.formWasEdited}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default PayRatesDetails;
