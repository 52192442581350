import { FC } from 'react';

import { JobStatus, Patient, PatientAlert } from '@doc-abode/data-models';
import toString from 'lodash/toString';
import moment from 'moment';
import filterPatientAlerts from '../../../../../../helpers/ucr/filterPatientAlerts';
import getHcpName from '../../../../../../helpers/ucr/getHcpName';
import useStores from '../../../../../../hook/useStores';
import {
    formatDisplayDate,
    formatDisplayDateTime,
} from '../../../../../modules/helpers/formatData';
import { getServiceDisplayName } from '../../../forms/common';
import { ADMIN_TIME } from '../../../forms/AdminTime/AdminTimeConsts';
import { JobSummaryWarning } from './JobSummaryWarning';
import { IHCP } from './JobSummaryHeader';
import { IJobSummaryComponentProps, JobType, JobSummaryComponent } from './JobSummaryComponent';
import { genderMapping } from '../../../../../../constants/mainConst';
import { createStepWarnings } from '../../../../../../helpers/ucr/warnings';

export const JobSummaryContainer: FC<{ job: Patient; hcpid: string }> = ({ job, hcpid }) => {
    const {
        RootStore: {
            ucrStore: { hcps: allHcps, warnings: allWarnings, patientAlerts: allPatientAlerts },
            usersStore: { users, hcpUsers },
            configStore: { pathways },
        },
    } = useStores() as { RootStore: any };

    const {
        disposition,
        id: jobId,

        // Header
        firstName,
        middleName,
        lastName,
        staffRequired,
        hcpId,
        jobStatus,
        buddyId,
        buddyJobStatus,

        // Patient Details
        addressLine1,
        addressLine2,
        town,
        postCode,
        dateOfBirth,
        age,
        gender,

        // Visit and Activity Details
        referrer,
        referralPathway,
        activityType,

        // Notes
        notes,
    } = job;

    const hcpJobMapper = (id: string | null | undefined, status: JobStatus): IHCP | null => {
        return id
            ? {
                  isMain: hcpid === id,
                  name: getHcpName(allHcps, id),
                  status: Patient.getFriendlyVisitStatus(status),
                  hcpID: id,
              }
            : null;
    };
    let hcps: (IHCP | null)[] = [hcpJobMapper(hcpId, jobStatus)];
    if (staffRequired === 2) {
        hcps.push(hcpJobMapper(buddyId, buddyJobStatus!));
    }

    ////////////////////////////////////////////////////////////////////////////
    // Warnings
    ////////////////////////////////////////////////////////////////////////////

    const { unresolved } = filterPatientAlerts(allPatientAlerts, jobId);
    const jobWarnings = allWarnings[jobId];
    let generalWarnings: JobSummaryWarning[] = [];

    if (jobWarnings) {
        generalWarnings = jobWarnings
            .map(({ category, data }: { category: any; data: any }): JobSummaryWarning | null => {
                let content;

                switch (category) {
                    case 'PATIENT_SCHEDULING_CONFLICT':
                        content =
                            'The patient already has a visit scheduled within this time period';
                        break;
                    case 'HCP_SCHEDULING_CONFLICT':
                        content = `Scheduling conflict identified for: ${getHcpName(
                            users,
                            data.hcpId,
                        )}`;
                        break;
                    case 'HCP_AVAILABILITY_CONFLICT':
                        content = `Planned time of the visit is out of ${getHcpName(
                            users,
                            data.hcpId,
                        )} availability hours!`;
                        break;
                    case 'WARNING_GEOLOCATION_MISSING':
                        content = 'Address details could not be verified!';
                        break;
                    default:
                        content = null;
                        break;
                }

                if (!content) {
                    return null;
                }

                return {
                    intent: 'danger',
                    content,
                };
            })
            .filter((value: any): value is JobSummaryWarning => Boolean(value));
    }

    const warnings: JobSummaryWarning[] = [
        ...unresolved.map(
            (patientAlert: PatientAlert): JobSummaryWarning => {
                return {
                    intent: 'danger',
                    content: `Unresolved patient alert raised by ${getHcpName(
                        users,
                        patientAlert.createdBy,
                    )} at ${formatDisplayDateTime(patientAlert.createdAt)}: ${patientAlert.type}`,
                };
            },
        ),
        ...generalWarnings,
        ...createStepWarnings(hcpUsers, job).map(
            (content): JobSummaryWarning => {
                return {
                    intent: 'danger',
                    content,
                };
            },
        ),
    ];

    ////////////////////////////////////////////////////////////////////////////
    // Aborted
    ////////////////////////////////////////////////////////////////////////////

    let aborted: {
        abortedReason: string | undefined;
        abortedNotes: string | undefined;
    } | null = null;

    const isFirstUser = hcpid === job.hcpId;

    if (job.jobStatus === JobStatus.HCP_ABORTED || job.buddyJobStatus === JobStatus.HCP_ABORTED) {
        aborted = {
            abortedReason: isFirstUser ? job.hcpAbortedReason : job.buddyHcpAbortedReason,
            abortedNotes: isFirstUser ? job.hcpAbortedNotes : job.buddyHcpAbortedNotes,
        };
    }

    if (
        job.jobStatus === JobStatus.CONTROLLER_ABORTED ||
        job.buddyJobStatus === JobStatus.CONTROLLER_ABORTED
    ) {
        aborted = {
            abortedReason: isFirstUser
                ? job.controllerAbortedReason
                : job.buddyControllerAbortedReason,
            abortedNotes: isFirstUser
                ? job.controllerAbortedNotes
                : job.buddyControllerAbortedNotes,
        };
    }

    ////////////////////////////////////////////////////////////////////////////
    // Data Construction
    ////////////////////////////////////////////////////////////////////////////

    const showComa =
        [lastName, firstName, middleName].filter((v) => v !== null).length > 1 && lastName;

    const isNonEnlishSpoken = job?.languagesSpoken?.length !== 0;
    const isCareComplex = job.careComplexity === 'complex';
    const iCarRequired = job.carRequired;

    const pathway = pathways.find((pathway: any) => pathway.value === referralPathway);

    const data: IJobSummaryComponentProps = {
        type: disposition === ADMIN_TIME ? JobType.ADMIN : JobType.VISIT,

        header: {
            name: `${toString(lastName)}${showComa ? ',' : ''} ${toString(firstName)} ${toString(
                middleName,
            )}`,
            hcps,
            currentHcp: hcpid,
        },

        warnings,

        data: {
            address: [
                [addressLine1, addressLine2, town]
                    .filter(Boolean)
                    .map((ln) => ln!.replace(/,\s*$/, ''))
                    .join(', '),
                postCode,
            ]
                .filter(Boolean)
                .join(' '),
            gender: gender ? genderMapping[gender] : genderMapping.not_provided,
            dateOfBirth: dateOfBirth && `${formatDisplayDate(dateOfBirth)} (age: ${age})`,
            ...aborted,
            pathway: pathway?.label,
            referrer,
            service: getServiceDisplayName(pathway?.services, disposition),
            activity: activityType,
            preVisitNotes: notes,
            isNonEnlishSpoken,
            isCareComplex,
            iCarRequired,
            availableFrom: job.availableFrom
                ? moment(job.availableFrom).format('h:mm a')
                : undefined,
            availableTo: job.availableTo ? moment(job.availableTo).format('h:mm a') : undefined,
        },
    };

    return <JobSummaryComponent {...data} />;
};

export default JobSummaryContainer;
