import { gql } from '@apollo/client';

export const GET_CHECKLISTS = gql`
    query GetInductionChecklists {
        getInductionChecklists {
            edges {
                node {
                    id
                    name
                    orderNumber
                    isDeleted
                }
            }
        }
    }
`;

export const UPDATE_CHECKLISTS = gql`
    mutation UpdateInductionChecklists($data: InductionChecklistEntityInput!) {
        updateInductionChecklists(data: $data) {
            count
        }
    }
`;
