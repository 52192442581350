import React from 'react';
// import { observer, inject } from 'mobx-react';

// import AlertsHeader from './AlertsHeader';
// import Loader from '../../../modules/helpers/Loader';
// import AlertsNavigation from './AlertsNavigation';
// import AlertsItem from './AlertsItem';
// import { AlertsTypes } from '../../../../stores/AlertStore';

export interface AlertsClick {
    objectId: string;
    ownerType: string;
    ownerId: string;
}

const Alerts = () => <div>Alerts</div>;

// const Alerts = inject('RootStore')(
//     observer(
//         class Alerts extends Component<any> {
//             async componentDidMount() {
//                 try {
//                     await this.props.RootStore.alertsStore.getAlerts();
//                 } catch (err) {
//                     //TODO
//                     console.log('err', err);
//                 }
//             }

//             componentWillUnmount() {
//                 this.props.RootStore.alertsStore.clear();
//             }

//             _handleToggleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
//                 const { selectAll, deselectAll } = this.props.RootStore.alertsStore;

//                 if (event.target.checked) {
//                     selectAll();
//                 } else {
//                     deselectAll();
//                 }
//             };

//             _handleToggleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
//                 const { select, deselect } = this.props.RootStore.alertsStore;
//                 const {
//                     target: { id, checked },
//                 } = event;

//                 if (checked) {
//                     select(id);
//                 } else {
//                     deselect(id);
//                 }
//             };

//             _handleAlertClick = (
//                 { objectId, ownerType, ownerId }: AlertsClick,
//                 event: React.MouseEvent,
//             ) => {
//                 const { tagName } = event.target as HTMLElement;
//                 if (tagName === 'TD') {
//                     const type =
//                         ownerType === 'Employee'
//                             ? 'employed-records'
//                             : ownerType === 'Sessional'
//                             ? 'sessional-records'
//                             : 'people';
//                     this.props.history.push({
//                         pathname: `/hr/${type}/${ownerId}/documents`,
//                         state: { documentId: objectId },
//                     });
//                 }
//             };

//             render() {
//                 const {
//                     RootStore: {
//                         alertsStore: {
//                             gridAlerts,
//                             onSort,
//                             allSelected,
//                             pageInfo,
//                             nextPage,
//                             previousPage,
//                             loaded,
//                             // onFilter,
//                             onSearch,
//                         },
//                     },
//                 } = this.props;

//                 return (
//                     <>
//                         {!loaded && <Loader />}
//                         <main className="common-layout__main">
//                             <AlertsNavigation
//                                 pageInfo={pageInfo}
//                                 previousPage={previousPage}
//                                 nextPage={nextPage}
//                                 loading={false}
//                                 // onFilter={onFilter}
//                                 onSearch={onSearch}
//                             />
//                             <table className="bp5-html-table bp5-interactive common-table">
//                                 <AlertsHeader
//                                     onSort={onSort}
//                                     isFrontendSort={false}
//                                     onToggleSelectAll={this._handleToggleSelectAll}
//                                     checked={allSelected}
//                                 />
//                                 <tbody>
//                                     {gridAlerts.map((alert: AlertsTypes) => (
//                                         <AlertsItem
//                                         // {...alert}
//                                         // onClick={this._handleAlertClick}
//                                         // key={alert.objectId}
//                                         // onToggle={this._handleToggleSelected}
//                                         />
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </main>
//                     </>
//                 );
//             }
//         },
//     ),
// );

export default Alerts;
