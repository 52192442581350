import { ApolloError, useMutation } from '@apollo/client';
import { useCallback, useState, useEffect } from 'react';
import { Alert, Button, Callout } from '@blueprintjs/core';
import { Formik, Form, useFormikContext, FormikValues } from 'formik';
import moment from 'moment';
import {
    Vaccination,
    friendlyCovid19Manufacturers,
    friendlyConsentStatuses,
    friendlyVaccinationCategories,
    VaccinationCategory,
    Flags,
    friendlyShinglesManufacturers,
    VaccinationType,
} from '@doc-abode/data-models';
import { useHistory } from 'react-router';

import { dateFormat } from '../../../../constants/patientsConst';
import CheckboxInput from '../../../common/formik/CheckboxInput';
import TextAreaInput from '../../../common/formik/TextAreaInput';
import TextInput from '../../../common/formik/TextInput';
import Modal from '../../../modules/modal/Modal';
import useStores from '../../../../hook/useStores';
import SelectInput from '../../../common/formik/SelectInput';
import { CREATE_JOB } from '../../../../graphql/queries/jobs';
import AppToaster from '../../../modules/helpers/Toaster';
import validationSchema from './validation';
import isMultiDoseVaccine from '../../../../helpers/isMultiDoseVaccine';
import { capitalize, splitPracticeDetails } from '../../../modules/helpers/formatData';
import { Accordion, AccordionColors, AccordionTab } from '../../../v2/components/Accordion';
import { PracticeSelect } from '../../../common/PracticeSelect';

type Hub = {
    id: string;
    name: string;
};

interface IAddPatientForm {
    onClose: () => void;
    loading: boolean;
    error: ApolloError | undefined;
    hubs: Hub[];
    showAddNextVaccinationDialog: boolean;
    setShowAddNextVaccinationDialog: (value: boolean) => void;
    vaccinationCategoriesToAdd: string[];
    createdVaccinationCategories: string[];
}

const scrollToTop = () =>
    document.querySelector('.modal')?.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

const AddPatientForm = ({
    onClose,
    loading,
    error,
    hubs,
    showAddNextVaccinationDialog,
    setShowAddNextVaccinationDialog,
    vaccinationCategoriesToAdd,
    createdVaccinationCategories,
}: IAddPatientForm) => {
    const { values, setValues } = useFormikContext<any>();
    const { setFieldValue } = useFormikContext<any>();

    const [vaccinationCategory] = vaccinationCategoriesToAdd;
    const isAdditionalCategory = vaccinationCategoriesToAdd.length > 0;

    const onAddNextVaccination = () => {
        setValues({
            ...values,
            coAdministeredWith: [
                ...vaccinationCategoriesToAdd,
                ...createdVaccinationCategories,
            ].filter((category) => category !== vaccinationCategory),
            doseNumber: '',
            dateOfDose1: '',
            dateOfDose2: '',
            dateOfDose3: '',
            dateOfDose3Primary: '',
            dateOfDose4: '',
            dateOfPreviousDose: '',
            manufacturerOfDose1: '',
            manufacturerOfDose2: '',
            manufacturerOfDose3: '',
            manufacturerOfDose3Primary: '',
            manufacturerOfDose4: '',
            manufacturerOfPreviousDose: '',
            vaccineManufacturer: '',
            vaccinationCategory,
        });
        setShowAddNextVaccinationDialog(false);
    };
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };
    return (
        <Form className="vaccinations__form">
            <Modal
                title="Add patient"
                onClose={onClose}
                footer={
                    <Button
                        intent="success"
                        large
                        type="submit"
                        icon="tick"
                        loading={loading}
                        outlined
                    >
                        Submit
                    </Button>
                }
                shadow
            >
                {error && (
                    <Callout intent="danger" className="callout">
                        Patient could not be added: {error.message}
                    </Callout>
                )}
                <dl className="info">
                    <dt className="info__title">First name *</dt>
                    <dd className="info__definition">
                        <TextInput name="firstName" disabled={loading || isAdditionalCategory} />
                    </dd>
                    <dt className="info__title">Last name *</dt>
                    <dd className="info__definition">
                        <TextInput name="lastName" disabled={loading || isAdditionalCategory} />
                    </dd>
                    <dt className="info__title">Vaccination type *</dt>
                    <dd className="info__definition">
                        <SelectInput
                            name="vaccinationCategory"
                            disabled={loading || isAdditionalCategory}
                        >
                            <option value="">Select...</option>
                            {Object.values(VaccinationCategory).map((category) => (
                                <option value={category} key={category}>
                                    {Vaccination.getFriendlyVaccinationCategory(category)}
                                </option>
                            ))}
                        </SelectInput>
                    </dd>
                    {isMultiDoseVaccine(values) && (
                        <>
                            <dt className="info__title">Dose number</dt>
                            <dd className="info__definition">
                                <SelectInput name="doseNumber">
                                    <option value="">Select...</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    {values.vaccinationCategory ===
                                        VaccinationCategory.COVID_19 && (
                                        <>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </>
                                    )}
                                </SelectInput>
                            </dd>
                        </>
                    )}
                    {values.vaccinationCategory === VaccinationCategory.COVID_19 && (
                        <>
                            <dt className="info__title">Primary/booster dose</dt>
                            <dd className="info__definition">
                                <SelectInput name="vaccinationType">
                                    <option value="">Select...</option>
                                    {Object.values(VaccinationType).map((type) => (
                                        <option value={type} key={type}>
                                            {capitalize(type.toLowerCase())}
                                        </option>
                                    ))}
                                </SelectInput>
                            </dd>
                        </>
                    )}
                    {values.vaccinationCategory && (
                        <>
                            <dt className="info__title">Manufacturer</dt>
                            <dd className="info__definition">
                                <SelectInput name="vaccineManufacturer">
                                    <option value="">Select...</option>
                                    {Object.entries(
                                        Vaccination.manufacturerList(
                                            values.vaccinationCategory as VaccinationCategory,
                                        ) || [],
                                    ).map(([value, name]) => (
                                        <option value={value} key={value}>
                                            {name}
                                        </option>
                                    ))}
                                </SelectInput>
                            </dd>
                        </>
                    )}
                    {isMultiDoseVaccine(values) && (
                        <>
                            <dt className="info__title">Previous dose date</dt>
                            <dd className="info__definition">
                                <TextInput
                                    name="dateOfPreviousDose"
                                    disabled={loading}
                                    placeholder="DD/MM/YYYY"
                                />
                            </dd>
                            <dt className="info__title">Previous dose manufacturer</dt>
                            <dd className="info__definition">
                                <SelectInput name="manufacturerOfPreviousDose">
                                    <option value="">Select...</option>
                                    {Object.entries(
                                        Vaccination.manufacturerList(
                                            values.vaccinationCategory as VaccinationCategory,
                                        ) || [],
                                    ).map(([value, name]) => (
                                        <option value={value} key={value}>
                                            {name}
                                        </option>
                                    ))}
                                </SelectInput>
                            </dd>
                        </>
                    )}

                    {values.vaccinationCategory === VaccinationCategory.COVID_19 &&
                        values.doseNumber > 1 && (
                            <div className="accordion-patient-details">
                                <Accordion>
                                    <AccordionTab
                                        name="Vaccination History"
                                        title={'Vaccination History'}
                                        color={AccordionColors.GREY}
                                        open={isOpen}
                                        onClick={() => handleClick()}
                                        className="v2__accordion-tab--accordion-pation-detaile"
                                    >
                                        {values.doseNumber === '5' && (
                                            <>
                                                <dt className="info__title">Date of fourth dose</dt>
                                                <dd className="info__definition">
                                                    <TextInput
                                                        name="dateOfDose4"
                                                        disabled={loading}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </dd>
                                                <dt className="info__title">
                                                    Manufacturer of fourth dose
                                                </dt>
                                                <dd className="info__definition">
                                                    <SelectInput name="manufacturerOfDose4">
                                                        <option value="">Select...</option>
                                                        {Object.entries(
                                                            friendlyCovid19Manufacturers,
                                                        ).map(([value, name]) => (
                                                            <option value={value} key={value}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </SelectInput>
                                                </dd>
                                            </>
                                        )}
                                        {(values.doseNumber === '5' ||
                                            values.doseNumber === '4') && (
                                            <>
                                                <dt className="info__title">
                                                    Date of third dose (booster)
                                                </dt>
                                                <dd className="info__definition">
                                                    <TextInput
                                                        name="dateOfDose3"
                                                        disabled={loading}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </dd>
                                                <dt className="info__title">
                                                    Manufacturer of third dose (booster)
                                                </dt>
                                                <dd className="info__definition">
                                                    <SelectInput name="manufacturerOfDose3">
                                                        <option value="">Select...</option>
                                                        {Object.entries(
                                                            friendlyCovid19Manufacturers,
                                                        ).map(([value, name]) => (
                                                            <option value={value} key={value}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </SelectInput>
                                                </dd>
                                            </>
                                        )}
                                        {(values.doseNumber === '5' ||
                                            values.doseNumber === '4' ||
                                            (values.doseNumber === '3' &&
                                                values.vaccinationType === 'BOOSTER')) && (
                                            <>
                                                <dt className="info__title">
                                                    Date of third dose (primary)
                                                </dt>
                                                <dd className="info__definition">
                                                    <TextInput
                                                        name="dateOfDose3Primary"
                                                        disabled={loading}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </dd>
                                                <dt className="info__title">
                                                    Manufacturer of third dose (primary)
                                                </dt>
                                                <dd className="info__definition">
                                                    <SelectInput name="manufacturerOfDose3Primary">
                                                        <option value="">Select...</option>
                                                        {Object.entries(
                                                            friendlyCovid19Manufacturers,
                                                        ).map(([value, name]) => (
                                                            <option value={value} key={value}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </SelectInput>
                                                </dd>
                                            </>
                                        )}
                                        {(values.doseNumber === '3' ||
                                            values.doseNumber === '4' ||
                                            values.doseNumber === '5') && (
                                            <>
                                                <dt className="info__title">Date of second dose</dt>
                                                <dd className="info__definition">
                                                    <TextInput
                                                        name="dateOfDose2"
                                                        disabled={loading}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </dd>
                                                <dt className="info__title">
                                                    Manufacturer of second dose
                                                </dt>
                                                <dd className="info__definition">
                                                    <SelectInput name="manufacturerOfDose2">
                                                        <option value="">Select...</option>
                                                        {Object.entries(
                                                            friendlyCovid19Manufacturers,
                                                        ).map(([value, name]) => (
                                                            <option value={value} key={value}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </SelectInput>
                                                </dd>
                                            </>
                                        )}
                                        {(values.doseNumber === '2' ||
                                            values.doseNumber === '3' ||
                                            values.doseNumber === '4' ||
                                            values.doseNumber === '5') && (
                                            <>
                                                <dt className="info__title">Date of first dose</dt>
                                                <dd className="info__definition">
                                                    <TextInput
                                                        name="dateOfDose1"
                                                        disabled={loading}
                                                        placeholder="DD/MM/YYYY"
                                                    />
                                                </dd>
                                                <dt className="info__title">
                                                    Manufacturer of first dose
                                                </dt>
                                                <dd className="info__definition">
                                                    <SelectInput name="manufacturerOfDose1">
                                                        <option value="">Select...</option>
                                                        {Object.entries(
                                                            values.vaccinationCategory ===
                                                                VaccinationCategory.SHINGLES
                                                                ? {
                                                                      SHINGRIX:
                                                                          friendlyShinglesManufacturers[
                                                                              'SHINGRIX'
                                                                          ],
                                                                  }
                                                                : friendlyCovid19Manufacturers,
                                                        ).map(([value, name]) => (
                                                            <option value={value} key={value}>
                                                                {name}
                                                            </option>
                                                        ))}
                                                    </SelectInput>
                                                </dd>
                                            </>
                                        )}
                                    </AccordionTab>
                                </Accordion>
                            </div>
                        )}
                    {values.vaccinationCategory && (
                        <>
                            <dt className="info__title">Can be co-administered with</dt>
                            <dd className="info__definition">
                                {Object.values(VaccinationCategory)
                                    .filter((category) => category !== values.vaccinationCategory)
                                    .map((category) => (
                                        <CheckboxInput
                                            name="coAdministeredWith"
                                            label={Vaccination.getFriendlyVaccinationCategory(
                                                category,
                                            )}
                                            value={category}
                                            disabled={loading || isAdditionalCategory}
                                            key={category}
                                        />
                                    ))}
                            </dd>
                        </>
                    )}
                    <dt className="info__title">NHS number *</dt>
                    <dd className="info__definition">
                        <TextInput
                            name="nhsNumber"
                            disabled={loading || isAdditionalCategory}
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                        />
                    </dd>
                    <dt className="info__title">Date of birth *</dt>
                    <dd className="info__definition">
                        <TextInput
                            name="dateOfBirth"
                            disabled={loading || isAdditionalCategory}
                            placeholder="DD/MM/YYYY"
                        />
                    </dd>
                    <dt className="info__title">Sex *</dt>
                    <dd className="info__definition">
                        <SelectInput name="gender" disabled={loading || isAdditionalCategory}>
                            <option value="">Select...</option>
                            <option value="female">Female</option>
                            <option value="male">Male</option>
                        </SelectInput>
                    </dd>
                    <dt className="info__title">Address *</dt>
                    <dd className="info__definition">
                        <>
                            <TextInput
                                name="addressLine1"
                                label="Address line 1 *"
                                disabled={loading || isAdditionalCategory}
                            />
                            <TextInput
                                name="addressLine2"
                                label="Address line 2"
                                disabled={loading || isAdditionalCategory}
                            />
                            <TextInput
                                name="town"
                                label="Town *"
                                disabled={loading || isAdditionalCategory}
                            />
                            <TextInput
                                name="postCode"
                                label="Postcode *"
                                disabled={loading || isAdditionalCategory}
                            />
                        </>
                    </dd>
                    <dt className="info__title">Mobile phone number</dt>
                    <dd className="info__definition">
                        <TextInput name="mobilePhone" disabled={loading || isAdditionalCategory} />
                    </dd>
                    <dt className="info__title">Home phone number</dt>
                    <dd className="info__definition">
                        <TextInput name="homePhone" disabled={loading || isAdditionalCategory} />
                    </dd>
                    <dt className="info__title">Registered GP practice</dt>
                    <dd className="info__definition">
                        <PracticeSelect
                            name="practice"
                            disabled={loading || isAdditionalCategory}
                            isClearable={true}
                            onChange={(value) => {
                                setFieldValue('practice', value);
                            }}
                        />
                    </dd>
                    <dt className="info__title">Hub *</dt>
                    <dd className="info__definition">
                        <SelectInput name="hubId" disabled={loading || isAdditionalCategory}>
                            <option value="">Select...</option>
                            {hubs.map((hub: Hub) => (
                                <option value={hub.id} key={hub.id}>
                                    {hub.name}
                                </option>
                            ))}
                        </SelectInput>
                    </dd>
                    <dt className="info__title">Consented to vaccination *</dt>
                    <dd className="info__definition">
                        <SelectInput name="consented">
                            <option value="">Select...</option>
                            {Object.entries(friendlyConsentStatuses).map(([value, name]) => (
                                <option value={value} key={value}>
                                    {name}
                                </option>
                            ))}
                        </SelectInput>
                    </dd>
                    <dt className="info__title">Notes</dt>
                    <dd className="info__definition">
                        <TextAreaInput name="notes" disabled={loading} />
                    </dd>
                    <dt className="info__title">Available from (HH:MM)</dt>
                    <dd className="info__definition">
                        <TextInput name="availableFrom" disabled={loading} />
                    </dd>
                    <dt className="info__title">Available to (HH:MM)</dt>
                    <dd className="info__definition">
                        <TextInput name="availableTo" disabled={loading} />
                    </dd>
                    <dt className="info__title">Flags</dt>
                    <dd className="info__definition">
                        {Object.values(Flags).map((flag) => (
                            <CheckboxInput
                                name="flags"
                                label={flag}
                                value={flag}
                                disabled={loading}
                                key={flag}
                            />
                        ))}
                    </dd>
                </dl>
            </Modal>
            <Alert
                isOpen={showAddNextVaccinationDialog}
                onConfirm={onAddNextVaccination}
                onCancel={onClose}
                cancelButtonText="No"
                confirmButtonText="Yes"
                icon="help"
                intent="primary"
            >
                <p>
                    You have selected a co-administered vaccine that has not been added to the
                    system:{' '}
                    <strong>
                        {friendlyVaccinationCategories[vaccinationCategory as VaccinationCategory]}
                    </strong>
                </p>
                <p>Would you like to add it now?</p>
            </Alert>
        </Form>
    );
};

export default function AddPatient() {
    const [showAddNextVaccinationDialog, setShowAddNextVaccinationDialog] = useState(false);
    const [createdVaccinationCategories, setCreatedVaccinationCategories] = useState<string[]>([]);
    const [vaccinationCategoriesToAdd, setVaccinationCategoriesToAdd] = useState<string[]>([]);

    const history = useHistory();

    const [createJob, { data, loading, error }] = useMutation(CREATE_JOB);

    const {
        RootStore: {
            configStore: { hubs, org },
            userStore: {
                user: { username },
            },
        },
    } = useStores();

    const onClose = useCallback(() => history.push('/vaccinations/patients'), [history]);

    useEffect(() => {
        if (data) {
            AppToaster.show({
                message: 'Patient added successfully!',
                intent: 'success',
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            createdVaccinationCategories.length > 0 &&
            vaccinationCategoriesToAdd.length === 0 &&
            !error &&
            !loading
        ) {
            onClose();
        }
    }, [onClose, createdVaccinationCategories, vaccinationCategoriesToAdd, error, loading]);

    const onSubmit = async (values: FormikValues) => {
        const doseNumber = parseInt(values.doseNumber, 10);
        const { name, odsCode } = splitPracticeDetails(values.practice?.value || '');
        const data = {
            ...values,
            createdBy: username,
            lastUpdatedBy: username,
            createDateTime: moment.utc().format(),
            contactNumber: values.mobilePhone || values.homePhone,
            additionalContactNumbers:
                values.mobilePhone && values.homePhone ? [values.homePhone] : undefined,
            homePhone: undefined,
            mobilePhone: undefined,
            doseNumber,
            dateOfBirth: moment.utc(values.dateOfBirth, dateFormat).format(),
            dateOfDose1:
                (values.doseNumber === '2' ||
                    values.doseNumber === '3' ||
                    values.doseNumber === '4' ||
                    values.doseNumber === '5') &&
                values.dateOfDose1
                    ? moment.utc(values.dateOfDose1, dateFormat).format()
                    : undefined,
            dateOfDose2:
                (values.doseNumber === '3' ||
                    values.doseNumber === '4' ||
                    values.doseNumber === '5') &&
                values.dateOfDose2
                    ? moment.utc(values.dateOfDose2, dateFormat).format()
                    : undefined,
            dateOfDose3Primary:
                ((values.doseNumber === '3' && values.vaccinationType !== 'PRIMARY') ||
                    values.doseNumber === '4' ||
                    values.doseNumber === '5') &&
                values.dateOfDose3Primary
                    ? moment.utc(values.dateOfDose3Primary, dateFormat).format()
                    : undefined,
            dateOfDose3:
                (values.doseNumber === '4' || values.doseNumber === '5') && values.dateOfDose3
                    ? moment.utc(values.dateOfDose3, dateFormat).format()
                    : undefined,
            dateOfDose4:
                values.doseNumber === '5' && values.dateOfDose4
                    ? moment.utc(values.dateOfDose4, dateFormat).format()
                    : undefined,
            dateOfPreviousDose: values.dateOfPreviousDose
                ? moment.utc(values.dateOfPreviousDose, dateFormat).format()
                : undefined,
            manufacturerOfDose1:
                values.doseNumber === '2' ||
                values.doseNumber === '3' ||
                values.doseNumber === '4' ||
                values.doseNumber === '5'
                    ? values.manufacturerOfDose1
                    : undefined,
            manufacturerOfDose2:
                values.doseNumber === '3' || values.doseNumber === '4' || values.doseNumber === '5'
                    ? values.manufacturerOfDose2
                    : undefined,
            manufacturerOfDose3:
                values.doseNumber === '4' || values.doseNumber === '5'
                    ? values.manufacturerOfDose3
                    : undefined,
            manufacturerOfDose3Primary:
                ((values.doseNumber === '3' && values.vaccinationType !== 'PRIMARY') ||
                    values.doseNumber === '4' ||
                    values.doseNumber === '5') &&
                values.dateOfDose3Primary
                    ? values.manufacturerOfDose3Primary
                    : undefined,
            manufacturerOfDose4: values.doseNumber === '5' ? values.manufacturerOfDose4 : undefined,
            jobType: 'vaccination',
            jobStatus: 'PENDING',
            organisation: org,
            priceInPence: 0,
            referralDateTime: undefined,
            buddyId: undefined,
            vaccinationType: values.vaccinationType,
            hcpId: values.hcpId || undefined,
            practice: name || undefined,
            odsCode: odsCode || undefined,
            version: 0,
        };

        try {
            await createJob({ variables: { input: data } });
            scrollToTop();
        } catch (err) {
            scrollToTop();
            return;
        }

        const nextCreatedVaccinationCategories = [
            ...createdVaccinationCategories,
            values.vaccinationCategory,
        ];

        const vaccinationCategoriesToAdd = values.coAdministeredWith.filter(
            (vaccinationCategory: string) =>
                !nextCreatedVaccinationCategories.includes(vaccinationCategory),
        );

        setVaccinationCategoriesToAdd(vaccinationCategoriesToAdd);

        if (vaccinationCategoriesToAdd.length > 0) {
            setShowAddNextVaccinationDialog(true);
        }

        setCreatedVaccinationCategories(nextCreatedVaccinationCategories);
    };

    return (
        <Formik
            initialValues={Vaccination.INITIAL_VALUES}
            validationSchema={validationSchema({ includeDateOfBirth: true })}
            onSubmit={onSubmit}
        >
            <AddPatientForm
                onClose={onClose}
                loading={loading}
                error={error}
                hubs={hubs}
                showAddNextVaccinationDialog={showAddNextVaccinationDialog}
                setShowAddNextVaccinationDialog={setShowAddNextVaccinationDialog}
                vaccinationCategoriesToAdd={vaccinationCategoriesToAdd}
                createdVaccinationCategories={createdVaccinationCategories}
            />
        </Formik>
    );
}
