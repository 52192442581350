import { Tooltip } from '@blueprintjs/core';
import { JobStatus, Patient } from '@doc-abode/data-models';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { FC, LegacyRef, useMemo } from 'react';

import { filterPatientAlerts } from '../../../../../../helpers/ucr';
import { isSecondDoubleUpHcp as isSecondDoubleUpHcpFunc } from '../../../../../../helpers/ucr/helpers';
import { AddressWarningIcon } from '../../../../../../helpers/ucr/icons';
import { createStepWarningsIcon } from '../../../../../../helpers/ucr/warnings';
import useStores from '../../../../../../hook/useStores';

import { IJobPos } from '../../../../../../interfaces/ucr';
import { FormattedWarning } from '../../../../../../stores/UCRStore';
import { ADMIN_TIME } from '../../../forms/AdminTime/AdminTimeConsts';
import { useView } from '../../../views/useView';
import { ViewToShow } from '../../../../../../constants/mainConst';
import { getIsAborted, getDoubleUpWithoutStaff } from './JobFunctions';
import { EnumJobContainer } from './JobTypes';
import WarningIcons from './WarningIcons';

interface IProps {
    pos?: IJobPos | any;
    job: Patient;
    showJobStatus?: boolean;
    container: EnumJobContainer;
    draggable?: boolean;
    preview?: boolean;
    elementRef?: LegacyRef<HTMLDivElement>;
}

export const getClassByStatus = (
    status?: string,
    trasformToCheck = (status: JobStatus) => status as string,
) => {
    switch (status) {
        case trasformToCheck(JobStatus.AVAILABLE):
        case trasformToCheck(JobStatus.PENDING):
            return 'available';
        case trasformToCheck(JobStatus.CURRENT):
            return 'current';
        case trasformToCheck(JobStatus.COMPLETED):
            return 'completed';
        case trasformToCheck(JobStatus.ACCEPTED):
            return 'accepted';
        case trasformToCheck(JobStatus.ARRIVED):
            return 'arrived';
        case trasformToCheck(JobStatus.HCP_ABORTED):
        case trasformToCheck(JobStatus.WITHDRAWN):
        case trasformToCheck(JobStatus.CONTROLLER_ABORTED):
            return 'aborted';
        default:
            return 'available';
    }
};

export const getLessHourTillVisit = (job: Patient, isSecondDoubleUpHcp: boolean) => {
    const today = moment();
    const startTime = isSecondDoubleUpHcp
        ? job.buddyArrivedDateTime || job.startDateTime
        : job.arrivedDateTime || job.startDateTime;

    const diffTime = moment(startTime).diff(moment(today)) / 60000;
    return diffTime <= 60 && diffTime > 0;
};

const getIsCompleted = (job: Patient, isBuddy: boolean) => {
    return isBuddy
        ? job.buddyJobStatus === JobStatus.COMPLETED
        : job.jobStatus === JobStatus.COMPLETED;
};

const getWarningStatus = (
    job: Patient,
    warnings: FormattedWarning,
    isSecondDoubleUpHcp = false,
    hcpUsers?: any,
): boolean => {
    let warningsStep = [];

    const isAdminTime = job.disposition === ADMIN_TIME;

    if (hcpUsers && hcpUsers.length > 0 && !isAdminTime) {
        warningsStep = createStepWarningsIcon(hcpUsers, job, isSecondDoubleUpHcp);
    }

    return (
        (warnings[job?.id]?.length > 0 || warningsStep.length > 0) &&
        !getIsAborted(job, isSecondDoubleUpHcp) &&
        !getIsCompleted(job, isSecondDoubleUpHcp)
    );
};

export const hasFirstIconsRow = (job: Patient, warnings: FormattedWarning): boolean => {
    return getDoubleUpWithoutStaff(job) || getWarningStatus(job, warnings);
};

const Job: FC<IProps> = ({
    pos = {
        height: 0,
        width: 0,
        isDoubleUp: false,
        hcpId: undefined,
        buddyId: undefined,
        offsetWidth: 0,
        isExpanded: false,
    },
    job,
    container,
    draggable = true,
    showJobStatus = true,
    preview = false,
    elementRef,
}) => {
    const isAdminTime = job.disposition === ADMIN_TIME;

    const {
        RootStore: {
            ucrStore: {
                warnings,
                patientAlerts: allPatientAlerts,
                focusedJobId,
                focusedUser,
                hcpsPos,
                draggedJobs,
                viewToShow,
            },
            usersStore: { hcpUsers },
            configStore: { pathways, adminTypes },
        },
    } = useStores() as { RootStore: any };

    const style = useMemo(() => {
        const styles: Record<string, unknown> = {};

        if ((pos.width && pos.height) || hcpsPos[pos.hcpId!]) {
            const height = hcpsPos[pos.hcpId!] ? hcpsPos[pos.hcpId!].height : pos.height;
            styles.width = `${pos.width}px`;
            styles.height = `${height}px`;
        }

        return styles;
    }, [pos.width, pos.height, pos.hcpId, hcpsPos]);

    const { openDeepLink } = useView();

    const onClickJob = () => {
        openDeepLink(job.id, pos.isSecondDoubleUpHcp ? 'user2' : 'user1');
    };

    const isSecondDoubleUpHcp = isSecondDoubleUpHcpFunc(job, pos as IJobPos);
    const isArrivedVisit = isSecondDoubleUpHcp
        ? job.buddyJobStatus === JobStatus.ARRIVED
        : job.jobStatus === JobStatus.ARRIVED;

    const { hasPatientAlerts, hasUnresolvedAlerts } = useMemo(
        () => filterPatientAlerts(allPatientAlerts, job.id),
        [allPatientAlerts, job.id],
    );

    const adminType = adminTypes.find((adminType: any) => {
        return adminType.value === job.activityType;
    });

    const bgClass = hasPatientAlerts
        ? hasUnresolvedAlerts
            ? 'alert-unresolved'
            : 'alert-resolved'
        : null;

    const borderClass = getClassByStatus(isSecondDoubleUpHcp ? job.buddyJobStatus : job.jobStatus);
    const isUnassigned =
        job.jobStatus === JobStatus.PENDING ||
        (job.staffRequired === 2 && job.buddyJobStatus === JobStatus.PENDING);
    const isFocused =
        !focusedUser ||
        (!pos.isDoubleUp && focusedUser === 'user1') ||
        (pos.isDoubleUp && focusedUser === 'user2');
    const jobStatus = Patient.getFriendlyVisitStatus(
        (isSecondDoubleUpHcp ? job.buddyJobStatus || job.jobStatus : job.jobStatus) as JobStatus,
    );
    const isDoubleUpVisit = job.staffRequired === 2 && job.buddyId && job.hcpId;
    const isDoubleUpWithoutStaff = getDoubleUpWithoutStaff(job);
    const isNonEnlishSpoken = job?.languagesSpoken?.length !== 0;
    const isCareComplex = job.careComplexity === 'complex';

    const warningStatus = useMemo(() => {
        return getWarningStatus(job, warnings, isSecondDoubleUpHcp, hcpUsers);
    }, [hcpUsers, isSecondDoubleUpHcp, job, warnings]);

    const hasBeenDragged = draggedJobs[job.id];
    const pathway = pathways.find((pathway: any) => pathway.value === job.referralPathway);
    const serviceProps = pathway?.services.find(
        (service: any) => service.value === job.disposition,
    );

    const isMapView = viewToShow === ViewToShow.MAP;
    const hasAddressWarning = isMapView && (!job.latitude || !job.longitude);
    const showAddressWarning = hasAddressWarning && container === EnumJobContainer.VISITS;

    const backgroundColor = serviceProps?.color || adminType?.color;

    return (
        <div
            className={cn('ucr__calendar-job', `ucr__calendar-job--${container}`, {
                [`ucr__calendar-job--${bgClass}`]: bgClass !== null,
            })}
            style={style}
            ref={elementRef}
        >
            {pos.offsetWidth && isArrivedVisit ? (
                <div
                    className="ucr__calendar-job-offset"
                    onClick={onClickJob}
                    style={{ width: pos.offsetWidth }}
                />
            ) : null}

            <button
                className={cn('ucr__calendar-job-inner', {
                    'ucr__calendar-job-inner--active-focused': focusedJobId === job.id && isFocused,
                    'ucr__calendar-job-inner--active': focusedJobId === job.id && !isFocused,
                    'ucr__calendar-job-inner--draggable': draggable,
                    'ucr__calendar-job-inner--has-been-dragged': hasBeenDragged,
                    'ucr__calendar-job-inner--preview': preview,
                })}
                onClick={onClickJob}
            >
                <div
                    className={cn(
                        'ucr__calendar-job-main',
                        `ucr__calendar-job-main--${borderClass}`,
                        {
                            [`ucr__calendar-job-main--${bgClass}`]: bgClass !== null,
                        },
                        {
                            'ucr__calendar-job-main--unassigned': !showJobStatus,
                        },
                        {
                            'ucr__calendar-job-main--partially-assigned':
                                job.hcpId && job.staffRequired === 2 && !job.buddyId,
                        },
                        {
                            'ucr__calendar-job-main--address-warning': hasAddressWarning,
                        },
                    )}
                    style={{
                        ...(backgroundColor &&
                            !hasUnresolvedAlerts &&
                            !hasAddressWarning && {
                                backgroundColor,
                            }),
                    }}
                >
                    <div
                        className={cn('ucr__calendar-job-about', {
                            'ucr__calendar-job-about--pl': showAddressWarning,
                        })}
                    >
                        <span
                            className="ucr__calendar-job-fullName"
                            aria-label={isAdminTime ? 'activity' : 'patient name'}
                        >
                            {showAddressWarning && (
                                <Tooltip
                                    position={'right'}
                                    popoverClassName={'ucr-listview__popover'}
                                    content="Address details could not be verified"
                                >
                                    <AddressWarningIcon className="ucr__calendar-job-about__icon" />
                                </Tooltip>
                            )}
                            {isAdminTime
                                ? job.activityType
                                : `${job.lastName}, ${job.firstName} ${job.middleName || ''}`}
                        </span>
                        {isAdminTime && (
                            <span className="ucr__calendar-job-fullName--admin-time">
                                {job.lastName
                                    ? `${job.lastName || ''}, ${job.firstName || ''} ${
                                          job.middleName || ''
                                      }`
                                    : `${job.firstName || ''} ${job.middleName || ''}`}
                            </span>
                        )}
                        <span className="ucr__calendar-job-fullName" aria-label="postcode">
                            {job.postCode}
                        </span>
                        {!isAdminTime && (
                            <span className="ucr__calendar-job-fullName ucr__calendar-job-pathway">
                                {pathway?.label || job.referralPathway}
                            </span>
                        )}
                        {!isAdminTime && isUnassigned && (
                            <span className="ucr__calendar-job-postCode">{job.referrer}</span>
                        )}
                        {isUnassigned &&
                        (job.startDateTime || job.dateOfVisit) &&
                        container !== EnumJobContainer.CALENDAR ? (
                            <span
                                className={cn('ucr__calendar-job-date', {
                                    'ucr__calendar-job-date--red': moment().isAfter(
                                        job.startDateTime || job.dateOfVisit,
                                        'day',
                                    ),
                                })}
                            >
                                {job.startDateTime
                                    ? moment(job.startDateTime).format('DD-MMM-YYYY H:mm')
                                    : moment(job.dateOfVisit).format('DD-MMM-YYYY')}
                            </span>
                        ) : undefined}
                    </div>

                    {!getIsAborted(job, isSecondDoubleUpHcp) ? (
                        <WarningIcons
                            container={container}
                            job={job}
                            warningStatus={warningStatus}
                            hasUnresolvedAlerts={hasUnresolvedAlerts}
                            isDoubleUpWithoutStaff={isDoubleUpWithoutStaff}
                            isCareComplex={isCareComplex}
                            isDoubleUpVisit={isDoubleUpVisit as boolean}
                            isNonEnlishSpoken={isNonEnlishSpoken}
                            isSecondDoubleUpHcp={isSecondDoubleUpHcp}
                        />
                    ) : null}
                </div>

                {showJobStatus && (
                    <div
                        className={cn(
                            'ucr__calendar-job-side',
                            `ucr__calendar-job-side--${borderClass}`,
                        )}
                    >
                        <span className="ucr__calendar-job-status">
                            {hasBeenDragged ? 'Updating' : jobStatus}
                        </span>
                    </div>
                )}
            </button>
        </div>
    );
};

export default observer(Job);
