import { FC, ReactNode } from 'react';
import { useField, FieldHookConfig } from 'formik';
import { Checkbox, CheckboxProps } from '@blueprintjs/core';

import { FormGroup } from '../FormGroup';

interface IProps extends CheckboxProps {
    name: string;
    className?: string;
    label?: string;
    title?: string;
    required?: boolean;
    children?: ReactNode;
}

const CheckboxComponent: FC<IProps> = ({
    className,
    label,
    title,
    required,
    children,
    ...props
}) => {
    // TODO better typing
    const [field, meta] = useField({ ...props, type: 'checkbox' } as FieldHookConfig<IProps>);

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            required={required}
            touched={meta.touched}
            error={meta.error}
        >
            {children ? (
                <Checkbox
                    /* TODO better typing */
                    {...((field as unknown) as CheckboxProps)}
                    {...(props as IProps)}
                    className="v2__form-checkbox-input"
                >
                    {children}
                </Checkbox>
            ) : (
                <Checkbox
                    /* TODO better typing */
                    {...((field as unknown) as CheckboxProps)}
                    {...(props as IProps)}
                    label={title}
                    className="v2__form-checkbox-input"
                />
            )}
        </FormGroup>
    );
};

export default CheckboxComponent;
