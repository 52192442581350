import moment from 'moment';
import { FormikValues } from 'formik';
import { JobStatus } from '@doc-abode/data-models';
import { getMomentEndTime } from './getEndDateTime';

export const getCareStepDisabledStatus = (values: FormikValues): boolean => {
    const today = moment();
    const isPastTime = values.startTime && moment(values.startTime).isBefore(today);
    const isSameOrAfter = values.visitDate && moment(values.visitDate).isSameOrAfter(today, 'day');
    let disabled = !!isPastTime;

    if (!values.startTime) {
        disabled = !isSameOrAfter;
    }

    disabled = values.jobStatus === JobStatus.PENDING && disabled;

    return disabled;
};

export const getCareStepWarnings = (values: FormikValues): string[] | [] => {
    const stepWarnings = [];
    const today = moment();

    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
        seconds: 0,
        milliseconds: 0,
    });

    const isPastTime = values.startTime && visitDateTime.isBefore(today);
    const isDoubleUp = Number(values.staffRequired) === 2;

    if (
        (isPastTime && !values.jobStatus) ||
        (isPastTime && values.jobStatus === JobStatus.PENDING) ||
        (isPastTime && values.jobStatus === JobStatus.ACCEPTED)
    ) {
        stepWarnings.push('Planned start time of the job is in the past!');
    }

    if (isDoubleUp && !values.buddyId?.length && values.hcpId?.length) {
        stepWarnings.push('Second staff member is not assigned!');
    }
    if (isDoubleUp && !values.hcpId?.length && values.buddyId?.length) {
        stepWarnings.push('First staff member is not assigned!');
    }

    return stepWarnings;
};

export const afterLatestTimeText = 'The planned time of visit is after to the latest time of visit';
export const beforeLatestTimeText =
    'The planned date or time of visit is prior to the earliest time of visit';

export const checkLatestTimeWarning = (values: FormikValues): string[] | [] => {
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const latestHours = values?.availableTo ? moment(values.availableTo).hour() : '23';
    const latestMinutes = values?.availableTo ? moment(values.availableTo).minute() : '00';
    const latestTimeVsPlaned = visitDateTime
        ? moment(visitDateTime).set({
              hour: Number(latestHours),
              minute: Number(latestMinutes),
          })
        : null;

    const isPlannedAfterLatestTime =
        values.availableTo &&
        moment(getMomentEndTime(visitDateTime.toDate(), values.duration)).isAfter(
            latestTimeVsPlaned,
        );

    if (isPlannedAfterLatestTime) {
        timeFrameWarnings.push(afterLatestTimeText);
    }
    return timeFrameWarnings;
};

export const getTimeFrameWarnings = (values: FormikValues): string[] | [] => {
    if (!values.availableFrom) return [];
    const timeFrameWarnings: string[] = [];
    const visitDateTime = moment(values.visitDate).set({
        hour: Number(moment(values.startTime).hour()),
        minutes: Number(moment(values.startTime).minutes()),
    });
    const visitDate = moment(values.visitDate).format('MM-DD-YYYY');
    const earliestHours = values?.availableFrom ? moment(values.availableFrom).hour() : '00';
    const earliestMinutes = values?.availableFrom ? moment(values.availableFrom).minute() : '00';
    const earliestDate = moment(values.earliestDateOfVisit).format('MM-DD-YYYY');
    const earliestTime = values.earliestDateOfVisit
        ? moment(earliestDate, 'MM-DD-YYYY').set({
              hour: Number(earliestHours),
              minute: Number(earliestMinutes),
              seconds: 0,
              milliseconds: 0,
          })
        : null;

    const visitTimeWithoutSeconds = moment(visitDate, 'MM-DD-YYYY').set({
        hour: visitDateTime.hour(),
        minute: visitDateTime.minute(),
        seconds: 0,
        milliseconds: 0,
    });

    const isBeforeEarliestTime =
        !!values.earliestDateOfVisit && visitTimeWithoutSeconds.isBefore(earliestTime);

    if (isBeforeEarliestTime) {
        timeFrameWarnings.push(beforeLatestTimeText);
    }
    return timeFrameWarnings;
};

export const getAllWarnings = (values: FormikValues): string[] => {
    const allWarnings = [];
    const isNotStarted =
        values.jobStatus === JobStatus.ACCEPTED ||
        values.jobStatus === JobStatus.PENDING ||
        !values.jobStatus;
    const isNotAborted =
        values.jobStatus !== JobStatus.HCP_ABORTED &&
        values.jobStatus !== JobStatus.CONTROLLER_ABORTED;

    if (isNotAborted && values.jobStatus !== JobStatus.COMPLETED) {
        allWarnings.push(...getTimeFrameWarnings(values));
    }
    if (isNotAborted) {
        allWarnings.push(...checkLatestTimeWarning(values));
    }

    if (isNotStarted || values.jobStatus === JobStatus.CURRENT) {
        allWarnings.push(...getCareStepWarnings(values));
    }

    return allWarnings;
};
