import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';

const SignIn = ({ didError, onSignIn, onToggleForgotPassword, onToggleForgotUsername }) => {
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        userName: '',
        password: '',
    };

    const validationSchema = Yup.object({
        userName: Yup.string()
            .matches(/^\S+$/, 'User Name cannot contain spaces.')
            .required('This field is required.'),
        password: Yup.string()
            .min(10, 'Password must contain at least ten characters.')
            .matches(
                /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/,
                'Password must contain at lease one upper case letter, one lower case letter and one number.',
            )
            .required('This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSignIn}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, values }) => {
                return (
                    <Form>
                        <h2 className="h2">Please sign in</h2>

                        <Input
                            name="userName"
                            label="Username, email address or phone number"
                            placeholder="e.g. jane.smith"
                            disabled={isSubmitting}
                            labelInfo="†"
                        />

                        <p>
                            <button
                                type="button"
                                className="minimal-button"
                                onClick={onToggleForgotUsername}
                            >
                                Forgotten your username?
                            </button>
                        </p>

                        <Input
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Password"
                            autoComplete="new-password"
                            disabled={isSubmitting}
                        />

                        <Checkbox
                            label="Show password"
                            checked={showPassword}
                            onChange={(event) => setShowPassword(event.target.checked)}
                        />

                        <p>
                            <button
                                type="button"
                                className="minimal-button"
                                onClick={onToggleForgotPassword}
                            >
                                Forgotten your password?
                            </button>
                        </p>

                        <Button
                            className="auth__submit-button"
                            loading={isSubmitting}
                            text="Sign in"
                            intent="success"
                            icon="log-in"
                            outlined
                            large
                            type="submit"
                            fill
                        />

                        <p className="auth__small-print">
                            † You may only use an email address or phone number in place of your
                            username if they have been verified. Otherwise you must log in using
                            your username, and once logged you will be prompted to verify your email
                            address or phone number.
                        </p>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default SignIn;
