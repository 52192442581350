import { FC } from 'react';
import { observer } from 'mobx-react';
import { CommonControls } from '../CommonControls';
import useStores from '../../../../../hook/useStores';
import { TagInputComponent } from '../../../../v2/form/TagInput/TagInput';

export const MapViewControls: FC = observer(() => {
    const {
        RootStore: {
            mapStore: { nameFilters, setNameFilters, refetchJobs },
        },
    } = useStores() as { RootStore: any };

    const onChangeInput = (name: string, values: string[]): void => {
        setNameFilters(name, values);
    };

    return (
        <CommonControls
            refresh={refetchJobs}
            additionalLeft={
                <>
                    <TagInputComponent
                        name="patientName"
                        className="ucr__filters-filter-input"
                        placeholder="Patient"
                        values={nameFilters.patientName}
                        onChange={(values) => onChangeInput('patientName', values as string[])}
                        inputProps={{ 'aria-label': 'patient filter' }}
                    />
                    <TagInputComponent
                        name="staffName"
                        className="ucr__filters-filter-input"
                        placeholder="Staff"
                        values={nameFilters.staffName}
                        onChange={(values) => onChangeInput('staffName', values as string[])}
                        inputProps={{ 'aria-label': 'staff filter' }}
                    />
                </>
            }
        />
    );
});
