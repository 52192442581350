import React, { useEffect } from 'react';
import {
    Collapse,
    Button,
    FormGroup,
    InputGroup,
    Menu,
    MenuItem,
    Popover,
} from '@blueprintjs/core';
import moment from 'moment';
import Select from 'react-select';
import CommonPagination from '../../CommonHRRotaPagination';
import Statuses from '../../../../common/Statuses';
import DateSelector from '../Builder/DateSelector';
import { IconFilter } from '../../../../../helpers/ucr/icons';
import CustomAsyncSelect from './CustomAsyncSelect';

const statusOptions = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Filled shifts' },
    { value: 2, label: 'Unfilled shifts' },
];

const rotaStatusOptions = [
    { value: '', label: 'All' },
    { value: true, label: 'Published' },
    { value: false, label: 'Draft' },
];

const shiftConflicts = [
    { value: '', label: 'All' },
    { value: 'MissingAsigment', label: 'Rotas with unfilled shifts due to missing assignment' },
    {
        value: 'ArchivedOrSuspendedStaff',
        label: 'Rotas with unfilled shifts due to archived or suspended staff',
    },
    { value: 'SchedulingConflict', label: 'Rotas with scheduling conflicts' },
    { value: 'InvalidTrainingShiftSetup', label: 'Rotas with invalid training shift setup' },
];

const RotaListNavigation = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onSearch,
    addNew,
    deleteItem,
    onFilter,
    minRota,
    loadStaffMembers,
    location,
    rotaBuilderStore,
}) => {
    const [dateRange, setDateRange] = React.useState([
        moment(minRota).toDate(),
        moment().add(1, 'month').toDate(),
    ]);
    const [status, setStatus] = React.useState(statusOptions[0]);
    const [showMoreFilters, setShowMoreFilters] = React.useState(true);
    const [publishedState, setPublishedState] = React.useState(rotaStatusOptions[0]);
    const [department, setDepartment] = React.useState('');
    const [createdByOptions, setCreatedByOptions] = React.useState([]);
    const [costCentreCodeOptions, setCostCentreCodeOptions] = React.useState([]);
    const [costCentreCode, setCostCentreCode] = React.useState('');
    const [costCentreOptions, setCostCentreOptions] = React.useState([]);
    const [costCentre, setCostCentre] = React.useState('');
    const [departmentCostCodeOptions, setDepartmentCostCodeOptions] = React.useState([]);
    const [departmentCostCode, setDepartmentCostCode] = React.useState('');
    const [departmentOptions, setDepartmentOptions] = React.useState([]);
    const [createdBy, setCreatedBy] = React.useState('');
    const [defaultStaffMember, setDefaultStaffMember] = React.useState('');
    const [confirmedStaffMember, setConfirmedStaffMember] = React.useState('');
    const [traineeStaffMember, setTraineeStaffMember] = React.useState('');
    const [shiftConflictsFilter, setShiftConflictsFilter] = React.useState(shiftConflicts[0]);
    const [usedFilters, setUsedFilters] = React.useState([]);

    const locationFromRota =
        !!location?.state?.prevPath?.pathname &&
        location?.state?.prevPath?.pathname.includes('builder');

    useEffect(() => {
        const createdByMembers = Array.from(
            new Set(
                rotaBuilderStore.allRotas.map(
                    (rota) => `${rota.createPerson.id}+SPLIT+${rota.createdBy}`,
                ),
            ),
        ).map((item) => {
            const [value, label] = item.split('+SPLIT+');
            return { label, value };
        });
        const departmentCostCodeOptions = extractOptionsFromRotas(
            rotaBuilderStore.allRotas,
            'department.costCode',
        );
        const costCentreCodeOptions = extractOptionsFromRotas(
            rotaBuilderStore.allRotas,
            'costCentre.code',
        );

        // get options for Cost centres and Departments
        const costCentreOptions = {};
        const departmentOptions = {};
        const costCentreOptionsNormalized = [];
        const departmentOptionsNormalized = [];
        rotaBuilderStore.allRotas.forEach((item) => {
            costCentreOptions[item.costCentre.id] = item.costCentre.name;
            departmentOptions[item.department.id] = item.department.name;
        });
        for (const [key, value] of Object.entries(costCentreOptions)) {
            costCentreOptionsNormalized.push({ label: value, value: key });
        }
        for (const [key, value] of Object.entries(departmentOptions)) {
            departmentOptionsNormalized.push({ label: value, value: key });
        }
        setCreatedByOptions(createdByMembers);
        setCostCentreCodeOptions(costCentreCodeOptions);
        setCostCentreOptions(costCentreOptionsNormalized);
        setDepartmentCostCodeOptions(departmentCostCodeOptions);
        setDepartmentOptions(departmentOptionsNormalized);
    }, [rotaBuilderStore.allRotas]);

    const extractOptionsFromRotas = (store, optionName) => {
        const optionNameParts = optionName.split('.');
        return Array.from(
            new Set(store.map((item) => item[optionNameParts[0]][optionNameParts[1]])),
        ).map((item) => ({
            label: item,
            value: item,
        }));
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('rotaBuilderFilters');
        if (locationFromRota && savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            for (const [key, value] of Object.entries(parsedFilters)) {
                setFiltersFromLocalStorage(key, value);
            }
        } else {
            localStorage.removeItem('rotaBuilderFilters');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const allFilters = {
            status,
            publishedState,
            costCentreCode,
            departmentCostCode,
            costCentre,
            department,
            createdBy,
            defaultStaffMember,
            confirmedStaffMember,
            traineeStaffMember,
            shiftConflictsFilter,
        };

        const updateFilterDeps = () => {
            for (const [key] of Object.entries(allFilters)) {
                const val =
                    allFilters[key].value || (allFilters[key].length && allFilters[key][0].value);
                if (val === '' || val === 0 || val === undefined || val === false) {
                    if (usedFilters.includes(key)) {
                        setUsedFilters((prev) => prev.filter((filter) => filter !== key));
                    }
                } else {
                    if (!usedFilters.includes(key)) {
                        setUsedFilters((prev) => [...prev, key]);
                    }
                }
            }
        };

        const updateFilters = () => {
            const filterParams = {
                dateRange,
                status: status.value,
            };

            const objToSave = { dateRange, status: status.value };
            if (publishedState.value !== '') {
                filterParams.published = publishedState.value;
                objToSave.published = publishedState;
            }
            if (department.value !== '') {
                filterParams.department = department.value;
                objToSave.department = department;
            }
            if (createdBy.length) {
                filterParams.createdBy = createdBy.map((member) => member.value);
                objToSave.createdBy = createdBy;
            }
            if (costCentreCode.length) {
                filterParams.costCentreCode = costCentreCode.map((item) => item.value);
                objToSave.costCentreCode = costCentreCode;
            }

            if (costCentre.length) {
                filterParams.costCentre = costCentre.map((item) => item.value);
                objToSave.costCentre = costCentre;
            }

            if (departmentCostCode.length) {
                filterParams.departmentCostCode = departmentCostCode.map((item) => item.value);
                objToSave.departmentCostCode = departmentCostCode;
            }

            if (department.length) {
                filterParams.department = department.map((item) => item.value);
                objToSave.department = department;
            }
            if (defaultStaffMember.length) {
                filterParams.defaultStaffMember = defaultStaffMember.map((member) => member.value);
                objToSave.defaultStaffMember = defaultStaffMember;
            }

            if (confirmedStaffMember.length) {
                filterParams.confirmedStaffMember = confirmedStaffMember.map(
                    (member) => member.value,
                );
                objToSave.confirmedStaffMember = confirmedStaffMember;
            }

            if (traineeStaffMember.length) {
                filterParams.traineeStaffMember = traineeStaffMember.map((member) => member.value);
                objToSave.traineeStaffMember = traineeStaffMember;
            }

            if (shiftConflictsFilter.value !== '') {
                filterParams.shiftIssue = shiftConflictsFilter.value;
                objToSave.shiftConflictsFilter = shiftConflictsFilter;
            }
            updateFilterDeps(); // add to dependency array - needed when we want to clear filters
            onFilter(filterParams); // send the request to get the list of filtered rotas
            saveToLocalStorage(objToSave);
        };

        const saveToLocalStorage = (filters) => {
            const objToSave = JSON.stringify(filters);
            localStorage.setItem('rotaBuilderFilters', objToSave);
        };

        updateFilters();
    }, [
        dateRange,
        status,
        costCentreCode,
        costCentre,
        departmentCostCode,
        publishedState,
        department,
        createdBy,
        defaultStaffMember,
        confirmedStaffMember,
        traineeStaffMember,
        shiftConflictsFilter,
        usedFilters,
        onFilter,
    ]);

    const setFiltersFromLocalStorage = (filterName, filterValue) => {
        switch (filterName) {
            case 'status':
                return setStatus(statusOptions[filterValue]);
            case 'dateRange':
                return setDateRange([new Date(filterValue[0]), new Date(filterValue[1])]);
            case 'confirmedStaffMember':
                return setConfirmedStaffMember(filterValue);
            case 'costCentre':
                return setCostCentre(filterValue);
            case 'costCentreCode':
                return setCostCentreCode(filterValue);
            case 'createdBy':
                return setCreatedBy(filterValue);
            case 'defaultStaffMember':
                return setDefaultStaffMember(filterValue);
            case 'department':
                return setDepartment(filterValue);
            case 'departmentCostCode':
                return setDepartmentCostCode(filterValue);
            case 'published':
                return setPublishedState(filterValue);
            case 'shiftConflictsFilter':
                return setShiftConflictsFilter(filterValue);
            case 'traineeStaffMember':
                return setTraineeStaffMember(filterValue);
            default:
                return;
        }
    };

    const onSearchChange = async (inputValue) => {
        if (inputValue && inputValue.length >= 3) {
            return onSearch(inputValue);
        } else if (inputValue?.length === 0) {
            return onSearch('');
        } else {
            return () => null;
        }
    };

    const clearFilters = () => {
        setStatus('');
        setCostCentreCode('');
        setDepartmentCostCode('');
        setCostCentre('');
        setPublishedState('');
        setDepartment('');
        setCreatedBy('');
        setDefaultStaffMember('');
        setConfirmedStaffMember('');
        setTraineeStaffMember('');
        setShiftConflictsFilter('');
        setDateRange([moment(minRota).toDate(), moment().add(1, 'month').toDate()]);
        setUsedFilters([]);
        localStorage.removeItem('rotaBuilderFilters');
    };

    const Content = () => (
        <Menu>
            <MenuItem
                className="bp5-intent-danger"
                disabled={rotaBuilderStore.selected.length < 1}
                onClick={deleteItem}
                text="Delete"
            />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup label="Status" className="common-header__filter">
                                <Select
                                    defaultValue={statusOptions[0]}
                                    options={statusOptions}
                                    onChange={(val) => {
                                        setStatus(val);
                                    }}
                                    value={status || statusOptions[0]}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Date range"
                                className="common-header__filter rota-builder__date-filter"
                            >
                                <DateSelector
                                    dateRange={dateRange}
                                    onChange={(val) => {
                                        setDateRange(val);
                                        setUsedFilters((prev) =>
                                            !prev.includes('dateRange')
                                                ? [...prev, 'dateRange']
                                                : prev,
                                        );
                                    }}
                                    minDate={moment(minRota).toDate()}
                                    maxDate={moment().add(1, 'years').toDate()}
                                />
                            </FormGroup>
                            <FormGroup
                                label="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Search..."
                                    large
                                />
                            </FormGroup>
                            <button
                                className="common-header__secondary-toggle btn btn--outlined"
                                onClick={() => setShowMoreFilters((prevState) => !prevState)}
                            >
                                <IconFilter /> {showMoreFilters ? 'Hide' : 'Show'} more filters
                            </button>
                            {usedFilters.length ? (
                                <button
                                    className="common-header__secondary-toggle btn btn--secondary"
                                    onClick={() => clearFilters()}
                                >
                                    Clear filters
                                </button>
                            ) : null}
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="rotas"
                        />
                    </div>
                    <Collapse
                        keepChildrenMounted={true}
                        isOpen={showMoreFilters}
                        className="common-header__secondary-filters"
                    >
                        <FormGroup label="Rota state" className="common-header__filter">
                            <Select
                                defaultValue={rotaStatusOptions[0]}
                                options={rotaStatusOptions}
                                onChange={(val) => {
                                    setPublishedState(val);
                                }}
                                value={publishedState || rotaStatusOptions[0]}
                            />
                        </FormGroup>
                        <FormGroup label="Cost centre code" className="common-header__filter">
                            <Select
                                options={costCentreCodeOptions}
                                isMulti
                                onChange={(val) => {
                                    setCostCentreCode(val);
                                }}
                                value={costCentreCode || ''}
                            />
                        </FormGroup>
                        <FormGroup label="Cost centre" className="common-header__filter">
                            <Select
                                options={costCentreOptions}
                                isMulti
                                onChange={(val) => {
                                    setCostCentre(val);
                                }}
                                value={costCentre || ''}
                            />
                        </FormGroup>
                        <FormGroup label="Department cost code" className="common-header__filter">
                            <Select
                                options={departmentCostCodeOptions}
                                isMulti
                                onChange={(val) => {
                                    setDepartmentCostCode(val);
                                }}
                                value={departmentCostCode || ''}
                            />
                        </FormGroup>
                        <FormGroup label="Department" className="common-header__filter">
                            <Select
                                isMulti
                                options={departmentOptions}
                                onChange={(val) => {
                                    setDepartment(val);
                                }}
                                value={department || ''}
                            />
                        </FormGroup>
                        <FormGroup label="Created by" className="common-header__filter">
                            <Select
                                options={createdByOptions}
                                isMulti
                                onChange={(val) => {
                                    setCreatedBy(val);
                                }}
                                value={createdBy || ''}
                            />
                        </FormGroup>
                        <FormGroup label="Default staff member" className="common-header__filter">
                            <CustomAsyncSelect
                                onLoadOptions={loadStaffMembers}
                                values={defaultStaffMember}
                                onChange={(values) => {
                                    setDefaultStaffMember(values);
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Confirmed staff member" className="common-header__filter">
                            <CustomAsyncSelect
                                onLoadOptions={loadStaffMembers}
                                values={confirmedStaffMember}
                                onChange={(values) => {
                                    setConfirmedStaffMember(values);
                                }}
                            />
                        </FormGroup>
                        <FormGroup label="Trainee" className="common-header__filter wider-filter">
                            <CustomAsyncSelect
                                onLoadOptions={loadStaffMembers}
                                values={traineeStaffMember}
                                onChange={(values) => {
                                    setTraineeStaffMember(values);
                                }}
                            />
                        </FormGroup>
                        <FormGroup
                            label="Shifts issues"
                            className="common-header__filter wider-filter"
                        >
                            <Select
                                defaultValue={shiftConflictsFilter[0]}
                                options={shiftConflicts}
                                onChange={(val) => {
                                    setShiftConflictsFilter(val);
                                }}
                                value={shiftConflictsFilter || shiftConflicts[0]}
                            />
                        </FormGroup>
                    </Collapse>
                    <div className="common-header__buttons">
                        <Button
                            text="New"
                            intent="success"
                            icon="add"
                            onClick={addNew}
                            className="common-header__button common-header__button--add bp5-outlined"
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content shouldDismissPopover={false} />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    className="bp5-outlined"
                                />
                            )}
                        />
                    </div>
                    <div className="common-header__statuses">
                        <Statuses />
                    </div>
                </>
            )}
        </nav>
    );
};
export default RotaListNavigation;
