import { baseUrl } from './baseApi';

export const getInvoices = (
    authToken: string,
    org: string,
    from: string,
    to: string,
): Promise<any> => {
    return fetch(`${baseUrl}/admin-invoicing/from/${from}/to/${to}`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};

export const downloadFile = (
    authToken: string,
    invoiceId: string,
    category: string,
): Promise<any> => {
    return fetch(`${baseUrl}/admin-invoicing/files/${category}/download/${invoiceId}`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};

export const mergeAndDownloadCSVs = (
    authToken: string,
    org: string,
    from: string,
    to: string,
): Promise<any> => {
    return fetch(`${baseUrl}/admin-invoicing/merge-and-download-csvs/from/${from}/to/${to}`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};
