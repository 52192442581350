import React, { Component } from 'react';
import { Tabs, Tab, Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import classNames from 'classnames';

const Header = inject('RootStore')(
    observer(
        class Header extends Component {
            render() {
                const {
                    match: {
                        params: { section },
                    },
                    RootStore: {
                        usersStore: { users },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                const availableUsers = users.filter((user) => user.available);

                const preventDragHandler = (e) => e.preventDefault();

                return (
                    <header className={classNames('header', 'ucr__header')}>
                        <Link to="/">
                            <img
                                src={'/images/logos/docabode_logo-white.png'}
                                alt="Doc Abode Logo"
                                className="header__logo"
                            />
                        </Link>
                        <Tabs
                            id="NavigationTabs"
                            selectedTabId={section}
                            className="header__tabs"
                            large
                        >
                            {isFeatureEnabled('onDemandJobs') && (
                                <Tab id="on-demand" onDragStart={preventDragHandler}>
                                    <Link to="/on-demand">
                                        <Icon icon="briefcase" className="header__tab-icon" />
                                        On-demand Jobs
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('scheduledJobs') && (
                                <Tab id="scheduled" onDragStart={preventDragHandler}>
                                    <Link to="/scheduled">
                                        <Icon icon="timeline-events" className="header__tab-icon" />
                                        Scheduled Jobs
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('vaccinations') && (
                                <Tab id="vaccinations" onDragStart={preventDragHandler}>
                                    <Link to="/vaccinations">
                                        <Icon icon="shield" className="header__tab-icon" />
                                        Vaccinations
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('ucr') && (
                                <Tab id="scheduling" onDragStart={preventDragHandler}>
                                    <Link to="/scheduling">
                                        <Icon icon="flash" className="header__tab-icon" />
                                        Scheduling
                                    </Link>
                                </Tab>
                            )}
                            <Tab id="users" onDragStart={preventDragHandler}>
                                <Link to="/users">
                                    <Icon icon="user" className="header__tab-icon" />
                                    HCPs
                                    <span className="nav__users-available">
                                        {' '}
                                        {availableUsers.length}
                                    </span>
                                    /<span className="nav__users-total">{users.length}</span>
                                </Link>
                            </Tab>
                            <Tab id="logout" onDragStart={preventDragHandler}>
                                <Link
                                    to="/logout"
                                    onClick={() => this.props.RootStore.userStore.logOut()}
                                >
                                    <Icon icon="log-out" className="header__tab-icon" />
                                    Log out
                                </Link>
                            </Tab>
                        </Tabs>
                    </header>
                );
            }
        },
    ),
);

export default Header;
