import { camelCaseString, formatParserAPIDate } from '../helpers/formatData';

const SystemOneParser = async (patientDetails, caseNarrative) => ({
    patientDetails: parsePatientDetails(patientDetails),
    caseNarrative: parseCaseNarrative(caseNarrative),
    rawCaseNarrative: caseNarrative,
});

export const parsePatientDetails = (patientDetails) => {
    if (patientDetails) {
        const patientLines = patientDetails.split('\n');
        let title = '';
        let firstName = '';
        let lastName = '';
        let dateOfBirth = '';
        let age = '';
        let gender = '';
        let foundDate = false;
        let dateIndex = null;

        // Ignore all lines in the patient details apart from the first one
        let patientPersonalData = patientLines[0].split(' ');

        patientPersonalData.map((detail, index) => {
            // Date of birth
            if (Number.isInteger(parseInt(detail, 0)) && !foundDate) {
                foundDate = true;
                dateIndex = index;
                dateOfBirth = `${detail} ${patientPersonalData[index + 1]} ${
                    patientPersonalData[index + 2]
                }`;
                dateOfBirth = formatParserAPIDate(dateOfBirth);
            }

            // Age
            if (detail.includes('(')) {
                age = detail.replace('(', '').replace('y', '').replace(')', '');
            }

            // Gender
            if (index + 1 === patientPersonalData.length) {
                gender = detail.replace(/ /g, '').replace('\n', '');
                if (gender === 'F' || gender === 'f') {
                    gender = 'female';
                } else if (gender === 'M' || gender === 'm') {
                    gender = 'male';
                } else {
                    gender = '';
                }
            }

            return '';
        });

        // Remove gender, age, date of birth
        patientPersonalData.length = dateIndex;

        // Title
        title = patientPersonalData[0];

        // Remove title
        patientPersonalData.splice(0, 1);

        // Last name
        lastName = patientPersonalData[patientPersonalData.length - 1];

        // Remove last name if there is one
        if (patientPersonalData.length > 1) {
            patientPersonalData.length = patientPersonalData.length - 1;

            // First name - more than one name
            if (patientPersonalData.length > 1) {
                patientPersonalData.map((name, i) => {
                    if (i === 0) {
                        firstName = name;

                        // add space between subsequent names
                    } else {
                        firstName = firstName + ' ' + name;
                    }
                    return '';
                });

                // Only one first name
            } else {
                firstName = patientPersonalData[0];
            }
        }

        return { title, firstName, lastName, dateOfBirth, age, gender };
    } else {
        return {};
    }
};

export const parseCaseNarrative = (caseNarrative) => {
    if (caseNarrative) {
        // Split on empty lines (two line breaks)
        let narrative = caseNarrative.split('\n\n');

        // Parse every line separately
        narrative = narrative.map((field) => {
            let obj = {};
            const fieldLines = field.split('\n');
            const lineOne = fieldLines[0];
            const lineTwo = fieldLines[1];

            // LINE ONE
            const lineOneParsed = lineOne.replace('   Delete', '').split(' - ');
            const name = camelCaseString(lineOneParsed[0]);
            obj[name] = {};

            // date
            if (lineOneParsed[1]) {
                obj[name]['date'] = formatParserAPIDate(lineOneParsed[1]);
            }

            // person
            if (lineOneParsed[2]) {
                obj[name]['person'] = lineOneParsed[2];
            }

            // LINE TWO
            if (lineTwo) {
                // Splitting the line at the first instance of ': '
                // all subsequent ': ' are retained in the second part of the split array
                // Useful example: https://stackoverflow.com/questions/4607745/split-string-only-on-first-instance-of-specified-character
                const lineTwoParsed = lineTwo.split(/: (.+)/);
                let fieldName = lineTwoParsed[0].replace('    ', '');
                let fieldValue = lineTwoParsed[1];

                // Check if the value exists, if there is no value
                // then the 'name' IS the value
                if (fieldValue) {
                    obj[name]['fieldName'] = fieldName;
                    obj[name]['fieldValue'] = fieldValue;
                } else {
                    obj[name]['fieldValue'] = fieldName;
                }

                // ==============================
                // LOCATION
                // ==============================

                // Location needs to be parsed separately because we need to explicitly know
                // what each piece of data is (i.e. post code, town, etc.).
                // And also the data is split into 3-4 lines rather than just on one line.
                if (name === 'currentLocation') {
                    let location = fieldLines;
                    let lineTwo = '';
                    let town = '';
                    let postCode = '';

                    // Remove empty indentation spaces
                    for (let i = 0; i < fieldLines.length; i++) {
                        fieldLines[i] = fieldLines[i].replace(/ {2}/g, '');
                    }

                    // If there are at least 4 address lines
                    // Address line TWO is the second one
                    // Address line ONE is already stored in fieldLines[1], so there's no need to set it
                    if (location.length >= 5) {
                        lineTwo = fieldLines[2];
                    }

                    // Town is always the second to last line
                    town = fieldLines[location.length - 2];

                    // Postcode is always the last line
                    postCode = fieldLines[location.length - 1];

                    obj[name]['fieldValue'] = {
                        addressLineOne: obj[name]['fieldValue'],
                        addressLineTwo: lineTwo,
                        town,
                        postCode,
                    };
                }

                // ==============================
                // CONTACT NUMBER
                // ==============================

                if (name === 'contactTelephoneRecorded') {
                    // Remove empty spaces
                    obj[name]['fieldValue'] = obj[name]['fieldValue'].replace(/ /g, '');
                }

                // ==============================
                // PRACTICE
                // ==============================

                if (name === 'registeredPractice') {
                    // Remove () and set to uppercase
                    obj[name]['fieldValue'] = obj[name]['fieldValue']
                        .replace('(', '')
                        .replace(')', '')
                        .toUpperCase();
                }
            }

            return obj;
        });

        // Covert the array of fields/lines into an object of fields/lines instead
        narrative = narrative.reduce(function (result, item) {
            const key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
        }, {});

        return narrative;
    } else {
        return {};
    }
};

export default SystemOneParser;
