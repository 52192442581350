import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import InputSuggest from '../../../../common/inputSuggest/InputSuggest';

import Error from '../../../../modules/forms/Error';

const LocationSummary = ({
    location,
    locationNames,
    editOrCreateMode,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
}) => {
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'locations'}
                componentPath={'/hr/locations/'}
                items={locationNames}
                editOrCreateMode={editOrCreateMode}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                className={'departments_suggest'}
            />
            <dl className="info">
                <dt className="info__title">Address 1</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="address1">
                            <InputGroup
                                id="address1"
                                name="address1"
                                onChange={handleChange}
                                value={values.address1}
                                large
                            />
                            {touched.address1 && <Error errors={[errors.address1]} />}
                        </FormGroup>
                    ) : (
                        location.address1
                    )}
                </dd>

                <dt className="info__title">Address 2</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="address2">
                            <InputGroup
                                id="address2"
                                name="address2"
                                onChange={handleChange}
                                value={values.address2}
                                disabled={values.address1 ? false : true}
                                large
                            />
                            {touched.address2 && <Error errors={[errors.address2]} />}
                        </FormGroup>
                    ) : (
                        location.address2
                    )}
                </dd>

                <dt className="info__title">Address 3</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="address3">
                            <InputGroup
                                id="address3"
                                name="address3"
                                onChange={handleChange}
                                value={values.address3}
                                disabled={values.address1 && values.address2 ? false : true}
                                large
                            />
                            {touched.address3 && <Error errors={[errors.address3]} />}
                        </FormGroup>
                    ) : (
                        location.address3
                    )}
                </dd>

                <dt className="info__title">Town/City</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="townCity">
                            <InputGroup
                                id="townCity"
                                name="townCity"
                                onChange={handleChange}
                                value={values.townCity}
                                large
                            />
                            {touched.townCity && <Error errors={[errors.townCity]} />}
                        </FormGroup>
                    ) : (
                        location.townCity
                    )}
                </dd>

                <dt className="info__title">Postcode</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="postcode">
                            <InputGroup
                                id="postcode"
                                name="postcode"
                                onChange={handleChange}
                                value={values.postcode}
                                large
                            />
                            {touched.postcode && <Error errors={[errors.postcode]} />}
                        </FormGroup>
                    ) : (
                        location.postcode
                    )}
                </dd>
            </dl>
        </>
    );
};

export default LocationSummary;
