import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Row, Col } from 'react-grid-system';
import { Button, FormGroup, TextArea, Callout, Dialog } from '@blueprintjs/core';

import Error from '../../forms/Error';
import { removePhoneLeadingZero, addPhoneCountryCode } from '../../helpers/formatData';
import SystemOneParser from '../../helpers/SystemOneParser';
import SystmOneAppointmentBookParser from '../../helpers/SystmOneAppointmentBookParser';
import XmlParser from '../../helpers/XmlParser';
import EMISWebParser from '../../helpers/EMISWebParser';
import AdastraParser from '../../helpers/AdastraParser';

const defaultState = {
    showModal: false,
    patientDetails: '',
    caseNarrative: '',
    errors: [],
};

const STANDARD_ERROR_MESSAGE = 'Incompatible data entered';

const SYSTM_ONE_ERROR_MESSAGE =
    'Incompatible data entered, please check it was entered into the correct field';

const getErrorMessage = (errors) => {
    if (errors.length === 0) {
        return [];
    }

    return errors.includes('patientDetails') ? [SYSTM_ONE_ERROR_MESSAGE] : [STANDARD_ERROR_MESSAGE];
};

const patentDetailsLabels = {
    XML: 'Paste XML formatted case details here:',
    SYSTM_ONE: 'Paste SystmOne patient details here:',
    SYSTM_ONE_APPT_BOOK: 'Paste SystmOne appointment book patient details here:',
    EMIS_WEB: 'Paste EMIS Web case details here:',
    ADASTRA: 'Paste exported Adastra case details here:',
};

const AutoPopulate = inject('RootStore')(
    observer(
        class AutoPopulate extends Component {
            state = defaultState;

            handleShowModal = () => {
                this.setState({ showModal: true });
            };

            handleCloseModal = () => {
                this.setState(defaultState);
            };

            _validSystmOne() {
                const errors = [];
                const IDENTIFIER = 'Current Location -';

                if (this.state.patientDetails.includes(IDENTIFIER)) {
                    errors.push('patientDetails');
                }

                if (!this.state.caseNarrative.includes(IDENTIFIER)) {
                    errors.push('caseNarrative');
                }

                this.setState({ errors });

                return errors.length === 0;
            }

            handlePopulate = async () => {
                const { autoPopulateParser } = this.props.RootStore.configStore;
                try {
                    switch (autoPopulateParser) {
                        case 'SYSTM_ONE':
                            return this.handleSystemOnePopulate();
                        case 'SYSTM_ONE_APPT_BOOK':
                            return this.handleSystemOneAppointmentBookPopulate();
                        case 'EMIS_WEB':
                            return this.handleEMISWebPopulate();
                        case 'ADASTRA':
                            return this.handleAdastraPopulate();
                        default:
                            return this.handleXmlPopulate();
                    }
                } catch (err) {
                    console.error(err);
                    this.setState({
                        errors: [...this.state.errors, autoPopulateParser],
                    });
                }
            };

            handleSystemOnePopulate = async () => {
                if (!this._validSystmOne()) {
                    return;
                }

                const parsedFields = await SystemOneParser(
                    this.state.patientDetails,
                    this.state.caseNarrative,
                );
                let narrative = parsedFields.caseNarrative;
                let patient = parsedFields.patientDetails;

                const fields = {
                    disposition: narrative.priorityUpdate
                        ? narrative.priorityUpdate.fieldValue.toLowerCase()
                        : narrative.definitivelyClinicallyAssessed
                        ? narrative.definitivelyClinicallyAssessed.fieldValue.toLowerCase()
                        : '',
                    nhsNumber: patient.nhsNumber ? patient.nhsNumber : '',
                    firstName: patient.firstName ? patient.firstName : '',
                    lastName: patient.lastName ? patient.lastName : '',
                    dateOfBirth: patient.dateOfBirth ? patient.dateOfBirth : '',
                    age: patient.age ? patient.age : '',
                    contactNumber: narrative.contactTelephoneRecorded
                        ? addPhoneCountryCode(
                              removePhoneLeadingZero(narrative.contactTelephoneRecorded.fieldValue),
                          )
                        : '',
                    addressLine1: narrative.currentLocation
                        ? narrative.currentLocation.fieldValue.addressLineOne
                        : '',
                    addressLine2: narrative.currentLocation
                        ? narrative.currentLocation.fieldValue.addressLineTwo
                        : '',
                    town: narrative.currentLocation
                        ? narrative.currentLocation.fieldValue.town
                        : '',
                    postCode: narrative.currentLocation
                        ? narrative.currentLocation.fieldValue.postCode
                        : '',
                    practice: narrative.registeredPractice
                        ? narrative.registeredPractice.fieldValue
                        : '',
                    notes: parsedFields.rawCaseNarrative,
                };

                this.props.onAutoPopulate(fields);
                this.handleCloseModal();
            };

            handleXmlPopulate = async () => {
                const parsedFields = XmlParser(this.state.patientDetails);
                const fields = {
                    systmOneRef: parsedFields.externalJobReference
                        ? parsedFields.externalJobReference[0]
                        : '',
                    jobType: parsedFields.jobType ? parsedFields.jobType[0] : '',
                    disposition: parsedFields.disposition
                        ? parsedFields.disposition[0].toLowerCase()
                        : '',
                    firstName: parsedFields.firstName ? parsedFields.firstName[0] : '',
                    lastName: parsedFields.lastName ? parsedFields.lastName[0] : '',
                    dateOfBirth: parsedFields.dOB ? parsedFields.dOB[0] : '',
                    age: parsedFields.age ? parsedFields.age[0] : '',
                    gender: parsedFields.gender ? parsedFields.gender[0].toLowerCase() : '',
                    contactNumber: parsedFields.contactNumber
                        ? addPhoneCountryCode(removePhoneLeadingZero(parsedFields.contactNumber[0]))
                        : '',
                    addressLine1: parsedFields.addressLine1 ? parsedFields.addressLine1[0] : '',
                    addressLine2: parsedFields.addressLine2 ? parsedFields.addressLine2[0] : '',
                    town: parsedFields.townCity ? parsedFields.townCity[0] : '',
                    postCode: parsedFields.postCode ? parsedFields.postCode[0] : '',
                    notes: parsedFields.notes ? parsedFields.notes[0] : '',
                    hcpType: parsedFields.hCPType ? parsedFields.hCPType : [],
                    nhsNumber: parsedFields.nHSNumber ? parsedFields.nHSNumber[0] : '',
                    // Adstrata may not a value in here so will need to populate it as unknown controller can always change this after
                    practice:
                        parsedFields.practice && parsedFields.practice[0] !== ''
                            ? parsedFields.practice[0]
                            : 'UNKNOWN',
                };
                this.props.onAutoPopulate(fields);
                this.handleCloseModal();
            };

            handleSystemOneAppointmentBookPopulate = () => {
                const parsedFields = SystmOneAppointmentBookParser(this.state.patientDetails);
                this.props.onAutoPopulate(parsedFields);
                this.handleCloseModal();
            };

            handleEMISWebPopulate = () => {
                const parsedFields = EMISWebParser(this.state.patientDetails);
                this.props.onAutoPopulate(parsedFields);
                this.handleCloseModal();
            };

            handleAdastraPopulate = () => {
                const parsedFields = AdastraParser(this.state.patientDetails);
                this.props.onAutoPopulate(parsedFields);
                this.handleCloseModal();
            };

            handleFieldChange = (e) => {
                this.setState({
                    [e.target.id]: e.target.value,
                });
            };

            render() {
                const { autoPopulateParser } = this.props.RootStore.configStore;
                const { errors } = this.state;

                return (
                    <div className="auto-populate">
                        <Callout intent="primary" icon={null}>
                            <Row>
                                <Col xs={12} md={6} lg={7}>
                                    Paste job details to auto-populate the relevant fields.
                                </Col>
                                <Col xs={12} md={6} lg={5}>
                                    <Button
                                        large
                                        onClick={this.handleShowModal}
                                        fill
                                        icon="manually-entered-data"
                                    >
                                        Populate
                                    </Button>
                                </Col>
                            </Row>
                        </Callout>
                        <Dialog
                            isOpen={this.state.showModal}
                            onConfirm={this.handlePopulate}
                            onClose={this.handleCloseModal}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            title="Auto-populate job details"
                            icon="manually-entered-data"
                        >
                            <div className="auto-populate__dialog-contents">
                                {/* PATIENT DETAILS */}
                                <FormGroup
                                    label={patentDetailsLabels[autoPopulateParser]}
                                    labelFor="patientDetails"
                                >
                                    <TextArea
                                        id="patientDetails"
                                        fill
                                        rows={10}
                                        value={this.state.patientDetails}
                                        onChange={this.handleFieldChange}
                                    />
                                    <Error errors={getErrorMessage(errors)} />
                                </FormGroup>

                                {/* CASE NARRATIVE */}
                                {autoPopulateParser === 'SYSTM_ONE' && (
                                    <FormGroup
                                        label="Paste SystmOne case narrative here:"
                                        labelFor="caseNarrative"
                                    >
                                        <TextArea
                                            id="caseNarrative"
                                            fill
                                            rows={10}
                                            value={this.state.caseNarrative}
                                            onChange={this.handleFieldChange}
                                        />
                                        <Error
                                            errors={
                                                errors.includes('caseNarrative')
                                                    ? [SYSTM_ONE_ERROR_MESSAGE]
                                                    : []
                                            }
                                        />
                                    </FormGroup>
                                )}

                                <footer className="bp5-alert-footer">
                                    <Button intent="primary" onClick={this.handlePopulate}>
                                        Populate
                                    </Button>
                                    <Button onClick={this.handleCloseModal}>Cancel</Button>
                                </footer>
                            </div>
                        </Dialog>
                    </div>
                );
            }
        },
    ),
);

export default AutoPopulate;
