import moment from 'moment';
import { capitalize } from 'lodash';

const formatPhoneNumber = (number) => {
    if (number) {
        return number.split(': ')[1].replace(/[()\s]/g, '');
    }
};

const EMISWebParser = (rawContent) => {
    const lines = rawContent
        .replace(/^\n/, '')
        .split('\n')
        .map((string) => string.trim());

    // Find date of birth

    let dateOfBirth;
    let age;
    let dateOfBirthIndex;

    lines.find((line, index) => {
        const match = /^\d{2}-\w{3}-\d{4}/.test(line);

        if (match) {
            const parts = line.split(' ');
            dateOfBirth = moment.utc(parts[0], 'DD-MMM-YYYY').format('YYYY-MM-DDTHH:mm:ss');
            dateOfBirthIndex = index;

            if (parts[1]) {
                age = parts[1].slice(1);
            }
        }

        return match;
    });

    // Get name, NHS number, EMIS number and gender

    const [lastName, firstName] = lines[0].split(' (')[0].split(', ').map(capitalize);
    const systmOneRef = lines[dateOfBirthIndex - 1];
    const gender = lines[dateOfBirthIndex + 1].toLowerCase();
    let nhsNumber;

    if (dateOfBirthIndex === 3) {
        nhsNumber = lines[1];
    }

    // Find post code

    let postCode;
    let postCodeIndex;

    lines.find((line, index) => {
        const stringToTest = line.toUpperCase();
        const match = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(stringToTest);

        if (match) {
            postCode = stringToTest;
            postCodeIndex = index;
        }

        return match;
    });

    // Get address

    const addressLines = lines.slice(dateOfBirthIndex + 2, postCodeIndex);

    const addressLine1 = addressLines.shift();
    let addressLine2;
    let town;

    if (addressLines.length === 1) {
        [town] = addressLines;
    } else {
        [addressLine2, town] = addressLines;
    }

    // Get contact number

    const mobile = lines.find((line) => line.startsWith('Mobile:'));
    const home = lines.find((line) => line.startsWith('Home:'));

    const contactNumber = formatPhoneNumber(mobile || home);

    return {
        dateOfBirth,
        age,
        firstName,
        lastName,
        gender,
        systmOneRef,
        nhsNumber,
        addressLine1,
        addressLine2,
        town,
        postCode,
        contactNumber,
    };
};

export default EMISWebParser;
