import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/client/react';
import { relayStylePagination } from '@apollo/client/utilities';

import HR from './HR';
import Rota from './Rota';
import Loader from '../../modules/helpers/Loader';
import { rootUrl } from '../../../api/baseApi';
import { getBearerToken } from '../../../api/amplifyApi';
import AppToaster from '../../modules/helpers/Toaster';

const { REACT_APP_GRAPHQL_URL } = process.env;

const createApolloClient = (organisation, httpLink, cacheOptions, tokenOptions) => {
    const authLink = setContext(async (_, { headers }) => {
        const Authorization = await getBearerToken(tokenOptions);

        return {
            headers: {
                ...headers,
                Authorization,
                'x-docabode-organisation': organisation,
            },
        };
    });

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(cacheOptions),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });
};

export const routes = [
    { path: '/hr', component: HR },
    { path: '/rota', component: Rota },
];

const Router = ({ homePage }) => {
    return (
        <Switch>
            {routes.map(({ path, component, exact }) => (
                <Route key={path} path={path} component={component} exact={exact} />
            ))}
        </Switch>
    );
};

const HRRota = inject('RootStore')(
    observer(
        class HRRota extends Component {
            state = {
                apolloClient: null,
                error: null,
            };

            componentDidMount() {
                const {
                    configStore,
                    userStore,
                    dictionaryStore,
                    locationsStore,
                    shiftPatternsStore,
                    payRatesStore,
                    groupsStore,
                    specialitiesStore,
                    peopleStore,
                    documentStore,
                    staffStore,
                    rolesStore,
                    rotaBuilderStore,
                    alertsStore,
                    departmentsStore,
                    agenciesStore,
                    inductionTasksStore,
                    holidaysStore,
                    fundingPoolStore,
                    registrationStore,
                    professionalBodyStore,
                    fitToPracticeStore,
                    costCentresStore,
                    functionsStore,
                    departmentAssignmentsStore,
                    absencesStore,
                } = this.props.RootStore;

                when(
                    () => configStore.loaded,
                    async () => {
                        const httpLink = createHttpLink({
                            uri: REACT_APP_GRAPHQL_URL || `${rootUrl}/hr-rota/graphql`,
                        });

                        const apolloClient = createApolloClient(
                            configStore.org,
                            httpLink,
                            {
                                typePolicies: {
                                    Query: {
                                        fields: {
                                            getPeopleList: relayStylePagination(),
                                        },
                                    },
                                },
                            },
                            {
                                asBearerToken: true,
                            },
                        );

                        this.setState({ apolloClient });

                        try {
                            await dictionaryStore.load(apolloClient);
                            userStore.init(apolloClient);
                            groupsStore.init(apolloClient);
                            specialitiesStore.init(apolloClient);
                            locationsStore.init(apolloClient);
                            rotaBuilderStore.init(apolloClient);
                            shiftPatternsStore.init(apolloClient);
                            payRatesStore.init(apolloClient);
                            peopleStore.init(apolloClient);
                            documentStore.init(apolloClient);
                            staffStore.init(apolloClient);
                            rolesStore.init(apolloClient);
                            alertsStore.init(apolloClient);
                            departmentsStore.init(apolloClient);
                            agenciesStore.init(apolloClient);
                            inductionTasksStore.init(apolloClient);
                            holidaysStore.init(apolloClient);
                            fundingPoolStore.init(apolloClient);
                            registrationStore.init(apolloClient);
                            professionalBodyStore.init(apolloClient);
                            fitToPracticeStore.init(apolloClient);
                            costCentresStore.init(apolloClient);
                            functionsStore.init(apolloClient);
                            departmentAssignmentsStore.init(apolloClient);
                            absencesStore.init(apolloClient);
                        } catch (error) {
                            this.setState({ error });
                            AppToaster.show({
                                message:
                                    'Sorry, there was a problem loading the page. Your user may not be configured for HR/Rota. Please contact an administrator.',
                                intent: 'danger',
                            });
                        }
                    },
                );
            }

            componentWillUnmount() {
                const {
                    locationsStore,
                    shiftPatternsStore,
                    payRatesStore,
                    groupsStore,
                    specialitiesStore,
                } = this.props.RootStore;

                locationsStore.dispose();
                shiftPatternsStore.dispose();
                payRatesStore.dispose();
                groupsStore.dispose();
                specialitiesStore.dispose();
            }

            render() {
                const {
                    configStore: { loaded, isFeatureEnabled },
                    dictionaryStore: { loaded: isDictionaryLoaded },
                } = this.props.RootStore;

                const { apolloClient, error } = this.state;

                if (error) {
                    return null;
                }

                if (!loaded || !isDictionaryLoaded) {
                    return (
                        <div>
                            <Loader fullscreen={false} />
                        </div>
                    );
                }

                let homePage;

                if (isFeatureEnabled('hr')) {
                    homePage = '/hr';
                } else {
                    homePage = '/rota';
                }

                return (
                    <ApolloProvider client={apolloClient}>
                        <Router props={this.props} homePage={homePage} />
                    </ApolloProvider>
                );
            }
        },
    ),
);

export default HRRota;
