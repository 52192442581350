import React, { ReactNode, useState } from 'react';
import { Alert, Checkbox } from '@blueprintjs/core';

interface ConfirmationProps {
    [x: string]: any;
    onConfirm: any;
    onCancel: (() => void) | undefined;
    isOpen: boolean;
    title?: string | undefined;
    description?: string | ReactNode | undefined;
    checkboxLabel?: string | undefined;
    hasCheckbox?: boolean | undefined;
}

const ConfirmationAlert = (props: ConfirmationProps) => {
    const [confirmCheckbox, setConfirmCheckbox] = useState(false);
    const {
        onConfirm,
        onCancel,
        isOpen,
        hasCheckbox = false,
        checkboxLabel = 'Please confirm that you wish to delete these items',
        title = 'Confirm action!',
        description = '',
        icon = 'trash',
        intent = 'danger',
        ...rest
    } = props;
    return (
        <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            className={`confirmation-alert ${
                hasCheckbox && !confirmCheckbox && 'confirmation-alert--not-confirmed'
            }`}
            onConfirm={hasCheckbox && !confirmCheckbox ? void 0 : onConfirm}
            onCancel={onCancel}
            isOpen={isOpen}
            icon={icon}
            intent={intent}
            canEscapeKeyCancel={true}
            {...rest}
        >
            <h4>{title}</h4>
            {typeof description === 'string' ? <p>{description}</p> : description}
            {hasCheckbox && (
                <Checkbox
                    checked={confirmCheckbox}
                    label={checkboxLabel}
                    onChange={() => setConfirmCheckbox(!confirmCheckbox)}
                />
            )}
        </Alert>
    );
};

export default ConfirmationAlert;
