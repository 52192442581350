import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Callout } from '@blueprintjs/core';

const calloutLabel = (count) => (
    <>
        <strong>{count}</strong> {count === 1 ? 'job' : 'jobs'}
    </>
);

const Stats = inject('RootStore')(
    observer(
        class Stats extends Component {
            _setJobStatusEmphasis = (status) => {
                // Set statusToEmphasise or remove it if already set to this value
                const nextStatus =
                    this.props.RootStore.jobsStore.jobStatusToEmphasise === status ? null : status;
                this.props.RootStore.jobsStore.setJobStatusToEmphasise(nextStatus);
            };

            render() {
                const {
                    RootStore: {
                        jobsStore: {
                            scheduled,
                            availableJobs,
                            acceptedJobs,
                            currentJobs,
                            arrivedJobs,
                            requireAttentionJobs,
                            historicJobs,
                            jobStatusToEmphasise,
                        },
                    },
                } = this.props;

                const inProgressJobs = [...scheduled(currentJobs), ...scheduled(arrivedJobs)];

                const available = scheduled(availableJobs).length;
                const accepted = scheduled(acceptedJobs).length;
                const inProgress = inProgressJobs.length;
                const requireAttention = scheduled(requireAttentionJobs).length;
                const aborted = scheduled(historicJobs).filter(
                    (job) => job.jobStatus === 'CONTROLLER_ABORTED',
                ).length;
                const completed = scheduled(historicJobs).filter(
                    (job) => job.jobStatus === 'COMPLETED',
                ).length;

                return (
                    <div className="stats">
                        <button
                            onClick={() => this._setJobStatusEmphasis('AVAILABLE')}
                            className={`stats__button ${
                                available > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'AVAILABLE'
                                    ? 'stats__button--selected'
                                    : ''
                            }`}
                        >
                            <Callout className="stats__callout" intent="warning" icon="stopwatch">
                                {calloutLabel(available)} available
                            </Callout>
                        </button>

                        <button
                            onClick={() => this._setJobStatusEmphasis('ACCEPTED')}
                            className={`stats__button ${
                                accepted > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'ACCEPTED' ? 'stats__button--selected' : ''
                            }`}
                        >
                            <Callout className="stats__callout" intent="success" icon="confirm">
                                {calloutLabel(accepted)} accepted
                            </Callout>
                        </button>

                        <button
                            onClick={() => this._setJobStatusEmphasis('IN_PROGRESS')}
                            className={`stats__button ${
                                inProgress > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'IN_PROGRESS'
                                    ? 'stats__button--selected'
                                    : ''
                            }`}
                        >
                            <Callout className="stats__callout" intent="primary" icon="play">
                                {calloutLabel(inProgress)} in progress
                            </Callout>
                        </button>

                        <button
                            onClick={() => this._setJobStatusEmphasis('REQUIRE_ATTENTION')}
                            className={`stats__button ${
                                requireAttention > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'REQUIRE_ATTENTION'
                                    ? 'stats__button--selected'
                                    : ''
                            }`}
                        >
                            <Callout className="stats__callout" intent="danger">
                                {calloutLabel(requireAttention)} require
                                {requireAttention === 1 && 's'} attention
                            </Callout>
                        </button>

                        <button
                            onClick={() => this._setJobStatusEmphasis('COMPLETED')}
                            className={`stats__button ${
                                completed > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'COMPLETED'
                                    ? 'stats__button--selected'
                                    : ''
                            }`}
                        >
                            <Callout icon="tick-circle" className="stats__callout">
                                {calloutLabel(completed)} completed{' '}
                                <span className="stats__info">last 60 days</span>
                            </Callout>
                        </button>

                        <button
                            onClick={() => this._setJobStatusEmphasis('CONTROLLER_ABORTED')}
                            className={`stats__button ${
                                aborted > 0 ? 'stats__button--clickable' : ''
                            } ${
                                jobStatusToEmphasise === 'CONTROLLER_ABORTED'
                                    ? 'stats__button--selected'
                                    : ''
                            }`}
                        >
                            <Callout className="stats__callout" icon="ban-circle">
                                {calloutLabel(aborted)} aborted by controller{' '}
                                <span className="stats__info">last 60 days</span>
                            </Callout>
                        </button>
                    </div>
                );
            }
        },
    ),
);

export default Stats;
