import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../helpers/Loader';
import JobForm from './JobForm';

const EditJob = inject('RootStore')(
    observer(
        class EditJob extends Component {
            render() {
                const { id } = this.props.match.params;
                const job = this.props.RootStore.jobsStore.getJob(id);

                if (this.props.RootStore.jobsStore.totalJobsNumber && job) {
                    let title = 'Edit job';

                    if (job.systmOneRef) {
                        title += `: ${job.systmOneRef}`;
                    }

                    return <JobForm title={title} actionType="edit" {...this.props} />;
                } else {
                    return <Loader />;
                }
            }
        },
    ),
);

export default EditJob;
