import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import GMapComponent from '../modules/map/GMap';

const Map = inject('RootStore')(
    observer(
        class Map extends Component {
            handleMarkerClick = (jobId) => {
                this.props.history.push(`/on-demand/${this.props.match.params.tab}/jobs/${jobId}`);
            };

            render() {
                const {
                    configStore,
                    jobsStore: {
                        onDemand,
                        availableJobs,
                        acceptedJobs,
                        currentJobs,
                        arrivedJobs,
                        requireAttentionJobs,
                    },
                } = this.props.RootStore;

                return (
                    <div className="map-container">
                        {configStore.clientKeys.googleMaps && (
                            <GMapComponent
                                data={[
                                    {
                                        type: 'available',
                                        data: onDemand(availableJobs),
                                    },
                                    {
                                        type: 'accepted',
                                        data: onDemand(acceptedJobs),
                                    },
                                    {
                                        type: 'current',
                                        data: onDemand(currentJobs),
                                    },
                                    {
                                        type: 'arrived',
                                        data: onDemand(arrivedJobs),
                                    },
                                    {
                                        type: 'requireAttention',
                                        data: onDemand(requireAttentionJobs),
                                    },
                                ]}
                                onMarkerClick={this.handleMarkerClick.bind(this)}
                                zoom={configStore.mapZoomLevel}
                                centre={configStore.mapCentre}
                                apiKey={configStore.clientKeys.googleMaps}
                            />
                        )}
                    </div>
                );
            }
        },
    ),
);

export default Map;
