import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import PayRatesTableRow from './PayRatesTableRow';
import PayRatesTableHeader from './PayRatesTableHeader';
import PayRatesTableNav from './PayRatesTableNav';
import Loader from '../../../modules/helpers/Loader';
import PayRatesDetails from '../../../modules/payRates/detail/PayRatesDetails';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import _ from 'lodash';
import { Alert } from '@blueprintjs/core';

const PayRates = inject('RootStore')(
    observer(
        class AdminManagePayRates extends Component {
            state = {
                showDeleteAlert: false,
                showSomeDeleteAlert: false,
                showDeniedDeleteAlert: false,
                showArchiveAlert: false,
                showSomeArchiveAlert: false,
                showDeniedArchiveAlert: false,
            };

            componentWillUnmount() {
                this.props.RootStore.payRatesStore.clear();
            }

            _handleRowClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/pay-rates/${id}`);
                }
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/pay-rates/new`);
            };

            _handleToggleSelectAll = async (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.payRatesStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
                await this.props.RootStore.payRatesStore.validateArchiveSelectedPayRates();
                await this.props.RootStore.payRatesStore.validateDeletableSelectedPayRates();
            };

            _handleToggleSelected = async (event) => {
                const { select, deselect } = this.props.RootStore.payRatesStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
                await this.props.RootStore.payRatesStore.validateArchiveSelectedPayRates();
                await this.props.RootStore.payRatesStore.validateDeletableSelectedPayRates();
            };

            _handleToggleDelete = () => {
                const {
                    someDeletableSelectedPayRates,
                    allDeletableSelectedPayRates,
                } = this.props.RootStore.payRatesStore;

                if (someDeletableSelectedPayRates && !allDeletableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showSomeDeleteAlert: !prevState.showSomeDeleteAlert };
                    });
                } else if (someDeletableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showDeleteAlert: !prevState.showDeleteAlert };
                    });
                } else if (!allDeletableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showDeniedDeleteAlert: !prevState.showDeniedDeleteAlert };
                    });
                }
            };

            _handleToggleArchive = () => {
                const {
                    someArchivableSelectedPayRates,
                    allArchivableSelectedPayRates,
                } = this.props.RootStore.payRatesStore;

                if (someArchivableSelectedPayRates && !allArchivableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showSomeArchiveAlert: !prevState.showSomeArchiveAlert };
                    });
                } else if (someArchivableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else if (!allArchivableSelectedPayRates) {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _handleActionEditPayRate = () => {
                let { selected } = this.props.RootStore.payRatesStore;
                selected.length &&
                    this.props?.history?.push(`/hr/pay-rates/${selected[0]}`, {
                        actionType: 'edit',
                        editModeOpenedFromViewList: true,
                    });
            };
            _handleBulkDelete = async () => {
                this.setState({
                    showDeleteAlert: false,
                    showSomeDeleteAlert: false,
                    showDeniedDeleteAlert: false,
                });
                const isNotEmpty = !_.isEmpty(
                    this.props.RootStore.payRatesStore.validatedArchivePayRates,
                );
                const alreadyArchivedPayRatesFilter = this.props.RootStore.payRatesStore.validatedArchivePayRates
                    .filter((pr) => pr.isArchivable)
                    .flatMap((pr) => pr.id);

                isNotEmpty &&
                    (await this.props.RootStore.payRatesStore.bulkDeletePayRates(
                        alreadyArchivedPayRatesFilter,
                    ));
            };

            _handleBulkArchive = async () => {
                this.setState({
                    showArchiveAlert: false,
                    showSomeArchiveAlert: false,
                    showDeniedArchiveAlert: false,
                });
                const isNotEmpty = !_.isEmpty(
                    this.props.RootStore.payRatesStore.validatedArchivePayRates,
                );
                const archivablePayRates = this.props.RootStore.payRatesStore.validatedArchivePayRates
                    .filter((pr) => pr.isArchivable)
                    .flatMap((pr) => pr.id);

                isNotEmpty &&
                    (await this.props.RootStore.payRatesStore.bulkArchivePayRates(
                        archivablePayRates,
                    ));
            };

            render() {
                const {
                    RootStore: {
                        payRatesStore: {
                            selected,
                            gridPayRates,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            loaded,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}

                        <main className="common-layout__main">
                            <PayRatesTableNav
                                selected={selected}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleDelete}
                                archiveItem={this._handleToggleArchive}
                                editItem={this._handleActionEditPayRate}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <PayRatesTableHeader
                                    data={gridPayRates}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridPayRates.map((payRate) => (
                                        <PayRatesTableRow
                                            {...payRate}
                                            onClick={this._handleRowClick}
                                            key={payRate.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <PayRatesDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleBulkDelete}
                            onCancel={this._handleToggleDelete}
                            isOpen={this.state.showDeleteAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these pay rates? You won't be
                                        able to recover them."
                        />
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleBulkDelete}
                            onCancel={this._handleToggleDelete}
                            isOpen={this.state.showSomeDeleteAlert}
                            title="Confirm deletion!"
                            description={
                                <div>
                                    The following pay rates cannot be deleted because they are
                                    related to other active entities and/or future shifts :
                                    <br />
                                    <ul>
                                        {this.props.RootStore.payRatesStore.validatedDeletablePayRates?.map(
                                            (payRate) =>
                                                !payRate.isDeletable && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/pay-rates/${payRate.id}`}
                                                        >
                                                            <b>{payRate.name}</b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                    <br />
                                    <ul>
                                        Do you want to continue with the deleting of :
                                        {this.props.RootStore.payRatesStore.validatedDeletablePayRates?.map(
                                            (payRate) =>
                                                payRate.isDeletable && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/pay-rates/${payRate.id}`}
                                                        >
                                                            <b>{payRate.name}</b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </div>
                            }
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description="Are you sure you want to archive the selected pay rate(s)?  This action is irreversible."
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showSomeArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description={
                                <div>
                                    <p>
                                        The following pay rates cannot be archived because they are
                                        related to other active entities and/or future shifts :
                                    </p>
                                    <ul>
                                        {this.props.RootStore.payRatesStore.validatedArchivePayRates?.map(
                                            (payRate) =>
                                                !payRate.isArchivable && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/pay-rates/${payRate.id}`}
                                                        >
                                                            <b> {payRate.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                    <p>Do you want to continue with the archiving of :</p>
                                    <ul>
                                        {this.props.RootStore.payRatesStore.validatedArchivePayRates?.map(
                                            (payRate) =>
                                                payRate.isArchivable && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/pay-rates/${payRate.id}`}
                                                        >
                                                            <b> {payRate.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </div>
                            }
                        />
                        <Alert
                            confirmButtonText="OK"
                            intent={'primary'}
                            isOpen={this.state.showDeniedDeleteAlert}
                            onClose={() => {
                                this.setState({ showDeniedDeleteAlert: false });
                            }}
                        >
                            <p>
                                The selected pay rates cannot be deleted because they are related to
                                other active entities and/or future shifts
                            </p>
                        </Alert>
                        <Alert
                            confirmButtonText="OK"
                            intent={'primary'}
                            isOpen={this.state.showDeniedArchiveAlert}
                            onClose={() => {
                                this.setState({ showDeniedArchiveAlert: false });
                            }}
                        >
                            <p>
                                The selected pay rates cannot be archived because they are related
                                to other active entities and/or future shifts
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default PayRates;
