import * as Yup from 'yup';
import moment from 'moment';
import { dateFormat } from '../../../../../constants/patientsConst';
import { formatToDate } from '../../../../modules/helpers/formatData';

export const validationSchema = Yup.object({
    reasonId: Yup.string().required('This field is required.'),
    statusId: Yup.string().required('This field is required.'),
    startDate: Yup.date().required('This field is required.').nullable(),
    startTime: Yup.string()
        .required('This field is required.')
        .matches(
            /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
            'Time must be in the format HH:MM in the range 00:00 - 23:59',
        ),
    endDate: Yup.date()
        .nullable()
        .required('This field is required.')
        .when('startDate', (startDate, schema) => {
            return startDate
                ? schema.test(
                      'endDate',
                      '"End Date" cannot be earlier than "Start Date"',
                      (value) => {
                          if (!value) {
                              return false;
                          }
                          const from = moment(startDate, dateFormat).startOf('day');
                          const to = moment(value, dateFormat).startOf('day');
                          return from?.valueOf() <= to?.valueOf();
                      },
                  )
                : schema;
        }),
    endTime: Yup.string()
        .required('This field is required.')
        .matches(
            /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
            'Time must be in the format HH:MM in the range 00:00 - 23:59',
        )
        .when(['startTime', 'startDate', 'endDate'], (startTime, startDate, endDate, schema) => {
            if (startTime && !moment(startDate).isValid() && !moment(endDate).isValid()) {
                return schema.test(
                    'endTime',
                    '"End Time" cannot be earlier than "Start Time"',
                    (value) => {
                        if (!value) {
                            return false;
                        }
                        const from = new Date('1/1/1999 ' + startTime);
                        const to = new Date('1/1/1999 ' + value);
                        return from < to;
                    },
                );
            } else if (startTime && startDate && endDate) {
                return schema.test(
                    'endTime',
                    '"End Time" cannot be earlier than "Start Time"',
                    (value) => {
                        if (!value) {
                            return false;
                        }
                        const startDateModified = moment(startDate).format(dateFormat);
                        const from = formatToDate(startDateModified, startTime);
                        const endDateModified = moment(endDate).format(dateFormat);
                        const to = formatToDate(endDateModified, value);
                        return from?.valueOf() < to?.valueOf();
                    },
                );
            }
        }),
    notes: Yup.string(),
});
