import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button, Checkbox } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';

const CompleteNewPassword = ({ didError, onCompleteNewPassword }) => {
    const [showPassword, setShowPassword] = useState(false);

    const initialValues = {
        newPassword: '',
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(10, 'Password must contain at least ten characters.')
            .matches(
                /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/,
                'Password must contain at lease one upper case letter, one lower case letter and one number.',
            )
            .required('This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onCompleteNewPassword}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Complete new password</h2>

                        <Input
                            name="newPassword"
                            type={showPassword ? 'text' : 'password'}
                            label="New password"
                            autoComplete="new-password"
                            disabled={isSubmitting}
                        />

                        <Checkbox
                            label="Show password"
                            checked={showPassword}
                            onChange={(event) => setShowPassword(event.target.checked)}
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Confirm new password"
                            outlined
                            icon="tick"
                            type="submit"
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default CompleteNewPassword;
