import React from 'react';

import { Form } from 'formik';
import { FormGroup, TextArea, Icon, Button, Checkbox, InputGroup } from '@blueprintjs/core';
import Select from 'react-select';
import { DateInput3 } from '@blueprintjs/datetime2';
import { TimePicker } from '@blueprintjs/datetime';
import moment from 'moment';
import Error from '../../../../modules/forms/Error';
import { momentFormatter } from '../../../../modules/helpers/formatData';
import { dateFormat } from '../../../../../constants/patientsConst';
import MultipleFileInput from '../../../../common/formik/MultipleFileInput';
import { Row, Col } from 'react-grid-system';
import AsyncSelect from 'react-select/async';

interface AbsenceFormProps {
    values: any;
    formWasEdited: boolean;
    dictionaryStore: any;
    touched: any;
    errors: any;
    org: string;
    isSubmitting: any;
    actionType: string;
    history: any;
    newForm: boolean;
    personProfilePage: boolean;
    setFieldValue: (valName: string, val: any) => void;
    setValues: (val: any) => void;
    handleChange: (e: any) => void;
    toggleIsEdit: () => void;
    loadPersonsOptions: (val: string) => any;
    closeAbsenceRecordFromProfile: () => void;
}

const AbsenceForm = ({
    values,
    formWasEdited,
    dictionaryStore,
    touched,
    errors,
    org,
    isSubmitting,
    actionType,
    history,
    newForm,
    personProfilePage,
    setFieldValue,
    setValues,
    loadPersonsOptions,
    closeAbsenceRecordFromProfile,
}: AbsenceFormProps) => {
    const timeToDate = React.useCallback((time: string) => {
        const [hour, minute] = time.split(':');
        return moment()
            .hours(+hour)
            .minutes(+minute)
            .toDate();
    }, []);

    const dateToTime = React.useCallback((date: Date) => {
        return (
            date.getHours().toString().padStart(2, '0') +
            ':' +
            date.getMinutes().toString().padStart(2, '0')
        );
    }, []);

    const isAllDay = (values: any) => values.startTime === '00:00' && values.endTime === '23:59';

    const toggleCheckbox = (setValues: any, values: any) => {
        if (values.startTime || values.endTime) {
            setValues({
                ...values,
                startTime: '',
                endTime: '',
            });
        } else {
            setValues({
                ...values,
                startTime: '00:00',
                endTime: '23:59',
            });
        }
    };

    const setTimeInRightInterval = (time: string, interval: number): string => {
        const timeSplit = time.split(':');
        const numberOfWholeMinutes = Math.trunc(Number(timeSplit[1]) / interval) * interval;
        const minutesRest = Number(timeSplit[1]) % interval;
        // check if the time was passed in right interval just return the time passed
        if (minutesRest === 0) return time;
        if (minutesRest < 8) {
            return `${timeSplit[0]}:${numberOfWholeMinutes}`;
        }
        /* check if the number of minutes that we will pass equals 60 in that case
         we will increase hours by '1' and pass "00" to minutes so everything works correctly */
        if (numberOfWholeMinutes + interval === 60) {
            return `${Number(timeSplit[0]) + 1}:00`;
        } else {
            return `${timeSplit[0]}:${numberOfWholeMinutes + interval}`;
        }
    };

    return (
        <>
            <Form className="absence__form">
                {!personProfilePage && (
                    <>
                        <Row>
                            <Col md={4}>
                                <FormGroup labelFor="personId" label="Person record*">
                                    <AsyncSelect
                                        loadOptions={loadPersonsOptions}
                                        noOptionsMessage={(val: any) =>
                                            val.inputValue && val.inputValue.length > 2
                                                ? 'No results'
                                                : 'Type at least three characters to see results'
                                        }
                                        onChange={async (e: any) => {
                                            setFieldValue('personId', e?.value ?? null);
                                            setFieldValue('departments', e?.departments ?? []);
                                            setFieldValue('roles', e?.roles ?? []);
                                        }}
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        defaultInputValue={values.name}
                                        /* TODO work out what large should be now we are on version 5*/
                                        //large
                                        isClearable
                                        menuPlacement="bottom"
                                        isDisabled={newForm ? false : true}
                                        className={!newForm ? 'disabled-async-select' : ''}
                                    />
                                    {touched.personId && <Error errors={[errors.personId]} />}
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                {values.personId ? (
                                    <a
                                        href={`/hr/people/${values.personId}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="bp5-button bp5-large bp5-intent-primary no-label"
                                    >
                                        View Person Record
                                    </a>
                                ) : null}
                            </Col>
                        </Row>
                        {values.personId ? (
                            <Row>
                                <Col md={4}>
                                    <FormGroup labelFor="roles" label="Roles">
                                        <InputGroup
                                            id="Roles"
                                            name="Roles"
                                            value={values.roles.toLocaleString().replace(',', ' ')}
                                            large
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md={4} offset={{ md: 2 }}>
                                    <FormGroup labelFor="departments" label="Departments">
                                        <InputGroup
                                            id="Departments"
                                            name="Departments"
                                            value={values.departments
                                                .toLocaleString()
                                                .replace(',', ', ')}
                                            large
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        ) : null}
                    </>
                )}

                <Row>
                    <Col md={4}>
                        <FormGroup labelFor="reasonId" label="Absence type*">
                            <Select
                                options={dictionaryStore.absenceTypes}
                                defaultValue={{
                                    label: dictionaryStore.absenceTypesDictionary[values.reasonId],
                                    value: values.reasonId,
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                onChange={(e: any) => setFieldValue('reasonId', e.value)}
                            />
                            {touched.reasonId && <Error errors={[errors.reasonId]} />}
                        </FormGroup>
                        <Row>
                            <Col md={8}>
                                <FormGroup
                                    labelFor="startDate"
                                    className="date input-with-icon"
                                    label="Start"
                                >
                                    <Icon icon="calendar" className="icon-near-input" />
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        {...momentFormatter(dateFormat)}
                                        onChange={(value) => setFieldValue('startDate', value)}
                                        minDate={moment().subtract(1, 'years').toDate()}
                                        maxDate={moment().add(1, 'years').toDate()}
                                        placeholder="Select date"
                                        value={values.startDate}
                                    />
                                    {touched.startDate && <Error errors={[errors.startDate]} />}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup
                                    labelFor="startTime"
                                    className="time no-label input-with-icon"
                                >
                                    <Icon icon="time" className="icon-near-input" />
                                    <TimePicker
                                        value={timeToDate(values.startTime)}
                                        onChange={(date) => {
                                            setFieldValue(
                                                `startTime`,
                                                setTimeInRightInterval(dateToTime(date), 15),
                                            );
                                        }}
                                    />
                                    {touched.startTime && <Error errors={[errors.startTime]} />}
                                </FormGroup>
                                <Checkbox
                                    id="startTime"
                                    label="All Day"
                                    checked={isAllDay(values)}
                                    onChange={() => toggleCheckbox(setValues, values)}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} offset={{ md: 2 }}>
                        <FormGroup labelFor="statusId" label="Approval status*">
                            <Select
                                options={dictionaryStore.absenceStatuses}
                                defaultValue={{
                                    label:
                                        dictionaryStore.absenceStatusesDictionary[values.statusId],
                                    value: values.statusId,
                                }}
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                                onChange={(e: any) => setFieldValue('statusId', e.value)}
                            />
                            {touched.statusId && <Error errors={[errors.statusId]} />}
                        </FormGroup>
                        <Row>
                            <Col md={8}>
                                <FormGroup
                                    labelFor="endDate"
                                    className="date input-with-icon"
                                    label="End"
                                >
                                    <Icon icon="calendar" className="icon-near-input" />
                                    <DateInput3
                                        showTimezoneSelect={false}
                                        {...momentFormatter(dateFormat)}
                                        onChange={(value) => setFieldValue('endDate', value)}
                                        minDate={moment().subtract(1, 'years').toDate()}
                                        maxDate={moment().add(1, 'years').toDate()}
                                        placeholder="Select date"
                                        value={values.endDate}
                                    />
                                    {touched.endDate && <Error errors={[errors.endDate]} />}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup
                                    labelFor="endTime"
                                    className="time no-label input-with-icon"
                                >
                                    <Icon icon="time" className="icon-near-input" />
                                    <TimePicker
                                        value={timeToDate(values.endTime)}
                                        onChange={(date) => {
                                            setFieldValue(
                                                `endTime`,
                                                setTimeInRightInterval(dateToTime(date), 15),
                                            );
                                        }}
                                    />
                                    {touched.endTime && <Error errors={[errors.endTime]} />}
                                </FormGroup>
                                <Checkbox
                                    id="startTime"
                                    label="All Day"
                                    checked={isAllDay(values)}
                                    onChange={() => toggleCheckbox(setValues, values)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>
                        <FormGroup labelFor="documents" label="Documents">
                            <MultipleFileInput org={org} maxFiles={5} />
                        </FormGroup>
                        <FormGroup labelFor="notes" className="notes-field" label="Notes">
                            <TextArea
                                growVertically={true}
                                large={true}
                                onChange={(e) => {
                                    setFieldValue(`notes`, e.target.value);
                                }}
                                value={values.notes}
                                fill
                                rows={3}
                            />
                            {touched.notes && <Error errors={[errors.notes]} />}
                        </FormGroup>
                    </Col>
                </Row>
                <div className="absence__footer">
                    <Button intent="primary" large type="submit" loading={isSubmitting}>
                        {actionType === 'create' ? 'Create absence' : 'Save'}
                    </Button>
                    <Button
                        large
                        onClick={() => {
                            return formWasEdited
                                ? history.replace(history.location.pathname)
                                : history.replace(
                                      personProfilePage
                                          ? closeAbsenceRecordFromProfile()
                                          : '/hr/absence-management',
                                  );
                        }}
                        className="no-intent"
                    >
                        Cancel
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default AbsenceForm;
