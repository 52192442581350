import { gql } from '@apollo/client';

export const GET_REGISTRATIONS = gql`
    query GetRegistrations(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $filter: RegistrationFilterInput
    ) {
        getRegistrations(
            first: $first
            last: $last
            before: $before
            after: $after
            filter: $filter
        ) {
            edges {
                node {
                    id
                    personId
                    updatedAt
                    createdAt
                    fitToPracticeId
                    professionalBodyId
                    registrationContent
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedPerson {
                        firstName
                        lastName
                    }
                    fitToPractice {
                        label
                    }
                    professionalBody {
                        name
                        key
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_REGISTRATION = gql`
    query GetRegistration($id: String!) {
        getRegistration(id: $id) {
            id
            personId
            updatedAt
            createdAt
            fitToPracticeId
            professionalBodyId
            registrationContent
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
            fitToPractice {
                label
            }
            professionalBody {
                name
                key
            }
        }
    }
`;

export const CREATE_REGISTRATION = gql`
    mutation CreateRegistration($data: RegistrationInput!) {
        createRegistration(data: $data) {
            id
        }
    }
`;

export const UPDATE_REGISTRATION = gql`
    mutation UpdateRegistration($id: String!, $data: RegistrationInput!) {
        updateRegistration(id: $id, data: $data) {
            id
            personId
            updatedAt
            createdAt
            fitToPracticeId
            professionalBodyId
            registrationContent
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
            fitToPractice {
                label
            }
            professionalBody {
                name
                key
            }
        }
    }
`;

export const DELETE_REGISTRATION = gql`
    mutation DeleteRegistration($id: String!) {
        deleteRegistration(id: $id)
    }
`;
