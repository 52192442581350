import { WeekHoursByDays } from './EmployeeDetailsTypes';

type DaysList = Array<{
    name: keyof WeekHoursByDays;
    label: string;
}>;

export const days: DaysList = [
    {
        name: 'mondayContractedHours',
        label: 'Mon',
    },
    {
        name: 'tuesdayContractedHours',
        label: 'Tue',
    },
    {
        name: 'wednesdayContractedHours',
        label: 'Wed',
    },
    {
        name: 'thursdayContractedHours',
        label: 'Thu',
    },
    {
        name: 'fridayContractedHours',
        label: 'Fri',
    },
    {
        name: 'saturdayContractedHours',
        label: 'Sat',
    },
    {
        name: 'sundayContractedHours',
        label: 'Sun',
    },
];
