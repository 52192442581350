import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _, { isEmpty } from 'lodash';

import {
    CREATE_AGENCY,
    DELETE_AGENCY,
    UPDATE_AGENCY,
    GET_AGENCY,
    GET_AGENCIES_LIST,
    BULK_DELETE_AGENCIES,
    GET_AGENCY_BY_NAME,
    VALIDATE_ARCHIVE_AGENCIES,
    ARCHIVE_AGENCY,
    BULK_ARCHIVE_AGENCIES,
} from '../graphql/queries/agencies';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';

class AgenciesStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            selected: observable,
            agencies: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridAgencies: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getAgencies: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    agencies = [];
    allAgencies = [];
    allAgenciesActive = [];
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeAgencies = (agency) => ({
        id: agency.id,
        selected: this.selected.includes(agency.id),
        name: agency.name,
        createdAt: agency.createdAt,
        updatedAt: agency.updatedAt,
        createdBy: agency.createdBy,
        updatedBy: agency.updatedBy,
        isArchived: agency.isArchived,
        isDeleted: agency.isDeleted,
        createdPerson: !isEmpty(agency.createdPerson) ? agency.createdPerson : '',
        updatedPerson: !isEmpty(agency.updatedPerson) ? agency.updatedPerson : '',
    });

    get gridAgencies() {
        const agencies = this.agencies.map(this.normalizeAgencies);
        if (this.sortFn) {
            agencies.sort(this.sortFn);
        }
        return agencies;
    }

    get allSelected() {
        return this.gridAgencies.every((r) => this.selected.includes(r.id));
    }

    async createAgency(agency) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_AGENCY,
                variables: {
                    data: {
                        name: agency.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Agency created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating agency record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteAgency(agency) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_AGENCY,
                variables: {
                    id: agency.id,
                },
            });
            AppToaster.show({
                message: 'Agency deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting agency record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateAgency(agency) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_AGENCY,
                variables: {
                    id: agency.id,
                    data: {
                        name: agency.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Agency updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating agency record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (agencyId) => {
        if (!this.selected.includes(agencyId)) {
            this.selected.push(agencyId);
        }
    };

    deselect = (agencyId) => {
        const index = this.selected.indexOf(agencyId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getAgencies();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getAgencies();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getAgencies();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getAgencies();
    };

    getAgencies = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getAgencies: data },
        } = await this.apolloClient.query({
            query: GET_AGENCIES_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });
        runInAction(() => {
            this.agencies = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAgenciesRecords = async (first, after, query) => {
        return await this.apolloClient.query({
            query: GET_AGENCIES_LIST,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: query || '',
            },
        });
    };

    getAllAgencies = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getAgencies: data },
        } = await this.apolloClient.query({
            query: GET_AGENCIES_LIST,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            this.allAgencies = data.edges.map((x) => x.node);
            this.allAgenciesActive = data.edges
                .map((x) => x.node)
                ?.filter((item) => !item.isArchived);
        });
    };

    getAgencyById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getAgencyById: data },
        } = await this.apolloClient.query({
            query: GET_AGENCY,
            variables: {
                id,
            },
        });

        return this.normalizeAgencies(data);
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getAgencies();
            this.fetchInterval = setInterval(() => this.getAgencies(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridAgencies.forEach((agency) => {
                !agency.isArchived && this.select(agency.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async bulkDeleteAgencies(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_AGENCIES,
                variables: {
                    id: ids,
                },
            });
            AppToaster.show({
                message: 'All selected agencies deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected agencies. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async bulkArchiveAgencies(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_AGENCIES,
                variables: {
                    id: ids,
                },
            });
            AppToaster.show({
                message: 'All selected agencies archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving selected agencies. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async validateArchiveAgencies(ids) {
        return await this.apolloClient.query({
            query: VALIDATE_ARCHIVE_AGENCIES,
            variables: {
                ids,
            },
        });
    }

    async validateArchiveSelectedAgencies() {
        const {
            data: { isAgencyArchivable },
        } = await this.validateArchiveAgencies(this.selected);
        runInAction(() => {
            this.validatedArchiveAgencies = isAgencyArchivable;
            this.someArchivableSelectedAgencies = isAgencyArchivable.some(
                (agency) => !agency.relatedStaff && !agency.relatedPayRate,
            );
            this.allArchivableSelectedAgencies = isAgencyArchivable.every(
                (agency) => agency.isArchivable,
            );
        });
    }

    async validateAgencyByName(name) {
        return await this.apolloClient.query({
            query: GET_AGENCY_BY_NAME,
            variables: {
                name,
            },
        });
    }

    async archiveAgency(agency) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_AGENCY,
                variables: {
                    id: agency.id,
                },
            });
            AppToaster.show({
                message: 'Agency archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving agency record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default AgenciesStore;
