import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import _ from 'lodash';

import {
    ARCHIVE_COST_CENTRE,
    BULK_ARCHIVE_COST_CENTRES,
    CREATE_COST_CENTRE,
    GET_ALL_COST_CENTRES_LIST,
    GET_COST_CENTRE_BY_DEPARTMENT_ID,
    GET_COST_CENTRE_BY_ID,
    GET_COST_CENTRES,
    IS_COST_CENTRE_ARCHIVABLE,
    UPDATE_COST_CENTRE,
    VALIDATE_COST_CENTRE_UNIQUE,
} from '../graphql/queries/costCentres';
import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';
import { GetCostCentres_getCostCentres_edges_node } from '../__generated__/GetCostCentres';

export interface CostCentres extends GetCostCentres_getCostCentres_edges_node {
    selected?: boolean;
}

class CostCentresStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            costCentres: observable,
            allCostCentres: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridCostCentres: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getCostCentres: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    costCentres = [];
    costCentresByRole = [];
    allCostCentres = [];
    allCostCentresActive = [];
    allCostCentresActiveOptions: any;
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeCostCentres = (costCentre: CostCentres) => ({
        id: costCentre.id,
        selected: this.selected.includes(costCentre.id),
        name: costCentre.name,
        code: costCentre.code,
        isArchived: costCentre.isArchived,
        createdAt: costCentre.createdAt,
        updatedAt: costCentre.updatedAt,
        createdPerson: costCentre.createdPerson,
        updatedPerson: costCentre.updatedPerson,
        departments: costCentre.departments,
    });

    get gridCostCentres() {
        const costCentres = this.costCentres.map(this.normalizeCostCentres);
        if (this.sortFn) {
            costCentres.sort(this.sortFn);
        }
        return costCentres;
    }

    get allSelected() {
        return this.gridCostCentres.every((r) => this.selected.includes(r.id));
    }

    async createCostCentre(costCentre: CostCentres) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_COST_CENTRE,
                variables: {
                    data: {
                        name: costCentre.name,
                        code: costCentre.code,
                    },
                },
            });
            AppToaster.show({
                message: 'Cost Centre created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem creating Cost Centre record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async archiveCostCentre(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_COST_CENTRE,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Cost Centre archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving Cost Centre record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateCostCentre(costCentre: CostCentres) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_COST_CENTRE,
                variables: {
                    id: costCentre.id,
                    data: {
                        name: costCentre.name,
                        code: costCentre.code,
                    },
                },
            });
            AppToaster.show({
                message: 'Cost Centre updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating Cost Centre record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (costCentreId: string) => {
        if (!this.selected.includes(costCentreId)) {
            this.selected.push(costCentreId);
        }
    };

    deselect = (costCentreId: string) => {
        const index = this.selected.indexOf(costCentreId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getCostCentres();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getCostCentres();
    };

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText: any) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (filters: any) => {
        this.pageInfo.filter = {
            isArchived: filters.isArchived,
        };
        this.refresh();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getCostCentres();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getCostCentres();
    };

    getCostCentres = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getCostCentres: data },
        } = await this.apolloClient.query({
            query: GET_COST_CENTRES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.costCentres = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllCostCentres = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getCostCentres: data },
        } = await this.apolloClient.query({
            query: GET_ALL_COST_CENTRES_LIST,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? '',
            },
        });

        runInAction(() => {
            this.allCostCentres = data.edges.map((x: any) => x.node);
            this.allCostCentresActive = data.edges
                .map((x: any) => x.node)
                ?.filter((item: any) => !item.isArchived);
            this.allCostCentresActiveOptions = this.allCostCentresActive.map((item: any) => ({
                value: item.id,
                label: `${item.name} (${item.code})`,
            }));
            this.allCostCentresActiveOptions = _.orderBy(
                this.allCostCentresActiveOptions,
                ['name'],
                ['asc'],
            );
        });
    };

    getCostCentresRecords = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_COST_CENTRES,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: query || '',
            },
        });
    };

    getCostCentreById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getCostCentreById: data },
        } = await this.apolloClient.query({
            query: GET_COST_CENTRE_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeCostCentres(data);
    };

    getCostCentreByDepartmentId = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getCostCentreByDepartmentId },
        } = await this.apolloClient.query({
            query: GET_COST_CENTRE_BY_DEPARTMENT_ID,
            variables: {
                id,
            },
        });
        return getCostCentreByDepartmentId;
    };

    getCostCentresByRole = async (roleId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getCostCentres: data },
        } = await this.apolloClient.query({
            query: GET_COST_CENTRES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roleId },
            },
        });

        runInAction(() => {
            this.costCentresByRole = data.edges?.map((x: any) => {
                return {
                    id: x.node.id,
                    name: x.node.name,
                    code: x.node.code,
                    isArchived: x.node.isArchived,
                };
            });
            this.costCentresByRole = _.orderBy(this.costCentresByRole, ['name'], ['asc']);
        });
    };

    selectAll = () => {
        runInAction(() => {
            this.gridCostCentres.forEach((costCentre) => {
                if (!costCentre.isArchived) {
                    this.select(costCentre.id);
                }
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    isCostCentreArchivable = async (ids: string[]) => {
        const {
            data: { isCostCentreArchivable: data },
        } = await this.apolloClient.query({
            query: IS_COST_CENTRE_ARCHIVABLE,
            variables: {
                ids,
            },
        });

        const result = data.map((x: any) => ({
            id: x.id,
            name: x.name,
            isArchivable: x.isArchivable,
        }));

        return result;
    };

    async bulkArchiveCostCentres(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_COST_CENTRES,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'All selected Cost Centres archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving selected Cost Centres.Please try again
                        or contact<a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async validateUnique(name: string, code: string) {
        return await this.apolloClient.query({
            query: VALIDATE_COST_CENTRE_UNIQUE,
            variables: {
                name,
                code,
            },
        });
    }
}

export default CostCentresStore;
