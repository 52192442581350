import { Button, ButtonGroup } from '@blueprintjs/core';
import React from 'react';
import { PAGE_SIZE } from '../../../constants/hrConst';

const CommonPagination = ({
    previousPage,
    nextPage,
    pageInfo: { totalCount, page, hasNextPage, hasPreviousPage },
    itemsName = 'users',
}) => {
    const elFrom = totalCount === 0 ? 0 : page * PAGE_SIZE + 1;
    const elTo = elFrom + PAGE_SIZE > totalCount ? totalCount : elFrom + PAGE_SIZE - 1;
    return (
        <div className="common-header__pagination">
            <span className="common-header__pagination-summary">
                Showing {itemsName} {elFrom} - {elTo} of {totalCount}
            </span>
            <ButtonGroup minimal>
                <Button
                    icon="chevron-left"
                    text="Previous"
                    disabled={!hasPreviousPage}
                    intent="primary"
                    onClick={previousPage}
                />
                <Button
                    rightIcon="chevron-right"
                    text="Next"
                    disabled={!hasNextPage}
                    intent="primary"
                    onClick={nextPage}
                />
            </ButtonGroup>
        </div>
    );
};
export default CommonPagination;
