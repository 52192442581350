import React from 'react';
import useHeaderSorting from '../../../../../hook/useHeaderSorting';
import SortedHeader from '../../../../common/sorting/SortedHeader';
import { Button, Checkbox } from '@blueprintjs/core';

const RotaTableHead = ({ onSort, isFrontendSort = true, onToggleSelectAll, checked }) => {
    const { sort, sortData } = useHeaderSorting((s) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr>
                <th>
                    <Checkbox onChange={onToggleSelectAll} checked={checked} />
                </th>
                <SortedHeader sort={sort} sortBy={'name'} text="Name" sortData={sortData} />
                <SortedHeader
                    sort={sort}
                    sortBy={'costCentre.code'}
                    text="Cost centre code"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'costCentre.name'}
                    text="Cost centre"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'department.costCode'}
                    text="Department cost code"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'department.name'}
                    text="Department"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'startDate'}
                    text="Start date"
                    sortData={sortData}
                />
                <SortedHeader sort={sort} sortBy={'endDate'} text="End date" sortData={sortData} />
                <th className="sorted-header">
                    <Button minimal fill alignText="left">
                        Shifts
                    </Button>
                </th>
                <SortedHeader
                    sort={sort}
                    sortBy={'createdAt'}
                    text="Created on"
                    sortData={sortData}
                />
                <th>Created by</th>
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedAt'}
                    text="Last updated"
                    sortData={sortData}
                />
            </tr>
        </thead>
    );
};

export default RotaTableHead;
