import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../modules/helpers/formatData';

interface Props {
    onClick: any;
    id: string;
    selected: boolean;
    name: string;
    createdAt: string;
    updatedAt: string;
    updatedPerson: any;
    createdPerson: any;
    isArchived: boolean;
    onToggle: any;
}

const FundingPoolsTableRow = ({
    onClick,
    id,
    selected,
    name,
    createdAt,
    updatedAt,
    createdPerson,
    updatedPerson,
    isArchived,
    onToggle,
}: Props) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                    disabled={isArchived}
                />
            </td>
            <td>{name}</td>
            <td>{isArchived ? 'Archived' : 'Active'}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>
                {createdPerson.firstName} {createdPerson.lastName}
            </td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
            <td>
                {updatedPerson?.firstName} {updatedPerson?.lastName}
            </td>
        </tr>
    );
};

export default FundingPoolsTableRow;
