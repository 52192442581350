import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Icon } from '@blueprintjs/core';

const MainNavigation = inject('RootStore')(
    observer(
        class MainNavigation extends Component {
            render() {
                const { tab } = this.props.match.params;

                return (
                    <nav className="nav">
                        <ul className="nav__list">
                            <li className="nav__item nav__item--loose">
                                <Link
                                    to={{
                                        pathname: `/on-demand/${tab}/jobs/add`,
                                        state: { closeModal: true },
                                    }}
                                    className="bp5-button bp5-large bp5-intent-success"
                                >
                                    <Icon icon="add" />
                                    <span className="bp5-button-text">Add a job</span>
                                </Link>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    activeClassName="nav__link--active"
                                    to={`/on-demand/${tab}/map`}
                                >
                                    Map
                                </NavLink>
                            </li>
                            <li className="nav__item">
                                <NavLink
                                    className="nav__link"
                                    activeClassName="nav__link--active"
                                    to={`/on-demand/${tab}/reporting`}
                                >
                                    Historic Jobs
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                );
            }
        },
    ),
);

export default MainNavigation;
