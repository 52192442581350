import { FC } from 'react';
import { useField } from 'formik';
import PhoneInput from 'react-phone-input-2';

import { FormGroup } from '../FormGroup';
import { formatPhoneNumber } from '../../../modules/helpers/FormatPhoneNumber';

interface IProps {
    name: string;
    className?: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    displayErrors?: string;
    isContinueBtnClicked?: boolean;
}

const PhoneInputComponent: FC<IProps> = ({
    className,
    label,
    required,
    disabled,
    displayErrors,
    isContinueBtnClicked,
    ...props
}) => {
    const [field, meta, { setValue }] = useField(props);

    return (
        <FormGroup
            className={className}
            label={label}
            labelFor={props.name}
            touched={meta.touched || isContinueBtnClicked}
            error={meta.error}
            displayErrors={displayErrors}
        >
            <PhoneInput
                {...field}
                {...props}
                value={field.value ? field.value : '44'}
                country="gb"
                onlyCountries={['gb']}
                autoFormat={false}
                onChange={(value) => setValue(formatPhoneNumber(value))}
                inputClass="v2__form-phone-input"
                dropdownClass="v2__form-phone-drop-down"
                specialLabel=""
                countryCodeEditable={false}
                disabled={disabled}
                inputProps={{
                    id: props.name,
                }}
            />
        </FormGroup>
    );
};

export default PhoneInputComponent;
