import React from 'react';
import { Button, Classes, Dialog, FormGroup } from '@blueprintjs/core';
import Select from 'react-select';
import { compareByLabels } from '../../../../modules/helpers/sortFunctions';

const weeks = [
    { value: 0, label: '1' },
    { value: 1, label: '2' },
    { value: 2, label: '3' },
    { value: 3, label: '4' },
    { value: 4, label: '5' },
    { value: 5, label: '6' },
    { value: 6, label: '7' },
    { value: 7, label: '8' },
];

const ShiftPatternSelector = (props: any) => {
    const {
        onClose,
        title,
        isOpen,
        shiftPatterns,
        onSubmit,
        departmentId,
        createdFromSp,
        shiftPatternSelectorPrefilled,
    } = props;

    const [item, setItem] = React.useState();
    const [offset, setOffset] = React.useState(weeks[0]);
    const [weekItems, setWeekItems] = React.useState(weeks);

    React.useEffect(() => {
        if (shiftPatterns && shiftPatterns.length > 0) {
            setItem(shiftPatterns[0]);
            setOffset(weeks[0]);
        }
    }, [departmentId, shiftPatterns]);

    React.useEffect(() => {
        setItem(shiftPatternSelectorPrefilled);
        setOffset(weeks[0]);
    }, [createdFromSp, shiftPatternSelectorPrefilled]);

    React.useEffect(() => {
        if (!item) return;

        const shiftPatternWeeks = Math.max.apply(
            Math,
            (item as any).shiftPatternEntries?.map(function (o: any) {
                return o.weekNumber;
            }),
        );
        const fWeekItems = weeks.filter((r: any) => r.value < shiftPatternWeeks);
        setWeekItems(fWeekItems);
    }, [item]);

    return (
        <Dialog
            onClose={onClose}
            title={title}
            autoFocus
            canOutsideClickClose
            usePortal
            isOpen={isOpen}
            className="rota-builder__shift-dialog modal__overflow-initial"
        >
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label="Select shift pattern">
                    <Select
                        isDisabled={createdFromSp}
                        options={shiftPatterns ? shiftPatterns.sort(compareByLabels) : []}
                        onChange={(e: any) => {
                            setItem(e);
                        }}
                        id="shiftPatterns"
                        value={item}
                    />
                </FormGroup>
                <FormGroup label="Select week to start">
                    <Select
                        options={weekItems}
                        onChange={(e: any) => {
                            setOffset(e);
                        }}
                        value={offset}
                        id="weeks"
                    />
                </FormGroup>
                <FormGroup>
                    <Button
                        onClick={() => {
                            onSubmit(item, offset);
                        }}
                        disabled={!item || !offset}
                    >
                        Apply
                    </Button>
                </FormGroup>
            </div>
        </Dialog>
    );
};

export default ShiftPatternSelector;
