import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button } from '@blueprintjs/core';

import DepartmentAssignmentsHeader from './DepartmentAssignmentsHeader';
import DepartmentAssignmentItem from './DepartmentAssignmentItem';
import Loader from '../../helpers/Loader';
import DepartmentAssignmentsForm from './DepartmentAssignmentsForm';
import { INormalizedDepartment } from './DepartmentAssignmentsTypes';

const DepartmentAssignments = inject('RootStore')(
    observer(
        class Departments extends Component<any> {
            state = {
                isLoading: true,
                createActionType: false,
                showForm: false,
                assignmentId: null,
            };

            async componentDidMount() {
                const { departmentAssignmentsStore } = this.props.RootStore;
                if (this.props.match.params.employeeId) {
                    await departmentAssignmentsStore.getDepartmentAssignments(
                        this.props.match.params.employeeId,
                    );
                }
                this.setState({
                    isLoading: false,
                });
            }

            componentWillUnmount() {
                this.setState({
                    showForm: false,
                });
            }

            _handleItemClick = (assignmentId: string, event: React.MouseEvent) => {
                const { tagName } = event.target as HTMLElement;
                if (tagName === 'TD') {
                    this.setState({ showForm: true, createActionType: false, assignmentId });
                }
            };

            _handleAddNew = () => {
                this.setState({ showForm: true, createActionType: true });
            };

            _handleCancel = () => {
                this.setState({
                    showForm: false,
                    assignmentId: null,
                });
            };

            render() {
                const { isLoading, showForm, createActionType, assignmentId } = this.state;
                const { archivedPersonRecordStatus } = this.props;
                const { statusId } = this.props.employee;
                const { departmentAssignmentsStore } = this.props.RootStore;
                return (
                    <>
                        {isLoading && <Loader />}
                        {!showForm && (
                            <>
                                {statusId !== archivedPersonRecordStatus ? (
                                    <div className="common-header">
                                        <Button
                                            text="Add"
                                            intent="success"
                                            icon="add"
                                            onClick={this._handleAddNew}
                                            className="common-header__button"
                                            outlined
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                                <table className="bp5-html-table bp5-interactive common-table">
                                    <DepartmentAssignmentsHeader
                                        onSort={departmentAssignmentsStore.setSortFunc}
                                        isFrontendSort={true}
                                        isEmployee={!!this.props.match.params.employeeId}
                                    />
                                    <tbody>
                                        {departmentAssignmentsStore.gridDepartmentAssignments &&
                                            departmentAssignmentsStore.gridDepartmentAssignments.map(
                                                (department: INormalizedDepartment) => (
                                                    <DepartmentAssignmentItem
                                                        {...department}
                                                        onClick={this._handleItemClick}
                                                    />
                                                ),
                                            )}
                                    </tbody>
                                </table>
                            </>
                        )}
                        {showForm && (
                            <DepartmentAssignmentsForm
                                {...this.props}
                                createActionType={createActionType}
                                onFormCancel={this._handleCancel}
                                assignmentId={assignmentId}
                            />
                        )}
                    </>
                );
            }
        },
    ),
);

export default DepartmentAssignments;
