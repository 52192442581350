import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { toJS, reaction, autorun } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import { Row, Col } from 'react-grid-system';
import {
    FormGroup,
    InputGroup,
    NumericInput,
    TextArea,
    Button,
    RadioGroup,
    Callout,
    ButtonGroup,
    Icon,
    Radio,
    Checkbox,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import ReactPhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import classNames from 'classnames';

import Modal from '../../modal/Modal';
import Error from '../../forms/Error';
import Loader from '../../helpers/Loader';
import { getFieldsDefaultState } from './formFields';
import {
    emptyErrorByType,
    getAPIReadyJobData,
    normalizeScheduleOptions,
    regexFieldValidation,
} from './formFormats';
import {
    parseDateStringToJSDate,
    formatDisplayDate,
    formatPenceToPounds,
    getDispositionExpiryDateTime,
    getRelativeDay,
    getAgeFromDate,
    formatPractices,
    splitPracticeDetails,
    formatDateTime,
} from '../../helpers/formatData';
import { getCoordinates } from '../../../../api/gmapsApi';
import AutoPopulate from '../actions/AutoPopulate';
import Abort from '../actions/Abort';
import {
    isDateBetweenCurrentAndOneYearInAdvance,
    isDateTimeInPast,
    isDateIsBigger,
} from './validateFields';
import AppToaster from '../../../modules/helpers/Toaster';
import { getOrganisationAddressByODSCode } from '../../../../api/odsApi';
import { PracticeSelect } from '../../../common/PracticeSelect';
import { DateInput3 } from '@blueprintjs/datetime2';

const MULTI_SELECT_FIELDS = ['languagesSpoken', 'speciality', 'flags', 'hcpType', 'nominatedHcps'];
const JobForm = inject('RootStore')(
    observer(
        class JobForm extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    fields: getFieldsDefaultState().fields,
                    validation: getFieldsDefaultState().validation,
                    errors: getFieldsDefaultState().errors,
                    autoPopulated: getFieldsDefaultState().autoPopulated,
                    visible: getFieldsDefaultState().visible,
                    helpers: {
                        minDate: new Date('1900-01-01'),
                        maxDate: new Date('2030-01-01'),
                        apiDateFormat: 'YYYY-MM-DDTHH:mm:ss',
                    },
                    formValid: null,
                    isLoading: false,
                    practiceList: 'GP_PRACTICES',
                    isShowAlert: false,
                    isShowMsg: false,
                    isSeries: false,
                    priceHasChanged: false,
                };

                this.isScheduledJob = props.match.path.startsWith('/scheduled');

                if (this.isScheduledJob) {
                    this.state.fields.jobType = { value: 'shift' };
                }
            }

            componentDidMount() {
                // Populate the job details if it's an edit form
                if (this.props.actionType === 'edit') {
                    const { id } = this.props.match.params;
                    const job = this.props.RootStore.jobsStore.getJob(id);

                    const { nominatedHcps } = this.props.RootStore.lovsStore;

                    if (nominatedHcps.length > 0) {
                        this.handlePopulate(job);
                    } else {
                        reaction(
                            () => nominatedHcps.length,
                            () => this.handlePopulate(job),
                        );
                    }
                }

                this.defaultShiftPriceDisposer = autorun(() => {
                    if (
                        this.props.RootStore.configStore.defaultShiftPrice &&
                        !this.state.priceHasChanged
                    ) {
                        this._handlePriceChange(this.props.RootStore.configStore.defaultShiftPrice);
                    }
                });

                this.defaultShiftLengthDisposer = autorun(() => {
                    if (
                        this.props.RootStore.configStore.defaultShiftLength &&
                        !this.state.endTimeHasChanged
                    ) {
                        const endDateTime = moment()
                            .add(1, 'hours')
                            .add(this.props.RootStore.configStore.defaultShiftLength, 'minutes')
                            .toDate();
                        this.handleInputChange(endDateTime, 'endDate');
                        this.handleInputChange(endDateTime, 'endTime');
                    }
                });
            }

            componentWillUnmount() {
                if (this.defaultShiftLengthDisposer) {
                    this.defaultShiftLengthDisposer();
                }
            }

            // TEXT / TEXTAREA / DATE / TIME / SELECT CHANGE
            handleInputChange = (value, field, callback = () => {}) => {
                // for warning on repeat sections
                let isShowMsg = false;

                // If field is a multi-select, maintain it as an empty array rather than being null
                if (MULTI_SELECT_FIELDS.includes(field) && value === null) {
                    value = [];
                }

                // React state doesn't handle updating multi-level state. So below is an accepted technique, to do that
                let fields = { ...this.state.fields };
                let autoPopulated = { ...this.state.autoPopulated };

                // Update the Age if Date of Birth has been changed
                if (field === 'dateOfBirth') {
                    fields['age'] = getAgeFromDate(value);
                }

                // if the age is updated, we should clear the date of birth field
                if (field === 'age') {
                    fields['dateOfBirth'] = null;
                }

                // Update expiry date and times if the disposition has been changed
                if (field === 'disposition') {
                    const dispositionExpiryDateTime = getDispositionExpiryDateTime(
                        value.timeInMins,
                    );
                    fields['expiryDate'] = dispositionExpiryDateTime;
                    fields['expiryTime'] = dispositionExpiryDateTime;
                }

                // Update end date and expiry date if start date has been changed
                if (field === 'startDate') {
                    fields['endDate'] = value; // Same day
                    fields['expiryDate'] = getRelativeDay(value, -1); // Previous day
                    // checks if need to update also End by field
                    if (isDateIsBigger(value, fields.until)) {
                        fields['until'] = value; // Same day
                    }
                }

                // Update End by date if end date has been changed
                if (field === 'endDate' && isDateIsBigger(value, fields.until)) {
                    fields['until'] = value; // Same day
                }

                // Update address lines if practice has been changed
                if (field === 'practice' && this.isScheduledJob) {
                    if (this.state.practiceList === 'GP_PRACTICES' && value) {
                        const ods = splitPracticeDetails(value.value).odsCode;

                        getOrganisationAddressByODSCode(ods)
                            .then((result) => {
                                if (result && result.AddrLn1) {
                                    this.handleInputChange(result.AddrLn1, 'addressLine1', () =>
                                        this.setFieldAsAutoPopulated('addressLine1'),
                                    );
                                }
                                if (result && result.Town) {
                                    this.handleInputChange(result.Town, 'town', () =>
                                        this.setFieldAsAutoPopulated('town'),
                                    );
                                }
                                if (result && result.PostCode) {
                                    this.handleInputChange(result.PostCode, 'postCode', () =>
                                        this.setFieldAsAutoPopulated('postCode'),
                                    );
                                }
                            })
                            .catch((err) => {
                                console.log('SOMETHING WENT WRONG', err);
                            });
                    } else if (this.state.practiceList === 'PRESET_ADDRESSES') {
                        const practice = this.props.RootStore.configStore.presetAddresses.find(
                            (practice) => practice.name === value.value,
                        );
                        if (practice && practice.addressLine1) {
                            fields['addressLine1'] = practice.addressLine1;
                            autoPopulated['addressLine1'] = true;
                        }
                        if (practice && practice.addressLine2) {
                            fields['addressLine2'] = practice.addressLine2;
                            autoPopulated['addressLine2'] = true;
                        }
                        if (practice && practice.town) {
                            fields['town'] = practice.town;
                            autoPopulated['town'] = true;
                        }
                        if (practice && practice.postCode) {
                            fields['postCode'] = practice.postCode;
                            autoPopulated['postCode'] = true;
                        }
                    }
                }

                // If a field has been auto-populated (and is highlighted)
                // remove the highlight once the user edits the field value
                if (autoPopulated[field] === true) {
                    autoPopulated[field] = false;
                }

                if (
                    field === 'repeatDayInMonth' &&
                    this.state.fields.repeats === 'MONTHLY' &&
                    this.state.fields.repeatsForMonthly === 'onDateInMonth' &&
                    value > 28
                ) {
                    isShowMsg = true;
                }

                if (field === 'endTime') {
                    this.setState({ endTimeHasChanged: true });

                    if (this.defaultShiftLengthDisposer) {
                        this.defaultShiftLengthDisposer();
                    }
                }

                fields[field] = value;
                this.setState(
                    {
                        fields,
                        autoPopulated,
                        isShowMsg,
                    },
                    callback,
                );

                // Prevent showing errors until the user has tried to submit
                // to avoid all fields showing red the first time they type something
                // But then start tracking their changes in order to remove errors as they fix them
                if (this.state.formValid !== null) {
                    setTimeout(() => {
                        this.validateFields();
                    });
                }
            };

            setFieldAsAutoPopulated = (key) => {
                let autoPopulated = { ...this.state.autoPopulated };

                // If a field has been auto-populated (and is highlighted)
                // remove the highlight once the user edits the field value
                if (autoPopulated[key] === false) {
                    autoPopulated[key] = true;
                }

                this.setState({
                    autoPopulated,
                });
            };

            handleOnDaysButtonChange = (value) => {
                let currentState = [...this.state.fields.onDays];
                if (currentState.includes(value)) {
                    currentState = currentState.filter((item) => item !== value);
                } else {
                    currentState.push(value);
                }
                this.setState({
                    fields: {
                        ...this.state.fields,
                        onDays: currentState,
                    },
                });

                // Prevent showing errors until the user has tried to submit
                // to avoid all fields showing red the first time they type something
                // But then start tracking their changes in order to remove errors as they fix them
                if (this.state.formValid !== null) {
                    setTimeout(() => {
                        this.validateFields();
                    });
                }
            };

            validateFields = () => {
                let errorsList = {},
                    errorsCount = 0;
                let isShowAlert = false;

                // Add an error message for a given field
                const setError = (field, errorMessage, errorsList) => {
                    errorsList[field].push(errorMessage);
                    return errorsList;
                };

                // For each field, if it is REQUIRED, generate an error message
                _.forIn(this.state.errors, (value, field) => {
                    errorsList[field] = [];

                    const fieldValue = this.state.fields[field];

                    const {
                        required,
                        type,
                        regex: { rule: regex, validationMessage: regexErrorMessage } = {},
                    } = this.state.validation[field];

                    let errorMessage;

                    if (regex && !regexFieldValidation(fieldValue, regex)) {
                        errorMessage = regexErrorMessage;
                    } else if (
                        required === true ||
                        (Array.isArray(required) &&
                            required.includes(this.state.fields.jobType.value))
                    ) {
                        errorMessage = emptyErrorByType(fieldValue, type);
                    }

                    if (errorMessage && errorMessage !== '') {
                        errorsList = setError(field, errorMessage, errorsList);
                        errorsCount = errorsCount + 1;
                    }
                });

                // Validate that the expiryDateTime is not in the past
                if (isDateTimeInPast(this.state.fields.expiryDate, this.state.fields.expiryTime)) {
                    errorsList = setError(
                        'expiryDate',
                        'Expiry date/time cannot be in the past.',
                        errorsList,
                    );
                    errorsCount++;
                }

                // Validate that the startDateTime is not in the past
                if (
                    this._isVisible('startDate') &&
                    isDateTimeInPast(this.state.fields.startDate, this.state.fields.startTime)
                ) {
                    errorsList = setError(
                        'startDate',
                        'Start date/time cannot be in the past.',
                        errorsList,
                    );
                    errorsCount++;
                }

                // Validate that the endDateTime is not in the past
                if (
                    this._isVisible('endDate') &&
                    isDateTimeInPast(this.state.fields.endDate, this.state.fields.endTime)
                ) {
                    errorsList = setError(
                        'endDate',
                        'End date/time cannot be in the past.',
                        errorsList,
                    );
                    errorsCount++;
                }

                if (
                    this.state.fields.repeatsForDaily !== 'everyWeekday' &&
                    (1 > this.state.fields.repeatEvery || this.state.fields.repeatEvery > 99)
                ) {
                    errorsList = setError(
                        'repeatEvery',
                        'must be more than 1 and less than 99',
                        errorsList,
                    );
                    errorsCount++;
                }

                if (
                    this.state.fields.repeats === 'MONTHLY' &&
                    (1 > this.state.fields.repeatDayInMonth || this.state.fields.repeatEvery > 31)
                ) {
                    errorsList = setError(
                        'repeatDayInMonth',
                        'must be more than 1 and less than 31',
                        errorsList,
                    );
                    errorsCount++;
                }

                if (
                    this.state.fields.repeats === 'WEEKLY' &&
                    this.state.fields.onDays.length === 0
                ) {
                    errorsList = setError('onDays', 'This field is required', errorsList);
                    errorsCount++;
                }

                if (
                    this.state.fields.repeats !== 'NEVER' &&
                    this.state.fields.until &&
                    !isDateBetweenCurrentAndOneYearInAdvance(this.state.fields.until)
                ) {
                    errorsList = setError(
                        'until',
                        'Until date cannot be in the past or more then 1 year in advance.',
                        errorsList,
                    );
                    errorsCount++;
                }

                let startDate = null;
                if (this.state.fields.startDate && this.state.fields.startTime) {
                    startDate = formatDateTime(
                        this.state.fields.startDate,
                        this.state.fields.startTime,
                    );
                }
                let endDate = null;
                if (this.state.fields.endDate && this.state.fields.endTime) {
                    endDate = formatDateTime(this.state.fields.endDate, this.state.fields.endTime);
                }

                if (this.state.fields.repeats === 'DAILY' && startDate && endDate) {
                    if (moment(endDate).diff(moment(startDate), 'days', true) > 1) {
                        errorsCount++;
                        isShowAlert = true;
                    }
                }
                if (this.state.fields.repeats === 'WEEKLY' && startDate && endDate) {
                    if (moment(endDate).diff(moment(startDate), 'weeks', true) > 1) {
                        errorsCount++;
                        isShowAlert = true;
                    }
                }

                if (this.state.fields.repeats === 'MONTHLY' && startDate && endDate) {
                    if (moment(endDate).diff(moment(startDate), 'months', true) > 1) {
                        errorsCount++;
                        isShowAlert = true;
                    }
                }

                const formValid = errorsCount === 0;
                this.setState({
                    formValid,
                    errors: errorsList,
                    isShowAlert,
                });

                // setState (above) doesn't update state synchronously
                // so the handlSubmit is expecting to know if the form is valid,
                // but when this function finishes state hasn't updated yet and the form
                // is still invalid. Returning formValid let's handleSubmit to continue immediatelly.
                // However, this.state.formValid is used for showing errors too, so it is still needed.
                return formValid;
            };

            handleSubmit = async () => {
                const formValid = this.validateFields();

                // Validate the form
                if (!formValid) {
                    return;
                }

                this.handleToggleLoading(true);

                let locationCoordinates = {};
                let locationType;
                let locationPartialMatch;

                const {
                    configStore: { org, clientKeys },
                } = this.props.RootStore;

                // Get job coordinates
                if (
                    (this.state.fields.jobType.value === 'homeVisit' ||
                        (this.state.fields.jobType.value === 'shift' &&
                            this.state.fields.postCode)) &&
                    clientKeys.googleMaps
                ) {
                    const fullAddress = `${this.state.fields.addressLine1}, ${this.state.fields.addressLine2}, ${this.state.fields.town}, ${this.state.fields.postCode}, United Kingdom`;
                    let geoLocation;

                    try {
                        geoLocation = await getCoordinates(fullAddress, clientKeys.googleMaps);

                        const [result] = geoLocation.results;

                        if (result) {
                            locationCoordinates = result.geometry.location;
                            locationType = result.geometry.location_type;
                            locationPartialMatch = result.geometry.partial_match;
                        }
                    } catch (err) {
                        console.error('Error getting coordinates', err);
                        AppToaster.show({
                            message: (
                                <>
                                    Sorry, there was a problem getting address information for the
                                    job. Please try again or contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            ),
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                }

                // Prepare the data for sending to the API
                let jobData = getAPIReadyJobData(
                    this.state,
                    locationCoordinates,
                    org,
                    this.props.actionType,
                );

                // Send job data to the API
                if (this.props.actionType === 'create') {
                    try {
                        const { hcpNotifiedCount } = await this.props.RootStore.jobsStore.createJob(
                            jobData,
                        );
                        AppToaster.show({
                            message: `The job has been created successfully and ${hcpNotifiedCount} ${
                                hcpNotifiedCount === 1 ? 'HCP was' : 'HCPs were'
                            } notified.`,
                            intent: 'success',
                        });
                    } catch (err) {
                        const message =
                            err.message === 'Invalid repeating rules.' ? (
                                <>
                                    Sorry, but repeating rules are invalid. Please try to change
                                    repeats section or contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            ) : (
                                <>
                                    Sorry, there was a problem creating the job. Please try again or
                                    contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            );
                        AppToaster.show({
                            message: message,
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                } else if (this.props.actionType === 'edit') {
                    jobData.id = this.props.match.params.id;

                    try {
                        const {
                            hcpNotifiedCount,
                        } = await this.props.RootStore.jobsStore.reSubmitJob(jobData);
                        AppToaster.show({
                            message: `The job has been re-submitted successfully and ${hcpNotifiedCount} ${
                                hcpNotifiedCount === 1 ? 'HCP was' : 'HCPs were'
                            } notified.`,
                            intent: 'success',
                        });
                    } catch (err) {
                        AppToaster.show({
                            message: (
                                <>
                                    Sorry, there was a problem updating the job. Please try again or
                                    contact{' '}
                                    <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                                    for help.
                                </>
                            ),
                            intent: 'danger',
                        });
                        return this.handleToggleLoading(false);
                    }
                }

                // If the location of the job is not a good match, let the user know
                if (locationType === 'APPROXIMATE' || locationPartialMatch === true) {
                    AppToaster.show({
                        message:
                            'There was not an exact match on the address for the submitted job, please verify that its location is correct.',
                        intent: 'warning',
                    });
                }

                this.props.RootStore.jobsStore.setJobs();
                this.goToHome();
            };

            handleAutoPopulate = (fields) => {
                let state = this.state;
                let populatedFieldsNumber = 0;

                _.forOwn(fields, (field, key) => {
                    if (field) {
                        // Make the date of birth a Date object and set that
                        // the datepicker plugin expects that format
                        if (key === 'dateOfBirth') {
                            state.fields[key] = new Date(field);
                            state.autoPopulated[key] = true;
                            populatedFieldsNumber = populatedFieldsNumber + 1;
                        } else if (key === 'disposition' || key === 'gender') {
                            // Check if the field exists in the LOVs and use that (it's a select list)
                            // if not, don't set the value
                            _.forOwn(this.props.RootStore.lovsStore[key], (lovValue) => {
                                if (field === toJS(lovValue).value) {
                                    state.fields[key] = toJS(lovValue);
                                    state.autoPopulated[key] = true;
                                    populatedFieldsNumber = populatedFieldsNumber + 1;

                                    // Make sure the disposition also sets the expiry date
                                    // accordingly (as it would if it was manually selected)
                                    if (key === 'disposition') {
                                        const dispositionExpiryDateTime = getDispositionExpiryDateTime(
                                            toJS(lovValue).timeInMins,
                                        );
                                        state.fields['expiryDate'] = dispositionExpiryDateTime;
                                        state.fields['expiryTime'] = dispositionExpiryDateTime;
                                    }
                                }
                            });
                        } else if (key === 'hcpType') {
                            let stateFieldValue = [];

                            //Multi-select inputs can have multiple values
                            _.forEach(field, (fieldValue) => {
                                // Get each of the values from the List-of-values store
                                // as each value is in fact a label/value pair and not just a simple string
                                _.forOwn(this.props.RootStore.lovsStore[key], (lovValue) => {
                                    // If the field value exists in the store, use the LOVs store value to populate the field
                                    // If the field value doesn't exist in the store, it's not a valid value and shouldn't be used
                                    if (fieldValue === toJS(lovValue).value) {
                                        stateFieldValue.push(toJS(lovValue));
                                    }
                                });
                            });
                            state.fields[key] = stateFieldValue;
                            state.autoPopulated[key] = true;
                            populatedFieldsNumber = populatedFieldsNumber + 1;
                        } else if (key === 'practice') {
                            const practices = formatPractices(
                                this.props.RootStore.lovsStore.practice,
                            );
                            _.forOwn(practices, (lovValue) => {
                                const practice = toJS(lovValue);
                                if (practice.value.includes(field)) {
                                    state.fields[key] = practice;
                                    state.autoPopulated[key] = true;
                                    populatedFieldsNumber = populatedFieldsNumber + 1;
                                }
                            });
                        } else if (key === 'jobType') {
                            // iterate over all job types and perform a case insensitive
                            // comparison against the job type label
                            _.forOwn(this.props.RootStore.lovsStore[key], (lovValue) => {
                                if (
                                    lovValue &&
                                    lovValue.label &&
                                    lovValue.label.toLowerCase() === field.toLowerCase()
                                ) {
                                    state.fields[key] = toJS(lovValue);
                                    state.autoPopulated[key] = true;
                                    populatedFieldsNumber = populatedFieldsNumber + 1;
                                }
                            });

                            // Do NOT count the notes in the total number of auto-populated fields
                            // but still highlight them
                        } else if (key === 'notes') {
                            state.fields[key] = field;
                            state.autoPopulated[key] = true;

                            // Set any other field directly
                        } else {
                            state.fields[key] = field;
                            state.autoPopulated[key] = true;
                            populatedFieldsNumber = populatedFieldsNumber + 1;
                        }
                    }
                });

                this.setState(state);

                if (populatedFieldsNumber > 0) {
                    AppToaster.show({
                        message: `${populatedFieldsNumber} fields were automatically populated`,
                        intent: 'success',
                    });
                } else {
                    AppToaster.show({
                        message: 'No individual fields were automatically populated',
                    });
                }
            };

            handlePopulate = (fields) => {
                let state = this.state;
                _.forOwn(fields, (field, key) => {
                    if (field) {
                        // Simple Dates
                        if (key === 'dateOfBirth') {
                            state.fields[key] = new Date(field);

                            // Complex Date/time fields
                        } else if (
                            key === 'startDateTime' ||
                            key === 'expiryDateTime' ||
                            key === 'endDateTime'
                        ) {
                            state.fields[key.replace('Date', '')] = new Date(field);
                            state.fields[key.replace('Time', '')] = new Date(field);

                            // Price
                        } else if (key === 'priceInPence') {
                            const price = formatPenceToPounds(field);
                            this._handlePriceChange(price, 'price');
                            state.fields['price'] = price;

                            // Single-selects
                        } else if (
                            key === 'jobType' ||
                            key === 'disposition' ||
                            key === 'gender' ||
                            key === 'practice' ||
                            key === 'blockSize' ||
                            key === 'shiftType'
                        ) {
                            let data = this.props.RootStore.lovsStore[key];
                            let identifier = 'value';
                            if (key === 'practice') {
                                data = formatPractices(data);
                            }
                            if (key === 'blockSize') {
                                identifier = 'label';
                                if (data) {
                                    data = data.hours.units;
                                } else {
                                    data = [{ label: field, value: field }];
                                }
                            }
                            _.forOwn(data, (lovValue) => {
                                if (field === toJS(lovValue)[identifier]) {
                                    state.fields[key] = toJS(lovValue);
                                }
                            });

                            // Multi-selects
                        } else if (MULTI_SELECT_FIELDS.includes(key)) {
                            let stateFieldValue = [];

                            //Multi-select inputs can have multiple values
                            _.forEach(field, (fieldValue) => {
                                // Get each of the values from the List-of-values store
                                // as each value is in fact a label/value pair and not just a simple string
                                _.forOwn(this.props.RootStore.lovsStore[key], (lovValue) => {
                                    // If the field value exists in the store, use the LOVs store value to populate the field
                                    // If the field value doesn't exist in the store, it's not a valid value and shouldn't be used
                                    if (fieldValue === toJS(lovValue).value) {
                                        stateFieldValue.push(toJS(lovValue));
                                    }
                                });
                            });
                            state.fields[key] = stateFieldValue;

                            // schedule options for repeating jobs
                        } else if (key === 'scheduleOptions') {
                            const scheduleOpt = JSON.parse(fields.scheduleOptions);
                            const scheduleOptions = normalizeScheduleOptions(scheduleOpt);
                            for (let key of Object.keys(scheduleOptions)) {
                                state.fields[key] = scheduleOptions[key];
                            }

                            state.isSeries = this.props.isSeries;
                        } else {
                            state.fields[key] = field;
                        }
                    }
                });

                this.setState(state);
            };

            handleToggleLoading = (status) => {
                this.setState({
                    isLoading: status,
                });
            };

            handleCancel = () => {
                this.goToHome();
            };

            goToHome = () => {
                const url = this.isScheduledJob
                    ? '/scheduled'
                    : `/on-demand/${this.props.match.params.tab}/map`;
                this.props.history.push(url);
            };

            _handleCloseClick = () => {
                if (this.props.location.state && this.props.location.state.closeModal) {
                    return this.props.history.goBack();
                }

                this.goToHome();
            };

            _isVisible = (field) => {
                const visible = this.state.visible[field];
                return (
                    visible === true ||
                    (Array.isArray(visible) && visible.includes(this.state.fields.jobType.value))
                );
            };

            _isRequired = (field) => {
                const { required } = this.state.validation[field];
                return (
                    required === true ||
                    (Array.isArray(required) && required.includes(this.state.fields.jobType.value))
                );
            };

            _requiredLabel = (field) => {
                return this._isRequired(field) ? '(required)' : '';
            };

            _filterHcpsByType = (users) =>
                users
                    .filter(
                        (user) =>
                            Array.isArray(this.state.fields.hcpType) &&
                            this.state.fields.hcpType.some((type) =>
                                user.hcpTypes.includes(type.value),
                            ),
                    )
                    .sort((a, b) => {
                        // Sort by surname
                        // Split value (userId) by '.' and sort by comparing last item
                        return a.value.split('.').pop().localeCompare(b.value.split('.').pop());
                    });

            _handleHcpTypeChange = (event) => {
                this.handleInputChange(event, 'hcpType', () => {
                    const allowedHcps = this._filterHcpsByType(this.state.fields.nominatedHcps);
                    this.handleInputChange(allowedHcps, 'nominatedHcps');
                });
            };

            _sortHcpTypes = (hcps) =>
                [...hcps].sort((a, b) => {
                    let aOrder = a.hasOwnProperty('order');
                    let bOrder = b.hasOwnProperty('order');
                    if (aOrder && bOrder) {
                        if (a.order > b.order) return 1;
                        if (a.order < b.order) return -1;
                        return a.label.localeCompare(b.label);
                    }
                    // Return items with an order first otherwise compare label
                    return aOrder ? -1 : bOrder ? 1 : a.label.localeCompare(b.label);
                });

            _handlePriceChange = (value) => {
                this.setState({ priceHasChanged: true });
                if (this.defaultShiftPriceDisposer) {
                    this.defaultShiftPriceDisposer();
                }

                this.handleInputChange(value, 'price', () => {
                    const {
                        blockSize: { value: selectedBlockSize },
                    } = this.state.fields;
                    const { blockSize } = this.props.RootStore.lovsStore;
                    if (!blockSize) return;
                    const [unit] = blockSize.hours.prices.filter(
                        (price) => price.value * selectedBlockSize === value,
                    );
                    this.handleInputChange(unit || { label: '', value: null }, 'unitPrice');
                });
            };

            _computePrice = (event, field) => {
                this.handleInputChange(event, field, () => {
                    const {
                        blockSize: { value: blockSize },
                        unitPrice: { value: unitPrice },
                    } = this.state.fields;

                    if (blockSize && unitPrice) {
                        this.handleInputChange(blockSize * unitPrice, 'price');
                    }
                });
            };

            _handlePracticeListChange = () => {
                const practiceFieldDefault = getFieldsDefaultState().fields.practice;
                this.setState({
                    ...this.state,
                    fields: { ...this.state.fields, practice: practiceFieldDefault },
                });
                if (this.state.practiceList === 'GP_PRACTICES') {
                    this.setState({ practiceList: 'PRESET_ADDRESSES' });
                } else if (this.state.practiceList === 'PRESET_ADDRESSES') {
                    this.setState({ practiceList: 'GP_PRACTICES' });
                }
            };

            _renderFooter() {
                if (this.props.actionType === 'create') {
                    return (
                        <>
                            <Button large onClick={this.handleCancel} icon="cross">
                                Cancel
                            </Button>
                            <Button intent="primary" large onClick={this.handleSubmit} icon="tick">
                                Submit
                            </Button>
                        </>
                    );
                }

                if (this.props.actionType === 'edit') {
                    return (
                        <>
                            <Abort
                                {...this.props}
                                onToggleLoading={this.handleToggleLoading}
                                isSeriesJob={this.state.fields?.repeatingGroupId}
                            />
                            <Button intent="primary" large onClick={this.handleSubmit} icon="redo">
                                Re-Submit
                            </Button>
                        </>
                    );
                }

                return null;
            }

            _joinOnDays = () => {
                const { lovsStore } = this.props.RootStore;
                const days = this.state.fields.onDays
                    .sort((a, b) => {
                        return (
                            lovsStore.onDaysSortDictionary[a] - lovsStore.onDaysSortDictionary[b]
                        );
                    })
                    .map((day) => lovsStore.onDaysDictionary[day]);
                return days.length > 1
                    ? days.slice(0, days.length - 1).join(', ') + ' and ' + days[days.length - 1]
                    : days[0];
            };

            _getOccursString() {
                const { lovsStore } = this.props.RootStore;
                if (
                    this.state.fields.onDays.length === 0 &&
                    this.state.fields.repeats === 'WEEKLY'
                ) {
                    return ' ';
                }
                if (this.state.fields.repeats === 'MONTHLY') {
                    const daysMessage =
                        this.state.fields.repeatsForMonthly === 'onDateInMonth'
                            ? `Recurs day ${this.state.fields.repeatDayInMonth} every ${this.state.fields.repeatEvery} month(s)`
                            : `Recurs every ${this.state.fields.monthlyRepeatsOften.label} ${this.state.fields.monthlyRepeatsDay.label} of every ${this.state.fields.repeatEvery} month(s)`;
                    return (
                        <>
                            <span style={{ marginRight: 10 }}>
                                <Icon icon="repeat" color="grey" iconSize={16} />
                            </span>
                            {`${daysMessage} until ${formatDisplayDate(this.state.fields.until)}.`}
                        </>
                    );
                }
                if (this.state.fields.repeats === 'WEEKLY') {
                    return (
                        <>
                            <span style={{ marginRight: 10 }}>
                                <Icon icon="repeat" color="grey" iconSize={16} />
                            </span>
                            {`Recur every ${
                                this.state.fields.repeatEvery
                            } week(s) on ${this._joinOnDays()}
                            until ${formatDisplayDate(this.state.fields.until)}.`}
                        </>
                    );
                }
                if (this.state.fields.repeats === 'DAILY') {
                    return (
                        <>
                            <span style={{ marginRight: 10 }}>
                                <Icon icon="repeat" color="grey" iconSize={16} />
                            </span>
                            {this.state.fields.repeatsForDaily === 'everyWeekday'
                                ? `Recur every weekday until ${formatDisplayDate(
                                      this.state.fields.until,
                                  )}.`
                                : `Recur every ${
                                      this.state.fields.repeatEvery
                                  } day(s) until ${formatDisplayDate(this.state.fields.until)}.`}
                        </>
                    );
                }
                return (
                    <>
                        <span style={{ marginRight: 10 }}>
                            <Icon icon="repeat" color="grey" iconSize={16} />
                        </span>
                        {`Occurs ${lovsStore.jobRepeatsDictionary[this.state.fields.repeats]} until
                        ${formatDisplayDate(this.state.fields.until)}.`}
                    </>
                );
            }

            render() {
                const { lovsStore } = this.props.RootStore;
                const jobTypeOptions = lovsStore.jobType.filter(
                    (jobType) => jobType.value !== 'shift',
                );
                return (
                    <Modal
                        title={this.props.title}
                        {...this.props}
                        footer={this._renderFooter()}
                        onClose={this._handleCloseClick}
                    >
                        {this.state.isLoading ? <Loader /> : ''}
                        <Row>
                            {/*
                        =======================================
                        LEFT COLUMN
                        =======================================
                    */}

                            <Col xl={6}>
                                {/* AUTO-POPULATE FROM SYSTM ONE  */}
                                <AutoPopulate onAutoPopulate={this.handleAutoPopulate} />

                                {/* EXTERNAL JOB REFERENCE */}
                                {this._isVisible('systmOneRef') && (
                                    <FormGroup
                                        label="External Job Reference"
                                        labelFor="systmOneRef"
                                        labelInfo={this._requiredLabel('systmOneRef')}
                                    >
                                        <InputGroup
                                            id="systmOneRef"
                                            autoComplete="new-password"
                                            large
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            value={this.state.fields.systmOneRef}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .systmOneRef,
                                            })}
                                        />
                                        <Error errors={this.state.errors.systmOneRef} />
                                    </FormGroup>
                                )}

                                {/* JOB TYPE */}
                                {!this.isScheduledJob && (
                                    <FormGroup
                                        label="Job Type"
                                        labelFor="jobType"
                                        labelInfo={this._requiredLabel('jobType')}
                                    >
                                        <Select
                                            options={jobTypeOptions}
                                            id="jobType"
                                            onChange={(e) => this.handleInputChange(e, 'jobType')}
                                            value={this.state.fields.jobType}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated.jobType,
                                            })}
                                        />
                                        <Error errors={this.state.errors.jobType} />
                                    </FormGroup>
                                )}

                                {/* DISPOSITION */}
                                {this._isVisible('disposition') && (
                                    <FormGroup
                                        label="Disposition"
                                        labelFor="disposition"
                                        labelInfo={this._requiredLabel('disposition')}
                                    >
                                        <Select
                                            options={lovsStore.disposition}
                                            id="disposition"
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'disposition')
                                            }
                                            value={this.state.fields.disposition}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .disposition,
                                            })}
                                        />
                                        <Error errors={this.state.errors.disposition} />
                                    </FormGroup>
                                )}

                                {/* BLOCK SIZE */}
                                {this._isVisible('blockSize') && (
                                    <FormGroup
                                        label="Block Size"
                                        labelFor="blockSize"
                                        labelInfo={this._requiredLabel('blockSize')}
                                    >
                                        {lovsStore.blockSize ? (
                                            <Select
                                                options={lovsStore.blockSize.hours.units}
                                                id="blockSize"
                                                onChange={(e) => this._computePrice(e, 'blockSize')}
                                                value={this.state.fields.blockSize}
                                                className={classNames({
                                                    'auto-populated': this.state.autoPopulated
                                                        .blockSize,
                                                })}
                                            />
                                        ) : (
                                            <InputGroup
                                                id="blockSize"
                                                autoComplete="new-password"
                                                large
                                                onChange={(e) =>
                                                    this.handleInputChange(
                                                        {
                                                            label: e.target.value,
                                                            value: e.target.value,
                                                        },
                                                        'blockSize',
                                                    )
                                                }
                                                value={this.state.fields.blockSize.label}
                                                className={classNames({
                                                    'auto-populated': this.state.autoPopulated
                                                        .blockSize,
                                                })}
                                            />
                                        )}
                                        <Error errors={this.state.errors.blockSize} />
                                    </FormGroup>
                                )}

                                {/* SHIFT TYPE */}
                                {this._isVisible('shiftType') && (
                                    <FormGroup
                                        label="Shift Type"
                                        labelFor="shiftType"
                                        labelInfo={this._requiredLabel('shiftType')}
                                    >
                                        <Select
                                            options={lovsStore.shiftType.sort((a, b) =>
                                                a.label.localeCompare(b.label),
                                            )}
                                            id="shiftType"
                                            onChange={(e) => this._computePrice(e, 'shiftType')}
                                            value={this.state.fields.shiftType}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .shiftType,
                                            })}
                                        />
                                        <Error errors={this.state.errors.shiftType} />
                                    </FormGroup>
                                )}

                                {/* FIRST NAME */}
                                {this._isVisible('nhsNumber') && (
                                    <FormGroup
                                        label="NHS Number"
                                        labelFor="nhsNumber"
                                        labelInfo={this._requiredLabel('nhsNumber')}
                                    >
                                        <InputGroup
                                            id="nhsNumber"
                                            autoComplete="new-password"
                                            large
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            value={this.state.fields.nhsNumber}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .nhsNumber,
                                            })}
                                        />
                                        <Error errors={this.state.errors.nhsNumber} />
                                    </FormGroup>
                                )}

                                <Row>
                                    <Col md={6}>
                                        {/* FIRST NAME */}
                                        {this._isVisible('firstName') && (
                                            <FormGroup
                                                label="First Name"
                                                labelFor="firstName"
                                                labelInfo={this._requiredLabel('firstName')}
                                            >
                                                <InputGroup
                                                    id="firstName"
                                                    autoComplete="new-password"
                                                    large
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    value={this.state.fields.firstName}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .firstName,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.firstName} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* LAST NAME */}
                                        {this._isVisible('lastName') && (
                                            <FormGroup
                                                label="Last Name"
                                                labelFor="lastName"
                                                labelInfo={this._requiredLabel('lastName')}
                                            >
                                                <InputGroup
                                                    id="lastName"
                                                    autoComplete="new-password"
                                                    large
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    value={this.state.fields.lastName}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .lastName,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.lastName} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        {/* DATE OF BIRTH */}
                                        {this._isVisible('dateOfBirth') && (
                                            <FormGroup label="Date of Birth" labelFor="dateOfBirth">
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'dateOfBirth');
                                                    }}
                                                    minDate={this.state.helpers.minDate}
                                                    value={this.state.fields.dateOfBirth?.toISOString()}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .dateOfBirth,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.dateOfBirth} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* AGE */}
                                        {this._isVisible('age') && (
                                            <FormGroup label="Age" labelFor="age">
                                                <NumericInput
                                                    id="age"
                                                    autoComplete="new-password"
                                                    onValueChange={(value) =>
                                                        this.handleInputChange(value, 'age')
                                                    }
                                                    value={
                                                        this.state.fields.age !== null
                                                            ? this.state.fields.age
                                                            : ''
                                                    }
                                                    large
                                                    fill
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .age,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.age} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        {/* GENDER */}
                                        {this._isVisible('gender') && (
                                            <FormGroup
                                                label="Gender"
                                                labelFor="gender"
                                                labelInfo={this._requiredLabel('gender')}
                                            >
                                                <Select
                                                    options={lovsStore.gender}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'gender')
                                                    }
                                                    value={this.state.fields.gender}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .gender,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.gender} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* CONTACT NUMBER */}

                                        {this._isVisible('contactNumber') && (
                                            <FormGroup
                                                label="Contact Number"
                                                labelFor="contactNumber"
                                                labelInfo={this._requiredLabel('contactNumber')}
                                                className={classNames({
                                                    'auto-populated': this.state.autoPopulated
                                                        .contactNumber,
                                                })}
                                            >
                                                <ReactPhoneInput
                                                    inputProps={{
                                                        id: 'contactNumber',
                                                        autoComplete: 'new-password',
                                                    }}
                                                    country="gb"
                                                    onlyCountries={['gb']}
                                                    onChange={(value) =>
                                                        this.handleInputChange(
                                                            value,
                                                            'contactNumber',
                                                        )
                                                    }
                                                    value={this.state.fields.contactNumber}
                                                    inputClass={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .contactNumber,
                                                    })}
                                                    countryCodeEditable={false}
                                                    specialLabel=""
                                                />
                                                <Error errors={this.state.errors.contactNumber} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                {/* PRACTICE */}
                                {this._isVisible('prefillPractice') && (
                                    <div className="prefill__practice">
                                        <RadioGroup
                                            label="Prefill address from:"
                                            labelFor="practiceList"
                                            onChange={this._handlePracticeListChange}
                                            selectedValue={this.state.practiceList}
                                            inline
                                        >
                                            <Radio label="GP Practices" value="GP_PRACTICES" />
                                            <Radio
                                                label="Preset Addresses"
                                                value="PRESET_ADDRESSES"
                                            />
                                        </RadioGroup>
                                        <FormGroup
                                            label={
                                                this.state.practiceList === 'GP_PRACTICES'
                                                    ? 'Practice'
                                                    : 'Preset Address'
                                            }
                                            labelFor="practice"
                                            labelInfo={this._requiredLabel('practice')}
                                        >
                                            {this.state.practiceList === 'GP_PRACTICES' ? (
                                                <PracticeSelect
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'practice')
                                                    }
                                                    value={this.state.fields.practice}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .practice,
                                                    })}
                                                    isClearable={true}
                                                />
                                            ) : (
                                                <Select
                                                    options={this.props.RootStore.configStore.presetAddresses.map(
                                                        (practice) => ({
                                                            label: practice.name,
                                                            value: practice.name,
                                                        }),
                                                    )}
                                                    onChange={(e) =>
                                                        this.handleInputChange(e, 'practice')
                                                    }
                                                    value={this.state.fields.practice}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .practice,
                                                    })}
                                                />
                                            )}
                                            <Error errors={this.state.errors.practice} />
                                        </FormGroup>
                                    </div>
                                )}

                                {/* ADDRESS LINE 1 */}
                                {this._isVisible('addressLine1') && (
                                    <FormGroup
                                        label="Address Line 1"
                                        labelFor="addressLine1"
                                        labelInfo={this._requiredLabel('addressLine1')}
                                    >
                                        <InputGroup
                                            id="addressLine1"
                                            autoComplete="new-password"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            large
                                            value={this.state.fields.addressLine1}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .addressLine1,
                                            })}
                                        />
                                        <Error errors={this.state.errors.addressLine1} />
                                    </FormGroup>
                                )}

                                {/* ADDRESS LINE 2 */}
                                {this._isVisible('addressLine2') && (
                                    <FormGroup label="Address Line 2" labelFor="addressLine2">
                                        <InputGroup
                                            id="addressLine2"
                                            autoComplete="new-password"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            large
                                            value={this.state.fields.addressLine2}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .addressLine2,
                                            })}
                                        />
                                        <Error errors={this.state.errors.addressLine2} />
                                    </FormGroup>
                                )}

                                <Row>
                                    <Col md={7}>
                                        {/* TOWN / CITY */}
                                        {this._isVisible('town') && (
                                            <FormGroup
                                                label="Town / City"
                                                labelFor="town"
                                                labelInfo={this._requiredLabel('town')}
                                            >
                                                <InputGroup
                                                    id="town"
                                                    autoComplete="new-password"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    large
                                                    value={this.state.fields.town}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .town,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.town} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={5}>
                                        {/* POST CODE */}
                                        {this._isVisible('postCode') && (
                                            <FormGroup
                                                label="Post Code"
                                                labelFor="postCode"
                                                labelInfo={this._requiredLabel('postCode')}
                                            >
                                                <InputGroup
                                                    id="postCode"
                                                    autoComplete="new-password"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.value,
                                                            e.target.id,
                                                        )
                                                    }
                                                    large
                                                    value={this.state.fields.postCode}
                                                    className={classNames({
                                                        'auto-populated': this.state.autoPopulated
                                                            .postCode,
                                                    })}
                                                />
                                                <Error errors={this.state.errors.postCode} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                            </Col>

                            {/*
                        =======================================
                        RIGHT COLUMN
                        =======================================
                    */}

                            <Col xl={6}>
                                {/* PRACTICE */}
                                {this._isVisible('practice') && (
                                    <FormGroup
                                        label="Practice"
                                        labelFor="practice"
                                        labelInfo={this._requiredLabel('practice')}
                                    >
                                        <PracticeSelect
                                            onChange={(e) => this.handleInputChange(e, 'practice')}
                                            value={this.state.fields.practice}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated.practice,
                                            })}
                                            placeholder="Start typing the practice name to see results"
                                        />
                                        <Error errors={this.state.errors.practice} />
                                    </FormGroup>
                                )}

                                {/* LANGUAGE */}
                                {this._isVisible('languagesSpoken') && (
                                    <FormGroup label="Language" labelFor="languagesSpoken">
                                        <Select
                                            options={lovsStore.languagesSpoken}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'languagesSpoken')
                                            }
                                            value={this.state.fields.languagesSpoken}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.languagesSpoken} />
                                    </FormGroup>
                                )}

                                {/* SPECIALITY */}
                                {this._isVisible('speciality') && (
                                    <FormGroup label="Speciality" labelFor="speciality">
                                        <Select
                                            options={lovsStore.speciality}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'speciality')
                                            }
                                            value={this.state.fields.speciality}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.speciality} />
                                    </FormGroup>
                                )}

                                {/* FLAGS */}
                                {this._isVisible('flags') && (
                                    <FormGroup label="Flags" labelFor="flags">
                                        <Select
                                            options={lovsStore.flags}
                                            onChange={(e) => this.handleInputChange(e, 'flags')}
                                            value={this.state.fields.flags}
                                            isMulti
                                        />
                                        <Error errors={this.state.errors.flags} />
                                    </FormGroup>
                                )}

                                {/* NOTES */}
                                {this._isVisible('notes') && (
                                    <FormGroup label="Notes" labelFor="notes">
                                        <TextArea
                                            id="notes"
                                            onChange={(e) =>
                                                this.handleInputChange(e.target.value, e.target.id)
                                            }
                                            fill
                                            large
                                            value={this.state.fields.notes}
                                            rows={9}
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated.notes,
                                            })}
                                        />
                                        <Error errors={this.state.errors.notes} />
                                    </FormGroup>
                                )}

                                {/* HCP TYPE */}
                                {this._isVisible('hcpType') && (
                                    <FormGroup
                                        label="HCP Type"
                                        labelFor="hcpType"
                                        labelInfo={this._requiredLabel('hcpType')}
                                    >
                                        <Select
                                            options={this._sortHcpTypes(lovsStore.hcpType)}
                                            onChange={(e) => this._handleHcpTypeChange(e)}
                                            value={this.state.fields.hcpType}
                                            isMulti
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated.hcpType,
                                            })}
                                        />
                                        <Error errors={this.state.errors.hcpType} />
                                    </FormGroup>
                                )}

                                {/* NOMINATED HCPS */}
                                {this._isVisible('nominatedHcps') && (
                                    <FormGroup
                                        label="Nominated HCPs"
                                        labelFor="nominatedHcps"
                                        helperText="Only notifies and makes the job available to the selected HCPs. Leave blank to post job to all relevant HCPs."
                                    >
                                        <Select
                                            options={this._filterHcpsByType(
                                                lovsStore.nominatedHcps,
                                            )}
                                            onChange={(e) =>
                                                this.handleInputChange(e, 'nominatedHcps')
                                            }
                                            value={this.state.fields.nominatedHcps}
                                            isMulti
                                            className={classNames({
                                                'auto-populated': this.state.autoPopulated
                                                    .nominatedHcps,
                                            })}
                                            noOptionsMessage={({ inputValue }) =>
                                                inputValue
                                                    ? 'No results'
                                                    : 'Please select at least one HCP type above to see results'
                                            }
                                        />
                                        <Error errors={this.state.errors.nominatedHcps} />
                                    </FormGroup>
                                )}

                                {/* PRICE */}
                                {this._isVisible('price') && (
                                    <Row>
                                        {/* UNIT PRICE */}
                                        {this._isVisible('blockSize') && lovsStore.blockSize && (
                                            <Col md={6}>
                                                <FormGroup label="Unit price" labelFor="unitPrice">
                                                    <Select
                                                        options={lovsStore.blockSize.hours.prices}
                                                        id="unitPrice"
                                                        onChange={(e) =>
                                                            this._computePrice(e, 'unitPrice')
                                                        }
                                                        value={this.state.fields.unitPrice}
                                                        className={classNames({
                                                            'auto-populated': this.state
                                                                .autoPopulated.unitPrice,
                                                        })}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                        <Col md={6}>
                                            <FormGroup
                                                label="Price"
                                                labelFor="price"
                                                labelInfo={this._requiredLabel('price')}
                                            >
                                                <NumericInput
                                                    id="price"
                                                    onValueChange={(value) =>
                                                        this._handlePriceChange(value)
                                                    }
                                                    large
                                                    value={
                                                        this.state.fields.price !== null
                                                            ? this.state.fields.price
                                                            : 0
                                                    }
                                                    fill
                                                />
                                                <Error errors={this.state.errors.price} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup
                                                label="Price Visibility"
                                                labelFor="hidePriceInApp"
                                            >
                                                <Checkbox
                                                    id="hidePriceInApp"
                                                    label="Hide price in app"
                                                    onChange={(e) =>
                                                        this.handleInputChange(
                                                            e.target.checked,
                                                            'hidePriceInApp',
                                                        )
                                                    }
                                                    checked={this.state.fields.hidePriceInApp}
                                                />
                                                <Error errors={this.state.errors.hidePriceInApp} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col md={6}>
                                        {/* START DATE */}
                                        {this._isVisible('startDate') && (
                                            <FormGroup
                                                label="Start Date"
                                                labelFor="startDate"
                                                labelInfo={this._requiredLabel('startDate')}
                                            >
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'startDate');
                                                    }}
                                                    minDate={this.state.helpers.minDate}
                                                    maxDate={this.state.helpers.maxDate}
                                                    value={this.state.fields.startDate?.toISOString()}
                                                />
                                                <Error errors={this.state.errors.startDate} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* START TIME */}
                                        {this._isVisible('startTime') && (
                                            <FormGroup
                                                label="Start Time"
                                                labelFor="startTime"
                                                labelInfo={this._requiredLabel('startTime')}
                                            >
                                                <TimePicker
                                                    onChange={(time) =>
                                                        this.handleInputChange(time, 'startTime')
                                                    }
                                                    value={this.state.fields.startTime}
                                                />
                                                <Error errors={this.state.errors.startTime} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>

                                {/* END DATE/TIME */}
                                {this._isVisible('endDate') && (
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup
                                                label="End Date"
                                                labelFor="endDate"
                                                labelInfo={this._requiredLabel('endDate')}
                                            >
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'endDate');
                                                    }}
                                                    minDate={this.state.helpers.minDate}
                                                    maxDate={this.state.helpers.maxDate}
                                                    value={this.state.fields.endDate?.toISOString()}
                                                />
                                                <Error errors={this.state.errors.endDate} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup
                                                label="End Time"
                                                labelFor="endTime"
                                                labelInfo={this._requiredLabel('endTime')}
                                            >
                                                <TimePicker
                                                    onChange={(time) =>
                                                        this.handleInputChange(time, 'endTime')
                                                    }
                                                    value={this.state.fields.endTime}
                                                />
                                                <Error errors={this.state.errors.endTime} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}

                                <Row>
                                    <Col md={6}>
                                        {/* EXPIRY DATE */}
                                        {this._isVisible('expiryDate') && (
                                            <FormGroup
                                                label="Expiry Date"
                                                labelFor="expiryDate"
                                                labelInfo={this._requiredLabel('expiryDate')}
                                            >
                                                <DateInput3
                                                    formatDate={(date) => formatDisplayDate(date)}
                                                    parseDate={parseDateStringToJSDate}
                                                    onChange={(date) => {
                                                        if (date) {
                                                            date = new Date(date);
                                                        }
                                                        this.handleInputChange(date, 'expiryDate');
                                                    }}
                                                    maxDate={this.state.helpers.maxDate}
                                                    value={this.state.fields.expiryDate?.toISOString()}
                                                />
                                                <Error errors={this.state.errors.expiryDate} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        {/* END TIME */}
                                        {this._isVisible('expiryTime') && (
                                            <FormGroup
                                                label="Expiry Time"
                                                labelFor="expiryTime"
                                                labelInfo={this._requiredLabel('expiryTime')}
                                            >
                                                <TimePicker
                                                    onChange={(time) =>
                                                        this.handleInputChange(time, 'expiryTime')
                                                    }
                                                    value={this.state.fields.expiryTime}
                                                />
                                                <Error errors={this.state.errors.expiryTime} />
                                            </FormGroup>
                                        )}
                                    </Col>
                                </Row>
                                {/* REPEATS */}
                                {this._isVisible('repeats') && (
                                    <RadioGroup
                                        inline
                                        label="Repeats"
                                        name="group"
                                        options={lovsStore.jobRepeats}
                                        onChange={(input) =>
                                            this.handleInputChange(
                                                input.currentTarget.value,
                                                'repeats',
                                            )
                                        }
                                        selectedValue={this.state.fields.repeats}
                                        disabled={
                                            !this.state.isSeries && this.props.actionType === 'edit'
                                        }
                                    />
                                )}
                                {((this.state.fields.repeats !== 'NEVER' &&
                                    this.props.actionType === 'create') ||
                                    (this.state.isSeries && this.props.actionType === 'edit')) && (
                                    <Callout intent="primary" icon={null} style={{ minWidth: 650 }}>
                                        <Row>
                                            {this.state.fields.repeats === 'MONTHLY' && (
                                                <Col xs={12} md={6} lg={12}>
                                                    <RadioGroup
                                                        label=""
                                                        name="group-monthly"
                                                        onChange={(input) =>
                                                            this.handleInputChange(
                                                                input.currentTarget.value,
                                                                'repeatsForMonthly',
                                                            )
                                                        }
                                                        selectedValue={
                                                            this.state.fields.repeatsForMonthly
                                                        }
                                                    >
                                                        <Row
                                                            style={{
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Col
                                                                xs={12}
                                                                md={6}
                                                                lg={2}
                                                                style={{ maxWidth: '12%' }}
                                                            >
                                                                <Radio
                                                                    label="Day"
                                                                    value="onDateInMonth"
                                                                    checked={
                                                                        this.state.fields
                                                                            .repeatsForMonthly ===
                                                                        'onDateInMonth'
                                                                    }
                                                                    onChange={(input) =>
                                                                        this.handleInputChange(
                                                                            input.currentTarget
                                                                                .value,
                                                                            'repeatsForMonthly',
                                                                        )
                                                                    }
                                                                    style={{ width: 80 }}
                                                                    className="radio-label-without-margin"
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6} lg={2}>
                                                                <NumericInput
                                                                    id="repeatDayInMonth"
                                                                    max={31}
                                                                    min={1}
                                                                    onValueChange={(value) =>
                                                                        this.handleInputChange(
                                                                            value,
                                                                            'repeatDayInMonth',
                                                                        )
                                                                    }
                                                                    large
                                                                    value={
                                                                        this.state.fields
                                                                            .repeatDayInMonth
                                                                    }
                                                                    fill
                                                                    disabled={false}
                                                                />
                                                                <Error
                                                                    errors={
                                                                        this.state.errors
                                                                            .repeatDayInMonth
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                md={6}
                                                                lg={2}
                                                                style={{ maxWidth: '12%' }}
                                                            >
                                                                <div>of every</div>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={2}>
                                                                <NumericInput
                                                                    id="repeatEvery"
                                                                    max={99}
                                                                    min={1}
                                                                    onValueChange={(value) =>
                                                                        this.handleInputChange(
                                                                            value,
                                                                            'repeatEvery',
                                                                        )
                                                                    }
                                                                    large
                                                                    value={
                                                                        this.state.fields
                                                                            .repeatEvery
                                                                    }
                                                                    fill
                                                                    disabled={
                                                                        this.state.fields
                                                                            .repeatsForDaily ===
                                                                            'everyWeekday' &&
                                                                        this.state.fields
                                                                            .repeats === 'DAILY'
                                                                    }
                                                                />
                                                                <Error
                                                                    errors={
                                                                        this.state.errors
                                                                            .repeatEvery
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6} lg={1}>
                                                                <div>month(s)</div>
                                                            </Col>
                                                        </Row>
                                                        <Row
                                                            style={{
                                                                marginTop: 20,
                                                                alignItems: 'center',
                                                            }}
                                                        >
                                                            <Col
                                                                xs={12}
                                                                md={6}
                                                                lg={2}
                                                                style={{ maxWidth: '12%' }}
                                                            >
                                                                <Radio
                                                                    label="The"
                                                                    value="onDayInMonth"
                                                                    checked={
                                                                        this.state.fields
                                                                            .repeatsForMonthly ===
                                                                        'onDayInMonth'
                                                                    }
                                                                    onChange={(input) =>
                                                                        this.handleInputChange(
                                                                            input.currentTarget
                                                                                .value,
                                                                            'repeatsForMonthly',
                                                                        )
                                                                    }
                                                                    className="radio-label-without-margin"
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6} lg={2}>
                                                                <Select
                                                                    options={
                                                                        lovsStore.monthlyRepeatsOften
                                                                    }
                                                                    id="monthlyRepeatsOften"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            'monthlyRepeatsOften',
                                                                        )
                                                                    }
                                                                    value={
                                                                        this.state.fields
                                                                            .monthlyRepeatsOften
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6} lg={3}>
                                                                <Select
                                                                    options={
                                                                        lovsStore.monthlyRepeatsDays
                                                                    }
                                                                    id="monthlyRepeatsOften"
                                                                    onChange={(e) =>
                                                                        this.handleInputChange(
                                                                            e,
                                                                            'monthlyRepeatsDay',
                                                                        )
                                                                    }
                                                                    value={
                                                                        this.state.fields
                                                                            .monthlyRepeatsDay
                                                                    }
                                                                    menuPlacement="top"
                                                                />
                                                            </Col>
                                                            <Col
                                                                xs={12}
                                                                md={6}
                                                                lg={2}
                                                                style={{ maxWidth: '12%' }}
                                                            >
                                                                <div>of every</div>
                                                            </Col>
                                                            <Col xs={12} md={6} lg={2}>
                                                                <NumericInput
                                                                    id="repeatEvery"
                                                                    max={99}
                                                                    min={1}
                                                                    onValueChange={(value) =>
                                                                        this.handleInputChange(
                                                                            value,
                                                                            'repeatEvery',
                                                                        )
                                                                    }
                                                                    large
                                                                    value={
                                                                        this.state.fields
                                                                            .repeatEvery
                                                                    }
                                                                    fill
                                                                    disabled={
                                                                        this.state.fields
                                                                            .repeatsForDaily ===
                                                                            'everyWeekday' &&
                                                                        this.state.fields
                                                                            .repeats === 'DAILY'
                                                                    }
                                                                />
                                                                <Error
                                                                    errors={
                                                                        this.state.errors
                                                                            .repeatEvery
                                                                    }
                                                                />
                                                            </Col>
                                                            <Col xs={12} md={6} lg={1}>
                                                                <div>month(s)</div>
                                                            </Col>
                                                        </Row>
                                                    </RadioGroup>
                                                    <Row>
                                                        <Col
                                                            xs={12}
                                                            md={6}
                                                            lg={4}
                                                            style={{ marginTop: 15 }}
                                                        >
                                                            <FormGroup
                                                                label="End by"
                                                                labelFor="until"
                                                                labelInfo={this._requiredLabel(
                                                                    'until',
                                                                )}
                                                            >
                                                                <DateInput3
                                                                    formatDate={(date) =>
                                                                        formatDisplayDate(date)
                                                                    }
                                                                    parseDate={
                                                                        parseDateStringToJSDate
                                                                    }
                                                                    onChange={(date) => {
                                                                        if (date) {
                                                                            date = new Date(date);
                                                                        }
                                                                        this.handleInputChange(
                                                                            date,
                                                                            'until',
                                                                        );
                                                                    }}
                                                                    minDate={
                                                                        this.state.helpers.minDate
                                                                    }
                                                                    maxDate={
                                                                        this.state.helpers.maxDate
                                                                    }
                                                                    value={this.state.fields.until?.toISOString()}
                                                                />
                                                                <Error
                                                                    errors={this.state.errors.until}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                        <Row>
                                            {this.state.fields.repeats === 'DAILY' && (
                                                <Col xs={12} md={6} lg={3}>
                                                    <RadioGroup
                                                        label="Recur"
                                                        name="group-daily"
                                                        onChange={(input) =>
                                                            this.handleInputChange(
                                                                input.currentTarget.value,
                                                                'repeatsForDaily',
                                                            )
                                                        }
                                                        options={lovsStore.dailyRepeats}
                                                        selectedValue={
                                                            this.state.fields.repeatsForDaily
                                                        }
                                                    />
                                                </Col>
                                            )}
                                            {this.state.fields.repeats !== 'NEVER' &&
                                                this.state.fields.repeats !== 'MONTHLY' && (
                                                    <Col xs={12} md={6} lg={4}>
                                                        <FormGroup
                                                            label={
                                                                this.state.fields.repeats ===
                                                                'DAILY'
                                                                    ? 'day(s)'
                                                                    : 'Recur every'
                                                            }
                                                            labelFor="repeatEvery"
                                                        >
                                                            <NumericInput
                                                                id="repeatEvery"
                                                                max={99}
                                                                min={1}
                                                                onValueChange={(value) =>
                                                                    this.handleInputChange(
                                                                        value,
                                                                        'repeatEvery',
                                                                    )
                                                                }
                                                                large
                                                                value={
                                                                    this.state.fields.repeatEvery
                                                                }
                                                                fill
                                                                disabled={
                                                                    this.state.fields
                                                                        .repeatsForDaily ===
                                                                        'everyWeekday' &&
                                                                    this.state.fields.repeats ===
                                                                        'DAILY'
                                                                }
                                                            />
                                                            <Error
                                                                errors={
                                                                    this.state.errors.repeatEvery
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                )}
                                            {this.state.fields.repeats === 'WEEKLY' && (
                                                <Col xs={12} md={6} lg={2}>
                                                    <div style={{ marginTop: 30 }}>week(s)</div>
                                                </Col>
                                            )}
                                            {this.state.fields.repeats !== 'MONTHLY' && (
                                                <Col xs={12} md={6} lg={4}>
                                                    <FormGroup
                                                        label="End by"
                                                        labelFor="until"
                                                        labelInfo={this._requiredLabel('until')}
                                                    >
                                                        <DateInput3
                                                            formatDate={(date) =>
                                                                formatDisplayDate(date)
                                                            }
                                                            parseDate={parseDateStringToJSDate}
                                                            onChange={(date) => {
                                                                if (date) {
                                                                    date = new Date(date);
                                                                }
                                                                this.handleInputChange(
                                                                    date,
                                                                    'until',
                                                                );
                                                            }}
                                                            minDate={this.state.helpers.minDate}
                                                            maxDate={this.state.helpers.maxDate}
                                                            value={this.state.fields.until?.toISOString()}
                                                        />
                                                        <Error errors={this.state.errors.until} />
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                                        {this.state.fields.repeats === 'WEEKLY' && (
                                            <Row>
                                                <Col>
                                                    <FormGroup
                                                        label="On days"
                                                        labelFor="On days"
                                                        labelInfo="(required)"
                                                    >
                                                        <ButtonGroup fill={true}>
                                                            {lovsStore.onDays.map((day) => (
                                                                <Button
                                                                    style={{ zIndex: 0 }}
                                                                    active={this.state.fields.onDays.includes(
                                                                        day,
                                                                    )}
                                                                    key={day}
                                                                    text={day}
                                                                    onClick={() =>
                                                                        this.handleOnDaysButtonChange(
                                                                            day,
                                                                        )
                                                                    }
                                                                />
                                                            ))}
                                                        </ButtonGroup>
                                                        <Error errors={this.state.errors.onDays} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        )}
                                        <Row>
                                            <Col>{this._getOccursString()}</Col>
                                        </Row>
                                        {this.state.isShowAlert && (
                                            <Callout intent="danger" style={{ marginTop: 10 }}>
                                                The duration of the appointment must be shorter than
                                                how frequently it occurs.
                                            </Callout>
                                        )}
                                        {this.state.isShowMsg && (
                                            <Callout intent="warning" style={{ marginTop: 10 }}>
                                                {`Some months have fewer than ${this.state.fields.repeatDayInMonth} days. For these
                                                months, the occurrence will fall on the last day of
                                                the month.`}
                                            </Callout>
                                        )}
                                    </Callout>
                                )}
                                {!this.state.isSeries && this.props.actionType === 'edit' && (
                                    <Callout intent="primary" icon={null} style={{ minWidth: 650 }}>
                                        <Col>{this._getOccursString()}</Col>
                                        <Callout intent="warning" style={{ marginTop: 10 }}>
                                            For edit repeating rules, you need to choose option edit
                                            entire series
                                        </Callout>
                                    </Callout>
                                )}
                            </Col>
                        </Row>
                    </Modal>
                );
            }
        },
    ),
);

export default JobForm;
