import { gql } from '@apollo/client';

export const GET_PROFESSIONALBODIES = gql`
    query GetProfessionalBodies {
        getProfessionalBodies {
            id
            updatedAt
            createdAt
            name
            key
        }
    }
`;
