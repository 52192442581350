import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import RegistrationNavigation from './RegistrationNavigation';
import RegistrationHeader from './RegistrationHeader';
import RegistrationItem from './RegistrationItem';
import RegistrationDetails from './RegistrationDetails';
import Loader from '../../../modules/helpers/Loader';

import { Registration } from '../../../../stores/RegistrationStore';

const AdminManageRegistrations = inject('RootStore')(
    observer(
        class AdminManageRegistrations extends Component<any> {
            state = {
                isLoading: true,
                isShowEditForm: false,
                isShowCreateForm: false,
                registrationId: null,
            };

            async componentDidMount() {
                try {
                    this.props.RootStore.registrationStore.onFilter(
                        'personId',
                        this.props.person.id,
                    );

                    await this.props.RootStore.registrationStore.getRegistrations();
                    await this.props.RootStore.professionalBodyStore.getProfessionalBodies();
                    await this.props.RootStore.fitToPracticeStore.getFitToPractices();
                } catch (err) {
                    //TODO
                    console.log('err', err);
                } finally {
                    if (this.props.location.state && this.props.location.state.registrationId) {
                        this.setState({
                            isShowEditForm: true,
                            registrationId: this.props.location.state.registrationId,
                        });
                    }
                    this.setState({ isLoading: false });
                }
            }

            componentWillUnmount() {
                this.props.RootStore.registrationStore.clear();
            }

            _handleAddNew = () => {
                this.setState({ isShowCreateForm: true });
            };

            _handleRegistrationClick = (registrationId: string, event: React.MouseEvent) => {
                const { tagName } = event.target as HTMLElement;
                if (tagName === 'TD') {
                    this.setState({ isShowEditForm: true, registrationId });
                }
            };

            _handleCancel = () => {
                this.setState({
                    isShowEditForm: false,
                    isShowCreateForm: false,
                    registrationId: null,
                });
                this.props.RootStore.registrationStore.refresh();
            };

            render() {
                const {
                    RootStore: {
                        registrationStore: { registrations, pageInfo, nextPage, previousPage },
                    },
                    person,
                } = this.props;

                const { isLoading, isShowEditForm, isShowCreateForm, registrationId } = this.state;

                return (
                    <>
                        {isLoading && <Loader />}
                        {!isShowEditForm && !isShowCreateForm ? (
                            <>
                                <RegistrationNavigation
                                    pageInfo={pageInfo}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    addRegistration={this._handleAddNew}
                                    loading={false}
                                />
                                <table className="bp5-html-table bp5-interactive common-table">
                                    <RegistrationHeader />
                                    <tbody>
                                        {registrations &&
                                            registrations.map((registration: Registration) => (
                                                <RegistrationItem
                                                    {...registration}
                                                    onClick={this._handleRegistrationClick}
                                                    key={registration.id}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            </>
                        ) : (
                            <RegistrationDetails
                                {...this.props}
                                actionType={isShowEditForm ? 'edit' : 'create'}
                                handleCancel={this._handleCancel}
                                registrationId={registrationId}
                                personId={person.id}
                                disableEditing={
                                    person &&
                                    person.statusId === '7799ad39-2f73-490d-9e98-27ac149dee55'
                                }
                            />
                        )}
                    </>
                );
            }
        },
    ),
);

export default AdminManageRegistrations;
