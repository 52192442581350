import React, { FC } from 'react';

import { VaccinationWithWarnings } from '../../types';

interface Props {
    warnings: VaccinationWithWarnings[];
}

export const Warnings: FC<Props> = ({ warnings }) => (
    <table className="bp5-html-table">
        <thead>
            <tr>
                <th>First name</th>
                <th>Last name</th>
                <th>Dose number</th>
                <th>Warnings</th>
            </tr>
        </thead>
        <tbody>
            {warnings.map(({ id, firstName, lastName, doseNumber, warnings }) => (
                <tr key={id}>
                    <td>{firstName}</td>
                    <td>{lastName}</td>
                    <td>{doseNumber}</td>
                    <td>
                        {warnings?.map((warning) => (
                            <li key={warning}>{warning}</li>
                        ))}
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
);
