import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';

import Error from '../../forms/Error';
import Select, { components } from 'react-select';
import { compareByLabels } from '../../helpers/sortFunctions';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';
import AppToaster from '../../helpers/Toaster';

interface RolesSummaryProps {
    editOrCreateMode: boolean;
    values: FormikValues;
    handleChange: () => void;
    setFieldValue: (key: string, value: any) => void;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    role: any;
    categories: any;
    rolesStore: any;
    actionType: string;
    functions: any;
}

const RolesSummary = ({
    role,
    actionType,
    editOrCreateMode,
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    categories,
    rolesStore,
    functions,
}: RolesSummaryProps) => {
    const MultiValueRemove = (props: any) => {
        if (props.data.isFixed) {
            return null;
        }
        return <components.MultiValueRemove {...props} />;
    };
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'roles'}
                componentPath={'/hr/roles/'}
                items={rolesStore.allRolesActive}
                editOrCreateMode={editOrCreateMode}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
            />
            <dl className="info">
                <dt className="info__title">Required documents</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="categories">
                            <Select
                                options={categories.sort(compareByLabels)}
                                defaultValue={(values.categories ?? []).map((x: any) => ({
                                    label: x.name,
                                    value: x.id,
                                }))}
                                onChange={(e) =>
                                    setFieldValue(
                                        'categories',
                                        (e ?? []).map((x) => x.value),
                                    )
                                }
                                isMulti
                                name="categories"
                                id="categories"
                            />
                            {touched.categories && <Error errors={[errors.categories]} />}
                        </FormGroup>
                    ) : (
                        role.categories.map((x: any) => x.name).join(', ')
                    )}
                </dd>
                <dt className="info__title">Functions</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="functions">
                            <Select
                                options={functions}
                                value={values.functions.map((x: any) => (!x.isArchived ? x : null))}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                onChange={async (e, selectedOptions) => {
                                    if (
                                        selectedOptions.action === 'remove-value' &&
                                        actionType === 'edit'
                                    ) {
                                        AppToaster.show({
                                            message: 'The function is removed!',
                                            intent: 'success',
                                        });
                                    }
                                    setFieldValue('functions', e);
                                }}
                                isMulti
                                name="functions"
                                id="functions"
                                components={{ MultiValueRemove }}
                            />
                            {values.functions.some((item: any) => item.isFixed) ? (
                                <p className="info-notification">
                                    <i className="info-notification__icon" />
                                    <span>
                                        Removal is disabled for those functions which are related to
                                        shift pattern shifts and/or future rota shifts of this role
                                    </span>
                                </p>
                            ) : (
                                ''
                            )}
                            {touched.functions && <Error errors={[errors.functions]} />}
                        </FormGroup>
                    ) : (
                        values.functions
                            .map((x: any) => (!x.isArchived ? x.label : null))
                            .join(', ')
                    )}
                </dd>
            </dl>
        </>
    );
};

export default RolesSummary;
