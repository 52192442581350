import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import TableRow from './TableRow';
import TableHead from './TableHead';
import Navigation from './Navigation';
import Loader from '../../../../modules/helpers/Loader';
import ShiftPatternsDetails from '../../../../modules/shiftPatterns/detail/ShiftPatternsDetails';
import ShiftPatternEntryDetails from '../../../../modules/shiftPatternEntry/detail/ShiftPatternEntryDetails';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import SelectDialog from '../../../../common/SelectDialog';

const RotaList = inject('RootStore')(
    observer(
        class ShiftPatterns extends Component {
            state = {
                showAlert: false,
                showDepartmentDialog: false,
            };

            componentDidMount() {
                this.props.RootStore.rotaBuilderStore.getAllRotas();
                this.props.RootStore.staffStore.getAllStaffs();
            }

            componentWillUnmount() {
                this.props.RootStore.rotaBuilderStore.clear();
                this.props.RootStore.rotaBuilderStore.showShiftEntryModal = false;
            }

            _handleItemClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/rota/builder/${id}`);
                    this.props.RootStore.rotaBuilderStore.isCreatePath = false;
                }
            };

            _handleAddNew = () => {
                this.setState((prevState) => {
                    return { showDepartmentDialog: !prevState.showDepartmentDialog };
                });
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.rotaBuilderStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.rotaBuilderStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };
            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.rotaBuilderStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.rotaBuilderStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });

                let rotas = [];
                selected.forEach((selectedItem) => {
                    const rToRemove = this.props.RootStore.rotaBuilderStore.gridRotas.find(
                        (r) => r.id === selectedItem,
                    );
                    rotas.push(rToRemove);
                });

                rotas.map((item) => item && this.props.RootStore.rotaBuilderStore.deleteRota(item));
            };

            _updateNewRotaCostCentre = (value) => {
                this.props.RootStore.rotaBuilderStore.updateNewRotaCostCentre(value);
            };

            _updateNewRotaDepartment = (value) => {
                this.props.RootStore.rotaBuilderStore.updateNewRotaDepartment(value);
            };

            render() {
                const {
                    RootStore: {
                        rotaBuilderStore: {
                            gridRotas,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            onSearch,
                            getEarliestRota,
                            loaded,
                            showShiftEntryModal,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};

                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';

                const { allStaffs } = this.props.RootStore.staffStore;

                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            {this.props.RootStore.staffStore.apolloClient && (
                                <Navigation
                                    onFilter={onFilter}
                                    onSearch={onSearch}
                                    pageInfo={pageInfo}
                                    previousPage={previousPage}
                                    nextPage={nextPage}
                                    loading={false}
                                    addNew={this._handleAddNew}
                                    deleteItem={this._handleToggleAlert}
                                    minRota={getEarliestRota}
                                    loadStaffMembers={
                                        this.props.RootStore.staffStore.getStaffRecords
                                    }
                                    loadPersons={this.props.RootStore.peopleStore.getPeopleRecords}
                                    rotaBuilderStore={this.props.RootStore.rotaBuilderStore}
                                    location={this.props.location}
                                />
                            )}
                            <table className="bp5-html-table bp5-interactive common-table">
                                <TableHead
                                    data={gridRotas}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridRotas.map((item) => {
                                        return (
                                            <TableRow
                                                {...item}
                                                onClick={this._handleItemClick}
                                                key={item.id}
                                                onToggle={this._handleToggleSelected}
                                                allStaffs={allStaffs}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <ShiftPatternsDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        {showShiftEntryModal && (
                            <div className="common-layout__modal">
                                <ShiftPatternEntryDetails />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these rotas? You won't be
                                        able to recover them."
                        />
                        <SelectDialog
                            title={'Select department to create rota'}
                            onCancel={() =>
                                this.setState((prevState) => {
                                    return {
                                        showDepartmentDialog: !prevState.showDepartmentDialog,
                                    };
                                })
                            }
                            onSubmit={(costCentre, department) => {
                                this._updateNewRotaCostCentre(costCentre);
                                this._updateNewRotaDepartment(department);
                                this.setState((prevState) => {
                                    return {
                                        showDepartmentDialog: !prevState.showDepartmentDialog,
                                    };
                                });
                                this.props.history.push(`/rota/builder`);
                            }}
                            isOpen={this.state.showDepartmentDialog}
                        />
                    </>
                );
            }
        },
    ),
);

export default RotaList;
