import moment from 'moment-timezone';
import { lowerCase } from 'lodash';

moment.locale('en-UK');

export const formatDateTime = (stateDate, stateTime) => {
    let stateDay = moment(stateDate).day(),
        stateMonth = moment(stateDate).month(),
        stateYear = moment(stateDate).year(),
        stateHour = moment(stateTime).hour(),
        stateMin = moment(stateTime).minute(),
        stateDateTime = moment(stateDate)
            .year(stateYear)
            .month(stateMonth)
            .day(stateDay)
            .hour(stateHour)
            .minute(stateMin)
            .seconds(0)
            .utcOffset(0) // Always apply utcOffset at the end to ensure correct conversion
            .format();

    return formatAPIDate(stateDateTime);
};

export const formatToDateWithoutUTC = (dateStr) => {
    const date = moment.utc(dateStr);
    const createdDate = new Date(Date.UTC(date.year(), date.month(), date.date()));

    createdDate.setUTCHours(0);
    createdDate.setUTCMinutes(0);
    createdDate.setUTCSeconds(0);
    createdDate.setUTCMilliseconds(0);
    return createdDate;
};

export const getCommaItemsList = (list) =>
    list.map((item, i) => {
        if (item === undefined) {
            return '';
        } else if (list.length === i + 1) {
            return item;
        }

        return item + ', ';
    });

export const capitalize = (text) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : text);

export const camelCaseString = (str) =>
    str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });

export const formatAPIMultiSelect = (value) => value.map((fieldValue) => fieldValue.value);

export const formatPrice = (price) => '£' + price / 100;

export const formatPenceToPounds = (pence) => pence / 100;

export const formatAPIDate = (date) => new Date(date).toISOString();

export const formatDateOfBirth = (date) =>
    moment.utc(moment(new Date(date)).format('YYYY-MM-DD')).format();

export const formatParserAPIDate = (date) => new Date(date + ' UTC').toISOString();

export const parseDateStringToJSDate = (dateString) => moment(dateString, 'DD MMM YYYY').toDate();

export const formatDisplayDate = (date) => moment(new Date(date)).format('DD MMM YYYY');

export const formatDisplayDateTime = (dateTime) =>
    moment(new Date(dateTime)).format('HH:mm on D MMM YYYY');

export const formatDisplayTime = (dateTime) => moment(new Date(dateTime)).format('HH:mm');

export const getAgeFromDate = (date) => moment(date).diff(new Date(), 'years') * -1;

export const formatFullName = (firstName, lastName) =>
    firstName && lastName ? `${lastName.toUpperCase()}, ${firstName}` : '';

export const formatJobType = (jobType) => capitalize(lowerCase(jobType));

export const addPhoneCountryCode = (phone) => (phone[0] === '+' ? phone : `+44${phone}`);

export const removePhoneLeadingZero = (phone) =>
    parseInt(phone[0], 0) === 0 ? phone.substring(1) : phone;

export const getDispositionExpiryDateTime = (timeInMins) =>
    new Date(moment().add(timeInMins, 'minutes'));

export const getRelativeDay = (date, numberDays) => new Date(moment(date).add(numberDays, 'days'));

export const getTimeRemaining = (dateTime) =>
    (moment(dateTime).diff(moment(new Date())) / 1000 / 60).toFixed(0);

export const isJobExpired = (expiryDateTime) => 0 >= getTimeRemaining(expiryDateTime);

export const isJobNotified = (job) =>
    job.hcpNotifiedCount !== 0 && job.hcpNotifiedCount !== undefined;

export const formatPractice = (name, odsCode) =>
    `${name || 'GP practice unknown'}${odsCode ? ` (${odsCode})` : ''}`;

export const formatPractices = (practices) =>
    practices.map((practice) => ({
        label: `${practice.name}${practice.odsCode ? ` ${practice.odsCode}` : ''}`,
        value: `${practice.name}${practice.odsCode ? ` ${practice.odsCode}` : ''}`,
    }));

export const splitPracticeDetails = (practice) => {
    const slicePoint = practice.lastIndexOf(' ');
    const name = practice.slice(0, slicePoint);
    const odsCode = practice.slice(slicePoint + 1);
    return { name, odsCode };
};

export const metresToMiles = (metres) => `${((metres / 1000 / 8) * 5).toFixed(1)} miles`;

export const formatTimeDifference = (a, b) => {
    return moment.duration(moment(a).diff(moment(b))).humanize(true);
};

export const timeDifferenceInDays = (a, b) =>
    Math.floor(moment.duration(moment(b).startOf('day').diff(moment(a).startOf('day'))).as('days'));

export const timeDifferenceInWeeks = (a, b) =>
    Math.floor(moment.duration(moment(b).diff(moment(a))).as('weeks'));

export const daysDifferenceWithToday = (date) => {
    return Math.ceil(moment.duration(date.diff(moment())).as('days'));
};

export const formatTimeDifferenceInWeeks = (a, b) => {
    const weeks = timeDifferenceInWeeks(a, b);
    if (weeks === 0) {
        return 'Less than a week ago';
    }
    if (weeks === 1) {
        return '1 week ago';
    }
    return `${weeks} weeks ago`;
};

export const formatTimeWithDate = (time, date) => {
    const [hour, minute] = time.split(':');
    return moment(date).hour(hour).minute(minute).startOf('minute').toISOString();
};

export const formatDateWithTime = (date) => {
    return moment(date).utc().local().format('HH:mm on DD/MM/YYYY');
};

export const formatTime = (date) => {
    return moment.utc(date).format('HH:mm');
};

export const formatTimeAMPM = (date) => moment(date).format('hh:mm a');

export const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY');
};

export const formatDateToStr = (date) => {
    return moment.utc(date).format('dddd Do MMMM YYYY');
};

export const formatToDate = (date, time) => {
    return moment.utc(`${date} ${time}`, 'DD/MM/YYYY HH:mm').toISOString();
};

export const momentFormatter = (format) => ({
    formatDate: (date) => (date ? moment(date).format(format) : ''),
    parseDate: (str) => (str ? moment(str, format).toDate() : null),
    placeholder: format,
});

export const formatFileSize = (sizeInBytes) => {
    if (!sizeInBytes) {
        return '';
    }

    let size = sizeInBytes;
    let unit = 'B';
    let decimalPoints = 0;

    if (size > 1024) {
        size = size / 1024;
        unit = 'KB';
    }

    if (size > 1024) {
        size = size / 1024;
        unit = 'MB';
        decimalPoints = 1;
    }

    return `${size.toFixed(decimalPoints)} ${unit}`;
};

export const daysBetween = (one, another) => {
    return Math.round(Math.abs(+one - +another) / 8.64e7);
};

export const daysBetweenNegativeIncluded = (one, another) => {
    return Math.round((+one - +another) / 8.64e7);
};

export const getStartAndEndDay = (date) => {
    const start = moment(date).startOf('day').toISOString();
    const end = moment(date).endOf('day').toISOString();

    return [start, end];
};

export const getMidnightDayString = (date) => {
    return moment(date).startOf('day').toISOString();
};

export const formatYearsOfService = (startDate, endDate) => {
    const start = moment(startDate);
    const end = endDate ? moment(endDate) : moment();
    if (end.isBefore(start)) return '-';

    let yearsOfService = '';

    const years = end.diff(start, 'years');
    if (years) {
        start.add(years, 'years');
        yearsOfService += years === 1 ? `${years} year` : `${years} years`;
    }

    const months = end.diff(start, 'months');
    if (months) {
        start.add(months, 'months');
        yearsOfService += months === 1 ? ` ${months} month` : ` ${months} months`;
    }

    const days = end.diff(start, 'days') + 1;
    if (days) {
        yearsOfService += days === 1 ? ` ${days} day` : ` ${days} days`;
    }

    return yearsOfService;
};

export const dateWithoutTime = (date) => date && moment(date).startOf('day').toDate();
