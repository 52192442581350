import React from 'react';
import { Checkbox, Tag } from '@blueprintjs/core';
import moment from 'moment';

const PeopleItem = ({
    onClick,
    id,
    departments,
    selected,
    firstName,
    lastName,
    middleName,
    onToggle,
    jobTitle,
    roles,
    employeeStatus,
    dictionaryStore,
    showName,
    contractTypeId,
    isCompliant,
    updatedAt,
    createdAt,
    createdByPerson,
    updatedByPerson,
    startedWorkOn,
    endedWorkOn,
}) => {
    return (
        <tr
            onClick={(event) => onClick({ employeeId: id, contractTypeId, event })}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    disabled={employeeStatus === 'Archived' ? true : false}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            {showName && (
                <>
                    <td>{firstName}</td>
                    <td>{middleName}</td>
                    <td>{lastName}</td>
                </>
            )}
            <td>
                {departments?.map((department) => (
                    <Tag key={department.departmentId} minimal={true} className="common-table__tag">
                        {department.department.name}
                    </Tag>
                ))}
            </td>
            <td>
                {departments?.map((department) => (
                    <Tag key={department.jobTitle} minimal={true} className="common-table__tag">
                        {department.jobTitle}
                    </Tag>
                ))}
            </td>
            <td>
                {roles?.map((roleId) =>
                    !!dictionaryStore.rolesDictionary[roleId] ? (
                        <span key={roleId}>{dictionaryStore.rolesDictionary[roleId]}</span>
                    ) : null,
                )}
            </td>
            <td>{employeeStatus}</td>
            <td>{isCompliant ? 'Compliant' : 'Not Compliant'}</td>
            <td>{startedWorkOn ? moment(startedWorkOn).format('DD/MM/YYYY') : ''}</td>
            <td>{endedWorkOn ? moment(endedWorkOn).format('DD/MM/YYYY') : ''}</td>
            <td>{createdAt}</td>
            <td>
                {createdByPerson && `${createdByPerson?.firstName}  ${createdByPerson?.lastName}`}
            </td>
            <td>{updatedAt}</td>
            <td>
                {updatedByPerson && `${updatedByPerson?.firstName}  ${updatedByPerson?.lastName}`}
            </td>
        </tr>
    );
};

export default PeopleItem;
