import { gql } from '@apollo/client';

export const ADD_EMPLOYEE_DOCUMENT = gql`
    mutation AddEmployeeDocument($employeeId: String!, $documentId: String!) {
        addEmployeeDocument(employeeId: $employeeId, documentId: $documentId) {
            id
        }
    }
`;

export const REMOVE_EMPLOYEE_DOCUMENT = gql`
    mutation RemoveEmployeeDocument($employeeId: String!, $documentId: String!) {
        removeEmployeeDocument(employeeId: $employeeId, documentId: $documentId) {
            count
        }
    }
`;

export const GET_OPTIONAL_DOCUMENTS = gql`
    query GetEmployeeOptionalDocuments($employeeId: String!) {
        getEmployeeOptionalDocuments(employeeId: $employeeId) {
            id
            key
            personId
            fileName
            sizeInBytes
            expiresAt
            isVerified
            isArchived
            updatedAt
            categoryId
            category {
                id
                name
            }
            verifiedPerson {
                firstName
                lastName
            }
            description
            mimeType
            createdPerson {
                firstName
                lastName
            }
            createdAt
        }
    }
`;

export const GET_DOCUMENTS = gql`
    query GetPersonDocuments(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: DocumentFilter!
    ) {
        getPersonDocuments(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
        ) {
            edges {
                node {
                    id
                    key
                    personId
                    fileName
                    sizeInBytes
                    expiresAt
                    isVerified
                    isArchived
                    updatedAt
                    categoryId
                    category {
                        id
                        name
                    }
                    verifiedPerson {
                        firstName
                        lastName
                    }
                    description
                    mimeType
                    createdPerson {
                        firstName
                        lastName
                    }
                    createdAt
                    required
                    verifiedPerson {
                        firstName
                        lastName
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_DOCUMENT = gql`
    query GetDocument($id: String!) {
        getDocument(id: $id) {
            id
            personId
            categoryId
            fileName
            mimeType
            sizeInBytes
            key
            description
            expiresAt
            isArchived
            isVerified
            verifiedBy
            createdBy
            updatedBy
            createdAt
            updatedAt
            createdPerson {
                id
                firstName
                lastName
            }
            updatedPerson {
                id
                firstName
                lastName
            }
            verifiedPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const CREATE_DOCUMENT = gql`
    mutation CreateDocument($data: DocumentInput!) {
        createDocument(data: $data) {
            id
        }
    }
`;

export const UPDATE_DOCUMENT = gql`
    mutation UpdateDocument($id: String!, $data: UpdateDocumentInput!) {
        updateDocument(id: $id, data: $data) {
            id
            personId
            fileName
            sizeInBytes
            expiresAt
            isVerified
            isArchived
            updatedAt
            categoryId
            category {
                id
                name
            }
            verifiedPerson {
                firstName
                lastName
            }
            description
            mimeType
            createdPerson {
                firstName
                lastName
            }
            createdAt
        }
    }
`;

export const BULK_UPDATE_VERIFICATION = gql`
    mutation BulkVerificationUpdate($isVerified: Boolean!, $ids: [String!]!) {
        bulkVerificationUpdate(isVerified: $isVerified, ids: $ids) {
            count
        }
    }
`;

export const BULK_UPDATE_STATUS = gql`
    mutation BulkStatusUpdate($isArchived: Boolean!, $ids: [String!]!) {
        bulkStatusUpdate(isArchived: $isArchived, ids: $ids) {
            count
        }
    }
`;

export const DELETE_DOCUMENTS = gql`
    mutation DeleteDocuments($ids: [String!]!) {
        deleteDocuments(ids: $ids) {
            count
        }
    }
`;
