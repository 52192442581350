import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { daysBetweenNegativeIncluded } from '../../../../modules/helpers/formatData';
import {
    SHIFT_TYPE_UNPAID_ID,
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_BANK_ID,
    SHIFT_TYPE_CONTRACTED_ID,
} from '../../../../../constants/rotaConst';
import { useEffect, useState } from 'react';

export const getNumberOfWeeks = (startDate: any, endDate: any) => {
    let numberOfWeeks = 1;

    const start = new Date(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate(),
        0,
        0,
        0,
        0,
    );

    const end = new Date(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate(),
        23,
        59,
        59,
        0,
    );

    const numberOfDays = Math.round(((end as any) - (start as any)) / (1000 * 60 * 60 * 24)) + 1;
    const startDay = startDate.getDay() === 0 ? 7 : startDate.getDay();
    const endDay = endDate.getDay() === 0 ? 7 : endDate.getDay();

    if (startDay <= endDay && numberOfDays <= 7) {
        numberOfWeeks = 1;
    } else {
        numberOfWeeks = (numberOfDays - (8 - startDay) - endDay) / 7 + 2;
    }

    return Math.round(numberOfWeeks);
};

export const stringTimeToDecimal = (str: string) => {
    const d = moment(`2013-10-10 ${str}`).toDate();
    const p0 = d.getHours();
    const p1 = d.getMinutes() / 60;
    return p0 + p1;
};

export const decimalToStringTime = (n: number) => {
    const hrs = Math.trunc(n);
    const mins = (n - hrs) * 60;
    return `${hrs > 9 ? '' : '0'}${hrs}:${mins > 9 ? '' : '0'}${mins}`;
};

const getShiftsForCycle = (
    shiftPatternEntries: [any],
    payRates: [any],
    offset: number,
    cycleOffset: number,
) => {
    return shiftPatternEntries
        .map((spe: any) => {
            return {
                id: uuid(),
                breakDurationMinutes: spe.breakDurationMinutes,
                statusId: spe.statusId,
                breaksPaid: true,
                dayOfWeek: spe.dayOfWeek,
                defaultEmployeeId: spe.defaultEmployeeId,
                confirmedEmployeeId: null,
                endTime: spe.endTime,
                locationId: spe.locationId,
                overrideValueInPence: spe.overrideValueInPence,
                roleId: spe.roleId,
                fundingPoolId: spe.fundingPoolId,
                payRateId: spe.payRateId,
                startTime: spe.startTime,
                weekNumber: spe.weekNumber - offset + cycleOffset,
                typeId: spe.typeId,
                isAdditional: false,
                thirdPartyPaid: spe.thirdPartyPaid,
                trainingShift: spe.trainingShift,
                rotaShiftFunctions: spe.shiftFunctions,
                trainees: spe.trainees?.length
                    ? spe.trainees.map((trainee: any) => {
                          return {
                              traineeId: trainee.traineeId,
                              roleId: trainee.roleId,
                              overrideValue: trainee.overrideValue,
                              payRateId: trainee.payRateId,
                          };
                      })
                    : [
                          {
                              traineeId: '',
                              roleId: '',
                              overrideValue: null,
                              payRateId: '',
                          },
                      ],
            };
        })
        .filter((r: any) => !!r);
};

export const getShiftsForDateRange = (
    shiftPattern: any,
    rangeStart: Date,
    rangeEnd: Date,
    payRates: [any],
    startWeek: number,
) => {
    let result: any[] = [];
    const rStart = moment(rangeStart).set({ hour: 0, minute: 0, second: 0 }).toDate();
    const rEnd = moment(rangeEnd).set({ hour: 23, minute: 59, second: 0 }).toDate();
    const shiftPatternWeeks = Math.max.apply(
        Math,
        shiftPattern.shiftPatternEntries?.map(function (o: any) {
            return o.weekNumber;
        }),
    );

    const rangeDays = moment(rangeEnd).diff(moment(rangeStart), 'days') + 2;
    const startDaysToTrim = rangeStart.getDay() === 0 ? 1 : 8 - rangeStart.getDay();
    const endDaysToTrim = rangeEnd.getDay() === 0 ? 7 : rangeEnd.getDay();
    const rangeWeeks = (rangeDays - startDaysToTrim - endDaysToTrim) / 7 + 2 + startWeek;
    const cycles =
        rangeWeeks % shiftPatternWeeks === 0
            ? rangeWeeks / shiftPatternWeeks
            : (rangeWeeks - (rangeWeeks % shiftPatternWeeks)) / shiftPatternWeeks + 1;

    for (let i = 0; i < cycles; i++) {
        result.push(
            ...getShiftsForCycle(
                shiftPattern.shiftPatternEntries,
                payRates,
                startWeek,
                i * shiftPatternWeeks,
            ),
        );
    }

    result = result
        .map((r: any) => {
            const days = (r.weekNumber - 1) * 7 + r.dayOfWeek - 1 - (7 - startDaysToTrim);

            const date = moment(rangeStart)
                .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                })
                .add(days, 'days')
                .toDate();

            return {
                ...r,
                date,
            };
        })
        .filter((r: any) => r.date >= rStart && r.date <= rEnd);

    return result;
};

const getWeeksOffset = (oldDate: Date, newDate: Date) => {
    let offset = 0;
    const rangeDays = moment(oldDate).diff(moment(newDate), 'days');

    if (rangeDays < 0) {
        const daysToNextSunday = 7 - (oldDate.getDay() === 0 ? 7 : oldDate.getDay());
        if (daysToNextSunday >= Math.abs(rangeDays)) return 0;
        const daysToPrevSunday = newDate.getDay() === 0 ? 7 : newDate.getDay();
        // eslint-disable-next-line prettier/prettier
        offset = -((Math.abs(rangeDays) - daysToNextSunday - daysToPrevSunday) / 7 + 1);
    } else if (rangeDays > 0) {
        const daysToNextSunday = 7 - (newDate.getDay() === 0 ? 7 : newDate.getDay());
        if (daysToNextSunday >= Math.abs(rangeDays)) return 0;
        const daysToPrevSunday = oldDate.getDay() === 0 ? 7 : oldDate.getDay();
        // eslint-disable-next-line prettier/prettier
        offset = (Math.abs(rangeDays) - daysToNextSunday - daysToPrevSunday) / 7 + 1;
    }

    return offset;
};

export const filterShiftsByDate = async (
    shifts: [any],
    startDate: Date,
    endDate: Date,
    oldStartDate: Date,
) => {
    const startDay = startDate.getDay() === 0 ? 7 : startDate.getDay();
    const oldRStart = moment(oldStartDate)
        .add(-startDay + 1, 'days')
        .set({
            hour: 0,
            minute: 0,
            second: 0,
        });
    const rStart = moment(startDate)
        .set({
            hour: 0,
            minute: 0,
            second: 0,
        })
        .toDate();

    const rEnd = moment(endDate)
        .set({
            hour: 23,
            minute: 59,
            second: 0,
        })
        .toDate();

    const weeksOffset = getWeeksOffset(oldStartDate, startDate);

    const newShifts = shifts
        .map((r: any) => {
            const days = (r.weekNumber - 1) * 7 + r.dayOfWeek - 1;
            const date = moment(oldRStart).add(days, 'days').toDate();

            if (date >= rStart && date <= rEnd) {
                return {
                    ...r,
                    weekNumber: r.weekNumber + weeksOffset,
                };
            }

            return null;
        })
        .filter((r: any) => !!r);

    return newShifts;
};

export const calculateParticularDayInWeek = (startDate: Date, week: number, day: number) => {
    startDate && startDate.setHours(0, 0, 0);
    let dayInWeek = moment(startDate).toDate().getDay();
    if (dayInWeek === 0) dayInWeek = 7;
    const dayDate = moment(startDate)
        .add((week - 1) * 7 + day - dayInWeek + 1, 'days')
        .toDate();
    const month = dayDate.getUTCMonth() + 1;
    const dayOfMonth = dayDate.getUTCDate();
    const year = dayDate.getUTCFullYear();

    return `${dayOfMonth}/${month}/${year}`;
};

export const convertMinutesToHoursDate = (time: number) => {
    const hours = time / 60 > 1 ? Math.trunc(time / 60) : 0;
    const minutes = time % 60;
    const date = new Date(0, 0);
    date.setHours(hours);
    date.setMinutes(minutes);
    return date;
};

export const convertMinutesToHours = (time: number) => {
    const date = convertMinutesToHoursDate(time);
    return date.toTimeString().slice(0, 5);
};

export const convertHoursToMinutes = (time: number): number => {
    const date = new Date(time);
    return date.getHours() * 60 + date.getMinutes();
};

export const convertTime = (time: number) =>
    +time % 1 !== 0
        ? Math.trunc(time) < 10
            ? '0' + Math.trunc(time).toString() + ':30'
            : Math.trunc(time).toString() + ':30'
        : time < 10
        ? '0' + time.toString() + ':00'
        : time.toString() + ':00';

export const dateToDecimal = (d: Date): number => {
    const p0 = d.getHours();
    const p1 = d.getMinutes() / 60;
    return p0 + p1;
};

export const roundHalf = (num: number) => {
    return Math.round(num * 2) / 2;
};

export const equalize = (items: [any], start: number, end: number) => {
    const range = end - start;
    const pRange = roundHalf(range / items.length);

    for (let i = 0; i < items.length; i++) {
        if (i === 0) {
            items[i].start = start;
            items[i].startTime = start;
        } else {
            items[i].start = pRange * i + start;
            items[i].startTime = pRange * i + start;
        }

        if (i === items.length - 1) {
            items[i].end = end;
            items[i].endTime = end;
        } else {
            items[i].end = pRange * i + pRange + start;
            items[i].endTime = pRange * i + pRange + start;
        }
    }

    return items;
};

const dt = new Date();

export const filledStatus = (rotaStartDate: any, entry: any) => {
    const dayOfWeek = moment(rotaStartDate).toDate().getDay();
    const startDate = moment(rotaStartDate)
        .add((entry.weekNumber - 1) * 7 + entry.dayOfWeek - dayOfWeek, 'days')
        .toDate();

    const daysBetweenDates = daysBetweenNegativeIncluded(startDate, dt);

    const contractedWarning =
        (entry.typeId === SHIFT_TYPE_CONTRACTED_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
        (entry.typeId === SHIFT_TYPE_CONTRACTED_ID &&
            !entry.confirmedEmployeeId &&
            entry.statusId === SHIFT_STATUS_STANDARD_ID);

    const bankWarning =
        (entry.typeId === SHIFT_TYPE_BANK_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
        (entry.typeId === SHIFT_TYPE_BANK_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.statusId === SHIFT_STATUS_STANDARD_ID);

    const warning = contractedWarning || bankWarning;

    const requiresAttention = warning && daysBetweenDates < 3;

    const filled = !warning && !requiresAttention;

    return filled
        ? 'Filled'
        : requiresAttention
        ? 'Requires attention'
        : warning
        ? 'Unfilled'
        : null;
};

export const getShiftDate = (rotaStartDate: any, shift: any, hour?: number, minute?: number) => {
    const daysToMinus = moment(rotaStartDate).day() > 0 ? moment(rotaStartDate).day() : 7;
    const prevSunday = moment(rotaStartDate);
    prevSunday.add(-daysToMinus, 'd');
    const daysToAdd = (shift.weekNumber - 1) * 7 + shift.dayOfWeek;
    return prevSunday
        .add(daysToAdd, 'd')
        .set('hour', hour || 0)
        .set('minute', minute || 0)
        .toDate();
};

export const numToTime = (num: any) => {
    const hours = Math.floor(num / 60);
    let minutes: any = num % 60;
    if (minutes + ''.length < 2) {
        minutes = '0' + minutes;
    }
    return hours + ':' + minutes;
};

export const useDebounce = (value: any, delay: number) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay], // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};
