import { observable, action, makeObservable, runInAction, computed } from 'mobx';
import { PagedStore } from './PagedStore';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GET_HOLIDAYS } from '../graphql/queries/holidays';

export interface HolidaysInterface {
    id: string;
    name: string;
    deletedBy: string;
}

class HolidaysStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            holidays: observable,
            holidayDates: observable,
            pageInfo: observable,
            gridHolidays: computed,
            loaded: observable,
            getHolidays: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    holidays: HolidaysInterface[] = [];
    holidayDates: any = [];
    fetchInterval: NodeJS.Timeout | null = null;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    getHolidays = async (startDate: Date, endDate: Date) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }

        const {
            data: { getHoliday: data },
        } = await this.apolloClient.query({
            query: GET_HOLIDAYS,
            variables: {
                filter: { startDate, endDate },
            },
        });

        runInAction(() => {
            this.holidays = data.edges.map((x: any) => x.node);
            this.holidayDates = this.gridHolidays.map((day: any) => {
                const date = new Date(day.date);
                const month = date.getUTCMonth() + 1;
                const dayOfMonth = date.getUTCDate();
                const year = date.getUTCFullYear();
                return `${dayOfMonth}/${month}/${year}`;
            });
            this.loaded = true;
        });
    };

    normalizeHolidays = (holiday: any) => ({
        id: holiday.id,
        name: holiday.name,
        date: holiday.date,
    });

    get gridHolidays() {
        return this.holidays.map(this.normalizeHolidays);
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }
}

export default HolidaysStore;
