import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
    query GetLocations(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: LocationFilter
        $query: String
    ) {
        getLocations(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    address1
                    address2
                    address3
                    townCity
                    postcode
                    isArchived
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                endCursor
            }
        }
    }
`;
export const GET_LOCATION = gql`
    query GetLocation($id: String!) {
        getLocation(id: $id) {
            id
            name
            address1
            address2
            address3
            townCity
            postcode
            isArchived
        }
    }
`;

export const CREATE_LOCATION = gql`
    mutation CreateLocation($data: LocationEntityInput!) {
        createLocation(data: $data) {
            id
        }
    }
`;

export const UPDATE_LOCATION = gql`
    mutation UpdateLocation($id: String!, $data: LocationEntityInput!) {
        updateLocation(id: $id, data: $data) {
            id
        }
    }
`;

export const DELETE_LOCATION = gql`
    mutation DeleteLocation($id: String!) {
        deleteLocation(id: $id) {
            count
        }
    }
`;

export const ARCHIVE_LOCATION = gql`
    mutation archiveLocation($id: String!) {
        archiveLocation(id: $id) {
            count
        }
    }
`;

export const BULK_ARCHIVE_LOCATIONS = gql`
    mutation bulkArchiveLocation($ids: [String!]!) {
        bulkArchiveLocation(ids: $ids) {
            count
        }
    }
`;

export const IS_LOCATION_ARCHIVABLE = gql`
    query IsLocationArchivable($ids: [String!]!) {
        isLocationArchivable(ids: $ids) {
            id
            name
            isArchivable
        }
    }
`;
