import { FC } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../../hook/useStores';
import AppToaster from '../../../../modules/helpers/Toaster';
import { Dialogs } from '../../../../../stores/UCRStore';
import { Dialog } from '../../../../v2/overlays';

import { StaffAlertMap } from './common/StaffAlertMap';
import { StaffAlertDetails } from './common/StaffAlertDetails';
import { StaffAlertMessages } from './common/StaffAlertMessages';
import { SummaryJobList } from '../../components';

const StaffAlertInfo: FC = () => {
    let {
        RootStore: {
            ucrStore: {
                openedDialog,
                openedStaffAlertToResolve: alert,
                clearOpenedDialog,
                setOpenedStaffAlertResolver,
                jobs,
                hcps,
            },
            configStore: { clientKeys, pathways },
        },
    } = useStores() as { RootStore: any };

    const filteredJobs = jobs
        .filter((job: any) => alert?.userId === job.hcpId)
        .sort((a: any, b: any) => {
            if (a.startDateTime && b.startDateTime) {
                return new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime();
            }

            return a.startDateTime ? 1 : -1;
        });

    const openResolutionForm = () => {
        setOpenedStaffAlertResolver(alert!);
    };

    const close = () => {
        clearOpenedDialog();
    };

    const showDialog = openedDialog === Dialogs.STAFF_ALERT_INFO;

    if (showDialog && !alert) {
        AppToaster.show({
            message:
                'Sorry, an error occurred and we were unable to show the staff alert information',
            intent: 'danger',
        });
        clearOpenedDialog();
    }

    return (
        <Dialog isShow={showDialog} closeEvent={close} title="Staff Alert">
            <StaffAlertDetails alert={alert!} hcps={hcps} actions={{ openResolutionForm, close }} />
            <StaffAlertMessages messages={alert?.messages} />
            {clientKeys.googleMaps.length > 0 && (
                <StaffAlertMap alert={alert} apiKey={clientKeys.googleMaps} />
            )}
            <SummaryJobList filteredJobs={filteredJobs} pathways={pathways} />
        </Dialog>
    );
};

export default observer(StaffAlertInfo);
