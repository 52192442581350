import { baseUrl, rootUrl, retryable } from './baseApi';
export const getConfig = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/config`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const updateConfig = (authToken: string, body: { [key: string]: any }): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/config`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const getClientKeys = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/config/client-keys`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );
