import { FC } from 'react';
import { Form } from 'formik';
import { Patient } from '@doc-abode/data-models';
import { IHcp } from '../../../../../interfaces/ucr';

import { Select, TextArea } from '../../../../v2/form';
import { Button, ButtonColors, ButtonElems, ButtonSizes, Callout } from '../../../../v2/components';
import useStores from '../../../../../hook/useStores';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

interface IProps {
    visit: Patient;
    isDoubleUp: boolean;
    loading: boolean;
    onClose: () => void;
}

const ReverseSingleVisitForm: FC<IProps> = ({ visit, isDoubleUp, loading, onClose }) => {
    const {
        RootStore: {
            lovsStore: { controllerReverseReason },
            usersStore: { users },
            ucrStore: { hcps },
        },
    } = useStores() as { RootStore: any };

    const hcp = hcps.find((hcp: IHcp) => hcp.userId === visit.lastUpdatedBy);
    const controller = users.find((user: any) => user.userId === visit.lastUpdatedBy);
    return (
        <Form>
            <div className="v2__dialog-block">
                This visit has been aborted with the following details
                <ul className="v2__dialog-block-list">
                    <li className="v2__dialog-block-list-item">
                        <span>Aborted on</span>
                        <span>
                            {formatDisplayDateTime(
                                visit.finishedDateTime || visit.buddyFinishedDateTime,
                            )}
                        </span>
                    </li>
                    <li className="v2__dialog-block-list-item">
                        <span>Aborted by</span>
                        <span>{hcp?.userName || controller?.userName}</span>
                    </li>
                    <li className="v2__dialog-block-list-item">
                        <span>Reason</span>
                        <span>
                            {visit.controllerAbortedReason || visit.buddyControllerAbortedReason}
                        </span>
                    </li>
                    <li className="v2__dialog-block-list-item">
                        <span>Notes</span>
                        <span>
                            {visit.controllerAbortedNotes ||
                                visit.buddyControllerAbortedNotes ||
                                'Not set'}
                        </span>
                    </li>
                </ul>
                {isDoubleUp ? (
                    <Callout intent="warning">
                        The visit is part of a double-up which has only been cancelled for one staff
                        member. Reversing the cancellation will not affect the visit of the other
                        staff member.
                    </Callout>
                ) : undefined}
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for reverting the cancellation"
                    name="controllerReverseReason"
                    options={controllerReverseReason}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea label="Notes on reversal" name="controllerReverseNotes" />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--right">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm reversal"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={loading}
                />
            </div>
        </Form>
    );
};

export default ReverseSingleVisitForm;
