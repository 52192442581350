import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import classnames from 'classnames';

import Loader from '../../modules/helpers/Loader';
import InviteDetails, { formatStatus } from '../../modules/users/detail/InviteDetails';
import { formatDisplayDateTime } from '../../modules/helpers/formatData';

const Invite = ({
    inviteId,
    userName,
    inviteStatus,
    onClick,
    selectedInvite,
    updatedAt,
    createdAt,
}) => {
    return (
        <tr
            onClick={() => onClick(inviteId)}
            className={selectedInvite === inviteId ? 'invites__table-row--active' : ''}
        >
            <td>{userName}</td>
            <td>{formatStatus(inviteStatus)}</td>
            <td>{formatDisplayDateTime(updatedAt || createdAt)}</td>
        </tr>
    );
};

const AdminManageInvites = inject('RootStore')(
    observer(
        class AdminManageInvites extends Component {
            async componentDidMount() {
                this.props.RootStore.invitesStore.getInvites();
            }

            _handleInviteClick = (inviteId) => {
                this.props.history.push(`/manage-applications/${inviteId}`);
            };

            render() {
                const {
                    RootStore: {
                        configStore: { isFeatureEnabled },
                        invitesStore: { loaded, invites },
                    },
                    match: {
                        params: { inviteId },
                    },
                } = this.props;

                if (!isFeatureEnabled('invites')) {
                    return <Redirect to="/page-not-found" />;
                }

                if (!loaded) {
                    return <Loader />;
                }

                return (
                    <div
                        className={classnames('invites', {
                            'invites--show-details': Boolean(inviteId),
                        })}
                    >
                        <div className="page">
                            <h1 className="h1">Manage Applications</h1>
                            {invites.length === 0 ? (
                                <p>There are currenlty no open applications.</p>
                            ) : (
                                <table className="bp5-html-table bp5-interactive invites__table">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Last Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invites.map((invite) => (
                                            <Invite
                                                {...invite}
                                                onClick={this._handleInviteClick}
                                                selectedInvite={inviteId}
                                                key={invite.inviteId}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                        <div className="invites__details">
                            {inviteId && <InviteDetails {...this.props} key={inviteId} />}
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default AdminManageInvites;
