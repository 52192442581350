import { FC, Fragment, useEffect } from 'react';
import moment from 'moment';
import { Patient, JobStatus } from '@doc-abode/data-models';
import useStores from '../../../../../hook/useStores';
import Warnings from '../../../../v2/form/Warnings';
import { WarningType } from '../../../../../interfaces/ucr';
import { formatDisplayDate, formatDisplayTime } from '../../../../modules/helpers/formatData';
import { Info, InfoItem } from '../../../../v2/components';
import { HcpSelect } from '../../components/HcpSelect';
import { TimeHelperActualStart, TimeHelperActualEnd } from './TimeHelper';
import { abortStatusCheck } from '../../forms/common';
import { genderMapping } from '../../../../../constants/mainConst';
import { VisitValuesType } from './VisitDetailsTypes';
import { getAllWarnings } from '../../../../../helpers/ucr/getVisitWarnings';
import { getStringEndTime } from '../../../../../helpers/ucr/getEndDateTime';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { isCompleted } from '../../../../../helpers/statusCheckHelper';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';

interface IProps {
    patient: Patient;
    warnings: WarningType[];
    onHcpReassignment: (userId: string) => void;
    isStaffMemberEditable: boolean;
}

const AdminDetails: FC<IProps> = ({
    patient,
    warnings,
    onHcpReassignment,
    isStaffMemberEditable,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            schedulesStore: { getSchedulesByDate },
        },
    } = useStores() as { RootStore: any };

    const {
        firstName,
        middleName,
        lastName,
        age,
        dateOfBirth,
        gender,
        nhsNumber,
        contactNumber,
        additionalContactNumbers,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        postCode,
        languagesSpoken,
        activityType,
        startDateTime,
        duration,
        notes,
        controllerAbortedReason,
        controllerAbortedNotes,
        hcpAbortedReason = '',
        hcpAbortedNotes = '',
        hcpId,
        dateOfVisit,
        postVisitNotes,
    } = patient;

    const isNotRelatedToPatient =
        !firstName &&
        !middleName &&
        !lastName &&
        !gender &&
        !nhsNumber &&
        !contactNumber &&
        !additionalContactNumbers?.length &&
        !addressLine1 &&
        !addressLine2 &&
        !addressLine3 &&
        !town &&
        !postCode &&
        !languagesSpoken?.length;
    const hcpName = users.find((user: any): any => user.userId === hcpId)?.userName;

    // Combine contact numbers into single array, and remove any null values
    const contactNumbers = [contactNumber, ...(additionalContactNumbers || [])].filter((_) => _);

    const jobStatus: JobStatus | undefined = patient?.jobStatus;
    const jobIsCompleted = isCompleted({ jobStatus });

    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    const addresses = [addressLine1, addressLine2, addressLine3, town, postCode];
    const emptyAddresses = addresses.every((item) => item === null);
    const stepWarnings = getAllWarnings({
        id: patient.id,
        visitDate: patient.dateOfVisit,
        startTime: patient.startDateTime,
        staffRequired: patient.staffRequired,
    });

    useEffect(() => {
        getSchedulesByDate(moment(dateOfVisit).format('YYYY-MM-DD'));
    }, [dateOfVisit, getSchedulesByDate]);

    return (
        <main className="visit-details__main visit-details__main--default">
            {!abortStatusCheck(patient?.jobStatus) && !jobIsCompleted && warnings?.length ? (
                <div className="visit-details__warning">
                    <Warnings warnings={warnings} stepWarnings={stepWarnings} spacerTop={false} />
                </div>
            ) : null}
            {isNotRelatedToPatient ? (
                <div className="visit-details__patient">
                    <Info title="The activity is not related to a patient">
                        <></>
                    </Info>
                </div>
            ) : (
                <div className="visit-details__patient">
                    <Info title="Patient details">
                        <InfoItem label="Patient">{firstName}</InfoItem>
                        <InfoItem label="Middle name(s)">{middleName}</InfoItem>
                        <InfoItem label="Last name(s)">{lastName}</InfoItem>
                        <InfoItem label="Address" alignSelf="start">
                            {!emptyAddresses
                                ? addresses
                                      .filter((_) => _)
                                      .map((addressPart, i) => (
                                          <Fragment key={i}>
                                              {i !== 0 && <br />}
                                              {addressPart}
                                          </Fragment>
                                      ))
                                : 'Not set'}
                        </InfoItem>
                        <InfoItem label="Age">{dateOfBirth && age}</InfoItem>
                        <InfoItem label="Date of birth">
                            {dateOfBirth ? formatDisplayDate(dateOfBirth) : dateOfBirth}
                        </InfoItem>
                        <InfoItem label="Gender">
                            {gender ? genderMapping[gender] : genderMapping.not_provided}
                        </InfoItem>
                        <InfoItem label="NHS number">{nhsNumber}</InfoItem>
                        <InfoItem label="Contact number(s)" alignSelf="start">
                            {contactNumbers.length > 0
                                ? contactNumbers.map((number, i) => (
                                      <Fragment key={i}>
                                          {i !== 0 && <br />}
                                          {`+${number}`}
                                      </Fragment>
                                  ))
                                : null}
                        </InfoItem>
                        <InfoItem label="Language(s) spoken">
                            {languagesSpoken?.join(', ')}
                        </InfoItem>
                    </Info>
                </div>
            )}
            <div className="visit-details__visit">
                <Info title="Activity details">
                    <InfoItem label="Staff member">
                        {isStaffMemberEditable ? (
                            <HcpSelect
                                patient={patient as VisitValuesType}
                                onHcpReassignment={onHcpReassignment}
                                insideForm={false}
                                includeUnavailableHcps={false}
                                isAdmin
                            />
                        ) : (
                            hcpName
                        )}
                    </InfoItem>
                    <InfoItem label="Activity type">{activityType}</InfoItem>
                    <InfoItem label="Date">
                        {startDateTime ? formatDisplayDate(startDateTime) : ''}
                    </InfoItem>
                    <InfoItem label="Pre-job notes" alignSelf="start">
                        {notes}
                    </InfoItem>
                    <ConditionalDisplay show={showPatientNotes}>
                        <InfoItem label="Post-job notes" alignSelf="start">
                            {postVisitNotes}
                        </InfoItem>
                    </ConditionalDisplay>
                    {patient.jobStatus === JobStatus.CONTROLLER_ABORTED && (
                        <>
                            <InfoItem label="Aborted reason" intent="danger">
                                {controllerAbortedReason}
                            </InfoItem>
                            <InfoItem label="Aborted notes" intent="danger">
                                {controllerAbortedNotes}
                            </InfoItem>
                        </>
                    )}
                    {patient.jobStatus === JobStatus.HCP_ABORTED && (
                        <>
                            <InfoItem label="Aborted reason" intent="danger">
                                {hcpAbortedReason}
                            </InfoItem>
                            <InfoItem label="Aborted notes" intent="danger">
                                {hcpAbortedNotes}
                            </InfoItem>
                        </>
                    )}
                </Info>
            </div>
            <div className="visit-details__timing">
                <Info title="Activity timing">
                    <InfoItem label="Planned start time">
                        {startDateTime ? formatDisplayTime(startDateTime) : ''}
                    </InfoItem>
                    <InfoItem label="Duration">{duration}</InfoItem>
                    <InfoItem label="Planned end time">
                        {getStringEndTime(startDateTime, duration || '', 'HH:mm')}
                    </InfoItem>
                    <InfoItem label="Actual Start time">
                        {TimeHelperActualStart({
                            patient: patient,
                            isFirstUser: true,
                        })}
                    </InfoItem>
                    <InfoItem label="Actual End time">
                        {TimeHelperActualEnd({
                            patient: patient,
                            isFirstUser: true,
                        })}
                    </InfoItem>
                </Info>
            </div>
        </main>
    );
};

export default AdminDetails;
