import React from 'react';

const RegistrationHeader = () => {
    return (
        <thead>
            <tr>
                <th style={{ width: 'auto' }}>Professional body</th>
                <th>Registration number</th>
                <th>Created by</th>
                <th>Last updated at</th>
                <th>Fit to practice</th>
            </tr>
        </thead>
    );
};

export default RegistrationHeader;
