import * as Yup from 'yup';

const contractedHoursSchema = Yup.number()
    .nullable()
    .when(['contractedWorkingHours'], {
        is: (contractedWorkingHours) => contractedWorkingHours > 0,
        then: Yup.number()
            .nullable()
            .typeError('Contracted hours should be a number')
            .min(0, 'Contracted hours should be positive number')
            .max(24, 'Contracted hours should be less then 24'),
        otherwise: Yup.number()
            .nullable()
            .typeError('Contracted hours should be a number')
            .oneOf(
                [null, 0],
                'The number of contracted hours split across days must be equal to the number of contracted hours per week',
            ),
    });

const basic = {
    personId: Yup.string().nullable().required('This field is required.'),
    roles: Yup.array().min(1, 'This field is required.'),
    notificationPreference: Yup.string().required('This field is required.'),
    contactNumber: Yup.string()
        .required('This field is required.')
        .matches(/^\d{11}$/, 'Mobile number is invalid, must be 11 digits only.'),
    email: Yup.string()
        .required('This field is required.')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email address.'),
    startedWorkOn: Yup.date().nullable(),
    endedWorkOn: Yup.date()
        .nullable()
        .when('startedWorkOn', (startedWorkOn, yup) => {
            if (
                Object.prototype.toString.call(startedWorkOn) !== '[object Date]' ||
                isNaN(startedWorkOn)
            ) {
                return Yup.string().required('Start date is not set');
            }
            if (startedWorkOn) {
                yup.min(
                    startedWorkOn,
                    'End Date of Engagement cannot be before Start Date of Engagement',
                );
            }
        }),
    holidayAllowance: Yup.string().matches(
        /^\d{1,3}(\.\d{1,2})?$/,
        'Holiday allowance should be positive number',
    ),
    contractTypeId: Yup.string().required('This field is required'),
    isSupervisor: Yup.boolean().required('This field is required.'),
    patientFacing: Yup.boolean().required('This field is required.'),
};

const employed = {
    ...basic,
    startedWorkOn: Yup.date().required('This field is required.'),
    holidayAllowance: Yup.string()
        .matches(/^\+?([1-9]\d*)$/, 'Holiday allowance should be positive number')
        .required('This field is required.')
        .when('contractTypeId', (contractTypeId) => {
            //If contractTypeId is Bank
            if (contractTypeId === '0a5e90b3-6ff7-414f-821c-5b8a6ecd361a') {
                return Yup.string()
                    .matches(/^\+?([0-9]\d*)$/, 'Holiday allowance should be a number')
                    .required('This field is required.');
            }
        }),
    contractedWorkingHours: Yup.number()
        .nullable()
        .when('contractTypeId', (contractTypeId) => {
            //If contractTypeId is not Bank
            if (contractTypeId !== '0a5e90b3-6ff7-414f-821c-5b8a6ecd361a') {
                return Yup.number()
                    .min(1, 'Contracted hours per week should be positive number')
                    .max(168, 'Cannot contain a value greater than 168');
            }
        }),
    contractedHoursSum: Yup.number().oneOf(
        [Yup.ref('contractedWorkingHours'), null],
        'The number of contracted hours split across days must be equal to the number of contracted hours per week',
    ),
    mondayContractedHours: contractedHoursSchema,
    tuesdayContractedHours: contractedHoursSchema,
    wednesdayContractedHours: contractedHoursSchema,
    thursdayContractedHours: contractedHoursSchema,
    fridayContractedHours: contractedHoursSchema,
    saturdayContractedHours: contractedHoursSchema,
    sundayContractedHours: contractedHoursSchema,
};

export const employedValidationSchema = Yup.object({
    ...employed,
});

export const unemployedValidationSchema = Yup.object({
    ...basic,
    agencyId: Yup.string().when('contractTypeId', (contractTypeId) => {
        //If contractTypeId is Agency or Direct engagement
        if (
            contractTypeId === 'cf689985-66cd-4649-b376-28da3c69db1f' ||
            contractTypeId === '51577de2-5488-4e93-a68a-705194e875fa'
        ) {
            return Yup.string().required('This field is required.');
        }
    }),
});
