const now = (offsetInHours) => {
    const date = new Date();

    if (offsetInHours) {
        date.setHours(date.getHours() + offsetInHours);
    }

    return date;
};

export const formFields = [
    {
        name: 'systmOneRef',
        value: () => '',
        validation: { required: true, type: 'string' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'nhsNumber',
        value: () => '',
        validation: { required: false, type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'jobType',
        value: () => ({ label: 'Home visit', value: 'homeVisit' }),
        validation: { required: true, type: 'object' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'disposition',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'blockSize',
        value: () => ({ label: '', value: null }),
        validation: { required: ['telephoneBlock'], type: 'object' },
        autoPopulated: false,
        visible: ['telephoneBlock'],
    },
    {
        name: 'shiftType',
        value: () => ({}),
        validation: { required: ['shift'], type: 'object' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'firstName',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'lastName',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'dateOfBirth',
        value: () => null,
        validation: { required: false, type: 'date' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'age',
        value: () => null,
        validation: { required: false, type: 'number' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'gender',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'contactNumber',
        value: () => '',
        validation: { required: ['homeVisit', 'telephone'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'addressLine1',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'shift'],
    },
    {
        name: 'addressLine2',
        value: () => '',
        validation: { required: false, type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'shift'],
    },
    {
        name: 'town',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'shift'],
    },
    {
        name: 'postCode',
        value: () => '',
        validation: { required: ['homeVisit'], type: 'string' },
        autoPopulated: false,
        visible: ['homeVisit', 'shift'],
    },
    {
        name: 'practice',
        value: () => ({}),
        validation: { required: ['homeVisit', 'telephone'], type: 'object' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone'],
    },
    {
        name: 'prefillPractice',
        value: () => ({}),
        validation: { required: false, type: 'object' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'notes',
        value: () => '',
        validation: { required: false, type: 'string' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'hcpType',
        value: () => [],
        validation: { required: true, type: 'array' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'nominatedHcps',
        value: () => [],
        validation: { required: false, type: 'array' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'languagesSpoken',
        value: () => [],
        validation: { required: false, type: 'array' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone', 'shift'],
    },
    {
        name: 'speciality',
        value: () => [],
        validation: { required: false, type: 'array' },
        autoPopulated: false,
        visible: ['homeVisit', 'telephone', 'shift'],
    },
    {
        name: 'flags',
        value: () => [],
        validation: { required: false, type: 'array' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'price',
        value: () => 80,
        validation: { required: true, type: 'number' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'unitPrice',
        value: () => ({}),
        validation: { required: false, type: 'object' },
        autoPopulated: false,
        visible: ['telephoneBlock'],
    },
    {
        name: 'hidePriceInApp',
        value: () => false,
        validation: { required: false, type: 'boolean' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'startDate',
        value: () => now(1),
        validation: { required: ['shift'], type: 'date' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'startTime',
        value: () => now(1),
        validation: { required: ['shift'], type: 'date' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'endDate',
        value: () => now(2),
        validation: { required: ['shift'], type: 'date' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'endTime',
        value: () => now(2),
        validation: { required: ['shift'], type: 'date' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'expiryDate',
        value: () => now(1),
        validation: { required: true, type: 'date' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'expiryTime',
        value: () => now(1),
        validation: { required: true, type: 'date' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'latitude',
        value: () => null,
        validation: { required: false, type: 'coordinates' },
        autoPopulated: false,
    },
    {
        name: 'longitude',
        value: () => null,
        validation: { required: false, type: 'coordinates' },
        autoPopulated: false,
    },
    {
        name: 'version',
        value: () => 0,
        validation: { required: false, type: 'number' },
        autoPopulated: false,
    },
    {
        name: 'repeats',
        value: () => 'NEVER',
        validation: { required: ['shift'], type: 'string' },
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'repeatEvery',
        value: () => 1,
        validation: { required: true, type: 'number' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'repeatsEvery',
        value: () => ({}),
        validation: { required: false, type: 'object' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'until',
        value: () => now(1),
        validation: { required: ['shift'], type: 'date' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'onDays',
        value: () => [],
        validation: { required: false, type: 'array' },
        autoPopulated: false,
        visible: true,
    },
    {
        name: 'repeatsForDaily',
        value: () => 'everyDay',
        validation: false,
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'repeatsForMonthly',
        value: () => 'onDateInMonth',
        validation: false,
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'repeatDayInMonth',
        value: () => 1,
        validation: false,
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'monthlyRepeatsOften',
        value: () => ({ label: 'first', value: '1' }),
        validation: false,
        autoPopulated: false,
        visible: ['shift'],
    },
    {
        name: 'monthlyRepeatsDay',
        value: () => ({ label: 'Monday', value: 'Mon' }),
        validation: false,
        autoPopulated: false,
        visible: ['shift'],
    },
];

export const getFieldsDefaultState = () => {
    let defaultState = {
        fields: {},
        validation: {},
        errors: {},
        autoPopulated: {},
        visible: {},
    };

    for (let i = 0; i < formFields.length; i++) {
        let name = formFields[i].name;

        defaultState.fields[name] = formFields[i].value();
        defaultState.validation[name] = formFields[i].validation;
        defaultState.errors[name] = [];
        defaultState.autoPopulated[name] = formFields[i].autoPopulated;
        defaultState.visible[name] = formFields[i].visible;
    }

    return defaultState;
};
