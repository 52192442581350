import { gql } from '@apollo/client';

export const GET_DEPARTMENTS_LIST = gql`
    query GetDepartments(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: DepartmentFilter!
        $query: String
    ) {
        getDepartments(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    isArchived
                    createdAt
                    createdBy
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedBy
                    costCode
                    roles {
                        id
                        employeeRole {
                            id
                            name
                            isArchived
                        }
                    }
                    employees {
                        employeeRecord {
                            id
                        }
                    }
                    costCentre {
                        id
                        name
                    }
                    locations {
                        location {
                            id
                            name
                            isArchived
                            address1
                            address2
                            address3
                            townCity
                            postcode
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_ALL_DEPARTMENTS_LIST = gql`
    query GetAllDepartmentsList(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: DepartmentFilter
        $query: String
    ) {
        getDepartments(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    isArchived
                    costCode
                    costCentre {
                        name
                        id
                    }
                    roles {
                        employeeRoleId
                        employeeRole {
                            name
                            id
                        }
                    }
                    employees {
                        employeeRecord {
                            id
                        }
                    }
                    locations {
                        location {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_DEPARTMENT = gql`
    mutation CreateDepartment($data: DepartmentEntityInput!) {
        createDepartment(data: $data) {
            id
        }
    }
`;

export const UPDATE_DEPARTMENT = gql`
    mutation UpdateDepartment($id: String!, $data: DepartmentEntityInput!) {
        updateDepartment(id: $id, data: $data) {
            id
        }
    }
`;

export const GET_DEPARTMENT_BY_ID = gql`
    query GetDepartmentById($id: String!) {
        getDepartmentById(id: $id) {
            id
            name
            costCode
            isArchived
            employees {
                employeeRecord {
                    id
                    person {
                        firstName
                        lastName
                    }
                }
            }
            roles {
                isInUse
                employeeRole {
                    id
                    name
                }
            }
            shiftPatterns {
                id
                name
                updatedAt
                shiftPatternEntries {
                    id
                    shiftPatternId
                    defaultEmployeeId
                    weekNumber
                    dayOfWeek
                    startTime
                    endTime
                    breakDurationMinutes
                    breakIsPaid
                    thirdPartyPaid
                    trainingShift
                    trainees {
                        traineeId
                        roleId
                        payRateId
                        overrideValue
                    }
                    shiftEmployeeRoleTypeId
                    shiftFunctions {
                        functionId
                    }
                    fundingPoolId
                    locationId
                    overrideValueInPence
                    typeId
                    shiftType {
                        id
                        name
                    }
                    payRateId
                }
            }
            costCentre {
                id
                name
            }
            locations {
                isInUse
                location {
                    id
                    name
                    isArchived
                    address1
                    address2
                    address3
                    townCity
                    postcode
                }
            }
        }
    }
`;

export const GET_DEPARTMENTS_BY_COST_CENTRE_ID = gql`
    query GetDepartmentsByCostCentreId($id: String!) {
        getDepartmentsByCostCentreId(id: $id) {
            id
            name
            costCode
            isArchived
        }
    }
`;

export const DELETE_DEPARTMENT = gql`
    mutation DeleteDepartment($id: String!) {
        deleteDepartment(id: $id) {
            id
        }
    }
`;

export const BULK_DELETE_DEPARTMENTS = gql`
    mutation BulkDeleteDepartments($ids: [String!]!) {
        bulkDeleteDepartments(ids: $ids) {
            count
        }
    }
`;

export const ARCHIVE_DEPARTMENT = gql`
    mutation ArchiveDepartment($id: String!) {
        archiveDepartment(id: $id) {
            id
        }
    }
`;

export const BULK_ARCHIVE_DEPARTMENTS = gql`
    mutation BulkArchiveDepartments($ids: [String!]!) {
        bulkArchiveDepartments(ids: $ids) {
            count
        }
    }
`;

export const IS_DEPARTMENT_ARCHIVABLE = gql`
    query IsDepartmentArchivable($ids: [String!]!) {
        isDepartmentArchivable(ids: $ids) {
            id
            name
            isArchivable
        }
    }
`;

export const IS_ROLE_IN_USE = gql`
    query IsRoleInUse($departmentId: String!, $roleId: String!) {
        isRoleInUse(departmentId: $departmentId, roleId: $roleId)
    }
`;

export const IS_LOCATION_IN_USE = gql`
    query IsLocationInUse($departmentId: String!, $locationId: String!) {
        isLocationInUse(departmentId: $departmentId, locationId: $locationId)
    }
`;
