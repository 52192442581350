import React from 'react';
import useHeaderSorting from '../../../../hook/useHeaderSorting';
import SortedHeader from '../../../common/sorting/SortedHeader';
import { Checkbox } from '@blueprintjs/core';

interface CostCentresHeaderProps {
    onSort: () => void;
    onToggleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isFrontendSort: boolean;
    checked: boolean;
}

const CostCentresTableHeader = ({
    onSort,
    isFrontendSort = true,
    onToggleSelectAll,
    checked,
}: CostCentresHeaderProps) => {
    const { sort, sortData } = useHeaderSorting((s: any) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr>
                <th>
                    <Checkbox onChange={onToggleSelectAll} checked={checked} />
                </th>
                <SortedHeader sort={sort} sortBy={'name'} text="Name" sortData={sortData} />
                <SortedHeader sort={sort} sortBy={'code'} text="Code" sortData={sortData} />
                <SortedHeader
                    sort={sort}
                    sortBy={'isArchived'}
                    text="Record Status"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdAt'}
                    text="Created on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'createdPerson'}
                    text="Created by"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedAt'}
                    text="Updated on"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedPerson'}
                    text="Last updated by"
                    sortData={sortData}
                />
            </tr>
        </thead>
    );
};

export default CostCentresTableHeader;
