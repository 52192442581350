import moment from 'moment';
import { addPhoneCountryCode, removePhoneLeadingZero } from './formatData';

const dispositionMappings: { [key: string]: string } = {
    '0 - 1 Day': '0-1DayResponse',
};

const serviceMappings: { [key: string]: string } = {
    CADS: 'cads',
};

const parseIntoLines = (rawContent: string) =>
    rawContent
        .replace(/\t/g, '')
        .split('\n')
        .map((string) => string.trim());

const parsePatientSummary = (rawContent: string) => {
    const lines = parseIntoLines(rawContent);

    const extractFromLine = (field: string) =>
        lines.find((line) => line.startsWith(field))?.replace(field, '') || '';

    const extractAddressFromLines = (index: number) => {
        const [addressLine1, addressLine2, addressLine3, town, , postCode] = lines.slice(
            index + 1,
            index + 7,
        );

        return {
            addressLine1: addressLine1?.replace('House Number', '').replace('House Name', ''),
            addressLine2: addressLine2?.replace('Road', ''),
            addressLine3: addressLine3?.replace('Locality', ''),
            town: town?.replace('Town', ''),
            postCode: postCode?.replace('Postcode', '').trim(),
        };
    };

    const [lastName, firstNameWithTitle] = extractFromLine('Patient Name').split(', ');
    const [firstName] =
        typeof firstNameWithTitle === 'string' ? firstNameWithTitle.split(' (') : [];

    const addressIndex = lines.indexOf('Address');
    const temporaryAddressIndex = lines.indexOf('Current temporary address');

    let address = extractAddressFromLines(addressIndex);
    let notes = '';

    if (temporaryAddressIndex > -1) {
        notes = `Permanent address: ${Object.values(address)
            .filter((_) => _)
            .join(', ')}`;
        address = extractAddressFromLines(temporaryAddressIndex);
    }

    const dateOfBirth = moment.utc(extractFromLine('Date Of Birth'), [
        'DD MMM YYYY HH:mm',
        'DD-MM-YYYY HH:mm',
    ]);

    const phoneTemplates = ['Mobile Telephone', 'Home Telephone', 'Work Telephone'];
    const [contactNumber, ...additionalContactNumbers] = phoneTemplates.reduce((res, template) => {
        const phone = extractFromLine(template);
        if (phone && phone !== '') {
            res.push(
                addPhoneCountryCode(
                    removePhoneLeadingZero(phone.split(',')[0].replace(/:|\s/g, '')),
                ).replace('+', ''),
            );
        }
        return res;
    }, [] as string[]);

    return {
        nhsNumber: extractFromLine('NHS Number').replace(/\s+/g, ''),
        gender: extractFromLine('Gender').toLowerCase(),
        dateOfBirth: dateOfBirth.isValid() ? dateOfBirth.toDate() : null,
        firstName,
        lastName,
        notes,
        contactNumber,
        additionalContactNumbers:
            additionalContactNumbers.length !== 0 ? additionalContactNumbers : undefined,
        ...address,
    };
};

const parseReferralDetails = (rawContent: string) => {
    const lines = parseIntoLines(rawContent);

    const extractFromLine = (field: string) =>
        lines.find((line) => line.startsWith(field))?.replace(`${field} `, '') || '';

    const referralDateTime = moment(extractFromLine('Date of Referral'), 'DD MMM YYYY HH:mm');

    return {
        referredBy: extractFromLine('Source of Referral'),
        referralDateTime: referralDateTime.isValid() ? referralDateTime.toDate() : null,
        referralPathway: serviceMappings[extractFromLine('Service Offered')],
        disposition: dispositionMappings[extractFromLine('Urgency')],
    };
};

export default function SystmOneCommunityParser(patientSummary: string, referralDetails: string) {
    const parsedData = {
        ...parsePatientSummary(patientSummary),
        ...parseReferralDetails(referralDetails),
    };

    const result: { [key: string]: any } = {};

    Object.entries(parsedData).forEach(([key, value]) => {
        if (value) {
            result[key] = value;
        }
    });

    return result;
}
