import { FC } from 'react';
import { observer } from 'mobx-react';

import useStores from '../../../../hook/useStores';
import { ViewToShow } from '../../../../constants/mainConst';

import { MapViewControls } from './viewComponents/ViewMap';
import { VisitListViewControls } from './viewComponents/ViewVisitList';
import { TimelineViewControls } from './viewComponents/ViewTimeline';
import { PatientListViewControls } from './viewComponents/ViewPatientList';
import { PatientListViewControlsV2 } from './viewComponents/v2/ViewPatientList';

const UcrFilters: FC<{ refreshJobs(): void }> = ({ refreshJobs }) => {
    const {
        RootStore: {
            ucrStore: { viewToShow },
        },
    } = useStores() as { RootStore: any };

    switch (viewToShow) {
        case ViewToShow.MAP:
            return <MapViewControls />;
        case ViewToShow.PATIENTS_LIST_V2:
            return <PatientListViewControlsV2 refreshJobs={refreshJobs} />;
        case ViewToShow.PATIENTS_LIST:
            return <PatientListViewControls refreshJobs={refreshJobs} />;
        case ViewToShow.TIMELINE:
            return <TimelineViewControls refreshJobs={refreshJobs} />;
        case ViewToShow.VISITS_LIST:
            return <VisitListViewControls refreshJobs={refreshJobs} />;
    }

    return null;
};

export default observer(UcrFilters);
