/* eslint-disable prettier/prettier */
import { observable, action, makeObservable, computed, runInAction } from 'mobx';

import {
    CREATE_DEPARTMENT_ASSIGNMENT,
    GET_DEPARTMENT_ASSIGNMENT_BY_ID,
    GET_DEPARTMENT_ASSIGNMENTS,
    UPDATE_DEPARTMENT_ASSIGNMENTS,
    ARCHIVE_DEPARTMENT_ASSIGNMENT,
    DEPARTMENT_ASSIGNMENT_VALID,
    // ARCHIVE_DEPARTMENT_ASSIGNMENTS,
} from '../graphql/queries/departmentAssignments';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import AppToaster from '../components/modules/helpers/Toaster';

export interface NormalizedDepartmentAssignments {
    id: string;
    costCentreName: string;
    departmentName: string;
    startDate: Date | null;
    endDate: Date | null;
    payRateName: string;
    isArchived: string;
    createdAt: Date | null;
    createdBy: string;
    updatedAt: Date | null;
    updatedBy: string;
}

export interface NormalizedDepartmentAssignment {
    id: string;
    costCentre: any;
    department: any;
    startDateOfAssignment: Date | null;
    endDateOfAssignment: Date | null;
    employeeRecordId: string;
    jobTitle: string;
    locations: any;
    payRates: any;
    payRatesFuture: any;
    payRatesFutureDate: any;
    reasonForEnding: string;
    comments: string;
    isArchived: string;
    isDepartmentEditable: boolean;
}

export interface DenormalizedDepartmentAssignment {
    departmentId: any;
    startDateOfAssignment: Date | null;
    endDateOfAssignment: Date | null;
    employeeRecordId: string;
    jobTitle: string;
    locations: string;
    payRates: any;
    reasonForEnding: string;
    comments: string;
}

class DepartmentAssignmentsStore {
    constructor(rootStore: any) {
        makeObservable(this, {
            departmentAssignments: observable,
            employeeDepartmentDatesValid: observable,
            loaded: observable,
            sortFn: observable,
            gridDepartmentAssignments: computed,
            // onSort: action,
            getDepartmentAssignments: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    departmentAssignments: any = [];
    departmentAssignmentsActive: any = [];
    departmentAssignmentEarliest: any = [];
    departmentAssignmentLatest: any = [];
    employeeDepartmentDatesValid: any = [];
    sortFn: (a: any, b: any) => number = () => 0;
    loaded = false;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeDepartmentAssignments = (item: any): NormalizedDepartmentAssignments => ({
        id: item.id,
        costCentreName: item?.department?.costCentre?.name,
        departmentName: item.department.name,
        startDate: item.startDateOfAssignment ? new Date(item.startDateOfAssignment) : null,
        endDate: item.endDateOfAssignment ? new Date(item.endDateOfAssignment) : null,
        payRateName: item.payRates.length ? item.payRates[0].payRate.name : '',
        isArchived: item.isArchived ? 'Archived' : 'Active',
        createdAt: item.createdAt ? new Date(item.createdAt) : null,
        createdBy: item.createdPerson
            ? `${item.createdPerson.firstName} ${item.createdPerson.lastName}`
            : '',
        updatedAt: item.updatedAt ? new Date(item.updatedAt) : null,
        updatedBy: item.updatedPerson
            ? `${item.updatedPerson.firstName} ${item.updatedPerson.lastName}`
            : '',
    });
    normalizeDepartmentAssignment = (item: any): NormalizedDepartmentAssignment => ({
        id: item.id,
        costCentre: item.department.costCentre
            ? {
                  value: item.department?.costCentre?.id,
                  label: `${item.department?.costCentre?.name} (${item.department?.costCentre?.code})`,
              }
            : [],
        department: item.department
            ? {
                  value: item.department?.id,
                  label: `${item.department?.name} (${item.department?.costCode})`,
              }
            : [],
        payRates:
            item.payRates.length === 1
                ? item.payRates.map((item: any) => ({
                      value: item.payRateId,
                      label: item.payRate.name,
                  }))
                : item.payRates
                      .filter((pr: any) => pr.endDate && true)
                      .map((item: any) => ({
                          value: item.payRateId,
                          label: item.payRate.name,
                      })),
        payRatesFuture:
            item.payRates.length > 1
                ? item.payRates
                      .filter((pr: any) => pr.endDate === null)
                      .map((item: any) => ({
                          value: item.payRateId,
                          label: item.payRate.name,
                      }))
                : null,
        payRatesFutureDate:
            item.payRates.length > 1
                ? new Date(item.payRates.filter((pr: any) => pr.endDate === null)[0]?.startDate)
                : null,
        isArchived: item.isArchived,
        isDepartmentEditable: item.isDepartmentEditable,
        startDateOfAssignment: item.startDateOfAssignment
            ? new Date(item.startDateOfAssignment)
            : null,
        endDateOfAssignment: item.endDateOfAssignment ? new Date(item.endDateOfAssignment) : null,
        jobTitle: item.jobTitle,
        reasonForEnding: item.reasonForEnding,
        comments: item.comments,
        locations: item.locations
            ? item.locations.map((item: any) => ({
                  value: item.location.id,
                  label: item.location.name,
              }))
            : [],
        employeeRecordId: item.employeeRecordId,
    });

    denormalizeDepartmentAssignment = (item: any): DenormalizedDepartmentAssignment => ({
        departmentId: item.department.value,
        payRates:
            item.payRatesFuture && item.payRatesFuture[0]?.value
                ? [
                      {
                          payRateId: item.payRates[0].value,
                          startDate: new Date(),
                          endDate: item.payRatesFutureDate,
                      },
                      {
                          payRateId: item.payRatesFuture[0].value,
                          startDate: item.payRatesFutureDate,
                      },
                  ]
                : [
                      {
                          payRateId: item.payRates[0].value,
                          startDate: new Date(),
                      },
                  ],

        startDateOfAssignment: item.startDateOfAssignment,
        endDateOfAssignment: item.endDateOfAssignment,
        jobTitle: item.jobTitle,
        reasonForEnding: item.reasonForEnding,
        comments: item.comments,
        locations: item.locations ? item.locations?.map((l: any) => l.value) : [],
        employeeRecordId: item.employeeRecordId,
    });

    get gridDepartmentAssignments() {
        const result = this.departmentAssignments.map(this.normalizeDepartmentAssignments);

        if (this.sortFn) {
            result.sort(this.sortFn);
        }
        return result;
    }

    setSortFunc = (sortFunc: any) => {
        if (sortFunc) {
            this.sortFn = sortFunc;
        }
    };

    async createDepartmentAssignments(dep: any) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_DEPARTMENT_ASSIGNMENT,
                variables: {
                    data: this.denormalizeDepartmentAssignment(dep),
                },
            });
            AppToaster.show({
                message: 'Department assignment created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem creating Department assignment record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async updateDepartmentAssignments(dep: any) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_DEPARTMENT_ASSIGNMENTS,
                variables: {
                    id: dep.id,
                    data: this.denormalizeDepartmentAssignment(dep),
                },
            });
            AppToaster.show({
                message: 'Department assignment updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating Department assignment record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async archiveDepartmentAssignment(id: any) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_DEPARTMENT_ASSIGNMENT,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Department assignment archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving Department assignment record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async areEmployeeDepartmentDatesValid(dep: any) {
        try {
            const {
                data: { areEmployeeDepartmentDatesValid: data },
            }: { data: any } = await this.apolloClient.query({
                query: DEPARTMENT_ASSIGNMENT_VALID,
                variables: {
                    data: dep,
                },
            });

            runInAction(() => {
                this.employeeDepartmentDatesValid =
                    {
                        isValid: data.isValid,
                        conflictingEmployeeDepartment: {
                            department: data.conflictingEmployeeDepartment?.department,
                            startDateOfAssignment: data.conflictingEmployeeDepartment
                                ?.startDateOfAssignment
                                ? new Date(data.conflictingEmployeeDepartment.startDateOfAssignment)
                                : null,
                            endDateOfAssignment: data.conflictingEmployeeDepartment
                                ?.endDateOfAssignment
                                ? new Date(data.conflictingEmployeeDepartment.endDateOfAssignment)
                                : null,
                        },
                    } || [];
            });
        } catch (err) {
            console.log(err);
        }
    }

    getDepartmentAssignmentById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeDepartmentById: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENT_ASSIGNMENT_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeDepartmentAssignment(data);
    };

    getDepartmentAssignments = async (employeeId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getEmployeeDepartments: data },
        }: { data: any } = await this.apolloClient.query({
            query: GET_DEPARTMENT_ASSIGNMENTS,
            variables: {
                filter: {
                    employeeId,
                },
                orderBy: 'asc',
                field: 'startDateOfAssignment',
            },
        });

        runInAction(() => {
            this.departmentAssignments = data.edges?.map((x: any) => x.node) || [];
            this.departmentAssignmentsActive = data.edges
                .map((x: any) => x.node)
                ?.filter((item: any) => !item.isArchived);
            this.departmentAssignmentEarliest = this.departmentAssignmentsActive?.sort(
                (a: any, b: any) => a.startDateOfAssignment - b.startDateOfAssignment,
            )[0];
            this.departmentAssignmentLatest = this.departmentAssignmentsActive?.sort(
                (a: any, b: any) => a.endDateOfAssignment - b.endDateOfAssignment,
            )[0];
            this.loaded = true;
        });
    };

    refresh = async (employeeId: string) => {
        await this.getDepartmentAssignments(employeeId);
    };
}

export default DepartmentAssignmentsStore;
