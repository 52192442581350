import { Icon, IconName } from '@blueprintjs/core';
import { NavLink, useHistory } from 'react-router-dom';

type SideNavLinkProps = {
    title: string;
    to: string;
    details?: any;
};

type SideNavItemProps = SideNavLinkProps & {
    icon: IconName;
    subItems: SideNavLinkProps[];
};

interface SideNavProps {
    items: SideNavItemProps[];
}

const usePartialRouteMatch = (paths: string[]) =>
    paths.some((path) => window.location.pathname.startsWith(path));

const SideNavSubItem = ({ title, to }: SideNavLinkProps) => {
    const isActive = usePartialRouteMatch([to]);

    return (
        <NavLink
            to={to}
            className="side-nav__sub-link"
            activeClassName="side-nav__sub-link--active"
            isActive={() => isActive}
        >
            {title}
        </NavLink>
    );
};

const SideNavItem = ({ title, to, icon, details, subItems = [] }: SideNavItemProps) => {
    const subPaths = subItems.map((subItem) => subItem.to);
    const isActive = usePartialRouteMatch([to, ...subPaths]);
    const history = useHistory();

    return (
        <>
            <NavLink
                to={{
                    pathname: to,
                    state: {
                        prevPath: history.location,
                    },
                }}
                className="side-nav__link"
                activeClassName="side-nav__link--active"
                isActive={() => isActive}
            >
                <Icon icon={icon} iconSize={20} />
                <span className="side-nav__link-text">{title}</span>
                {details && details}
            </NavLink>
            {isActive &&
                subItems.map((subItem) => (
                    <SideNavSubItem {...subItem} key={`${to}_${subItem.to}`} />
                ))}
        </>
    );
};

const SideNav = ({ items }: SideNavProps) => {
    return (
        <nav className="side-nav">
            {items.map((item) => (
                <SideNavItem {...item} key={item.to} />
            ))}
        </nav>
    );
};

export default SideNav;
