import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tag, Callout } from '@blueprintjs/core';

import JobListItem from './JobListItem';

const JobsList = inject('RootStore')(
    observer(
        class JobsList extends Component {
            handleListItemClick = (jobId, event) => {
                if (!event.target.href) {
                    this.props.history.push(
                        `/on-demand/${this.props.match.params.tab}/jobs/${jobId}`,
                    );
                }
            };

            render() {
                const status = this.props.match.params.tab;
                const activeJob = this.props.match.params.id ? this.props.match.params.id : null;
                const { jobsStore } = this.props.RootStore;
                const jobs = jobsStore.onDemand(jobsStore[`${status}Jobs`]);
                const requireAttentionJobs = jobsStore.onDemand(jobsStore.requireAttentionJobs);

                return (
                    <div className="jobs-list">
                        {/* Requires attention list */}
                        {this.props.RootStore && requireAttentionJobs.length ? (
                            <>
                                <h3 className="h3 require-attention">
                                    Require attention
                                    <Tag round intent="danger" className="require-attention__tag">
                                        {requireAttentionJobs.length}
                                    </Tag>
                                </h3>

                                {requireAttentionJobs.map((job, i) => (
                                    <JobListItem
                                        data={job}
                                        activeJob={activeJob}
                                        status="requireAttention"
                                        onListItemClick={this.handleListItemClick}
                                        key={job.id}
                                        {...this.props}
                                    />
                                ))}
                            </>
                        ) : (
                            ''
                        )}

                        {/* Tab specific list */}
                        {this.props.RootStore && !jobs.length ? (
                            <Callout intent="primary">
                                There are no <strong>{status}</strong> jobs
                            </Callout>
                        ) : (
                            jobs.map((job, i) => (
                                <JobListItem
                                    data={job}
                                    activeJob={activeJob}
                                    status={status}
                                    onListItemClick={this.handleListItemClick}
                                    key={job.id}
                                    {...this.props}
                                />
                            ))
                        )}
                    </div>
                );
            }
        },
    ),
);

export default JobsList;
