import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ShiftPatternEntry from './ShiftPatternEntry';
import { Formik, Form } from 'formik';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import { Dialog } from '@blueprintjs/core';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';
import _ from 'lodash';

const ShiftPatternEntryDetails = inject('RootStore')(
    observer(
        class ShiftPatternDetails extends Component {
            state = {
                isEdit: false,
                initialData: {
                    id: null,
                    defaultEmployeeId: '',
                    shiftPatternId: '',
                    weekNumber: 0,
                    dayOfWeek: 0,
                    startTime: moment().set({ hour: 8, minute: 0, second: 0 }).toDate(),
                    endTime: moment().set({ hour: 17, minute: 0, second: 0 }).toDate(),
                    breakDurationMinutes: 0,
                    breakIsPaid: false,
                    shiftEmployeeRoleTypeId: '',
                    shiftFunctions: [],
                    patientFacing: false,
                    fundingPoolId: '',
                    typeId: '',
                    locationId: '',
                    overrideValueInPence: 0,
                    newShiftId: '',
                    payRateId: '',
                    department: null,
                    thirdPartyPaid: false,
                    trainingShift: false,
                    trainees: [
                        {
                            traineeId: '',
                            roleId: '',
                            overrideValue: null,
                            payRateId: '',
                        },
                    ],
                    conflicts: [],
                    comments: [],
                    locationOptions: [],
                },
                isLoaded: false,
                title: '',
            };

            async componentDidMount() {
                if (this.props.RootStore.shiftPatternsStore.entryActionType === 'create') {
                    this.setState({
                        initialData: {
                            ...this.state.initialData,
                            ...this.props.RootStore.shiftPatternsStore.newShiftPatternEntry,
                        },
                        isEdit: true,
                        isLoaded: true,
                    });
                }
                if (this.props.RootStore.shiftPatternsStore.entryActionType === 'edit') {
                    this.props.RootStore.shiftPatternsStore.isCreatePath = false;
                    const shiftPatternEntry = this.props.RootStore.shiftPatternsStore
                        .newShiftPatternEntry;

                    if (shiftPatternEntry) {
                        this.setState({
                            initialData: { ...shiftPatternEntry },
                            isLoaded: true,
                        });
                    }
                }

                const departmentId = this.props.RootStore.shiftPatternsStore.newShiftPattern
                    .departmentId;
                if (departmentId) {
                    let department = this.props.RootStore.departmentsStore.allDepartmentsActive.find(
                        (item) => item.id === departmentId,
                    );
                    if (!department) {
                        department = await this.props.RootStore.departmentsStore.getDepartmentById(
                            departmentId,
                        );
                    }
                    const locationOptions = department?.locations?.map((loc) => ({
                        label: loc?.location?.name ? loc?.location?.name : loc.label,
                        value: loc?.location?.id ? loc?.location?.id : loc.value,
                    }));
                    this.setState({ locationOptions });
                }
                await this.props.RootStore.staffStore.getAllStaffs();
                await this.props.RootStore.payRatesStore.getAllPayRates();
                this.props.RootStore.payRatesStore.clearPayRatesByRole();
            }

            _handleCloseClick = (values) => {
                const updatedCommentsObj = {
                    ...this.props.RootStore.shiftPatternsStore.newShiftPatternEntry,
                    comments: values.comments,
                };
                this.props.RootStore.shiftPatternsStore.replaceNewShiftPatternEntry(
                    updatedCommentsObj,
                );
                this.props.RootStore.shiftPatternsStore.showShiftEntryModal = false;
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnCancel = (values) => {
                const updatedCommentsObj = {
                    ...this.props.RootStore.shiftPatternsStore.newShiftPatternEntry,
                    comments: values.comments,
                };
                this.props.RootStore.shiftPatternsStore.replaceNewShiftPatternEntry(
                    updatedCommentsObj,
                );
                this.props.RootStore.shiftPatternsStore.showShiftEntryModal = false;
            };

            updateShiftPatternComments = async () => {
                const pageId = this.props.id;
                if (!pageId) return;
                const shiftPattern = await this.props.RootStore.shiftPatternsStore.getShiftPatternById(
                    pageId,
                );
                if (shiftPattern) {
                    const currentEntryId = this.state.initialData.id;
                    const currentEntry = shiftPattern.shiftPatternEntries
                        ? shiftPattern.shiftPatternEntries.find(
                              (entry) => entry.id === currentEntryId,
                          )
                        : undefined;
                    if (currentEntry) {
                        this.setState((prev) => ({
                            initialData: {
                                ...prev.initialData,
                                comments: currentEntry.comments,
                            },
                        }));
                    }
                }
            };

            getStaffAgencyAndContractType = async (id) =>
                await this.props.RootStore.staffStore.getStaffRecordAgencyAndContractTypeById(id);

            setTitle = (title) => this.setState({ title });

            render() {
                const {
                    RootStore: { dictionaryStore, shiftPatternsStore, userStore },
                } = this.props;
                const {
                    state: { locationOptions },
                } = this;

                if (
                    this.props.RootStore.shiftPatternsStore.entryActionType === 'edit' &&
                    !this.state.isLoaded
                ) {
                    return null;
                }

                const currentUser = userStore.person;

                const showModal = shiftPatternsStore.showShiftEntryModal;

                return (
                    showModal && (
                        <Formik
                            name="subform"
                            initialValues={{
                                ...this.state.initialData,
                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={async (values, { setSubmitting }) => {
                                const submitValues = async () => {
                                    if (values.defaultEmployeeId) {
                                        const agencyAndContractType = await this.getStaffAgencyAndContractType(
                                            values.defaultEmployeeId,
                                        );
                                        !!agencyAndContractType.contractTypeId &&
                                            _.set(
                                                values,
                                                'defaultEmployee.contractType.name',
                                                `${
                                                    dictionaryStore.contractDictionary[
                                                        agencyAndContractType.contractTypeId
                                                    ]
                                                }`,
                                            );
                                        !!agencyAndContractType.agencyId &&
                                            _.set(
                                                values,
                                                'defaultEmployee.agency.name',
                                                `${
                                                    dictionaryStore.agenciesDictionary[
                                                        agencyAndContractType.agencyId
                                                    ]
                                                }`,
                                            );

                                        !!agencyAndContractType.person &&
                                            _.set(
                                                values,
                                                'defaultEmployee.person',
                                                agencyAndContractType.person,
                                            );
                                        !!agencyAndContractType.person &&
                                            _.set(
                                                values,
                                                'defaultEmployee.id',
                                                values.defaultEmployeeId,
                                            );
                                    } else {
                                        _.set(values, 'defaultEmployee.person', null);
                                        _.set(values, 'defaultEmployee.id', null);
                                    }
                                    if (values.trainees[0].traineeId) {
                                        const trainee = this.props.RootStore.staffStore.allStaffs.find(
                                            (staffMember) =>
                                                staffMember.id === values.trainees[0].traineeId,
                                        );
                                        const traineePerson = trainee
                                            ? {
                                                  id: trainee.id,
                                                  firstName: trainee?.person?.firstName,
                                                  lastName: trainee?.person?.lastName,
                                              }
                                            : trainee;
                                        !!traineePerson &&
                                            _.set(values, 'traineePerson', traineePerson);
                                    } else {
                                        _.set(values, 'traineePerson', null);
                                    }
                                    if (shiftPatternsStore.entryActionType === 'create') {
                                        if (!shiftPatternsStore.isCreatePath) {
                                            await shiftPatternsStore.updateNewShiftPatternEntry({
                                                ...shiftPatternsStore.newShiftPatternEntry,
                                                newShiftId: uuid_v4(),
                                                shiftPatternId:
                                                    shiftPatternsStore.newShiftPattern.id,
                                                ...values,
                                            });
                                        } else {
                                            await shiftPatternsStore.replaceNewShiftPatternEntry(
                                                values,
                                            );
                                        }

                                        shiftPatternsStore.showShiftEntryModal = false;
                                    } else {
                                        await shiftPatternsStore.replaceNewShiftPatternEntry(
                                            values,
                                        );
                                        await shiftPatternsStore.updateShiftPatternEntry(values);
                                        shiftPatternsStore.showShiftEntryModal = false;
                                    }
                                    await shiftPatternsStore.orderFilteredShiftsByDefault();
                                };

                                await submitValues();
                                setSubmitting(false);
                            }}
                        >
                            {({
                                isSubmitting,
                                values,
                                handleChange,
                                setFieldValue,
                                errors,
                                touched,
                            }) => (
                                <Dialog
                                    onClose={() => this._handleCloseClick(values)}
                                    title={this.state.title}
                                    canOutsideClickClose={false}
                                    isOpen={showModal}
                                    className="shift-pattern-entry__dialog"
                                >
                                    <Form className="shift-pattern-entry__form common-form">
                                        <>
                                            <ShiftPatternEntry
                                                shiftPatternEntry={this.state.initialData}
                                                editOrCreateMode={this.state.isEdit}
                                                values={values}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                actionType={this.props.actionType}
                                                currentUser={currentUser}
                                                errors={errors}
                                                touched={touched}
                                                updateShiftPatternComments={
                                                    this.updateShiftPatternComments
                                                }
                                                locationOptions={locationOptions}
                                                setTitle={this.setTitle}
                                            />
                                            <div className="shift-pattern-entry__form-footer">
                                                <CommonModalFooter
                                                    isSubmitting={isSubmitting}
                                                    hasEditOption={
                                                        !this.props.RootStore.shiftPatternsStore
                                                            .entryActionType === 'edit'
                                                    }
                                                    toggleIsEdit={this._toggleIsEdit}
                                                    isEditing={this.state.isEdit}
                                                    onCancel={() => this._handleOnCancel(values)}
                                                />
                                            </div>
                                        </>
                                    </Form>
                                </Dialog>
                            )}
                        </Formik>
                    )
                );
            }
        },
    ),
);

export default ShiftPatternEntryDetails;
