import { IconName } from '@blueprintjs/icons';

export const PAGE_SIZE = 20;
export const PEOPLE_STATUS_OPTIONS = [
    {
        label: 'Agency',
        value: 'Agency',
    },
    {
        label: 'Contractor',
        value: 'Contractor',
    },
    {
        label: 'Employee',
        value: 'Employee',
    },
    {
        label: 'TBD',
        value: 'TBD',
    },
];

export const CONTRACT_TYPE_AGENCY = 'cf689985-66cd-4649-b376-28da3c69db1f';
export const CONTRACT_TYPE_DIRECT_ENGAGEMENT = '51577de2-5488-4e93-a68a-705194e875fa';
export const CONTRACT_TYPE_SELF_EMPLOYED = 'ffebebbc-ab3b-412f-847d-0015e15e9ebf';
export const CONTRACT_TYPE_BANK = '0a5e90b3-6ff7-414f-821c-5b8a6ecd361a';

export const PEOPLE_RECORD_STATUS_OPTIONS = [
    {
        label: 'Active',
        value: 'd89d6d81-1ca1-4038-b4f1-7794ef3cd4a9',
    },
    {
        label: 'Archived',
        value: '7799ad39-2f73-490d-9e98-27ac149dee55',
    },
    {
        label: 'In progress',
        value: 'c33a1670-d497-4e25-a736-0bc9a000b127',
    },
    {
        label: 'Suspended',
        value: '464df4f3-8268-484d-a83f-e4d8947b8b7e',
    },
];

export const PERSON_ARCHIVED_STATUS = '7799ad39-2f73-490d-9e98-27ac149dee55';
export const PERSON_ACTIVE_STATUS = 'd89d6d81-1ca1-4038-b4f1-7794ef3cd4a9';
export const PERSON_IN_PROGRESS_STATUS = 'd89d6d81-1ca1-4038-b4f1-7794ef3cd4a9';
export const PERSON_SUSPENDED_STATUS = '464df4f3-8268-484d-a83f-e4d8947b8b7e';

export const EMPLOYEE_RECORD_STATUS_OPTIONS = [
    { label: 'In progress', value: '4d826365-bf81-4579-af17-722347def18d' },
    { label: 'Active', value: 'f4717860-2a2e-40bc-b9db-71d67972fc6b' },
    { label: 'Archived', value: '097741c6-c0af-48c0-a848-947a9e8e3472' },
    { label: 'Suspended', value: '8613093d-ec25-4e0f-99c3-10e8405997d3' },
];

export const EMPLOYEE_RECORD_COMPLIANCE_STATUS = [
    { label: 'Compliant', value: true },
    { label: 'Not Compliant', value: false },
];

export const EMPLOYEE_RECORD_ACTIVE_STATUS_ID = 'f4717860-2a2e-40bc-b9db-71d67972fc6b';
export const EMPLOYEE_RECORD_SUSPENDED_STATUS_ID = '8613093d-ec25-4e0f-99c3-10e8405997d3';
export const EMPLOYEE_RECORD_ARCHIVED_STATUS_ID = '097741c6-c0af-48c0-a848-947a9e8e3472';
export const EMPLOYEE_RECORD_IN_PROGRESS_STATUS_ID = '4d826365-bf81-4579-af17-722347def18d';

export const PENDING_VALUE = 'PENDING';
export const APPROVED_VALUE = 'APPROVED';
export const REJECTED_VALUE = 'REJECTED';
export const AUTHORISED_VALUE = 'Authorised';

const PENDING_LABEL = 'Pending';
const APPROVED_LABEL = 'Approved';
const REJECTED_LABEL = 'Rejected';
const AUTHORISED_LABEL = 'Authorised';

export const ABSENCE_STATUSES = [
    {
        label: PENDING_LABEL,
        value: PENDING_VALUE,
    },
    {
        label: APPROVED_LABEL,
        value: APPROVED_VALUE,
    },
    {
        label: REJECTED_LABEL,
        value: REJECTED_VALUE,
    },
    {
        label: AUTHORISED_VALUE,
        value: AUTHORISED_LABEL,
    },
];

export const ABSENCE_STATUSES_DICTIONARY = {
    [PENDING_VALUE]: {
        text: PENDING_LABEL,
        icon: 'help',
        color: '#5c7080',
    },
    [APPROVED_VALUE]: {
        text: APPROVED_LABEL,
        icon: 'tick-circle',
        color: '#0d8050',
    },
    [REJECTED_VALUE]: {
        text: REJECTED_LABEL,
        icon: 'delete',
        color: '#d9534f',
    },
    [AUTHORISED_VALUE]: {
        text: AUTHORISED_LABEL,
        icon: 'tick-circle',
        color: '#0d8050',
    },
};

export const EMPLOYED_IDS = [
    'f724e774-3563-49d1-a5ee-72d2115f4bff',
    '0a5e90b3-6ff7-414f-821c-5b8a6ecd361a',
    'fd1411d1-ca9a-4b56-a63e-a4119788b1dc',
];

export const CONTRACTOR_IDS = [
    'cf689985-66cd-4649-b376-28da3c69db1f',
    'ffebebbc-ab3b-412f-847d-0015e15e9ebf',
    '51577de2-5488-4e93-a68a-705194e875fa',
];

export const CONTRACT_TYPE_OPTIONS = [
    { value: 'cf689985-66cd-4649-b376-28da3c69db1f', label: 'Agency' },
    { value: '51577de2-5488-4e93-a68a-705194e875fa', label: 'Direct engagement' },
    { value: 'ffebebbc-ab3b-412f-847d-0015e15e9ebf', label: 'Self-employed' },
    { value: '0a5e90b3-6ff7-414f-821c-5b8a6ecd361a', label: 'Bank' },
    { value: 'ffebebbc-ab3b-412f-847d-0115e15e9ebf', label: 'Employed' },
    { value: 'f724e774-3563-49d1-a5ee-72d2115f4bff', label: 'Worker' },
];

export const DOCUMENT_EXPIRES = [
    {
        label: 'Expired',
        value: 'expired',
    },
    {
        label: 'Expires soon',
        value: 'expiring soon',
    },
];

export const DOCUMENT_STATUSES = [
    {
        label: 'Active',
        value: true,
    },
    {
        label: 'Archived',
        value: false,
    },
];

export const DOCUMENT_VERIFIED_OPTIONS = [
    {
        label: 'Verified',
        value: true,
    },
    {
        label: 'Unverified',
        value: false,
    },
];

interface verifiedDictionary {
    text: string;
    icon: IconName;
    color: string;
}

export const DOCUMENT_VERIFIED_DICTIONARY: { [key: string]: verifiedDictionary } = {
    true: {
        text: 'Verified',
        icon: 'tick-circle',
        color: '#0d8050',
    },
    false: {
        text: 'Unverified',
        icon: 'help',
        color: '#5c7080',
    },
};

export const ALERT_TYPE_DICTIONARY: { [key: string]: string } = {
    DOCUMENT_EXPIRY: 'Document expiry',
};

export const RECORD_STATUS_OPTIONS = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Archived',
        value: 'archived',
    },
    {
        label: 'All',
        value: null,
    },
];

export const ANY_FUNCTION = '598dd0a0-fa35-4ad1-8ad1-a5040ce375bc';
