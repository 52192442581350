import { gql } from '@apollo/client';

export const GET_SHIFT_PATTERNS = gql`
    query GetShiftPatterns(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: ShiftPatternFilter
        $query: String
    ) {
        getShiftPatterns(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    createdBy
                    updatedAt
                    costCentreId
                    departmentId
                    costCentre {
                        name
                        code
                    }
                    createdPerson {
                        firstName
                        lastName
                    }
                    department {
                        name
                        costCode
                    }
                    numberOfWeeks
                    shiftPatternEntries {
                        id
                        shiftPatternId
                        defaultEmployeeId
                        defaultEmployee {
                            agency {
                                name
                                id
                            }
                            contractType {
                                name
                                id
                            }
                        }
                        weekNumber
                        dayOfWeek
                        startTime
                        endTime
                        breakDurationMinutes
                        breakIsPaid
                        thirdPartyPaid
                        shiftEmployeeRoleTypeId
                        shiftFunctions {
                            functionId
                        }
                        patientFacing
                        fundingPoolId
                        locationId
                        overrideValueInPence
                        typeId
                        shiftType {
                            id
                            name
                        }
                        payRateId
                        trainingShift
                        trainees {
                            traineeId
                            roleId
                            payRateId
                            overrideValue
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_SHIFT_PATTERN_BY_ID = gql`
    query getShiftPatternById($id: String!) {
        getShiftPatternById(id: $id) {
            id
            name
            createdAt
            updatedAt
            createdBy
            costCentreId
            departmentId
            costCentre {
                name
                code
            }
            department {
                name
                costCode
            }
            numberOfWeeks
            shiftPatternEntries {
                id
                shiftPatternId
                defaultEmployeeId
                defaultEmployee {
                    id
                    agency {
                        name
                        id
                    }
                    contractType {
                        name
                        id
                    }
                    person {
                        firstName
                        lastName
                    }
                }
                weekNumber
                dayOfWeek
                startTime
                endTime
                breakDurationMinutes
                breakIsPaid
                thirdPartyPaid
                shiftEmployeeRoleTypeId
                shiftFunctions {
                    functionId
                }
                patientFacing
                fundingPoolId
                locationId
                overrideValueInPence
                typeId
                payRateId
                shiftType {
                    id
                    name
                }
                trainingShift
                trainees {
                    traineeId
                    roleId
                    payRateId
                    overrideValue
                    trainee {
                        person {
                            firstName
                            lastName
                        }
                    }
                }
                comments {
                    id
                    comment
                    isEdited
                    createdBy
                    createdAt
                    updatedAt
                    isImportant
                    createdPerson {
                        firstName
                        lastName
                    }
                }
                conflicts0 {
                    shiftPatternEntryId0
                    shiftPatternEntryId1
                    shiftPatternEntry0 {
                        startTime
                        shiftPattern {
                            id
                            name
                        }
                    }
                    shiftPatternEntry1 {
                        startTime
                        shiftPattern {
                            id
                            name
                        }
                    }
                }
                conflicts1 {
                    shiftPatternEntryId0
                    shiftPatternEntryId1
                    shiftPatternEntry0 {
                        startTime
                        shiftPattern {
                            id
                            name
                        }
                    }
                    shiftPatternEntry1 {
                        startTime
                        shiftPattern {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_SHIFT_PATTERN = gql`
    mutation CreateShiftPattern($data: CreateOrUpdateShiftPatternInput!) {
        createShiftPattern(data: $data) {
            id
        }
    }
`;

export const DELETE_SHIFT_PATTERN = gql`
    mutation DeleteShiftPattern($id: String!) {
        deleteShiftPattern(id: $id)
    }
`;

export const UPDATE_SHIFT_PATTERN = gql`
    mutation UpdateShiftPattern($id: String!, $data: CreateOrUpdateShiftPatternInput!) {
        updateShiftPattern(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const UPDATE_SHIFT_PATTERN_ENTRY = gql`
    mutation UpdateShiftPatternEntry($id: String!, $data: CreateOrUpdateShiftPatternEntryInput!) {
        updateShiftPatternEntry(id: $id, data: $data) {
            id
            shiftPatternId
            defaultEmployeeId
            weekNumber
            dayOfWeek
            startTime
            endTime
            breakDurationMinutes
            breakIsPaid
            thirdPartyPaid
            shiftEmployeeRoleTypeId
            shiftFunctions {
                functionId
            }
            patientFacing
            fundingPoolId
            payRateId
            locationId
            overrideValueInPence
            shiftType {
                id
                name
            }
            trainingShift
            trainees {
                traineeId
                roleId
                overrideValue
                payRateId
            }
            comments {
                id
                comment
                isEdited
                createdBy
                createdAt
                updatedAt
                isImportant
                createdPerson {
                    firstName
                    lastName
                }
            }
        }
    }
`;

export const CREATE_SHIFT_PATTERN_COMMENT = gql`
    mutation CreateRotaShiftComment(
        $comment: String!
        $shiftPatternEntryId: String!
        $isImportant: Boolean
    ) {
        createRotaShiftComment(
            data: {
                comment: $comment
                shiftPatternEntryId: $shiftPatternEntryId
                isImportant: $isImportant
            }
        ) {
            id
            comment
            createdAt
            createdBy
            isEdited
            updatedAt
            isImportant
            createdPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_SHIFT_PATTERN_COMMENT = gql`
    mutation UpdateRotaShiftComment($comment: String!, $id: String!, $isImportant: Boolean) {
        updateRotaShiftComment(data: { comment: $comment, isImportant: $isImportant }, id: $id) {
            id
            comment
            createdAt
            createdBy
            isEdited
            updatedAt
            isImportant
            createdPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const DELETE_SHIFT_PATTERN_COMMENT = gql`
    mutation DeleteRotaShiftComment($id: String!) {
        deleteRotaShiftComment(id: $id)
    }
`;

export const GET_SHIFT_PATTERN_ENTRY_CONFLICTS = gql`
    query getShiftPatternEntityConflicts(
        $employeeId: String!
        $startTime: String!
        $endTime: String!
        $weekNumber: Int!
        $dayOfWeek: Int!
        $entryId: String
    ) {
        getShiftPatternEntityConflicts(
            employeeId: $employeeId
            startTime: $startTime
            endTime: $endTime
            weekNumber: $weekNumber
            dayOfWeek: $dayOfWeek
            entryId: $entryId
        ) {
            shiftPatternId
            shiftPatternEntryId
            shiftPatternName
            startTime
        }
    }
`;
