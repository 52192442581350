import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
    Button,
    FormGroup,
    TextArea,
    Callout,
    ControlGroup,
    Checkbox,
    Dialog,
} from '@blueprintjs/core';
import Select from 'react-select';

import Error from '../../forms/Error';
import AppToaster from '../../../modules/helpers/Toaster';

const Abort = inject('RootStore')(
    observer(
        class Abort extends Component {
            state = {
                showConfirmation: false,
                abortReason: '',
                abortReasonOptions: [],
                abortNotes: '',
                errors: { abortReason: [''] },
                formValid: null,
                jobId: '',
                isChecked: false,
            };

            componentDidMount() {
                const abortReasonOptions = this.props.RootStore.lovsStore.controllerAbortReason;
                const defaultAbortReason = abortReasonOptions.find(
                    (reason) => reason.label === 'Job removed from Doc Abode by controller',
                );

                this.setState({
                    jobId: this.props.match.params.id,
                    abortReasonOptions,
                    abortReason: defaultAbortReason,
                });
            }

            abortJob = async (jobId) => {
                this.props.onToggleLoading(true);
                try {
                    await this.props.RootStore.jobsStore.abortJob(
                        jobId,
                        this.state.abortReason.value,
                        this.state.abortNotes,
                        this.props.isSeriesJob,
                    );
                } catch (err) {
                    AppToaster.show({
                        message: (
                            <>
                                Sorry, there was a problem aborting the job. Please try again or
                                contact{' '}
                                <a href="mailto:support@docabode.com">support@docabode.com</a> for
                                help.
                            </>
                        ),
                        intent: 'danger',
                        timeout: 0,
                    });
                    return this.props.onToggleLoading(false);
                }

                AppToaster.show({
                    message: 'The job has been aborted successfully!',
                    intent: 'success',
                });

                this.props.onToggleLoading(false);
                this.props.RootStore.jobsStore.setJobs();

                const url = this.props.match.path.startsWith('/scheduled')
                    ? '/scheduled'
                    : `/on-demand/${this.props.match.params.tab}/map`;

                this.props.history.push(url);
            };

            handleAbort = async () => {
                this.toggleConfirmation(true);
            };

            validateFields = () => {
                let errorsList = {},
                    errorsCount = 0;

                const setError = (field, errorMessage, errorsList) => {
                    errorsList[field].push(errorMessage);
                    return errorsList;
                };

                if (this.state.abortReason === '') {
                    errorsList['abortReason'] = [];
                    errorsList = setError(
                        'abortReason',
                        'An abort reason needs to be set',
                        errorsList,
                    );
                    errorsCount = +1;
                }

                const formValid = errorsCount === 0;

                this.setState({
                    formValid,
                    errors: errorsList,
                });

                // setState (above) doesn't update state synchronously
                // so the handlSubmit is expecting to know if the form is valid,
                // but when this function finishes state hasn't updated yet and the form
                // is still invalid. Returning formValid let's handleSubmit to continue immediatelly.
                // However, this.state.formValid is used for showing errors too, so it is still needed.
                return formValid;
            };

            handleConfirmation = async () => {
                const formValid = this.validateFields();

                // Validate the form
                if (!formValid) {
                    return;
                }
                this.toggleConfirmation(false);

                await this.abortJob(this.state.jobId);
            };

            handleCancel = () => {
                this.setState({ abortReason: '' });
                this.setState({ abortNotes: '' });
                this.toggleConfirmation(false);
            };

            toggleConfirmation = (status) => {
                this.setState({ showConfirmation: status });
            };

            handleCheckboxChange = () =>
                this.setState((state) => ({
                    isChecked: !state.isChecked,
                }));

            getAbortDisabled = () => this.props.isSeriesJob && !this.state.isChecked;

            render() {
                return (
                    <div>
                        <Dialog
                            onClose={this.handleCancel}
                            isOpen={this.state.showConfirmation}
                            className="alert"
                        >
                            <div className="alert__body">
                                <div className="alert__contents">
                                    <p>
                                        <strong>
                                            You are about to abort a job, please provide a reason
                                            why:
                                        </strong>
                                    </p>
                                    <FormGroup
                                        label="Abort reason"
                                        labelFor="abortreason"
                                        labelInfo="(required)"
                                    >
                                        <Select
                                            options={this.state.abortReasonOptions}
                                            id="abortReason"
                                            onChange={(e) => this.setState({ abortReason: e })}
                                            value={this.state.abortReason}
                                            menuPlacement="top"
                                        />
                                        <Error errors={this.state.errors.abortReason} />
                                    </FormGroup>
                                    <FormGroup label="Abort notes" labelFor="abortNotes">
                                        <TextArea
                                            id="abortNotes"
                                            onChange={(e) =>
                                                this.setState({
                                                    abortNotes: e.target.value,
                                                })
                                            }
                                            fill
                                            large
                                            value={this.state.abortNotes}
                                        />
                                    </FormGroup>
                                    {this.props.isSeriesJob && (
                                        <Checkbox
                                            label="Please confirm that you wish to abort the entire series"
                                            checked={this.state.isChecked}
                                            onChange={this.handleCheckboxChange}
                                        />
                                    )}
                                    <Callout intent="danger">
                                        You must also ensure that the external case details system
                                        is updated
                                    </Callout>
                                </div>
                            </div>
                            <div className="alert__footer">
                                <Button
                                    intent="danger"
                                    text="Abort"
                                    onClick={this.handleConfirmation}
                                    className="alert__btn"
                                    disabled={this.getAbortDisabled()}
                                />
                                <Button text="Cancel" onClick={this.handleCancel} />
                            </div>
                        </Dialog>
                        <ControlGroup fill>
                            <Button
                                intent="danger"
                                large
                                onClick={() => this.handleAbort()}
                                icon="ban-circle"
                            >
                                Abort
                            </Button>
                        </ControlGroup>
                    </div>
                );
            }
        },
    ),
);

export default Abort;
