import React from 'react';
import moment from 'moment';
import { Alert } from '@blueprintjs/core';

const RejectConfirmationAlert = (props: any) => {
    const {
        onConfirm,
        onCancel,
        isOpen,
        approvedItems,
        pendingItems,
        rejectedItems,
        selectedItems,
    } = props;

    return (
        <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Confirm"
            className={`confirmation-alert confirmation-alert--not-confirmed`}
            onConfirm={onConfirm}
            onCancel={onCancel}
            isOpen={isOpen}
            icon={'archive'}
            intent={'warning'}
            canEscapeKeyCancel={true}
        >
            <h4>Confirm rejection!</h4>
            <p>
                {pendingItems.length + approvedItems.length === selectedItems.length ? (
                    'Are you sure you want to reject the selected absence record(s)'
                ) : (
                    <div>
                        <p>
                            The following absence record(s) are already rejected and won’t be
                            changed:
                        </p>
                        <ul>
                            {rejectedItems.map((r: any) => (
                                <li key={r.id}>
                                    <a
                                        href={`/hr/absence-management/${r.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {r.name} ({moment(r.startDate).format('DD/MM')} -{' '}
                                        {moment(r.endDate).format('DD/MM')})
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <p>
                            Do you want to continue with rejection of the following absence
                            record(s)?
                        </p>
                        <ul>
                            {pendingItems.map((r: any) => (
                                <li key={r.id}>
                                    <a
                                        href={`/hr/absence-management/${r.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {r.name} ({moment(r.startDate).format('DD/MM')} -{' '}
                                        {moment(r.endDate).format('DD/MM')})
                                    </a>
                                </li>
                            ))}
                            {approvedItems.map((r: any) => (
                                <li key={r.id}>
                                    <a
                                        href={`/hr/absence-management/${r.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {r.name} ({moment(r.startDate).format('DD/MM')} -{' '}
                                        {moment(r.endDate).format('DD/MM')})
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </p>
        </Alert>
    );
};

export default RejectConfirmationAlert;
