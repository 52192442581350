/*
TODO.
its possible that pending is not a thing anymore for routes

from Oliver Ternes

"I've run a search for PENDING on the Controller code and although I can see two classes that imply routes use this status:
src/components/pages/vaccinations/utils/constants.ts · develop · Doc Abode / controller-app · GitLab
src/components/pages/vaccinations/MapExperimental.js · develop · Doc Abode / controller-app · GitLab
I can't see any other vaccination code that uses PENDING as a status for a route. I can only speculate that this is either a status that has carried over from the On-Demand module, or which is no longer in use today"

Note - MapExperimental.js relates to the 3rd "tab" in the vaccination module (see screenshot). This is only available via a
feature flag which to my knowledge has only been set for the Demo and CNWL Test orgs. I can't see the constant in it being used
so if you did want to make the world a slightly better place, I reckon you could remove those two consts in it
allowEditStatus seems to be specific to validating a route and whether it can be edited. If my assessment above is correct,
there is no reason why PENDING should be in that list.



Big caveat here is that I may not have read the code correct on gitlab
 */

export const routeStatusMappings = {
    PENDING: 'Pending',
    AVAILABLE: 'Unconfirmed',
    ACCEPTED: 'Accepted',
    CURRENT: 'Current',
    ARRIVED: 'In progress',
    COMPLETED: 'Completed',
    HCP_ABORTED: 'Aborted by HCP',
    WITHDRAWN: 'Withdrawn',
    CONTROLLER_ABORTED: 'Aborted by Controller',
};

export const friendlyShortRouteTypes = {
    COVID: 'COVID-19+',
    NON_COVID: 'Non COVID-19',
};

export const routeStatusTags = {
    AVAILABLE: 'warning',
    PENDING: 'warning',
    ACCEPTED: 'success',
    COMPLETED: 'success',
    CURRENT: 'primary',
    ARRIVED: 'primary',
    HCP_ABORTED: 'danger',
    WITHDRAWN: 'danger',
    CONTROLLER_ABORTED: 'danger',
};
// todo look at tying allowEditStatus in with the logic in src/models/PatientRemoveFromRoute.ts
// so we have modification of a route logic / const's all in one place (there may be other places in the code that it exits
// nb.  PatientRemoveFromRoute is concerned with making changes to the patient, allowEditStatus seems to be about VaccinationRoutes
// the way the data model works a change to the Patients does not mean we have made a change to the VaccinationRoutes' data (or the routes instructions)
// those change are inferred when the data is rendered
export const allowEditStatus = ['PENDING', 'AVAILABLE', 'ACCEPTED', 'CURRENT', 'ARRIVED'];

export const friendlyRouteTypes = {
    COVID: 'COVID-19 (plus other vaccines that can be co-administered)',
    NON_COVID: 'Non COVID-19',
};

export const routeTypes = {
    COVID: 'COVID',
    NON_COVID: 'NON_COVID',
    NON_COVID_PLUS_CO_ADMIN: 'NON_COVID_PLUS_CO_ADMIN',
    ANY: 'ANY',
};
