import React, { Component, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import {
    Button,
    ButtonGroup,
    FormGroup,
    Checkbox,
    Callout,
    Card,
    NumericInput,
    HTMLSelect,
    Icon,
    Collapse,
    Switch,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useLocation } from 'react-router-dom';

import { colors } from './Map';
import {
    Itinerary,
    MapWrapper,
    RoutesTable,
    Warnings,
    RouteDetails,
    UpdateRoute,
    ItineraryItemSummary,
} from './components';
import {
    getWarnings,
    routeStatusMappings,
    checkDateTimeWarnings,
    friendlyRouteTypes,
    routeTypes,
    formatPatientForRoute,
} from './utils';
import Modal from '../../modules/modal/Modal';
import Filters from '../../modules/jobs/list/Filters';
import Loader from '../../modules/helpers/Loader';
import {
    formatDisplayDateTime,
    formatDisplayDate,
    formatDisplayTime,
    parseDateStringToJSDate,
    metresToMiles,
} from '../../modules/helpers/formatData';
import { requestRoutes, createRoutes, confirmRoutes } from '../../../api/routesApi';
import { PatientsContext } from '../../../hook/usePatientsContext';
import { ConditionalDisplay } from '../../CondtionalDisplay';
import { DateInput3 } from '@blueprintjs/datetime2';
import { isValidRouteForm } from './routesHelpers';

const moment = extendMoment(Moment);

const CreateRoutesFooter = ({ onSubmit, disabled }) => (
    <Button intent="success" large onClick={onSubmit} icon="play" disabled={disabled}>
        Generate routes
    </Button>
);

const ConfirmRoutesFooter = ({ onConfirm }) => (
    <Button intent="success" large onClick={onConfirm} icon="tick">
        Accept routes
    </Button>
);

const ConfirmedRoutesFooter = ({ onClose }) => (
    <Button large onClick={onClose} icon="cross">
        Close
    </Button>
);

const CreateRoutes = ({
    onClose,
    jobs,
    users,
    fetchSchedules,
    getUserSession,
    getAuthToken,
    setJobs,
    deselect,
    setVaccinations,
    hubs,
    allPatients,
    getUserHcpTypes,
    apiKey,
    vaccinationDuration,
    vaccinationDetails,
    hcpType,
    isSuperuser,
    doseInterval,
}) => {
    const [selectedDate, setSelectedDate] = useState(moment().add(1, 'day').toDate());
    const [startTime, setStartTime] = useState(moment().hour(9).startOf('hour').toDate());
    const [endTime, setEndTime] = useState(moment().hour(17).startOf('hour').toDate());
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [requestingRoutes, setRequestingRoutes] = useState(false);
    const [confirmingRoutes, setConfirmingRoutes] = useState(false);
    const [confirmedRoutes, setConfirmedRoutes] = useState(false);
    const [routes, setRoutes] = useState([]);
    const [capacity, setCapacity] = useState(11);
    const [hub, setHub] = useState({});
    const [multipleHubWarning, setMultipleHubWarning] = useState(false);
    const [daysSincePreviousDoseWarnings, setDaysSincePreviousDoseWarnings] = useState([]);
    const [didError, setDidError] = useState(null);
    const [smsResults, setSmsResults] = useState({});
    const [selectedRoute, setSelectedRoute] = useState(null);
    const [showPatientList, setShowPatientList] = useState(false);
    const { state } = useLocation();
    const [selectedDateTimeWarnings, setSelectedDateTimeWarnings] = useState([]);
    const [loadingWarnings, setLoadingWarnings] = useState(true);
    const [selectedDateErrorMessage, setSelectedDateErrorMessage] = useState('');

    let routeType = jobs.some(({ vaccinationCategory }) => vaccinationCategory === 'COVID-19')
        ? routeTypes.COVID
        : routeTypes.NON_COVID;

    if (state?.routeType) {
        routeType = state.routeType;
    }

    useEffect(() => {
        const { selectedDate } = state || {};
        if (selectedDate && moment(selectedDate).isAfter(moment().startOf('day'))) {
            setSelectedDate(selectedDate);
        }
    }, [state]);

    useEffect(() => {
        let multipleHubWarning = false;

        jobs.forEach((job, i) => {
            const { hubId } = job;
            const hub = hubs.find((hub) => hub.id === hubId);

            if (i === 0) {
                setHub(hub);
            }

            const { compatibleWith = [] } = hub;
            const otherHubIds = jobs
                .filter((otherJob) => otherJob.id !== job.id)
                .map((otherJob) => otherJob.hubId);

            if (otherHubIds.some((hubId) => hubId !== hub.id && !compatibleWith.includes(hubId))) {
                multipleHubWarning = true;
            }
        });

        setMultipleHubWarning(multipleHubWarning);
    }, [hubs, jobs]);

    useEffect(() => {
        const fetchWarnings = async () => {
            const warnings = await getWarnings(
                jobs,
                selectedDate,
                doseInterval,
                vaccinationDetails,
            );
            let errorMessageSelectedDate = '';
            if (!selectedDate) {
                errorMessageSelectedDate = 'Date required';
            }
            setSelectedDateErrorMessage(errorMessageSelectedDate);

            setDaysSincePreviousDoseWarnings(warnings);
            setLoadingWarnings(false);
        };

        fetchWarnings();
    }, [doseInterval, jobs, selectedDate, vaccinationDetails]);

    const onRemoveWarningPatients = () => {
        daysSincePreviousDoseWarnings.forEach(({ id }) => deselect(id));
    };

    const onToggleUser = (id, checked, event) => {
        if (event) {
            event.preventDefault();
        }

        if (checked) {
            setSelectedUsers([...selectedUsers, id]);
        } else {
            setSelectedUsers(selectedUsers.filter((userId) => userId !== id));
        }
    };

    const getUserSchedules = (userId) => {
        return [
            {
                endDateTime: moment(selectedDate).endOf('day').format(),
                startDateTime: moment(selectedDate).startOf('day').format(),
                userId,
            },
        ];
        // return schedules.filter((schedule) => {
        //     return schedule.userId === userId && isInSelectedRange(schedule);
        // });
    };

    const selectedStartDateTime = () =>
        moment(selectedDate)
            .hour(moment(startTime).hour())
            .minute(moment(startTime).minute())
            .startOf('minute');

    const selectedEndDateTime = () =>
        moment(selectedDate)
            .hour(moment(endTime).hour())
            .minute(moment(endTime).minute())
            .startOf('minute');

    const selectedDateTimeRange = () =>
        moment.range(selectedStartDateTime(), selectedEndDateTime());

    // const isInSelectedRange = ({ startDateTime, endDateTime }) =>
    //     selectedDateTimeRange().overlaps(moment.range(startDateTime, endDateTime));

    const rangeIntersect = ({ startDateTime, endDateTime }) =>
        selectedDateTimeRange().intersect(moment.range(startDateTime, endDateTime));

    const onChangeHub = (event) => setHub(hubs.find((hub) => hub.id === event.currentTarget.value));

    const hubOptions = Array.from(new Set(jobs.map((job) => job.hubId)))
        .map((hubId) => hubs.find((hub) => hub.id === hubId))
        .map((hub) => ({
            label: hub.name,
            value: hub.id,
        }));

    const isCovidRoute = jobs.some(({ vaccinationCategory }) => vaccinationCategory === 'COVID-19');

    const onSubmit = async () => {
        const hcps = selectedUsers.map((userId) => {
            const shifts = getUserSchedules(userId).map((schedule) => {
                let scheduleStartDateTime = moment(schedule.startDateTime);
                let scheduleEndDateTime = moment(schedule.endDateTime);

                if (scheduleStartDateTime.isBefore(selectedStartDateTime())) {
                    scheduleStartDateTime = selectedStartDateTime();
                }

                if (scheduleEndDateTime.isAfter(selectedEndDateTime())) {
                    scheduleEndDateTime = selectedEndDateTime();
                }

                return {
                    startTime: scheduleStartDateTime.format(),
                    endTime: scheduleEndDateTime.format(),
                    startAddress: hub.address,
                    endAddress: hub.address,
                };
            });

            return {
                userId,
                shifts,
                capacity: [isCovidRoute ? capacity : 0],
            };
        });

        let patients = jobs.reduce((patients, job) => {
            const formattedPatient = formatPatientForRoute({
                patient: job,
                selectedDate,
                vaccinationDuration,
                vaccinationDetails,
                routeType,
                jobs,
            });

            if (formattedPatient) {
                patients.push(formattedPatient);
            }

            return patients;
        }, []);

        setDidError(false);
        setRequestingRoutes(true);

        try {
            const userSession = await getUserSession();
            const response = await requestRoutes(userSession.tokens.id, {
                hcps,
                patients,
            });

            const { requestId } = response;
            let timeout = response.callbackInSeconds;

            const queryRoutes = async () => {
                const response = await new Promise((resolve) =>
                    setTimeout(async () => {
                        const response = await createRoutes(
                            userSession.tokens.id,
                            requestId,
                            isCovidRoute ? routeTypes.COVID : routeTypes.NON_COVID,
                        );
                        resolve(response);
                    }, timeout * 1000),
                );

                if (response.callbackInSeconds) {
                    timeout = response.callbackInSeconds;
                    return queryRoutes();
                }

                setRoutes(response.routes);
                setRequestingRoutes(false);
            };

            queryRoutes();
        } catch (err) {
            setRequestingRoutes(false);
            try {
                const parsedError = JSON.parse(err.message);
                setDidError(parsedError);
                if (parsedError.message !== 'Not Found') {
                    console.error(err.message);
                }
            } catch (parseError) {
                console.error(err);
            }
        }
    };

    const onConfirm = async () => {
        setConfirmingRoutes(true);

        try {
            const userSession = await getUserSession();
            const { smsResults } = await confirmRoutes(userSession.tokens.id, {
                routes: routes.map(({ id, itineraryId, itinerary }) => ({
                    id,
                    itineraryId,
                    hubId: hub.id,
                    hcpTypes: hcpType.map(({ value }) => value),
                    jobs: itinerary.instructions
                        .map(
                            ({ instructionType, itineraryItem }) =>
                                instructionType === 'VisitLocation' && itineraryItem?.name,
                        )
                        .filter((_) => _),
                })),
            });
            setSmsResults(smsResults);
            setVaccinations();
            await setJobs();
            jobs.forEach(({ id }) => deselect(id));
            setConfirmedRoutes(true);
        } catch (err) {
            console.error(err);
        }

        setConfirmingRoutes(false);
    };

    useEffect(() => {
        const warnings = checkDateTimeWarnings(selectedDate, startTime, endTime);
        setSelectedDateTimeWarnings(warnings);
    }, [selectedDate, startTime, endTime]);
    const disableSubmit = !isValidRouteForm({
        multipleHubWarning,
        selectedUsers,
        jobs,
        selectedDate,
    });
    return (
        <Modal
            title="Create routes"
            onClose={onClose}
            footer={
                requestingRoutes || confirmingRoutes ? null : confirmedRoutes ? (
                    <ConfirmedRoutesFooter onClose={onClose} />
                ) : routes.length > 0 ? (
                    <ConfirmRoutesFooter onConfirm={onConfirm} />
                ) : (
                    <CreateRoutesFooter onSubmit={onSubmit} disabled={disableSubmit} />
                )
            }
            shadow
        >
            <div className="create-routes">
                {loadingWarnings ? (
                    <Loader fullscreen={false} />
                ) : confirmedRoutes ? (
                    <>
                        <Callout
                            intent="success"
                            icon="tick-circle"
                            className="create-routes__callout"
                        >
                            Routes confirmed.
                        </Callout>
                        {(smsResults.successes?.length > 0 || smsResults.failures?.length > 0) && (
                            <>
                                <h3 className="h3">SMS messages sent to patients</h3>
                                <table className="bp5-html-table">
                                    <thead>
                                        <tr>
                                            <th>NHS number</th>
                                            <th>Name</th>
                                            <th>Contact number</th>
                                            <th>Success</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {smsResults.successes.map(
                                            ({ contactNumber, firstName, lastName, nhsNumber }) => {
                                                return (
                                                    <tr key={nhsNumber}>
                                                        <td>{nhsNumber}</td>
                                                        <td>
                                                            {firstName} {lastName}
                                                        </td>
                                                        <td>{contactNumber}</td>
                                                        <td className="create-routes__sms--sent">
                                                            <Icon icon="tick-circle" /> Sent
                                                        </td>
                                                    </tr>
                                                );
                                            },
                                        )}
                                        {smsResults.failures.map((contactNumber) => {
                                            const { nhsNumber, firstName, lastName } = jobs.find(
                                                (job) => job.contactNumber === contactNumber,
                                            );
                                            return (
                                                <tr key={nhsNumber}>
                                                    <td>{nhsNumber}</td>
                                                    <td>
                                                        {firstName} {lastName}
                                                    </td>
                                                    <td>{contactNumber}</td>
                                                    <td className="create-routes__sms--not-sent">
                                                        <Icon icon="ban-circle" /> Not sent
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </>
                ) : confirmingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">Confirming routes...</p>
                    </div>
                ) : routes.length > 0 ? (
                    <>
                        <Callout intent="success" icon="tick-circle">
                            Generated {routes.length} routes covering{' '}
                            {routes.reduce(
                                (total, route) =>
                                    (total += route.itinerary.instructions.filter(
                                        ({ instructionType }) =>
                                            instructionType === 'VisitLocation',
                                    ).length),
                                0,
                            )}{' '}
                            patients.
                        </Callout>
                        <div className="create-routes__summaries">
                            {routes.map(
                                (
                                    {
                                        id,
                                        nominatedHcps,
                                        itinerary: {
                                            instructions,
                                            route: {
                                                totalTravelDistance,
                                                totalTravelTime,
                                                startTime,
                                                endTime,
                                            },
                                        },
                                    },
                                    index,
                                ) => {
                                    const selected = id === selectedRoute;
                                    return (
                                        <Card
                                            key={id}
                                            className="create-routes__summary"
                                            style={{ borderBottomColor: colors[index] }}
                                        >
                                            <p>
                                                <strong>HCP:</strong> {nominatedHcps[0]}
                                            </p>
                                            <p>
                                                <strong>Patients:</strong>{' '}
                                                {
                                                    instructions.filter(
                                                        ({ instructionType }) =>
                                                            instructionType === 'VisitLocation',
                                                    ).length
                                                }
                                            </p>
                                            <p>
                                                <strong>Start:</strong>{' '}
                                                {formatDisplayDateTime(startTime)}
                                            </p>
                                            <p>
                                                <strong>End:</strong>{' '}
                                                {formatDisplayDateTime(endTime)}
                                            </p>
                                            <p>
                                                <strong>Total travel time:</strong>{' '}
                                                {totalTravelTime}
                                            </p>
                                            <p>
                                                <strong>Total travel distance:</strong>{' '}
                                                {metresToMiles(totalTravelDistance)}
                                            </p>
                                            <Button
                                                text={
                                                    selected ? 'Hide itinerary' : 'Show itinerary'
                                                }
                                                icon={selected ? 'cross' : 'properties'}
                                                className="create-routes__summary-btn"
                                                onClick={() =>
                                                    setSelectedRoute(selected ? null : id)
                                                }
                                            />
                                        </Card>
                                    );
                                },
                            )}
                        </div>
                        {selectedRoute ? (
                            <Itinerary
                                hub={hub}
                                getAuthToken={getAuthToken}
                                instructions={
                                    routes.find((route) => route.id === selectedRoute).itinerary
                                        .instructions
                                }
                                openInNewWindow
                            />
                        ) : (
                            <MapWrapper
                                itineraries={routes.map(({ itinerary }) => itinerary)}
                                apiKey={apiKey}
                            />
                        )}
                    </>
                ) : requestingRoutes ? (
                    <div className="vaccinations__loading">
                        <Loader fullscreen={false} />
                        <p className="create-routes__please-wait">
                            Calculating route(s). Please wait, this could take several minutes...
                        </p>
                    </div>
                ) : (
                    <>
                        {didError && (
                            <Callout intent="danger" className="create-routes__callout">
                                Route generation was unsuccessful. Reported error:{' '}
                                {didError.message}
                                {didError.details?.map((detail) => (
                                    <div key={detail}>{detail}</div>
                                ))}
                            </Callout>
                        )}
                        {jobs.length > 0 ? (
                            <div className="create-routes__patients-selected">
                                <p>
                                    <strong>Route type:</strong> {friendlyRouteTypes[routeType]}
                                </p>
                                <span className="create-routes__patients-selected-summary">
                                    <strong>{jobs.length} patients selected.</strong>
                                    <Button
                                        className="create-routes__patients-selected-button"
                                        onClick={() => setShowPatientList(!showPatientList)}
                                        icon={showPatientList ? 'eye-off' : 'eye-open'}
                                    >
                                        {showPatientList ? 'Hide' : 'Show'}
                                    </Button>
                                </span>
                                <Collapse isOpen={showPatientList}>
                                    <ul>
                                        {jobs.map(
                                            ({
                                                id,
                                                firstName,
                                                lastName,
                                                nhsNumber,
                                                dateOfBirth,
                                                vaccinationCategory,
                                                practice,
                                                odsCode,
                                            }) => (
                                                <li key={id}>
                                                    <ItineraryItemSummary
                                                        id={id}
                                                        firstName={firstName}
                                                        lastName={lastName}
                                                        nhsNumber={nhsNumber}
                                                        dateOfBirth={dateOfBirth}
                                                        vaccinationCategory={vaccinationCategory}
                                                        practice={practice}
                                                        odsCode={odsCode}
                                                        openInNewWindow
                                                    />
                                                </li>
                                            ),
                                        )}
                                    </ul>
                                </Collapse>
                            </div>
                        ) : (
                            <Callout intent="danger" className="create-routes__callout">
                                There are no patients selected, please select at least one patient
                                and try again.
                            </Callout>
                        )}
                        <h3 className="h3">Select route options</h3>
                        {daysSincePreviousDoseWarnings.length > 0 && (
                            <Callout intent="danger" className="create-routes__callout">
                                The following patients have validation warnings, please see below
                                for details.
                                <Warnings warnings={daysSincePreviousDoseWarnings} />
                                <Button
                                    text="Remove affected patient(s) from selection"
                                    onClick={onRemoveWarningPatients}
                                    outlined
                                />
                            </Callout>
                        )}
                        {multipleHubWarning && (
                            <Callout intent="danger" className="create-routes__callout">
                                The selected patients belong multiple hubs that cannot be combined
                                into a single route. Please refine your selection and try again.
                            </Callout>
                        )}
                        <div className="create-routes__select-date-time">
                            <div className="create-routes__date-time-controls">
                                <FormGroup label="Date" labelFor="date">
                                    <DateInput3
                                        inputProps={{ id: 'date' }}
                                        formatDate={formatDisplayDate}
                                        parseDate={parseDateStringToJSDate}
                                        onChange={(date) => {
                                            if (date) {
                                                date = new Date(date);
                                            }
                                            setSelectedDate(date);
                                        }}
                                        minDate={isSuperuser ? undefined : new Date()}
                                        maxDate={moment().add(1, 'year').toDate()}
                                        value={selectedDate?.toISOString()}
                                    />
                                </FormGroup>
                                <FormGroup label="Start time">
                                    <TimePicker
                                        onChange={setStartTime}
                                        value={startTime}
                                        selectAllOnFocus
                                        maxTime={endTime}
                                    />
                                </FormGroup>
                                <FormGroup label="End time">
                                    <TimePicker
                                        onChange={setEndTime}
                                        value={endTime}
                                        minTime={startTime}
                                        selectAllOnFocus
                                    />
                                </FormGroup>
                                {isCovidRoute && (
                                    <FormGroup label="Max. doses *">
                                        <NumericInput
                                            className="create-routes__numeric-input"
                                            onValueChange={setCapacity}
                                            value={capacity}
                                            min={1}
                                            selectAllOnFocus
                                            large
                                        />
                                    </FormGroup>
                                )}
                                <FormGroup label="Start/end location">
                                    <HTMLSelect
                                        onChange={onChangeHub}
                                        value={hub.id}
                                        large
                                        options={hubOptions}
                                        disabled={multipleHubWarning}
                                    />
                                </FormGroup>
                            </div>
                            <div className="form-error-message">{selectedDateErrorMessage}</div>
                            {isCovidRoute && (
                                <span>
                                    * Applies only to COVID-19 vaccines, this is usually based on
                                    vial size
                                </span>
                            )}
                        </div>
                        <h3 className="h3">Select HCPs</h3>
                        <table className="bp5-html-table users-table bp5-interactive">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Name</th>
                                    <th>User ID</th>
                                    <th>Role(s)</th>
                                    <th>Availability</th>
                                    <th>Shifts</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users
                                    .filter((user) => user.enabled && user.roles?.includes('hcp'))
                                    .map(({ userId, userName, hcpTypes }) => {
                                        const schedulesOnSelectedDate = getUserSchedules(userId);

                                        if (schedulesOnSelectedDate.length === 0) return null;

                                        const shifts = schedulesOnSelectedDate
                                            .map(
                                                (schedule) =>
                                                    `${formatDisplayTime(
                                                        schedule.startDateTime,
                                                    )} to ${formatDisplayTime(
                                                        schedule.endDateTime,
                                                    )}`,
                                            )
                                            .join(', ');

                                        const availabilityMilliseconds = schedulesOnSelectedDate.reduce(
                                            (mins, schedule) =>
                                                (mins += rangeIntersect(schedule)?.valueOf()),
                                            0,
                                        );

                                        const availabilityMins = moment
                                            .duration(availabilityMilliseconds)
                                            .asMinutes();

                                        const hours = Math.floor(availabilityMins / 60);
                                        const mins = Math.floor(availabilityMins % 60);

                                        const selected = selectedUsers.includes(userId);
                                        const toggleSelected = (event) =>
                                            onToggleUser(userId, !selected, event);

                                        const formattedHcpTypes = getUserHcpTypes(userId);

                                        return (
                                            <tr
                                                key={userId}
                                                className={`users-table__row ${
                                                    selected ? 'users-table__row--selected' : ''
                                                }`}
                                                onClick={toggleSelected}
                                            >
                                                <td>
                                                    <Checkbox
                                                        checked={selected}
                                                        onChange={toggleSelected}
                                                    />
                                                </td>
                                                <td>{userName}</td>
                                                <td>{userId}</td>
                                                <td>
                                                    {formattedHcpTypes.length > 0
                                                        ? formattedHcpTypes.join(', ')
                                                        : '-'}
                                                </td>
                                                <td>
                                                    {hours} {hours === 1 ? 'hour' : 'hours'}
                                                    {mins > 0 && <>, {mins} mins</>}
                                                </td>
                                                <td>{shifts}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {selectedDateTimeWarnings.length > 0 && (
                            <Callout intent="warning" className="create-routes__callout">
                                {selectedDateTimeWarnings.map((warning) => (
                                    <li key={warning}>{warning}</li>
                                ))}
                            </Callout>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};

const filterOptions = [
    {
        name: 'jobStatus',
        label: 'Status',
        options: [
            'AVAILABLE',
            'ACCEPTED',
            'CURRENT',
            'ARRIVED',
            'COMPLETED',
            'HCP_ABORTED',
            'WITHDRAWN',
        ],
        labelTransform: (option) => routeStatusMappings[option],
    },
];

const Routes = inject('RootStore')(
    observer(
        class Routes extends Component {
            componentDidMount() {
                const {
                    RootStore: { routesStore },
                    location: { search },
                } = this.props;

                const query = new URLSearchParams(search);
                const page = query.get('page');

                if (page) {
                    const pageNumber = parseInt(page, 10) - 1;

                    if (pageNumber < 0) {
                        return this._setPage(0);
                    }

                    routesStore.setPage(pageNumber);

                    when(
                        () => routesStore.isLoadingJobs === false,
                        () => {
                            if (pageNumber >= routesStore.pageCount) {
                                this._setPage(routesStore.pageCount - 1);
                            }
                        },
                    );
                }
            }

            componentDidUpdate() {
                const {
                    RootStore: { routesStore },
                    location: { search },
                } = this.props;

                const query = new URLSearchParams(search);
                const page = query.get('page');

                if (page) {
                    const pageNumber = parseInt(page, 10) - 1;

                    if (!routesStore.loading && pageNumber >= routesStore.pageCount) {
                        this._setPage(routesStore.pageCount - 1);
                    }
                }
            }

            _setPage = (page) => {
                const {
                    RootStore: {
                        routesStore: { setPage },
                    },
                    location: { pathname },
                    history: { replace },
                } = this.props;

                replace(`${pathname}?page=${page + 1}`);
                setPage(page);
            };

            _handleShowRouteDetails = (id) => {
                this.props.history.push(`/vaccinations/routes/${id}`);
            };

            _handleCloseModal = () => {
                if (this.props.location.state && this.props.location.state.closeModal) {
                    return this.props.history.goBack();
                }

                this.props.history.push('/vaccinations/routes');
            };

            _handleSort = (sortFunc) => {
                this.props.RootStore.routesStore.setSortFn(sortFunc);
            };

            render() {
                const {
                    RootStore: {
                        routesStore: {
                            pagedJobs,
                            pageStart,
                            pageEnd,
                            page,
                            pageCount,
                            filters,
                            setFilters,
                            loading,
                            totalJobsNumber,
                            setJobs,
                            abortJob,
                            allJobs,
                            editJob,
                            showAbortedByController,
                            toggleShowAbortedByController,
                        },
                        usersStore: { hcpUsers, getUserHcpTypes },
                        userStore: { getUserSession, isSuperuser, getAuthToken },
                        schedulesStore: { allSchedules, fetchSchedules },
                        configStore: {
                            hubs,
                            clientKeys,
                            vaccinationDuration,
                            vaccinationDetails,
                            doseInterval,
                        },
                        lovsStore: { hcpType },
                    },
                    match: {
                        params: { jobId },
                    },
                    location: { pathname },
                    history,
                } = this.props;

                const showUpdateRoute =
                    pathname.endsWith('/add-to-existing') ||
                    pathname.endsWith('/withdraw-from-existing') ||
                    pathname.endsWith('/recalculate');

                const showCreateRoutes = pathname.endsWith('/new');
                const showRouteDetails = jobId && !pathname.endsWith('/recalculate');
                return (
                    <>
                        <nav className="vaccinations__navigation">
                            {!loading && (
                                <>
                                    Showing routes {totalJobsNumber > 0 ? pageStart + 1 : 0} -{' '}
                                    {pageEnd < totalJobsNumber ? pageEnd : totalJobsNumber} of{' '}
                                    {totalJobsNumber}
                                    <ButtonGroup className="vaccinations__pager" minimal>
                                        <Button
                                            icon="chevron-left"
                                            text="Previous"
                                            disabled={page === 0}
                                            intent="primary"
                                            onClick={() => this._setPage(page - 1)}
                                        />
                                        <Button
                                            rightIcon="chevron-right"
                                            text="Next"
                                            disabled={page === pageCount - 1}
                                            intent="primary"
                                            onClick={() => this._setPage(page + 1)}
                                        />
                                    </ButtonGroup>
                                </>
                            )}
                        </nav>
                        <aside className="vaccinations__tools">
                            <h3 className="h3">Filters</h3>
                            <Filters
                                filterOptions={filterOptions}
                                filters={filters}
                                setFilters={setFilters}
                            />
                            <h3 className="h3">Mode</h3>
                            <Switch
                                checked={showAbortedByController}
                                onChange={({ currentTarget }) => {
                                    toggleShowAbortedByController();
                                }}
                                alignIndicator="right"
                                className={showAbortedByController ? '' : 'filter__label--any'}
                            >
                                Controller aborted routes
                            </Switch>
                        </aside>
                        <main className="vaccinations__main">
                            {loading ? (
                                <Loader fullscreen={false} />
                            ) : (
                                <RoutesTable
                                    jobs={pagedJobs}
                                    handleOnClick={this._handleShowRouteDetails}
                                    hubs={hubs}
                                    onSort={this._handleSort}
                                />
                            )}
                        </main>
                        <PatientsContext.Consumer>
                            {({
                                getPatients: { refetch: setVaccinations },
                                patients: allPatients,
                                selectedPatients: selectedJobs,
                                select,
                                deselect,
                                deselectAll,
                            }) => (
                                <>
                                    <ConditionalDisplay show={showCreateRoutes}>
                                        <div className="vaccinations__modal">
                                            <CreateRoutes
                                                onClose={this._handleCloseModal}
                                                jobs={selectedJobs}
                                                users={hcpUsers}
                                                schedules={allSchedules}
                                                fetchSchedules={fetchSchedules}
                                                getUserSession={getUserSession}
                                                getAuthToken={getAuthToken}
                                                setJobs={setJobs}
                                                history={history}
                                                deselect={deselect}
                                                setVaccinations={setVaccinations}
                                                hubs={hubs}
                                                allPatients={allPatients}
                                                getUserHcpTypes={getUserHcpTypes}
                                                apiKey={clientKeys.googleMaps}
                                                vaccinationDuration={vaccinationDuration}
                                                vaccinationDetails={vaccinationDetails}
                                                hcpType={hcpType}
                                                isSuperuser={isSuperuser}
                                                doseInterval={doseInterval}
                                            />
                                        </div>
                                    </ConditionalDisplay>
                                    <ConditionalDisplay show={showUpdateRoute}>
                                        <div className="vaccinations__modal">
                                            {/* VSU-2078 Looks like you cannot remove/withdraw a patient and recalculate
                                            is that because you must first remove/withdraw a patient and then trigger a separate recalculate
                                            or are they just mutually exclusive?  UpdateRoute does not seem to trigger a recalculate after a remove/withdraw */}
                                            <UpdateRoute
                                                onClose={this._handleCloseModal}
                                                patientsToAdd={selectedJobs}
                                                routes={allJobs}
                                                hubs={hubs}
                                                getUserSession={getUserSession}
                                                history={history}
                                                setJobs={setJobs}
                                                deselect={deselect}
                                                setVaccinations={setVaccinations}
                                                editJob={editJob}
                                                allPatients={allPatients}
                                                addPatients={pathname.endsWith('/add-to-existing')}
                                                onSort={this._handleSort}
                                                recalculateRoute={pathname.endsWith('/recalculate')}
                                                apiKey={clientKeys.googleMaps}
                                                vaccinationDuration={vaccinationDuration}
                                                hcpType={hcpType}
                                                vaccinationDetails={vaccinationDetails}
                                                doseInterval={doseInterval}
                                            />
                                        </div>
                                    </ConditionalDisplay>
                                    <ConditionalDisplay show={showRouteDetails}>
                                        <div className="vaccinations__modal">
                                            <RouteDetails
                                                loading={loading}
                                                onClose={this._handleCloseModal}
                                                route={null}
                                                getUserSession={getUserSession}
                                                getAuthToken={getAuthToken}
                                                abortJob={abortJob}
                                                setJobs={setJobs}
                                                setVaccinations={setVaccinations}
                                                hubs={hubs}
                                                editJob={editJob}
                                                users={hcpUsers}
                                                jobId={jobId}
                                                allPatients={allPatients}
                                                select={select}
                                                deselectAll={deselectAll}
                                                apiKey={clientKeys.googleMaps}
                                                isSuperuser={isSuperuser}
                                            />
                                        </div>
                                    </ConditionalDisplay>
                                </>
                            )}
                        </PatientsContext.Consumer>
                    </>
                );
            }
        },
    ),
);

export default Routes;
