import { FC, useEffect } from 'react';
import { Form, FormikValues, useFormikContext, FormikContextType } from 'formik';
import { JobStatus, Patient } from '@doc-abode/data-models';

import { Select, TextArea } from '../../../../v2/form';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import useStores from '../../../../../hook/useStores';
import { IHcp } from '../../../../../interfaces/ucr';

const defaultOption = {
    label: 'Select option',
    value: '',
    disabled: true,
};

interface IProps {
    visit: Patient;
    loading: boolean;
    onClose: () => void;
}

const AbortDoubleVisitForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        RootStore: {
            lovsStore: { controllerSingleVisitAbortReason, controllerAbortReason },
            ucrStore: { hcps },
        },
    } = useStores() as { RootStore: any };

    const { values, setFieldValue }: FormikContextType<FormikValues> = useFormikContext();
    const isJobStatusCompleted = visit.jobStatus === JobStatus.COMPLETED;
    const isBuddyJobStatusCompleted = visit.buddyJobStatus === JobStatus.COMPLETED;
    const isJobStatusAborted =
        visit.jobStatus === JobStatus.CONTROLLER_ABORTED ||
        visit.jobStatus === JobStatus.HCP_ABORTED;

    const isBuddyJobStatusAborted =
        visit.buddyJobStatus === JobStatus.CONTROLLER_ABORTED ||
        visit.buddyJobStatus === JobStatus.HCP_ABORTED;

    const user1 = hcps.find((hcp: IHcp) => hcp.userId === visit.hcpId);
    const user2 = hcps.find((hcp: IHcp) => hcp.userId === visit.buddyId);

    const reasonsForAborting = [
        defaultOption,
        ...((values.cancelationOption === 'user1' && !isBuddyJobStatusAborted) ||
        (values.cancelationOption === 'user2' && !isJobStatusAborted)
            ? controllerSingleVisitAbortReason
            : controllerAbortReason),
    ];

    useEffect(() => {
        setFieldValue('controllerAbortedReason', '');
    }, [values.cancelationOption, setFieldValue]);

    const isControllerAbortedReasonDisabled = values.cancelationOption === '';
    const isConfirmAbortVisitDisabled =
        loading || values.cancelationOption === '' || values.controllerAbortedReason === '';

    const labelUser1 = `#1 ${user1 && `- ${user1.firstName} ${user1.lastName}`} - ${
        visit.friendlyVisitStatus
    }`;

    const labelUser2 = `#2 ${
        user2 && `- ${user2.firstName} ${user2.lastName}`
    } - ${Patient.getFriendlyVisitStatus(visit.buddyJobStatus as JobStatus)}`;

    return (
        <Form>
            <div className="v2__dialog-block">
                <p>This is a double-up visit. Please select cancelation option</p>
                <p>{labelUser1}</p>
                <p>{labelUser2}</p>
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Cancelation option"
                    name="cancelationOption"
                    options={[
                        defaultOption,
                        {
                            label: labelUser1,
                            value: 'user1',
                            disabled: isJobStatusCompleted || isJobStatusAborted,
                        },
                        {
                            label: labelUser2,
                            value: 'user2',
                            disabled: isBuddyJobStatusCompleted || isBuddyJobStatusAborted,
                        },
                        {
                            label: 'All visits',
                            value: 'all',
                            disabled:
                                isJobStatusCompleted ||
                                isBuddyJobStatusCompleted ||
                                isJobStatusAborted ||
                                isBuddyJobStatusAborted,
                        },
                    ]}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for aborting"
                    name="controllerAbortedReason"
                    options={reasonsForAborting}
                    disabled={isControllerAbortedReasonDisabled}
                    required
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea
                    label="Notes"
                    name="controllerAbortedNotes"
                    className="v2__form-group--pos-1-1"
                />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--left">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm abort visit"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={isConfirmAbortVisitDisabled}
                />
            </div>
        </Form>
    );
};

export default AbortDoubleVisitForm;
