import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import CustomAgenda from './AgendaView';
import { Tag } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

const localizer = momentLocalizer(moment);

function AgendaItem({ event: job }) {
    return (
        <>
            <td className="rbc-agenda-event-cell">
                <span className="agenda">
                    <Link
                        className="agenda__link"
                        to={{
                            pathname: `/scheduled/jobs/${job.id}`,
                            state: { closeModal: true },
                        }}
                    >
                        {job.systmOneRef} - {job.title}
                    </Link>
                    {job.flags.map((flag) => (
                        <Tag className="agenda__flag" minimal key={flag}>
                            {flag}
                        </Tag>
                    ))}
                </span>
            </td>
            <td className="rbc-agenda-hcp-accepted-cell">
                {job.hcpId && <span>{job.hcpId}</span>}
            </td>
            <td className="rbc-agenda-hcp-type-cell">{job.hcpType.join(', ')}</td>
            <td className="rbc-agenda-status-cell">
                <Tag className={`agenda__status ${job.jobStatus.toLowerCase()}-bg`}>
                    {job.jobStatus}
                </Tag>
            </td>
        </>
    );
}

const Calendar = inject('RootStore')(
    observer(
        class Calendar extends Component {
            constructor(props) {
                super(props);
                this.state = {
                    agendaLength: 30,
                };
            }

            _handleSelectEvent = (job) =>
                this.props.history.push({
                    pathname: `/scheduled/jobs/${job.id}`,
                    state: { closeModal: true },
                });

            _handleLengthChange = (len) => this.setState({ agendaLength: len });

            _handleViewChange = (view) => this.props.history.push(`/scheduled/calendar/${view}`);

            _eventPropGetter = (job) => ({
                className: `rbc-event--${job.displayStatus.toLowerCase()} ${
                    job.fadeDisplay ? 'rbc-event--faded' : ''
                }`,
            });

            render() {
                const {
                    RootStore: {
                        jobsStore: { events },
                    },
                    match: {
                        params: { view },
                    },
                } = this.props;

                const formats = {
                    agendaHeaderFormat: ({ start, end }, culture, localizer) =>
                        localizer.format(start, 'DD/MM/YYYY', culture) +
                        ' — ' +
                        localizer.format(end, 'DD/MM/YYYY', culture),
                    eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
                        let s = localizer.format(start, 'HH:mm', culture);
                        let e = localizer.format(end, 'HH:mm', culture);
                        return `${s} - ${e}`;
                    },
                    agendaTimeRangeFormat: ({ start, end }, culture, localizer) => {
                        let s = localizer.format(start, 'HH:mm', culture);
                        let e = localizer.format(end, 'HH:mm', culture);
                        return `${s} - ${e}`;
                    },
                    agendaTimeFormat: (date, culture, localizer) =>
                        localizer.format(date, 'HH:mm', culture),
                    timeGutterFormat: (date, culture, localizer) =>
                        localizer.format(date, 'HH:mm', culture),
                };

                return (
                    <div className="calendar">
                        <BigCalendar
                            localizer={localizer}
                            view={view}
                            views={{
                                month: true,
                                week: true,
                                day: true,
                                agenda: CustomAgenda,
                            }}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            length={this.state.agendaLength}
                            onSelectEvent={this._handleSelectEvent}
                            eventPropGetter={this._eventPropGetter}
                            onView={this._handleViewChange}
                            scrollToTime={new Date(1970, 1, 1, 8)}
                            formats={formats}
                            components={{
                                agenda: {
                                    event: AgendaItem,
                                },
                            }}
                            handleLengthChange={this._handleLengthChange}
                        />
                    </div>
                );
            }
        },
    ),
);

export default Calendar;
