import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, from } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { hasDirectives, removeDirectivesFromDocument } from '@apollo/client/utilities';

import Header from './Header';
import OnDemand from './OnDemand';
import Scheduled from './Scheduled';
import Vaccinations from './Vaccinations';
import UCR from './UCR';
import Users from '../../pages/Users';
import PageNotFound from '../../pages/PageNotFound';
import Loader from '../../modules/helpers/Loader';
import { graphqlUrl, graphqlV2Url } from '../../../api/baseApi';
import { getBearerToken } from '../../../api/amplifyApi';
import { getDirectiveArgumentValueFromOperation } from '../../../graphql/helper';

const createApolloClient = (organisation, httpLink) => {
    const apiVersionLink = new ApolloLink(async (operation, forward) => {
        let tokenType = 'access';

        if (hasDirectives(['api'], operation.query)) {
            let apiVersion = getDirectiveArgumentValueFromOperation(operation, 'api', 'version');
            const query = removeDirectivesFromDocument(
                [{ name: 'api', remove: true }],
                operation.query,
            );
            operation.query = query;
            operation.setContext({
                uri: apiVersion === 'v2' ? graphqlV2Url : graphqlUrl,
            });
            if (apiVersion === 'v2') {
                tokenType = 'id';
            }
        }

        const Authorization = await getBearerToken({ tokenType });

        operation.setContext({
            headers: {
                ...operation.getContext().headers,
                Authorization,
                'x-docabode-organisation': organisation,
            },
        });

        return forward(operation);
    });

    return new ApolloClient({
        link: from([apiVersionLink, httpLink]),
        cache: new InMemoryCache(),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });
};

export const routes = [
    { path: '/on-demand', component: OnDemand },
    { path: '/scheduled', component: Scheduled },
    { path: '/vaccinations', component: Vaccinations },
    { path: '/scheduling', component: UCR },
    { path: '/users/:userId?', component: Users, exact: true },
];

const Router = ({ homePage }) => {
    return (
        <div className="controller">
            <Route path="/:section" component={Header} />
            <Switch>
                <Redirect exact from="/" to={homePage} />
                <Redirect from="/ucr" to="/scheduling" />
                {routes.map(({ path, component, exact }) => (
                    <Route key={path} path={path} component={component} exact={exact} />
                ))}
                <Route component={PageNotFound} />
            </Switch>
        </div>
    );
};

const ControllerMain = inject('RootStore')(
    observer(
        class ControllerMain extends Component {
            state = {
                apolloClient: null,
            };

            componentDidMount() {
                const {
                    configStore,
                    jobsStore,
                    routesStore,
                    ucrStore,
                    schedulesStore,
                } = this.props.RootStore;

                when(
                    () => configStore.loaded,
                    () => {
                        if (
                            configStore.isFeatureEnabled('vaccinations') ||
                            configStore.isFeatureEnabled('ucr')
                        ) {
                            const httpLink = createHttpLink({
                                uri: graphqlUrl,
                            });

                            const apolloClient = createApolloClient(configStore.org, httpLink);

                            this.setState({ apolloClient });
                        }

                        if (
                            configStore.isFeatureEnabled('onDemandJobs') ||
                            configStore.isFeatureEnabled('scheduledJobs')
                        ) {
                            jobsStore.startTimer();
                        }
                        if (configStore.isFeatureEnabled('ucr')) {
                            ucrStore.startTimer();
                            schedulesStore.startTimer();
                        }
                        if (configStore.isFeatureEnabled('vaccinations')) {
                            routesStore.startTimer();
                        }
                    },
                );
            }

            componentWillUnmount() {
                const {
                    usersStore,
                    jobsStore,
                    vaccinationsStore,
                    routesStore,
                    ucrStore,
                    schedulesStore,
                } = this.props.RootStore;

                usersStore.dispose();
                ucrStore.dispose();
                jobsStore.dispose();
                vaccinationsStore.dispose();
                routesStore.dispose();
                schedulesStore.dispose();
            }

            render() {
                const {
                    configStore: { loaded, isFeatureEnabled },
                } = this.props.RootStore;

                const { apolloClient } = this.state;

                if (!loaded) {
                    return (
                        <div>
                            <Loader />
                        </div>
                    );
                }

                let homePage;

                if (isFeatureEnabled('ucr')) {
                    homePage = '/scheduling';
                } else if (isFeatureEnabled('vaccinations')) {
                    homePage = '/vaccinations';
                } else if (isFeatureEnabled('onDemandJobs')) {
                    homePage = '/on-demand';
                } else if (isFeatureEnabled('scheduledJobs')) {
                    homePage = '/scheduled';
                } else {
                    homePage = '/users';
                }

                if (apolloClient) {
                    return (
                        <ApolloProvider client={apolloClient}>
                            <Router props={this.props} homePage={homePage} />
                        </ApolloProvider>
                    );
                }

                return <Router props={this.props} homePage={homePage} />;
            }
        },
    ),
);

export default ControllerMain;
