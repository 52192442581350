// @ts-check

import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Icon, Callout } from '@blueprintjs/core';
import { OnboardingUser } from '@doc-abode/data-models';

import UserDetails from '../modules/users/detail/UserDetails';
import Filters from '../modules/jobs/list/Filters';

const { hcpTypes } = OnboardingUser.lookups;

const User = ({ available, userId, userName, hcpTypes, maxDriveTimeMinutes, onClick }) => {
    const notificationsEnabled = available ? 'on' : 'off';

    return (
        <tr onClick={() => onClick(userId)}>
            <td>{userName}</td>
            <td>{hcpTypes.length > 0 ? hcpTypes.join(', ') : '-'}</td>
            <td>
                <span
                    className={`users-table__notifications users-table__notifications--${notificationsEnabled}`}
                >
                    <Icon icon={available ? 'tick-circle' : 'ban-circle'} />
                    <span className="users-table__notifications-label">{notificationsEnabled}</span>
                </span>
            </td>
            <td>{maxDriveTimeMinutes ? `${maxDriveTimeMinutes} mins` : '-'}</td>
        </tr>
    );
};

const filterOptions = [
    {
        name: 'hcpType',
        label: 'Role',
        options: hcpTypes.map(({ value }) => value),
        labelTransform: (option) => hcpTypes.find(({ value }) => value === option)?.label,
    },
];

const Users = inject('RootStore')(
    observer(
        class Users extends Component {
            _handleUserClick = (userId) => {
                this.props.history.push(`/users/${userId}`);
            };

            render() {
                const {
                    RootStore: {
                        usersStore: { filteredUsers, filters, setFilters, getUserHcpTypes },
                    },
                } = this.props;

                const { userId } = this.props.match.params;

                return (
                    <section className="users">
                        <aside className="users__tools">
                            <h3 className="h3">Filters</h3>
                            <Filters
                                filterOptions={filterOptions}
                                filters={filters}
                                setFilters={setFilters}
                            />
                        </aside>
                        <main className="users__main">
                            <Callout intent="primary" className="users__callout">
                                HCPs with notifications set to <strong>off</strong> will still be
                                able to view and accept jobs via the jobs list in the mobile app,
                                but will not be notified when new jobs are added.
                            </Callout>
                            <table className="bp5-html-table bp5-interactive users-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Role(s)</th>
                                        <th>Notifications</th>
                                        <th>Maximum drive time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredUsers
                                        .slice()
                                        .sort((user) => (user.available ? -1 : 1))
                                        .map((user) => (
                                            <User
                                                {...user}
                                                hcpTypes={getUserHcpTypes(user.userId)}
                                                onClick={this._handleUserClick}
                                                key={user.userId}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </main>
                        {userId && (
                            <div className="users__modal">
                                <UserDetails {...this.props} />
                            </div>
                        )}
                    </section>
                );
            }
        },
    ),
);

export default Users;
