import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

const ArchivePersonStaffConfirmationPopUp = (props: any) => {
    const {
        isOpen,
        titleText = 'Confirm',
        bodyText = 'Do you want to save the changes made to this record?',
        onCancel,
        onSubmit,
    } = props;

    return (
        <Dialog isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <h4>{titleText}</h4>
                <p>{bodyText}</p>
            </div>
            <div className={`${Classes.DIALOG_FOOTER}`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type="submit" intent={Intent.WARNING} onClick={onSubmit}>
                        Archive
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ArchivePersonStaffConfirmationPopUp;
