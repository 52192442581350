import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _, { isEmpty } from 'lodash';
import {
    CREATE_FUNCTION,
    UPDATE_FUNCTION,
    DELETE_FUNCTION,
    GET_FUNCTION_BY_ID,
    GET_FUNCTIONS_LIST,
    BULK_DELETE_FUNCTIONS,
    GET_FUNCTIONS_BY_DEPARTMENT_AND_ROLE,
    GET_FUNCTION_BY_NAME,
    VALIDATE_ARCHIVE_FUNCTIONS,
    ARCHIVE_FUNCTION,
    BULK_ARCHIVE_FUNCTIONS,
} from '../graphql/queries/functions';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';
import { GetFunctions_getFunctions_edges_node } from '../__generated__/GetFunctions';

export interface Functions extends GetFunctions_getFunctions_edges_node {
    selected?: boolean;
    categories: Array<string>;
}

class FunctionsStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            functions: observable,
            allFunctions: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridFunctions: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getFunctions: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    functions = [];
    allFunctions = [];
    allFunctionsActive = [];
    allFunctionsActiveOptions: any;
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];
    validatedArchiveFunctions: any;
    allArchivableSelectedFunctions: any;
    noRelatedShiftsButRolesInFunctions: any;
    someRelatedShiftsButRolesInFunctions: any;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeFunctions = (functionEntity: Functions) => ({
        id: functionEntity.id,
        selected: this.selected.includes(functionEntity.id),
        name: functionEntity.name,
        createdAt: functionEntity.createdAt,
        updatedAt: functionEntity.updatedAt,
        createdBy: functionEntity.createdBy,
        updatedBy: functionEntity.updatedBy,
        isArchived: functionEntity.isArchived,
        isDeleted: functionEntity.isDeleted,
        createdPerson: !isEmpty(functionEntity.createdPerson) ? functionEntity.createdPerson : '',
        updatedPerson: !isEmpty(functionEntity.updatedPerson) ? functionEntity.updatedPerson : '',
    });

    get gridFunctions() {
        const functions = this.functions.map(this.normalizeFunctions);
        if (this.sortFn) {
            functions.sort(this.sortFn);
        }
        return functions;
    }

    get allSelected() {
        return this.gridFunctions.every((r) => this.selected.includes(r.id));
    }

    async createFunction(functionEntity: Functions) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_FUNCTION,
                variables: {
                    data: {
                        name: functionEntity.name,
                        isArchived: false,
                    },
                },
            });
            AppToaster.show({
                message: 'The function has been created!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating function record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteFunction(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_FUNCTION,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Function deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting function record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateFunction(functionEntity: Functions) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_FUNCTION,
                variables: {
                    id: functionEntity.id,
                    data: {
                        name: functionEntity.name,
                        isArchived: functionEntity.isArchived,
                    },
                },
            });
            AppToaster.show({
                message: 'Function updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating function record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (functionId: string) => {
        if (!this.selected.includes(functionId)) {
            this.selected.push(functionId);
        }
    };

    deselect = (functionId: string) => {
        const index = this.selected.indexOf(functionId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getFunctions();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getFunctions();
    };

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText: any) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getFunctions();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getFunctions();
    };

    getFunctions = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getFunctions: data },
        } = await this.apolloClient.query({
            query: GET_FUNCTIONS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.functions = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllFunctions = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getFunctions: data },
        } = await this.apolloClient.query({
            query: GET_FUNCTIONS_LIST,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
            },
        });

        runInAction(() => {
            this.allFunctions = data.edges.map((x: any) => x.node);
            this.allFunctionsActive = data.edges
                .map((x: any) => x.node)
                ?.filter((item: any) => !item.isArchived);
            this.allFunctionsActiveOptions = this.allFunctionsActive.map((item: any) => ({
                value: item.id,
                label: item.name,
            }));
        });
    };

    getFunctionsByDepartmentAndRole = async (departmentId: string, roleId?: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getFunctionsByDepartmentAndRole },
        } = await this.apolloClient.query({
            query: GET_FUNCTIONS_BY_DEPARTMENT_AND_ROLE,
            variables: {
                departmentId,
                roleId,
            },
        });

        return getFunctionsByDepartmentAndRole.map((x: any) => ({
            label: x.name,
            value: x.id,
            isArchived: x.isArchived,
        }));
    };

    getFunctionById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getFunctionById: data },
        } = await this.apolloClient.query({
            query: GET_FUNCTION_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeFunctions(data);
    };

    async validateFunctionName(name: string) {
        return await this.apolloClient.query({
            query: GET_FUNCTION_BY_NAME,
            variables: {
                name,
            },
        });
    }

    async validateArchiveFunctions(ids: any) {
        return await this.apolloClient.query({
            query: VALIDATE_ARCHIVE_FUNCTIONS,
            variables: {
                ids,
            },
        });
    }

    async archiveFunction(functionItem: any) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_FUNCTION,
                variables: {
                    id: functionItem.id,
                },
            });
            AppToaster.show({
                message: 'Function archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving function record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async validateArchiveSelectedFunctions() {
        const {
            data: { isFunctionArchivable },
        } = await this.validateArchiveFunctions(this.selected);
        runInAction(() => {
            this.validatedArchiveFunctions = isFunctionArchivable;
            const functionWithSomeRole = isFunctionArchivable.some((func: any) => func.relatedRole);
            const functionWithNoShifts = isFunctionArchivable.every(
                (func: any) => !func.relatedRota && !func.relatedShift,
            );
            this.noRelatedShiftsButRolesInFunctions = functionWithSomeRole && functionWithNoShifts;
            this.someRelatedShiftsButRolesInFunctions = isFunctionArchivable.some(
                (func: any) => !func.relatedRota && !func.relatedShift,
            );
            this.allArchivableSelectedFunctions = isFunctionArchivable?.every(
                (func: any) => func.isArchivable,
            );
        });
    }

    selectAll = () => {
        runInAction(() => {
            this.gridFunctions.forEach((func) => {
                !func.isArchived && this.select(func.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async bulkDeleteFunctions(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_FUNCTIONS,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'All selected functions deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected functions.Please try again or
                        contact<a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async bulkArchiveFunctions(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_FUNCTIONS,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'All selected functions archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving selected functions.Please try again or
                        contact<a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default FunctionsStore;
