import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';
import Select, { components } from 'react-select';
import AppToaster from '../../../modules/helpers/Toaster';

import Error from '../../../modules/forms/Error';
import { compareByLabels } from '../../../modules/helpers/sortFunctions';

interface DepartmentSummaryProps {
    editOrCreateMode: boolean;
    values: FormikValues;
    handleChange: () => void;
    setFieldValue: (key: string, value: any) => void;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    item: any;
    roles: any;
    persons: any;
    costCentres: any;
    locations: any;
    departmentNames: any;
    onInputChange: any;
    actionType: string;
}

const DepartmentSummary = ({
    editOrCreateMode,
    item,
    errors,
    handleChange,
    setFieldValue,
    touched,
    values,
    roles,
    costCentres,
    locations,
    departmentNames,
    actionType,
}: DepartmentSummaryProps) => {
    const handleOnChangeRolesLocations = async (e: any, option: any) => {
        setFieldValue(option.name, e ?? []);

        if (actionType !== 'create' && option.action === 'remove-value') {
            const optionName = option.name === 'roles' ? 'role' : 'location';
            AppToaster.show({
                message: `The ${optionName} is removed`,
                intent: 'success',
            });
        }
    };

    const MultiValueRemove = (props: any) => {
        if (props.data.isFixed) {
            return null;
        }
        return <components.MultiValueRemove {...props} />;
    };
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'departments'}
                componentPath={'/hr/departments/'}
                items={departmentNames}
                editOrCreateMode={editOrCreateMode}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                className={'departments_suggest'}
            />
            <dl className="info">
                <dt className="info__title">Cost code *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="costCode">
                            <InputGroup
                                id="costCode"
                                name="costCode"
                                onChange={handleChange}
                                value={values.costCode}
                                large
                                type="number"
                            />
                            {errors.costCodeExistsDepId && (
                                <div
                                    style={{ margin: '10px 0' }}
                                    className="form-error-message no-padding"
                                    hidden={!errors.costCodeExistsDepId}
                                >
                                    The{' '}
                                    <a
                                        target={'_blank'}
                                        rel={'noreferrer'}
                                        href={`/hr/departments/${errors.costCodeExistsDepId}`}
                                    >
                                        {values.costCode}
                                    </a>{' '}
                                    already exists in the system. Please enter a unique Cost code to
                                    save
                                </div>
                            )}
                            {touched.costCode && <Error errors={[errors.costCode]} />}
                        </FormGroup>
                    ) : (
                        item.costCode
                    )}
                </dd>

                <dt className="info__title">Cost Centre *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="costCentre">
                            <Select
                                options={costCentres.sort(compareByLabels)}
                                defaultValue={values.costCentre}
                                onChange={(e) => setFieldValue('costCentre', e ?? [])}
                                name="costCentre"
                                id="costCentre"
                            />
                            {touched.costCentre && <Error errors={[errors.costCentre]} />}
                        </FormGroup>
                    ) : item.costCentre ? (
                        item.costCentre.label
                    ) : (
                        ''
                    )}
                </dd>
                <dt className="info__title">Roles *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="roles">
                            <Select
                                options={roles.sort(compareByLabels)}
                                defaultValue={values.roles ?? []}
                                onChange={handleOnChangeRolesLocations}
                                value={values.roles}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isMulti
                                name="roles"
                                id="roles"
                                components={{ MultiValueRemove }}
                            />
                            {values.roles.some((item: any) => item.isFixed) ? (
                                <p className="info-notification">
                                    <i className="info-notification__icon" />
                                    <span>
                                        Removal is disabled for those roles which are related to
                                        active staff records and/or shift pattern shifts and/or
                                        future rota shifts of this department
                                    </span>
                                </p>
                            ) : (
                                ''
                            )}
                            {touched.roles && <Error errors={[errors.roles]} />}
                        </FormGroup>
                    ) : (
                        item.roles.map((x: any) => x.label).join(', ')
                    )}
                </dd>
                <dt className="info__title">Locations *</dt>
                <dd className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor="locations">
                            <Select
                                options={locations.sort(compareByLabels)}
                                defaultValue={values.locations ?? []}
                                onChange={handleOnChangeRolesLocations}
                                value={values.locations}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isMulti
                                name="locations"
                                id="locations"
                                components={{ MultiValueRemove }}
                            />
                            {values.locations.some((item: any) => item.isFixed) ? (
                                <p className="info-notification">
                                    <i className="info-notification__icon" />
                                    <span>
                                        Removal is disabled for those locations which are related to
                                        active staff records and/or shift pattern shifts and/or
                                        future rota shifts of this department
                                    </span>
                                </p>
                            ) : (
                                ''
                            )}
                            {touched.locations && <Error errors={[errors.locations]} />}
                        </FormGroup>
                    ) : (
                        item.locations.map((x: any) => x.label).join(', ')
                    )}
                </dd>
            </dl>
        </>
    );
};

export default DepartmentSummary;
