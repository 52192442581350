import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Tag } from '@blueprintjs/core';

function HcpNameLink({ match, hcpId, hcpName, tag, closeModal, minimal }) {
    const pathname = `/users/${hcpId}`;
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return (
            <Redirect
                to={{
                    pathname,
                    state: { closeModal },
                }}
                push
            />
        );
    }

    return tag ? (
        <Tag
            interactive
            onClick={() => setRedirect(true)}
            className="hcp-name-link__tag"
            minimal={minimal}
        >
            {hcpName}
        </Tag>
    ) : (
        <Link
            to={{
                pathname,
                state: { closeModal },
            }}
        >
            {hcpName}
        </Link>
    );
}

export default HcpNameLink;
