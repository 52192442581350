import { FC, useEffect, useState } from 'react';
import moment from 'moment';

import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import useVisitWarnings, { VisitValuesType } from '../../../../../hook/useVisitWarnings';
import { formatNhsNumber } from '../../../../../helpers/ucr';
import useStores from '../../../../../hook/useStores';
import { TextInput } from '../../../../v2/form';
import {
    FormMode,
    FormSteps,
    LookupPatientlBtn,
    PullFromReferralBtn,
    warningHighlight,
} from '../common';

import { PatientDetailsForm } from './PatientDetailsForm';
import { Gender } from '@doc-abode/data-models';

type PatientType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    onNextStep?: () => void;
    onSaveForm?: (curStep: FormSteps) => void;
    languagesSpoken?: any;
    gender?: any;
    genderStaffPreferred?: any;
    showPullFromReferral?(): void;
    showPatientLookup?(): void;
    usePatientData?: boolean;
    setPatientData?: () => void;
    required?: boolean;
    handleChangePatientRequired?: (isRequired: boolean) => void;
};

export const PatientDetails: FC<PatientType> = ({
    values,
    loading,
    formMode,
    languagesSpoken,
    gender,
    onNextStep = () => {},
    onSaveForm = () => {},
    genderStaffPreferred,
    showPullFromReferral,
    showPatientLookup,
    usePatientData,
    setPatientData = () => {},
    required = true,
    handleChangePatientRequired,
}) => {
    const {
        RootStore: {
            ucrStore: {
                localPatientData,
                setLocalPatientData,
                selectedPatientData,
                focusedJobId,
                warnings,
            },
        },
    } = useStores() as { RootStore: any };
    const visitWarnings = useVisitWarnings(values as VisitValuesType);
    const [isContinueBtnClicked, setIsContinueBtnClicked] = useState(false);

    const [highlightedWarnings, setHighlightedWarnings] = useState<any>({});

    const { setFieldValue, setValues }: FormikContextType<FormikValues> = useFormikContext();

    const clearPatientDetails = () => {
        setValues({
            ...values,
            nhsNumber: '',
            dateOfBirth: new Date(),
            gender: '',
            firstName: '',
            middleName: '',
            lastName: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            languagesSpoken: [],
            staffPreferredGender: [],
            contactNumber: '',
            additionalContactNumbers: [],
            town: '',
            postCode: '',
            visitDate: new Date(),
            duration: '01:00',
        });

        handleChangePatientRequired?.(false);
    };

    const onSubmit = () => setIsContinueBtnClicked(true);

    useEffect(() => {
        if (selectedPatientData && localPatientData && Object.keys(localPatientData).length > 0) {
            const dateOfBirth = moment(localPatientData.dateOfBirth).toDate() || '';
            const gender = localPatientData.gender || Gender.NOTPROVIDED;
            const nhsNumber = localPatientData.nhsNumber || '';
            const firstName = localPatientData.firstName || '';
            const middleName = localPatientData.middleName || '';
            const lastName = localPatientData.lastName || '';
            const addressLine1 = localPatientData.addressLine1 || '';
            const addressLine2 = localPatientData.addressLine2 || '';
            const addressLine3 = localPatientData.addressLine3 || '';
            const languagesSpoken = localPatientData.languagesSpoken || [];
            const staffPreferredGender = localPatientData.staffPreferredGender || [];
            const contactNumber = localPatientData.contactNumber || '';
            const additionalContactNumbers = localPatientData.additionalContactNumbers || [];
            const town = localPatientData.town || '';
            const postCode = localPatientData.postCode || '';

            setValues({
                ...values,
                dateOfBirth,
                gender,
                nhsNumber,
                firstName,
                middleName,
                lastName,
                addressLine1,
                addressLine2,
                addressLine3,
                languagesSpoken,
                staffPreferredGender,
                contactNumber,
                additionalContactNumbers,
                town,
                postCode,
                visitDate: new Date(),
                duration: '01:00',
            });

            setPatientData();

            setIsContinueBtnClicked(true);

            // After we set the patientLookupData into the form values we clear it
            // so further edits are possible
            setLocalPatientData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        localPatientData,
        setFieldValue,
        usePatientData,
        setPatientData,
        setValues,
        setLocalPatientData,
        selectedPatientData,
    ]);

    useEffect(() => {
        setHighlightedWarnings(
            warningHighlight({
                warnings: focusedJobId === values.id ? warnings[focusedJobId] : visitWarnings,
                values: values,
            }),
        );
    }, [warnings, values, focusedJobId, visitWarnings]);

    useEffect(() => {
        if (values.nhsNumber) {
            handleChangePatientRequired?.(true);
        } else {
            handleChangePatientRequired?.(false);
        }
    }, [handleChangePatientRequired, values.nhsNumber]);

    return (
        <>
            <div className="v2__form-block">
                <TextInput
                    name="nhsNumber"
                    className="v2__form-group--pos-1-2"
                    label="NHS number"
                    required={required}
                    displayErrors={highlightedWarnings['nhsNumber']}
                    disabled={loading}
                    onChange={(e) => {
                        setFieldValue('nhsNumber', formatNhsNumber(e.target.value));
                    }}
                    isContinueBtnClicked={isContinueBtnClicked}
                />
                {formMode !== FormMode.EDIT_VISIT && (
                    <div className="v2__form-group--pos-3-3 v2__form-block-right-buttons-wrapper">
                        <span
                            className="v2__form-block-clear-patient-details"
                            onClick={clearPatientDetails}
                        >
                            Clear patient details
                        </span>
                        {showPatientLookup && <LookupPatientlBtn clickEvent={showPatientLookup} />}
                        {showPullFromReferral && (
                            <PullFromReferralBtn clickEvent={showPullFromReferral} />
                        )}
                    </div>
                )}
            </div>

            <PatientDetailsForm
                loading={loading}
                formMode={formMode}
                gender={gender}
                languagesSpoken={languagesSpoken}
                genderStaffPreferred={genderStaffPreferred}
                onNextStep={onNextStep}
                onSaveForm={onSaveForm}
                onSubmitAction={onSubmit}
                required={required}
            />
        </>
    );
};
