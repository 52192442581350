import React, { Component } from 'react';
import _ from 'lodash';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import FunctionsTableRow from './FunctionsTableRow';
import FunctionsTableHeader from './FunctionsTableHeader';
import FunctionsTableNav from './FunctionsTableNav';
import Loader from '../../../modules/helpers/Loader';
import FunctionsDetails from '../../../modules/functions/detail/FunctionsDetails';

import { Functions } from '../../../../stores/FunctionsStore';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert } from '@blueprintjs/core';

interface FunctionsListProps extends Partial<RouteComponentProps<{ id: string }>> {
    actionType: string;
    RootStore?: any;
}

const FunctionsList = inject('RootStore')(
    observer(
        class AdminManageFunctions extends Component<
            FunctionsListProps,
            {
                showAlert: boolean;
                showArchiveAlert: boolean;
                showSomeRelatedShiftArchiveAlert: boolean;
                showRelatedRoleArchiveAlert: boolean;
                showDeniedDeleteAlert: boolean;
                showDeniedArchiveAlert: boolean;
                formWasEdited: boolean;
            }
        > {
            state = {
                showAlert: false,
                showDeniedDeleteAlert: false,
                showDeniedArchiveAlert: false,
                showRelatedRoleArchiveAlert: false,
                showArchiveAlert: false,
                showSomeRelatedShiftArchiveAlert: false,
                formWasEdited: false,
            };

            componentDidMount() {
                this.props.RootStore.functionsStore.getFunctions();
            }

            componentWillUnmount() {
                this.props.RootStore.functionsStore.clear();
            }

            _handleRowClick = (id: string, event: any) => {
                if (event.target.tagName === 'TD') {
                    this.props.history?.push(`/hr/functions/${id}`);
                }
            };

            _handleAddNew = () => {
                this.props.history?.replace(`/hr/functions/new`);
            };

            _handleToggleSelectAll = async (event: any) => {
                const { selectAll, deselectAll } = this.props.RootStore.functionsStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
                await this.props.RootStore.functionsStore.validateArchiveSelectedFunctions();
            };

            _handleToggleSelected = async (event: any) => {
                const { select, deselect } = this.props.RootStore.functionsStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
                await this.props.RootStore.functionsStore.validateArchiveSelectedFunctions();
            };

            _handleActionEditFunc = () => {
                let { selected } = this.props.RootStore.functionsStore;
                selected.length &&
                    this.props?.history?.push(`/hr/functions/${selected[0]}`, {
                        actionType: 'edit',
                        editModeOpenedFromViewList: true,
                    });
            };

            _handleToggleArchive = () => {
                const {
                    noRelatedShiftsButRolesInFunctions,
                    someRelatedShiftsButRolesInFunctions,
                    allArchivableSelectedFunctions,
                } = this.props.RootStore.functionsStore;

                if (noRelatedShiftsButRolesInFunctions && !someRelatedShiftsButRolesInFunctions) {
                    this.setState((prevState) => {
                        return {
                            showRelatedRoleArchiveAlert: !prevState.showRelatedRoleArchiveAlert,
                        };
                    });
                } else if (allArchivableSelectedFunctions) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else if (someRelatedShiftsButRolesInFunctions) {
                    this.setState((prevState) => {
                        return {
                            showSomeRelatedShiftArchiveAlert: !prevState.showSomeRelatedShiftArchiveAlert,
                        };
                    });
                } else if (!allArchivableSelectedFunctions) {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _handleBulkArchive = async () => {
                this.setState({
                    showArchiveAlert: false,
                    showSomeRelatedShiftArchiveAlert: false,
                    showRelatedRoleArchiveAlert: false,
                    showDeniedArchiveAlert: false,
                });
                const isNotEmpty = !_.isEmpty(
                    this.props.RootStore.functionsStore.validatedArchiveFunctions,
                );
                const archivableFunctions = this.props.RootStore.functionsStore.validatedArchiveFunctions
                    .filter((f: any) => !f.relatedRota && !f.relatedShift)
                    .flatMap((f: any) => f.id);

                isNotEmpty &&
                    (await this.props.RootStore.functionsStore.bulkArchiveFunctions(
                        archivableFunctions,
                    ));
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.functionsStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
                const isNotEmpty = !_.isEmpty(selected);
                isNotEmpty &&
                    (await this.props.RootStore.functionsStore.bulkDeleteFunctions(selected));
            };

            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.functionsStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            render() {
                const {
                    RootStore: {
                        functionsStore: {
                            gridFunctions,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            onFilter,
                            loaded,
                            selected,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const id = this.props.match?.params?.id;
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <FunctionsTableNav
                                onSearch={onSearch}
                                onFilter={onFilter}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                selected={selected}
                                addNew={this._handleAddNew}
                                editItem={this._handleActionEditFunc}
                                archiveItem={this._handleToggleArchive}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <FunctionsTableHeader
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridFunctions.map((functionEntity: Functions) => (
                                        <FunctionsTableRow
                                            {...functionEntity}
                                            onClick={this._handleRowClick}
                                            key={functionEntity.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <FunctionsDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these functions? You won't be
                                        able to recover them."
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description="Are you sure you want to archive the selected function(s)? This action is irreversible"
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showRelatedRoleArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description={
                                <div>
                                    The following function(s) have related role(s), but not assigned
                                    to shift pattern shifts and future rota shifts. The function(s)
                                    will be removed from related role(s) after archiving:
                                    <br />
                                    {this.props.RootStore.functionsStore.validatedArchiveFunctions?.map(
                                        (func: any) =>
                                            !func.relatedRota &&
                                            !func.relatedShift &&
                                            func.relatedRole && (
                                                <a
                                                    target={'_blank'}
                                                    rel={'noreferrer'}
                                                    href={`/hr/functions/${func.id}`}
                                                >
                                                    <b> {func.name} </b>
                                                </a>
                                            ),
                                    )}
                                    <br />
                                    Are you sure you want to archive the selected function(s)? This
                                    action is irreversible
                                </div>
                            }
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showSomeRelatedShiftArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description={
                                <div>
                                    <p>
                                        The following function(s) cannot be archived due to having
                                        related future rota shift(s) and/or shift pattern shift(s):
                                    </p>
                                    <ul>
                                        {this.props.RootStore.functionsStore.validatedArchiveFunctions?.map(
                                            (func: any) =>
                                                (func.relatedRota || func.relatedShift) && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/functions/${func.id}`}
                                                        >
                                                            <b> {func.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                    <p>
                                        The following function(s) have related role(s), but not
                                        assigned to shift pattern shifts and future rota shifts. The
                                        function(s) will be removed from related role(s) after
                                        archiving:
                                    </p>
                                    <ul>
                                        {this.props.RootStore.functionsStore.validatedArchiveFunctions?.map(
                                            (func: any) =>
                                                !func.relatedRota &&
                                                !func.relatedShift &&
                                                func.relatedRole && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/functions/${func.id}`}
                                                        >
                                                            <b> {func.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                    <p>
                                        Do you want to continue with archiving the following
                                        functions?
                                    </p>
                                    <ul>
                                        {this.props.RootStore.functionsStore.validatedArchiveFunctions?.map(
                                            (func: any) =>
                                                !func.relatedRota &&
                                                !func.relatedShift && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/functions/${func.id}`}
                                                        >
                                                            <b> {func.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </div>
                            }
                        />
                        <Alert
                            confirmButtonText="OK"
                            intent={'primary'}
                            isOpen={this.state.showDeniedDeleteAlert}
                            onClose={() => {
                                this.setState({ showDeniedDeleteAlert: false });
                            }}
                        >
                            <p>
                                The selected functions cannot be deleted because they are related to
                                other active entities and/or future shifts
                            </p>
                        </Alert>
                        <Alert
                            confirmButtonText="OK"
                            intent={'primary'}
                            isOpen={this.state.showDeniedArchiveAlert}
                            onClose={() => {
                                this.setState({ showDeniedArchiveAlert: false });
                            }}
                        >
                            <p>
                                The selected function(s) cannot be archived because of being
                                selected in future rota shifts and/or shift pattern shifts. Remove
                                the function(s) from related shifts to archive
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default FunctionsList;
