import { observable, action, computed, toJS, makeObservable, runInAction } from 'mobx';
import { getJobs, createJob, updateJob } from '../api/jobsApi';
import { sortDateDescending } from '../helpers/sorting';

import { isJobExpired, isJobNotified } from '../components/modules/helpers/formatData';
import _ from 'lodash';
import AppToaster from '../components/modules/helpers/Toaster';
import { timeDifferenceInWeeks } from '../components/modules/helpers/formatData';

class JobsStore {
    constructor(rootStore, jobTypes, refreshTimeout) {
        makeObservable(this, {
            isLoadingJobs: observable,
            isUpdatingJobs: observable,
            pendingJobs: observable,
            availableJobs: observable,
            acceptedJobs: observable,
            currentJobs: observable,
            arrivedJobs: observable,
            requireAttentionJobs: observable,
            historicJobs: observable,
            controllerAbortedJobs: observable,
            filters: observable,
            page: observable,
            selected: observable,
            sortFn: observable,
            selectableCriteria: observable,
            jobStatusToEmphasise: observable,
            showAbortedByController: observable,
            filterAdapters: observable,
            selectedOnly: observable,
            pageCount: computed,
            events: computed,
            allJobs: computed,
            pagedJobs: computed,
            pageStart: computed,
            pageEnd: computed,
            totalJobsNumber: computed,
            selectedJobs: computed,
            allSelected: computed,
            selectable: computed,
            loading: computed,
            startTimer: action,
            dispose: action,
            setJobs: action,
            createJob: action,
            reSubmitJob: action,
            withdrawJob: action,
            controllerMakeJobCurrent: action,
            controllerUndoMakeJobCurrent: action,
            abortJob: action,
            updateJobInvoicing: action,
            setLoadingJobs: action,
            setUpdatingJobs: action,
            clearToasts: action,
            getJob: action,
            removeJobFromStore: action,
            doesJobRequireAttention: action,
            setFilters: action,
            setFilterAdapter: action,
            setPage: action,
            select: action,
            deselect: action,
            selectAll: action,
            setSortFn: action,
            setSelectableCriteria: action,
            setJobStatusToEmphasise: action,
            cleanUpSelected: action,
            setSelectedOnly: action,
            toggleShowAbortedByController: action,
        });

        this.rootStore = rootStore;
        this.jobTypes = jobTypes || ['homeVisit', 'telephone', 'telephoneBlock', 'shift'];
        this.refreshTimeout = refreshTimeout || 60000;
    }

    isLoadingJobs = true;
    isUpdatingJobs = false;

    uiJobsTypes = [
        'pending',
        'available',
        'accepted',
        'current',
        'arrived',
        'requireAttention',
        'historic',
        'controllerAborted',
    ];

    pendingJobs = [];
    availableJobs = [];
    acceptedJobs = [];
    currentJobs = [];
    arrivedJobs = [];
    requireAttentionJobs = [];
    historicJobs = [];
    controllerAbortedJobs = [];

    filters = {};
    filterAdapters = [];
    page = 0;
    pageSize = 20;
    selected = [];
    selectableCriteria = [];
    selectedOnly = false;
    sortFn = null;
    jobStatusToEmphasise = null;
    showAbortedByController = false;

    onDemand = (jobs) => jobs.filter((job) => job.jobType !== 'shift');
    scheduled = (jobs) => jobs.filter((job) => job.jobType === 'shift');

    get loading() {
        return (this.isLoadingJobs && this.totalJobsNumber === 0) || this.isUpdatingJobs;
    }

    select = (id) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    get selectable() {
        let selected = this.allJobs;
        this.selectableCriteria.forEach((filterFn) => {
            selected = selected.filter(filterFn);
        });
        return selected;
    }

    selectAll = () => {
        this.selectable.forEach((job) => this.select(job.id));
    };

    deselectAll = () => {
        this.selected = [];
    };

    get allSelected() {
        return this.selectable.every((job) => this.selected.includes(job.id));
    }

    setJobStatusToEmphasise = (status) => {
        this.jobStatusToEmphasise = status;
    };

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    cleanUpSelected = () => {
        this.selected = this.selected.filter((id) => this.allJobs.some((job) => job.id === id));
    };

    setSortFn = (sortFn) => {
        this.sortFn = sortFn;
    };

    get selectedJobs() {
        return this.allJobs.filter((job) => this.selected.includes(job.id));
    }

    setSelectedOnly = (value) => {
        this.selectedOnly = value;
    };

    get events() {
        return this.scheduled(this.allJobs).map((job) => {
            const displayStatus = this.doesJobRequireAttention(job)
                ? 'REQUIRE_ATTENTION'
                : job.jobStatus;

            // Should appear faded if there is no emphasis set, or (emphasis is IN_PROGRESS and job is not current or arrived, or job status is not same as emphasis)
            const fadeDisplay =
                this.jobStatusToEmphasise !== null &&
                (this.jobStatusToEmphasise === 'IN_PROGRESS'
                    ? displayStatus !== 'ARRIVED' && displayStatus !== 'CURRENT'
                    : this.jobStatusToEmphasise !== displayStatus);
            return {
                ...job,
                start: new Date(job.startDateTime),
                end: new Date(job.endDateTime),
                title: job.shiftType,
                displayStatus,
                fadeDisplay,
            };
        });
    }

    fetchInterval = null;

    get allJobs() {
        let allJobs = this.showAbortedByController
            ? this.controllerAbortedJobs
            : this.uiJobsTypes.flatMap((jobsType) =>
                  jobsType !== 'controllerAborted' ? this[`${jobsType}Jobs`] : [],
              );

        allJobs = allJobs.map((job) => ({
            ...job,
            selected: this.selected.includes(job.id),
            requiresAttention: this.doesJobRequireAttention(job),
        }));

        if (this.selectedOnly) {
            allJobs = allJobs.filter((job) => job.selected);
        }

        if (this.sortFn) {
            allJobs.sort(this.sortFn);
        }

        return allJobs;
    }

    get totalJobsNumber() {
        return this.allJobs.length;
    }

    get pageStart() {
        return this.page * this.pageSize;
    }

    get pageEnd() {
        return this.pageStart + this.pageSize;
    }

    get pagedJobs() {
        return this.allJobs.slice(this.pageStart, this.pageEnd);
    }

    get pageCount() {
        return this.totalJobsNumber > 0 ? Math.ceil(this.totalJobsNumber / this.pageSize) : 0;
    }

    setPage = (page) => (this.page = page);

    startTimer() {
        if (!this.fetchInterval) {
            this.setJobs();
            this.fetchInterval = setInterval(() => this.setJobs(), this.refreshTimeout);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    setJobs = async () => {
        this.setLoadingJobs(true);
        const userSession = await this.rootStore.userStore.getUserSession();
        let jobsBody;

        try {
            const filters = this.runFilterAdapters();
            jobsBody = await getJobs(userSession.tokens.id, {
                ...filters,
                jobType: this.jobTypes,
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'There was a problem getting the latest jobs information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
            this.setLoadingJobs(false);
            this.setUpdatingJobs(false);
            return this.dispose();
        }

        runInAction(() => {
            this.uiJobsTypes.forEach((jobsType) => {
                let sorted = [];

                if (
                    jobsType === 'pending' ||
                    jobsType === 'available' ||
                    jobsType === 'accepted' ||
                    jobsType === 'current'
                ) {
                    sorted = jobsBody[jobsType]?.Items || [];
                    // Remove expired jobs
                    // Remove jobs that have no HCPs Notified
                    sorted = sorted.filter((sortedJob) => !this.doesJobRequireAttention(sortedJob));
                }

                // Merge the WITHDRAWN, HCP_ABORTED, expired: AVIALABLE, ACCEPTED, CURRENT
                else if (jobsType === 'requireAttention') {
                    sorted = _.flatten(
                        [
                            'pending',
                            'available',
                            'accepted',
                            'current',
                            'arrived',
                            'withdrawn',
                            'hcp_aborted',
                        ].map((status) => {
                            sorted = jobsBody[status]?.Items || [];
                            return sorted.filter(this.doesJobRequireAttention);
                        }),
                    );
                }

                // Completed Jobs
                else if (jobsType === 'historic') {
                    sorted = jobsBody.completed?.Items || [];
                }

                // Controller Aborted Jobs
                else if (jobsType === 'controllerAborted') {
                    sorted = jobsBody.controller_aborted?.Items || [];
                }

                // Any other job type (e.g. ARRIVED)
                else {
                    sorted = jobsBody[jobsType]?.Items || [];

                    sorted = sorted.filter((sortedJob) => !this.doesJobRequireAttention(sortedJob));
                }

                // Sort the respective list of jobs
                if (sorted.length > 1) {
                    sorted = sorted.sort((a, b) =>
                        sortDateDescending(a.createDateTime, b.createDateTime),
                    );
                }

                // Set the jobs in the store
                // only if the observable for that job type exists,
                // so that we don't have unwanted observables polluting the store
                if (this[`${jobsType}Jobs`]) {
                    this[`${jobsType}Jobs`] = sorted;
                }
            });
        });

        this.setLoadingJobs(false);
        this.setUpdatingJobs(false);
    };

    createJob = async (jobData) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        return createJob(jobData, userSession.tokens.id);
    };

    editJob = async (jobData = {}) => {
        const job = {
            ...jobData,
            editJob: true,
            version: jobData.version + 1,
        };

        const userSession = await this.rootStore.userStore.getUserSession();
        return updateJob(job, userSession.tokens.id);
    };

    reSubmitJob = async (jobData, jobStatus = 'AVAILABLE') => {
        let job = { ...jobData };
        job.jobStatus = jobStatus;
        job.version = job.version + 1;

        if (job.itineraryId) {
            job.itineraryId = null;
        }

        const userSession = await this.rootStore.userStore.getUserSession();
        return updateJob(job, userSession.tokens.id);
    };

    withdrawJob = async (jobData, additionalData = {}) => {
        const job = typeof jobData === 'string' ? toJS(this.getJob(jobData)) : jobData;
        // Create a copy of the job so that the status update will only be applied on the copy
        const jobClone = { ...job, ...additionalData };
        jobClone.jobStatus = 'WITHDRAWN';
        jobClone.version = jobClone.version + 1;
        const userSession = await this.rootStore.userStore.getUserSession();
        return updateJob(jobClone, userSession.tokens.id);
    };

    controllerMakeJobCurrent = async (jobData, additionalData = {}) => {
        const job = typeof jobData === 'string' ? toJS(this.getJob(jobData)) : jobData;
        // Create a copy of the job so that the status update will only be applied on the copy
        const jobClone = { ...job, ...additionalData };
        jobClone.jobStatus = 'CURRENT';
        jobClone.version = jobClone.version + 1;
        const userSession = await this.rootStore.userStore.getUserSession();
        jobClone.markedCurrentByController = userSession.username;
        return updateJob(jobClone, userSession.tokens.id);
    };

    controllerUndoMakeJobCurrent = async (jobData, additionalData = {}) => {
        const job = typeof jobData === 'string' ? toJS(this.getJob(jobData)) : jobData;
        // Create a copy of the job so that the status update will only be applied on the copy
        const jobClone = { ...job, ...additionalData };
        jobClone.jobStatus = 'ACCEPTED';
        jobClone.version = jobClone.version + 1;
        const userSession = await this.rootStore.userStore.getUserSession();
        jobClone.markedCurrentByController = null;
        return updateJob(jobClone, userSession.tokens.id);
    };

    abortJob = async (
        jobData,
        controllerAbortedReason,
        controllerAbortedNotes,
        isSeriesAbort = false,
    ) => {
        const job = typeof jobData === 'string' ? toJS(this.getJob(jobData)) : jobData;
        // Create a copy of the job so that the status update will only be applied on the copy
        const jobClone = JSON.parse(JSON.stringify(job));
        jobClone.jobStatus = 'CONTROLLER_ABORTED';
        jobClone.version = jobClone.version + 1;
        jobClone.controllerAbortedReason = controllerAbortedReason;
        jobClone.controllerAbortedNotes = controllerAbortedNotes;
        if (isSeriesAbort) {
            jobClone.isSeriesAbort = isSeriesAbort;
        }

        const userSession = await this.rootStore.userStore.getUserSession();
        return updateJob(jobClone, userSession.tokens.id);
    };

    updateJobInvoicing = async (jobId, invoiceable, paymentType) => {
        // Create a copy of the job so that the status update will only be applied on the copy
        const jobClone = JSON.parse(JSON.stringify(toJS(this.getJob(jobId))));
        jobClone.version = jobClone.version + 1;
        if (invoiceable !== 'NOT_SET') {
            jobClone.invoiceable = invoiceable === 'YES';
        }
        if (paymentType !== 'NOT_SET') {
            jobClone.paymentType = paymentType;
        }

        const userSession = await this.rootStore.userStore.getUserSession();
        try {
            await updateJob(jobClone, userSession.tokens.id);
            return { successful: true };
        } catch (err) {
            return { successful: false };
        }
    };

    setLoadingJobs = (status) => {
        this.isLoadingJobs = status;
    };

    setUpdatingJobs = (status) => {
        this.isUpdatingJobs = status;
    };

    clearToasts = () => AppToaster.clear();

    // Finds a job in any list by ID and returns it
    getJob = (jobId) => {
        return _.find(this.allJobs, { id: jobId });
    };

    // Finds a job in any list by ID and delete it
    removeJobFromStore = (jobId) => {
        const jobInStore = this.getJob(jobId);
        let jobStatus = jobInStore.jobStatus.toLowerCase();
        const jobRequiresAttention = this.doesJobRequireAttention(jobInStore);

        if (jobRequiresAttention) {
            jobStatus = 'requireAttention';
        }

        this[`${jobStatus}Jobs`] = this[`${jobStatus}Jobs`].filter((job) => job.id !== jobId);
    };

    // Finds a job in any list by ID and determine if it requires attention or not
    doesJobRequireAttention = (job) => {
        const jobStatus = job.jobStatus.toLowerCase();
        const isExpired = isJobExpired(job.expiryDateTime);
        const isNotified = isJobNotified(job);
        const isShift = job.jobType === 'shift';
        const isVaccination = job.jobType === 'vaccination';
        const isRoute = job.jobType === 'route';

        if (
            jobStatus === 'withdrawn' ||
            jobStatus === 'hcp_aborted' ||
            (isExpired && (isShift || isRoute) && jobStatus === 'available') ||
            (isExpired &&
                !isShift &&
                !isVaccination &&
                !isRoute &&
                !['arrived', 'completed', 'controller_aborted'].includes(jobStatus)) ||
            (!isNotified && jobStatus === 'available' && !isVaccination && !isRoute) ||
            (isVaccination &&
                job.dateOfDose1 &&
                timeDifferenceInWeeks(job.dateOfDose1) > 9 &&
                jobStatus !== 'completed' &&
                jobStatus !== 'controller_aborted')
        ) {
            return true;
        } else {
            return false;
        }
    };

    runFilterAdapters = () => {
        let filters = { ...this.filters };
        this.filterAdapters.forEach((adapter) => {
            filters = adapter(filters);
        });
        return filters;
    };

    setFilterAdapter = (adapter) => {
        this.filterAdapters.push(adapter);
    };

    setFilters = async (filters) => {
        if (filters) {
            this.filters = filters;
            this.setPage(0);
        }
        this.setUpdatingJobs(true);
        await this.setJobs();
        this.cleanUpSelected();
    };

    toggleShowAbortedByController = () => {
        this.showAbortedByController = !this.showAbortedByController;
        this.page = 0;
    };
}

export default JobsStore;
