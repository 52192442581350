import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import moment from 'moment';

import { PagedStore } from './PagedStore';
import {
    CREATE_ABSENCE,
    UPDATE_ABSENCE,
    GET_ABSENCE_BY_ID,
    GET_ABSENCES_LIST,
    GET_ABSENCES_FILTER_OPTIONS,
    BULK_ARCHIVE_ABSENCE,
    APPROVE_ABSENCE,
    REJECT_ABSENCE,
    GET_PEOPLE_FOR_SELECT,
    BULK_CHANGE_ABSENCE_STATUS,
} from '../graphql/queries/absencesNew';
import { PAGE_SIZE, EMPLOYEE_RECORD_ARCHIVED_STATUS_ID } from '../constants/hrConst';
import { formatTime, formatToDate } from '../components/modules/helpers/formatData';
import { dateFormat } from '../constants/patientsConst';
import AppToaster from '../components/modules/helpers/Toaster';

class AbsencesStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            absenceTypes: computed,
            approvalStatuses: computed,
            getPersonRecords: action,
            recordStatuses: computed,
            gridItems: computed,
            init: action,
            filterOptions: observable,
            items: observable,
            sortFn: observable,
            loaded: observable,
            pageInfo: observable,
            nextPage: action,
            onFilter: action,
            onSort: action,
            previousPage: action,
            toggleSelect: action,
            selected: observable,
            selectedApproved: computed,
            selectedPending: computed,
            selectedRejected: computed,
            allSelected: computed,
        });
        this.rootStore = rootStore;
    }

    apolloClient: any;
    items = [];
    rootStore: any;
    selected: string[] = [];
    sortFn: (a: any, b: any) => number = () => 0;
    loaded = false;
    filterOptions = {};
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeItem = (item: any) => {
        const roles = [];
        const departments = [];
        const documents = [] as any;
        for (const emp of item.person.employees) {
            for (const role of emp.roles) {
                if (
                    roles.indexOf(role.employeeRole.name) === -1 &&
                    emp.statusId !== EMPLOYEE_RECORD_ARCHIVED_STATUS_ID
                ) {
                    roles.push(role.employeeRole.name);
                }
            }

            for (const dep of emp.departments) {
                if (departments.indexOf(dep.department.name) === -1 && !dep.isArchived) {
                    departments.push(dep.department.name);
                }
            }
        }
        item.documents.length &&
            item.documents.forEach((doc: any) => {
                const docObj = { ...doc };
                delete docObj.__typename;
                documents.push(docObj);
            });
        return {
            selected: this.selected.includes(item.id),
            id: item.id,
            name: `${item.person.firstName} ${
                item.person.middleName ? `${item.person.middleName} ` : ''
            }${item.person.lastName}`,
            personId: item.person.id,
            roles: roles.join(', '),
            departments: departments.join(', '),
            absenceType: item.absenceType.name,
            reasonId: item.absenceTypeId,
            approvalStatus: item.status.name,
            recordStatus: !item.isArchived ? 'Active' : 'Archived',
            startDateTime: moment(item.startDateTime).format('HH:hh DD.MM.YYYY'),
            endDateTime: moment(item.endDateTime).format('HH:hh DD.MM.YYYY'),
            startDate: moment(item.startDateTime).toDate(),
            endDate: moment(item.endDateTime).toDate(),
            startTime: formatTime(item.startDateTime),
            endTime: formatTime(item.endDateTime),
            createdAt: moment(item.createdAt).format('DD.MM.YYYY'),
            createdAtInsideView: moment(item.createdAt).format('ddd Do MMMM, h:mm'),
            createdBy: `${item.createdPerson.firstName} ${item.createdPerson.lastName}`,
            updatedAt: moment(item.updatedAt).format('DD.MM.YYYY'),
            updatedAtInsideView: moment(item.updatedAt).format('ddd Do MMMM, h:mm'),
            updatedBy: item.updatedPerson
                ? `${item.updatedPerson.firstName} ${item.updatedPerson.lastName}`
                : '',
            absenceTypeId: item.absenceTypeId,
            statusId: item.statusId,
            isArchived: item.isArchived,
            documents: documents,
            notes: item.notes,
        };
    };

    get allSelected() {
        return this.gridItems.every((r) => r.isArchived || this.selected.includes(r.id));
    }

    get selectedApproved() {
        return this.gridItems.filter(
            (r: any) =>
                this.selected.indexOf(r.id) !== -1 &&
                r.statusId === 'a4c22059-52d2-4b23-8240-a97aeb896a59',
        );
    }

    get selectedPending() {
        return this.gridItems.filter(
            (r: any) =>
                this.selected.indexOf(r.id) !== -1 &&
                r.statusId === 'f4429b44-6535-42c7-ac29-2935e14b31ce',
        );
    }

    get selectedRejected() {
        return this.gridItems.filter(
            (r: any) =>
                this.selected.indexOf(r.id) !== -1 &&
                r.statusId === '6e4f0c21-81fd-427f-a2fc-6afbc716bc50',
        );
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    toggleSelect = (id: string) => {
        const index = this.selected.indexOf(id);

        if (index === -1) {
            this.selected.push(id);
        } else {
            this.selected.splice(index, 1);
        }
    };

    toggleSelectAll = (checked: boolean) => {
        if (checked) {
            runInAction(() => {
                this.gridItems.forEach((item: any) => {
                    const index = this.selected.indexOf(item.id);
                    if (index === -1 && !item.isArchived) {
                        this.selected.push(item.id);
                    }
                });
            });
        } else {
            this.selected = [];
        }
    };

    get gridItems() {
        const gItems = this.items.map(this.normalizeItem);
        if (this.sortFn) {
            gItems.sort(this.sortFn);
        }
        return gItems;
    }

    get absenceTypes() {
        if (!(this.filterOptions as any).absenceTypeFilterOptions) return [];

        return (this.filterOptions as any).absenceTypeFilterOptions.map(
            ({ label, value }: any) => ({
                label,
                value,
            }),
        );
    }

    get approvalStatuses() {
        if (!(this.filterOptions as any).approvalStatusFilterOptions) return [];

        return (this.filterOptions as any).approvalStatusFilterOptions.map(
            ({ label, value }: any) => ({
                label,
                value,
            }),
        );
    }

    get recordStatuses() {
        if (!(this.filterOptions as any).recordStatusFilterOptions) return [];

        return (this.filterOptions as any).recordStatusFilterOptions.map(
            ({ label, value }: any) => ({
                label,
                value,
            }),
        );
    }

    getItems = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getAbsences: data },
        } = await this.apolloClient.query({
            query: GET_ABSENCES_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.items = data.edges?.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getItems();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getItems();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getItems();
    };

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onFilter = (filter: any) => {
        this.pageInfo.filter = {
            personId: filter.personId,
            absenceTypeId: filter.absenceTypeId,
            statusId: filter.statusId,
            startDate: filter.startDate,
            endDate: filter.endDate,
            isArchived: filter.isArchived,
        };
        this.refresh();
    };

    getAbsenceById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getAbsenceById: data },
        } = await this.apolloClient.query({
            query: GET_ABSENCE_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeItem(data);
    };

    getFilterOptions = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getAbsenceFilterOptions },
        } = await this.apolloClient.query({
            query: GET_ABSENCES_FILTER_OPTIONS,
        });

        runInAction(() => {
            this.filterOptions = getAbsenceFilterOptions;
        });
    };

    getPersonRecords = async (pageSize: number, query: string | null, lastItem: string | null) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: {
                getPersons: { edges: items, pageInfo },
            },
        } = await this.apolloClient.query({
            query: GET_PEOPLE_FOR_SELECT,
            variables: {
                first: pageSize,
                query,
                after: lastItem,
                orderBy: 'asc',
                field: 'firstName',
                filter: {
                    hasAbsences: true,
                },
            },
        });

        return {
            items: items.map((r: any) => ({
                label: `${r.node.firstName} ${r.node.lastName}`,
                value: r.node.id,
            })),
            pageInfo,
        };
    };

    async createAbsence(absence: any) {
        try {
            const {
                data: {
                    createAbsence: { id },
                },
            } = await this.apolloClient.mutate({
                mutation: CREATE_ABSENCE,
                variables: {
                    data: {
                        personId: absence.personId,
                        absenceTypeId: absence.reasonId,
                        statusId: absence.statusId,
                        startDateTime: formatToDate(
                            moment(absence.startDate).format(dateFormat),
                            absence.startTime,
                        ),
                        endDateTime: formatToDate(
                            moment.utc(absence.endDate).format(dateFormat),
                            absence.endTime,
                        ),
                        notes: absence.notes,
                        documents: absence.documents,
                    },
                },
            });
            AppToaster.show({
                message: 'Absence record created successfully!',
                intent: 'success',
            });
            return id;
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem creating absence record. Please try again later.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateAbsence(absence: any) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_ABSENCE,
                variables: {
                    id: absence.id,
                    data: {
                        personId: absence.personId,
                        absenceTypeId: absence.reasonId,
                        statusId: absence.statusId,
                        startDateTime: formatToDate(
                            moment.utc(absence.startDate).format(dateFormat),
                            absence.startTime,
                        ),
                        endDateTime: formatToDate(
                            moment.utc(absence.endDate).format(dateFormat),
                            absence.endTime,
                        ),
                        notes: absence.notes,
                        documents: absence.documents,
                    },
                },
            });
            AppToaster.show({
                message: 'Absence record updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating absence record. Please try again later.',
                intent: 'danger',
            });
        }
        // await this.getAbsence();
    }

    async bulkArchiveAbsence(ids: [string]) {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_ABSENCE,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message:
                    ids.length > 1
                        ? 'The absence record(s) have been archived'
                        : 'The absence record has been archived',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating absence record. Please try again.',
                intent: 'danger',
            });
        }
        await this.getItems();
    }

    async bulkChangeAbsenceStatus(ids: [string], statusId: string) {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_CHANGE_ABSENCE_STATUS,
                variables: {
                    ids,
                    statusId,
                },
            });
            AppToaster.show({
                message: 'The absence record(s) have been updated',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating absence records. Please try again.',
                intent: 'danger',
            });
        }
        await this.getItems();
    }

    async approveAbsence(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: APPROVE_ABSENCE,
                variables: {
                    id: id,
                },
            });
            AppToaster.show({
                message: 'The absence has been successfully approved',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating status. Please try again.',
                intent: 'danger',
            });
        }
        await this.getItems();
    }

    async rejectAbsence(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: REJECT_ABSENCE,
                variables: {
                    id: id,
                },
            });
            AppToaster.show({
                message: 'The absence has been successfully rejected',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating status. Please try again.',
                intent: 'danger',
            });
        }
        await this.getItems();
    }

    deselectAll = () => {
        this.selected = [];
    };
}

export default AbsencesStore;
