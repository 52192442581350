import moment from 'moment';
import { formatDateTime } from '../../helpers/formatData';

export const isDateTimeInPast = (date, time) => {
    if (date !== null && time !== null && !isNaN(date.valueOf())) {
        const dateTime = formatDateTime(date, time);
        const now = moment();
        if (now.isAfter(dateTime)) {
            return true;
        }
    }
    return false;
};

export const isDateBetweenCurrentAndOneYearInAdvance = (date) => {
    if (date !== null) {
        const today = moment();
        const momentDate = moment(date);
        if (
            momentDate.startOf('day').isSameOrAfter(today.startOf('day')) &&
            momentDate.startOf('day').isSameOrBefore(moment().add(1, 'years').startOf('day'))
        ) {
            return true;
        }
    }
    return false;
};

export const isDateIsBigger = (dateForComparing, date) => {
    if (dateForComparing !== null) {
        const copyDateForComparing = moment(dateForComparing);
        const copyDate = moment(date);
        if (copyDateForComparing.startOf('day').isAfter(copyDate.startOf('day'))) {
            return true;
        }
    }
    return false;
};
