import { observable, action, makeObservable } from 'mobx';
import { getInvoices, downloadFile, mergeAndDownloadCSVs } from '../api/invoicesApi';

class InvoicesStore {
    constructor(rootStore) {
        makeObservable(this, {
            invoices: observable,
            isExportingAsCSV: observable,
            individualExportingInvoiceId: observable,
            isLoading: observable,
            selected: observable,
            getInvoices: action,
        });

        this.rootStore = rootStore;
    }

    invoices = [];
    isExportingAsCSV = false;
    individualExportingInvoiceId = null;
    isLoading = false;
    selected = [];

    exportAllInvoicesAsCSV = async (from, to) => {
        this.isExportingAsCSV = true;

        try {
            const userSession = await this.rootStore.userStore.getUserSession();

            const org = userSession.organisations[0];
            const response = await mergeAndDownloadCSVs(
                userSession.tokens.id,
                org,
                from.toISOString(),
                to.toISOString(),
            );
            this.isExportingAsCSV = false;
            const { url } = await response.json();
            return url;
        } catch (err) {
            console.error('Unable to get download link', err);
            this.isExportingAsCSV = false;
        }
    };

    exportInvoiceAsCSV = async (invoiceId, category = 'csv') => {
        this.individualExportingInvoiceId = invoiceId;

        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await downloadFile(userSession.tokens.id, invoiceId, category);
            this.individualExportingInvoiceId = null;
            const { url } = await response.json();
            return url;
        } catch (err) {
            console.error('Unable to get download link', err);
            this.individualExportingInvoiceId = null;
        }
    };

    exportInvoiceAsPDF = async (invoiceId, category = 'pdf') => {
        this.individualExportingInvoiceId = invoiceId;

        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const response = await downloadFile(userSession.tokens.id, invoiceId, category);
            this.individualExportingInvoiceId = null;
            const { url } = await response.json();
            return url;
        } catch (err) {
            console.error('Unable to get download link', err);
            this.individualExportingInvoiceId = null;
        }
    };

    getInvoices = async (from, to) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        this.isLoading = true;
        this.invoices = [];

        const org = userSession.organisations[0];
        const response = await getInvoices(
            userSession.tokens.id,
            org,
            from.toISOString(),
            to.toISOString(),
        );

        this.isLoading = false;

        if (response.status === 200) {
            const invoices = await response.json();
            this.invoices = invoices.sort(function (a, b) {
                return Date.parse(a.coveringPeriod) - Date.parse(b.coveringPeriod);
            });
        }
    };
}

export default InvoicesStore;
