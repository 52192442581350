import React from 'react';

export default function InfoPair(props) {
    return (
        <div className="info-pair">
            <div className="info-pair__heading">{props.label}</div>
            <div
                className={`info-pair__content ${
                    props.lighter ? 'info-pair__content--lighter' : ''
                }`}
            >
                {props.content ? props.content : props.children}
            </div>
        </div>
    );
}
