import { JobStatus, Service, AdminTypeV2, Pathway } from '@doc-abode/data-models';
import moment from 'moment';

export enum FormMode {
    ADD_VISIT = 'addVisit',
    EDIT_VISIT = 'editVisit',
    FOLLOW_UP = 'followUp',
    DEFAULT = 'default',
}

export enum FormSteps {
    PATIENT = 'patient',
    ACTIVITY = 'activity',
    REVIEW = 'review',
    REFERRAL = 'referral',
    CARE = 'care',
}

export enum ButtonNames {
    ADD_VISIT = 'Save',
    EDIT_VISIT = 'Save',
    DEFAULT_LAST = 'Review',
    DEFAULT = 'Continue',
}

type FriendlyVisitDetails = {
    [key: string]: string;
};

export const getButtonName = (formMode: FormMode, step?: FormSteps): ButtonNames => {
    if (formMode === FormMode.ADD_VISIT) {
        return ButtonNames.ADD_VISIT;
    } else if (formMode === FormMode.EDIT_VISIT) {
        return ButtonNames.EDIT_VISIT;
    } else if (step && [FormSteps.ACTIVITY, FormSteps.CARE].includes(step)) {
        return ButtonNames.DEFAULT_LAST;
    } else {
        return ButtonNames.DEFAULT;
    }
};

export const getVisitMaxDuration = (startDateTime?: Date, maxDateTime?: Date): Date => {
    const defaultDateTime = moment().startOf('day');

    if (!maxDateTime) return defaultDateTime.toDate();

    const startTime = startDateTime
        ? defaultDateTime.set({
              hour: startDateTime.getHours(),
              minute: startDateTime.getMinutes(),
          })
        : defaultDateTime;

    const maxTime = moment(maxDateTime);
    const diffHour = maxTime.diff(startTime, 'hours');
    const diffMinute = maxTime.diff(startTime, 'minutes') - diffHour * 60;
    const maxDuration = moment(`${diffHour}:${diffMinute}`, 'HH:mm');

    return maxDuration.toDate();
};

export const friendlyDisposition: FriendlyVisitDetails = {
    within2hResponse: 'Within 2h response',
    '0-1DayResponse': '0-1 day response',
    over2hResponse: 'Over 2h response',
    '2dReablement': '2d reablement',
    '2hCrisis': '2h Crisis',
    '24hVisit': '24h Visit',
    followUp: 'Follow Up',
    admin: 'Admin',
    other: 'Other',
};

export const getPathwayDisplayName = (pathways: Pathway[] = [], value?: string): string => {
    // Default value if pathway cannot be resolved
    let name = 'Other';

    if (value) {
        const pathwayConfig = pathways.find((pathway) => pathway.value === value);

        // Use the display label from configuration where found
        // or fallback to uppercasing the value for defunct/non-migrated pathways
        name = pathwayConfig ? pathwayConfig.label : value.toUpperCase();
    }

    return name;
};

export const getServiceDisplayName = (services: Service[] = [], value?: string): string => {
    const existingService = services.find((serv) => serv.value === value);
    const name = existingService?.label;

    if (name) {
        return name;
    }

    // We used to have hardcoded values for service names which are no longer supported
    return value ? friendlyDisposition[value] : '';
};

export const getAdminDisplayName = (adminTypes: AdminTypeV2[] = [], value?: string): string => {
    const existingType = adminTypes.find((adminType) => adminType.value === value);
    const name = existingType?.label;

    if (name) {
        return name;
    }

    return 'Admin';
};

export const friendlyCareComplexity: FriendlyVisitDetails = {
    nonComplex: 'Non-complex',
    complex: 'Complex',
};
export const abortStatusCheck = (jobStatus: JobStatus) =>
    jobStatus === JobStatus.CONTROLLER_ABORTED || jobStatus === JobStatus.HCP_ABORTED;

export const offSetTimeTo7AM = moment()
    .set('hour', 7)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

export const offSetTimeTo9PM = moment()
    .set('hour', 21)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);

export const isBetween7AMTo9PM = (): boolean => {
    const currentTime = moment();
    if (currentTime.isBetween(offSetTimeTo7AM, offSetTimeTo9PM)) {
        return true;
    } else {
        return false;
    }
};
