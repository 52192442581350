export interface PageInfo {
    totalCount: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    currentStartCursor: string | null;
    currentEndCursor: string | null;
    startCursor: string | null;
    endCursor: string | null;
    page: number;
    sortColumn: string | null;
    sortBy: string | null;
    filter: object;
    searchText: string | null;
}

export type SortInfo = {
    column: string;
    orderBy: string;
};

export abstract class PagedStore {
    loaded = false;
    pageInfo: any = {
        totalCount: 0,
        hasNextPage: false,
        hasPreviousPage: false,
        currentStartCursor: null,
        currentEndCursor: null,
        startCursor: null,
        endCursor: null,
        page: 0,
        sortColumn: null,
        sortBy: null,
        filter: {},
        searchText: null,
    };

    protected reset = () => {
        this.loaded = false;
        this.pageInfo.page = 0;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.hasPreviousPage = false;
        this.pageInfo.hasNextPage = false;
    };

    protected clearPageInfo = (initialFilter: object = {}) => {
        this.reset();
        this.pageInfo.searchText = '';
        this.pageInfo.filter = initialFilter;
    };

    onCommonSort = (sortInfo: SortInfo) => {
        this.pageInfo = {
            ...this.pageInfo,
            sortColumn: sortInfo.column,
            sortBy: sortInfo.orderBy,
        };
    };
}
