import { Moment } from 'moment/moment';

export enum EnumOptionPattern {
    NONE = 'none',
    CUSTOM = 'custom',
    LONG_DAY_8 = 'longDay8',
    LONG_DAY_9 = 'longDay9',
    EARLY_DAY = 'earlyDay',
    LATE_DAY = 'lateDay',
    LATE_EVENING_ONLY = 'lateEveningOnly',
}

export interface IAvailabilityTime {
    enabled: boolean;
    startTime: string;
    endTime: string;
    value: string;
    label: string;
}

export type HcpSchedule = {
    endDateTime: string;
    startDateTime: string;
    userId: string;
};

export type ScheduleHcp = {
    startDateTime: Moment;
    endDateTime: Moment;
};
