import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { observer, inject } from 'mobx-react';
import AgenciesTableRow from './AgenciesTableRow';
import AgenciesTableHeader from './AgenciesTableHeader';
import AgenciesTableNav from './AgenciesTableNav';
import Loader from '../../../modules/helpers/Loader';
import AgenciesDetails from '../../../modules/agencies/detail/AgenciesDetails';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert } from '@blueprintjs/core';

const Agencies = inject('RootStore')(
    observer(
        class AdminManageAgencies extends Component {
            state = {
                showAlert: false,
                showArchiveAlert: false,
                showSomeArchiveAlert: false,
                showDeniedArchiveAlert: false,
            };

            async componentDidMount() {
                await this.props.RootStore.agenciesStore.getAgencies();
            }

            componentWillUnmount() {
                this.props.RootStore.agenciesStore.clear();
            }

            _handleRowClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/agencies/${id}`);
                }
            };
            _handleActionEdit = () => {
                let { selected } = this.props.RootStore.agenciesStore;
                selected.length &&
                    this.props?.history?.push(`/hr/agencies/${selected[0]}`, {
                        actionType: 'edit',
                        editModeOpenedFromViewList: true,
                    });
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/agencies/new`);
            };

            _handleToggleSelectAll = async (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.agenciesStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
                await this.props.RootStore.agenciesStore.validateArchiveSelectedAgencies();
            };

            _handleToggleSelected = async (event) => {
                const { select, deselect } = this.props.RootStore.agenciesStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
                await this.props.RootStore.agenciesStore.validateArchiveSelectedAgencies();
            };

            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.agenciesStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };
            _handleToggleArchive = () => {
                const {
                    someArchivableSelectedAgencies,
                    allArchivableSelectedAgencies,
                } = this.props.RootStore.agenciesStore;

                if (allArchivableSelectedAgencies) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else if (someArchivableSelectedAgencies) {
                    this.setState((prevState) => {
                        return {
                            showSomeArchiveAlert: !prevState.showSomeArchiveAlert,
                        };
                    });
                } else if (!allArchivableSelectedAgencies) {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.agenciesStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
                const isNotEmpty = !_.isEmpty(selected);
                isNotEmpty &&
                    (await this.props.RootStore.agenciesStore.bulkDeleteAgencies(selected));
            };

            _handleBulkArchive = async () => {
                this.setState({
                    showArchiveAlert: false,
                    showSomeArchiveAlert: false,
                    showDeniedArchiveAlert: false,
                });
                const isNotEmpty = !_.isEmpty(
                    this.props.RootStore.agenciesStore.validatedArchiveAgencies,
                );
                const archivableAgencies = this.props.RootStore.agenciesStore.validatedArchiveAgencies
                    .filter((a) => a.isArchivable)
                    .flatMap((a) => a.id);

                isNotEmpty &&
                    (await this.props.RootStore.agenciesStore.bulkArchiveAgencies(
                        archivableAgencies,
                    ));
            };

            render() {
                const {
                    RootStore: {
                        agenciesStore: {
                            gridAgencies,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            onFilter,
                            loaded,
                            selected,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <AgenciesTableNav
                                onFilter={onFilter}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                selected={selected}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleAlert}
                                editItem={this._handleActionEdit}
                                archiveItem={this._handleToggleArchive}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <AgenciesTableHeader
                                    data={gridAgencies}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridAgencies.map((agency) => (
                                        <AgenciesTableRow
                                            {...agency}
                                            onClick={this._handleRowClick}
                                            key={agency.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <AgenciesDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these agencies? You won't be
                                        able to recover them."
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showSomeArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description={
                                <div>
                                    <p>
                                        The following agency/agencies cannot be archived because one
                                        or more of their staff record(s) and/or pay rate(s) are
                                        still active:
                                    </p>
                                    <ul>
                                        {this.props.RootStore.agenciesStore.validatedArchiveAgencies?.map(
                                            (agency) =>
                                                (agency.relatedStaff || agency.relatedPayRate) && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/agencies/${agency.id}`}
                                                        >
                                                            <b> {agency.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>

                                    <p>
                                        Do you want to continue with archiving the following
                                        agency/agencies?
                                    </p>
                                    <ul>
                                        {this.props.RootStore.agenciesStore.validatedArchiveAgencies?.map(
                                            (agency) =>
                                                !agency.relatedStaff &&
                                                !agency.relatedPayRate && (
                                                    <li>
                                                        <a
                                                            target={'_blank'}
                                                            rel={'noreferrer'}
                                                            href={`/hr/agencies/${agency.id}`}
                                                        >
                                                            <b> {agency.name} </b>
                                                        </a>
                                                    </li>
                                                ),
                                        )}
                                    </ul>
                                </div>
                            }
                        />
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._handleBulkArchive}
                            onCancel={this._handleToggleArchive}
                            isOpen={this.state.showArchiveAlert}
                            title="Confirm archiving!"
                            icon={'archive'}
                            intent={'warning'}
                            description="Are you sure you want to archive the selected agency/agencies? This action is irreversible"
                        />
                        <Alert
                            confirmButtonText="OK"
                            intent={'primary'}
                            isOpen={this.state.showDeniedArchiveAlert}
                            onClose={() => {
                                this.setState({ showDeniedArchiveAlert: false });
                            }}
                        >
                            <p>
                                The selected agency/agencies cannot be archived because one or more
                                of their staff record(s) and/or pay rate(s) are still active. Review
                                and archive related entities first
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default Agencies;
