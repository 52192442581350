import React, { useEffect, useState } from 'react';
import { Button, Icon, InputGroup } from '@blueprintjs/core';
import { useRef } from 'react';
import type { Identifier, XYCoord } from 'dnd-core';
import { useDrag, useDrop } from 'react-dnd';

interface DragItem {
    index: number;
    id: string;
    type: string;
}

const ItemTypes = {
    LIST_ITEM: 'listItem',
};

const InductionTasksItem = ({
    newId,
    name,
    isEdit,
    deletedBy,
    handleOnDelete,
    handleOnEdit,
    index,
    handleMove,
}: any) => {
    const ref = useRef<HTMLDivElement>(null);
    const [editItem, setEditItem] = useState(false);
    const [nameValue, setNameValue] = useState(name);

    useEffect(() => {
        return () => {
            setNameValue(name);
            setEditItem(false);
        };
    }, [isEdit]); // eslint-disable-line react-hooks/exhaustive-deps

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
        accept: ItemTypes.LIST_ITEM,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            handleMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: isEdit ? ItemTypes.LIST_ITEM : 'null',
        item: () => {
            return { newId, index };
        },
        collect: (monitor: any) => {
            return {
                isDragging: monitor.isDragging(),
            };
        },
    });

    const opacity = isEdit && isDragging ? 0 : 1;
    const cursor = isEdit ? { cursor: 'grab' } : { cursor: 'auto' };
    drag(drop(ref));

    return !deletedBy ? (
        <div
            className="induction-tasks__row"
            ref={ref}
            draggable={false}
            style={{ ...cursor, opacity }}
            data-handler-id={handlerId}
        >
            {editItem && (
                <div style={{ width: '90%' }}>
                    <InputGroup
                        aria-label="Task"
                        id="name-input"
                        placeholder="Type the task here"
                        min={3}
                        value={nameValue}
                        onChange={(e) => {
                            setNameValue(e.target.value);
                        }}
                    />
                </div>
            )}
            {!editItem && <div className="induction-tasks__td-name">{name}</div>}

            {isEdit && !editItem && (
                <div className="induction-tasks__td-actions">
                    <Icon
                        className="induction-tasks__td-action"
                        onClick={() => {
                            setEditItem(!editItem);
                        }}
                        icon="edit"
                    />
                    <Icon
                        className="induction-tasks__td-action"
                        onClick={() => handleOnDelete(newId)}
                        icon="trash"
                        intent={'danger'}
                    />
                </div>
            )}
            {isEdit && editItem && (
                <div className="induction-tasks__td-actions">
                    <Button
                        icon="tick"
                        outlined={true}
                        intent="success"
                        className={`induction-tasks__td-action`}
                        onClick={() => {
                            handleOnEdit(newId, nameValue);
                            setEditItem(!editItem);
                        }}
                    />
                    <Button
                        icon="cross"
                        outlined={true}
                        intent="warning"
                        className={`induction-tasks__td-action`}
                        onClick={() => {
                            setEditItem(!editItem);
                            setNameValue(name);
                        }}
                    />
                </div>
            )}
        </div>
    ) : null;
};

export default InductionTasksItem;
