import { gql } from '@apollo/client';

export const GET_COST_CENTRES = gql`
    query GetCostCentres(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: CostCentreFilter
        $query: String
    ) {
        getCostCentres(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    code
                    isArchived
                    createdAt
                    updatedAt
                    departments {
                        id
                        name
                    }
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedPerson {
                        firstName
                        lastName
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_ALL_COST_CENTRES_LIST = gql`
    query GetALLCostCentresList(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: CostCentreFilter
        $query: String
    ) {
        getCostCentres(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    code
                    isArchived
                }
            }
        }
    }
`;

export const GET_COST_CENTRE_BY_ID = gql`
    query GetCostCentreById($id: String!) {
        getCostCentreById(id: $id) {
            id
            name
            code
            isArchived
            createdAt
            updatedAt
            departments {
                id
                name
            }
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
        }
    }
`;

export const GET_COST_CENTRE_BY_DEPARTMENT_ID = gql`
    query GetCostCentreByDepartmentId($id: String!) {
        getCostCentreByDepartmentId(id: $id) {
            id
            name
            code
        }
    }
`;

export const CREATE_COST_CENTRE = gql`
    mutation CreateCostCentre($data: CostCentreEntityInput!) {
        createCostCentre(data: $data) {
            id
            name
            code
            isArchived
            createdAt
            updatedAt
            departments {
                id
                name
            }
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_COST_CENTRE = gql`
    mutation UpdateCostCentre($id: String!, $data: CostCentreEntityInput!) {
        updateCostCentre(id: $id, data: $data) {
            id
            name
            code
            isArchived
            createdAt
            updatedAt
            departments {
                id
                name
            }
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
        }
    }
`;

export const ARCHIVE_COST_CENTRE = gql`
    mutation ArchiveCostCentre($id: String!) {
        archiveCostCentre(id: $id) {
            id
        }
    }
`;

export const BULK_ARCHIVE_COST_CENTRES = gql`
    mutation BulkArchiveCostCentre($ids: [String!]!) {
        bulkArchiveCostCentre(ids: $ids) {
            count
        }
    }
`;

export const VALIDATE_COST_CENTRE_UNIQUE = gql`
    query isCostCentreUnique($name: String!, $code: String!) {
        isCostCentreUnique(name: $name, code: $code) {
            isNameUnique
            isCodeUnique
        }
    }
`;

export const IS_COST_CENTRE_ARCHIVABLE = gql`
    query isCostCentreArchivable($ids: [String!]!) {
        isCostCentreArchivable(ids: $ids) {
            id
            name
            isArchivable
        }
    }
`;
