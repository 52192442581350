import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Formik, Form } from 'formik';
import { Tab, Tabs, Alert } from '@blueprintjs/core';

import Modal from '../../modal/Modal';
import FundingPoolsSummary from './FundingPoolsSummary';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { RouterPrompt } from '../../../common/RouterPrompt';

import RelatedEntities from '../../../common/RelatedEntities';
import { Link } from 'react-router-dom';
import AppToaster from '../../helpers/Toaster';

const FundingPoolsDetails = inject('RootStore')(
    observer(
        class FundingPoolsDetails extends Component {
            state = {
                loadingRelatedEntries: false,
                isEdit: false,
                showFooter: true,
                activeTab: 1,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                    isArchived: false,
                    relatedRotas: [] as any,
                    relatedShiftPatterns: [] as any,
                },
                listOfUsedFundingPoolNames: [] as any,
                changesNotSaved: false,
                isLoaded: false,
                showAlert: false,
                showRelatedAlert: false,
                showUpdateAlert: false,
                showCancelAlert: false,
                values: {},
            };

            async componentDidMount() {
                const actionType = (this.props as any).actionType;
                const editModeOpenedFromViewList = (this.props as any).location.state
                    ?.editModeOpenedFromViewList;
                if (actionType === 'create') {
                    this.setState({ isEdit: true });
                } else {
                    this._updateFundingPool(editModeOpenedFromViewList);
                }
                const fundingPoolsNames = await (this
                    .props as any).RootStore.fundingPoolStore.getFundingPoolsOptions();
                const fundingPoolsNamesNormalized = fundingPoolsNames
                    .filter((item: any) => !item.isArchived)
                    .map((item: any) => ({ name: item.label, id: item.value }));
                this.setState({ listOfUsedFundingPoolNames: fundingPoolsNamesNormalized });
            }

            _updateFundingPool = async (editModeOpenedFromViewList: boolean) => {
                const { match } = this.props as any;
                const { id } = match?.params;
                const fundingPool = await (this
                    .props as any).RootStore.fundingPoolStore.getFundingPoolById(id);
                if (fundingPool) {
                    this.setState({
                        initialData: { ...fundingPool },
                        isEdit: editModeOpenedFromViewList ? true : false,
                        isLoaded: true,
                        showFooter: !fundingPool.isArchived,
                    });
                }
            };

            _handleCloseClick = () => {
                if ((this.props as any).actionType === 'create') {
                    return (this.props as any).history.replace('/hr/funding-pools');
                }
                return (this.props as any).history.goBack();
            };

            _handleCloseAlert = () => {
                this.setState({
                    showAlert: false,
                    showRelatedAlert: false,
                    showUpdateAlert: false,
                });
            };

            _handleOnArchive = async () => {
                const { relatedRotas, relatedShiftPatterns } = this.state.initialData;

                if (relatedRotas.length || relatedShiftPatterns.length) {
                    this.setState({ showRelatedAlert: true });
                } else {
                    this.setState({ showAlert: true });
                }
            };

            _archiveFundingPool = async () => {
                const { id } = this.state.initialData;
                await (this.props as any).RootStore.fundingPoolStore.archiveFundingPool(id);
                (this.props as any).history.replace('/hr/funding-pools');
            };

            _handleSubmit = async (values: any, { setSubmitting }: any) => {
                const {
                    actionType,
                    RootStore: { fundingPoolStore },
                } = this.props as any;

                this.setState({ changesNotSaved: false });

                if (actionType === 'create') {
                    await fundingPoolStore.createFundingPool(values);
                    (this.props as any).history.replace('/hr/funding-pools');
                } else {
                    const { relatedRotas } = this.state.initialData;
                    if (relatedRotas.length) {
                        this.setState({ showUpdateAlert: true, values });
                    } else {
                        this.setState({ values });
                        this._submitUpdate();
                    }
                }
                setSubmitting(false);
            };

            _submitUpdate = async () => {
                const { fundingPoolStore } = (this.props as any).RootStore;
                await fundingPoolStore.updateFundingPool(this.state.values);
                (this.props as any).history.replace('/hr/funding-pools');
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !(prevState as any).isEdit };
                });
            };

            _handleOnCancel = () => {
                if ((this.props as any).actionType === 'create') {
                    return (this.props as any).history.replace('/hr/funding-pools');
                }
                if ((this.props as any).actionType === 'edit' && !this.state.changesNotSaved) {
                    return this._toggleIsEdit();
                }
                const path = (this.props as any).history.location.pathname;
                (this.props as any).history.replace(path);
            };

            _handleOnCancelConfirm = async (replacePath: string) => {
                await this.setState({ changesNotSaved: false });
                if ((this.props as any).actionType === 'edit') {
                    (this as any).formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                (this.props as any).history.replace(
                    replacePath ? replacePath : '/hr/funding-pools',
                );
            };

            render() {
                const { listOfUsedFundingPoolNames } = this.state;

                if ((this.props as any).actionType !== 'create' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    (this.props as any).actionType === 'create'
                        ? 'Add Funding pool record'
                        : `${this.state.initialData.name}`;

                return (
                    <Formik
                        innerRef={(f) => ((this as any).formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async ({ name }) => {
                            if (name !== this.state.initialData.name) {
                                this.setState({ changesNotSaved: true });
                            } else {
                                this.setState({ changesNotSaved: false });
                            }
                            const errors = {};

                            if (name !== this.state.initialData.name) {
                                const index = listOfUsedFundingPoolNames.findIndex(
                                    (item: any) => item.name === name,
                                );

                                if (index > -1) {
                                    (errors as any).notUniqueId =
                                        listOfUsedFundingPoolNames[index].id;
                                    (errors as any).notUniqueName = name;
                                }
                            }

                            return errors;
                        }}
                        onSubmit={this._handleSubmit}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        this.state.activeTab === 1 ? (
                                            <CommonModalFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onArchive={this._handleOnArchive}
                                                hasArchiveOption={
                                                    this.state.initialData.isArchived ? false : true
                                                }
                                                hasEditOption={
                                                    this.state.initialData.isArchived ? false : true
                                                }
                                            />
                                        ) : null
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="fundingPoolsTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                            this.setState({
                                                loadingRelatedEntries: false,
                                            });
                                        }}
                                        selectedTabId={this.state.activeTab}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                (this.props as any).actionType === 'edit'
                                                    ? 'Summary'
                                                    : ''
                                            }
                                            panel={
                                                <FundingPoolsSummary
                                                    fundingPool={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched as any}
                                                    fundingPools={listOfUsedFundingPoolNames}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                (this.props as any).actionType === 'edit'
                                                    ? `Related entities ${
                                                          this.state.loadingRelatedEntries
                                                              ? '...'
                                                              : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        relatedShiftPatterns={
                                                            this.state.initialData
                                                                .relatedShiftPatterns
                                                        }
                                                        relatedRotas={
                                                            this.state.initialData.relatedRotas
                                                        }
                                                        description={
                                                            'This funding pool is related to the following entities (archived entities are not displayed)'
                                                        }
                                                        hasRelatedShiftPatterns={true}
                                                        hasRelatedRotas={true}
                                                        relatedRotasTitle={
                                                            'Rotas with future shifts'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>

                                    <ConfirmationAlert
                                        confirmButtonText="Archive"
                                        onConfirm={this._archiveFundingPool}
                                        onCancel={this._handleCloseAlert}
                                        isOpen={this.state.showAlert}
                                        title="Confirm archiving!"
                                        description="Are you sure you want to archive this Funding Pool? You won’t be able to recover it."
                                    />
                                    <Alert
                                        confirmButtonText="Ok"
                                        isOpen={this.state.showRelatedAlert}
                                        onClose={this._handleCloseAlert}
                                    >
                                        <>
                                            <p>
                                                This funding pool cannot be archived because it is
                                                selected in future rota shift(s) and/or shift
                                                pattern shift(s). Remove the funding pool from the
                                                related shift(s) to archive it“
                                            </p>
                                            <ul>
                                                {this.state.initialData.relatedRotas.map(
                                                    (item: any) => (
                                                        <li key={item.id}>
                                                            <Link
                                                                to={`/rota/builder/${item.id}`}
                                                                onClick={this._handleCloseAlert}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ),
                                                )}
                                                {this.state.initialData.relatedShiftPatterns.map(
                                                    (item: any) => (
                                                        <li key={item.id}>
                                                            <Link
                                                                to={`/rota/shift-patterns/${item.id}`}
                                                                onClick={this._handleCloseAlert}
                                                            >
                                                                {item.name}
                                                            </Link>
                                                        </li>
                                                    ),
                                                )}
                                            </ul>
                                        </>
                                    </Alert>
                                    <ConfirmationAlert
                                        confirmButtonText="Save"
                                        onConfirm={this._submitUpdate}
                                        onCancel={this._handleCloseAlert}
                                        isOpen={this.state.showUpdateAlert}
                                        icon="refresh"
                                        title="Confirm update!"
                                        description="Are you sure you want to save changes to this Funding Pool? It will affect related shift(s)."
                                    />
                                    <RouterPrompt
                                        when={this.state.changesNotSaved}
                                        onPromptConfirm={this._handleOnCancelConfirm}
                                    />
                                </Modal>
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default FundingPoolsDetails;
