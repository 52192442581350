/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import Select from 'react-select';

export interface IItem {
    label: string;
    value: string;
}

interface IProps {
    onChange: Function;
    onLoadOptions(
        pageSize: number,
        query: string | null,
        lastItem: string | null,
    ): Promise<IItem[]>;
    pageSize?: number;
    values: IItem[];
}

const AsyncSelect: FC<IProps> = ({ onLoadOptions, onChange, pageSize = 10, values }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = useState<IItem[]>([]);
    const [query, setQuery] = useState<string | null>(null);
    const [loadMore, setLoadMore] = useState(false);

    useEffect(() => {
        setLoadMore(true);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (loadMore) {
                setIsLoading(true);
                const lastItem = items.length === 0 ? null : items[items.length - 1].value;
                const { items: newItems } = (await onLoadOptions(pageSize, query, lastItem)) as any;
                setItems([...items, ...newItems]);
                setIsLoading(false);
                setLoadMore(false);
            }
        };
        fetchData();
    }, [loadMore]);

    useEffect(() => {
        if (!query) return;

        setItems([]);
        setLoadMore(true);
    }, [query]);

    return (
        <div>
            <Select
                isMulti
                isLoading={isLoading}
                onMenuScrollToBottom={() => {
                    setLoadMore(true);
                }}
                onInputChange={(val) => {
                    setQuery(val);
                }}
                onChange={(values) => {
                    setQuery(null);
                    setItems([]);
                    setLoadMore(true);

                    if (onChange) onChange(values);
                }}
                onMenuClose={() => {
                    if (query && query.length > 0) {
                        setItems([]);
                        setQuery(null);
                        setLoadMore(true);
                    }
                }}
                options={items}
                value={values}
            />
        </div>
    );
};

export default AsyncSelect;
