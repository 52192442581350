import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { Button, Tag } from '@blueprintjs/core';
import { DateRangePicker } from '@blueprintjs/datetime';

import { getReports, getCompletedJobs } from '../../../api/adminApi';
import AdminTable from './AdminTable';
import { sortDateDescending } from '../../../helpers/sorting';
import InvoiceListAndExport from '../../invoicing/InvoiceListAndExport';

const parseDateFromFileName = (report) => new Date(report.file.replace('.csv', ''));

const AdminReporting = inject('RootStore')(
    observer(
        class AdminReporting extends Component {
            state = {
                reports: null,
                loadingReports: false,
                from: null,
                to: null,
                loadingCompletedJobs: false,
            };

            _handleLoadReports = async () => {
                this.setState({ loadingReports: true });

                const userSession = await this.props.RootStore.userStore.getUserSession();
                const response = await getReports(
                    userSession.tokens.id,
                    userSession.organisations[0],
                );

                if (response.status === 502) {
                    this.setState({ loadingReports: false });
                } else {
                    let reports = await response.json();
                    reports = reports.sort((a, b) =>
                        sortDateDescending(parseDateFromFileName(a), parseDateFromFileName(b)),
                    );
                    this.setState({ reports: reports, loadingReports: false });
                }
            };

            _handleDateChange = (event) => {
                const [from, to] = event;
                this.setState({ from, to });
            };

            _handleGetCompletedJobs = async () => {
                this.setState({ loadingCompletedJobs: true });

                const { from, to } = this.state;

                const userSession = await this.props.RootStore.userStore.getUserSession();
                const response = await getCompletedJobs(
                    userSession.tokens.id,
                    userSession.organisations[0],
                    from.toISOString(),
                    to.toISOString(),
                );

                if (response.ok) {
                    const { url } = await response.json();
                    window.location.href = url;
                }

                this.setState({ loadingCompletedJobs: false });
            };

            render() {
                const {
                    RootStore: {
                        configStore: { isFeatureEnabled },
                        userStore: { isComplianceOfficer },
                    },
                } = this.props;
                if (
                    isComplianceOfficer ||
                    (!isFeatureEnabled('onDemandJobs') &&
                        !isFeatureEnabled('scheduledJobs') &&
                        !isFeatureEnabled('vaccinations'))
                ) {
                    return <Redirect to="/page-not-found" />;
                }

                const { reports, loadingReports, from, to, loadingCompletedJobs } = this.state;

                return (
                    <div className="page" data-test="page-admin-reporting">
                        <h1 className="h1">Reporting</h1>
                        <h2 className="h2">
                            Completed jobs
                            <Tag intent="success" className="admin-report__tag" minimal>
                                New!
                            </Tag>
                        </h2>
                        <p>Generate a report of all jobs completed between two dates.</p>
                        <DateRangePicker
                            maxDate={new Date()}
                            onChange={this._handleDateChange}
                            className="admin-report__date-range-picker"
                        />
                        <Button
                            icon="confirm"
                            intent="primary"
                            loading={loadingCompletedJobs}
                            onClick={this._handleGetCompletedJobs}
                            disabled={!from || !to}
                        >
                            Generate Report
                        </Button>{' '}
                        {(!from || !to) && (
                            <span className="admin-report__helper-text">
                                <em>Please select a date range above</em>
                            </span>
                        )}
                        <h2 className="h2 admin-report__audit-reports-heading">Audit reports</h2>
                        <p>
                            Daily reports that contain logs of all jobs created and their outcomes.
                            Dates for which there are no records are excluded.
                        </p>
                        {!Array.isArray(reports) ? (
                            <Button
                                icon="bring-data"
                                intent="primary"
                                loading={loadingReports}
                                onClick={this._handleLoadReports}
                            >
                                Load Reports
                            </Button>
                        ) : reports.length > 0 ? (
                            <AdminTable reports={this.state.reports} />
                        ) : (
                            <span>There are no reports</span>
                        )}
                        {isFeatureEnabled('invoicing') && <InvoiceListAndExport />}
                    </div>
                );
            }
        },
    ),
);

export default AdminReporting;
