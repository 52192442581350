import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';

import * as dates from 'react-big-calendar/lib/utils/dates';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import { inRange } from 'react-big-calendar/lib/utils/eventLevels';
import { isSelected } from 'react-big-calendar/lib/utils/selection';

function CustomAgenda({
    selected,
    getters,
    accessors,
    localizer,
    components,
    length,
    date,
    events,
    handleLengthChange,
}) {
    const headerRef = useRef(null);
    const dateColRef = useRef(null);
    const timeColRef = useRef(null);
    const tbodyRef = useRef(null);

    const renderDay = (day, events, dayKey) => {
        const { event: Event, date: AgendaDate } = components;

        events = events.filter((e) =>
            inRange(e, dates.startOf(day, 'day'), dates.endOf(day, 'day'), accessors),
        );

        return events.map((event, idx) => {
            let title = accessors.title(event);
            let end = accessors.end(event);
            let start = accessors.start(event);

            const userProps = getters.eventProp(event, start, end, isSelected(event, selected));

            let dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat');
            let first =
                idx === 0 ? (
                    <td rowSpan={events.length} className="rbc-agenda-date-cell">
                        {AgendaDate ? <AgendaDate day={day} label={dateLabel} /> : dateLabel}
                    </td>
                ) : (
                    false
                );

            return (
                <tr
                    key={dayKey + '_' + idx}
                    className={userProps.className}
                    style={userProps.style}
                >
                    {first}
                    <td className="rbc-agenda-time-cell">{timeRangeLabel(day, event)}</td>

                    {Event ? <Event event={event} title={title} /> : title}
                </tr>
            );
        }, []);
    };

    const timeRangeLabel = (day, event) => {
        let labelClass = '',
            TimeComponent = components.time,
            label = localizer.messages.allDay;

        let end = accessors.end(event);
        let start = accessors.start(event);

        if (!accessors.allDay(event)) {
            if (dates.eq(start, end)) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (dates.eq(start, end, 'day')) {
                label = localizer.format({ start, end }, 'agendaTimeRangeFormat');
            } else if (dates.eq(day, start, 'day')) {
                label = localizer.format(start, 'agendaTimeFormat');
            } else if (dates.eq(day, end, 'day')) {
                label = localizer.format(end, 'agendaTimeFormat');
            }
        }

        if (dates.gt(day, start, 'day')) labelClass = 'rbc-continues-prior';
        if (dates.lt(day, end, 'day')) labelClass += ' rbc-continues-after';

        return (
            <span className={labelClass.trim()}>
                {TimeComponent ? <TimeComponent event={event} day={day} label={label} /> : label}
            </span>
        );
    };

    let { messages } = localizer;

    let end = length === 1 ? dates.endOf(date, 'day') : dates.add(date, length, 'day');

    let range = dates.range(date, end, 'day');

    events = events.filter((event) => inRange(event, date, end, accessors));

    events.sort((a, b) => +accessors.start(a) - +accessors.start(b));

    return (
        <div className="rbc-agenda-view">
            <ButtonGroup minimal={false} className="agenda-time-btns">
                <Button
                    onClick={() => {
                        handleLengthChange(1);
                    }}
                    className={`${length === 1 ? 'bp5-active' : ''}`}
                >
                    Day
                </Button>
                <Button
                    onClick={() => {
                        handleLengthChange(7);
                    }}
                    className={`${length === 7 ? 'bp5-active' : ''}`}
                >
                    Week
                </Button>
                <Button
                    onClick={() => {
                        handleLengthChange(30);
                    }}
                    className={`${length === 30 ? 'bp5-active' : ''}`}
                >
                    Month
                </Button>
            </ButtonGroup>

            {events.length !== 0 ? (
                <React.Fragment>
                    <table ref={headerRef} className="rbc-agenda-table">
                        <thead>
                            <tr>
                                <th className="rbc-header" ref={dateColRef}>
                                    {messages.date}
                                </th>
                                <th className="rbc-header" ref={timeColRef}>
                                    {messages.time}
                                </th>
                                <th className="rbc-header">Job</th>
                                <th className="rbc-header">Assigned HCP</th>
                                <th className="rbc-header">HCP Type</th>
                                <th className="rbc-header rbc-header--status">Status</th>
                            </tr>
                        </thead>
                        <tbody ref={tbodyRef}>
                            {range.map((day, idx) => renderDay(day, events, idx))}
                        </tbody>
                    </table>
                </React.Fragment>
            ) : (
                <span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
            )}
        </div>
    );
}

CustomAgenda.propTypes = {
    events: PropTypes.array,
    date: PropTypes.instanceOf(Date),
    length: PropTypes.number.isRequired,
    handleLengthChange: PropTypes.func.isRequired,

    selected: PropTypes.object,

    accessors: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    getters: PropTypes.object.isRequired,
    localizer: PropTypes.object.isRequired,
};

CustomAgenda.defaultProps = {
    length: 30,
};

CustomAgenda.range = (start, { length = CustomAgenda.defaultProps.length }) => {
    if (length === 1) {
        return [dates.startOf(start, 'day')]; // Taken from 'Day' component
    }

    let end = dates.add(start, length, 'day');
    return { start, end };
};

CustomAgenda.navigate = (date, action, { length = CustomAgenda.defaultProps.length }) => {
    switch (action) {
        case navigate.PREVIOUS:
            return dates.add(date, -length, 'day');

        case navigate.NEXT:
            return dates.add(date, length, 'day');

        default:
            return date;
    }
};

CustomAgenda.title = (start, { length = CustomAgenda.defaultProps.length, localizer }) => {
    if (length === 1) {
        return localizer.format(start, 'dayHeaderFormat');
    }

    let end = dates.add(start, length, 'day');
    return localizer.format({ start, end }, 'agendaHeaderFormat');
};

export default CustomAgenda;
