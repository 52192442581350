import React from 'react';

import TableHeader from './TableHeader';
import TableRow from './TableRow';

const Table = (props: any) => {
    const {
        extended,
        onSort,
        onToggle,
        onToggleSelectAll,
        allSelected,
        history,
        handleOpenAbsenceRecord,
    } = props;

    const extendedColumns = extended
        ? [
              {
                  key: 'name',
                  sortable: true,
                  render: (item: any) => <span className="absence-name-column">{item.name}</span>,
              },
              { key: 'roles', sortable: false },
              { key: 'departments', sortable: false },
          ]
        : [];

    const columns = [
        ...extendedColumns,
        {
            key: 'absenceType',
            label: 'Absence type',
            sortKey: 'absenceTypeId',
        },
        { key: 'approvalStatus', label: 'Approval status', sortKey: 'statusId' },
        {
            key: 'isArchived',
            label: 'Record status',
            render: (item: any) => {
                const text = item.isArchived ? 'Archived' : 'Active';
                return <span>{text}</span>;
            },
        },
        { key: 'startDateTime', label: 'Starts' },
        { key: 'endDateTime', label: 'Ends' },
        { key: 'createdAt', label: 'Created on' },
        { key: 'createdBy', label: 'Created by' },
        { key: 'updatedAt', label: 'Updated on' },
        { key: 'updatedBy', label: 'Updated by' },
    ];

    const openAbsenceRecord = (id: string, event: any) => {
        if (!event.target.classList.contains('bp5-control-indicator')) {
            // open absence record from person profile page
            if (handleOpenAbsenceRecord) {
                handleOpenAbsenceRecord(id);
            } else {
                history.push(`/hr/absence-management/${id}`);
            }
        }
    };

    return (
        <table className="absence-table bp5-html-table">
            <TableHeader
                columns={columns}
                onSort={onSort}
                selectable
                isFrontendSort={false}
                onToggleSelectAll={onToggleSelectAll}
                allSelected={allSelected}
            />
            <tbody>
                {props.data.map((item: any) => (
                    <TableRow
                        key={item.id}
                        columns={columns}
                        item={item}
                        onClick={openAbsenceRecord}
                        selectable
                        selected={item.selected}
                        onToggle={onToggle}
                    />
                ))}
            </tbody>
        </table>
    );
};

export default Table;
