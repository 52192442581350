import { Vaccination, Hub, Instruction } from '@doc-abode/data-models';
import { timeDifferenceInDays } from '../../../../../modules/helpers/formatData';
import { VaccinationRoute } from '../../../types';
import { routeTypes } from '../../../utils';

/**
 * Code / logic  here was originally in UpdateRoute
 * src/components/pages/vaccinations/components/route/UpdateRoute/UpdateRoute.tsx
 * moved in the first instance to try and work out what's going on and make it easier to test.
 * some of the types may need fixing / updating later.
 */
interface IGetRequiredRouteType {
    vaccinationRouteType: string | undefined;
    vaccinations: Vaccination[];
}
/**
 * todo it may be that vaccinationRouteType can be limited to being one of src/components/pages/vaccinations/utils/constants.ts::routeTypes
 * todo this function is not exhaustive of the possible vaccinationRouteType its just what was used in UpdateRoutes components
 */
export function getRequiredRouteTypeForUpdateRoutes({
    vaccinationRouteType,
    vaccinations,
}: IGetRequiredRouteType): string | undefined {
    if (
        vaccinationRouteType === routeTypes.NON_COVID ||
        vaccinationRouteType === routeTypes.NON_COVID_PLUS_CO_ADMIN
    ) {
        return routeTypes.NON_COVID;
    }

    if (
        vaccinationRouteType === routeTypes.COVID ||
        (!vaccinationRouteType &&
            vaccinations.some(({ vaccinationCategory }) => vaccinationCategory === 'COVID-19'))
    ) {
        return routeTypes.COVID;
    }

    return routeTypes.ANY;
}

interface IGetAvailableHubs {
    vaccinations: Vaccination[];
    hubs: Hub[];
}

/**
 * I do not know what constitutes an available hub.  this is just existing logic refactored into its own function
 */
export function getAvailableHubs({ vaccinations, hubs }: IGetAvailableHubs) {
    return vaccinations.reduce((result, { hubId }) => {
        const hub = hubs.find((hub) => hub.id === hubId);

        if (hub) {
            // todo what is Hub.compatibleWith
            return [...result, hub.id, ...(hub.compatibleWith || [])];
        } else {
            return result;
        }
    }, [] as string[]);
}

interface IGetFilteredRoutesBetterFunctionNameToFollow {
    hubs: Hub[];
    mostRecentPreviousDose: string; // date string?
    vaccinations: Vaccination[];
    vaccinationRoutes: VaccinationRoute[];
    vaccinationRouteType: string | undefined;
}

/**
 * what are the filtered routes.
 * routes that are or are not what?.
 * Speculation:
 * In an acceptable state taking into account route type we require and
 * the minimum days since last does (the earlier we can do the route?)
 *
 * Seems to get consumed as jobs prop in RoutesTable
 * src/components/pages/vaccinations/components/route/RoutesTable.tsx
 */
export function getFilteredRoutesBetterFunctionNameToFollow({
    hubs,
    mostRecentPreviousDose,
    vaccinations,
    vaccinationRoutes,
    vaccinationRouteType,
}: IGetFilteredRoutesBetterFunctionNameToFollow): VaccinationRoute[] {
    const availableHubs = getAvailableHubs({
        vaccinations,
        hubs,
    });
    // each hub id just the once.
    const uniqueHubIds = Array.from(new Set(availableHubs));
    const minDaysSincePreviousDose = calculateMinDaysSincePreviousDose({
        vaccinations,
    });

    const requiredVaccinationRouteType = getRequiredRouteTypeForUpdateRoutes({
        vaccinationRouteType,
        vaccinations,
    });
    return vaccinationRoutes.filter(
        (
            {
                jobStatus,
                hubId,
                itinerary: {
                    route: { startTime },
                },
                routeType,
            } /* we are trying to work something out here.  What that is, is anybody's guess*/,
        ) =>
            // the route type is any or the route type matches the required route type
            (requiredVaccinationRouteType === routeTypes.ANY ||
                routeType === routeTypes.ANY ||
                routeType === requiredVaccinationRouteType) &&
            // route not aborted
            !['CONTROLLER_ABORTED', 'HCP_ABORTED'].includes(jobStatus) &&
            // not sure yet
            uniqueHubIds.includes(hubId) &&
            (!mostRecentPreviousDose ||
                mostRecentPreviousDose === '' ||
                timeDifferenceInDays(mostRecentPreviousDose, startTime) >=
                    minDaysSincePreviousDose),
    );
}

interface ICalculateMinDaysSincePreviousDose {
    vaccinations: Vaccination[];
}

/**
 *
 * Calculates the minimum number of days the next vaccination can be administered based on the
 * previous vaccination for the group as a whole.
 * e.g.
 * group of 3 patients
 * 2 patient are on their 2nd dose
 * 1 patient is on their 3rd dose
 *
 * we need at least 90 days before we can administer these vaccinations as a group.
 * if you want to do the 2 patients on their 2nd does earlier, you have to split them out of the group
 *
 * todo check what about dose 5 or greater - i think our logic needs looking at.
 */
export function calculateMinDaysSincePreviousDose({
    vaccinations,
}: ICalculateMinDaysSincePreviousDose): number {
    if (vaccinations.some(({ doseNumber }) => doseNumber === 3 || doseNumber === 4)) {
        return 90;
    }

    if (vaccinations.some(({ doseNumber }) => doseNumber === 2)) {
        return 28;
    }

    return 0;
}

interface IVaccinationRoute {
    vaccinationRoute: VaccinationRoute;
}
export function vaccinationRouteHasPickup({ vaccinationRoute }: IVaccinationRoute): boolean {
    return vaccinationRoute?.itinerary.instructions.some(
        ({ instructionType }: Instruction) => instructionType === 'Pickup',
    );
}
