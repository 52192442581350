import { gql } from '@apollo/client';

export const GET_SHIFT_PATTERNS = gql`
    query shiftPatterns {
        getShiftPatterns(first: 100) {
            edges {
                node {
                    id
                    name
                    departmentId
                    createdAt
                    updatedAt
                    shiftPatternEntries {
                        id
                        weekNumber
                        dayOfWeek
                        startTime
                        endTime
                        breakDurationMinutes
                        breakIsPaid
                        overrideValueInPence
                        defaultEmployeeId
                        locationId
                        shiftPatternId
                        typeId
                        thirdPartyPaid
                        shiftType {
                            id
                            name
                        }
                        defaultEmployee {
                            id
                            contractTypeId
                            person {
                                id
                                firstName
                                lastName
                            }
                        }
                        location {
                            id
                            name
                            address1
                            address2
                            address3
                            townCity
                            postcode
                        }
                        shiftEmployeeRoleType {
                            id
                            name
                        }
                        payRateId
                        trainingShift
                        trainees {
                            traineeId
                            roleId
                            payRateId
                            overrideValue
                        }
                    }
                }
            }
        }
    }
`;

export const CREATE_ROTA = gql`
    mutation CreateRota($data: RotaEntityInput!, $utcOffset: Int!) {
        createRota(data: $data, utcOffset: $utcOffset) {
            id
        }
    }
`;
