import React from 'react';
import { Checkbox } from '@blueprintjs/core';

const PeopleItem = ({
    onClick,
    id,
    selected,
    firstName,
    lastName,
    middleName,
    onToggle,
    status,
    personStatus,
    updatedAt,
    createdAt,
    createdByPerson,
    updatedByPerson,
}) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                    disabled={personStatus === 'Archived' ? true : false}
                />
            </td>
            <td>{firstName}</td>
            <td>{middleName}</td>
            <td>{lastName}</td>
            <td>{status}</td>
            <td>{personStatus}</td>
            <td></td>
            <td>{createdAt}</td>
            <td>
                {createdByPerson && `${createdByPerson?.firstName}  ${createdByPerson?.lastName}`}
            </td>
            <td>{updatedAt}</td>
            <td>
                {updatedByPerson && `${updatedByPerson?.firstName}  ${updatedByPerson?.lastName}`}
            </td>
        </tr>
    );
};

export default PeopleItem;
