import moment from 'moment';

export const validateOverlap = (customPayRates: any) => {
    if (!customPayRates) return false;
    const overlapping = (a: any, b: any) => {
        const aDays = !a.day || a.day.length === 0 ? [] : a.day.split(',');
        const bDays = !b.day || b.day.length === 0 ? [] : b.day.split(',');

        let dayOverlap = false;

        if (aDays.length === 0 || bDays.length === 0) {
            dayOverlap = true;
        } else {
            const abCollide = aDays.filter((value: any) => bDays.indexOf(value) !== -1);
            dayOverlap = abCollide.length > 0;
        }

        if (dayOverlap) {
            const timeSegments = [];
            timeSegments.push([a.startTime, formatEndTime(a.endTime)]);
            timeSegments.push([b.startTime, formatEndTime(b.endTime)]);
            return checkTimeOverlap(timeSegments);
        }
    };

    let i, j;
    for (i = 0; i < customPayRates.length - 1; i++) {
        for (j = i + 1; j < customPayRates.length; j++) {
            if (overlapping(customPayRates[i], customPayRates[j])) {
                return true;
            }
        }
    }
    return false;
};

export const validateCode = (customPayRates: any) => {
    const codes = customPayRates.map((customPayRate: any) => customPayRate.code);
    const uniqueCodes = [...(new Set(codes) as any)];
    return codes.length !== uniqueCodes.length;
};

export const isDateTimeOverlap = (
    start1: Date,
    end1: Date,
    start2: Date,
    end2: Date,
    timeOnly: boolean = false,
) => {
    const s1 = moment(start1);
    const e1 = moment(end1);
    const s2 = moment(start2);
    const e2 = moment(end2);

    if (!timeOnly && !s1.isSame(s2, 'day')) return false;

    const segments = [
        [s1.format('HH:mm'), formatEndTime(e1.format('HH:mm'))],
        [s2.format('HH:mm'), formatEndTime(e2.format('HH:mm'))],
    ];

    return checkTimeOverlap(segments);
};

const checkTimeOverlap = (timeSegments: any) => {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1: any, timeSegment2: any) =>
        timeSegment1[0].localeCompare(timeSegment2[0]),
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
        const currentEndTime = timeSegments[i][1];
        const nextStartTime = timeSegments[i + 1][0];

        if (currentEndTime > nextStartTime) {
            return true;
        }
    }

    return false;
};

const formatEndTime = (t: string) => {
    const spl = t.split(':');

    if (spl.length !== 2) return t;

    return spl[0] === '00' ? `24:${spl[1]}` : t;
};
