import { FC, ReactNode } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../../../../hook/useStores';
import { TagInputComponent } from '../../../../../v2/form/TagInput/TagInput';
import { Checkbox } from '@blueprintjs/core';
import { CommonControls } from '../../CommonControls';

export const PatientListViewControlsV2: FC<{ refreshJobs(): void }> = observer(() => {
    const {
        RootStore: {
            patientListStoreV2: {
                includeDischarged,
                setNameOrNhsNumber,
                setIncludeDischarged,
                filter,
            },
        },
    } = useStores() as { RootStore: any };

    const setNameAndNhsFilter = (values: ReactNode[]) => {
        const filtered = values.filter(
            (value): value is string => typeof value === 'string' && value.length !== 0,
        );
        setNameOrNhsNumber(filtered.length !== 0 ? filtered : []);
    };

    return (
        <div className="ucr__main-filters">
            <CommonControls
                disableDatePicker
                disableAddVisit
                disableAddAdmin
                additionalLeft={
                    <>
                        <TagInputComponent
                            name="patientName"
                            className="ucr__filters-filter-input ucr__filters-filter-first"
                            placeholder="Patient"
                            values={filter.nameOrNhsNumber || []}
                            onChange={(values) => setNameAndNhsFilter(values)}
                        />
                        {false && (
                            <div className="ucr__controls-button">
                                <Checkbox
                                    label="Show discharged patients"
                                    checked={Boolean(includeDischarged)}
                                    onChange={(e) => {
                                        setIncludeDischarged(includeDischarged ? false : true);
                                    }}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            </div>
                        )}
                    </>
                }
            />
        </div>
    );
});
