import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@blueprintjs/core';

import AppToaster from '../helpers/Toaster';

const Input = ({ onboardingId, getFileUploadParams, uploadFile, confirmFileUpload }) => {
    const [selectedFile, setSelectedFile] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const onDrop = useCallback(
        async ([file]) => {
            const fileName = file.name;
            setSelectedFile(fileName);

            try {
                setIsUploading(true);
                const uploadParams = await getFileUploadParams(onboardingId);

                await uploadFile(file, uploadParams);

                await confirmFileUpload(onboardingId, uploadParams.uploadId, {
                    category: 'MISC',
                    fileName,
                });

                AppToaster.show({
                    message: 'File uploaded successfully!',
                    intent: 'success',
                });
            } catch (err) {
                console.error(err);
                AppToaster.show({
                    message: 'There was an error uploading the file, please try again.',
                    intent: 'danger',
                });
            }
            setIsUploading(false);
        },
        [onboardingId, getFileUploadParams, uploadFile, confirmFileUpload],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="file-upload">
            {isUploading ? (
                <div className="file-upload__input file-upload__input--uploading">
                    Uploading {selectedFile}...
                </div>
            ) : (
                <label htmlFor="fileUpload">
                    <div {...getRootProps()} className="file-upload__input">
                        <input name="fileUpload" {...getInputProps()} multiple={false} />
                        <span className="opacity-75">
                            <Icon icon="cloud-upload" className="file-upload__icon" />
                            {isDragActive
                                ? 'Drop the file here...'
                                : 'To upload a new file, drag and drop it here, or click to choose...'}
                        </span>
                    </div>
                </label>
            )}
        </div>
    );
};

export default Input;
