import { observable, action, makeObservable, toJS, computed } from 'mobx';

import { OrderDirection, PatientOrder, Patient } from '../../__generated__/v2';

export enum LIST_STATE {
    PATIENTS_TO_LIST = 'This is not intentionally blank',
    NO_PATIENTS = 'There are no patient records yet',
    NO_PATIENTS_IN_FILTER = 'There are no patient records matching your criteria',
    LOADING = 'Loading patient records',
}

export enum SORT_COLUMN {
    NHS_NUMBER = 'nhsNumber',
    NAME = 'name',
    DOB = 'dateOfBirth',
    GENDER = 'gender',
    POSTCODE = 'postcode',
    LAST_COMPLETED_VISIT = 'lastCompletedJob',
    UPCOMING_VISIT = 'nextUpcomingJob',
    ADMISSIONS = 'admissions',
}

type Sorts = { [key in SORT_COLUMN]?: OrderDirection };

class PatientListStoreV2 {
    constructor() {
        makeObservable(this, {
            patients: observable,
            loading: observable,
            setIncludeDischarged: action,
            clearAllFilters: action,
            setSort: action,
            setNameOrNhsNumber: action,
            listPatients: computed,
            filter: computed,
            state: observable,
            sorts: observable,
            sortsGql: computed,
            clearPatients: action,
            includeDischarged: observable,
            nameOrNhsNumber: observable,
            appliedFilters: computed,
        });
    }

    patients: Patient[] = [];
    loading = true;
    sorts: Sorts = {};
    state = LIST_STATE.LOADING;
    includeDischarged = false;
    nameOrNhsNumber: string[] = [];

    setIncludeDischarged = (value: boolean) => {
        this.patients = [];
        this.includeDischarged = value;
    };

    setNameOrNhsNumber = (value: string[]) => {
        this.patients = [];
        this.nameOrNhsNumber = [...value];
    };

    clearAllFilters = () => {
        this.includeDischarged = false;
    };

    setSort = (sort: SORT_COLUMN) => {
        this.patients = [];
        const { [sort]: oldOrder } = this.sorts;
        let newOrder: OrderDirection | undefined;

        if (!oldOrder) {
            newOrder = OrderDirection.Asc;
        } else if (oldOrder === OrderDirection.Asc) {
            newOrder = OrderDirection.Desc;
        } else {
            newOrder = undefined;
        }

        this.sorts = {
            [sort]: newOrder,
        };
    };

    clearPatients = () => {
        this.patients = [];
    };

    setPatients = (items: Patient[]) => {
        this.patients = [...toJS(this.patients), ...items];

        if (this.patients.length === 0) {
            this.state = LIST_STATE.NO_PATIENTS;
        } else {
            this.state = LIST_STATE.PATIENTS_TO_LIST;
        }
    };

    get listPatients() {
        return toJS(this.patients);
    }

    get filter() {
        return {
            nameOrNhsNumber: this.nameOrNhsNumber,
            includeDischarged: this.includeDischarged,
        };
    }

    get appliedFilters() {
        return this.includeDischarged === true;
    }

    get sortsGql(): PatientOrder {
        const keys = Object.keys(this.sorts) as SORT_COLUMN[];
        if (keys.length === 0 || !this.sorts[keys[0]]) {
            return { field: 'createdAt', direction: OrderDirection.Desc };
        } else {
            let columnName: string = keys[0];
            switch (columnName) {
                case 'name':
                    columnName = 'person.lastName';
                    break;
                case 'dateOfBirth':
                    columnName = 'person.dateOfBirth';
                    break;
                case 'gender':
                    columnName = 'person.gender.name';
            }
            return { field: columnName, direction: this.sorts[keys[0]] || OrderDirection.Desc };
        }
    }
}

export default PatientListStoreV2;
