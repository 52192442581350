import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

const LocationItem = ({
    onClick,
    id,
    name,
    address1,
    address2,
    address3,
    townCity,
    postcode,
    isArchived,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    selected,
    onToggle,
    dictionaryStore,
}) => {
    const peopleList = dictionaryStore.persons.reduce((acc, item) => {
        acc[item.value] = item.label;
        return acc;
    }, {});

    const createdByPerson = peopleList[createdBy] || '';
    const updatedByPerson = peopleList[updatedBy] || '';

    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    disabled={isArchived ? true : false}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{name}</td>
            <td>{address1}</td>
            <td>{address2}</td>
            <td>{address3}</td>
            <td>{townCity}</td>
            <td>{postcode}</td>
            <td>{isArchived ? 'Archived' : 'Active'}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>{createdByPerson}</td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
            <td>{updatedByPerson}</td>
        </tr>
    );
};

export default LocationItem;
