import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Loader from '../../../modules/helpers/Loader';
import InductionTasksContainer from './InductionTasksContainer';
import InductionTasksDialog from './InductionTasksDialog';
import { Alert, Button, Classes, Dialog, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { v4 as uuid_v4 } from 'uuid';
import Provider from '../../../../providers/DndProvider';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../../modules/helpers/Toaster';

export interface InductionTasksInterface {
    id: string;
    name: string;
    newId?: string;
    orderNumber: number;
    isDeleted: boolean;
}

const InductionTasks = inject('RootStore')(
    observer(
        class InductionTasks extends Component<any> {
            state = {
                inductionTasks: [],
                inductionTasksUpdated: [],
                initialTasksSorted: [],
                isEdit: false,
                hasError: false,
                isAdd: false,
                showDeleteModal: false,
                showCloseModal: false,
                showSaveModal: false,
                showUpdateAlert: false,
                name: '',
                newTask: '',
                deleteId: '',
                editId: '',
                disabledSubmit: false,
                formWasEdited: false,
            };

            async componentDidMount() {
                await this.props.RootStore.inductionTasksStore.getInductionTasks();
                this._getInductionTasks();
            }

            _getInductionTasks = () => {
                const inductionTasks = this.props.RootStore.inductionTasksStore.inductionTasks;
                const inductionTasksUpdated = inductionTasks.map(
                    (task: InductionTasksInterface) => ({
                        name: task.name,
                        id: task.id,
                        newId: uuid_v4(),
                        orderNumber: task.orderNumber,
                        isDeleted: task.isDeleted,
                    }),
                );
                inductionTasksUpdated.sort(
                    (a: InductionTasksInterface, b: InductionTasksInterface) =>
                        a.orderNumber - b.orderNumber,
                );
                this.setState({
                    inductionTasks: inductionTasks,
                    inductionTasksUpdated: inductionTasksUpdated,
                    initialTasksSorted: inductionTasksUpdated,
                });
            };

            async componentWillUnmount() {
                this.props.RootStore.inductionTasksStore.clear();
            }

            _toggleIsEdit = () => {
                this.setState((prevState: any) => {
                    return { isEdit: !prevState.isEdit, isAdd: false, disabledSubmit: false };
                });
            };

            _handleOnAdd = () => {
                const { inductionTasksUpdated } = this.state;
                const maxOrderNumber = Math.max.apply(
                    Math,
                    inductionTasksUpdated.map((i: InductionTasksInterface) => i.orderNumber),
                );
                const newInductionTasksUpdated = [
                    ...this.state.inductionTasksUpdated,
                    {
                        newId: uuid_v4(),
                        name: this.state.newTask,
                        isDeleted: false,
                        orderNumber: maxOrderNumber + 1,
                    },
                ];
                this.setState({
                    inductionTasksUpdated: newInductionTasksUpdated,
                    hasError: false,
                });

                this.setState({
                    newTask: '',
                    editId: '',
                    newId: '',
                    formWasEdited: true,
                });
            };

            _handleShowForm = () => {
                this.setState({
                    isAdd: true,
                });
            };

            _handleOnClear = () => {
                this.setState({
                    newTask: '',
                    isAdd: false,
                });
            };

            _handleOnCancel = () => {
                this.props.history.replace('/hr/induction-tasks');
                !this.state.formWasEdited && this._toggleIsEdit();
            };

            _handleOnCancelConfirm = async () => {
                await this.setState({ formWasEdited: false });
                this.setState({
                    inductionTasksUpdated: this.state.initialTasksSorted,
                });
                this._toggleIsEdit();
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace('/hr/induction-tasks');
            };

            _handleOnSave = async () => {
                !this.state.formWasEdited
                    ? this._toggleIsEdit()
                    : this.setState({
                          showSaveModal: true,
                      });
            };

            _updateInductionTasks = async (updateForCurrentStaff: boolean) => {
                this.setState({ disabledSubmit: true });
                await this.props.RootStore.inductionTasksStore.updateInductionTasks(
                    updateForCurrentStaff,
                    this.state.inductionTasksUpdated,
                );
                this.setState({
                    showSaveModal: false,
                    formWasEdited: false,
                    showUpdateAlert: true,
                    isAdd: false,
                });
                this._getInductionTasks();
                this._toggleIsEdit();
            };

            _deleteConfirmation = (newId: any) => {
                this.setState({
                    showDeleteModal: true,
                    deleteId: newId,
                });
            };
            _handleOnDelete = () => {
                const filteredTasks = this.state.inductionTasksUpdated.map(
                    (task: InductionTasksInterface) =>
                        task.newId === this.state.deleteId ? { ...task, isDeleted: true } : task,
                );
                this._getInductionTasks();
                this.setState({
                    showDeleteModal: false,
                    deleteId: '',
                    inductionTasksUpdated: filteredTasks,
                    formWasEdited: true,
                });
            };

            _handleOnEdit = (editId: any, value: string) => {
                const filteredTasks = this.state.inductionTasksUpdated.filter(
                    (task: InductionTasksInterface) => task.newId !== editId,
                );
                const editTask: any = this.state.inductionTasksUpdated.find(
                    (task: InductionTasksInterface) => task.newId === editId,
                );
                editTask.name = value;
                this.setState({
                    inductionTasksUpdated: [editTask, ...filteredTasks],
                    formWasEdited: true,
                });
            };

            _movelistItem = (dragIndex: any, hoverIndex: any) => {
                const dragItem = this.state.inductionTasksUpdated[dragIndex];

                if (dragItem) {
                    this.setState((prev: any) => {
                        const coppiedTasksArray = [...prev.inductionTasksUpdated];

                        const prevItem = coppiedTasksArray.splice(hoverIndex, 1, dragItem);

                        coppiedTasksArray.splice(dragIndex, 1, prevItem[0]);

                        return { inductionTasksUpdated: coppiedTasksArray };
                    });
                }
            };

            render() {
                const {
                    RootStore: {
                        inductionTasksStore: { loaded },
                    },
                } = this.props;

                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main induction-tasks">
                            <h2 className="induction-tasks__title">Induction checklist tasks</h2>
                            {this.state.isEdit && (
                                <>
                                    <div className="induction-tasks__add">
                                        <Button
                                            large
                                            onClick={this._handleShowForm}
                                            icon="add"
                                            intent="success"
                                            outlined
                                        >
                                            Add Task
                                        </Button>
                                    </div>

                                    {this.state.isAdd && (
                                        <div className="induction-tasks__form">
                                            <FormGroup>
                                                <InputGroup
                                                    aria-label="Task"
                                                    id="name-input"
                                                    placeholder="Type the task here"
                                                    min={3}
                                                    value={this.state.newTask}
                                                    onChange={(e) => {
                                                        this.setState({ newTask: e.target.value });
                                                    }}
                                                />
                                                {this.state.hasError && (
                                                    <div className="induction-tasks__form-error">
                                                        Fill in the field to add the task
                                                    </div>
                                                )}
                                            </FormGroup>
                                            <div className="induction-tasks__form-actions">
                                                <Button
                                                    disabled={this.state.newTask?.length < 2}
                                                    icon="tick"
                                                    outlined={true}
                                                    intent="success"
                                                    className={`induction-tasks__form-action`}
                                                    onClick={this._handleOnAdd}
                                                />
                                                <Button
                                                    icon="cross"
                                                    outlined={true}
                                                    intent="warning"
                                                    className={`induction-tasks__form-action`}
                                                    onClick={this._handleOnClear}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            <div className="bp5-html-table bp5-interactive common-table">
                                <Provider>
                                    <InductionTasksContainer
                                        items={this.state.inductionTasksUpdated}
                                        isEdit={this.state.isEdit}
                                        handleOnDelete={this._deleteConfirmation}
                                        handleOnEdit={this._handleOnEdit}
                                    />
                                </Provider>
                            </div>
                            <div className="induction-tasks__footer">
                                {this.state.isEdit && (
                                    <>
                                        <Button
                                            large
                                            onClick={this._handleOnCancel}
                                            icon="cross"
                                            outlined
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            intent="success"
                                            large
                                            onClick={this._handleOnSave}
                                            icon={'floppy-disk'}
                                            type="submit"
                                            outlined
                                            disabled={this.state.disabledSubmit}
                                        >
                                            Save
                                        </Button>
                                    </>
                                )}
                                {!this.state.isEdit && (
                                    <Button
                                        large
                                        onClick={this._toggleIsEdit}
                                        icon="edit"
                                        intent="primary"
                                        outlined
                                    >
                                        Edit details
                                    </Button>
                                )}
                            </div>
                        </main>
                        <InductionTasksDialog
                            title={'Confirm deletion'}
                            text={'Are you sure you want to delete the task?'}
                            isOpen={this.state.showDeleteModal}
                            onSave={this._handleOnDelete}
                            cancelText="No"
                            saveText="Yes"
                            onCancel={() =>
                                this.setState({
                                    showDeleteModal: false,
                                })
                            }
                        />
                        <Dialog
                            onClose={() => {
                                this.setState({
                                    showSaveModal: false,
                                });
                            }}
                            title={'Confirm saving'}
                            isOpen={this.state.showSaveModal}
                        >
                            <div className={Classes.DIALOG_BODY}>
                                <p>
                                    Do you want to apply the changes to all current staff members?
                                </p>
                            </div>
                            <div className={`${Classes.DIALOG_FOOTER}`}>
                                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                                    <Button
                                        style={{ textAlign: 'center' }}
                                        intent={Intent.SUCCESS}
                                        onClick={() => {
                                            this.setState({
                                                formWasEdited: false,
                                            });
                                            this._updateInductionTasks(true);
                                        }}
                                    >
                                        Apply for all current staff members
                                    </Button>

                                    <Button
                                        style={{ textAlign: 'center' }}
                                        intent={Intent.PRIMARY}
                                        onClick={() => {
                                            this.setState({
                                                formWasEdited: false,
                                            });
                                            this._updateInductionTasks(false);
                                        }}
                                    >
                                        Apply for new staff members only
                                    </Button>
                                </div>
                            </div>
                        </Dialog>
                        <Alert
                            confirmButtonText="OK"
                            isOpen={this.state.showUpdateAlert}
                            onClose={() => {
                                this.setState({ showUpdateAlert: false });
                            }}
                        >
                            <p>
                                Person records will be updated in background, you can continue using
                                the application or close the browser. Changes could take some time
                                to be reflected in user's induction checklist.
                            </p>
                        </Alert>

                        <RouterPrompt
                            when={this.state.formWasEdited}
                            onPromptConfirm={this._handleOnCancelConfirm}
                        />
                    </>
                );
            }
        },
    ),
);

export default InductionTasks;
