import moment from 'moment';

const SystmOneAppointmentBookParser = (rawContent) => {
    const lines = rawContent
        .replace(/\t/, '\n')
        .replace(/^\n/, '')
        .split('\n')
        .map((string) => string.trim());

    const nameParts = lines[0].split(' ');
    const firstName = nameParts.length > 2 ? nameParts[1] : undefined;
    const lastName = nameParts.length > 2 ? nameParts[nameParts.length - 1] : nameParts[1];

    const dateOfBirth = moment.utc(lines[1], 'DD MMM YYYY');
    const age = String(moment.utc().diff(dateOfBirth, 'years'));

    let nhsNumber;
    let nhsNumberIndex;

    lines.find((line, index) => {
        const match = line.startsWith('NHS');

        if (match) {
            nhsNumber = line.split('NHS:')[1]?.trim() || undefined;
            nhsNumberIndex = index;
        }

        return match;
    });

    const addressParts = lines[2].split(', ');
    const addressLine1 = addressParts[0];
    const addressLine2 = addressParts.length >= 3 ? addressParts[1] : undefined;
    const town = addressParts[addressParts.length - 1];

    const postCode = lines[3];

    let contactNumber;
    let notes;

    [lines[nhsNumberIndex - 1], lines[nhsNumberIndex - 2]].forEach((line) => {
        if (/^\d{11}$/.test(line.replace(/\s/g, '').replace(/^\+44/, '0'))) {
            contactNumber = line.replace(/\s/g, '').replace(/^0/, '+44');
        } else if (lines.indexOf(line) > 3) {
            notes = line;
        }
    });

    return {
        firstName,
        lastName,
        dateOfBirth: dateOfBirth.format(),
        age,
        contactNumber,
        nhsNumber,
        addressLine1,
        addressLine2,
        town,
        postCode,
        notes,
    };
};

export default SystmOneAppointmentBookParser;
