import { FormikValues } from 'formik';
import { VisitData } from '../../components/pages/ucr/forms/AddVisit/AddVisitTypes';

const fixDateFields = (values: FormikValues): void => {
    const { availableFrom, availableTo, dateOfBirth, dateOfVisit, visitDate, startTime } = values;
    values.dateOfBirth = typeof dateOfBirth === 'string' ? new Date(dateOfBirth) : dateOfBirth;
    values.visitDate = visitDate || new Date(dateOfVisit);

    values.availableFrom =
        availableFrom && typeof availableFrom === 'string'
            ? new Date(availableFrom)
            : availableFrom;
    values.availableTo =
        availableTo && typeof availableTo === 'string' ? new Date(availableTo) : availableTo;
    values.startTime = startTime && typeof startTime === 'string' ? new Date(startTime) : startTime;
};

export const getFollowUpAdminTimeValues = (values: FormikValues): Partial<VisitData> => {
    const extraFields = [
        'id',
        'itineraryId',
        'arrivedDateTime',
        'buddyArrivedDateTime',
        'buddyFinishedDateTime',
        'buddyAbortedBy',
        'buddyControllerReverseNotes',
        'buddyControllerReverseReason',
        'buddyHcpReverseNotes',
        'buddyHcpReverseReason',
        'buddyId',
        'buddyJobStatus',
        'buddyJobStatusBeforeAbort',
        'carRequired',
        'careComplexity',
        'controllerAbortedNotes',
        'controllerAbortedReason',
        'withdrawnReason',
        'withdrawnNotes',
        'vaccineBatchNumber',
        'vaccineExpiryDate',
        'coAdministeredWith',
        'earliestDateOfVisit',
        'consented',
        'dateOfDose1',
        'dateOfDose2',
        'dateOfDose3',
        'dateOfDose4',
        'doseNumber',
        'finishedDateTime',
        'flags',
        'hcpAbortedNotes',
        'hcpAbortedReason',
        'hcpName',
        'manufacturerOfDose1',
        'manufacturerOfDose2',
        'manufacturerOfDose3',
        'manufacturerOfDose4',
        'dateOfDose3Primary',
        'practice',
        'priceInPence',
        'vaccinationCategory',
        'vaccineManufacturer',
        'vaccineSiteOfAdministration',
        'buddyControllerAbortedReason',
        'buddyControllerAbortedNotes',
        'buddyHcpAbortedReason',
        'buddyHcpAbortedNotes',
        'postVisitNotes',
        'postVisitNotesBuddy',
        'markedCompletedByController',
        'markedCompletedByControllerNotes',
        'hcpAbortedDateTime',
        'lastUpdatedDateTime',
        'vaccinationType',
        'jobStatus',
        '__typename',
    ];
    const fieldsShouldBeString = [
        'addressLine2',
        'addressLine3',
        'referredBy',
        'postCode',
        'notes',
    ];
    fixDateFields(values);
    const visitValues: FormikValues = {
        ...values,
        initialVisit: values.id,
        jobType: 'ucr',
    };

    extraFields.forEach((propName) => propName in visitValues && delete visitValues[propName]);
    fieldsShouldBeString.forEach(
        (propName) => (visitValues[propName] = visitValues[propName] ? visitValues[propName] : ''),
    );
    return visitValues;
};
