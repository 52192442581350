import React, { Component } from 'react';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import SpecialitiesTableRow from './SpecialitiesTableRow';
import SpecialitiesTableHeader from './SpecialitiesTableHeader';
import SpecialitiesTableNav from './SpecialitiesTableNav';
import Loader from '../../../../modules/helpers/Loader';
import SpecialitiesDetails from '../../../../modules/specialities/detail/SpecialitiesDetails';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';

const Specialities = inject('RootStore')(
    observer(
        class AdminManageSpecialities extends Component {
            state = {
                showAlert: false,
            };

            componentWillUnmount() {
                this.props.RootStore.specialitiesStore.clear();
            }

            _handleRowClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/specialities/${id}`);
                }
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/specialities/new`);
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.specialitiesStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.specialitiesStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.specialitiesStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.specialitiesStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
                const isNotEmpty = !_.isEmpty(selected);

                isNotEmpty &&
                    (await this.props.RootStore.specialitiesStore.bulkDeleteSpecialities(selected));
            };

            render() {
                const {
                    RootStore: {
                        specialitiesStore: {
                            gridSpecialities,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onSearch,
                            loaded,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <SpecialitiesTableNav
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleAlert}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <SpecialitiesTableHeader
                                    data={gridSpecialities}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridSpecialities.map((speciality) => (
                                        <SpecialitiesTableRow
                                            {...speciality}
                                            onClick={this._handleRowClick}
                                            key={speciality.id}
                                            onToggle={this._handleToggleSelected}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <SpecialitiesDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these specialities? You won't be
                                        able to recover them."
                        />
                    </>
                );
            }
        },
    ),
);

export default Specialities;
