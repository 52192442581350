const dispositionMappings = {
    'Priority 1': 'emergency',
    'Priority 2': 'urgent',
    'Priority 3': 'routine',
    'Priority 4': 'routine',
};

const AdastraParser = (rawContent) => {
    const systmOneRef = rawContent.split('Case No:')[1].split('Patient Called:')[0].trim();

    const age = parseInt(rawContent.split('Age:')[1].split('\n')[0]);

    const gender = rawContent.split('Sex:')[1].split('\n')[0].trim().toLowerCase();

    const possiblePhoneNumbers = rawContent.match(/\D0(1|2|7)\d{9}\D/);
    let contactNumber;

    if (Array.isArray(possiblePhoneNumbers) && possiblePhoneNumbers.length > 0) {
        contactNumber = possiblePhoneNumbers[0].replace(/\D/g, '').trim();
    }

    const disposition =
        dispositionMappings[rawContent.split('Priority (Completion):')[1].split('<')[0].trim()];

    return {
        systmOneRef,
        age,
        gender,
        disposition,
        contactNumber,
        notes: rawContent,
    };
};

export default AdastraParser;
