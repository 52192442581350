import { FC } from 'react';
import { FormGroup, NumericInput } from '@blueprintjs/core';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';

import Error from '../../forms/Error';
import { days } from './EmployeeSummaryDays';

type ContractHoursType = {
    values: FormikValues;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    editOrCreateMode: boolean;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
};

const ContractedHours: FC<ContractHoursType> = ({
    values,
    setFieldValue,
    editOrCreateMode,
    errors,
    touched,
    setFieldTouched,
}) => {
    const uniqErrors = new Set();
    days.forEach((d) => {
        if (errors[d.name]) {
            uniqErrors.add(errors[d.name]);
        }
    });
    const uniqErrorsArray = Array.from(uniqErrors);

    const hasDaysError =
        errors.mondayContractedHours ||
        errors.tuesdayContractedHours ||
        errors.wednesdayContractedHo ||
        errors.thursdayContractedHours ||
        errors.fridayContractedHours ||
        errors.saturdayContractedHo ||
        errors.sundayContractedHours;

    return (
        <>
            {days.map((i) => (
                <>
                    <FormGroup labelFor={i.name} inline label={i.label} key={i.name}>
                        <NumericInput
                            id={i.name}
                            name={i.name}
                            onValueChange={async (_, value) => {
                                setFieldValue(i.name, value);
                                const sum = getSumHours(values, i.name, Number(value || 0));
                                setFieldValue('contractedHoursSum', sum);
                            }}
                            onBlur={({ target: { value } }) => {
                                const parsedValue =
                                    Math.min(Math.max(Math.round(+value / 0.25) * 0.25, 0), 24) ||
                                    0;
                                setFieldValue(i.name, parsedValue);
                                const sum = getSumHours(values, i.name, parsedValue);
                                setFieldValue('contractedHoursSum', sum);
                            }}
                            minorStepSize={null}
                            stepSize={0.25}
                            value={values[i.name]}
                            large
                            min={0}
                            max={24}
                            buttonPosition="none"
                            disabled={!editOrCreateMode}
                        />
                    </FormGroup>
                </>
            ))}
            {hasDaysError ? (
                uniqErrorsArray.map((e) => <Error errors={[e]} key={e as string} />)
            ) : errors.contractedHoursSum ? (
                <Error errors={[errors.contractedHoursSum]} />
            ) : null}
        </>
    );
};

function getSumHours(values: any, currentFieldName: string, currentValue = 0) {
    const sum = days.reduce((sum, { name }) => {
        const value = name !== currentFieldName ? Number(values[name] || 0) : Number(currentValue);
        return sum + value;
    }, 0);

    return Number(sum);
}

export default ContractedHours;
