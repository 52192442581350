import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

const ExitConfirmationDialog = (props: any) => {
    const { onCancel, onPublish, onSaveDraft, onExit, isOpen, isPublished } = props;

    return (
        <Dialog icon="info-sign" onClose={onCancel} title="Exit confirmation" isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <p>Do you want to save the Rota?</p>
            </div>
            <div className={`${Classes.DIALOG_FOOTER} exit-dialog-footer`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    {!isPublished && (
                        <Button intent={Intent.WARNING} onClick={onSaveDraft}>
                            Save as a draft
                        </Button>
                    )}
                    <Button intent={Intent.SUCCESS} onClick={onPublish}>
                        Save and publish
                    </Button>
                    <Button intent={Intent.DANGER} onClick={onExit}>
                        Don’t save
                    </Button>
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ExitConfirmationDialog;
