import React, { useState } from 'react';
import { Button, Collapse, Tag } from '@blueprintjs/core';
import {
    relatedDepartmentsTh,
    relatedPayRatesTh,
    relatedRolesTh,
    relatedRotasTh,
    relatedShiftPatternsTh,
    relatedStaffsTh,
} from '../../constants/RelatedEntitiesConst';
import _ from 'lodash';

interface RelatedEntitiesProps {
    relatedRotas?: [
        {
            id: string;
            name: string;
            startDate: string;
            endDate: string;
            department: { name: string };
            costCentre: { name: string };
            isPublished: boolean;
        },
    ];
    relatedShiftPatterns?: [
        {
            id: string;
            name: string;
            weeks: [];
            shifts: number;
            department: { name: string };
            costCentre: { name: string };
        },
    ];
    relatedStaffs?: [
        {
            id: string;
            firstName: string;
            middleName: string;
            lastName: number;
            costCentres: [];
            departments: [];
            jobTitle: [];
            roles: [];
            employeeStatus: string;
        },
    ];
    relatedRoles?: [
        {
            id: string;
            name: string;
            roleFunctions: [];
        },
    ];
    relatedDepartments?: [
        {
            id: string;
            name: string;
            costCode: string;
            costCentre: string;
            roles: [];
            locations: [];
        },
    ];
    relatedPayRates?: [
        {
            id: string;
            name: string;
            employeeRoleName: string;
            generalCode: string;
        },
    ];
    description?: string | undefined;
    hasRelatedRotas?: boolean;
    hasRelatedShiftPatterns?: boolean;
    hasRelatedStaffs?: boolean;
    hasRelatedRoles?: boolean;
    hasRelatedDepartments?: boolean;
    hasRelatedPayRates?: boolean;
    relatedRotasTitle?: string;
    relatedShiftPatternsTitle?: string;
    relatedStaffsTitle?: string;
    relatedRolesTitle?: string;
    relatedDepartmentsTitle?: string;
    relatedPayRatesTitle?: string;
    className?: string;
}

const RelatedEntities = (props: RelatedEntitiesProps) => {
    const {
        relatedRotas,
        relatedShiftPatterns,
        relatedStaffs,
        relatedPayRates,
        relatedRoles,
        relatedDepartments,
        relatedRotasTitle = 'Rotas with future shifts',
        relatedShiftPatternsTitle = 'Shift patterns',
        relatedStaffsTitle = 'Staff records',
        relatedRolesTitle = 'Roles',
        relatedDepartmentsTitle = 'Departments',
        relatedPayRatesTitle = 'Pay rates',
        description = '',
        hasRelatedRotas = false,
        hasRelatedShiftPatterns = false,
        hasRelatedStaffs = false,
        hasRelatedRoles = false,
        hasRelatedDepartments = false,
        hasRelatedPayRates = false,
        className,
    } = props;
    const [relatedRotasCollapse, setRelatedRotasCollapse] = useState(true);
    const [relatedShiftPatternsCollapse, setRelatedShiftPatternsCollapse] = useState(true);
    const [relatedStaffsCollapse, setRelatedStaffsCollapse] = useState(true);
    const [relatedRolesCollapse, setRelatedRolesCollapse] = useState(true);
    const [relatedDepartmentsCollapse, setRelatedDepartmentsCollapse] = useState(true);
    const [relatedPayRatesCollapse, setRelatedPayRatesCollapse] = useState(true);

    const relatedStaffsUrl = relatedStaffsTitle?.includes('Sessional')
        ? 'sessional-records'
        : 'employed-records';

    return (
        <section className={`related-entries ${className}`}>
            {description && <p>{description}</p>}

            {hasRelatedRotas && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedRotasCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'
                        }
                        onClick={() => setRelatedRotasCollapse(!relatedRotasCollapse)}
                    >
                        {!_.isEmpty(relatedRotas)
                            ? relatedRotasTitle
                            : 'No related ' + relatedRotasTitle}
                    </Button>
                    <Collapse isOpen={relatedRotasCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedRotas) &&
                                        relatedRotasTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedRotas?.map((tr, index) => (
                                    <tr
                                        key={index}
                                        onClick={() =>
                                            window.open(`/rota/builder/${tr.id}`, '_blank')
                                        }
                                        className="common-table__row"
                                    >
                                        <td>{tr.isPublished ? tr.name : '[Draft] ' + tr.name}</td>
                                        <td>{tr.startDate}</td>
                                        <td>{tr.endDate}</td>
                                        <td>{tr.department.name}</td>
                                        <td>{tr.costCentre.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}

            {hasRelatedShiftPatterns && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedShiftPatternsCollapse
                                ? 'symbol-triangle-up'
                                : 'symbol-triangle-down'
                        }
                        onClick={() =>
                            setRelatedShiftPatternsCollapse(!relatedShiftPatternsCollapse)
                        }
                    >
                        {!_.isEmpty(relatedShiftPatterns)
                            ? relatedShiftPatternsTitle
                            : 'No related ' + relatedShiftPatternsTitle}
                    </Button>
                    <Collapse isOpen={relatedShiftPatternsCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedShiftPatterns) &&
                                        relatedShiftPatternsTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedShiftPatterns?.map((tr, index) => (
                                    <tr
                                        key={index}
                                        onClick={() =>
                                            window.open(`/rota/shift-patterns/${tr.id}`, '_blank')
                                        }
                                        className="common-table__row"
                                    >
                                        <td>{tr.name}</td>
                                        <td>{Math.max(0, ...tr.weeks)}</td>
                                        <td>{tr.shifts}</td>
                                        <td>{tr.department.name}</td>
                                        <td>{tr.costCentre.name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}
            {hasRelatedStaffs && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedStaffsCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'
                        }
                        onClick={() => setRelatedStaffsCollapse(!relatedStaffsCollapse)}
                    >
                        {!_.isEmpty(relatedStaffs)
                            ? relatedStaffsTitle
                            : 'No related ' + relatedStaffsTitle}
                    </Button>
                    <Collapse isOpen={relatedStaffsCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedStaffs) &&
                                        relatedStaffsTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedStaffs?.map((tr, index) => {
                                    return (
                                        <tr
                                            key={index}
                                            onClick={() =>
                                                window.open(
                                                    `/hr/${relatedStaffsUrl}/${tr.id}`,
                                                    '_blank',
                                                )
                                            }
                                            className="common-table__row"
                                        >
                                            <td>{tr.firstName}</td>
                                            <td>{tr.middleName}</td>
                                            <td>{tr.lastName}</td>
                                            <td>
                                                {tr.departments?.map((dep, index) =>
                                                    dep ? (
                                                        <Tag
                                                            key={index}
                                                            minimal={true}
                                                            className="common-table__tag"
                                                        >
                                                            {dep}
                                                        </Tag>
                                                    ) : null,
                                                )}
                                            </td>
                                            <td>
                                                {tr.costCentres?.map((costCentre, index) =>
                                                    costCentre ? (
                                                        <Tag
                                                            key={index}
                                                            minimal={true}
                                                            className="common-table__tag"
                                                        >
                                                            {costCentre}
                                                        </Tag>
                                                    ) : null,
                                                )}
                                            </td>
                                            <td>
                                                {tr.jobTitle?.map((title, index) =>
                                                    title ? (
                                                        <Tag
                                                            key={index}
                                                            minimal={true}
                                                            className="common-table__tag"
                                                        >
                                                            {title}
                                                        </Tag>
                                                    ) : null,
                                                )}
                                            </td>
                                            <td>{tr.roles?.map((role) => (role ? role : null))}</td>
                                            <td>{tr.employeeStatus}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}
            {hasRelatedRoles && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedRolesCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'
                        }
                        onClick={() => setRelatedRolesCollapse(!relatedRolesCollapse)}
                    >
                        {!_.isEmpty(relatedRoles)
                            ? relatedRolesTitle
                            : 'No related ' + relatedRolesTitle}
                    </Button>
                    <Collapse isOpen={relatedRolesCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedRoles) &&
                                        relatedRolesTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedRoles?.map((tr, index) => (
                                    <tr
                                        key={index}
                                        onClick={() => window.open(`/hr/roles/${tr.id}`, '_blank')}
                                        className="common-table__row"
                                    >
                                        <td>{tr.name}</td>
                                        <td>
                                            {tr.roleFunctions?.map(
                                                (functionItem: { id: string; name: string }) =>
                                                    functionItem.name ? (
                                                        <Tag
                                                            key={functionItem.id}
                                                            minimal={true}
                                                            className="common-table__tag"
                                                        >
                                                            {functionItem.name}
                                                        </Tag>
                                                    ) : null,
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}
            {hasRelatedDepartments && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedDepartmentsCollapse
                                ? 'symbol-triangle-up'
                                : 'symbol-triangle-down'
                        }
                        onClick={() => setRelatedDepartmentsCollapse(!relatedDepartmentsCollapse)}
                    >
                        {!_.isEmpty(relatedDepartments)
                            ? relatedDepartmentsTitle
                            : 'No related ' + relatedDepartmentsTitle}
                    </Button>
                    <Collapse isOpen={relatedDepartmentsCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns related-departments">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedDepartments) &&
                                        relatedDepartmentsTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedDepartments?.map((tr, index) => (
                                    <tr
                                        key={index}
                                        onClick={() =>
                                            window.open(`/hr/departments/${tr.id}`, '_blank')
                                        }
                                        className="common-table__row"
                                    >
                                        <td>{tr.name}</td>
                                        <td>{tr.costCode}</td>
                                        <td>{tr.costCentre}</td>
                                        <td>
                                            {tr.roles?.map((name, index) =>
                                                !!name ? (
                                                    <Tag
                                                        key={index}
                                                        minimal={true}
                                                        className="common-table__tag"
                                                    >
                                                        {name}
                                                    </Tag>
                                                ) : null,
                                            )}
                                        </td>
                                        <td>
                                            {tr.locations?.map((name, index) =>
                                                !!name ? (
                                                    <Tag
                                                        key={index}
                                                        minimal={true}
                                                        className="common-table__tag"
                                                    >
                                                        {name}
                                                    </Tag>
                                                ) : null,
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}
            {hasRelatedPayRates && (
                <div>
                    <Button
                        className={'related-entries__collapse-btn'}
                        fill
                        large
                        rightIcon={
                            relatedPayRatesCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'
                        }
                        onClick={() => setRelatedPayRatesCollapse(!relatedPayRatesCollapse)}
                    >
                        {!_.isEmpty(relatedPayRates)
                            ? relatedPayRatesTitle
                            : 'No related ' + relatedPayRatesTitle}
                    </Button>
                    <Collapse isOpen={relatedPayRatesCollapse}>
                        <table className="bp5-html-table bp5-interactive common-table common-table--list-view auto-fit-columns">
                            <thead>
                                <tr>
                                    {!_.isEmpty(relatedPayRates) &&
                                        relatedPayRatesTh?.map((th, index) => (
                                            <th
                                                key={index}
                                                data-sort={`${th.dataSort}`}
                                                className="sorted-header"
                                            >
                                                <Button minimal fill alignText="left">
                                                    {th.name}
                                                </Button>
                                            </th>
                                        ))}
                                </tr>
                            </thead>
                            <tbody>
                                {relatedPayRates?.map((tr, index) => (
                                    <tr
                                        key={index}
                                        onClick={() =>
                                            window.open(`/hr/pay-rates/${tr.id}`, '_blank')
                                        }
                                        className="common-table__row"
                                    >
                                        <td>{tr.name}</td>
                                        <td>{tr.employeeRoleName}</td>
                                        <td>{tr.generalCode}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </div>
            )}
        </section>
    );
};

export default RelatedEntities;
