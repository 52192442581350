import React, { Component } from 'react';
import { Tabs, Tab, Icon } from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

const Header = inject('RootStore')(
    observer(
        class Header extends Component {
            render() {
                const {
                    match: {
                        params: { section },
                    },
                    RootStore: {
                        userStore: { isComplianceOfficer, logOut },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                return (
                    <header className="header header--admin">
                        <Link to="/">
                            <img
                                src="/images/logos/docabode_logo.png"
                                alt="Doc Abode Logo"
                                className="header__logo"
                            />
                        </Link>
                        <Tabs
                            id="AdminNavigation"
                            selectedTabId={section}
                            className="header__tabs"
                            large
                        >
                            {isFeatureEnabled('hr') && (
                                <Tab id="hr">
                                    <Link to="/hr">
                                        <Icon icon="people" className="header__tab-icon" />
                                        HR
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('rota') && (
                                <Tab id="rota">
                                    <Link to="/rota">
                                        <Icon icon="timeline-events" className="header__tab-icon" />
                                        Rota
                                    </Link>
                                </Tab>
                            )}
                            {!isComplianceOfficer && (
                                <Tab id="reporting">
                                    <Link to="/reporting">
                                        <Icon icon="doughnut-chart" className="header__tab-icon" />
                                        Reporting
                                    </Link>
                                </Tab>
                            )}
                            {!isComplianceOfficer && (
                                <Tab id="manage-users">
                                    <Link to="/manage-users">
                                        <Icon icon="people" className="header__tab-icon" />
                                        Manage Users
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('addUser') && (
                                <Tab id="add-user">
                                    <Link to="/add-user">
                                        <Icon icon="new-person" className="header__tab-icon" />
                                        Add User
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('invites') && (
                                <Tab id="manage-applications">
                                    <Link to="/manage-applications">
                                        <Icon icon="clipboard" className="header__tab-icon" />
                                        Manage Applications
                                    </Link>
                                </Tab>
                            )}
                            {isFeatureEnabled('invites') && !isComplianceOfficer && (
                                <Tab id="organisation-details">
                                    <Link to="/organisation-details">
                                        <Icon icon="office" className="header__tab-icon" />
                                        Organisation
                                    </Link>
                                </Tab>
                            )}
                            <Tab id="logout">
                                <Link to="/logout" onClick={logOut}>
                                    <Icon icon="log-out" className="header__tab-icon" />
                                    Log out
                                </Link>
                            </Tab>
                        </Tabs>
                    </header>
                );
            }
        },
    ),
);

export default Header;
