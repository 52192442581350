import _ from 'lodash';
import moment from 'moment';
import { formatAPIMultiSelect, formatDateTime, formatDateOfBirth } from '../../helpers/formatData';
import { formFields } from './formFields';
import {
    monthlyRepeatsDaysDictionary,
    monthlyRepeatsOftenDictionary,
    onDaysApiDictionary,
} from './constants';
import { getKeyByValue } from '../../../../helpers/sorting';

export const emptyErrorByType = (fieldValue, fieldType) => {
    const emptyFieldErrorMessage = 'This field is required.';
    switch (fieldType) {
        case 'number':
            return !_.isNumber(fieldValue) ? emptyFieldErrorMessage : '';
        case 'date':
            return !_.isDate(fieldValue) ? emptyFieldErrorMessage : '';
        case 'object':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        case 'string':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        case 'array':
            return _.isEmpty(fieldValue) ? emptyFieldErrorMessage : '';
        default:
            return [];
    }
};

export const regexFieldValidation = (value, regex) => {
    if (regex) {
        return regex.test(value);
    }
    return true;
};

export const getAPIReadyJobData = (state, { lat, lng }, organisation, actionType) => {
    let scheduleOptions = undefined;
    let until = new Date(state.fields.until.valueOf());
    until.setUTCHours(23, 59, 59, 999);
    const from = formatDateTime(state.fields.startDate, state.fields.startTime);

    if (state.fields.repeats === 'DAILY') {
        scheduleOptions = {
            rrules: [
                {
                    frequency: state.fields.repeats,
                    start: from,
                    end: until.toISOString(),
                    interval:
                        state.fields.repeatsForDaily === 'everyDay'
                            ? state.fields.repeatEvery
                            : undefined,
                    byDayOfWeek:
                        state.fields.repeatsForDaily === 'everyWeekday'
                            ? ['MO', 'TU', 'WE', 'TH', 'FR']
                            : undefined,
                },
            ],
        };
    }
    if (state.fields.repeats === 'WEEKLY') {
        scheduleOptions = {
            rrules: [
                {
                    frequency: state.fields.repeats,
                    start: from,
                    end: until.toISOString(),
                    byDayOfWeek: state.fields.onDays.map((day) => onDaysApiDictionary[day]),
                    interval: state.fields.repeatEvery,
                },
            ],
        };
    }
    if (state.fields.repeats === 'MONTHLY') {
        scheduleOptions = {
            rrules: [
                {
                    frequency: state.fields.repeats,
                    start: from,
                    end: until.toISOString(),
                    byDayOfWeek:
                        state.fields.repeatsForMonthly === 'onDayInMonth'
                            ? [
                                  [
                                      onDaysApiDictionary[state.fields.monthlyRepeatsDay.value],
                                      state.fields.monthlyRepeatsOften.value,
                                  ],
                              ]
                            : undefined,
                    byDayOfMonth:
                        state.fields.repeatsForMonthly === 'onDateInMonth'
                            ? [state.fields.repeatDayInMonth]
                            : undefined,
                    interval: state.fields.repeatEvery,
                },
            ],
        };
    }

    let normalizedData = {
        systmOneRef: state.fields.systmOneRef === '' ? null : state.fields.systmOneRef,
        nhsNumber: state.fields.nhsNumber === '' ? null : state.fields.nhsNumber,
        jobType: state.fields.jobType.value ? state.fields.jobType.value : '',
        disposition: state.fields.disposition.value ? state.fields.disposition.value : null,
        blockSize: state.fields.blockSize.label !== '' ? state.fields.blockSize.label : null,
        shiftType: state.fields.shiftType.label !== '' ? state.fields.shiftType.label : null,
        firstName: state.fields.firstName !== '' ? state.fields.firstName : null,
        lastName: state.fields.lastName !== '' ? state.fields.lastName : null,
        gender: state.fields.gender.value ? state.fields.gender.value : null,
        addressLine1: state.fields.addressLine1 !== '' ? state.fields.addressLine1 : null,
        addressLine2: state.fields.addressLine2 !== '' ? state.fields.addressLine2 : null,
        town: state.fields.town !== '' ? state.fields.town : null,
        postCode: state.fields.postCode !== '' ? state.fields.postCode : null,
        hcpType: state.fields.hcpType.length ? formatAPIMultiSelect(state.fields.hcpType) : [],
        nominatedHcps: state.fields.nominatedHcps.length
            ? formatAPIMultiSelect(state.fields.nominatedHcps)
            : [],
        languagesSpoken: state.fields.languagesSpoken.length
            ? formatAPIMultiSelect(state.fields.languagesSpoken)
            : [],
        speciality: state.fields.speciality.length
            ? formatAPIMultiSelect(state.fields.speciality)
            : [],
        flags: state.fields.flags.length ? formatAPIMultiSelect(state.fields.flags) : [],
        dateOfBirth:
            state.fields.dateOfBirth !== null ? formatDateOfBirth(state.fields.dateOfBirth) : null,
        age:
            state.fields.age !== '' && state.fields.age !== null
                ? parseInt(state.fields.age, 0)
                : null,
        contactNumber: state.fields.contactNumber !== '' ? state.fields.contactNumber : null,
        priceInPence: state.fields.price !== null ? state.fields.price * 100 : null,
        hidePriceInApp: state.fields.hidePriceInApp !== null ? state.fields.hidePriceInApp : false,
        startDateTime:
            state.fields.startDate !== null && state.fields.startTime !== null
                ? formatDateTime(state.fields.startDate, state.fields.startTime)
                : '',
        expiryDateTime:
            state.fields.expiryDate !== null && state.fields.expiryTime !== null
                ? formatDateTime(state.fields.expiryDate, state.fields.expiryTime)
                : '',
        endDateTime:
            state.fields.endDate !== null && state.fields.endTime !== null
                ? formatDateTime(state.fields.endDate, state.fields.endTime)
                : '',
        notes: state.fields.notes !== '' ? state.fields.notes : null,
        practice: state.fields.practice.value ? state.fields.practice.value : null,
        latitude: lat ? lat : null,
        longitude: lng ? lng : null,
        version: state.fields.version,
        organisation,
        scheduleOptions,
        isSeries: actionType === 'create' ? undefined : state.isSeries,
        ...(state.fields.repeatingGroupId && {
            repeatingGroupId: state.fields.repeatingGroupId,
        }),
    };

    // Remove fields not relevant to job type
    formFields.forEach((field) => {
        const { jobType } = normalizedData;
        const { visible, name } = field;
        if (visible !== true && Array.isArray(visible) && !visible.includes(jobType)) {
            if (name === 'endDate') {
                delete normalizedData.endDateTime;
            } else if (name === 'startDate') {
                delete normalizedData.startDateTime;
            } else {
                delete normalizedData[name];
            }
        }
    });

    // Remove empty optional fields
    _.forIn(state.validation, (fieldValidation, field) => {
        if (
            normalizedData[field] === null &&
            (fieldValidation.required === false ||
                fieldValidation.required !== state.fields.jobType.value)
        ) {
            delete normalizedData[field];
        }
    });

    return normalizedData;
};

export const normalizeScheduleOptions = (scheduleOptions) => {
    const date = moment(scheduleOptions.rrules[0].end);
    const dateFormatted = date.utc().format('DD MMM YYYY');
    if (scheduleOptions.rrules[0].frequency === 'DAILY') {
        return {
            repeats: scheduleOptions.rrules[0].frequency,
            until: new Date(dateFormatted),
            repeatEvery: scheduleOptions.rrules[0].interval ?? 1, // return default value for preventing error with required field
            repeatsForDaily: scheduleOptions.rrules[0].interval ? 'everyDay' : 'everyWeekday',
        };
    }
    if (scheduleOptions.rrules[0].frequency === 'WEEKLY') {
        return {
            repeats: scheduleOptions.rrules[0].frequency,
            until: new Date(dateFormatted),
            onDays: scheduleOptions.rrules[0].byDayOfWeek.map((day) => {
                for (const [key, value] of Object.entries(onDaysApiDictionary)) {
                    if (value === day) {
                        return key;
                    }
                }
                return day;
            }),
            repeatEvery: scheduleOptions.rrules[0].interval,
        };
    }
    if (scheduleOptions.rrules[0].frequency === 'MONTHLY') {
        return {
            repeats: scheduleOptions.rrules[0].frequency,
            until: new Date(dateFormatted),
            repeatsForMonthly: scheduleOptions.rrules[0].byDayOfMonth
                ? 'onDateInMonth'
                : 'onDayInMonth',
            ...(scheduleOptions.rrules[0].byDayOfMonth && {
                repeatDayInMonth: scheduleOptions.rrules[0].byDayOfMonth[0],
            }),
            repeatEvery: scheduleOptions.rrules[0].interval,
            ...(scheduleOptions.rrules[0].byDayOfWeek && {
                monthlyRepeatsOften: {
                    value: scheduleOptions.rrules[0].byDayOfWeek[0][1],
                    label:
                        monthlyRepeatsOftenDictionary[scheduleOptions.rrules[0].byDayOfWeek[0][1]],
                },
                monthlyRepeatsDay: {
                    value: getKeyByValue(
                        onDaysApiDictionary,
                        scheduleOptions.rrules[0].byDayOfWeek[0][0],
                    ),
                    label:
                        monthlyRepeatsDaysDictionary[
                            getKeyByValue(
                                onDaysApiDictionary,
                                scheduleOptions.rrules[0].byDayOfWeek[0][0],
                            )
                        ],
                },
            }),
        };
    }
};
