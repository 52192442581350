import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../modules/helpers/formatData';

const PayRatesTableRow = ({
    onClick,
    id,
    selected,
    name,
    agency,
    employeeRole,
    createdAt,
    updatedAt,
    createdByPerson,
    updatedByPerson,
    onToggle,
    isArchived,
}) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    disabled={isArchived}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{name}</td>
            <td>{employeeRole?.name}</td>
            <td>{agency?.name}</td>
            <td>{isArchived ? 'Archived' : 'Active'}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>
                {createdByPerson && `${createdByPerson?.firstName}  ${createdByPerson?.lastName}`}
            </td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
            <td>
                {updatedByPerson && `${updatedByPerson?.firstName}  ${updatedByPerson?.lastName}`}
            </td>
        </tr>
    );
};

export default PayRatesTableRow;
