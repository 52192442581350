import React, { useEffect, useState } from 'react';
import { FormGroup, Menu, MenuItem, Button, Popover } from '@blueprintjs/core';
import moment from 'moment';

import DateSelector from '../Rota/Builder/DateSelector';
import { Button as ButtonV2, ButtonColors, ButtonElems, ButtonSizes } from '../../../v2/components';
import { IconFilter } from '../../../../helpers/ucr/icons';
import Filters from './Filters';
import CommonPagination from '../CommonHRRotaPagination';

const AbsenceHeader = (props: any) => {
    const [showFilters, setShowFilters] = useState(false);
    const [filter, setFilter] = useState<any>({
        dateRange: [new Date(`01/01/${moment().year()}`), moment().add(1, 'year').toDate()],
    });
    const {
        absenceTypes,
        approveItems,
        approvalStatuses,
        getFilterOptions,
        getPersonRecords,
        nextPage,
        onFilter,
        previousPage,
        recordStatuses,
        rejectItems,
        selected,
        archiveItems,
        history,
        personId,
        handleAddNewAbsenceFromPerson,
        handleOpenAbsenceRecordFromPerson,
    } = props;

    const Content = () => (
        <Menu>
            <MenuItem
                disabled={!selected.length || selected.length !== 1}
                onClick={handleEdit}
                text="Edit"
            />
            <MenuItem disabled={!selected.length} onClick={archiveItems} text="Archive" />
            <MenuItem disabled={props.disableApproval} onClick={approveItems} text="Approve" />
            <MenuItem disabled={props.disableRejection} onClick={rejectItems} text="Reject" />
        </Menu>
    );

    const handleAddNew = () => {
        if (personId) {
            handleAddNewAbsenceFromPerson();
        } else {
            history.replace(`/hr/absence-management/new`);
        }
    };

    const handleEdit = () => {
        if (selected.length === 1) {
            history.push(
                personId
                    ? handleOpenAbsenceRecordFromPerson(selected[0])
                    : `/hr/absence-management/${selected[0]}`,
            );
        }
    };

    useEffect(() => {
        getFilterOptions();
    }, [getFilterOptions]);
    return (
        <React.Fragment>
            <div className="absence-header">
                <div className="absence-header-row">
                    <FormGroup label="Date range">
                        <DateSelector
                            dateRange={filter.dateRange}
                            onChange={async (val: any) => {
                                if (!val[0] || !val[1]) return;

                                setFilter({
                                    ...filter,
                                    dateRange: val,
                                });
                                onFilter({
                                    personId: filter.persons
                                        ? filter.persons.map((r: any) => r.value)
                                        : undefined,
                                    absenceTypeId: filter.absenceTypes
                                        ? filter.absenceTypes.map((r: any) => r.value)
                                        : undefined,
                                    statusId: filter.approvalStatuses
                                        ? filter.approvalStatuses.map((r: any) => r.value)
                                        : undefined,
                                    startDate: val[0],
                                    endDate: val[1],
                                    isArchived: filter.isArchived
                                        ? filter.isArchived.value
                                        : undefined,
                                });
                            }}
                            shortcuts
                        />
                    </FormGroup>
                    <CommonPagination
                        previousPage={previousPage}
                        nextPage={nextPage}
                        pageInfo={props.pageInfo}
                        itemsName="absences"
                    />
                </div>

                <div className="absence-header-row">
                    <div className="buttons-section">
                        <ButtonV2 name="+ Add" className="margin5" clickEvent={handleAddNew} />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content />}
                            className="popover-as-button"
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    /* TODO elementRef removed in blueprintjs5 check consequences*/
                                    /*elementRef={ref as any}*/
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    className="bp5-outlined v2-popover-as-button"
                                />
                            )}
                        />
                    </div>
                    <div className="buttons-section">
                        <ButtonV2
                            className="margin5"
                            size={ButtonSizes.MEDIUM}
                            elem={ButtonElems.BUTTON}
                            color={ButtonColors.WHITE}
                            name="Filter"
                            Icon={IconFilter}
                            clickEvent={() => {
                                setShowFilters(!showFilters);
                            }}
                        />
                    </div>
                </div>

                <Filters
                    absenceTypes={absenceTypes}
                    approvalStatuses={approvalStatuses}
                    filter={filter}
                    getFilterOptions={getFilterOptions}
                    getPersonRecords={getPersonRecords}
                    recordStatuses={recordStatuses}
                    show={showFilters}
                    personId={personId}
                    onChange={(updatedFilter: any) => {
                        setFilter({
                            dateRange: filter.dateRange,
                            ...updatedFilter,
                        });
                        onFilter({
                            personId: personId
                                ? [personId]
                                : updatedFilter.persons
                                ? updatedFilter.persons.map((r: any) => r.value)
                                : undefined,
                            absenceTypeId: updatedFilter.absenceTypes
                                ? updatedFilter.absenceTypes.map((r: any) => r.value)
                                : undefined,
                            statusId: updatedFilter.approvalStatuses
                                ? updatedFilter.approvalStatuses.map((r: any) => r.value)
                                : undefined,
                            startDate: filter.dateRange[0],
                            endDate: filter.dateRange[1],
                            isArchived: updatedFilter.isArchived?.value || undefined,
                        });
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default AbsenceHeader;
