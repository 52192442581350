import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

const InductionTasksDialog = (props: any) => {
    const {
        title = '',
        text = '',
        onCancel,
        cancelText = 'Cancel',
        onSave,
        saveText = 'Save',
        onExit,
        hasExit,
        isOpen,
    } = props;

    return (
        <Dialog onClose={onCancel} title={title} isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <p>{text}</p>
            </div>
            <div className={`${Classes.DIALOG_FOOTER}`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.SUCCESS} onClick={onSave}>
                        {saveText}
                    </Button>
                    {hasExit && (
                        <Button intent={Intent.DANGER} onClick={onExit}>
                            No, exit without saving
                        </Button>
                    )}
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        {cancelText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default InductionTasksDialog;
