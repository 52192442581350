import { FC, ReactNode, CSSProperties, useContext, useEffect } from 'react';
import cn from 'classnames';

import { IconExpandLess, IconExpandMore, IconEdit } from '../../../../helpers/ucr/icons';

import { AccordionContext } from './Accordion';

export enum AccordionColors {
    BLUE = 'blue',
    PINK = 'pink',
    GREY = 'grey',
    DARK_BLUE = 'dark-blue',
}

interface IProps {
    name: string;
    title: string;
    color: AccordionColors;
    open?: boolean;
    disabled?: boolean;
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
    onClick?: () => void;
    onEdit?: () => void;
}

const AccordionTab: FC<IProps> = ({
    name,
    title,
    onClick,
    onEdit,
    color,
    open = false,
    disabled = false,
    children,
    className,
    style,
}) => {
    const { allowCloseLastTab, openTabs, toggleTabEvent, mountTabEvent } = useContext(
        AccordionContext,
    );

    // If there is an external onClick function, we control the tabs state from the external component.
    const isOpen = onClick ? open : openTabs.includes(name);
    const isClickable = (allowCloseLastTab || !isOpen || openTabs.length !== 1) && !disabled;
    const onClickEvent = () => toggleTabEvent({ name, isOpen, isClickable });

    useEffect(() => {
        mountTabEvent({ name, open });
    }, [name, open, mountTabEvent]);

    return (
        <div className={cn('v2__accordion-tab', className)} style={style}>
            <div
                className={cn('v2__accordion-summary', `v2__accordion-summary--${color}`, {
                    'v2__accordion-summary--clickable': isClickable,
                    'v2__accordion-summary--open': isOpen,
                    'v2__accordion-summary--closed': !isOpen,
                    'v2__accordion-summary--disabled': disabled,
                })}
                onClick={onClick || onClickEvent}
            >
                <div className={cn('v2__accordion-summary-left')}>
                    <span
                        className={cn(
                            'v2__accordion-summary-name v2__accordion-summary-name--relative',
                        )}
                    >
                        {title}
                        {onEdit && (
                            <div
                                className="v2__accordion-icon-wrap"
                                aria-label={`Edit ${title}`}
                                onClick={onEdit}
                            >
                                <IconEdit className="v2__accordion-editIcon" />
                            </div>
                        )}
                    </span>
                    <span className={cn('v2__accordion-summary-edit')}></span>
                </div>
                <div className={cn('v2__accordion-summary-right')}>
                    <span className={cn('v2__accordion-summary-icon')}>
                        {isOpen && <IconExpandLess />}
                        {!isOpen && <IconExpandMore />}
                    </span>
                </div>
            </div>
            <div
                className={cn('v2__accordion-wrapper', {
                    'v2__accordion-wrapper--open': isOpen,
                    'v2__accordion-wrapper--closed': !isOpen,
                })}
            >
                <div
                    className={cn('v2__accordion-content', {
                        'v2__accordion-content--open': isOpen,
                        'v2__accordion-content--closed': !isOpen,
                    })}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AccordionTab;
