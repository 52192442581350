import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../../modules/helpers/formatData';

const ShiftsTableRow = ({
    onClick,
    id,
    selected,
    name,
    createdAt,
    updatedAt,
    weeks,
    department,
    shifts,
    createdPerson,
    onToggle,
    costCentre,
}) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{name}</td>
            <td>{costCentre.code}</td>
            <td>{costCentre.name}</td>
            <td>{department.costCode}</td>
            <td>{department.name}</td>
            <td>{Math.max(0, ...weeks)}</td>
            <td>{shifts}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>{createdPerson}</td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
        </tr>
    );
};

export default ShiftsTableRow;
