import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../../hook/useStores';
import Loader from '../../modules/helpers/Loader';
import { JobsContext } from '../../../providers';

export const PageLoader: FC = observer(() => {
    const {
        RootStore: {
            ucrStore: { loadingJobs },
            usersStore: { loaded: usersLoaded },
        },
    } = useStores() as { RootStore: any };

    const [refreshingData, setRefreshingData] = useState(false);

    const { refreshAssignedJobs, refreshUnassignedJobs, refreshPatients } = useContext(JobsContext);

    useEffect(() => {
        setRefreshingData(refreshAssignedJobs || refreshUnassignedJobs || refreshPatients);
    }, [refreshAssignedJobs, refreshPatients, refreshUnassignedJobs]);

    return loadingJobs || !usersLoaded || refreshingData ? (
        <div className="ucr__section-loader">
            <Loader fullscreen={false} />
        </div>
    ) : null;
});
