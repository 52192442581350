import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Dashboard from '../../pages/admin/Rota/Dashboard';
import RotaList from '../../pages/admin/Rota/RotaList';
import RotaBuilder from '../../pages/admin/Rota/Builder';
import ShiftPatterns from '../../pages/admin/Rota/ShiftPatterns';
import SideNav from './SideNav';

const sideNavItems = [
    {
        title: 'Rotas',
        to: '/rota/list',
        icon: 'timeline-events',
    },
    {
        title: 'Shift Patterns',
        to: '/rota/shift-patterns',
        icon: 'control',
    },
];

class AdminMain extends Component {
    render() {
        return (
            <section className="common-layout">
                <aside className="common-layout__tools">
                    <SideNav items={sideNavItems} />
                </aside>
                <Switch>
                    <Redirect exact from="/rota" to="/rota/list" />

                    <Route
                        exact
                        path="/rota/shift-patterns/new"
                        component={(props) => <ShiftPatterns actionType="create" {...props} />}
                    />
                    <Route exact path="/rota/shift-patterns/:id?" component={ShiftPatterns} />

                    <Route exact path="/rota/list/:id?" component={RotaList} />
                    <Route exact path="/rota" component={Dashboard} />
                    <Route
                        exact
                        path="/rota/builder"
                        component={(props) => <RotaBuilder actionType="create" {...props} />}
                    />
                    <Route exact path="/rota/builder/:id" component={RotaBuilder} />
                    <Route exact path="/rota/shift-patterns" component={ShiftPatterns} />
                    <Route exact path="/rota/shift-patterns/:id" component={ShiftPatterns} />
                    <Route
                        exact
                        path="/rota/add-shift-patterns"
                        component={(props) => <ShiftPatterns actionType="create" {...props} />}
                    />
                </Switch>
            </section>
        );
    }
}

export default AdminMain;
