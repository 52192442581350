import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Modal from '../../modal/Modal';
import CostCentresSummary from './CostCentresSummary';
import { Formik, Form } from 'formik';
import { Tab, Tabs } from '@blueprintjs/core';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import RelatedEntities from '../../../common/RelatedEntities';
import { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';

const CostCentresDetails = inject('RootStore')(
    observer(
        class CostCentresDetails extends Component {
            state = {
                isEdit: false,
                showFooter: true,
                activeTab: 1,
                initialData: {
                    id: '',
                    name: '',
                    code: '',
                    createdAt: '',
                    updatedAt: '',
                    departments: [],
                    isArchived: false,
                },
                relatedEntitiesConnected: false,
                departmentsByCostCentre: [],
                costCentreNames: [],
                changesNotSaved: false,
                costCentresNames: [],
                isLoaded: false,
                showCancelAlert: false,
                showAlert: false,
                showUpdateAlert: false,
                showRelatedAlert: false,
                values: {},
            };

            async componentDidMount() {
                const actionType = (this.props as any).actionType;
                if (actionType === 'create') {
                    this.setState({ isEdit: true });
                } else {
                    const editModeOpenedFromViewList = (this.props as any).location.state
                        ?.editModeOpenedFromViewList;
                    this._updateCostCentre(editModeOpenedFromViewList);
                }

                await (this.props as any).RootStore.costCentresStore.getAllCostCentres();

                const costCentreNames = [
                    ...(this.props as any).RootStore.costCentresStore.allCostCentres,
                ]
                    .filter((costCentre) => !costCentre.isArchived)
                    .map((costCentre) => ({ name: costCentre.name }));

                this.setState({ costCentreNames });
            }

            componentWillUnmount() {
                (this.props as any).RootStore.departmentsStore.departmentsByCostCentre = [];
            }

            _updateCostCentre = async (editModeOpenedFromViewList = false) => {
                const { match } = this.props as any;
                const { id } = match?.params;
                const costCentre = await (this
                    .props as any).RootStore.costCentresStore.getCostCentreById(id);
                if (costCentre) {
                    this.setState(
                        {
                            initialData: { ...costCentre },
                            isLoaded: true,
                            isEdit: editModeOpenedFromViewList ? true : false,
                            showFooter: !costCentre.isArchived,
                        },
                        () => {
                            this._queryRelatedEntries();
                        },
                    );
                }
            };

            _handleCloseClick = () => {
                if ((this.props as any).actionType === 'create') {
                    return (this.props as any).history.replace('/hr/cost-centres');
                }
                return (this.props as any).history.goBack();
            };

            _handleCloseAlert = () => {
                this.setState({
                    showAlert: false,
                    showRelatedAlert: false,
                    showUpdateAlert: false,
                });
            };

            _queryRelatedEntries = async () => {
                const { departmentsStore } = (this.props as any).RootStore;
                await departmentsStore.getDepartmentsByCostCenter(this.state.initialData.id);
                const relatedEntitiesConnected = !!departmentsStore.departmentsByCostCentre.length;
                this.setState({
                    departmentsByCostCentre: departmentsStore.departmentsByCostCentre,
                    relatedEntitiesConnected: relatedEntitiesConnected,
                });
            };

            _handleOnArchive = async () => {
                const { relatedEntitiesConnected } = this.state;

                if (relatedEntitiesConnected) {
                    this.setState({ showRelatedAlert: true });
                } else {
                    this.setState({ showAlert: true });
                }
            };

            _archiveCostCentre = async () => {
                const { id } = this.state.initialData;
                await (this.props as any).RootStore.costCentresStore.archiveCostCentre(id);
                (this.props as any).history.replace('/hr/cost-centres');
            };

            _handleSubmit = async (values: any, { setSubmitting }: any) => {
                const normalizedValues = validationSchema.cast(values);
                const {
                    actionType,
                    RootStore: { costCentresStore },
                    history,
                } = this.props as any;

                if (actionType === 'create') {
                    await costCentresStore.createCostCentre(normalizedValues);
                }

                if (actionType === 'edit') {
                    if (this.state.relatedEntitiesConnected && this.state.changesNotSaved) {
                        return this.setState({ showUpdateAlert: true });
                    }

                    this.setState({ values: normalizedValues }, () => {
                        this._submitUpdate();
                    });
                }

                this.setState({ changesNotSaved: false });
                setSubmitting(false);

                if (actionType === 'create') {
                    history.replace('/hr/cost-centres');
                }

                if (actionType === 'edit') {
                    this._updateCostCentre();
                }
            };

            _submitUpdate = async () => {
                const { costCentresStore } = (this.props as any).RootStore;
                await costCentresStore.updateCostCentre(this.state.values);
                this.setState({ isEdit: false });
            };

            _finishSubmitProcess = async (values: any) => {
                this._closeAlert();
                const normalizedValues = validationSchema.cast(values);
                this.setState({ values: normalizedValues }, () => {
                    this._submitUpdate();
                });
                this.setState({ changesNotSaved: false });
                this._updateCostCentre();
            };

            _closeAlert = () => {
                this.setState({
                    showCancelAlert: false,
                    showRelatedAlert: false,
                    showArchiveRelatedAlert: false,
                    showUpdateAlert: false,
                    showAlert: false,
                });
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !(prevState as any).isEdit };
                });
            };

            _handleOnCancel = () => {
                if ((this.props as any).actionType === 'create') {
                    return (this.props as any).history.replace('/hr/cost-centres');
                }
                if ((this.props as any).actionType === 'edit' && !this.state.changesNotSaved) {
                    return this._toggleIsEdit();
                }
                const path = (this.props as any).history.location.pathname;
                (this.props as any).history.replace(path);
            };

            _handleOnCancelConfirm = async (replacePath: string) => {
                await this.setState({ changesNotSaved: false });
                if ((this.props as any).actionType === 'edit') {
                    (this as any).formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                (this.props as any).history.replace(replacePath ? replacePath : '/hr/cost-centres');
            };

            render() {
                const {
                    RootStore: { costCentresStore, dictionaryStore },
                } = this.props as any;

                if ((this.props as any).actionType !== 'create' && !this.state.isLoaded) {
                    return null;
                }

                const { departmentsByCostCentre, costCentreNames } = this.state;

                const title =
                    (this.props as any).actionType === 'create'
                        ? 'Add Cost Centre record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        innerRef={(f) => ((this as any).formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validateOnChange={true}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            const normalizedValues = validationSchema.cast(values);
                            const { name, code } = normalizedValues;
                            const errors = {};

                            if (name !== this.state.initialData.name) {
                                const isNameUnique = costCentresStore.allCostCentres.filter(
                                    (costCentre: any) =>
                                        costCentre.name === name && !costCentre.isArchived,
                                );

                                if (isNameUnique.length) {
                                    (errors as any).notUniqueId = isNameUnique[0].id;
                                    (errors as any).notUniqueName = isNameUnique[0].name;
                                }
                            }

                            if (code !== this.state.initialData.code) {
                                const isCodeUnique = costCentresStore.allCostCentres.filter(
                                    (costCentre: any) =>
                                        costCentre.code === code && !costCentre.isArchived,
                                );
                                if (isCodeUnique.length) {
                                    (errors as any).codeExistsCostCentreId = isCodeUnique[0].id;
                                }
                            }

                            // update changesNotSaved flag to show popUp if closing form without saving
                            if (isEqual(this.state.initialData, normalizedValues)) {
                                this.setState({ changesNotSaved: false });
                            } else {
                                this.setState({ changesNotSaved: true });
                            }

                            return errors;
                        }}
                        onSubmit={this._handleSubmit}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        this.state.activeTab === 1 ? (
                                            <CommonModalFooter
                                                isSubmitting={isSubmitting}
                                                toggleIsEdit={this._toggleIsEdit}
                                                isEditing={this.state.isEdit}
                                                onCancel={this._handleOnCancel}
                                                onArchive={this._handleOnArchive}
                                                hasDeleteOption={false}
                                                hasArchiveOption={
                                                    !this.state.initialData.isArchived
                                                }
                                                hasEditOption={!this.state.initialData.isArchived}
                                            />
                                        ) : null
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="costCentreTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                        }}
                                        selectedTabId={this.state.activeTab}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                (this.props as any).actionType === 'edit'
                                                    ? 'Summary'
                                                    : ''
                                            }
                                            panel={
                                                <CostCentresSummary
                                                    costCentre={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    touched={touched as any}
                                                    costCentres={Object.values(
                                                        dictionaryStore.costCentresDictionary,
                                                    )}
                                                    costCentreNames={costCentreNames}
                                                    setFieldValue={setFieldValue}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                (this.props as any).actionType === 'edit'
                                                    ? `Related entities`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        hasRelatedDepartments={true}
                                                        relatedDepartments={
                                                            departmentsByCostCentre as any
                                                        }
                                                        description={
                                                            'This cost centre is related to the following entities (archived entities are not displayed)'
                                                        }
                                                        className={'cost-centres'}
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._archiveCostCentre}
                                    onCancel={this._handleCloseAlert}
                                    isOpen={this.state.showAlert}
                                    title="Confirm archiving!"
                                    description="Are you sure you want to archive this Cost Centre? This action is irreversible."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Save"
                                    onConfirm={() => this._finishSubmitProcess(values)}
                                    onCancel={this._handleCloseAlert}
                                    isOpen={this.state.showUpdateAlert}
                                    icon="refresh"
                                    title="Confirm update!"
                                    description="Are you sure you want to save changes to this Cost Centre? It will affect related Department(s)."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Ok"
                                    onConfirm={this._handleCloseAlert}
                                    isOpen={this.state.showRelatedAlert}
                                    icon="error"
                                    title="Cannot archive!"
                                    description="The Cost Centre cannot be archived because at least one Department is associated with it. To archive this Cost Centre, remove it from related departmnets"
                                    cancelButtonText={undefined}
                                    onCancel={undefined}
                                    canEscapeKeyCancel={false}
                                />
                                <RouterPrompt
                                    when={this.state.changesNotSaved && this.state.isEdit}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default CostCentresDetails;
