import React, { Component } from 'react';
import {
    Button,
    Callout,
    FormGroup,
    InputGroup,
    Checkbox,
    Collapse,
    Switch,
} from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';

import Select from 'react-select';
import Error from '../../forms/Error';
import { dateFormat } from '../../../../constants/patientsConst';
import {
    formatDate,
    momentFormatter,
    formatYearsOfService,
    dateWithoutTime,
} from '../../helpers/formatData';
import { inject, observer } from 'mobx-react';
import { Form, Formik, FieldArray, FormikProps, FormikErrors } from 'formik';
import { validationSchema } from './validation';
import { sendInvite } from '../../../../api/hrRotaApi';
import PersonModalFooter from '../../../common/PersonModalFooter';
import PhotoInput from '../../../common/formik/PhotoInput';
import { getBearerToken } from '../../../../api/amplifyApi';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { GenderReassignment } from '../../../../constants/rotaConst';
import CustomAsyncSelect from '../../../common/CustomAsyncSelect';
import CreatableAsyncSelect from '../../../common/CreatableAsyncSelect';
import _ from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import { isEqual } from 'lodash';

// todo correct tpyes , in this interface and lots of any in the file.
interface IPersonSummaryProps {
    RootStore: any; //todo correct type
    updatePersonDataAfterChange: () => void;
    handleCloseClick: () => void;
    deletePerson: any; //probably boolean
    person: { [key: string]: any }; //todo correct type
    toggleIsEdit: () => void;
    handleOnModalExit: () => void;
    updatePerson: () => void;
    actionType: string;
    history: any;
    editOrCreateMode: any;
    isEditMode: any;
    isCreateMode: any;
}
const PersonSummary = inject('RootStore')(
    observer(
        class PersonSummary extends Component<IPersonSummaryProps> {
            formik: FormikProps<any> | { resetForm: () => void } = { resetForm: () => {} };
            state = {
                sendingInvite: false,
                t: null,
                profileCollapse: true,
                contactDetailsCollapse: true,
                financeCollapse: true,
                emergencyContactDetailsCollapse: true,
                governanceCollapse: true,
                equalityAndDiversityCollapse: true,
                employmentDetailsCollapse: true,
                formWasEdited: false,
            };

            _handleSendInvite = async () => {
                const { org } = this.props.RootStore.configStore;

                this.setState({ sendingInvite: true });

                await sendInvite(this.props.person.id, org);
                await this.props.updatePersonDataAfterChange();

                this.setState({ sendingInvite: false });
            };
            _handleOnCancel = () => {
                this.state.formWasEdited
                    ? this.props.history.replace('/hr/people')
                    : this.props.toggleIsEdit();
            };

            _editStatusForm = (values: any) => {
                this.setState(() => {
                    return { formWasEdited: !isEqual(this.props.person, values) };
                });
            };

            _handleOnCancelConfirm = async () => {
                await this.setState({ formWasEdited: false });
                if (this.props.actionType === 'edit') {
                    this?.formik?.resetForm({ values: this.props.person });
                    this.props.toggleIsEdit();
                }
                if (this.props.actionType === 'create') {
                    this.props.history.replace('/hr/people');
                }
            };

            render() {
                const {
                    RootStore: {
                        peopleStore,
                        dictionaryStore: {
                            languages,
                            genders,
                            getTitles,
                            getEthnicities,
                            getCountries,
                            getReligionsOrBeliefs,
                            getSexualOrientations,
                            getMartialStatuses,
                            getDisabilities,
                            languagesDictionary,
                            gendersDictionary,
                            performerAlignments,
                            performerAlignmentsDictionary,
                            performerRoles,
                            performerRolesDictionary,
                            performerStatuses,
                            performerStatusesDictionary,
                            performerTeams,
                            performerTeamsDictionary,
                        },
                        configStore: { org },
                    },
                    person,
                    editOrCreateMode,
                    isEditMode,
                    isCreateMode,
                } = this.props;

                const {
                    profileCollapse,
                    contactDetailsCollapse,
                    financeCollapse,
                    emergencyContactDetailsCollapse,
                    governanceCollapse,
                    equalityAndDiversityCollapse,
                    employmentDetailsCollapse,
                } = this.state;

                const inviteSent = person.inviteStatus === 'SENT';
                const nationalInsuranceMask = [
                    /[A-CEGHJ-PR-TW-Z]/,
                    /[A-CEGHJ-PR-TW-Z]/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /\d/,
                    /\d/,
                    ' ',
                    /[A-D]/,
                ];

                return (
                    <Formik
                        enableReinitialize={true}
                        innerRef={(f: FormikProps<any>) => (this.formik = f)}
                        initialValues={{
                            ...person,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values: any) => {
                            this._editStatusForm(values);

                            const {
                                personalEmail,
                                personalPhone,
                                dbsNumber,
                                nationalInsurance,
                                alternativeNames,
                            } = values;
                            const errors: FormikErrors<{
                                [key: string]: any;
                                alternativeNames: {}[];
                                dbsNumberNonUnique?: { [key: string]: any };
                            }> = { alternativeNames: [] };
                            if (
                                !!personalEmail &&
                                personalEmail.length > 0 &&
                                personalEmail !== person.personalEmail
                            ) {
                                const {
                                    data: { isPersonEmailUnique },
                                } = await peopleStore.validateEmail(personalEmail);

                                if (!isPersonEmailUnique) {
                                    errors.personalEmail = 'Email should be unique';
                                }
                            }
                            if (
                                personalPhone &&
                                personalPhone.length > 0 &&
                                personalPhone !== person.personalPhone
                            ) {
                                const {
                                    data: { isPersonPhoneUnique },
                                } = await peopleStore.validatePhoneNumber(personalPhone);

                                if (!isPersonPhoneUnique) {
                                    errors.personalPhone = 'Phone number should be unique';
                                }
                            }

                            if (
                                dbsNumber &&
                                dbsNumber.length > 0 &&
                                dbsNumber !== person.dbsNumber
                            ) {
                                const {
                                    data: {
                                        isDBSNumberUnique: { isUnique, person },
                                    },
                                } = await peopleStore.validateDBSNumber(dbsNumber);

                                if (!isUnique) {
                                    const id = person.id;
                                    const personDetails = `${person.firstName} ${
                                        person.middleName ? person.middleName : ''
                                    } ${person.lastName}`;
                                    /* @ts-ignore todo fix*/
                                    errors.dbsNumberNonUnique = { id, personDetails };
                                }
                            }
                            if (
                                nationalInsurance &&
                                nationalInsurance.length > 0 &&
                                nationalInsurance !== person.nationalInsurance
                            ) {
                                const {
                                    data: { isNationalInsuranceUnique },
                                } = await peopleStore.validateNationalInsurance(nationalInsurance);

                                if (!isNationalInsuranceUnique) {
                                    errors.nationalInsurance =
                                        'National insurance number should be unique';
                                }
                            }

                            if (alternativeNames.length) {
                                errors.alternativeNames = [];
                                const length = alternativeNames.length;
                                alternativeNames.forEach((row: any, index: number) => {
                                    const { firstName, lastName, middleName } = row;
                                    //@ts-ignore todo fix
                                    errors.alternativeNames.push({});
                                    // first check
                                    if (
                                        (firstName || lastName || middleName) &&
                                        (!firstName || !lastName)
                                    ) {
                                        if (!firstName) {
                                            //@ts-ignore todo fix
                                            errors.alternativeNames[index].firstName =
                                                'This field is required.';
                                        }

                                        if (!lastName) {
                                            //@ts-ignore todo fix
                                            errors.alternativeNames[index].lastName =
                                                'This field is required.';
                                        }
                                    }
                                    // second check for blank rows in the middle
                                    if (!firstName && !lastName && !middleName) {
                                        let laterRowsHaveData = false;
                                        for (let i = index; i < length; i++) {
                                            const {
                                                firstName,
                                                lastName,
                                                middleName,
                                            } = alternativeNames[i];
                                            if (firstName || lastName || middleName) {
                                                laterRowsHaveData = true;
                                                break;
                                            }
                                        }
                                        if (laterRowsHaveData) {
                                            //@ts-ignore todo fix
                                            errors.alternativeNames[index].firstName =
                                                'This field is required.';
                                            //@ts-ignore todo fix
                                            errors.alternativeNames[index].lastName =
                                                'This field is required.';
                                        }
                                    }
                                });

                                if (errors.alternativeNames.every((obj: any) => _.isEmpty(obj))) {
                                    delete errors.alternativeNames;
                                }
                            }

                            return errors;
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (this.props.actionType === 'create') {
                                await this.setState({ formWasEdited: false });
                                await peopleStore.createPerson(values);
                                this.props.history.replace('/hr/people');
                            }
                            if (this.props.actionType === 'edit') {
                                if (!isEqual(this.props.person, values)) {
                                    await peopleStore.updatePerson(values);
                                }
                                this.props.updatePerson();
                                await this.setState({ formWasEdited: false });
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            validateForm,
                            errors,
                            isValid,
                        }: {
                            [key: string]: any;
                            errors: { [key: string]: any };
                        }) => {
                            const [imgData, setImgData] = React.useState<string | null>(null);
                            React.useEffect(() => {
                                if (!person.profilePhoto) return;

                                async function getImage() {
                                    const token = await getBearerToken({ asBearerToken: true });

                                    const imgResp = await fetch(`https://${person.profilePhoto}`, {
                                        method: 'GET',
                                        headers: {
                                            Authorization: token,
                                        },
                                    }).catch((error) => console.log(error));

                                    function arrayBufferToBase64(buffer: any) {
                                        // @ts-ignore todo fix ignore
                                        return btoa(String.fromCharCode(...new Uint8Array(buffer)));
                                    }

                                    const binaryData = imgResp && (await imgResp.arrayBuffer());
                                    const base64 = arrayBufferToBase64(binaryData);
                                    setImgData(`data:image/png;base64,${base64}`);
                                }

                                getImage();
                                // eslint-disable-next-line react-hooks/exhaustive-deps
                            }, [person.profilePhoto]);
                            return (
                                <Form className="modal__form">
                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            profileCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({ profileCollapse: !profileCollapse })
                                        }
                                    >
                                        Profile
                                    </Button>
                                    <Collapse
                                        isOpen={profileCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">Profile Photo</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <PhotoInput org={org} />
                                                ) : imgData ? (
                                                    <img
                                                        width="70px"
                                                        src={imgData}
                                                        alt={
                                                            values.firstName
                                                                ? `${values.firstName} profile photo`
                                                                : 'Profile photo'
                                                        }
                                                    />
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Title *</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="title">
                                                        <CustomAsyncSelect
                                                            isMulti={false}
                                                            onLoadOptions={getTitles}
                                                            values={values.title}
                                                            onChange={(e) => {
                                                                setFieldValue('title', e.value);
                                                            }}
                                                        />
                                                        {errors.title && (
                                                            <Error errors={[errors.title]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.title?.name ? (
                                                    values.title?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">First Name *</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="firstName">
                                                        <InputGroup
                                                            id="firstName"
                                                            name="firstName"
                                                            onChange={handleChange}
                                                            value={values.firstName}
                                                            large
                                                        />
                                                        {errors.firstName && (
                                                            <Error errors={[errors.firstName]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.firstName ? (
                                                    values.firstName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Preferred Name</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="preferredName">
                                                        <InputGroup
                                                            id="preferredName"
                                                            name="preferredName"
                                                            onChange={handleChange}
                                                            value={values.preferredName}
                                                            large
                                                        />
                                                        {errors.preferredName && (
                                                            <Error
                                                                errors={[errors.preferredName]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : person.preferredName ? (
                                                    person.preferredName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Middle Name</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="middleName">
                                                        <InputGroup
                                                            id="middleName"
                                                            name="middleName"
                                                            onChange={handleChange}
                                                            value={values.middleName}
                                                            large
                                                        />
                                                        {errors.middleName && (
                                                            <Error errors={[errors.middleName]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.middleName ? (
                                                    values.middleName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Last Name *</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="lastName">
                                                        <InputGroup
                                                            id="lastName"
                                                            name="lastName"
                                                            onChange={handleChange}
                                                            value={values.lastName}
                                                            large
                                                        />
                                                        {errors.lastName && (
                                                            <Error errors={[errors.lastName]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.lastName ? (
                                                    values.lastName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Maiden Name</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="maidenName">
                                                        <InputGroup
                                                            id="maidenName"
                                                            name="maidenName"
                                                            onChange={handleChange}
                                                            value={values.maidenName}
                                                            large
                                                        />
                                                        {errors.maidenName && (
                                                            <Error errors={[errors.maidenName]} />
                                                        )}
                                                    </FormGroup>
                                                ) : person.maidenName ? (
                                                    person.maidenName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>

                                            {editOrCreateMode ? (
                                                <FormGroup
                                                    label="Alternative names"
                                                    labelFor="alternativeNamesToggle"
                                                >
                                                    <div>
                                                        <Switch
                                                            inline={true}
                                                            name="alternativeNamesToggle"
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'alternativeNamesToggle',
                                                                    !values.alternativeNamesToggle,
                                                                );
                                                                const alternativeNames = values.alternativeNamesToggle
                                                                    ? []
                                                                    : [
                                                                          {
                                                                              firstName: '',
                                                                              middleName: '',
                                                                              lastName: '',
                                                                          },
                                                                      ];
                                                                setFieldValue(
                                                                    'alternativeNames',
                                                                    alternativeNames,
                                                                );
                                                            }}
                                                            checked={values.alternativeNamesToggle}
                                                        ></Switch>
                                                        <div style={{ fontStyle: 'italic' }}>
                                                            {!values.alternativeNamesToggle
                                                                ? 'No alternative names recorded'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                </FormGroup>
                                            ) : values.alternativeNames &&
                                              values.alternativeNames.length ? null : (
                                                <div className="person-fields__definition">
                                                    No alternative names recorded
                                                </div>
                                            )}

                                            <FieldArray
                                                name="alternativeNames"
                                                render={(arrayHelpers: any) => (
                                                    <div>
                                                        {values.alternativeNames &&
                                                        values.alternativeNames.length > 0
                                                            ? values.alternativeNames.map(
                                                                  (row: any, index: any) => {
                                                                      return (
                                                                          <div
                                                                              className="person-fields__alternative-names-row"
                                                                              key={index}
                                                                          >
                                                                              <div className="person-fields__alternative-names-row-fields">
                                                                                  <div className="person-fields__alternative-names-field">
                                                                                      {index ===
                                                                                      0 ? (
                                                                                          <dt className="person-fields__title">
                                                                                              Alternative
                                                                                              First
                                                                                              Name *
                                                                                          </dt>
                                                                                      ) : null}

                                                                                      <dd className="person-fields__definition">
                                                                                          {editOrCreateMode ? (
                                                                                              <FormGroup
                                                                                                  labelFor={`alternativeNames-${index}-firstName`}
                                                                                              >
                                                                                                  <InputGroup
                                                                                                      id={`alternativeNames-${index}-firstName`}
                                                                                                      name={`alternativeNames-${index}-firstName`}
                                                                                                      onChange={(
                                                                                                          e,
                                                                                                      ) => {
                                                                                                          setFieldValue(
                                                                                                              `alternativeNames.${index}.firstName`,
                                                                                                              e &&
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                          );
                                                                                                      }}
                                                                                                      value={
                                                                                                          row.firstName
                                                                                                      }
                                                                                                      large
                                                                                                  />
                                                                                                  {errors.alternativeNames ? (
                                                                                                      <Error
                                                                                                          errors={[
                                                                                                              // @ts-ignore todo fix
                                                                                                              errors
                                                                                                                  .alternativeNames[
                                                                                                                  index
                                                                                                              ]
                                                                                                                  .firstName,
                                                                                                          ]}
                                                                                                      />
                                                                                                  ) : (
                                                                                                      ''
                                                                                                  )}
                                                                                              </FormGroup>
                                                                                          ) : // @ts-ignore todo fix
                                                                                          person
                                                                                                .alternativeNames[
                                                                                                index
                                                                                            ]
                                                                                                ?.firstName ? (
                                                                                              // @ts-ignore todo fix                                                                                              // @ts-ignore todo fix
                                                                                              person
                                                                                                  .alternativeNames[
                                                                                                  index
                                                                                              ]
                                                                                                  ?.firstName
                                                                                          ) : (
                                                                                              '-'
                                                                                          )}
                                                                                      </dd>
                                                                                  </div>

                                                                                  <div className="person-fields__alternative-names-field">
                                                                                      {index ===
                                                                                      0 ? (
                                                                                          <dt className="person-fields__title">
                                                                                              Alternative
                                                                                              Middle
                                                                                              Name
                                                                                          </dt>
                                                                                      ) : null}

                                                                                      <dd className="person-fields__definition">
                                                                                          {editOrCreateMode ? (
                                                                                              <FormGroup
                                                                                                  labelFor={`alternativeNames-${index}-middleName`}
                                                                                              >
                                                                                                  <InputGroup
                                                                                                      id={`alternativeNames-${index}-middleName`}
                                                                                                      name={`alternativeNames-${index}-middleName`}
                                                                                                      onChange={(
                                                                                                          e,
                                                                                                      ) => {
                                                                                                          setFieldValue(
                                                                                                              `alternativeNames.${index}.middleName`,
                                                                                                              e &&
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                          );
                                                                                                      }}
                                                                                                      value={
                                                                                                          row?.middleName
                                                                                                      }
                                                                                                      large
                                                                                                  />
                                                                                                  {errors.alternativeNames ? (
                                                                                                      <Error
                                                                                                          errors={[
                                                                                                              // @ts-ignore todo fix
                                                                                                              errors
                                                                                                                  .alternativeNames[
                                                                                                                  index
                                                                                                              ]
                                                                                                                  .middleName,
                                                                                                          ]}
                                                                                                      />
                                                                                                  ) : (
                                                                                                      ''
                                                                                                  )}
                                                                                              </FormGroup>
                                                                                          ) : // @ts-ignore todo fix
                                                                                          person
                                                                                                .alternativeNames[
                                                                                                index
                                                                                            ]
                                                                                                ?.middleName ? (
                                                                                              person
                                                                                                  .alternativeNames[
                                                                                                  index
                                                                                              ]
                                                                                                  ?.middleName
                                                                                          ) : (
                                                                                              '-'
                                                                                          )}
                                                                                      </dd>
                                                                                  </div>

                                                                                  <div className="person-fields__alternative-names-field">
                                                                                      {index ===
                                                                                      0 ? (
                                                                                          <dt className="person-fields__title">
                                                                                              Alternative
                                                                                              Last
                                                                                              Name *
                                                                                          </dt>
                                                                                      ) : null}

                                                                                      <dd className="person-fields__definition">
                                                                                          {editOrCreateMode ? (
                                                                                              <FormGroup
                                                                                                  labelFor={`alternativeNames-${index}-lastName`}
                                                                                              >
                                                                                                  <InputGroup
                                                                                                      id={`alternativeNames-${index}-lastName`}
                                                                                                      name={`alternativeNames-${index}-lastName`}
                                                                                                      onChange={(
                                                                                                          e,
                                                                                                      ) => {
                                                                                                          setFieldValue(
                                                                                                              `alternativeNames.${index}.lastName`,
                                                                                                              e &&
                                                                                                                  e
                                                                                                                      .target
                                                                                                                      .value,
                                                                                                          );
                                                                                                      }}
                                                                                                      value={
                                                                                                          row?.lastName
                                                                                                      }
                                                                                                      large
                                                                                                  />
                                                                                                  {errors.alternativeNames ? (
                                                                                                      <Error
                                                                                                          errors={[
                                                                                                              // @ts-ignore todo fix
                                                                                                              errors
                                                                                                                  .alternativeNames[
                                                                                                                  index
                                                                                                              ]
                                                                                                                  .lastName,
                                                                                                          ]}
                                                                                                      />
                                                                                                  ) : (
                                                                                                      ''
                                                                                                  )}
                                                                                              </FormGroup>
                                                                                          ) : // @ts-ignore todo fix
                                                                                          person
                                                                                                .alternativeNames[
                                                                                                index
                                                                                            ]
                                                                                                ?.lastName ? (
                                                                                              // @ts-ignore todo fix
                                                                                              person
                                                                                                  .alternativeNames[
                                                                                                  index
                                                                                              ]
                                                                                                  ?.lastName
                                                                                          ) : (
                                                                                              '-'
                                                                                          )}
                                                                                      </dd>
                                                                                  </div>
                                                                              </div>
                                                                              {editOrCreateMode ? (
                                                                                  <div className="person-fields__alternative-names-row-controls">
                                                                                      <Button
                                                                                          intent="success"
                                                                                          icon="add"
                                                                                          onClick={() => {
                                                                                              arrayHelpers.push(
                                                                                                  {
                                                                                                      firstName:
                                                                                                          '',
                                                                                                      middleName:
                                                                                                          '',
                                                                                                      lastName:
                                                                                                          '',
                                                                                                  },
                                                                                              );
                                                                                          }}
                                                                                          disabled={
                                                                                              values
                                                                                                  .alternativeNames
                                                                                                  .length <
                                                                                              2
                                                                                                  ? false
                                                                                                  : true
                                                                                          }
                                                                                          className="bp5-outlined btn--secondary"
                                                                                      />
                                                                                      <Button
                                                                                          intent="danger"
                                                                                          icon="remove"
                                                                                          onClick={() => {
                                                                                              arrayHelpers.remove(
                                                                                                  index,
                                                                                              );
                                                                                              // if we have only one field than on deleting it we will toggle Alternative names button
                                                                                              if (
                                                                                                  values
                                                                                                      .alternativeNames
                                                                                                      .length ===
                                                                                                  1
                                                                                              ) {
                                                                                                  setFieldValue(
                                                                                                      'alternativeNamesToggle',
                                                                                                      !values.alternativeNamesToggle,
                                                                                                  );
                                                                                              }
                                                                                          }}
                                                                                          className="bp5-outlined btn--secondary"
                                                                                      />
                                                                                  </div>
                                                                              ) : (
                                                                                  ''
                                                                              )}
                                                                          </div>
                                                                      );
                                                                  },
                                                              )
                                                            : null}
                                                    </div>
                                                )}
                                            />
                                            <dt className="person-fields__title">Date of Birth</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="dateOfBirth">
                                                        <DateInput3
                                                            showTimezoneSelect={false}
                                                            // @ts-ignore todo
                                                            id="dateOfBirth"
                                                            {...momentFormatter(dateFormat)}
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'dateOfBirth',
                                                                    dateWithoutTime(value),
                                                                )
                                                            }
                                                            minDate={moment()
                                                                .subtract(100, 'years')
                                                                .toDate()}
                                                            maxDate={moment().toDate()}
                                                            value={values.dateOfBirth}
                                                        />
                                                        {errors.dateOfBirth && (
                                                            <Error errors={[errors.dateOfBirth]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.dateOfBirth ? (
                                                    formatDate(values.dateOfBirth)
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Gender *</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="genderId">
                                                        <Select
                                                            options={genders}
                                                            defaultValue={{
                                                                label:
                                                                    gendersDictionary[
                                                                        values.genderId
                                                                    ],
                                                                value: values.genderId,
                                                            }}
                                                            onChange={(e: any) =>
                                                                setFieldValue('genderId', e.value)
                                                            }
                                                        />
                                                        {errors.genderId && (
                                                            <Error errors={[errors.genderId]} />
                                                        )}
                                                    </FormGroup>
                                                ) : values.genderId ? (
                                                    gendersDictionary[values.genderId]
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Languages Spoken
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="languages">
                                                        <Select
                                                            options={languages}
                                                            defaultValue={(
                                                                values.languages ?? []
                                                            ).map((languageId: string) => ({
                                                                label:
                                                                    languagesDictionary[languageId],
                                                                value: languageId,
                                                            }))}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'languages',
                                                                    (e ?? []).map((x) => x.value),
                                                                )
                                                            }
                                                            isMulti
                                                            name="languages"
                                                            id="languages"
                                                        />
                                                    </FormGroup>
                                                ) : values.languages.length ? (
                                                    values.languages
                                                        .map(
                                                            (languageId: any) =>
                                                                languagesDictionary[languageId],
                                                        )
                                                        .join(', ')
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            {!isCreateMode && (
                                                <>
                                                    <dt className="person-fields__title">
                                                        Doc Abode Username
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        {person.cognitoUsername ? (
                                                            person.cognitoUsername
                                                        ) : (
                                                            <Callout
                                                                intent={
                                                                    inviteSent
                                                                        ? 'success'
                                                                        : 'primary'
                                                                }
                                                            >
                                                                {inviteSent ? (
                                                                    <p>
                                                                        Invite sent and awaiting a
                                                                        response.
                                                                    </p>
                                                                ) : (
                                                                    <p>
                                                                        This person does not
                                                                        currently have a username
                                                                        assocatied with your
                                                                        organisation, meaning that
                                                                        they will not be able to log
                                                                        into Doc Abode and manage
                                                                        their own details or shifts.
                                                                        To connect this person's
                                                                        record to a Doc Abode user
                                                                        account, please click below
                                                                        to send them an email
                                                                        invitation.
                                                                    </p>
                                                                )}
                                                                <Button
                                                                    onClick={this._handleSendInvite}
                                                                    loading={
                                                                        this.state.sendingInvite
                                                                    }
                                                                    text={
                                                                        inviteSent
                                                                            ? 'Resend invite'
                                                                            : 'Send invite'
                                                                    }
                                                                    icon="send-message"
                                                                    intent={
                                                                        inviteSent
                                                                            ? 'success'
                                                                            : 'primary'
                                                                    }
                                                                    outlined
                                                                />
                                                            </Callout>
                                                        )}
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </Collapse>

                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            contactDetailsCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({
                                                contactDetailsCollapse: !contactDetailsCollapse,
                                            })
                                        }
                                    >
                                        Contact Details
                                    </Button>
                                    <Collapse
                                        isOpen={contactDetailsCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">
                                                Personal Phone Number
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="personalPhone">
                                                        <InputGroup
                                                            id="personalPhone"
                                                            name="personalPhone"
                                                            onChange={handleChange}
                                                            value={values.personalPhone}
                                                            large
                                                        />
                                                        {errors.personalPhone && (
                                                            <Error
                                                                errors={[errors.personalPhone]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.personalPhone ? (
                                                    values.personalPhone
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Home Phone Number
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="homePhone">
                                                        <InputGroup
                                                            id="homePhone"
                                                            name="homePhone"
                                                            onChange={handleChange}
                                                            value={values.homePhone}
                                                            large
                                                        />
                                                    </FormGroup>
                                                ) : values.homePhone ? (
                                                    values.homePhone
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Personal Email Address
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="personalEmail">
                                                        <InputGroup
                                                            id="personalEmail"
                                                            name="personalEmail"
                                                            large
                                                            onChange={handleChange}
                                                            value={values.personalEmail}
                                                        />
                                                        {errors.personalEmail && (
                                                            <Error
                                                                errors={[errors.personalEmail]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.personalEmail ? (
                                                    values.personalEmail
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Home Address</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="homeAddress">
                                                        <InputGroup
                                                            id="homeAddress"
                                                            name="homeAddress"
                                                            onChange={handleChange}
                                                            value={values.homeAddress}
                                                            large
                                                        />
                                                    </FormGroup>
                                                ) : values.homeAddress ? (
                                                    values.homeAddress
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                        </dl>
                                    </Collapse>

                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            financeCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({ financeCollapse: !financeCollapse })
                                        }
                                    >
                                        Finance
                                    </Button>
                                    <Collapse
                                        isOpen={financeCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">NI Number</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        labelFor="nationalInsurance"
                                                        className="bp5-input-group bp5-large"
                                                    >
                                                        <MaskedInput
                                                            mask={nationalInsuranceMask}
                                                            id="nationalInsurance"
                                                            placeholder="XX 12 34 56 X"
                                                            type="text"
                                                            onChange={handleChange}
                                                            className="bp5-input"
                                                            value={values.nationalInsurance}
                                                        />
                                                        {errors.nationalInsurance && (
                                                            <Error
                                                                errors={[errors.nationalInsurance]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.nationalInsurance ? (
                                                    values.nationalInsurance
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Payroll Reference Number
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="payrollReference">
                                                        <InputGroup
                                                            id="payrollReference"
                                                            name="payrollReference"
                                                            large
                                                            onChange={handleChange}
                                                            value={values.payrollReference}
                                                        />
                                                        {errors.payrollReference && (
                                                            <Error
                                                                errors={[errors.payrollReference]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.payrollReference ? (
                                                    values.payrollReference
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                        </dl>
                                    </Collapse>

                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            governanceCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({
                                                governanceCollapse: !governanceCollapse,
                                            })
                                        }
                                    >
                                        Governance
                                    </Button>
                                    <Collapse
                                        isOpen={governanceCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">DBS Number</dt>
                                            <dd className="person-fields__definition info__definition--fullWidth">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="dbs">
                                                        <InputGroup
                                                            id="dbsNumber"
                                                            name="dbsNumber"
                                                            onChange={handleChange}
                                                            value={values.dbsNumber}
                                                            maxLength={12}
                                                            large
                                                            // @ts-ignore todo
                                                            buttonPosition="none"
                                                        />

                                                        {errors.dbsNumber && (
                                                            <Error errors={[errors.dbsNumber]} />
                                                        )}

                                                        {errors.dbsNumber &&
                                                            errors.dbsNumberNonUnique && (
                                                                <div
                                                                    className="info__definition--danger"
                                                                    hidden={
                                                                        !errors.dbsNumberNonUnique
                                                                    }
                                                                >
                                                                    Reference number already
                                                                    assigned to{' '}
                                                                    <a
                                                                        target={'_blank'}
                                                                        rel={'noreferrer'}
                                                                        // @ts-ignore todo
                                                                        href={`hr/people/${errors.dbsNumberNonUnique.id}`}
                                                                    >
                                                                        {
                                                                            /*  @ts-ignore todo fix */
                                                                            errors
                                                                                .dbsNumberNonUnique
                                                                                .personDetails
                                                                        }
                                                                    </a>
                                                                </div>
                                                            )}
                                                    </FormGroup>
                                                ) : values.dbsNumber ? (
                                                    values.dbsNumber
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Professional Body Name
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="professionalBodyName">
                                                        <InputGroup
                                                            id="professionalBodyName"
                                                            name="professionalBodyName"
                                                            onChange={handleChange}
                                                            value={values.professionalBodyName}
                                                            large
                                                        />
                                                        {errors.professionalBodyName && (
                                                            <Error
                                                                errors={[
                                                                    errors.professionalBodyName,
                                                                ]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.professionalBodyName ? (
                                                    values.professionalBodyName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Professional Registration Number
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="professionalRegNO">
                                                        <InputGroup
                                                            id="professionalRegNO"
                                                            name="professionalRegNO"
                                                            onChange={handleChange}
                                                            value={values.professionalRegNO}
                                                            large
                                                        />
                                                        {errors.professionalRegNO && (
                                                            <Error
                                                                errors={[errors.professionalRegNO]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.professionalRegNO ? (
                                                    values.professionalRegNO
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Add Performers List Information
                                            </dt>
                                            <dd className="person-fields__definition">
                                                <Checkbox
                                                    disabled={!editOrCreateMode}
                                                    aria-label="Add Performers List information"
                                                    checked={values.hasPerformerInfo}
                                                    onChange={(e) =>
                                                        setFieldValue(
                                                            'hasPerformerInfo',
                                                            !values.hasPerformerInfo,
                                                        )
                                                    }
                                                />
                                            </dd>
                                            {values.hasPerformerInfo && (
                                                <>
                                                    <h2>Performers List</h2>
                                                    <dd></dd>
                                                    <dt className="person-fields__title">
                                                        Alignment
                                                    </dt>
                                                    <dd className="person-fields__definition common-select__has-empty">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerAlignmentId">
                                                                <Select
                                                                    options={[
                                                                        {},
                                                                        ...performerAlignments,
                                                                    ]}
                                                                    defaultValue={{
                                                                        label:
                                                                            performerAlignmentsDictionary[
                                                                                person
                                                                                    .performerAlignmentId
                                                                            ],
                                                                        value:
                                                                            person.performerAlignmentId,
                                                                    }}
                                                                    onChange={(e: any) =>
                                                                        setFieldValue(
                                                                            'performerAlignmentId',
                                                                            e.value,
                                                                        )
                                                                    }
                                                                />
                                                                {errors.performerAlignmentId && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerAlignmentId,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerAlignmentId ? (
                                                            performerAlignmentsDictionary[
                                                                values.performerAlignmentId
                                                            ]
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="person-fields__title">
                                                        Performer Role
                                                    </dt>
                                                    <dd className="person-fields__definition common-select__has-empty">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerRoleId">
                                                                <Select
                                                                    options={[
                                                                        {},
                                                                        ...performerRoles,
                                                                    ]}
                                                                    defaultValue={{
                                                                        label:
                                                                            performerRolesDictionary[
                                                                                person
                                                                                    .performerRoleId
                                                                            ],
                                                                        value:
                                                                            person.performerRoleId,
                                                                    }}
                                                                    onChange={(e: any) =>
                                                                        setFieldValue(
                                                                            'performerRoleId',
                                                                            e.value,
                                                                        )
                                                                    }
                                                                />
                                                                {errors.performerRoleId && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerRoleId,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerRoleId ? (
                                                            performerRolesDictionary[
                                                                values.performerRoleId
                                                            ]
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="person-fields__title">
                                                        Professional Registration Number
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerProfessionalRegNumber">
                                                                <InputGroup
                                                                    id="performerProfessionalRegNumber"
                                                                    name="performerProfessionalRegNumber"
                                                                    onChange={handleChange}
                                                                    value={
                                                                        values.performerProfessionalRegNumber
                                                                    }
                                                                    large
                                                                />
                                                                {errors.performerProfessionalRegNumber && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerProfessionalRegNumber,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerProfessionalRegNumber ? (
                                                            values.performerProfessionalRegNumber
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="person-fields__title">Status</dt>
                                                    <dd className="person-fields__definition common-select__has-empty">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerStatusId">
                                                                <Select
                                                                    options={[
                                                                        {},
                                                                        ...performerStatuses,
                                                                    ]}
                                                                    defaultValue={{
                                                                        label:
                                                                            performerStatusesDictionary[
                                                                                person
                                                                                    .performerStatusId
                                                                            ],
                                                                        value:
                                                                            person.performerStatusId,
                                                                    }}
                                                                    onChange={(e: any) =>
                                                                        setFieldValue(
                                                                            'performerStatusId',
                                                                            e.value,
                                                                        )
                                                                    }
                                                                />
                                                                {errors.performerStatusId && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerStatusId,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerStatusId ? (
                                                            performerStatusesDictionary[
                                                                values.performerStatusId
                                                            ]
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="person-fields__title">
                                                        Date First on Performers List
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerDateFirstOnPerformersList">
                                                                <DateInput3
                                                                    showTimezoneSelect={false}
                                                                    // @ts-ignore todo
                                                                    id="performerDateFirstOnPerformersList"
                                                                    {...momentFormatter(dateFormat)}
                                                                    onChange={(value) =>
                                                                        setFieldValue(
                                                                            'performerDateFirstOnPerformersList',
                                                                            dateWithoutTime(value),
                                                                        )
                                                                    }
                                                                    minDate={moment()
                                                                        .subtract(100, 'years')
                                                                        .toDate()}
                                                                    maxDate={moment()
                                                                        .add(1, 'years')
                                                                        .toDate()}
                                                                    value={
                                                                        values.performerDateFirstOnPerformersList
                                                                            ? values.performerDateFirstOnPerformersList
                                                                            : null
                                                                    }
                                                                />
                                                                {errors.performerDateFirstOnPerformersList && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerDateFirstOnPerformersList,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerDateFirstOnPerformersList ? (
                                                            formatDate(
                                                                values.performerDateFirstOnPerformersList,
                                                            )
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="person-fields__title">
                                                        Date in GP Register
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerDateInGPRegister">
                                                                <DateInput3
                                                                    showTimezoneSelect={false}
                                                                    // @ts-ignore todo
                                                                    id="performerDateInGPRegister"
                                                                    {...momentFormatter(dateFormat)}
                                                                    onChange={(value) =>
                                                                        setFieldValue(
                                                                            'performerDateInGPRegister',
                                                                            dateWithoutTime(value),
                                                                        )
                                                                    }
                                                                    minDate={moment()
                                                                        .subtract(100, 'years')
                                                                        .toDate()}
                                                                    maxDate={moment()
                                                                        .add(1, 'years')
                                                                        .toDate()}
                                                                    value={
                                                                        values.performerDateInGPRegister
                                                                            ? values.performerDateInGPRegister
                                                                            : null
                                                                    }
                                                                />
                                                                {errors.performerDateInGPRegister && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerDateInGPRegister,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerDateInGPRegister ? (
                                                            formatDate(
                                                                values.performerDateInGPRegister,
                                                            )
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>

                                                    <dt className="person-fields__title">
                                                        NHSEI Regional Team
                                                    </dt>
                                                    <dd className="person-fields__definition common-select__has-empty">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="performerTeamId">
                                                                <Select
                                                                    options={[
                                                                        {},
                                                                        ...performerTeams,
                                                                    ]}
                                                                    defaultValue={{
                                                                        label:
                                                                            performerTeamsDictionary[
                                                                                person
                                                                                    .performerTeamId
                                                                            ],
                                                                        value:
                                                                            person.performerTeamId,
                                                                    }}
                                                                    onChange={(e: any) =>
                                                                        setFieldValue(
                                                                            'performerTeamId',
                                                                            e.value,
                                                                        )
                                                                    }
                                                                />
                                                                {errors.performerTeamId && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.performerTeamId,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.performerTeamId ? (
                                                            performerTeamsDictionary[
                                                                values.performerTeamId
                                                            ]
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>

                                                    <dt className="person-fields__title">
                                                        Currently in Probationary Period
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        <Checkbox
                                                            disabled={!editOrCreateMode}
                                                            aria-label="Currently in Probationary Period"
                                                            checked={
                                                                values.performerCurrentlyInProbationary
                                                            }
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'performerCurrentlyInProbationary',
                                                                    !values.performerCurrentlyInProbationary,
                                                                )
                                                            }
                                                        />
                                                    </dd>

                                                    <dt className="person-fields__title">
                                                        Last Check Date
                                                    </dt>
                                                    <dd className="person-fields__definition">
                                                        {editOrCreateMode ? (
                                                            <FormGroup labelFor="hasPerformerInfoLastCheckDate">
                                                                <DateInput3
                                                                    showTimezoneSelect={false}
                                                                    // @ts-ignore todo
                                                                    id="hasPerformerInfoLastCheckDate"
                                                                    {...momentFormatter(dateFormat)}
                                                                    onChange={(value) =>
                                                                        setFieldValue(
                                                                            'hasPerformerInfoLastCheckDate',
                                                                            dateWithoutTime(value),
                                                                        )
                                                                    }
                                                                    minDate={moment()
                                                                        .subtract(100, 'years')
                                                                        .toDate()}
                                                                    maxDate={moment().toDate()}
                                                                    value={
                                                                        values.hasPerformerInfoLastCheckDate
                                                                            ? values.hasPerformerInfoLastCheckDate
                                                                            : null
                                                                    }
                                                                />
                                                                {errors.hasPerformerInfoLastCheckDate && (
                                                                    <Error
                                                                        errors={[
                                                                            errors.hasPerformerInfoLastCheckDate,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </FormGroup>
                                                        ) : values.hasPerformerInfoLastCheckDate ? (
                                                            formatDate(
                                                                values.hasPerformerInfoLastCheckDate,
                                                            )
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </dd>
                                                    <dt className="info__title">Last checked by</dt>
                                                    <dd className="info__definition">
                                                        {values.hasPerformerInfoCheckedByPerson}
                                                    </dd>
                                                </>
                                            )}
                                        </dl>
                                    </Collapse>
                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            emergencyContactDetailsCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({
                                                emergencyContactDetailsCollapse: !emergencyContactDetailsCollapse,
                                            })
                                        }
                                    >
                                        Emergency Contact Details
                                    </Button>

                                    <Collapse
                                        isOpen={emergencyContactDetailsCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">
                                                Emergency Contact Name
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="emergencyContactName">
                                                        <InputGroup
                                                            id="emergencyContactName"
                                                            name="emergencyContactName"
                                                            onChange={handleChange}
                                                            value={values.emergencyContactName}
                                                            large
                                                        />
                                                    </FormGroup>
                                                ) : values.emergencyContactName ? (
                                                    values.emergencyContactName
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Emergency Contact Phone Number
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="emergencyContactPhone">
                                                        <InputGroup
                                                            id="emergencyContactPhone"
                                                            name="emergencyContactPhone"
                                                            onChange={handleChange}
                                                            value={values.emergencyContactPhone}
                                                            large
                                                        />
                                                        {errors.emergencyContactPhone && (
                                                            <Error
                                                                errors={[
                                                                    errors.emergencyContactPhone,
                                                                ]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.emergencyContactPhone ? (
                                                    values.emergencyContactPhone
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                        </dl>
                                    </Collapse>
                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            equalityAndDiversityCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({
                                                equalityAndDiversityCollapse: !equalityAndDiversityCollapse,
                                            })
                                        }
                                    >
                                        Equality & Diversity
                                    </Button>
                                    <Collapse
                                        isOpen={equalityAndDiversityCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            <dt className="person-fields__title">Nationality</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="nationality">
                                                        <CustomAsyncSelect
                                                            onLoadOptions={getCountries}
                                                            values={values.nationality}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'nationality',
                                                                    e.value,
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.nationality?.name ? (
                                                    values.nationality?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Ethnicity</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="ethnicity">
                                                        <CreatableAsyncSelect
                                                            onLoadOptions={getEthnicities}
                                                            values={values.ethnicity}
                                                            onChange={(e) => {
                                                                setFieldValue('ethnicity', e.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.ethnicity?.name ? (
                                                    values.ethnicity?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Gender</dt>
                                            <dd className="person-fields__definition">
                                                {values.genderId
                                                    ? gendersDictionary[values.genderId]
                                                    : '-'}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Gender reassignment
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="genderReassignment">
                                                        <Select
                                                            options={GenderReassignment}
                                                            defaultValue={{
                                                                label: GenderReassignment?.find(
                                                                    (x) =>
                                                                        x.value ===
                                                                        values?.genderReassignment,
                                                                )?.label,

                                                                value: values.genderReassignment,
                                                            }}
                                                            onChange={(e: any) =>
                                                                setFieldValue(
                                                                    'genderReassignment',
                                                                    e.value,
                                                                )
                                                            }
                                                        />
                                                        {errors.genderReassignment && (
                                                            <Error
                                                                errors={[errors.genderReassignment]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.genderReassignment ? (
                                                    GenderReassignment?.find(
                                                        (x) =>
                                                            x.value === values?.genderReassignment,
                                                    )?.label
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Religion or belief
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="religionOrBelief">
                                                        <CustomAsyncSelect
                                                            onLoadOptions={getReligionsOrBeliefs}
                                                            values={values.religionOrBelief}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'religionOrBelief',
                                                                    e.value,
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.religionOrBelief?.name ? (
                                                    values.religionOrBelief?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Sexual orientation
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="sexualOrientation">
                                                        <CustomAsyncSelect
                                                            onLoadOptions={getSexualOrientations}
                                                            values={values.sexualOrientation}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'sexualOrientation',
                                                                    e.value,
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.sexualOrientation?.name ? (
                                                    values.sexualOrientation?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Marital status</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="maritalStatus">
                                                        <CustomAsyncSelect
                                                            isMulti={false}
                                                            onLoadOptions={getMartialStatuses}
                                                            values={values.maritalStatus}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'maritalStatus',
                                                                    e.value,
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.maritalStatus?.name ? (
                                                    values.maritalStatus?.name
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Age band</dt>
                                            <dd className="person-fields__definition">
                                                {values.dateOfBirth
                                                    ? moment().diff(values.dateOfBirth, 'years')
                                                    : '-'}
                                            </dd>
                                            <dt className="person-fields__title">Disability</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="personDisability">
                                                        <CustomAsyncSelect
                                                            isMulti={true}
                                                            onLoadOptions={getDisabilities}
                                                            values={values.personDisability}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    'personDisability',
                                                                    (e ?? []).map(
                                                                        (x: {
                                                                            [key: string]: any;
                                                                        }) => x.value,
                                                                    ),
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : values.personDisability.length ? (
                                                    values.personDisability.map(
                                                        (el: any, index: any) =>
                                                            index < 1 ? el?.name : ', ' + el?.name,
                                                    )
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">Carer</dt>
                                            <dd className="person-fields__definition">
                                                <Checkbox
                                                    disabled={!editOrCreateMode}
                                                    aria-label="Carer"
                                                    checked={values.carer}
                                                    onChange={(e) =>
                                                        setFieldValue('carer', !values.carer)
                                                    }
                                                />
                                            </dd>
                                        </dl>
                                    </Collapse>

                                    <Button
                                        className={'person-fields_group-title__button'}
                                        rightIcon={
                                            employmentDetailsCollapse
                                                ? 'symbol-triangle-up'
                                                : 'symbol-triangle-down'
                                        }
                                        onClick={() =>
                                            this.setState({
                                                employmentDetailsCollapse: !employmentDetailsCollapse,
                                            })
                                        }
                                    >
                                        Employment Details
                                    </Button>
                                    <Collapse
                                        isOpen={employmentDetailsCollapse}
                                        className="person-fields__collapse"
                                    >
                                        <dl className="person-fields_group">
                                            {values.employmentPeriodErrors?.length > 0 && (
                                                <div className="person-fields__definition">
                                                    <Error errors={values.employmentPeriodErrors} />
                                                </div>
                                            )}
                                            <dt className="person-fields__title">
                                                Continuous Employment Start Date
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="employmentStartDate">
                                                        <DateInput3
                                                            showTimezoneSelect={false}
                                                            // @ts-ignore todo
                                                            id="employmentStartDate"
                                                            {...momentFormatter(dateFormat)}
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'employmentStartDate',
                                                                    dateWithoutTime(value),
                                                                )
                                                            }
                                                            minDate={moment()
                                                                .subtract(100, 'years')
                                                                .toDate()}
                                                            maxDate={
                                                                values.employmentEndDate ||
                                                                moment().add(10, 'years').toDate()
                                                            }
                                                            value={values.employmentStartDate}
                                                        />
                                                        {errors.employmentStartDate && (
                                                            <Error
                                                                errors={[
                                                                    errors.employmentStartDate,
                                                                ]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.employmentStartDate ? (
                                                    formatDate(values.employmentStartDate)
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Continuous Employment End Date
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="employmentEndDate">
                                                        <DateInput3
                                                            showTimezoneSelect={false}
                                                            /* @ts-ignore todo fix*/
                                                            id="employmentEndDate"
                                                            {...momentFormatter(dateFormat)}
                                                            onChange={(value) =>
                                                                setFieldValue(
                                                                    'employmentEndDate',
                                                                    dateWithoutTime(value),
                                                                )
                                                            }
                                                            minDate={
                                                                values.employmentStartDate ||
                                                                moment()
                                                                    .subtract(100, 'years')
                                                                    .toDate()
                                                            }
                                                            maxDate={moment()
                                                                .add(10, 'years')
                                                                .toDate()}
                                                            value={values.employmentEndDate}
                                                        />
                                                        {errors.employmentEndDate && (
                                                            <Error
                                                                errors={[errors.employmentEndDate]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.employmentEndDate ? (
                                                    formatDate(values.employmentEndDate)
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                            <dt className="person-fields__title">
                                                Years of Service
                                            </dt>
                                            <dd className="person-fields__definition">
                                                {formatYearsOfService(
                                                    values.employmentStartDate,
                                                    values.employmentEndDate,
                                                )}
                                            </dd>
                                        </dl>
                                    </Collapse>

                                    <dl className="person-fields_group no-margin">
                                        {isEditMode && (
                                            <>
                                                <dt className="person-fields__title">
                                                    Last Modified
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {person.updatedAt}
                                                </dd>
                                            </>
                                        )}
                                    </dl>

                                    <footer className="modal__footer">
                                        <PersonModalFooter
                                            isValid={isValid}
                                            isSubmitting={isSubmitting}
                                            isEditing={editOrCreateMode}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            validateForm={validateForm}
                                            // @ts-ignore todo
                                            errors={errors}
                                            toggleIsEdit={this.props.toggleIsEdit}
                                            onCancel={this._handleOnCancel}
                                            onModalExit={this.props.handleOnModalExit}
                                            onClose={this.props.handleCloseClick}
                                            onDelete={this.props.deletePerson}
                                            inProgressPersonRecordStatus="c33a1670-d497-4e25-a736-0bc9a000b127"
                                            activePersonRecordStatus="d89d6d81-1ca1-4038-b4f1-7794ef3cd4a9"
                                            archivedPersonRecordStatus="7799ad39-2f73-490d-9e98-27ac149dee55"
                                            suspendedPersonRecordStatus="464df4f3-8268-484d-a83f-e4d8947b8b7e"
                                            peopleStore={peopleStore}
                                            updateEmployeeData={
                                                this.props.updatePersonDataAfterChange
                                            }
                                        />
                                    </footer>
                                    <RouterPrompt
                                        when={this.state.formWasEdited}
                                        onPromptConfirm={this._handleOnCancelConfirm}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                );
            }
        },
    ),
);

export default PersonSummary;
