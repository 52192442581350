import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Tab, Tabs } from '@blueprintjs/core';
import { Link, RouteComponentProps } from 'react-router-dom';

import Modal from '../../modal/Modal';
import { WeekHoursByDays } from './EmployeeDetailsTypes';
import EmployeeSummary from './EmployeeSummary';
import Documents from '../documents/Documents';
import DepartmentAssignments from '../departmentAssignments/DepartmentAssignments';
import { cutLastItemInURL, cutTwoLastItemsInURL } from '../../../../helpers/common';
import { StaticContext } from 'react-router';
import { days } from './EmployeeSummaryDays';
import { employedValidationSchema, unemployedValidationSchema } from './validation';
import { Form, Formik } from 'formik';
import PersonModalFooter from '../../../common/PersonModalFooter';
import { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';

interface RouterProps {
    employeeId?: string;
    tab?: string;
}

interface EmployeeDetailsProps
    extends Partial<RouteComponentProps<RouterProps, StaticContext, { personId?: string }>> {
    actionType: string;
    RootStore?: any;
    documentId: string | null;
    handleCancel: () => void;
    handleDownload: (key: string) => Promise<void>;
    updateEmployeeDataAfterChange: () => Promise<void>;
    tab: string;
    handleTabChange: () => void;
}

interface InitialData extends WeekHoursByDays {
    personId?: string;
    roles?: Object[];
    groups?: Object[];
    placeOfWork?: string;
    contractedWorkingHours: number;
    contractedHoursSum: number;
    startedWorkOn: string | null;
    endedWorkOn: string | null;
    statusOfEngagement?: string;
    specialities: Array<Object | null>;
    contactNumber?: string;
    notificationPreference?: string;
    gpPractice?: string;
    contractTypeId?: string;
    jobTitle?: string;
    email?: string;
    holidayAllowance?: number;
    firstName?: string;
    lastName?: string;
    agencyId?: string;
    isTupe: boolean;
    tupeOrganisationName?: string;
}

interface EmployeeDetailsState {
    formWasEdited: boolean;
    isEdit: boolean;
    isLoaded: boolean;
    hasSuspendedParent: boolean;
    initialData: InitialData;
    helpers: any;
    listOfPermittedDomains: string[];
}

const companyRegistrationNumberRegExp = {
    'United Kingdom': new RegExp(/^([a-zA-Z|[0-9]{1})([a-zA-Z|[0-9]{1})[0-9]{5,6}$/m),
} as any;

const vatRegistrationNumberRegExp = {
    'United Kingdom': new RegExp(/^[0-9]{9}$/m),
} as any;

const EmployeeDetails = inject('RootStore')(
    observer(
        class EmployeeDetails extends Component<EmployeeDetailsProps, EmployeeDetailsState> {
            state = {
                formWasEdited: false,
                hasSuspendedParent: false,
                isEdit: false,
                initialData: {
                    personId: '',
                    roles: [],
                    roleName: [],
                    groups: [],
                    placeOfWork: '',
                    contractedWorkingHours: 0,
                    mondayContractedHours: null,
                    tuesdayContractedHours: null,
                    wednesdayContractedHours: null,
                    thursdayContractedHours: null,
                    fridayContractedHours: null,
                    saturdayContractedHours: null,
                    sundayContractedHours: null,
                    contractedHoursSum: 0,
                    startedWorkOn: null,
                    endedWorkOn: null,
                    statusOfEngagement: '',
                    specialities: [],
                    contactNumber: '',
                    // id related to a App notifications
                    // TODO think better and made more stable implementation maybe
                    notificationPreference: '85e0ee5b-c161-4440-a0df-034c16984c65',
                    gpPractice: '',
                    contractTypeId: '',
                    email: '',
                    holidayAllowance: 0,
                    firstName: '',
                    lastName: '',
                    agencyId: '',
                    isSupervisor: false,
                    futurePayRateId: '',
                    futurePayRateDate: null,
                    typeOfRegistrationOptions: '',
                    countryOfRegistration: '',
                    companyRegistrationNumber: '',
                    registeredCompanyName: '',
                    registeredCompanyAddress: '',
                    registeredCompanyAddressLine1: '',
                    registeredCompanyAddressLine2: '',
                    registeredCompanyAddressLine3: '',
                    registeredCompanyAddressLine4: '',
                    registeredCompanyAddressCity: '',
                    registeredCompanyAddressPostcode: '',
                    registeredCompanyAddressCountry: '',
                    vatRegistrationNumber: '',
                    isTupe: false,
                    tupeOrganisationName: '',
                    patientFacing: false,
                },
                listOfPermittedDomains: [''],
                isLoaded: false,
                helpers: {
                    minDate: new Date('1900-01-01'),
                },
            };
            private formik: any;

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    this._updateEmployeeData();
                }

                const { getAllowedEmailDomains } = this.props.RootStore.peopleStore;
                const listOfPermittedDomains = await getAllowedEmailDomains();
                const normalizedList = listOfPermittedDomains.map((item: any) =>
                    item.toLocaleLowerCase().trim(),
                );
                this.setState({ listOfPermittedDomains: normalizedList });
            }

            _getWeekHours = (employee: InitialData) => {
                return days.reduce((sum: any, { name }: { name: keyof WeekHoursByDays }) => {
                    const value = Number(employee[name] || 0);
                    return sum + value;
                }, 0);
            };

            _updateEmployeeData = async () => {
                const employeeId = this.props.match?.params?.employeeId as string;
                const employee = await this.props.RootStore.staffStore.getStaffRecordById(
                    employeeId,
                );

                if (employee) {
                    this.setState({
                        initialData: {
                            ...employee,
                            contractedHoursSum: this._getWeekHours(employee),
                        },
                        isEdit: false,
                        isLoaded: true,
                    });
                }
                const person = await this.props.RootStore.peopleStore.getPersonById(
                    this.state.initialData.personId,
                );

                this.setState({
                    hasSuspendedParent: person.statusId === '464df4f3-8268-484d-a83f-e4d8947b8b7e',
                });
            };

            _updateEmployeeDataAfterChange = async () => {
                await this.props.RootStore.staffStore.getStaffs();
                this._updateEmployeeData();
            };

            _getUrl = () => {
                let url = cutLastItemInURL(this.props.history?.location?.pathname ?? '');
                if (!!this.props.tab) {
                    url = cutTwoLastItemsInURL(this.props.history?.location?.pathname ?? '');
                }
                return url;
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history?.replace('/hr/employed-records');
                } else {
                    return this.props.history?.replace(this._getUrl());
                }
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnModalExit = () => {
                return this.props.history?.replace(this._getUrl());
            };

            _editStatusForm = (values: any) => {
                this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _handleOnCancel = () => {
                this.state.formWasEdited
                    ? (this.props.history as any).replace('/hr/employed-records')
                    : this._toggleIsEdit();
            };

            _handleOnCancelConfirm = async () => {
                await this.setState({ formWasEdited: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                if (this.props.actionType === 'create') {
                    (this.props.history as any).replace('/hr/employed-records');
                }
            };

            _deleteStaff = async () => {
                const employeeId = this.props.match?.params?.employeeId as string;
                const employee = await this.props.RootStore.staffStore.getStaffRecordById(
                    employeeId,
                );

                employee && (await this.props.RootStore.staffStore.deleteStaff(employee));
                this.props.history?.replace(this._getUrl());
            };

            _validateForm = async (values: any) => {
                this._editStatusForm(values);
                const {
                    email,
                    companyRegistrationNumber,
                    countryOfRegistration,
                    vatRegistrationNumber,
                } = values;
                const errors = {} as any;

                const domain = email.split('@')[1]?.toLocaleLowerCase();
                const { listOfPermittedDomains } = this.state;

                if (domain && !listOfPermittedDomains.includes(domain)) {
                    errors.email =
                        'Email domain is not permitted, please speak with an administrator';
                }

                if (
                    (companyRegistrationNumber || vatRegistrationNumber) &&
                    !countryOfRegistration.name
                ) {
                    errors.countryOfRegistration = 'Country of Registration must be set';
                }

                if (
                    companyRegistrationNumber &&
                    companyRegistrationNumberRegExp[countryOfRegistration.name]
                ) {
                    const regExp = companyRegistrationNumberRegExp[countryOfRegistration.name];
                    const isValidCompanyRegistrationNumber = regExp.test(companyRegistrationNumber);
                    if (!isValidCompanyRegistrationNumber) {
                        errors.companyRegistrationNumber = `Invalid company registration number for ${countryOfRegistration.name}`;
                    }
                }

                if (
                    vatRegistrationNumber &&
                    vatRegistrationNumberRegExp[countryOfRegistration.name]
                ) {
                    const regExp = vatRegistrationNumberRegExp[countryOfRegistration.name];
                    const isValidVatRegistrationNumber = regExp.test(vatRegistrationNumber);
                    if (!isValidVatRegistrationNumber) {
                        errors.vatRegistrationNumber = `Invalid VAT registration number for ${countryOfRegistration.name}`;
                    }
                }
                return errors;
            };

            render() {
                const {
                    RootStore: { staffStore, departmentAssignmentsStore },
                    tab: currentTab,
                } = this.props;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const isEmployed = this.props.location?.pathname.includes('employed');

                const validationSchema = isEmployed
                    ? employedValidationSchema
                    : unemployedValidationSchema;

                return (
                    <Formik
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                            contractedWorkingHours: this.state.initialData.contractedWorkingHours,
                            ...(this.state.initialData.personId && {
                                personId: this.state.initialData.personId,
                            }),
                        }}
                        enableReinitialize
                        validationSchema={validationSchema}
                        validate={this._validateForm}
                        onSubmit={async (values, { setSubmitting }) => {
                            const normalizedValues = {
                                ...values,
                                contractedWorkingHours: Number(values.contractedWorkingHours),
                            };
                            if (this.props.actionType === 'create') {
                                await this.setState({ formWasEdited: false });
                                await staffStore.createStaff(normalizedValues);
                                if (this.props.history?.location?.state?.personId) {
                                    this.props.history.goBack();
                                } else {
                                    const url = cutLastItemInURL(
                                        this.props.history?.location?.pathname ?? '',
                                    );
                                    return this.props.history?.replace(url);
                                }
                            }
                            if (this.props.actionType === 'edit') {
                                await staffStore.updateStaff(normalizedValues);
                                await this.setState({ formWasEdited: false });
                                this._updateEmployeeData();
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            validateForm,
                            setFieldTouched,
                            errors,
                            touched,
                            isValid,
                        }) => {
                            const title =
                                this.props.actionType === 'create' ? (
                                    'Add staff record'
                                ) : (
                                    <span>
                                        <Link to={`/hr/people/${this.state.initialData.personId}`}>
                                            {this.state.initialData.firstName}{' '}
                                            {this.state.initialData.lastName}
                                        </Link>
                                        <span className="employee-title"> </span>
                                        <span className="employee-role">
                                            {this.state.initialData.roleName[0]
                                                ? `- ${this.state.initialData.roleName[0]}`
                                                : ''}
                                        </span>
                                    </span>
                                );

                            return (
                                <Form className="modal__form common-form">
                                    <Modal title={title} onClose={this._handleCloseClick} shadow>
                                        <Tabs
                                            id="staffTabs"
                                            defaultSelectedTabId="summary"
                                            selectedTabId={this.props.tab}
                                            onChange={this.props.handleTabChange}
                                            className="modal__tabs"
                                            large
                                        >
                                            <Tab
                                                id="summary"
                                                title="Summary"
                                                className={
                                                    errors.contactNumber ||
                                                    errors.contractTypeId ||
                                                    errors.email ||
                                                    errors.personId ||
                                                    errors.roles
                                                        ? 'tabs__has-error'
                                                        : ''
                                                }
                                                panel={
                                                    <EmployeeSummary
                                                        {...this.props}
                                                        isEmployed={isEmployed}
                                                        employee={this.state.initialData}
                                                        editOrCreateMode={this.state.isEdit}
                                                        actionType={this.props.actionType}
                                                        setFieldValue={setFieldValue}
                                                        setFieldTouched={setFieldTouched}
                                                        errors={errors}
                                                        touched={touched}
                                                        values={values}
                                                        handleChange={handleChange}
                                                        minDate={this.state.helpers.minDate}
                                                    />
                                                }
                                            />
                                            {this.props.actionType === 'edit' && (
                                                <Tab
                                                    id="departments"
                                                    title="Departments"
                                                    className="modal__tab"
                                                    panel={
                                                        <DepartmentAssignments
                                                            {...this.props}
                                                            isArchived={false}
                                                            archivedPersonRecordStatus="097741c6-c0af-48c0-a848-947a9e8e3472"
                                                            employee={this.state.initialData}
                                                            updateEmployeeDataAfterChange={
                                                                this._updateEmployeeDataAfterChange
                                                            }
                                                        />
                                                    }
                                                />
                                            )}
                                            {this.props.actionType === 'edit' && (
                                                <Tab
                                                    id="documents"
                                                    title="Documents"
                                                    className="modal__tab"
                                                    panel={
                                                        <Documents
                                                            {...this.props}
                                                            employee={this.state.initialData}
                                                            updateEmployeeDataAfterChange={
                                                                this._updateEmployeeDataAfterChange
                                                            }
                                                        />
                                                    }
                                                />
                                            )}
                                        </Tabs>
                                        <footer className="modal__footer">
                                            <PersonModalFooter
                                                hasSuspendedParent={this.state.hasSuspendedParent}
                                                isSubmitting={isSubmitting}
                                                isEditing={this.state.isEdit}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                validateForm={validateForm}
                                                isValid={isValid}
                                                toggleIsEdit={this._toggleIsEdit}
                                                onCancel={this._handleOnCancel}
                                                onModalExit={this._handleOnModalExit}
                                                onClose={this._handleCloseClick}
                                                onDelete={this._deleteStaff}
                                                employeeFooter={true}
                                                staffStore={staffStore}
                                                departmentAssignmentsStore={
                                                    departmentAssignmentsStore
                                                }
                                                updateEmployeeData={this._updateEmployeeData}
                                                inProgressPersonRecordStatus="4d826365-bf81-4579-af17-722347def18d"
                                                activePersonRecordStatus="f4717860-2a2e-40bc-b9db-71d67972fc6b"
                                                archivedPersonRecordStatus="097741c6-c0af-48c0-a848-947a9e8e3472"
                                                suspendedPersonRecordStatus="8613093d-ec25-4e0f-99c3-10e8405997d3"
                                                hideButtons={
                                                    !!currentTab && currentTab !== 'summary'
                                                }
                                            />
                                        </footer>
                                    </Modal>
                                    <RouterPrompt
                                        when={this.state.formWasEdited}
                                        onPromptConfirm={this._handleOnCancelConfirm}
                                    />
                                </Form>
                            );
                        }}
                    </Formik>
                );
            }
        },
    ),
);

export default EmployeeDetails;
