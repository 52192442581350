import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

interface DepartmentRelatedDialogProps {
    isOpen: boolean;
    onClose: () => void;
    notAchievableDepartments: any[];
    achievableDepartments: any[];
    onArchiveNotRelated: () => void;
}

const DepartmentsRelatedDialog = ({
    isOpen,
    onClose,
    notAchievableDepartments,
    achievableDepartments,
    onArchiveNotRelated,
}: DepartmentRelatedDialogProps) => {
    return (
        <Dialog isOpen={isOpen} onClose={onClose} canEscapeKeyClose={true}>
            <div className={Classes.DIALOG_BODY}>
                <h4>Confirm archiving!</h4>
                <p>
                    The following departments cannot be archived because they are related to other
                    active entities and/or future shifts
                </p>

                <ul>
                    {notAchievableDepartments.map((item: any) => (
                        <li key={item.id}>
                            <Link to={`/hr/departments/${item.id}`} onClick={onClose}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>

                <p>Do you want to continue with archiving the following department(s)?</p>

                <ul>
                    {achievableDepartments.map((item: any) => (
                        <li key={item.id}>
                            <Link to={`/hr/departments/${item.id}`} onClick={onClose}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button intent={Intent.WARNING} onClick={onArchiveNotRelated}>
                        Archive
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DepartmentsRelatedDialog;
