import { observable, action, makeObservable, runInAction } from 'mobx';
import { GET_PROFESSIONALBODIES } from '../graphql/queries/professionalBody';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PagedStore } from './PagedStore';

class ProfessionalBodyStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            professionalBodies: observable,
            getProfessionalBodies: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    professionalBodies = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    getProfessionalBodies = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getProfessionalBodies: data },
        } = await this.apolloClient.query({
            query: GET_PROFESSIONALBODIES,
            variables: {},
        });

        runInAction(() => {
            this.professionalBodies = data;
            this.loaded = true;
        });
    };
}

export default ProfessionalBodyStore;
