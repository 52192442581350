import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { ErrorMessage, Form, Field, FastField, FieldArray } from 'formik';
import { Button, Classes, FormGroup, InputGroup, Intent, Switch } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import moment from 'moment';

import FormSwitch from './FormSwitch';
import CommentsSection from './CommentsSection';
import FormikObserver from '../../../../common/FormikObserver';

import { compareByLabels } from '../../../../modules/helpers/sortFunctions';

import './styles/rota-shift-form.css';
import Select from 'react-select';
import Error from '../../../../modules/forms/Error';
import _ from 'lodash';
import {
    EMPLOYEE_RECORD_ACTIVE_STATUS_ID,
    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID,
    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
    EMPLOYEE_RECORD_IN_PROGRESS_STATUS_ID,
} from '../../../../../constants/hrConst';
import { SHIFT_TYPE_UNPAID_ID } from '../../../../../constants/rotaConst';
import Warnings from '../../../../v2/form/Warnings';
import { DateInput3 } from '@blueprintjs/datetime2';
import { TimePicker } from '@blueprintjs/datetime';
import { momentFormatter } from '../../../../modules/helpers/formatData';
import { dateFormat } from '../../../../../constants/patientsConst';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const ShiftForm = ({
    staffMembers,
    onClose,
    shiftStatuses,
    updateRotaShiftComments,
    currentUser,
    editItem,
    values,
    setFieldValue,
    setValues,
    touched,
    errors,
    actionType,
    getShiftConflicts,
    dateRange,
    isSubmitting,
    getRotaShiftCost,
    fundingPoolsOptions,
    setTitle,
    paymentStatusNormalized,
    isPublished,
    isViewMode,
    getShiftPatientFacingWarnings,
    RootStore: {
        staffStore,
        rotaBuilderStore,
        payRatesStore,
        locationsStore,
        rolesStore,
        functionsStore,
        dictionaryStore: {
            payRatesDictionary,
            locationsDictionary,
            rolesDictionary,
            functionsDictionary,
            shiftStatusesDictionary,
            shiftTypes,
            shiftTypesDictionary,
        },
    },
}: any) => {
    const [locationOptions, setLocationOptions] = useState([] as any[]);
    const [roleOptions, setRoleOptions] = useState([] as any[]);
    const [functionOptions, setFunctionOptions] = useState([] as any[]);

    const editOrCreateMode = actionType === 'edit' || actionType === 'create';

    const shiftWasPublished = editItem && editItem.shiftWasPublished;
    const listOfComments = editItem ? editItem.comments : [];
    const hasCriticalConflicts =
        values.conflicts &&
        values.conflicts.length > 0 &&
        values.conflicts.filter((r: any) => r.isCritical).length > 0;

    React.useEffect(() => {}, [values.conflicts]);
    const fundingPoolsOptionsDictionary = {} as any;
    fundingPoolsOptions.forEach((fundingPool: any) => {
        fundingPoolsOptionsDictionary[fundingPool.value] = fundingPool.label;
    });

    const updatePayRate = async (defaultEmployeeId?: string, confirmedEmployeeId?: string) => {
        const employeeId = confirmedEmployeeId || defaultEmployeeId;
        if (values.typeId !== SHIFT_TYPE_UNPAID_ID) {
            if (payRatesStore.payRatesByRole.length > 0) {
                if (employeeId) {
                    const departmentId = rotaBuilderStore.newRotaDepartmentId;
                    const employee = await staffStore.getStaffRecordById(employeeId);
                    const shiftStartDate = moment(values.startDate);
                    const filteredDepartments = employee.departments.filter(
                        (dep: any) =>
                            dep.departmentId === departmentId &&
                            !dep.isArchived &&
                            shiftStartDate.isSameOrAfter(dep.startDateOfAssignment, 'day') &&
                            (shiftStartDate.isSameOrBefore(dep.endDateOfAssignment, 'day') ||
                                dep.endDateOfAssignment === null),
                    );
                    const filteredPayRate =
                        filteredDepartments.length &&
                        filteredDepartments[0].payRates.filter(
                            (payRate: any) =>
                                shiftStartDate.isSameOrAfter(payRate.startDate, 'day') &&
                                (shiftStartDate.isSameOrBefore(
                                    payRate.endDateOfAssignment,
                                    'day',
                                ) ||
                                    payRate.endDateOfAssignment === null),
                        );
                    if (filteredPayRate.length) {
                        setFieldValue('payRateId', filteredPayRate[0].payRate.id);
                    } else {
                        setFieldValue('payRateId', payRatesStore.payRatesByRole[0]?.id);
                    }
                } else {
                    setFieldValue('payRateId', payRatesStore.payRatesByRole[0]?.id);
                }
            } else {
                setFieldValue('payRateId', null);
            }
        }
    };

    const updateNotPublishedComments = (comments: any) => {
        setFieldValue('comments', comments);
    };

    const shiftStatusesList = shiftStatuses.map((r: any) => ({ label: r.name, value: r.id }));
    const staffsList = staffStore.allStaffs.filter((item: any) => {
        return staffMembers?.some((emp: any) => item.id === emp.value);
    });

    const staffsListWithRoles =
        staffsList?.map((item: any) => ({
            id: item.id,
            value: item.id,
            role: item.roles[0] ? item.roles[0].employeeRoleTypeId : '',
            statusId: item.statusId,
            isSupervisor: item.isSupervisor,
            label: item.person
                ? item.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID
                    ? `${item.person.firstName} ${item.person.lastName} - suspended`
                    : `${item.person.firstName} ${item.person.lastName}`
                : '',
            italic: item.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID ? 'italic' : 'normal',
        })) ?? {};

    const staffsListWithRolesStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            fontStyle: state.data.italic,
        }),
    };

    const staffsWithSelectedRole =
        (values.roleId &&
            staffsListWithRoles.filter(
                (item: any) =>
                    item.role === values.roleId &&
                    (item.statusId === EMPLOYEE_RECORD_ACTIVE_STATUS_ID ||
                        item.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID),
            )) ??
        [];

    const staffList = staffMembers.reduce((acc: any, item: any) => {
        const member = staffsListWithRoles.find(
            (staffMember: any) => staffMember.id === item.value,
        );
        acc[item.value] = member !== undefined ? member.label : item.label;
        return acc;
    }, {});

    const checkMembersWorkStatus = (val: string) => {
        const selectedMember = staffsListWithRoles.find((item: any) => item.id === val);
        const selectedMemberStatus = selectedMember !== undefined && selectedMember.statusId;
        if (!selectedMemberStatus) return;
        if (selectedMemberStatus === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID) {
            return ' suspended';
        } else if (selectedMemberStatus === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID) {
            return ' archived';
        } else {
            return false;
        }
    };

    const checkIsSupervisorStaff = (val: any) =>
        staffsWithSelectedRole.filter((staff: any) => staff.isSupervisor && staff.id === val)
            .length;

    const makeChangeIsSupervisorStaff = async (val: any) => {
        return staffsWithSelectedRole.filter((staff: any) => staff.isSupervisor && staff.id === val)
            .length
            ? setFieldValue('trainingShift', true) && _.values(values.trainees[0]).every(_.isEmpty)
                ? setFieldValue('trainees', [
                      {
                          traineeId: '',
                          roleId: '',
                          overrideValue: null,
                          payRateId: '',
                      },
                  ])
                : null
            : _.values(values.trainees[0]).every(_.isEmpty)
            ? setFieldValue('trainingShift', false) &&
              setFieldValue('trainees', [
                  {
                      traineeId: '',
                      roleId: '',
                      overrideValue: null,
                      payRateId: '',
                  },
              ])
            : null;
    };

    const updateConflicts = useCallback(
        async (values: any) => {
            const conflicts = await getShiftConflicts({
                values,
            });
            setFieldValue('conflicts', conflicts);
        },
        [getShiftConflicts, setFieldValue],
    );

    const handleDateChange = useCallback(
        (dateString: string | null) => {
            const date = new Date(dateString || '');
            const d1 = moment(dateRange[0]).weekday(1).toDate();
            const weekNumber = moment(date).diff(d1, 'week') + 1;
            const start = moment(date)
                .hours(values.startTime.getHours())
                .minutes(values.startTime.getMinutes())
                .seconds(0)
                .milliseconds(0)
                .toDate();
            const end = moment(date)
                .hours(values.endTime.getHours())
                .minutes(values.endTime.getMinutes())
                .seconds(0)
                .milliseconds(0)
                .toDate();
            const newValues = {
                ...values,
                startTime: start,
                startDate: start,
                endTime: end,
                endDate: end,
                dayOfWeek: moment(date).isoWeekday(),
                weekNumber,
            };
            setValues(newValues);
            updateConflicts(newValues);
        },
        [dateRange, setValues, updateConflicts, values],
    );

    const handleStartTimeChange = useCallback(
        (value: any) => {
            const newValues = { ...values, startDate: value, startTime: value };
            setValues(newValues);
            updateConflicts(newValues);
        },
        [setValues, updateConflicts, values],
    );

    const handleEndTimeChange = useCallback(
        (value: any) => {
            const newValues = { ...values, endDate: value, endTime: value };
            setValues(newValues);
            updateConflicts(newValues);
        },
        [setValues, updateConflicts, values],
    );

    useEffect(() => {
        const updateLocationOptions = async () => {
            const locations = await locationsStore.getLocationsByDepartment(
                rotaBuilderStore.newRotaDepartmentId,
            );
            setLocationOptions(locations);
        };
        setLocationOptions([]);
        updateLocationOptions();
    }, [locationsStore, rotaBuilderStore.newRotaDepartmentId]);

    useEffect(() => {
        const updateRoleOptions = async () => {
            const roles = await rolesStore.getRolesByDepartmentAndFunctions(
                rotaBuilderStore.newRotaDepartmentId,
                values.rotaShiftFunctions?.length ? values.rotaShiftFunctions : undefined,
            );
            setRoleOptions([{}, ...roles]);
        };
        setRoleOptions([]);
        updateRoleOptions();
    }, [rolesStore, rotaBuilderStore.newRotaDepartmentId, values.rotaShiftFunctions]);

    useEffect(() => {
        const updateFunctionOptions = async () => {
            const functions = await functionsStore.getFunctionsByDepartmentAndRole(
                rotaBuilderStore.newRotaDepartmentId,
                values.roleId?.length ? values.roleId : undefined,
            );
            setFunctionOptions(functions);
        };
        setFunctionOptions([]);
        updateFunctionOptions();
    }, [functionsStore, rotaBuilderStore.newRotaDepartmentId, values.roleId]);

    useEffect(() => {
        const paymentStatus =
            ' - ' + (paymentStatusNormalized[values.paymentStatusId] || 'Planned');

        const title = values.startDate
            ? `Week ${values.weekNumber} - ${days[values.dayOfWeek - 1]} (${
                  values.startDate ? moment(values.startDate).format(dateFormat) : ''
              }) ${isPublished ? paymentStatus : ''}`
            : `Create Shift`;

        setTitle(title);
    }, [
        isPublished,
        paymentStatusNormalized,
        setTitle,
        values.dayOfWeek,
        values.paymentStatusId,
        values.startDate,
        values.weekNumber,
    ]);

    useEffect(() => {
        if (values.patientFacing) {
            getShiftPatientFacingWarnings(values, setFieldValue);
        } else {
            setFieldValue('shiftPatientFacingWarnings', []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.patientFacing, values.defaultEmployeeId, values.confirmedEmployeeId]);

    return (
        <Form className="builder-form">
            <div className="builder-form__col">
                <FormGroup label="Date *" labelFor="startDate">
                    <DateInput3
                        showTimezoneSelect={false}
                        {...momentFormatter(dateFormat)}
                        onChange={handleDateChange}
                        dayPickerProps={{
                            weekStartsOn: 1,
                        }}
                        inputProps={{
                            onKeyDown: (e) => {
                                e.preventDefault();
                            },
                        }}
                        canClearSelection={false}
                        minDate={dateRange[0]}
                        maxDate={dateRange[1]}
                        disabled={isViewMode}
                        value={values.dayOfWeek && moment(values.startDate).toDate()}
                    />
                    <ErrorMessage
                        className="builder-form__error"
                        name="dayOfWeek"
                        component="div"
                    />
                </FormGroup>

                <FormGroup label="Location *" labelFor="locationId">
                    <Select
                        options={locationOptions}
                        isLoading={!locationOptions.length}
                        onChange={async (e) => {
                            setFieldValue('locationId', e.value);
                        }}
                        defaultValue={
                            values.locationId && {
                                label: locationsDictionary[values.locationId],
                                value: values.locationId,
                            }
                        }
                        isDisabled={isViewMode}
                        name="locationId"
                        id="locationId"
                        placeholder="Select..."
                    />
                    {touched.locationId && <Error errors={[errors.locationId]} />}
                </FormGroup>
            </div>

            <div className="builder-form__col">
                <div className={hasCriticalConflicts && 'haws-error'}>
                    <FormGroup label="Start time">
                        <TimePicker
                            onChange={handleStartTimeChange}
                            showArrowButtons={true}
                            value={values.startTime}
                            disabled={isViewMode}
                            maxTime={moment(values.endTime).subtract(1, 'hours').toDate()}
                        />
                        <ErrorMessage
                            className="builder-form__error"
                            name="startTime"
                            component="div"
                        />
                    </FormGroup>
                </div>
                <div className={hasCriticalConflicts && 'has-error'}>
                    <FormGroup label="End time">
                        <TimePicker
                            onChange={handleEndTimeChange}
                            showArrowButtons={true}
                            value={values.endTime}
                            disabled={isViewMode}
                            minTime={moment(values.startTime).add(1, 'hours').toDate()}
                        />
                        <ErrorMessage
                            className="builder-form__error"
                            name="endTime"
                            component="div"
                        />
                    </FormGroup>
                </div>
            </div>

            <FormGroup label="Role *" labelFor="roleId" className="common-select__has-empty">
                <Select
                    isDisabled={!values.dayOfWeek || isViewMode}
                    options={roleOptions}
                    isLoading={!roleOptions.length}
                    defaultValue={
                        values.roleId && {
                            label: rolesDictionary[values.roleId],
                            value: values.roleId,
                        }
                    }
                    onChange={async (e) => {
                        await payRatesStore.getPayRatesByRole(e.value);
                        setFieldValue('defaultEmployeeId', null);
                        setFieldValue('confirmedEmployeeId', null);
                        setFieldValue('conflicts', []);
                        setFieldValue('roleId', e && e.value);
                        if (values.typeId !== SHIFT_TYPE_UNPAID_ID) {
                            setFieldValue('payRateId', null);
                            await updatePayRate();
                        }
                    }}
                    name="roleId"
                    id="roleId"
                    placeholder="Select..."
                />
                {touched.roleId && <Error errors={[errors.roleId]} />}
            </FormGroup>

            <FormGroup label="Function *" labelFor="rotaShiftFunctions">
                <Select
                    options={functionOptions}
                    isLoading={!functionOptions.length}
                    isMulti={true}
                    defaultValue={
                        values.rotaShiftFunctions
                            ? values.rotaShiftFunctions.map((shiftFunction: string) => ({
                                  label: functionsDictionary[shiftFunction],
                                  value: shiftFunction,
                              }))
                            : []
                    }
                    onChange={(e) => {
                        setFieldValue('rotaShiftFunctions', e && e.map((item) => item.value));
                    }}
                    isDisabled={isViewMode}
                    name="rotaShiftFunctions"
                    id="rotaShiftFunctions"
                    placeholder="Select..."
                />
                {touched.rotaShiftFunctions && <Error errors={[errors.rotaShiftFunctions]} />}
            </FormGroup>

            <FormGroup label="Patient-facing?">
                <FastField
                    disabled={isViewMode}
                    label="Patient-facing?"
                    name="patientFacing"
                    as={FormSwitch}
                />
            </FormGroup>

            <div className="builder-form__col common-select__has-empty">
                <Field>
                    {() => (
                        <FormGroup
                            label="Default Staff member"
                            className="common-select__has-empty"
                        >
                            <Select
                                isDisabled={!values.roleId || isViewMode}
                                options={
                                    staffsWithSelectedRole && [
                                        {},
                                        ..._.orderBy(
                                            staffsWithSelectedRole.filter((item: any) => {
                                                if (!values.trainees.length) {
                                                    return true;
                                                }
                                                return item.id !== values.trainees[0]?.traineeId;
                                            }),
                                            ['label'],
                                            ['asc'],
                                        ),
                                    ]
                                }
                                value={
                                    values.defaultEmployeeId && {
                                        label: staffList[values.defaultEmployeeId],
                                        value: values.defaultEmployeeId,
                                    }
                                }
                                onChange={async (e) => {
                                    setFieldValue('defaultEmployeeId', e && e.value);
                                    if (values.typeId !== SHIFT_TYPE_UNPAID_ID) {
                                        if (!e.value && !values.confirmedEmployeeId) {
                                            await payRatesStore.getPayRatesByRole(values.roleId);
                                            await makeChangeIsSupervisorStaff(null);
                                        } else if (!values.confirmedEmployeeId) {
                                            await payRatesStore.getPayRatesByRole(
                                                values.roleId,
                                                e.value,
                                            );
                                            await makeChangeIsSupervisorStaff(e && e.value);
                                        }
                                        await updatePayRate(e.value, values.confirmedEmployeeId);
                                    }
                                    const conflicts = await getShiftConflicts({
                                        ...values,
                                        defaultEmployeeId: e.value,
                                    });
                                    setFieldValue('conflicts', conflicts);
                                }}
                                styles={staffsListWithRolesStyles}
                                name="defaultEmployeeId"
                                id="defaultEmployeeId"
                                placeholder="Select..."
                            />
                            {touched.defaultEmployeeId && (
                                <Error errors={[errors.defaultEmployeeId]} />
                            )}
                        </FormGroup>
                    )}
                </Field>
                <div className={hasCriticalConflicts && 'has-error'}>
                    <Field>
                        {() => (
                            <FormGroup
                                label="Confirmed Staff member"
                                className="common-select__has-empty"
                            >
                                <Select
                                    isDisabled={!values.roleId || isViewMode}
                                    options={
                                        staffsWithSelectedRole && [
                                            {},
                                            ..._.orderBy(
                                                staffsWithSelectedRole.filter((item: any) => {
                                                    if (!values.trainees.length) {
                                                        return true;
                                                    }
                                                    return (
                                                        item.id !== values.trainees[0]?.traineeId
                                                    );
                                                }),
                                                ['label'],
                                                ['asc'],
                                            ),
                                        ]
                                    }
                                    value={
                                        values.confirmedEmployeeId && {
                                            label: staffList[values.confirmedEmployeeId],
                                            value: values.confirmedEmployeeId,
                                        }
                                    }
                                    onChange={async (e) => {
                                        setFieldValue('confirmedEmployeeId', e && e.value);
                                        if (values.typeId !== SHIFT_TYPE_UNPAID_ID) {
                                            if (!e.value && values.defaultEmployeeId) {
                                                await payRatesStore.getPayRatesByRole(
                                                    values.roleId,
                                                    values.defaultEmployeeId,
                                                );
                                                await makeChangeIsSupervisorStaff(
                                                    values.defaultEmployeeId,
                                                );
                                            } else if (!e.value) {
                                                await payRatesStore.getPayRatesByRole(
                                                    values.roleId,
                                                );
                                            } else {
                                                await payRatesStore.getPayRatesByRole(
                                                    values.roleId,
                                                    e.value,
                                                );
                                                await makeChangeIsSupervisorStaff(e && e.value);
                                            }
                                            await updatePayRate(undefined, e.value);
                                        }
                                        const conflicts = await getShiftConflicts({
                                            ...values,
                                            confirmedEmployeeId: e.value,
                                        });
                                        setFieldValue('conflicts', conflicts);
                                    }}
                                    styles={staffsListWithRolesStyles}
                                    name="confirmedEmployeeId"
                                    id="confirmedEmployeeId"
                                    placeholder="Select..."
                                />
                                {touched.confirmedEmployeeId && (
                                    <Error errors={[errors.confirmedEmployeeId]} />
                                )}
                            </FormGroup>
                        )}
                    </Field>
                </div>
            </div>

            <div className="builder-form__col">
                <>
                    <FormGroup label="Shift Type  *" labelFor="typeId">
                        <Select
                            options={shiftTypes}
                            onChange={async (e: any) => {
                                setFieldValue('typeId', e && e.value);
                                if (e.value === SHIFT_TYPE_UNPAID_ID) {
                                    await payRatesStore.getPayRatesZero(true);
                                    setFieldValue('payRateId', payRatesStore.payRatesZero[0]?.id);
                                }
                            }}
                            value={
                                values.typeId && {
                                    label: shiftTypesDictionary[values.typeId],
                                    value: values.typeId,
                                }
                            }
                            isDisabled={isViewMode}
                            name="typeId"
                            id="typeId"
                            placeholder="Select..."
                        />
                        {touched.typeId && <Error errors={[errors.typeId]} />}
                    </FormGroup>
                </>
                <>
                    <FormGroup label="Shift Status  *" labelFor="statusId">
                        <Select
                            options={shiftStatusesList.sort(compareByLabels)}
                            defaultValue={
                                values.statusId && {
                                    label: shiftStatusesDictionary[values.statusId],
                                    value: values.statusId,
                                }
                            }
                            onChange={async (e: any) => {
                                setFieldValue('statusId', e && e.value);
                                if (values.typeId !== SHIFT_TYPE_UNPAID_ID) {
                                    setFieldValue('payRateId', null);
                                    if (
                                        (values.roleId && !values.defaultEmployeeId) ||
                                        (values.roleId && !values.confirmedEmployeeId)
                                    ) {
                                        await payRatesStore.getPayRatesByRole(values.roleId);
                                        await setFieldValue(
                                            'payRateId',
                                            payRatesStore.payRatesByRole[0]?.id,
                                        );
                                    } else {
                                        await payRatesStore.getPayRatesByRole(
                                            values.roleId,
                                            values.confirmedEmployeeId
                                                ? values.confirmedEmployeeId
                                                : values.defaultEmployeeId,
                                        );
                                        await setFieldValue(
                                            'payRateId',
                                            payRatesStore.payRatesByRole[0]?.id,
                                        );
                                    }
                                }
                            }}
                            isDisabled={isViewMode}
                            name="statusId"
                            id="statusId"
                            placeholder="Select..."
                        />
                        {touched.statusId && <Error errors={[errors.statusId]} />}
                    </FormGroup>
                </>
            </div>

            <div className="builder-form__col">
                <FormGroup label="Break Duration (minutes) *">
                    <FastField
                        name="breakDurationMinutes"
                        placeholder="e.g. 30"
                        as={InputGroup}
                        type="number"
                        min={0}
                        disabled={isViewMode}
                    />
                    <ErrorMessage name="breakDurationMinutes" component="div" />
                </FormGroup>
                <FormGroup label="Break is paid">
                    <FastField
                        disabled={isViewMode}
                        label="Break is paid"
                        name="breaksPaid"
                        as={FormSwitch}
                    />
                </FormGroup>
            </div>

            <div className="builder-form__col">
                <FormGroup label="Funding pool" labelFor="fundingPoolId">
                    <Select
                        options={fundingPoolsOptions.filter(
                            (fundingPool: any) => !fundingPool.isArchived,
                        )}
                        defaultValue={
                            values.fundingPoolId && {
                                label: fundingPoolsOptionsDictionary[values.fundingPoolId],
                                value: values.fundingPoolId,
                            }
                        }
                        isDisabled={isViewMode}
                        onChange={async (e) => {
                            setFieldValue('fundingPoolId', e && e.value);
                        }}
                        name="fundingPoolId"
                        id="fundingPoolId"
                        placeholder="Select..."
                    />
                    {touched.fundingPoolId && <Error errors={[errors.fundingPoolId]} />}
                </FormGroup>
                <FormGroup label="Third-party paid">
                    <FastField
                        disabled={isViewMode}
                        label="Third-party paid"
                        name="thirdPartyPaid"
                        as={FormSwitch}
                    />
                </FormGroup>
            </div>

            <div className="builder-form__col common-select__has-empty">
                <FormGroup label="Pay Rate *" labelFor="payRateId" className="no-margin">
                    <Select
                        isDisabled={
                            values.typeId === SHIFT_TYPE_UNPAID_ID ||
                            (values.roleId && !payRatesStore.payRatesByRole.length) ||
                            (!!values.roleId && !!values.defaultEmployeeId) ||
                            (!!values.roleId && !!values.confirmedEmployeeId) ||
                            !values.roleId ||
                            isViewMode
                        }
                        options={payRatesStore.payRatesByRole.map((payRate: any) => ({
                            label: payRate.name,
                            value: payRate.id,
                        }))}
                        value={
                            values.payRateId && {
                                label: payRatesDictionary[values.payRateId],
                                value: values.payRateId,
                            }
                        }
                        onChange={(e) => setFieldValue('payRateId', e?.value ?? null)}
                        name="payRateId"
                        id="payRateId"
                    />
                    {touched.payRateId && <Error errors={[errors.payRateId]} />}
                    {values.roleId && !values.payRateId && !payRatesStore.payRatesByRole.length && (
                        <p className="builder-form__error">
                            No pay rates related to the selected role are found. Please, select
                            another role
                        </p>
                    )}
                    {!!values.roleId &&
                        values.typeId !== SHIFT_TYPE_UNPAID_ID &&
                        !!values.payRateId &&
                        !values.defaultEmployeeId &&
                        !values.confirmedEmployeeId && (
                            <p className="builder-form__info">
                                <i className="builder-form__info-content" />
                                <span>Using role related pay rate</span>
                            </p>
                        )}
                    {!!values.roleId &&
                        values.typeId !== SHIFT_TYPE_UNPAID_ID &&
                        !!values.payRateId &&
                        !!values.defaultEmployeeId &&
                        !values.confirmedEmployeeId && (
                            <p className="builder-form__info">
                                <i className="builder-form__info-content" />
                                <span>Using default staff member pay rate</span>
                            </p>
                        )}
                    {!!values.roleId &&
                        !!values.payRateId &&
                        values.typeId !== SHIFT_TYPE_UNPAID_ID &&
                        !!values.confirmedEmployeeId && (
                            <p className="builder-form__info">
                                <i className="builder-form__info-content" />
                                <span>Using confirmed staff member pay rate</span>
                            </p>
                        )}
                    {values.typeId === SHIFT_TYPE_UNPAID_ID && (
                        <p className="builder-form__info">
                            <i className="builder-form__info-content" />
                            <span>Using pay rate with £0 rate</span>
                        </p>
                    )}
                </FormGroup>
                <FormGroup label="Shift value, £" className="no-margin">
                    <FastField
                        name="cost"
                        placeholder="0"
                        as={InputGroup}
                        type="number"
                        disabled={isViewMode}
                        min={0}
                    />
                </FormGroup>
                <FormGroup label="Override value per shift (£)">
                    <FastField
                        name="overrideValueInPence"
                        placeholder="e.g. 30"
                        as={InputGroup}
                        type="number"
                        disabled={isViewMode}
                        min={0}
                    />
                </FormGroup>
            </div>

            <br />
            <div className="builder-form__col">
                <FormGroup label="Training Shift ?" labelFor="trainingShift">
                    {editOrCreateMode ? (
                        <Switch
                            inline={true}
                            name="trainingShift"
                            innerLabelChecked="yes"
                            innerLabel="no"
                            onChange={(e: any) => {
                                setFieldValue('trainingShift', !values.trainingShift);
                                setFieldValue('trainees', [
                                    {
                                        traineeId: '',
                                        roleId: '',
                                        overrideValue: null,
                                        payRateId: '',
                                    },
                                ]);
                            }}
                            disabled={isViewMode}
                            checked={values.trainingShift}
                        >
                            {touched.trainingShift && <Error errors={[errors.trainingShift]} />}
                        </Switch>
                    ) : values.trainingShift ? (
                        'Yes'
                    ) : (
                        'No'
                    )}
                </FormGroup>
            </div>

            {values.trainingShift && (
                <FieldArray
                    name="trainees"
                    render={() => (
                        <>
                            {values.trainees?.map((trainee: any, index: number) => {
                                const traineeStaffsWithSelectedRole =
                                    (values.trainees[index].roleId &&
                                        staffsListWithRoles.filter(
                                            (item: any) =>
                                                item.role === values.trainees[index].roleId &&
                                                item.statusId !==
                                                    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID &&
                                                item.statusId !==
                                                    EMPLOYEE_RECORD_IN_PROGRESS_STATUS_ID &&
                                                (values.confirmedEmployeeId
                                                    ? item.id !== values.confirmedEmployeeId
                                                    : item.id !== values.defaultEmployeeId),
                                        )) ??
                                    [];

                                const updateTraineePayRate = async () => {
                                    if (payRatesStore.traineePayRatesByRole.length) {
                                        setFieldValue(
                                            `trainees.${index}.payRateId`,
                                            payRatesStore.traineePayRatesByRole[0]?.id,
                                        );
                                    } else {
                                        setFieldValue(`trainees.${index}.payRateId`, null);
                                    }
                                };

                                return (
                                    <Fragment key={index}>
                                        <div className="builder-form__col">
                                            <>
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        label="Trainee’s role *"
                                                        labelFor={`trainees-${index}-roleId`}
                                                    >
                                                        <Select
                                                            options={roleOptions}
                                                            onChange={async (e) => {
                                                                setFieldValue(
                                                                    `trainees.${index}.roleId`,
                                                                    e && e.value,
                                                                );
                                                                setFieldValue(
                                                                    `trainees.${index}.traineeId`,
                                                                    null,
                                                                );
                                                                await payRatesStore.getTraineePayRatesByRole(
                                                                    e.value,
                                                                );
                                                                await updateTraineePayRate();
                                                            }}
                                                            value={
                                                                values.trainees[index].roleId && {
                                                                    label:
                                                                        rolesDictionary[
                                                                            values.trainees[index]
                                                                                .roleId
                                                                        ],
                                                                    value:
                                                                        values.trainees[index]
                                                                            .roleId,
                                                                }
                                                            }
                                                            id={`trainees-${index}-roleId`}
                                                            name={`trainees-${index}-roleId`}
                                                            placeholder="Select..."
                                                        />
                                                        <div className="info__definition--danger">
                                                            <ErrorMessage
                                                                name={`trainees.${index}.roleId`}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                ) : (
                                                    rolesDictionary[values.trainees[index].roleId]
                                                )}
                                            </>
                                            <>
                                                {' '}
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        label="Trainee"
                                                        labelFor={`trainees-${index}-traineeId`}
                                                        className="common-select__has-empty"
                                                    >
                                                        <Select
                                                            isDisabled={
                                                                !values.trainees[index].roleId
                                                            }
                                                            options={
                                                                traineeStaffsWithSelectedRole && [
                                                                    {},
                                                                    ..._.orderBy(
                                                                        traineeStaffsWithSelectedRole,
                                                                        ['label'],
                                                                        ['asc'],
                                                                    ),
                                                                ]
                                                            }
                                                            value={
                                                                values.trainees[index]
                                                                    .traineeId && {
                                                                    label:
                                                                        staffList[
                                                                            values.trainees[index]
                                                                                .traineeId
                                                                        ],
                                                                    value:
                                                                        values.trainees[index]
                                                                            .traineeId,
                                                                }
                                                            }
                                                            onChange={async (e) => {
                                                                setFieldValue(
                                                                    `trainees.${index}.traineeId`,
                                                                    e && e.value,
                                                                );
                                                                if (!e.value) {
                                                                    setFieldValue(
                                                                        `trainees.${index}.payRateId`,
                                                                        null,
                                                                    );
                                                                    await payRatesStore.getTraineePayRatesByRole(
                                                                        values.trainees[index]
                                                                            .roleId,
                                                                    );
                                                                } else {
                                                                    await payRatesStore.getTraineePayRatesByRole(
                                                                        values.trainees[index]
                                                                            .roleId,
                                                                        e.value,
                                                                    );
                                                                }
                                                                await updateTraineePayRate();
                                                            }}
                                                            styles={staffsListWithRolesStyles}
                                                            id={`trainees-${index}-traineeId`}
                                                            name={`trainees-${index}-traineeId`}
                                                            placeholder="Select..."
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    staffList[values.trainees[index].traineeId]
                                                )}
                                            </>
                                        </div>

                                        <div className="builder-form__col">
                                            <>
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        label="Trainee’s Pay Rate *"
                                                        labelFor={`trainees-${index}-payRateId`}
                                                    >
                                                        <Select
                                                            isDisabled={
                                                                (values.trainees[index]?.roleId &&
                                                                    !payRatesStore
                                                                        .traineePayRatesByRole
                                                                        .length) ||
                                                                (!!values.trainees[index].roleId &&
                                                                    !!values.trainees[index]
                                                                        .traineeId) ||
                                                                !values.trainees[index].roleId
                                                            }
                                                            options={payRatesStore.traineePayRatesByRole.map(
                                                                (payRate: any) => ({
                                                                    label: payRate.name,
                                                                    value: payRate.id,
                                                                }),
                                                            )}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    `trainees.${index}.payRateId`,
                                                                    e && e.value,
                                                                );
                                                            }}
                                                            value={
                                                                values.trainees[index]
                                                                    .payRateId && {
                                                                    label:
                                                                        payRatesDictionary[
                                                                            values.trainees[index]
                                                                                .payRateId
                                                                        ],
                                                                    value:
                                                                        values.trainees[index]
                                                                            .payRateId,
                                                                }
                                                            }
                                                            id={`trainees-${index}-payRateId`}
                                                            name={`trainees-${index}-payRateId`}
                                                            placeholder="Select..."
                                                        />
                                                        <div className="info__definition--danger">
                                                            <ErrorMessage
                                                                name={`trainees.${index}.payRateId`}
                                                            />
                                                        </div>
                                                        {values.trainees[index].roleId &&
                                                            !values.trainees[index].payRateId &&
                                                            !payRatesStore.traineePayRatesByRole
                                                                .length && (
                                                                <p className="builder-form__error">
                                                                    No pay rates related to the
                                                                    selected role are found. Please,
                                                                    select another role
                                                                </p>
                                                            )}
                                                    </FormGroup>
                                                ) : (
                                                    payRatesDictionary[
                                                        values.trainees[index].payRateId
                                                    ]
                                                )}
                                            </>
                                            <>
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        label=" Override value per shift for the trainee (£)"
                                                        labelFor={`trainees-${index}-overrideValue`}
                                                    >
                                                        <InputGroup
                                                            id={`trainees-${index}-overrideValue`}
                                                            name={`trainees-${index}-overrideValue`}
                                                            onChange={(e) => {
                                                                setFieldValue(
                                                                    `trainees.${index}.overrideValue`,
                                                                    e && +e.target.value,
                                                                );
                                                            }}
                                                            value={
                                                                values.trainees[index].overrideValue
                                                            }
                                                            large
                                                            type="number"
                                                            placeholder="e.g. 150"
                                                            min="0"
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    values.trainees[index].overrideValue
                                                )}
                                            </>
                                        </div>
                                    </Fragment>
                                );
                            })}
                        </>
                    )}
                />
            )}

            {values.trainingShift && (
                <div className="warning-blocks warning-blocks--sp">
                    {values.trainingShift &&
                        values.confirmedEmployeeId &&
                        !checkIsSupervisorStaff(values.confirmedEmployeeId) && (
                            <div className="warning-block">
                                Note that the confirmed staff member selected is not a supervisor
                            </div>
                        )}
                    {values.trainingShift &&
                        !values.confirmedEmployeeId &&
                        values.defaultEmployeeId &&
                        !checkIsSupervisorStaff(values.defaultEmployeeId) && (
                            <div className="warning-block">
                                Note that the default staff member selected is not a supervisor
                            </div>
                        )}
                    {values.trainingShift &&
                        !values.confirmedEmployeeId &&
                        !values.defaultEmployeeId && (
                            <div className="warning-block">
                                No staff member is selected as a supervisor to the training shift
                            </div>
                        )}
                </div>
            )}
            {(values.defaultEmployeeId || values.confirmedEmployeeId) && (
                <div className="warning-blocks warning-blocks--sp">
                    {values.confirmedEmployeeId &&
                        checkMembersWorkStatus(values.confirmedEmployeeId) && (
                            <div className="warning-block">
                                The selected confirmed staff member is
                                {checkMembersWorkStatus(values.confirmedEmployeeId)}
                            </div>
                        )}
                    {values.defaultEmployeeId &&
                        checkMembersWorkStatus(values.defaultEmployeeId) &&
                        !values.confirmedEmployeeId &&
                        !checkMembersWorkStatus(values.confirmedEmployeeId) && (
                            <div className="warning-block">
                                The selected default staff member is
                                {checkMembersWorkStatus(values.defaultEmployeeId)}
                            </div>
                        )}
                </div>
            )}

            {values.trainingShift &&
                values.trainees[0] &&
                values.trainees[0].traineeId &&
                checkMembersWorkStatus(values.trainees[0].traineeId) && (
                    <div className="warning-blocks warning-blocks--sp">
                        <div className="warning-block">
                            The selected trainee is
                            {checkMembersWorkStatus(values.trainees[0].traineeId)}
                        </div>
                    </div>
                )}

            {values.shiftPatientFacingWarnings && values.shiftPatientFacingWarnings.length > 0 && (
                <div className="warning-blocks warning-blocks--sp">
                    <Warnings
                        stepWarnings={values.shiftPatientFacingWarnings.map((r: any) => r)}
                        warnings={[]}
                    />
                </div>
            )}

            <CommentsSection
                comments={listOfComments}
                rotaBuilderStore={rotaBuilderStore}
                rotaShiftId={values.id}
                shiftWasPublished={shiftWasPublished}
                updateRotaShiftComments={updateRotaShiftComments}
                updateNotPublishedComments={updateNotPublishedComments}
                currentUser={currentUser}
            />

            {values.conflicts && values.conflicts.length > 0 && (
                <div className="warning-blocks warning-blocks--sp">
                    <Warnings
                        stepWarnings={values.conflicts.map((r: any) => r.label)}
                        warnings={[]}
                    />
                </div>
            )}

            <FormikObserver
                value={values}
                onChange={(values: any) => getRotaShiftCost(values, setFieldValue)}
            />

            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button outlined icon="cross" large onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        outlined
                        large
                        icon={'floppy-disk'}
                        loading={isSubmitting}
                        intent={Intent.SUCCESS}
                        type={'submit'}
                        disabled={isViewMode}
                    >
                        Submit
                    </Button>
                </div>
            </div>
        </Form>
    );
};

export default inject('RootStore')(observer(ShiftForm));
