import { observable, action, makeObservable } from 'mobx';
import moment from 'moment';
import { Schedule } from '@doc-abode/data-models';

import { fetchSchedules, setSchedule, validateSchedule } from '../api/schedulesApi';

interface AvailabilityCheckResult {
    message?: string;
    error?: string;
}

class SchedulesStore {
    constructor(rootStore: any, refreshTimeout = 60000) {
        makeObservable(this, {
            allSchedules: observable,
            allSchedulesByDate: observable,
            loaded: observable,
            date: observable,
            availabilityCheckResult: observable,
            validatingAvailability: observable,
            fetchSchedules: action,
            getSchedulesByDate: action,
            setDate: action,
            validateNewAvailability: action,
        });

        this.rootStore = rootStore;
        this.refreshTimeout = refreshTimeout;
    }

    rootStore: any;

    allSchedules: Schedule[] = [];
    allSchedulesByDate: Schedule[] = [];
    loaded = false;
    date = '';

    validatingAvailability = false;
    availabilityCheckResult: AvailabilityCheckResult = {};

    fetchInterval: NodeJS.Timeout | undefined = undefined;
    refreshTimeout = 60000;

    fetchSchedules = async () => {
        if (!this.date) {
            return;
        }
        this.loaded = false;
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const query = {
                date: this.date,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            this.allSchedules = await fetchSchedules(userSession?.tokens.id, query);
        } catch (err) {
            console.error('Unable to fetch schedules', err);
        }
        this.loaded = true;
    };

    setSchedules = async ({
        userId,
        organisation,
        date,
        startTime,
        endTime,
        timezone,
    }: {
        userId: string;
        organisation: string;
        date: string;
        startTime: string;
        endTime: string;
        timezone: string;
    }) => {
        this.loaded = false;
        try {
            const userSession = await this.rootStore.userStore.getUserSession();

            await setSchedule(userSession?.tokens.id, {
                userId,
                organisation,
                date,
                startTime,
                endTime,
                timezone,
            });

            this.fetchSchedules();
        } catch (err) {
            console.error('Unable to set schedule', err);
        }
        this.loaded = true;
    };

    setDate = (date: Date) => {
        this.date = moment(date).format('YYYY-MM-DD');
        this.fetchSchedules();
    };

    getSchedulesByDate = async (date: string) => {
        if (!date) {
            return;
        }
        this.loaded = false;
        try {
            const userSession = await this.rootStore.userStore.getUserSession();

            const result = await fetchSchedules(userSession?.tokens.id, { date });
            this.allSchedulesByDate = result;
        } catch (err) {
            console.error('Unable to fetch schedules', err);
        }
        this.loaded = true;
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.fetchSchedules();
            this.fetchInterval = setInterval(() => this.fetchSchedules(), this.refreshTimeout);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = undefined;
        }
    }

    validateNewAvailability = async ({
        userId,
        startTime,
        endTime,
        date,
        with24hSupport,
    }: {
        userId: string;
        startTime: string;
        endTime: string;
        date: string;
        with24hSupport: boolean;
    }) => {
        try {
            this.validatingAvailability = true;
            this.availabilityCheckResult = {};
            const userSession = await this.rootStore.userStore.getUserSession();
            const data = {
                userId,
                date,
                startTime,
                endTime,
                with24hSupport,
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            };
            this.availabilityCheckResult = await validateSchedule(userSession?.tokens.id, data);
            this.validatingAvailability = false;
        } catch (err) {
            this.validatingAvailability = false;
            this.availabilityCheckResult = {};
            console.error('Unable to validate new availability', err);
        }
    };
}

export default SchedulesStore;
