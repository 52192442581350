import { TimePrecision } from '@blueprintjs/datetime2';
import { FC } from 'react';
import { observer } from 'mobx-react';
import { FormikValues } from 'formik';
import { getMomentDateFormatter } from '../../../../../helpers/ucr/getMomentDateFormatter';

import { Button, ButtonSizes } from '../../../../v2/components';
import { dateTimeFormat } from '../../../../../constants/patientsConst';
import { TextInput, DateInput, Select } from '../../../../v2/form';
import { FormMode, FormSteps, getButtonName } from '../common';
import useStores from '../../../../../hook/useStores';

const MAXDATE = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const MINDATE = new Date(1900, 1, 1);

type ReferralDetailsType = {
    values: FormikValues;
    loading: boolean;
    formMode: FormMode;
    setFieldValue: (field: string, value: any) => void;
    onNextStep?: (nextStep: FormSteps, prevStep: FormSteps) => void;
    onSaveForm?: (curStep: FormSteps) => void;
};

const ReferralDetails: FC<ReferralDetailsType> = ({
    loading,
    formMode,
    onNextStep = () => {},
    onSaveForm = () => {},
}) => {
    const {
        RootStore: { lovsStore: { referredBy, referrer } = {} },
    }: {
        RootStore: { lovsStore: { [key: string]: any } };
    } = useStores() as {
        RootStore: any;
    };

    const buttonName = getButtonName(formMode);
    const referredByOptions = referredBy
        ?.slice()
        .sort((a: any, b: any) => a.toLowerCase().localeCompare(b.toLowerCase()));

    const onSubmit = () => {
        if (formMode === FormMode.DEFAULT) {
            onNextStep(FormSteps.CARE, FormSteps.REFERRAL);
        } else if (formMode === FormMode.ADD_VISIT) {
            onNextStep(FormSteps.REVIEW, FormSteps.REFERRAL);
        } else if (formMode === FormMode.EDIT_VISIT) {
            onSaveForm(FormSteps.REFERRAL);
        } else if (formMode === FormMode.FOLLOW_UP) {
            onNextStep(FormSteps.CARE, FormSteps.REFERRAL);
        }
    };

    return (
        <>
            <div className="v2__form-block">
                <TextInput
                    name="systmOneRef"
                    className="v2__form-group--pos-1-2"
                    label="Referral reference number"
                    disabled={loading}
                />
                <Select
                    name="referredBy"
                    className="v2__form-group--pos-1-2"
                    label="Referred by"
                    disabled={loading}
                >
                    <option value="">Not set</option>
                    {referredByOptions?.map((option: string) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Select>
                <Select
                    name="referrer"
                    className="v2__form-group--pos-1-2"
                    label="Referrer"
                    disabled={loading}
                >
                    <option value="">Not set</option>
                    {referrer
                        ?.slice()
                        .sort((a: any, b: any) => a.localeCompare(b))
                        .map((option: string) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                </Select>
                <DateInput
                    showTimezoneSelect={false}
                    name="referralDateTime"
                    className="v2__form-group--pos-1-2"
                    label="Referral date"
                    dateFormat={dateTimeFormat}
                    {...getMomentDateFormatter(dateTimeFormat)}
                    dateFnsFormat={dateTimeFormat}
                    disabled={loading}
                    timePickerProps={{
                        showArrowButtons: true,
                        precision: TimePrecision.MINUTE,
                    }}
                    maxDate={MAXDATE}
                    minDate={MINDATE}
                />
                <label className="v2__form-group">
                    <Button
                        className="v2__form-submit-button"
                        name={buttonName}
                        size={ButtonSizes.MEDIUM}
                        clickEvent={onSubmit}
                    />
                </label>
            </div>
        </>
    );
};

export default observer(ReferralDetails);
