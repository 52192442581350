import { FC, useState, useCallback } from 'react';
import { ApolloError } from '@apollo/client';
import { Form, FormikValues, useFormikContext, FormikContextType } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';

import { Accordion, AccordionTab, AccordionColors } from '../../../../v2/components';
import ReferralDetails from './ReferralDetails';
import CareDetails from './CareDetails';
import { getStepSchema } from './validation';
import { PullFromReferralForm } from '../DetailsFromReferral';
import { FormMode, FormSteps } from '../common';
import { PatientTab } from '../PatientFormTab';

export interface IProps {
    step: FormSteps;
    loading: boolean;
    error?: ApolloError;
    formMode: FormMode;
    onSubmit: (values: FormikValues) => Promise<void>;
}

const AddVisitForm: FC<IProps> = ({ step, loading, formMode, onSubmit }) => {
    const [currentStep, setCurrentStep] = useState<FormSteps>(step);
    const [stepsCompleted, setStepsCompleted] = useState<string[]>([]);

    const {
        errors,
        values,
        setFieldValue,
        setTouched,
    }: FormikContextType<FormikValues> = useFormikContext();
    const onNextStep = useCallback(
        (nextStep: FormSteps, prevStep: FormSteps) => {
            const stepSchema = getStepSchema(prevStep);
            const stepFields = Object.keys(stepSchema.fields).reduce(
                (fields, field) => ({ ...fields, [field]: true }),
                {},
            );

            setTouched(stepFields, false);

            try {
                if (!stepSchema.validateSync(values)) return;

                if (nextStep === FormSteps.REVIEW) {
                    onSubmit(values);
                } else {
                    setStepsCompleted([...stepsCompleted, prevStep]);
                    setCurrentStep(nextStep);
                }
            } catch (err: any) {
                console.log('Validation error:', err.message);
            }
        },
        [onSubmit, stepsCompleted, values, setTouched],
    );

    const onPatientDetailsNextStep = useCallback(() => {
        if (formMode === FormMode.DEFAULT || formMode === FormMode.FOLLOW_UP) {
            onNextStep(FormSteps.REFERRAL, FormSteps.PATIENT);
        } else if (formMode === FormMode.ADD_VISIT) {
            onNextStep(FormSteps.REVIEW, FormSteps.PATIENT);
        }
    }, [formMode, onNextStep]);

    const setStep = (step: FormSteps) => {
        if (isEmpty(errors)) {
            setCurrentStep(step);
        }
    };

    const referralStepColor =
        currentStep === FormSteps.REFERRAL ? AccordionColors.PINK : AccordionColors.GREY;
    const careStepColor =
        currentStep === FormSteps.CARE ? AccordionColors.PINK : AccordionColors.GREY;

    return (
        <Form>
            <Accordion>
                <PatientTab
                    stepsCompleted={stepsCompleted}
                    currentStep={currentStep}
                    formMode={formMode}
                    loading={loading}
                    onClick={() => setCurrentStep(FormSteps.PATIENT)}
                    onNextStep={onPatientDetailsNextStep}
                />
                <AccordionTab
                    name="referral"
                    title="Referral details"
                    color={
                        stepsCompleted.includes(FormSteps.REFERRAL) &&
                        currentStep !== FormSteps.REFERRAL
                            ? AccordionColors.BLUE
                            : referralStepColor
                    }
                    open={currentStep === FormSteps.REFERRAL}
                    disabled={
                        !stepsCompleted.includes(FormSteps.PATIENT) ||
                        formMode === FormMode.FOLLOW_UP
                    }
                    onClick={() => setStep(FormSteps.REFERRAL)}
                >
                    <ReferralDetails
                        values={values}
                        setFieldValue={setFieldValue}
                        loading={loading}
                        formMode={formMode}
                        onNextStep={onNextStep}
                    />
                </AccordionTab>
                <AccordionTab
                    name="care"
                    title="Care details"
                    color={
                        stepsCompleted.includes(FormSteps.CARE) && currentStep !== FormSteps.CARE
                            ? AccordionColors.BLUE
                            : careStepColor
                    }
                    open={currentStep === FormSteps.CARE}
                    disabled={
                        !stepsCompleted.includes(FormSteps.PATIENT) ||
                        !stepsCompleted.includes(FormSteps.REFERRAL)
                    }
                    onClick={() => setStep(FormSteps.CARE)}
                >
                    <CareDetails
                        values={values}
                        loading={loading}
                        formMode={formMode}
                        onNextStep={onNextStep}
                        setFieldValue={setFieldValue}
                    />
                </AccordionTab>
            </Accordion>
            <PullFromReferralForm />
        </Form>
    );
};

export default observer(AddVisitForm);
