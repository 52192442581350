import { Button, FormGroup, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import Select from 'react-select';
import CommonPagination from '../CommonHRRotaPagination';
import { PEOPLE_RECORD_STATUS_OPTIONS, PEOPLE_STATUS_OPTIONS } from '../../../../constants/hrConst';
import { onFilterChange, onTableSearch } from '../../../../helpers/common';

const NavigationPeople = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onFilter,
    onSearch,
    addNew,
    refresh,
    selected,
    validatePersonBeforeArchiving,
}) => {
    const onChange = useCallback((name) => (el) => onFilterChange(name, el, onFilter), [onFilter]);
    const onSearchChange = useCallback(
        async (inputValue) => {
            onTableSearch(inputValue, onSearch);
        },
        [onSearch],
    );

    const Content = () => (
        <Menu>
            <MenuItem
                text="Archive"
                disabled={!selected.length}
                onClick={() => validatePersonBeforeArchiving(selected)}
            />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup label="Employment Status" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={PEOPLE_STATUS_OPTIONS}
                                    isMulti
                                    onChange={onChange('status')}
                                    className="react-select"
                                />
                            </FormGroup>
                            <FormGroup label="Record Status" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={PEOPLE_RECORD_STATUS_OPTIONS}
                                    isMulti
                                    onChange={onChange('statusId')}
                                    className="react-select"
                                />
                            </FormGroup>
                            <FormGroup
                                label="Search"
                                helperText="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Enter search query..."
                                    large
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="people"
                        />
                    </div>
                    <div>
                        <Button
                            text="New"
                            intent="success"
                            icon="new-person"
                            onClick={addNew}
                            className="common-header__button"
                            outlined
                        />
                        <Button
                            text="Refresh"
                            icon="refresh"
                            onClick={refresh}
                            className="common-header__button"
                            outlined
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content shouldDismissPopover={false} />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    outlined
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default NavigationPeople;
