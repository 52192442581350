import React from 'react';
import moment from 'moment';
import { INormalizedDepartment } from './DepartmentAssignmentsTypes';

const DepartmentAssignmentItem = ({
    id,
    costCentreName,
    departmentName,
    startDate,
    endDate,
    payRateName,
    isArchived,
    createdAt,
    createdBy,
    updatedAt,
    updatedBy,
    onClick,
}: INormalizedDepartment) => {
    return (
        <tr
            onClick={(event) => {
                if (!id || id.length === 0) return;
                onClick(id, event);
            }}
        >
            <td>{costCentreName}</td>
            <td>{departmentName}</td>
            <td>{startDate ? moment(startDate).format('D MMM YYYY') : ''}</td>
            <td>{endDate ? moment(endDate).format('D MMM YYYY') : ''}</td>
            <td>{payRateName}</td>
            <td>{isArchived}</td>
            <td>{createdAt ? moment(createdAt).format('D MMM YYYY') : ''}</td>
            <td>{createdBy}</td>
            <td>{updatedAt ? moment(updatedAt).format('D MMM YYYY') : ''}</td>
            <td>{updatedBy}</td>
        </tr>
    );
};

export default DepartmentAssignmentItem;
