import { formatDisplayDate } from '../../../../modules/helpers/formatData';

interface PerformersList {
    status?: string;
    surname?: string;
    alignment?: string;
    forenames?: string;
    performerRole?: string;
    dateInGpRegister?: string;
    nhseiRegionalTeam?: string;
    dateOfRegistration?: string;
    professionalBodyId?: string;
    dateFirstOnPerformersList?: string;
    currentlyInProbationaryPeriod?: string;
}

const PerformersListDetails = ({
    status,
    surname,
    alignment,
    forenames,
    performerRole,
    dateInGpRegister,
    nhseiRegionalTeam,
    dateOfRegistration,
    professionalBodyId,
    dateFirstOnPerformersList,
    currentlyInProbationaryPeriod,
}: PerformersList) => (
    <>
        <h3 className="h3 modal__sub-heading">Key Details</h3>
        <dl className="info">
            <dt className="info__title">Professional Registration Number</dt>
            <dd className="info__definition">{professionalBodyId}</dd>
            <dt className="info__title">Forename(s)</dt>
            <dd className="info__definition">{forenames}</dd>
            <dt className="info__title">Surname</dt>
            <dd className="info__definition">{surname}</dd>
            <dt className="info__title">Status</dt>
            <dd className="info__definition">{status}</dd>
            <dt className="info__title">Alignment</dt>
            <dd className="info__definition">{alignment}</dd>
            <dt className="info__title">Performer Role</dt>
            <dd className="info__definition">{performerRole}</dd>
        </dl>
        <h3 className="h3 modal__sub-heading">Additional Details</h3>
        <dl className="info">
            <dt className="info__title">Date In GP Register</dt>
            <dd className="info__definition">
                {dateInGpRegister ? formatDisplayDate(dateInGpRegister) : ''}
            </dd>
            <dt className="info__title">Date First On Performers List</dt>
            <dd className="info__definition">
                {dateFirstOnPerformersList ? formatDisplayDate(dateFirstOnPerformersList) : ''}
            </dd>
            <dt className="info__title">Date of Registration</dt>
            <dd className="info__definition">
                {dateOfRegistration ? formatDisplayDate(dateOfRegistration) : ''}
            </dd>
            <dt className="info__title">NHSEI Regional Team</dt>
            <dd className="info__definition">{nhseiRegionalTeam}</dd>
            <dt className="info__title">Currently In Probationary Period</dt>
            <dd className="info__definition">{currentlyInProbationaryPeriod}</dd>
        </dl>
    </>
);

export default PerformersListDetails;
