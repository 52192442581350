import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Alert, RadioGroup } from '@blueprintjs/core';
import AppToaster from '../../../modules/helpers/Toaster';

const Withdraw = inject('RootStore')(
    observer(
        class Withdraw extends Component {
            state = {
                dialogShowConfirmation: false,
                dialogEditRecurring: false,
                recurringOption: 'item',
            };

            withdrawJob = async (jobId, additionalData) => {
                this.props.onToggleLoading(true);

                try {
                    await this.props.RootStore.jobsStore.withdrawJob(jobId, additionalData);
                } catch (err) {
                    AppToaster.show({
                        message: (
                            <>
                                Sorry, there was a problem withdrawing the job. Please try again or
                                contact{' '}
                                <a href="mailto:support@docabode.com">support@docabode.com</a> for
                                help.
                            </>
                        ),
                        intent: 'danger',
                        timeout: 0,
                    });
                    return this.props.onToggleLoading(false);
                }

                AppToaster.show({
                    message: 'The job has been withdrawn successfully!',
                    intent: 'success',
                });

                this.props.onToggleLoading(false);
                this.props.RootStore.jobsStore.setJobs();
            };

            redirectToEdit(jobId, isSeries) {
                const url = this.props.match.path.startsWith('/scheduled')
                    ? isSeries
                        ? `/scheduled/jobs/${jobId}/series/edit`
                        : `/scheduled/jobs/${jobId}/edit`
                    : `/on-demand/${this.props.match.params.tab}/jobs/${jobId}/edit`;

                this.props.history.push(url);
            }

            handleWithdraw = async ({ jobStatus, id, repeatingGroupId }) => {
                let dialogResult = true;

                if (
                    jobStatus === 'ACCEPTED' ||
                    jobStatus === 'CURRENT' ||
                    jobStatus === 'ARRIVED'
                ) {
                    dialogResult = await this.invokeDialog('dialogShowConfirmation');
                }
                if (dialogResult && repeatingGroupId) {
                    dialogResult = await this.invokeDialog('dialogEditRecurring');
                }
                if (dialogResult) {
                    const additionalData =
                        dialogResult?.isSeries !== undefined
                            ? { isSeries: dialogResult.isSeries }
                            : {};
                    await this.withdrawJob(id, additionalData);
                    this.redirectToEdit(id, dialogResult?.isSeries ?? false);
                }
            };

            handleEdit = async ({ id, repeatingGroupId }) => {
                let dialogResult = true;
                if (repeatingGroupId) {
                    dialogResult = await this.invokeDialog('dialogEditRecurring');
                }
                if (dialogResult) {
                    this.redirectToEdit(id, dialogResult?.isSeries ?? false);
                }
            };

            invokeDialog(name) {
                return new Promise((resolve) => {
                    this.setState({ [name]: (result) => resolve(result) });
                });
            }

            closeDialog(name, result) {
                const resultFunc = this.state[name];
                this.setState({ [name]: null });
                resultFunc(result);
            }

            handleRadioGroup = (input) => {
                this.setState({ recurringOption: input.currentTarget.value });
            };

            render() {
                const { job } = this.props;

                return (
                    <div>
                        <Alert
                            isOpen={this.state.dialogShowConfirmation}
                            onConfirm={() => this.closeDialog('dialogShowConfirmation', true)}
                            onCancel={() => this.closeDialog('dialogShowConfirmation', false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="danger"
                        >
                            <p>
                                You are about to withdraw a job that is assigned to a HCP and you
                                must inform the HCP before doing this.
                            </p>
                            <p>
                                <strong>Please confirm you have informed the HCP:</strong>
                            </p>
                        </Alert>
                        <Alert
                            isOpen={this.state.dialogEditRecurring}
                            onConfirm={() =>
                                this.closeDialog('dialogEditRecurring', {
                                    isSeries: this.state.recurringOption === 'series',
                                })
                            }
                            onCancel={() => this.closeDialog('dialogEditRecurring', false)}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="danger"
                        >
                            <p>
                                <b>Open Recurring item</b>
                            </p>
                            <p>This is one job in a series. What do you want to open?</p>
                            <RadioGroup
                                name="group"
                                options={[
                                    { label: 'Just this one', value: 'item' },
                                    { label: 'The entire series', value: 'series' },
                                ]}
                                onChange={this.handleRadioGroup}
                                selectedValue={this.state.recurringOption}
                            />
                        </Alert>

                        {job.jobStatus === 'WITHDRAWN' ? (
                            <Button large={true} onClick={() => this.handleEdit(job)}>
                                Edit
                            </Button>
                        ) : (
                            <Button
                                intent="danger"
                                large={true}
                                onClick={() => this.handleWithdraw(job)}
                                icon="undo"
                            >
                                Withdraw
                            </Button>
                        )}
                    </div>
                );
            }
        },
    ),
);

export default Withdraw;
