import { FC } from 'react';
import { Form } from 'formik';
import { JobStatus, Patient } from '@doc-abode/data-models';

import { Select, TextArea } from '../../../../v2/form';
import { Button, ButtonColors, ButtonElems, ButtonSizes } from '../../../../v2/components';
import useStores from '../../../../../hook/useStores';

interface IProps {
    visit: Patient;
    loading: boolean;
    onClose: () => void;
}

const AbortSingleVisitForm: FC<IProps> = ({ visit, loading, onClose }) => {
    const {
        RootStore: {
            lovsStore: { controllerAbortReason },
        },
    } = useStores() as { RootStore: any };

    const isArrived = visit.jobStatus === JobStatus.ARRIVED;
    const isCurrent = visit.jobStatus === JobStatus.CURRENT;

    return (
        <Form>
            <div className="v2__dialog-block">
                {isArrived && <strong>This visit is in progress. </strong>}
                {isCurrent && <strong>This visit is current. </strong>}
                Are you sure you want to abort the visit?
            </div>
            <div className="v2__dialog-block">
                <Select
                    label="Reason for aborting"
                    name="controllerAbortedReason"
                    options={controllerAbortReason}
                />
            </div>
            <div className="v2__dialog-block">
                <TextArea
                    label="Notes"
                    name="controllerAbortedNotes"
                    className="v2__form-group--pos-1-1"
                />
            </div>
            <div className="v2__dialog-buttons v2__dialog-buttons--left">
                <Button
                    name="Cancel"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    color={ButtonColors.GREY}
                    disabled={loading}
                    clickEvent={onClose}
                />
                <Button
                    name="Confirm abort visit"
                    elem={ButtonElems.BUTTON}
                    size={ButtonSizes.MEDIUM}
                    type="submit"
                    color={ButtonColors.RED}
                    disabled={loading}
                />
            </div>
        </Form>
    );
};

export default AbortSingleVisitForm;
