import React from 'react';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import { Button, FormGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import './styles/index.css';

import DateSelector from './DateSelector';
import Calendar from './Calendar';
import ShiftPatternSelector from './ShiftPatternSelector';
import TableViewVariants from '../../../../common/TableViewVariants';
import { filledStatus, getShiftDate, getShiftsForDateRange } from './helper';
import Loader from '../../../../modules/helpers/Loader';
import {
    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID,
} from '../../../../../constants/hrConst';
import {
    DAYS,
    SHIFT_FILL_STATUS,
    SHIFT_STATUS_OPTIONS,
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPES_OPTIONS,
    PAYMENT_STATUS_PLANED,
} from '../../../../../constants/rotaConst';
import _ from 'lodash';
import ExtendRotaDialog from './Dialogs/ExtendRotaDialog';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import { isDateTimeOverlap } from '../../../../../helpers/customPayRateValidation';
import { RotaPrompt } from './RotaPrompt';
import BuilderHeader from './components/Header';
import BuilderFilter from './components/Filters';

const Builder = inject('RootStore')(
    observer(
        class Builder extends React.Component {
            private myRef: React.RefObject<any>;

            constructor(props: any) {
                super(props);
                this.myRef = React.createRef();
            }

            state = {
                eRota: [],
                deps: [],
                filteredShifts: [],
                formattedShifts: [],
                dateRange: [null, null],
                isFormDirty: false,
                shiftPattern: null,
                showShiftPatterns: false,
                shiftPatternEntries: [],
                locations: [],
                locationsFromShifts: [],
                roles: [],
                rolesFromShifts: [],
                functions: [],
                functionsFromShifts: [],
                fundingPools: [],
                fundingPoolsFromShifts: [],
                shiftValueMin: 0,
                shiftValueMax: 1,
                shiftValueChosen: [0, 1],
                typesFromShifts: [],
                statusFromShifts: [],
                staffRecords: [],
                defaultStaffsFromShifts: [],
                confirmedStaffsFromShifts: [],
                timeFromShifts: [],
                daysFromShifts: [],
                fillStatusFromShifts: [],
                filterContractTypes: [],
                filterAgencies: [],
                filterShiftSource: [],
                filterWeeks: [],
                filterTrainees: [],
                filterDates: [null, null],
                name: '',
                costCentreName: '',
                departmentName: '',
                payRates: [],
                shifts: [],
                rotaId: null,
                isViewMode: false,
                isCreatePath: true,
                shiftTypes: [],
                shiftStatuses: [],
                showShiftPatternSelector: false,
                shiftPatternSelectorPrefilled: [],
                isGridView: true,
                loading: true,
                costCentreId: null,
                costCentre: null,
                departmentId: null,
                department: null,
                isPublished: false,
                showMoreFilters: false,
                appliedShiftPattern: null,
                appliedShiftPatternId: null,
                shiftPatternStartWeek: null,
                shiftPatternStartWeeksCount: null,
                editName: true,
                extendRotaDialogOpened: false,
                previousDateRange: [],
                shiftPatternWeeksCount: 1,
                shiftPatternAppliedAt: new Date(),
                showAlert: false,
                paymentStatusOptions: [],
            };

            async componentDidMount() {
                const {
                    rotaBuilderStore,
                    dictionaryStore,
                    departmentsStore,
                    shiftPatternsStore,
                    staffStore,
                    payRatesStore,
                    holidaysStore,
                    functionsStore,
                    fundingPoolStore,
                } = (this.props as any).RootStore;

                if ((this.props as any).location.state?.editModeOpenedFromSP) {
                    rotaBuilderStore.newRotaCostCentreName = `${shiftPatternsStore.rotaByShiftPatternId?.costCentre.name} (${shiftPatternsStore.rotaByShiftPatternId?.costCentre.code})`;
                    rotaBuilderStore.newRotaCostCentreId =
                        shiftPatternsStore.rotaByShiftPatternId?.costCentreId;
                    rotaBuilderStore.newRotaDepartmentId =
                        shiftPatternsStore.rotaByShiftPatternId?.departmentId;
                    rotaBuilderStore.newRotaDepartmentName = `${shiftPatternsStore.rotaByShiftPatternId?.department.name} (${shiftPatternsStore.rotaByShiftPatternId?.department.costCode})`;
                    this.setState({
                        showShiftPatternSelector: true,
                        shiftPatternSelectorPrefilled: {
                            label: shiftPatternsStore.rotaByShiftPatternId?.name,
                            value: shiftPatternsStore.rotaByShiftPatternId?.id,
                            shiftPatternEntries: shiftPatternsStore.rotaByShiftPatternId?.shiftPatternEntries.map(
                                (item: any) => {
                                    return {
                                        id: item.id,
                                        shiftPatternId: item.shiftPatternId ?? '',
                                        weekNumber: item.weekNumber,
                                        dayOfWeek: item.dayOfWeek,
                                        startTime: new Date(item.startTime),
                                        endTime: new Date(item.endTime),
                                        breakDurationMinutes: item.breakDurationMinutes,
                                        breakIsPaid: item.breakIsPaid,
                                        overrideValueInPence: item.overrideValueInPence,
                                        roleId: item.shiftEmployeeRoleTypeId,
                                        fundingPoolId: item.fundingPoolId,
                                        locationId: item.locationId,
                                        defaultEmployeeId: item.defaultEmployeeId,
                                        payRateId: item.payRateId,
                                        typeId: item.typeId,
                                        statusId: SHIFT_STATUS_STANDARD_ID,
                                        thirdPartyPaid: item.thirdPartyPaid,
                                        trainingShift: item.trainingShift,
                                        rotaShiftFunctions: item.rotaShiftFunctions?.map(
                                            (shiftFunction: any) => shiftFunction.functionId,
                                        ),
                                        trainees: item.trainees?.length
                                            ? item.trainees.map((trainee: any) => {
                                                  return {
                                                      traineeId: trainee.traineeId,
                                                      roleId: trainee.roleId,
                                                      overrideValue: trainee.overrideValue,
                                                      payRateId: trainee.payRateId,
                                                  };
                                              })
                                            : [
                                                  {
                                                      traineeId: '',
                                                      roleId: '',
                                                      overrideValue: null,
                                                      payRateId: '',
                                                  },
                                              ],
                                    };
                                },
                            ),
                            shifts: shiftPatternsStore.rotaByShiftPatternId?.shifts,
                            weeks: shiftPatternsStore.rotaByShiftPatternId?.weeks,
                        },
                    });
                }

                const fromDate = new Date();
                let toDate = fromDate;
                toDate = new Date(
                    fromDate.getFullYear(),
                    fromDate.getMonth() + 1,
                    fromDate.getDate(),
                );

                const statusesItems = [];
                for (const key of Object.keys(dictionaryStore.shiftStatusesDictionary)) {
                    statusesItems.push({
                        id: key,
                        name: dictionaryStore.shiftStatusesDictionary[key],
                    });
                }

                const typesItems = [];
                for (const key of Object.keys(dictionaryStore.shiftTypesDictionary)) {
                    typesItems.push({
                        id: key,
                        name: dictionaryStore.shiftTypesDictionary[key],
                    });
                }

                this.setState({
                    dateRange: [fromDate, toDate],
                    shiftStatuses: statusesItems,
                    shiftTypes: typesItems,
                    staffRecords: staffStore.allStaffs.map((r: any) => ({
                        value: r.id,
                        label: `${r.person.firstName} ${r.person.lastName}`,
                        statusId: r.statusId,
                    })),
                    locations: dictionaryStore.locations,
                    roles: dictionaryStore.roles,
                    functions: dictionaryStore.functions,
                    fundingPools: dictionaryStore.fundingPools,
                });

                const { match } = this.props as any;

                if (match?.params.id) {
                    await payRatesStore.getPayRates();
                    const { id } = (this.props as any).match.params;
                    const eRota = await rotaBuilderStore.getRotaByIdExtended(id);
                    const rotaShifts = eRota.rotaShifts.map((r: any) => {
                        const shiftDate = moment(getShiftDate(eRota.startDate, r));

                        const startTime = shiftDate
                            .set({
                                hour: parseInt(r.startTime.split(':')[0], 10),
                                minute: parseInt(r.startTime.split(':')[1], 10),
                                second: 0,
                            })
                            .toDate();
                        const endTime = shiftDate
                            .set({
                                hour: parseInt(r.endTime.split(':')[0], 10),
                                minute: parseInt(r.endTime.split(':')[1], 10),
                                second: 0,
                            })
                            .toDate();

                        const conflicts = this._processConflicts(r, eRota.name);

                        return {
                            ...r,
                            date: shiftDate.toDate(),
                            startTime: startTime,
                            endTime: endTime,
                            __typename: undefined,
                            conflicts,
                        };
                    });
                    const selectedShiftPattern = eRota.department?.shiftPatterns.find(
                        (r: any) => r.value === eRota.shiftPatternId,
                    );
                    const appliedShiftPatternWeeksCount = selectedShiftPattern
                        ? Math.max.apply(
                              Math,
                              (selectedShiftPattern as any).shiftPatternEntries.map(function (
                                  o: any,
                              ) {
                                  return o.weekNumber;
                              }),
                          )
                        : 0;
                    const appliedShiftPattern = eRota.department?.shiftPatterns.find(
                        (r: any) => r.value === eRota.shiftPatternId,
                    );

                    rotaBuilderStore.newRotaCostCentreId = eRota.costCentreId;
                    rotaBuilderStore.newRotaDepartmentId = eRota.departmentId;

                    this.setState({
                        eRota: eRota,
                        costCentre: eRota.costCentre,
                        department: eRota.department,
                        costCentreName: `${eRota.costCentre?.name} (${eRota.costCentre?.code})`,
                        departmentName: `${eRota.department?.name} (${eRota.department?.costCode})`,
                        costCentreId: eRota.costCentreId,
                        departmentId: eRota.departmentId,
                        name: eRota.name,
                        isPublished: eRota.isPublished,
                        shifts: rotaShifts,
                        isViewMode: true,
                        isCreatePath: false,
                        rotaId: match.params.id,
                        dateRange: [new Date(eRota.startDate), new Date(eRota.endDate)],
                        filterContractTypes: eRota.rotaShifts.map((item: any) =>
                            item.confirmedEmployee?.contractType?.name
                                ? item.confirmedEmployee?.contractType?.name
                                : item.defaultEmployee?.contractType?.name
                                ? item.defaultEmployee?.contractType?.name
                                : null,
                        ),
                        filterAgencies: eRota.rotaShifts.map((item: any) =>
                            item.confirmedEmployee?.agency?.name
                                ? item.confirmedEmployee?.agency?.name
                                : item.defaultEmployee?.agency?.name
                                ? item.defaultEmployee?.agency?.name
                                : null,
                        ),
                        filterShiftSource: eRota.rotaShifts.map((item: any) =>
                            item.isAdditional ? 'Manually Created' : 'Shift Pattern',
                        ),
                        filterWeeks: eRota.rotaShifts.map((item: any) => item.weekNumber),
                        filterTrainees: eRota.rotaShifts.map(
                            (item: any) =>
                                !!item.trainees[0]?.traineeId && item.trainees[0]?.traineeId,
                        ),
                        appliedShiftPattern,
                        appliedShiftPatternWeeksCount,
                        appliedShiftPatternId: eRota.shiftPatternId,
                        shiftPatternStartWeek: eRota.shiftPatternStartWeek,
                        shiftPatternAppliedAt: new Date(eRota.shiftPatternAppliedAt),
                        shiftPattern: selectedShiftPattern,
                    });
                } else {
                    this.setState({
                        rotaId: null,
                        department:
                            rotaBuilderStore.newRotaDepartmentId &&
                            (await departmentsStore.getDepartmentById(
                                rotaBuilderStore.newRotaDepartmentId,
                            )),
                        costCentreId: rotaBuilderStore.newRotaCostCentreId,
                        departmentId: rotaBuilderStore.newRotaDepartmentId,
                        costCentreName: rotaBuilderStore.newRotaCostCentreName,
                        departmentName: rotaBuilderStore.newRotaDepartmentName,
                    });
                }
                this.setState({
                    editName: this.state.isPublished,
                    deps: [],
                    filteredShifts: this.state.formattedShifts,
                    loading: false,
                });

                // getPaymentOptions
                const paymentStatusOptions = await rotaBuilderStore.getPaymentStatuses();
                this.setState({ paymentStatusOptions });

                if (!this.state.loading) {
                    await payRatesStore.clearPayRatesByRole();
                    await staffStore.getAllStaffs();
                    await functionsStore.getAllFunctions();
                    await fundingPoolStore.getAllFundingPools();
                    this._generateFormattedShifts(this.state.shifts);
                    const payRateItems = payRatesStore?.gridPayRates.map((r: any) => ({
                        value: r.id,
                        label: r.name,
                        isEmployee: r.isEmployee,
                        rate: r.rate,
                        code: r.code,
                    }));
                    this.setState({
                        filteredShifts: this.state.formattedShifts,
                        payRates: payRateItems,
                    });
                    await holidaysStore.getHolidays(
                        this.state.dateRange[0],
                        this.state.dateRange[1],
                    );
                }
            }

            getConflict = (shift0: any, shift1: any) => {
                const isOverlap = isDateTimeOverlap(
                    shift0.startDate,
                    shift0.endDate,
                    shift1.startDate,
                    shift1.endDate,
                );

                if (!isOverlap) return null;

                const { confirmedEmployeeId, defaultEmployeeId, startDate } = shift0;
                const rotaName = 'the current';

                // case 1
                if (
                    !shift1.confirmedEmployeeId &&
                    !confirmedEmployeeId &&
                    shift1.defaultEmployeeId &&
                    shift1.defaultEmployeeId === defaultEmployeeId
                ) {
                    return {
                        rotaShiftId: shift1.id,
                        label: `Default staff member is provisionally rostered in ${moment(
                            startDate,
                        ).format('HH:mm')} shift of ${rotaName} rota`,
                    };
                }

                // case 2
                if (
                    !confirmedEmployeeId &&
                    shift1.confirmedEmployeeId &&
                    shift1.confirmedEmployeeId === defaultEmployeeId
                ) {
                    return {
                        rotaShiftId: shift1.id,
                        label: `Default staff member is rostered in ${moment(startDate).format(
                            'HH:mm',
                        )} shift of ${rotaName} rota`,
                    };
                }

                // case 3
                if (
                    confirmedEmployeeId &&
                    !shift1.confirmedEmployeeId &&
                    shift1.defaultEmployeeId === confirmedEmployeeId
                ) {
                    return {
                        rotaShiftId: shift1.id,
                        label: `Confirmed Staff member is provisionally rostered in ${moment(
                            startDate,
                        ).format('HH:mm')} shift of ${rotaName} rota`,
                    };
                }

                // case 4
                if (
                    confirmedEmployeeId &&
                    shift1.confirmedEmployeeId &&
                    shift1.confirmedEmployeeId === confirmedEmployeeId
                ) {
                    return {
                        rotaShiftId: shift1.id,
                        label: `Confirmed Staff member is rostered in ${moment(startDate).format(
                            'HH:mm',
                        )} shift of ${rotaName} rota`,
                        isCritical: true,
                    };
                }

                return null;
            };

            getCurrentRotaConflicts = (shift: any) => {
                const result = [];

                const dayShifts = this.state.shifts.filter(
                    (r: any) =>
                        r.id !== shift.id &&
                        moment(shift.startDate).isSame(r.startDate, 'day') &&
                        moment(shift.endDate).isSame(r.endDate, 'day'),
                );

                for (const rotaShift of dayShifts) {
                    const isOverlap = isDateTimeOverlap(
                        shift.startDate,
                        shift.endDate,
                        (rotaShift as any).startDate,
                        (rotaShift as any).endDate,
                    );

                    if (!isOverlap) continue;

                    const { confirmedEmployeeId, defaultEmployeeId, startDate } = rotaShift;
                    const rotaName = 'the current';

                    // case 1
                    if (
                        !(rotaShift as any).confirmedEmployeeId &&
                        !confirmedEmployeeId &&
                        (rotaShift as any).defaultEmployeeId &&
                        (rotaShift as any).defaultEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: (rotaShift as any).id,
                            label: `Default staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                        });
                    }

                    // case 2
                    if (
                        !confirmedEmployeeId &&
                        (rotaShift as any).confirmedEmployeeId &&
                        (rotaShift as any).confirmedEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: (rotaShift as any).id,
                            label: `Default staff member is rostered in ${moment(startDate).format(
                                'HH:mm',
                            )} shift of ${rotaName} rota`,
                        });
                    }

                    // case 3
                    if (
                        confirmedEmployeeId &&
                        !(rotaShift as any).confirmedEmployeeId &&
                        (rotaShift as any).defaultEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: (rotaShift as any).id,
                            label: `Confirmed Staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                        });
                    }

                    // case 4
                    if (
                        confirmedEmployeeId &&
                        (rotaShift as any).confirmedEmployeeId &&
                        (rotaShift as any).confirmedEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: (rotaShift as any).id,
                            label: `Confirmed Staff member is rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                            isCritical: true,
                        });
                    }
                }

                return result;
            };

            getShiftConflicts = async (shift: any) => {
                const { defaultEmployeeId, confirmedEmployeeId, startDate } = shift;

                if (!shift.defaultEmployeeId && !shift.confirmedEmployeeId) return [];
                const { rotaBuilderStore } = (this.props as any).RootStore;

                const defaultEmployeeConflicts =
                    shift.defaultEmployeeId && shift.defaultEmployeeId !== shift.confirmedEmployeeId
                        ? await rotaBuilderStore.getShiftConflicts(
                              shift.defaultEmployeeId,
                              shift.startDate,
                              shift.endDate,
                              shift.id,
                          )
                        : [];

                const confirmedEmployeeConflicts = shift.confirmedEmployeeId
                    ? await rotaBuilderStore.getShiftConflicts(
                          shift.confirmedEmployeeId,
                          shift.startDate,
                          shift.endDate,
                          shift.id,
                      )
                    : [];

                const conflicts = [...defaultEmployeeConflicts, ...confirmedEmployeeConflicts];
                const result = [];

                for (const conf of conflicts) {
                    const { rotaName, rotaShift } = conf;

                    if (this.state.rotaId === rotaShift.rota.id) continue;

                    // case 1
                    if (
                        !rotaShift.confirmedEmployeeId &&
                        !confirmedEmployeeId &&
                        rotaShift.defaultEmployeeId &&
                        rotaShift.defaultEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: rotaShift.id,
                            label: `Default staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                        });
                    }

                    // case 2
                    if (
                        !confirmedEmployeeId &&
                        rotaShift.confirmedEmployeeId &&
                        rotaShift.confirmedEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: rotaShift.id,
                            label: `Default staff member is rostered in ${moment(startDate).format(
                                'HH:mm',
                            )} shift of ${rotaName} rota`,
                        });
                    }

                    // case 3
                    if (
                        confirmedEmployeeId &&
                        !rotaShift.confirmedEmployeeId &&
                        rotaShift.defaultEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: rotaShift.id,
                            label: `Confirmed Staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                        });
                    }

                    // case 4
                    if (
                        confirmedEmployeeId &&
                        rotaShift.confirmedEmployeeId &&
                        rotaShift.confirmedEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: rotaShift.id,
                            label: `Confirmed Staff member is rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${rotaName} rota`,
                            isCritical: true,
                        });
                    }
                }

                const localConflicts = this.getCurrentRotaConflicts(shift);

                return [...result, ...localConflicts];
            };

            _processConflicts = (shift: any, rotaName: string) => {
                const result = [];

                for (const conf of [...shift.conflicts0, ...shift.conflicts1]) {
                    const cShift =
                        conf.rotaShift0.id === shift.id ? conf.rotaShift1 : conf.rotaShift0;

                    const { confirmedEmployeeId, defaultEmployeeId, startDate } = cShift;

                    // case 1
                    if (
                        !shift.confirmedEmployeeId &&
                        !confirmedEmployeeId &&
                        shift.defaultEmployeeId &&
                        shift.defaultEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: shift.id,
                            label: `Default staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${cShift.rota.name} rota`,
                        });
                    }

                    // case 2
                    if (
                        !confirmedEmployeeId &&
                        shift.confirmedEmployeeId &&
                        shift.confirmedEmployeeId === defaultEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: shift.id,
                            label: `Default staff member is rostered in ${moment(startDate).format(
                                'HH:mm',
                            )} shift of ${cShift.rota.name} rota`,
                        });
                    }

                    // case 3
                    if (
                        confirmedEmployeeId &&
                        !shift.confirmedEmployeeId &&
                        shift.defaultEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: shift.id,
                            label: `Confirmed Staff member is provisionally rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${cShift.rota.name} rota`,
                        });
                    }

                    // case 4
                    if (
                        confirmedEmployeeId &&
                        shift.confirmedEmployeeId &&
                        shift.confirmedEmployeeId === confirmedEmployeeId
                    ) {
                        result.push({
                            rotaShiftId: shift.id,
                            label: `Confirmed Staff member is rostered in ${moment(
                                startDate,
                            ).format('HH:mm')} shift of ${cShift.rota.name} rota`,
                            isCritical: true,
                        });
                    }
                }

                return result;
            };

            _handleGridClick = () => {
                this.setState({
                    isGridView: true,
                    isListView: false,
                });
            };

            _handleListClick = () => {
                this.setState({
                    isGridView: false,
                    isListView: true,
                });
            };

            _handleViewMode = () => {
                this.setState(() => {
                    return { isViewMode: !this.state.isViewMode };
                });
            };

            handleStaffSearch = async (searchText: string) => {};

            updateRotaShiftComments = (rotaId: string, commentsArr: any) => {
                const shiftWasPublished = this.state.shifts.some((item: any) => item.id === rotaId);
                if (shiftWasPublished) {
                    this.setState({
                        shifts: this.state.shifts.map((entry: any) => {
                            const updateItem = entry.id === rotaId;
                            if (updateItem) {
                                return {
                                    ...entry,
                                    comments: [...commentsArr],
                                };
                            } else {
                                return { ...entry };
                            }
                        }),
                    });
                }
            };

            handleSavingRota = async (isPublished: boolean) => {
                await (this.props as any).RootStore.rotaBuilderStore.updateChangesState(false);
                const staffMembersList = new Set();

                this.state.shifts.forEach((item: any) => {
                    if (item.defaultEmployeeId) {
                        staffMembersList.add(item.defaultEmployeeId);
                    }
                    if (item.confirmedEmployeeId) {
                        staffMembersList.add(item.confirmedEmployeeId);
                    }
                    if (item.trainees && item.trainees.length && item.trainees[0].traineeId) {
                        staffMembersList.add(item.trainees[0].traineeId);
                    }
                });

                staffMembersList.forEach((member) => {
                    if (this.state.staffRecords) {
                        const staffMember = this.state.staffRecords.find(
                            (item: any) => item.value === member,
                        );
                        if (staffMember && staffMember['statusId']) {
                            if (
                                staffMember['statusId'] === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID ||
                                staffMember['statusId'] === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID
                            ) {
                                return;
                            } else {
                                staffMembersList.delete(member);
                            }
                        }
                    }
                });

                if (staffMembersList.size) {
                    this.setState((prevState) => {
                        return { showAlert: !(prevState as any).showAlert };
                    });
                } else {
                    this.saveRota(isPublished);
                }
            };

            confirmSaveRota = () => {
                this.setState((prevState) => {
                    return { showAlert: !(prevState as any).showAlert };
                });

                this.saveRota(true);
            };

            handleClosingAlert = () => {
                this.setState((prevState) => {
                    return { showAlert: !(prevState as any).showAlert };
                });
            };

            exitRota = async () => {
                const { history } = this.props as any;
                await (this.props as any).RootStore.rotaBuilderStore.updateChangesState(false);
                history.push(`/rota/list/`, {
                    prevPath: history.location,
                });
            };

            saveRota = async (isPublished: boolean) => {
                await (this.props as any).RootStore.rotaBuilderStore.updateChangesState(false);
                if (!this.state.name || this.state.name.length === 0) {
                    document.getElementById('name-input')?.focus();
                    this.setState({ isFormDirty: true });
                    return;
                }

                if (!this.state.department) {
                    document.getElementById('service-input')?.scrollIntoView();

                    this.setState({ isFormDirty: true });
                    return;
                }

                this.setState({ loading: true });

                const {
                    RootStore: {
                        rotaBuilderStore: { createRotaNew },
                    },
                } = this.props as any;

                const rotaStart = this.state.dateRange[0] || new Date();
                const rotaEnd = this.state.dateRange[1] || new Date();

                const result = {
                    id: this.state.rotaId,
                    name: this.state.name,
                    costCentreId: this.state.costCentreId,
                    departmentId: this.state.departmentId,
                    startDate: this.state.dateRange[0],
                    endDate: this.state.dateRange[1],
                    shiftPatternId: this.state.appliedShiftPatternId,
                    shiftPatternStartWeek: this.state.shiftPatternStartWeek,
                    isPublished,
                    shifts: this.state.shifts
                        .filter((r: any) =>
                            moment(r.date).isBetween(
                                moment(rotaStart),
                                moment(rotaEnd),
                                'days',
                                '[]',
                            ),
                        )
                        .map((r: any) => ({
                            ...r,
                            date: undefined,
                            endTime: moment(r.endTime).format('HH:mm'),
                            startTime: moment(r.startTime).format('HH:mm'),
                            overrideValueInPence: parseInt(r.overrideValueInPence, 10)
                                ? parseInt(r.overrideValueInPence, 10)
                                : 0,
                            conflicts: r.conflicts,
                            paymentStatusId: r?.paymentStatusId || PAYMENT_STATUS_PLANED,
                        })),
                };

                await createRotaNew(result);

                this.setState({ isViewMode: true, loading: false });
            };

            _handleFilterDatesChange = async (val: any) =>
                this.setState({
                    filterDates: val,
                });

            _handleDatesFilter = async (dateRange: any) => {
                const range = dateRange.every((element: any) => element === null)
                    ? [this.state.dateRange[0], this.state.dateRange[1]]
                    : dateRange;
                await this.setState(
                    {
                        formattedShifts: this.state.formattedShifts.map((entry: any) => {
                            return {
                                ...entry,
                                shiftInDateRange: moment(entry.startDate)
                                    .clone()
                                    .isBetween(
                                        moment(range[0]).toISOString()
                                            ? moment(range[0]).toISOString()
                                            : undefined,
                                        moment(range[1]).toISOString()
                                            ? moment(range[1]).toISOString()
                                            : moment(range[0]).toISOString(),
                                        'days',
                                        '[]',
                                    ),
                            };
                        }),
                    },
                    () => {
                        this._handleShiftsFilter(
                            [
                                {
                                    label: 'shiftInDateRange',
                                    value: true,
                                },
                            ],
                            'shiftInDateRange',
                        );
                    },
                );
            };

            _generateFormattedShifts = (shifts: any) => {
                const formattedShifts = shifts.map((entry: any) => {
                    const holidayEntry = _.includes(
                        (this.props as any).RootStore.holidaysStore.holidayDates,
                        moment(entry.startDate).format('D/M/YYYY'),
                    );
                    const fStatus = filledStatus(this.state.dateRange[0], entry);
                    const trainee = entry.trainees.length
                        ? entry.trainees[0]?.traineeId
                            ? entry.trainees[0]?.traineeId
                            : 'no-trainee-set'
                        : ' ';
                    return {
                        ...entry,
                        defaultEmployeeFilter: entry.defaultEmployeeId
                            ? entry.defaultEmployeeId
                            : ' ',
                        confirmedEmployeeFilter: entry.confirmedEmployeeId
                            ? entry.confirmedEmployeeId
                            : ' ',
                        timeCover:
                            entry.endTime.getHours() >= 12 && entry.startTime.getHours() <= 12
                                ? ['AM', 'PM']
                                : entry.startTime.getHours() < 12
                                ? 'AM'
                                : 'PM',
                        fillStatus: fStatus,
                        contractType: entry.confirmedEmployee?.contractType?.name
                            ? entry.confirmedEmployee?.contractType?.name
                            : entry.defaultEmployee?.contractType?.name
                            ? entry.defaultEmployee?.contractType?.name
                            : null,
                        agency: entry.confirmedEmployee?.agency?.name
                            ? entry.confirmedEmployee?.agency?.name
                            : entry.defaultEmployee?.agency?.name
                            ? entry.defaultEmployee?.agency?.name
                            : null,
                        shiftSource: entry.isAdditional ? 'Manually Created' : 'Shift Pattern',
                        specialDays: holidayEntry,
                        trainee: trainee,
                    };
                });
                this.setState(
                    {
                        formattedShifts: _.orderBy(
                            formattedShifts,
                            ['startDate', 'startTime', 'endTime'],
                            ['desc', 'asc', 'asc'],
                        ),
                        filteredShifts: _.orderBy(
                            formattedShifts,
                            ['startDate', 'startTime', 'endTime'],
                            ['desc', 'asc', 'asc'],
                        ),
                        shifts: _.orderBy(
                            this.state.shifts,
                            ['startDate', 'startTime', 'endTime'],
                            ['desc', 'asc', 'asc'],
                        ),
                    },
                    () => {
                        this._generateFilterOptions();
                    },
                );
            };

            _generateFilterOptions = () => {
                const locationsFromShifts = this._extractShiftsData(
                    'locationId',
                    this.state.locations,
                );
                const rolesFromShifts = this._extractShiftsData('roleId', this.state.roles);

                const functionsIds = [] as any;
                this.state.formattedShifts.forEach((shift: any) => {
                    shift.rotaShiftFunctions.forEach((functionId: string) => {
                        functionsIds.push(functionId);
                    });
                });
                const functionsIdsFiltered = Array.from(new Set(functionsIds));
                const functionsFromShifts = this.state.functions.filter((item: any) =>
                    functionsIdsFiltered.includes(item.value),
                );

                const fundingPoolsFromShifts = this._extractShiftsData(
                    'fundingPoolId',
                    this.state.fundingPools,
                );
                const daysFromShifts = this._extractShiftsData('dayOfWeek', DAYS);

                const payRatesFromShifts = this._extractShiftsData(
                    'payRateId',
                    this.state.payRates,
                );
                const typesFromShifts = this._extractShiftsData('typeId', SHIFT_TYPES_OPTIONS);
                const statusFromShifts = this._extractShiftsData('statusId', SHIFT_STATUS_OPTIONS);
                const defaultStaffsFromShifts = this._extractShiftsData(
                    'defaultEmployeeId',
                    this.state.staffRecords,
                );
                const confirmedStaffsFromShifts = this._extractShiftsData(
                    'confirmedEmployeeFilter',
                    this.state.staffRecords,
                );
                const fillStatusFromShifts = this._extractShiftsData(
                    'fillStatus',
                    SHIFT_FILL_STATUS,
                );

                const contractTypesFromShifts = [
                    ...(new Set(this.state.filterContractTypes.filter((x: any) => !!x)) as any),
                ].map((val: any) => ({
                    value: val,
                    label: val,
                }));
                const traineesFromShifts = [
                    ...(new Set(this.state.filterTrainees.filter((x: any) => !!x)) as any),
                ].map((val: any) => ({
                    value: val,
                    label: (this.props as any).RootStore.staffStore.staffOptions[val],
                }));
                const agenciesFromShifts = [
                    ...(new Set(this.state.filterAgencies.filter((x: any) => !!x)) as any),
                ].map((val: any) => ({
                    value: val,
                    label: val,
                }));
                const shiftSourceFromShifts = [
                    ...(new Set(this.state.filterShiftSource.filter((x: any) => !!x)) as any),
                ].map((val: any) => ({
                    value: val,
                    label: val,
                }));
                const weeksFromShifts = [
                    ...(new Set(this.state.filterWeeks.filter((x: any) => !!x)) as any),
                ].map((val: any) => ({
                    value: val,
                    label: val,
                }));

                const shiftValueMax = Math.max.apply(
                    Math,
                    this.state.formattedShifts.map((item: any) => (item.cost ? item.cost : 1)),
                );

                if (!this.state.loading) {
                    this.setState({
                        locationsFromShifts,
                        rolesFromShifts,
                        functionsFromShifts,
                        fundingPoolsFromShifts,
                        payRatesFromShifts,
                        typesFromShifts,
                        statusFromShifts,
                        defaultStaffsFromShifts,
                        confirmedStaffsFromShifts,
                        daysFromShifts,
                        fillStatusFromShifts,
                        contractTypesFromShifts,
                        agenciesFromShifts,
                        shiftSourceFromShifts,
                        weeksFromShifts,
                        traineesFromShifts,
                        shiftValueMax,
                        shiftValueChosen: [0, shiftValueMax],
                    });
                } else {
                    this.setState({
                        locationsFromShifts,
                        rolesFromShifts,
                        daysFromShifts,
                    });
                }
            };

            // Get unique data from all shifts
            _extractShiftsData = (values: any, item: any) =>
                Array.from(
                    new Set(
                        this.state.formattedShifts.map((shift: any) => {
                            return shift[values];
                        }),
                    ),
                )
                    .filter((x) => x != null && !Array.isArray(x)) //[AM,PM] array check
                    .map((id) => item.find((val: any) => val.value === id))
                    .filter((x) => x !== undefined);

            // Check if selected deps match particular shift
            _checkVal = (shift: any) => {
                const depsEntries = Object.entries(this.state.deps);
                const check = depsEntries.every((dep: any) => {
                    const depName = dep[0];
                    if (depName === 'cost') {
                        return dep[1][0] <= shift[depName] && shift[depName] <= dep[1][1];
                    }
                    return dep[1].some((depInner: any) => {
                        if (Array.isArray(shift[depName])) {
                            return shift[depName].includes(depInner.value);
                        } else {
                            return shift[depName] === depInner.value;
                        }
                    });
                });
                return check;
            };

            _clearFilter = () => {
                this.setState({
                    deps: [],
                    filteredShifts: this.state.formattedShifts,
                    filterDates: [null, null],
                    shiftValueChosen: [0, this.state.shiftValueMax],
                });
            };
            _handleApplyShiftPattern = () => {
                this.setState({
                    showShiftPatternSelector: true,
                });
            };

            // Handle logic after particular select change
            _handleShiftsFilter = async (values: any, item: any) => {
                // Add particular item to dependency
                const deps = { ...this.state.deps, [item]: values };
                // Check if it is cost and is a default value (in that case remove from dep array)
                if (
                    item === 'cost' &&
                    values[0] === this.state.shiftValueMin &&
                    values[1] === this.state.shiftValueMax
                ) {
                    delete deps[item];
                }
                // Update state
                await this.setState(
                    {
                        deps: deps,
                    },
                    () => {
                        // Remove empty item from dependency
                        if (this.state.deps[item] && (this.state.deps[item] as any).length === 0) {
                            delete this.state.deps[item];
                        }
                        // Generate new shifts list after every filter
                        return this._filterShifts();
                    },
                );
            };

            _filterShifts = () => {
                this.setState({
                    filteredShifts: this.state.formattedShifts.filter((shift: any) => {
                        return this._checkVal(shift);
                    }),
                });
            };

            _updateFilterData = async (values: any) => {
                this._generateFormattedShifts(values);
                this._filterShifts();
            };

            _updateFormattedShifts = async (shifts: any) => {
                this._generateFormattedShifts(shifts);
            };

            _handleSliderChange = (range: [number, number]) => {
                this.setState({ shiftValueChosen: range });
                this._handleShiftsFilter(range, 'cost');
            };

            _handleRotaNameChange = (e: any) => {
                this.setState({
                    name: e.target.value,
                });
                (this.props as any).RootStore.rotaBuilderStore.updateChangesState(true);
            };

            render() {
                const {
                    RootStore: {
                        staffStore,
                        holidaysStore,
                        dictionaryStore,
                        userStore,
                        rotaBuilderStore,
                        fundingPoolStore,
                        functionsStore,
                    },
                    history,
                } = this.props as any;

                const {
                    deps,
                    isFormDirty,
                    name,
                    isGridView,
                    isViewMode,
                    isCreatePath,
                    locations,
                    payRates,
                    shiftTypes,
                    shiftStatuses,
                    loading,
                    shifts,
                    filteredShifts,
                    department,
                    isPublished,
                    appliedShiftPatternId,
                    shiftPatternStartWeek,
                    appliedShiftPatternWeeksCount,
                    paymentStatusOptions,
                } = this.state as any;

                const currentUser = userStore.person;

                const shiftFilterByRotaDates = (r: any) => {
                    const startDate = _.clone(this.state.dateRange[0] || new Date());
                    const endDate = _.clone(this.state.dateRange[1] || new Date());

                    startDate.setHours(0, 0, 0);
                    endDate.setHours(0, 0, 1);
                    const shiftDate = r.date ? r.date : r.endTime;
                    return shiftDate && shiftDate >= startDate && shiftDate <= endDate;
                };

                const actionType = (this.props as any).actionType === 'create' ? 'create' : 'edit';

                return (
                    <section
                        className={`rota-builder ${
                            this.state.isGridView ? 'rota-builder--grid' : 'rota-builder--list'
                        } `}
                    >
                        {loading && <Loader />}
                        <div className="rota-builder__content">
                            <BuilderHeader
                                handleRotaNameChange={this._handleRotaNameChange}
                                isPublished={isPublished}
                                name={name}
                                isFormDirty={isFormDirty}
                                history={history}
                                isViewMode={isViewMode}
                                isCreatePath={isCreatePath}
                                handleViewMode={this._handleViewMode}
                                handleSaveRota={this.saveRota}
                                handleSavingRota={this.handleSavingRota}
                                loading={loading}
                            />

                            <div className="rota-builder__inner-content">
                                <div
                                    className={`rota-builder__inner-content-inputs ${
                                        this.state.isViewMode
                                            ? 'rota-builder__inner-content-inputs--edit'
                                            : ''
                                    }`}
                                >
                                    <div className="rota-builder__inner-content-input">
                                        <FormGroup
                                            className="rota-builder__date-range-wrapper"
                                            label="Date range"
                                        >
                                            <DateSelector
                                                dateRange={this.state.dateRange}
                                                onChange={async (val: any) => {
                                                    if (
                                                        !!val[0] &&
                                                        !!val[1] &&
                                                        this.state.dateRange &&
                                                        !!this.state.dateRange[0]
                                                    ) {
                                                        val[0].setHours(0, 0, 0);
                                                        if (
                                                            (this.state as any).appliedShiftPattern
                                                        ) {
                                                            const updatedAt = new Date(
                                                                (this
                                                                    .state as any).appliedShiftPattern.updatedAt,
                                                            );

                                                            if (
                                                                updatedAt >
                                                                this.state.shiftPatternAppliedAt
                                                            ) {
                                                                const { dateRange } = this.state;
                                                                const prevEndDate = !!dateRange[1]
                                                                    ? dateRange[1]
                                                                    : new Date();

                                                                if (
                                                                    prevEndDate !== null &&
                                                                    prevEndDate < val[1]
                                                                ) {
                                                                    this.setState({
                                                                        previousDateRange: this
                                                                            .state.dateRange,
                                                                        extendRotaDialogOpened: true,
                                                                    });
                                                                }
                                                            } else {
                                                                const sp = (department as any).shiftPatterns.find(
                                                                    (r: any) =>
                                                                        r.value ===
                                                                        appliedShiftPatternId,
                                                                );
                                                                const extendedShifts = getShiftsForDateRange(
                                                                    sp,
                                                                    val[0] || new Date(),
                                                                    val[1] || new Date(),
                                                                    payRates,
                                                                    (this.state as any)
                                                                        .shiftPatternStartWeek,
                                                                );

                                                                const newShifts = [
                                                                    ...this.state.shifts.filter(
                                                                        (r: any) => r.isAdditional,
                                                                    ),
                                                                    ...extendedShifts,
                                                                ];
                                                                this.setState({
                                                                    shifts: newShifts,
                                                                });
                                                            }
                                                        }
                                                        this.setState({
                                                            dateRange: val,
                                                        });
                                                    }
                                                }}
                                                disabled={isPublished}
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className="rota-builder__inner-content-input">
                                        <div className="rota-builder__info">
                                            <div>
                                                <h4 className="rota-builder__info-title">
                                                    Cost centre:
                                                </h4>
                                                <p className="rota-builder__info-text">
                                                    {this.state.costCentreName}
                                                </p>
                                            </div>
                                            <div>
                                                <h4 className="rota-builder__info-title">
                                                    Department:
                                                </h4>
                                                <p className="rota-builder__info-text">
                                                    {this.state.departmentName}
                                                </p>
                                            </div>
                                            <div>
                                                <h4 className="rota-builder__info-title">
                                                    Applied shift pattern:
                                                </h4>
                                                <p className="rota-builder__info-text">
                                                    {(this.state
                                                        .shiftPatternSelectorPrefilled as any).label
                                                        ? (this.state
                                                              .shiftPatternSelectorPrefilled as any)
                                                              .label
                                                        : this.state.appliedShiftPattern
                                                        ? (this.state.appliedShiftPattern as any)
                                                              ?.label
                                                        : '-'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={`rota-builder__inner-content-main ${
                                        this.state.isGridView
                                            ? ''
                                            : 'rota-builder__inner-content-main-lv'
                                    }`}
                                >
                                    {!loading && (
                                        <>
                                            <div
                                                className={'rota-builder__inner-content-main-right'}
                                            >
                                                <BuilderFilter
                                                    {...this.state}
                                                    handleSliderChange={this._handleSliderChange}
                                                    handleDatesFilter={this._handleDatesFilter}
                                                    handleDatesChange={
                                                        this._handleFilterDatesChange
                                                    }
                                                    handleShiftsFilter={this._handleShiftsFilter}
                                                    clearFilter={this._clearFilter}
                                                />
                                            </div>

                                            <div
                                                className={'rota-builder__inner-content-main-left'}
                                            >
                                                {!isViewMode && isGridView && (
                                                    <div className={'rota-builder__grid-actions'}>
                                                        <Popover
                                                            enforceFocus={false}
                                                            placement="bottom-start"
                                                            interactionKind="click"
                                                            content={
                                                                <Menu>
                                                                    <MenuItem
                                                                        disabled={
                                                                            !department ||
                                                                            isPublished
                                                                        }
                                                                        onClick={() =>
                                                                            this._handleApplyShiftPattern()
                                                                        }
                                                                        text="Apply shift pattern"
                                                                    />
                                                                    <MenuItem
                                                                        onClick={() =>
                                                                            (this
                                                                                .myRef as any).current.setGridConfirmStaffDialogOpen()
                                                                        }
                                                                        text="Confirm default staff"
                                                                    />
                                                                </Menu>
                                                            }
                                                            renderTarget={({
                                                                isOpen,
                                                                ref,
                                                                ...p
                                                            }) => (
                                                                <Button
                                                                    {...p}
                                                                    active={isOpen}
                                                                    /* TODO elementRef removed in v5 look at consequences of this*/
                                                                    /*elementRef={ref as any}*/
                                                                    rightIcon="chevron-down"
                                                                    text="Actions"
                                                                    className="btn btn--outlined"
                                                                />
                                                            )}
                                                        />
                                                    </div>
                                                )}
                                                <div className="rota-builder__views-variants">
                                                    <TableViewVariants
                                                        isGridView={isGridView}
                                                        handleGridClick={this._handleGridClick}
                                                        handleListClick={this._handleListClick}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <Calendar
                                    loading={loading}
                                    ref={this.myRef}
                                    deps={deps}
                                    isViewMode={isViewMode}
                                    department={department}
                                    staffStore={staffStore}
                                    rotaBuilderStore={rotaBuilderStore}
                                    shifts={shifts.filter(shiftFilterByRotaDates)}
                                    filteredShifts={filteredShifts.filter(shiftFilterByRotaDates)}
                                    updateFilter={this._updateFilterData}
                                    updateFS={this._updateFormattedShifts}
                                    dateRange={this.state.dateRange}
                                    onStaffSearch={this.handleStaffSearch}
                                    applyShiftPattern={this._handleApplyShiftPattern}
                                    locations={locations}
                                    shiftStatuses={shiftStatuses}
                                    shiftTypes={shiftTypes}
                                    holidaysStore={holidaysStore}
                                    dictionaryStore={dictionaryStore}
                                    functionsStore={functionsStore}
                                    fundingPoolStore={fundingPoolStore}
                                    isPublished={isPublished}
                                    paymentStatusOptions={paymentStatusOptions}
                                    onUpdateConflicts={(conflicts: [any], shiftId: string) => {
                                        for (const conf of conflicts) {
                                            const pairedShift = this.state.shifts.find(
                                                (sh: any) => sh.id === conf.rotaShiftId,
                                            );

                                            if (
                                                pairedShift &&
                                                (pairedShift as any).conflicts.filter(
                                                    (c: any) => c.rotaShiftId === shiftId,
                                                ).length === 0
                                            ) {
                                                const conflict = this.getConflict(
                                                    pairedShift,
                                                    this.state.shifts.find(
                                                        (r: any) => r.id === shiftId,
                                                    ),
                                                );

                                                if (conflict) {
                                                    const resConflicts = (pairedShift as any).conflicts.filter(
                                                        (r: any) =>
                                                            r.rotaShiftId !== conflict.rotaShiftId,
                                                    );
                                                    (pairedShift as any).conflicts = [
                                                        ...resConflicts,
                                                        conflict,
                                                    ];
                                                }
                                            }
                                        }
                                    }}
                                    onChange={(shiftEntities: any) => {
                                        const rotaStart = this.state.dateRange[0] || new Date();
                                        const rotaEnd = this.state.dateRange[1] || new Date();

                                        const shiftsOutOfDateRange = this.state.shifts.filter(
                                            (r: any) =>
                                                !moment(r.date).isBetween(
                                                    moment(rotaStart),
                                                    moment(rotaEnd),
                                                    'days',
                                                    '[]',
                                                ),
                                        );

                                        const shifts = [
                                            ...shiftEntities.map((r: any) => ({
                                                ...r,
                                                date: getShiftDate(this.state.dateRange[0], r),
                                            })),
                                            ...shiftsOutOfDateRange,
                                        ];

                                        this.setState(
                                            {
                                                shifts,
                                            },
                                            () => {
                                                this._updateFilterData(this.state.shifts);
                                            },
                                        );
                                    }}
                                    isGridView={this.state.isGridView}
                                    shiftPatternStartWeek={shiftPatternStartWeek}
                                    appliedShiftPatternWeeksCount={appliedShiftPatternWeeksCount}
                                    actionType={actionType}
                                    updateRotaShiftComments={this.updateRotaShiftComments}
                                    getShiftConflicts={this.getShiftConflicts}
                                    currentUser={currentUser}
                                />
                            </div>
                        </div>

                        {!!department && (
                            <ShiftPatternSelector
                                createdFromSp={
                                    (this.props as any).location.state?.editModeOpenedFromSP
                                }
                                shiftPatternSelectorPrefilled={
                                    this.state.shiftPatternSelectorPrefilled
                                }
                                isOpen={this.state.showShiftPatternSelector}
                                shiftPatterns={(department as any).shiftPatterns}
                                departmentId={department.id}
                                title="Apply shift pattern"
                                onClose={() => {
                                    this.setState({ showShiftPatternSelector: false });
                                }}
                                onSubmit={async (item: any, offset: any) => {
                                    this.setState({
                                        appliedShiftPattern: item,
                                        appliedShiftPatternId: item.value,
                                        shiftPatternStartWeek: offset.value,
                                    });

                                    const newShifts = getShiftsForDateRange(
                                        item,
                                        this.state.dateRange[0] || new Date(),
                                        this.state.dateRange[1] || new Date(),
                                        payRates,
                                        offset.value,
                                    );

                                    for (const shift of newShifts) {
                                        shift.date = getShiftDate(this.state.dateRange[0], shift);
                                        shift.startDate = getShiftDate(
                                            this.state.dateRange[0],
                                            {
                                                dayOfWeek: shift.dayOfWeek,
                                                weekNumber: shift.weekNumber,
                                            },
                                            8,
                                            0,
                                        );
                                        shift.endDate = getShiftDate(
                                            this.state.dateRange[0],
                                            {
                                                dayOfWeek: shift.dayOfWeek,
                                                weekNumber: shift.weekNumber,
                                            },
                                            17,
                                            0,
                                        );
                                        if (!shift.overrideValueInPence) {
                                            shift.overrideValueInPence = 0;
                                        }

                                        if (!shift.rotaShiftFunctions) {
                                            shift.rotaShiftFunctions = [];
                                        }

                                        const shiftCalculationDate = {
                                            ...shift,
                                            paymentStatusId: PAYMENT_STATUS_PLANED,
                                        };
                                        delete shiftCalculationDate.date;

                                        shift.cost = await rotaBuilderStore.getRotaShiftCost(
                                            shiftCalculationDate,
                                        );

                                        shift.conflicts = await this.getShiftConflicts(shift);
                                    }

                                    const manualShifts = this.state.shifts.filter(
                                        (r: any) => r.isAdditional,
                                    );

                                    this.setState(
                                        {
                                            shifts: [...manualShifts, ...newShifts],
                                            showShiftPatternSelector: false,
                                        },
                                        () => {
                                            this._generateFormattedShifts(this.state.shifts);
                                        },
                                    );
                                }}
                            />
                        )}
                        <RotaPrompt
                            when={rotaBuilderStore.changesNotSaved}
                            isPublished={isPublished}
                            onPromptExit={() => {
                                this.exitRota();
                            }}
                            onPromptPublish={async () => {
                                await this.saveRota(true);
                                (this.props as any).history.push(`/rota/list/`, {
                                    prevPath: history.location,
                                });
                            }}
                            onPromptSaveDraft={async () => {
                                await this.saveRota(false);
                                (this.props as any).history.push(`/rota/list/`, {
                                    prevPath: history.location,
                                });
                            }}
                        ></RotaPrompt>
                        <ExtendRotaDialog
                            onClose={() => {
                                this.setState({ extendRotaDialogOpened: false });
                            }}
                            isOpen={this.state.extendRotaDialogOpened}
                            onSubmit={(option: string, offset: number) => {
                                if (option === '1') {
                                    const sp = (department as any)?.shiftPatterns?.find(
                                        (r: any) => r.value === appliedShiftPatternId,
                                    );
                                    const extendedShifts = getShiftsForDateRange(
                                        sp,
                                        this.state.dateRange[0] || new Date(),
                                        this.state.dateRange[1] || new Date(),
                                        payRates,
                                        offset,
                                    );

                                    const newShifts = [
                                        ...this.state.shifts.filter((r: any) => r.isAdditional),
                                        ...extendedShifts,
                                    ];
                                    this.setState({ shifts: newShifts });
                                }

                                this.setState({ extendRotaDialogOpened: false });
                            }}
                            weeksCount={(this.state as any).appliedShiftPatternWeeksCount}
                        />
                        <ConfirmationAlert
                            confirmButtonText="Yes"
                            onConfirm={this.confirmSaveRota}
                            onCancel={this.handleClosingAlert}
                            isOpen={this.state.showAlert}
                            icon="archive"
                            title="Confirm publishing rota"
                            description="There are suspended and/or archived staff members in this rota. Are you sure you still want to publish it?"
                        />
                    </section>
                );
            }
        },
    ),
);

export default Builder;
