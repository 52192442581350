import { Button, Checkbox } from '@blueprintjs/core';
import { FC } from 'react';
import { observer } from 'mobx-react';

import { Panel } from '../side';
import { MultiSelectComponent } from '../../../../v2/form/MultiSelect';
import useStores from '../../../../../hook/useStores';
import { IFilterOption } from '../../../../../stores/UCRStore';

interface IProps {
    isShow: boolean;
    closePanel: () => void;
}

const MapFiltersPanel: FC<IProps> = observer(({ isShow, closePanel }) => {
    const {
        RootStore: {
            mapStore: {
                jobFilters,
                setShowAbortedJobs,
                showAbortedJobs,
                showUnassignedJobs,
                setShowUnassignedJobs,
                setJobFilters,
                clearAllFilters,
                setHcpFilters,
                hcpFilters,
            },
            configStore: { bands },
            ucrStore: { hcpTypes },
        },
    } = useStores();

    const onAddJobInput = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = jobFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? jobFilters[name]
                    : [...jobFilters[name], option];
                setJobFilters(name, filters);
            } else {
                setJobFilters(name, [option]);
            }
        } else {
            setJobFilters(name, []);
        }
    };
    const onRemoveJobInput = (name: string, option: IFilterOption): void => {
        setJobFilters(
            name,
            jobFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };
    const onSelectJobInput = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (jobFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            onRemoveJobInput(name, option);
        } else {
            onAddJobInput(name, option, forceSingleOption);
        }
    };
    const onRemoveHcpFilterValue = (name: string, option: IFilterOption): void => {
        setHcpFilters(
            name,
            hcpFilters[name].filter((opt: IFilterOption) => opt.value !== option.value),
        );
    };

    const onAddHcpFilterValue = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (option.value !== '') {
            if (!forceSingleOption) {
                const filters = hcpFilters[name].find(
                    (opt: IFilterOption) => opt.value === option.value,
                )
                    ? hcpFilters[name]
                    : [...hcpFilters[name], option];
                setHcpFilters(name, filters);
            } else {
                setHcpFilters(name, [option]);
            }
        } else {
            setHcpFilters(name, []);
        }
    };
    const onSelectHcpFilterOption = (
        name: string,
        option: IFilterOption,
        forceSingleOption?: boolean,
    ): void => {
        if (hcpFilters[name].some((opt: IFilterOption) => opt.value === option.value)) {
            onRemoveHcpFilterValue(name, option);
        } else {
            onAddHcpFilterValue(name, option, forceSingleOption);
        }
    };

    const onClearAllFilters = (): void => {
        clearAllFilters();
    };

    const jobStatusOptions: IFilterOption[] = [
        {
            label: 'Not started',
            value: 'notStarted',
        },
        {
            label: 'Current',
            value: 'current',
        },
        {
            label: 'Arrived',
            value: 'arrived',
        },
        {
            label: 'Completed',
            value: 'completed',
        },
    ];

    return (
        <Panel title="Filters" side="right" isShow={isShow} closePanel={closePanel}>
            <div className="ucr__side-panel-block ucr__side-panel-block--scrollable">
                <div className="ucr__side-panel-container">
                    <MultiSelectComponent
                        name="jobStatus"
                        className="ucr__filters-panel-filter"
                        optionsSelected={jobFilters.jobStatus}
                        placeholder="Status"
                        options={jobStatusOptions}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectJobInput('jobStatus', option)}
                        onRemove={(option) => onRemoveJobInput('jobStatus', option)}
                        sortOptions={false}
                    />
                    <MultiSelectComponent
                        name="hcpType"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.hcpType}
                        placeholder="HCP Type"
                        options={hcpTypes}
                        optionClassName="v2__form-multi-select-option"
                        onItemSelect={(option) => onSelectHcpFilterOption('hcpType', option)}
                        onRemove={(option) => onSelectHcpFilterOption('hcpType', option)}
                    />
                    <MultiSelectComponent
                        name="band"
                        className="ucr__filters-panel-filter"
                        optionsSelected={hcpFilters.band}
                        placeholder="Band"
                        options={bands}
                        optionClassName="v2__form-multi-select-option v2__form-multi-select-option--big"
                        onItemSelect={(option) => onSelectHcpFilterOption('band', option)}
                        onRemove={(option) => onSelectHcpFilterOption('band', option)}
                    />
                    <Checkbox
                        label="Show unassigned visits"
                        checked={showUnassignedJobs}
                        className="shift-pattern-select-all-checkbox"
                        onChange={(e) => setShowUnassignedJobs(!showUnassignedJobs)}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Checkbox
                        label="Show aborted visits"
                        checked={showAbortedJobs}
                        className="shift-pattern-select-all-checkbox"
                        onChange={(e) => setShowAbortedJobs(!showAbortedJobs)}
                        onClick={(event) => event.stopPropagation()}
                    />

                    <Button
                        className="ucr__filters-clear-filters"
                        text="Clear filters"
                        minimal={true}
                        name="Clear filters"
                        onClick={onClearAllFilters}
                    />
                </div>
            </div>
        </Panel>
    );
});

export default MapFiltersPanel;
