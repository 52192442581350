import { gql } from '@apollo/client';

export const GET_SPECIALITIES_LIST = gql`
    query GetSpecialities(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
    ) {
        getSpecialities(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    updatedAt
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const CREATE_SPECIALITY = gql`
    mutation CreateSpeciality($data: SpecialityEntityInput!) {
        createSpeciality(data: $data) {
            id
        }
    }
`;

export const UPDATE_SPECIALITY = gql`
    mutation UpdateSpeciality($id: String!, $data: SpecialityEntityInput!) {
        updateSpeciality(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const DELETE_SPECIALITY = gql`
    mutation DeleteSpeciality($id: String!) {
        deleteSpeciality(id: $id) {
            id
        }
    }
`;

export const GET_SPECIALITY = gql`
    query GetSpecialityById($id: String!) {
        getSpecialityById(id: $id) {
            id
            name
            createdAt
            updatedAt
        }
    }
`;

export const BULK_DELETE_SPECIALITIES = gql`
    mutation BulkDeleteSpeciality($id: [String!]!) {
        bulkDeleteSpeciality(id: $id) {
            count
        }
    }
`;
