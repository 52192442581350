import React, { useEffect } from 'react';
import { useCallback, useState } from 'react';
import InductionTasksItem from './InductionTasksItem';

interface InductionTasksInterface {
    id: string;
    name: string;
    isDeleted: boolean;
    newId: string;
    orderNumber: number;
}

const InductionTasksContainer = (props: any) => {
    const { items, isEdit, handleOnDelete, handleOnEdit } = props;
    const [tasks, setTasks] = useState(items);
    useEffect(() => {
        const filteredTasks = items.filter((task: InductionTasksInterface) => !task.isDeleted);
        setTasks(filteredTasks);
    }, [items]);

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setTasks((prev: InductionTasksInterface[]) => {
            const dragItem = prev[dragIndex];
            const coppiedTasksArray = [...prev];
            dragItem.orderNumber = hoverIndex;
            const deletedItem = coppiedTasksArray.splice(hoverIndex, 1, dragItem);
            deletedItem[0].orderNumber = dragIndex;
            coppiedTasksArray.splice(dragIndex, 1, deletedItem[0]);
            return coppiedTasksArray;
        });
    }, []);

    const renderTaskItem = useCallback(
        (task: InductionTasksInterface, index: number) => {
            return (
                <InductionTasksItem
                    {...task}
                    isEdit={isEdit}
                    key={task.id}
                    id={task.id}
                    index={index}
                    handleOnDelete={handleOnDelete}
                    handleOnEdit={handleOnEdit}
                    handleMove={moveCard}
                />
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEdit],
    );

    return (
        <>
            <div>
                {tasks
                    .filter((task: InductionTasksInterface) => !task.isDeleted)
                    .map((task: InductionTasksInterface, i: number) => renderTaskItem(task, i))}
            </div>
        </>
    );
};

export default InductionTasksContainer;
