import React, { useCallback, useState, useEffect } from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

const SelectPaymentDialog = (props: any) => {
    const {
        title = 'Select a payment status',
        options,
        activePaymentStatusId,
        onCancel,
        onSubmit,
        submitText = 'Ok',
        isOpen,
    } = props;

    const [selectedOption, setSelectedOption] = useState(activePaymentStatusId);

    useEffect(() => {
        setSelectedOption(activePaymentStatusId);
    }, [activePaymentStatusId]);

    const handleDialogClose = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const handleSubmit = useCallback(() => {
        onSubmit(selectedOption);
    }, [onSubmit, selectedOption]);

    return (
        <Dialog onClose={handleDialogClose} title={title} className="dialog-select" isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <div className="payment-status-options">
                    {options?.map((item: any) => (
                        <label>
                            <input
                                type="radio"
                                id={item.value}
                                name="paymentStatus"
                                value={item.label}
                                checked={item.value === selectedOption ? true : false}
                                onChange={() => setSelectedOption(item.value)}
                            />
                            <span>{item.label}</span>
                        </label>
                    ))}
                </div>
            </div>
            <div className={`${Classes.DIALOG_FOOTER}`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        className="dialog-select__button"
                        intent={Intent.NONE}
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="dialog-select__button"
                        type="submit"
                        intent={Intent.SUCCESS}
                        onClick={handleSubmit}
                    >
                        {submitText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default inject('RootStore')(observer(SelectPaymentDialog));
