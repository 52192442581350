import React from 'react';

const ArchiveStaffConfirmationPopUpText = (
    listOfNotValidRecords: [],
    numberOfSelectedItems: number,
) => {
    if (!listOfNotValidRecords.length) {
        return 'Are you sure you want to archive the selected staff record(s)? This action is irreversible, and all related Department Assignment(s) will be archived as well.';
    }

    if (numberOfSelectedItems === 1) {
        return 'The selected staff record does not have an End Date of Engagement and/or End Date of Department Assignment set, or its end dates are already set for the future. Proceeding with the Archive operation will set the end dates to today’s date.';
    }

    return (
        <div>
            <p>
                The following staff record(s) do not have an End Date of Engagement and/or End Date
                of Department Assignment set, or their end dates are already set for the future:
            </p>
            <ul>
                {listOfNotValidRecords.map((item: any) => (
                    <li>
                        <a href={`/hr/${item.staffType}/${item.id}`} target="blank">
                            {item.role}
                        </a>
                    </li>
                ))}
            </ul>
            <p>
                Proceeding with the Archive operation will set the end dates for affected records to
                today’s date.
            </p>
        </div>
    );
};

export default ArchiveStaffConfirmationPopUpText;
