import React, { useEffect, useState } from 'react';
import { Button, FormGroup, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import CommonPagination from '../CommonHRRotaPagination';
import Select from 'react-select';

const recordStatusOptions = [
    { value: 'All', label: 'All' },
    { value: false, label: 'Active' },
    { value: true, label: 'Archived' },
];

const Navigation = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onSearch,
    addNew,
    editItem,
    archiveItems,
    onFilter,
    selected,
    departmentsStore,
}: any) => {
    const [recordStatus, setRecordStatus] = useState(0);
    const [costCenters, setCostCenters] = useState([] as any);
    const [roles, setRoles] = useState([] as any);
    const [locations, setLocations] = useState([] as any);
    const [costCentresOptions, setCostCentresOptions] = useState([] as any);
    const [rolesOptions, setRolesOptionsOptions] = useState([] as any);
    const [locationsOptions, setLocationsOptionsOptions] = useState([] as any);

    useEffect(() => {
        async function getOptions() {
            await departmentsStore.getAllDepartments();
            const allDepartments = departmentsStore.allDepartments;
            const costCentresOptions = {} as any;
            const rolesOptions = {} as any;
            const locationsOptions = {} as any;
            allDepartments.forEach((item: any) => {
                costCentresOptions[item.costCentre.id] = item.costCentre.name;
                if (item.roles) {
                    item.roles.forEach((role: any) => {
                        rolesOptions[role.employeeRole.id] = role.employeeRole.name;
                    });
                }
                if (item.locations) {
                    item.locations.forEach((loc: any) => {
                        locationsOptions[loc.location.id] = loc.location.name;
                    });
                }
            });
            const normalizedCostCentres = [];
            const normalizedRolesOptions = [];
            const normalizedLocationsOptions = [];
            for (let prop in costCentresOptions) {
                normalizedCostCentres.push({
                    value: prop,
                    label: costCentresOptions[prop],
                });
            }
            for (let prop in rolesOptions) {
                normalizedRolesOptions.push({
                    value: prop,
                    label: rolesOptions[prop],
                });
            }
            for (let prop in locationsOptions) {
                normalizedLocationsOptions.push({
                    value: prop,
                    label: locationsOptions[prop],
                });
            }

            setCostCentresOptions(normalizedCostCentres);
            setRolesOptionsOptions(normalizedRolesOptions);
            setLocationsOptionsOptions(normalizedLocationsOptions);
        }

        getOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const updateFilters = () => {
            const filterParams = {} as any;
            if (recordStatusOptions[recordStatus].value !== 'All')
                filterParams.isArchived = recordStatusOptions[recordStatus].value;

            if (costCenters.length)
                filterParams.costCenters = costCenters.map((item: any) => item.value);
            if (roles.length) filterParams.roles = roles.map((item: any) => item.value);
            if (locations.length)
                filterParams.locationIds = locations.map((item: any) => item.value);
            onFilter(filterParams);
        };

        updateFilters();
    }, [recordStatus, costCenters, locations, roles, onFilter]);

    const onSearchChange = async (inputValue: any) => {
        if (inputValue && inputValue.length >= 3) {
            return onSearch(inputValue);
        } else if (inputValue?.length === 0) {
            return onSearch('');
        } else {
            return () => {};
        }
    };

    const Content = () => (
        <Menu>
            <MenuItem text="Edit" disabled={selected.length !== 1} onClick={editItem} />
            <MenuItem text="Archive" disabled={!selected.length} onClick={archiveItems} />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup
                                label="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Search..."
                                    large
                                />
                            </FormGroup>
                            <FormGroup label="Record Status" className="common-header__filter">
                                <Select
                                    defaultValue={recordStatusOptions[recordStatus]}
                                    options={recordStatusOptions}
                                    onChange={(val: any) => {
                                        const index = recordStatusOptions.findIndex(
                                            (item: any) => item.value === val.value,
                                        );
                                        setRecordStatus(index);
                                    }}
                                    value={recordStatusOptions[recordStatus]}
                                />
                            </FormGroup>
                            <FormGroup label="Cost centres" className="common-header__filter">
                                <Select
                                    options={costCentresOptions}
                                    isMulti={true}
                                    onChange={(val: any) => {
                                        setCostCenters([...val]);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup label="Roles" className="common-header__filter">
                                <Select
                                    options={rolesOptions}
                                    isMulti={true}
                                    onChange={(val: any) => {
                                        setRoles([...val]);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup label="Locations" className="common-header__filter">
                                <Select
                                    options={locationsOptions}
                                    isMulti={true}
                                    onChange={(val: any) => {
                                        setLocations([...val]);
                                    }}
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="departments"
                        />
                    </div>
                    <div className="common-header__buttons">
                        <Button
                            text="New"
                            intent="success"
                            icon="add"
                            onClick={addNew}
                            className="common-header__button common-header__button--add bp5-outlined"
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content />}
                            renderTarget={({ isOpen, ref, ...p }) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    /* TODO elementRef removed in blueprintjs5 check consequences*/
                                    /*elementRef={ref as any}*/
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    className="bp5-outlined"
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default Navigation;
