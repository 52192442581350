import React from 'react';
import TableRowActions from '../../../../../common/TableRowActions';
import moment from 'moment';
import { daysBetweenNegativeIncluded } from '../../../../../modules/helpers/formatData';
import {
    SHIFT_TYPE_UNPAID_ID,
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_BANK_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    SHIFT_TYPE_NON_CONTRACTED_ID,
    SHIFT_TYPE_FLEXI_ID,
    SHIFT_TYPE_EXTRA_ID,
    SHIFT_TYPE_SICK_ID,
} from '../../../../../../constants/rotaConst';
import { calculateParticularDayInWeek } from '../helper';

import '../styles/rota-shift.css';
import '../styles/rota-shift-tooltip.css';
import { Icon, Tooltip } from '@blueprintjs/core';
import { EMPLOYEE_RECORD_STATUS_OPTIONS } from '../../../../../../constants/hrConst';

const Shift = (props: any) => {
    const {
        entry,
        onDutyStationClick,
        disabled,
        locations,
        shiftStatuses,
        onDelete,
        onSplit,
        rotaStartDate,
        shiftTypes,
        staffStore,
        payRatesDictionary,
        rolesDictionary,
        isPublished,
        openPaymentDialog,
        paymentStatusNormalized,
        isViewMode,
    } = props;

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const formatTime = (date: Date) => {
        if (!date) return '00:00';

        const hours = date.getHours() > 9 ? `${date.getHours()}` : `0${date.getHours()}`;
        const minutes = date.getMinutes() > 9 ? `${date.getMinutes()}` : `0${date.getMinutes()}`;

        return `${hours}:${minutes}`;
    };

    const shiftStatus = shiftStatuses.find((rr: any) => entry.statusId === rr.id);
    const shiftType = shiftTypes.find((rr: any) => entry.typeId === rr.id);

    const { allStaffs } = staffStore;
    const staffList = allStaffs.reduce((acc: any, item: any) => {
        acc[item.id] = item.person.firstName + ' ' + item.person.lastName;
        return acc;
    }, {});

    const particularDayDate = calculateParticularDayInWeek(
        rotaStartDate,
        entry.weekNumber,
        entry.dayOfWeek,
    );

    const trainee = entry.trainees[0]?.traineeId ? staffList[entry.trainees[0]?.traineeId] : null;

    const isSupervisorStaff = allStaffs.filter(
        (staff: any) =>
            (staff.isSupervisor &&
                !entry.confirmedEmployeeId &&
                staff.id === entry.defaultEmployeeId) ||
            (staff.isSupervisor && staff.id === entry.confirmedEmployeeId),
    ).length;

    const workStatus = (employee: string) => {
        if (!employee) return null;
        const person = allStaffs.filter((item: any) => item.id === employee);
        if (person && person.length > 0) {
            const statusObj = EMPLOYEE_RECORD_STATUS_OPTIONS.filter(
                (obj) => obj.value === person[0].statusId,
            );
            return statusObj[0].label;
        }
    };
    const defaultEmployeeStatus = workStatus(entry.defaultEmployeeId);
    const defaultEmployeeSuspendedArchived =
        defaultEmployeeStatus === 'Archived' || defaultEmployeeStatus === 'Suspended';
    const defaultEmployeeStatusCssClass = defaultEmployeeSuspendedArchived
        ? 'rota-shift__content-employee--warning'
        : '';
    const confirmedEmployeeStatus = workStatus(entry.confirmedEmployeeId);
    const confirmedEmployeeSuspendedArchived =
        confirmedEmployeeStatus === 'Archived' || confirmedEmployeeStatus === 'Suspended';
    const confirmedEmployeeStatusCssClass = confirmedEmployeeSuspendedArchived
        ? 'rota-shift__content-employee--warning'
        : '';
    const traineeId = entry.trainees[0]?.traineeId;
    const confirmedTraineeStatus = workStatus(traineeId);
    const confirmedTraineeStatusCssClass =
        confirmedTraineeStatus === 'Archived' || confirmedTraineeStatus === 'Suspended'
            ? 'rota-shift__content-employee--warning'
            : '';

    const dt = new Date();

    const dayOfWeek = moment(rotaStartDate).toDate().getDay();

    const startDate = moment(rotaStartDate)
        .add((entry.weekNumber - 1) * 7 + entry.dayOfWeek - dayOfWeek, 'days')
        .toDate();

    const daysBetweenDates = daysBetweenNegativeIncluded(startDate, dt);

    const contractedWarning =
        (entry.typeId === SHIFT_TYPE_CONTRACTED_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
        ((entry.typeId === SHIFT_TYPE_CONTRACTED_ID ||
            entry.typeId === SHIFT_TYPE_EXTRA_ID ||
            entry.typeId === SHIFT_TYPE_FLEXI_ID ||
            entry.typeId === SHIFT_TYPE_SICK_ID ||
            entry.typeId === SHIFT_TYPE_UNPAID_ID ||
            entry.typeId === SHIFT_TYPE_NON_CONTRACTED_ID) &&
            (!entry.confirmedEmployeeId ||
                confirmedEmployeeStatus === 'Archived' ||
                confirmedEmployeeStatus === 'Suspended') &&
            entry.statusId === SHIFT_STATUS_STANDARD_ID);

    const bankWarning =
        (entry.typeId === SHIFT_TYPE_BANK_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
        (entry.typeId === SHIFT_TYPE_BANK_ID &&
            !entry.confirmedEmployeeId &&
            daysBetweenDates < 4 &&
            entry.statusId === SHIFT_STATUS_STANDARD_ID);
    const warning =
        contractedWarning || bankWarning || (entry.conflicts && entry.conflicts.length > 0);

    const requiresAttention = warning && daysBetweenDates < 3;

    return (
        <div
            className={`rota-shift ${!warning && !requiresAttention ? 'rota-shift--success' : ''} ${
                warning && !requiresAttention ? 'rota-shift--warning' : ''
            } ${requiresAttention && warning ? ' rota-shift--error' : ''}

        `}
        >
            <Tooltip
                openOnTargetFocus={false}
                position={'bottom'}
                className="rota-shift-tooltip"
                content={
                    <div className="rota-shift-tooltip__inner">
                        <h4 className="rota-shift-tooltip__title">
                            {!disabled && days[entry.dayOfWeek - 1]}{' '}
                            {!disabled && particularDayDate}
                        </h4>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Location:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {locations.find((rr: any) => entry.locationId === rr.value).label}
                            </p>
                        </div>

                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Role:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {rolesDictionary[entry.roleId]}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Pay rate:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {payRatesDictionary[entry.payRateId]}
                            </p>
                        </div>
                        {entry.defaultEmployeeId && (
                            <div className="rota-shift-tooltip__item">
                                <p className="rota-shift-tooltip__item-key">
                                    Default staff member:
                                </p>
                                {defaultEmployeeSuspendedArchived ? (
                                    <p className="rota-shift-tooltip__item-val rota-shift-tooltip__item-val--warning">
                                        {`${
                                            staffList[entry.defaultEmployeeId]
                                        } (${defaultEmployeeStatus})`}
                                    </p>
                                ) : (
                                    <p className="rota-shift-tooltip__item-val">
                                        {staffList[entry.defaultEmployeeId]}
                                    </p>
                                )}
                            </div>
                        )}

                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Confirmed staff member:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {entry.confirmedEmployeeId ? (
                                    confirmedEmployeeSuspendedArchived ? (
                                        <span className="rota-shift-tooltip__item-val--warning">
                                            {`${
                                                staffList[entry.confirmedEmployeeId]
                                            } (${confirmedEmployeeStatus})`}
                                        </span>
                                    ) : (
                                        `${staffList[entry.confirmedEmployeeId]}`
                                    )
                                ) : (
                                    <span className="rota-shift-tooltip__item--warning">
                                        Unfilled
                                    </span>
                                )}
                            </p>
                        </div>

                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Shift:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {moment(entry.startTime).format('HH:mm')} -{' '}
                                {moment(entry.endTime).format('HH:mm')}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Break duration:</p>
                            <p className="rota-shift-tooltip__item-val">{`${entry.breakDurationMinutes} mins`}</p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Break is paid:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {entry.breaksPaid ? 'Yes' : 'No'}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Shift cost:</p>
                            <p className="rota-shift-tooltip__item-val">
                                &#163; {` ${entry.overrideValueInPence}`}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Is additional:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {entry.isAdditional ? 'Yes' : 'No'}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Status:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {shiftStatus && shiftStatus.name}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Type:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {shiftType && shiftType.name}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Third-party paid:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {entry.thirdPartyPaid ? 'Yes' : 'No'}
                            </p>
                        </div>
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Training shift:</p>
                            <p className="rota-shift-tooltip__item-val">
                                {entry.trainingShift ? 'Yes' : 'No'}
                            </p>
                        </div>
                        {isPublished ? (
                            <div className="rota-shift-tooltip__item">
                                <p className="rota-shift-tooltip__item-key">Payment status:</p>
                                <p className="rota-shift-tooltip__item-val">
                                    {entry.paymentStatusId &&
                                        paymentStatusNormalized[entry.paymentStatusId]}
                                </p>
                            </div>
                        ) : null}
                        <div className="rota-shift-tooltip__item">
                            <p className="rota-shift-tooltip__item-key">Shift value:</p>
                            <p className="rota-shift-tooltip__item-val"> {entry.cost}</p>
                        </div>
                    </div>
                }
                usePortal={true}
            >
                <div
                    className="rota-shift-tooltip__content"
                    onClick={() => {
                        onDutyStationClick({
                            id: entry.id,
                            breakDurationMinutes: entry.breakDurationMinutes,
                            breaksPaid: entry.breaksPaid,
                            dayOfWeek: entry.dayOfWeek,
                            defaultEmployeeId: entry.defaultEmployeeId,
                            confirmedEmployeeId: entry.confirmedEmployeeId,
                            typeId: entry.typeId,
                            statusId: entry.statusId,
                            isAdditional: entry.isAdditional,
                            endTime: entry.endTime,
                            locationId: entry.locationId,
                            overrideValueInPence: entry.overrideValueInPence,
                            roleId: entry.roleId,
                            rotaShiftFunctions: entry.rotaShiftFunctions,
                            patientFacing: entry.patientFacing,
                            fundingPoolId: entry.fundingPoolId,
                            startTime: entry.startTime,
                            weekNumber: entry.weekNumber,
                            payRateId: entry.payRateId,
                            cost: entry.cost,
                            thirdPartyPaid: entry.thirdPartyPaid,
                            trainingShift: entry.trainingShift,
                            trainees: entry.trainees?.map((trainee: any) => ({
                                traineeId: trainee.traineeId,
                                roleId: trainee.roleId,
                                overrideValue: trainee.overrideValue,
                                payRateId: trainee.payRateId,
                            })),
                            comments: entry.comments?.map((comment: any) => ({
                                comment: comment.comment,
                                createdAt: comment.createdAt,
                                createdBy: comment.createdBy,
                                id: comment.id,
                                isDeleted: comment.isDeleted,
                                isEdited: comment.isEdited,
                                updatedAt: comment.updatedAt,
                                createdPerson: comment.createdPerson,
                                isImportant: comment.isImportant,
                            })),
                            shiftWasPublished: entry.hasOwnProperty('__typename'),
                            startDate: entry.startDate,
                            endDate: entry.endDate,
                            conflicts: entry.conflicts,
                            paymentStatusId: entry.paymentStatusId,
                        });
                    }}
                >
                    <div className="rota-shift__content">
                        <div className="rota-shift__content-header">
                            <div className="rota-shift__content-status">
                                {!warning && !requiresAttention && (
                                    <span className="common__status common__status--success" />
                                )}
                                {warning && !requiresAttention && (
                                    <span className="common__status common__status--warning" />
                                )}
                                {requiresAttention && warning && (
                                    <span className="common__status common__status--error" />
                                )}
                            </div>
                            <div className="rota-shift__content-time">
                                {moment(entry.startTime).format('HH:mm')} -
                                {moment(entry.endTime).format('HH:mm')}
                            </div>
                        </div>
                        <div className="rota-shift__content-body">
                            <h5 className={`rota-shift__content-duty-station truncate`}>
                                {rolesDictionary[entry.roleId]}
                            </h5>
                            {entry.defaultEmployeeId && !entry.confirmedEmployeeId && (
                                <div
                                    className={`rota-shift__content-employee ${defaultEmployeeStatusCssClass}`}
                                >
                                    {entry.defaultEmployeeId && staffList[entry.defaultEmployeeId]}
                                    {defaultEmployeeStatus !== 'Active' &&
                                        defaultEmployeeStatus !== 'In progress' &&
                                        defaultEmployeeStatus !== undefined && (
                                            <span>({defaultEmployeeStatus})</span>
                                        )}
                                </div>
                            )}
                            {entry.confirmedEmployeeId && (
                                <div
                                    className={`rota-shift__content-employee ${confirmedEmployeeStatusCssClass}`}
                                >
                                    <Icon icon="tick" />
                                    &nbsp;
                                    {entry.confirmedEmployeeId &&
                                        staffList[entry.confirmedEmployeeId]}
                                    {confirmedEmployeeStatus !== 'Active' &&
                                        confirmedEmployeeStatus !== 'In progress' &&
                                        confirmedEmployeeStatus !== undefined && (
                                            <span> ({confirmedEmployeeStatus})</span>
                                        )}
                                </div>
                            )}
                            <span
                                className={`weeks-table__col-content-subtitle rota-shift__content-trainee-icon ${confirmedTraineeStatusCssClass}`}
                            >
                                {entry.trainingShift && (
                                    <>
                                        <Icon
                                            icon="learning"
                                            intent={isSupervisorStaff ? 'none' : 'danger'}
                                        />
                                        {trainee
                                            ? confirmedTraineeStatus !== 'Active' &&
                                              confirmedTraineeStatus !== 'In progress' &&
                                              confirmedTraineeStatus !== undefined
                                                ? trainee + ` (${confirmedTraineeStatus})`
                                                : trainee
                                            : 'No trainee set'}
                                    </>
                                )}
                            </span>
                            {entry.conflicts && entry.conflicts.length > 0 && (
                                <>
                                    <Icon icon="warning-sign" intent="danger" />
                                    Conflict
                                </>
                            )}
                            {entry.comments?.length > 0 &&
                                entry.comments.some((item: any) => item.isImportant) && (
                                    <Icon
                                        color={'#FF7F00'}
                                        className="rota-shift__content-comments-icon"
                                        icon="envelope"
                                        size={20}
                                    />
                                )}
                        </div>
                    </div>

                    <>
                        {entry.staff &&
                            entry.staff.map((s: any) => (
                                <div>
                                    <b>{`${s.firstName} ${s.lastName}`}</b> <br />
                                    <i>
                                        {formatTime(s.startTime)}-{formatTime(s.endTime)}
                                    </i>
                                </div>
                            ))}
                    </>
                </div>
            </Tooltip>
            {!isViewMode && (
                <div className="rota-shift__actions">
                    <TableRowActions
                        hasDuplicateOption={false}
                        handleDeleteClick={() => onDelete(entry.id)}
                        hasSplitOption={true}
                        handleSplitClick={() => onSplit(entry.id)}
                        isPublished={isPublished}
                        shiftId={entry.id}
                        paymentStatusId={entry.paymentStatusId}
                        openPaymentDialog={openPaymentDialog}
                    />
                </div>
            )}
        </div>
    );
};

export default Shift;
