import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { Row, Col } from 'react-grid-system';
import { Tag, RadioGroup, Radio, HTMLSelect, Button, Icon } from '@blueprintjs/core';

import HcpNameLink from './HcpNameLink';
import Loader from '../../helpers/Loader';
import AppToaster from '../../helpers/Toaster';
import Modal from '../../modal/Modal';
import Withdraw from '../actions/Withdraw';
import MakeCurrent from '../actions/MakeCurrent';
import UndoMakeCurrent from '../actions/UndoMakeCurrent';
import RequiresAttentionHighlight from './RequiresAttentionHighlight';
import {
    formatDisplayDateTime,
    formatDisplayDate,
    getCommaItemsList,
    formatPrice,
    formatFullName,
    formatJobType,
    capitalize,
} from '../../helpers/formatData';

const JobDetails = inject('RootStore')(
    observer(
        class JobDetails extends Component {
            state = {
                loading: false,
            };

            componentDidMount() {
                const { allJobs } = this.props.RootStore.jobsStore;
                when(() => allJobs.length > 0, this._updateStateWithJob);
            }

            _updateStateWithJob = () => {
                const {
                    match: {
                        params: { id },
                    },
                    RootStore: {
                        jobsStore: { allJobs },
                    },
                } = this.props;

                const job = allJobs.find((job) => job.id === id);

                let invoiceable = 'NOT_SET';

                if (job.invoiceable === true) invoiceable = 'YES';
                if (job.invoiceable === false) invoiceable = 'NO';

                this.setState({
                    saving: false,
                    invoiceable,
                    paymentType: job.paymentType || 'NOT_SET',
                });
            };

            handleToggleLoading = (status) => {
                this.setState({
                    loading: status,
                });
            };

            _handleCloseClick = () => {
                if (this.props.location.state && this.props.location.state.closeModal) {
                    return this.props.history.goBack();
                }

                const url = this.props.match.path.startsWith('/scheduled')
                    ? '/scheduled'
                    : `/on-demand/${this.props.match.params.tab}/map`;

                return this.props.history.push(url);
            };

            _renderFooter = (job, username) => {
                if (job.jobStatus === 'COMPLETED' || job.jobStatus === 'CONTROLLER_ABORTED') {
                    return null;
                }
                return (
                    <>
                        <Withdraw
                            job={job}
                            {...this.props}
                            onToggleLoading={this.handleToggleLoading}
                        />
                        {job.jobType === 'shift' && job.jobStatus === 'ACCEPTED' && (
                            <MakeCurrent
                                job={job}
                                {...this.props}
                                onToggleLoading={this.handleToggleLoading}
                            />
                        )}
                        {job.jobType === 'shift' &&
                            job.jobStatus === 'CURRENT' &&
                            job.markedCurrentByController === username && (
                                <UndoMakeCurrent
                                    job={job}
                                    {...this.props}
                                    onToggleLoading={this.handleToggleLoading}
                                />
                            )}
                    </>
                );
            };

            _formatHcpList(hcps, options = {}) {
                return hcps.map((hcp) => {
                    const user = this.props.RootStore.usersStore.users.find(
                        (user) => user.userId === hcp,
                    );
                    return user ? (
                        <HcpNameLink
                            hcpId={user.userId}
                            hcpName={user.userName}
                            closeModal={true}
                            key={user.userId}
                            tag
                            {...this.props}
                            {...options}
                        />
                    ) : null;
                });
            }

            _formatHcpTypes = (hcpTypes) => {
                const {
                    RootStore: { lovsStore },
                } = this.props;

                return hcpTypes
                    .map(
                        (hcpType) => lovsStore.hcpType.find((type) => type.value === hcpType).label,
                    )
                    .join(', ');
            };

            _handleInvoiceableChange = ({ target: { value } }) =>
                this.setState({ invoiceable: value });

            _handlePaymentTypeChange = ({ target: { value } }) =>
                this.setState({ paymentType: value });

            _handleSaveChanges = async () => {
                const {
                    match: {
                        params: { id },
                    },
                    RootStore: {
                        jobsStore: { setJobs },
                    },
                } = this.props;

                const { invoiceable, paymentType } = this.state;

                this.setState({ saving: true });

                const response = await this.props.RootStore.jobsStore.updateJobInvoicing(
                    id,
                    invoiceable,
                    paymentType,
                );

                if (response.successful) {
                    AppToaster.show({
                        message: 'Job successfully updated.',
                        intent: 'success',
                    });
                } else {
                    AppToaster.show({
                        message: 'Unable to update job, please try again.',
                        intent: 'danger',
                    });
                }

                await setJobs();
                this._updateStateWithJob();
            };

            render() {
                if (this.state.loading) {
                    return <Loader />;
                }

                const {
                    match: {
                        params: { id },
                    },
                    RootStore: {
                        jobsStore: { allJobs },
                        configStore: { isFeatureEnabled },
                        userStore: { user },
                    },
                } = this.props;

                const job = allJobs.find((job) => job.id === id);
                const username = user && user.username ? user.username : 'unknown';

                if (!job) {
                    return <Modal title="Job not found" onClose={this._handleCloseClick} />;
                }

                const {
                    systmOneRef,
                    firstName,
                    lastName,
                    jobStatus,
                    jobType,
                    gender,
                    dateOfBirth,
                    age,
                    addressLine1,
                    addressLine2,
                    town,
                    postCode,
                    contactNumber,
                    languagesSpoken,
                    practice,
                    hcpType,
                    speciality,
                    flags,
                    disposition,
                    notes,
                    priceInPence,
                    hidePriceInApp,
                    startDateTime,
                    expiryDateTime,
                    hcpId,
                    hcpAbortedNotes,
                    hcpAbortedReason,
                    blockSize,
                    nominatedHcps,
                    hcpsNotified,
                    shiftType,
                    createDateTime,
                    endDateTime,
                    nhsNumber,
                } = job;

                const { saving, invoiceable, paymentType } = this.state;

                let title = 'Job details';

                if (systmOneRef) {
                    title += `: ${systmOneRef}`;
                }
                if (firstName || lastName) {
                    title += ` - ${formatFullName(firstName, lastName)}`;
                }

                const isFinished = jobStatus === 'COMPLETED' || jobStatus === 'CONTROLLER_ABORTED';

                return (
                    <Modal
                        title={title}
                        onClose={this._handleCloseClick}
                        footer={this._renderFooter(job, username)}
                    >
                        <RequiresAttentionHighlight job={job} />
                        <Row>
                            <Col xl={6}>
                                <dl className="info">
                                    <dt className="info__title">Status</dt>
                                    <dd className="info__definition">
                                        <Tag className={`${jobStatus.toLowerCase()}-bg`}>
                                            {jobStatus}
                                        </Tag>
                                    </dd>
                                    {hcpAbortedReason && (
                                        <>
                                            <dt className="info__title">HCP aborted reason</dt>
                                            <dd className="info__definition">{hcpAbortedReason}</dd>
                                        </>
                                    )}
                                    {hcpAbortedNotes && (
                                        <>
                                            <dt className="info__title">HCP aborted notes</dt>
                                            <dd className="info__definition">{hcpAbortedNotes}</dd>
                                        </>
                                    )}
                                    <dt className="info__title">Job type</dt>
                                    <dd className="info__definition">{formatJobType(jobType)}</dd>
                                    {systmOneRef && (
                                        <>
                                            <dt className="info__title">Reference</dt>
                                            <dd className="info__definition">{systmOneRef}</dd>
                                        </>
                                    )}
                                    {blockSize && (
                                        <>
                                            <dt className="info__title">Block size</dt>
                                            <dd className="info__definition">{blockSize}</dd>
                                        </>
                                    )}
                                    {shiftType && (
                                        <>
                                            <dt className="info__title">Shift type</dt>
                                            <dd className="info__definition">{shiftType}</dd>
                                        </>
                                    )}
                                    {nhsNumber && (
                                        <>
                                            <dt className="info__title">NHS number</dt>
                                            <dd className="info__definition">{nhsNumber}</dd>
                                        </>
                                    )}
                                    {(firstName || lastName) && (
                                        <>
                                            <dt className="info__title">Patient</dt>
                                            <dd className="info__definition">
                                                {formatFullName(firstName, lastName)}
                                            </dd>
                                        </>
                                    )}
                                    {gender && (
                                        <>
                                            <dt className="info__title">Gender</dt>
                                            <dd className="info__definition">
                                                {capitalize(gender)}
                                            </dd>
                                        </>
                                    )}
                                    {dateOfBirth && (
                                        <>
                                            <dt className="info__title">Date of birth</dt>
                                            <dd className="info__definition">
                                                {formatDisplayDate(dateOfBirth)}
                                            </dd>
                                        </>
                                    )}
                                    {age && (
                                        <>
                                            <dt className="info__title">Age</dt>
                                            <dd className="info__definition">{age}</dd>
                                        </>
                                    )}
                                    {addressLine1 && (
                                        <>
                                            <dt className="info__title">Address</dt>
                                            <dd className="info__definition">
                                                {getCommaItemsList([
                                                    addressLine1,
                                                    addressLine2,
                                                    town,
                                                    postCode,
                                                ])}
                                            </dd>
                                        </>
                                    )}
                                    {contactNumber && (
                                        <>
                                            <dt className="info__title">Contact number</dt>
                                            <dd className="info__definition">{contactNumber}</dd>
                                        </>
                                    )}
                                    {practice && (
                                        <>
                                            <dt className="info__title">Practice</dt>
                                            <dd className="info__definition">{practice}</dd>
                                        </>
                                    )}
                                    {languagesSpoken && languagesSpoken.length > 0 && (
                                        <>
                                            <dt className="info__title">Language</dt>
                                            <dd className="info__definition">
                                                {getCommaItemsList(languagesSpoken)}
                                            </dd>
                                        </>
                                    )}
                                    {notes && (
                                        <>
                                            <dt className="info__title">Notes</dt>
                                            <dd className="info__definition">{notes}</dd>
                                        </>
                                    )}
                                </dl>
                                {isFeatureEnabled('invoicing') && isFinished && hcpId && (
                                    <dl className="info">
                                        <dt className="info__title">
                                            {invoiceable === 'NOT_SET' && (
                                                <Icon
                                                    icon="warning-sign"
                                                    className="info__title-icon"
                                                />
                                            )}
                                            Invoiceable
                                        </dt>
                                        <dd className="info__definition">
                                            <RadioGroup
                                                onChange={this._handleInvoiceableChange}
                                                selectedValue={invoiceable}
                                                disabled={saving}
                                                inline
                                            >
                                                <Radio label="Yes" value="YES" />
                                                <Radio label="No" value="NO" />
                                            </RadioGroup>
                                        </dd>
                                        <dt className="info__title">
                                            {paymentType === 'NOT_SET' && (
                                                <Icon
                                                    icon="warning-sign"
                                                    className="info__title-icon"
                                                />
                                            )}
                                            Payment type
                                        </dt>
                                        <dd className="info__definition">
                                            <HTMLSelect
                                                value={paymentType}
                                                onChange={this._handlePaymentTypeChange}
                                                disabled={saving}
                                            >
                                                <option value="NOT_SET">Please choose...</option>
                                                <option value="DIRECT">Direct</option>
                                                <option value="UNDER_CONTRACT">
                                                    Under contract
                                                </option>
                                            </HTMLSelect>
                                        </dd>
                                        <dt className="info__title"></dt>
                                        <dd className="info__definition">
                                            <Button
                                                text="Save changes"
                                                intent="success"
                                                icon="tick"
                                                loading={saving}
                                                onClick={() => this._handleSaveChanges(job)}
                                            />
                                        </dd>
                                    </dl>
                                )}
                            </Col>
                            <Col xl={6}>
                                <dl className="info">
                                    <dt className="info__title">HCP Type</dt>
                                    <dd className="info__definition">
                                        {this._formatHcpTypes(hcpType)}
                                    </dd>
                                    {speciality && speciality.length > 0 && (
                                        <>
                                            <dt className="info__title">Speciality</dt>
                                            <dd className="info__definition">
                                                {getCommaItemsList(speciality)}
                                            </dd>
                                        </>
                                    )}
                                    {flags && flags.length > 0 && (
                                        <>
                                            <dt className="info__title">Flags</dt>
                                            <dd className="info__definition">
                                                {getCommaItemsList(flags)}
                                            </dd>
                                        </>
                                    )}
                                    {disposition && (
                                        <>
                                            <dt className="info__title">Disposition</dt>
                                            <dd className="info__definition">
                                                {capitalize(disposition)}
                                            </dd>
                                        </>
                                    )}
                                    <dt className="info__title">Price</dt>
                                    <dd className="info__definition">
                                        {formatPrice(priceInPence)}
                                    </dd>
                                    {hidePriceInApp != null && (
                                        <>
                                            <dt className="info__title">Hide price in app</dt>
                                            <dd className="info__definition">
                                                {hidePriceInApp ? 'Yes' : 'No'}
                                            </dd>
                                        </>
                                    )}
                                    <dt className="info__title">Created on</dt>
                                    <dd className="info__definition">
                                        {formatDisplayDateTime(createDateTime)}
                                    </dd>
                                    <dt className="info__title">Expiry date/time</dt>
                                    <dd className="info__definition">
                                        {formatDisplayDateTime(expiryDateTime)}
                                    </dd>
                                    {endDateTime && (
                                        <>
                                            <dt className="info__title">Start date/time</dt>
                                            <dd className="info__definition">
                                                {formatDisplayDateTime(startDateTime)}
                                            </dd>
                                            <dt className="info__title">End date/time</dt>
                                            <dd className="info__definition">
                                                {formatDisplayDateTime(endDateTime)}
                                            </dd>
                                        </>
                                    )}
                                    {Array.isArray(hcpsNotified) && (
                                        <>
                                            <dt className="info__title">Notified HCPs</dt>
                                            <dd className="info__definition">
                                                {hcpsNotified.length > 0 ? (
                                                    this._formatHcpList(hcpsNotified, {
                                                        minimal: true,
                                                    })
                                                ) : (
                                                    <span className="job__attention">None</span>
                                                )}
                                            </dd>
                                        </>
                                    )}
                                    {Array.isArray(nominatedHcps) && nominatedHcps.length > 0 && (
                                        <>
                                            <dt className="info__title">Nominated HCPs</dt>
                                            <dd className="info__definition">
                                                {this._formatHcpList(nominatedHcps, {
                                                    minimal: true,
                                                })}
                                            </dd>
                                        </>
                                    )}
                                    {hcpId && (
                                        <>
                                            <dt className="info__title">Assigned HCP</dt>
                                            <dd className="info__definition">
                                                {this._formatHcpList([hcpId])}
                                            </dd>
                                        </>
                                    )}
                                </dl>
                            </Col>
                        </Row>
                    </Modal>
                );
            }
        },
    ),
);

export default JobDetails;
