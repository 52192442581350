import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Icon } from '@blueprintjs/core';
import { Link, Switch, Route, Redirect } from 'react-router-dom';

import JobForm from '../../modules/jobs/add/JobForm';
import EditJob from '../../modules/jobs/add/EditJob';
import JobDetails from '../../modules/jobs/detail/JobDetails';
import Calendar from '../../modules/jobs/list/Calendar';
import Stats from '../../modules/jobs/list/Stats';

const Schedule = inject('RootStore')(
    observer(
        class Schedule extends Component {
            render() {
                const {
                    RootStore: {
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('scheduledJobs')) {
                    return <Redirect to="/page-not-found" />;
                }

                return (
                    <div className="scheduled">
                        <div className="scheduled__tools">
                            <Link
                                to={{
                                    pathname: `/scheduled/jobs/add`,
                                    state: { closeModal: true },
                                }}
                                className="bp5-button bp5-large bp5-intent-success bp5-fill"
                            >
                                <Icon icon="add" />
                                <span className="bp5-button-text">Add a scheduled job</span>
                            </Link>
                            <Stats />
                        </div>
                        <div className="scheduled__content">
                            <Switch>
                                <Redirect exact from="/scheduled" to="/scheduled/calendar/month" />
                                <Redirect
                                    exact
                                    from="/scheduled/calendar"
                                    to="/scheduled/calendar/month"
                                />
                                <Route exact path="/scheduled/jobs/add">
                                    <JobForm
                                        title="Add a scheduled job"
                                        actionType="create"
                                        {...this.props}
                                    />
                                </Route>
                                <Route
                                    exact
                                    path="/scheduled/jobs/:id/series/edit"
                                    component={(props) => <EditJob isSeries={true} {...props} />}
                                />
                                <Route
                                    exact
                                    path="/scheduled/jobs/:id/edit"
                                    component={(props) => <EditJob isSeries={false} {...props} />}
                                />
                                <Route exact path="/scheduled/jobs/:id" component={JobDetails} />
                                <Route
                                    exact
                                    path="/scheduled/calendar/:view"
                                    component={Calendar}
                                />
                            </Switch>
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default Schedule;
