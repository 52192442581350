import { FC } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Vaccination, VaccinationCategory } from '@doc-abode/data-models';

import { formatDisplayDate, formatPractice } from '../../../../modules/helpers/formatData';

interface Props {
    id: string;
    firstName?: string;
    lastName?: string;
    nhsNumber?: string;
    dateOfBirth?: string;
    vaccinationCategory?: string;
    practice?: string;
    odsCode?: string;
    openInNewWindow?: boolean;
    modalRef?: any;
}

export const ItineraryItemSummary: FC<Props> = ({
    id,
    firstName,
    lastName,
    nhsNumber,
    dateOfBirth,
    vaccinationCategory,
    practice,
    odsCode,
    openInNewWindow,
    modalRef,
}) => {
    const history = useHistory();

    return (
        <>
            {`${firstName} ${lastName} (${nhsNumber}, ${formatDisplayDate(dateOfBirth)}) for`}{' '}
            <Link
                to={() => ({
                    pathname: `/vaccinations/patients/${id}`,
                    state: {
                        closeModal: true,
                        previousLocation: history.location.pathname,
                        scrollTop: modalRef?.current?.scrollTop,
                    },
                })}
                target={openInNewWindow ? '_blank' : '_self'}
                data-testid={`instruction-patient-link-${id}`}
            >
                {Vaccination.getFriendlyVaccinationCategory(
                    vaccinationCategory as VaccinationCategory,
                )}
            </Link>{' '}
            - {formatPractice(practice, odsCode)}
        </>
    );
};
