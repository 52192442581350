import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import _ from 'lodash';

import {
    CREATE_STAFF_RECORD,
    DELETE_STAFF_RECORD,
    IS_DEPARTMENT_IN_USE,
    GET_STAFF_RECORD,
    GET_STAFF_RECORD_OPTIONS,
    GET_STAFF_RECORDS,
    UPDATE_STAFF_RECORD,
    GET_COUNTRIES_LIST,
    UPDATE_EMPLOYEE_STATUS,
    GET_TUPE_ORGANISATIONS,
    VALIDATE_EMPLOYEE_RECORDS_ARCHIVATION,
    ARCHIVE_EMPLOYEE_RECORDS,
} from '../graphql/queries/staffRecords';
import { GET_PEOPLE } from '../graphql/queries/people';

import AppToaster from '../components/modules/helpers/Toaster';
import {
    PAGE_SIZE,
    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
    PERSON_ACTIVE_STATUS,
} from '../constants/hrConst';
import { roundToTwoDecimals } from '../components/modules/helpers/utils';
import { PagedStore, SortInfo } from './PagedStore';

import type { ApolloClient, ApolloQueryResult, NormalizedCacheObject } from '@apollo/client';
import type {
    GetStaffRecords,
    GetStaffRecords_getEmployees_edges_node as StaffRecord,
} from '../__generated__/GetStaffRecords';
import type { GetPeople } from '../__generated__/GetPeople';
import { formatDateWithTime } from '../components/modules/helpers/formatData';

export type NormalizedStaff = {
    id: string;
    selected: boolean;
    email: string;
    agencyId: string;
    statusId: string;
    employeeStatus: any;
    roles: string[];
    roleName: string[];
    groups: string[];
    firstName: string;
    middleName: string | null;
    lastName: string;
    specialities: string[];
    departments: any;
    contractedWorkingHours: number | null;
    mondayContractedHours: null | number;
    tuesdayContractedHours: null | number;
    wednesdayContractedHours: null | number;
    thursdayContractedHours: null | number;
    fridayContractedHours: null | number;
    saturdayContractedHours: null | number;
    sundayContractedHours: null | number;
    startedWorkOn: Date | null;
    endedWorkOn: Date | null;
    personId: string;
    contactNumber: string;
    contractTypeId: string;
    typeOfRegistration: string;
    companyRegistrationNumber: string;
    registeredCompanyName: string;
    countryOfRegistration: CountryOfRegistration;
    countryOfRegistrationId: string;
    registeredCompanyAddressLine1: string;
    registeredCompanyAddressLine2: string;
    registeredCompanyAddressLine3: string;
    registeredCompanyAddressLine4: string;
    registeredCompanyAddressCity: string;
    registeredCompanyAddressPostcode: string;
    registeredCompanyAddressCountry: string;
    vatRegistrationNumber: string;
    notificationPreference: string;
    gpPractice: string | null;
    holidayAllowance: number | null;
    holidayTaken: number | null;
    workHoursTotal: number | null;
    sickOffTotal: number | null;
    holidayPending: number | null;
    isCompliant: Boolean;
    isSupervisor: Boolean;
    updatedAt: Date | string | null;
    createdAt: Date | string | null;
    createdByPerson: any;
    updatedByPerson: any;
    isTupe: boolean;
    tupeOrganisationName: string | null;
    patientFacing: boolean;
};

export type PayRate = {
    payRateId: string;
    startDate: string;
    endDate: string | null;
};

export type CountryOfRegistration = {
    // id: string;
    name: string;
};

export type RegisteredCompanyAddress = {
    line1: string;
    line2: string;
    line3: string;
    line4: string;
    city: string;
    postcode: string;
    country: string;
};

export type RotaShiftPayRateInput = {
    end: string;
    payRateId: string;
    start: string;
};

export type ShiftInput = {
    id?: string;
    breakDurationMinutes: number;
    breakIsPaid: boolean;
    dayOfWeek: number;
    employeeId: string;
    endTime: string;
    locationId: string;
    overrideValueInPence: number;
    roleId: string;
    rotaShiftFunctions: string[];
    fundingPoolId: string;
    startTime: string;
    weekNumber: number;
    rotaShiftPayRates: [RotaShiftPayRateInput];
};

export type RotaInput = {
    id?: string;
    name: string;
    startDate: Date;
    endDate: Date;
    shifts: [ShiftInput];
};

class StaffStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            staffs: observable,
            staffsByPayRate: observable,
            staffsByRole: observable,
            staffOptions: observable,
            allStaffs: observable,
            staffsByDepartment: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            apolloClient: observable,
            gridStaffs: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getStaffs: action,
            getAllStaffs: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    currentGetTask: Promise<ApolloQueryResult<any>> | null = null;
    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    staffs: StaffRecord[] = [];
    staffsByPayRate: any | StaffRecord[] = [];
    staffsByRole: any | StaffRecord[] = [];
    staffsByAgency: any | StaffRecord[] = [];
    allStaffs: StaffRecord[] = [];
    staffOptions: any;
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];
    fetchInterval: NodeJS.Timeout | null = null;
    staffsByDepartment: any | StaffRecord[] = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeStaff = (staff: StaffRecord): NormalizedStaff => {
        return {
            id: staff.id,
            selected: this.selected.includes(staff.id),
            email: staff.email ?? '',
            departments: staff?.departments,
            roles: staff.roles.flatMap((roleEl) =>
                roleEl?.employeeRole.id ? [roleEl.employeeRole.id] : [],
            ),
            roleName: staff.roles.flatMap((roleEl) =>
                roleEl?.employeeRole.id ? roleEl.employeeRole.name : '',
            ),
            groups: staff.groups.flatMap((groupEl) =>
                groupEl?.group.id ? [groupEl?.group.id] : [],
            ),
            firstName: staff.person.firstName,
            middleName: staff.person.middleName,
            lastName: staff.person.lastName,
            specialities: staff.specialities.flatMap((item) =>
                item?.speciality.id ? [item?.speciality.id] : [],
            ),
            contractedWorkingHours: staff.contractedWorkingHours ?? 0,
            mondayContractedHours: staff.mondayContractedHours ?? 0,
            tuesdayContractedHours: staff.tuesdayContractedHours ?? 0,
            wednesdayContractedHours: staff.wednesdayContractedHours ?? 0,
            thursdayContractedHours: staff.thursdayContractedHours ?? 0,
            fridayContractedHours: staff.fridayContractedHours ?? 0,
            saturdayContractedHours: staff.saturdayContractedHours ?? 0,
            sundayContractedHours: staff.sundayContractedHours ?? 0,
            startedWorkOn: staff.startedWorkOn?.length
                ? new Date(staff.startedWorkOn.slice(0, -1))
                : null,
            endedWorkOn: staff.endedWorkOn?.length
                ? new Date(staff.endedWorkOn.slice(0, -1))
                : null,
            personId: staff.person.id,
            contactNumber: staff.phone ?? '',
            agencyId: staff.agencyId ?? '',
            statusId: staff.statusId ?? '',
            employeeStatus: staff.employeeStatus.name ?? '',
            contractTypeId: staff.contractTypeId ?? '',
            typeOfRegistration: staff.typeOfRegistration ?? '',
            countryOfRegistration: { name: staff.countryOfRegistration?.name || '' },
            countryOfRegistrationId: staff.countryOfRegistration
                ? staff.countryOfRegistration.id
                : '',
            companyRegistrationNumber: staff.companyRegistrationNumber ?? '',
            registeredCompanyName: staff.registeredCompanyName ?? '',
            vatRegistrationNumber: staff.vatRegistrationNumber ?? '',
            registeredCompanyAddressLine1: staff.registeredCompanyAddressLine1 ?? '',
            registeredCompanyAddressLine2: staff.registeredCompanyAddressLine2 ?? '',
            registeredCompanyAddressLine3: staff.registeredCompanyAddressLine3 ?? '',
            registeredCompanyAddressLine4: staff.registeredCompanyAddressLine4 ?? '',
            registeredCompanyAddressCity: staff.registeredCompanyAddressCity ?? '',
            registeredCompanyAddressPostcode: staff.registeredCompanyAddressPostcode ?? '',
            registeredCompanyAddressCountry: staff.registeredCompanyAddressCountry ?? '',
            notificationPreference: staff.notificationType?.id ?? '',
            gpPractice: staff.gpPracticeOod,
            holidayAllowance: staff.holidayAllowance
                ? roundToTwoDecimals(staff.holidayAllowance / 24)
                : 0,
            holidayTaken: staff.holidayTaken ? roundToTwoDecimals(staff.holidayTaken / 24) : 0,
            holidayPending: staff.holidayPending
                ? roundToTwoDecimals(staff.holidayPending / 24)
                : 0,
            sickOffTotal: staff.sickOffTotal ? roundToTwoDecimals(staff.sickOffTotal / 24) : 0,
            workHoursTotal: staff.workHoursTotal
                ? roundToTwoDecimals(staff.workHoursTotal / 24)
                : 0,
            isCompliant: staff.isCompliant,
            isSupervisor: staff.isSupervisor,
            updatedAt: formatDateWithTime(staff.updatedAt),
            createdAt: formatDateWithTime(staff.createdAt),
            createdByPerson: staff.createdByPerson,
            updatedByPerson: staff.updatedByPerson,
            isTupe: staff.isTupe,
            tupeOrganisationName: staff.tupeOrganisationName,
            patientFacing: staff.patientFacing,
        };
    };

    normalizeStaffAgencyAndContractType = (staff: any) => {
        return {
            agencyId: staff.agencyId ?? '',
            contractTypeId: staff.contractTypeId ?? '',
            person: staff.person ?? '',
        };
    };

    denormalizeStaff = (staff: NormalizedStaff) => ({
        personId: staff.personId,
        contractTypeId: _.isEmpty(staff.contractTypeId) ? null : staff.contractTypeId,
        typeOfRegistration: staff.typeOfRegistration,
        countryOfRegistrationId: staff.countryOfRegistrationId
            ? staff.countryOfRegistrationId
            : null,
        companyRegistrationNumber: staff.companyRegistrationNumber,
        registeredCompanyName: staff.registeredCompanyName,
        vatRegistrationNumber: staff.vatRegistrationNumber,
        registeredCompanyAddressLine1: staff.registeredCompanyAddressLine1,
        registeredCompanyAddressLine2: staff.registeredCompanyAddressLine2,
        registeredCompanyAddressLine3: staff.registeredCompanyAddressLine3,
        registeredCompanyAddressLine4: staff.registeredCompanyAddressLine4,
        registeredCompanyAddressCity: staff.registeredCompanyAddressCity,
        registeredCompanyAddressPostcode: staff.registeredCompanyAddressPostcode,
        registeredCompanyAddressCountry: staff.registeredCompanyAddressCountry,
        startedWorkOn: staff.startedWorkOn ? moment(staff.startedWorkOn).utc(true) : null,
        endedWorkOn: staff.endedWorkOn ? moment(staff.endedWorkOn).utc(true) : null,
        contractedWorkingHours: staff.contractedWorkingHours,
        mondayContractedHours: Number(staff.mondayContractedHours) ?? 0,
        tuesdayContractedHours: Number(staff.tuesdayContractedHours) ?? 0,
        wednesdayContractedHours: Number(staff.wednesdayContractedHours) ?? 0,
        thursdayContractedHours: Number(staff.thursdayContractedHours) ?? 0,
        fridayContractedHours: Number(staff.fridayContractedHours) ?? 0,
        saturdayContractedHours: Number(staff.saturdayContractedHours) ?? 0,
        sundayContractedHours: Number(staff.sundayContractedHours) ?? 0,
        groups: staff.groups,
        roles: staff.roles,
        specialities: staff.specialities,
        email: staff.email,
        agencyId: _.isEmpty(staff.agencyId) ? null : staff.agencyId,
        statusId: staff.statusId,
        gpPracticeOod: _.isEmpty(staff.gpPractice) ? null : staff.gpPractice,
        phone: staff.contactNumber,
        notificationTypeId: _.isEmpty(staff.notificationPreference)
            ? null
            : staff.notificationPreference,
        holidayAllowance: staff.holidayAllowance ? staff.holidayAllowance * 24 : 0,
        isCompliant: staff.isCompliant,
        isSupervisor: staff.isSupervisor,
        isTupe: staff.isTupe,
        tupeOrganisationName: staff.tupeOrganisationName,
        patientFacing: staff.patientFacing,
    });

    async createStaff(staff: NormalizedStaff) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_STAFF_RECORD,
                variables: {
                    data: this.denormalizeStaff(staff),
                },
            });
            AppToaster.show({
                message: 'Staff created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating staff record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateStaff(staff: NormalizedStaff) {
        try {
            const updatedData = await this.apolloClient.mutate({
                mutation: UPDATE_STAFF_RECORD,
                variables: {
                    id: staff.id,
                    data: this.denormalizeStaff(staff),
                },
            });
            const indexOfChangedItem = this.staffs
                .map((el) => el.id)
                .indexOf(updatedData.data.updateEmployee.id);
            this.staffs.splice(indexOfChangedItem, 1, updatedData.data.updateEmployee);
            AppToaster.show({
                message: 'Staff updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating staff record. Please try again.',
                intent: 'danger',
            });
        }
        await this.getStaffs();
    }

    async updateEmployeeStatus(employeeId: string, statusId: string) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_EMPLOYEE_STATUS,
                variables: {
                    employeeId,
                    statusId,
                },
            });
            AppToaster.show({
                message:
                    statusId === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID
                        ? 'Staff record has been successfully archived'
                        : 'Staff updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating staff record. Please try again.',
                intent: 'danger',
            });
        }
    }

    async deleteStaff(staff: NormalizedStaff) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_STAFF_RECORD,
                variables: {
                    id: staff.id,
                },
            });
            AppToaster.show({
                message: 'Staff deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting staff record. Please try again.',
                intent: 'danger',
            });
        }
        await this.getStaffs();
    }

    async loadPersonsOptions(inputValue: string, filterUserByStatuses = []) {
        const result = await this.apolloClient.query({
            query: GET_PEOPLE,
            variables: {
                query: inputValue,
                orderBy: 'desc',
                field: '',
                filter: {
                    statusId: filterUserByStatuses.length
                        ? filterUserByStatuses
                        : [PERSON_ACTIVE_STATUS],
                },
            },
        });

        const { data }: { data: GetPeople } = result;

        if (!data?.getPersons?.edges) {
            return [];
        }

        return data.getPersons.edges.map((person) => {
            const roles = new Set() as any;
            const departments = new Set() as any;

            person?.node?.employees.length &&
                person?.node?.employees.forEach((employee: any) => {
                    // Add roles to the Set
                    employee.roles.length &&
                        employee.statusId !== EMPLOYEE_RECORD_ARCHIVED_STATUS_ID &&
                        roles.add(employee.roles[0].employeeRole.name);
                    // Add departments to the Set
                    employee.departments.length &&
                        employee.departments.forEach((dep: any) => {
                            !dep.isArchived && departments.add(dep?.department?.name);
                        });
                });

            return {
                value: person.node.id,
                label: `${person.node.firstName} ${person.node.lastName} ${
                    !!person.node.middleName ? person.node.middleName : ''
                } ${!!person.node.personalEmail ? person.node.personalEmail : ''}`,
                phone: person.node?.personalPhone,
                email: person.node?.personalEmail,
                statusId: person.node?.statusId,
                roles: [...roles],
                departments: [...departments],
            };
        });
    }

    getStaffRecordById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeById: data },
        } = await this.apolloClient.query({
            query: GET_STAFF_RECORD,
            variables: {
                id,
            },
        });
        return this.normalizeStaff(data);
    };

    getStaffRecordAgencyAndContractTypeById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeById: data },
        } = await this.apolloClient.query({
            query: GET_STAFF_RECORD,
            variables: {
                id,
            },
        });

        return this.normalizeStaffAgencyAndContractType(data);
    };

    get gridStaffs() {
        const staffs = this.staffs.map(this.normalizeStaff);
        if (this.sortFn) {
            staffs.sort(this.sortFn);
        }
        return staffs;
    }

    select = (employeeId: string) => {
        if (!this.selected.includes(employeeId)) {
            this.selected.push(employeeId);
        }
    };

    deselect = (employeeId: string) => {
        const index = this.selected.indexOf(employeeId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getStaffs();
    };

    refreshPage = () => {
        this.loaded = false;
        this.getStaffs();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getStaffs();
    };

    onSort = (sortInfo: SortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getStaffs();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                groups: null,
                roles: null,
            });
        });
        this.selected = [];
        await this.getStaffs();
    };

    getStaffs = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    before: this.pageInfo.currentStartCursor,
                    after: this.pageInfo.currentEndCursor,
                    first:
                        this.pageInfo.currentEndCursor ||
                        (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                            ? PAGE_SIZE
                            : null,
                    last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? 'endedWorkOn',
                    filter: this.pageInfo.filter,
                    query: this.pageInfo.searchText,
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.staffs = data.edges?.map((x) => x.node) || [];
                    this.pageInfo.totalCount = data.totalCount;
                    this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
                    this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
                    this.pageInfo.startCursor = data.pageInfo.startCursor;
                    this.pageInfo.endCursor = data.pageInfo.endCursor;
                    this.loaded = true;
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    getStaffsByPayRate = async (payRateId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? '',
                    filter: { payRateId },
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.staffsByPayRate =
                        data.edges
                            ?.map((x: any) => {
                                return {
                                    id: x.node.id,
                                    firstName: x.node.person.firstName,
                                    middleName: x.node.person.middleName,
                                    lastName: x.node.person.lastName,
                                    department: x.node.departments.flatMap((department: any) =>
                                        department?.departmentId ? department.name : [],
                                    ),
                                    jobTitle: x.node.jobTitle,
                                    roles: x.node.roles?.flatMap((roleEl: any) =>
                                        roleEl?.employeeRole.id ? roleEl.employeeRole.name : [],
                                    ),
                                    employeeStatus: x.node.employeeStatus.name,
                                };
                            })
                            .filter((staff: any) => staff.employeeStatus !== 'Archived') || [];
                    this.staffsByPayRate = _.orderBy(this.staffsByPayRate, ['firstName'], ['asc']);
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    getStaffsByRole = async (roleId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? '',
                    filter: { roles: [roleId] },
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.staffsByRole =
                        data.edges
                            ?.map((x: any) => {
                                return {
                                    id: x.node.id,
                                    firstName: x.node.person.firstName,
                                    middleName: x.node.person.middleName,
                                    lastName: x.node.person.lastName,
                                    departments: x.node.departments.flatMap((departmentEl: any) =>
                                        departmentEl?.departmentId
                                            ? departmentEl?.department?.name
                                            : [],
                                    ),
                                    costCentres: x.node.departments.flatMap((d: any) =>
                                        d?.department.costCentre?.name
                                            ? d.department?.costCentre?.name
                                            : '',
                                    ),
                                    roles: x.node.roles?.flatMap((roleEl: any) =>
                                        roleEl?.employeeRole.id ? roleEl.employeeRole?.name : [],
                                    ),
                                    employeeStatus: x.node.employeeStatus.name,
                                };
                            })
                            .filter((staff: any) => staff.employeeStatus !== 'Archived') || [];
                    this.staffsByRole = _.orderBy(this.staffsByRole, ['firstName'], ['asc']);
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    getStaffsByAgency = async (agencyId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? '',
                    filter: { agencyId },
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.staffsByAgency =
                        data.edges
                            ?.map((x: any) => {
                                return {
                                    id: x.node.id,
                                    firstName: x.node.person.firstName,
                                    middleName: x.node.person.middleName,
                                    lastName: x.node.person.lastName,
                                    departments: x.node.departments.flatMap((department: any) =>
                                        department?.departmentId ? department.name : [],
                                    ),
                                    costCentres: x.node.departments.flatMap((d: any) =>
                                        d?.department.costCentre?.name
                                            ? d.department?.costCentre?.name
                                            : '',
                                    ),
                                    roles: x.node.roles?.flatMap((roleEl: any) =>
                                        roleEl?.employeeRole.id ? roleEl.employeeRole.name : [],
                                    ),
                                    employeeStatus: x.node.employeeStatus.name,
                                };
                            })
                            .filter((staff: any) => staff.employeeStatus !== 'Archived') || [];
                    this.staffsByAgency = _.orderBy(this.staffsByAgency, ['firstName'], ['asc']);
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    getAllStaffs = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? '',
                    filter: this.pageInfo.filter,
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.allStaffs = data.edges?.map((x) => x.node) || [];
                    this.staffOptions = this.allStaffs?.reduce((acc: any, item) => {
                        acc[item.id] = item.person.firstName + ' ' + item.person.lastName;
                        return acc;
                    }, {});
                    this.pageInfo.totalCount = data.totalCount;
                    this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
                    this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
                    this.pageInfo.startCursor = data.pageInfo.startCursor;
                    this.pageInfo.endCursor = data.pageInfo.endCursor;
                    this.loaded = true;
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    get allSelected() {
        return this.staffs.every(
            (r: any) =>
                (r.statusId === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID && this.selected.length) ||
                this.selected.includes(r.id),
        );
    }

    selectAll = () => {
        runInAction(() => {
            this.gridStaffs.forEach((employee) => {
                if (employee.statusId !== EMPLOYEE_RECORD_ARCHIVED_STATUS_ID) {
                    this.select(employee.id);
                }
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    isDepartmentInUse = async (departmentId: string, staffId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }

        const result = await this.apolloClient.query({
            query: IS_DEPARTMENT_IN_USE,
            variables: {
                staffId,
                departmentId,
            },
        });

        return result.data.isDepartmentInUse;
    };

    getStaffRecords = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_STAFF_RECORD_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'person.firstName',
                query: query || '',
            },
        });
    };

    getStaffsByDepartment = async (departmentId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        const activeStatus = 'f4717860-2a2e-40bc-b9db-71d67972fc6b';
        const inProgressStatus = '4d826365-bf81-4579-af17-722347def18d';
        const suspendedStatus = '8613093d-ec25-4e0f-99c3-10e8405997d3';
        try {
            const getTask = this.apolloClient.query({
                query: GET_STAFF_RECORDS,
                variables: {
                    orderBy: this.pageInfo.sortBy ?? 'desc',
                    field: this.pageInfo.sortColumn ?? '',
                    filter: {
                        departmentId,
                        statusId: [activeStatus, inProgressStatus, suspendedStatus],
                    },
                },
            });
            this.currentGetTask = getTask;
            const {
                data: { getEmployees: data },
            }: { data: GetStaffRecords } = await getTask;
            if (this.currentGetTask === getTask) {
                runInAction(() => {
                    this.staffsByDepartment =
                        data.edges?.map((x: any) => {
                            return {
                                id: x.node.id,
                                firstName: x.node.person.firstName,
                                middleName: x.node.person.middleName,
                                lastName: x.node.person.lastName,
                                departments: x.node.departments.flatMap((department: any) =>
                                    department?.department.name ? department?.department.name : [],
                                ),
                                costCentres: x.node.departments.flatMap((d: any) =>
                                    d?.department.costCentre?.name
                                        ? d.department?.costCentre?.name
                                        : '',
                                ),
                                jobTitle: x.node.departments.flatMap((department: any) =>
                                    department?.jobTitle ? department?.jobTitle : [],
                                ),
                                roles: x.node.roles.flatMap((role: any) =>
                                    role?.employeeRole.id ? role.employeeRole.name : [],
                                ),
                                employeeStatus: x.node.employeeStatus.name,
                            };
                        }) || [];
                    this.staffsByDepartment = _.orderBy(
                        this.staffsByDepartment,
                        ['firstName'],
                        ['asc'],
                    );
                });
            }
        } catch (err) {
            console.error(err);
            this.loaded = true;
            AppToaster.show({
                message:
                    'There was a problem getting the latest staff information. Please reload the page.',
                intent: 'danger',
                timeout: 0,
                action: {
                    onClick: () => window.location.reload(),
                    text: 'Reload',
                },
            });
        }
    };

    getCountries = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getCountries },
        } = await this.apolloClient.query({
            query: GET_COUNTRIES_LIST,
        });

        const countriesList = getCountries?.edges?.map((x: any) => ({
            id: x.node.id,
            name: x.node.name,
        }));

        return countriesList;
    };

    getTupeOrganisations = async (search: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { searchTupeOrganisations },
        } = await this.apolloClient.query({
            query: GET_TUPE_ORGANISATIONS,
            variables: {
                search,
            },
        });

        return searchTupeOrganisations;
    };

    validateEmployeeRecordsArchivation = async (ids: string[]) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            const {
                data: { validateEmployeeRecordsArchivation },
            } = await this.apolloClient.query({
                query: VALIDATE_EMPLOYEE_RECORDS_ARCHIVATION,
                variables: {
                    ids: ids,
                },
            });
            return validateEmployeeRecordsArchivation;
        } catch (err) {
            return [];
        }
    };

    archiveEmployeeRecords = async (ids: string[]) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            await this.apolloClient.query({
                query: ARCHIVE_EMPLOYEE_RECORDS,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'The staff record(s) have been successfully archived',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem with archiving. Please try again.',
                intent: 'danger',
            });
            return [];
        }
    };
}

export default StaffStore;
