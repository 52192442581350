import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import moment from 'moment';
import _ from 'lodash';
import {
    GET_PEOPLE,
    GET_PEOPLE_OPTIONS,
    CREATE_PERSON,
    UPDATE_PERSON,
    GET_PERSON,
    DELETE_PERSON,
    VALIDATE_EMAIL,
    VALIDATE_PHONE,
    VALIDATE_DBS,
    VALIDATE_NATIONAL_INSURANCE,
    GET_PERSON_EMAILS,
    GET_PERSON_PHONE_NUMBERS,
    GET_ALLOWED_EMAIL_DOMAINS,
    VALIDATE_PERSON_ARCHIVATION,
    ARCHIVE_PERSON_RECORDS,
} from '../graphql/queries/people';
import { UPDATE_PERSON_CHECKLIST } from '../graphql/queries/personInductionTasks';
import { PERSON_ARCHIVED_STATUS } from '../constants/hrConst';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { formatDateWithTime } from '../components/modules/helpers/formatData';
import { PagedStore } from './PagedStore';

class PeopleStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            selected: observable,
            people: observable,
            allPeople: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridPeople: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getPeople: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    people = [];
    allPeople = [];
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
    };

    normalizePerson = (person) => ({
        id: person.id,
        selected: this.selected.includes(person.id),
        profilePhoto: person.profilePhoto,
        emergencyContactPhone: person.emergencyContactPhone,
        emergencyContactName: person.emergencyContactName,
        homePhone: person.homePhone,
        personalEmail: person.personalEmail,
        nationalInsurance: person.nationalInsurance,
        payrollReference: person.payrollReference,
        title: person.title ?? '',
        firstName: person.firstName,
        preferredName: person.preferredName,
        maidenName: person.maidenName,
        lastName: person.lastName,
        middleName: person.middleName,
        createdByPerson: person.createdByPerson,
        updatedByPerson: person.updatedByPerson,
        alternativeNamesToggle: !!person?.alternativeNames?.length,
        alternativeNames: person.alternativeNames,
        dateOfBirth: person.dateOfBirth?.length ? new Date(person.dateOfBirth.slice(0, -1)) : null,
        personalPhone: person.personalPhone,
        homeAddress: person.homeAddress ?? '',
        cognitoId: person.cognitoId ?? '',
        cognitoUsername: person.cognitoUsername ?? '',
        inviteStatus: person.inviteStatus,
        genderId: person.gender?.id ?? '',
        status: person.status,
        statusId: person.statusId ?? '',
        personStatus: person.personStatus?.name ?? '',
        languages: person.languages ? person.languages.map((el) => el.languageId) : [],
        updatedAt: formatDateWithTime(person.updatedAt),
        createdAt: formatDateWithTime(person.createdAt),
        dbsNumber: person.dbsNumber ?? '',
        professionalBodyName: person.professionalBodyName ?? '',
        personInductionChecklists: person.personInductionChecklists,
        hasPerformerInfo: person.hasPerformerInfo,
        performerAlignmentId: person.performerAlignmentId ?? '',
        performerRoleId: person.performerRoleId ?? '',
        performerProfessionalRegNumber: person.performerProfessionalRegNumber ?? '',
        performerStatusId: person.performerStatusId ?? '',
        performerDateFirstOnPerformersList: person.performerDateFirstOnPerformersList?.length
            ? new Date(person.performerDateFirstOnPerformersList.slice(0, -1))
            : null,
        performerDateInGPRegister: person.performerDateInGPRegister?.length
            ? new Date(person.performerDateInGPRegister.slice(0, -1))
            : null,
        performerTeamId: person.performerTeamId ?? '',
        performerCurrentlyInProbationary: person.performerCurrentlyInProbationary,

        hasPerformerInfoLastCheckDate: person.hasPerformerInfoLastCheckDate?.length
            ? new Date(person.hasPerformerInfoLastCheckDate.slice(0, -1))
            : null,
        hasPerformerInfoCheckedByPerson: person.hasPerformerInfoCheckedByPerson
            ? `${person.hasPerformerInfoCheckedByPerson.firstName} ${person.hasPerformerInfoCheckedByPerson.lastName}`
            : null,
        nationality: person.nationality ?? '',
        ethnicity: person.ethnicity ?? '',
        religionOrBelief: person.religionOrBelief ?? '',
        genderReassignment: person.genderReassignment ?? '',
        sexualOrientation: person.sexualOrientation ?? '',
        maritalStatus: person.maritalStatus ?? '',
        personDisability: person.personDisability
            ? person.personDisability.map((d) => ({
                  id: d.disability?.id,
                  name: d.disability?.name,
              }))
            : [],
        carer: person.carer,
        employmentStartDate: person.employmentStartDate?.length
            ? new Date(person.employmentStartDate.slice(0, -1))
            : null,
        employmentEndDate: person.employmentEndDate?.length
            ? new Date(person.employmentEndDate.slice(0, -1))
            : null,
        employmentPeriodErrors: person.employmentPeriodErrors,
    });

    get gridPeople() {
        const people = this.people.map(this.normalizePerson);
        if (this.sortFn) {
            people.sort(this.sortFn);
        }
        return people;
    }

    get allSelected() {
        return this.people.every(
            (r) =>
                (r.statusId === PERSON_ARCHIVED_STATUS && this.selected.length) ||
                this.selected.includes(r.id),
        );
    }

    async createPerson(person) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_PERSON,
                variables: {
                    data: {
                        dateOfBirth: person.dateOfBirth
                            ? moment(person.dateOfBirth).utc(true)
                            : null,
                        titleId: person.title ? person.title : null,
                        firstName: person.firstName,
                        preferredName: person.preferredName,
                        lastName: person.lastName,
                        maidenName: person.maidenName,
                        alternativeNames: person.alternativeNames.filter(
                            (names) => names.firstName,
                        ),
                        genderId: _.isEmpty(person.genderId) ? null : person.genderId,
                        statusId: person.statusId,
                        homeAddress: _.isEmpty(person.homeAddress) ? null : person.homeAddress,
                        personalEmail: person.personalEmail,
                        nationalInsurance: person.nationalInsurance,
                        payrollReference: person.payrollReference,
                        personalPhone: person.personalPhone,
                        languages: person.languages,
                        profilePhoto: person.profilePhoto,
                        emergencyContactPhone: person.emergencyContactPhone,
                        emergencyContactName: person.emergencyContactName,
                        homePhone: person.homePhone,
                        middleName: person.middleName,
                        dbsNumber: person.dbsNumber,
                        professionalBodyName: person.professionalBodyName,
                        hasPerformerInfo: person.hasPerformerInfo,
                        performerAlignmentId: person.hasPerformerInfo
                            ? person.performerAlignmentId
                            : undefined,
                        performerRoleId: person.hasPerformerInfo
                            ? person.performerRoleId
                            : undefined,
                        performerProfessionalRegNumber: person.hasPerformerInfo
                            ? person.performerProfessionalRegNumber
                            : undefined,
                        performerStatusId: person.hasPerformerInfo
                            ? person.performerStatusId
                            : undefined,
                        performerDateFirstOnPerformersList: person.hasPerformerInfo
                            ? moment(person.performerDateFirstOnPerformersList).utc(true)
                            : undefined,
                        performerDateInGPRegister: person.hasPerformerInfo
                            ? moment(person.performerDateInGPRegister).utc(true)
                            : undefined,
                        performerTeamId: person.hasPerformerInfo
                            ? person.performerTeamId
                            : undefined,
                        performerCurrentlyInProbationary: person.hasPerformerInfo
                            ? person.performerCurrentlyInProbationary
                            : undefined,
                        hasPerformerInfoLastCheckDate: person.hasPerformerInfo
                            ? moment(person.hasPerformerInfoLastCheckDate).utc(true)
                            : undefined,
                        nationalityId: person.nationality ? person.nationality : null,
                        ethnicityId: person.ethnicity ? person.ethnicity : null,
                        genderReassignment: person.genderReassignment,
                        religionOrBeliefId: person.religionOrBelief
                            ? person.religionOrBelief
                            : null,
                        sexualOrientationId: person.sexualOrientation
                            ? person.sexualOrientation
                            : null,
                        maritalStatusId: person.maritalStatus ? person.maritalStatus : null,
                        disabilityIds: person.personDisability,
                        carer: person.carer,
                        employmentStartDate:
                            person.employmentStartDate &&
                            moment(person.employmentStartDate).utc(true),
                        employmentEndDate:
                            person.employmentEndDate && moment(person.employmentEndDate).utc(true),
                    },
                },
            });
            AppToaster.show({
                message: 'Person created successfully!',
                intent: 'success',
            });
        } catch (err) {
            if (err.message.includes('personalEmail')) {
                AppToaster.show({
                    message: (
                        <>
                            This email address is already in use. Please change the email and try
                            again.
                        </>
                    ),
                    intent: 'danger',
                });
                return err;
            } else if (err.message.includes('nationalInsurance')) {
                AppToaster.show({
                    message: (
                        <>
                            This national insurance number is already in use. Please change the
                            national insurance number and try again.
                        </>
                    ),
                    intent: 'danger',
                });
                return err;
            }
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating person record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async validateEmail(email) {
        return await this.apolloClient.query({
            query: VALIDATE_EMAIL,
            variables: {
                email,
            },
        });
    }

    async validatePhoneNumber(phone) {
        return await this.apolloClient.query({
            query: VALIDATE_PHONE,
            variables: {
                phone,
            },
        });
    }

    async validateDBSNumber(dbsNumber) {
        return await this.apolloClient.query({
            query: VALIDATE_DBS,
            variables: {
                dbsNumber,
            },
        });
    }

    async validateNationalInsurance(nationalInsurance) {
        return await this.apolloClient.query({
            query: VALIDATE_NATIONAL_INSURANCE,
            variables: {
                nationalInsurance,
            },
        });
    }

    async updatePerson(person) {
        try {
            const alternativeNamesList = person.alternativeNames
                ? person.alternativeNames
                      .map((n) => ({
                          firstName: n.firstName,
                          middleName: n.middleName,
                          lastName: n.lastName,
                      }))
                      .filter((names) => names.firstName)
                : [];
            await this.apolloClient.mutate({
                mutation: UPDATE_PERSON,
                variables: {
                    id: person.id,
                    data: {
                        dateOfBirth: person.dateOfBirth
                            ? moment(person.dateOfBirth).utc(true)
                            : null,
                        titleId: person.title?.id ? person.title.id : person.title,
                        firstName: person.firstName,
                        preferredName: person.preferredName,
                        lastName: person.lastName,
                        maidenName: person.maidenName,
                        middleName: person.middleName,
                        alternativeNames: alternativeNamesList,
                        genderId: _.isEmpty(person.genderId) ? null : person.genderId,
                        statusId: person.statusId,
                        homeAddress: person.homeAddress,
                        personalEmail: person.personalEmail,
                        nationalInsurance: person.nationalInsurance,
                        payrollReference: person.payrollReference,
                        personalPhone: person.personalPhone,
                        languages: person.languages,
                        profilePhoto: person.profilePhoto,
                        emergencyContactPhone: person.emergencyContactPhone,
                        emergencyContactName: person.emergencyContactName,
                        homePhone: person.homePhone,
                        dbsNumber: person.dbsNumber,
                        professionalBodyName: person.professionalBodyName,
                        hasPerformerInfo: person.hasPerformerInfo,
                        performerAlignmentId:
                            person.hasPerformerInfo && person.performerAlignmentId
                                ? person.performerAlignmentId
                                : null,
                        performerRoleId:
                            person.hasPerformerInfo && person.performerRoleId
                                ? person.performerRoleId
                                : null,
                        performerProfessionalRegNumber: person.hasPerformerInfo
                            ? person.performerProfessionalRegNumber
                            : null,
                        performerStatusId:
                            person.hasPerformerInfo && person.performerStatusId
                                ? person.performerStatusId
                                : null,
                        performerDateFirstOnPerformersList:
                            person.hasPerformerInfo && person.performerDateFirstOnPerformersList
                                ? moment(person.performerDateFirstOnPerformersList).utc(true)
                                : null,
                        performerDateInGPRegister:
                            person.hasPerformerInfo && person.performerDateInGPRegister
                                ? moment(person.performerDateInGPRegister).utc(true)
                                : null,
                        performerTeamId:
                            person.hasPerformerInfo && person.performerTeamId
                                ? person.performerTeamId
                                : null,
                        performerCurrentlyInProbationary: person.hasPerformerInfo
                            ? person.performerCurrentlyInProbationary
                            : false,
                        hasPerformerInfoLastCheckDate:
                            person.hasPerformerInfo && person.hasPerformerInfoLastCheckDate
                                ? moment(person.hasPerformerInfoLastCheckDate).utc(true)
                                : null,
                        nationalityId: person.nationality ? person.nationality?.id : null,
                        ethnicityId: person.ethnicity ? person.ethnicity?.id : null,
                        genderReassignment: person.genderReassignment
                            ? person.genderReassignment
                            : null,
                        religionOrBeliefId: person.religionOrBelief
                            ? person.religionOrBelief?.id
                            : null,
                        sexualOrientationId: person.sexualOrientation
                            ? person.sexualOrientation?.id
                            : null,
                        maritalStatusId: person.maritalStatus ? person.maritalStatus?.id : null,
                        disabilityIds: person.personDisability.map((d) =>
                            d.hasOwnProperty('id') ? d.id : d,
                        ),
                        carer: person.carer,
                        employmentStartDate:
                            person.employmentStartDate &&
                            moment(person.employmentStartDate).utc(true),
                        employmentEndDate:
                            person.employmentEndDate && moment(person.employmentEndDate).utc(true),
                    },
                },
            });
            AppToaster.show({
                message: 'Person updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating person record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deletePerson(person) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_PERSON,
                variables: {
                    id: person.id,
                },
            });
            AppToaster.show({
                message: 'Person deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting person record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (employeeId) => {
        if (!this.selected.includes(employeeId)) {
            this.selected.push(employeeId);
        }
    };

    deselect = (employeeId) => {
        const index = this.selected.indexOf(employeeId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getPeople();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getPeople();
    };

    refreshPage = () => {
        this.loaded = false;
        this.getPeople();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getPeople();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                status: null,
            });
        });
        this.selected = [];
        await this.getPeople();
    };

    getPeople = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getPersons: data },
        } = await this.apolloClient.query({
            query: GET_PEOPLE,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                filter: this.pageInfo.filter,
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            this.people = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllPeople = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getPersons: data },
        } = await this.apolloClient.query({
            query: GET_PEOPLE,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'firstName',
                filter: {},
            },
        });
        runInAction(() => {
            this.allPeople = data.edges.map((x) => x.node);
        });
    };

    getPersonById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getPerson: data },
        } = await this.apolloClient.query({
            query: GET_PERSON,
            variables: {
                id,
            },
        });
        return this.normalizePerson(data);
    };

    async updatePersonInductionTask(id, task) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_PERSON_CHECKLIST,
                variables: {
                    id: id,
                    data: {
                        ...task,
                    },
                },
            });
            AppToaster.show({
                message: 'Person Induction task updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating person  induction task record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    selectAll = () => {
        runInAction(() => {
            this.gridPeople.forEach((employee) => {
                if (employee.statusId !== PERSON_ARCHIVED_STATUS) {
                    this.select(employee.id);
                }
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    getPeopleRecords = async (first, after, query) => {
        const result = await this.apolloClient.query({
            query: GET_PEOPLE_OPTIONS,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: query || '',
            },
        });

        return result;
    };

    getPersonEmails = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            const {
                data: { getPersonEmails },
            } = await this.apolloClient.query({
                query: GET_PERSON_EMAILS,
                variables: {
                    id,
                },
            });
            return getPersonEmails;
        } catch (err) {
            return [];
        }
    };

    getPersonPhoneNumbers = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            const {
                data: { getPersonPhoneNumbers },
            } = await this.apolloClient.query({
                query: GET_PERSON_PHONE_NUMBERS,
                variables: {
                    id,
                },
            });
            return getPersonPhoneNumbers;
        } catch (err) {
            return [];
        }
    };

    getAllowedEmailDomains = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            const {
                data: { getAllowedEmailDomains },
            } = await this.apolloClient.query({
                query: GET_ALLOWED_EMAIL_DOMAINS,
                variables: {
                    id,
                },
            });
            return getAllowedEmailDomains;
        } catch (err) {
            return [];
        }
    };

    validatePersonArchivation = async (ids) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            const {
                data: { validatePersonArchivation },
            } = await this.apolloClient.query({
                query: VALIDATE_PERSON_ARCHIVATION,
                variables: {
                    ids: ids,
                },
            });
            return validatePersonArchivation;
        } catch (err) {
            return [];
        }
    };

    archivePersonRecords = async (ids) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        try {
            await this.apolloClient.query({
                query: ARCHIVE_PERSON_RECORDS,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message:
                    ids.length > 1
                        ? 'The person record(s) have been successfully archived'
                        : 'Person record has been successfully archived',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem with archiving process. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
            return [];
        }
    };
}

export default PeopleStore;
