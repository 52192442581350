import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import Modal from '../../modal/Modal';
import FunctionsSummary from './FunctionsSummary';
import { Formik, Form } from 'formik';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { Alert, Tab, Tabs } from '@blueprintjs/core';
import RelatedEntities from '../../../common/RelatedEntities';
import _, { isEqual } from 'lodash';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';

const FunctionsDetails = inject('RootStore')(
    observer(
        class FunctionsDetails extends Component {
            state = {
                isEdit: false,
                activeTab: 1,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                    isArchived: false,
                },
                isLoaded: false,
                relatedLoaded: false,
                formWasEdited: false,
                showDeleteAlert: false,
                showEditAlert: false,
                showArchiveAlert: false,
                showArchiveByRoleAlert: false,
            };

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    await this._updateFunction(
                        this.props.location.state?.editModeOpenedFromViewList,
                    );
                    await this._queryRelatedEntries();
                }
                await this.props.RootStore.functionsStore.getAllFunctions();
            }

            componentWillUnmount() {
                this.props.RootStore.shiftPatternsStore.shiftPatternsByFunction = [];
                this.props.RootStore.rotaBuilderStore.rotasByFunction = [];
                this.props.RootStore.rolesStore.rolesByFunction = [];
            }

            _editStatusForm = async (values) => {
                await this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _updateFunction = async (editModeOpenedFromViewList = false) => {
                const { id } = this.props.match.params;
                const functionEntity = await this.props.RootStore.functionsStore.getFunctionById(
                    id,
                );
                if (functionEntity) {
                    this.setState({
                        initialData: { ...functionEntity },
                        isEdit: editModeOpenedFromViewList,
                        isLoaded: true,
                    });
                }
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleEditAlertClick = () => {
                this.setState((prevState) => {
                    return { showEditAlert: !prevState.showEditAlert };
                });
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/functions');
                }
                return this.props.history.goBack();
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/hr/functions');
                }
                if (this.props.actionType === 'edit' && !this.state.formWasEdited) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                id && (await this.props.RootStore.functionsStore.deleteFunction(id));
                this.props.history.replace('/hr/functions');
            };

            _handleDeleteAlertClick = () => {
                this.setState((prevState) => {
                    return { showDeleteAlert: !prevState.showDeleteAlert };
                });
            };

            _handleOnArchive = async () => {
                const { id } = this.props.match.params;

                const func = this.props.RootStore.functionsStore.gridFunctions.find(
                    (f) => f.id === id,
                );
                this.props.RootStore.functionsStore.deselect(id);
                func && (await this.props.RootStore.functionsStore.archiveFunction(func));
                await this.setState({ formWasEdited: false });
                this.props.history.replace('/hr/functions');
            };

            _handleArchiveAlertClick = async () => {
                const {
                    data: { isFunctionArchivable },
                } = await this.props.RootStore.functionsStore.validateArchiveFunctions([
                    this.state.initialData.id,
                ]);

                const isArchivable = isFunctionArchivable.every((func) => func.isArchivable);

                const hasEmptyRelatedEntitiesButRoles = isFunctionArchivable.every(
                    (func) => func.relatedRole && !func.relatedRota && !func.relatedShift,
                );

                if (isArchivable && !hasEmptyRelatedEntitiesButRoles) {
                    this.setState((prevState) => {
                        return { showArchiveAlert: !prevState.showArchiveAlert };
                    });
                } else if (hasEmptyRelatedEntitiesButRoles) {
                    this.setState((prevState) => {
                        return { showArchiveByRoleAlert: !prevState.showArchiveByRoleAlert };
                    });
                } else {
                    this.setState((prevState) => {
                        return { showDeniedArchiveAlert: !prevState.showDeniedArchiveAlert };
                    });
                }
            };

            _queryRelatedEntries = async () => {
                return (
                    await this.props.RootStore.shiftPatternsStore.getShiftPatternsByFunction(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.rotaBuilderStore.getRotasByFunction(
                        this.state.initialData.id,
                    ),
                    await this.props.RootStore.rolesStore.getRolesByFunction(
                        this.state.initialData.id,
                    ),
                    this.setState({
                        relatedLoaded: true,
                    })
                );
            };

            _handleOnCancelConfirm = async (replacePath) => {
                await this.setState({ formWasEdited: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/hr/functions');
            };

            render() {
                const {
                    RootStore: {
                        functionsStore,
                        shiftPatternsStore,
                        rotaBuilderStore,
                        dictionaryStore,
                        rolesStore,
                    },
                } = this.props;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Add function record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            await this._editStatusForm(values);
                            const errors = {};
                            if (
                                !!values.name &&
                                values.name.length > 0 &&
                                values.name !== this.state.initialData.name
                            ) {
                                const { data } = await functionsStore.validateFunctionName(
                                    values.name,
                                );
                                if (!!data && data?.getFunctionByName) {
                                    errors.notUniqueId = data?.getFunctionByName.id;
                                    errors.notUniqueName = data?.getFunctionByName.name;
                                }

                                return errors;
                            }
                        }}
                        onSubmit={async (values, { setSubmitting }) => {
                            if (this.props.actionType === 'create') {
                                this.setState({
                                    formWasEdited: false,
                                });
                                await functionsStore.createFunction(values);
                                this.props.history.replace('/hr/functions');
                            }
                            if (this.props.actionType === 'edit') {
                                if (
                                    this.state.formWasEdited &&
                                    (!_.isEmpty(shiftPatternsStore.shiftPatternsByFunction) ||
                                        !_.isEmpty(rolesStore.rolesByFunction) ||
                                        !_.isEmpty(rotaBuilderStore.rotasByFunction))
                                ) {
                                    this.setState({ showEditAlert: true });
                                } else {
                                    await functionsStore.updateFunction(values);
                                    this._updateFunction();
                                    await this.setState({
                                        formWasEdited: false,
                                    });
                                }
                            }
                            setSubmitting(false);
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            setSubmitting,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        <>
                                            {!this.state.initialData.isArchived && (
                                                <CommonModalFooter
                                                    isSubmitting={isSubmitting}
                                                    toggleIsEdit={this._toggleIsEdit}
                                                    isEditing={this.state.isEdit}
                                                    onCancel={this._handleOnCancel}
                                                    onDelete={this._handleDeleteAlertClick}
                                                    onArchive={this._handleArchiveAlertClick}
                                                    hasArchiveOption={
                                                        !this.state.initialData.isArchived
                                                    }
                                                />
                                            )}
                                        </>
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="functionsTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        selectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            if (newTabId === 2) {
                                                await this._queryRelatedEntries();
                                            }
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                        }}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title={
                                                this.props.actionType === 'edit' ? 'Summary' : ''
                                            }
                                            panel={
                                                <FunctionsSummary
                                                    functionEntity={this.state.initialData}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    errors={errors}
                                                    functionsStore={functionsStore}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          !this.state.relatedLoaded ? '...' : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        dictionaryStore={dictionaryStore}
                                                        relatedShiftPatterns={
                                                            shiftPatternsStore.shiftPatternsByFunction
                                                        }
                                                        relatedRoles={rolesStore.rolesByFunction}
                                                        relatedRotas={
                                                            rotaBuilderStore.rotasByFunction
                                                        }
                                                        description={
                                                            'This function is related to the following entities (archived entities are not displayed)'
                                                        }
                                                        hasRelatedShiftPatterns={true}
                                                        hasRelatedRotas={true}
                                                        hasRelatedRoles={true}
                                                        relatedRotasTitle={
                                                            'Rotas with future shifts'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showDeleteAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this function? You won't be
                                        able to recover it."
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._handleOnArchive}
                                    onCancel={() =>
                                        this.setState({
                                            showArchiveAlert: false,
                                        })
                                    }
                                    isOpen={this.state.showArchiveAlert}
                                    title="Confirm archiving!"
                                    icon={'archive'}
                                    intent={'warning'}
                                    description="Are you sure you want to archive this function? This action is irreversible"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._handleOnArchive}
                                    onCancel={() =>
                                        this.setState({
                                            showArchiveByRoleAlert: false,
                                        })
                                    }
                                    isOpen={this.state.showArchiveByRoleAlert}
                                    title="Confirm archiving!"
                                    icon={'archive'}
                                    intent={'warning'}
                                    description={`This function is assigned to role(s), but not selected in shift pattern shifts or future rota shifts. 
                                    The function will be removed from related role(s) after archiving.
                                    Are you sure you want to archive the function? This action is irreversible`}
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Yes"
                                    cancelButtonText="No"
                                    onConfirm={async () => {
                                        if (this.props.actionType === 'create') {
                                            await functionsStore.createFunction(values);
                                            this.props.history.replace('/hr/functions');
                                        }
                                        if (this.props.actionType === 'edit') {
                                            await functionsStore.updateFunction(values);
                                            this._updateFunction();
                                        }
                                        this._handleEditAlertClick();
                                        setSubmitting(false);
                                    }}
                                    onCancel={this._handleEditAlertClick}
                                    isOpen={this.state.showEditAlert}
                                    title="Confirm editing!"
                                    icon={'edit'}
                                    intent={'primary'}
                                    description="Are you sure you want to save the changes? The function will be updated in all related roles, shift pattern shifts and past, current, and future rota shifts"
                                />
                                <Alert
                                    confirmButtonText="OK"
                                    intent={'primary'}
                                    isOpen={this.state.showDeniedArchiveAlert}
                                    onClose={() => {
                                        this.setState({ showDeniedArchiveAlert: false });
                                    }}
                                >
                                    <p>
                                        This function cannot be archived because it is selected in
                                        future rota shift(s) and/or shift pattern shift(s). Remove
                                        the function from the related shift(s) to archive it
                                    </p>
                                </Alert>
                                <RouterPrompt
                                    when={this.state.formWasEdited}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default FunctionsDetails;
