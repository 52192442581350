import React, { Component, useCallback } from 'react';
import CacheBuster from 'react-cache-buster';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

import Layout from './layout/Layout';
import CustomPrompt from './common/CustomPrompt';
import packageInfo from '../../package.json';

const version = packageInfo.version;
const Router = ({ props }) => {
    const userConfirmation = useCallback((message, callback) => {
        const node = document.getElementById('custom-prompt');

        const cleanUp = (answer) => {
            ReactDOM.unmountComponentAtNode(node);
            callback(answer);
        };

        ReactDOM.render(<CustomPrompt message={message} cleanUp={cleanUp} />, node);
    }, []);

    console.log('Current app version', version);

    return (
        <BrowserRouter getUserConfirmation={userConfirmation}>
            <Switch>
                <Redirect exact from="/logout" to="/" />
                <Layout props={props} />
            </Switch>
        </BrowserRouter>
    );
};

const App = inject('RootStore')(
    observer(
        class App extends Component {
            async componentDidMount() {
                const {
                    configStore,
                    userStore,
                    lovsStore,
                    usersStore,
                    ucrStore,
                } = this.props.RootStore;

                await userStore.authorize();

                lovsStore.setLovs();
                await configStore.getConfig();
                configStore.getClientKeys();
                usersStore.startTimer();
                ucrStore.applyConfig();
            }

            componentWillUnmount() {
                const { usersStore } = this.props.RootStore;

                usersStore.dispose();
            }

            render() {
                return (
                    <CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={true}>
                        <Router props={this.props} />
                    </CacheBuster>
                );
            }
        },
    ),
);

export default App;
