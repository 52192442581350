import { JobStatus } from '@doc-abode/data-models';

export interface IIsArrived {
    jobStatus: JobStatus | undefined;
}

// Checks whether a job qualifies as ARRIVED
export function isArrived({ jobStatus }: IIsArrived): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return jobStatus === JobStatus.ARRIVED;
}

export interface IIsCompleted {
    jobStatus: JobStatus | undefined;
}

// Checks whether a job qualifies as COMPLETED
export function isCompleted({ jobStatus }: IIsCompleted): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return jobStatus === JobStatus.COMPLETED;
}

export interface IIsAborted {
    jobStatus: JobStatus | undefined;
}

// Checks whether a job qualifies as ABORTED
export function isAborted({ jobStatus }: IIsAborted): boolean {
    if (jobStatus === undefined) {
        return false;
    }

    return [JobStatus.CONTROLLER_ABORTED, JobStatus.HCP_ABORTED].includes(jobStatus);
}
