import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Alert } from '@blueprintjs/core';

import Edit from './Edit';
import AppToaster from '../../helpers/Toaster';

const MakeCurrent = inject('RootStore')(
    observer(
        class MakeCurrent extends Component {
            state = {
                showConfirmation: false,
            };

            makeJobCurrent = async (jobId) => {
                this.props.onToggleLoading(true);

                try {
                    await this.props.RootStore.jobsStore.controllerMakeJobCurrent(jobId);
                } catch (err) {
                    AppToaster.show({
                        message: (
                            <>
                                Sorry, there was a problem marking the job as current. Please try
                                again or contact{' '}
                                <a href="mailto:support@docabode.com">support@docabode.com</a> for
                                help.
                            </>
                        ),
                        intent: 'danger',
                        timeout: 0,
                    });
                    return this.props.onToggleLoading(false);
                }

                AppToaster.show({
                    message: 'The job has been marked as current successfully!',
                    intent: 'success',
                });

                this.props.onToggleLoading(false);
                this.props.RootStore.jobsStore.setJobs();

                const url = this.props.match.path.startsWith('/scheduled')
                    ? `/scheduled/jobs/${jobId}`
                    : `/on-demand/${this.props.match.params.tab}/jobs/${jobId}`;

                this.props.history.push(url);
            };

            handleMakeCurrent = async ({ jobStatus, id }) => {
                if (jobStatus === 'CURRENT') {
                    this.setState({ showConfirmation: true });
                } else {
                    this.makeJobCurrent(id);
                }
            };

            handleConfirm = async () => {
                await this.makeJobCurrent(this.props.job.id);
                this.setState({ showConfirmation: false });
            };

            handleCancel = () => {
                this.setState({ showConfirmation: false });
            };

            render() {
                const { job } = this.props;

                return (
                    <div>
                        <Alert
                            isOpen={this.state.showConfirmation}
                            onConfirm={this.handleConfirm}
                            onCancel={this.handleCancel}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="danger"
                        >
                            <p>
                                You are about to mark a job as current on behalf of a HCP and you
                                must inform the HCP before doing this.
                            </p>
                            <p>
                                <strong>Please confirm you have informed the HCP:</strong>
                            </p>
                        </Alert>

                        {job.jobStatus === 'CURRENT' ? (
                            <Edit id={job.id} {...this.props} />
                        ) : (
                            <Button
                                intent="success"
                                large={true}
                                onClick={() => this.makeJobCurrent(job.id)}
                                icon="route"
                            >
                                Mark as Current
                            </Button>
                        )}
                    </div>
                );
            }
        },
    ),
);

export default MakeCurrent;
