import { DateInput3 } from '@blueprintjs/datetime2';
import React, { Component, ChangeEvent } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, FormGroup, InputGroup, TextArea } from '@blueprintjs/core';
import Loader from '../../helpers/Loader';
import { Formik, FormikErrors, FormikValues } from 'formik';
import Select from 'react-select';
import { dateWithoutTime, formatDate, momentFormatter } from '../../helpers/formatData';
import { dateFormat } from '../../../../constants/patientsConst';
import moment from 'moment';
import { validationSchema } from './DepartmentAssignmentsFormValidation';
import Error from '../../forms/Error';
import ConfirmationAlert from '../../../common/ConfirmationAlert';
import { RouterPrompt } from '../../../common/RouterPrompt';
import AppToaster from '../../helpers/Toaster';
import { isEqual } from 'lodash';

export interface NormalizedDepartment {
    id: string;
    costCentre: string;
    department: string;
    jobTitle: string;
    startDateOfAssignment: Date | null;
    endDateOfAssignment: Date | null;
    payRates: [];
    payRatesFuture: [];
    payRatesFutureDate: Date | null;
    locations: [];
    isArchived: boolean;
    isDepartmentEditable: boolean;
    showFuturePayRate: boolean;
    onFormCancel: (event: React.MouseEvent) => void;
    reasonForEnding: string;
    comments: string;
}

interface InputProps {
    errors: FormikErrors<FormikValues>;
    values?: FormikValues | NormalizedDepartment;
    handleChange: (event: string | ChangeEvent<any>) => void;
    setFieldValue: (key: string, value: any) => void;
    validateForm: () => void;
    isSubmitting: boolean;
    submitForm: () => void;
}

const DepartmentAssignments = inject('RootStore')(
    observer(
        class Departments extends Component<any> {
            state = {
                isEdit: true,
                isLoading: true,
                assignmentId: null,
                initialData: {},
                departmentOptions: [],
                locationOptions: [],
                payRatesOptions: [],
                payRatesFutureOptions: [],
                costCentreOptions: [],
                showPrDeleteAlert: false,
                editableCC: true,
                formWasEdited: false,
                showArchiveViaPastOrCurrentDateAlert: false,
                showArchiveViaFutureDateAlert: false,
                showArchiveViaNoDateAlert: false,
            };

            async componentDidMount() {
                this.props.RootStore.departmentAssignmentsStore.employeeDepartmentDatesValid = [];
                await this.props.RootStore.departmentsStore.getAllDepartments();

                await this.props.RootStore.costCentresStore.getAllCostCentres();

                if (this.props.assignmentId) {
                    const assignment = await this.props.RootStore.departmentAssignmentsStore.getDepartmentAssignmentById(
                        this.props.assignmentId,
                    );

                    this.setState({ initialData: assignment });
                }
                this.setState({ isEdit: this.props.createActionType, isLoading: false });
            }

            async componentWillUnmount() {
                this.props.RootStore.departmentAssignmentsStore.employeeDepartmentDatesValid = [];
            }

            onPayRateDelete = () => {
                this.setState((prevState: any) => {
                    return {
                        showPrDeleteAlert: !prevState.showPrDeleteAlert,
                    };
                });
            };

            onArchive = (date: Date) => {
                if (!date) {
                    this.setState({
                        showArchiveViaNoDateAlert: true,
                    });
                } else if (moment().isBefore(date)) {
                    this.setState({
                        showArchiveViaFutureDateAlert: true,
                    });
                } else if (moment().isSameOrAfter(date)) {
                    this.setState({
                        showArchiveViaPastOrCurrentDateAlert: true,
                    });
                }
            };

            onCloseArchiveAlert = async () => {
                this.setState({
                    showArchiveViaPastOrCurrentDateAlert: false,
                    showArchiveViaFutureDateAlert: false,
                    showArchiveViaNoDateAlert: false,
                });
                this.props.onFormCancel();
                await this.props.RootStore.departmentAssignmentsStore.refresh(
                    this.props.employee.id,
                );
            };

            _editStatusForm = async (values: any) => {
                await this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _removeEditStatusForm = () => {
                this.setState({
                    formWasEdited: false,
                });
            };

            _handleOnCancelConfirm = async () => {
                this._removeEditStatusForm();
                if (!this.props.createActionType) {
                    (this as any).formik.resetForm({ values: this.state.initialData });
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.onFormCancel();
                this.props.history.replace(this.props.history.location.pathname);
            };

            render() {
                const {
                    RootStore: {
                        costCentresStore,
                        departmentsStore,
                        locationsStore,
                        payRatesStore,
                        departmentAssignmentsStore,
                    },
                    createActionType,
                    employee,
                    onFormCancel,
                } = this.props;

                const {
                    initialData,
                    isLoading,
                    isEdit,
                    payRatesOptions,
                    payRatesFutureOptions,
                    departmentOptions,
                    locationOptions,
                    costCentreOptions,
                    formWasEdited,
                } = this.state;

                return (
                    <>
                        {isLoading && <Loader />}
                        <Formik
                            enableReinitialize={true}
                            innerRef={(f) => ((this as any).formik = f)}
                            initialValues={{
                                employeeRecordId: employee.id,
                                jobTitle: employee.roleName[0],
                                showFuturePayRate: false,
                                ...initialData,
                            }}
                            validationSchema={validationSchema}
                            validate={async (values: FormikValues | NormalizedDepartment) => {
                                await this._editStatusForm(values);
                                const errors = {
                                    employeeDepartmentDatesValid: '',
                                };
                                const checkDepartmentAssignmentValid = async () => {
                                    return await departmentAssignmentsStore.areEmployeeDepartmentDatesValid(
                                        {
                                            employeeRecordId: employee.id,
                                            departmentId: values?.department?.value,
                                            startDateOfAssignment: values?.startDateOfAssignment,
                                            endDateOfAssignment: values?.endDateOfAssignment,
                                            employeeDepartmentId: values?.id,
                                        },
                                    );
                                };
                                if (values?.department?.value && values?.startDateOfAssignment) {
                                    await checkDepartmentAssignmentValid();
                                }

                                if (
                                    (await departmentAssignmentsStore.employeeDepartmentDatesValid
                                        ?.isValid) === false
                                ) {
                                    errors.employeeDepartmentDatesValid =
                                        'Department dates are not valid';
                                } else {
                                    delete (errors as any)?.employeeDepartmentDatesValid;
                                }

                                return errors;
                            }}
                            validateOnChange={true}
                            onSubmit={async (values, { setSubmitting }) => {
                                if (createActionType) {
                                    await departmentAssignmentsStore.createDepartmentAssignments(
                                        values,
                                    );
                                } else {
                                    await departmentAssignmentsStore.updateDepartmentAssignments(
                                        values,
                                    );
                                }

                                this.setState({
                                    isEdit: false,
                                });
                                this._removeEditStatusForm();
                                await departmentAssignmentsStore.refresh(employee.id);
                                setSubmitting(false);
                            }}
                        >
                            {({
                                values,
                                errors,
                                handleChange,
                                setFieldValue,
                                validateForm,
                                isSubmitting,
                                submitForm,
                            }: InputProps) => {
                                return (
                                    <section className={'department-assignment-form'}>
                                        <div className={'department-assignment-form__title'}>
                                            <h3>
                                                {createActionType
                                                    ? 'Add department assignment record '
                                                    : values?.department?.label}
                                            </h3>

                                            <Button
                                                icon="cross"
                                                outlined={true}
                                                intent="warning"
                                                onClick={() =>
                                                    formWasEdited
                                                        ? this.props.history.replace(
                                                              this.props.history.location.pathname,
                                                          )
                                                        : onFormCancel()
                                                }
                                            />
                                        </div>
                                        <form>
                                            <dl className="person-fields_group">
                                                <dt className="person-fields__title">
                                                    Cost centre *
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="costCentre">
                                                            <Select
                                                                options={costCentreOptions}
                                                                onChange={async (e: any) => {
                                                                    setFieldValue('costCentre', e);
                                                                    if (values?.department?.value) {
                                                                        setFieldValue(
                                                                            'department',
                                                                            null,
                                                                        );
                                                                    }
                                                                }}
                                                                onMenuOpen={async () => {
                                                                    await costCentresStore.getCostCentresByRole(
                                                                        employee.roles[0],
                                                                    );
                                                                    await this.setState({
                                                                        costCentreOptions: costCentresStore.costCentresByRole
                                                                            ?.filter(
                                                                                (item: any) =>
                                                                                    !item.isArchived,
                                                                            )
                                                                            ?.map((item: any) => ({
                                                                                value: item.id,
                                                                                label: `${item?.name} (${item?.code})`,
                                                                            })),
                                                                    });
                                                                }}
                                                                isDisabled={
                                                                    !createActionType &&
                                                                    !values?.isDepartmentEditable
                                                                }
                                                                value={values?.costCentre}
                                                            />
                                                            {errors?.costCentre && (
                                                                <Error
                                                                    errors={[errors?.costCentre]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        `${values?.costCentre?.label}`
                                                    )}
                                                </dd>
                                                <dt className="person-fields__title">
                                                    Department *
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="department">
                                                            <Select
                                                                options={departmentOptions}
                                                                onMenuOpen={async () => {
                                                                    if (values?.costCentre?.value) {
                                                                        const departmentOptions = await departmentsStore.getDepartmentsByCostCentreId(
                                                                            values?.costCentre
                                                                                .value,
                                                                        );
                                                                        await this.setState({
                                                                            departmentOptions,
                                                                        });
                                                                    } else {
                                                                        await departmentsStore.getDepartmentsByRole(
                                                                            employee.roles[0],
                                                                        );
                                                                        await this.setState({
                                                                            departmentOptions: await departmentsStore.departmentsByRole
                                                                                ?.filter(
                                                                                    (item: any) =>
                                                                                        !item.isArchived,
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        item: any,
                                                                                    ) => ({
                                                                                        value:
                                                                                            item.id,
                                                                                        label: `${item?.name} (${item?.costCode})`,
                                                                                    }),
                                                                                ),
                                                                        });
                                                                    }
                                                                }}
                                                                onChange={async (e: any) => {
                                                                    setFieldValue('department', e);
                                                                    const costCentreByDep = await costCentresStore.getCostCentreByDepartmentId(
                                                                        e.value,
                                                                    );
                                                                    setFieldValue('costCentre', {
                                                                        label: `${costCentreByDep?.name} (${costCentreByDep?.code})`,
                                                                        value: costCentreByDep.id,
                                                                    });
                                                                }}
                                                                isDisabled={
                                                                    !createActionType &&
                                                                    !values?.isDepartmentEditable
                                                                }
                                                                name="department"
                                                                id="department"
                                                                isLoading={
                                                                    !values &&
                                                                    !departmentOptions.length
                                                                }
                                                                value={values?.department}
                                                            />
                                                            {errors?.department && (
                                                                <Error
                                                                    errors={[errors?.department]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        `${values?.department?.label}`
                                                    )}
                                                    <div
                                                        className={
                                                            'person-fields__title info__title--secondary'
                                                        }
                                                    >
                                                        {!departmentAssignmentsStore
                                                            .employeeDepartmentDatesValid
                                                            ?.isValid &&
                                                            !!departmentAssignmentsStore
                                                                .employeeDepartmentDatesValid
                                                                ?.conflictingEmployeeDepartment
                                                                ?.startDateOfAssignment &&
                                                            !departmentAssignmentsStore
                                                                .employeeDepartmentDatesValid
                                                                ?.conflictingEmployeeDepartment
                                                                ?.endDateOfAssignment && (
                                                                <div>
                                                                    <div>
                                                                        {`The person is already
                                                                        rostered for shifts in ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCentre?.name} ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCentre?.code}
                                                                        ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.name} ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCode}  before the start date.
                                                                        Please check the date input
                                                                        or amend the rota.`}
                                                                    </div>
                                                                    <div>
                                                                        There’s a conflict with
                                                                        another active assignment to
                                                                        the same department with the
                                                                        start date{' '}
                                                                        {formatDate(
                                                                            departmentAssignmentsStore
                                                                                .employeeDepartmentDatesValid
                                                                                ?.conflictingEmployeeDepartment
                                                                                ?.startDateOfAssignment,
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        {!departmentAssignmentsStore
                                                            .employeeDepartmentDatesValid
                                                            ?.isValid &&
                                                            !!departmentAssignmentsStore
                                                                .employeeDepartmentDatesValid
                                                                ?.conflictingEmployeeDepartment
                                                                ?.startDateOfAssignment &&
                                                            !!departmentAssignmentsStore
                                                                .employeeDepartmentDatesValid
                                                                ?.conflictingEmployeeDepartment
                                                                ?.endDateOfAssignment && (
                                                                <div>
                                                                    <div>
                                                                        {`The person is already
                                                                        rostered for shifts in ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCentre?.name} ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCentre?.code} 
                                                                        ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.name} ${departmentAssignmentsStore.employeeDepartmentDatesValid?.conflictingEmployeeDepartment?.department?.costCode} after the end date. Please
                                                                        check the date input or
                                                                        amend the rota.`}
                                                                    </div>
                                                                    <div>
                                                                        There’s a conflict with
                                                                        another active assignment to
                                                                        the same department with the
                                                                        start date{' '}
                                                                        {formatDate(
                                                                            departmentAssignmentsStore
                                                                                .employeeDepartmentDatesValid
                                                                                ?.conflictingEmployeeDepartment
                                                                                ?.startDateOfAssignment,
                                                                        )}{' '}
                                                                        and{' '}
                                                                        {formatDate(
                                                                            departmentAssignmentsStore
                                                                                .employeeDepartmentDatesValid
                                                                                ?.conflictingEmployeeDepartment
                                                                                ?.endDateOfAssignment,
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                    </div>
                                                </dd>
                                                <dt className="person-fields__title">
                                                    Start date of assignment *
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="startDateOfAssignment">
                                                            <DateInput3
                                                                showTimezoneSelect={false}
                                                                {...momentFormatter(dateFormat)}
                                                                onChange={async (value) => {
                                                                    setFieldValue(
                                                                        'startDateOfAssignment',
                                                                        dateWithoutTime(value),
                                                                    );
                                                                }}
                                                                dayPickerProps={{
                                                                    weekStartsOn: 1,
                                                                }}
                                                                inputProps={{
                                                                    onKeyDown: (e) => {
                                                                        e.preventDefault();
                                                                    },
                                                                }}
                                                                canClearSelection={false}
                                                                minDate={moment()
                                                                    .subtract(1, 'years')
                                                                    .toDate()}
                                                                maxDate={
                                                                    values?.endDateOfAssignment ||
                                                                    moment()
                                                                        .add(1, 'years')
                                                                        .toDate()
                                                                }
                                                                value={
                                                                    values?.startDateOfAssignment
                                                                        ? new Date(
                                                                              values?.startDateOfAssignment,
                                                                          ).toISOString()
                                                                        : null
                                                                }
                                                            />
                                                            {errors?.startDateOfAssignment && (
                                                                <Error
                                                                    errors={[
                                                                        errors?.startDateOfAssignment,
                                                                    ]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : values?.startDateOfAssignment ? (
                                                        formatDate(values?.startDateOfAssignment)
                                                    ) : (
                                                        '-'
                                                    )}
                                                </dd>
                                                <dt className="person-fields__title">
                                                    End date of assignment
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="endDateOfAssignment">
                                                            <DateInput3
                                                                showTimezoneSelect={false}
                                                                dayPickerProps={{
                                                                    weekStartsOn: 1,
                                                                }}
                                                                {...momentFormatter(dateFormat)}
                                                                onChange={async (value) => {
                                                                    setFieldValue(
                                                                        'endDateOfAssignment',
                                                                        value
                                                                            ? dateWithoutTime(value)
                                                                            : null,
                                                                    );
                                                                    if (!value) {
                                                                        setFieldValue(
                                                                            'reasonForEnding',
                                                                            null,
                                                                        );
                                                                    }
                                                                }}
                                                                minDate={
                                                                    values?.startDateOfAssignment ||
                                                                    moment()
                                                                        .subtract(1, 'years')
                                                                        .toDate()
                                                                }
                                                                disabled={
                                                                    !values?.startDateOfAssignment
                                                                }
                                                                maxDate={moment()
                                                                    .add(1, 'years')
                                                                    .toDate()}
                                                                value={
                                                                    values?.endDateOfAssignment
                                                                        ? values?.endDateOfAssignment
                                                                        : null
                                                                }
                                                            />
                                                        </FormGroup>
                                                    ) : values?.endDateOfAssignment ? (
                                                        formatDate(values?.endDateOfAssignment)
                                                    ) : (
                                                        '-'
                                                    )}
                                                </dd>
                                                <dt className="person-fields__title">
                                                    Job Title *
                                                </dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="jobTitle">
                                                            <InputGroup
                                                                id="jobTitle"
                                                                name="jobTitle"
                                                                onChange={handleChange}
                                                                value={values?.jobTitle}
                                                                large
                                                            />
                                                            {errors?.jobTitle && (
                                                                <Error
                                                                    errors={[errors?.jobTitle]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        values?.jobTitle
                                                    )}
                                                </dd>
                                                <dt className="person-fields__title">Location</dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="locations">
                                                            <Select
                                                                options={
                                                                    locationOptions
                                                                        ? locationOptions
                                                                        : []
                                                                }
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        'locations',
                                                                        e ?? [],
                                                                    );
                                                                }}
                                                                onMenuOpen={async () => {
                                                                    const locationOptions = await locationsStore.getLocationsByDepartment(
                                                                        values?.department.value,
                                                                    );
                                                                    await this.setState({
                                                                        locationOptions,
                                                                    });
                                                                }}
                                                                value={values?.locations}
                                                                isClearable={false}
                                                                backspaceRemovesValue={false}
                                                                isMulti
                                                                name="locations"
                                                                id="locations"
                                                                isDisabled={!values?.department}
                                                            />
                                                            {errors.locations && (
                                                                <Error
                                                                    errors={[errors.locations]}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    ) : (
                                                        values?.locations
                                                            ?.map((l: any) => l.label)
                                                            ?.join(', ')
                                                    )}
                                                </dd>
                                                <div
                                                    className={
                                                        'department-assignment-form__payRates'
                                                    }
                                                >
                                                    <div>
                                                        <dt className="person-fields__title">
                                                            Pay rate *
                                                        </dt>
                                                        <dd className="person-fields__definition common-select__has-empty">
                                                            {isEdit ? (
                                                                <FormGroup labelFor="department">
                                                                    <Select
                                                                        options={payRatesOptions}
                                                                        onChange={async (
                                                                            e: any,
                                                                        ) => {
                                                                            setFieldValue(
                                                                                'payRates',
                                                                                [e],
                                                                            );
                                                                        }}
                                                                        name="payRates"
                                                                        id="payRates"
                                                                        noOptionsMessage={({
                                                                            inputValue,
                                                                        }) =>
                                                                            !inputValue
                                                                                ? employee.agencyId
                                                                                    ? 'Pay rates related to the selected Role and Agency name are not found'
                                                                                    : 'Pay rates related to the selected Role are not found'
                                                                                : 'Pay rates are not found'
                                                                        }
                                                                        onMenuOpen={async () => {
                                                                            if (employee.agencyId) {
                                                                                await payRatesStore.getPayRatesByRoleAndAgency(
                                                                                    employee
                                                                                        .roles[0],
                                                                                    employee.agencyId,
                                                                                );
                                                                                const payRatesOptions = payRatesStore.payRatesByRoleAndAgency?.map(
                                                                                    (
                                                                                        item: any,
                                                                                    ) => ({
                                                                                        value:
                                                                                            item.id,
                                                                                        label:
                                                                                            item.name,
                                                                                    }),
                                                                                );
                                                                                await this.setState(
                                                                                    {
                                                                                        payRatesOptions,
                                                                                    },
                                                                                );
                                                                            } else {
                                                                                await payRatesStore.getPayRatesByRole(
                                                                                    employee
                                                                                        .roles[0],
                                                                                );
                                                                                const payRatesOptions = payRatesStore.payRatesByRole?.map(
                                                                                    (
                                                                                        item: any,
                                                                                    ) => ({
                                                                                        value:
                                                                                            item.id,
                                                                                        label:
                                                                                            item.name,
                                                                                    }),
                                                                                );
                                                                                await this.setState(
                                                                                    {
                                                                                        payRatesOptions,
                                                                                    },
                                                                                );
                                                                            }
                                                                        }}
                                                                        isLoading={
                                                                            !values &&
                                                                            !payRatesOptions.length
                                                                        }
                                                                        isDisabled={
                                                                            (!createActionType &&
                                                                                !values?.isDepartmentEditable) ||
                                                                            (!values?.department &&
                                                                                !values?.costCentre) ||
                                                                            (!createActionType &&
                                                                                values?.payRatesFuture)
                                                                        }
                                                                        value={values?.payRates}
                                                                    />
                                                                    {errors?.payRates && (
                                                                        <Error
                                                                            errors={[
                                                                                errors?.payRates,
                                                                            ]}
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                            ) : (
                                                                `${values?.payRates[0]?.label}`
                                                            )}
                                                        </dd>
                                                    </div>
                                                    {isEdit && !createActionType && (
                                                        <Button
                                                            icon="edit"
                                                            outlined={true}
                                                            intent="primary"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'showFuturePayRate',
                                                                    !values?.showFuturePayRate,
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                {!createActionType &&
                                                    (values?.payRatesFuture?.length ||
                                                        values?.showFuturePayRate) && (
                                                        <>
                                                            <dt className="person-fields__title info__title--secondary">
                                                                Pay rate update *
                                                            </dt>
                                                            <dd className="person-fields__definition common-select__has-empty">
                                                                {isEdit ? (
                                                                    <FormGroup labelFor="payRatesFuture">
                                                                        <Select
                                                                            options={
                                                                                payRatesFutureOptions
                                                                            }
                                                                            onChange={async (
                                                                                e: any,
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'payRatesFuture',
                                                                                    [e],
                                                                                );
                                                                            }}
                                                                            name="payRatesFuture"
                                                                            id="payRatesFuture"
                                                                            noOptionsMessage={({
                                                                                inputValue,
                                                                            }) =>
                                                                                !inputValue
                                                                                    ? employee.agencyId
                                                                                        ? 'Pay rates related to the selected Role and Agency name are not found'
                                                                                        : 'Pay rates related to the selected Role are not found'
                                                                                    : 'Pay rates are not found'
                                                                            }
                                                                            onMenuOpen={async () => {
                                                                                if (
                                                                                    employee.agencyId
                                                                                ) {
                                                                                    await payRatesStore.getPayRatesByRoleAndAgency(
                                                                                        employee
                                                                                            .roles[0],
                                                                                        employee.agencyId,
                                                                                    );
                                                                                    const payRatesFutureOptions = payRatesStore.payRatesByRoleAndAgency
                                                                                        ?.filter(
                                                                                            (
                                                                                                pr: any,
                                                                                            ) =>
                                                                                                pr.id !==
                                                                                                (values as any)
                                                                                                    .payRates[0]
                                                                                                    .value,
                                                                                        )
                                                                                        ?.map(
                                                                                            (
                                                                                                item: any,
                                                                                            ) => ({
                                                                                                value:
                                                                                                    item.id,
                                                                                                label:
                                                                                                    item.name,
                                                                                            }),
                                                                                        );
                                                                                    await this.setState(
                                                                                        {
                                                                                            payRatesFutureOptions,
                                                                                        },
                                                                                    );
                                                                                } else {
                                                                                    await payRatesStore.getPayRatesByRole(
                                                                                        employee
                                                                                            .roles[0],
                                                                                    );
                                                                                    const payRatesFutureOptions = payRatesStore.payRatesByRole
                                                                                        ?.filter(
                                                                                            (
                                                                                                pr: any,
                                                                                            ) =>
                                                                                                pr.id !==
                                                                                                (values as any)
                                                                                                    .payRates[0]
                                                                                                    .value,
                                                                                        )
                                                                                        ?.map(
                                                                                            (
                                                                                                item: any,
                                                                                            ) => ({
                                                                                                value:
                                                                                                    item.id,
                                                                                                label:
                                                                                                    item.name,
                                                                                            }),
                                                                                        );
                                                                                    await this.setState(
                                                                                        {
                                                                                            payRatesFutureOptions,
                                                                                        },
                                                                                    );
                                                                                }
                                                                            }}
                                                                            isLoading={
                                                                                !values &&
                                                                                !payRatesOptions.length
                                                                            }
                                                                            value={
                                                                                values?.payRatesFuture
                                                                            }
                                                                            isDisabled={
                                                                                values?.payRatesFuture &&
                                                                                values?.payRatesFutureDate
                                                                            }
                                                                        />
                                                                        {errors.payRatesFuture && (
                                                                            <Error
                                                                                errors={[
                                                                                    errors.payRatesFuture,
                                                                                ]}
                                                                            />
                                                                        )}
                                                                    </FormGroup>
                                                                ) : (
                                                                    `${values?.payRatesFuture[0].label}`
                                                                )}
                                                            </dd>
                                                            <div
                                                                className={
                                                                    'department-assignment-form__payRates'
                                                                }
                                                            >
                                                                <div>
                                                                    <dt className="person-fields__title info__title--secondary">
                                                                        Date of update *
                                                                    </dt>
                                                                    <dd className="person-fields__definition">
                                                                        {isEdit ? (
                                                                            <FormGroup labelFor="payRatesFutureDate">
                                                                                <DateInput3
                                                                                    showTimezoneSelect={
                                                                                        false
                                                                                    }
                                                                                    {...momentFormatter(
                                                                                        dateFormat,
                                                                                    )}
                                                                                    onChange={(
                                                                                        value,
                                                                                    ) =>
                                                                                        setFieldValue(
                                                                                            'payRatesFutureDate',
                                                                                            dateWithoutTime(
                                                                                                value,
                                                                                            ),
                                                                                        )
                                                                                    }
                                                                                    minDate={moment()
                                                                                        .add(
                                                                                            1,
                                                                                            'days',
                                                                                        )
                                                                                        .toDate()}
                                                                                    maxDate={moment()
                                                                                        .add(
                                                                                            1,
                                                                                            'years',
                                                                                        )
                                                                                        .toDate()}
                                                                                    value={
                                                                                        values?.payRatesFutureDate
                                                                                            ? values?.payRatesFutureDate
                                                                                            : null
                                                                                    }
                                                                                    disabled={
                                                                                        values?.payRatesFuture &&
                                                                                        values?.payRatesFutureDate
                                                                                    }
                                                                                />
                                                                                {errors.payRatesFutureDate && (
                                                                                    <Error
                                                                                        errors={[
                                                                                            errors?.payRatesFutureDate,
                                                                                        ]}
                                                                                    />
                                                                                )}
                                                                            </FormGroup>
                                                                        ) : values?.payRatesFutureDate ? (
                                                                            formatDate(
                                                                                values?.payRatesFutureDate,
                                                                            )
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </dd>
                                                                </div>
                                                                {isEdit && (
                                                                    <Button
                                                                        icon="trash"
                                                                        outlined={true}
                                                                        intent="danger"
                                                                        onClick={() => {
                                                                            if (
                                                                                values?.payRatesFutureDate ||
                                                                                values?.payRatesFuture
                                                                            ) {
                                                                                return this.onPayRateDelete();
                                                                            } else {
                                                                                setFieldValue(
                                                                                    'showFuturePayRate',
                                                                                    false,
                                                                                );
                                                                            }
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                {values?.endDateOfAssignment && (
                                                    <>
                                                        <dt className="person-fields__title">
                                                            Reason for ending assignment
                                                        </dt>
                                                        <dd className="person-fields__definition">
                                                            {isEdit ? (
                                                                <FormGroup labelFor="reasonForEnding">
                                                                    <InputGroup
                                                                        id="reasonForEnding"
                                                                        name="reasonForEnding"
                                                                        onChange={handleChange}
                                                                        value={
                                                                            values?.reasonForEnding
                                                                        }
                                                                        large
                                                                    />
                                                                    {errors?.reasonForEnding && (
                                                                        <Error
                                                                            errors={[
                                                                                errors?.reasonForEnding,
                                                                            ]}
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                            ) : values?.reasonForEnding ? (
                                                                values?.reasonForEnding
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </dd>
                                                    </>
                                                )}

                                                <dt className="person-fields__title">Comments</dt>
                                                <dd className="person-fields__definition">
                                                    {isEdit ? (
                                                        <FormGroup labelFor="comments">
                                                            <TextArea
                                                                id="comments"
                                                                name="comments"
                                                                onChange={handleChange}
                                                                style={{ width: '100%' }}
                                                                value={values?.comments}
                                                            />
                                                        </FormGroup>
                                                    ) : values?.comments ? (
                                                        values?.comments
                                                    ) : (
                                                        '-'
                                                    )}
                                                </dd>
                                            </dl>

                                            {!isEdit ? (
                                                <div
                                                    className={`department-assignment-form__footer department-assignment-form__footer--edit ${
                                                        createActionType
                                                            ? 'department-assignment-form__footer--edit-cr'
                                                            : ''
                                                    }`}
                                                >
                                                    {!values?.isArchived && (
                                                        <>
                                                            <Button
                                                                large
                                                                outlined
                                                                onClick={() =>
                                                                    this.setState({
                                                                        isEdit: !this.state.isEdit,
                                                                    })
                                                                }
                                                            >
                                                                Edit
                                                            </Button>
                                                            {!createActionType && (
                                                                <Button
                                                                    large
                                                                    intent="warning"
                                                                    outlined
                                                                    disabled={isSubmitting}
                                                                    onClick={() =>
                                                                        this.onArchive(
                                                                            values?.endDateOfAssignment,
                                                                        )
                                                                    }
                                                                >
                                                                    Archive
                                                                </Button>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ) : (
                                                <div
                                                    className={'department-assignment-form__footer'}
                                                >
                                                    <Button
                                                        large
                                                        outlined
                                                        onClick={() =>
                                                            formWasEdited
                                                                ? this.props.history.replace(
                                                                      this.props.history.location
                                                                          .pathname,
                                                                  )
                                                                : onFormCancel()
                                                        }
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        large
                                                        intent="success"
                                                        outlined
                                                        onClick={() => {
                                                            validateForm();
                                                            return submitForm();
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </div>
                                            )}
                                        </form>
                                        <ConfirmationAlert
                                            confirmButtonText="Yes"
                                            onConfirm={() => {
                                                setFieldValue('showFuturePayRate', false);
                                                setFieldValue('payRatesFuture', null);
                                                setFieldValue('payRatesFutureDate', null);
                                                return this.onPayRateDelete();
                                            }}
                                            onCancel={() => this.onPayRateDelete()}
                                            isOpen={this.state.showPrDeleteAlert}
                                            title="Confirm deletion!"
                                            description="Are you sure you want to delete the changed pay rate?"
                                        />
                                        <ConfirmationAlert
                                            confirmButtonText="Yes"
                                            onConfirm={async () => {
                                                await departmentAssignmentsStore.archiveDepartmentAssignment(
                                                    values?.id,
                                                );
                                                return this.onCloseArchiveAlert();
                                            }}
                                            onCancel={() => this.onCloseArchiveAlert()}
                                            isOpen={this.state.showArchiveViaNoDateAlert}
                                            title="Confirm archiving!"
                                            icon={'archive'}
                                            intent={'warning'}
                                            description="No end date has been set. Proceeding will set this the current date. Would you like to proceed?"
                                        />
                                        <ConfirmationAlert
                                            confirmButtonText="Yes"
                                            onConfirm={async () => {
                                                await departmentAssignmentsStore.archiveDepartmentAssignment(
                                                    values?.id,
                                                );
                                                return this.onCloseArchiveAlert();
                                            }}
                                            icon={'archive'}
                                            intent={'warning'}
                                            onCancel={() => this.onCloseArchiveAlert()}
                                            isOpen={this.state.showArchiveViaFutureDateAlert}
                                            title="Confirm archiving!"
                                            description="The end date is already set for a date in the future. Proceeding will reset the date to the current date. Would you like to proceed?"
                                        />
                                        <ConfirmationAlert
                                            confirmButtonText="Yes"
                                            onConfirm={async () => {
                                                await departmentAssignmentsStore.archiveDepartmentAssignment(
                                                    values?.id,
                                                );
                                                return this.onCloseArchiveAlert();
                                            }}
                                            icon={'archive'}
                                            intent={'warning'}
                                            onCancel={() => this.onCloseArchiveAlert()}
                                            isOpen={this.state.showArchiveViaPastOrCurrentDateAlert}
                                            title="Confirm archiving!"
                                            description="Are you sure you want to archive this record? This action is irreversible."
                                        />
                                        <RouterPrompt
                                            when={this.state.formWasEdited}
                                            onPromptConfirm={this._handleOnCancelConfirm}
                                        />
                                    </section>
                                );
                            }}
                        </Formik>
                    </>
                );
            }
        },
    ),
);

export default DepartmentAssignments;
