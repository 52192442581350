import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';
import { Icon, Tabs, Tab } from '@blueprintjs/core';

import Modal from '../../modal/Modal';
import { formatDisplayDateTime } from '../../helpers/formatData';
import { getHcpName } from '../../../../helpers/ucr';
import { genderMapping } from '../../../../constants/mainConst';

const UserDetails = inject('RootStore')(
    observer(
        class UserDetails extends Component {
            componentDidMount() {
                const {
                    configStore: { loaded, isFeatureEnabled },
                    schedulesStore: { fetchSchedules },
                } = this.props.RootStore;

                when(
                    () => loaded,
                    () => {
                        if (isFeatureEnabled('scheduledJobs')) {
                            fetchSchedules();
                        }
                    },
                );
            }

            _handleCloseClick = () => {
                this.props.history.push(`/users`);
            };

            _renderDetails = (user) => {
                const {
                    hcpTypes,
                    email,
                    phoneNumber,
                    available,
                    maxDriveTimeMinutes,
                    preferences: { specialities = [], languages } = {},
                    currentPractices = [],
                    userId,
                    gender,
                    lastUpdatedBy,
                    lastUpdatedAt,
                } = user;

                const {
                    usersStore: { users },
                } = this.props.RootStore;

                const notificationsEnabled = available ? 'on' : 'off';

                return (
                    <div>
                        <dl className="info">
                            <dt className="info__title">Notifications</dt>
                            <dd className="info__definition">
                                <span
                                    className={`users-table__notifications users-table__notifications--${notificationsEnabled}`}
                                >
                                    <Icon icon={available ? 'tick-circle' : 'ban-circle'} />
                                    <span className="users-table__notifications-label">
                                        {notificationsEnabled}
                                    </span>
                                </span>
                            </dd>
                            <dt className="info__title">HCP Type(s)</dt>
                            <dd className="info__definition">{hcpTypes.join(', ')}</dd>
                            <dt className="info__title">User ID</dt>
                            <dd className="info__definition">{userId}</dd>
                            <dt className="info__title">Email</dt>
                            <dd className="info__definition">
                                <a href={`mailto:${email}`}>{email}</a>
                            </dd>
                            <dt className="info__title">Phone number</dt>
                            <dd className="info__definition">{phoneNumber}</dd>
                            <dt className="info__title">Current practice(s)</dt>
                            <dd className="info__definition">
                                {currentPractices
                                    .map(({ name, odsCode }) => `${name} (${odsCode})`)
                                    .join(', ')}
                            </dd>
                            <dt className="info__title">Maximum drive time</dt>
                            <dd className="info__definition">
                                {maxDriveTimeMinutes && `${maxDriveTimeMinutes} mins`}
                            </dd>
                            <dt className="info__title">Specialities</dt>
                            <dd className="info__definition">{specialities.join(', ')}</dd>
                        </dl>
                        <h3 className="h3">Demographics</h3>
                        <dl className="info">
                            <dt className="info__title">Gender</dt>
                            <dd className="info__definition">
                                {gender ? genderMapping[gender] : 'Not set'}
                            </dd>
                            <dt className="info__title">Languages</dt>
                            <dd className="info__definition">
                                {languages?.length > 0 ? languages.join(', ') : 'Not set'}
                            </dd>
                            <dt className="info__title">Last changed on</dt>
                            <dd className="info__definition">
                                {lastUpdatedAt ? formatDisplayDateTime(lastUpdatedAt) : null}
                            </dd>
                            <dt className="info__title">Last changed by</dt>
                            <dd className="info__definition">{getHcpName(users, lastUpdatedBy)}</dd>
                        </dl>
                    </div>
                );
            };

            _renderSchedules = (schedules) => {
                if (schedules.length === 0) return 'User currently has no scheduled availability.';

                return (
                    <table className="bp5-html-table bp5-html-table-striped users-table">
                        <thead>
                            <tr>
                                <th>From</th>
                                <th>To</th>
                                <th>Available</th>
                                <th>Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schedules.map(
                                ({ startDateTime, endDateTime, type, reason, scheduleId }) => {
                                    const available = type === 'AVAILABLE' ? 'yes' : 'no';

                                    return (
                                        <tr key={scheduleId}>
                                            <td>{formatDisplayDateTime(startDateTime)}</td>
                                            <td>{formatDisplayDateTime(endDateTime)}</td>
                                            <td>
                                                <span
                                                    className={`users-table__available users-table__available--${available}`}
                                                >
                                                    <Icon
                                                        icon={
                                                            available === 'yes'
                                                                ? 'tick-circle'
                                                                : 'ban-circle'
                                                        }
                                                    />
                                                    <span className="users-table__available-label">
                                                        {available}
                                                    </span>
                                                </span>
                                            </td>
                                            <td>{type === 'UNAVAILABLE' && reason}</td>
                                        </tr>
                                    );
                                },
                            )}
                        </tbody>
                    </table>
                );
            };

            render() {
                const {
                    RootStore: {
                        usersStore: { users },
                        configStore: { isFeatureEnabled },
                        schedulesStore: { allSchedules },
                    },
                } = this.props;

                const { userId } = this.props.match.params;

                const user = users.find((user: any) => user.userId === userId);
                const schedules = allSchedules.filter((schedule) => schedule.userId === userId);

                if (!user) {
                    return (
                        <Modal title="User not found" onClose={this._handleCloseClick} shadow>
                            <p>
                                No user found with id: <strong>{userId}</strong>
                            </p>
                        </Modal>
                    );
                }

                return (
                    <Modal title={user.userName} onClose={this._handleCloseClick} shadow>
                        <Tabs defaultSelectedTabId="details" large>
                            <Tab id="details" title="Details" panel={this._renderDetails(user)} />
                            {isFeatureEnabled('scheduledJobs') && (
                                <Tab
                                    id="availability"
                                    title="Availability"
                                    panel={this._renderSchedules(schedules)}
                                />
                            )}
                        </Tabs>
                    </Modal>
                );
            }
        },
    ),
);

export default UserDetails;
