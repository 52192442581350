import React from 'react';
import moment from 'moment';
import { Button, Checkbox, Icon } from '@blueprintjs/core';

import { formatFileSize, formatDate } from '../../helpers/formatData';

import type { NormalizedDocument } from '../../../../stores/DocumentStore';
import { getDocumentColor } from '../../../../helpers/common';
import { daysDifferenceWithToday } from '../../helpers/formatData';
import { DOCUMENT_VERIFIED_DICTIONARY } from '../../../../constants/hrConst';

interface DocumentsItemProps extends NormalizedDocument {
    onClick: (id: string, event: React.MouseEvent) => void;
    onDownload: (key: string) => Promise<void>;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
    categoriesDictionary: any;
    isEmployee: boolean;
    onHideDocument: (documentId: string) => void;
    isEmployeeArchived: boolean;
}

const DocumentsItem = ({
    onClick,
    id,
    selected,
    onToggle,
    expiresAt,
    expiresAtDate,
    fileName,
    categoryName,
    sizeInBytes,
    isArchived,
    isVerified,
    verifiedPerson,
    updatedAt,
    isEmployee,
    required,
    onHideDocument,
    isEmployeeArchived,
}: DocumentsItemProps) => {
    const documentInfo = getDocumentColor(expiresAtDate);
    const isEmpty = fileName.length === 0;

    const momentExpiredDate = moment(expiresAtDate);
    const daysToExpired = daysDifferenceWithToday(momentExpiredDate);

    const isWarning = !isEmpty && daysToExpired < 28;
    const isDanger =
        (isEmpty && required) ||
        (!isEmpty && required && (!isVerified || isArchived || daysToExpired < 1));
    let exclamationClass = '';

    if (isDanger) {
        exclamationClass = 'danger__cell';
    } else if (isWarning) {
        exclamationClass = 'warning__cell';
    }

    const renderFistColumn = () => {
        if (!required) return '';

        return !isWarning && !isDanger ? (
            <span className="success__cell">
                <Icon icon="tick" />
            </span>
        ) : (
            <span className={exclamationClass}>
                <Icon icon="warning-sign" />
            </span>
        );
    };

    return (
        <tr
            onClick={(event) => {
                if (!id || id.length === 0) return;
                onClick(id, event);
            }}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''} ${
                documentInfo?.documentClass ?? ''
            }`}
        >
            {isEmployee ? (
                <td>{renderFistColumn()}</td>
            ) : (
                <td>
                    <Checkbox
                        id={id}
                        checked={selected}
                        onChange={onToggle}
                        onClick={(event) => event.stopPropagation()}
                    />
                </td>
            )}
            <td>
                {categoryName} {required && ' *'}
            </td>
            <td>{fileName}</td>
            <td className="common-table__cell-no-wrap">{formatFileSize(sizeInBytes)}</td>
            <td>
                {expiresAt}
                <span className="document-row__info">
                    {documentInfo?.text && <Icon icon="warning-sign" />}
                    {documentInfo?.text ?? ''}
                </span>
            </td>
            {!isEmployee && <td>{isArchived ? 'Archived' : 'Active'}</td>}
            {isEmpty ? (
                <td></td>
            ) : (
                <td
                    style={{
                        color: DOCUMENT_VERIFIED_DICTIONARY[isVerified.toString()]?.color,
                    }}
                >
                    <Icon
                        icon={DOCUMENT_VERIFIED_DICTIONARY[isVerified.toString()]?.icon}
                        className="header__tab-icon"
                    />
                    {DOCUMENT_VERIFIED_DICTIONARY[isVerified.toString()]?.text}
                </td>
            )}
            <td>
                {verifiedPerson ? `${verifiedPerson.firstName} ${verifiedPerson.lastName}` : ''}
            </td>
            {isEmpty ? <td></td> : <td>{formatDate(updatedAt)}</td>}
            {isEmployee && !isEmployeeArchived && (
                <td>{!required && <Button onClick={() => onHideDocument(id)}>Hide</Button>}</td>
            )}
        </tr>
    );
};

export default DocumentsItem;
