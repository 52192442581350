import { gql } from '@apollo/client';

export const GET_AGENCIES_LIST = gql`
    query GetAgencies(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: AgencyFilter
    ) {
        getAgencies(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    createdBy
                    updatedBy
                    isArchived
                    isDeleted
                    updatedAt
                    updatedBy
                    createdPerson {
                        id
                        firstName
                        lastName
                    }
                    updatedPerson {
                        id
                        firstName
                        lastName
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const CREATE_AGENCY = gql`
    mutation CreateAgency($data: AgencyEntityInput!) {
        createAgency(data: $data) {
            id
        }
    }
`;

export const UPDATE_AGENCY = gql`
    mutation UpdateAgency($id: String!, $data: AgencyEntityInput!) {
        updateAgency(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const DELETE_AGENCY = gql`
    mutation DeleteAgency($id: String!) {
        deleteAgency(id: $id) {
            id
        }
    }
`;

export const GET_AGENCY = gql`
    query GetAgencyById($id: String!) {
        getAgencyById(id: $id) {
            id
            name
            createdAt
            createdBy
            updatedBy
            isArchived
            isDeleted
            updatedAt
            updatedBy
            createdPerson {
                id
                firstName
                lastName
            }
            updatedPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const VALIDATE_ARCHIVE_AGENCIES = gql`
    query IsAgencyArchivable($ids: [String!]!) {
        isAgencyArchivable(ids: $ids) {
            id
            name
            isArchivable
            relatedStaff
            relatedPayRate
        }
    }
`;

export const BULK_DELETE_AGENCIES = gql`
    mutation BulkDeleteAgency($id: [String!]!) {
        bulkDeleteAgency(id: $id) {
            count
        }
    }
`;
export const BULK_ARCHIVE_AGENCIES = gql`
    mutation BulkArchiveAgency($id: [String!]!) {
        bulkArchiveAgency(id: $id) {
            count
        }
    }
`;

export const GET_AGENCY_BY_NAME = gql`
    query GetAgencyByName($name: String!) {
        getAgencyByName(name: $name) {
            id
            name
        }
    }
`;

export const ARCHIVE_AGENCY = gql`
    mutation ArchiveAgency($id: String!) {
        archiveAgency(id: $id) {
            id
        }
    }
`;
