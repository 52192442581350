import moment from 'moment';
import useStores from './useStores';
import { useMemo } from 'react';
import { useScheduleWarnings } from './useVisitWarnings';
import { VisitValuesType } from '../components/pages/ucr/blocks/panels/VisitDetailsTypes';
import { warningType } from '../components/pages/ucr/forms/common';
import {
    getMaxSchedule,
    getSortedHcpHours,
} from '../components/pages/ucr/components/calendar/HcpAvailability/HcpAvailabilityFunctions';

const NOT_AT_WORK = 'Not at work';

export function useHcpSuggestionOptions(patient: VisitValuesType) {
    const {
        RootStore: {
            usersStore: { hcpUsers, getEnabledUsers },
            schedulesStore: { allSchedulesByDate },
            configStore: { bands },
        },
    } = useStores() as { RootStore: any };

    const hcpIds = useMemo(() => {
        return getEnabledUsers(hcpUsers).map(({ userId }: { userId: string }) => userId);
    }, [hcpUsers, getEnabledUsers]);

    const visitScheduleWarnings = useScheduleWarnings(patient, hcpIds);

    return useMemo(() => {
        return [
            ...getEnabledUsers(hcpUsers).map(
                ({
                    userId,
                    userName,
                    absent,
                    band,
                }: {
                    userId: string;
                    userName: string;
                    absent: boolean;
                    band: string;
                }) => {
                    const hcpSchedules = allSchedulesByDate?.filter(
                        (s: any) => s.userId === userId,
                    );
                    const label = getAvailableHours(
                        hcpSchedules,
                        absent,
                        visitScheduleWarnings,
                        userId,
                    );

                    const bandVal = bands.find(({ value }: { value: string }) => value === band);

                    return {
                        label: `${userName} ${
                            bandVal?.label ? `( ${bandVal.label} )` : ''
                        } - ${label}`,
                        value: userId,
                        isWorking: label !== NOT_AT_WORK,
                    };
                },
            ),
        ];
    }, [allSchedulesByDate, bands, getEnabledUsers, hcpUsers, visitScheduleWarnings]);
}

export function getAvailableHours(
    hcpSchedules: any,
    isSick: boolean,
    visitWarnings: warningType[],
    hcpId?: string,
) {
    if (!hcpSchedules?.length || isSick) return NOT_AT_WORK;

    const schedulesConflictWarnings = visitWarnings?.filter(
        (w) => w.category === 'HCP_AVAILABILITY_CONFLICT' && w?.data?.hcpId === hcpId,
    );

    if (schedulesConflictWarnings?.length) {
        const sortedHcpSchedules = getSortedHcpHours(hcpSchedules);
        const hcpHcpAvailableHours = sortedHcpSchedules.length
            ? getMaxSchedule(sortedHcpSchedules)
            : null;

        const formattedStartDateTime = moment(hcpHcpAvailableHours?.startDateTime).format('HH:mm');
        const formattedEndDateTime = moment(hcpHcpAvailableHours?.endDateTime).format('HH:mm');

        return `Working: ${formattedStartDateTime}-${formattedEndDateTime}`;
    }

    return 'Working during planned time';
}
