import React, { Component } from 'react';
import { Tab, Tabs } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import Week from './Week';

const ShiftPatternGridView = inject('RootStore')(
    observer(
        class ShiftPatternWeeks extends Component {
            state = {
                activeTab: 1,
            };

            componentDidMount() {
                Object.keys(this.props.RootStore.shiftPatternsStore.newShiftPattern).length === 0 &&
                    this.props.RootStore.shiftPatternsStore.initializeShiftPattern();
            }

            render() {
                const {
                    values,
                    editOrCreateMode,
                    actionType,
                    history,
                    shiftPatternsStore,
                    updateShiftPattern,
                    generateFilterOptions,
                } = this.props;

                return (
                    <>
                        <Tabs
                            className={'tabs-new tabs-new--shift-patterns'}
                            id="rota-builder__tabs"
                            large
                        >
                            {shiftPatternsStore.newShiftPattern.weeks
                                ?.slice()
                                .sort()
                                ?.map((week, index) => {
                                    return (
                                        <Tab
                                            id={index + 1}
                                            key={index}
                                            title={`Week ${index + 1}`}
                                            panel={
                                                <Week
                                                    week={week}
                                                    key={index}
                                                    actionType={actionType}
                                                    editOrCreateMode={editOrCreateMode}
                                                    history={history}
                                                    shiftPatternsStore={shiftPatternsStore}
                                                    values={values}
                                                    updateShiftPattern={updateShiftPattern}
                                                    generateFilterOptions={generateFilterOptions}
                                                />
                                            }
                                        />
                                    );
                                })}
                        </Tabs>
                    </>
                );
            }
        },
    ),
);

export default ShiftPatternGridView;
