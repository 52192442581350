import React from 'react';
import { HTMLTable, AnchorButton } from '@blueprintjs/core';

import { formatDisplayDate } from '../../modules/helpers/formatData';

export default function AdminTable(props) {
    return props.reports.length > 0 ? (
        <HTMLTable striped={true} bordered={true} className="admin__table">
            <thead>
                <tr>
                    <th>Report date</th>
                    <th>File size</th>
                    <th>Download report</th>
                </tr>
            </thead>
            <tbody>
                {props.reports.map((file) => (
                    <tr key={file.file}>
                        <td>{formatDisplayDate(file.file.replace('.csv', ''))}</td>
                        <td>
                            {file.size >= 1024
                                ? `${(file.size / 1024).toFixed(1)} KB`
                                : `${file.size} B`}
                        </td>
                        <td>
                            <AnchorButton href={file.url} icon="cloud-download" intent="primary">
                                Download Report
                            </AnchorButton>
                        </td>
                    </tr>
                ))}
            </tbody>
        </HTMLTable>
    ) : (
        <p>There are no reports to show</p>
    );
}
