import React from 'react';

const ArchivePersonConfirmationPopUpText = (
    listOfNotValidPeopleForArchive: [],
    listOfStaffNotValidForArchive: [],
) => {
    if (!listOfNotValidPeopleForArchive.length && !listOfStaffNotValidForArchive.length) {
        return 'Are you sure you want to archive the selected person record(s)? This action is irreversible, and all related staff records will be archived as well';
    }

    if (listOfNotValidPeopleForArchive.length && !listOfStaffNotValidForArchive.length) {
        return (
            <div>
                <p>
                    The following person record(s) do not have a Continuous Employment End Date set,
                    or their end date is already set for the future:
                </p>
                <ul>
                    {listOfNotValidPeopleForArchive.map((item: any) => (
                        <li>
                            <a href={`/hr/people/${item.id}`} target="blank">
                                {item.person}
                            </a>
                        </li>
                    ))}
                </ul>
                <p>
                    Proceeding with the Archive operation will set the end dates for affected
                    records to today’s date
                </p>
            </div>
        );
    }

    if (!listOfNotValidPeopleForArchive.length && listOfStaffNotValidForArchive.length) {
        return (
            <div>
                <p>
                    The following staff record(s) do not have an End Date of Engagement and/or End
                    Date of Department Assignment set, or their end dates are already set for the
                    future:
                </p>
                <ul>
                    {listOfStaffNotValidForArchive.map((item: any) => (
                        <li>
                            <a href={`/hr/${item.staffType}/${item.id}`} target="blank">
                                {`${item.person}: ${item.role}`}
                            </a>
                        </li>
                    ))}
                </ul>
                <p>
                    Proceeding with the Archive operation will set the end dates for affected
                    records to today’s date.
                </p>
            </div>
        );
    }

    return (
        <div>
            <p>
                The following person record(s) do not have a Continuous Employment End Date set, or
                their end date is already set for the future:
            </p>
            <ul>
                {listOfNotValidPeopleForArchive.map((item: any) => (
                    <li>
                        <a href={`/hr/people/${item.id}`} target="blank">
                            {item.person}
                        </a>
                    </li>
                ))}
            </ul>
            <p>
                The following staff record(s) do not have an End Date of Engagement and/or End Date
                of Department Assignment set, or their end dates are already set for the future:
            </p>
            <ul>
                {listOfStaffNotValidForArchive.map((item: any) => (
                    <li>
                        <a href={`/hr/${item.staffType}/${item.id}`} target="blank">
                            {`${item.person}: ${item.role}`}
                        </a>
                    </li>
                ))}
            </ul>
            <p>
                Proceeding with the Archive operation will set the end dates for affected records to
                today’s date.
            </p>
        </div>
    );
};

export default ArchivePersonConfirmationPopUpText;
