import React from 'react';
import WeekDay from './WeekDay';

const Week = (props: any) => {
    const {
        weekNumber,
        department,
        entries,
        onAdd,
        onDutyStationClick,
        startDay,
        endDay,
        locations,
        onDelete,
        onSplit,
        shiftStatuses,
        shiftTypes,
        rotaStartDate,
        staffStore,
        holidayDates,
        payRatesDictionary,
        rolesDictionary,
        isPublished,
        openPaymentDialog,
        paymentStatusNormalized,
        isViewMode,
    } = props;

    const days = [1, 2, 3, 4, 5, 6, 7].map((d: any) => (
        <WeekDay
            key={d}
            dayOfWeek={d}
            weekNumber={weekNumber}
            department={department}
            items={entries.filter((r: any) => r.dayOfWeek === d && r.weekNumber === weekNumber)}
            onDutyStationClick={onDutyStationClick}
            onAdd={onAdd}
            isViewMode={isViewMode}
            disabled={(startDay && startDay > d) || (endDay && endDay < d)}
            locations={locations}
            shiftStatuses={shiftStatuses}
            onDelete={onDelete}
            onSplit={onSplit}
            shiftTypes={shiftTypes}
            rotaStartDate={rotaStartDate}
            staffStore={staffStore}
            holidayDates={holidayDates}
            payRatesDictionary={payRatesDictionary}
            rolesDictionary={rolesDictionary}
            isPublished={isPublished}
            openPaymentDialog={openPaymentDialog}
            paymentStatusNormalized={paymentStatusNormalized}
        />
    ));

    return <div className="rota-builder__week-days">{days}</div>;
};

export default Week;
