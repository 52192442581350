import React from 'react';
import { Checkbox } from '@blueprintjs/core';

import SortedHeader from '../../../common/sorting/SortedHeader';
import useHeaderSorting from '../../../../hook/useHeaderSorting';

export interface HeaderColumnType {
    key: string;
    label?: string;
    sortable?: boolean;
    render?: Function;
    sortKey?: string;
}

interface Props {
    checked?: boolean;
    isFrontendSort?: boolean;
    onSort?: () => void;
    onToggleSelectAll?: (checked: boolean) => {};
    selectable: boolean;
    columns: HeaderColumnType[];
    allSelected?: boolean;
}

const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const TableHeader = ({
    onSort = () => {},
    isFrontendSort = true,
    onToggleSelectAll,
    checked,
    selectable = false,
    columns,
    allSelected = false,
}: Props) => {
    const { sort, sortData } = useHeaderSorting((s: any) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr>
                {selectable && (
                    <th>
                        <Checkbox
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                                onToggleSelectAll ? onToggleSelectAll(evt.target.checked) : null
                            }
                            checked={allSelected}
                        />
                    </th>
                )}
                {columns.map(({ sortable = true, key, label, sortKey }: HeaderColumnType) =>
                    sortable ? (
                        <SortedHeader
                            key={key}
                            sort={sort}
                            sortBy={sortKey || key}
                            text={label ? label : capitalizeFirstLetter(key)}
                            sortData={sortData}
                        />
                    ) : (
                        <th key={key}>{label ? label : capitalizeFirstLetter(key)}</th>
                    ),
                )}
            </tr>
        </thead>
    );
};

export default TableHeader;
