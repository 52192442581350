import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';

const MFA = ({ didError, onConfirmCode }) => {
    const initialValues = {
        code: '',
    };

    const validationSchema = Yup.object({
        code: Yup.string()
            .matches(/^\d{6}$$/, 'Verification Code must be 6 digits only.')
            .required('This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onConfirmCode}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Multi-factor authentication</h2>

                        <p>
                            We've sent a verification code to your registered phone number, please
                            enter it below to continue. The code will expire in three minutes, if
                            you do not enter it in time you must sign in again.
                        </p>

                        <Input
                            name="code"
                            type="text"
                            inputMode="numeric"
                            label="Verification code"
                            placeholder="e.g. 123456"
                            disabled={isSubmitting}
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Continue"
                            outlined
                            icon="log-in"
                            type="submit"
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default MFA;
