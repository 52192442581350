import { observer } from 'mobx-react';
import moment from 'moment';
import { FC } from 'react';
import useStores from '../../../../../../hook/useStores';
import { getServiceDisplayName } from '../../../forms/common';
import { useView } from '../../../views/useView';
import { NotSet } from '../NotSet';
import { Job } from '../../../../../../__generated__/v2';

export const PatientListJobDetails: FC<{
    job?: Job | null;
    date?: string | null;
    user?: number | null;
    referralPathway?: string | null;
    hideHour?: boolean;
}> = observer(({ job, date, user, referralPathway, hideHour }) => {
    const {
        RootStore: {
            configStore: { pathways },
        },
    } = useStores() as { RootStore: any };

    const { openDeepLink } = useView();

    if (!job) {
        return <td>-</td>;
    }

    const { disposition, id } = job;

    const pathway = pathways.find((pathway: any) => pathway.value === referralPathway);

    return (
        <td
            className="patient-list__job-cell"
            onClick={() => openDeepLink(id || '', `user${user || 1}`)}
        >
            {date ? moment(date).format(hideHour ? 'DD/MM/YYYY' : 'DD/MM/YYYY HH:mm') : <NotSet />}
            <div className="patient-list__job-cell-details">
                {pathway?.label || <NotSet />}
                {', '}
                {pathway?.services && disposition ? (
                    getServiceDisplayName(pathway.services, disposition)
                ) : (
                    <NotSet />
                )}
            </div>
        </td>
    );
});
