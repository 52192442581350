import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@blueprintjs/core';
import { useFormikContext, useField } from 'formik';

import { getUploadParams, uploadFile } from '../../../api/hrRotaApi';
import AppToaster from '../../modules/helpers/Toaster';
import Error from '../../modules/forms/Error';

const FIVE_MEGABYTES = 5242880;
const allowedMIMETypes = [
    'application/pdf',
    'image/jpeg',
    'image/tiff',
    'image/png',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
];

const MultipleFileInput = ({ org, maxFiles = 5 }: { org: string; maxFiles: number }) => {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [selectedFiles, setSelectedFiles] = useState({} as any);
    const [isUploading, setIsUploading] = useState(false);
    const [selectedFileError, setSelectedFileError] = useState('');

    const { values, setValues } = useFormikContext();
    const [, meta] = useField('fileName');

    const existingValues = values as any;

    useEffect(() => {
        if (existingValues.documents.length) {
            const uploadedFilesEditMode = {} as any;
            existingValues.documents.forEach((file: any) => {
                uploadedFilesEditMode[file.key] = file.fileName;
            });
            setSelectedFiles(uploadedFilesEditMode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reset = () => {
        setIsUploading(false);
        setSelectedFile(null);
        setSelectedFileError('');
    };

    const removeFile = (key: string) => {
        setSelectedFiles((prev: any) => {
            const listOfFiles = { ...prev };
            delete listOfFiles[key];
            return listOfFiles;
        });
        const formikListOfFiles = [...existingValues.documents];
        const filteredList = formikListOfFiles.filter((file) => file.key !== key);
        setValues({
            ...existingValues,
            documents: filteredList,
        });
        reset();
    };

    const onDrop = useCallback(
        async ([file]: File[]) => {
            reset();

            const { name: fileName, size: sizeInBytes, type: mimeType } = file;
            setSelectedFile(fileName);

            if (existingValues.documents.length >= maxFiles) {
                setSelectedFileError(`Max number of documents per one absence record ${maxFiles}`);
                return;
            }

            if (sizeInBytes > FIVE_MEGABYTES) {
                setSelectedFileError('File is too large.');
                return;
            }

            if (!allowedMIMETypes.includes(mimeType)) {
                setSelectedFileError('File type is not allowed.');
                return;
            }

            try {
                setIsUploading(true);

                const uploadParams = await getUploadParams(fileName, org);
                await uploadFile(file, uploadParams);

                setValues({
                    ...existingValues,
                    documents: [
                        ...existingValues?.documents,
                        { fileName, sizeInBytes, mimeType, key: uploadParams.key },
                    ],
                });

                setSelectedFiles((prev: any) => ({ ...prev, [uploadParams.key]: fileName }));

                AppToaster.show({
                    message: 'File uploaded successfully!',
                    intent: 'success',
                });
            } catch (err) {
                console.error(err);

                AppToaster.show({
                    message: 'There was an error uploading the file, please try again.',
                    intent: 'danger',
                });
            }
            setIsUploading(false);
        },
        [existingValues, maxFiles, org, setValues],
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div className="multiple-file-upload">
            {Object.entries(selectedFiles).length ? (
                <div className="list-of-uploads">
                    {Object.entries(selectedFiles).map((file: any) => {
                        return (
                            <div key={file[0]} className="uploaded-file">
                                {file[1]}
                                <Icon
                                    icon="cross"
                                    onClick={() => removeFile(file[0])}
                                    className="file-upload__delete-icon"
                                />
                            </div>
                        );
                    })}
                </div>
            ) : null}
            <>
                <label htmlFor="fileUpload">
                    <div {...getRootProps()} className="file-upload__drop-area">
                        {isUploading ? (
                            <>
                                <Icon icon="cloud-upload" className="file-upload__icon" />
                                Uploading {selectedFile}...
                            </>
                        ) : (
                            <>
                                <input name="fileUpload" {...getInputProps()} multiple={false} />
                                <span className="opacity-75">
                                    <Icon icon="upload" className="file-upload__icon" />
                                    {'Upload documents'}
                                    {meta.touched && <Error errors={[meta.error]} />}
                                    {selectedFileError && <Error errors={[selectedFileError]} />}
                                </span>
                            </>
                        )}
                    </div>
                </label>
            </>
        </div>
    );
};

export default MultipleFileInput;
