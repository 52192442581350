import { Patient } from '@doc-abode/data-models';
import { FC, useEffect, useState } from 'react';
import { FormikValues } from 'formik';
import moment from 'moment';
import { JobStatus } from '@doc-abode/data-models';
import { getStringEndTime, presentationNameLMF } from '../../../../../helpers';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';
import { getDateEndTime, getAllWarnings } from '../../../../../helpers/ucr';
import { IconChangeStatus } from '../../../../../helpers/ucr/icons';
import { Button, ButtonElems, ButtonSizes } from '../../../../v2/components';
import useStores from '../../../../../hook/useStores';
import { usePatientFlagsToChangeStatus } from '../../hooks/usePatientFlagsToChangeStatus';
import { dateFormat } from '../../../../../constants/patientsConst';
import { Accordion, AccordionTab, AccordionColors } from '../../../../v2/components';
import { Text } from '../../../../v2/form';
import { FormMode, FormSteps } from '../common';
import { TimeHelperActualStart, TimeHelperActualEnd } from '../../blocks/panels/TimeHelper';
import Warnings, { Warning } from '../../../../v2/form/Warnings';
import RootStore from '../../../../../stores/RootStore';
import { DialogAlerts, Dialogs } from '../../../../../stores/UCRStore';
import { genderMapping } from '../../../../../constants/mainConst';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';

interface Props {
    values: FormikValues;
    onSave: (values: FormikValues) => Promise<void>;
    onEdit: (step: FormSteps) => void;
    formMode: string;
    setVisitData: (values: FormikValues | null) => void;
}

const ReviewForm: FC<Props> = ({ values, onSave, onEdit, formMode, setVisitData }) => {
    const {
        RootStore: {
            usersStore: { users },
            ucrStore: { openedDialogAlert, setOpenedDialogAlert, setOpenedDialog, warnings, jobs },
        },
    } = useStores() as { RootStore: RootStore };

    const patient: Patient = jobs.filter((user: any) => user.id === values.id)[0];
    const { isDisabled } = usePatientFlagsToChangeStatus(patient);

    if (
        openedDialogAlert !== DialogAlerts.SAVE_ADMINISTRATIVE_TIME &&
        formMode !== FormMode.EDIT_VISIT
    ) {
        setOpenedDialogAlert(DialogAlerts.SAVE_ADMINISTRATIVE_TIME);
    }

    const staffFiltered = users
        .filter((user: any) => values.hcpId.includes(user.userId))
        .map((user: any, index: number) => {
            return `#${index + 1} ${user.userName}`;
        });

    const schedulingWarnings: Warning[] = warnings ? warnings[values?.id || ''] : [];
    const [visitStepWarnings, setVisitStepWarnings] = useState<any>({});

    useEffect(() => {
        const endAdminTime = getDateEndTime(values.startTime, values.duration);

        const getVisitOverlap = (item: Patient) => {
            // check to see if overlapping is looking at the same item. it shouldn't be.
            if (item.id !== values.id) {
                const isStartTimeOverlaps =
                    item.hcpId === values.hcpId &&
                    moment(values.startTime).isAfter(item.startDateTime) &&
                    moment(values.startTime).isBefore(
                        getDateEndTime(item.startDateTime, item.duration),
                    );

                const isEndTimeOverlaps =
                    item.hcpId === values.hcpId &&
                    moment(endAdminTime).isAfter(item.startDateTime) &&
                    moment(endAdminTime).isBefore(
                        getDateEndTime(item.startDateTime, item.duration),
                    );

                if (isStartTimeOverlaps) {
                    return isStartTimeOverlaps;
                }
                if (isEndTimeOverlaps) {
                    return isEndTimeOverlaps;
                }
                if (isEndTimeOverlaps && isStartTimeOverlaps) {
                    return true;
                }
            }
        };

        const isVisitOverlapping = jobs.some((job: Patient) => getVisitOverlap(job));
        const allStepWarnings = getAllWarnings(values);
        if (isVisitOverlapping) {
            allStepWarnings.push(
                'Overlap with another visit/administrative time of the assigned HCP(s)',
            );
        }
        setVisitStepWarnings(allStepWarnings);
    }, [values, jobs]);

    return (
        <Accordion>
            <AccordionTab
                name="patiens"
                title="Patient details"
                onEdit={() => onEdit(FormSteps.PATIENT)}
                color={AccordionColors.PINK}
                open
            >
                <PatientDetails values={values} />
            </AccordionTab>
            <AccordionTab
                name="activity"
                title="Activity details"
                onEdit={() => onEdit(FormSteps.ACTIVITY)}
                color={AccordionColors.PINK}
                open
            >
                <CareDetails values={values} staffFiltered={staffFiltered} patient={patient} />
            </AccordionTab>
            {(schedulingWarnings || (visitStepWarnings && visitStepWarnings.length > 0)) && (
                <Warnings warnings={schedulingWarnings} stepWarnings={visitStepWarnings} />
            )}
            {formMode !== FormMode.EDIT_VISIT && (
                <div>
                    <label className="v2__form-group">
                        <Button
                            className="v2__form-submit-button"
                            elem={ButtonElems.DIV}
                            name={'Add administrative time'}
                            size={ButtonSizes.MEDIUM}
                            clickEvent={() => onSave(values)}
                        />
                    </label>
                </div>
            )}

            {isDisabled ? (
                <Button
                    className="v2__form-submit-button"
                    size={ButtonSizes.MEDIUM}
                    name="Change status"
                    Icon={IconChangeStatus}
                    clickEvent={() => setOpenedDialog(Dialogs.CHANGE_VISIT_STATUS)}
                />
            ) : null}
        </Accordion>
    );
};

export default ReviewForm;

function PatientDetails({ values }: { values: FormikValues }) {
    const allContactNumbers = values.contactNumber
        ? [values.contactNumber, ...(values.additionalContactNumbers || [])]
        : [];

    let genderPreferredMapping = values.staffPreferredGender ? values.staffPreferredGender : [];
    genderPreferredMapping = genderPreferredMapping.map((index: any) => {
        if (index) {
            return genderMapping[index];
        }
        return '';
    });

    return (
        <div className="v2__form-section">
            <Text name="nhsNumber" title="NHS number" description={values.nhsNumber} />

            <Text name="name" title="Name" description={presentationNameLMF(values)} />
            <Text
                name="contactNumber"
                title="Phone number"
                description={
                    allContactNumbers?.map((number: string) => number && `+${number}`) || 'Not set'
                }
            />
            <Text
                name="dateOfBirth"
                title="Date of birth"
                description={
                    values.dateOfBirth ? moment(values.dateOfBirth).format(dateFormat) : ''
                }
            />
            <Text name="Gender" title="Gender" description={genderMapping[values.gender]} />
            <Text name="addressLine1" title="Address line 1" description={values.addressLine1} />
            <Text name="addressLine2" title="Address line 2" description={values.addressLine2} />
            <Text name="addressLine3" title="Address line 3" description={values.addressLine3} />
            <Text name="town" title="Town" description={values.town} />
            <Text name="postCode" title="Post code" description={values.postCode} />
            <Text
                name="languagesSpoken"
                title="Spoken language"
                description={values.languagesSpoken}
            />
            <Text
                name="staffPreferredGender"
                title="Gender of visiting staff"
                description={genderPreferredMapping}
            />
        </div>
    );
}

function CareDetails({
    values,
    staffFiltered,
    patient,
}: {
    values: FormikValues;
    staffFiltered: string[];
    patient: Patient;
}) {
    const jobStatus: JobStatus = values.jobStatus;
    const showPatientNotes = shouldShowPostJobNotes({ jobStatus });

    return (
        <div className="v2__form-section">
            <Text name="activityType" title="Activity" description={values.activityType} />
            <Text
                name="visitDate"
                title="Planned date of job"
                description={moment(values.visitDate).format('dddd Do MMMM YYYY')}
            />
            <Text
                name="startTime"
                title="Planned start time"
                description={values.startTime ? moment(values.startTime).format('hh:mm a') : ''}
            />
            <Text name="duration" title="Planned duration" description={values.duration} />
            <Text
                name="endTime"
                title="Expected end time"
                description={getStringEndTime(
                    values.startTime || values.startDateTime,
                    values.duration,
                    'hh:mm a',
                )}
            />
            <Text
                name="Notes"
                title="Pre-job notes"
                className="visit-details__notes"
                description={values.notes}
            />
            <Text name="staffRequired" title="Staffing requirements" description={staffFiltered} />
            <Text
                name="arrivedDateTime"
                title="Actual start time"
                notSetText="Not yet recorded"
                description={TimeHelperActualStart({ patient: patient, isFirstUser: true })}
            />
            <Text
                name="finishedDateTime"
                title="Actual end time"
                notSetText="Not yet recorded"
                description={TimeHelperActualEnd({ patient: patient, isFirstUser: true })}
            />
            <ConditionalDisplay show={showPatientNotes}>
                <Text
                    name="postVisitNotes"
                    title="Post-job notes"
                    className="visit-details__notes"
                    description={values.postVisitNotes}
                />
            </ConditionalDisplay>
        </div>
    );
}
