import React from 'react';
import {
    Button,
    FormGroup,
    Icon,
    InputGroup,
    Intent,
    NumericInput,
    Tooltip,
} from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import moment from 'moment';
import Select from 'react-select';
import { ErrorMessage, FieldArray } from 'formik';
import { validateCode, validateOverlap } from '../../../../helpers/customPayRateValidation';

const PayRatesSummaryCustom = ({ editOrCreateMode, setFieldValue, values, errors }: any) => {
    const codeError = React.useMemo(() => validateCode(values.customPayRates), [
        values.customPayRates,
    ]);

    const overlap = React.useMemo(() => validateOverlap(values.customPayRates), [
        values.customPayRates,
    ]);

    const timeToDate = React.useCallback((time: string) => {
        const [hour, minute] = time.split(':');
        return moment()
            .hours(+hour)
            .minutes(+minute)
            .toDate();
    }, []);

    const dateToTime = React.useCallback((date: Date) => {
        return (
            date.getHours().toString().padStart(2, '0') +
            ':' +
            date.getMinutes().toString().padStart(2, '0')
        );
    }, []);

    return (
        <FieldArray
            name="customPayRates"
            render={(arrayHelpers: any) => (
                <div>
                    <div className="payRates-info__block payRates-info__block--fullWidth">
                        <div className="payRates-info-customTitle">
                            {!!values.customPayRates &&
                                values.customPayRates.map((r: any, index: number) => (
                                    <h3 className="payRates-info__title payRates-info__title--center">
                                        Custom rate {index + 1}
                                    </h3>
                                ))}
                        </div>
                        <div>
                            {!!values.customPayRates &&
                                values.customPayRates?.map((customPayRate: any, index: any) => {
                                    const isEndTimeError =
                                        errors.customPayRates &&
                                        errors.customPayRates
                                            .filter((r: any) => !!r)
                                            .find(
                                                (r: any) =>
                                                    r.endTime &&
                                                    r.endTime ===
                                                        `customPayRates[${index}].endTime`,
                                            );
                                    const dayDefaultValue =
                                        customPayRate.day.length === 0
                                            ? null
                                            : customPayRate.day.split(',').map((r: any) => ({
                                                  label: r,
                                                  value: r,
                                              }));
                                    return (
                                        <div
                                            key={index}
                                            className={`payRates-info__definition payRates-info__definition--inline
                                            payRates-info__definition--inline-custom ${
                                                overlap || codeError ? 'overlap-error' : ''
                                            }`}
                                        >
                                            <div>
                                                <h3 className="payRates-info__title">Code</h3>
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        labelFor={`customPayRates-${index}-code`}
                                                    >
                                                        <InputGroup
                                                            id={`customPayRates-${index}-code`}
                                                            name={`customPayRates-${index}-code`}
                                                            onChange={(e: any) => {
                                                                setFieldValue(
                                                                    `customPayRates.${index}.code`,
                                                                    e && e.target.value,
                                                                );
                                                            }}
                                                            value={
                                                                values.customPayRates[index]?.code
                                                            }
                                                            large
                                                        />
                                                        <div className="payRates-info__warning">
                                                            <ErrorMessage
                                                                name={`customPayRates.${index}.code`}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                ) : (
                                                    values.customPayRates[index].code
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="payRates-info__title">
                                                    Rate per hour *
                                                </h3>
                                                {editOrCreateMode ? (
                                                    <FormGroup
                                                        labelFor={`customPayRates-${index}-rate`}
                                                    >
                                                        <NumericInput
                                                            id={`customPayRates-${index}-rate`}
                                                            name={`customPayRates-${index}-rate`}
                                                            onValueChange={(_, value) => {
                                                                setFieldValue(
                                                                    `customPayRates.${index}.rate`,
                                                                    value ? value : '',
                                                                );
                                                            }}
                                                            minorStepSize={0.01}
                                                            stepSize={0.01}
                                                            value={
                                                                values.customPayRates[index]?.rate
                                                            }
                                                            buttonPosition="none"
                                                            type="number"
                                                            large
                                                        />
                                                        <div className="payRates-info__warning">
                                                            <ErrorMessage
                                                                name={`customPayRates.${index}.rate`}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                ) : (
                                                    values.customPayRates[index].rate
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="payRates-info__title">
                                                    Start Time *
                                                </h3>
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="startTime">
                                                        <TimePicker
                                                            value={timeToDate(
                                                                values.customPayRates[index]
                                                                    .startTime,
                                                            )}
                                                            onChange={(date) => {
                                                                setFieldValue(
                                                                    `customPayRates.${index}.startTime`,
                                                                    dateToTime(date),
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                ) : (
                                                    values.customPayRates[index].startTime
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="payRates-info__title">End Time *</h3>
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="endTime">
                                                        <TimePicker
                                                            value={timeToDate(
                                                                values.customPayRates[index]
                                                                    .endTime,
                                                            )}
                                                            onChange={(date) => {
                                                                setFieldValue(
                                                                    `customPayRates.${index}.endTime`,
                                                                    dateToTime(date),
                                                                );
                                                            }}
                                                        />
                                                        {isEndTimeError && (
                                                            <div className="payRates-info__warning">
                                                                <span className="bp5-intent-danger">
                                                                    Invalid time
                                                                </span>
                                                            </div>
                                                        )}
                                                    </FormGroup>
                                                ) : (
                                                    values.customPayRates[index].endTime
                                                )}
                                            </div>
                                            <div>
                                                <h3 className="payRates-info__title inline">Day</h3>
                                                <Tooltip
                                                    openOnTargetFocus={false}
                                                    position={'bottom'}
                                                    className="payRates-tooltip"
                                                    usePortal={true}
                                                    content={
                                                        <div className="payRates-tooltip__content">
                                                            <p>
                                                                Custom rates are applied to shifts
                                                                according to their relative
                                                                priority:
                                                            </p>
                                                            <p>
                                                                1 - Christmas Day, Boxing Day, New
                                                                Year Day have the highest priority
                                                                and override all other custom rates
                                                            </p>
                                                            <p>
                                                                2 - Bank holidays override all
                                                                custom rates except the 1st priority
                                                                group
                                                            </p>
                                                            <p>
                                                                3 - Monday, Tuesday, Wednesday,
                                                                Thursday, Friday, Saturday, Sunday
                                                                have a higher priority than a rate
                                                                for weekdays or weekends
                                                            </p>
                                                            <p>
                                                                4 - Weekdays, Weekends only override
                                                                the general rate“
                                                            </p>
                                                        </div>
                                                    }
                                                >
                                                    ?
                                                </Tooltip>
                                                <div className="payRates-info__definition">
                                                    {editOrCreateMode ? (
                                                        <FormGroup labelFor="role">
                                                            <Select
                                                                isMulti
                                                                options={[
                                                                    {
                                                                        label: 'Monday',
                                                                        value: 'Monday',
                                                                    },
                                                                    {
                                                                        label: 'Tuesday',
                                                                        value: 'Tuesday',
                                                                    },
                                                                    {
                                                                        label: 'Wednesday',
                                                                        value: 'Wednesday',
                                                                    },
                                                                    {
                                                                        label: 'Thursday',
                                                                        value: 'Thursday',
                                                                    },
                                                                    {
                                                                        label: 'Wednesday',
                                                                        value: 'Wednesday',
                                                                    },
                                                                    {
                                                                        label: 'Friday',
                                                                        value: 'Friday',
                                                                    },
                                                                    {
                                                                        label: 'Saturday',
                                                                        value: 'Saturday',
                                                                    },
                                                                    {
                                                                        label: 'Sunday',
                                                                        value: 'Sunday',
                                                                    },
                                                                    {
                                                                        label: 'Weekdays',
                                                                        value: 'Weekdays',
                                                                    },
                                                                    {
                                                                        label: 'Weekends',
                                                                        value: 'Weekends',
                                                                    },
                                                                    {
                                                                        label: 'Bank holidays',
                                                                        value: 'Bank holidays',
                                                                    },
                                                                    {
                                                                        label: 'Christmas Day',
                                                                        value: 'Christmas Day',
                                                                    },
                                                                    {
                                                                        label: 'Boxing Day',
                                                                        value: 'Boxing Day',
                                                                    },
                                                                    {
                                                                        label: 'New Year Day',
                                                                        value: 'New Year Day',
                                                                    },
                                                                ]}
                                                                value={dayDefaultValue}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        `customPayRates.${index}.day`,
                                                                        e &&
                                                                            e
                                                                                .map(
                                                                                    (r: any) =>
                                                                                        r.value,
                                                                                )
                                                                                .join(','),
                                                                    );
                                                                }}
                                                                id={`customPayRates-${index}-day`}
                                                                name={`customPayRates-${index}-day`}
                                                                placeholder="Select..."
                                                            />
                                                        </FormGroup>
                                                    ) : (
                                                        values.customPayRates[index].day
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                {editOrCreateMode && (
                                                    <Icon
                                                        className="payRates-info__delete"
                                                        icon="trash"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                        intent={'danger'}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    <div className="payRates-info__warning">
                        {editOrCreateMode && (overlap || codeError) && (
                            <>
                                <span className="bp5-intent-danger">
                                    <Icon icon="warning-sign" intent="danger" />
                                    &nbsp;
                                    {overlap
                                        ? 'Please ensure pay rates are not overlapped'
                                        : 'Custom rate code should be unique'}
                                </span>
                                <ErrorMessage name={`hasError`} />
                            </>
                        )}
                    </div>

                    <div>
                        {editOrCreateMode && (
                            <Button
                                intent={Intent.SUCCESS}
                                disabled={codeError || overlap}
                                onClick={() => {
                                    (arrayHelpers as any).push({
                                        code: '',
                                        rate: 0,
                                        startTime: '00:00',
                                        endTime: '00:00',
                                        day: '',
                                    });
                                }}
                            >
                                + Add custom pay rate
                            </Button>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

export default PayRatesSummaryCustom;
