import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';

// import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore, SortInfo } from './PagedStore';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
// import { GET_ALERTS } from '../graphql/queries/alerts';
// import AppToaster from '../components/modules/helpers/Toaster';
// import { GetAlerts_getAlerts_edges_node } from '../__generated__/GetAlerts';

// export interface AlertsTypes extends GetAlerts_getAlerts_edges_node {
//     selected?: boolean;
// }

class AlertsStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            alerts: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridAlerts: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getAlerts: action,
            importantAlerts: observable,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    // alerts: AlertsTypes[] = [];
    alerts: any[] = [];
    sortFn: (a: any, b: any) => number = () => 0;
    fetchInterval: NodeJS.Timeout | null = null;
    importantAlerts: number | null = null;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    get gridAlerts() {
        return this.alerts;
    }

    select = (id: string) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id: string) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getAlerts();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getAlerts();
    };

    onSort = (sortInfo: SortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getAlerts();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                employeeId: null,
                personId: null,
            });
        });
    };

    getAlerts = async () => {
        // const userSession = await this.rootStore.userStore.getUserSession();
        // if (!userSession) {
        //     return this.dispose();
        // }
        // const { data }: { data: any } = await this.apolloClient.query({
        //     query: GET_ALERTS,
        //     variables: {
        //         before: this.pageInfo.currentStartCursor,
        //         after: this.pageInfo.currentEndCursor,
        //         first:
        //             this.pageInfo.currentEndCursor ||
        //             (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
        //                 ? PAGE_SIZE
        //                 : null,
        //         last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
        //         orderBy: this.pageInfo.sortBy ?? 'desc',
        //         field: this.pageInfo.sortColumn ?? '',
        //         query: this.pageInfo.searchText,
        //     },
        // });
        // if (data === null) {
        //     this.loaded = true;
        //     AppToaster.show({
        //         message:
        //             'There was a problem getting the latest alerts information. Please reload the page.',
        //         intent: 'danger',
        //         timeout: 0,
        //         action: {
        //             onClick: () => window.location.reload(),
        //             text: 'Reload',
        //         },
        //     });
        //     return;
        // }
        // runInAction(() => {
        //     this.alerts = data.getAlerts.edges?.map((x: any) => x.node) || [];
        //     this.pageInfo.totalCount = data.getAlerts.totalCount;
        //     this.pageInfo.hasNextPage = data.getAlerts.pageInfo.hasNextPage;
        //     this.pageInfo.hasPreviousPage = data.getAlerts.pageInfo.hasPreviousPage;
        //     this.pageInfo.startCursor = data.getAlerts.pageInfo.startCursor;
        //     this.pageInfo.endCursor = data.getAlerts.pageInfo.endCursor;
        //     this.importantAlerts = data.getAlerts.edges?.[0]?.node?.totalExpired ?? null;
        //     this.loaded = true;
        // });
    };

    selectAll = () => {
        runInAction(() => {
            this.gridAlerts.forEach((item: any) => {
                this.select(item.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getAlerts();
            this.fetchInterval = setInterval(() => this.getAlerts(), 10000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }
}

export default AlertsStore;
