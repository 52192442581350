import { gql } from '@apollo/client';

export const GET_FUNCTIONS_LIST = gql`
    query GetFunctions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: FunctionFilter
    ) {
        getFunctions(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    createdAt
                    createdBy
                    updatedBy
                    isArchived
                    isDeleted
                    updatedAt
                    updatedBy
                    createdPerson {
                        id
                        firstName
                        lastName
                    }
                    updatedPerson {
                        id
                        firstName
                        lastName
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_FUNCTIONS_BY_DEPARTMENT_AND_ROLE = gql`
    query GetFunctionsByDepartmentAndRole($departmentId: String!, $roleId: String) {
        getFunctionsByDepartmentAndRole(departmentId: $departmentId, roleId: $roleId) {
            id
            name
            isArchived
        }
    }
`;

export const CREATE_FUNCTION = gql`
    mutation CreateFunction($data: FunctionEntityInput!) {
        createFunction(data: $data) {
            id
        }
    }
`;

export const UPDATE_FUNCTION = gql`
    mutation UpdateFunction($id: String!, $data: FunctionEntityInput!) {
        updateFunction(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const DELETE_FUNCTION = gql`
    mutation deleteFunction($id: String!) {
        deleteFunction(id: $id) {
            id
        }
    }
`;

export const ARCHIVE_FUNCTION = gql`
    mutation ArchiveFunction($id: String!) {
        archiveFunction(id: $id) {
            id
        }
    }
`;

export const GET_FUNCTION_BY_ID = gql`
    query GetFunctionById($id: String!) {
        getFunctionById(id: $id) {
            id
            name
            createdAt
            updatedAt
            isArchived
        }
    }
`;

export const GET_FUNCTION_BY_NAME = gql`
    query GetFunctionByName($name: String!) {
        getFunctionByName(name: $name) {
            id
            name
        }
    }
`;

export const VALIDATE_ARCHIVE_FUNCTIONS = gql`
    query IsFunctionArchivable($ids: [String!]!) {
        isFunctionArchivable(ids: $ids) {
            id
            name
            isArchivable
            relatedRole
            relatedShift
            relatedRota
        }
    }
`;

export const BULK_ARCHIVE_FUNCTIONS = gql`
    mutation BulkArchiveFunctions($ids: [String!]!) {
        bulkArchiveFunctions(ids: $ids) {
            count
        }
    }
`;

export const BULK_DELETE_FUNCTIONS = gql`
    mutation BulkDeleteFunctions($ids: [String!]!) {
        bulkDeleteFunctions(ids: $ids) {
            count
        }
    }
`;
