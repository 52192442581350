import { useState, useCallback, FC } from 'react';
import { useDropzone } from 'react-dropzone';

import {
    getFileUploadParams,
    uploadFile,
    confirmSchedulesUpload,
    previewSchedulesUpload,
} from '../../../../../api/schedulesApi';
import {
    IconError,
    IconSave,
    IconUpload,
    IconWarning,
    IconTick,
} from '../../../../../helpers/ucr/icons';
import useStores from '../../../../../hook/useStores';
import { Button, ButtonElems, Callout } from '../../../../v2/components';
import AppToaster from '../../../../modules/helpers/Toaster';

interface IProps {
    onClose: () => void;
}

export default function ImportPatients({ onClose }: IProps) {
    const [newSchedulesCount, setNewSchedulesCount] = useState(0);
    const [fileSelected, setFileSelected] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [uploadConfirmed, setUploadConfirmed] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [fileFormatError, setFileFormatError] = useState(false);
    const [invalidUserIdError, setInvalidUserIdError] = useState(false);
    const [invalidUserIdList, setInvalidUserIdList] = useState([]);
    const [invalidDateError, setInvalidDateError] = useState(false);
    const [invalidDateList, setInvalidDateList] = useState([]);
    const [pastDateError, setPastDateError] = useState(false);
    const [pastDateList, setPastDateList] = useState([]);
    const [endTimeError, setEndTimeError] = useState(false);
    const [endTimeList, setEndTimeList] = useState([]);
    const [scheduleValidationWarning, setScheduleValidationWarning] = useState(false);
    const [overlappingUserIds, setOverlappingUserIds] = useState([]);
    const [overlappingError, setOverlappingError] = useState(false);
    const [outsideAvailabilityUserNames, setOutsideAvailabilityUserNames] = useState([]);
    const [validationMsg, setValidationMsg] = useState(null);
    const [validationPassed, setValidationPassed] = useState(false);
    const [unavailableUsers, setUnavailableUsers] = useState([]);
    const [tokenId, setTokenId] = useState(null);
    const [uploadId, setUploadId] = useState(null);
    const [overlappingPreviousDaySchduleList, setOverlappingPreviousDaySchduleList] = useState([]);
    const [overlappingNextDaySchduleList, setOverlappingNextDaySchduleList] = useState([]);
    const [overlappingExistingSchduleError, setOverlappingExistingSchduleError] = useState(false);

    const {
        RootStore: {
            userStore: { getUserSession },
            configStore: { org, isFeatureEnabled },
        },
    } = useStores();

    const reset = () => {
        setFileSelected(false);
        setUploadComplete(false);
        setUploadConfirmed(false);
        setNewSchedulesCount(0);
        setFileFormatError(false);
    };

    const resetAll = () => {
        reset();
        setUploadError(null);
        setPastDateError(false);
        setInvalidDateError(false);
        setInvalidUserIdError(false);
        setOverlappingError(false);
    };

    const confirmUpload = async () => {
        const { newSchedulesCount } = await confirmSchedulesUpload(tokenId, uploadId, {
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        setNewSchedulesCount(newSchedulesCount);
        setUploadConfirmed(true);
        AppToaster.show({
            message: 'Availability applied successfully',
            intent: 'success',
        });
        onClose();
    };

    const onDrop = useCallback(
        async ([file]: File[]) => {
            setUploadError(null);
            setFileFormatError(false);

            if (!file.name.endsWith('.csv')) {
                setFileFormatError(true);
                return;
            }

            setFileSelected(true);

            let uploadParams;

            try {
                const { tokens } = await getUserSession();
                uploadParams = await getFileUploadParams(tokens.id);

                const key = `${org}/${uploadParams.uploadId}.csv`;

                await uploadFile(key, file, uploadParams);

                setUploadComplete(true);
                setTokenId(tokens.id);
                setUploadId(uploadParams.uploadId);

                const previewResponse = await previewSchedulesUpload(
                    tokens.id,
                    uploadParams.uploadId,
                    {
                        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        with24hSupport: isFeatureEnabled('24hTimeline'),
                    },
                );

                setValidationMsg(previewResponse.message);
                setValidationPassed(!previewResponse.hasValidationErrors);

                if (
                    !previewResponse.hasValidationErrors &&
                    !previewResponse.hasValidationWarnings
                ) {
                    const { newSchedulesCount } = await confirmSchedulesUpload(
                        tokens.id,
                        uploadParams.uploadId,
                        {
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        },
                    );

                    setNewSchedulesCount(newSchedulesCount);
                    setUploadConfirmed(true);
                    AppToaster.show({
                        message: 'Availability applied successfully',
                        intent: 'success',
                    });
                    onClose();
                } else {
                    if (previewResponse.hasValidationErrors) {
                        if (previewResponse.invalidUserId?.length) {
                            setInvalidUserIdList(previewResponse.invalidUserId);
                            setInvalidUserIdError(true);
                        }

                        if (previewResponse.invalidDateTime?.length) {
                            setInvalidDateList(previewResponse.invalidDateTime);
                            setInvalidDateError(true);
                        }

                        if (previewResponse.pastDate?.length) {
                            setPastDateList(previewResponse.pastDate);
                            setPastDateError(true);
                        }

                        if (previewResponse.overlappingUserIds?.length) {
                            setOverlappingUserIds(previewResponse.overlappingUserIds);
                            setOverlappingError(true);
                        }

                        if (previewResponse.invalidEndTime?.length) {
                            setEndTimeList(previewResponse.invalidEndTime);
                            setEndTimeError(true);
                        }

                        if (previewResponse.overlappingExistingSchedule?.length) {
                            setOverlappingPreviousDaySchduleList(
                                previewResponse.overlappingExistingSchedule.filter(
                                    ({ isPreviousDay }: { isPreviousDay: boolean }) =>
                                        isPreviousDay,
                                ),
                            );
                            setOverlappingNextDaySchduleList(
                                previewResponse.overlappingExistingSchedule.filter(
                                    ({ isPreviousDay }: { isPreviousDay: boolean }) =>
                                        !isPreviousDay,
                                ),
                            );
                            setOverlappingExistingSchduleError(true);
                        }
                    }

                    if (previewResponse.hasValidationWarnings) {
                        setOutsideAvailabilityUserNames(
                            previewResponse.outsideAvailabilityUserNames,
                        );
                        setUnavailableUsers(previewResponse.unavailableUsers);
                        setScheduleValidationWarning(true);
                    }
                }
            } catch (err: any) {
                setUploadError(err.message);
                reset();
                return;
            }
        },
        [getUserSession, isFeatureEnabled, onClose, org],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const hasValitaionErrors =
        invalidDateError ||
        pastDateError ||
        overlappingExistingSchduleError ||
        overlappingError ||
        invalidUserIdError ||
        endTimeError;

    return (
        <>
            {!uploadError && !fileSelected ? (
                <label htmlFor="fileUpload">
                    <div
                        {...getRootProps()}
                        className="v2-callout v2-callout--interactive v2-callout--spacer-bottom"
                    >
                        <input
                            name="fileUpload"
                            data-testid="fileUpload"
                            {...getInputProps()}
                            multiple={false}
                        />
                        <span className="v2-callout__icon">
                            <IconUpload />
                        </span>
                        <span className="v2-callout__text">
                            {isDragActive
                                ? 'Drop the file here...'
                                : 'To upload a new file, drag and drop it here, or click to choose...'}
                        </span>
                    </div>
                </label>
            ) : uploadError ? (
                <Callout intent="danger" Icon={IconError} spacerBottom>
                    <p>
                        Sorry, we encountered an error uploading the schedules file and it could not
                        be processed. Please check the file and try again. If the problem persists,
                        please try refreshing the page.
                    </p>
                    <p>
                        <Button name="Try again" elem={ButtonElems.BUTTON} clickEvent={resetAll} />
                    </p>
                </Callout>
            ) : fileSelected && !uploadComplete ? (
                <Callout intent="primary" Icon={IconUpload} spacerBottom>
                    Uploading...
                </Callout>
            ) : !validationPassed && hasValitaionErrors ? (
                <Callout intent="warning" Icon={IconError} spacerBottom>
                    <p>{validationMsg}</p>
                    {invalidUserIdError && (
                        <div>
                            <p className="validation-err-title">Unknown user ids:</p>
                            <p>{invalidUserIdList.join(' , ')}</p>
                        </div>
                    )}
                    {invalidDateError && (
                        <Errors
                            title="Invalid dates:"
                            columns={['userId', 'date', 'startTime', 'endTime']}
                            data={invalidDateList}
                        />
                    )}
                    {pastDateError && (
                        <Errors
                            title="Past dates:"
                            columns={['userId', 'date']}
                            data={pastDateList}
                        />
                    )}
                    {endTimeError && (
                        <Errors
                            title="The end time cannot be earlier than the start time:"
                            columns={['userId', 'date', 'startTime', 'endTime']}
                            data={endTimeList}
                        />
                    )}
                    {overlappingError && (
                        <div>
                            <div>
                                <p className="validation-err-title">
                                    Overlapping availability for:
                                </p>
                                <p>{overlappingUserIds.join(' , ')}</p>
                            </div>
                        </div>
                    )}
                    {overlappingExistingSchduleError && (
                        <div>
                            {overlappingPreviousDaySchduleList.length > 0 && (
                                <Errors
                                    title="The imported working hours in the file overlap with an
                                existing shift for the previous day for:"
                                    columns={['userId', 'date']}
                                    data={overlappingPreviousDaySchduleList}
                                />
                            )}
                            {overlappingNextDaySchduleList.length > 0 && (
                                <Errors
                                    title="The imported working hours overlap with an existing shift
                                for the next day for:"
                                    columns={['userId', 'date']}
                                    data={overlappingNextDaySchduleList}
                                />
                            )}
                        </div>
                    )}
                    <p>
                        <Button
                            name="Try again"
                            elem={ButtonElems.BUTTON}
                            clickEvent={resetAll}
                            style={{ marginTop: '10px' }}
                        />
                    </p>
                </Callout>
            ) : scheduleValidationWarning ? (
                <Callout intent="warning" Icon={IconError} spacerBottom>
                    <p>{validationMsg}</p>
                    {unavailableUsers.map((item: any) => {
                        return (
                            <p>
                                HCP {item} has visits assigned but their availability has been
                                removed.
                            </p>
                        );
                    })}
                    {outsideAvailabilityUserNames.map((item: any) => {
                        return (
                            <p>
                                HCP {item} has visits assigned outside their new availability hours.
                            </p>
                        );
                    })}
                    <p>
                        <Button name="Try again" elem={ButtonElems.BUTTON} clickEvent={resetAll} />
                        <Button
                            name="Confirm upload"
                            elem={ButtonElems.BUTTON}
                            clickEvent={confirmUpload}
                            style={{ margin: '0 10px' }}
                        />
                        <Button name="Close" elem={ButtonElems.BUTTON} clickEvent={onClose} />
                    </p>
                </Callout>
            ) : uploadComplete && !uploadConfirmed ? (
                <Callout intent="primary" Icon={IconSave} spacerBottom>
                    Saving schedules...
                </Callout>
            ) : uploadConfirmed ? (
                <Callout intent="success" Icon={IconTick} spacerBottom>
                    <p>
                        Imported <strong>{newSchedulesCount}</strong>{' '}
                        {newSchedulesCount === 1 ? 'schedule' : 'schedules'}.
                    </p>
                    <p>
                        <Button name="Close" elem={ButtonElems.BUTTON} clickEvent={onClose} />
                    </p>
                </Callout>
            ) : fileFormatError ? (
                <Callout intent="warning" Icon={IconWarning} spacerBottom>
                    Invalid file format, only .csv files are allowed.
                </Callout>
            ) : invalidUserIdError ? (
                <Callout intent="danger" Icon={IconError} spacerBottom>
                    <p>Unknown user ids:</p>
                    <p>{invalidUserIdList.join(' , ')}</p>
                </Callout>
            ) : null}
            <p>
                You can use the upload function above to import schedules. Files must be in .csv
                format. Please see{' '}
                <a href="/example-schedule-upload.csv" target="_blank" rel="noreferrer noopener">
                    this example file
                </a>{' '}
                or the instructions below for the required structure.
            </p>
            <p>
                The schedule data represents time periods, or shifts, that a HCP is available to be
                assigned work. A HCP can have multiple shifts per day, for example they might have a
                lunch break or some other period during which they are unavailable, so could have
                one shift in the morning and another in the afternoon.
            </p>
            <p>
                In the example below, <strong>another.user</strong> has one shift on 21/01/2022 from
                08:00 to 12:00, and another from 13:00 to 17:30.
            </p>
            <p>There are four columns required:</p>
            <ul>
                <li>
                    <strong>userId</strong> - the user ID of the HCP
                </li>
                <li>
                    <strong>date</strong> - the date of the schedule, must be in the format:
                    DD/MM/YYYY
                </li>
                <li>
                    <strong>startTime</strong> - the start time of the shift, must be written as a
                    24 hour time in the format: HH:MM
                </li>
                <li>
                    <strong>endTime</strong> - the end time of the shift, must be written as a 24
                    hour time in the format: HH:MM
                </li>
            </ul>
            <table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped">
                <thead>
                    <tr>
                        <th>userId</th>
                        <th>date</th>
                        <th>startTime</th>
                        <th>endTime</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>test.user</td>
                        <td>21/01/2022</td>
                        <td>09:00</td>
                        <td>17:00</td>
                    </tr>
                    <tr>
                        <td>another.user</td>
                        <td>21/01/2022</td>
                        <td>08:00</td>
                        <td>12:00</td>
                    </tr>
                    <tr>
                        <td>another.user</td>
                        <td>21/01/2022</td>
                        <td>13:00</td>
                        <td>17:30</td>
                    </tr>
                    <tr>
                        <td>test.user</td>
                        <td>22/01/2022</td>
                        <td>09:00</td>
                        <td>13:00</td>
                    </tr>
                </tbody>
            </table>
            <Callout intent="primary" Icon={IconWarning} spacerTop>
                <strong>Important note:</strong> any previous schedules that have been uploaded for
                the same date as newly added ones will be invalidated, even if they don't overlap.
                This means you can change a HCP's availability for a certain date by uploading new
                schedule data for that date.
            </Callout>
        </>
    );
}

interface ErrorsProps {
    title: string;
    columns: string[];
    data: Record<string, string>[];
}

const Errors: FC<ErrorsProps> = ({ title, columns, data }) => {
    return (
        <div>
            <p className="validation-err-title">{title}</p>
            <table className="bp5-html-table bp5-html-table-bordered bp5-html-table-striped">
                <thead>
                    <tr>
                        {columns.map((c) => {
                            return <th key={c}>{c}</th>;
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any) => {
                        return (
                            <tr key={item}>
                                {columns.map((c) => (
                                    <td key={c}>{item[c]}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
