// @ts-check

import { observable, action, makeObservable } from 'mobx';
import { OnboardingUser } from '@doc-abode/data-models';

import {
    fetchUserOnboarding,
    fetchOwnOnboarding,
    downloadFile,
    uploadFile,
    getFileUploadParams,
    confirmFileUpload,
    updateOwnOnboarding,
} from '../api/onboardingsApi';

class OnboardingsStore {
    constructor(rootStore) {
        makeObservable(this, {
            userOnboardings: observable,
            ownOnboarding: observable,
            fetchOnboarding: action,
            fetchOwnOnboarding: action,
            updateOwnOnboarding: action,
        });

        this.rootStore = rootStore;
    }

    userOnboardings = [];
    ownOnboarding = null;

    fetchOnboarding = async (onboardingId) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const onboardingData = await fetchUserOnboarding(userSession.tokens.id, onboardingId);
            const onboardingUser = new OnboardingUser();
            onboardingUser.import(onboardingData);
            this.userOnboardings.push(onboardingUser);
        } catch (err) {
            console.error('Unable to fetch onboarding for user', err);
        }
    };

    getUserOnboarding = (onboardingId) => {
        const onboarding = this.userOnboardings.find(
            (onboarding) => onboarding.attributes.onboardingId === onboardingId,
        );

        if (!onboarding) {
            this.fetchOnboarding(onboardingId);
            return null;
        }

        return onboarding;
    };

    fetchOwnOnboarding = async (onboardingId) => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const onboarding = await fetchOwnOnboarding(userSession.tokens.id, onboardingId);
            this.ownOnboarding = onboarding;
        } catch (err) {
            console.error('Unable to fetch onboarding for organisation', err);
        }
    };

    updateOwnOnboarding = async (params) => {
        try {
            const { onboardingId } = this.ownOnboarding;
            const userSession = await this.rootStore.userStore.getUserSession();
            const onboarding = await updateOwnOnboarding(
                userSession.tokens.id,
                onboardingId,
                params,
            );
            this.ownOnboarding = onboarding;
            return { success: true };
        } catch (err) {
            console.error('Unable to update onboarding', err);
            return { success: false };
        }
    };

    downloadFile = async (key, source = 'users') => {
        try {
            const userSession = await this.rootStore.userStore.getUserSession();
            const { url } = await downloadFile(userSession.tokens.id, key, source);
            return url;
        } catch (err) {
            console.error('Unable to get download link', err);
        }
    };

    uploadFile = async (file, uploadParams) => {
        const { org } = this.rootStore.configStore;
        const key = `${org}/${uploadParams.uploadId}_${file.name}`;
        const formData = new FormData();

        Object.keys(uploadParams.fields).forEach((key) => {
            formData.append(key, uploadParams.fields[key]);
        });

        formData.append('key', key);
        formData.append('file', file);

        return uploadFile(uploadParams.url, formData);
    };

    getFileUploadParams = async (onboardingId) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        return getFileUploadParams(userSession.tokens.id, onboardingId);
    };

    confirmFileUpload = async (onboardingId, uploadId, body) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        await confirmFileUpload(userSession.tokens.id, onboardingId, uploadId, body);
        return this.fetchOwnOnboarding(onboardingId);
    };
}

export default OnboardingsStore;
