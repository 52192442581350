import React from 'react';
import { Checkbox } from '@blueprintjs/core';
import { formatDisplayDateTime } from '../../../modules/helpers/formatData';
import { Roles } from '../../../../stores/RolesStore';

interface RolesItemProps extends Roles {
    onClick: (id: string, event: React.MouseEvent) => void;
    onToggle: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RolesTableRow = ({
    onClick,
    id,
    selected,
    name,
    createdAt,
    updatedAt,
    createdPerson,
    updatedPerson,
    onToggle,
    isArchived,
}: RolesItemProps) => {
    return (
        <tr
            onClick={(event) => onClick(id, event)}
            className={`common-table__row ${selected ? 'common-table__row--selected' : ''}`}
        >
            <td>
                <Checkbox
                    id={id}
                    checked={selected}
                    onChange={onToggle}
                    disabled={isArchived}
                    onClick={(event) => event.stopPropagation()}
                />
            </td>
            <td>{name}</td>
            <td>{isArchived ? 'Archived' : 'Active'}</td>
            <td>{formatDisplayDateTime(createdAt)}</td>
            <td>{createdPerson && `${createdPerson?.firstName}  ${createdPerson?.lastName}`}</td>
            <td>{formatDisplayDateTime(updatedAt)}</td>
            <td>{updatedPerson && `${updatedPerson?.firstName}  ${updatedPerson?.lastName}`}</td>
        </tr>
    );
};

export default RolesTableRow;
