import { gql } from '@apollo/client';

export const GET_ROTAS = gql`
    query GetRotas(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $filter: RotaFilter!
        $query: String
    ) {
        getRotas(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            filter: $filter
            query: $query
        ) {
            edges {
                node {
                    id
                    name
                    startDate
                    endDate
                    isPublished
                    createdBy
                    updatedBy
                    createdAt
                    updatedAt
                    department {
                        id
                        name
                        costCode
                    }
                    costCentre {
                        name
                        code
                        id
                    }
                    rotaShifts {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        weekNumber
                        typeId
                        statusId
                        startTime
                        weekNumber
                        dayOfWeek
                        trainingShift
                        trainees {
                            traineeId
                            roleId
                            payRateId
                            overrideValue
                        }
                        comments {
                            id
                            comment
                            isEdited
                            createdBy
                            createdAt
                            updatedAt
                            isImportant
                            createdPerson {
                                firstName
                                lastName
                            }
                        }
                        startTime
                        endTime
                    }
                    createPerson {
                        firstName
                        lastName
                        id
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                hasPreviousPage
                startCursor
                endCursor
            }
        }
    }
`;

export const GET_ROTA_BY_ID = gql`
    query GetRota($id: String!) {
        getRota(id: $id) {
            id
            name
            startDate
            endDate
            isPublished
            createdBy
            updatedBy
            createdAt
            updatedAt
            costCentreId
            departmentId
            shiftPatternId
            shiftPatternAppliedAt
            shiftPatternStartWeek
            rotaShifts {
                id
                startTime
                endTime
                weekNumber
                dayOfWeek
                breakDurationMinutes
                breaksPaid
                thirdPartyPaid
                overrideValueInPence
                locationId
                roleId
                rotaShiftFunctions {
                    functionId
                }
                patientFacing
                fundingPoolId
                defaultEmployeeId
                confirmedEmployeeId
                cost
                paymentStatusId
                defaultEmployee {
                    agency {
                        name
                    }
                    contractType {
                        name
                    }
                }
                confirmedEmployee {
                    agency {
                        name
                    }
                    contractType {
                        name
                    }
                }
                statusId
                typeId
                isAdditional
                payRateId
                trainingShift
                trainees {
                    traineeId
                    roleId
                    payRateId
                    overrideValue
                }
                comments {
                    id
                    comment
                    isEdited
                    createdBy
                    createdAt
                    updatedAt
                    isImportant
                    createdPerson {
                        firstName
                        lastName
                    }
                }
                startDate
                endDate
                conflicts0 {
                    id
                    rotaShift0 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                    rotaShift1 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                }
                conflicts1 {
                    id
                    rotaShift0 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                    rotaShift1 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ROTA_BY_ID_EXTENDED = gql`
    query GetRotaExtended($id: String!) {
        getRota(id: $id) {
            id
            name
            startDate
            endDate
            isPublished
            createdBy
            updatedBy
            createdAt
            updatedAt
            costCentreId
            departmentId
            department {
                id
                name
                costCode
                roles {
                    isInUse
                    employeeRole {
                        id
                        name
                    }
                }
                employees {
                    employeeRecord {
                        id
                        person {
                            firstName
                            lastName
                        }
                    }
                }
                shiftPatterns {
                    id
                    name
                    updatedAt
                    shiftPatternEntries {
                        id
                        shiftPatternId
                        defaultEmployeeId
                        weekNumber
                        dayOfWeek
                        startTime
                        endTime
                        breakDurationMinutes
                        breakIsPaid
                        thirdPartyPaid
                        trainingShift
                        trainees {
                            traineeId
                            roleId
                            payRateId
                            overrideValue
                        }
                        shiftEmployeeRoleTypeId
                        shiftFunctions {
                            functionId
                        }
                        fundingPoolId
                        locationId
                        overrideValueInPence
                        typeId
                        shiftType {
                            id
                            name
                        }
                        payRateId
                    }
                }
            }
            costCentre {
                id
                name
                code
            }
            shiftPatternId
            shiftPatternAppliedAt
            shiftPatternStartWeek
            rotaShifts {
                id
                startTime
                endTime
                weekNumber
                dayOfWeek
                breakDurationMinutes
                breaksPaid
                thirdPartyPaid
                overrideValueInPence
                locationId
                roleId
                rotaShiftFunctions {
                    functionId
                }
                patientFacing
                fundingPoolId
                defaultEmployeeId
                confirmedEmployeeId
                cost
                paymentStatusId
                defaultEmployee {
                    agency {
                        name
                    }
                    contractType {
                        name
                    }
                }
                confirmedEmployee {
                    agency {
                        name
                    }
                    contractType {
                        name
                    }
                }
                statusId
                typeId
                isAdditional
                payRateId
                trainingShift
                trainees {
                    traineeId
                    roleId
                    payRateId
                    overrideValue
                }
                comments {
                    id
                    comment
                    isEdited
                    createdBy
                    createdAt
                    updatedAt
                    isImportant
                    createdPerson {
                        firstName
                        lastName
                    }
                }
                startDate
                endDate
                conflicts0 {
                    id
                    rotaShift0 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                    rotaShift1 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                }
                conflicts1 {
                    id
                    rotaShift0 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                    rotaShift1 {
                        id
                        defaultEmployeeId
                        confirmedEmployeeId
                        startDate
                        endDate
                        rota {
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_ROTA = gql`
    mutation DeleteRota($id: String!) {
        deleteRota(id: $id)
    }
`;

export const CREATE_ROTA_SHIFT_COMMENT = gql`
    mutation CreateRotaShiftComment0(
        $comment: String!
        $rotaShiftId: String!
        $isImportant: Boolean
    ) {
        createRotaShiftComment(
            data: { comment: $comment, rotaShiftId: $rotaShiftId, isImportant: $isImportant }
        ) {
            id
            comment
            createdAt
            createdBy
            isEdited
            updatedAt
            isImportant
            createdPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const UPDATE_ROTA_SHIFT_COMMENT = gql`
    mutation UpdateRotaShiftComment0($comment: String!, $id: String!, $isImportant: Boolean) {
        updateRotaShiftComment(data: { comment: $comment, isImportant: $isImportant }, id: $id) {
            id
            comment
            createdAt
            createdBy
            isEdited
            updatedAt
            isImportant
            createdPerson {
                id
                firstName
                lastName
            }
        }
    }
`;

export const DELETE_ROTA_SHIFT_COMMENT = gql`
    mutation DeleteRotaShiftComment0($id: String!) {
        deleteRotaShiftComment(id: $id)
    }
`;

export const GET_ROTA_SHIFT_CONFLICTS = gql`
    query GetRotaShiftConflicts(
        $employeeId: String!
        $startDate: DateTime!
        $endDate: DateTime!
        $shiftId: String
    ) {
        getRotaShiftConflicts(
            employeeId: $employeeId
            startDate: $startDate
            endDate: $endDate
            shiftId: $shiftId
        ) {
            rotaName
            rotaShift {
                id
                startDate
                defaultEmployeeId
                confirmedEmployeeId
                rota {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_ROTA_SHIFT_COST = gql`
    query GetRotaShiftCost($data: ShiftInput!, $utcOffset: Int!) {
        getRotaShiftCost(data: $data, utcOffset: $utcOffset)
    }
`;

export const GET_PAYMENT_STATUSES = gql`
    query GetPaymentStatuses {
        getPaymentStatuses {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
