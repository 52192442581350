import moment from 'moment';

import { Patient, PatientAddress } from '@doc-abode/data-models';
import { IJobPos } from '../../interfaces/ucr';
import { IHcp } from '../../interfaces/ucr';

type FormikValues = {
    [key: string]: any;
};

export function isDisableFormBtn(
    values: FormikValues,
    requiredFields: Array<keyof FormikValues>,
): boolean {
    let isDisabled = false;

    requiredFields.forEach((field) => {
        if (
            values[field] === null ||
            values[field] === undefined ||
            values[field] === '' ||
            moment(values['duration'], 'HH:mm') < moment('00:05', 'HH:mm')
        ) {
            isDisabled = true;
            return;
        }
    });

    return isDisabled;
}

export const isSecondDoubleUpHcp = (job: Patient, position: IJobPos): boolean => {
    return Boolean(job.staffRequired === 2 && position?.isSecondDoubleUpHcp);
};

export const isDoubleUpVisit = (job: Patient, position: IJobPos): boolean => {
    return Boolean(job.staffRequired === 2 && position?.isDoubleUp);
};

export type GenderCompatibility = {
    female: Array<{ gender: string; warningType: string }>;
    male: Array<{ gender: string; warningType: string }>;
    non_binary: Array<{ gender: string; warningType: string }>;
    transgender: Array<{ gender: string; warningType: string }>;
    intersex: Array<{ gender: string; warningType: string }>;
    non_listed: Array<{ gender: string; warningType: string }>;
    not_provided: Array<{ gender: string; warningType: string }>;
    nopreference: Array<{ gender: string; warningType: string }>;
};

export const genderLabels: any = {
    male: 'Male',
    female: 'Female',
    transgender: 'Transgender',
    intersex: 'Intersex',
    non_listed: 'Non listed',
    not_provided: 'Not provided',
    non_binary: 'Non binary',
    nopreference: 'No preference',
};

type PreferenceGenderWarningType = { warningType: 'uncertain' | 'poor'; genders: string };

const getGenderWarningByType = (warning: PreferenceGenderWarningType, hcp: IHcp) => {
    return [
        `${
            warning.warningType === 'uncertain' ? 'Uncertain' : 'Poor'
        } compatibility: patient gender preference ${
            warning.genders || 'blank'
        } vs staff demographic ${genderLabels[hcp.gender] || 'Not provided'} for ${hcp.firstName} ${
            hcp.lastName
        }`,
    ];
};

export const getGenderWarning = (
    hcp?: IHcp,
    genderPreferences: Array<keyof GenderCompatibility> | null = [],
): string[] => {
    if (!hcp) {
        return [];
    }

    if (!genderPreferences?.length || (!genderPreferences.length && !hcp.gender)) {
        return [];
    }

    if (!hcp.gender && genderPreferences.length) {
        const genders = genderPreferences.map((g) => genderLabels[g]).join(', ');
        return getGenderWarningByType({ warningType: 'uncertain', genders }, hcp);
    }

    const isCompatible =
        genderPreferences.includes(hcp.gender as keyof GenderCompatibility) ||
        genderPreferences[0] === 'nopreference';

    if (isCompatible) {
        return [];
    }

    const warning: PreferenceGenderWarningType = {
        warningType: 'poor',
        genders: genderPreferences.map((g) => genderLabels[g])?.join(', '),
    };

    return getGenderWarningByType(warning, hcp);
};

type PreferenceWarningType = { warningType: 'uncertain' | 'poor'; languages: string };

const getLanguageWarningByType = (warning: PreferenceWarningType, hcp: IHcp) => {
    const hcpLanguage = hcp?.preferences?.languages?.join(', ');

    return [
        `${
            warning.warningType === 'uncertain' ? 'Uncertain' : 'Poor'
        } compatibility: patient language preference ${
            warning.languages || 'blank'
        } vs staff demographic ${hcpLanguage} for ${hcp.firstName} ${hcp.lastName}`,
    ];
};

export const getLanguageWarning = (hcp?: IHcp, languagePreferences: string[] = []) => {
    if (!hcp) {
        return [];
    }

    if (!languagePreferences?.length) {
        return [];
    }

    if (languagePreferences?.length && !hcp?.preferences?.languages?.length) {
        return getLanguageWarningByType(
            { warningType: 'uncertain', languages: languagePreferences?.join(', ') || '' },
            hcp,
        );
    }

    const isCompatible = languagePreferences.filter((preference) => {
        return hcp.preferences.languages?.includes(preference);
    });

    if (isCompatible.length) {
        return [];
    }

    const warnings: PreferenceWarningType = {
        warningType: 'poor',
        languages: languagePreferences?.join(', '),
    };

    return getLanguageWarningByType(warnings, hcp);
};

export const getValidityPeriod = (period?: { start?: string; end?: string }) => {
    const validFrom = period?.start ? moment(period.start).format('DD.MM.YYYY') : null;
    const validTo = period?.end ? moment(period.end).format('DD.MM.YYYY') : null;

    const validToText = validTo ? 'to ' + validTo : '';
    const description = validFrom ? `Valid from ${validFrom} ${validToText}` : '';

    return description;
};

export const formatAddressString = ({
    addressLine1,
    addressLine2,
    addressLine3,
    town,
    postCode,
}: PatientAddress) =>
    [addressLine1, addressLine2, addressLine3, town, postCode].filter((_) => _).join(', ');
