import React, { useEffect, useState } from 'react';
import { RangeSlider } from '@blueprintjs/core';
import { useDebounce } from './helper';

const ShiftValueSlider = (props: any) => {
    const {
        shiftValueMin,
        shiftValueMax,
        stepSize,
        labelStepSize,
        onChange,
        activeInterval,
    } = props;

    const [range, setRange] = useState(activeInterval);
    const debouncedSliderRange = useDebounce(range, 50);

    useEffect(() => {
        setRange(activeInterval);
    }, [activeInterval]);

    useEffect(() => {
        if (debouncedSliderRange) {
            onChange(debouncedSliderRange);
        }
    }, [debouncedSliderRange, onChange]);

    const handleChange = (range: any) => {
        setRange(range);
    };

    return (
        <React.Fragment>
            <RangeSlider
                min={shiftValueMin}
                max={shiftValueMax}
                stepSize={stepSize}
                labelStepSize={labelStepSize}
                value={range as any}
                onChange={handleChange}
                className="rota-builder__pay-rate__slider"
            />
        </React.Fragment>
    );
};

export default ShiftValueSlider;
