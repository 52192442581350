import { JobStatus, Patient, PatientAlert, StaffAlert } from '@doc-abode/data-models';
import useStores from '../../../../hook/useStores';

export function usePatientFlagsToChangeStatus(
    patient: Patient | undefined,
    needPatientAlertsToFilterBefore = false,
) {
    const {
        RootStore: {
            ucrStore: {
                focusedUser,
                focusedJobId,
                patientAlerts: allPatientAlerts,
                staffAlerts: allStaffAlerts,
            },
        },
    } = useStores() as { RootStore: any };

    const patientAlerts = needPatientAlertsToFilterBefore
        ? allPatientAlerts.filter((alert: any) => alert.jobId === focusedJobId)
        : allPatientAlerts;

    // TODO: add filter Staff Alerts functionality if needed
    const staffAlerts = allStaffAlerts;

    const isStatusCanBeChange =
        patient?.jobStatus === JobStatus.CURRENT ||
        patient?.buddyJobStatus === JobStatus.CURRENT ||
        patient?.jobStatus === JobStatus.ARRIVED ||
        patient?.buddyJobStatus === JobStatus.ARRIVED ||
        patient?.jobStatus === JobStatus.ACCEPTED ||
        patient?.buddyJobStatus === JobStatus.ACCEPTED ||
        patient?.jobStatus === JobStatus.COMPLETED ||
        patient?.buddyJobStatus === JobStatus.COMPLETED;

    const isNoPatientAlerts = patientAlerts.some(
        (alert: PatientAlert) => alert.jobId === focusedJobId && !alert.resolvedBy,
    );
    const isNoStaffAlerts = staffAlerts.some(
        (alert: StaffAlert) => alert.userId === patient?.hcpId && !alert.resolvedBy,
    );
    const isNoBuddyStaffAlerts = staffAlerts.some(
        (alert: StaffAlert) => alert.userId === patient?.buddyId && !alert.resolvedBy,
    );
    const isDoubleUp = patient?.staffRequired === 2;
    const isFirstUser = Boolean(!isDoubleUp || (isDoubleUp && focusedUser === 'user1'));
    const isDisabled =
        (isFirstUser && isStatusCanBeChange && !isNoPatientAlerts && !isNoStaffAlerts) ||
        (!isFirstUser && isStatusCanBeChange && !isNoPatientAlerts && !isNoBuddyStaffAlerts);

    return {
        isStatusCanBeChange,
        isNoPatientAlerts,
        isNoStaffAlerts,
        isNoBuddyStaffAlerts,
        isDoubleUp,
        isFirstUser,
        isDisabled,
    };
}
