import { rootUrl, retryable } from './baseApi';
export const fetchInvites = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/invites/users`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const updateInvite = (authToken: string, inviteId: string, body: BodyInit): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/invites/users/${inviteId}`, {
            headers: {
                Authorization: authToken,
            },
            method: 'PATCH',
            body: JSON.stringify(body),
        }),
    );
