import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import {
    RadioGroup,
    Radio,
    TextArea,
    Button,
    Tag,
    Tabs,
    Tab,
    Alert,
    Callout,
    Checkbox,
} from '@blueprintjs/core';
import { lowerCase, capitalize } from 'lodash';

import Modal from '../../modal/Modal';
import AppToaster from '../../helpers/Toaster';
import { formatDisplayDateTime } from '../../helpers/formatData';
import Error from '../../forms/Error';
import Onboarding from './Onboarding';

const statusIntents = {
    IN_PROGRESS: 'primary',
    MISSING_INFORMATION: 'warning',
    REJECTED: 'danger',
    APPROVED: 'success',
};

export const formatStatus = (status, { large, bold, disabled } = {}) => {
    return (
        <Tag minimal={!bold} intent={disabled ? 'none' : statusIntents[status]} large={large}>
            {capitalize(lowerCase(status))}
        </Tag>
    );
};

const InviteDetails = inject('RootStore')(
    observer(
        class InviteDetails extends Component {
            state = {
                status: undefined,
                comment: '',
                updating: false,
                downloading: {},
                isOpenConfirm: false,
                onboarding: {},
                invite: undefined,
                loading: true,
                complianceCheckMode: false,
                complianceCheckSubmitted: false,
                complianceChecked: false,
                complianceCheckedFiles: {},
            };

            _setInitialState = (state) => this.setState({ ...state });

            async componentDidMount() {
                const {
                    RootStore: {
                        invitesStore: { invites },
                        onboardingsStore: { getUserOnboarding, fetchOnboarding },
                    },
                    match: {
                        params: { inviteId },
                    },
                } = this.props;

                const invite = invites.find((invite) => invite.inviteId === inviteId);

                const { onboardingId, complianceChecks = [] } = invite;

                await fetchOnboarding(onboardingId);

                const complianceCheckMode = complianceChecks.length === 0;
                const onboarding = getUserOnboarding(onboardingId);

                if (complianceCheckMode) {
                    this.setState({
                        complianceCheckedFiles: onboarding.values.files.reduce(
                            (files, { uploadId }) => ({
                                ...files,
                                [uploadId]: false,
                            }),
                            {},
                        ),
                    });
                }

                this._setInitialState({
                    onboarding,
                    invite,
                    complianceCheckMode,
                    loading: false,
                });
            }

            _handleCloseClick = () => this.props.history.push(`/manage-applications`);

            _handleCommentChange = (event) => this.setState({ comment: event.target.value });

            _handleStatusChange = (event) => this.setState({ status: event.target.value });

            _submitForm = async (invite) => {
                this.setState({ updating: true, isOpenConfirm: false });

                const { status, comment } = this.state;
                const { inviteId, inviteStatus } = invite;

                const params = {};

                if (status && status !== inviteStatus) {
                    params.inviteStatus = status;
                }

                if (comment) {
                    params.comment = comment;
                }

                const { success } = await this.props.RootStore.invitesStore.updateInvite(
                    inviteId,
                    params,
                );

                this.setState({ updating: false });

                if (success) {
                    this.setState({
                        comment: '',
                    });

                    AppToaster.show({
                        message: 'Invite successfully updated.',
                        intent: 'success',
                    });

                    if (status === 'APPROVED') {
                        this._handleCloseClick();
                    }
                } else {
                    AppToaster.show({
                        message: 'Unable to update invite, please try again.',
                        intent: 'danger',
                    });
                }
            };

            _handleSubmit = async (event, invite) => {
                event.preventDefault();

                const { status, comment } = this.state;
                const { inviteStatus } = invite;

                if (!comment && (!status || status === inviteStatus)) {
                    return;
                }

                if (status === 'APPROVED') {
                    this.setState({ isOpenConfirm: true });
                    return;
                }

                this._submitForm(invite);
            };

            _complianceFileCheckedChange = (event) => {
                const { id, checked } = event.target;

                this.setState({
                    complianceCheckedFiles: {
                        ...this.state.complianceCheckedFiles,
                        [id]: checked,
                    },
                });
            };

            _complianceCheckedChanged = (event) =>
                this.setState({ complianceChecked: event.target.checked });

            _complianceCheckComplete = () => {
                const { complianceChecked, complianceCheckedFiles } = this.state;
                return (
                    complianceChecked &&
                    Object.values(complianceCheckedFiles).every((value) => value === true)
                );
            };

            _complianceCheckedSubmitted = async () => {
                this.setState({ complianceCheckSubmitted: true });

                if (!this._complianceCheckComplete()) return;

                this.setState({ updating: true });

                const {
                    invite: { inviteId },
                    onboarding: {
                        values: { files },
                    },
                } = this.state;

                const { success } = await this.props.RootStore.invitesStore.updateInvite(inviteId, {
                    complianceCheck: { files },
                });

                this.setState({ updating: false });

                if (success) {
                    this.setState({
                        comment: '',
                    });

                    AppToaster.show({
                        message: 'Invite successfully updated.',
                        intent: 'success',
                    });
                } else {
                    AppToaster.show({
                        message: 'Unable to update invite, please try again.',
                        intent: 'danger',
                    });
                }
            };

            _renderDetails = () => {
                const {
                    complianceCheckMode,
                    complianceChecked,
                    complianceCheckedFiles,
                    onboarding,
                    invite: { createdAt, updatedAt, inviteStatus, userId },
                    complianceCheckSubmitted,
                    updating,
                } = this.state;

                const error = !this._complianceCheckComplete() && complianceCheckSubmitted;

                return (
                    <>
                        <dl className="info">
                            <dt className="info__title">User ID</dt>
                            <dd className="info__definition">{userId}</dd>
                            <dt className="info__title">Status</dt>
                            <dd className="info__definition">{formatStatus(inviteStatus)}</dd>
                            <dt className="info__title">Last Updated</dt>
                            <dd className="info__definition">
                                {formatDisplayDateTime(updatedAt || createdAt)}
                            </dd>
                        </dl>
                        <Onboarding
                            onboarding={onboarding}
                            complianceCheckMode={complianceCheckMode}
                            complianceCheckError={error}
                            complianceCheckedFiles={complianceCheckedFiles}
                            complianceCheckboxChange={this._complianceFileCheckedChange}
                        />
                        {complianceCheckMode && (
                            <>
                                <Callout
                                    title="Compliance Review"
                                    intent={
                                        complianceChecked ? 'success' : error ? 'danger' : 'primary'
                                    }
                                >
                                    <Checkbox
                                        checked={complianceChecked}
                                        onChange={this._complianceCheckedChanged}
                                        label="I confirm that I have checked all above information and I am safisfied that it meets the requirements for this organisation."
                                    />
                                </Callout>
                                {error && (
                                    <Error
                                        errors={[
                                            'You must confirm that all information and individual files have been checked before submitting.',
                                        ]}
                                    />
                                )}
                                <div className="invite-details__button-row">
                                    <Button
                                        intent="primary"
                                        className="invite-details__submit-button"
                                        onClick={this._complianceCheckedSubmitted}
                                        loading={updating}
                                        large
                                    >
                                        Submit Compliance Review
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                );
            };

            _renderComments = (comments = []) =>
                comments.length > 0 ? (
                    <ul className="comments">
                        {comments.map(({ message, author, timestamp }) => (
                            <li className="comments__item" key={timestamp}>
                                <h3 className="h3 comments__heading">
                                    By {author} at {formatDisplayDateTime(timestamp)}
                                </h3>
                                <span>{message}</span>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <span>There are currently no comments.</span>
                );

            _renderUpdateInvite = () => {
                const {
                    configStore: { confirmRegisterHCPText, confirmRegisterHCPChecklist },
                    userStore: { isComplianceOfficer },
                } = this.props.RootStore;

                const {
                    status,
                    comment,
                    isOpenConfirm,
                    complianceCheckMode,
                    invite,
                    invite: { inviteStatus, complianceChecks = [] },
                } = this.state;

                const [complianceCheck] = complianceChecks;

                return (
                    <form onSubmit={(event) => this._handleSubmit(event, invite)}>
                        {!isComplianceOfficer && (
                            <>
                                {inviteStatus !== 'APPROVED' && (
                                    <>
                                        <h3 className="h3">Update status</h3>
                                        <RadioGroup
                                            className="invite-details__change-status"
                                            onChange={this._handleStatusChange}
                                            selectedValue={status || inviteStatus}
                                        >
                                            {['MISSING_INFORMATION', 'REJECTED', 'APPROVED'].map(
                                                (statusName) => {
                                                    const active =
                                                        statusName === status &&
                                                        statusName !== inviteStatus;
                                                    const disabled =
                                                        statusName === 'APPROVED' &&
                                                        complianceCheckMode;
                                                    return (
                                                        <Radio
                                                            large
                                                            value={statusName}
                                                            disabled={disabled}
                                                            key={statusName}
                                                        >
                                                            {formatStatus(statusName, {
                                                                large: true,
                                                                bold: active,
                                                                disabled,
                                                            })}
                                                        </Radio>
                                                    );
                                                },
                                            )}
                                        </RadioGroup>
                                    </>
                                )}
                                {complianceCheck ? (
                                    <Callout
                                        intent="success"
                                        className="invite-details__compliance-check"
                                    >
                                        Compliance checked by {complianceCheck.completedBy} at{' '}
                                        {formatDisplayDateTime(complianceCheck.timestamp)}
                                    </Callout>
                                ) : (
                                    <Callout
                                        icon="issue"
                                        className="invite-details__compliance-check"
                                    >
                                        The application cannot be approved until it has been
                                        compliance checked. Please use the Details tab above to
                                        confirm that all details of the application are compliant.
                                    </Callout>
                                )}
                            </>
                        )}
                        <h3 className="h3">Add a comment</h3>
                        <TextArea
                            fill
                            className="invite-details__comment-box"
                            rows="4"
                            value={comment}
                            onChange={this._handleCommentChange}
                        />
                        <div className="invite-details__button-row">
                            <Button
                                className="invite-details__submit-button"
                                intent="success"
                                icon="tick"
                                large
                                type="submit"
                                loading={this.state.updating}
                            >
                                Save changes
                            </Button>
                        </div>
                        <Alert
                            isOpen={isOpenConfirm}
                            onConfirm={() => this._submitForm(invite)}
                            onCancel={() => this.setState({ isOpenConfirm: false })}
                            cancelButtonText="Cancel"
                            confirmButtonText="Confirm"
                            icon="warning-sign"
                            intent="primary"
                            className="dialog--wide"
                        >
                            <h2 className="h2">Important Note</h2>
                            <p>{confirmRegisterHCPText}</p>
                            {confirmRegisterHCPChecklist && (
                                <ul className="bp5-list">
                                    {confirmRegisterHCPChecklist.map((item) => (
                                        <li key={item}>{item}</li>
                                    ))}
                                </ul>
                            )}
                        </Alert>
                    </form>
                );
            };

            render() {
                const {
                    match: {
                        params: { inviteId },
                    },
                } = this.props;

                const { invite, loading } = this.state;

                if (loading) {
                    return (
                        <Modal title="Loading..." onClose={this._handleCloseClick}>
                            <p>Loading user's onboarding information...</p>
                        </Modal>
                    );
                }

                if (!invite && !loading) {
                    return (
                        <Modal title="Application not found" onClose={this._handleCloseClick}>
                            <p>
                                No application found with id: <strong>{inviteId}</strong>
                            </p>
                        </Modal>
                    );
                }

                const { comments, userName } = invite;

                return (
                    <Modal
                        title={`Application details: ${userName}`}
                        onClose={this._handleCloseClick}
                    >
                        <Tabs id="TabsExample" defaultSelectedTabId="details" large>
                            <Tab id="details" title="Details" panel={this._renderDetails()} />
                            <Tab
                                id="comments"
                                title="Comments"
                                panel={this._renderComments(comments)}
                            />
                            <Tab
                                id="updateInvite"
                                title="Update application"
                                panel={this._renderUpdateInvite()}
                            />
                        </Tabs>
                    </Modal>
                );
            }
        },
    ),
);

export default InviteDetails;
