import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Callout } from '@blueprintjs/core';
import { Row, Col } from 'react-grid-system';

const RequiresAttentionHighlight = inject('RootStore')(
    observer(
        class RequiresAttentionHighlight extends Component {
            render() {
                const jobRequiresAttention = this.props.RootStore.jobsStore.doesJobRequireAttention(
                    this.props.job,
                );

                return jobRequiresAttention ? (
                    <div className="requires-attention-highlight-wrapper">
                        <Row className="requires-attention-highlight">
                            <Col xs={12}>
                                <Callout intent="danger">Requires attention</Callout>
                            </Col>
                        </Row>
                    </div>
                ) : null;
            }
        },
    ),
);

export default RequiresAttentionHighlight;
