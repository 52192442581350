/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { Switch } from '@blueprintjs/core';

const FormSwitch = ({ name, options, disabled, ...rest }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helpers] = useField(name);

    React.useEffect(() => {
        if (meta.initialValue) {
            helpers.setValue(meta.initialValue);
        }
    }, [meta.initialValue]);

    return (
        <Switch
            checked={field.value}
            onChange={() => {
                helpers.setValue(!field.value);
            }}
            disabled={disabled}
            innerLabelChecked="yes"
            innerLabel="no"
        />
    );
};

export default FormSwitch;
