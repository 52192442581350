import { gql } from '@apollo/client';

export const GET_FITTOPRACTICE = gql`
    query GetFitToPractices {
        getFitToPractices {
            id
            updatedAt
            createdAt
            label
        }
    }
`;
