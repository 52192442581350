import { Button, FormGroup, InputGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import Select from 'react-select';
import { onFilterChange, onTableSearch } from '../../../../helpers/common';
import CommonPagination from '../CommonHRRotaPagination';

interface Props {
    previousPage: any;
    nextPage: any;
    loading: boolean;
    pageInfo: any;
    onSearch: any;
    onFilter: any;
    addNew: any;
    editItem: any;
    archiveItem: any;
    selected: any;
}

const RECORD_STATUS_OPTIONS = [
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Archived',
        value: 'archived',
    },
    {
        label: 'All',
        value: null,
    },
];

const FundingPoolsTableNav = ({
    previousPage,
    nextPage,
    loading,
    pageInfo,
    onSearch,
    onFilter,
    addNew,
    editItem,
    archiveItem,
    selected,
}: Props) => {
    const onChange = useCallback((name: any) => (el: any) => onFilterChange(name, el, onFilter), [
        onFilter,
    ]);
    const onSearchChange = useCallback(
        async (inputValue: any) => {
            onTableSearch(inputValue, onSearch);
        },
        [onSearch],
    );

    const ContentProps = {
        shouldDismissPopover: false,
    };

    const Content = (shouldDismissPopover: any) => (
        <Menu>
            <MenuItem onClick={editItem} text="Edit" disabled={selected.length !== 1} />
            <MenuItem
                onClick={archiveItem}
                text="Archive"
                className="bp5-intent-danger"
                disabled={selected.length < 1}
            />
        </Menu>
    );

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            <FormGroup
                                label="Type at least three characters to see results"
                                className="common-header__filter"
                            >
                                <InputGroup
                                    onChange={(e) => onSearchChange(e.target.value)}
                                    placeholder="Search..."
                                    large
                                />
                            </FormGroup>
                            <FormGroup label="Record Status" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={RECORD_STATUS_OPTIONS}
                                    onChange={onChange('recordStatus')}
                                    className="react-select"
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="Funding Pulls"
                        />
                    </div>
                    <div className="common-header__buttons">
                        <Button
                            text="New"
                            intent="success"
                            icon="add"
                            onClick={addNew}
                            className="common-header__button common-header__button--add bp5-outlined"
                        />
                        <Popover
                            enforceFocus={false}
                            placement="bottom-start"
                            interactionKind="click"
                            content={<Content {...ContentProps} />}
                            renderTarget={({ isOpen, ref, ...p }: any) => (
                                <Button
                                    {...p}
                                    active={isOpen}
                                    elementRef={ref}
                                    intent="primary"
                                    rightIcon="chevron-down"
                                    text="Actions"
                                    className="bp5-outlined"
                                />
                            )}
                        />
                    </div>
                </>
            )}
        </nav>
    );
};
export default FundingPoolsTableNav;
