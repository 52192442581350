import * as Yup from 'yup';
import moment from 'moment';

import { transformDate } from '../../../../helpers/transformDate';

type Options = {
    includeDateOfBirth?: boolean;
};

export default function validationSchema(options: Options = {}) {
    const schema: { [key: string]: any } = {
        addressLine1: Yup.string().required('This field is required.'),
        addressLine2: Yup.string(),
        gender: Yup.string().required('This field is required.'),
        flags: Yup.array(),
        vaccinationCategory: Yup.string().required('This field is required.'),
        coAdministeredWith: Yup.array(),
        consented: Yup.string().required('This field is required.'),
        notes: Yup.string(),
        doseNumber: Yup.string().matches(
            /^\d+$/,
            'Dose number can only be a positive number or blank',
        ),
        firstName: Yup.string().required('This field is required.'),
        lastName: Yup.string().required('This field is required.'),
        postCode: Yup.string().required('This field is required.'),
        town: Yup.string().required('This field is required.'),
        hubId: Yup.string().required('This field is required.'),
        nhsNumber: Yup.string()
            .required('This field is required.')
            .matches(/^\d{10}$/, 'NHS number is invalid, must be 10 digits only.'),
        homePhone: Yup.string().matches(
            /^\d{11}$/,
            'Home phone number is invalid, must be 11 digits only.',
        ),
        mobilePhone: Yup.string().matches(
            /^\d{11}$/,
            'Mobile phone number is invalid, must be 11 digits only.',
        ),
        manufacturerOfDose1: Yup.string(),
        manufacturerOfDose2: Yup.string(),
        manufacturerOfDose3: Yup.string(),
        manufacturerOfDose4: Yup.string(),
        dateOfDose1: Yup.date()
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test(
                'min',
                'Date cannot be in the future or less than 1 December 2020',
                (value: Date | undefined) => {
                    if (!value) {
                        return true;
                    }
                    const current = value.valueOf();
                    return (
                        current >= new Date(2020, 11, 1).valueOf() &&
                        current <= new Date().valueOf()
                    );
                },
            ),
        dateOfDose2: Yup.date()
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test(
                'min',
                'Date cannot be in the future or less than 1 December 2020',
                (value: Date | undefined) => {
                    if (!value) {
                        return true;
                    }
                    const current = value.valueOf();
                    return (
                        current >= new Date(2020, 11, 1).valueOf() &&
                        current <= new Date().valueOf()
                    );
                },
            ),
        dateOfDose3: Yup.date()
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test(
                'min',
                'Date cannot be in the future or less than 1 December 2020',
                (value: Date | undefined) => {
                    if (!value) {
                        return true;
                    }
                    const current = value.valueOf();
                    return (
                        current >= new Date(2020, 11, 1).valueOf() &&
                        current <= new Date().valueOf()
                    );
                },
            ),
        dateOfDose4: Yup.date()
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test(
                'min',
                'Date cannot be in the future or less than 1 December 2020',
                (value: Date | undefined) => {
                    if (!value) {
                        return true;
                    }
                    const current = value.valueOf();
                    return (
                        current >= new Date(2020, 11, 1).valueOf() &&
                        current <= new Date().valueOf()
                    );
                },
            ),
        dateOfPreviousDose: Yup.date()
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test(
                'min',
                'Date cannot be in the future or less than 1 December 2020',
                (value: Date | undefined) => {
                    if (!value) {
                        return true;
                    }
                    const current = value.valueOf();
                    return (
                        current >= new Date(2020, 11, 1).valueOf() &&
                        current <= new Date().valueOf()
                    );
                },
            ),
        availableFrom: Yup.string().matches(
            /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
            'Time must be in the format HH:MM in the range 00:00 - 23:59',
        ),
        availableTo: Yup.string()
            .matches(
                /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
                'Time must be in the format HH:MM in the range 00:00 - 23:59',
            )
            .when('availableFrom', (availableFrom, schema) => {
                return availableFrom
                    ? schema.test(
                          'availableTo',
                          '"Available to" cannot be earlier than "Available from"',
                          (value: Date) => {
                              if (!value) {
                                  return false;
                              }
                              const from = new Date('1/1/1999 ' + availableFrom);
                              const to = new Date('1/1/1999 ' + value);
                              return from < to;
                          },
                      )
                    : schema;
            }),
        madeCurrentDateTime: Yup.date().typeError('Date is required'),
        arrivedDateTime: Yup.date()
            .typeError('Date is required')
            .when('madeCurrentDateTime', (madeCurrentDateTime, schema) => {
                return madeCurrentDateTime
                    ? schema.test(
                          'arrivedDateTime',
                          'The time of arrival for the visit cannot be earlier than the time it was made current',
                          (value: Date) => {
                              if (!value) {
                                  return true;
                              }
                              return moment(madeCurrentDateTime)
                                  .startOf('minute')
                                  .isSameOrBefore(moment(value).startOf('minute'));
                          },
                      )
                    : schema;
            }),
        finishedDateTime: Yup.date()
            .typeError('Date is required')
            .when('arrivedDateTime', (arrivedDateTime, schema) => {
                return arrivedDateTime
                    ? schema.test(
                          'finishedDateTime',
                          'The time of completion for the visit cannot be earlier than the time of arrival',
                          (value: Date) => {
                              if (!value) {
                                  return true;
                              }
                              return moment(arrivedDateTime)
                                  .startOf('minute')
                                  .isSameOrBefore(moment(value).startOf('minute'));
                          },
                      )
                    : schema;
            }),
    };

    if (options.includeDateOfBirth) {
        schema.dateOfBirth = Yup.date()
            .required('This field is required.')
            .transform(transformDate)
            .typeError('Date must be in the format DD/MM/YYYY.')
            .test('min', 'Date cannot be in the future or more than 150 years ago.', (value) => {
                if (!value) {
                    return false;
                }
                const current = value.valueOf();
                return (
                    current <= new Date().valueOf() && moment().diff(moment(current), 'years') < 150
                );
            });
    }

    return Yup.object(schema);
}
