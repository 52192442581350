import Auth from '@aws-amplify/auth';
export const userSignOut = async (): Promise<any> => {
    return Auth.signOut();
};

export const getUserCurrentSession = async (): Promise<any> => {
    const session = await Auth.currentSession();

    if (!session) {
        await userSignOut();
        return window.location.assign('/');
    }

    return session;
};

export const getBearerToken = async (options: { [key: string]: any } = {}): Promise<any> => {
    const userSession = await getUserCurrentSession();
    let token = '';

    if (userSession && userSession.isValid()) {
        const cognitoToken =
            options.tokenType === 'id' ? userSession.getIdToken() : userSession.getAccessToken();
        token = cognitoToken.getJwtToken();
    }

    return `${options.asBearerToken ? 'Bearer ' : ''}${token}`;
};

export const AUTH_STATUS = {
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
    VERIFICATION_REQUIRED: 'VERIFICATION_REQUIRED',
    SMS_MFA: 'SMS_MFA',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    LOGGED_IN: 'LOGGED_IN',
};

export const signIn = async (username: string, password: string): Promise<any> => {
    const user = await Auth.signIn(username, password);
    const emailVerified = user?.attributes?.email_verified;
    const phoneNumberVerified = user?.attributes?.phone_number_verified;

    return {
        status:
            user.challengeName ||
            (emailVerified || phoneNumberVerified
                ? AUTH_STATUS.LOGGED_IN
                : AUTH_STATUS.VERIFICATION_REQUIRED),
        user,
    };
};

export const completeNewPassword = async (user: any, newPassword: string): Promise<any> => {
    const result = await Auth.completeNewPassword(user, newPassword);
    const emailVerified = user?.attributes?.email_verified;
    const phoneNumberVerified = user?.attributes?.phone_number_verified;

    return {
        status:
            result.challengeName ||
            (emailVerified || phoneNumberVerified
                ? AUTH_STATUS.LOGGED_IN
                : AUTH_STATUS.VERIFICATION_REQUIRED),
        user,
    };
};

export const forgotPassword = (username: string): Promise<any> => {
    return Auth.forgotPassword(username);
};

export const resetPassword = (
    username: string,
    code: any,
    newPassword: string,
): Promise<string> => {
    return Auth.forgotPasswordSubmit(username, code.toString(), newPassword);
};

export const verifyCurrentUserAttribute = (contactType: string): Promise<void> => {
    return Auth.verifyCurrentUserAttribute(contactType);
};

export const verifyCurrentUserAttributeSubmit = (
    contactType: string,
    code: string,
): Promise<string> => {
    return Auth.verifyCurrentUserAttributeSubmit(contactType, code);
};

export const confirmMFACode = (user: any, code: any, mfaType: any): Promise<any> => {
    return Auth.confirmSignIn(user, code.toString(), mfaType);
};
