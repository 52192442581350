import React from 'react';
import Select from 'react-select';

const PAGE_SIZE = 10;

interface selectProps {
    isDisabled?: boolean;
    onLoadOptions: any;
    onChange: (val: any) => void;
    values: any;
    isMulti?: boolean;
    placeholder?: string;
    labelModifier?: any;
    hideArchived?: boolean;
    emptyOption?: boolean;
}

const CustomAsyncSelect = ({
    isDisabled = false,
    onLoadOptions,
    onChange,
    values,
    isMulti = false,
    labelModifier,
    placeholder,
    hideArchived = false,
    emptyOption = false,
}: selectProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [items, setItems] = React.useState([]);
    const [query, setQuery] = React.useState(null);
    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        setLoadMore(true);
    }, []);

    React.useEffect(() => {
        async function getData() {
            if (loadMore) {
                setIsLoading(true);
                const lastItem = items.length === 0 ? null : (items[items.length - 1] as any).value;

                const { data } = await onLoadOptions(PAGE_SIZE, lastItem, query);
                const { edges } = data[Object.keys(data)[0]];
                let newItems = edges
                    .filter((item: any) => (hideArchived ? !item.node?.isArchived : item))
                    .map((item: any) => ({
                        value: item.node.id,
                        label: `${item.node.name} ${
                            labelModifier ? `(${item.node[labelModifier]})` : ''
                        }`,
                    }));

                // @ts-ignore
                setItems([...items, ...newItems]);
                setIsLoading(false);
                setLoadMore(false);
            }
        }

        getData();
    }, [hideArchived, items, labelModifier, loadMore, onLoadOptions, query]);

    React.useEffect(() => {
        if (!query) return;
        setItems([]);
        setLoadMore(true);
    }, [query]);

    return (
        <div className={emptyOption ? 'common-select__has-empty' : ''}>
            <Select
                isDisabled={isDisabled}
                isMulti={isMulti}
                isLoading={isLoading}
                onMenuScrollToBottom={() => {
                    setLoadMore(true);
                }}
                onInputChange={(val: any) => {
                    setQuery(val);
                }}
                onChange={(values) => {
                    if (onChange) onChange(values);
                    setQuery(null);
                    setItems([]);
                    setLoadMore(true);
                }}
                onMenuClose={() => {
                    if (query && (query as any).length > 0) {
                        setItems([]);
                        setQuery(null);
                    }
                }}
                options={emptyOption ? [{}, ...items] : items}
                defaultValue={
                    isMulti
                        ? values?.map((val: any) => ({
                              value: val?.id,
                              label: val?.name,
                          }))
                        : {
                              value: values?.id,
                              label: values?.name,
                          }
                }
                value={items.find((item: any) => item.value === values)}
                placeholder={<span>{placeholder}</span>}
            />
        </div>
    );
};

export default CustomAsyncSelect;
