import cn from 'classnames';
import { observer } from 'mobx-react';
import { FC } from 'react';
import useStores from '../../../../../../hook/useStores';
import { LIST_STATE, SORT_COLUMN } from '../../../../../../stores/v2/PatientListStore';
import { IconArrowDropDown, IconArrowDropUp } from '../../../../../../helpers/ucr/icons';

/**
 * TODO can children type also be React.ReactNode ?
 */
export const PatientListHeader: FC<{ column?: SORT_COLUMN; children?: string }> = observer(
    ({ column, children = '' }) => {
        const {
            RootStore: {
                patientListStoreV2: { sorts, setSort, state },
            },
        } = useStores() as { RootStore: any };
        let sortedColumn = '';
        for (let val in sorts) {
            sortedColumn = val;
        }
        return (
            <th
                className={cn('patient-list__header', {
                    'patient-list__header--sortable': Boolean(column),
                    'patient-list__header--sorted': sortedColumn === column,
                    'patient-list__header--disabled': sorts && state === LIST_STATE.LOADING,
                })}
                onClick={() => {
                    if (state !== LIST_STATE.LOADING && column) {
                        setSort(column);
                    }
                }}
            >
                <div className="patient-list__header-flex">
                    <div
                        className={`patient-list__header-text ${
                            sortedColumn === column && sorts[sortedColumn]
                        }`}
                    >
                        {children}
                    </div>
                    {column && (
                        <div
                            className={
                                sortedColumn !== undefined && sortedColumn === column
                                    ? sorts[sortedColumn]
                                    : ''
                            }
                        >
                            <IconArrowDropUp className="patient-list__header-sort-icon icon-asc" />
                            <IconArrowDropDown className="patient-list__header-sort-icon icon-desc" />
                        </div>
                    )}
                </div>
            </th>
        );
    },
);
