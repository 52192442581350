import React from 'react';
import { Button, FormGroup, Icon, InputGroup } from '@blueprintjs/core';
import Statuses from '../../../../../common/Statuses';

export interface BuilderHeaderProps {
    handleRotaNameChange: (e: any) => void;
    handleViewMode: () => void;
    handleSaveRota: (bool: boolean) => void;
    handleSavingRota: (bool: boolean) => void;
    isPublished: boolean;
    isViewMode: boolean;
    isCreatePath: boolean;
    isFormDirty: boolean;
    loading: boolean;
    name?: string;
    history: any;
}

export const BuilderHeader = (props: BuilderHeaderProps) => {
    const {
        handleRotaNameChange,
        handleSaveRota,
        handleSavingRota,
        handleViewMode,
        isPublished,
        isFormDirty,
        name,
        isViewMode,
        history,
        isCreatePath,
        loading,
    } = props;

    const handleCancel = () => {
        history?.push(`/rota/list/`, {
            prevPath: history.location,
        });
    };

    return (
        <div className="rota-builder__header">
            <div className="rota-builder__header-left">
                <div className="rota-builder__name">
                    {!isViewMode && (
                        <FormGroup className="rota-builder__header-input">
                            <InputGroup
                                data-testid="rbhNameInput"
                                placeholder="Name"
                                role="rbhNameInput"
                                value={name}
                                onChange={(e) => {
                                    handleRotaNameChange(e);
                                }}
                            />
                            {isFormDirty && name?.length === 0 && (
                                <div className="builder-form__error">This field is required</div>
                            )}
                        </FormGroup>
                    )}
                    {isViewMode && (
                        <h2 data-testid="rbhName" className="rota-builder__title">
                            {name}
                        </h2>
                    )}
                </div>
                <div>
                    {!isCreatePath && (
                        <div
                            data-testid="rbhStatus"
                            className={`rota-builder__status rota-builder__status--${
                                isPublished ? 'published' : 'draft'
                            }`}
                        >
                            {isPublished ? 'Published' : 'Draft'}
                        </div>
                    )}
                </div>
                {!loading && (
                    <div className="rota-builder__header-buttons">
                        {!isViewMode && isCreatePath && (
                            <>
                                <Button
                                    icon="cross"
                                    data-testid="rbhCreateCancelButton"
                                    outlined={true}
                                    intent="none"
                                    onClick={() => {
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>

                                {!isPublished && (
                                    <button
                                        data-testid="rbhCreateSaveDraftButton"
                                        className="btn btn--secondary"
                                        onClick={() => handleSaveRota(false)}
                                    >
                                        Save as Draft
                                    </button>
                                )}

                                <Button
                                    icon="tick"
                                    data-testid="rbhCreatePublishButton"
                                    outlined={true}
                                    intent="success"
                                    onClick={() => handleSavingRota(true)}
                                >
                                    Publish
                                </Button>
                            </>
                        )}
                        {!isViewMode && !isCreatePath && (
                            <>
                                <Button
                                    icon="cross"
                                    outlined={true}
                                    data-testid="rbhPublishedCancelButton"
                                    intent="none"
                                    onClick={() => {
                                        handleCancel();
                                    }}
                                >
                                    Cancel
                                </Button>

                                {!isPublished && (
                                    <Button
                                        data-testid="rbhPublishedSaveButton"
                                        intent="success"
                                        onClick={() => handleSaveRota(false)}
                                    >
                                        Save
                                    </Button>
                                )}
                                {!isPublished && (
                                    <Button
                                        icon="tick"
                                        outlined={true}
                                        intent="success"
                                        data-testid="rbhPublishedPublishButton"
                                        onClick={() => handleSavingRota(true)}
                                    >
                                        Publish
                                    </Button>
                                )}

                                {isPublished && (
                                    <Button
                                        icon="tick"
                                        outlined={true}
                                        intent="success"
                                        data-testid="rbhPublishedRePublishButton"
                                        onClick={() => handleSavingRota(true)}
                                    >
                                        Re-publish
                                    </Button>
                                )}
                            </>
                        )}

                        {isViewMode && (
                            <>
                                <Button
                                    data-testid="rbhViewEditButton"
                                    aria-label={'editButton'}
                                    icon="edit"
                                    outlined={true}
                                    intent="primary"
                                    onClick={() => {
                                        handleViewMode();
                                    }}
                                >
                                    Edit
                                </Button>
                                {!isPublished && (
                                    <Button
                                        data-testid="rbhDraftPublishButton"
                                        icon="tick"
                                        outlined={true}
                                        intent="success"
                                        onClick={() => handleSavingRota(true)}
                                    >
                                        Publish
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>

            <div className="rota-builder__header-right">
                <div data-testid="rbhStatuses">
                    <Statuses />
                </div>

                <div>
                    <Icon
                        data-testid="rbhCloseIcon"
                        className="rota-builder__close"
                        onClick={() => {
                            handleCancel();
                        }}
                        icon="cross"
                    />
                </div>
            </div>
        </div>
    );
};

export default BuilderHeader;
