import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import ExitConfirmation from './Dialogs/ExitConfirmationDialog';

interface RotaPromptProps {
    when: any;
    isPublished: boolean;
    message?: string;
    onPromptPublish: any;
    onPromptSaveDraft: any;
    onPromptExit: any;
}

export function RotaPrompt(props: RotaPromptProps) {
    const { when, isPublished, onPromptPublish, onPromptSaveDraft, onPromptExit } = props;

    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [replacePath, setReplacePath] = useState('');

    const unblockRef = useRef<any>();

    useEffect(() => {
        // @ts-ignore
        unblockRef.current = history.block((path) => {
            if (when) {
                setShowPrompt(true);
                setReplacePath(path.pathname);
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [history, when]);

    return (
        <ExitConfirmation
            isPublished={isPublished}
            isOpen={showPrompt}
            onCancel={() => setShowPrompt(false)}
            onExit={() => {
                setShowPrompt(false);
                onPromptExit();
            }}
            onPublish={() => {
                setShowPrompt(false);
                return onPromptPublish(replacePath);
            }}
            onSaveDraft={() => {
                setShowPrompt(false);
                return onPromptSaveDraft(replacePath);
            }}
        />
    );
}
