import React, { Component } from 'react';
import { Alert, Button, ButtonGroup } from '@blueprintjs/core';
import { repeatsDays } from '../constants';
import { inject, observer } from 'mobx-react';
import ShiftPattern from './ShiftPattern';
import { IconAdd } from '../../../../../helpers/ucr/icons';
import { v4 as uuid_v4 } from 'uuid';

const ShiftPatternWeek = inject('RootStore')(
    observer(
        class ShiftPatternWeek extends Component {
            state = {
                showAlert: false,
            };

            componentDidMount() {
                ['click', 'keyup'].forEach((evt) =>
                    window.addEventListener(evt, this._handlePasteCancel, false),
                );
            }

            componentWillUnmount() {
                ['click', 'keyup'].forEach((evt) =>
                    window.addEventListener(evt, this._handlePasteCancel, false),
                );
            }

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _handleShiftClick = (shiftItem) => {
                if (shiftItem) {
                    if (this.props.RootStore.shiftPatternsStore.entryActionType === 'create') {
                        this.props.RootStore.shiftPatternsStore.isCreatePath = true;
                    }
                    this.props.RootStore.shiftPatternsStore.updateShiftEntry(shiftItem);
                    this.props.RootStore.shiftPatternsStore.showShiftEntryModal = true;
                }
            };

            componentDidUpdate() {
                // update filter options on change of shifts (adding, deleting)
                this.props.generateFilterOptions();
            }

            _handleAddShift = (week, day) => {
                this.props.RootStore.shiftPatternsStore.addNewShiftPatternEntry({
                    weekNumber: +week,
                    dayOfWeek: +day,
                    newShiftId: uuid_v4(),
                });
                this.props.RootStore.shiftPatternsStore.updateEntryActionType('create');
                this.props.RootStore.shiftPatternsStore.showShiftEntryModal = true;
                this.props.RootStore.shiftPatternsStore.isCreatePath = false;
            };
            _handleDeleteClick = (week) => {
                const filteredWeeks = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber !== week,
                );

                const movedWeeks = filteredWeeks.filter((shift) => shift.weekNumber > week);
                const stableWeeks = filteredWeeks.filter((shift) => shift.weekNumber < week);

                const updatedWeeks = movedWeeks.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber - 1,
                }));

                const combinedWeeks = [...stableWeeks, ...updatedWeeks];

                this.props.RootStore.shiftPatternsStore.newShiftPattern.weeks = this.props.RootStore.shiftPatternsStore.newShiftPattern.weeks.filter(
                    (a) =>
                        a !== this.props.RootStore.shiftPatternsStore.newShiftPattern.weeks.length,
                );
                combinedWeeks &&
                    this.props.RootStore.shiftPatternsStore.modifyNewShiftPatternEntries(
                        combinedWeeks,
                    );
                this.setState({ showAlert: false });
            };

            _handleMoveUpClick = () => {
                const filteredWeekSmaller = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber === this.props.week - 1,
                );
                const filteredWeekCurrent = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber === this.props.week,
                );

                const updatedSmallerWeeksShifts = filteredWeekSmaller.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber + 1,
                }));
                const updatedCurrentWeeksShifts = filteredWeekCurrent.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber - 1,
                }));

                const combinedWeeks = [...updatedSmallerWeeksShifts, ...updatedCurrentWeeksShifts];

                combinedWeeks &&
                    combinedWeeks.forEach((currentWeekShift) =>
                        this.props.RootStore.shiftPatternsStore.replaceNewShiftPatternEntry(
                            currentWeekShift,
                        ),
                    );
            };

            _handleMoveDownClick = () => {
                const filteredWeekLarger = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber === this.props.week + 1,
                );
                const filteredWeekCurrent = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber === this.props.week,
                );

                const updatedLargerWeeksShifts = filteredWeekLarger.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber - 1,
                }));
                const updatedCurrentWeeksShifts = filteredWeekCurrent.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber + 1,
                }));

                const combinedWeeks = [...updatedLargerWeeksShifts, ...updatedCurrentWeeksShifts];

                combinedWeeks &&
                    combinedWeeks.forEach((currentWeekShift) =>
                        this.props.RootStore.shiftPatternsStore.replaceNewShiftPatternEntry(
                            currentWeekShift,
                        ),
                    );
            };

            _handleDuplicateClick = () => {
                const duplicatedWeek = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber === this.props.week,
                );

                const currentWeeks = this.props.RootStore.shiftPatternsStore.newShiftPattern.shiftPatternEntries.filter(
                    (obj) => obj.weekNumber > this.props.week,
                );

                const currentWeeksShifts = currentWeeks.map((currentWeekShift) => ({
                    ...currentWeekShift,
                    weekNumber: currentWeekShift.weekNumber + 1,
                }));

                const duplicatedWeekShifts = duplicatedWeek.map((duplicatedWeekShift) => {
                    const duplicatedShift = {
                        ...duplicatedWeekShift,
                        weekNumber: duplicatedWeekShift.weekNumber + 1,
                        newShiftId: uuid_v4(),
                        id: null,
                    };
                    delete duplicatedShift.id;
                    return duplicatedShift;
                });

                currentWeeksShifts &&
                    currentWeeksShifts.forEach((currentWeekShift) =>
                        this.props.RootStore.shiftPatternsStore.replaceNewShiftPatternEntry(
                            currentWeekShift,
                        ),
                    );

                duplicatedWeekShifts &&
                    duplicatedWeekShifts.forEach((duplicatedWeekShift) =>
                        this.props.RootStore.shiftPatternsStore.updateNewShiftPatternEntries(
                            duplicatedWeekShift,
                        ),
                    );

                this.props.RootStore.shiftPatternsStore.addNewWeek();
            };

            _handlePasteClick = (week, day) => {
                this.props.RootStore.shiftPatternsStore.updateShiftEntry({
                    ...this.props.RootStore.shiftPatternsStore.newShiftPatternEntry,
                    comments: [],
                    weekNumber: +week,
                    dayOfWeek: +day,
                });
                this.props.RootStore.shiftPatternsStore.updateNewShiftPatternEntries(
                    this.props.RootStore.shiftPatternsStore.newShiftPatternEntry,
                );
                this.props.RootStore.shiftPatternsStore.clearNewShiftPatternEntry();
                this.props.RootStore.shiftPatternsStore.clipboardActive = false;
            };

            _handlePasteCancel = (e) => {
                if (this.props.RootStore.shiftPatternsStore.clipboardActive === true) {
                    if (
                        !e.target.classList.contains('weeks-table__col-content-item--paste') ||
                        e.key === 'Escape'
                    ) {
                        this.props.RootStore.shiftPatternsStore.clipboardActive = false;
                        this.props.RootStore.shiftPatternsStore.clearNewShiftPatternEntry();
                    }
                }
            };

            _addNewWeek = () => {
                this.props.RootStore.shiftPatternsStore.addNewWeek();
            };

            render() {
                const { editOrCreateMode, week, history, shiftPatternsStore, values } = this.props;

                const availableStore =
                    shiftPatternsStore && shiftPatternsStore.filteredShifts.length > 0;

                return (
                    <>
                        <>
                            {editOrCreateMode && (
                                <div className="weeks-table__row-header-actions">
                                    <ButtonGroup>
                                        {week !== 1 && (
                                            <Button
                                                onClick={this._handleMoveUpClick}
                                                disabled={week === 1}
                                                icon="arrow-left"
                                            >
                                                Swap with Week {week - 1}
                                            </Button>
                                        )}
                                        {week <=
                                            this.props.RootStore.shiftPatternsStore.newShiftPattern
                                                .weeks.length -
                                                1 && (
                                            <Button
                                                onClick={this._handleMoveDownClick}
                                                disabled={
                                                    this.props.RootStore.shiftPatternsStore
                                                        .newShiftPattern.weeks.length <= week
                                                }
                                                icon="arrow-right"
                                            >
                                                Swap with Week {week + 1}
                                            </Button>
                                        )}

                                        <Button onClick={this._addNewWeek} icon="plus">
                                            Add
                                        </Button>
                                        <Button
                                            onClick={
                                                this.props.RootStore.shiftPatternsStore
                                                    .newShiftPattern.weeks.length < 8
                                                    ? this._handleDuplicateClick
                                                    : null
                                            }
                                            icon="duplicate"
                                        >
                                            Duplicate
                                        </Button>
                                        <Button onClick={this._handleAlertClick} icon="trash">
                                            Delete
                                        </Button>
                                    </ButtonGroup>

                                    <Alert
                                        cancelButtonText="Cancel"
                                        confirmButtonText="Delete"
                                        className="delete-alert"
                                        onConfirm={() => this._handleDeleteClick(week)}
                                        onCancel={this._handleAlertClick}
                                        isOpen={this.state.showAlert}
                                        icon="trash"
                                        intent={'danger'}
                                        canEscapeKeyCancel={true}
                                    >
                                        <h4>Confirm deletion!</h4>
                                        <p>
                                            Are you sure you want to delete this week? You won't be
                                            able to recover it.
                                        </p>
                                    </Alert>
                                </div>
                            )}
                        </>
                        <div className="rota-builder__week-days">
                            {Object.entries(repeatsDays).map(([key, value]) => {
                                return (
                                    <div key={key}>
                                        <div key={key} className="rota-builder__day-card day-card">
                                            <div className={'day-card__header'}>
                                                <div className="day-card__header-content">
                                                    <h4 className="day-card__title">{value}</h4>
                                                </div>
                                            </div>
                                            <div className="shift-pattern-shifts rota-shifts">
                                                {shiftPatternsStore.clipboardActive && (
                                                    <div
                                                        className="weeks-table__col-content-item--paste"
                                                        onClick={() =>
                                                            this._handlePasteClick(week, key)
                                                        }
                                                    >
                                                        Paste
                                                    </div>
                                                )}
                                                {availableStore &&
                                                    shiftPatternsStore.filteredShifts
                                                        .slice()
                                                        .sort((d1, d2) => {
                                                            let t1 = new Date(d1.startTime).setDate(
                                                                1,
                                                            );
                                                            let t2 = new Date(d2.startTime).setDate(
                                                                1,
                                                            );
                                                            if (t1 === t2) {
                                                                t1 = new Date(d1.endTime).setDate(
                                                                    1,
                                                                );
                                                                t2 = new Date(d2.endTime).setDate(
                                                                    1,
                                                                );
                                                            }
                                                            return t1 - t2;
                                                        })
                                                        .map((shift, index) => {
                                                            return (
                                                                <ShiftPattern
                                                                    dayNum={key}
                                                                    weekNum={week}
                                                                    shift={shift}
                                                                    key={index}
                                                                    editOrCreateMode={
                                                                        editOrCreateMode
                                                                    }
                                                                    history={history}
                                                                    onClick={this._handleShiftClick}
                                                                />
                                                            );
                                                        })}
                                            </div>
                                        </div>
                                        <div className="rota-shift-add">
                                            <div
                                                className={`${
                                                    !values.departmentId || !editOrCreateMode
                                                        ? 'disabled'
                                                        : ''
                                                } btn btn--secondary`}
                                                onClick={() => this._handleAddShift(week, key)}
                                            >
                                                <IconAdd /> Add shift
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                );
            }
        },
    ),
);

export default ShiftPatternWeek;
