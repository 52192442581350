import React, { useState } from 'react';
import { Checkbox, Switch } from '@blueprintjs/core';
import moment from 'moment';
import _ from 'lodash';

const PersonChecklist = ({ checklist, person, peopleStore, personsDictionary, onUpdate }: any) => {
    const [personChecklist, setPersonChecklist] = useState(
        checklist.slice().sort((a: any, b: any) => a.orderNumber - b.orderNumber),
    );

    const onToggle = async (id: any, data: any, event?: any, field?: any) => {
        const dataModified = {
            ...data,
            [field]: event.target.checked,
        };

        setPersonChecklist((prevState: any) =>
            prevState.map((el: any) =>
                el.id === id ? { ...el, [field]: event.target.checked } : el,
            ),
        );
        onUpdate();
        await peopleStore.updatePersonInductionTask(id, dataModified);
    };

    return (
        <table>
            <thead>
                <tr className="person-checklist__row">
                    <th />
                    <th>Task</th>
                    <th>Date</th>
                    <th>Done by</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {personChecklist?.map((item: any) => {
                    const data = {
                        name: item.name,
                        personId: person.id,
                        inductionChecklistId: item.inductionChecklistId,
                        isApplicable: item.isApplicable,
                        isChecked: item.isChecked,
                        orderNumber: item.orderNumber,
                    };
                    return (
                        <tr
                            key={item.id}
                            className={`person-checklist__row ${
                                item.isApplicable ? '' : 'person-checklist__row--inactive'
                            }`}
                        >
                            <td>
                                <Checkbox
                                    disabled={!item.isApplicable}
                                    checked={item.isChecked}
                                    onChange={(e) => onToggle(item.id, data, e, 'isChecked')}
                                    onClick={(event) => event.stopPropagation()}
                                />
                            </td>
                            <td>{item.name}</td>
                            <td>
                                {!_.isEmpty(item.doneAt)
                                    ? moment(item.doneAt).format('DD/MM/YYYY HH:MM')
                                    : ''}
                            </td>
                            <td>{!!item.doneBy ? personsDictionary[item.doneBy] : ''}</td>
                            <td>
                                <Switch
                                    checked={item.isApplicable}
                                    label={item.isApplicable ? 'Applicable' : 'N/A'}
                                    onChange={(e) => onToggle(item.id, data, e, 'isApplicable')}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default PersonChecklist;
