import React, { Component } from 'react';
import { Button, FormGroup, InputGroup, NumericInput, Collapse } from '@blueprintjs/core';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import CreatableSelectAdvanced from '../../../common/CreatableSelectAdvanced';

import {
    formatPractices,
    momentFormatter,
    formatDate,
    dateWithoutTime,
} from '../../helpers/formatData';
import Error from '../../forms/Error';
import { inject, observer } from 'mobx-react';
import { DateInput3 } from '@blueprintjs/datetime2';
import { dateFormat } from '../../../../constants/patientsConst';
import ContractedHours from './ContractedHours';
import {
    CONTRACTOR_IDS,
    EMPLOYED_IDS,
    CONTRACT_TYPE_AGENCY,
    CONTRACT_TYPE_DIRECT_ENGAGEMENT,
    CONTRACT_TYPE_SELF_EMPLOYED,
    CONTRACT_TYPE_BANK,
} from '../../../../constants/hrConst';
import moment from 'moment';

const filterPractices = (inputValue: string, lovsStore: any) =>
    formatPractices(lovsStore.practice).filter((practice: { label: string }) =>
        practice.label.toLowerCase().includes(inputValue.toLowerCase()),
    );

type Option = {
    label: string;
    value: string;
};

export const typeOfRegistrationOptions = [
    { value: 'Sole Trader', label: 'Sole Trader' },
    { value: 'Limited Company', label: 'Limited Company' },
    { value: 'LLP', label: 'LLP' },
    { value: 'Limited Partnership', label: 'Limited Partnership' },
    { value: 'Other', label: 'Other' },
];

const EmployeeSummary = inject('RootStore')(
    observer(
        class EmployeeSummary extends Component<any> {
            state = {
                showConfirmFieldDialog: false,
                showPayRateUpdateAlert: false,
                showPayRateUpdate: false,
                isLoading: false,
                isLoadingDeps: false,
                itemTypeToDelete: null,
                itemToDelete: null,
                roleCollapse: true,
                servicesCollapse: true,
                contractDetailsCollapse: true,
                contractingCollapse: true,
                workingPatternCollapse: true,
                leaveAllowancesCollapse: true,
                otherFieldsCollapse: true,
                phoneNumbersOptions: [] as any,
                emailsOptions: [] as any,
                contactDetailsCollapse: true,
                countryOfRegistrationOptions: [],
                currentPayRateIndex: 0,
                futurePayRateIndex: -1,
                tupeOrganisationNameOptions: [] as any,
            };

            async componentDidMount() {
                this._loadCountryOptions();
                // Set personId field in case we are creating a record from the person directly
                const personId = this.props.history?.location?.state?.personId;
                if (personId) {
                    this._loadPhoneNumbersOptions(personId);
                    this._loadEmailOptions(personId);
                    this.props.setFieldValue('personId', personId);
                }

                // Condition when we come in created record
                const personIdCreated = this.props.employee?.personId;
                if (personIdCreated) {
                    this._loadPhoneNumbersOptions(personIdCreated);
                    this._loadEmailOptions(personIdCreated);
                }

                if (this.props.values.payRates?.length) {
                    const current = new Date();
                    this.setState({
                        currentPayRateIndex: this.props.values.payRates.findIndex(
                            (payRate: any) =>
                                new Date(payRate.startDate) < current &&
                                (payRate.endDate === null || new Date(payRate.endDate) > current),
                        ),
                        futurePayRateIndex: this.props.values.payRates.findIndex(
                            (payRate: any) => new Date(payRate.startDate) > current,
                        ),
                    });
                }

                this._loadTupeOrganisations('');
                this._loadDepartmentAssignments();
            }

            _loadDepartmentAssignments = async () => {
                await this.setState({
                    isLoadingDeps: true,
                });
                await this.props.RootStore.departmentAssignmentsStore.getDepartmentAssignments(
                    this.props.match.params.employeeId,
                );
                await this.setState({
                    isLoadingDeps: false,
                });
            };

            _loadOptions = (inputValue: string): Promise<Option[]> => {
                return new Promise((resolve) => {
                    if (inputValue && inputValue.length >= 3) {
                        resolve(filterPractices(inputValue, this.props.RootStore.lovsStore));
                    } else {
                        resolve([]);
                    }
                });
            };

            _loadPersonsOptions = async (inputValue: string) => {
                if (inputValue && inputValue.length >= 3) {
                    return await this.props.RootStore.staffStore.loadPersonsOptions(inputValue);
                } else {
                    return [];
                }
            };

            _loadPhoneNumbersOptions = async (id: string) => {
                const { getPersonPhoneNumbers } = this.props.RootStore.peopleStore;
                const phoneNumbers = await getPersonPhoneNumbers(id);
                const filteredPhoneNumbers = Array.from(new Set(phoneNumbers));
                let optionsArr = [] as any;
                filteredPhoneNumbers.forEach((item: any) => {
                    if (item !== '') {
                        optionsArr.push({ value: item, label: item });
                    }
                });
                this.setState({ phoneNumbersOptions: optionsArr });
            };

            _loadTupeOrganisations = async (search: string) => {
                const { getTupeOrganisations } = this.props.RootStore.staffStore;
                const orgs = await getTupeOrganisations(search);
                const options = orgs.map((r: any) => ({ label: r, value: r })) as any;
                this.setState({ tupeOrganisationNameOptions: options });
            };

            _loadEmailOptions = async (id: string) => {
                const { getPersonEmails } = this.props.RootStore.peopleStore;
                const emails = await getPersonEmails(id);
                const filteredEmails = Array.from(new Set(emails));
                let optionsArr = [] as any;
                filteredEmails.forEach((item: any) => {
                    if (item !== '') {
                        optionsArr.push({ value: item, label: item });
                    }
                });
                this.setState({ emailsOptions: optionsArr });
            };

            _loadCountryOptions = async () => {
                const { getCountries } = this.props.RootStore.staffStore;
                const countryList = await getCountries();
                const countryOfRegistrationOptions = countryList.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                this.setState({ countryOfRegistrationOptions });
            };

            render() {
                const {
                    RootStore: {
                        dictionaryStore: {
                            agencies,
                            roles,
                            groups,
                            specialities,
                            contractTypes,
                            notificationTypes,
                            specialitiesDictionary,
                            agenciesDictionary,
                            rolesDictionary,
                            groupsDictionary,
                            contractDictionary,
                            notificationDictionary,
                        },
                    },
                    setFieldValue,
                    touched,
                    errors,
                    values,
                    handleChange,
                    editOrCreateMode,
                    employee,
                    setFieldTouched,
                } = this.props;

                const {
                    phoneNumbersOptions,
                    emailsOptions,
                    roleCollapse,
                    contactDetailsCollapse,
                    contractingCollapse,
                    workingPatternCollapse,
                    leaveAllowancesCollapse,
                    otherFieldsCollapse,
                    countryOfRegistrationOptions,
                    tupeOrganisationNameOptions,
                } = this.state;

                const isEmployed = this.props.location.pathname.includes('employed');

                const countryOfRegistrationIndex = countryOfRegistrationOptions.findIndex(
                    (item: any) => item.value === employee?.countryOfRegistrationId,
                );

                let staffOptions = contractTypes;
                if (isEmployed && !this.props.history?.location?.state?.isShowAllContractTypes) {
                    staffOptions = contractTypes.filter((option: Option) =>
                        EMPLOYED_IDS.includes(option.value),
                    );
                }
                if (
                    this.props.location.pathname.includes('sessional') &&
                    !this.props.history?.location?.state?.isShowAllContractTypes
                ) {
                    staffOptions = contractTypes.filter((option: Option) =>
                        CONTRACTOR_IDS.includes(option.value),
                    );
                }

                const isAgencyContractType =
                    values.contractTypeId === CONTRACT_TYPE_AGENCY ||
                    values.contractTypeId === CONTRACT_TYPE_DIRECT_ENGAGEMENT;

                const hasCompanyRegistrationFields =
                    values.contractTypeId === CONTRACT_TYPE_DIRECT_ENGAGEMENT ||
                    values.contractTypeId === CONTRACT_TYPE_SELF_EMPLOYED;

                const isActiveOrSuspended =
                    values.statusId === 'f4717860-2a2e-40bc-b9db-71d67972fc6b' ||
                    values.statusId === '8613093d-ec25-4e0f-99c3-10e8405997d3';

                return (
                    <div>
                        {this.props.actionType === 'create' && (
                            <>
                                <dt className="person-fields__title">Staff member *</dt>
                                <dd className="person-fields__definition">
                                    <FormGroup labelFor="personId">
                                        <AsyncSelect
                                            loadOptions={this._loadPersonsOptions}
                                            noOptionsMessage={(val) =>
                                                val.inputValue && val.inputValue.length > 2
                                                    ? 'No results'
                                                    : 'Type at least three characters to see results'
                                            }
                                            defaultValue={{
                                                label: this.props.history?.location?.state?.label
                                                    ? this.props.history.location.state.label
                                                    : '',
                                                value: this.props.history?.location?.state?.personId
                                                    ? this.props.history.location.state.personId
                                                    : '',
                                            }}
                                            onChange={async (e: any) => {
                                                const id = e?.value;
                                                if (id) {
                                                    this._loadPhoneNumbersOptions(id);
                                                    this._loadEmailOptions(id);
                                                }
                                                setFieldValue('personId', e?.value ?? null);
                                            }}
                                            defaultOptions
                                            /* TODO work out what large should be now we are on version 5*/
                                            //large
                                            isClearable
                                            menuPlacement="bottom"
                                            isDisabled={
                                                !!this.props.history?.location?.state?.personId
                                            }
                                        />
                                        {errors.personId && <Error errors={[errors.personId]} />}
                                    </FormGroup>
                                </dd>
                            </>
                        )}

                        <Button
                            className={'person-fields_group-title__button'}
                            rightIcon={roleCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'}
                            onClick={() => this.setState({ roleCollapse: !roleCollapse })}
                        >
                            Role
                        </Button>
                        <Collapse isOpen={roleCollapse} className="person-fields__collapse">
                            <dl className="person-fields_group">
                                <dt className="person-fields__title">Role *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode && !isActiveOrSuspended ? (
                                        <FormGroup labelFor="roles">
                                            <Select
                                                options={roles}
                                                defaultValue={(employee.roles ?? []).map(
                                                    (roleId: string) =>
                                                        !!rolesDictionary[roleId]
                                                            ? {
                                                                  label: rolesDictionary[roleId],
                                                                  value: roleId,
                                                              }
                                                            : '',
                                                )}
                                                onChange={(e) => {
                                                    setFieldValue('roles', e && [e.value]);
                                                }}
                                                name="roles"
                                                id="roles"
                                            />
                                            {errors.roles && <Error errors={[errors.roles]} />}
                                        </FormGroup>
                                    ) : values.roles.length ? (
                                        values.roles.map((roleId: string, index: any) =>
                                            !!rolesDictionary[roleId] ? (
                                                <span key={index} className="info__definition-item">
                                                    {rolesDictionary[roleId]}
                                                </span>
                                            ) : (
                                                ''
                                            ),
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                                <dt className="person-fields__title">Specialities</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="specialities">
                                            <Select
                                                options={specialities}
                                                defaultValue={(values.specialities ?? []).map(
                                                    (specialityId: string) =>
                                                        !!specialitiesDictionary[specialityId]
                                                            ? {
                                                                  label:
                                                                      specialitiesDictionary[
                                                                          specialityId
                                                                      ],
                                                                  value: specialityId,
                                                              }
                                                            : '',
                                                )}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'specialities',
                                                        (e ?? []).map((x) => x.value),
                                                    )
                                                }
                                                isMulti
                                            />
                                        </FormGroup>
                                    ) : values.specialities.length ? (
                                        values.specialities.map((specialityId: string) =>
                                            !!specialitiesDictionary[specialityId] ? (
                                                <span className="info__definition-item">
                                                    {specialitiesDictionary[specialityId]}
                                                </span>
                                            ) : (
                                                ''
                                            ),
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                                <dt className="person-fields__title">Patient-facing? *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="patientFacing">
                                            <Select
                                                options={[
                                                    { label: 'Yes', value: true },
                                                    { label: 'No', value: false },
                                                ]}
                                                defaultValue={{
                                                    label: values.patientFacing ? 'Yes' : 'No',
                                                    value: values.patientFacing,
                                                }}
                                                onChange={(e) =>
                                                    setFieldValue('patientFacing', e?.value)
                                                }
                                            />
                                            {errors.patientFacing && (
                                                <Error errors={[errors.patientFacing]} />
                                            )}
                                        </FormGroup>
                                    ) : values.patientFacing ? (
                                        'Yes'
                                    ) : (
                                        'No'
                                    )}
                                </dd>
                                <dt className="person-fields__title">{`Start Date of Engagement${
                                    isEmployed ? ' *' : ''
                                }`}</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="startedWorkOn">
                                            <DateInput3
                                                showTimezoneSelect={false}
                                                {...momentFormatter(dateFormat)}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        'startedWorkOn',
                                                        dateWithoutTime(value),
                                                    )
                                                }
                                                dayPickerProps={{
                                                    weekStartsOn: 1,
                                                }}
                                                inputProps={{
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                }}
                                                minDate={moment().subtract(1, 'years').toDate()}
                                                maxDate={
                                                    values.endedWorkOn ||
                                                    moment().add(1, 'years').toDate()
                                                }
                                                value={values.startedWorkOn}
                                            />
                                            {errors.startedWorkOn && (
                                                <Error errors={[errors.startedWorkOn]} />
                                            )}
                                        </FormGroup>
                                    ) : employee.startedWorkOn ? (
                                        formatDate(employee.startedWorkOn)
                                    ) : (
                                        '-'
                                    )}
                                    {!this.state.isLoadingDeps && (
                                        <>
                                            <div
                                                className={
                                                    'person-fields__title info__title--secondary'
                                                }
                                            >
                                                {values.startedWorkOn &&
                                                    !values?.endedWorkOn &&
                                                    this.props.RootStore.departmentAssignmentsStore
                                                        .departmentAssignmentsActive?.length ===
                                                        0 &&
                                                    'There are no current department assignment records for the person in this role'}
                                            </div>
                                            <div
                                                className={
                                                    'person-fields__title info__title--secondary'
                                                }
                                            >
                                                {values?.startedWorkOn &&
                                                    this.props.RootStore.departmentAssignmentsStore
                                                        .departmentAssignmentEarliest &&
                                                    moment(values?.startedWorkOn).isAfter(
                                                        moment(
                                                            this.props.RootStore
                                                                .departmentAssignmentsStore
                                                                .departmentAssignmentEarliest
                                                                .startDateOfAssignment,
                                                        ),
                                                    ) &&
                                                    `The start date of engagement and earliest assignment to department do not align: ${formatDate(
                                                        values?.startedWorkOn,
                                                    )} vs assignment to ${
                                                        this.props.RootStore
                                                            .departmentAssignmentsStore
                                                            .departmentAssignmentEarliest
                                                            ?.department?.name
                                                    } starting ${formatDate(
                                                        this.props.RootStore
                                                            .departmentAssignmentsStore
                                                            .departmentAssignmentEarliest
                                                            .startDateOfAssignment,
                                                    )}`}
                                            </div>
                                        </>
                                    )}
                                </dd>
                                <dt className="person-fields__title">End Date of Engagement</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="endedWorkOn">
                                            <DateInput3
                                                showTimezoneSelect={false}
                                                {...momentFormatter(dateFormat)}
                                                onChange={(value) =>
                                                    setFieldValue(
                                                        'endedWorkOn',
                                                        dateWithoutTime(value),
                                                    )
                                                }
                                                dayPickerProps={{
                                                    weekStartsOn: 1,
                                                }}
                                                inputProps={{
                                                    onKeyDown: (e) => {
                                                        e.preventDefault();
                                                    },
                                                }}
                                                minDate={
                                                    values.startedWorkOn ||
                                                    moment().subtract(1, 'years').toDate()
                                                }
                                                maxDate={moment().add(1, 'years').toDate()}
                                                value={values.endedWorkOn}
                                            />
                                            {touched.endedWorkOn && (
                                                <Error errors={[errors.endedWorkOn]} />
                                            )}
                                        </FormGroup>
                                    ) : employee.endedWorkOn ? (
                                        formatDate(employee.endedWorkOn)
                                    ) : (
                                        '-'
                                    )}
                                    {!this.state.isLoadingDeps && (
                                        <>
                                            <div
                                                className={
                                                    'person-fields__title info__title--secondary'
                                                }
                                            >
                                                {values?.startedWorkOn &&
                                                    values?.endedWorkOn &&
                                                    this.props.RootStore.departmentAssignmentsStore
                                                        .departmentAssignmentLatest &&
                                                    moment(values?.endedWorkOn).isBefore(
                                                        moment(
                                                            this.props.RootStore
                                                                .departmentAssignmentsStore
                                                                .departmentAssignmentLatest
                                                                .endDateOfAssignment,
                                                        ),
                                                    ) &&
                                                    `The end date of engagement and latest assignment to department do not align: ${formatDate(
                                                        values?.endedWorkOn,
                                                    )} vs assignment to ${
                                                        this.props.RootStore
                                                            .departmentAssignmentsStore
                                                            .departmentAssignmentLatest?.department
                                                            ?.name
                                                    } ending ${formatDate(
                                                        this.props.RootStore
                                                            .departmentAssignmentsStore
                                                            .departmentAssignmentLatest
                                                            .endDateOfAssignment,
                                                    )}`}
                                            </div>
                                            <div
                                                className={
                                                    'person-fields__title info__title--secondary'
                                                }
                                            >
                                                {values?.startedWorkOn &&
                                                    values?.endedWorkOn &&
                                                    this.props.RootStore.departmentAssignmentsStore
                                                        .departmentAssignmentLatest &&
                                                    this.props.RootStore.departmentAssignmentsStore
                                                        .departmentAssignmentLatest
                                                        .endDateOfAssignment === null &&
                                                    `The end date of engagement and latest assignment to department do not align: ${formatDate(
                                                        values?.endedWorkOn,
                                                    )} vs assignment to ${
                                                        this.props.RootStore
                                                            .departmentAssignmentsStore
                                                            .departmentAssignmentLatest?.department
                                                            ?.name
                                                    } ending in future`}
                                            </div>
                                        </>
                                    )}
                                </dd>
                                <dt className="person-fields__title">Is trainer? *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="isSupervisor">
                                            <Select
                                                options={[
                                                    { label: 'Yes', value: true },
                                                    { label: 'No', value: false },
                                                ]}
                                                defaultValue={{
                                                    label: values.isSupervisor ? 'Yes' : 'No',
                                                    value: values.isSupervisor,
                                                }}
                                                onChange={(e) =>
                                                    setFieldValue('isSupervisor', e?.value)
                                                }
                                            />
                                            {errors.isSupervisor && (
                                                <Error errors={[errors.isSupervisor]} />
                                            )}
                                        </FormGroup>
                                    ) : values.isSupervisor ? (
                                        'Yes'
                                    ) : (
                                        'No'
                                    )}
                                </dd>
                            </dl>
                        </Collapse>

                        <Button
                            className={'person-fields_group-title__button'}
                            rightIcon={
                                contactDetailsCollapse
                                    ? 'symbol-triangle-up'
                                    : 'symbol-triangle-down'
                            }
                            onClick={() =>
                                this.setState({
                                    contactDetailsCollapse: !contactDetailsCollapse,
                                })
                            }
                        >
                            Contact Details
                        </Button>
                        <Collapse
                            isOpen={contactDetailsCollapse}
                            className="person-fields__collapse"
                        >
                            <dl className="person-fields_group">
                                <dt className="person-fields__title">Work Phone Number *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="contactNumber">
                                            <CreatableSelectAdvanced
                                                name="contactNumber"
                                                options={phoneNumbersOptions}
                                                onChange={(val: any) => {
                                                    setFieldValue('contactNumber', val.value);
                                                }}
                                                defaultValue={employee.contactNumber}
                                                placeholder={''}
                                            />
                                            {errors.contactNumber && (
                                                <Error errors={[errors.contactNumber]} />
                                            )}
                                        </FormGroup>
                                    ) : employee.contactNumber ? (
                                        employee.contactNumber
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                                <dt className="person-fields__title">Work Email Address *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="email">
                                            <CreatableSelectAdvanced
                                                name="email"
                                                options={emailsOptions}
                                                onChange={(val: any) => {
                                                    setFieldValue('email', val.value);
                                                }}
                                                defaultValue={employee.email}
                                                placeholder={''}
                                            />
                                            {errors.email && <Error errors={[errors.email]} />}
                                        </FormGroup>
                                    ) : employee.email ? (
                                        employee.email
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                                <dt className="person-fields__title">Notification Preference *</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="notificationPreference">
                                            <Select
                                                options={notificationTypes}
                                                defaultValue={{
                                                    label:
                                                        notificationDictionary[
                                                            values.notificationPreference
                                                        ],
                                                    value: values.notificationPreference,
                                                }}
                                                onChange={(e) =>
                                                    setFieldValue(
                                                        'notificationPreference',
                                                        e!.value,
                                                    )
                                                }
                                            />
                                            {errors.notificationPreference && (
                                                <Error errors={[errors.notificationPreference]} />
                                            )}
                                        </FormGroup>
                                    ) : values.notificationPreference ? (
                                        notificationDictionary[values.notificationPreference]
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                            </dl>
                        </Collapse>

                        <Button
                            className={'person-fields_group-title__button'}
                            rightIcon={
                                contractingCollapse ? 'symbol-triangle-up' : 'symbol-triangle-down'
                            }
                            onClick={() =>
                                this.setState({
                                    contractingCollapse: !contractingCollapse,
                                })
                            }
                        >
                            {isEmployed ? 'Contracting' : 'Engagement'}
                        </Button>
                        <Collapse isOpen={contractingCollapse} className="person-fields__collapse">
                            <dl className="person-fields_group">
                                <dt className="person-fields__title">
                                    {isEmployed ? 'Contract Type *' : 'Engagement Type'}
                                </dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode && !isActiveOrSuspended ? (
                                        <FormGroup labelFor="contractTypeId">
                                            <Select
                                                options={staffOptions}
                                                defaultValue={{
                                                    label:
                                                        contractDictionary[values.contractTypeId],
                                                    value: values.contractTypeId,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('contractTypeId', e!.value);
                                                    !isAgencyContractType &&
                                                        setFieldValue('agencyId', '');
                                                }}
                                            />
                                            {errors.contractTypeId && (
                                                <Error errors={[errors.contractTypeId]} />
                                            )}
                                        </FormGroup>
                                    ) : values.contractTypeId ? (
                                        contractDictionary[values.contractTypeId]
                                    ) : (
                                        '-'
                                    )}
                                </dd>
                                <dt className="person-fields__title">TUPE Staff</dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="isTupe">
                                            <Select
                                                options={[
                                                    { label: 'Yes', value: true },
                                                    { label: 'No', value: false },
                                                ]}
                                                defaultValue={{
                                                    label: values.isTupe ? 'Yes' : 'No',
                                                    value: values.isTupe,
                                                }}
                                                onChange={(e) => {
                                                    setFieldValue('isTupe', e?.value);
                                                    if (!e?.value) {
                                                        setFieldValue('tupeOrganisationName', '');
                                                    }
                                                }}
                                            />
                                            {errors.isSupervisor && (
                                                <Error errors={[errors.isTupe]} />
                                            )}
                                        </FormGroup>
                                    ) : values.isTupe ? (
                                        'Yes'
                                    ) : (
                                        'No'
                                    )}
                                </dd>

                                <dt className="person-fields__title">
                                    TUPE Organisation Transferred From
                                </dt>
                                <dd className="person-fields__definition">
                                    {editOrCreateMode ? (
                                        <FormGroup labelFor="tupeOrganisationName">
                                            <CreatableSelectAdvanced
                                                isDisabled={!values.isTupe}
                                                name="tupeOrganisationName"
                                                options={tupeOrganisationNameOptions}
                                                onChange={(val: any) => {
                                                    setFieldValue(
                                                        'tupeOrganisationName',
                                                        val.value,
                                                    );
                                                }}
                                                defaultValue={employee.tupeOrganisationName}
                                                placeholder={''}
                                            />
                                            {errors.email && (
                                                <Error errors={[errors.tupeOrganisationName]} />
                                            )}
                                        </FormGroup>
                                    ) : employee.tupeOrganisationName ? (
                                        employee.tupeOrganisationName
                                    ) : (
                                        '-'
                                    )}
                                </dd>

                                {!isEmployed && isAgencyContractType && (
                                    <>
                                        <dt className="person-fields__title">
                                            Agency name{' '}
                                            {values.contractTypeId ===
                                                'cf689985-66cd-4649-b376-28da3c69db1f' ||
                                            values.contractTypeId ===
                                                '51577de2-5488-4e93-a68a-705194e875fa'
                                                ? '*'
                                                : ''}
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode && !isActiveOrSuspended ? (
                                                <FormGroup labelFor="agencyId">
                                                    <Select
                                                        options={agencies}
                                                        defaultValue={{
                                                            label:
                                                                agenciesDictionary[values.agencyId],
                                                            value: values.agencyId,
                                                        }}
                                                        onChange={(e) =>
                                                            setFieldValue('agencyId', e!.value)
                                                        }
                                                    />
                                                    {errors.agencyId && (
                                                        <Error errors={[errors.agencyId]} />
                                                    )}
                                                </FormGroup>
                                            ) : values.agencyId ? (
                                                agenciesDictionary[values.agencyId]
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                    </>
                                )}

                                {!isEmployed && hasCompanyRegistrationFields && (
                                    <>
                                        <dt className="person-fields__title">
                                            Type of Registration
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="typeOfRegistration">
                                                    <Select
                                                        options={typeOfRegistrationOptions}
                                                        defaultValue={{
                                                            label: employee.typeOfRegistration,
                                                            value: employee.typeOfRegistration,
                                                        }}
                                                        onChange={(e) => {
                                                            setFieldValue(
                                                                'typeOfRegistration',
                                                                e!.value,
                                                            );
                                                        }}
                                                    />
                                                    {touched.typeOfRegistrationOptions && (
                                                        <Error
                                                            errors={[
                                                                errors.typeOfRegistrationOptions,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.typeOfRegistration ? (
                                                values.typeOfRegistration
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">
                                            Country of Registration
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="countryOfRegistrationId">
                                                    <Select
                                                        options={countryOfRegistrationOptions}
                                                        defaultValue={
                                                            countryOfRegistrationOptions[
                                                                countryOfRegistrationIndex
                                                            ]
                                                        }
                                                        onChange={(e: any) => {
                                                            setFieldValue(
                                                                'countryOfRegistrationId',
                                                                e.value,
                                                            );
                                                            setFieldValue('countryOfRegistration', {
                                                                name: e.label,
                                                            });
                                                        }}
                                                    />
                                                    {errors.countryOfRegistration && (
                                                        <Error
                                                            errors={[errors.countryOfRegistration]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values?.countryOfRegistration?.name ? (
                                                values.countryOfRegistration.name
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">
                                            Company Registration Number
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="companyRegistrationNumber">
                                                    <InputGroup
                                                        id="companyRegistrationNumber"
                                                        name="companyRegistrationNumber"
                                                        onChange={handleChange}
                                                        value={values.companyRegistrationNumber}
                                                        large
                                                    />
                                                    {errors.companyRegistrationNumber && (
                                                        <Error
                                                            errors={[
                                                                errors.companyRegistrationNumber,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.companyRegistrationNumber ? (
                                                values.companyRegistrationNumber
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">
                                            Registered Company Name
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyName">
                                                    <InputGroup
                                                        id="registeredCompanyName"
                                                        name="registeredCompanyName"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyName}
                                                        large
                                                    />
                                                    {touched.contactNumber && (
                                                        <Error
                                                            errors={[errors.registeredCompanyName]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyName ? (
                                                values.registeredCompanyName
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">Address line 1</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressLine1">
                                                    <InputGroup
                                                        id="registeredCompanyAddressLine1"
                                                        name="registeredCompanyAddressLine1"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyAddressLine1}
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressLine1 && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressLine1,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressLine1 ? (
                                                values.registeredCompanyAddressLine1
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">Address line 2</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressLine2">
                                                    <InputGroup
                                                        id="registeredCompanyAddressLine2"
                                                        name="registeredCompanyAddressLine2"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyAddressLine2}
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressLine2 && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressLine2,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressLine2 ? (
                                                values.registeredCompanyAddressLine2
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">Address line 3</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressLine3">
                                                    <InputGroup
                                                        id="registeredCompanyAddressLine3"
                                                        name="registeredCompanyAddressLine3"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyAddressLine3}
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressLine3 && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressLine3,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressLine3 ? (
                                                values.registeredCompanyAddressLine3
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">Address line 4</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressLine4">
                                                    <InputGroup
                                                        id="registeredCompanyAddressLine4"
                                                        name="registeredCompanyAddressLine4"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyAddressLine4}
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressLine4 && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressLine4,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressLine4 ? (
                                                values.registeredCompanyAddressLine4
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">City/Town</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressCity">
                                                    <InputGroup
                                                        id="registeredCompanyAddressCity"
                                                        name="registeredCompanyAddressCity"
                                                        onChange={handleChange}
                                                        value={values.registeredCompanyAddressCity}
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressCity && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressCity,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressCity ? (
                                                values.registeredCompanyAddressCity
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">Postcode</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressPostcode">
                                                    <InputGroup
                                                        id="registeredCompanyAddressPostcode"
                                                        name="registeredCompanyAddressPostcode"
                                                        onChange={handleChange}
                                                        value={
                                                            values.registeredCompanyAddressPostcode
                                                        }
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressPostcode && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressPostcode,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressPostcode ? (
                                                values.registeredCompanyAddressPostcode
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                        <dt className="person-fields__title">Country</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="registeredCompanyAddressCountry">
                                                    <InputGroup
                                                        id="registeredCompanyAddressCountry"
                                                        name="registeredCompanyAddressCountry"
                                                        onChange={handleChange}
                                                        value={
                                                            values.registeredCompanyAddressCountry
                                                        }
                                                        large
                                                    />
                                                    {touched.registeredCompanyAddressCountry && (
                                                        <Error
                                                            errors={[
                                                                errors.registeredCompanyAddressCountry,
                                                            ]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.registeredCompanyAddressCountry ? (
                                                values.registeredCompanyAddressCountry
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">
                                            VAT Registration Number
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="vatRegistrationNumber">
                                                    <InputGroup
                                                        id="vatRegistrationNumber"
                                                        name="vatRegistrationNumber"
                                                        onChange={handleChange}
                                                        value={values.vatRegistrationNumber}
                                                        large
                                                    />
                                                    {errors.vatRegistrationNumber && (
                                                        <Error
                                                            errors={[errors.vatRegistrationNumber]}
                                                        />
                                                    )}
                                                </FormGroup>
                                            ) : values.vatRegistrationNumber ? (
                                                values.vatRegistrationNumber
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                    </>
                                )}
                            </dl>
                        </Collapse>

                        {isEmployed && (
                            <>
                                {values.contractTypeId !== CONTRACT_TYPE_BANK && (
                                    <>
                                        <Button
                                            className={'person-fields_group-title__button'}
                                            rightIcon={
                                                workingPatternCollapse
                                                    ? 'symbol-triangle-up'
                                                    : 'symbol-triangle-down'
                                            }
                                            onClick={() =>
                                                this.setState({
                                                    workingPatternCollapse: !workingPatternCollapse,
                                                })
                                            }
                                        >
                                            Working Pattern
                                        </Button>
                                        <Collapse
                                            isOpen={workingPatternCollapse}
                                            className="person-fields__collapse"
                                        >
                                            <dl className="person-fields_group">
                                                {!isAgencyContractType && (
                                                    <>
                                                        <dt className="person-fields__title">
                                                            Contracted Hours per Week *
                                                        </dt>
                                                        <dd className="person-fields__definition">
                                                            {editOrCreateMode ? (
                                                                <FormGroup
                                                                    labelFor="contractedWorkingHours"
                                                                    inline
                                                                >
                                                                    <InputGroup
                                                                        id="contractedWorkingHours"
                                                                        name="contractedWorkingHours"
                                                                        onChange={handleChange}
                                                                        value={
                                                                            values.contractedWorkingHours
                                                                        }
                                                                        large
                                                                        type="number"
                                                                        placeholder="0"
                                                                        min={0}
                                                                        max={168}
                                                                        className="info__weekDay"
                                                                    />
                                                                    {errors.contractedWorkingHours && (
                                                                        <Error
                                                                            errors={[
                                                                                errors.contractedWorkingHours,
                                                                            ]}
                                                                        />
                                                                    )}
                                                                </FormGroup>
                                                            ) : values.contractedWorkingHours ? (
                                                                values.contractedWorkingHours
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </dd>
                                                        <dt className="person-fields__title">
                                                            Hours Split across Days
                                                        </dt>
                                                        <dd className="person-fields__definition info__definition--inline info__definition--week">
                                                            <ContractedHours
                                                                values={values}
                                                                errors={errors}
                                                                touched={touched}
                                                                setFieldValue={setFieldValue}
                                                                editOrCreateMode={editOrCreateMode}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                        </dd>
                                                    </>
                                                )}
                                            </dl>
                                        </Collapse>
                                    </>
                                )}

                                <Button
                                    className={'person-fields_group-title__button'}
                                    rightIcon={
                                        leaveAllowancesCollapse
                                            ? 'symbol-triangle-up'
                                            : 'symbol-triangle-down'
                                    }
                                    onClick={() =>
                                        this.setState({
                                            leaveAllowancesCollapse: !leaveAllowancesCollapse,
                                        })
                                    }
                                >
                                    Leave Allowances
                                </Button>
                                <Collapse
                                    isOpen={leaveAllowancesCollapse}
                                    className="person-fields__collapse"
                                >
                                    <dl className="person-fields_group">
                                        <>
                                            <dt className="person-fields__title">{`Holiday Allowance${
                                                isEmployed ? ' *' : ''
                                            }`}</dt>
                                            <dd className="person-fields__definition">
                                                {editOrCreateMode ? (
                                                    <FormGroup labelFor="holidayAllowance">
                                                        <NumericInput
                                                            id="holidayAllowance"
                                                            name="holidayAllowance"
                                                            allowNumericCharactersOnly={false}
                                                            onValueChange={(_, value) => {
                                                                setFieldValue(
                                                                    'holidayAllowance',
                                                                    value ? value : '0',
                                                                );
                                                            }}
                                                            value={values.holidayAllowance}
                                                            large
                                                            fill
                                                            min={0}
                                                            max={100}
                                                            minorStepSize={0.01}
                                                            selectAllOnFocus={true}
                                                            buttonPosition={'none'}
                                                        />
                                                        {errors.holidayAllowance && (
                                                            <Error
                                                                errors={[errors.holidayAllowance]}
                                                            />
                                                        )}
                                                    </FormGroup>
                                                ) : values.holidayAllowance ? (
                                                    values.holidayAllowance
                                                ) : (
                                                    '-'
                                                )}
                                            </dd>
                                        </>
                                    </dl>
                                </Collapse>

                                <Button
                                    className={'person-fields_group-title__button'}
                                    rightIcon={
                                        otherFieldsCollapse
                                            ? 'symbol-triangle-up'
                                            : 'symbol-triangle-down'
                                    }
                                    onClick={() =>
                                        this.setState({
                                            otherFieldsCollapse: !otherFieldsCollapse,
                                        })
                                    }
                                >
                                    Other
                                </Button>
                                <Collapse
                                    isOpen={otherFieldsCollapse}
                                    className="person-fields__collapse"
                                >
                                    <dl className="person-fields_group">
                                        <dt className="person-fields__title">Group(s)</dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="groups">
                                                    <Select
                                                        options={groups}
                                                        defaultValue={(values.groups ?? []).map(
                                                            (groupId: string) =>
                                                                !!groupsDictionary[groupId]
                                                                    ? {
                                                                          label:
                                                                              groupsDictionary[
                                                                                  groupId
                                                                              ],
                                                                          value: groupId,
                                                                      }
                                                                    : '',
                                                        )}
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                'groups',
                                                                (e ?? []).map((x) => x.value),
                                                            )
                                                        }
                                                        isMulti
                                                    />
                                                </FormGroup>
                                            ) : values.groups.length ? (
                                                values.groups.map((groupId: string, index: any) =>
                                                    !!groupsDictionary[groupId] ? (
                                                        <span
                                                            key={index}
                                                            className="info__definition-item"
                                                        >
                                                            {groupsDictionary[groupId]}
                                                        </span>
                                                    ) : (
                                                        '-'
                                                    ),
                                                )
                                            ) : (
                                                '-'
                                            )}
                                        </dd>

                                        <dt className="person-fields__title">
                                            Affiliated GP Practice
                                        </dt>
                                        <dd className="person-fields__definition">
                                            {editOrCreateMode ? (
                                                <FormGroup labelFor="gpPractice">
                                                    <AsyncSelect
                                                        loadOptions={this._loadOptions}
                                                        noOptionsMessage={(val) =>
                                                            val.inputValue &&
                                                            val.inputValue.length > 2
                                                                ? 'No results'
                                                                : 'Type at least three characters to see results'
                                                        }
                                                        defaultValue={{
                                                            label: values.gpPractice,
                                                            value: values.gpPractice,
                                                        }}
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                'gpPractice',
                                                                e?.value ?? null,
                                                            )
                                                        }
                                                        defaultOptions
                                                        /* TODO work out what large should be now we are on version 5*/
                                                        //large
                                                        isClearable
                                                        menuPlacement="top"
                                                    />
                                                </FormGroup>
                                            ) : values.gpPractice ? (
                                                values.gpPractice
                                            ) : (
                                                '-'
                                            )}
                                        </dd>
                                    </dl>
                                </Collapse>
                            </>
                        )}
                    </div>
                );
            }
        },
    ),
);

export default EmployeeSummary;
