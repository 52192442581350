import { FC } from 'react';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { FormMode, FormSteps } from '../common';
import { PatientDetailsPds } from '../PatientDetails';
import { AccordionColors, AccordionTab } from '../../../../v2/components';
import useStores from '../../../../../hook/useStores';
import { SubDialogs } from '../../../../../stores/UCRStore';

interface IProps {
    stepsCompleted: string[];
    currentStep: FormSteps;
    formMode: FormMode;
    loading: boolean;
    patientDetailsRequired?: boolean;
    onClick: () => void;
    onNextStep: () => void;
    onSaveForm?: (step: FormSteps) => void;
    handleChangePatientRequired?: (isRequired: boolean) => void;
}

export const PatientTab: FC<IProps> = ({
    stepsCompleted,
    currentStep,
    formMode,
    loading,
    patientDetailsRequired = true,
    onClick,
    onNextStep,
    onSaveForm,
    handleChangePatientRequired,
}) => {
    const {
        RootStore: {
            lovsStore: { languagesSpoken, gender, genderStaffPreferred },
            ucrStore: { setOpenedSubDialog },
        },
    } = useStores() as { RootStore: any };

    const { values }: FormikContextType<FormikValues> = useFormikContext();

    const patientStepColor =
        currentStep === FormSteps.PATIENT ? AccordionColors.PINK : AccordionColors.GREY;

    return (
        <AccordionTab
            name="patients"
            title="Patient details"
            color={
                stepsCompleted.includes(FormSteps.PATIENT) && currentStep !== FormSteps.PATIENT
                    ? AccordionColors.BLUE
                    : patientStepColor
            }
            open={currentStep === FormSteps.PATIENT}
            onClick={onClick}
        >
            <PatientDetailsPds
                values={values}
                loading={loading}
                formMode={formMode}
                languagesSpoken={languagesSpoken}
                gender={gender}
                genderStaffPreferred={genderStaffPreferred}
                onNextStep={onNextStep}
                onSaveForm={onSaveForm}
                showPullFromReferral={() => setOpenedSubDialog(SubDialogs.PULL_FROM_REFERRAL)}
                required={patientDetailsRequired}
                handleChangePatientRequired={handleChangePatientRequired}
            />
        </AccordionTab>
    );
};
