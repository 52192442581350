import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import { CREATE_ROTA, GET_SHIFT_PATTERNS } from '../graphql/queries/rotaBuilder';
import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore, SortInfo } from './PagedStore';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import type { GetRotas, GetRotas_getRotas_edges_node } from '../__generated__/GetRotas';
import {
    DELETE_ROTA,
    GET_ROTA_BY_ID,
    GET_ROTAS,
    CREATE_ROTA_SHIFT_COMMENT,
    DELETE_ROTA_SHIFT_COMMENT,
    UPDATE_ROTA_SHIFT_COMMENT,
    GET_ROTA_SHIFT_COST,
    GET_ROTA_BY_ID_EXTENDED,
    GET_PAYMENT_STATUSES,
    //GET_ROTA_SHIFT_CONFLICTS,
} from '../graphql/queries/rotas';
import { GET_SHIFT_PATIENT_FACING_WARNINGS } from '../graphql/queries/shiftPatientFacing';
import {
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    PAYMENT_STATUS_PLANED,
} from '../constants/rotaConst';
import moment from 'moment';
import { compareByLabels } from '../components/modules/helpers/sortFunctions';

export type ShiftInput = {
    id?: string;
    breakDurationMinutes: number;
    breaksPaid: boolean;
    dayOfWeek: number;
    employeeId: string;
    endTime: string;
    locationId: string;
    overrideValueInPence: number;
    roleId: string;
    rotaShiftFunctions: string[];
    patientFacing: boolean;
    fundingPoolId: string;
    payRateId: string;
    startTime: string;
    weekNumber: number;
    thirdPartyPaid: boolean;
    trainingShift: boolean;
    trainees: any;
    comments: any;
    paymentStatusId: string;
};

export type RotaInput = {
    id?: string;
    name: string;
    startDate: Date;
    endDate: Date;
    shiftPatternId?: string;
    shiftPatternAppliedAt?: Date;
    shiftPatternStartWeek?: Number;
    shifts: [ShiftInput];
};

export interface Rota extends GetRotas_getRotas_edges_node {
    selected?: boolean;
}

export interface NormalizedRota extends Rota {
    department: any;
}

class RotaBuilderStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            rotas: observable,
            rotasByPayRate: observable,
            rotasByFunction: observable,
            rotasByLocation: observable,
            allRotas: observable,
            rotasByDepartment: observable,
            newRotaCostCentreId: observable,
            newRotaDepartmentId: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            changesNotSaved: observable,
            allSelected: computed,
            gridRotas: computed,
            getEarliestRota: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getRotas: action,
            init: action,
            updateChangesState: action,
            updateNewRotaCostCentre: action,
            updateNewRotaDepartment: action,
            createRotaShiftComment: action,
            updateRotaShiftComment: action,
            deleteRotaShiftComment: action,
            getPaymentStatuses: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any = null;
    shiftPatterns = [];
    selected: string[] = [];
    newRotaCostCentreId: any;
    newRotaCostCentreName: any;
    newRotaDepartmentId: any;
    newRotaDepartmentName: any;
    rotas: Rota[] = [];
    rotasByPayRate: any | Rota[] = [];
    rotasByFunction: any | Rota[] = [];
    rotasByRole: any | Rota[] = [];
    rotasByDepartment: any | Rota[] = [];
    rotasByLocation: { name: any }[] | undefined = [];
    allRotas: Rota[] = [];
    sortFn: (a: any, b: any) => number = () => 0;
    fetchInterval: NodeJS.Timeout | null = null;
    changesNotSaved = false;

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    updateNewRotaCostCentre(value: any) {
        this.newRotaCostCentreId = value.value;
        this.newRotaCostCentreName = value.label;
    }

    updateNewRotaDepartment(value: any) {
        this.newRotaDepartmentId = value.value;
        this.newRotaDepartmentName = value.label;
    }

    normalizeDepartment = (item: any) => {
        return {
            id: item.id,
            name: item.name,
            costCode: item.costCode,
            roles: item.roles
                ? item.roles
                      .map((r: any) => ({
                          value: r.employeeRole.id,
                          label: r.employeeRole.name,
                          isFixed: r.isInUse,
                      }))
                      .sort(compareByLabels)
                : [],
            employees: item.employees
                .map((emp: any) => ({
                    value: emp.employeeRecord.id,
                    label: emp.employeeRecord.person
                        ? `${emp.employeeRecord.person.firstName} ${emp.employeeRecord.person.lastName}`
                        : '',
                }))
                .sort(compareByLabels),
            shiftPatterns: item.shiftPatterns
                ? item.shiftPatterns.map((sp: any) => ({
                      value: sp.id,
                      label: sp.name,
                      updatedAt: sp.updatedAt,
                      shiftPatternEntries: sp.shiftPatternEntries.map((item: any) => {
                          return {
                              id: item.id,
                              shiftPatternId: item.shiftPatternId ?? '',
                              weekNumber: item.weekNumber,
                              dayOfWeek: item.dayOfWeek,
                              startTime: new Date(item.startTime),
                              endTime: new Date(item.endTime),
                              breakDurationMinutes: item.breakDurationMinutes,
                              breakIsPaid: item.breakIsPaid,
                              overrideValueInPence: item.overrideValueInPence,
                              roleId: item.shiftEmployeeRoleTypeId,
                              shiftFunctions: item.shiftFunctions.map(
                                  (shiftFunction: any) => shiftFunction.functionId,
                              ),
                              fundingPoolId: item.fundingPoolId,
                              locationId: item.locationId,
                              defaultEmployeeId: item.defaultEmployeeId,
                              payRateId: item.payRateId,
                              typeId: item.typeId,
                              statusId: SHIFT_STATUS_STANDARD_ID,
                              thirdPartyPaid: item.thirdPartyPaid,
                              trainingShift: item.trainingShift,
                              trainees: item.trainees?.length
                                  ? item.trainees.map((trainee: any) => {
                                        return {
                                            traineeId: trainee.traineeId,
                                            roleId: trainee.roleId,
                                            overrideValue: trainee.overrideValue,
                                            payRateId: trainee.payRateId,
                                        };
                                    })
                                  : [
                                        {
                                            traineeId: '',
                                            roleId: '',
                                            overrideValue: null,
                                            payRateId: '',
                                        },
                                    ],
                          };
                      }),
                      shifts: sp.shiftPatternEntries.length,
                      weeks: [
                          ...Array.from(
                              new Set(sp.shiftPatternEntries.map((item: any) => item.weekNumber)),
                          ),
                      ] ?? [1],
                  }))
                : [],
        };
    };

    normalizeShift = (item: any): any => ({
        ...item,
        rotaShiftFunctions:
            item.rotaShiftFunctions &&
            item.rotaShiftFunctions.map((shiftFunction: any) => shiftFunction.functionId),
    });

    normalizeRota = (item: Rota): NormalizedRota => {
        return {
            ...item,
            selected: this.selected.includes(item.id),
            rotaShifts: item.rotaShifts.map((r) => this.normalizeShift(r)),
        };
    };

    normalizeRotaExtended = (item: Rota): NormalizedRota => {
        return {
            ...item,
            selected: this.selected.includes(item.id),
            department: this.normalizeDepartment(item.department as any),
            rotaShifts: item.rotaShifts.map((r) => this.normalizeShift(r)),
        };
    };

    get allSelected() {
        return this.gridRotas.every((r) => this.selected.includes(r.id));
    }

    get gridRotas() {
        const result = this.rotas.map(this.normalizeRota);
        if (this.sortFn) {
            result.sort(this.sortFn);
        }
        return result;
    }

    get getEarliestRota() {
        const result = this.allRotas.map((d) => moment(d.startDate));
        return moment.min(result);
    }

    selectAll = () => {
        runInAction(() => {
            this.gridRotas.forEach((item) => {
                this.select(item.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getRotas();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getRotas();
    };

    select = (id: string) => {
        if (!this.selected.includes(id)) {
            this.selected.push(id);
        }
    };

    deselect = (id: string) => {
        const index = this.selected.indexOf(id);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    onSort = (sortInfo: SortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getRotas();
    };

    createRotaNew = async (inData: RotaInput) => {
        const newShifts = inData.shifts.map(
            ({ defaultEmployee, confirmedEmployee, ...keepAttrs }: any) => keepAttrs,
        );
        const data = {
            ...inData,
            shifts: newShifts.map((r: any) => {
                const shift = {
                    ...r,
                    trainees: _.values(r.trainees[0]).every(_.isEmpty)
                        ? null
                        : r.trainees?.map((trainee: any) => ({
                              traineeId: trainee.traineeId,
                              roleId: trainee.roleId,
                              overrideValue: trainee.overrideValue,
                              payRateId: trainee.payRateId,
                          })),
                    comments: !r.comments
                        ? []
                        : r.comments?.map((comment: any) => ({
                              comment: comment.comment,
                              createdBy: comment.createdBy,
                              isEdited: comment.isEdited,
                              createdAt: comment.createdAt,
                              updatedAt: comment.updatedAt,
                              isImportant: comment.isImportant,
                          })),
                    conflicts: r.conflicts ? r.conflicts.map((r: any) => r.rotaShiftId) : [],
                    conflicts0: undefined,
                    conflicts1: undefined,
                };
                delete shift.cost;
                return shift;
            }),
        };
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_ROTA,
                variables: {
                    data,
                    utcOffset: moment().utcOffset(),
                },
            });
            AppToaster.show({
                message: inData.id
                    ? 'The changes have been saved successfully'
                    : 'Rota has been created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating rota. Please try again.',
                intent: 'danger',
            });
        }
    };

    getRotas = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.rotas =
                data.edges?.map((x) => ({
                    ...x.node,
                    createdBy: x.node.createPerson
                        ? `${(x.node.createPerson as any).firstName} ${
                              (x.node.createPerson as any).lastName
                          }`
                        : '',
                })) || [];
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getRotasByPayRate = async (payRateId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: { payRateId },
            },
        });

        runInAction(() => {
            this.rotasByPayRate = data.edges
                ?.filter((r: any) => moment(r.node.startDate).isAfter(moment().toDate()))
                ?.map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        startDate: moment(x.node.startDate).format('DD MMM YY'),
                        endDate: moment(x.node.endDate).format('DD MMM YY'),
                        department: x.node.department,
                        costCentre: x.node.costCentre,
                        isPublished: x.node.isPublished,
                    };
                });
            this.rotasByPayRate = _.orderBy(this.rotasByPayRate, ['name'], ['asc']);
        });
    };

    getRotasByFunction = async (functionId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: { functionId },
            },
        });

        runInAction(() => {
            this.rotasByFunction = data.edges
                ?.filter((r: any) => moment(r.node.startDate).isAfter(moment().toDate()))
                ?.map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        startDate: moment(x.node.startDate).format('DD MMM YY'),
                        endDate: moment(x.node.endDate).format('DD MMM YY'),
                        department: x.node.department,
                        costCentre: x.node.costCentre,
                        isPublished: x.node.isPublished,
                    };
                });
            this.rotasByFunction = _.orderBy(this.rotasByFunction, ['name'], ['asc']);
        });
    };

    getRotasByLocation = async (locationId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: { locationId: [locationId] },
            },
        });

        runInAction(() => {
            this.rotasByLocation = data.edges
                ?.filter((r: any) => moment(r.node.startDate).isAfter(moment().toDate()))
                ?.map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        startDate: moment(x.node.startDate).format('DD MMM YY'),
                        endDate: moment(x.node.endDate).format('DD MMM YY'),
                        department: x.node.department,
                        costCentre: x.node.costCentre,
                        isPublished: x.node.isPublished,
                    };
                });
            this.rotasByLocation = _.orderBy(this.rotasByLocation, ['name'], ['asc']);
        });
    };

    getRotasByRole = async (roleId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roleId },
            },
        });

        runInAction(() => {
            this.rotasByRole = data.edges
                ?.filter((r: any) => moment(r.node.startDate).isAfter(moment().toDate()))
                ?.map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        startDate: moment(x.node.startDate).format('DD MMM YY'),
                        endDate: moment(x.node.endDate).format('DD MMM YY'),
                        department: x.node.department,
                        costCentre: x.node.costCentre,
                        isPublished: x.node.isPublished,
                    };
                });
            this.rotasByRole = _.orderBy(this.rotasByRole, ['name'], ['asc']);
        });
    };

    getAllRotas = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                filter: {},
            },
        });

        runInAction(() => {
            this.allRotas =
                data.edges?.map((x) => ({
                    ...x.node,
                    createdBy: x.node.createPerson
                        ? `${(x.node.createPerson as any).firstName} ${
                              (x.node.createPerson as any).lastName
                          }`
                        : '',
                })) || [];
        });
    };

    getRotasByDepartment = async (departmentId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getRotas: data },
        }: { data: GetRotas } = await this.apolloClient.query({
            query: GET_ROTAS,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'createdAt',
                query: this.pageInfo.searchText,
                filter: { departmentId },
            },
        });

        runInAction(() => {
            this.rotasByDepartment = data.edges
                ?.filter((r: any) => moment(r.node.startDate).isAfter(moment().toDate()))
                .map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        startDate: moment(x.node.startDate).format('DD MMM YY'),
                        endDate: moment(x.node.endDate).format('DD MMM YY'),
                        department: {
                            name: x.node.department?.name ? x.node.department?.name : '',
                        },
                        costCentre: {
                            name: x.node.costCentre?.name ? x.node.costCentre?.name : '',
                        },
                        isPublished: x.node.isPublished,
                    };
                });
            this.rotasByDepartment = _.orderBy(this.rotasByDepartment, ['name'], ['asc']);
        });
    };

    async createRota(inData: RotaInput) {
        const newShifts = inData.shifts.map(
            ({ defaultEmployee, confirmedEmployee, ...keepAttrs }: any) => keepAttrs,
        );

        const data = {
            ...inData,
            shifts: newShifts.map((r: any) => ({
                ...r,
                defaultEmployeeId: _.isEmpty(r.defaultEmployeeId) ? null : r.defaultEmployeeId,
                overrideValueInPence: r.overrideValueInPence || 0,
                trainees: _.values(r.trainees[0]).every(_.isEmpty)
                    ? null
                    : r.trainees?.map((trainee: any) => ({
                          traineeId: trainee.traineeId,
                          roleId: trainee.roleId,
                          overrideValue: trainee.overrideValue,
                          payRateId: trainee.payRateId,
                      })),
                comments: [],
            })),
        };
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_ROTA,
                variables: {
                    data,
                    utcOffset: moment().utcOffset(),
                },
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating rota. Please try again.',
                intent: 'danger',
            });
        }
        // await this.refresh();
    }

    getShiftPatterns = async () => {
        const {
            data: { getShiftPatterns: data },
        } = await this.apolloClient.query({
            query: GET_SHIFT_PATTERNS,
        });

        runInAction(() => {
            this.shiftPatterns = data.edges.map((x: any) => x.node);
        });
    };

    getRotaById = async (id: string) => {
        const {
            data: { getRota: data },
        } = await this.apolloClient.query({
            query: GET_ROTA_BY_ID,
            variables: {
                id,
            },
        });

        return this.normalizeRota(data);
    };

    getRotaByIdExtended = async (id: string) => {
        const {
            data: { getRota: data },
        } = await this.apolloClient.query({
            query: GET_ROTA_BY_ID_EXTENDED,
            variables: {
                id,
            },
        });

        return this.normalizeRotaExtended(data);
    };

    normalizeRole = (item: any) => {
        return {
            id: item.id,
            name: item.name,
        };
    };

    normalizeShiftPatternEntries = (items: [any]) => {
        let result = [];

        for (const spe of items) {
            const start = new Date(spe.startTime);
            const end = new Date(spe.endTime);

            result.push({
                weekNumber: spe.weekNumber,
                dayOfWeek: spe.dayOfWeek,
                startTime: start,
                endTime: end,
                breakDurationMinutes: spe.breakDurationMinutes,
                breaksPaid: spe.breaksPaid,
                overrideValueInPence: spe.overrideValueInPence,
                shiftPatternId: spe.shiftPatternId,
                defaultEmployeeId: spe.defaultEmployeeId,
                confirmedEmployeeId: spe.confirmedEmployeeId,
                statusId: spe.statusId ? spe.statusId : SHIFT_STATUS_STANDARD_ID,
                typeId: spe.typeId ? spe.typeId : SHIFT_TYPE_CONTRACTED_ID,
                isAdditional: spe.isAdditional,
                locationId: spe.locationId,
                location: this.normalizeLocation(spe.location),
                defaultEmployee: spe.defaultEmployee
                    ? this.normaliseEmployeee(spe.defaultEmployee)
                    : null,
                confirmedEmployee: spe.confirmedEmployee
                    ? this.normaliseEmployeee(spe.confirmedEmployee)
                    : null,
                id: spe.id,
                roleId: spe.shiftEmployeeRoleType.id,
                rotaShiftFunctions: spe.rotaShiftFunctions.map(
                    (shiftFunction: any) => shiftFunction.functionId,
                ),
                fundingPoolId: spe.fundingPoolId,
                payRateId: spe.shiftEmployeeRoleType.payRateId,
                paymentStatusId: spe.paymentStatusId ? spe.paymentStatusId : PAYMENT_STATUS_PLANED,
                thirdPartyPaid: spe.thirdPartyPaid,
                trainingShift: spe.trainingShift,
                trainees: spe.trainees?.length
                    ? spe.trainees.map((trainee: any) => {
                          return {
                              traineeId: trainee.traineeId,
                              roleId: trainee.roleId,
                              overrideValue: trainee.overrideValue,
                              payRateId: trainee.payRateId,
                          };
                      })
                    : [
                          {
                              traineeId: '',
                              roleId: '',
                              overrideValue: null,
                              payRateId: '',
                          },
                      ],
                comments: spe.comments?.length
                    ? spe.comments.map((comment: any) => {
                          return {
                              comment: comment.comment,
                              isImportant: comment.isImportant,
                          };
                      })
                    : [
                          {
                              comment: '',
                          },
                      ],
            });
        }

        return result;
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
    };

    normaliseEmployeee = (employee: any) =>
        employee
            ? {
                  id: employee.id,
                  agencyId: employee.agencyId,
                  contractTypeId: employee.contractTypeId,
                  person: {
                      firstName: employee.person.firstName,
                      lastName: employee.person.lastName,
                      id: employee.person.id,
                  },
              }
            : null;

    normalizeLocation = (location: any) => ({
        id: location.id,
        name: location.name,
        address1: location.address1,
        address2: location.address2,
        address3: location.address3,
        townCity: location.townCity,
        postcode: location.postcode,
    });

    normalize = (item: any) => ({
        value: item.id,
        label: item.name,
        costCentreId: item.costCentreId,
        departmentId: item.departmentId,
        shiftPatternId: item.shiftPatternId,
        shiftPatternAppliedAt: item.shiftPatternAppliedAt,
        shiftPatternStartWeek: item.shiftPatternStartWeek,
        selected: this.selected.includes(item.id),
        shiftPatternEntries: this.normalizeShiftPatternEntries(item.shiftPatternEntries),
    });

    get shiftPatternEntities() {
        return this.shiftPatterns.map(this.normalize);
    }

    makeDictionary(items: any) {
        return items.reduce((acc: any, item: any) => {
            acc[item.value] = item.label;
            return acc;
        }, {});
    }

    onFilter = (filter: any) => {
        this.pageInfo.filter = {
            status: filter.status,
            startDate: filter.dateRange && filter.dateRange[0],
            endDate: filter.dateRange && filter.dateRange[1],
            published: filter.published,
            departmentId: filter.department,
            createdBy: filter.createdBy,
            costCentreCode: filter.costCentreCode,
            costCentreId: filter.costCentre,
            departmentCostCode: filter.departmentCostCode,
            defaultStaffMembers: filter.defaultStaffMember,
            confirmedStaffMembers: filter.confirmedStaffMember,
            trainees: filter.traineeStaffMember,
            shiftIssue: filter.shiftIssue,
        };
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    deleteRota = async (rota: any) => {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_ROTA,
                variables: {
                    id: rota.id,
                },
            });
            AppToaster.show({
                message: 'Rota deleted successfully!',
                intent: 'success',
            });
        } catch (err) {}

        await this.refresh();
    };

    createRotaShiftComment = async (comment: string, rotaShiftId: string, isImportant: boolean) => {
        try {
            const newComment = await this.apolloClient.mutate({
                mutation: CREATE_ROTA_SHIFT_COMMENT,
                variables: {
                    rotaShiftId: rotaShiftId,
                    comment: comment,
                    isImportant: isImportant,
                },
            });

            AppToaster.show({
                message: 'New comment added!',
                intent: 'success',
            });

            return newComment?.data?.createRotaShiftComment;
        } catch (err) {}
    };

    updateRotaShiftComment = async (comment: string, commentId: string, isImportant: boolean) => {
        try {
            const updatedComment = await this.apolloClient.mutate({
                mutation: UPDATE_ROTA_SHIFT_COMMENT,
                variables: {
                    id: commentId,
                    comment: comment,
                    isImportant: isImportant,
                },
            });
            AppToaster.show({
                message: 'Comment updated',
                intent: 'success',
            });

            return updatedComment?.data?.updateRotaShiftComment;
        } catch (err) {}
    };

    deleteRotaShiftComment = async (commentId: string) => {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_ROTA_SHIFT_COMMENT,
                variables: {
                    id: commentId,
                },
            });
            AppToaster.show({
                message: 'Comment deleted',
                intent: 'success',
            });
        } catch (err) {}
    };

    getRotaShiftCost = async (data: any) => {
        try {
            const {
                data: { getRotaShiftCost },
            } = await this.apolloClient.query({
                query: GET_ROTA_SHIFT_COST,
                variables: {
                    data,
                    utcOffset: moment().utcOffset(),
                },
            });
            return getRotaShiftCost;
        } catch (err) {}
    };

    getShiftConflicts = async (
        employeeId: string,
        startDate: Date,
        endDate: Date,
        shiftId?: string,
    ) => {
        // const {
        //     data: { getRotaShiftConflicts },
        // } = await this.apolloClient.query({
        //     query: GET_ROTA_SHIFT_CONFLICTS,
        //     variables: {
        //         employeeId,
        //         startDate,
        //         endDate,
        //         shiftId,
        //     },
        // });

        return [];
    };

    getPaymentStatuses = async () => {
        try {
            const {
                data: { getPaymentStatuses },
            } = await this.apolloClient.query({
                query: GET_PAYMENT_STATUSES,
            });

            return getPaymentStatuses.edges?.map((x: any) => ({
                label: x.node.name,
                value: x.node.id,
            }));
        } catch (err) {}
    };

    updateChangesState(value: boolean) {
        this.changesNotSaved = value;
    }

    getShiftPatientFacingWarnings = async (data: any) => {
        try {
            const {
                data: { getShiftPatientFacingWarnings },
            } = await this.apolloClient.query({
                query: GET_SHIFT_PATIENT_FACING_WARNINGS,
                variables: {
                    data,
                },
            });
            return getShiftPatientFacingWarnings;
        } catch (err) {}
    };
}

export default RotaBuilderStore;
