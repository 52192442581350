import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { Link } from 'react-router-dom';

interface LocationRelatedDialogProps {
    isOpen: boolean;
    onClose: () => void;
    notAchievableLocations: any[];
    achievableLocations: any[];
    onArchiveNotRelated: () => void;
}

const LocationsRelatedDialog = ({
    isOpen,
    onClose,
    notAchievableLocations,
    achievableLocations,
    onArchiveNotRelated,
}: LocationRelatedDialogProps) => {
    return (
        <Dialog isOpen={isOpen} onClose={onClose} canEscapeKeyClose={true}>
            <div className={Classes.DIALOG_BODY}>
                <h4>Confirm archiving!</h4>
                <p>
                    The following location(s) cannot be archived due to having related future rota
                    shifts and/or shift pattern shifts, and/or departments:
                </p>

                <ul>
                    {notAchievableLocations.map((item: any) => (
                        <li key={item.id}>
                            <Link to={`/hr/locations/${item.id}`} onClick={onClose}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>

                <p>Do you want to continue with archiving the following location(s)?</p>

                <ul>
                    {achievableLocations.map((item: any) => (
                        <li key={item.id}>
                            <Link to={`/hr/locations/${item.id}`} onClick={onClose}>
                                {item.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button intent={Intent.WARNING} onClick={onArchiveNotRelated}>
                        Archive
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default LocationsRelatedDialog;
