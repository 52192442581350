import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';

import {
    CREATE_SPECIALITY,
    DELETE_SPECIALITY,
    UPDATE_SPECIALITY,
    GET_SPECIALITY,
    GET_SPECIALITIES_LIST,
    BULK_DELETE_SPECIALITIES,
} from '../graphql/queries/specialities';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';

class SpecialitiesStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            selected: observable,
            specialities: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridSpecialities: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getSpecialities: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    specialities = [];
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeSpecialities = (speciality) => ({
        id: speciality.id,
        selected: this.selected.includes(speciality.id),
        name: speciality.name,
        createdAt: speciality.createdAt,
        updatedAt: speciality.updatedAt,
    });

    get gridSpecialities() {
        const specialities = this.specialities.map(this.normalizeSpecialities);
        if (this.sortFn) {
            specialities.sort(this.sortFn);
        }
        return specialities;
    }

    get allSelected() {
        return this.gridSpecialities.every((r) => this.selected.includes(r.id));
    }

    async createSpeciality(speciality) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_SPECIALITY,
                variables: {
                    data: {
                        name: speciality.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Speciality created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating speciality record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteSpeciality(speciality) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_SPECIALITY,
                variables: {
                    id: speciality.id,
                },
            });
            AppToaster.show({
                message: 'Speciality deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting speciality record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateSpeciality(speciality) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_SPECIALITY,
                variables: {
                    id: speciality.id,
                    data: {
                        name: speciality.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Speciality updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating speciality record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (specialityId) => {
        if (!this.selected.includes(specialityId)) {
            this.selected.push(specialityId);
        }
    };

    deselect = (specialityId) => {
        const index = this.selected.indexOf(specialityId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getSpecialities();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getSpecialities();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getSpecialities();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({
                groups: null,
                roles: null,
            });
        });
        await this.getSpecialities();
    };

    getSpecialities = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getSpecialities: data },
        } = await this.apolloClient.query({
            query: GET_SPECIALITIES_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            this.specialities = data.edges.map((x) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getSpecialityById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getSpecialityById: data },
        } = await this.apolloClient.query({
            query: GET_SPECIALITY,
            variables: {
                id,
            },
        });
        return this.normalizeSpecialities(data);
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getSpecialities();
            this.fetchInterval = setInterval(() => this.getSpecialities(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridSpecialities.forEach((speciality) => {
                this.select(speciality.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async bulkDeleteSpecialities(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_SPECIALITIES,
                variables: {
                    id: ids,
                },
            });
            AppToaster.show({
                message: 'All selected specialities deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected specialities. Please try again
                        or contact <a href="mailto:support@docabode.com">support@docabode.com</a>{' '}
                        for help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default SpecialitiesStore;
