import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import JobForm from './JobForm';

const AddJob = inject('RootStore')(
    observer(
        class AddJob extends Component {
            render() {
                return <JobForm title="Add a job" actionType="create" {...this.props} />;
            }
        },
    ),
);

export default AddJob;
