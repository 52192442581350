import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import ShiftsTableRow from './ShiftsTableRow';
import ShiftsTableHead from './ShiftsTableHead';
import ShiftPatternsNavigation from './ShiftPatternsNavigation';
import Loader from '../../../../modules/helpers/Loader';
import ShiftPatternsDetails from '../../../../modules/shiftPatterns/detail/ShiftPatternsDetails';
import ShiftPatternEntryDetails from '../../../../modules/shiftPatternEntry/detail/ShiftPatternEntryDetails';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import SelectDialog from '../../../../common/SelectDialog';

const ShiftPatterns = inject('RootStore')(
    observer(
        class ShiftPatterns extends Component {
            state = {
                showAlert: false,
                showDepartmentDialog: false,
            };

            componentDidMount() {
                this.props.RootStore.departmentsStore.getAllDepartments();
                this.props.RootStore.functionsStore.getAllFunctions();
                this.props.RootStore.fundingPoolStore.getAllFundingPools();
            }

            componentWillUnmount() {
                this.props.RootStore.shiftPatternsStore.clear();
                this.props.RootStore.shiftPatternsStore.showShiftEntryModal = false;
            }

            _handleShiftPatternClick = (id, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/rota/shift-patterns/${id}`);
                    this.props.RootStore.shiftPatternsStore.isCreatePath = false;
                }
            };

            _handleAddNew = () => {
                this.props.RootStore.shiftPatternsStore.initializeShiftPattern();
                this.props.RootStore.shiftPatternsStore.isCreatePath = true;
                this.setState((prevState) => {
                    return { showDepartmentDialog: !prevState.showDepartmentDialog };
                });
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.shiftPatternsStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.shiftPatternsStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };
            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.shiftPatternsStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _updateNewShiftPatternCostCentre = (value) => {
                this.props.RootStore.shiftPatternsStore.updateNewShiftPatternCostCentre(value);
            };

            _updateNewShiftPatternDepartment = (value) => {
                this.props.RootStore.shiftPatternsStore.updateNewShiftPatternDepartment(value);
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.shiftPatternsStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });

                let shiftPatterns = [];
                selected.forEach((selectedItem) => {
                    const selectedShiftPattern = this.props.RootStore.shiftPatternsStore.gridShiftPatterns.find(
                        (shiftPattern) => shiftPattern.id === selectedItem,
                    );
                    shiftPatterns.push(selectedShiftPattern);
                });

                shiftPatterns.map(
                    (item) =>
                        item && this.props.RootStore.shiftPatternsStore.deleteShiftPattern(item),
                );
            };

            _handleEditItem = () => {
                let { selected } = this.props.RootStore.shiftPatternsStore;
                this.props?.history?.push(`/rota/shift-patterns/${selected[0]}`, {
                    actionType: 'edit',
                    editModeOpenedFromViewList: true,
                });
            };
            _handleCreateRota = async () => {
                let { selected } = this.props.RootStore.shiftPatternsStore;
                await this.props.RootStore.shiftPatternsStore.createRotaByShiftPatternId(
                    selected[0],
                );
                this.props?.history?.push(`/rota/builder`, {
                    actionType: 'edit',
                    editModeOpenedFromSP: true,
                });
            };

            render() {
                const {
                    RootStore: {
                        shiftPatternsStore: {
                            gridShiftPatterns,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            onSearch,
                            loaded,
                            showShiftEntryModal,
                            selected,
                        },
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};

                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';
                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <ShiftPatternsNavigation
                                onFilter={onFilter}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                deleteItem={this._handleToggleAlert}
                                editItem={this._handleEditItem}
                                createRota={this._handleCreateRota}
                                itemsSelected={selected}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <ShiftsTableHead
                                    data={gridShiftPatterns}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridShiftPatterns.map((shiftPattern) => {
                                        return (
                                            <ShiftsTableRow
                                                {...shiftPattern}
                                                onClick={this._handleShiftPatternClick}
                                                key={shiftPattern.id}
                                                onToggle={this._handleToggleSelected}
                                            />
                                        );
                                    })}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <ShiftPatternsDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        {showShiftEntryModal && (
                            <div className="common-layout__modal">
                                <ShiftPatternEntryDetails id={id} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Delete"
                            onConfirm={this._handleDeleteItem}
                            onCancel={this._handleToggleAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm deletion!"
                            description="Are you sure you want to delete these shift patterns? You won't be
                                        able to recover them."
                        />
                        <SelectDialog
                            title={'Select department to create shift pattern'}
                            onCancel={() =>
                                this.setState((prevState) => {
                                    return {
                                        showDepartmentDialog: !prevState.showDepartmentDialog,
                                    };
                                })
                            }
                            onSubmit={(costCentre, department) => {
                                this._updateNewShiftPatternCostCentre(costCentre);
                                this._updateNewShiftPatternDepartment(department);
                                this.setState((prevState) => {
                                    return {
                                        showDepartmentDialog: !prevState.showDepartmentDialog,
                                    };
                                });
                                this.props.history.replace(`/rota/shift-patterns/new`);
                            }}
                            isOpen={this.state.showDepartmentDialog}
                        />
                    </>
                );
            }
        },
    ),
);

export default ShiftPatterns;
