import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tabs, Tab, Alert } from '@blueprintjs/core';
import Modal from '../../../../modules/modal/Modal';
import LocationSummary from './LocationSummary';

import { Formik, Form } from 'formik';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import CommonModalFooter from '../../../../common/CommonModalFooter';
import RelatedEntities from '../../../../common/RelatedEntities';
import _ from 'lodash';
import { RouterPrompt } from '../../../../common/RouterPrompt';
import AppToaster from '../../../../modules/helpers/Toaster';

const LocationDetails = inject('RootStore')(
    observer(
        class LocationDetails extends Component {
            state = {
                isEdit: false,
                activeTab: 1,
                initialData: {
                    name: '',
                    address1: '',
                    address2: '',
                    address3: '',
                    townCity: '',
                    postcode: '',
                    isArchived: false,
                },
                changesNotSaved: false,
                shiftPatternsByLocation: [],
                rotasByLocation: [],
                departmentsByLocation: [],
                relatedEntitiesConnected: false,
                loadingRelatedEntries: false,
                locationNames: [],
                isLoaded: false,
                showAlert: false,
                showRelatedAlert: false,
                showArchiveRelatedAlert: false,
                showCancelAlert: false,
            };

            async componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    const editModeOpenedFromViewList = this.props.location.state
                        ?.editModeOpenedFromViewList;
                    const { id } = this.props.match.params;

                    const location = await this.props.RootStore.locationsStore.getItemById(id);
                    if (location) {
                        this.setState(
                            {
                                initialData: { ...location },
                                isLoaded: true,
                                isEdit: editModeOpenedFromViewList
                                    ? editModeOpenedFromViewList
                                    : false,
                            },
                            () => {
                                this._queryRelatedEntries();
                            },
                        );
                    }
                }

                const locationNames = [...this.props.RootStore.locationsStore.allLocations]
                    .filter((location) => !location.isArchived)
                    .map((item) => ({ name: item.name }));

                this.setState({ locationNames });
            }

            componentWillUnmount() {
                this.props.RootStore.shiftPatternsStore.shiftPatternsByLocation = [];
                this.props.RootStore.rotaBuilderStore.rotasByLocation = [];
                this.props.RootStore.departmentsStore.departmentsByLocation = [];
            }

            _queryRelatedEntries = async () => {
                const {
                    departmentsStore,
                    rotaBuilderStore,
                    shiftPatternsStore,
                } = this.props.RootStore;
                Promise.all([
                    departmentsStore.getDepartmentsByLocation(this.state.initialData.id),
                    rotaBuilderStore.getRotasByLocation(this.state.initialData.id),
                    shiftPatternsStore.getShiftPatternsByLocation(this.state.initialData.id),
                ]).then(() => {
                    const relatedEntitiesConnected =
                        shiftPatternsStore.shiftPatternsByLocation.length ||
                        rotaBuilderStore.rotasByLocation.length ||
                        departmentsStore.departmentsByLocation.length;
                    this.setState({
                        shiftPatternsByLocation: shiftPatternsStore.shiftPatternsByLocation,
                        rotasByLocation: rotaBuilderStore.rotasByLocation,
                        departmentsByLocation: departmentsStore.departmentsByLocation,
                        relatedEntitiesConnected: relatedEntitiesConnected,
                    });
                });
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/rota/locations');
                }
                return this.props.history.goBack();
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                id && (await this.props.RootStore.locationsStore.deleteLocation(id));
                this.props.history.replace('/rota/locations');
            };

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _handleCloseAlert = () => {
                this.setState({
                    showAlert: false,
                    showRelatedAlert: false,
                });
            };

            _handleOnArchive = async () => {
                if (this.state.relatedEntitiesConnected) {
                    return this.setState({ showArchiveRelatedAlert: true });
                }
                this.setState({ showAlert: true });
            };

            _archiveLocation = async () => {
                const { id } = this.state.initialData;
                this.props.RootStore.locationsStore.archiveLocation(id);
                this.props.history.replace('/rota/locations');
            };

            _handleSubmit = async (values, { setSubmitting }) => {
                const normalizedValues = validationSchema.cast(values);
                const { locationsStore } = this.props.RootStore;

                if (this.props.actionType === 'create') {
                    await locationsStore.createItem(normalizedValues);
                }

                if (this.props.actionType === 'edit') {
                    if (this.state.relatedEntitiesConnected && this.state.changesNotSaved) {
                        return this.setState({ showRelatedAlert: true });
                    }

                    await locationsStore.updateItem(normalizedValues);
                }

                this.setState({ changesNotSaved: false });

                setSubmitting(false);
                this._finishSubmitProcess(setSubmitting);
            };

            _finishSubmitProcess = async () => {
                if (this.props.actionType === 'create') {
                    this.props.history.replace('/rota/locations');
                }
                if (this.props.actionType === 'edit') {
                    const { id } = this.props.match.params;
                    const location = await this.props.RootStore.locationsStore.getItemById(id);
                    if (location) {
                        this.setState({
                            initialData: { ...location },
                            isEdit: false,
                        });
                    }
                }
            };

            _submitUpdate = async (values) => {
                const { locationsStore } = this.props.RootStore;
                await locationsStore.updateItem(values);
                this._finishSubmitProcess();
                this._handleCloseAlert();
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _handleOnCancel = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/rota/locations');
                }
                if (this.props.actionType === 'edit' && !this.state.changesNotSaved) {
                    return this._toggleIsEdit();
                }
                const path = this.props.history.location.pathname;
                this.props.history.replace(path);
            };

            _handleOnCancelConfirm = async (replacePath) => {
                await this.setState({ changesNotSaved: false });
                if (this.props.actionType === 'edit') {
                    this.formik.resetForm({ values: this.state.initialData });
                    this._toggleIsEdit();
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                this.props.history.replace(replacePath ? replacePath : '/rota/locations');
            };

            render() {
                const {
                    RootStore: { locationsStore },
                } = this.props;

                const {
                    locationNames,
                    shiftPatternsByLocation,
                    rotasByLocation,
                    departmentsByLocation,
                } = this.state;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Add location record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        innerRef={(f) => (this.formik = f)}
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        validate={async (values) => {
                            const normalizedValues = validationSchema.cast(values);
                            const { name } = normalizedValues;
                            const errors = {};
                            if (name !== this.state.initialData.name) {
                                const nameIsUnique = await locationsStore.nameIsUnique(name);
                                if (nameIsUnique.length) {
                                    errors.notUniqueId = nameIsUnique[0].id;
                                    errors.notUniqueName = nameIsUnique[0].name;
                                }
                            }
                            // update changesNotSaved flag to show popUp if closing form without saving
                            if (_.isEqual(this.state.initialData, normalizedValues)) {
                                this.setState({ changesNotSaved: false });
                            } else {
                                this.setState({ changesNotSaved: true });
                            }
                            return errors;
                        }}
                        onSubmit={this._handleSubmit}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        <CommonModalFooter
                                            isSubmitting={isSubmitting}
                                            toggleIsEdit={this._toggleIsEdit}
                                            hasEditOption={
                                                this.state.initialData.isArchived ? false : true
                                            }
                                            isEditing={this.state.isEdit}
                                            onArchive={this._handleOnArchive}
                                            onCancel={this._handleOnCancel}
                                            hasArchiveOption={
                                                this.state.initialData.isArchived ? false : true
                                            }
                                        />
                                    }
                                    shadow
                                >
                                    <Tabs
                                        id="locationTabs"
                                        defaultSelectedTabId={this.state.activeTab}
                                        onChange={async (newTabId) => {
                                            this.setState({
                                                activeTab: newTabId,
                                            });
                                            this.setState({
                                                loadingRelatedEntries: false,
                                            });
                                        }}
                                        large
                                    >
                                        <Tab
                                            id={1}
                                            title="Summary"
                                            panel={
                                                <LocationSummary
                                                    location={this.state.initialData}
                                                    locationNames={locationNames}
                                                    editOrCreateMode={this.state.isEdit}
                                                    values={values}
                                                    handleChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    actionType={this.props.actionType}
                                                    errors={errors}
                                                    touched={touched}
                                                />
                                            }
                                        />
                                        <Tab
                                            id={2}
                                            title={
                                                this.props.actionType === 'edit'
                                                    ? `Related entities ${
                                                          this.state.loadingRelatedEntries
                                                              ? '...'
                                                              : ''
                                                      }`
                                                    : ''
                                            }
                                            panel={
                                                <div>
                                                    <RelatedEntities
                                                        hasRelatedShiftPatterns={true}
                                                        relatedShiftPatterns={
                                                            shiftPatternsByLocation
                                                        }
                                                        hasRelatedRotas={true}
                                                        relatedRotas={rotasByLocation}
                                                        hasRelatedDepartments={true}
                                                        relatedDepartments={departmentsByLocation}
                                                        description={
                                                            'This location is related to the following entities (archived entities are not displayed)'
                                                        }
                                                    />
                                                </div>
                                            }
                                        />
                                    </Tabs>
                                </Modal>
                                <Alert
                                    confirmButtonText="Ok"
                                    isOpen={this.state.showArchiveRelatedAlert}
                                    onClose={() =>
                                        this.setState({ showArchiveRelatedAlert: false })
                                    }
                                >
                                    <>
                                        <p>
                                            This location cannot be archived because it is selected
                                            in future rota shifts and/or shift pattern shifts,
                                            and/or departments. Remove location from the related
                                            entities to archive it
                                        </p>
                                    </>
                                </Alert>
                                <ConfirmationAlert
                                    confirmButtonText="Yes"
                                    onConfirm={() =>
                                        this._submitUpdate(validationSchema.cast(values))
                                    }
                                    onCancel={this._handleCloseAlert}
                                    isOpen={this.state.showRelatedAlert}
                                    title="Confirm update!"
                                    description="Are you sure you want to save the changes? The location will be updated for all related departments, shift pattern shifts, and past, current, and future rota shifts"
                                />
                                <ConfirmationAlert
                                    confirmButtonText="Archive"
                                    onConfirm={this._archiveLocation}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showAlert}
                                    title="Confirm archiving!"
                                    description="Are you sure you want to archive this location? This action is irreversible"
                                />
                                <RouterPrompt
                                    when={this.state.changesNotSaved && this.state.isEdit}
                                    onPromptConfirm={this._handleOnCancelConfirm}
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default LocationDetails;
