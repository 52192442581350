/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Select from 'react-select';

const PAGE_SIZE = 10;

const CustomAsyncSelect = ({ onLoadOptions, onChange, values }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [items, setItems] = React.useState([]);
    const [query, setQuery] = React.useState(null);
    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        setLoadMore(true);
    }, []);

    React.useEffect(async () => {
        if (loadMore) {
            setIsLoading(true);
            const lastItem = items.length === 0 ? null : items[items.length - 1].value;

            const { data } = await onLoadOptions(PAGE_SIZE, lastItem, query);
            const { edges } = data[Object.keys(data)[0]];

            const newItems = edges.map(({ node: r }) => {
                const role =
                    r.roles && r.roles.length > 0 ? ` (${r.roles[0].employeeRole.name})` : '';

                return {
                    value: r.id,
                    label: `${r.person ? r.person.firstName : r.firstName}
                        ${r.person ? r.person.lastName : r.lastName}${role}`,
                };
            });
            setItems([...items, ...newItems]);
            setIsLoading(false);
            setLoadMore(false);
        }
    }, [loadMore]);

    React.useEffect(() => {
        if (!query) return;
        setItems([]);
        setLoadMore(true);
    }, [query]);

    return (
        <div>
            <Select
                isMulti
                isLoading={isLoading}
                onMenuScrollToBottom={() => {
                    setLoadMore(true);
                }}
                onInputChange={(val) => {
                    setQuery(val);
                }}
                onChange={(values) => {
                    setQuery(null);
                    setItems([]);
                    setLoadMore(true);

                    if (onChange) onChange(values);
                }}
                onMenuClose={() => {
                    if (query && query.length > 0) {
                        setItems([]);
                        setQuery(null);
                        setLoadMore(true);
                    }
                }}
                options={items}
                value={values}
            />
        </div>
    );
};

export default CustomAsyncSelect;
