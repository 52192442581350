import React, { FC, useCallback, useState, useEffect } from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import cn from 'classnames';

import { IconThermometer } from '../../../../../helpers/ucr/icons';
import { ExpandMore } from '../../../../../helpers/ucr/icons';

interface IProps {
    isSick?: boolean;
    handleChange?: (val: boolean) => void;
}

enum SicknessItem {
    SICK = 'Sick',
    NOT_SICK = 'Not Sick',
}

const Sickness: FC<IProps> = ({ isSick = false, handleChange }) => {
    const SicknessSelect = Select.ofType<SicknessItem>();
    const [selected, setSelected] = useState<SicknessItem>(SicknessItem.NOT_SICK);

    useEffect(() => {
        setSelected(isSick ? SicknessItem.SICK : SicknessItem.NOT_SICK);
    }, [isSick]);

    const onSelect = useCallback(
        (val: SicknessItem) => {
            setSelected(val);
            handleChange && handleChange(val === SicknessItem.SICK);
        },
        [handleChange, setSelected],
    );
    const itemRenderer = useCallback<ItemRenderer<SicknessItem>>((isSick, props) => {
        if (!props.modifiers.matchesPredicate) {
            return null;
        }
        const key = Object.keys(SicknessItem)[Object.values(SicknessItem).indexOf(isSick)];
        return (
            <MenuItem
                active={props.modifiers.active}
                key={key}
                className={cn('ucr__calendar-hcp-sickness-option', {
                    'ucr__calendar-hcp-sickness-option--active': props.modifiers.active,
                })}
                onClick={props.handleClick}
                icon={props.modifiers.active ? 'tick' : null}
                text={isSick}
            />
        );
    }, []);

    return (
        <SicknessSelect
            className={cn('ucr__calendar-hcp-sickness', {
                'ucr__calendar-hcp-sickness--sick': selected === SicknessItem.SICK,
            })}
            filterable={false}
            activeItem={selected}
            popoverProps={{ minimal: true }}
            items={Object.values(SicknessItem)}
            noResults={<MenuItem disabled={true} text="No results." />}
            onItemSelect={onSelect}
            itemRenderer={itemRenderer}
        >
            {/* children become the popover target; render value here */}
            <Button
                className={cn('ucr__calendar-hcp-sickness-select')}
                icon={<IconThermometer className={cn('ucr__calendar-hcp-sickness-select-icon')} />}
                text={selected}
                rightIcon={<ExpandMore className="ucr__calendar-hcp-sickness-select-icon-arrow" />}
            />
        </SicknessSelect>
    );
};

export default Sickness;
