import React from 'react';

import { FundingPoolsSummaryPropTypes } from './FundingPoolsTypes';
import InputSuggest from '../../../common/inputSuggest/InputSuggest';

const FundingPoolsSummary = ({
    fundingPool,
    editOrCreateMode,
    values,
    handleChange,
    errors,
    touched,
    fundingPools,
    setFieldValue,
}: FundingPoolsSummaryPropTypes) => {
    return (
        <>
            <InputSuggest
                inputName={'name'}
                componentName={'funding-pools'}
                componentPath={'/hr/funding-pools/'}
                items={fundingPools}
                editOrCreateMode={editOrCreateMode}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
            />
        </>
    );
};

export default FundingPoolsSummary;
