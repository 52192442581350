import { Button, Icon } from '@blueprintjs/core';
import { Vaccination, JobStatus } from '@doc-abode/data-models';
import moment from 'moment';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { dateFormat } from '../../../../constants/patientsConst';
import { canRemoveVaccinationPatientFromVaccinationRoute } from '../../../../models/PatientRemoveFromRoute';
import { VaccinationRoute } from '../types';

interface IPatientDetailsFooter {
    setEditing: (editing: boolean) => void;
    setShowWithdrawPrompt: (showWithdrawPrompt: boolean) => void;
    setShowAbortPrompt: (showAbortPrompt: boolean) => void;
    setShowUnassignPrompt: (showUnassignPrompt: boolean) => void;
    onResubmit: () => void;
    onRevertingAbort: () => void;
    editing: boolean;
    jobStatus: string;
    isAssignedToRoute: boolean | undefined;
    route: VaccinationRoute;
    patient: Vaccination;
    resetForm: any;
    isSaving: boolean;
    isSuperuser: boolean;
    onShowCompletePrompt: () => void;
}

const PatientDetailsFooter = ({
    editing,
    setEditing,
    setShowWithdrawPrompt,
    setShowAbortPrompt,
    setShowUnassignPrompt,
    onShowCompletePrompt,
    onResubmit,
    onRevertingAbort,
    jobStatus,
    isAssignedToRoute,
    route,
    patient,
    resetForm,
    isSaving,
    isSuperuser,
}: IPatientDetailsFooter): JSX.Element => {
    useEffect(() => {
        const values: any = { ...Vaccination.INITIAL_VALUES, ...patient };

        if (Array.isArray(values.additionalContactNumbers)) {
            values.additionalContactNumbers = values.additionalContactNumbers.join(',');
        }

        if (values.dateOfDose1) {
            values.dateOfDose1 = moment(patient.dateOfDose1).format(dateFormat);
        }

        if (values.dateOfDose2) {
            values.dateOfDose2 = moment(patient.dateOfDose2).format(dateFormat);
        }

        if (values.dateOfDose3) {
            values.dateOfDose3 = moment(patient.dateOfDose3).format(dateFormat);
        }

        if (values.dateOfDose3Primary) {
            values.dateOfDose3Primary = moment(patient.dateOfDose3Primary).format(dateFormat);
        }

        if (values.dateOfDose4) {
            values.dateOfDose4 = moment(patient.dateOfDose4).format(dateFormat);
        }

        if (values.dateOfPreviousDose) {
            values.dateOfPreviousDose = moment(patient.dateOfPreviousDose).format(dateFormat);
        }

        if (values.dateOfBirth) {
            values.dateOfBirth = moment(patient.dateOfBirth).format(dateFormat);
        }

        if (values.doseNumber) {
            values.doseNumber = String(values.doseNumber);
        }

        resetForm({ values });
    }, [resetForm, patient]);

    if (editing) {
        return (
            <>
                <Button large onClick={() => setEditing(false)} icon="cross" outlined>
                    Cancel
                </Button>
                <Button
                    intent="success"
                    large
                    icon="tick"
                    // triggers the form action of the parent component which is a form, that's how the onSave is triggered.
                    // onSave is in the parent component, these components are tightly coupled.
                    type="submit"
                    loading={isSaving}
                    outlined
                >
                    Save
                </Button>
            </>
        );
    }

    const showRemoveFromRoute = canRemoveVaccinationPatientFromVaccinationRoute({
        vaccinationPatient: patient,
        vaccinationRoute: route,
        isSuperuser,
    });

    const showResubmit =
        isAssignedToRoute === false &&
        (jobStatus === JobStatus.WITHDRAWN || jobStatus === JobStatus.HCP_ABORTED);

    const showArchive =
        isAssignedToRoute === false &&
        (jobStatus === JobStatus.WITHDRAWN || jobStatus === JobStatus.HCP_ABORTED);

    const showWithdraw = isAssignedToRoute === false && jobStatus === JobStatus.PENDING;

    const showUnassign =
        isSuperuser &&
        (jobStatus === JobStatus.COMPLETED ||
            (isAssignedToRoute === false &&
                [
                    JobStatus.ACCEPTED,
                    JobStatus.AVAILABLE,
                    JobStatus.ARRIVED,
                    JobStatus.CURRENT,
                ].includes(jobStatus as JobStatus)));

    const showComplete = isSuperuser && jobStatus !== JobStatus.COMPLETED;

    const showRevertAbort =
        isAssignedToRoute &&
        jobStatus === JobStatus.HCP_ABORTED &&
        route.jobStatus !== JobStatus.COMPLETED &&
        route.jobStatus !== JobStatus.CONTROLLER_ABORTED &&
        route.jobStatus !== JobStatus.HCP_ABORTED &&
        moment(route.itinerary.route.endTime).isAfter(moment().startOf('day'));

    return (
        <>
            <Button intent="primary" large onClick={() => setEditing(true)} icon="edit" outlined>
                Edit
            </Button>
            {showResubmit && (
                <Button intent="success" large onClick={onResubmit} icon="redo" outlined>
                    Resubmit
                </Button>
            )}
            {showUnassign && (
                <Button
                    intent="warning"
                    large
                    onClick={() => setShowUnassignPrompt(true)}
                    icon="undo"
                    outlined
                >
                    Mark as incomplete
                </Button>
            )}
            {showRevertAbort && (
                <Button intent="success" large onClick={onRevertingAbort} icon="redo" outlined>
                    Revert abort
                </Button>
            )}
            {/*
             VSU-2078 i think this is close to where we do the VaccineRoute recalculation if its required.
                need to find out exact location
                //check if recalculation is required.
                vaccinationRouteStatusAllowsRecalculation({ vaccinationRoute: routes[0] })
                /*
                    Then The job is removed from a route:
                    together with GP pick up if other jobs in the route don’t have the same GP pickup
                    OR
                    without GP pick up if one or more other jobs have the same GP pickup
                    AND
                    No recalculation of a route is triggered

            */}
            {showRemoveFromRoute && (
                <Link
                    className="bp5-button bp5-intent-danger bp5-large bp5-outlined"
                    to={{
                        pathname: '/vaccinations/routes/withdraw-from-existing',
                        state: {
                            patient,
                            route,
                        },
                    }}
                >
                    <Icon icon="undo" />
                    <span className="bp5-button-text">Remove from route</span>
                </Link>
            )}
            {showWithdraw && (
                <Button
                    intent="warning"
                    large
                    onClick={() => setShowWithdrawPrompt(true)}
                    icon="undo"
                    outlined
                >
                    Withdraw
                </Button>
            )}
            {showComplete && (
                <Button
                    intent="success"
                    large
                    onClick={() => onShowCompletePrompt()}
                    icon="endorsed"
                    outlined
                >
                    Mark as completed
                </Button>
            )}
            {showArchive && (
                <Button
                    intent="danger"
                    large
                    onClick={() => setShowAbortPrompt(true)}
                    icon="archive"
                    outlined
                >
                    Archive
                </Button>
            )}
        </>
    );
};

export default PatientDetailsFooter;
