import React from 'react';
import { FormGroup, MenuItem } from '@blueprintjs/core';
import { FormikValues, FormikErrors, FormikTouched } from 'formik';

import Error from '../../modules/forms/Error';
import { Suggest } from '@blueprintjs/select';

export interface SuggestProps {
    inputName: string;
    componentName: string;
    componentPath: string;
    values: FormikValues;
    editOrCreateMode: boolean;
    handleChange: () => void;
    errors: FormikErrors<FormikValues>;
    touched: FormikTouched<FormikValues>;
    items: any;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    className?: string;
}

export interface InputProps {
    id: string;

    [inputName: string]: any;
}

export interface Item {
    name: string;
}

const InputSuggest = ({
    inputName,
    componentName,
    componentPath,
    editOrCreateMode,
    values,
    errors,
    touched,
    items,
    setFieldValue,
    className = '',
}: SuggestProps) => {
    const handleItemRenderer = (item: InputProps, { handleClick, query }: any) => (
        <MenuItem
            onClick={handleClick}
            role={'inputSuggestItem'}
            text={highlightText(item[inputName], query)}
            key={item?.id}
        />
    );
    const handleFilterItems = (query: string, item: any, index: any, exactMatch: any) => {
        const Title = item[inputName]?.toLowerCase();
        const Query = query?.toLowerCase();
        if (exactMatch) {
            return Title === query;
        } else {
            return Title.indexOf(Query) >= 0;
        }
    };
    const highlightText = (text: string, query: string) => {
        let lastIndex = 0;
        const words = query
            .split(/\s+/)
            .filter((word) => word?.length > 0)
            .map(escapeRegExpChars);
        if (words?.length === 0) {
            return [text];
        }
        const regexp = new RegExp(words.join('|'), 'gi');
        const tokens: React.ReactNode[] = [];
        while (true) {
            const match = regexp.exec(text);
            if (!match) {
                break;
            }
            const length = match[0]?.length;
            const before = text?.slice(lastIndex, regexp.lastIndex - length);
            if (before?.length > 0) {
                tokens.push(before);
            }
            lastIndex = regexp.lastIndex;
            tokens.push(<strong key={lastIndex}>{match[0]}</strong>);
        }
        const rest = text?.slice(lastIndex);
        if (rest?.length > 0) {
            tokens.push(rest);
        }
        return tokens;
    };

    const itemListPredicate = (query: string, items: any) => {
        return items
            .filter(
                (item: Item) => `${item.name?.toLowerCase()}`.indexOf(query?.toLowerCase()) >= 0,
            )
            ?.slice(0, 10);
    };

    const escapeRegExpChars = (text: string) => {
        return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
    };

    return (
        <>
            <dl className={`info ${className}`}>
                <dt
                    style={{
                        textTransform: 'capitalize',
                    }}
                    data-testid="inputSuggestTitle"
                    className="info__title"
                >
                    {inputName} *
                </dt>
                <dd data-testid="inputSuggestField" className="info__definition">
                    {editOrCreateMode ? (
                        <FormGroup labelFor={inputName}>
                            <Suggest
                                className="suggest"
                                inputValueRenderer={() => values[inputName]}
                                items={items}
                                itemRenderer={handleItemRenderer}
                                itemPredicate={handleFilterItems}
                                itemListPredicate={itemListPredicate}
                                openOnKeyDown={true}
                                onQueryChange={(value: any) => setFieldValue(inputName, value)}
                                selectedItem={values[inputName]}
                                inputProps={{
                                    placeholder: `Type ${inputName}`,
                                }}
                                popoverProps={{
                                    popoverClassName: `suggest__popover suggest__popover--${componentName}`,
                                    portalClassName: 'suggest__popover',
                                }}
                            />
                            {touched[inputName] && <Error errors={[errors[inputName]]} />}
                            <div
                                data-testid="inputSuggestError"
                                style={{ margin: '10px 0' }}
                                className="form-error-message no-padding"
                                hidden={!errors.notUniqueId}
                            >
                                The{' '}
                                <a
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    href={`${componentPath}${errors.notUniqueId}`}
                                >
                                    {errors.notUniqueName as React.ReactNode}
                                </a>{' '}
                                already exists in the system. Please enter a unique {inputName} to
                                save
                            </div>
                        </FormGroup>
                    ) : (
                        values[inputName]
                    )}
                </dd>
            </dl>
        </>
    );
};

export default InputSuggest;
