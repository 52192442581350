import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _, { isEmpty } from 'lodash';

import {
    GET_PAY_RATES,
    CREATE_PAY_RATE,
    CREATE_GENERAL_PAY_RATE,
    DELETE_PAY_RATE,
    UPDATE_PAY_RATE,
    UPDATE_GENERAL_PAY_RATE,
    GET_PAY_RATE,
    VALIDATE_PAY_RATE_NAME,
    DELETE_LAST_GENERAL_PAY_RATE,
    BULK_DELETE_PAYRATES,
    BULK_ARCHIVE_PAYRATES,
    VALIDATE_ARCHIVE_PAYRATES,
    ARCHIVE_PAY_RATE,
    VALIDATE_DELETE_PAYRATES,
} from '../graphql/queries/payRates';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';
import moment from 'moment';

class PayRatesStore extends PagedStore {
    constructor(rootStore) {
        super();
        makeObservable(this, {
            selected: observable,
            allPayRates: observable,
            payRates: observable,
            payRatesByRole: observable,
            traineePayRatesByRole: observable,
            payRatesByRoleAndAgency: observable,
            payRatesZero: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridPayRates: computed,
            allSelected: computed,
            gridPayRatesByRoles: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getPayRates: action,
            getPayRatesByRole: action,
            getTraineePayRatesByRole: action,
            getPayRatesZero: action,
            setSelectableCriteria: action,
            clearPayRatesByRole: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    apolloClient;
    selected = [];
    payRates = [];
    allPayRates = [];
    payRatesByRole = [];
    traineePayRatesByRole = [];
    payRatesByRoleAndAgency = [];
    payRatesZero = [];
    sortFn = null;
    selectableCriteria = [];

    init = (apolloClient) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizePayRates = (payRate) => {
        const actualGeneralPayRateIndex = payRate.generalPayRates.findIndex(
            (payRate) =>
                new Date() > new Date(payRate.startDate) &&
                (payRate.endDate === null || new Date() < new Date(payRate.endDate)),
        );
        const futurePayRateIndex = payRate.generalPayRates.findIndex(
            (payRate) => new Date() < new Date(payRate.startDate) && payRate.endDate === null,
        );
        if (actualGeneralPayRateIndex === -1) return;
        return {
            id: payRate.id,
            selected: this.selected.includes(payRate.id),
            name: payRate.name,
            createdAt: payRate.createdAt,
            updatedAt: payRate.updatedAt,
            createdBy: payRate.createdBy,
            updatedBy: payRate.updatedBy,
            createdByPerson: !isEmpty(payRate.createdByPerson) ? payRate.createdByPerson : '',
            updatedByPerson: !isEmpty(payRate.updatedByPerson) ? payRate.updatedByPerson : '',
            agencyId: payRate.agencyId,
            isArchived: payRate.isArchived,
            isDeleted: payRate.isDeleted,
            code:
                actualGeneralPayRateIndex > -1
                    ? payRate.generalPayRates[actualGeneralPayRateIndex].code
                    : '',
            employeeRoleId: payRate.employeeRoleId,
            employeeRole: payRate.employeeRole,
            agency: payRate.agency,
            generalPayRates:
                actualGeneralPayRateIndex > -1
                    ? {
                          id: payRate.generalPayRates[actualGeneralPayRateIndex].id,
                          code: payRate.generalPayRates[actualGeneralPayRateIndex].code,
                          rate: payRate.generalPayRates[actualGeneralPayRateIndex].rate,
                          startDate: payRate.generalPayRates[actualGeneralPayRateIndex].startDate,
                          endDate: payRate.generalPayRates[actualGeneralPayRateIndex].endDate,
                          payRateId: payRate.generalPayRates[actualGeneralPayRateIndex].payRateId,
                      }
                    : [],
            customPayRates: payRate.generalPayRates[actualGeneralPayRateIndex].customPayRates?.map(
                (custom) => ({
                    id: custom.id,
                    code: custom.code,
                    rate: custom.rate,
                    startTime: custom.startTime,
                    endTime: custom.endTime,
                    day: custom.day,
                }),
            ),
            futurePayRate:
                futurePayRateIndex > -1
                    ? {
                          name: payRate.name,
                          employeeRole: payRate.employeeRole,
                          agency: payRate.agency,
                          generalPayRates: {
                              id: payRate.generalPayRates[futurePayRateIndex].id,
                              code: payRate.generalPayRates[futurePayRateIndex].code,
                              rate: payRate.generalPayRates[futurePayRateIndex].rate,
                              startDate: payRate.generalPayRates[futurePayRateIndex].startDate,
                              endDate: payRate.generalPayRates[futurePayRateIndex].endDate,
                              payRateId: payRate.generalPayRates[futurePayRateIndex].payRateId,
                          },
                          customPayRates: payRate.generalPayRates[
                              futurePayRateIndex
                          ]?.customPayRates?.map((custom) => ({
                              id: custom.id,
                              code: custom.code,
                              rate: custom.rate,
                              startTime: custom.startTime,
                              endTime: custom.endTime,
                              day: custom.day,
                          })),
                      }
                    : null,
        };
    };

    get gridPayRates() {
        const payRates = this.payRates.map(this.normalizePayRates).filter((r) => !!r);
        if (this.sortFn) {
            payRates.sort(this.sortFn);
        }
        return payRates;
    }

    get allSelected() {
        return this.gridPayRates.every((r) => this.selected.includes(r.id));
    }

    async createPayRate(payRate) {
        try {
            const customPayRates = payRate.customPayRates
                ? payRate.customPayRates.map((r) => ({ ...r, rate: parseFloat(r.rate) }))
                : [];

            await this.apolloClient.mutate({
                mutation: CREATE_PAY_RATE,
                variables: {
                    data: {
                        name: payRate.name,
                        generalPayRates: {
                            code: payRate.generalPayRates.code,
                            customPayRates: customPayRates,
                            rate: parseFloat(payRate.generalPayRates.rate),
                            startDate: new Date(),
                        },
                        employeeRoleId: payRate.employeeRoleId,
                        agencyId: _.isEmpty(payRate.agencyId) ? null : payRate.agencyId,
                    },
                },
            });
            AppToaster.show({
                message: 'Pay rate created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async createFuturePayRate(payRateId, availableFrom, payRate) {
        try {
            const customPayRates = payRate.customPayRates
                ? payRate.customPayRates.map((r) => ({
                      code: r.code,
                      rate: parseFloat(r.rate),
                      startTime: r.startTime,
                      endTime: r.endTime,
                      day: r.day,
                  }))
                : [];

            await this.apolloClient.mutate({
                mutation: CREATE_GENERAL_PAY_RATE,
                variables: {
                    payRateId,
                    data: {
                        startDate: availableFrom,
                        code: payRate.generalPayRates.code,
                        rate: parseFloat(payRate.generalPayRates.rate),
                        customPayRates: customPayRates,
                    },
                    utcOffset: moment().utcOffset(),
                },
            });
            AppToaster.show({
                message: 'Pay rate created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem creating pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deletePayRate(payRate) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_PAY_RATE,
                variables: {
                    id: payRate.id,
                },
            });
            AppToaster.show({
                message: 'Pay rate deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async archivePayRate(payRate) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_PAY_RATE,
                variables: {
                    id: payRate.id,
                },
            });
            AppToaster.show({
                message: 'Pay rate archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteFuturePayRate(payRate) {
        const payRateId = payRate?.id;
        if (payRateId) {
            await this.apolloClient.mutate({
                mutation: DELETE_LAST_GENERAL_PAY_RATE,
                variables: {
                    payRateId,
                },
            });
            await this.refresh();
        }
    }

    async updateGeneralPayRate(payRateId, payRate, futureDialogDate) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_GENERAL_PAY_RATE,
                variables: {
                    generalPayRateId: payRateId,
                    data: {
                        code: payRate.generalPayRates.code,
                        rate: parseFloat(payRate.generalPayRates.rate),
                        startDate: futureDialogDate
                            ? futureDialogDate
                            : payRate.generalPayRates.startDate,
                        customPayRates: payRate.customPayRates?.map((custom) => ({
                            code: custom.code,
                            rate: parseFloat(custom.rate),
                            startTime: custom.startTime,
                            endTime: custom.endTime,
                            day: custom.day,
                        })),
                    },
                    utcOffset: moment().utcOffset(),
                },
            });
            AppToaster.show({
                message: 'Pay rate updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updatePayRate(payRate) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_PAY_RATE,
                variables: {
                    id: payRate.id,
                    data: {
                        name: payRate.name,
                        code: payRate.code,
                        rate: parseFloat(payRate.rate),
                        employeeRoleId: payRate.employeeRoleId,
                        customPayRates: payRate.customPayRates?.map((custom) => ({
                            id: custom.id,
                            code: custom.code,
                            rate: parseFloat(custom.rate),
                            startTime: custom.startTime,
                            endTime: custom.endTime,
                            day: custom.day,
                        })),
                    },
                    utcOffset: moment().utcOffset(),
                },
            });
            AppToaster.show({
                message: 'Pay rate updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem updating pay rate record. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (payRateId) => {
        if (!this.selected.includes(payRateId)) {
            this.selected.push(payRateId);
        }
    };

    deselect = (payRateId) => {
        const index = this.selected.indexOf(payRateId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getPayRates();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getPayRates();
    };

    onSort = (sortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name, values) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    getPayRates = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
            },
        });
        runInAction(() => {
            let arr = data.edges.map((x) => x.node);
            this.payRates = _.orderBy(
                arr,
                ['isArchived', [(pr) => pr.name.toLowerCase()]],
                ['asc', 'asc'],
            );
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllPayRates = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
            },
        });
        runInAction(() => {
            let arr = data.edges.map((x) => x.node);
            this.allPayRates = _.orderBy(arr, ['name'], ['asc']);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getPayRateById = async (id) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRateById: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATE,
            variables: {
                id,
            },
        });
        return this.normalizePayRates(data);
    };

    queryPayRatesByRole = async (roleId, employeeId) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roleId, employeeId, recordStatus: 'active' },
            },
        });
        return data.edges.map((x) => x.node);
    };

    queryPayRatesByRoleAndAgency = async (roleId, agencyId) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roleId, agencyId, recordStatus: 'active' },
            },
        });
        return data.edges.map((x) => x.node);
    };

    getPayRatesByRole = async (roleId, employeeId) => {
        const data = await this.queryPayRatesByRole(roleId, employeeId);
        runInAction(() => {
            this.payRatesByRole = _.orderBy(data, ['name'], ['asc']);
        });
    };

    getTraineePayRatesByRole = async (roleId, employeeId) => {
        const data = await this.queryPayRatesByRole(roleId, employeeId);
        runInAction(() => {
            this.traineePayRatesByRole = _.orderBy(data, ['name'], ['asc']);
        });
    };

    getPayRatesByRoleAndAgency = async (roleId, agencyId) => {
        const data = await this.queryPayRatesByRoleAndAgency(roleId, agencyId);
        runInAction(() => {
            this.payRatesByRoleAndAgency = _.orderBy(data, ['name'], ['asc']);
        });
    };

    getPayRatesByAgency = async (agencyId) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { agencyId },
            },
        });
        runInAction(() => {
            this.payRatesByAgency = data.edges
                ?.filter((item) => !item.node.isArchived)
                .map((x) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        employeeRoleName: x.node.employeeRole?.name,
                        generalCode: x.node.generalPayRates[0]?.code,
                    };
                });
            this.payRatesByAgency = _.orderBy(this.payRatesByAgency, ['name'], ['asc']);
        });
    };

    getPayRatesZero = async (zeroPayRate) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getPayRates: data },
        } = await this.apolloClient.query({
            query: GET_PAY_RATES,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { zeroPayRate },
            },
        });
        runInAction(() => {
            let arr = data.edges.map((x) => x.node);
            this.payRatesZero = _.orderBy(arr, ['name'], ['asc']);
        });
    };

    clearPayRatesByRole() {
        return (this.payRatesByRole = []);
    }

    get gridPayRatesByRoles() {
        return this.payRatesByRole.map(this.normalizePayRates).filter((r) => !!r);
    }

    startTimer() {
        if (!this.fetchInterval) {
            this.getPayRates();
            this.fetchInterval = setInterval(() => this.getPayRates(), 60000);
        }
    }

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridPayRates.forEach((payRate) => {
                !payRate.isArchived && this.select(payRate.id);
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getPayRates();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        this.getPayRates();
    };

    async validateName(name) {
        return await this.apolloClient.query({
            query: VALIDATE_PAY_RATE_NAME,
            variables: {
                name,
            },
        });
    }

    async validateDeletePayRates(ids) {
        return await this.apolloClient.query({
            query: VALIDATE_DELETE_PAYRATES,
            variables: {
                ids,
            },
        });
    }

    async validateArchivePayRates(ids) {
        return await this.apolloClient.query({
            query: VALIDATE_ARCHIVE_PAYRATES,
            variables: {
                ids,
            },
        });
    }

    async validateArchiveSelectedPayRates() {
        const {
            data: { arePayRatesArchivable },
        } = await this.validateArchivePayRates(this.selected);
        runInAction(() => {
            this.validatedArchivePayRates = arePayRatesArchivable;
            this.allArchivableSelectedPayRates = arePayRatesArchivable.every(
                (pr) => pr.isArchivable === true,
            );
            this.someArchivableSelectedPayRates = arePayRatesArchivable.some(
                (pr) => pr.isArchivable === true,
            );
        });
    }

    async validateDeletableSelectedPayRates() {
        const {
            data: { arePayRatesDeletable },
        } = await this.validateDeletePayRates(this.selected);
        runInAction(() => {
            this.validatedDeletablePayRates = arePayRatesDeletable;
            this.allDeletableSelectedPayRates = arePayRatesDeletable.every(
                (pr) => pr.isDeletable === true,
            );
            this.someDeletableSelectedPayRates = arePayRatesDeletable.some(
                (pr) => pr.isDeletable === true,
            );
        });
    }

    async bulkDeletePayRates(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_PAYRATES,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'All selected pay rates deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected pay rates. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async bulkArchivePayRates(ids) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_PAYRATES,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'All selected pay rates archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving selected pay rates. Please try again or
                        contact <a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default PayRatesStore;
