import { Patient, PatientAlert, StaffAlert } from '@doc-abode/data-models';
import { IHcp, IHcpExtended, IHcpFilter } from '../../interfaces/ucr';
import { INameFilter } from '../../stores/UCRStore';
import moment from 'moment';

interface IProps {
    users: IHcp[];
    hcpFilters: IHcpFilter;
    nameFilters: INameFilter;
    assignedJobsUnFiltered: Patient[];
    allSchedules: any[];
    patientAlerts: PatientAlert[];
    staffAlerts: StaffAlert[];
    selectedDate: Date;
    staffPinned: Record<string, number | undefined>;
}

const getFilteredHCPs = ({
    users,
    hcpFilters,
    nameFilters,
    assignedJobsUnFiltered,
    allSchedules,
    patientAlerts,
    staffAlerts,
    selectedDate,
    staffPinned,
}: IProps): IHcpExtended[] => {
    const unresolvedStaffAlert = new Set(staffAlerts.map((alert) => alert.userId));

    const usersIdsWithUnresolvedPatientAlerts = new Set(
        assignedJobsUnFiltered
            .filter((job) =>
                patientAlerts.some((alert) => {
                    const isSame = moment(alert.createdAt).isSame(selectedDate, 'day');
                    return alert.jobId === job.id && isSame;
                }),
            )
            .flatMap((job) => [job.hcpId, job.buddyId])
            .filter((id) => Boolean(id)),
    );

    const sortByFullName = (a: IHcp, b: IHcp) => {
        const aFullName = a.lastName + ', ' + a.userName + ', ' + a.firstName;
        const bFullName = b.lastName + ', ' + b.userName + ', ' + b.firstName;
        if (aFullName > bFullName) return 1;
        else if (aFullName < bFullName) return -1;
        return 0;
    };

    return users
        .map((user: IHcp) => {
            return {
                ...user,
                // Is the user available
                isAvailable: allSchedules.some((schedule) => schedule.userId === user.userId),
                isPinned: staffPinned[user.userId] ? staffPinned[user.userId] : false,
                // Does the user have any patient alerts
                hasPatientAlert: usersIdsWithUnresolvedPatientAlerts.has(user.userId),
                // Does the user have any staff alerts
                hasStaffAlert: unresolvedStaffAlert.has(user.userId),
                // Does the user have any jobs assigned
                // we now check to see if date of visit is the same as the
                // job we have so any rogue jobs in assigned with different dates no longer causes swimlane ordering issues.
                hasJobs: assignedJobsUnFiltered.some((job) => {
                    const isSame = moment(job.startDateTime || job.dateOfVisit).isSame(
                        selectedDate,
                        'day',
                    );
                    const isSameArrivedDate = moment(job.arrivedDateTime).isSame(
                        selectedDate,
                        'day',
                    );

                    if (isSame || isSameArrivedDate) {
                        if (job.buddyId === user.userId) {
                            return (
                                job.buddyJobStatus &&
                                job.buddyJobStatus !== 'PENDING' &&
                                job.buddyId === user.userId
                            );
                        } else {
                            return job.hcpId === user.userId;
                        }
                    } else {
                        return false;
                    }
                }),
            };
        })
        .filter((user: IHcpExtended): boolean => {
            let staffNameStatus = true;
            let hcpTypeIncluded = true;
            let hcpBandIncluded = true;
            let hcpGenderIncluded = true;

            const availabilities = hcpFilters.availability?.map(({ value }) => value) || [];

            const withVisits =
                (availabilities.includes('staffWithVisits') && user.hasJobs) ||
                (availabilities.includes('staffWithoutVisits') && !user.hasJobs) ||
                (!availabilities.includes('staffWithVisits') &&
                    !availabilities.includes('staffWithoutVisits'));

            const isAvailable =
                (availabilities.includes('availableStaff') && user.isAvailable) ||
                !availabilities.includes('availableStaff');
            const availabilityStatus =
                availabilities.length === 1
                    ? availabilities.includes('availableStaff')
                        ? isAvailable
                        : withVisits
                    : isAvailable || withVisits;
            if (nameFilters.staffName.length) {
                staffNameStatus = nameFilters.staffName.some(
                    (name) =>
                        user.firstName?.toLowerCase().includes(String(name).toLowerCase().trim()) ||
                        user.lastName?.toLowerCase().includes(String(name).toLowerCase().trim()) ||
                        user.userId?.toLowerCase().includes(String(name).toLowerCase().trim()),
                );
            }

            if (hcpFilters.hcpType?.length) {
                // TODO extend filter to support multiple options
                hcpTypeIncluded = hcpFilters.hcpType
                    .map(({ value }) => value)
                    .includes(
                        user.hcpTypes !== undefined ? user.hcpTypes.map((value) => value)[0] : '',
                    );
            }

            if (hcpFilters.band?.length) {
                hcpBandIncluded = hcpFilters.band.map(({ value }) => value).includes(user.band);
            }
            if (hcpFilters.gender?.length) {
                hcpGenderIncluded = hcpFilters.gender
                    .map(({ value }) => value)
                    .includes(user.gender);
            }
            return (
                user.enabled &&
                availabilityStatus &&
                staffNameStatus &&
                hcpTypeIncluded &&
                hcpBandIncluded &&
                hcpGenderIncluded
            );
        })
        .sort((a: IHcpExtended, b: IHcpExtended) => {
            // Prioritise users with staff alerts
            if (a.hasStaffAlert && b.hasStaffAlert) {
                return sortByFullName(a, b);
            } else if (a.hasStaffAlert !== b.hasStaffAlert) {
                return a.hasStaffAlert ? -1 : 1;
            }

            // Priortise users with patient alerts

            if (a.hasPatientAlert && b.hasPatientAlert) {
                return sortByFullName(a, b);
            } else if (a.hasPatientAlert !== b.hasPatientAlert) {
                return a.hasPatientAlert ? -1 : 1;
            }

            if (a.isPinned && b.isPinned) {
                const staffPinnedA: number = a.isPinned ? (a.isPinned as number) : 0;
                const staffPinnedB: number = b.isPinned ? (b.isPinned as number) : 0;

                if (staffPinnedA < staffPinnedB) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (a.isPinned !== b.isPinned) {
                return a.isPinned ? -1 : 1;
            }

            // Prioritise availablity and has a job
            if (a.isAvailable && b.isAvailable) {
                return sortByFullName(a, b);
            } else if (a.isAvailable !== b.isAvailable) {
                return a.isAvailable ? -1 : 1;
            }

            // Prioritise users with jobs
            if (a.hasJobs && b.hasJobs) {
                return sortByFullName(a, b);
            } else if (a.hasJobs !== b.hasJobs) {
                return a.hasJobs ? -1 : 1;
            }

            // Sort by fullName
            return sortByFullName(a, b);
        });
};

export default getFilteredHCPs;
