import React from 'react';
import '../styles/rota-builder-list.css';
import { EMPLOYEE_RECORD_STATUS_OPTIONS } from '../../../../../../constants/hrConst';
import moment from 'moment';
import { daysBetweenNegativeIncluded } from '../../../../../modules/helpers/formatData';
import { Checkbox } from '@blueprintjs/core';
import {
    SHIFT_STATUS_STANDARD_ID,
    SHIFT_TYPE_BANK_ID,
    SHIFT_TYPE_CONTRACTED_ID,
    SHIFT_TYPE_EXTRA_ID,
    SHIFT_TYPE_FLEXI_ID,
    SHIFT_TYPE_NON_CONTRACTED_ID,
    SHIFT_TYPE_SICK_ID,
    SHIFT_TYPE_UNPAID_ID,
} from '../../../../../../constants/rotaConst';
import { Icon } from '@blueprintjs/core';
import { numToTime } from '../helper';
import TableRowActions from '../../../../../common/TableRowActions';
import _ from 'lodash';

const Row = (props: any) => {
    const {
        entries,
        onDutyStationClick,
        onDelete,
        onSplit,
        rotaStartDate,
        dictionaryStore,
        holidayDates,
        staffStore,
        handleToggle,
        selectedListViewShifts,
        isPublished,
        openPaymentDialog,
        paymentStatusNormalized,
        functionsDictionary,
        fundingPoolsDictionary,
        isViewMode,
    } = props;

    const {
        shiftTypesDictionary,
        shiftStatusesDictionary,
        payRatesDictionary,
        rolesDictionary,
    } = dictionaryStore;

    const { allStaffs } = staffStore;

    const staffList = allStaffs.reduce((acc: any, item: any) => {
        acc[item.id] = item.person.firstName + ' ' + item.person.lastName;
        return acc;
    }, {});

    const dt = new Date();

    return entries?.map((entry: any, index: any) => {
        const entryClick = entry;

        const workStatus = (employee: string) => {
            if (!employee) return null;
            const person = staffStore.allStaffs.filter((item: any) => item.id === employee);
            if (person && person.length > 0) {
                const statusObj = EMPLOYEE_RECORD_STATUS_OPTIONS.filter(
                    (obj) => obj.value === person[0].statusId,
                );
                return statusObj[0].label;
            }
        };
        const defaultEmployeeStatus = workStatus(entry.defaultEmployeeId);
        const defaultEmployeeSuspendedArchived =
            defaultEmployeeStatus === 'Archived' || defaultEmployeeStatus === 'Suspended';
        const confirmedEmployeeStatus = workStatus(entry.confirmedEmployeeId);
        const confirmedEmployeeSuspendedArchived =
            confirmedEmployeeStatus === 'Archived' || confirmedEmployeeStatus === 'Suspended';

        const trainee = entry.trainees[0]?.traineeId
            ? staffList[entry.trainees[0]?.traineeId]
            : null;

        const traineeId = entry.trainees[0]?.traineeId;
        const confirmedTraineeStatus = workStatus(traineeId);
        const confirmedTraineeStatusCssClass =
            confirmedTraineeStatus === 'Archived' || confirmedTraineeStatus === 'Suspended'
                ? 'rota-shift__content-employee--warning'
                : '';

        const isSupervisorStaff = allStaffs.filter(
            (staff: any) =>
                (staff.isSupervisor &&
                    !entry.confirmedEmployeeId &&
                    staff.id === entry.defaultEmployeeId) ||
                (staff.isSupervisor && staff.id === entry.confirmedEmployeeId),
        ).length;

        const dayOfWeek = moment(rotaStartDate).toDate().getDay();
        const startDate = moment(rotaStartDate)
            .add((entry.weekNumber - 1) * 7 + entry.dayOfWeek - dayOfWeek, 'days')
            .toDate();

        const daysBetweenDates = daysBetweenNegativeIncluded(startDate, dt);

        const contractedWarning =
            (entry.typeId === SHIFT_TYPE_CONTRACTED_ID &&
                !entry.confirmedEmployeeId &&
                daysBetweenDates < 4 &&
                entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
            ((entry.typeId === SHIFT_TYPE_CONTRACTED_ID ||
                entry.typeId === SHIFT_TYPE_EXTRA_ID ||
                entry.typeId === SHIFT_TYPE_FLEXI_ID ||
                entry.typeId === SHIFT_TYPE_SICK_ID ||
                entry.typeId === SHIFT_TYPE_UNPAID_ID ||
                entry.typeId === SHIFT_TYPE_NON_CONTRACTED_ID) &&
                !entry.confirmedEmployeeId &&
                entry.statusId === SHIFT_STATUS_STANDARD_ID);

        const bankWarning =
            (entry.typeId === SHIFT_TYPE_BANK_ID &&
                !entry.confirmedEmployeeId &&
                daysBetweenDates < 4 &&
                entry.typeId === SHIFT_TYPE_UNPAID_ID) ||
            (entry.typeId === SHIFT_TYPE_BANK_ID &&
                !entry.confirmedEmployeeId &&
                daysBetweenDates < 4 &&
                entry.statusId === SHIFT_STATUS_STANDARD_ID);

        const warning = contractedWarning || bankWarning;

        const requiresAttention = warning && daysBetweenDates < 3;

        const holidayEntry = _.includes(
            Array.from(holidayDates),
            moment(entry.startDate).format('D/M/YYYY'),
        );

        const shiftDuration = new Date(entry.endTime.getTime() - entry.startTime.getTime())
            .toISOString()
            .substring(11, 16);

        const netDuration = entry.breaksPaid
            ? new Date(entry.endTime.getTime() - entry.startTime.getTime())
                  .toISOString()
                  .substring(11, 16)
            : new Date(
                  entry.endTime.getTime() -
                      entry.startTime.getTime() -
                      1000 * (60 * entry.breakDurationMinutes),
              )
                  .toISOString()
                  .substring(11, 16);

        return (
            <tr
                className={`common-table__row ${
                    entry.isAdditional ? 'common-table__row--is-additional' : ''
                }  `}
                key={index}
            >
                {!isViewMode && (
                    <td>
                        <Checkbox
                            id={entry.id}
                            checked={selectedListViewShifts.includes(entry.id)}
                            onChange={handleToggle}
                            onClick={(event) => event.stopPropagation()}
                        />
                    </td>
                )}
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    {!warning && !requiresAttention && (
                        <span className="common__status common__status--success" />
                    )}
                    {warning && !requiresAttention && (
                        <span className="common__status common__status--warning" />
                    )}
                    {requiresAttention && warning && (
                        <span className="common__status common__status--error" />
                    )}
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={'common-table__row-flex'}>
                        <span>{moment().day(moment(entry.startDate).day()).format('dddd')}</span>
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={'common-table__row-flex'}>
                        <span>{holidayEntry && <span className="holiday" />}</span>
                        <span>{moment(entry.startDate).format('DD/MM/YYYY')}</span>
                    </span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    {entry.weekNumber}
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{rolesDictionary[entry.roleId]}</span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{moment(entry.startTime).format('HH:mm')}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{moment(entry.endTime).format('HH:mm')}</span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.rotaShiftFunctions
                            ? entry.rotaShiftFunctions.map((id: string, index: number) =>
                                  index === 0
                                      ? functionsDictionary[id]
                                      : `, ${functionsDictionary[id]}`,
                              )
                            : null}
                    </span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.locationId
                            ? dictionaryStore.locationsDictionary[entry.locationId]
                            : null}
                    </span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span>
                        {entry.defaultEmployeeId && defaultEmployeeSuspendedArchived ? (
                            <span className="weeks-table__col-content-title--warning">
                                {staffList[entry.defaultEmployeeId]} ({defaultEmployeeStatus})
                            </span>
                        ) : (
                            staffList[entry.defaultEmployeeId]
                        )}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span>
                        {entry.confirmedEmployeeId && confirmedEmployeeSuspendedArchived ? (
                            <span className="weeks-table__col-content-title--warning">
                                {staffList[entry.confirmedEmployeeId]} ({confirmedEmployeeStatus})
                            </span>
                        ) : (
                            staffList[entry.confirmedEmployeeId]
                        )}
                    </span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`common-table__row-flex ${confirmedTraineeStatusCssClass}`}>
                        {entry.trainingShift && (
                            <>
                                <Icon
                                    icon="learning"
                                    intent={isSupervisorStaff ? 'none' : 'danger'}
                                />{' '}
                                {trainee
                                    ? confirmedTraineeStatus !== 'Active' &&
                                      confirmedTraineeStatus !== 'In progress'
                                        ? trainee + ` (${confirmedTraineeStatus})`
                                        : trainee
                                    : 'No trainee set'}
                            </>
                        )}
                    </span>
                </td>

                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{shiftTypesDictionary[entry.typeId]}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{shiftStatusesDictionary[entry.statusId]}</span>
                </td>
                <td>
                    <span className={`truncate`}>{shiftDuration}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.breakDurationMinutes > 0 && numToTime(entry.breakDurationMinutes)}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{entry.breaksPaid ? 'Yes' : 'No'}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.breaksPaid &&
                            entry.breakDurationMinutes > 0 &&
                            numToTime(entry.breakDurationMinutes)}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{netDuration}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{payRatesDictionary[entry.payRateId]}</span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    {entry.isAdditional ? 'Manually Created' : 'Shift Pattern'}
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.confirmedEmployee?.contractType?.name
                            ? entry.confirmedEmployee?.contractType?.name
                            : entry.defaultEmployee?.contractType?.name
                            ? entry.defaultEmployee?.contractType?.name
                            : ''}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.confirmedEmployee?.agency?.name
                            ? entry.confirmedEmployee?.agency?.name
                            : entry.defaultEmployee?.agency?.name
                            ? entry.defaultEmployee?.agency?.name
                            : ''}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>
                        {entry.fundingPoolId ? fundingPoolsDictionary[entry.fundingPoolId] : null}
                    </span>
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    {entry.conflicts?.length > 0 && (
                        <Icon color={'#FF7F00'} icon="warning-sign" size={20} />
                    )}
                </td>
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    {entry.comments?.length > 0 &&
                        entry.comments.some((item: any) => item.isImportant) && (
                            <Icon color={'#FF7F00'} icon="envelope" size={20} />
                        )}
                </td>
                {isPublished ? (
                    <td
                        onClick={() => {
                            onDutyStationClick(entryClick);
                        }}
                    >
                        {entry.paymentStatusId && paymentStatusNormalized[entry.paymentStatusId]}
                    </td>
                ) : null}
                <td
                    onClick={() => {
                        onDutyStationClick(entryClick);
                    }}
                >
                    <span className={`truncate`}>{entry.cost}</span>
                </td>
                <td>
                    <div className="rota-builder__day-shift-item__actions">
                        <TableRowActions
                            hasDuplicateOption={false}
                            handleDeleteClick={() => onDelete(entry.id)}
                            hasSplitOption={true}
                            handleSplitClick={() => onSplit(entry.id)}
                            paymentStatusId={entry.paymentStatusId}
                            isPublished={isPublished}
                            shiftId={entry.id}
                            openPaymentDialog={openPaymentDialog}
                        />
                    </div>
                </td>
            </tr>
        );
    });
};

export default Row;
