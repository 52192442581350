import React from 'react';

interface StatusesProps {
    filled?: string | number;
    unfilled?: string | number;
    requiresAttention?: string | number;
}

const Statuses = (props: StatusesProps) => {
    const {
        filled = 'Filled',
        unfilled = 'Unfilled',
        requiresAttention = 'Requires attention',
    } = props;
    return (
        <>
            <div className="common__statuses">
                <b
                    className={`common__status common__status--success ${
                        filled === 0 ? 'common__status--disabled' : ''
                    }`}
                >
                    {filled}
                </b>
                <b
                    className={`common__status common__status--warning ${
                        unfilled === 0 ? 'common__status--disabled' : ''
                    }`}
                >
                    {unfilled}
                </b>
                <b
                    className={`common__status common__status--error ${
                        requiresAttention === 0 ? 'common__status--disabled' : ''
                    }`}
                >
                    {requiresAttention}
                </b>
            </div>
        </>
    );
};

export default Statuses;
