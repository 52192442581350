import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@blueprintjs/core';
import { useFormikContext, useField } from 'formik';

import { getPhotoUploadParams, uploadFile } from '../../../api/hrRotaApi';
import AppToaster from '../../modules/helpers/Toaster';
import Error from '../../modules/forms/Error';

const FIVE_MEGABYTES = 5242880;
const allowedMIMETypes = ['image/jpeg', 'image/webp', 'image/jpg', 'image/png'];

const FileInput = ({ org }: { org: string }) => {
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isComplete, setIsComplete] = useState(false);
    const [selectedFileError, setSelectedFileError] = useState('');

    const { values, setValues } = useFormikContext();
    const [, meta] = useField('fileName');

    const existingValues = values as Record<string, unknown>;

    const reset = () => {
        setIsUploading(false);
        setIsComplete(false);
        setSelectedFile(null);
        setSelectedFileError('');
    };

    const onDrop = useCallback(
        async ([file]: File[]) => {
            reset();

            const { name: fileName, size: sizeInBytes, type: mimeType } = file;
            setSelectedFile(fileName);

            if (sizeInBytes > FIVE_MEGABYTES) {
                setSelectedFileError('File is too large.');
                return;
            }

            if (!allowedMIMETypes.includes(mimeType)) {
                setSelectedFileError('File type is not allowed.');
                return;
            }

            try {
                setIsUploading(true);

                const uploadParams = await getPhotoUploadParams(fileName, org);
                await uploadFile(file, uploadParams);

                setValues({
                    ...existingValues,
                    profilePhoto: uploadParams.profileUrl,
                });
                setIsComplete(true);

                AppToaster.show({
                    message: 'File uploaded successfully!',
                    intent: 'success',
                });
            } catch (err) {
                console.error(err);

                AppToaster.show({
                    message: 'There was an error uploading the file, please try again.',
                    intent: 'danger',
                });
            }
            setIsUploading(false);
        },
        [setValues, existingValues, org],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className="file-upload">
            {isComplete ? (
                <div className="file-upload__input file-upload__input--success">
                    <Icon icon="paperclip" className="file-upload__icon" />
                    {selectedFile}
                </div>
            ) : isUploading ? (
                <div className="file-upload__input file-upload__input--uploading">
                    <Icon icon="cloud-upload" className="file-upload__icon" />
                    Uploading {selectedFile}...
                </div>
            ) : (
                <>
                    <label htmlFor="fileUpload">
                        <div {...getRootProps()} className="file-upload__input">
                            <input name="fileUpload" {...getInputProps()} multiple={false} />
                            <span className="opacity-75">
                                <Icon icon="document-open" className="file-upload__icon" />
                                {isDragActive
                                    ? 'Drop the file here...'
                                    : 'To upload a new file, drag and drop it here, or click to choose...'}
                                {meta.touched && <Error errors={[meta.error]} />}
                                {selectedFileError && <Error errors={[selectedFileError]} />}
                            </span>
                        </div>
                    </label>
                </>
            )}
        </div>
    );
};

export default FileInput;
