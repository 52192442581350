export function presentationNameLMF({
    firstName,
    middleName,
    lastName,
}: {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
}): string {
    return [lastName?.trim(), [firstName, middleName].filter(Boolean).join(' ').trim()]
        .filter(Boolean)
        .join(', ');
}

export function presentationNameFML({
    firstName,
    middleName,
    lastName,
}: {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
}): string {
    return [firstName?.trim(), [middleName, lastName].filter(Boolean).join(' ').trim()]
        .filter(Boolean)
        .join(' ');
}
