import React from 'react';
import useHeaderSorting from '../../../../hook/useHeaderSorting';
import SortedHeader from '../../../common/sorting/SortedHeader';
import { Checkbox } from '@blueprintjs/core';

interface DocumentsHeaderProps {
    onSort: () => void;
    onToggleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isFrontendSort: boolean;
    checked: boolean;
    isEmployee: boolean;
}

const DocumentsHeader = ({
    onSort,
    isFrontendSort = true,
    onToggleSelectAll,
    checked,
    isEmployee,
}: DocumentsHeaderProps) => {
    const { sort, sortData } = useHeaderSorting((s: any) => s, onSort, isFrontendSort);
    return (
        <thead>
            <tr>
                <th className="common-table__checkbox-column-heading">
                    {!isEmployee && <Checkbox onChange={onToggleSelectAll} checked={checked} />}
                </th>
                <SortedHeader
                    sort={sort}
                    sortBy={'category.name'}
                    text="Category"
                    sortData={sortData}
                />
                <SortedHeader
                    sort={sort}
                    sortBy={'fileName'}
                    text="File name"
                    sortData={sortData}
                />
                <SortedHeader sort={sort} sortBy={'sizeInBytes'} text="Size" sortData={sortData} />
                <SortedHeader sort={sort} sortBy={'expiresAt'} text="Expires" sortData={sortData} />
                {!isEmployee && (
                    <SortedHeader
                        sort={sort}
                        sortBy={'isArchived'}
                        text="Status"
                        sortData={sortData}
                    />
                )}
                <SortedHeader
                    sort={sort}
                    sortBy={'isVerified'}
                    text="Verified status"
                    sortData={sortData}
                />
                <th className="common-table__checkbox-column-heading">Verified By</th>
                <SortedHeader
                    sort={sort}
                    sortBy={'updatedAt'}
                    text="Last modified"
                    sortData={sortData}
                />
            </tr>
        </thead>
    );
};

export default DocumentsHeader;
