import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import {
    FormattedPDSRecord,
    formatPhoneNumber,
    Patient,
    Gender,
    PatientAddress,
    PatientName,
    FormattedPDSContact,
} from '@doc-abode/data-models';
import { FormikContextType, FormikValues, useFormikContext } from 'formik';
import { Dialog } from '@blueprintjs/core';

import { genderLabels, getValidityPeriod } from '../../../../../helpers/ucr/helpers';
import { Button, ButtonSizes } from '../../../../v2/components';
import Checkbox from '../../../../v2/form/Checkbox';
import Radio from '../../../../v2/form/Radio';
import Text from '../../../../v2/form/Text';
import TableHeader, { HeaderColumnType } from '../../../../common/Table/TableHeader';
import TableRow from '../../../../common/Table/TableRow';
import { filterPatientData } from './helpers';
import useStores from '../../../../../hook/useStores';
import { presentationNameLMF, formatAddressString } from '../../../../../helpers';
import { PdsIssues } from '../PatientDetails/PdsIssues';

type IPatientDataWrap = {
    nhsNumber: string;
    hasReferralData: boolean;
    setToFormPatientData: (data: Patient) => void;
    insideDialog?: boolean;
    showDialog?: boolean;
    onClose?: () => void;
};

const PatientDataWrap = ({
    nhsNumber,
    hasReferralData,
    setToFormPatientData,
    insideDialog = false,
    showDialog = false,
    onClose = () => {},
}: IPatientDataWrap) => {
    const {
        RootStore: {
            ucrStore: { localPatientData, setSelectedPatientData, pdsData },
        },
    } = useStores() as {
        RootStore: {
            ucrStore: {
                localPatientData: Patient;
                setSelectedPatientData: (data: Patient) => void;
                pdsData: Record<string, FormattedPDSRecord>;
            };
        };
    };

    const { values }: FormikContextType<FormikValues> = useFormikContext();

    const getFilteredPatientData = useCallback(() => {
        const prePopulatedAllData = filterPatientData({
            localData: localPatientData,
            pdsData: pdsData?.[nhsNumber],
            setSelectedPatientData,
            values,
            setToFormPatientData,
            bypassSetToForm: insideDialog,
        });

        return prePopulatedAllData;
    }, [
        localPatientData,
        pdsData,
        nhsNumber,
        setSelectedPatientData,
        values,
        setToFormPatientData,
        insideDialog,
    ]);

    const prePopulatedAllData = getFilteredPatientData();

    // If we only have one set of data don't display the form at all
    if (prePopulatedAllData) return null;

    const Form = (
        <PatientDataTable
            pdsData={pdsData?.[nhsNumber]}
            hasReferralData={hasReferralData}
            setToFormPatientData={setToFormPatientData}
            insideDialog={insideDialog}
            onClose={onClose}
        />
    );

    return insideDialog ? (
        <Dialog
            title={<span className="v2__dialog-title">Amended patient details detected</span>}
            className="v2__dialog-content v2__dialog-content--large"
            isOpen={!!pdsData && showDialog}
            onClose={onClose}
        >
            <p className="v2__dialog-description">
                The patient's details have changed on SPINE. Please review the below and select the
                correct details.
            </p>
            <div className="v2__dialog-description">
                <PdsIssues />
            </div>
            {Form}
        </Dialog>
    ) : (
        Form
    );
};

type IPatientDataTable = {
    pdsData?: FormattedPDSRecord;
    hasReferralData: boolean;
    setToFormPatientData: (values: Patient) => void;
    insideDialog?: boolean;
    onClose?: () => void;
};

const defaultPatientName = {
    firstName: '',
    lastName: '',
    middleName: '',
};

const PatientDataTable: FC<IPatientDataTable> = ({
    pdsData,
    setToFormPatientData,
    insideDialog = false,
    onClose = () => {},
}: IPatientDataTable) => {
    const {
        RootStore: {
            ucrStore: { localPatientData, selectedPatientData },
        },
    } = useStores() as {
        RootStore: { ucrStore: { localPatientData: Patient; selectedPatientData: Patient } };
    };

    const localData = localPatientData as Patient;
    const selectedData = selectedPatientData as Patient;

    const localDataPatientName = {
        firstName: localData?.firstName || selectedData?.firstName || '',
        lastName: localData?.lastName || selectedData?.lastName || '',
        middleName: localData?.middleName || selectedData?.middleName || '',
    };
    const hasSameName =
        pdsData?.singleDataSet?.name &&
        presentationNameLMF(localDataPatientName).toLowerCase() ===
            presentationNameLMF(pdsData?.name[0]).toLowerCase();
    const [selectedPatientName, setSelectedPatientName] = useState<PatientName>(
        hasSameName ? localDataPatientName : defaultPatientName,
    );
    const [choseLocalPatientName, setChoseLocalPatientName] = useState(hasSameName);

    const localDateOfBirth = localData?.dateOfBirth || selectedData?.dateOfBirth;
    const hasSameDateOfBirth =
        moment(localDateOfBirth).format('DD/MM/YYYY') ===
        moment(pdsData?.dateOfBirth).format('DD/MM/YYYY');
    const [selectedDateOfBirth, setSelectedDateOfBirth] = useState<string | null>(
        localDateOfBirth && hasSameDateOfBirth ? localDateOfBirth : null,
    );
    const [choseLocalDateOfBirth, setChoseLocalDateOfBirth] = useState(hasSameDateOfBirth);

    const localGender = localData?.gender || selectedData?.gender;
    const hasSameGender = localGender?.toLowerCase() === pdsData?.gender?.toLowerCase();
    const [selectedGender, setSelectedGender] = useState<Gender>(
        localGender && hasSameGender ? localGender : Gender.NOTPROVIDED,
    );
    const [choseLocalGender, setChoseLocalGender] = useState(hasSameGender);

    const localContactNumbersSet = new Set([
        ...(!!localData?.contactNumber ? [localData?.contactNumber] : []),
        ...(localData?.additionalContactNumbers || []),
        ...(!!selectedData?.contactNumber ? [selectedData?.contactNumber] : []),
    ]) as Set<string>;
    const pdsContactNumbersSet = new Set(pdsData?.contactNumber?.map(({ value }) => value) || []);
    const hasSameContacts =
        pdsData?.singleDataSet?.contactNumber &&
        localContactNumbersSet.size === pdsContactNumbersSet.size &&
        Array.from(localContactNumbersSet).every(
            (value) => value && pdsContactNumbersSet.has(value),
        );
    const [selectedContactNumber, setSelectedContactNumber] = useState<string[]>(
        hasSameContacts ? Array.from(localContactNumbersSet) : [],
    );
    const [selectedContactNumberLocal, setSelectedContactNumberLocal] = useState<
        Record<string, boolean>
    >({});
    const [selectedContactNumberPDS, setSelectedContactNumberPDS] = useState<
        Record<string, boolean>
    >({});

    const localAddressData = {
        addressLine1: localData?.addressLine1 || selectedData?.addressLine1,
        addressLine2: localData?.addressLine2 || selectedData?.addressLine2,
        addressLine3: localData?.addressLine3 || selectedData?.addressLine3,
        town: localData?.town || selectedData?.town,
        postCode: localData?.postCode || selectedData?.postCode,
    };
    const localAddressLabel = formatAddressString(localAddressData);
    const hasSameAddress =
        pdsData?.address.length === 0 ||
        (pdsData?.singleDataSet?.address &&
            localAddressLabel.toLowerCase() ===
                formatAddressString({
                    addressLine1: pdsData?.address[0].addressLine1,
                    addressLine2: pdsData?.address[0].addressLine2,
                    addressLine3: pdsData?.address[0].addressLine3,
                    town: pdsData?.address[0].town,
                    postCode: pdsData?.address[0].postCode,
                }).toLowerCase());
    const [selectedAddress, setSelectedAddress] = useState<PatientAddress | null>(
        hasSameAddress ? localAddressData : null,
    );
    const [choseLocalAddress, setChoseLocalAddress] = useState(false);

    const [selectedAllLocalData, setSelectedAllLocalData] = useState(false);
    const [selectedAllPDSData, setSelectedAllPDSData] = useState(false);

    const [ActionButtons, setActionButtons] = useState<JSX.Element>();

    const setSelectedValues = useCallback(
        (force = false) =>
            (!insideDialog || force) &&
            setToFormPatientData({
                firstName: selectedPatientName?.firstName || '',
                lastName: selectedPatientName?.lastName || '',
                middleName: selectedPatientName?.middleName || '',
                dateOfBirth: selectedDateOfBirth || '',
                gender: selectedGender || Gender.NOTPROVIDED,
                contactNumber: selectedContactNumber?.[0] || '',
                addressLine1: selectedAddress?.addressLine1 || '',
                addressLine2: selectedAddress?.addressLine2 || '',
                addressLine3: selectedAddress?.addressLine3 || '',
                postCode: selectedAddress?.postCode || '',
                town: selectedAddress?.town || '',
                languagesSpoken: localData?.languagesSpoken || [],
                staffPreferredGender: localData?.staffPreferredGender || [],
                additionalContactNumbers: selectedContactNumber?.slice(1),
                pds: { versionId: pdsData?.versionId || -1 },
                ...(pdsData?.nhsNumber && { nhsNumber: pdsData.nhsNumber }),
            } as Patient),
        [
            insideDialog,
            localData?.languagesSpoken,
            localData?.staffPreferredGender,
            pdsData?.nhsNumber,
            pdsData?.versionId,
            selectedAddress?.addressLine1,
            selectedAddress?.addressLine2,
            selectedAddress?.addressLine3,
            selectedAddress?.postCode,
            selectedAddress?.town,
            selectedContactNumber,
            selectedDateOfBirth,
            selectedGender,
            selectedPatientName?.firstName,
            selectedPatientName?.lastName,
            selectedPatientName?.middleName,
            setToFormPatientData,
        ],
    );

    useEffect(() => {
        setActionButtons(
            insideDialog ? (
                <div className="v2__button-container v2__button-container--justify-end">
                    <Button
                        className="v2__button v2__button--margin-right"
                        name="Apply selected changes"
                        size={ButtonSizes.MEDIUM}
                        clickEvent={setSelectedValues}
                        disabled={
                            !selectedPatientName?.firstName ||
                            !selectedDateOfBirth ||
                            !selectedGender ||
                            !selectedAddress?.addressLine1 ||
                            !selectedAddress?.town ||
                            !selectedAddress?.postCode
                        }
                    />
                    <Button
                        className="v2__button v2__button--red"
                        name="Review later"
                        size={ButtonSizes.MEDIUM}
                        clickEvent={onClose}
                    />
                </div>
            ) : (
                <Button
                    className="v2__form-submit-button"
                    type="submit"
                    name="Use selected data"
                    size={ButtonSizes.MEDIUM}
                    clickEvent={setSelectedValues}
                />
            ),
        );
    }, [
        insideDialog,
        onClose,
        selectedAddress?.addressLine1,
        selectedAddress?.postCode,
        selectedAddress?.town,
        selectedDateOfBirth,
        selectedGender,
        selectedPatientName?.firstName,
        setSelectedValues,
    ]);

    const selectAllLocalData = () => {
        rows.forEach(({ fieldName }: { fieldName: string }) => {
            if (fieldName === 'name') {
                setSelectedPatientName(localDataPatientName || defaultPatientName);
                setChoseLocalPatientName(true);
            }

            if (fieldName === 'dateOfBirth') {
                setSelectedDateOfBirth(localData?.dateOfBirth || null);
                setChoseLocalDateOfBirth(true);
            }

            if (fieldName === 'gender') {
                setSelectedGender(localData?.gender || Gender.NOTPROVIDED);
                setChoseLocalGender(true);
            }

            if (fieldName === 'contactNumber') {
                let allContactNumbers = [localData?.contactNumber].filter((f) => !!f);

                if (localData?.additionalContactNumbers?.length) {
                    allContactNumbers = [
                        ...allContactNumbers,
                        ...localData.additionalContactNumbers,
                    ].filter((f) => !!f);
                }
                const newContactNumber = allContactNumbers.map((n) =>
                    formatPhoneNumber(n as string),
                );
                const selectedContactNumbers = newContactNumber.reduce(
                    (acc: Record<string, boolean>, n: string) => {
                        acc[n] = true;
                        return acc;
                    },
                    {},
                );
                setSelectedContactNumber(Array.from(new Set([...newContactNumber])));
                setSelectedContactNumberLocal(selectedContactNumbers);
                setSelectedContactNumberPDS({});
            }

            if (fieldName === 'address') {
                setSelectedAddress(localAddressData);
                setChoseLocalAddress(true);
            }
        });

        setSelectedAllLocalData(true);
        setSelectedAllPDSData(false);
    };

    const unselectAllLocalData = () => {
        rows.forEach(({ fieldName }: { fieldName: string }) => {
            if (fieldName === 'name') {
                setSelectedPatientName(defaultPatientName);
                setChoseLocalPatientName(false);
            }

            if (fieldName === 'dateOfBirth') {
                setSelectedDateOfBirth(null);
                setChoseLocalDateOfBirth(false);
            }

            if (fieldName === 'gender') {
                setSelectedGender(Gender.NOTPROVIDED);
                setChoseLocalGender(false);
            }

            if (fieldName === 'contactNumber') {
                let allContactNumbers = [localData?.contactNumber].filter((f) => !!f);

                if (localData?.additionalContactNumbers?.length) {
                    allContactNumbers = [
                        ...allContactNumbers,
                        ...localData.additionalContactNumbers,
                    ].filter((f) => !!f);
                }
                const newContactNumber = allContactNumbers.map((n) =>
                    formatPhoneNumber(n as string),
                );
                const selectedContactNumbers = newContactNumber.reduce(
                    (acc: Record<string, boolean>, n: string) => {
                        acc[n] = false;
                        return acc;
                    },
                    {},
                );

                setSelectedContactNumber(
                    Array.from(
                        new Set([
                            ...selectedContactNumber.filter((c) => !newContactNumber.includes(c)),
                        ]),
                    ),
                );
                setSelectedContactNumberLocal(selectedContactNumbers);
            }

            if (fieldName === 'address') {
                setSelectedAddress(null);
                setChoseLocalAddress(false);
            }
        });

        setSelectedAllLocalData(false);
    };

    const selectAllPDSData = () => {
        rows.forEach(({ fieldName }: { fieldName: string }) => {
            if (fieldName === 'name') {
                const pdsName = {
                    firstName: pdsData?.name?.[0]?.firstName || defaultPatientName.firstName,
                    lastName: pdsData?.name?.[0]?.lastName || defaultPatientName.lastName,
                    middleName: pdsData?.name?.[0]?.middleName || defaultPatientName.middleName,
                };
                setSelectedPatientName(pdsName);
                setChoseLocalPatientName(false);
            }

            if (fieldName === 'dateOfBirth') {
                setSelectedDateOfBirth(pdsData?.dateOfBirth || null);
                setChoseLocalDateOfBirth(false);
            }

            if (fieldName === 'gender') {
                setSelectedGender(pdsData?.gender || Gender.NOTPROVIDED);
                setChoseLocalGender(false);
            }

            if (fieldName === 'contactNumber') {
                const selectedContactNumbers =
                    pdsData?.contactNumber?.reduce(
                        (acc: Record<string, boolean>, n: FormattedPDSContact) => {
                            acc[n.value] = true;
                            return acc;
                        },
                        {},
                    ) || {};
                setSelectedContactNumber(
                    Array.from(
                        new Set([
                            ...(pdsData?.contactNumber?.map((n: FormattedPDSContact) => n.value) ||
                                []),
                        ]),
                    ),
                );
                setSelectedContactNumberPDS(selectedContactNumbers);
                setSelectedContactNumberLocal({});
            }

            if (fieldName === 'address') {
                const addressData = {
                    addressLine1: pdsData?.address?.[0]?.addressLine1,
                    addressLine2: pdsData?.address?.[0]?.addressLine2,
                    addressLine3: pdsData?.address?.[0]?.addressLine3,
                    town: pdsData?.address?.[0]?.town,
                    postCode: pdsData?.address?.[0]?.postCode,
                };
                setSelectedAddress(addressData);
                setChoseLocalAddress(false);
            }
        });

        setSelectedAllPDSData(true);
        setSelectedAllLocalData(false);
    };

    const unselectAllPDSData = () => {
        rows.forEach(({ fieldName }: { fieldName: string }) => {
            if (fieldName === 'name') {
                setSelectedPatientName(defaultPatientName);
            }

            if (fieldName === 'dateOfBirth') {
                setSelectedDateOfBirth(null);
            }

            if (fieldName === 'gender') {
                setSelectedGender(Gender.NOTPROVIDED);
            }

            if (fieldName === 'contactNumber') {
                const pdsNumbers =
                    pdsData?.contactNumber?.map((n: FormattedPDSContact) => n.value) || [];
                const selectedContactNumbers =
                    pdsData?.contactNumber?.reduce(
                        (acc: Record<string, boolean>, n: FormattedPDSContact) => {
                            acc[n.value] = false;
                            return acc;
                        },
                        {},
                    ) || {};
                setSelectedContactNumber(
                    Array.from(
                        new Set([...selectedContactNumber.filter((c) => !pdsNumbers.includes(c))]),
                    ),
                );
                setSelectedContactNumberPDS(selectedContactNumbers);
            }

            if (fieldName === 'address') {
                setSelectedAddress(null);
            }
        });

        setSelectedAllPDSData(false);
    };

    const columnsHeader: HeaderColumnType[] = [
        {
            key: '',
            label: '',
            sortable: false,
        },
        ...(!!localData
            ? [
                  {
                      key: 'local',
                      label: 'Local',
                      sortable: false,
                      hasSelectAll: true,
                      onSelect: (checked: boolean) =>
                          checked ? selectAllLocalData() : unselectAllLocalData(),
                      isSelected: selectedAllLocalData,
                  },
              ]
            : []),
        {
            key: 'spine',
            label: 'SPINE',
            sortable: false,
            hasSelectAll: true,
            onSelect: (checked: boolean) => (checked ? selectAllPDSData() : unselectAllPDSData()),
            isSelected: selectedAllPDSData,
        },
    ];

    const nameColumns = [
        {
            key: 'name',
            label: 'Patient name',
            render: () => {
                return (
                    <Text
                        name="name"
                        description="Patient name"
                        descriptionClassName="v2__form-section-text-title"
                    />
                );
            },
        },
        ...(!!localData
            ? [
                  {
                      key: 'patientName',
                      label: 'Patient Name',
                      render: () => {
                          if (!localData) return;

                          const name = presentationNameLMF(localDataPatientName);

                          return (
                              <Radio
                                  name="patientName"
                                  className="v2__form-group--pos-1-2"
                                  checked={
                                      choseLocalPatientName &&
                                      presentationNameLMF(selectedPatientName) === name
                                  }
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setSelectedPatientName(
                                          e.target.checked
                                              ? localDataPatientName
                                              : defaultPatientName,
                                      );
                                      setChoseLocalPatientName(true);
                                      setSelectedAllPDSData(false);
                                  }}
                              >
                                  <Text
                                      name={name}
                                      description={name}
                                      descriptionClassName="v2__form-section-radio-description"
                                  />
                              </Radio>
                          );
                      },
                  },
              ]
            : []),
        {
            key: 'patientNamePDS',
            label: 'Patient Name PDS',
            render: () => {
                if (!pdsData?.name) return;

                return (
                    <>
                        {pdsData?.name.map((n) => {
                            const pdsName = {
                                firstName: n.firstName ? n.firstName : '',
                                lastName: n.lastName,
                                middleName: n.middleName ? n.middleName : '',
                            };

                            const name = presentationNameLMF(pdsName);

                            return (
                                <Radio
                                    key={name}
                                    name="patientName"
                                    className="v2__form-group--pos-1-2"
                                    checked={
                                        !choseLocalPatientName &&
                                        presentationNameLMF(selectedPatientName) === name
                                    }
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setSelectedPatientName(
                                            e.target.checked ? pdsName : defaultPatientName,
                                        );
                                        setChoseLocalPatientName(false);
                                        setSelectedAllLocalData(false);
                                    }}
                                >
                                    <Text
                                        key={`${name}-text`}
                                        name={name}
                                        titleComponent={
                                            <>
                                                <span>{n.use}</span> {'  '}
                                                <span className="v2__form-section-radio-title--normal">
                                                    {name}
                                                </span>
                                            </>
                                        }
                                        description={getValidityPeriod(n.period)}
                                        titleClassName="v2__form-section-radio-title"
                                        descriptionClassName="v2__form-section-radio-description v2__form-section-radio-description--small"
                                    />
                                </Radio>
                            );
                        })}
                    </>
                );
            },
        },
    ];
    const dateOfBirthColumns = [
        {
            key: 'name',
            label: '',
            render: () => {
                return (
                    <Text
                        name="name"
                        description="Date of birth"
                        descriptionClassName="v2__form-section-text-title"
                    />
                );
            },
        },
        ...(!!localData
            ? [
                  {
                      key: 'dateOfBirth',
                      label: 'Date of birth',
                      render: () => {
                          if (!localData) return;
                          if (!localData.dateOfBirth) return;

                          const value = moment(localData.dateOfBirth).format('DD/MM/YYYY');

                          return (
                              <Radio
                                  name="dateOfBirth"
                                  className="v2__form-group--pos-1-2"
                                  checked={
                                      choseLocalDateOfBirth &&
                                      selectedDateOfBirth === localData.dateOfBirth
                                  }
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setSelectedDateOfBirth(
                                          e.target.checked && localData?.dateOfBirth
                                              ? localData.dateOfBirth
                                              : null,
                                      );
                                      setChoseLocalDateOfBirth(true);
                                      setSelectedAllPDSData(false);
                                  }}
                              >
                                  <Text
                                      name={value}
                                      description={value}
                                      descriptionClassName="v2__form-section-radio-description"
                                  />
                              </Radio>
                          );
                      },
                  },
              ]
            : []),
        {
            key: 'dateOfBirth',
            label: 'Date of birth PDS',
            render: () => {
                const value = moment(pdsData?.dateOfBirth).format('DD/MM/YYYY');
                return (
                    <>
                        {pdsData?.dateOfBirth && (
                            <Radio
                                name="dateOfBirth"
                                className="v2__form-group--pos-1-2"
                                checked={
                                    !choseLocalDateOfBirth &&
                                    selectedDateOfBirth === pdsData?.dateOfBirth
                                }
                                onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                                    setSelectedDateOfBirth(
                                        changeEvent.target.checked && pdsData?.dateOfBirth
                                            ? pdsData.dateOfBirth
                                            : null,
                                    );
                                    setChoseLocalDateOfBirth(false);
                                    setSelectedAllLocalData(false);
                                }}
                            >
                                <Text
                                    name={value}
                                    description={value}
                                    descriptionClassName="v2__form-section-radio-description"
                                />
                            </Radio>
                        )}
                    </>
                );
            },
        },
    ];
    const genderColumns = [
        {
            key: 'name',
            label: 'Gender',
            render: () => {
                return (
                    <Text
                        name="name"
                        description="Gender"
                        descriptionClassName="v2__form-section-text-title"
                    />
                );
            },
        },
        ...(!!localData
            ? [
                  {
                      key: 'gender',
                      label: 'Gender',
                      render: () => {
                          if (!localData) return;

                          const value = localData.gender
                              ? genderLabels[localData.gender]
                              : genderLabels.not_provided;
                          const gender = localData.gender ? localData.gender : Gender.NOTPROVIDED;

                          return (
                              <Radio
                                  name="patientGender"
                                  className="v2__form-group--pos-1-2"
                                  checked={choseLocalGender && selectedGender === gender}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setSelectedGender(
                                          e.target.checked ? gender : Gender.NOTPROVIDED,
                                      );
                                      setChoseLocalGender(true);
                                      setSelectedAllPDSData(false);
                                  }}
                              >
                                  <Text
                                      name={value}
                                      description={value}
                                      descriptionClassName="v2__form-section-radio-description"
                                  />
                              </Radio>
                          );
                      },
                  },
              ]
            : []),
        {
            key: 'genderPDS',
            label: 'Gender PDS',
            render: () => {
                const value = genderLabels[pdsData?.gender || 'not_provided'];
                return (
                    <>
                        {pdsData?.gender && (
                            <Radio
                                name="patientGender"
                                className="v2__form-group--pos-1-2"
                                checked={!choseLocalGender && selectedGender === pdsData?.gender}
                                onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) => {
                                    setSelectedGender(
                                        changeEvent.target.checked && pdsData?.gender
                                            ? pdsData.gender
                                            : Gender.NOTPROVIDED,
                                    );
                                    setChoseLocalGender(false);
                                    setSelectedAllLocalData(false);
                                }}
                            >
                                <Text
                                    name={value}
                                    description={value}
                                    descriptionClassName="v2__form-section-radio-description"
                                />
                            </Radio>
                        )}
                    </>
                );
            },
        },
    ];
    const phoneColumns = [
        {
            key: 'name',
            label: 'Phone',
            render: () => {
                return (
                    <Text
                        name="name"
                        description="Phone number(s)"
                        descriptionClassName="v2__form-section-text-title"
                    />
                );
            },
        },
        ...(!!localData
            ? [
                  {
                      key: 'contactNumber',
                      label: 'contactNumber Local',
                      render: () => {
                          if (!localData) return;

                          let allContactNumbers = [localData.contactNumber].filter((f) => !!f);
                          const additionalContactNumbers = localData?.additionalContactNumbers?.filter(
                              (str: any) => str,
                          );

                          if (additionalContactNumbers?.length) {
                              allContactNumbers = [
                                  ...allContactNumbers,
                                  ...additionalContactNumbers,
                              ];
                          }

                          return allContactNumbers?.length
                              ? allContactNumbers
                                    // n what is an n,  was there a letter shortage when this code was written?
                                    .filter((n) => n)
                                    .map((n) => (
                                        <Checkbox
                                            key={n}
                                            name="contactNumber"
                                            className="bp5-control-indicator--small"
                                            checked={
                                                selectedContactNumberLocal[
                                                    formatPhoneNumber(n as string)
                                                ]
                                            }
                                            onChange={(
                                                changeEvent: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                                let newContactNumber = [...selectedContactNumber];

                                                if (changeEvent.target.checked) {
                                                    newContactNumber.push(
                                                        formatPhoneNumber(n as string),
                                                    );
                                                    setSelectedContactNumberLocal({
                                                        ...selectedContactNumberLocal,
                                                        [formatPhoneNumber(n as string)]: true,
                                                    });
                                                } else {
                                                    newContactNumber = newContactNumber.filter(
                                                        (c) => c !== formatPhoneNumber(n as string),
                                                    );
                                                    setSelectedContactNumberLocal({
                                                        ...selectedContactNumberLocal,
                                                        [formatPhoneNumber(n as string)]: false,
                                                    });
                                                    setSelectedAllLocalData(false);
                                                }

                                                setSelectedContactNumber(
                                                    Array.from(new Set([...newContactNumber])),
                                                );
                                            }}
                                        >
                                            <Text
                                                key={`${n}-text`}
                                                name={n || ''}
                                                description={n || ''}
                                                descriptionClassName="v2__form-section-radio-description"
                                            />
                                        </Checkbox>
                                    ))
                              : null;
                      },
                  },
              ]
            : []),
        {
            key: 'contactNumber PDS',
            label: 'contactNumber PDS',
            render: () => {
                if (!pdsData?.contactNumber) return;

                return (
                    <>
                        {pdsData?.contactNumber.map((t) => {
                            return (
                                <Checkbox
                                    key={t.value}
                                    name="contactNumber"
                                    className="bp5-control-indicator--small"
                                    title={`${t.use}: ${t.value}`}
                                    checked={selectedContactNumberPDS[t.value]}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        let newContactNumber = [...selectedContactNumber];

                                        if (e.target.checked) {
                                            newContactNumber.push(t.value);
                                            setSelectedContactNumberPDS({
                                                ...selectedContactNumberPDS,
                                                [t.value]: true,
                                            });
                                        } else {
                                            newContactNumber = newContactNumber.filter(
                                                (c) => c !== t.value,
                                            );
                                            setSelectedContactNumberPDS({
                                                ...selectedContactNumberPDS,
                                                [t.value]: false,
                                            });
                                            setSelectedAllPDSData(false);
                                        }

                                        setSelectedContactNumber(
                                            Array.from(new Set([...newContactNumber])),
                                        );
                                    }}
                                >
                                    <Text
                                        key={`${t.value}-text`}
                                        name={t.value}
                                        titleComponent={
                                            <>
                                                <span>{t.use}</span> {'  '}
                                                <span className="v2__form-section-radio-title--normal">
                                                    {t.value}
                                                </span>
                                            </>
                                        }
                                        description={getValidityPeriod(t.period)}
                                        titleClassName="v2__form-section-radio-title"
                                        descriptionClassName="v2__form-section-radio-description v2__form-section-radio-description--small"
                                    />
                                </Checkbox>
                            );
                        })}
                    </>
                );
            },
        },
    ];
    const addressColumns = [
        {
            key: 'name',
            label: 'Address',
            render: () => {
                return (
                    <Text
                        name="name"
                        description="Address"
                        descriptionClassName="v2__addressColumnsform-section-text-title"
                    />
                );
            },
        },
        ...(!!localData
            ? [
                  {
                      key: 'addressLocal',
                      label: 'address Local',
                      render: () => {
                          if (!localData) return;

                          if (
                              ![
                                  localData.addressLine1,
                                  localData.addressLine2,
                                  localData.addressLine3,
                                  localData.town,
                                  localData.postCode,
                              ].some((_) => _)
                          ) {
                              return;
                          }

                          const isChecked =
                              choseLocalAddress &&
                              !!selectedAddress &&
                              selectedAddress.addressLine1 === localAddressData.addressLine1 &&
                              selectedAddress.addressLine2 === localAddressData.addressLine2 &&
                              selectedAddress.addressLine3 === localAddressData.addressLine3 &&
                              selectedAddress.town === localAddressData.town &&
                              selectedAddress.postCode === localAddressData.postCode;

                          return (
                              <Radio
                                  name="address"
                                  className="v2__form-group--pos-1-2"
                                  checked={isChecked}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setSelectedAddress(
                                          e.target.checked ? localAddressData : null,
                                      );
                                      setChoseLocalAddress(true);
                                      setSelectedAllPDSData(false);
                                  }}
                              >
                                  <Text
                                      name={localAddressLabel}
                                      description={localAddressLabel}
                                      descriptionClassName="v2__form-section-radio-description"
                                  />
                              </Radio>
                          );
                      },
                  },
              ]
            : []),
        {
            key: 'addressPDS',
            label: 'address PDS',
            render: () => {
                if (!pdsData?.address) return;

                return (
                    <>
                        {pdsData?.address.length &&
                            pdsData?.address.map((a) => {
                                const addressData = {
                                    addressLine1: a.addressLine1,
                                    addressLine2: a.addressLine2,
                                    addressLine3: a.addressLine3,
                                    town: a.town,
                                    postCode: a.postCode,
                                };

                                const addressLabel = formatAddressString(addressData);

                                const isChecked =
                                    !choseLocalAddress &&
                                    !!selectedAddress &&
                                    selectedAddress.addressLine1 === a.addressLine1 &&
                                    selectedAddress.addressLine2 === a.addressLine2 &&
                                    selectedAddress.addressLine3 === a.addressLine3 &&
                                    selectedAddress.town === a.town &&
                                    selectedAddress.postCode === a.postCode;

                                return (
                                    <>
                                        <Radio
                                            name="address"
                                            className="v2__form-group--pos-1-2"
                                            checked={isChecked}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setSelectedAddress(
                                                    e.target.checked ? addressData : null,
                                                );
                                                setChoseLocalAddress(false);
                                                setSelectedAllLocalData(false);
                                            }}
                                        >
                                            <Text
                                                name={addressLabel}
                                                titleComponent={
                                                    <>
                                                        <span>{a.use}</span> {'  '}
                                                        <span className="v2__form-section-radio-title--normal">
                                                            {addressLabel}
                                                        </span>
                                                    </>
                                                }
                                                description={getValidityPeriod(a.period)}
                                                titleClassName="v2__form-section-radio-title"
                                                descriptionClassName="v2__form-section-radio-description v2__form-section-radio-description--small"
                                            />
                                        </Radio>
                                    </>
                                );
                            })}
                    </>
                );
            },
        },
    ];

    const rows: {
        key: string;
        fieldName: string;
        columns: ReactNode;
    }[] = [];
    // TODO columns as ReactNode is a quick fix
    if (
        !hasSameName &&
        (localData?.firstName ||
            localData?.lastName ||
            localData?.middleName ||
            pdsData?.name?.length)
    ) {
        rows.push({ key: 'Patient name', columns: nameColumns as ReactNode, fieldName: 'name' });
    }

    if (!hasSameDateOfBirth && (localData?.dateOfBirth || pdsData?.dateOfBirth)) {
        rows.push({
            key: 'Date of birth',
            columns: dateOfBirthColumns as ReactNode,
            fieldName: 'dateOfBirth',
        });
    }

    if (!hasSameGender && (localData?.gender || pdsData?.gender)) {
        rows.push({ key: 'Gender', columns: genderColumns as ReactNode, fieldName: 'gender' });
    }

    if (!hasSameContacts && (localContactNumbersSet.size || pdsContactNumbersSet.size)) {
        rows.push({
            key: 'Phone number(s)',
            columns: phoneColumns as ReactNode,
            fieldName: 'contactNumber',
        });
    }

    if (!hasSameAddress && (localAddressLabel || pdsData?.address?.length)) {
        rows.push({ key: 'Address', columns: addressColumns as ReactNode, fieldName: 'address' });
    }

    if (!rows.length) {
        setSelectedValues();
    }

    return (
        <div className="patient-data">
            {rows.length > 0 ? (
                <>
                    <table className="absence-table bp5-html-table patient-data-table">
                        <TableHeader columns={columnsHeader} />
                        <tbody>
                            {rows.map((item: any) => (
                                <TableRow
                                    key={item.key}
                                    columns={item.columns}
                                    item={item}
                                    onClick={() => {}}
                                    alignTop
                                />
                            ))}
                        </tbody>
                    </table>
                    {ActionButtons}
                </>
            ) : null}
        </div>
    );
};

export default PatientDataWrap;
