import React from 'react';
import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import moment from 'moment';

const PayRatesFutureDialog = (props: any) => {
    const {
        bodyText = 'Please set the date and time to update the pay rate',
        onCancel,
        onSubmit,
        submitText = 'Submit',
        isOpen,
        showWarning = false,
    } = props;

    let minDate = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
    minDate.setHours(0);
    minDate.setMinutes(0);

    const [futureDate, setSutureDate] = React.useState(minDate);

    return (
        <Dialog onClose={onCancel} title="Update in the future" isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                {showWarning && (
                    <p className="payRates-future-warning">
                        There is an existing future pay rate update - it will be overridden!
                    </p>
                )}
                <p>{bodyText}</p>
                <div>
                    <DatePicker
                        defaultValue={minDate}
                        className={Classes.ELEVATION_1}
                        onChange={(e) => setSutureDate(e)}
                        highlightCurrentDay={true}
                        minDate={minDate}
                        maxDate={moment().add(1, 'years').toDate()}
                        timePrecision={'minute'}
                    />
                </div>
            </div>
            <div className={`${Classes.DIALOG_FOOTER} exit-dialog-footer`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button intent={Intent.SUCCESS} onClick={() => onSubmit(futureDate)}>
                        {submitText}
                    </Button>
                    <Button intent={Intent.NONE} onClick={onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default PayRatesFutureDialog;
