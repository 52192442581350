import React, { FC, Fragment, useEffect } from 'react';
import cn from 'classnames';
import { JobStatus, Patient, PatientAlert } from '@doc-abode/data-models';
import { observer } from 'mobx-react';
import moment from 'moment/moment';

import useStores from '../../../../../hook/useStores';
import { formatDisplayDate, formatDisplayTime } from '../../../../modules/helpers/formatData';
import { Info, InfoItem } from '../../../../v2/components';
import { friendlyCareComplexity, getServiceDisplayName } from '../../forms/common';
import { getStringEndTime } from '../../../../../helpers';
import { VisitDetailsWarnings } from '../../components/VisitDetailsWarnings';
import { HcpSelect } from '../../components/HcpSelect';
import { WarningType } from '../../../../../interfaces/ucr';
import { TimeHelperActualEnd, TimeHelperActualStart } from './TimeHelper';
import { genderMapping } from '../../../../../constants/mainConst';
import { VisitValuesType } from './VisitDetailsTypes';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';

interface IProps {
    patient: Patient;
    isDoubleUp: boolean;
    isFirstUser: boolean;
    warnings: WarningType[];
    patientAlerts: PatientAlert[];
    onHcpReassignment: (userId: string) => void;
    isStaffMemberEditable: boolean;
}

const VisitDetails: FC<IProps> = ({
    patient,
    isDoubleUp,
    isFirstUser,
    warnings,
    patientAlerts,
    onHcpReassignment,
    isStaffMemberEditable,
}) => {
    const {
        RootStore: {
            usersStore: { users },
            configStore: { pathways },
            schedulesStore: { getSchedulesByDate },
        },
    } = useStores() as { RootStore: any };
    const {
        jobStatus,
        buddyJobStatus,
        addressLine1,
        addressLine2,
        addressLine3,
        town,
        postCode,
        age,
        dateOfBirth,
        gender,
        nhsNumber,
        contactNumber,
        additionalContactNumbers,
        languagesSpoken,
        notes,
        postVisitNotes,
        postVisitNotesBuddy,
        startDateTime,
        duration,
        availableFrom,
        availableTo,
        disposition,
        referrer,
        referralPathway,
        careComplexity,
        carRequired,
        staffRequired,
        hcpId,
        buddyId,
        controllerAbortedReason = '',
        controllerAbortedNotes = '',
        buddyControllerAbortedReason = '',
        buddyControllerAbortedNotes = '',
        hcpAbortedReason = '',
        hcpAbortedNotes = '',
        buddyHcpAbortedReason = '',
        buddyHcpAbortedNotes = '',
        earliestDateOfVisit,
        dateOfVisit,
    } = patient;

    const staff = users.find(
        ({ userId }: { userId: any }) => userId === (isFirstUser ? hcpId : buddyId),
    );
    const staffName = staff ? `${staff.firstName} ${staff.lastName}` : '';
    // Combine contact numbers into single array, and remove any null values
    const contactNumbers = [contactNumber, ...(additionalContactNumbers || [])].filter((_) => _);

    let jobState: JobStatus | undefined = patient?.jobStatus;

    let postJobNotesLabel = 'Post-job notes';
    let postJobNotesDisplayVal = postVisitNotes;

    if (isDoubleUp) {
        if (isFirstUser) {
            postJobNotesLabel = postJobNotesLabel.concat(' #1');
        } else {
            jobState = patient?.buddyJobStatus;
            postJobNotesLabel = postJobNotesLabel.concat(' #2');
            postJobNotesDisplayVal = postVisitNotesBuddy;
        }
    }
    const showPostJobNotes = shouldShowPostJobNotes({ jobStatus: jobState });

    useEffect(() => {
        getSchedulesByDate(moment(dateOfVisit).format('YYYY-MM-DD'));
    }, [dateOfVisit, getSchedulesByDate]);

    const pathway = pathways.find((pathway: any) => pathway.value === referralPathway);

    return (
        <main
            className={cn('visit-details__main', {
                'visit-details__main--default': !isDoubleUp,
                'visit-details__main--double-up': isDoubleUp,
            })}
        >
            <VisitDetailsWarnings
                patient={patient}
                warnings={warnings}
                patientAlerts={patientAlerts}
            />
            <div className="visit-details__patient">
                <Info title="Patient details">
                    <InfoItem label="Address" alignSelf="start">
                        {[addressLine1, addressLine2, addressLine3, town, postCode]
                            .filter((_) => _)
                            .map((addressPart, i) => (
                                <Fragment key={i}>
                                    {i !== 0 && <br />}
                                    {addressPart}
                                </Fragment>
                            ))}
                    </InfoItem>
                    <InfoItem label="Age">{String(age)}</InfoItem>
                    <InfoItem label="Date&nbsp;of&nbsp;birth">
                        {dateOfBirth ? formatDisplayDate(dateOfBirth) : dateOfBirth}
                    </InfoItem>
                    <InfoItem label="Gender">
                        {gender ? genderMapping[gender] : genderMapping.not_provided}
                    </InfoItem>
                    <InfoItem label="NHS&nbsp;number">{nhsNumber}</InfoItem>
                    <InfoItem label="Contact number(s)" alignSelf="start">
                        {contactNumbers.length > 0
                            ? contactNumbers.map((number, i) => (
                                  <Fragment key={i}>
                                      {i !== 0 && <br />}
                                      {`+${number}`}
                                  </Fragment>
                              ))
                            : null}
                    </InfoItem>
                    <InfoItem label="Language(s) spoken">{languagesSpoken?.join(', ')}</InfoItem>
                    {((isFirstUser && jobStatus === JobStatus.CONTROLLER_ABORTED) ||
                        (!isFirstUser && buddyJobStatus === JobStatus.CONTROLLER_ABORTED)) && (
                        <>
                            <InfoItem label="Aborted&nbsp;reason" intent="danger">
                                {isFirstUser
                                    ? controllerAbortedReason
                                    : buddyControllerAbortedReason}
                            </InfoItem>
                            <InfoItem label="Aborted&nbsp;notes" intent="danger">
                                {isFirstUser ? controllerAbortedNotes : buddyControllerAbortedNotes}
                            </InfoItem>
                        </>
                    )}
                    {((isFirstUser && jobStatus === JobStatus.HCP_ABORTED) ||
                        (!isFirstUser && buddyJobStatus === JobStatus.HCP_ABORTED)) && (
                        <>
                            <InfoItem label="Aborted&nbsp;reason" intent="danger">
                                {hcpAbortedReason || buddyHcpAbortedReason}
                            </InfoItem>
                            <InfoItem label="Aborted&nbsp;notes" intent="danger">
                                {hcpAbortedNotes || buddyHcpAbortedNotes}
                            </InfoItem>
                        </>
                    )}
                </Info>
            </div>
            <div className="visit-details__visit">
                <Info title="Visit details">
                    <InfoItem label="#&nbsp;Staff&nbsp;required">{staffRequired || 1}</InfoItem>
                    <InfoItem
                        label={
                            <>
                                Staff&nbsp;member&nbsp;
                                {isDoubleUp ? (isFirstUser ? '#1' : '#2') : ''}
                            </>
                        }
                    >
                        {isStaffMemberEditable ? (
                            <HcpSelect
                                patient={patient as VisitValuesType}
                                isFirstUser={isFirstUser}
                                onHcpReassignment={onHcpReassignment}
                                insideForm={false}
                                includeUnavailableHcps={false}
                            />
                        ) : (
                            staffName
                        )}
                    </InfoItem>
                    <InfoItem label="Referrer">{referrer}</InfoItem>
                    <InfoItem label="Pathway">{pathway?.label || null}</InfoItem>
                    <InfoItem label="Service">
                        {getServiceDisplayName(pathway?.services, disposition) || null}
                    </InfoItem>
                    <InfoItem label="Complexity">
                        {careComplexity ? friendlyCareComplexity[careComplexity] : null}
                    </InfoItem>
                    <InfoItem label="Car&nbsp;required">{carRequired ? 'Yes' : 'No'}</InfoItem>
                    <InfoItem label="Pre-job notes" alignSelf="start">
                        {notes}
                    </InfoItem>
                    <ConditionalDisplay show={showPostJobNotes}>
                        <InfoItem label={postJobNotesLabel} alignSelf="start">
                            {postJobNotesDisplayVal}
                        </InfoItem>
                    </ConditionalDisplay>
                </Info>
            </div>
            <div className="visit-details__timing">
                <div className="visit-details__timing-container">
                    <Info title="Visit timing">
                        <InfoItem label="Earliest&nbsp;date of&nbsp;visit">
                            {earliestDateOfVisit ? formatDisplayDate(earliestDateOfVisit) : ''}
                        </InfoItem>
                        <InfoItem label="Earliest&nbsp;time of&nbsp;visit">
                            {availableFrom ? formatDisplayTime(availableFrom) : ''}
                        </InfoItem>
                        <InfoItem label="Planned&nbsp;start">
                            {startDateTime ? formatDisplayTime(startDateTime) : ''}
                        </InfoItem>
                        <InfoItem label="Planned&nbsp;duration">{duration}</InfoItem>
                        <InfoItem label="Planned&nbsp;end">
                            {startDateTime
                                ? getStringEndTime(startDateTime, duration || '', 'HH:mm')
                                : ''}
                        </InfoItem>
                        <InfoItem label="Latest&nbsp;time of&nbsp;visit">
                            {availableTo ? formatDisplayTime(availableTo) : ''}
                        </InfoItem>
                        <InfoItem
                            label={
                                <>
                                    Actual&nbsp;start&nbsp;
                                    {isDoubleUp ? (isFirstUser ? '#1' : '#2') : ''}
                                </>
                            }
                        >
                            {TimeHelperActualStart({
                                patient: patient,
                                isFirstUser: isFirstUser,
                            })}
                        </InfoItem>
                    </Info>
                    <Info>
                        <InfoItem
                            label={
                                <>
                                    Actual&nbsp;end&nbsp;
                                    {isDoubleUp ? (isFirstUser ? '#1' : '#2') : ''}
                                </>
                            }
                        >
                            {TimeHelperActualEnd({
                                patient: patient,
                                isFirstUser: isFirstUser,
                            })}
                        </InfoItem>
                    </Info>
                </div>
            </div>
        </main>
    );
};

export default observer(VisitDetails);
