// @ts-check

import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import { Button, Tag, Checkbox, Callout } from '@blueprintjs/core';
import { OnboardingUser } from '@doc-abode/data-models';

const { documents, trainingOptions } = OnboardingUser.lookups;

const getDocumentTitle = (category) => {
    let name = [...documents, ...trainingOptions].find((file) => file.category === category)?.name;
    if (!name) {
        name = 'Reference';
    }
    return name;
};

const SectionProgress = ({ validation }) => {
    if (validation.errors.length === 0) {
        return (
            <Tag intent="success" minimal large>
                Complete
            </Tag>
        );
    }

    return (
        <Tag intent="danger" minimal large>
            Incomplete
        </Tag>
    );
};

const SectionMissingInfo = ({ validation }) => {
    if (validation.errors.length === 0) {
        return null;
    }

    return (
        <>
            {validation.errors.map((error) => (
                <Fragment key={error}>
                    <dt className="info__title onboarding__missing-info">
                        {error.split(' | ')[0]}
                    </dt>
                    <dd className="info__definition onboarding__missing-info">Not provided</dd>
                </Fragment>
            ))}
        </>
    );
};

const Onboarding = inject('RootStore')(
    observer(
        class Onboarding extends Component {
            state = {
                downloading: {},
                summary: {},
            };

            _updateSummary = (summary) => this.setState({ summary });

            async componentDidMount() {
                const summary = await this.props.onboarding.summary();
                this._updateSummary(summary);
            }

            _handleDownloadFileClick = async (key) => {
                this.setState({
                    downloading: {
                        ...this.state.downloading,
                        [key]: true,
                    },
                });

                const url = await this.props.RootStore.onboardingsStore.downloadFile(key);
                window.open(url);

                this.setState({
                    downloading: {
                        ...this.state.downloading,
                        [key]: false,
                    },
                });
            };

            render() {
                const {
                    onboarding,
                    complianceCheckMode,
                    complianceCheckError,
                    complianceCheckedFiles,
                    complianceCheckboxChange,
                    hideDocuments,
                } = this.props;
                const { summary } = this.state;

                const files = onboarding.values.files.reduce((result, file) => {
                    const existingSet = result.find((set) => set.category === file.category);
                    if (existingSet) {
                        existingSet.files.push(file);
                    } else {
                        result.push({ category: file.category, files: [file] });
                    }
                    return result;
                }, []);

                return (
                    <>
                        {Object.values(summary).map(({ title, items, validation }) => (
                            <Fragment key={title}>
                                <h3 className="h3 info__heading onboarding__heading">
                                    {title}
                                    <span className="onboarding__progress">
                                        <SectionProgress validation={validation} />
                                    </span>
                                </h3>
                                <dl className="info">
                                    <SectionMissingInfo validation={validation} />
                                    {Object.values(items).map(({ title, value }) => {
                                        if (!value) return null;
                                        return (
                                            <Fragment key={title}>
                                                <dt className="info__title">{title}</dt>
                                                <dd className="info__definition">{value}</dd>
                                            </Fragment>
                                        );
                                    })}
                                </dl>
                            </Fragment>
                        ))}
                        {!hideDocuments && (
                            <>
                                <h3 className="h3 info__heading onboarding__heading">Documents</h3>
                                <dl className="info">
                                    {files.map(({ category, files }) => (
                                        <Fragment key={category}>
                                            <dt className="info__title">
                                                {getDocumentTitle(category)}
                                            </dt>
                                            <dd className="info__definition">
                                                {files.map(
                                                    ({ key, fileName, expiryDate, uploadId }) => (
                                                        <div className="onboarding__file" key={key}>
                                                            <div className="onboarding__file-row">
                                                                <div className="onboarding__file-details">
                                                                    {fileName}
                                                                    {expiryDate && (
                                                                        <span className="onboarding__expiry">
                                                                            Expiry: {expiryDate}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="onboarding__file-download">
                                                                    <Button
                                                                        onClick={() =>
                                                                            this._handleDownloadFileClick(
                                                                                key,
                                                                            )
                                                                        }
                                                                        icon="cloud-download"
                                                                        intent="primary"
                                                                        loading={
                                                                            this.state.downloading[
                                                                                key
                                                                            ]
                                                                        }
                                                                    >
                                                                        Download
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                            {complianceCheckMode && (
                                                                <Callout
                                                                    className="onboarding__file-compliance"
                                                                    intent={
                                                                        complianceCheckedFiles[
                                                                            uploadId
                                                                        ]
                                                                            ? 'success'
                                                                            : complianceCheckError
                                                                            ? 'danger'
                                                                            : 'primary'
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        label="Compliance checked"
                                                                        onChange={
                                                                            complianceCheckboxChange
                                                                        }
                                                                        checked={
                                                                            complianceCheckedFiles[
                                                                                uploadId
                                                                            ]
                                                                        }
                                                                        id={uploadId}
                                                                    />
                                                                </Callout>
                                                            )}
                                                        </div>
                                                    ),
                                                )}
                                            </dd>
                                        </Fragment>
                                    ))}
                                </dl>
                            </>
                        )}
                    </>
                );
            }
        },
    ),
);

export default Onboarding;
