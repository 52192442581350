import React from 'react';
import { JobStatus, Admission } from '@doc-abode/data-models';

import { NotSet } from '../NotSet';
import { nhsNumberFormatter } from '../../../../../../helpers/presentation';
import moment from 'moment';
import { getAgeFromDate } from '../../../../../modules/helpers/formatData';
import { presentationNameLMF } from '../../../../../../helpers';
import { genderMapping } from '../../../../../../constants/mainConst';
import { Patient, Job } from '../../../../../../__generated__/v2';
import { PatientListJobDetails } from './PatientListJobDetails';
// import { PatientListQuickActions } from '../PatientListQuickActions';

function getLastCompletedJobInfo(lastCompletedJob?: Job | null) {
    if (!lastCompletedJob) {
        return {};
    }

    const [{ arrivedDateTime, user }] = lastCompletedJob.personJobs
        .map((personJob, i) => {
            const arrivedDateTime = personJob.personJobStatuses?.find(
                (personJobStatus) => personJobStatus.jobTypeStatus?.name === JobStatus.ARRIVED,
            )?.createdAt;

            return {
                arrivedDateTime,
                user: i + 1,
            };
        })
        .sort((a, b) => {
            if (a.arrivedDateTime && b.arrivedDateTime) {
                return a.arrivedDateTime > b.arrivedDateTime ? 1 : -1;
            } else if (a.arrivedDateTime) {
                return 1;
            } else if (b.arrivedDateTime) {
                return -1;
            } else {
                return 0;
            }
        });

    return { lastCompletedJobUser: user, lastCompletedJobDate: arrivedDateTime };
}

function nextUpcomingJobInfo(nextUpcomingJob?: Job | null) {
    if (!nextUpcomingJob) {
        return {};
    }

    const { plannedDate, plannedTime } = nextUpcomingJob;

    const nextUpcomingJobDate = plannedTime
        ? moment(`${plannedDate} ${plannedTime}`, 'YYYY-MM-DD HH:mm').toISOString()
        : moment(plannedDate).startOf('day').toISOString();

    return { nextUpcomingJobDate, hideHour: !plannedTime };
}

const PatientListRow = ({ patient }: { patient: Patient }) => {
    const { admissions, nhsNumber, person, lastCompletedJob, nextUpcomingJob } = patient;
    const { dateOfBirth, gender, contactRecords } = person;

    const postCode = contactRecords?.find((contactRecord) => contactRecord.postCode)?.postCode;

    const latestAdmission = admissions?.sort((a, b) => {
        const aDate = new Date(a.createdAt);
        const bDate = new Date(b.createdAt);
        return bDate.getTime() - aDate.getTime();
    })[0];

    const { referralPathway } = latestAdmission || {};

    const { nextUpcomingJobDate, hideHour } = nextUpcomingJobInfo(nextUpcomingJob);
    const { lastCompletedJobUser, lastCompletedJobDate } = getLastCompletedJobInfo(
        lastCompletedJob,
    );

    const isAdmitted = latestAdmission?.admissionStatuses?.some(
        (status) => status.statusType?.name === Admission.ADMITTED,
    );

    return (
        <tr>
            <td>{nhsNumberFormatter(nhsNumber) || <NotSet />}</td>
            <td>{presentationNameLMF(person) || <NotSet />}</td>
            <td>
                {dateOfBirth ? (
                    `${moment(dateOfBirth).format('DD/MM/YYYY')} (Age: ${getAgeFromDate(
                        dateOfBirth,
                    )})`
                ) : (
                    <NotSet />
                )}
            </td>
            <td>{gender?.name ? genderMapping[gender.name] || gender.name : <NotSet />}</td>
            <td>{postCode?.toUpperCase() || <NotSet />}</td>
            <td>
                <div
                    className={`ucr__patient-list-admission-status
                ucr__patient-list-admission-status${isAdmitted ? '__admitted' : '__discharged'}`}
                >
                    {isAdmitted ? 'Current' : 'Discharged'}
                </div>
            </td>
            <PatientListJobDetails
                job={lastCompletedJob}
                date={lastCompletedJobDate}
                user={lastCompletedJobUser}
                referralPathway={referralPathway}
            />
            <PatientListJobDetails
                job={nextUpcomingJob}
                date={nextUpcomingJobDate}
                referralPathway={referralPathway}
                hideHour={hideHour}
            />
            <td className="patient-list__actions-cell">
                {/* <PatientListQuickActions patient={patient} /> */}
            </td>
        </tr>
    );
};

export default PatientListRow;
