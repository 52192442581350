import { gql } from '@apollo/client';

export const GET_PAY_RATES = gql`
    query GetPayRates(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: PayRatesFilterInput
    ) {
        getPayRates(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    employeeRoleId
                    employeeRole {
                        id
                        name
                    }
                    agencyId
                    agency {
                        id
                        name
                    }
                    createdAt
                    createdBy
                    isArchived
                    isDeleted
                    updatedAt
                    updatedBy
                    createdByPerson {
                        id
                        firstName
                        lastName
                    }
                    updatedByPerson {
                        id
                        firstName
                        lastName
                    }
                    generalPayRates {
                        id
                        code
                        rate
                        startDate
                        endDate
                        payRateId
                        customPayRates {
                            code
                            day
                            endTime
                            generalPayRateId
                            id
                            rate
                            startTime
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const CREATE_PAY_RATE = gql`
    mutation CreatePayRate($data: PayRateEntity!) {
        createPayRate(data: $data) {
            id
            name
            employeeRoleId
            agencyId
            createdAt
            createdBy
            updatedAt
            updatedBy
            isDeleted
            isArchived
            generalPayRates {
                code
                rate
                endDate
                id
                payRateId
                rate
                startDate
                customPayRates {
                    code
                    startTime
                    endTime
                    id
                    day
                    rate
                }
            }
        }
    }
`;

export const CREATE_GENERAL_PAY_RATE = gql`
    mutation CreateGeneralPayRate(
        $payRateId: String!
        $data: GeneralPayRateEntityInput!
        $utcOffset: Int!
    ) {
        createGeneralPayRate(payRateId: $payRateId, data: $data, utcOffset: $utcOffset) {
            id
            name
            employeeRoleId
            createdAt
            createdBy
            isDeleted
            isArchived
            updatedAt
            updatedBy
            generalPayRates {
                id
                code
                rate
                startDate
                endDate
                payRateId
                customPayRates {
                    code
                    day
                    endTime
                    generalPayRateId
                    id
                    rate
                    startTime
                }
            }
        }
    }
`;

export const UPDATE_GENERAL_PAY_RATE = gql`
    mutation UpdateGeneralPayRate(
        $generalPayRateId: String!
        $data: GeneralPayRateEntityInput!
        $utcOffset: Int!
    ) {
        updateGeneralPayRate(
            generalPayRateId: $generalPayRateId
            data: $data
            utcOffset: $utcOffset
        ) {
            id
            name
            employeeRoleId
            createdAt
            createdBy
            isDeleted
            isArchived
            updatedAt
            updatedBy
            generalPayRates {
                id
                code
                rate
                startDate
                endDate
                payRateId
                customPayRates {
                    code
                    day
                    endTime
                    generalPayRateId
                    id
                    rate
                    startTime
                }
            }
        }
    }
`;

export const UPDATE_PAY_RATE = gql`
    mutation UpdatePayRate(
        $payRateId: String!
        $data: [GeneralPayRateEntityInput!]!
        $utcOffset: Int!
    ) {
        updatePayRate(payRateId: $payRateId, data: $data, utcOffset: $utcOffset) {
            id
            name
            employeeRoleId
            createdAt
            createdBy
            isDeleted
            isArchived
            updatedAt
            updatedBy
            generalPayRates {
                id
                code
                rate
                startDate
                endDate
                payRateId
                customPayRates {
                    code
                    day
                    endTime
                    generalPayRateId
                    id
                    rate
                    startTime
                }
            }
        }
    }
`;

export const DELETE_PAY_RATE = gql`
    mutation DeletePayRate($id: String!) {
        deletePayRate(id: $id) {
            id
        }
    }
`;

export const ARCHIVE_PAY_RATE = gql`
    mutation ArchivePayRate($id: String!) {
        archivePayRate(id: $id) {
            id
        }
    }
`;

export const DELETE_LAST_GENERAL_PAY_RATE = gql`
    mutation DeleteLastGeneralPayRate($payRateId: String!) {
        deleteLastGeneralPayRate(payRateId: $payRateId) {
            id
        }
    }
`;

export const GET_PAY_RATE = gql`
    query GetPayRate($id: String!) {
        getPayRateById(id: $id) {
            id
            name
            employeeRoleId
            employeeRole {
                id
                name
            }
            agencyId
            agency {
                id
                name
            }
            createdAt
            createdBy
            isDeleted
            isArchived
            updatedAt
            updatedBy
            generalPayRates {
                id
                code
                rate
                startDate
                endDate
                payRateId
                customPayRates {
                    code
                    day
                    endTime
                    generalPayRateId
                    id
                    rate
                    startTime
                }
            }
        }
    }
`;

export const VALIDATE_PAY_RATE_NAME = gql`
    query isPayRateNameUnique($name: String!) {
        isPayRateNameUnique(name: $name)
    }
`;

export const VALIDATE_DELETE_PAYRATES = gql`
    query ArePayRatesDeletable($ids: [String!]!) {
        arePayRatesDeletable(ids: $ids) {
            id
            name
            isDeletable
        }
    }
`;

export const VALIDATE_ARCHIVE_PAYRATES = gql`
    query ArePayRatesArchivable($ids: [String!]!) {
        arePayRatesArchivable(ids: $ids) {
            id
            name
            isArchivable
        }
    }
`;

export const BULK_DELETE_PAYRATES = gql`
    mutation BulkDeletePayRates($ids: [String!]!) {
        bulkDeletePayRates(ids: $ids) {
            count
        }
    }
`;

export const BULK_ARCHIVE_PAYRATES = gql`
    mutation BulkArchivePayRates($ids: [String!]!) {
        bulkArchivePayRates(ids: $ids) {
            count
        }
    }
`;
