import React from 'react';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { Button } from '@blueprintjs/core';

const PayRatesFooter = ({
    isSubmitting,
    toggleIsEdit,
    isEditing,
    onCancel,
    onDelete,
    onArchive,
    hasDeleteOption,
    hasArchiveOption,
    actionType,
    submitForm,
    onShowFutureDialog,
    formWasEdited,
}: any) => {
    return (
        <>
            {actionType === 'create' ? (
                <CommonModalFooter
                    isSubmitting={isSubmitting}
                    toggleIsEdit={toggleIsEdit}
                    isEditing={isEditing}
                    onCancel={onCancel}
                    onDelete={onDelete}
                    hasDeleteOption={hasDeleteOption}
                />
            ) : (
                <>
                    {!isEditing ? (
                        <div>
                            {hasArchiveOption && (
                                <Button
                                    className="bp5-intent-warning"
                                    large
                                    onClick={onArchive}
                                    icon="archive"
                                    disabled={isSubmitting}
                                    outlined
                                >
                                    Archive
                                </Button>
                            )}

                            <Button
                                className="bp5-intent-danger"
                                large
                                onClick={onDelete}
                                icon="trash"
                                intent="primary"
                                disabled={isSubmitting || !hasArchiveOption}
                                outlined
                            >
                                Delete
                            </Button>

                            <Button
                                large
                                onClick={toggleIsEdit}
                                icon="edit"
                                intent="primary"
                                disabled={isSubmitting}
                                outlined
                            >
                                Edit details
                            </Button>
                        </div>
                    ) : (
                        <>
                            <Button
                                intent="success"
                                large
                                icon={'floppy-disk'}
                                loading={isSubmitting}
                                disabled={!formWasEdited}
                                outlined
                                onClick={submitForm}
                            >
                                Update in real time
                            </Button>

                            <Button
                                large
                                icon="arrow-right"
                                intent="primary"
                                disabled={!formWasEdited || isSubmitting}
                                outlined
                                onClick={onShowFutureDialog}
                            >
                                Update in the future
                            </Button>
                            <Button
                                large
                                onClick={() => {
                                    onCancel();
                                }}
                                icon="cross"
                                outlined
                            >
                                Cancel
                            </Button>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PayRatesFooter;
