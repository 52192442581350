export const SHIFT_TYPES_OPTIONS = [
    { value: '055cf310-4e10-427d-922c-cb58232a5731', label: 'Contracted' },
    { value: '231dcd5b-c894-4a3e-8bfc-2d5cf8eedc54', label: 'Non-contracted' },
    { value: '331dcd5b-c894-4a3e-8bfc-2d5cf8eedc64', label: 'Flexi' },
    { value: '431dcd5b-c894-4a3e-8bfc-2d5cf8eedc74', label: 'Extra' },
    { value: 'f56c7cbb-3040-44a3-998b-9ca5a11e3df0', label: 'Unpaid' },
    { value: '631dcd5b-c894-4a3e-8bfc-2d5cf8eedc94', label: 'Sick' },
];

export const SHIFT_TYPE_CONTRACTED_ID = '055cf310-4e10-427d-922c-cb58232a5731';
export const SHIFT_TYPE_NON_CONTRACTED_ID = '231dcd5b-c894-4a3e-8bfc-2d5cf8eedc54';
export const SHIFT_TYPE_FLEXI_ID = '331dcd5b-c894-4a3e-8bfc-2d5cf8eedc64';
export const SHIFT_TYPE_EXTRA_ID = '431dcd5b-c894-4a3e-8bfc-2d5cf8eedc74';
export const SHIFT_TYPE_BANK_ID = '231dcd5b-c894-4a3e-8bfc-2d5cf8eedc54';
export const SHIFT_TYPE_UNPAID_ID = 'f56c7cbb-3040-44a3-998b-9ca5a11e3df0';
export const SHIFT_TYPE_SICK_ID = '631dcd5b-c894-4a3e-8bfc-2d5cf8eedc94';

export const SHIFT_STATUS_OPTIONS = [
    { value: '0c365b20-2983-4f6d-9a61-599e17c699a8', label: 'Backfilled' },
    { value: '613b1a24-b37a-4097-902b-3527c86e219d', label: 'Standard' },
    { value: '8e7d0407-83a9-4f99-91ba-167362fd8359', label: 'Blocked' },
];

export const SHIFT_TIME = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
];

export const SHIFT_FILL_STATUS = [
    { value: 'Filled', label: 'Filled' },
    { value: 'Unfilled', label: 'Unfilled' },
    { value: 'Requires attention', label: 'Requires attention' },
];

export const SHIFT_STATUS_BACKFILLED_ID = '0c365b20-2983-4f6d-9a61-599e17c699a8';
export const SHIFT_STATUS_STANDARD_ID = '613b1a24-b37a-4097-902b-3527c86e219d';
export const SHIFT_STATUS_BLOCKED_ID = '8e7d0407-83a9-4f99-91ba-167362fd8359';

export const DAYS = [
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Wednesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
    { value: 7, label: 'Sunday' },
];

export const GenderReassignment = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'PreferNotToSay', label: 'Prefer not to say' },
];

export const PAYMENT_STATUS_PLANED = 'ba6e3912-2b19-46dd-bbb7-604fb220d4a9';
