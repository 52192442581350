import { Button, FormGroup, Menu, MenuItem, Popover } from '@blueprintjs/core';
import React, { useCallback } from 'react';

import CommonPagination from '../../../pages/admin/CommonHRRotaPagination';
import Select from 'react-select';
import {
    DOCUMENT_EXPIRES,
    DOCUMENT_STATUSES,
    DOCUMENT_VERIFIED_OPTIONS,
} from '../../../../constants/hrConst';
import { onFilterChange } from '../../../../helpers/common';
import { DictionaryItem } from '../../../../stores/DictionaryStore';

interface DocumentBulkActions {
    onBulkDownload: () => void;
    onBulkVerificationChange: (value: boolean) => void;
    onBulkStatusChange: (value: boolean) => void;
    disableEditing: boolean;
}

interface DocumentsNavigationProps extends DocumentBulkActions {
    loading: boolean;
    addNewDocument: () => void;
    previousPage: () => void;
    nextPage: () => void;
    onFilter: () => void;
    onAddOptionalDocument: (documentId: string) => void;
    pageInfo: {
        totalCount: number;
        page: number;
        hasNextPage: boolean;
        hasPreviousPage: boolean;
    };
    categories: Array<DictionaryItem>;
    isEmployee: boolean;
    optionalDocuments?: Array<any>;
    disableEditing: boolean;
    isEmployeeArchived: boolean;
}

const Content = ({
    onBulkDownload,
    onBulkStatusChange,
    onBulkVerificationChange,
    disableEditing,
}: DocumentBulkActions) => (
    <Menu>
        {!disableEditing && <MenuItem text="Activate" onClick={() => onBulkStatusChange(false)} />}
        {!disableEditing && <MenuItem text="Archive" onClick={() => onBulkStatusChange(true)} />}
        <MenuItem text="Download" onClick={onBulkDownload} />
        {!disableEditing && (
            <MenuItem text="Verify" onClick={() => onBulkVerificationChange(true)} />
        )}
        {!disableEditing && (
            <MenuItem text="Unverify" onClick={() => onBulkVerificationChange(false)} />
        )}
    </Menu>
);
const DocumentsNavigation = ({
    loading,
    addNewDocument,
    previousPage,
    nextPage,
    pageInfo,
    onFilter,
    categories,
    onBulkDownload,
    onBulkStatusChange,
    onBulkVerificationChange,
    isEmployee,
    optionalDocuments,
    onAddOptionalDocument,
    disableEditing,
    isEmployeeArchived,
}: DocumentsNavigationProps) => {
    const onChange = useCallback((name: any) => (el: any) => onFilterChange(name, el, onFilter), [
        onFilter,
    ]);

    return (
        <nav className="common-header">
            {!loading && (
                <>
                    <div className="common-header__actions">
                        <div className="common-header__filters">
                            {!isEmployee && (
                                <FormGroup label="Status" className="common-header__filter">
                                    <Select
                                        placeholder="All"
                                        options={DOCUMENT_STATUSES}
                                        isMulti
                                        onChange={onChange('status')}
                                        className="react-select"
                                    />
                                </FormGroup>
                            )}
                            <FormGroup label="Expiry status" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={DOCUMENT_EXPIRES}
                                    isMulti
                                    onChange={onChange('expiry')}
                                    className="react-select"
                                />
                            </FormGroup>
                            <FormGroup label="Verified status" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={DOCUMENT_VERIFIED_OPTIONS}
                                    isMulti
                                    onChange={onChange('verification')}
                                    className="react-select"
                                />
                            </FormGroup>
                            <FormGroup label="Category" className="common-header__filter">
                                <Select
                                    placeholder="All"
                                    options={categories}
                                    isMulti
                                    onChange={onChange('category')}
                                    className="react-select"
                                />
                            </FormGroup>
                        </div>
                        <CommonPagination
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageInfo={pageInfo}
                            itemsName="documents"
                        />
                    </div>
                    {!isEmployee && (
                        <div>
                            {!disableEditing && (
                                <Button
                                    text="Add"
                                    intent="success"
                                    icon="add"
                                    onClick={addNewDocument}
                                    className="common-header__button"
                                    outlined
                                />
                            )}
                            <Popover
                                enforceFocus={false}
                                placement="bottom-start"
                                interactionKind="click"
                                content={
                                    <Content
                                        onBulkDownload={onBulkDownload}
                                        onBulkStatusChange={onBulkStatusChange}
                                        onBulkVerificationChange={onBulkVerificationChange}
                                        disableEditing={disableEditing}
                                    />
                                }
                                renderTarget={({ isOpen, ref, ...p }) => (
                                    <Button
                                        {...p}
                                        active={isOpen}
                                        /* TODO elementRef removed in blueprintjs5 check consequences*/
                                        /*elementRef={ref as any}*/
                                        intent="primary"
                                        rightIcon="chevron-down"
                                        text="Actions"
                                        outlined
                                    />
                                )}
                            />
                        </div>
                    )}
                    {isEmployee && !isEmployeeArchived && (
                        <div className="common-header__attachDocuments">
                            <Select
                                placeholder="Attach more documents"
                                options={optionalDocuments}
                                value={null}
                                onChange={(e: any) => onAddOptionalDocument(e.value)}
                                className="react-select"
                            />
                        </div>
                    )}
                </>
            )}
        </nav>
    );
};
export default DocumentsNavigation;
