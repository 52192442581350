export const onDaysApiDictionary = {
    Mon: 'MO',
    Tue: 'TU',
    Wed: 'WE',
    Thu: 'TH',
    Fri: 'FR',
    Sat: 'SA',
    Sun: 'SU',
};

export const monthlyRepeatsOftenDictionary = {
    1: 'first',
    2: 'second',
    3: 'third',
    4: 'fourth',
    '-1': 'last',
};

export const monthlyRepeatsDaysDictionary = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
};
