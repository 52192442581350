import { Callout, Button } from '@blueprintjs/core';
import { Hub, Vaccination } from '@doc-abode/data-models';
import React from 'react';
import { ConditionalDisplay } from '../../../../../CondtionalDisplay';
import { VaccinationRoute, VaccinationWithWarnings } from '../../../types';
import { RoutesTable } from '../RoutesTable';
import { Warnings } from '../Warnings';

interface IUpdateRouteAddPatientsProps {
    didError: { message: string; details: string[] } | null;
    filteredRoutes: VaccinationRoute[];
    hubs: Hub[];
    onClick: (id: string) => Promise<void>;
    onRemoveWarningPatients: () => void;
    onSort: () => void;
    patientsToAdd: Vaccination[];
    patientsWithWarnings: VaccinationWithWarnings[];
}
/**
 * this was originally part of UpdateRoute.
 * Removed to make UpdateRoute easier to test, manage and generally see what's going on there.
 */
export function UpdateRouteAddPatients({
    didError,
    filteredRoutes,
    hubs,
    onClick,
    onRemoveWarningPatients,
    onSort,
    patientsToAdd,
    patientsWithWarnings,
}: IUpdateRouteAddPatientsProps) {
    const patientWord = patientsToAdd.length === 1 ? 'patient' : 'patients';

    return (
        <>
            {didError && (
                <Callout intent="danger" className="create-routes__callout">
                    Route generation was unsuccessful. Reported error: {didError.message}
                    {didError.details?.map((detail: string) => (
                        <div key={detail}>{detail}</div>
                    ))}
                </Callout>
            )}
            <Callout intent="primary" className="create-routes__callout">
                Only routes for compatible hub(s) as the selected {patientWord}, and scheduled for
                at least 28 days after any first doses received or at least six months after any
                second doses received by the selected {patientWord} (COVID-19 vaccine only), will be
                available to add the {patientWord} to.
            </Callout>
            <ConditionalDisplay show={patientsWithWarnings.length > 0}>
                <Callout intent="danger" className="create-routes__callout">
                    The following patients have validation warnings, please see below for details.
                    <Warnings warnings={patientsWithWarnings} />
                    <Button
                        text="Remove affected patient(s) from selection"
                        onClick={onRemoveWarningPatients}
                        outlined
                    />
                </Callout>
            </ConditionalDisplay>
            {filteredRoutes.length > 0 ? (
                /* todo: better description of what going on here
                   we have filtered routes (not sure what they are.), so we add patients to them
                */
                <>
                    <p>
                        {patientsToAdd.length} {patientWord} selected.{' '}
                        {patientsToAdd.length > 0 && 'Please select the route to add them to:'}
                    </p>
                    {patientsToAdd.length > 0 && (
                        <RoutesTable
                            jobs={filteredRoutes}
                            handleOnClick={onClick}
                            hubs={hubs}
                            onSort={onSort}
                        />
                    )}
                </>
            ) : (
                <Callout intent="warning">
                    There are no available routes to which the selected patients can be added.
                </Callout>
            )}
        </>
    );
}
