import React, { useCallback, useState } from 'react';
import { Button, Classes, Dialog, FormGroup, Intent } from '@blueprintjs/core';
import Select from 'react-select';
import { inject, observer } from 'mobx-react';
import CustomAsyncSelect from '../common/CustomAsyncSelect';

interface SelectOption {
    label: string;
    value: string;
}

interface FormValues {
    costCentre: SelectOption | null;
    department: SelectOption | null;
}

const SelectDialog = (props: any) => {
    const {
        title = 'Select a department',
        onCancel,
        onSubmit,
        submitText = 'Ok',
        isOpen,
        RootStore: { departmentsStore, costCentresStore },
    } = props;

    const [values, setValues] = useState({} as FormValues);
    const [departmentOptions, setDepartmentOptions] = useState([]);

    const handleCostCentreChange = useCallback(
        async (selected: any) => {
            setValues({ costCentre: selected, department: null });
            setDepartmentOptions([]);
            setDepartmentOptions(
                await departmentsStore.getDepartmentsByCostCentreId(selected.value),
            );
        },
        [departmentsStore],
    );
    const handleDepartmentChange = useCallback(
        async (selected: any) => {
            if (selected) {
                if (!values.costCentre) {
                    const costCentre = await costCentresStore.getCostCentreByDepartmentId(
                        selected.value,
                    );
                    setValues({
                        costCentre: { label: costCentre.name, value: costCentre.id },
                        department: selected,
                    });
                    setDepartmentOptions(
                        await departmentsStore.getDepartmentsByCostCentreId(costCentre.id),
                    );
                }
                setValues((currentValues) => ({ ...currentValues, department: selected }));
            }
        },
        [costCentresStore, departmentsStore, values.costCentre],
    );

    const handleDialogClose = useCallback(() => {
        setValues({ costCentre: null, department: null });
        setDepartmentOptions([]);
        onCancel();
    }, [onCancel]);

    const handleSubmit = useCallback(() => {
        onSubmit(values.costCentre, values.department);
    }, [onSubmit, values]);

    return (
        <Dialog onClose={handleDialogClose} title={title} className="dialog-select" isOpen={isOpen}>
            <div className={Classes.DIALOG_BODY}>
                <p>Select cost centre</p>
                <div>
                    <FormGroup>
                        <CustomAsyncSelect
                            onLoadOptions={costCentresStore.getCostCentresRecords}
                            values={values.costCentre}
                            onChange={handleCostCentreChange}
                            placeholder={'Select...'}
                            labelModifier={'code'}
                            hideArchived={true}
                        />
                    </FormGroup>
                </div>
                <p>Select department</p>
                <div>
                    <FormGroup labelFor="department">
                        <Select
                            options={
                                values.costCentre
                                    ? departmentOptions
                                    : departmentsStore.allDepartmentsActiveOptions
                            }
                            onChange={handleDepartmentChange}
                            name="departmentId"
                            id="departmentId"
                            isLoading={!!values.costCentre && !departmentOptions.length}
                            value={values.department}
                        />
                    </FormGroup>
                </div>
            </div>
            <div className={`${Classes.DIALOG_FOOTER}`}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                        className="dialog-select__button"
                        intent={Intent.NONE}
                        onClick={handleDialogClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="dialog-select__button"
                        type="submit"
                        disabled={!values.costCentre || !values.department}
                        intent={Intent.SUCCESS}
                        onClick={handleSubmit}
                    >
                        {submitText}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default inject('RootStore')(observer(SelectDialog));
