import { gql } from '@apollo/client';

export const GET_FUNDING_POOL_LIST = gql`
    query GetFundingPools(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: FundingPoolFilter
    ) {
        getFundingPools(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    isArchived
                    isDeleted
                    createdAt
                    updatedAt
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedPerson {
                        firstName
                        lastName
                    }
                    rotaShifts {
                        startDate
                        endDate
                        rota {
                            id
                            name
                            startDate
                            endDate
                        }
                    }
                    shiftPatternEntries {
                        shiftPattern {
                            id
                            name
                        }
                    }
                }
            }
            totalCount
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
        }
    }
`;

export const GET_FUNDING_POOL_OPTIONS = gql`
    query GetFundingPoolsOptions(
        $before: String
        $after: String
        $first: Int
        $last: Int
        $orderBy: OrderDirection!
        $field: String!
        $query: String
        $filter: FundingPoolFilter
    ) {
        getFundingPools(
            first: $first
            last: $last
            before: $before
            after: $after
            orderBy: { direction: $orderBy, field: $field }
            query: $query
            filter: $filter
        ) {
            edges {
                node {
                    id
                    name
                    isArchived
                }
            }
        }
    }
`;

export const CREATE_FUNDING_POOL = gql`
    mutation CreateFundingPool($data: FundingPoolEntityInput!) {
        createFundingPool(data: $data) {
            id
        }
    }
`;

export const UPDATE_FUNDING_POOL = gql`
    mutation UpdateFundingPool($id: String!, $data: FundingPoolEntityInput!) {
        updateFundingPool(id: $id, data: $data) {
            id
            name
        }
    }
`;

export const GET_FUNDING_POOL = gql`
    query GetFundingPoolById($id: String!) {
        getFundingPoolById(id: $id) {
            id
            name
            isArchived
            isDeleted
            createdAt
            updatedAt
            createdPerson {
                firstName
                lastName
            }
            updatedPerson {
                firstName
                lastName
            }
            rotaShifts {
                id
                startDate
                endDate
                rota {
                    id
                    name
                    startDate
                    endDate
                    isPublished
                    department {
                        name
                    }
                    costCentre {
                        name
                    }
                }
            }
            shiftPatternEntries {
                weekNumber
                shiftPattern {
                    id
                    name
                    department {
                        name
                    }
                    costCentre {
                        name
                    }
                }
            }
        }
    }
`;

export const VALIDATE_FUNDING_POOL_NAME = gql`
    query isFundingPoolNameUnique($name: String!) {
        isFundingPoolNameUnique(name: $name)
    }
`;

export const ARCHIVE_FUNDING_POOL = gql`
    mutation ArchiveFundingPool($id: String!) {
        archiveFundingPool(id: $id) {
            id
        }
    }
`;

export const BULK_ARCHIVE_FUNDING_POOLS = gql`
    mutation BulkArchiveFundingPools($ids: [String!]!) {
        bulkArchiveFundingPools(ids: $ids) {
            count
        }
    }
`;
