import { get } from 'lodash';
import moment from 'moment';

export const sortDateDescending = (a: any, b: any) => {
    if (moment(a).isBefore(b)) return 1;
    if (moment(a).isAfter(b)) return -1;
    return 0;
};

export const getKeyByValue = (object: any, value: any) => {
    return Object.keys(object).find((key) => object[key] === value);
};

export type sorter<T extends {}> = (a: T, b: T) => number;

function anotherDateSorter(a?: string, b?: string): number {
    const typeA = typeof a === 'string';
    const typeB = typeof b === 'string';

    if (!typeA || !typeB) {
        if (!typeA && !typeB) {
            return 0;
        } else if (!typeA) {
            return -1;
        } else {
            return 1;
        }
    }

    // @ts-ignore
    const dateA = new Date(a).getTime();
    // @ts-ignore
    const dateB = new Date(b).getTime();

    if (dateA < dateB) {
        return -1;
    } else if (dateA > dateB) {
        return 1;
    } else {
        return 0;
    }
}

export function createDateSorter<T extends {}>(key: string): sorter<T> {
    return (a: T, b: T) => {
        const valueA = get(a, key);
        const valueB = get(b, key);

        return anotherDateSorter(valueA, valueB);
    };
}
