import React from 'react';

import { Button } from '@blueprintjs/core';
interface UploadedDocuments {
    fileName: string;
    key: string;
}

interface ViewAbsenceRecordProps {
    person: string;
    personId: string;
    absenceType: string;
    roles: string;
    createdAt: string;
    createdBy: string;
    departments: string;
    updatedAt: string;
    updatedBy: string;
    status: string;
    documents: UploadedDocuments[];
    notes: string;
    isSubmitting: boolean;
    isArchived: boolean;
    changeStatus: (val: string) => void;
    downloadFile: (key: string) => void;
    archiveRecord: () => void;
}

const ViewAbsenceRecord = ({
    person,
    personId,
    absenceType,
    roles,
    createdAt,
    createdBy,
    departments,
    updatedAt,
    updatedBy,
    status,
    documents,
    notes,
    isSubmitting,
    isArchived,
    changeStatus,
    downloadFile,
    archiveRecord,
}: ViewAbsenceRecordProps) => {
    return (
        <>
            <div className="absence absence-view">
                <div className="absence-view-grid">
                    <div className="view-section">
                        <div className="view-group">
                            <h4 className="view-section-title">Person record</h4>
                            <p className="view-section-description primary-color">
                                <a href={`/hr/people/${personId}`} target="_blank" rel="noreferrer">
                                    {person}
                                </a>
                            </p>
                        </div>
                        <div className="view-group">
                            <h4 className="view-section-title">Absence type</h4>
                            <p className="view-section-description primary-color">{absenceType}</p>
                        </div>
                    </div>
                    <div className="view-section">
                        <div className="view-group">
                            <h4 className="view-section-title">Role</h4>
                            <p className="view-section-description">{roles}</p>
                        </div>
                        <div className="view-group">
                            <h4 className="view-section-title">Created</h4>
                            <p className="view-section-description">
                                {`${createdAt}, by ${createdBy}`}
                            </p>
                        </div>
                    </div>
                    <div className="view-section">
                        <div className="view-group">
                            <h4 className="view-section-title">Department</h4>
                            <p className="view-section-description">{departments}</p>
                        </div>
                        <div className="view-group">
                            <h4 className="view-section-title">Last updated</h4>
                            <p className="view-section-description">
                                {updatedBy ? `${updatedAt}, by ${updatedBy}` : ''}
                            </p>
                        </div>
                    </div>
                    <div className="view-section">
                        <div className="view-group">
                            <h4 className="view-section-title">{status}</h4>
                            <p className="view-section-description">
                                {updatedBy
                                    ? `${updatedAt}, by ${updatedBy}`
                                    : `${createdAt}, by ${createdBy}`}
                            </p>
                        </div>
                    </div>
                </div>
                {documents.length ? (
                    <div className="view-section-documents">
                        <h4 className="view-section-title">Documents</h4>
                        <div className="multiple-file-upload view">
                            <div className="list-of-uploads">
                                {documents.map((doc) => (
                                    <div
                                        key={doc.key}
                                        className="uploaded-file"
                                        onClick={() => downloadFile(doc.key)}
                                    >
                                        {doc.fileName}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="view-section-notes">
                    <div className="view-group">
                        <h4 className="view-section-title">Notes</h4>
                        <p className="view-section-description notes-description">{notes}</p>
                    </div>
                </div>
            </div>
            {!isArchived ? (
                <footer className="absence__footer">
                    {(status === 'Rejected' || status === 'Pending') && (
                        <Button
                            large
                            intent="success"
                            className="bp4-intent-success"
                            onClick={() => changeStatus('APPROVE')}
                            type="button"
                            loading={isSubmitting}
                            key="approve"
                        >
                            Approve
                        </Button>
                    )}
                    {(status === 'Approved' || status === 'Pending') && (
                        <Button
                            large
                            type="button"
                            disabled={isSubmitting}
                            intent="danger"
                            key="reject"
                            onClick={() => changeStatus('REJECT')}
                        >
                            Reject
                        </Button>
                    )}
                    <Button
                        large
                        type="button"
                        disabled={isSubmitting}
                        intent="none"
                        className="no-intent"
                        key="archive"
                        onClick={archiveRecord}
                    >
                        Archive
                    </Button>
                </footer>
            ) : null}
        </>
    );
};

export default ViewAbsenceRecord;
