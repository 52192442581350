import classNames from 'classnames';
import moment from 'moment';
import * as React from 'react';

import { Props } from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';

const FORMAT = 'DD MMM YYYY ';
const FORMAT_TIME = 'DD MMM YYYY ';

export const MomentDate: React.FunctionComponent<{
    date: Date;
    format?: string;
    withTime?: boolean;
    onClick?: Function;
}> = ({ date, withTime = false, format = withTime ? FORMAT_TIME : FORMAT, onClick = () => {} }) => {
    const m = moment(date);
    const handleClick = () => {
        onClick();
    };
    if (m.isValid()) {
        return (
            <div className={'rota-builder__date-item'} onClick={handleClick}>
                {m.format(format)}
            </div>
        );
    } else {
        return <div onClick={handleClick}>no date</div>;
    }
};

export const MomentDateRange: React.FunctionComponent<
    { range: DateRange; format?: string; withTime?: boolean; onDateClick?: Function } & Props
> = ({
    className,
    range: [start, end],
    withTime = false,
    format = withTime ? FORMAT_TIME : FORMAT,
    onDateClick,
}) => (
    <div className={classNames('rota-builder__date-range', className)}>
        <MomentDate
            withTime={withTime}
            date={start || new Date()}
            format={format}
            onClick={onDateClick}
        />
        <span className="rota-builder__date-range-divider"> - </span>
        <MomentDate
            withTime={withTime}
            date={end || new Date()}
            format={format}
            onClick={onDateClick}
        />
    </div>
);

export default MomentDateRange;
