import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Alert } from '@blueprintjs/core';

import { PageLoader } from './PageLoader';
import useStores from '../../../hook/useStores';
import { Dialogs, DialogAlerts } from '../../../stores/UCRStore';
import { DndProvider, JobsProvider } from '../../../providers';
import { Main, Left, Right } from '../../pages/ucr/blocks';
import {
    AddVisit,
    AdminTime,
    AbortVisit,
    ReverseVisit,
    EditVisit,
    ExportJobTimings,
    ImportSchedules,
    ResolvePatientAlert,
    ResolveStaffAlert,
    StaffAlertInfo,
    PatientAlertInfo,
} from '../../pages/ucr/forms';
import { Overlay, Dialog, DialogSizes } from '../../v2/overlays';
import ChangeVisitStatus from '../../pages/ucr/forms/ChangeVisitStatus/ChangeVisitStatus';
import ChangeToSingleVisit from '../../pages/ucr/forms/ChangeToSingleVisit/ChangeToSingleVisit';
import { DialogDischarge } from '../../pages/ucr/components/patientView/DischargeDialog';

const UCR: FC = () => {
    const {
        RootStore: {
            ucrStore: {
                openedDialogAlert,
                setOpenedDialog,
                openedDialog,
                alertProps,
                openAlert,
                closeAlert,
                setAbortVisit,
                setReverseVisit,
                setLocalPatientData,
                setFollowUpAdminTimeData,
                setSelectedPatientData,
            },
            configStore: { isFeatureEnabled },
        },
    } = useStores() as { RootStore: any };

    const isEditAdminTime = openedDialog === Dialogs.EDIT_ADMINISTRATIVE_TIME;

    const onCloseDialog = () => {
        setOpenedDialog(Dialogs.NONE);
    };
    const onCloseVisitDialog = () => {
        onCloseDialog();
        closeAlert();
        setLocalPatientData();
        setFollowUpAdminTimeData({});
        setSelectedPatientData();
    };
    const onCloseVisit = () =>
        openedDialogAlert === DialogAlerts.SAVE_VISIT ||
        openedDialogAlert === DialogAlerts.SAVE_ADMINISTRATIVE_TIME
            ? openAlert({
                  message: `The ${
                      openedDialog === Dialogs.ADMINISTRATIVE_TIME ? 'administrative time' : 'visit'
                  } won’t be saved, are you sure you want to exit?`,
                  onConfirm: onCloseVisitDialog,
              })
            : onCloseVisitDialog();

    const onCloseAbortVisit = () => {
        setAbortVisit(null);
        onCloseDialog();
    };

    const onCloseReverseVisit = () => {
        setReverseVisit(null);
        onCloseDialog();
    };

    if (!isFeatureEnabled('ucr')) {
        return <Redirect to="/page-not-found" />;
    }

    return (
        <DndProvider>
            <JobsProvider>
                <section className="ucr__section">
                    <Left />
                    <Main />
                    <Right />

                    <Overlay isShow={openedDialog !== Dialogs.NONE} />
                    <Dialog
                        title={
                            openedDialog === Dialogs.ADD_NEW_VISIT
                                ? 'Add a new visit'
                                : openedDialog === Dialogs.EDIT_VISIT
                                ? 'Edit visit'
                                : ''
                        }
                        isShow={
                            openedDialog === Dialogs.ADD_NEW_VISIT ||
                            openedDialog === Dialogs.EDIT_VISIT
                        }
                        closeEvent={onCloseVisit}
                        renderChildrenOnlyWhenVisible
                    >
                        {openedDialog === Dialogs.ADD_NEW_VISIT ? (
                            <AddVisit />
                        ) : openedDialog === Dialogs.EDIT_VISIT ? (
                            <EditVisit />
                        ) : undefined}
                    </Dialog>

                    <Dialog
                        title={`${isEditAdminTime ? 'Edit' : 'Add new'} administrative time`}
                        isShow={openedDialog === Dialogs.ADMINISTRATIVE_TIME || isEditAdminTime}
                        closeEvent={onCloseVisit}
                        renderChildrenOnlyWhenVisible
                    >
                        <AdminTime isEdit={openedDialog === Dialogs.EDIT_ADMINISTRATIVE_TIME} />
                    </Dialog>

                    <Dialog
                        title="Export job timings"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.EXPORT_JOB_TIMINGS}
                        closeEvent={onCloseDialog}
                        renderChildrenOnlyWhenVisible
                    >
                        <ExportJobTimings onClose={onCloseDialog} />
                    </Dialog>

                    <Dialog
                        title="Import schedules"
                        isShow={openedDialog === Dialogs.IMPORT_SCHEDULES}
                        closeEvent={onCloseDialog}
                        renderChildrenOnlyWhenVisible
                    >
                        <ImportSchedules onClose={onCloseDialog} />
                    </Dialog>

                    <Dialog
                        title="Abort visit"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.ABORT_VISIT}
                        closeEvent={onCloseAbortVisit}
                        renderChildrenOnlyWhenVisible
                    >
                        <AbortVisit onClose={onCloseAbortVisit} />
                    </Dialog>
                    <Dialog
                        title="Change to single staff"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.CHANGE_DOUBLE_UP_TO_SINGLE}
                        closeEvent={onCloseDialog}
                        renderChildrenOnlyWhenVisible
                    >
                        <ChangeToSingleVisit onClose={onCloseDialog} />
                    </Dialog>
                    <Dialog
                        // used in cypress test end-to-end tests
                        // cypress/support/page_object/components/changeVisit.ts
                        title="Change job status"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.CHANGE_VISIT_STATUS}
                        closeEvent={onCloseDialog}
                        renderChildrenOnlyWhenVisible
                    >
                        <ChangeVisitStatus onClose={onCloseDialog} />
                    </Dialog>
                    <Dialog
                        title="Reverse cancellation"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.REVERSE_VISIT}
                        closeEvent={onCloseReverseVisit}
                        renderChildrenOnlyWhenVisible
                    >
                        <ReverseVisit onClose={onCloseReverseVisit} />
                    </Dialog>
                    <Dialog
                        title="Discharge patient"
                        titleClassName="v2__dialog-title--blue"
                        size={DialogSizes.SMALL}
                        isShow={openedDialog === Dialogs.DISCHARGE_PATIENT}
                        closeEvent={onCloseDialog}
                        renderChildrenOnlyWhenVisible
                    >
                        <DialogDischarge onClose={onCloseDialog} />
                    </Dialog>
                    <StaffAlertInfo />
                    <ResolveStaffAlert />
                    <PatientAlertInfo />
                    <ResolvePatientAlert />

                    <Alert
                        isOpen={alertProps.isOpen}
                        onConfirm={alertProps.onConfirm}
                        onCancel={closeAlert}
                        cancelButtonText="Cancel"
                        confirmButtonText="Confirm"
                        icon="warning-sign"
                        intent="danger"
                    >
                        {alertProps.heading && (
                            <h4 style={{ marginTop: 0 }}>{alertProps.heading}</h4>
                        )}
                        {typeof alertProps.message === 'string' ? (
                            <p>{alertProps.message}</p>
                        ) : (
                            alertProps.message.map((message: any, i: number) => (
                                <p key={`urc-alert-message++${i}`}>{message}</p>
                            ))
                        )}
                    </Alert>

                    <PageLoader />
                </section>
            </JobsProvider>
        </DndProvider>
    );
};

export default observer(UCR);
