import { gql } from '@apollo/client';

export const GET_DEPARTMENT_ASSIGNMENTS = gql`
    query GetEmployeeDepartments(
        $filter: EmployeeDepartmentFilter
        $orderBy: OrderDirection!
        $field: String!
    ) {
        getEmployeeDepartments(filter: $filter, orderBy: { direction: $orderBy, field: $field }) {
            edges {
                node {
                    id
                    startDateOfAssignment
                    endDateOfAssignment
                    department {
                        name
                        costCentre {
                            name
                        }
                    }
                    payRates {
                        payRate {
                            name
                        }
                    }
                    createdAt
                    createdPerson {
                        firstName
                        lastName
                    }
                    updatedAt
                    updatedPerson {
                        firstName
                        lastName
                    }
                    isArchived
                }
            }
        }
    }
`;

export const GET_DEPARTMENT_ASSIGNMENT_BY_ID = gql`
    query GetEmployeeDepartmentById($id: String!) {
        getEmployeeDepartmentById(id: $id) {
            id
            jobTitle
            employeeRecordId
            startDateOfAssignment
            endDateOfAssignment
            departmentId
            isArchived
            payRates {
                payRateId
                startDate
                endDate
                payRate {
                    name
                }
            }
            locations {
                location {
                    id
                    name
                }
            }
            isDepartmentEditable
            department {
                id
                name
                costCode
                costCentre {
                    name
                    id
                    code
                }
            }
            reasonForEnding
            comments
        }
    }
`;

export const CREATE_DEPARTMENT_ASSIGNMENT = gql`
    mutation CreateEmployeeDepartment($data: EmployeeDepartmentInput!) {
        createEmployeeDepartment(data: $data) {
            id
            jobTitle
            locations {
                location {
                    name
                    id
                }
            }
            employeeRecordId
            startDateOfAssignment
            endDateOfAssignment
            departmentId
            payRates {
                payRateId
                startDate
            }
        }
    }
`;

export const UPDATE_DEPARTMENT_ASSIGNMENTS = gql`
    mutation UpdateEmployeeDepartment($id: String!, $data: EmployeeDepartmentInput!) {
        updateEmployeeDepartment(id: $id, data: $data) {
            id
            jobTitle
            employeeRecordId
            locations {
                location {
                    id
                    name
                }
            }
            startDateOfAssignment
            endDateOfAssignment
            departmentId
            payRates {
                payRateId
                startDate
                endDate
            }
        }
    }
`;

export const ARCHIVE_DEPARTMENT_ASSIGNMENT = gql`
    mutation ArchiveEmployeeDepartment($id: String!) {
        archiveEmployeeDepartment(id: $id) {
            id
        }
    }
`;

export const DEPARTMENT_ASSIGNMENT_VALID = gql`
    query AreEmployeeDepartmentDatesValid($data: EmployeeDepartmentValidInput!) {
        areEmployeeDepartmentDatesValid(data: $data) {
            isValid
            conflictingEmployeeDepartment {
                department {
                    name
                    costCode
                    costCentre {
                        name
                        code
                    }
                }
                startDateOfAssignment
                endDateOfAssignment
            }
        }
    }
`;
