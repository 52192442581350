import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Modal from '../../modal/Modal';
import SpecialitiesSummary from './SpecialitiesSummary';
import { Formik, Form } from 'formik';
import CommonModalFooter from '../../../common/CommonModalFooter';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../common/ConfirmationAlert';

const SpecialitiesDetails = inject('RootStore')(
    observer(
        class SpecialitiesDetails extends Component {
            state = {
                isEdit: false,
                hasDeleteOption: true,
                initialData: {
                    id: '',
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                },
                isLoaded: false,
                showAlert: false,
            };

            componentDidMount() {
                if (this.props.actionType === 'create') {
                    this.setState({ isEdit: true });
                }
                if (this.props.actionType === 'edit') {
                    this._updateSpeciality();
                }
            }

            _updateSpeciality = async () => {
                const { id } = this.props.match.params;
                const speciality = await this.props.RootStore.specialitiesStore.getSpecialityById(
                    id,
                );
                if (speciality) {
                    this.setState({
                        initialData: { ...speciality },
                        isEdit: false,
                        isLoaded: true,
                    });
                }
            };

            _handleCloseClick = () => {
                if (this.props.actionType === 'create') {
                    return this.props.history.replace('/rota/specialities');
                }
                return this.props.history.goBack();
            };

            _toggleIsEdit = () => {
                this.setState((prevState) => {
                    return { isEdit: !prevState.isEdit };
                });
            };
            _handleOnCancel = () => {
                if (this.props.actionType === 'edit') {
                    return this._toggleIsEdit();
                }
                this.props.history.replace('/rota/specialities');
            };

            _handleAlertClick = () => {
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });
            };

            _handleOnDelete = async () => {
                const { id } = this.props.match.params;

                const speciality = this.props.RootStore.specialitiesStore.gridSpecialities.find(
                    (speciality) => speciality.id === id,
                );

                speciality &&
                    (await this.props.RootStore.specialitiesStore.deleteSpeciality(speciality));
                this.props.history.replace('/rota/specialities');
            };

            render() {
                const {
                    RootStore: { specialitiesStore },
                } = this.props;

                if (this.props.actionType === 'edit' && !this.state.isLoaded) {
                    return null;
                }

                const title =
                    this.props.actionType === 'create'
                        ? 'Add speciality record'
                        : `${this.state.initialData.name}`;
                return (
                    <Formik
                        initialValues={{
                            ...this.state.initialData,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            this.props.actionType === 'create'
                                ? await specialitiesStore.createSpeciality(values)
                                : await specialitiesStore.updateSpeciality(values);
                            setSubmitting(false);
                            if (this.props.actionType === 'create') {
                                this.props.history.replace('/rota/specialities');
                            }
                            if (this.props.actionType === 'edit') {
                                this._updateSpeciality();
                            }
                        }}
                    >
                        {({
                            isSubmitting,
                            values,
                            handleChange,
                            setFieldValue,
                            errors,
                            touched,
                        }) => (
                            <Form className="common-form">
                                <Modal
                                    title={title}
                                    onClose={this._handleCloseClick}
                                    footer={
                                        <CommonModalFooter
                                            isSubmitting={isSubmitting}
                                            toggleIsEdit={this._toggleIsEdit}
                                            isEditing={this.state.isEdit}
                                            onCancel={this._handleOnCancel}
                                            onDelete={this._handleAlertClick}
                                            hasDeleteOption={this.state.hasDeleteOption}
                                        />
                                    }
                                    shadow
                                >
                                    <SpecialitiesSummary
                                        speciality={this.state.initialData}
                                        editOrCreateMode={this.state.isEdit}
                                        values={values}
                                        handleChange={handleChange}
                                        setFieldValue={setFieldValue}
                                        actionType={this.props.actionType}
                                        errors={errors}
                                        touched={touched}
                                    />
                                </Modal>
                                <ConfirmationAlert
                                    confirmButtonText="Delete"
                                    onConfirm={this._handleOnDelete}
                                    onCancel={this._handleAlertClick}
                                    isOpen={this.state.showAlert}
                                    title="Confirm deletion!"
                                    description="Are you sure you want to delete this speciality? You won't be
                                        able to recover it."
                                />
                            </Form>
                        )}
                    </Formik>
                );
            }
        },
    ),
);

export default SpecialitiesDetails;
