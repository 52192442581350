import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';

const PAGE_SIZE = 10;

interface selectProps {
    onLoadOptions: any;
    onChange: (val: any) => void;
    values: any;
}

const CreatableAsyncSelect = ({ onLoadOptions, onChange, values }: selectProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [items, setItems] = React.useState([]);
    const [query, setQuery] = React.useState(null);
    const [loadMore, setLoadMore] = React.useState(false);

    React.useEffect(() => {
        setLoadMore(true);
    }, []);

    React.useEffect(() => {
        async function getData() {
            if (loadMore) {
                setIsLoading(true);
                const lastItem = items.length === 0 ? null : (items[items.length - 1] as any).value;

                const { data } = await onLoadOptions(PAGE_SIZE, lastItem, query);
                const { edges } = data[Object.keys(data)[0]];
                const newItems = edges.map((item: any) => ({
                    value: item.node.id,
                    label: item.node.name,
                }));

                setItems([...items, ...newItems] as any);
                setIsLoading(false);
                setLoadMore(false);
            }
        }

        getData();
    }, [items, loadMore, onLoadOptions, query]);

    React.useEffect(() => {
        if (!query) return;
        setItems([]);
        setLoadMore(true);
    }, [query]);

    return (
        <div>
            <AsyncCreatableSelect
                cacheOptions
                isLoading={isLoading}
                onMenuScrollToBottom={() => {
                    setLoadMore(true);
                }}
                onInputChange={(val: any) => {
                    setQuery(val);
                }}
                onChange={(values) => {
                    setQuery(null);
                    setItems([]);
                    setLoadMore(true);

                    if (onChange) onChange(values);
                }}
                onMenuClose={() => {
                    if (query && (query as any).length > 0) {
                        setItems([]);
                        setQuery(null);
                        setLoadMore(true);
                    }
                }}
                defaultOptions={items}
                defaultValue={{
                    value: values?.id,
                    label: values?.name,
                }}
                value={items.find((item: any) => item.value === values)}
                placeholder={''}
            />
        </div>
    );
};

export default CreatableAsyncSelect;
