import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Redirect } from 'react-router-dom';

import LocationItem from './LocationItem';
import Header from './Header';
import Navigation from './Navigation';
import LocationsRelatedDialog from './LocationsRelatedDialog';
import Loader from '../../../../modules/helpers/Loader';
import LocationDetails from './LocationDetails';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import { Alert } from '@blueprintjs/core';

const ManageLocations = inject('RootStore')(
    observer(
        class ManageLocations extends Component {
            state = {
                showAlert: false,
                showOnlyRelatedEntitiesAlert: false,
                showRelatedAlert: false,
                notAchievableLocations: [],
                achievableLocations: [],
            };

            async componentDidMount() {
                if (!this.props.RootStore.locationsStore.allLocations.length) {
                    await this.props.RootStore.locationsStore.getAllLocations();
                }
            }

            _handleItemClick = (locationId, event) => {
                if (event.target.tagName === 'TD') {
                    this.props.history.push(`/hr/locations/${locationId}`);
                }
            };

            _handleAddNew = () => {
                this.props.history.replace(`/hr/locations/new`);
            };

            _handleToggleSelectAll = (event) => {
                const { selectAll, deselectAll } = this.props.RootStore.locationsStore;

                if (event.target.checked) {
                    selectAll();
                } else {
                    deselectAll();
                }
            };

            _handleToggleSelected = (event) => {
                const { select, deselect } = this.props.RootStore.locationsStore;
                const {
                    target: { id, checked },
                } = event;

                if (checked) {
                    select(id);
                } else {
                    deselect(id);
                }
            };

            _handleToggleAlert = () => {
                let { selected } = this.props.RootStore.locationsStore;
                selected.length &&
                    this.setState((prevState) => {
                        return { showAlert: !prevState.showAlert };
                    });
            };

            _handleEditItem = () => {
                let { selected } = this.props.RootStore.locationsStore;
                this.props.history.push(`/hr/locations/${selected[0]}`, {
                    actionType: 'edit',
                    editModeOpenedFromViewList: true,
                });
            };

            _handleArchiveItem = async () => {
                const { selected, isLocationArchivable } = this.props.RootStore.locationsStore;
                const selectedIds = selected.map((item) => item);
                const locationsList = await isLocationArchivable(selectedIds);

                if (locationsList.every((item) => item.isArchivable)) {
                    this.setState({ achievableLocations: locationsList, showAlert: true });
                } else if (locationsList.some((item) => item.isArchivable)) {
                    const canBeArchived = locationsList.filter((item) => item.isArchivable);
                    const notArchivable = locationsList.filter((item) => !item.isArchivable);
                    this.setState({
                        notAchievableLocations: notArchivable,
                        achievableLocations: canBeArchived,
                        showRelatedAlert: true,
                    });
                } else {
                    this.setState({ showOnlyRelatedEntitiesAlert: true });
                }
            };

            _archiveItems = async () => {
                const { bulkArchiveLocations, deselectAll } = this.props.RootStore.locationsStore;
                const locationsIds = this.state.achievableLocations.map((location) => location.id);
                bulkArchiveLocations(locationsIds);
                this._closeAlert();
                deselectAll();
            };

            _handleDeleteItem = async () => {
                let { selected } = this.props.RootStore.locationsStore;
                this.setState((prevState) => {
                    return { showAlert: !prevState.showAlert };
                });

                await selected.forEach(
                    (id) => id && this.props.RootStore.locationsStore.deleteLocation(id),
                );
            };

            _closeAlert = () => {
                this.setState({
                    showAlert: false,
                    showOnlyRelatedEntitiesAlert: false,
                    showRelatedAlert: false,
                });
            };

            render() {
                const {
                    RootStore: {
                        locationsStore: {
                            gridLocations,
                            onSort,
                            allSelected,
                            pageInfo,
                            nextPage,
                            previousPage,
                            onFilter,
                            onSearch,
                            loaded,
                            selected,
                        },
                        locationsStore,
                        dictionaryStore,
                        configStore: { isFeatureEnabled },
                    },
                } = this.props;

                if (!isFeatureEnabled('hr')) {
                    return <Redirect to="/page-not-found" />;
                }

                const { id } = this.props.match?.params ?? {};
                const actionType = this.props.actionType === 'create' ? 'create' : 'edit';

                return (
                    <>
                        {!loaded && <Loader />}
                        <main className="common-layout__main">
                            <Navigation
                                onFilter={onFilter}
                                onSearch={onSearch}
                                pageInfo={pageInfo}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                loading={false}
                                addNew={this._handleAddNew}
                                editItem={this._handleEditItem}
                                archiveItems={this._handleArchiveItem}
                                allLocations={locationsStore.allLocations}
                                selected={selected}
                            />
                            <table className="bp5-html-table bp5-interactive common-table">
                                <Header
                                    data={gridLocations}
                                    onSort={onSort}
                                    isFrontendSort={false}
                                    onToggleSelectAll={this._handleToggleSelectAll}
                                    checked={allSelected}
                                />
                                <tbody>
                                    {gridLocations.map((item) => (
                                        <LocationItem
                                            {...item}
                                            onClick={this._handleItemClick}
                                            key={item.id}
                                            onToggle={this._handleToggleSelected}
                                            dictionaryStore={dictionaryStore}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </main>
                        {(id || this.props.actionType === 'create') && (
                            <div className="common-layout__modal">
                                <LocationDetails {...this.props} actionType={actionType} />
                            </div>
                        )}
                        <ConfirmationAlert
                            confirmButtonText="Archive"
                            onConfirm={this._archiveItems}
                            onCancel={this._closeAlert}
                            isOpen={this.state.showAlert}
                            title="Confirm archiving!"
                            description="Are you sure you want to archive the selected location(s)? This action is irreversible"
                        />
                        <LocationsRelatedDialog
                            isOpen={this.state.showRelatedAlert}
                            onClose={this._closeAlert}
                            notAchievableLocations={this.state.notAchievableLocations}
                            achievableLocations={this.state.achievableLocations}
                            onArchiveNotRelated={this._archiveItems}
                        />
                        <Alert
                            confirmButtonText="Ok"
                            isOpen={this.state.showOnlyRelatedEntitiesAlert}
                            onClose={this._closeAlert}
                        >
                            <p>
                                The selected location(s) cannot be archived because of being
                                selected in future rota shifts and/or shift pattern shifts, and/or
                                departments. Remove location(s) from related entities to archive
                            </p>
                        </Alert>
                    </>
                );
            }
        },
    ),
);

export default ManageLocations;
