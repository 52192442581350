import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';

import {
    CREATE_FUNDING_POOL,
    UPDATE_FUNDING_POOL,
    GET_FUNDING_POOL,
    GET_FUNDING_POOL_LIST,
    VALIDATE_FUNDING_POOL_NAME,
    ARCHIVE_FUNDING_POOL,
    BULK_ARCHIVE_FUNDING_POOLS,
    GET_FUNDING_POOL_OPTIONS,
} from '../graphql/queries/fundingPools';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore, SortInfo } from './PagedStore';
import moment from 'moment';

import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { GetFundingPools_getFundingPools_edges_node } from '../__generated__/GetFundingPools';

export interface FundingPool extends GetFundingPools_getFundingPools_edges_node {
    selected?: boolean;
    relatedRotas: any[];
    relatedShiftPatterns: any[];
}

class FundingPoolStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            fundingPools: observable,
            allFundingPools: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridFundingPools: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getFundingPools: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    fundingPools: any[] = [];
    allFundingPools: any[] = [];
    sortFn: (a: any, b: any) => number = () => 0;
    fetchInterval: NodeJS.Timeout | null = null;
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
        this.startTimer();
    };

    normalizeFundingPool = (fundingPool: FundingPool) => {
        return {
            id: fundingPool.id,
            selected: this.selected.includes(fundingPool.id),
            name: fundingPool.name,
            createdAt: fundingPool.createdAt,
            updatedAt: fundingPool.updatedAt,
            createdPerson: fundingPool.createdPerson,
            updatedPerson: fundingPool.updatedPerson,
            isArchived: fundingPool.isArchived,
            isDeleted: fundingPool.isDeleted,
            rotaShifts: fundingPool.rotaShifts,
            shiftPatternEntries: fundingPool.shiftPatternEntries,
            relatedRotas: fundingPool.rotaShifts
                .map((shift) => ({
                    ...shift?.rota,
                    startDate: moment(shift?.rota?.startDate).format('DD MMM YY'),
                    endDate: moment(shift?.rota?.endDate).format('DD MMM YY'),
                }))
                .filter((value, index, self) => self.findIndex((t) => t.id === value.id) === index)
                .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 1)),
            relatedShiftPatterns: Object.values(
                fundingPool.shiftPatternEntries
                    .map((shift: any) => ({ ...shift!.shiftPattern, weekNumber: shift.weekNumber }))
                    .reduce((acc, shift: any) => {
                        if (acc[shift.id]) {
                            const { shifts } = acc[shift.id];
                            const weeks = [Math.max(shift.weekNumber, acc[shift.id].weekNumber)];
                            acc[shift.id] = {
                                ...shift,
                                weeks,
                                shifts: shifts + 1,
                            };
                        } else {
                            acc[shift.id] = {
                                ...shift,
                                shifts: 1,
                                weeks: [shift.weekNumber],
                            };
                        }
                        return acc;
                    }, {} as any),
            ).sort((a: any, b: any) => a.name.localeCompare(b.name)),
        };
    };

    refresh = async () => {
        runInAction(this.reset);
        await this.getFundingPools();
    };

    startTimer() {
        if (!this.fetchInterval) {
            this.getFundingPools();
            this.fetchInterval = setInterval(() => this.getFundingPools(), 60000);
        }
    }

    deselectAll = () => {
        this.selected = [];
    };

    get gridFundingPools() {
        const fundingPools = this.fundingPools.map(this.normalizeFundingPool);
        if (this.sortFn) {
            fundingPools.sort(this.sortFn);
        }
        return fundingPools;
    }

    get allSelected() {
        return this.gridFundingPools.every((r) => this.selected.includes(r.id));
    }

    async createFundingPool(fundingPool: any) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_FUNDING_POOL,
                variables: {
                    data: {
                        name: fundingPool.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Funding pool created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem creating funding pool record. Please try again or contact support@docabode.com for help',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateFundingPool(fundingPool: any) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_FUNDING_POOL,
                variables: {
                    id: fundingPool.id,
                    data: {
                        name: fundingPool.name,
                    },
                },
            });
            AppToaster.show({
                message: 'Funding pool updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem updating funding pool record. Please try again or contact <a href="mailto:support@docabode.com">support@docabode.com</a> for help.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (fundingPoolId: string) => {
        if (!this.selected.includes(fundingPoolId)) {
            this.selected.push(fundingPoolId);
        }
    };

    deselect = (fundingPoolId: string) => {
        const index = this.selected.indexOf(fundingPoolId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getFundingPools();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getFundingPools();
    };

    onSort = (sortInfo: SortInfo) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo();
        });
        await this.getFundingPools();
    };

    getFundingPools = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getFundingPools: data },
        } = await this.apolloClient.query({
            query: GET_FUNDING_POOL_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.fundingPools = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getAllFundingPools = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getFundingPools: data },
        } = await this.apolloClient.query({
            query: GET_FUNDING_POOL_OPTIONS,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
            },
        });

        runInAction(() => {
            this.allFundingPools = data.edges.map((x: any) => x.node);
        });
    };

    getFundingPoolsOptions = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getFundingPools: data },
        } = await this.apolloClient.query({
            query: GET_FUNDING_POOL_OPTIONS,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? '',
            },
        });

        return data.edges.map((x: any) => ({
            label: x.node.name,
            value: x.node.id,
            isArchived: x.node.isArchived,
        }));
    };

    getFundingPoolById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return this.dispose();
        }
        const {
            data: { getFundingPoolById: data },
        } = await this.apolloClient.query({
            query: GET_FUNDING_POOL,
            variables: {
                id,
            },
        });

        return this.normalizeFundingPool(data);
    };

    dispose() {
        if (this.fetchInterval) {
            clearTimeout(this.fetchInterval);
            this.fetchInterval = null;
        }
    }

    selectAll = () => {
        runInAction(() => {
            this.gridFundingPools
                .filter((item: any) => !item.isArchived)
                .forEach((fundingPool) => {
                    this.select(fundingPool.id);
                });
        });
    };

    async validateName(name: string) {
        return await this.apolloClient.query({
            query: VALIDATE_FUNDING_POOL_NAME,
            variables: {
                name,
            },
        });
    }

    async archiveFundingPool(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_FUNDING_POOL,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Funding pool archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving funding pool record. Please try again or contact <a href="mailto:support@docabode.com">support@docabode.com</a> for help',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async bulkArchiveFundingPools(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_FUNDING_POOLS,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'All selected funding pools archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving selected funding pools. Please try again or contact <a href="mailto:support@docabode.com">support@docabode.com</a> for help.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default FundingPoolStore;
