import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Formik } from 'formik';
import { Button } from '@blueprintjs/core';

import ViewAbsenceRecord from './ViewAbsenceRecord';
import AbsenceForm from './AbsenceForm';
import { validationSchema } from './validation';
import ConfirmationAlert from '../../../../common/ConfirmationAlert';
import { downloadFile } from '../../../../../api/hrRotaApi';
import moment from 'moment';
import { RouterPrompt } from '../../../../common/RouterPrompt';
import AppToaster from '../../../../modules/helpers/Toaster';
import { isEqual } from 'lodash';
import { PERSON_ACTIVE_STATUS, PERSON_SUSPENDED_STATUS } from '../../../../../constants/hrConst';
import Loader from '../../../../modules/helpers/Loader';

const AbsenceManagement = inject('RootStore')(
    observer(
        class AbsenceManagement extends Component<{
            personProfileId?: string;
            absenceRecordId?: string;
            actionType?: boolean;
            history?: any;
            closeAbsenceRecordFromProfile?: () => void;
        }> {
            state = {
                isEdit: false,
                initialData: {
                    id: '',
                    name: '',
                    personId: '',
                    roles: '',
                    departments: '',
                    reasonId: '',
                    startDate: undefined,
                    endDate: undefined,
                    startTime: '00:00',
                    endTime: '23:59',
                    startDateTime: undefined,
                    endDateTime: undefined,
                    createdAt: '',
                    createdAtInsideView: '',
                    createdBy: '',
                    updatedAtInsideView: '',
                    updatedBy: '',
                    statusId: '',
                    notes: '',
                    documents: [],
                    isArchived: false,
                    absenceType: '',
                },
                isLoaded: false,
                isOpenTable: false,
                showAlert: false,
                showUpdateAlert: false,
                showArchiveAlert: false,
                updateStatusTo: '',
                formWasEdited: false,
                newForm: true,
                personProfilePage: false,
                actionType: '',
            };

            async componentDidMount() {
                const personId = (this.props as any).personProfileId;
                if ((this.props as any).actionType === 'create') {
                    /* check if the absence record was opened from the Person profile, 
                        if that is the case update the needed information */
                    if (personId) {
                        const { peopleStore } = (this.props as any).RootStore;
                        const person = await peopleStore.getPersonById(personId);
                        const name = `${person.firstName} ${person.lastName}`;
                        this.setState((prev: any) => {
                            return {
                                ...prev,
                                initialData: {
                                    ...prev.initialData,
                                    personId: personId,
                                    name: name,
                                },
                                personProfilePage: true,
                            };
                        });
                    }
                    this.setState({ isEdit: true, actionType: 'create', isLoaded: true });
                } else {
                    // case when we open view form
                    const id =
                        (this.props as any).absenceRecordId || (this.props as any).match?.params.id;
                    if (!id) return;
                    const absence = await (this
                        .props as any).RootStore.absencesStore.getAbsenceById(id);
                    if (absence) {
                        this.setState({
                            initialData: { ...absence },
                            isLoaded: true,
                            newForm: false,
                            actionType: 'edit',
                            personProfilePage: personId ? true : false,
                        });
                    }
                }
            }

            _toggleIsEdit = () => {
                this.setState((prevState: any) => {
                    return { isEdit: !prevState.isEdit };
                });
            };

            _updateStateAfterChanges = async (recordId?: string) => {
                /* we check if the absence recordId was passed,
                    in case of newly created record */
                const id = recordId ? recordId : this.state.initialData.id;
                const absence = await (this.props as any).RootStore.absencesStore.getAbsenceById(
                    id,
                );
                if (absence) {
                    this.setState({
                        initialData: { ...absence },
                        isEdit: false,
                        formWasEdited: false,
                        actionType: 'edit',
                    });
                }
            };

            _showConfirmationPopUp = async (status: string) => {
                this.setState({ showAlert: true, updateStatusTo: status });
            };

            _handleCloseAlert = () => {
                this.setState({
                    showAlert: false,
                    showUpdateAlert: false,
                    showArchiveAlert: false,
                    updateStatusTo: '',
                });
            };

            _updateStatus = async () => {
                const {
                    updateStatusTo,
                    initialData: { id },
                } = this.state;
                const { absencesStore } = (this.props as any).RootStore;
                if (updateStatusTo === 'APPROVE') {
                    await absencesStore.approveAbsence(id);
                }
                if (updateStatusTo === 'REJECT') {
                    await absencesStore.rejectAbsence(id);
                }
                this.setState({ showAlert: false, updateStatusTo: '' });
                this._updateStateAfterChanges();
            };

            _handleDocumentDownload = async (key: string) => {
                const { org } = (this.props as any).RootStore.configStore;
                const { url } = await downloadFile(key, org);
                window.open(url);
            };

            _removeEditStatusForm = () => {
                this.setState({
                    formWasEdited: false,
                });
            };

            // check if values in form have been edited and in that case update "formWasEdited" flag
            _editStatusForm = async (values: any) => {
                await this.setState(() => {
                    return { formWasEdited: !isEqual(this.state.initialData, values) };
                });
            };

            _handleOnCancelConfirm = async (replacePath: string) => {
                this._removeEditStatusForm();
                if (!(this.props as any).createActionType) {
                    (this as any).formik?.resetForm({ values: this.state.initialData });
                }
                AppToaster.show({
                    message: 'Editing has been cancelled. The changes have not been saved',
                    intent: 'success',
                });
                // check if the record is open in person profile, and the user wants just to close it
                if (replacePath === `/hr/people/${(this.props as any).personProfileId}/absence`) {
                    (this.props as any).closeAbsenceRecordFromProfile();
                } else if (replacePath.includes('/hr/absence-management')) {
                    (this.props as any).history.replace('/hr/absence-management');
                } else {
                    // case when user clicked on some other link
                    (this.props as any).history.replace(
                        replacePath ? replacePath : '/hr/absence-management',
                    );
                }
            };

            _loadPersonsOptions = async (inputValue: string) => {
                if (inputValue && inputValue.length >= 3) {
                    return await (this
                        .props as any).RootStore.staffStore.loadPersonsOptions(inputValue, [
                        PERSON_ACTIVE_STATUS,
                        PERSON_SUSPENDED_STATUS,
                    ]);
                } else {
                    return [];
                }
            };

            _handleSubmit = async (values: any, { setSubmitting }: any) => {
                // Case for updating absence record
                const {
                    personProfilePage,
                    initialData: { id },
                } = this.state;
                if (id) {
                    return this.setState({ showUpdateAlert: true });
                }
                // Case for creating absence record
                const { absencesStore } = (this.props as any).RootStore;
                const absenceRecordId = await absencesStore.createAbsence({ ...values });
                this.setState({ formWasEdited: false });
                if (personProfilePage) {
                    this._updateStateAfterChanges(absenceRecordId);
                } else {
                    return (this.props as any).history.replace(
                        `/hr/absence-management/${absenceRecordId}`,
                    );
                }
            };

            _finishUpdateProcess = async (values: any) => {
                const { absencesStore } = (this.props as any).RootStore;
                this._handleCloseAlert();
                this.setState({ formWasEdited: false });
                await absencesStore.updateAbsence({ ...values });
                await this._updateStateAfterChanges();
            };

            _handleOnArchive = () => {
                this.setState({ showArchiveAlert: true });
            };

            _archiveAbsenceRecord = async () => {
                const recordId = this.state.initialData.id;
                const { absencesStore } = (this.props as any).RootStore;
                this._handleCloseAlert();
                await absencesStore.bulkArchiveAbsence([recordId]);
                this._updateStateAfterChanges();
            };

            render() {
                const {
                    RootStore: {
                        dictionaryStore,
                        configStore: { org },
                    },
                    personProfileId,
                    closeAbsenceRecordFromProfile,
                } = this.props as any;

                const { actionType } = this.state;

                const {
                    name,
                    roles,
                    departments,
                    absenceType,
                    startDateTime,
                    startTime,
                    endDateTime,
                    endTime,
                    createdAtInsideView,
                    createdBy,
                    updatedAtInsideView,
                    updatedBy,
                    statusId,
                    notes,
                    documents,
                    isArchived,
                    personId,
                } = this.state.initialData;

                const {
                    showAlert,
                    showUpdateAlert,
                    showArchiveAlert,
                    updateStatusTo,
                    isEdit,
                    formWasEdited,
                    newForm,
                    personProfilePage,
                    isLoaded,
                } = this.state;

                return (
                    <div
                        className={
                            personProfileId
                                ? 'absence-record-container inside-profile'
                                : 'absence-record-container'
                        }
                    >
                        {!isLoaded && <Loader />}
                        <div className="common-layout__modal">
                            <div className="modal modal--shadow">
                                <div className="modal__wrapper">
                                    <div className="modal__content">
                                        <Formik
                                            initialValues={{
                                                ...this.state.initialData,
                                            }}
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            validate={async (values) => {
                                                await this._editStatusForm(values);
                                                const errors = {} as any;
                                                if (!values.personId) {
                                                    errors.personId =
                                                        'Please select Person record before creating new record';
                                                }
                                                return errors;
                                            }}
                                            onSubmit={this._handleSubmit}
                                        >
                                            {({
                                                isSubmitting,
                                                values,
                                                handleChange,
                                                setFieldValue,
                                                setValues,
                                                errors,
                                                touched,
                                            }) => (
                                                <>
                                                    <div className="absence">
                                                        <div className="absence-inner-header full-border">
                                                            {actionType === 'create' ? (
                                                                <h3>
                                                                    {personProfilePage
                                                                        ? name
                                                                        : 'Create new absence'}
                                                                </h3>
                                                            ) : (
                                                                <h3>
                                                                    Absence details{' '}
                                                                    {values.isArchived
                                                                        ? '(archived)'
                                                                        : ''}
                                                                </h3>
                                                            )}
                                                            <Button
                                                                icon="cross"
                                                                minimal
                                                                onClick={() => {
                                                                    personProfilePage
                                                                        ? closeAbsenceRecordFromProfile()
                                                                        : (this
                                                                              .props as any).history.replace(
                                                                              '/hr/absence-management',
                                                                          );
                                                                }}
                                                            />
                                                        </div>
                                                        {actionType === 'create' ? (
                                                            <div className="absence-inner-header inner">
                                                                <h3 className="primary-color">
                                                                    {personProfilePage
                                                                        ? 'Create new absence'
                                                                        : 'Absence information'}
                                                                </h3>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    minimal
                                                                    onClick={() => {
                                                                        return formWasEdited
                                                                            ? (this
                                                                                  .props as any).history.replace(
                                                                                  (this
                                                                                      .props as any)
                                                                                      .history
                                                                                      .location
                                                                                      .pathname,
                                                                              )
                                                                            : (this
                                                                                  .props as any).history.replace(
                                                                                  personProfilePage
                                                                                      ? closeAbsenceRecordFromProfile()
                                                                                      : '/hr/absence-management',
                                                                              );
                                                                    }}
                                                                    className="go-back-link"
                                                                >
                                                                    &lt;{' '}
                                                                    <span className="text">
                                                                        all absences
                                                                    </span>
                                                                </Button>
                                                                <div className="absence-inner-header inner">
                                                                    <h3
                                                                        className={
                                                                            !isArchived
                                                                                ? 'primary-color'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        {`${moment(
                                                                            startDateTime,
                                                                            'HH:MM DD.MM.YYYY',
                                                                        ).format(
                                                                            'ddd Do MMMM',
                                                                        )}, ${startTime} - ${moment(
                                                                            endDateTime,
                                                                            'HH:MM DD.MM.YYYY',
                                                                        ).format(
                                                                            'ddd Do MMMM',
                                                                        )}, ${endTime}`}{' '}
                                                                    </h3>
                                                                    {!isArchived ? (
                                                                        <Button
                                                                            intent="none"
                                                                            icon={
                                                                                this.state.isEdit
                                                                                    ? 'cross'
                                                                                    : 'edit'
                                                                            }
                                                                            onClick={
                                                                                this._toggleIsEdit
                                                                            }
                                                                            type="button"
                                                                            loading={isSubmitting}
                                                                            key="approve"
                                                                            minimal
                                                                        >
                                                                            {this.state.isEdit
                                                                                ? 'Cancel edit'
                                                                                : 'edit'}
                                                                        </Button>
                                                                    ) : null}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    {!isEdit && (
                                                        <ViewAbsenceRecord
                                                            person={name}
                                                            personId={personId}
                                                            absenceType={absenceType}
                                                            roles={roles}
                                                            createdAt={createdAtInsideView}
                                                            createdBy={createdBy}
                                                            departments={departments}
                                                            updatedAt={updatedAtInsideView}
                                                            updatedBy={updatedBy}
                                                            status={
                                                                dictionaryStore
                                                                    .absenceStatusesDictionary[
                                                                    statusId
                                                                ]
                                                            }
                                                            documents={documents}
                                                            notes={notes}
                                                            changeStatus={
                                                                this._showConfirmationPopUp
                                                            }
                                                            isSubmitting={isSubmitting}
                                                            downloadFile={
                                                                this._handleDocumentDownload
                                                            }
                                                            archiveRecord={this._handleOnArchive}
                                                            isArchived={isArchived}
                                                        />
                                                    )}
                                                    {isEdit && (
                                                        <AbsenceForm
                                                            values={values}
                                                            formWasEdited={formWasEdited}
                                                            dictionaryStore={dictionaryStore}
                                                            setFieldValue={setFieldValue}
                                                            setValues={setValues}
                                                            handleChange={handleChange}
                                                            touched={touched}
                                                            errors={errors}
                                                            org={org}
                                                            isSubmitting={isSubmitting}
                                                            toggleIsEdit={this._toggleIsEdit}
                                                            actionType={actionType}
                                                            loadPersonsOptions={
                                                                this._loadPersonsOptions
                                                            }
                                                            newForm={newForm}
                                                            history={(this.props as any).history}
                                                            personProfilePage={personProfilePage}
                                                            closeAbsenceRecordFromProfile={
                                                                closeAbsenceRecordFromProfile
                                                            }
                                                        />
                                                    )}
                                                    <ConfirmationAlert
                                                        confirmButtonText="Yes"
                                                        cancelButtonText="No"
                                                        onConfirm={() =>
                                                            this._finishUpdateProcess(values)
                                                        }
                                                        onCancel={this._handleCloseAlert}
                                                        isOpen={showUpdateAlert}
                                                        icon="refresh"
                                                        title="Confirm update!"
                                                        description="Are you sure you want to save changes?"
                                                    />
                                                    <ConfirmationAlert
                                                        confirmButtonText="Yes"
                                                        cancelButtonText="No"
                                                        onConfirm={this._updateStatus}
                                                        onCancel={this._handleCloseAlert}
                                                        isOpen={showAlert}
                                                        intent={
                                                            updateStatusTo === 'APPROVE'
                                                                ? 'success'
                                                                : 'danger'
                                                        }
                                                        icon={
                                                            updateStatusTo === 'APPROVE'
                                                                ? 'tick-circle'
                                                                : 'cross'
                                                        }
                                                        title="Confirm update!"
                                                        description={
                                                            updateStatusTo === 'APPROVE'
                                                                ? 'Are you sure you want to approve the absence?'
                                                                : 'Are you sure you want to reject the absence?'
                                                        }
                                                    />
                                                    <ConfirmationAlert
                                                        confirmButtonText="Archive"
                                                        onConfirm={this._archiveAbsenceRecord}
                                                        onCancel={this._handleCloseAlert}
                                                        isOpen={showArchiveAlert}
                                                        title="Confirm archiving!"
                                                        icon={'archive'}
                                                        intent={'warning'}
                                                        description="Are you sure you want to archive the absence record? This action is irreversible"
                                                    />
                                                    <RouterPrompt
                                                        when={formWasEdited}
                                                        onPromptConfirm={
                                                            this._handleOnCancelConfirm
                                                        }
                                                    />
                                                </>
                                            )}
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        },
    ),
);

export default AbsenceManagement;
