import { Tooltip } from '@blueprintjs/core';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';
import { JobStatus, StaffAlert } from '@doc-abode/data-models';
import moment from 'moment';

import { IHcp } from '../../../../../interfaces/ucr';
import useStores from '../../../../../hook/useStores';
import {
    IconAdd,
    IconPushPin,
    IconPushPinFilled,
    IconRemove,
    IconWarning,
    AddressWarningIcon,
} from '../../../../../helpers/ucr/icons';
import { IHCPBreakdown } from '../../../../../stores/MapStoreTypes';
import { Button, ButtonColors, ButtonSizes } from '../../../../v2/components';

import HcpAvailability from './HcpAvailability/HcpAvailability';
import Sickness from './Sickness';
import HcpFunctions from './HcpFunctions';
import HCPBreakdown from './HCPBreakdown';
import { useView } from '../../views/useView';
import { ViewToShow } from '../../../../../constants/mainConst';
import RootStore from '../../../../../stores/RootStore';

interface IProps {
    hcp: IHcp;
    staffAlerts: StaffAlert[];
    numberAllHcpsPos: number;
    updateAllHcpsPos: () => void;
    pinnedStatuses: Record<string, number>;
    mapViewBreakDown?: IHCPBreakdown[];
}

const SchedulingConflictIcon = () => (
    <IconWarning className="ucr__calendar-scheduling-conflict-icon" />
);

const HCP: FC<IProps> = ({
    hcp,
    staffAlerts,
    numberAllHcpsPos,
    updateAllHcpsPos,
    pinnedStatuses,
    mapViewBreakDown,
}) => {
    const hcpRef = useRef<HTMLDivElement>(null);
    const {
        RootStore: {
            lovsStore: { managementDay, managementDayNullValue },
            ucrStore: {
                selectedDate,
                hcpsOffset,
                setHcpsPos,
                hcpsMinHeight,
                expandedSwimlanes,
                expandHcpSwimlane,
                schedulingConflicts: conflicts,
                clearAllFilters,
                setPinStaff,
                pinnedStaff,
                setNameFilters,
                viewToShow,
            },
            mapStore: {
                setBoundedArea,
                setHCPBreakdown,
                removeHCPBreakdown,
                setCompletedBreakdown,
                completedBreakdown,
                expandHcpPanel,
                expandedPanels,
            },
            schedulesStore: { allSchedules },
            userStore: { user },
            usersStore: {
                setUserAbsence,
                setHcpFunction,
                loading,
                updateUserData,
                getUserHcpTypes,
                getUserBand,
                hcpFunctions,
            },
        },
    } = useStores() as { RootStore: RootStore };

    const { navigateTo } = useView();

    const isMapView = viewToShow === ViewToShow.MAP;

    const isExpanded = isMapView
        ? expandedPanels[hcp.userId] || false
        : expandedSwimlanes[hcp.userId] || false;
    const selectedDateFormatted = moment(selectedDate).format('YYYY-MM-DD');

    useEffect(() => {
        if (!hcpRef.current || !hcpsOffset) return;
        setHcpsPos(hcp.userId, hcpRef.current.offsetTop, hcpRef.current.offsetHeight);
    }, [hcpRef, hcp, setHcpsPos, numberAllHcpsPos, hcpsOffset, isExpanded]);

    const toggleEvent = useCallback(() => {
        if (isMapView) {
            expandHcpPanel(!isExpanded, hcp.userId);
        } else {
            expandHcpSwimlane(!isExpanded, hcp.userId);
        }
        updateAllHcpsPos();

        if (!isExpanded && mapViewBreakDown) {
            setHCPBreakdown(mapViewBreakDown, hcp.userId);
        }
        if (isExpanded && mapViewBreakDown) {
            removeHCPBreakdown(hcp.userId);
        }
    }, [
        isMapView,
        updateAllHcpsPos,
        isExpanded,
        mapViewBreakDown,
        expandHcpPanel,
        hcp.userId,
        expandHcpSwimlane,
        setHCPBreakdown,
        removeHCPBreakdown,
    ]);

    const hasStaffWarning = staffAlerts.some((alert) => alert.userId === hcp.userId);
    const hasSchedulingConflict = conflicts.hasOwnProperty(hcp.userId);

    const handleSicknessChange = useCallback(
        (val: any) => {
            setUserAbsence(hcp.userId, val).then();
        },
        [hcp.userId, setUserAbsence],
    );

    const handleHcpFunctionChange = useCallback(
        (hcpDayResponsibility: string[]) => {
            setHcpFunction(hcp.userId, hcpDayResponsibility, selectedDateFormatted);
        },
        [hcp.userId, setHcpFunction, selectedDateFormatted],
    );

    const respByDate = hcpFunctions?.find((r: any) => r.userId === hcp.userId);
    const initialResponsibilityValues =
        respByDate?.hcpDayResponsibility?.map(
            (value: string) =>
                managementDay?.find((config: any) => config.value === value) ||
                managementDayNullValue,
        ) || [];

    // This is the local button state, it has a default value from the server.
    // The server does not update the store again so it needs to be saved in setState.
    let [isPinned, setIsPinned] = useState<boolean>(Boolean(pinnedStatuses[hcp.userId]));

    const togglePin = () => {
        setIsPinned(isPinned ? false : true);
        setPinStaff(hcp.userId, isPinned ? false : true);
        updateUserData(user.username, {
            staffPins: pinnedStaff,
        });
    };

    const hcpSchedules = allSchedules.filter((schedule: any) => schedule.userId === hcp.userId);
    const exludeStatuses = [
        JobStatus.COMPLETED,
        JobStatus.HCP_ABORTED,
        JobStatus.CONTROLLER_ABORTED,
        null,
    ];

    let breakDownFiltered: IHCPBreakdown[] = [];
    const isAddressError = mapViewBreakDown?.some((v) => !v.latitude || !v.longitude);

    if (mapViewBreakDown) {
        breakDownFiltered = mapViewBreakDown.filter((breakdown) => {
            return (
                (isExpanded &&
                    breakdown.status !== JobStatus.COMPLETED &&
                    breakdown.status !== JobStatus.HCP_ABORTED &&
                    breakdown.status !== JobStatus.CONTROLLER_ABORTED &&
                    !completedBreakdown[hcp.userId]) ||
                (isExpanded && completedBreakdown[hcp.userId])
            );
        });
    }

    const hcpTypes = getUserHcpTypes(hcp.userId);
    const band = getUserBand(hcp.userId);

    return (
        <div
            ref={hcpRef}
            className={cn('ucr__calendar-hcp', {
                'ucr__calendar-hcp--staff-alert': hasStaffWarning,
            })}
            style={{ minHeight: hcpsMinHeight }}
        >
            <div
                className={cn('ucr__calendar-hcp-top', {
                    'ucr__calendar-hcp-top--warning': isAddressError,
                })}
            >
                <button className="ucr__calendar-hcp-toggle" onClick={toggleEvent}>
                    {isExpanded ? <IconRemove /> : <IconAdd />}
                </button>
                <div className="ucr__calendar-hcp-image">
                    <span
                        className={cn('ucr__calendar-hcp-avatar', {
                            'ucr__calendar-hcp-avatar--available': hcp.available,
                            'ucr__calendar-hcp-avatar--unavailable': !hcp.available,
                        })}
                    >
                        {hcp.firstName.slice(0, 1).toUpperCase()}
                        {hcp.lastName.slice(0, 1).toUpperCase()}
                    </span>
                    <span
                        className={cn('ucr__calendar-hcp-status', {
                            'ucr__calendar-hcp-status--available': hcp.available,
                            'ucr__calendar-hcp-status--unavailable': !hcp.available,
                        })}
                    />
                </div>
                <div
                    className={cn('ucr__calendar-hcp-about', {
                        'ucr__calendar-hcp-about--pl': isAddressError,
                    })}
                >
                    <button
                        className={cn('ucr__calendar-hcp-push-pin', {
                            'ucr__calendar-hcp-push-pin--pinned': isPinned,
                        })}
                        onClick={togglePin}
                    >
                        {isPinned ? <IconPushPinFilled /> : <IconPushPin />}
                    </button>

                    <span
                        className={cn('ucr__calendar-hcp-name', {
                            'ucr__calendar-hcp-name--warning': isAddressError,
                        })}
                    >
                        {isAddressError && (
                            <span>
                                <Tooltip
                                    popoverClassName={'ucr-listview__popover'}
                                    content="Address details for one or more jobs could not be verified"
                                >
                                    <AddressWarningIcon className="ucr-mapview__icon" />
                                </Tooltip>
                            </span>
                        )}
                        {hcp.firstName} {hcp.lastName}
                    </span>
                    <span className="ucr__calendar-hcp-userId">{hcp.userId}</span>
                    {isExpanded ? (
                        <>
                            <span className="ucr__calendar-hcp-phone">{hcp.phoneNumber}</span>
                            <div className="ucr__calendar-hcp-badges">
                                {hcpTypes.map((hcpType: string) => (
                                    <span className="ucr__calendar-hcp-badge" key={hcpType}>
                                        {hcpType}
                                    </span>
                                ))}
                                {band && <span className="ucr__calendar-hcp-badge">{band}</span>}
                            </div>
                        </>
                    ) : undefined}

                    {hasSchedulingConflict && (
                        <Button
                            Icon={SchedulingConflictIcon}
                            name="Scheduling Conflict"
                            color={hasStaffWarning ? ButtonColors.WHITE : ButtonColors.RED}
                            size={ButtonSizes.SMALL}
                            className="ucr__calendar-scheduling-conflict"
                            clickEvent={() => {
                                navigateTo(ViewToShow.VISITS_LIST);
                                clearAllFilters();
                                setNameFilters('staffName', [hcp.userId]);
                            }}
                        />
                    )}

                    <div className="ucr__calendar-hcp-settings">
                        <HcpFunctions
                            handleChange={handleHcpFunctionChange}
                            loading={loading}
                            hcp={hcp}
                            initialResponsibilityValues={initialResponsibilityValues}
                        />
                        <Sickness
                            isSick={hcp.absent}
                            handleChange={handleSicknessChange}
                        ></Sickness>
                        <HcpAvailability hcpSchedules={hcpSchedules} hcp={hcp} />
                    </div>
                </div>
            </div>
            <div className="ucr__calendar-hcp-bottom">
                {mapViewBreakDown && (
                    <div
                        key={`${hcp.userId}--breakdownContainer`}
                        className="ucr__hcp-mapview-breakdown-container"
                    >
                        {isExpanded &&
                            mapViewBreakDown.some((breakdown) =>
                                exludeStatuses.includes(breakdown.status || null),
                            ) && (
                                <Button
                                    className={'ucr__hcp-mapview-hide-show-completed'}
                                    name={`${
                                        !completedBreakdown[hcp.userId]
                                            ? 'Show all'
                                            : 'Show incomplete only'
                                    }`}
                                    clickEvent={() => {
                                        setCompletedBreakdown(
                                            completedBreakdown[hcp.userId] ? false : true,
                                            hcp.userId,
                                        );
                                    }}
                                ></Button>
                            )}
                        {breakDownFiltered.map((breakdown, index) => (
                            <HCPBreakdown
                                key={`${hcp.userId}${index}--breakdown"`}
                                breakdown={breakdown}
                                index={index}
                                mapViewBreakDown={breakDownFiltered}
                                setBoundedArea={setBoundedArea}
                                hasAddressWarning={!breakdown.longitude || !breakdown.latitude}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default observer(HCP);
