import React from 'react';
import TableRowActions from '../../../../../common/TableRowActions';
import { calculateParticularDayInWeek } from '../helper';
import Shift from './Shift';
import _ from 'lodash';
import { IconAdd } from '../../../../../../helpers/ucr/icons';
import '../styles/rota-day.css';

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const WeekDay = (props: any) => {
    const {
        dayOfWeek,
        weekNumber,
        items,
        onAdd,
        onDutyStationClick,
        disabled,
        locations,
        shiftStatuses,
        onDelete,
        onSplit,
        rotaStartDate,
        shiftTypes,
        department,
        staffStore,
        holidayDates,
        payRatesDictionary,
        rolesDictionary,
        isPublished,
        openPaymentDialog,
        paymentStatusId,
        paymentStatusNormalized,
        isViewMode,
    } = props;

    if (!items) return null;

    items.sort((d1: any, d2: any) => {
        let t1 = new Date(d1.startTime).getTime();
        let t2 = new Date(d2.startTime).getTime();
        if (t1 === t2) {
            t1 = new Date(d1.endTime).getTime();
            t2 = new Date(d2.endTime).getTime();
        }
        return t1 - t2;
    });

    const particularDayDate = calculateParticularDayInWeek(rotaStartDate, weekNumber, dayOfWeek);
    const holidayEntry = _.includes(Array.from(holidayDates), particularDayDate);

    return (
        <div>
            <div className="rota-builder__day-card day-card">
                <div className="day-card__header">
                    <div className="day-card__header-content">
                        {holidayEntry ? !disabled && <span className="holiday" /> : ''}
                        <h4 className={`day-card__title`}>{!disabled && days[dayOfWeek - 1]}</h4>
                        <span className="day-card__time">{!disabled && particularDayDate}</span>
                    </div>
                    <div>
                        {!isViewMode && (
                            <div className="day-card__actions">
                                <TableRowActions
                                    hasDuplicateOption={false}
                                    hasSplitOption={false}
                                    paymentStatusId={paymentStatusId}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="rota-shifts">
                    {items &&
                        !disabled &&
                        !!department &&
                        items.map((entry: any, index: number) => {
                            return (
                                <Shift
                                    key={index}
                                    isViewMode={isViewMode}
                                    onDutyStationClick={onDutyStationClick}
                                    locations={locations}
                                    props={props}
                                    entry={entry}
                                    shiftStatuses={shiftStatuses}
                                    onDelete={onDelete}
                                    onSplit={onSplit}
                                    shiftTypes={shiftTypes}
                                    rotaStartDate={rotaStartDate}
                                    department={department}
                                    staffStore={staffStore}
                                    weekNumber={weekNumber}
                                    payRatesDictionary={payRatesDictionary}
                                    rolesDictionary={rolesDictionary}
                                    isPublished={isPublished}
                                    openPaymentDialog={openPaymentDialog}
                                    paymentStatusNormalized={paymentStatusNormalized}
                                />
                            );
                        })}
                </div>
            </div>

            <div className="rota-shift-add">
                <button
                    className="btn btn--secondary"
                    disabled={(disabled && !_.isEmpty(department)) || isViewMode}
                    onClick={() => {
                        if (onAdd) onAdd(weekNumber, dayOfWeek);
                    }}
                >
                    <IconAdd /> Add shift
                </button>
            </div>
        </div>
    );
};

export default WeekDay;
