import { observable, action, makeObservable, runInAction } from 'mobx';
import { GET_FITTOPRACTICE } from '../graphql/queries/fitToPractice';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PagedStore } from './PagedStore';

class FitToPracticeStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            fitToPractices: observable,
            getFitToPractices: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    fitToPractices = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    getFitToPractices = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getFitToPractices: data },
        } = await this.apolloClient.query({
            query: GET_FITTOPRACTICE,
            variables: {},
        });

        runInAction(() => {
            this.fitToPractices = data;
            this.loaded = true;
        });
    };
}

export default FitToPracticeStore;
