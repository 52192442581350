import React from 'react';
import { FormGroup, InputGroup, NumericInput } from '@blueprintjs/core';
import Error from '../../forms/Error';

const PayRatesSummaryGeneral = ({
    editOrCreateMode,
    setFieldValue,
    values,
    handleChange,
    errors,
    touched,
}: any) => {
    return (
        <div className="payRates-info__block">
            <h3 className="payRates-info__title payRates-info__title--center">General rate *</h3>
            <div className="payRates-info__definition payRates-info__definition--inline">
                <div>
                    <h3 className="payRates-info__title">Code</h3>
                    {editOrCreateMode ? (
                        <FormGroup labelFor="code">
                            <InputGroup
                                id="generalPayRates.code"
                                name="generalPayRates.code"
                                onChange={handleChange}
                                value={values.generalPayRates.code}
                                large
                            />
                            {touched.code && <Error errors={[errors.code]} />}
                        </FormGroup>
                    ) : (
                        values.generalPayRates && values.generalPayRates.code
                    )}
                </div>
                <div>
                    <h3 className="payRates-info__title">Rate per hour *</h3>
                    {editOrCreateMode ? (
                        <FormGroup labelFor="rate">
                            <NumericInput
                                id="generalPayRates.rate"
                                name="generalPayRates.rate"
                                onValueChange={(_, value) => {
                                    setFieldValue(`generalPayRates.rate`, value);
                                }}
                                value={values.generalPayRates.rate}
                                large
                                minorStepSize={0.01}
                                stepSize={0.01}
                                type="number"
                                buttonPosition="none"
                            />
                            {touched.rate && <Error errors={[errors.rate]} />}
                        </FormGroup>
                    ) : (
                        values.generalPayRates && values.generalPayRates.rate
                    )}
                </div>
            </div>
        </div>
    );
};

export default PayRatesSummaryGeneral;
