import { rootUrl, retryable } from './baseApi';

export const getRegistrationData = (
    professionalBody: string,
    professionalBodyId: string,
    authToken: string,
): Promise<any> => {
    return retryable(() =>
        fetch(
            `${rootUrl}/controller/compliance/registrations/${professionalBody}/${professionalBodyId}`,
            {
                method: 'GET',
                headers: {
                    Authorization: authToken,
                    'Cache-Control': 'no-cache',
                },
            },
        ),
    );
};
