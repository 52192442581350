import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _, { isEmpty } from 'lodash';
import {
    CREATE_ROLE,
    UPDATE_ROLE,
    DELETE_ROLE,
    GET_ROLE_BY_ID,
    GET_ROLES_LIST,
    BULK_DELETE_ROLE,
    GET_ROLES_BY_DEPARTMENT_AND_FUNCTIONS,
    ARCHIVE_ROLE,
    BULK_ARCHIVE_ROLE,
    GET_ROLE_BY_NAME,
    VALIDATE_ARCHIVE_ROLES,
} from '../graphql/queries/roles';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

import AppToaster from '../components/modules/helpers/Toaster';
import { PAGE_SIZE } from '../constants/hrConst';
import { PagedStore } from './PagedStore';
import { GetEmployeeRoleTypes_getEmployeeRoleTypes_edges_node } from '../__generated__/GetEmployeeRoleTypes';

export interface Roles extends GetEmployeeRoleTypes_getEmployeeRoleTypes_edges_node {
    selected?: boolean;
    categories: Array<string>;
    functions: Array<string>;
}

class RolesStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            roles: observable,
            allRoles: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridRoles: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getRoles: action,
            getRolesByFunction: action,
            setSelectableCriteria: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    roles = [];
    allRoles = [];
    allRolesActive = [];
    validatedArchiveRoles = [];
    someArchivableSelectedRoles = [];
    allArchivableSelectedRoles = [];
    rolesByFunction = [];
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    normalizeRoles = (role: Roles) => ({
        id: role.id,
        selected: this.selected.includes(role.id),
        name: role.name,
        createdAt: role.createdAt,
        updatedAt: role.updatedAt,
        createdBy: role.createdBy,
        updatedBy: role.updatedBy,
        categories: role.documentCategories,
        functions: role.roleFunctions
            ? role.roleFunctions.map((f: any) => ({
                  value: f.function?.id,
                  label: f.function?.name,
                  isFixed: f.isInUse,
                  isArchived: f.function?.isArchived,
              }))
            : [],
        isArchived: role.isArchived,
        createdPerson: !isEmpty(role.createdPerson) ? role.createdPerson : '',
        updatedPerson: !isEmpty(role.updatedPerson) ? role.updatedPerson : '',
    });

    get gridRoles() {
        const roles = this.roles.map(this.normalizeRoles);
        if (this.sortFn) {
            roles.sort(this.sortFn);
        }
        return roles;
    }

    get allSelected() {
        return this.gridRoles.every((r) => this.selected.includes(r.id));
    }

    async createRole(role: Roles) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_ROLE,
                variables: {
                    data: {
                        name: role.name,
                        documentCategories: role.categories,
                        functions: role.functions.map((f: any) => f.value),
                    },
                },
            });
            AppToaster.show({
                message: 'Role created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating role record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async deleteRole(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_ROLE,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Role deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting role record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateRole(role: Roles) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_ROLE,
                variables: {
                    id: role.id,
                    data: {
                        name: role.name,
                        documentCategories: role.categories,
                        functions: role.functions.map((f: any) => f.value),
                    },
                },
            });
            AppToaster.show({
                message: 'Role updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating role record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    setSelectableCriteria = (selectableCriteria: any) => {
        this.selectableCriteria = selectableCriteria;
    };

    select = (roleId: string) => {
        if (!this.selected.includes(roleId)) {
            this.selected.push(roleId);
        }
    };

    deselect = (roleId: string) => {
        const index = this.selected.indexOf(roleId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getRoles();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getRoles();
    };

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    onSearch = _.debounce((searchText: any) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onFilter = (name: string, values: any) => {
        this.pageInfo.filter[name] = values?.length === 0 ? null : values;
        this.refresh();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getRoles();
    };

    clear = async () => {
        runInAction(() => {
            this.clearPageInfo({});
        });
        await this.getRoles();
    };

    getRoles = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeRoleTypes: data },
        } = await this.apolloClient.query({
            query: GET_ROLES_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.roles = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getRolesRecords = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_ROLES_LIST,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: query || '',
            },
        });
    };

    getAllRoles = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeRoleTypes: data },
        } = await this.apolloClient.query({
            query: GET_ROLES_LIST,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
            },
        });

        runInAction(() => {
            this.allRoles = data.edges.map((x: any) => x.node);
            this.allRolesActive = data.edges
                .map((x: any) => x.node)
                ?.filter((item: any) => !item.isArchived);
        });
    };

    getRolesByFunction = async (functionId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getEmployeeRoleTypes: data },
        } = await this.apolloClient.query({
            query: GET_ROLES_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: 'asc',
                field: 'name',
                query: this.pageInfo.searchText,
                filter: { functionId },
            },
        });
        runInAction(() => {
            this.rolesByFunction = data.edges
                ?.filter((item: any) =>
                    item.node.roleFunctions.every((item: any) => !item.function.isArchived),
                )
                ?.map((x: any) => {
                    return {
                        id: x.node.id,
                        name: x.node.name,
                        roleFunctions: x.node.roleFunctions.flatMap((functionItem: any) =>
                            !functionItem?.function.isArchived ? functionItem.function : [],
                        ),
                    };
                });
        });
    };

    getRolesByDepartmentAndFunctions = async (departmentId: string, functionIds?: [string]) => {
        const userSession = await this.rootStore.userStore.getUserSession();
        if (!userSession) {
            return;
        }

        const {
            data: { getEmployeeRoleTypesByDepartmentAndFunctions },
        } = await this.apolloClient.query({
            query: GET_ROLES_BY_DEPARTMENT_AND_FUNCTIONS,
            variables: {
                departmentId,
                functionIds,
            },
        });

        return getEmployeeRoleTypesByDepartmentAndFunctions.map((x: any) => ({
            label: x.name,
            value: x.id,
        }));
    };

    getRoleById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getEmployeeRoleTypeById: data },
        } = await this.apolloClient.query({
            query: GET_ROLE_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeRoles(data);
    };

    async validateRoleName(name: string) {
        return await this.apolloClient.query({
            query: GET_ROLE_BY_NAME,
            variables: {
                name,
            },
        });
    }

    selectAll = () => {
        runInAction(() => {
            this.gridRoles
                .filter((role) => !role.isArchived)
                .forEach((role) => {
                    this.select(role.id);
                });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    async bulkDeleteRoles(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_ROLE,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'All selected roles deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem deleting selected roles.Please try again or
                        contact<a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async archiveRole(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_ROLE,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Role archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem archiving role record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async validateArchiveRoles(ids: any) {
        return await this.apolloClient.query({
            query: VALIDATE_ARCHIVE_ROLES,
            variables: {
                ids,
            },
        });
    }

    async validateArchiveSelectedRoles() {
        const {
            data: { isEmployeeRoleTypeArchivable },
        } = await this.validateArchiveRoles(this.selected);
        runInAction(() => {
            this.validatedArchiveRoles = isEmployeeRoleTypeArchivable;
            this.someArchivableSelectedRoles = isEmployeeRoleTypeArchivable.some(
                (role: any) => role.isArchivable,
            );
            this.allArchivableSelectedRoles = isEmployeeRoleTypeArchivable.every(
                (role: any) => role.isArchivable,
            );
        });
    }

    async bulkArchiveRoles(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_ROLE,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'All selected roles archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: (
                    <>
                        Sorry, there was a problem archiving selected roles.Please try again or
                        contact<a href="mailto:support@docabode.com">support@docabode.com</a> for
                        help.
                    </>
                ),
                intent: 'danger',
            });
        }
        await this.refresh();
    }
}

export default RolesStore;
