import { baseUrl } from './baseApi';
export const getReports = (authToken: string): Promise<any> => {
    return fetch(`${baseUrl}/admin`, {
        method: 'GET',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};

export const getCompletedJobs = (
    authToken: string,
    org: string,
    from: string,
    to: string,
): Promise<any> => {
    return fetch(`${baseUrl}/admin/from/${from}/to/${to}`, {
        method: 'POST',
        headers: {
            Authorization: authToken,
            'Cache-Control': 'no-cache',
        },
    });
};
