import { FC, useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useLazyQuery, NetworkStatus } from '@apollo/client';
import { JobStatus, Patient } from '@doc-abode/data-models';

import { MapContainer } from './MapContainer';
import ExpandFrame from './ExpandFrame';
import HCPs from '../calendar/HCPs';
import Empty from '../calendar/Empty';
import AppToaster from '../../../../modules/helpers/Toaster';
import { getStartAndEndDay } from '../../../../modules/helpers/formatData';
import useStores from '../../../../../hook/useStores';
import { getFilteredJobs, getFilteredHCPs } from '../../../../../helpers/ucr';
import { IHcpExtended } from '../../../../../interfaces/ucr';

import { QUERY_JOBS_BY_JOB_TYPE } from '../../../../../graphql/queries/jobs';
import { JobsContext } from '../../../../../providers';

interface IProps {}

const MapViewController: FC<IProps> = () => {
    const {
        RootStore: {
            ucrStore: {
                selectedDate,
                bottomPanelOpen,
                patientAlerts,
                viewToShow,
                pinnedStaff,
                staffAlerts,
            },
            usersStore: { users },
            configStore: { mapCentre, clientKeys },
            mapStore: {
                jobs,
                setJobs,
                setLoadingJobs,
                loadingJobs,
                processingJobs,
                boundedArea,
                setBoundedArea,
                setJobRefresher,
                jobFilters,
                hcpFilters,
                nameFilters,
                appliedFilters,
                hcps,
                setHcps,
                assignedJobsUnFiltered,
                focusedHCPBreakdownOfJobs,
                resetHCPBreakdown,
                setHCPBreakdownReload,
                hcpsBreakdownInfo,
                completedBreakdown,
                showUnassignedJobs,
                assignedJobsFiltered,
                unassignedJobsFilter,
            },
            schedulesStore: { allSchedules },
        },
    } = useStores() as { RootStore: any };

    const jobsContext = useContext(JobsContext);

    const [loadJobs, { data, refetch, networkStatus }] = useLazyQuery(QUERY_JOBS_BY_JOB_TYPE, {
        fetchPolicy: 'network-only',
        pollInterval: 60000,
        onError: ({ graphQLErrors }) => {
            let message = 'Please check your network connection!';
            if (graphQLErrors && graphQLErrors.length > 0) {
                message = graphQLErrors[0].message;
            }
            AppToaster.show({
                message,
                intent: 'danger',
            });
        },
    });

    const refreshJobs = () => {
        if (refetch) {
            refetch();
        }
    };

    setJobRefresher(refreshJobs);

    useEffect(() => {
        if (jobsContext.refreshAssignedJobs) {
            refetch?.();
        }
    }, [jobsContext.refreshAssignedJobs, refetch]);

    useEffect(() => {
        const isLoading =
            networkStatus === NetworkStatus.loading ||
            networkStatus === NetworkStatus.refetch ||
            networkStatus === NetworkStatus.setVariables;
        setLoadingJobs(isLoading);
    }, [networkStatus, setLoadingJobs]);

    useEffect(() => {
        const [start, end] = getStartAndEndDay(selectedDate);
        loadJobs({
            variables: {
                jobType: 'ucr',
                filter: {
                    or: [
                        // Assigned visit
                        {
                            startDateTime: {
                                between: [start, end],
                            },
                            or: [
                                {
                                    jobStatus: {
                                        ne: JobStatus.PENDING,
                                    },
                                },
                                {
                                    buddyJobStatus: {
                                        ne: JobStatus.PENDING,
                                    },
                                },
                            ],
                        },
                        // Unassigned visit
                        {
                            or: [
                                {
                                    jobStatus: {
                                        eq: JobStatus.PENDING,
                                    },
                                },
                                {
                                    buddyJobStatus: {
                                        eq: JobStatus.PENDING,
                                    },
                                },
                            ],
                        },
                    ],
                },
            },
        });
    }, [selectedDate, loadJobs]);

    // Saving all Jobs
    useEffect(() => {
        if (data) {
            setJobs(data?.queryJobsByJobTypeIndex?.items.map((job: Patient) => new Patient(job)));
            jobsContext.setRefreshAssignedJobs(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, setJobs]);

    // Saving filtered Jobs
    useEffect(() => {
        const filteredJobs: Patient[] = getFilteredJobs({
            jobs,
            jobFilters,
            nameFilters,
            patientAlerts,
            viewToShow,
            filterByHcp: true,
        });
        setJobs(filteredJobs, true);
    }, [users, selectedDate, jobs, setJobs, jobFilters, patientAlerts, viewToShow, nameFilters]);

    // Filtering and saving HCPs
    useEffect(() => {
        const filteredHCPs: IHcpExtended[] = getFilteredHCPs({
            users,
            assignedJobsUnFiltered,
            hcpFilters,
            nameFilters,
            allSchedules,
            patientAlerts,
            staffAlerts,
            selectedDate,
            staffPinned: pinnedStaff,
        });
        setHcps(filteredHCPs);
    }, [
        users,
        selectedDate,
        assignedJobsUnFiltered,
        allSchedules,
        setHcps,
        hcpFilters,
        patientAlerts,
        staffAlerts,
        pinnedStaff,
        nameFilters,
    ]);

    const [isExpanded, setIsExpanded] = useState<boolean>(true);

    useEffect(() => {
        if (completedBreakdown) {
            setHCPBreakdownReload();
        }
    }, [completedBreakdown, setHCPBreakdownReload]);

    const minOrExpand = (): void => {
        setIsExpanded(!isExpanded);
    };

    const cssExpandStatus = isExpanded ? 'expanded' : 'collapsed';
    const bottomOpenCSS = bottomPanelOpen ? 'opened' : 'closed';

    if (hcps.length === 0) {
        return <Empty />;
    }

    return (
        <>
            <div
                className={`ucr-mapview__expandFrame ucr-mapview__expandFrame--${cssExpandStatus}`}
            >
                <ExpandFrame reverse={!isExpanded} clickEvent={minOrExpand} />
            </div>
            <div className={`ucr-mapview__main ucr-mapview ucr-mapview__main--${cssExpandStatus}`}>
                <div
                    className={`ucr-mapview__col1 ucr-mapview__col1--${cssExpandStatus} ucr-mapview__col1--${bottomOpenCSS}`}
                >
                    <div className={`ucr-mapview__hcps ucr-mapview__hcps--${cssExpandStatus}`}>
                        <HCPs />
                        {Object.keys(hcpsBreakdownInfo).length === 0 &&
                            (appliedFilters === 0 ? (
                                <p className="ucr-mapview__nohcps">
                                    There are no HCPs on duty or with assigned jobs for the selected
                                    date
                                </p>
                            ) : (
                                <p className="ucr-mapview__nohcps">
                                    There are no HCPs on duty or with assigned jobs which are
                                    matching your filter criteria
                                </p>
                            ))}
                    </div>
                </div>
                <div className="ucr-mapview__col2">
                    {clientKeys.googleMaps.length > 0 && (
                        <MapContainer
                            users={users}
                            loadingJobs={loadingJobs}
                            selectedDate={selectedDate}
                            assignedJobs={assignedJobsFiltered}
                            unassignedJobs={showUnassignedJobs ? unassignedJobsFilter : []}
                            mapCentre={mapCentre}
                            processing={processingJobs}
                            focusedHCPBreakdownOfJobs={focusedHCPBreakdownOfJobs}
                            boundedArea={boundedArea}
                            setBoundedArea={setBoundedArea}
                            resetHCPBreakdown={resetHCPBreakdown}
                            apiKey={clientKeys.googleMaps}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default observer(MapViewController);
