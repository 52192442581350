import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Button } from '@blueprintjs/core';

import Input from '../common/formik/TextInput';

const ResetPassword = ({ onResetPassword, onResendCode }) => {
    const initialValues = {
        code: '',
        newPassword: '',
    };

    const validationSchema = Yup.object({
        newPassword: Yup.string()
            .min(10, 'Password must contain at least ten characters.')
            .matches(
                /(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+/,
                'Password must contain at lease one upper case letter, one lower case letter and one number.',
            )
            .required('This field is required.'),
    });

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onResetPassword}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => {
                return (
                    <Form>
                        <h2 className="h2">Reset password</h2>

                        <p>
                            We've sent a verification code to your registered email address or phone
                            number, please enter it below along with your new password.
                        </p>

                        <Input
                            name="code"
                            type="text"
                            inputMode="numeric"
                            label="Verification code"
                            placeholder="e.g. 123456"
                            disabled={isSubmitting}
                        />

                        <p>
                            <button type="button" className="minimal-button" onClick={onResendCode}>
                                Resend verification code
                            </button>
                        </p>

                        <Input
                            name="newPassword"
                            type="password"
                            label="New password"
                            autoComplete="new-password"
                            disabled={isSubmitting}
                        />

                        <Button
                            intent="success"
                            large
                            loading={isSubmitting}
                            text="Confirm new password"
                            outlined
                            icon="tick"
                            type="submit"
                            fill
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ResetPassword;
