import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    weekNumber: Yup.mixed().required('This field is required.'),
    dayOfWeek: Yup.mixed().required('This field is required.'),
    roleId: Yup.mixed().required('This field is required.'),
    rotaShiftFunctions: Yup.array().of(Yup.string()).min(1, 'This field is required.'),
    locationId: Yup.mixed().required('This field is required.'),
    breakDurationMinutes: Yup.string().required('This field is required.'),
    startTime: Yup.date().required('This field is required.'),
    endTime: Yup.date().required('This field is required.'),
    statusId: Yup.mixed().required('This field is required.'),
    payRateId: Yup.mixed().nullable().required('This field is required.'),
    typeId: Yup.mixed().required('This field is required.'),
    trainingShift: Yup.bool(),
    trainees: Yup.array().when('trainingShift', {
        is: true,
        then: Yup.array().of(
            Yup.object().shape({
                traineeId: Yup.string().nullable(),
                roleId: Yup.string().required('This field is required.'),
                payRateId: Yup.string().required('This field is required.'),
                overrideValue: Yup.string().nullable(),
            }),
        ),
    }),
});
