import { observable, action, makeObservable, computed, runInAction } from 'mobx';
import _ from 'lodash';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PagedStore } from './PagedStore';
import { GetDepartments_getDepartments_edges_node } from '../__generated__/GetDepartments';
import {
    GET_DEPARTMENTS_LIST,
    GET_ALL_DEPARTMENTS_LIST,
    CREATE_DEPARTMENT,
    GET_DEPARTMENT_BY_ID,
    UPDATE_DEPARTMENT,
    DELETE_DEPARTMENT,
    BULK_DELETE_DEPARTMENTS,
    GET_DEPARTMENTS_BY_COST_CENTRE_ID,
    ARCHIVE_DEPARTMENT,
    BULK_ARCHIVE_DEPARTMENTS,
    IS_DEPARTMENT_ARCHIVABLE,
    IS_ROLE_IN_USE,
    IS_LOCATION_IN_USE,
} from '../graphql/queries/departments';
import { PAGE_SIZE } from '../constants/hrConst';
import AppToaster from '../components/modules/helpers/Toaster';
import { compareByLabels } from '../components/modules/helpers/sortFunctions';
import { SHIFT_STATUS_STANDARD_ID } from '../constants/rotaConst';

export interface Filters {
    isArchived?: string;
    locationIds?: string[];
    costCenters?: string[];
    roles?: string[];
}

export interface Department extends GetDepartments_getDepartments_edges_node {
    selected?: boolean;
    shiftPatterns: [Array<string>];
    costCentre: any;
    costCode: string;
    locations: any;
}

class DepartmentsStore extends PagedStore {
    constructor(rootStore: any) {
        super();
        makeObservable(this, {
            selected: observable,
            departments: observable,
            allDepartments: observable,
            departmentsByLocation: observable,
            departmentsByCostCentre: observable,
            pageInfo: observable,
            loaded: observable,
            sortFn: observable,
            gridDepartments: computed,
            allSelected: computed,
            onSort: action,
            onFilter: action,
            onSearch: action,
            select: action,
            deselect: action,
            nextPage: action,
            previousPage: action,
            getDepartments: action,
            init: action,
        });
        this.rootStore = rootStore;
    }

    rootStore: any;
    apolloClient: any;
    selected: string[] = [];
    departments = [];
    allDepartments = [];
    allDepartmentsActive = [];
    allDepartmentsActiveOptions: any;
    departmentsByLocation = [];
    departmentsByCostCentre = [];
    departmentsByRole: any;
    sortFn: (a: any, b: any) => number = () => 0;
    selectableCriteria = [];

    init = (apolloClient: ApolloClient<NormalizedCacheObject>) => {
        this.apolloClient = apolloClient;
    };

    unNormalizePayRatesTime = (payRates: any) => {
        return payRates.map((payRateItem: any) => ({
            id: payRateItem.id,
            payRateId: payRateItem.payRateId,
            startTime: payRateItem.startTime.includes(':30')
                ? parseInt(payRateItem.startTime.replace(':30', ''))
                : parseInt(payRateItem.startTime.replace(':00', '')),
            endTime: payRateItem.endTime.includes(':30')
                ? parseInt(payRateItem.endTime.replace(':30', ''))
                : parseInt(payRateItem.endTime.replace(':00', '')),
            payRate: {
                name: payRateItem.payRate.name,
                isEmployee: payRateItem.payRate.isEmployee,
            },
        }));
    };

    normalizeDepartment = (item: Department) => {
        return {
            id: item.id,
            selected: this.selected.includes(item.id),
            name: item.name,
            costCode: item.costCode,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy,
            isArchived: item.isArchived,
            roles: item.roles
                ? item.roles
                      .map((r: any) => ({
                          value: r.employeeRole.id,
                          label: r.employeeRole.name,
                          isFixed: r.isInUse,
                      }))
                      .sort(compareByLabels)
                : [],
            locations: item.locations
                ? item.locations
                      .map((r: any) => ({
                          value: r.location.id,
                          label: r.location.name,
                          isFixed: r.isInUse,
                      }))
                      .sort(compareByLabels)
                : [],
            employees: item.employees
                .map((emp: any) => ({
                    value: emp.employeeRecord.id,
                    label: emp.employeeRecord.person
                        ? `${emp.employeeRecord.person.firstName} ${emp.employeeRecord.person.lastName}`
                        : '',
                }))
                .sort(compareByLabels),
            shiftPatterns: item.shiftPatterns
                ? item.shiftPatterns.map((sp: any) => ({
                      value: sp.id,
                      label: sp.name,
                      updatedAt: sp.updatedAt,
                      shiftPatternEntries: sp.shiftPatternEntries.map((item: any) => {
                          return {
                              id: item.id,
                              shiftPatternId: item.shiftPatternId ?? '',
                              weekNumber: item.weekNumber,
                              dayOfWeek: item.dayOfWeek,
                              startTime: new Date(item.startTime),
                              endTime: new Date(item.endTime),
                              breakDurationMinutes: item.breakDurationMinutes,
                              breakIsPaid: item.breakIsPaid,
                              overrideValueInPence: item.overrideValueInPence,
                              roleId: item.shiftEmployeeRoleTypeId,
                              shiftFunctions: item.shiftFunctions.map(
                                  (shiftFunction: any) => shiftFunction.functionId,
                              ),
                              fundingPoolId: item.fundingPoolId,
                              locationId: item.locationId,
                              defaultEmployeeId: item.defaultEmployeeId,
                              payRateId: item.payRateId,
                              typeId: item.typeId,
                              statusId: SHIFT_STATUS_STANDARD_ID,
                              thirdPartyPaid: item.thirdPartyPaid,
                              trainingShift: item.trainingShift,
                              trainees: item.trainees?.length
                                  ? item.trainees.map((trainee: any) => {
                                        return {
                                            traineeId: trainee.traineeId,
                                            roleId: trainee.roleId,
                                            overrideValue: trainee.overrideValue,
                                            payRateId: trainee.payRateId,
                                        };
                                    })
                                  : [
                                        {
                                            traineeId: '',
                                            roleId: '',
                                            overrideValue: null,
                                            payRateId: '',
                                        },
                                    ],
                          };
                      }),
                      shifts: sp.shiftPatternEntries.length,
                      weeks: [
                          ...Array.from(
                              new Set(sp.shiftPatternEntries.map((item: any) => item.weekNumber)),
                          ),
                      ] ?? [1],
                  }))
                : [],
            costCentre: {
                label: item.costCentre.name,
                value: item.costCentre.id,
            },
        };
    };

    get gridDepartments() {
        const roles = this.departments.map(this.normalizeDepartment);
        if (this.sortFn) {
            roles.sort(this.sortFn);
        }
        return roles;
    }

    select = (itemId: string) => {
        if (!this.selected.includes(itemId)) {
            this.selected.push(itemId);
        }
    };
    deselect = (itemId: string) => {
        const index = this.selected.indexOf(itemId);
        if (index > -1) {
            this.selected.splice(index, 1);
        }
    };

    nextPage = () => {
        this.pageInfo.currentEndCursor = this.pageInfo.endCursor;
        this.pageInfo.currentStartCursor = null;
        this.pageInfo.page++;
        this.loaded = false;
        this.getDepartments();
    };

    previousPage = () => {
        this.pageInfo.currentEndCursor = null;
        this.pageInfo.currentStartCursor = this.pageInfo.startCursor;
        this.pageInfo.page--;
        this.loaded = false;
        this.getDepartments();
    };

    refresh = async () => {
        runInAction(() => {
            this.reset();
            this.selected = [];
        });
        await this.getDepartments();
    };

    getDepartments = async () => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: this.pageInfo.searchText,
                filter: this.pageInfo.filter,
            },
        });

        runInAction(() => {
            this.departments = data.edges.map((x: any) => x.node);
            this.pageInfo.totalCount = data.totalCount;
            this.pageInfo.hasNextPage = data.pageInfo.hasNextPage;
            this.pageInfo.hasPreviousPage = data.pageInfo.hasPreviousPage;
            this.pageInfo.startCursor = data.pageInfo.startCursor;
            this.pageInfo.endCursor = data.pageInfo.endCursor;
            this.loaded = true;
        });
    };

    getDepartmentRecords = async (first: number, after?: string, query?: string) => {
        return await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                before: null,
                after,
                first,
                last: null,
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                query: query || '',
                filter: {},
            },
        });
    };

    getAllDepartments = async (filter?: any) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_ALL_DEPARTMENTS_LIST,
            variables: {
                orderBy: this.pageInfo.sortBy ?? 'asc',
                field: this.pageInfo.sortColumn ?? 'name',
                filter: filter,
            },
        });

        runInAction(() => {
            this.allDepartments = data.edges.map((x: any) => x.node);
            this.allDepartmentsActive = data.edges
                .map((x: any) => x.node)
                ?.filter((item: any) => !item.isArchived);
            this.allDepartmentsActiveOptions = this.allDepartmentsActive.map((item: any) => ({
                value: item.id,
                label: `${item.name} (${item.costCode})`,
            }));
            this.allDepartmentsActiveOptions = _.orderBy(
                this.allDepartmentsActiveOptions,
                ['name'],
                ['asc'],
            );
        });
    };

    async createDepartment(department: Department) {
        try {
            await this.apolloClient.mutate({
                mutation: CREATE_DEPARTMENT,
                variables: {
                    data: {
                        name: department.name,
                        costCode: department.costCode,
                        roles: department.roles.map((r: any) => r.value),
                        locations: department.locations.map((r: any) => r.value),
                        costCentreId: department.costCentre.value,
                    },
                },
            });
            AppToaster.show({
                message: 'Department created successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem creating department record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async updateDepartment(department: Department) {
        try {
            await this.apolloClient.mutate({
                mutation: UPDATE_DEPARTMENT,
                variables: {
                    id: department.id,
                    data: {
                        name: department.name,
                        costCode: department.costCode,
                        roles: department.roles.map((r: any) => r.value),
                        locations: department.locations.map((r: any) => r.value),
                        costCentreId: department.costCentre.value,
                    },
                },
            });
            AppToaster.show({
                message: 'Department updated successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem updating department record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    getDepartmentById = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartmentById: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENT_BY_ID,
            variables: {
                id,
            },
        });
        return this.normalizeDepartment(data);
    };

    getDepartmentsByCostCentreId = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartmentsByCostCentreId },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_BY_COST_CENTRE_ID,
            variables: {
                id,
            },
        });
        return getDepartmentsByCostCentreId
            .map((x: any) => ({
                label: `${x.name} (${x.costCode})`,
                value: x.id,
                isArchived: x.isArchived,
            }))
            .filter((item: any) => !item.isArchived);
    };

    onFilter = (filter: Filters) => {
        this.pageInfo.filter = {
            isArchived: filter.isArchived,
            locationId: filter.locationIds,
            costCenters: filter.costCenters,
            roles: filter.roles,
        };
        this.refresh();
    };

    getDepartmentsByRole = async (roleId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }

        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { roles: roleId },
            },
        });

        runInAction(() => {
            this.departmentsByRole = data.edges?.map((x: any) => {
                return {
                    id: x.node.id,
                    name: x.node.name,
                    costCode: x.node.costCode,
                    costCentre: x.node.costCentre.name,
                    roles: x.node.roles?.flatMap((roleEl: any) =>
                        roleEl?.employeeRole.name ? roleEl.employeeRole?.name : '',
                    ),
                    locations: x.node.locations?.flatMap((locEl: any) =>
                        locEl?.location.name ? locEl.location?.name : '',
                    ),
                    isArchived: x.node.isArchived,
                    costCentreData: x.node.costCentre,
                };
            });
            this.departmentsByRole = _.orderBy(this.departmentsByRole, ['name'], ['asc']);
        });
    };

    getDepartmentsByLocation = async (locationId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { locationId: [locationId], isArchived: false },
            },
        });

        runInAction(() => {
            this.departmentsByLocation = data.edges?.map((x: any) => {
                return {
                    id: x.node.id,
                    name: x.node.name,
                    costCode: x.node.costCode,
                    costCentre: x.node.costCentre?.name,
                    roles: x.node.roles?.flatMap((roleEl: any) =>
                        roleEl?.employeeRole.name ? roleEl.employeeRole.name : '',
                    ),
                    locations: x.node.locations?.flatMap((locEl: any) =>
                        locEl?.location.name ? locEl.location.name : '',
                    ),
                };
            });
            this.departmentsByLocation = _.orderBy(this.departmentsByLocation, ['name'], ['asc']);
        });
    };

    getDepartmentsByCostCenter = async (costCentreId: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                before: this.pageInfo.currentStartCursor,
                after: this.pageInfo.currentEndCursor,
                first:
                    this.pageInfo.currentEndCursor ||
                    (!this.pageInfo.currentStartCursor && !this.pageInfo.currentEndCursor)
                        ? PAGE_SIZE
                        : null,
                last: this.pageInfo.currentStartCursor ? PAGE_SIZE : null,
                orderBy: this.pageInfo.sortBy ?? 'desc',
                field: this.pageInfo.sortColumn ?? 'updatedAt',
                query: this.pageInfo.searchText,
                filter: { costCenters: [costCentreId], isArchived: false },
            },
        });

        runInAction(() => {
            this.departmentsByCostCentre = data.edges?.map((x: any) => {
                return {
                    id: x.node.id,
                    name: x.node.name,
                    isArchived: x.node.isArchived,
                    costCode: x.node.costCode,
                    costCentre: x.node.costCentre?.name,
                    roles: x.node.roles?.flatMap((roleEl: any) =>
                        roleEl?.employeeRole.name ? roleEl.employeeRole.name : '',
                    ),
                    locations: x.node.locations?.flatMap((locEl: any) =>
                        locEl?.location.name ? locEl.location.name : '',
                    ),
                };
            });
            this.departmentsByCostCentre = _.orderBy(
                this.departmentsByCostCentre,
                ['name'],
                ['asc'],
            );
        });
    };

    getServicesByCostCentreId = async (id: string) => {
        const userSession = await this.rootStore.userStore.getUserSession();

        if (!userSession) {
            return;
        }
        const {
            data: { getServicesByCostCentreId },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_BY_COST_CENTRE_ID,
            variables: {
                id,
            },
        });
        return getServicesByCostCentreId.map((x: any) => ({ label: x.name, value: x.id }));
    };

    onSearch = _.debounce((searchText) => {
        runInAction(() => {
            this.pageInfo.searchText = searchText;
            this.refresh();
        });
    }, 400);

    onSort = (sortInfo: any) => {
        this.onCommonSort(sortInfo);
        this.refresh();
    };

    selectAll = () => {
        runInAction(() => {
            this.gridDepartments.forEach((s) => {
                if (!s.isArchived) {
                    this.select(s.id);
                }
            });
        });
    };

    deselectAll = () => {
        this.selected = [];
    };

    get allSelected() {
        return this.departments.every((r: any) => r.isArchived || this.selected.includes(r.id));
    }

    async deleteDepartment(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: DELETE_DEPARTMENT,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Department deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message: 'Sorry, there was a problem deleting department record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async bulkDeleteDepartments(ids: [String]) {
        this.deselectAll();
        try {
            await this.apolloClient.mutate({
                mutation: BULK_DELETE_DEPARTMENTS,
                variables: {
                    ids: ids,
                },
            });
            AppToaster.show({
                message: 'All selected departments deleted successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem deleting departments records. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    async nameIsUnique(name: string) {
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                field: 'updatedAt',
                orderBy: 'desc',
                filter: { name, isArchived: false },
            },
        });

        const result = data.edges.map((x: any) => x.node);

        return result;
    }

    async costCodeIsUnique(costCode: string) {
        const {
            data: { getDepartments: data },
        } = await this.apolloClient.query({
            query: GET_DEPARTMENTS_LIST,
            variables: {
                field: 'updatedAt',
                orderBy: 'desc',
                filter: { costCode, isArchived: false },
            },
        });

        const result = data.edges.map((x: any) => x.node);

        return result;
    }

    async archiveDepartment(id: string) {
        try {
            await this.apolloClient.mutate({
                mutation: ARCHIVE_DEPARTMENT,
                variables: {
                    id,
                },
            });
            AppToaster.show({
                message: 'Department archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving Department record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    }

    bulkArchiveDepartments = async (ids: string[]) => {
        try {
            await this.apolloClient.mutate({
                mutation: BULK_ARCHIVE_DEPARTMENTS,
                variables: {
                    ids,
                },
            });
            AppToaster.show({
                message: 'Department archived successfully!',
                intent: 'success',
            });
        } catch (err) {
            AppToaster.show({
                message:
                    'Sorry, there was a problem archiving Departments record. Please try again.',
                intent: 'danger',
            });
        }
        await this.refresh();
    };

    isDepartmentArchivable = async (ids: string[]) => {
        const {
            data: { isDepartmentArchivable: data },
        } = await this.apolloClient.query({
            query: IS_DEPARTMENT_ARCHIVABLE,
            variables: {
                ids,
            },
        });

        const result = data.map((x: any) => ({
            id: x.id,
            name: x.name,
            isArchivable: x.isArchivable,
        }));

        return result;
    };

    async isRoleInUse(departmentId: string, roleId: string) {
        const {
            data: { isRoleInUse },
        } = await this.apolloClient.query({
            query: IS_ROLE_IN_USE,
            variables: {
                departmentId,
                roleId,
            },
        });

        return isRoleInUse;
    }

    async isLocationInUse(departmentId: string, locationId: string) {
        const {
            data: { isLocationInUse },
        } = await this.apolloClient.query({
            query: IS_LOCATION_IN_USE,
            variables: {
                departmentId,
                locationId,
            },
        });

        return isLocationInUse;
    }
}

export default DepartmentsStore;
