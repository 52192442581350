import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Icon } from '@blueprintjs/core';

import AdminUserDetails from '../../modules/users/detail/AdminUserDetails';

const User = ({ onClick, userId, userName, selectedUser, enabled }) => {
    const status = enabled ? 'enabled' : 'disabled';

    return (
        <tr
            onClick={(event) => onClick(userId, event)}
            className={selectedUser === userId ? 'users-table__active-row' : ''}
        >
            <td>{userName}</td>
            <td>{userId}</td>
            <td>
                <span className={`users-list__status users-list__status--${status}`}>
                    <Icon icon={enabled ? 'tick-circle' : 'ban-circle'} />
                    <span className="users-list__status-label">{status}</span>
                </span>
            </td>
        </tr>
    );
};

const AdminManageUsers = inject('RootStore')(
    observer(
        class AdminManageUsers extends Component {
            _handleUserClick = (userId) => {
                this.props.history.push(`/manage-users/${userId}`);
            };

            render() {
                const {
                    RootStore: {
                        usersStore: { allUsers },
                        userStore: { isComplianceOfficer },
                    },
                    match: {
                        params: { userId },
                    },
                } = this.props;

                if (isComplianceOfficer) {
                    return <Redirect to="/page-not-found" />;
                }

                return (
                    <section className="users users--admin">
                        <main className="users__main">
                            <table className="bp5-html-table bp5-html-table-striped bp5-interactive users-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>User ID</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allUsers
                                        .filter((user) => user.accountType === 'unified')
                                        .sort(
                                            (a, b) =>
                                                // Sort by enabled status and then alphabetically by User ID
                                                b.enabled - a.enabled ||
                                                a.userId.localeCompare(b.userId),
                                        )
                                        .map((user) => (
                                            <User
                                                {...user}
                                                onClick={this._handleUserClick}
                                                currentUser={userId}
                                                key={user.userId}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        </main>
                        {userId && (
                            <div className="users__modal">
                                <AdminUserDetails {...this.props} />
                            </div>
                        )}
                    </section>
                );
            }
        },
    ),
);

export default AdminManageUsers;
