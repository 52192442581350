import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
    query GetDocumentCategories($filter: DocumentCategoryFilter!) {
        getDocumentCategories(filter: $filter) {
            id
            name
            role
            createdAt
            updatedAt
        }
    }
`;
