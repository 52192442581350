import {
    Alert,
    Callout,
    FormGroup,
    HTMLSelect,
    Intent,
    NumericInput,
    Radio,
    Tab,
    Tabs,
    Tag,
    TextArea,
} from '@blueprintjs/core';
import { DateInput3 } from '@blueprintjs/datetime2';
import { TimePicker } from '@blueprintjs/datetime';
import { Hub, JobStatus, Vaccination, VaccinationCategory } from '@doc-abode/data-models';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getJob } from '../../../../../api/jobsApi';
import { reassignRoute } from '../../../../../api/routesApi';
import { IHcp } from '../../../../../interfaces';
import {
    formatDisplayDate,
    formatDisplayDateTime,
    formatDisplayTime,
    parseDateStringToJSDate,
} from '../../../../modules/helpers/formatData';
import Loader from '../../../../modules/helpers/Loader';
import Modal from '../../../../modules/modal/Modal';
import { VaccinationRoute, VaccinationWithWarnings } from '../../types';
import {
    allowEditStatus,
    checkDateTimeWarnings,
    friendlyRouteTypes,
    getValidationErrors,
    getWarnings,
    routeStatusMappings,
    routeStatusTags,
    routeTypes,
} from '../../utils';
import Error from '../../../../modules/forms/Error';
// direct to specific files to avoid circular references.
// TODO i think this is the Itinerary Initialization error in development.
import { Itinerary } from './Itinerary/Itinerary';
import { MapWrapper } from './MapWrapper';
import { PickupSummary } from './PickupSummary';
import { RouteDetailsFooter } from './RouteDetailsFooter';
import { Warnings } from './Warnings';
import { ConditionalDisplay } from '../../../../CondtionalDisplay';
import { shouldShowPostJobNotes } from '../../../../../helpers/shouldShowPostJobNotes';

interface Props {
    onClose: () => void;
    loading: boolean;
    getAuthToken: () => Promise<string>;
    abortJob: (
        data: Vaccination,
        controllerAbortedReason: string,
        controllerAbortedNotes: string,
    ) => void;
    editJob: (data: Vaccination) => void;
    setJobs: () => void;
    setVaccinations: () => void;
    hubs: Hub[];
    jobId: string;
    users: IHcp[];
    allPatients: Vaccination[];
    select: (id: string) => void;
    deselectAll: () => void;
    apiKey: string;
    isSuperuser: boolean;
}

export const RouteDetails: FC<Props> = ({
    onClose,
    loading,
    getAuthToken,
    abortJob,
    editJob,
    setJobs,
    setVaccinations,
    hubs,
    jobId,
    users,
    allPatients,
    select,
    deselectAll,
    apiKey,
    isSuperuser,
}) => {
    const [fetchingRouteDetails, setFetchingRouteDetails] = useState(false);
    const [aborting, setAborting] = useState(false);
    const [saving, setSaving] = useState(false);
    const [editing, setEditing] = useState(false);
    const [routeDetails, setRouteDetails] = useState<VaccinationRoute | null>(null);
    const [routeDetailsLoaded, setRouteDetailsLoaded] = useState(false);
    const [showAbortPrompt, setShowAbortPrompt] = useState(false);
    const [abortNotes, setAbortNotes] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedStartTime, setSelectedStartTime] = useState<Date>(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null | undefined>(new Date());
    const [loadingPatientsOnRoute, setLoadingPatientsOnRoute] = useState(true);
    const [patientsOnRoute, setPatientsOnRoute] = useState<Vaccination[]>([]);
    const [covidVaccinesOnRoute, setCovidVaccinesOnRoute] = useState<Vaccination[]>([]);
    const [daysSincePreviousDoseWarnings, setDaysSinceFirstDoseWarnings] = useState<
        VaccinationWithWarnings[]
    >([]);
    const [selectedCapacity, setSelectedCapacity] = useState<number>(0);
    const [validationErrors, setValidationErrors] = useState<string[]>([]);
    const [hub, setHub] = useState<Hub | undefined>(undefined);
    const [hubOptions, setHubOptions] = useState<{ label: string; value: string }[]>([]);
    const history = useHistory();
    const { state } = useLocation<{
        scrollTop?: number;
        from?: string;
    }>();
    const modalRef = useRef<any>();
    const [selectedDateTimeWarnings, setSelectedDateTimeWarnings] = useState<string[]>([]);
    /**
     * our state takes date - the DateInput passes a string to the onChange handler - i think this passing of string rather than Date is due to Blueprint js upgrade
     */
    const convertDateForStateSetter = (dateString: string | null | undefined) => {
        let date;
        if (dateString) {
            date = new Date(dateString);
        }
        setSelectedDate(date);
    };
    const fetchRoute = useCallback(async () => {
        setFetchingRouteDetails(true);
        const authToken = await getAuthToken();
        const response = (await getJob(authToken, jobId)) as {
            Items: VaccinationRoute[];
        };
        const routeDetails = response.Items[0];
        setRouteDetails(routeDetails);
        setSelectedUser(routeDetails.itinerary.agent.name);
        setSelectedStartTime(new Date(routeDetails.itinerary.agent.shifts[0].startTime));
        setSelectedEndTime(new Date(routeDetails.itinerary.agent.shifts[0].endTime));
        // todo when we have time/ in another ticket  it may make more sense  to have the selected date set to the start time.
        // as long as the start time carries the correct date info
        setSelectedDate(new Date(routeDetails.itinerary.agent.shifts[0].endTime));
        setSelectedCapacity(routeDetails.itinerary.agent.capacity[0]);
        setFetchingRouteDetails(false);
        setRouteDetailsLoaded(true);
        return routeDetails;
    }, [jobId, getAuthToken]);

    useLayoutEffect(() => {
        if (!loadingPatientsOnRoute && state?.scrollTop && modalRef?.current) {
            modalRef.current.scrollTop = state.scrollTop;
        }
    }, [loadingPatientsOnRoute, state?.scrollTop]);

    useEffect(() => {
        if (!routeDetailsLoaded) {
            fetchRoute();
        }
    }, [routeDetailsLoaded, fetchRoute]);

    useLayoutEffect(() => {
        if (state?.from === 'Recalculation') {
            setEditing(true);
        }
    }, [state?.from]);

    // as allPatients is not  ALL patients (it just ones filtered by filterableJobStatuses)
    // we need to get any patients we don't have in allPatients from the api/gql
    // looks like we try to find the patient and if we don't have it already we go to the call the api for
    // the missing patient(s), this looks like its done one at a time.
    const getAllPatients = useCallback(
        (patientIds: string[]): Promise<Vaccination[]> => {
            return Promise.all(
                (patientIds || [])?.map(
                    async (patientId: string): Promise<Vaccination> => {
                        const patient = allPatients.find((patient) => patient.id === patientId);

                        if (patient) {
                            return patient;
                        }
                        const authToken = await getAuthToken();
                        const response = await getJob(authToken, patientId);

                        return response.Items[0] as Vaccination;
                    },
                ),
            );
        },
        [allPatients, getAuthToken],
    );

    useEffect(() => {
        const getPatients = async () => {
            const patients = (await getAllPatients(patientIds)) as Vaccination[];
            setPatientsOnRoute(patients);
            const covidVaccines = patients.filter(
                ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
            );
            setCovidVaccinesOnRoute(covidVaccines);
            setLoadingPatientsOnRoute(false);
            setHubOptions(
                Array.from(new Set(patients.map((patient) => patient.hubId)))
                    .map((hubId) => hubs.find((hub) => hub.id === hubId))
                    .map((hub) => ({
                        label: hub?.name || '',
                        value: hub?.id || '',
                    })),
            );
        };

        const patientIds: string[] = routeDetails?.itinerary.instructions
            .map(
                (instruction) =>
                    instruction.instructionType === 'VisitLocation' &&
                    instruction.itineraryItem?.name,
            )
            .filter((_) => _) as string[];

        if (patientIds && patientIds.length > 0) {
            getPatients();
        }

        setHub(hubs.find((hub) => hub.id === routeDetails?.hubId));
    }, [routeDetails, getAllPatients, hubs]);

    useEffect(() => {
        const fetchWarnings = async () => {
            const warnings = await getWarnings(patientsOnRoute, selectedDate?.toISOString(), {});
            const validationErrors = getValidationErrors({
                selectedDate: selectedDate?.toISOString(),
                selectedStartTime: selectedStartTime.toISOString(),
                selectedEndTime: selectedEndTime.toISOString(),
                selectedCapacity,
                routeDetails,
                isSuperuser,
            });
            const selectDateTimeWarnings = checkDateTimeWarnings(
                selectedDate?.toISOString(),
                selectedStartTime.toISOString(),
                selectedEndTime?.toISOString(),
            );

            setSelectedDateTimeWarnings(selectDateTimeWarnings);
            setDaysSinceFirstDoseWarnings(warnings);
            setValidationErrors(validationErrors);
        };

        fetchWarnings();
    }, [
        patientsOnRoute,
        selectedDate,
        selectedStartTime,
        selectedEndTime,
        selectedCapacity,
        routeDetails,
        isSuperuser,
    ]);

    if (loading || fetchingRouteDetails) {
        return <Modal title="Loading route details..." onClose={onClose} shadow />;
    }

    if (!routeDetails) {
        return <Modal title="Route not found" onClose={onClose} shadow />;
    }

    const {
        jobStatus,
        itinerary,
        createDateTime,
        expiryDateTime,
        hubId,
        hcpAbortedReason,
        hcpAbortedNotes,
        controllerAbortedNotes,
        createdBy,
        lastUpdatedBy,
        routeType,
        postVisitNotes,
    } = routeDetails;

    const expired = moment(expiryDateTime).isBefore(moment());

    const isCovidRoute = patientsOnRoute.some(
        ({ vaccinationCategory }) => vaccinationCategory === VaccinationCategory.COVID_19,
    );

    const {
        agent: { name: userId, capacity, shifts },
        route: { startTime, endTime, totalTravelDistance, totalTravelTime },
        instructions,
        itineraryId,
    } = itinerary;

    const changes: string[] = [];

    if (userId !== selectedUser) {
        changes.push('USER_ID');
    }

    if (selectedCapacity !== capacity[0]) {
        changes.push('CAPACITY');
    }

    const newEndTime = moment(selectedDate)
        .hour(moment(selectedEndTime).hour())
        .minute(moment(selectedEndTime).minute())
        .toDate();

    if (!moment(routeDetails.itinerary.agent.shifts[0].endTime).isSame(newEndTime)) {
        changes.push('DATE');
    }

    if (hub?.id !== hubId) {
        changes.push('HUB');
    }

    const newStartTime = moment(selectedDate)
        .hour(moment(selectedStartTime).hour())
        .minute(moment(selectedStartTime).minute())
        .toDate();

    if (!moment(routeDetails.itinerary.agent.shifts[0].startTime).isSame(newStartTime)) {
        changes.push('START_TIME');
    }

    if (
        allowEditStatus.includes(jobStatus) &&
        (!moment(routeDetails.itinerary.agent.shifts[0].endTime).isSame(newEndTime) ||
            !moment(routeDetails.itinerary.agent.shifts[0].startTime).isSame(selectedStartTime) ||
            hub?.id !== hubId)
    ) {
        changes.push('RECALCULATE');
    }

    const isHcpUneditable = [
        JobStatus.CURRENT,
        JobStatus.ARRIVED,
        JobStatus.COMPLETED,
        JobStatus.CONTROLLER_ABORTED,
    ].includes(jobStatus);

    const onAbort = async () => {
        setShowAbortPrompt(false);
        setAborting(true);
        try {
            await abortJob(routeDetails, 'Job removed from Doc Abode by controller', abortNotes);
            setVaccinations();
            await setJobs();
            await fetchRoute();
        } catch (err) {
            console.error(err);
        }
        setAborting(false);
    };

    const onChangeCapacity = (valueAsNumber: number) => {
        if (valueAsNumber < covidVaccinesOnRoute.length) {
            setSelectedCapacity(covidVaccinesOnRoute.length);
        } else {
            setSelectedCapacity(valueAsNumber);
        }
    };
    const onChangeHub = (event: { currentTarget: { value: string } }) => {
        setHub(hubs.find((hub) => hub.id === event.currentTarget.value));
    };

    const onResubmit = async () => {
        deselectAll();
        patientsOnRoute.forEach((patient) => {
            if (patient.jobStatus === 'PENDING') {
                select(patient.id);
            }
        });
        history.push('/vaccinations/routes/new', { selectedDate });
    };

    const onSave = async () => {
        const params = {};
        const hubObj = {
            ...hub,
            compatibleWith: null,
        };

        if (changes.length === 2 && changes.includes('HUB') && changes.includes('RECALCULATE')) {
            history.push('/vaccinations/routes/recalculate', {
                route: routeDetails,
                selectedStartTime: moment(newStartTime).toISOString(),
                hub: hubObj,
            });
            return;
        }

        if (changes.includes('USER_ID')) {
            Object.assign(params, { userId: selectedUser });
        }

        if (changes.includes('CAPACITY')) {
            Object.assign(params, { capacity: selectedCapacity });
        }

        if (changes.includes('DATE')) {
            // bit of a hack to minimise the extent of the changes from having to have a separate value for selectedEndTime
            let updatedDate = moment(selectedDate)
                .hour(moment(selectedEndTime).hour())
                .minute(moment(selectedEndTime).minute());
            Object.assign(params, { date: moment.utc(updatedDate).format() });
        }

        if (changes.includes('START_TIME')) {
            Object.assign(params, { startTime: moment.utc(newStartTime).format() });
        }

        setSaving(true);
        setEditing(false);

        try {
            const authToken = await getAuthToken();
            await reassignRoute(authToken, itineraryId, params);
            const updatedRouteDetails = await fetchRoute();
            setJobs();
            if (changes.includes('RECALCULATE')) {
                history.push('/vaccinations/routes/recalculate', {
                    route: updatedRouteDetails,
                    selectedStartTime: moment(newStartTime).toISOString(),
                    hub: changes.includes('HUB') ? hubObj : undefined,
                    userId: changes.includes('USER_ID') ? selectedUser : undefined,
                });
            }
        } catch (err) {
            setEditing(true);
            console.error(err);
        }
        setSaving(false);
    };

    const title = (
        <>
            {editing ? (
                <span className="modal__heading-text">Change route options</span>
            ) : (
                <span className="modal__heading-text">
                    {userId} - {formatDisplayDate(startTime)}
                </span>
            )}
            <Tag
                intent={routeStatusTags[jobStatus] as Intent}
                minimal={jobStatus !== 'COMPLETED'}
                large
            >
                {routeStatusMappings[jobStatus]}
            </Tag>
        </>
    );

    const showPostJobNotes = shouldShowPostJobNotes({ jobStatus: jobStatus });

    return (
        <Modal
            title={title}
            onClose={onClose}
            forwardedRef={modalRef}
            footer={
                saving ? null : (
                    <RouteDetailsFooter
                        setShowAbortPrompt={setShowAbortPrompt}
                        setEditing={setEditing}
                        aborting={aborting}
                        jobStatus={jobStatus}
                        editing={editing}
                        onSave={onSave}
                        hasChanges={changes.length > 0}
                        hasErrors={validationErrors.length > 0}
                        onResubmit={onResubmit}
                        isSuperuser={isSuperuser}
                    />
                )
            }
            shadow
            showPrintButton
        >
            {saving ? (
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">Updating route...</p>
                </div>
            ) : loadingPatientsOnRoute ? (
                <div className="vaccinations__loading">
                    <Loader fullscreen={false} />
                    <p className="create-routes__please-wait">Loading route details...</p>
                </div>
            ) : editing ? (
                <>
                    {daysSincePreviousDoseWarnings.length > 0 && (
                        <Callout intent="danger" className="create-routes__callout">
                            The following patients have validation warnings, please see below for
                            details.
                            <Warnings warnings={daysSincePreviousDoseWarnings} />
                        </Callout>
                    )}
                    <h3 className="h3">Route options</h3>
                    <div className="create-routes__select-date-time">
                        <div className="create-routes__date-time-controls">
                            <FormGroup label="Date" labelFor="date">
                                <DateInput3
                                    showTimezoneSelect={false}
                                    inputProps={{ id: 'date' }}
                                    formatDate={formatDisplayDate}
                                    parseDate={parseDateStringToJSDate}
                                    onChange={convertDateForStateSetter}
                                    value={selectedDate?.toISOString() || null}
                                    maxDate={moment().add(1, 'year').toDate()}
                                />
                            </FormGroup>
                            <FormGroup label="Start time">
                                <TimePicker
                                    onChange={setSelectedStartTime}
                                    value={selectedStartTime}
                                    selectAllOnFocus
                                />
                            </FormGroup>
                            <FormGroup label="End time">
                                <TimePicker
                                    onChange={setSelectedEndTime}
                                    value={selectedEndTime}
                                    selectAllOnFocus
                                />
                            </FormGroup>
                            {isCovidRoute && (
                                <FormGroup label="Max. doses *">
                                    <NumericInput
                                        className="create-routes__numeric-input"
                                        onValueChange={onChangeCapacity}
                                        value={selectedCapacity}
                                        min={covidVaccinesOnRoute.length}
                                        selectAllOnFocus
                                        large
                                    />
                                </FormGroup>
                            )}
                            <FormGroup label="Start/end location">
                                <HTMLSelect
                                    onChange={onChangeHub}
                                    value={hub?.id}
                                    large
                                    options={hubOptions}
                                />
                            </FormGroup>
                        </div>
                        <Error errors={validationErrors} />
                    </div>
                    <h3 className="h3">Assigned HCP</h3>
                    <table className="bp5-html-table users-table bp5-interactive">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Name</th>
                                <th>User ID</th>
                                <th>Role(s)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users
                                .filter((user) => user.enabled && user.roles?.includes('hcp'))
                                .map(({ userId, userName, hcpTypes }) => {
                                    const selected = selectedUser === userId;
                                    const onSelect = () => setSelectedUser(userId);

                                    return (
                                        <tr
                                            key={userId}
                                            className={`users-table__row ${
                                                selected ? 'users-table__row--selected' : ''
                                            }`}
                                            onClick={() => {
                                                if (!isHcpUneditable) {
                                                    onSelect();
                                                }
                                            }}
                                        >
                                            <td>
                                                <Radio
                                                    id={userId}
                                                    checked={selected}
                                                    onChange={onSelect}
                                                    disabled={isHcpUneditable}
                                                />
                                            </td>
                                            <td>{userName}</td>
                                            <td>{userId}</td>
                                            <td>{hcpTypes.join(', ')}</td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    {selectedDateTimeWarnings.length > 0 && (
                        <Callout intent="warning" className="create-routes__callout">
                            {selectedDateTimeWarnings.map((warning) => (
                                <li key={warning}>{warning}</li>
                            ))}
                        </Callout>
                    )}
                </>
            ) : (
                <>
                    <dl className="info">
                        <dt className="info__title">Route type</dt>
                        <dd className="info__definition">
                            {friendlyRouteTypes[routeType as keyof typeof friendlyRouteTypes]}
                        </dd>
                        <dt className="info__title">Number of patients</dt>
                        <dd className="info__definition">
                            {
                                instructions.filter(
                                    ({ instructionType }) => instructionType === 'VisitLocation',
                                ).length
                            }
                        </dd>
                        {routeType === routeTypes.COVID && (
                            <>
                                <dt className="info__title">Max. doses</dt>
                                <dd className="info__definition">{capacity[0]}</dd>
                            </>
                        )}
                        <dt className="info__title">Shift start/end time</dt>
                        <dd className="info__definition">
                            {formatDisplayTime(shifts[0].startTime)} to{' '}
                            {formatDisplayTime(shifts[0].endTime)}
                        </dd>
                        <dt className="info__title">Route start/end time</dt>
                        <dd className="info__definition">
                            {formatDisplayTime(startTime)} to {formatDisplayTime(endTime)}{' '}
                            (estimated)
                        </dd>
                        <dt className="info__title">Total travel distance</dt>
                        <dd className="info__definition">
                            {((totalTravelDistance / 1000 / 8) * 5).toFixed(1)} miles
                        </dd>
                        <dt className="info__title">Total travel duration</dt>
                        <dd className="info__definition">{totalTravelTime}</dd>
                        <ConditionalDisplay show={showPostJobNotes}>
                            <dt className="info__title">Post-job notes</dt>
                            <dd className="info__definition info__definition--notes">
                                {postVisitNotes}
                            </dd>
                        </ConditionalDisplay>
                        <dt className="info__title">Created on</dt>
                        <dd className="info__definition">
                            {formatDisplayDateTime(createDateTime)}
                        </dd>
                        <dt className="info__title">Created by</dt>
                        <dd className="info__definition">{createdBy}</dd>
                        {lastUpdatedBy && (
                            <>
                                <dt className="info__title">Last updated by</dt>
                                <dd className="info__definition">{lastUpdatedBy}</dd>
                            </>
                        )}
                        {jobStatus === 'AVAILABLE' && (
                            <>
                                <dt
                                    className={`info__title ${
                                        expired ? 'info__title--danger' : ''
                                    }`}
                                >
                                    Expiry
                                </dt>
                                <dd
                                    className={`info__definition ${
                                        expired ? 'info__title--danger' : ''
                                    }`}
                                >
                                    {formatDisplayDateTime(expiryDateTime)}
                                </dd>
                            </>
                        )}
                        {jobStatus === 'HCP_ABORTED' && (
                            <>
                                <dt className="info__title info__title--danger">
                                    Reason for aborting
                                </dt>
                                <dd className="info__definition info__definition--danger">
                                    {hcpAbortedReason}
                                    {hcpAbortedNotes && (
                                        <>
                                            <br />
                                            {hcpAbortedNotes}
                                        </>
                                    )}
                                </dd>
                            </>
                        )}
                        {jobStatus === 'CONTROLLER_ABORTED' && controllerAbortedNotes && (
                            <>
                                <dt className="info__title info__title--danger">
                                    Reason for aborting
                                </dt>
                                <dd className="info__definition info__definition--danger">
                                    {controllerAbortedNotes}
                                </dd>
                            </>
                        )}
                    </dl>
                    <div className="bp5-callout route-details__pickup-summary-container">
                        <PickupSummary
                            vaccinationRoute={routeDetails}
                            patientsOnRoute={patientsOnRoute}
                        />
                    </div>
                    <Tabs
                        id="RouteDetails"
                        defaultSelectedTabId="itinerary"
                        renderActiveTabPanelOnly
                        large
                    >
                        <Tab
                            id="itinerary"
                            title="Itinerary"
                            panel={
                                <Itinerary
                                    hub={hub}
                                    getAuthToken={getAuthToken}
                                    instructions={instructions}
                                    routeItineraryId={itineraryId}
                                    routeStatus={jobStatus}
                                    modalRef={modalRef}
                                />
                            }
                        />
                        <Tab
                            id="map"
                            title="Map"
                            panel={<MapWrapper itineraries={[itinerary]} apiKey={apiKey} />}
                        />
                    </Tabs>
                </>
            )}
            <Alert
                isOpen={showAbortPrompt}
                onConfirm={onAbort}
                onCancel={() => setShowAbortPrompt(false)}
                cancelButtonText="Cancel"
                confirmButtonText="Confirm"
                icon="warning-sign"
                intent="danger"
            >
                <p>
                    <strong>Are you sure you want to abort this route?</strong>
                </p>
                <FormGroup
                    label="You can enter a reason for aborting or any other information here:"
                    labelFor="abortNotes"
                >
                    <TextArea
                        id="abortNotes"
                        onChange={(event) => setAbortNotes(event.target.value)}
                        fill
                        value={abortNotes}
                    />
                </FormGroup>
            </Alert>
        </Modal>
    );
};
