import { useEffect } from 'react';

interface Props {
    onChange: (value: any) => void;
    value: any;
}

const FormikObserver = (props: Props) => {
    useEffect(() => {
        props.onChange(props.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Object.values(props.value).join(', ')]);
    return null;
};

export default FormikObserver;
