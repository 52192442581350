import { Patient, JobStatus } from '@doc-abode/data-models';

export const getIsAborted = (job: Patient, isSecondDoubleUpHcp: boolean) => {
    return isSecondDoubleUpHcp
        ? job.buddyJobStatus === JobStatus.CONTROLLER_ABORTED ||
              job.buddyJobStatus === JobStatus.HCP_ABORTED
        : job.jobStatus === JobStatus.CONTROLLER_ABORTED || job.jobStatus === JobStatus.HCP_ABORTED;
};

export const getDoubleUpWithoutStaff = (job: Patient) => {
    return (job.staffRequired === 2 && !job.buddyId) || (job.staffRequired === 2 && !job.hcpId);
};

export const hasThirdIconsRow = (job: Patient, isSecondDoubleUpHcp: boolean): boolean => {
    const isNonEnlishSpoken = job?.languagesSpoken?.length !== 0;
    const isWithNotes = Boolean(job?.notes);

    return !getIsAborted(job, isSecondDoubleUpHcp) && (isNonEnlishSpoken || isWithNotes);
};

export const hasSecondIconsRow = (job: Patient, isSecondDoubleUpHcp: boolean): boolean => {
    const isDoubleUpVisit = Boolean(job.staffRequired === 2 && job.buddyId && job.hcpId);
    const isDoubleUpWithoutStaff = getDoubleUpWithoutStaff(job);
    const isCarRequired = job.carRequired;
    const isCareComplex = job.careComplexity === 'complex';

    return (
        !getIsAborted(job, isSecondDoubleUpHcp) &&
        (isDoubleUpVisit || isDoubleUpWithoutStaff || isCarRequired || isCareComplex)
    );
};
