import React, { Fragment, useEffect, useState } from 'react';
import { FormGroup, InputGroup, RadioGroup, Radio } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';

import Select from 'react-select';
import Error from '../../forms/Error';
import { TimePicker } from '@blueprintjs/datetime';

import { formatTime } from '../../helpers/FormatTime';
import _ from 'lodash';
import moment from 'moment';
import {
    EMPLOYEE_RECORD_ACTIVE_STATUS_ID,
    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID,
    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID,
} from '../../../../constants/hrConst';
import { ErrorMessage, FieldArray } from 'formik';
import Warnings from '../../../v2/form/Warnings';
import CommentsSection from './CommentsSection';
import { DAYS } from '../../../../constants/rotaConst';
import { formatDisplayDate } from '../../helpers/formatData';
import { repeatsDays } from '../../shiftPatterns/detail/constants';

const ShiftPatternEntry = ({
    shiftPatternEntry,
    editOrCreateMode,
    values,
    handleChange,
    setFieldValue,
    errors,
    touched,
    currentUser,
    updateShiftPatternComments,
    locationOptions,
    setTitle,
    RootStore: {
        payRatesStore,
        staffStore,
        departmentsStore,
        shiftPatternsStore,
        rolesStore,
        functionsStore,
        fundingPoolStore,
        dictionaryStore: {
            rolesDictionary,
            functionsDictionary,
            shiftTypesDictionary,
            shiftTypes,
            locationsDictionary,
        },
    },
}) => {
    const [roleOptions, setRoleOptions] = useState([]);
    const [functionOptions, setFunctionOptions] = useState([]);
    const listOfComments = shiftPatternEntry ? shiftPatternEntry.comments : [];
    const fundingPoolsDictionary = {};
    const fundingPoolsFiltered = fundingPoolStore.allFundingPools
        .filter((item) => !item.isArchived)
        .map((item) => {
            fundingPoolsDictionary[item.id] = item.name;
            return { label: item.name, value: item.id };
        });

    const [payRates, setPayRates] = React.useState(
        editOrCreateMode ? [] : payRatesStore.gridPayRatesByRoles,
    );
    const [payRateUpdateWarning, setPayRateUpdateWarning] = React.useState('');

    const updatePayRate = async () => {
        if (payRatesStore.payRatesByRole.length) {
            setFieldValue('payRateId', payRatesStore.payRatesByRole[0]?.id);
        } else {
            setFieldValue('payRateId', null);
        }
    };

    const updateNotPublishedComments = (comments) => {
        setFieldValue('comments', comments);
    };

    const selectedDepartment = departmentsStore.allDepartments.filter(
        (item) => item.id === shiftPatternsStore.newShiftPattern.departmentId,
    );

    const staffsList = staffStore.allStaffs.filter((item) => {
        return selectedDepartment[0]?.employees?.some((emp) => item.id === emp.employeeRecord.id);
    });

    const payRatesList = payRatesStore.allPayRates?.reduce((acc, item) => {
        acc[item.id] = item.name;
        return acc;
    }, {});

    const staffWithFilteredRoles = staffsList.filter(
        (item) =>
            item.departments.length &&
            item.departments.filter(
                (department) => department.departmentId === selectedDepartment[0].id,
            ) &&
            item.roles.length &&
            item.roles[0].employeeRoleTypeId === values.shiftEmployeeRoleTypeId &&
            (item.statusId === EMPLOYEE_RECORD_ACTIVE_STATUS_ID ||
                item.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID),
    );

    const staffWithFilteredRolesAndArchivedStatus = staffsList.filter(
        (item) =>
            item.departments.length &&
            item.departments.map(
                (department) => department.departmentId === selectedDepartment[0].id,
            ) &&
            item.roles.length &&
            item.roles[0].employeeRoleTypeId === values.shiftEmployeeRoleTypeId &&
            (item.statusId === EMPLOYEE_RECORD_ACTIVE_STATUS_ID ||
                item.statusId === EMPLOYEE_RECORD_SUSPENDED_STATUS_ID ||
                item.statusId === EMPLOYEE_RECORD_ARCHIVED_STATUS_ID),
    );

    const staffListWithArchivedStatus = staffWithFilteredRolesAndArchivedStatus.reduce(
        (acc, item) => {
            acc[item.id] =
                item.employeeStatus?.name.includes('Suspended') ||
                item.employeeStatus?.name.includes('Archived')
                    ? item.person.firstName +
                      ' ' +
                      item.person.lastName +
                      ' - ' +
                      item.employeeStatus?.name
                    : item.person.firstName + ' ' + item.person.lastName;
            return acc;
        },
        {},
    );

    const staffListWithFilteredRoles = staffWithFilteredRoles.map((staff) => ({
        label: staff.employeeStatus?.name.includes('Suspended')
            ? staff.person.firstName +
              ' ' +
              staff.person.lastName +
              '-(' +
              staff.employeeStatus?.name +
              ')'
            : staff.person.firstName + ' ' + staff.person.lastName,
        value: staff.id,
    }));

    const checkIsSupervisorStaff = (val) =>
        staffWithFilteredRoles.filter((staff) => staff.isSupervisor && staff.id === val).length;

    const makeChangeIsSupervisorStaff = async (val) =>
        staffWithFilteredRoles.filter((staff) => staff.isSupervisor && staff.id === val).length
            ? setFieldValue('trainingShift', true)
            : _.values(values.trainees[0]).every(_.isEmpty)
            ? setFieldValue('trainingShift', false) &&
              setFieldValue('trainees', [
                  {
                      traineeId: '',
                      roleId: '',
                      overrideValue: null,
                      payRateId: '',
                  },
              ])
            : null;

    if (editOrCreateMode) {
        if (staffListWithArchivedStatus[values.defaultEmployeeId]?.includes('-(Archived)')) {
            setFieldValue('defaultEmployeeId', null);
        }
        if (staffListWithArchivedStatus[values.trainees[0]?.traineeId]?.includes('-(Archived)')) {
            setFieldValue(`trainees.${0}.traineeId`, null);
        }
    }

    const weeksList = shiftPatternsStore.newShiftPattern.weeks.map((item) => ({
        value: item,
        label: item,
    }));

    const weeksListSorted = _.orderBy(weeksList, ['label'], ['asc']);

    useEffect(() => {
        const updateRoleOptions = async () => {
            const roles = await rolesStore.getRolesByDepartmentAndFunctions(
                shiftPatternsStore.newShiftPattern.departmentId,
                values.shiftFunctions?.length ? values.shiftFunctions : undefined,
            );
            setRoleOptions([{}, ...roles]);
        };
        setRoleOptions([]);
        updateRoleOptions();
    }, [rolesStore, shiftPatternsStore.newShiftPattern.departmentId, values.shiftFunctions]);

    useEffect(() => {
        const updateFunctionOptions = async () => {
            const functions = await functionsStore.getFunctionsByDepartmentAndRole(
                shiftPatternsStore.newShiftPattern.departmentId,
                values.shiftEmployeeRoleTypeId?.length ? values.shiftEmployeeRoleTypeId : undefined,
            );
            setFunctionOptions(functions);
        };
        setFunctionOptions([]);
        updateFunctionOptions();
    }, [
        functionsStore,
        shiftPatternsStore.newShiftPattern.departmentId,
        values.shiftEmployeeRoleTypeId,
    ]);

    useEffect(() => {
        const updatePayRate = async () => {
            const employee = await staffStore.getStaffRecordById(values.defaultEmployeeId);
            const departmentId = shiftPatternsStore.newShiftPattern.departmentId;
            const currentDate = moment(new Date());
            const filteredDepartments = employee.departments.filter(
                (dep) =>
                    dep.departmentId === departmentId &&
                    !dep.isArchived &&
                    currentDate.isSameOrAfter(dep.startDateOfAssignment, 'day') &&
                    (currentDate.isSameOrBefore(dep.endDateOfAssignment, 'day') ||
                        dep.endDateOfAssignment === null),
            );
            const currentPayRate =
                filteredDepartments.length &&
                filteredDepartments[0].payRates.filter(
                    (payRate) =>
                        currentDate.isSameOrAfter(payRate.startDate, 'day') &&
                        (currentDate.isSameOrBefore(payRate.endDateOfAssignment, 'day') ||
                            payRate.endDateOfAssignment === null),
                );
            const futurePayRate =
                filteredDepartments.length &&
                filteredDepartments[0].payRates.filter(
                    (payRate) =>
                        currentDate.isBefore(payRate.startDate, 'day') && payRate.endDate === null,
                );
            if (currentPayRate.length) {
                setFieldValue('payRateId', currentPayRate[0].payRate.id);
                if (futurePayRate.length) {
                    setPayRateUpdateWarning(
                        `On ${formatDisplayDate(
                            futurePayRate[0].startDate,
                        )} pay rate will change to ${futurePayRate[0].payRate.name}`,
                    );
                }
            }
        };

        if (values.defaultEmployeeId) {
            updatePayRate();
        } else {
            setPayRateUpdateWarning('');
        }
    }, [
        payRatesList,
        setFieldValue,
        staffStore,
        values.defaultEmployeeId,
        shiftPatternsStore.newShiftPattern.departmentId,
    ]);

    useEffect(() => {
        const dayOfWeek = repeatsDays[values.dayOfWeek];

        const title =
            shiftPatternsStore.entryActionType === 'create'
                ? `Create shift ${dayOfWeek ? '- ' + dayOfWeek + ',' : ''} ${
                      values.weekNumber ? 'week ' + values.weekNumber : ''
                  }`
                : rolesDictionary[values.shiftEmployeeRoleTypeId];

        setTitle(title);
    }, [
        rolesDictionary,
        setTitle,
        shiftPatternsStore.entryActionType,
        values.dayOfWeek,
        values.shiftEmployeeRoleTypeId,
        values.weekNumber,
    ]);

    const getShiftPatientFacingWarnings = async (val, setFieldValue) => {
        if (val.defaultEmployeeId) {
            const data = {
                staffMemberId: val.defaultEmployeeId,
                shiftType: 'shiftPatternShift',
            };
            const shiftPatientFacingWarnings = await shiftPatternsStore.getShiftPatientFacingWarnings(
                data,
            );
            setFieldValue('shiftPatientFacingWarnings', shiftPatientFacingWarnings);
        } else {
            setFieldValue('shiftPatientFacingWarnings', []);
        }
    };

    useEffect(() => {
        if (values.patientFacing) {
            getShiftPatientFacingWarnings(values, setFieldValue);
        } else {
            setFieldValue('shiftPatientFacingWarnings', []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.patientFacing]);

    return (
        <>
            <div className="info info--shift-pattern__entry">
                <dl>
                    <dt className="info__title">Week number *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="weekNumber">
                                <Select
                                    options={weeksListSorted}
                                    onChange={(e) => {
                                        setFieldValue('weekNumber', e && +e.value);
                                    }}
                                    value={weeksListSorted[values.weekNumber - 1]}
                                    name="weekNumber"
                                    id="weekNumber"
                                    placeholder="Select..."
                                />
                                {touched.weekNumber && <Error errors={[errors.weekNumber]} />}
                            </FormGroup>
                        ) : (
                            values.weekNumber
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Day of week *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="dayOfWeek">
                                <Select
                                    options={DAYS}
                                    value={DAYS[values.dayOfWeek - 1]}
                                    onChange={(e) => {
                                        setFieldValue('dayOfWeek', e && +e.value);
                                    }}
                                    name="dayOfWeek"
                                    id="dayOfWeek"
                                    placeholder="Select..."
                                />
                                {touched.dayOfWeek && <Error errors={[errors.dayOfWeek]} />}
                            </FormGroup>
                        ) : (
                            values.dayOfWeek
                        )}
                    </dd>
                </dl>
                <dl className="info__full-width">
                    <dt className="info__title">Location *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="location">
                                <Select
                                    options={locationOptions}
                                    defaultValue={
                                        shiftPatternEntry.locationId && {
                                            label:
                                                locationsDictionary[shiftPatternEntry.locationId],
                                            value: shiftPatternEntry.locationId,
                                        }
                                    }
                                    onChange={(e) => {
                                        setFieldValue('locationId', e && e.value);
                                    }}
                                    name="locationId"
                                    id="locationId"
                                    placeholder="Select..."
                                />
                                {touched.locationId && <Error errors={[errors.locationId]} />}
                            </FormGroup>
                        ) : (
                            locationsDictionary[shiftPatternEntry.locationId]
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Start time (HH:MM)</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="startTime">
                                <TimePicker
                                    id="startTime"
                                    name="startTime"
                                    maxTime={moment(new Date(values.endTime))
                                        .subtract(1, 'hours')
                                        .toDate()}
                                    defaultValue={new Date(shiftPatternEntry.startTime)}
                                    onChange={(date) => {
                                        setFieldValue('startTime', new Date(date));
                                        setFieldValue('rotaShiftPayRates', []);
                                    }}
                                    placeholder="e.g. 09:00"
                                />

                                {touched.startTime && <Error errors={[errors.startTime]} />}
                            </FormGroup>
                        ) : (
                            formatTime(new Date(shiftPatternEntry.startTime))
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">End time (HH:MM)</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="endTime">
                                <TimePicker
                                    id="endTime"
                                    name="endTime"
                                    minTime={moment(new Date(values.startTime))
                                        .add(1, 'hours')
                                        .toDate()}
                                    defaultValue={new Date(shiftPatternEntry.endTime)}
                                    onChange={(date) => {
                                        setFieldValue('endTime', new Date(date));
                                        setFieldValue('rotaShiftPayRates', []);
                                    }}
                                    placeholder="e.g. 09:00"
                                />

                                {touched.endTime && <Error errors={[errors.endTime]} />}
                            </FormGroup>
                        ) : (
                            formatTime(new Date(shiftPatternEntry.endTime))
                        )}
                    </dd>
                </dl>
                <dl className="info__full-width">
                    <dt className="info__title">Role *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="role" className="common-select__has-empty">
                                <Select
                                    options={roleOptions}
                                    isLoading={!roleOptions.length}
                                    defaultValue={
                                        shiftPatternEntry.shiftEmployeeRoleTypeId && {
                                            label:
                                                rolesDictionary[
                                                    shiftPatternEntry.shiftEmployeeRoleTypeId
                                                ],
                                            value: shiftPatternEntry.shiftEmployeeRoleTypeId,
                                        }
                                    }
                                    onChange={async (e) => {
                                        await payRatesStore.getPayRatesByRole(e.value);
                                        setFieldValue('defaultEmployeeId', null);
                                        setFieldValue('payRateId', null);
                                        setFieldValue('shiftEmployeeRoleTypeId', e && e.value);
                                        setPayRates(payRatesStore.gridPayRatesByRoles);
                                        await updatePayRate();
                                    }}
                                    name="shiftEmployeeRoleTypeId"
                                    id="shiftEmployeeRoleTypeId"
                                    placeholder="Select..."
                                />
                                {touched.shiftEmployeeRoleTypeId && (
                                    <Error errors={[errors.shiftEmployeeRoleTypeId]} />
                                )}
                            </FormGroup>
                        ) : (
                            rolesDictionary[shiftPatternEntry.shiftEmployeeRoleTypeId]
                        )}
                    </dd>
                </dl>
                <dl className="info__full-width">
                    <dt className="info__title">Function *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="shiftFunctions">
                                <Select
                                    options={functionOptions}
                                    isLoading={!functionOptions.length}
                                    isMulti={true}
                                    defaultValue={shiftPatternEntry.shiftFunctions.map(
                                        (shiftFunction) => ({
                                            label: functionsDictionary[shiftFunction],
                                            value: shiftFunction,
                                        }),
                                    )}
                                    onChange={(e) => {
                                        setFieldValue(
                                            'shiftFunctions',
                                            e && e.map((item) => item.value),
                                        );
                                    }}
                                    name="shiftFunctions"
                                    id="shiftFunctions"
                                    placeholder="Select..."
                                />
                                {touched.shiftEmployeeRoleTypeId && (
                                    <Error errors={[errors.shiftFunctions]} />
                                )}
                            </FormGroup>
                        ) : (
                            shiftPatternEntry.shiftFunctions
                                .map((shiftFunction) => functionsDictionary[shiftFunction])
                                .join(', ')
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Patient-facing?</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <RadioGroup
                                inline={true}
                                aria-label="Patient-facing?"
                                name="patientFacing"
                                onChange={() => {
                                    setFieldValue('patientFacing', !values.patientFacing);
                                }}
                                selectedValue={values.patientFacing}
                            >
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                                {touched.patientFacing && <Error errors={[errors.patientFacing]} />}
                            </RadioGroup>
                        ) : shiftPatternEntry.patientFacing ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Default staff member</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup
                                labelFor="defaultEmployeeId"
                                className="common-select__has-empty"
                            >
                                <Select
                                    isDisabled={!values.shiftEmployeeRoleTypeId}
                                    options={
                                        staffListWithFilteredRoles && [
                                            {},
                                            ..._.orderBy(
                                                staffListWithFilteredRoles.filter(
                                                    (item) =>
                                                        item.value !==
                                                        values.trainees[0]?.traineeId,
                                                ),
                                                ['label'],
                                                ['asc'],
                                            ),
                                        ]
                                    }
                                    value={
                                        values.defaultEmployeeId && {
                                            label:
                                                staffListWithArchivedStatus[
                                                    values.defaultEmployeeId
                                                ],
                                            value: values.defaultEmployeeId,
                                        }
                                    }
                                    onChange={async (e) => {
                                        setFieldValue('defaultEmployeeId', e && e.value);
                                        if (!e.value) {
                                            setFieldValue('conflicts', []);
                                            setFieldValue('payRateId', null);
                                            await payRatesStore.getPayRatesByRole(
                                                values.shiftEmployeeRoleTypeId,
                                            );
                                        } else {
                                            await payRatesStore.getPayRatesByRole(
                                                values.shiftEmployeeRoleTypeId,
                                                e.value,
                                            );

                                            const conflicts = shiftPatternsStore.getShiftConflicts
                                                ? await shiftPatternsStore.getShiftConflicts(
                                                      e.value,
                                                      moment(values.startTime).format('HH:mm'),
                                                      moment(values.endTime).format('HH:mm'),
                                                      values.weekNumber,
                                                      values.dayOfWeek,
                                                      values.id,
                                                  )
                                                : [];

                                            setFieldValue('conflicts', conflicts);
                                        }
                                        setPayRates(payRatesStore.gridPayRatesByRoles);
                                        await makeChangeIsSupervisorStaff(e && e.value);
                                        getShiftPatientFacingWarnings(values, setFieldValue);
                                    }}
                                    name="defaultEmployeeId"
                                    id="defaultEmployeeId"
                                    placeholder="Select..."
                                />
                                {touched.defaultEmployeeId && (
                                    <Error errors={[errors.defaultEmployeeId]} />
                                )}
                            </FormGroup>
                        ) : (
                            staffListWithArchivedStatus[values.defaultEmployeeId]
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Shift type *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="typeId">
                                <Select
                                    options={shiftTypes}
                                    defaultValue={
                                        shiftPatternEntry.typeId && {
                                            label: shiftTypesDictionary[shiftPatternEntry.typeId],
                                            value: shiftPatternEntry.typeId,
                                        }
                                    }
                                    onChange={(e) => {
                                        setFieldValue('typeId', e && e.value);
                                    }}
                                    name="typeId"
                                    id="typeId"
                                    placeholder="Select..."
                                />
                                {touched.typeId && <Error errors={[errors.typeId]} />}
                            </FormGroup>
                        ) : (
                            shiftTypesDictionary[shiftPatternEntry.typeId]
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Break duration (minutes) *</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="breakDurationMinutes">
                                <InputGroup
                                    id="breakDurationMinutes"
                                    name="breakDurationMinutes"
                                    type="number"
                                    min="0"
                                    onChange={handleChange}
                                    value={values.breakDurationMinutes}
                                    large
                                    placeholder="e.g. 30"
                                />
                                {touched.breakDurationMinutes && (
                                    <Error errors={[errors.breakDurationMinutes]} />
                                )}
                            </FormGroup>
                        ) : (
                            shiftPatternEntry.breakDurationMinutes
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Break is paid ?</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <RadioGroup
                                inline={true}
                                aria-label="Break is Paid"
                                name="breakIsPaid"
                                onChange={() => {
                                    setFieldValue('breakIsPaid', !values.breakIsPaid);
                                }}
                                selectedValue={values.breakIsPaid}
                            >
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                                {touched.breakIsPaid && <Error errors={[errors.breakIsPaid]} />}
                            </RadioGroup>
                        ) : shiftPatternEntry.breakIsPaid ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Funding pool</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="fundingPoolId">
                                <Select
                                    options={fundingPoolsFiltered}
                                    defaultValue={
                                        shiftPatternEntry.fundingPoolId && {
                                            label:
                                                fundingPoolsDictionary[
                                                    shiftPatternEntry.fundingPoolId
                                                ],
                                            value: shiftPatternEntry.fundingPoolId,
                                        }
                                    }
                                    onChange={(e) => {
                                        setFieldValue('fundingPoolId', e && e.value);
                                    }}
                                    name="fundingPoolId"
                                    id="fundingPoolId"
                                    placeholder="Select..."
                                />
                                {touched.fundingPoolId && <Error errors={[errors.fundingPoolId]} />}
                            </FormGroup>
                        ) : (
                            fundingPoolsDictionary[shiftPatternEntry.fundingPoolId]
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Third party paid</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <RadioGroup
                                inline={true}
                                aria-label="Third party paid"
                                name="thirdPartyPaid"
                                onChange={() => {
                                    setFieldValue('thirdPartyPaid', !values.thirdPartyPaid);
                                }}
                                selectedValue={values.thirdPartyPaid}
                            >
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                                {touched.thirdPartyPaid && (
                                    <Error errors={[errors.thirdPartyPaid]} />
                                )}
                            </RadioGroup>
                        ) : values.thirdPartyPaid ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Pay Rate *</dt>
                    <dd className="info__definition common-select__has-empty">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="payRateId">
                                <Select
                                    isDisabled={
                                        (values.shiftEmployeeRoleTypeId &&
                                            !payRatesStore.payRatesByRole.length) ||
                                        (!!values.shiftEmployeeRoleTypeId &&
                                            !!values.defaultEmployeeId) ||
                                        !values.shiftEmployeeRoleTypeId
                                    }
                                    options={payRates.map((payRate) => ({
                                        label: payRate.name,
                                        value: payRate.id,
                                    }))}
                                    value={
                                        values.payRateId && {
                                            label: payRatesList[values.payRateId],
                                            value: values.payRateId,
                                        }
                                    }
                                    onChange={(e) => setFieldValue('payRateId', e && e.value)}
                                    name="payRateId"
                                    id="payRateId"
                                />
                                {touched.payRateId && <Error errors={[errors.payRateId]} />}
                                {values.shiftEmployeeRoleTypeId &&
                                    !values.payRateId &&
                                    !payRatesStore.payRatesByRole.length && (
                                        <p className="builder-form__error">
                                            No pay rates related to the selected role are found.
                                            Please, select another role
                                        </p>
                                    )}
                                {payRateUpdateWarning && (
                                    <p className="builder-form__error">{payRateUpdateWarning}</p>
                                )}
                            </FormGroup>
                        ) : (
                            payRatesList[values.payRateId]
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Override value per shift (£)</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <FormGroup labelFor="overrideValueInPence">
                                <InputGroup
                                    id="overrideValueInPence"
                                    name="overrideValueInPence"
                                    onChange={handleChange}
                                    value={values.overrideValueInPence}
                                    large
                                    type="number"
                                    placeholder="e.g. 150"
                                    min="0"
                                />
                            </FormGroup>
                        ) : (
                            values.overrideValueInPence
                        )}
                    </dd>
                </dl>
                <dl>
                    <dt className="info__title">Training Shift ?</dt>
                    <dd className="info__definition">
                        {editOrCreateMode ? (
                            <RadioGroup
                                inline={true}
                                aria-label="Training Shift"
                                name="trainingShift"
                                onChange={(e) => {
                                    setFieldValue('trainingShift', !values.trainingShift);
                                    if (e.currentTarget.value === 'false') {
                                        setFieldValue('trainees', [
                                            {
                                                traineeId: '',
                                                roleId: '',
                                                overrideValue: null,
                                                payRateId: '',
                                            },
                                        ]);
                                    }
                                }}
                                selectedValue={values.trainingShift}
                            >
                                <Radio label="Yes" value={true} />
                                <Radio label="No" value={false} />
                                {touched.trainingShift && <Error errors={[errors.trainingShift]} />}
                            </RadioGroup>
                        ) : values.trainingShift ? (
                            'Yes'
                        ) : (
                            'No'
                        )}
                    </dd>
                </dl>
                {values.trainingShift && (
                    <FieldArray
                        name="trainees"
                        render={(arrayHelpers) => (
                            <>
                                {values.trainees.map((trainee, index) => {
                                    const traineeStaffWithFilteredRoles = staffStore.allStaffs.filter(
                                        (item) =>
                                            item.departments.length &&
                                            item.departments.map(
                                                (department) =>
                                                    department.departmentId ===
                                                    selectedDepartment[0].id,
                                            ) &&
                                            item.roles.length &&
                                            item.roles[0].employeeRoleTypeId ===
                                                values.trainees[index].roleId &&
                                            (item.statusId === EMPLOYEE_RECORD_ACTIVE_STATUS_ID ||
                                                item.statusId ===
                                                    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID) &&
                                            item.id !== values.defaultEmployeeId,
                                    );
                                    const traineeStaffWithFilteredRolesAndArchived = staffStore.allStaffs.filter(
                                        (item) =>
                                            item.departments.length &&
                                            item.departments.map(
                                                (department) =>
                                                    department.departmentId ===
                                                    selectedDepartment[0].id,
                                            ) &&
                                            item.roles.length &&
                                            item.roles[0].employeeRoleTypeId ===
                                                values.trainees[index].roleId &&
                                            (item.statusId === EMPLOYEE_RECORD_ACTIVE_STATUS_ID ||
                                                item.statusId ===
                                                    EMPLOYEE_RECORD_SUSPENDED_STATUS_ID ||
                                                item.statusId ===
                                                    EMPLOYEE_RECORD_ARCHIVED_STATUS_ID),
                                    );
                                    const traineeStaffListWithArchived = traineeStaffWithFilteredRolesAndArchived.reduce(
                                        (acc, item) => {
                                            acc[item.id] =
                                                item.employeeStatus?.name.includes('Suspended') ||
                                                item.employeeStatus?.name.includes('Archived')
                                                    ? item.person.firstName +
                                                      ' ' +
                                                      item.person.lastName +
                                                      ' - ' +
                                                      item.employeeStatus?.name
                                                    : item.person.firstName +
                                                      ' ' +
                                                      item.person.lastName;
                                            return acc;
                                        },
                                        {},
                                    );

                                    const traineeStaffListWithFilteredRoles = traineeStaffWithFilteredRoles.map(
                                        (staff) => ({
                                            label: staff.employeeStatus?.name.includes('Suspended')
                                                ? staff.person.firstName +
                                                  ' ' +
                                                  staff.person.lastName +
                                                  ' - ' +
                                                  staff.employeeStatus?.name
                                                : staff.person.firstName +
                                                  ' ' +
                                                  staff.person.lastName,
                                            value: staff.id,
                                        }),
                                    );

                                    const updateTraineePayRate = async () => {
                                        if (payRatesStore.traineePayRatesByRole.length) {
                                            setFieldValue(
                                                `trainees.${index}.payRateId`,
                                                payRatesStore.traineePayRatesByRole[0]?.id,
                                            );
                                        } else {
                                            setFieldValue(`trainees.${index}.payRateId`, null);
                                        }
                                    };

                                    return (
                                        <Fragment key={index}>
                                            <dl>
                                                <dt className="info__title">Trainee’s role *</dt>
                                                <dd className="info__definition">
                                                    {editOrCreateMode ? (
                                                        <FormGroup
                                                            labelFor={`trainees-${index}-roleId`}
                                                        >
                                                            <Select
                                                                options={roleOptions}
                                                                onChange={async (e) => {
                                                                    setFieldValue(
                                                                        `trainees.${index}.roleId`,
                                                                        e && e.value,
                                                                    );
                                                                    setFieldValue(
                                                                        `trainees.${index}.traineeId`,
                                                                        null,
                                                                    );
                                                                    await payRatesStore.getTraineePayRatesByRole(
                                                                        e.value,
                                                                    );
                                                                    await updateTraineePayRate();
                                                                }}
                                                                value={
                                                                    values.trainees[index]
                                                                        .roleId && {
                                                                        label:
                                                                            rolesDictionary[
                                                                                values.trainees[
                                                                                    index
                                                                                ].roleId
                                                                            ],
                                                                        value:
                                                                            values.trainees[index]
                                                                                .roleId,
                                                                    }
                                                                }
                                                                id={`trainees-${index}-roleId`}
                                                                name={`trainees-${index}-roleId`}
                                                                placeholder="Select..."
                                                            />
                                                            <div className="info__definition--danger">
                                                                <ErrorMessage
                                                                    name={`trainees.${index}.roleId`}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    ) : (
                                                        rolesDictionary[
                                                            values.trainees[index].roleId
                                                        ]
                                                    )}
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt className="info__title">Trainee</dt>
                                                <dd className="info__definition common-select__has-empty">
                                                    {editOrCreateMode ? (
                                                        <FormGroup
                                                            labelFor={`trainees-${index}-traineeId`}
                                                        >
                                                            <Select
                                                                isDisabled={
                                                                    !values.trainees[index].roleId
                                                                }
                                                                options={
                                                                    traineeStaffListWithFilteredRoles && [
                                                                        {},
                                                                        ..._.orderBy(
                                                                            traineeStaffListWithFilteredRoles,
                                                                            ['label'],
                                                                            ['asc'],
                                                                        ),
                                                                    ]
                                                                }
                                                                isOptionDisabled={(option) =>
                                                                    option.disabled
                                                                }
                                                                value={
                                                                    values.trainees[index]
                                                                        .traineeId && {
                                                                        label:
                                                                            traineeStaffListWithArchived[
                                                                                values.trainees[
                                                                                    index
                                                                                ].traineeId
                                                                            ],
                                                                        value:
                                                                            values.trainees[index]
                                                                                .traineeId,
                                                                    }
                                                                }
                                                                onChange={async (e) => {
                                                                    setFieldValue(
                                                                        `trainees.${index}.traineeId`,
                                                                        e && e.value,
                                                                    );
                                                                    if (!e.value) {
                                                                        setFieldValue(
                                                                            `trainees.${index}.payRateId`,
                                                                            null,
                                                                        );
                                                                        await payRatesStore.getTraineePayRatesByRole(
                                                                            values.trainees[index]
                                                                                .roleId,
                                                                        );
                                                                    } else {
                                                                        await payRatesStore.getTraineePayRatesByRole(
                                                                            values.trainees[index]
                                                                                .roleId,
                                                                            e.value,
                                                                        );
                                                                    }
                                                                    await updateTraineePayRate();
                                                                }}
                                                                id={`trainees-${index}-traineeId`}
                                                                name={`trainees-${index}-traineeId`}
                                                                placeholder="Select..."
                                                            />
                                                            <div className="info__definition--danger">
                                                                <ErrorMessage
                                                                    name={`trainees.${index}.traineeId`}
                                                                />
                                                            </div>
                                                        </FormGroup>
                                                    ) : (
                                                        traineeStaffListWithArchived[
                                                            values.trainees[index].traineeId
                                                        ]
                                                    )}
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt className="info__title">
                                                    Trainee’s Pay Rate *
                                                </dt>
                                                <dd className="info__definition">
                                                    {editOrCreateMode ? (
                                                        <FormGroup
                                                            labelFor={`trainees-${index}-payRateId`}
                                                        >
                                                            <Select
                                                                isDisabled={
                                                                    (values.trainees[index]
                                                                        ?.roleId &&
                                                                        !payRatesStore
                                                                            .traineePayRatesByRole
                                                                            .length) ||
                                                                    (!!values.trainees[index]
                                                                        .roleId &&
                                                                        !!values.trainees[index]
                                                                            .traineeId) ||
                                                                    !values.trainees[index].roleId
                                                                }
                                                                options={payRatesStore.traineePayRatesByRole.map(
                                                                    (payRate) => ({
                                                                        label: payRate.name,
                                                                        value: payRate.id,
                                                                    }),
                                                                )}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        `trainees.${index}.payRateId`,
                                                                        e && e.value,
                                                                    );
                                                                }}
                                                                value={
                                                                    values.trainees[index]
                                                                        .payRateId && {
                                                                        label:
                                                                            payRatesList[
                                                                                values.trainees[
                                                                                    index
                                                                                ].payRateId
                                                                            ],
                                                                        value:
                                                                            values.trainees[index]
                                                                                .payRateId,
                                                                    }
                                                                }
                                                                id={`trainees-${index}-payRateId`}
                                                                name={`trainees-${index}-payRateId`}
                                                                placeholder="Select..."
                                                            />
                                                            <div className="info__definition--danger">
                                                                <ErrorMessage
                                                                    name={`trainees.${index}.payRateId`}
                                                                />
                                                            </div>
                                                            {values.trainees[index].roleId &&
                                                                !values.trainees[index].payRateId &&
                                                                !payRatesStore.traineePayRatesByRole
                                                                    .length && (
                                                                    <p className="builder-form__error">
                                                                        No pay rates related to the
                                                                        selected role are found.
                                                                        Please, select another role
                                                                    </p>
                                                                )}
                                                        </FormGroup>
                                                    ) : (
                                                        payRatesList[
                                                            values.trainees[index].payRateId
                                                        ]
                                                    )}
                                                </dd>
                                            </dl>
                                            <dl>
                                                <dt className="info__title">
                                                    Override value per shift for the trainee (£)
                                                </dt>
                                                <dd className="info__definition">
                                                    {editOrCreateMode ? (
                                                        <FormGroup
                                                            labelFor={`trainees-${index}-overrideValue`}
                                                        >
                                                            <InputGroup
                                                                id={`trainees-${index}-overrideValue`}
                                                                name={`trainees-${index}-overrideValue`}
                                                                onChange={(e) => {
                                                                    setFieldValue(
                                                                        `trainees.${index}.overrideValue`,
                                                                        e && +e.target.value,
                                                                    );
                                                                }}
                                                                value={
                                                                    values.trainees[index]
                                                                        .overrideValue
                                                                }
                                                                large
                                                                type="number"
                                                                placeholder="e.g. 150"
                                                                min="0"
                                                            />
                                                        </FormGroup>
                                                    ) : (
                                                        values.trainees[index].overrideValue
                                                    )}
                                                </dd>
                                            </dl>
                                        </Fragment>
                                    );
                                })}
                            </>
                        )}
                    />
                )}
            </div>
            <CommentsSection
                comments={listOfComments}
                shiftPatternsStore={shiftPatternsStore}
                shiftPatternEntryId={values.id}
                shiftWasPublished={true}
                currentUser={currentUser}
                updateShiftPatternComments={updateShiftPatternComments}
                updateNotPublishedComments={updateNotPublishedComments}
            />
            {values.trainingShift && (
                <div className="warning-blocks warning-blocks--sp">
                    {values.trainingShift &&
                        values.defaultEmployeeId &&
                        !checkIsSupervisorStaff(values.defaultEmployeeId) && (
                            <div className="warning-block">
                                Note that the default staff member selected is not a supervisor
                            </div>
                        )}
                    {values.trainingShift && !values.defaultEmployeeId && (
                        <div className="warning-block">
                            No staff member is selected as a supervisor to the training shift
                        </div>
                    )}
                </div>
            )}
            {values.defaultEmployeeId && (
                <div className="warning-blocks warning-blocks--sp">
                    {staffListWithArchivedStatus[values.defaultEmployeeId]?.includes(
                        'Suspended',
                    ) && (
                        <div className="warning-block">
                            The selected default staff member is suspended
                        </div>
                    )}
                    {staffListWithArchivedStatus[values.defaultEmployeeId]?.includes(
                        'Archived',
                    ) && (
                        <div className="warning-block">
                            The selected default staff member is archived
                        </div>
                    )}
                </div>
            )}
            {!_.values(values.trainees[0]).every(_.isEmpty) && (
                <div className="warning-blocks warning-blocks--sp">
                    {staffListWithArchivedStatus[values.trainees[0]?.traineeId]?.includes(
                        'Suspended',
                    ) && <div className="warning-block">The selected trainee is suspended</div>}
                    {staffListWithArchivedStatus[values.trainees[0]?.traineeId]?.includes(
                        'Archived',
                    ) && <div className="warning-block">The selected trainee is archived</div>}
                </div>
            )}
            <div className="warning-blocks warning-blocks--sp no-margin-callout">
                <Warnings stepWarnings={values.conflicts.map((r) => r.label)} />
            </div>
            {values.shiftPatientFacingWarnings && values.shiftPatientFacingWarnings.length > 0 && (
                <div className="warning-blocks warning-blocks--sp no-margin-callout">
                    <Warnings stepWarnings={values.shiftPatientFacingWarnings.map((r) => r)} />
                </div>
            )}
        </>
    );
};

export default inject('RootStore')(observer(ShiftPatternEntry));
