import React from 'react';
import { DateRangePicker3 } from '@blueprintjs/datetime2';
import { Classes, Icon, Popover } from '@blueprintjs/core';
import { DateRange } from '@blueprintjs/datetime';
import classNames from 'classnames';

import MomentDateRange from './MomentDateRange';
import moment from 'moment';

const DateSelector = (props: any) => {
    const { onChange, disabled } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const [dateRange, setDateRange] = React.useState<DateRange | undefined>(
        props.dateRange || [null, null],
    );

    React.useEffect(() => {
        setDateRange(props.dateRange);
    }, [props.dateRange]);

    const handleChange = (val: any) => {
        setDateRange(val);
        onChange(val);
    };

    const handleInteraction = (nextOpenState: boolean) => {
        if (disabled) setIsOpen(false);
        else setIsOpen(nextOpenState);
    };

    return (
        <div className="date-selector-wrapper">
            <Popover
                isOpen={isOpen}
                onInteraction={handleInteraction}
                placement="bottom"
                interactionKind="click"
                usePortal
                defaultIsOpen={false}
                content={
                    <DateRangePicker3
                        minDate={props.minDate}
                        highlightCurrentDay
                        shortcuts={false}
                        maxDate={moment().add(1, 'years').toDate()}
                        onChange={(evt: any) => {
                            handleChange(evt);

                            if (!!evt[0] && !!evt[1]) {
                                setIsOpen(false);
                            }
                        }}
                        className={classNames(
                            Classes.CARD,
                            Classes.ELEVATION_4,
                            'date-range-picker',
                        )}
                        value={dateRange}
                        dayPickerProps={{
                            weekStartsOn: 1,
                        }}
                    />
                }
            >
                <div className="date-selector">
                    <Icon
                        icon="calendar"
                        intent="primary"
                        className="date-selector__calendar-icon"
                        onClick={() => setIsOpen(!isOpen)}
                    />
                    <MomentDateRange
                        withTime={false}
                        range={dateRange as any}
                        onDateClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            </Popover>
        </div>
    );
};

export default DateSelector;
