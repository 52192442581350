import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { Tag } from '@blueprintjs/core';
import _ from 'lodash';

const SidebarNavigation = inject('RootStore')(
    observer(
        class SidebarNavigation extends Component {
            render() {
                const {
                    match: { params },
                    RootStore: {
                        jobsStore: {
                            onDemand,
                            availableJobs,
                            acceptedJobs,
                            currentJobs,
                            arrivedJobs,
                        },
                    },
                } = this.props;

                // This will make the page variable to add
                // everything in the URL after the /
                // This prevents any length of URL from breaking the tabs
                let noTabUrl = '';
                _.forOwn(params, (paramValue, paramName) =>
                    paramName !== 'tab' && paramValue !== undefined
                        ? (noTabUrl = `${noTabUrl}/${paramValue}`)
                        : '',
                );
                noTabUrl = noTabUrl.replace('/', '');

                return (
                    <nav className="tabs">
                        <ul className="tabs__list">
                            <li className="tabs__item">
                                <NavLink
                                    className="tabs__link tabs__link--available"
                                    activeClassName="tabs__link--active"
                                    to={`/on-demand/available/${noTabUrl}`}
                                >
                                    Available
                                    <Tag minimal round className="tabs__tag">
                                        {onDemand(availableJobs).length}
                                    </Tag>
                                </NavLink>
                            </li>
                            <li className="tabs__item">
                                <NavLink
                                    className="tabs__link tabs__link--accepted"
                                    activeClassName="tabs__link--active"
                                    to={`/on-demand/accepted/${noTabUrl}`}
                                >
                                    Accepted
                                    <Tag minimal round className="tabs__tag">
                                        {onDemand(acceptedJobs).length}
                                    </Tag>
                                </NavLink>
                            </li>
                            <li className="tabs__item">
                                <NavLink
                                    className="tabs__link tabs__link--current"
                                    activeClassName="tabs__link--active"
                                    to={`/on-demand/current/${noTabUrl}`}
                                >
                                    Current
                                    <Tag minimal round className="tabs__tag">
                                        {onDemand(currentJobs).length}
                                    </Tag>
                                </NavLink>
                            </li>
                            <li className="tabs__item">
                                <NavLink
                                    className="tabs__link tabs__link--arrived"
                                    activeClassName="tabs__link--active"
                                    to={`/on-demand/arrived/${noTabUrl}`}
                                >
                                    Arrived
                                    <Tag minimal round className="tabs__tag">
                                        {onDemand(arrivedJobs).length}
                                    </Tag>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                );
            }
        },
    ),
);

export default SidebarNavigation;
