import { PatientAlert, StaffAlert } from '@doc-abode/data-models';

import { rootUrl, retryable, headers } from './baseApi';

interface GetAlertsResponse {
    patientAlerts: PatientAlert[];
    staffAlerts: StaffAlert[];
}

export const getAlerts = (
    authToken: string,
    body: { jobIds: string[] },
): Promise<GetAlertsResponse> =>
    retryable(() =>
        fetch(`${rootUrl}/alerts/search`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
            method: 'POST',
            body: JSON.stringify(body),
        }),
    ) as Promise<GetAlertsResponse>;

export const updateAlert = (
    authToken: string,
    alertId: string,
    body: Partial<PatientAlert | StaffAlert>,
): Promise<PatientAlert> =>
    retryable(() =>
        fetch(`${rootUrl}/alerts/${alertId}`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
            method: 'PATCH',
            body: JSON.stringify(body),
        }),
    ) as Promise<PatientAlert>;

export const getJobAlerts = (
    authToken: string,
    jobId: string,
): Promise<{ alerts: PatientAlert[] }> =>
    retryable(() =>
        fetch(`${rootUrl}/alerts/search/${jobId}`, {
            headers: {
                Authorization: authToken,
                ...headers,
            },
            method: 'POST',
            body: JSON.stringify({}),
        }),
    ) as Promise<{ alerts: PatientAlert[] }>;
