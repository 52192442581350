import { JobStatus, Patient } from '@doc-abode/data-models';
import { getAllWarnings } from './getVisitWarnings';
import { GenderCompatibility, getGenderWarning, getLanguageWarning } from './helpers';

export type VisitValuesType = {
    id?: string;
    nhsNumber: string;
    startTime?: Date | string | null;
    visitDate?: Date | string | null;
    duration?: Date | string;
    arrivedDateTime?: Date | string | null;
    finishedDateTime?: Date | string | null;
    hcpId?: string | null;
    buddyId?: string | null;
    jobStatus?: JobStatus;
    staffRequired?: number;
};

const getWarningVisitData = (values: Patient): VisitValuesType =>
    ({
        id: values.id,
        nhsNumber: values.nhsNumber,
        startTime: values.startDateTime,
        visitDate: values.dateOfVisit,
        earliestDateOfVisit: values.earliestDateOfVisit,
        availableFrom: values.availableFrom,
        availableTo: values.availableTo,
        duration: values.duration,
        arrivedDateTime: values.arrivedDateTime,
        finishedDateTime: values.finishedDateTime,
        jobStatus: values.jobStatus,
        hcpId: values.hcpId,
        buddyId: values.buddyId,
        staffRequired: values.staffRequired,
    } as any);

export function createStepWarnings(hcpUsers: any[], patient: Patient) {
    const hcpArr: (any | void)[] = [
        hcpUsers.find((hcp) => hcp.userId === patient?.hcpId),
        hcpUsers.find((hcp) => hcp.userId === patient?.buddyId),
    ];

    const genderWarnings = hcpArr.flatMap((hcp) =>
        getGenderWarning(hcp, patient.staffPreferredGender as Array<keyof GenderCompatibility>),
    );
    const hcpLanguageWarnings = hcpArr.flatMap((hcp) =>
        getLanguageWarning(hcp, patient.languagesSpoken),
    );
    return [
        ...getAllWarnings(getWarningVisitData(patient)),
        ...genderWarnings,
        ...hcpLanguageWarnings,
    ].filter(Boolean); // Just make sure there's no empties
}

export function createStepWarningsIcon(hcpUsers: any[], patient: Patient, isSecondHCP: boolean) {
    let hcp;
    if (!isSecondHCP) {
        hcp = hcpUsers.find((hcp) => hcp.userId === patient?.hcpId);
    } else {
        hcp = hcpUsers.find((hcp) => hcp.userId === patient?.buddyId);
    }

    const genderWarnings = getGenderWarning(
        hcp,
        patient.staffPreferredGender as Array<keyof GenderCompatibility>,
    );

    const hcpLanguageWarnings = getLanguageWarning(hcp, patient.languagesSpoken);

    return [
        ...getAllWarnings(getWarningVisitData(patient)),
        ...genderWarnings,
        ...hcpLanguageWarnings,
    ].filter(Boolean); // Just make sure there's no empties
}
